// This file was generated from com.worksmith.user.enums.UserAffiliationType
// noinspection JSUnusedGlobalSymbols
const UserAffiliationType = Object.freeze({
    CLIENT: 'Client',
    CLIENT_LOCATION: 'Client Location',
    MULTIPLE_CLIENT_LOCATIONS: 'Multiple Client Locations',
    VENDOR: 'Vendor',
    WORKSMITH: 'Worksmith'
});

export default UserAffiliationType;
