import React from 'react';
import styled from 'styled-components';
import Proptypes from "prop-types";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Tooltip from "worksmith/components/Tooltip/Tooltip.web";
import {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";

const AutoMarkedNoncompliantIcon = (props) => {

    const {styles, height, width} = props;
    let margin = '0 0 0 0';
    if(ValueIsSet(props.margin)){
        margin = props.margin;
    }

    const IconSvg = styled.svg`
        height: ${height};
        width: ${width}; 
        margin: ${margin};
        ${styles}
    `;

    return (
        <Tooltip placement={TooltipPlacement.BOTTOM} title={'noncompliant - auto'} style={{margin: '0 auto'}}>
            <IconSvg width={'24px'} height={'24px'} viewBox={"0 0 24 24"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
                <g id="v3-sla-admin-portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-55.000000, -158.000000)">
                        <g id="NC" transform="translate(55.000000, 158.000000)">
                            <rect id="Rectangle" fill="#DA1E28" x="0" y="0" width="24" height="24" rx="4"></rect>
                            <path d="M4.83820955,17 L4.83820955,11.9174248 C4.83820955,11.2308314 4.80700076,10.4729035 4.74458317,9.64364122 L4.74458317,9.64364122 L4.71114517,9.20894729 L4.76464596,9.20894729 L9.57302957,17 L11.532496,17 L11.532496,7.22273042 L10.0679119,7.22273042 L10.0679119,12.3454312 C10.0679119,12.7065615 10.0812871,13.209246 10.1080375,13.8534847 C10.1347879,14.4977234 10.1526215,14.8711144 10.1615383,14.9736575 L10.1615383,14.9736575 L10.1214127,14.9736575 L5.32640427,7.22273042 L3.38700059,7.22273042 L3.38700059,17 L4.83820955,17 Z M18.2796554,17.133752 C19.2515864,17.133752 20.1209743,16.988854 20.8878189,16.699058 L20.8878189,16.699058 L20.8878189,15.3281003 C20.4687294,15.4573939 20.0652443,15.5632808 19.6773635,15.6457612 C19.2894828,15.7282416 18.8882269,15.7694818 18.4735957,15.7694818 C17.5150399,15.7694818 16.7827478,15.4618523 16.2767195,14.8465932 C15.7706912,14.2313341 15.517677,13.3240498 15.517677,12.1247404 C15.517677,10.9789318 15.7807226,10.0805643 16.3068137,9.42963805 C16.8329048,8.77871176 17.5551655,8.45324862 18.4735957,8.45324862 C18.8793101,8.45324862 19.2660762,8.51232241 19.6338941,8.63046999 C20.0017121,8.74861757 20.3505818,8.88571334 20.6805034,9.04175732 L20.6805034,9.04175732 L21.2556369,7.71092514 C20.408541,7.29183561 19.4856524,7.08229084 18.4869709,7.08229084 C17.5373319,7.08229084 16.7102988,7.28514801 16.0058717,7.69086234 C15.3014447,8.09657667 14.7630929,8.68062698 14.3908166,9.44301325 C14.0185403,10.2053995 13.8324021,11.0948502 13.8324021,12.1113652 C13.8324021,13.7253057 14.2158244,14.9658553 14.9826691,15.833014 C15.7495138,16.7001726 16.8485092,17.133752 18.2796554,17.133752 Z" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </IconSvg>
        </Tooltip>
    )
};

AutoMarkedNoncompliantIcon.propTypes = {
    styles: Proptypes.string,
    height:  Proptypes.string,
    width: Proptypes.string,
    margin: Proptypes.string
};

export default AutoMarkedNoncompliantIcon;