// This file was generated from com.worksmith.contract.enums.ServiceLevelAgreementType
// noinspection JSUnusedGlobalSymbols
const ServiceLevelAgreementType = Object.freeze({
    REQUEST_ACKNOWLEDGEMENT: 'REQUEST_ACKNOWLEDGEMENT',
    ON_DEMAND_REQUEST_COMPLETION: 'ON_DEMAND_REQUEST_COMPLETION',
    RECURRING_JOB_COMPLETION: 'RECURRING_JOB_COMPLETION',
    EMERGENCY_REQUEST_COMPLETION: 'EMERGENCY_REQUEST_COMPLETION',
    EMERGENCY_REQUEST_SCHEDULING: 'EMERGENCY_REQUEST_SCHEDULING',
    EMERGENCY_REQUEST_RESPONSE: 'EMERGENCY_REQUEST_RESPONSE'
});

export default ServiceLevelAgreementType;
