import React from 'react';
import PropTypes from 'prop-types';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {InputAdornment} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {withStyles} from "@mui/styles";
import styled from "styled-components";
import Input from "@mui/material/Input";

import {Display} from "../../enums/CSSEnums";
import {InputBaseVariant} from "../Inputs/InputBase/InputBase.web";

const customStyles = theme => {

    return {
        divider: {
            marginLeft: 10,
            marginRight: 10,
        },
    };
};

const DividerWithMargins = styled(Divider)`
    margin-left: 10px;
    margin-right: 10px;
`;

const SimpleLineRangePickerInputView = (props) =>{
    const {
        classes,
        disabled,
        autoFocusEndDate,
        autoFocusStartDate,
        childRef,
        endDate,
        endDateInputRef,
        error,
        onInputBlur,
        onEndDateChange,
        onEndDateClick,
        onEndDateFocus,
        endDatePlaceholderText,
        fullWidth,
        readOnly,
        startDate,
        startDateInputRef,
        onStartDateChange,
        onStartDateClick,
        onStartDateFocus,
        startDatePlaceholderText,
    } = props;

    return (
        <Box width={fullWidth ? '100%' : null}
             ref={childRef}
             display={Display.INLINE_FLEX}>
            <Input
                inputRef={startDateInputRef}
                fullWidth
                disabled={disabled}
                readOnly={readOnly}
                error={error && autoFocusStartDate}
                variant={InputBaseVariant.SIMPLE_LINE}
                startAdornment={
                    <InputAdornment position={'start'} disablePointerEvents>
                        <DateRangeIcon/>
                    </InputAdornment>
                }
                autoFocus={autoFocusStartDate}
                onBlur={onInputBlur}
                onClick={onStartDateClick}
                onFocus={onStartDateFocus}
                onChange={onStartDateChange}
                placeholder={startDatePlaceholderText}
                value={startDate}/>
            <DividerWithMargins orientation="vertical" flexItem className={classes.divider}/>
            <Input
                inputRef={endDateInputRef}
                fullWidth
                disabled={disabled}
                readOnly={readOnly}
                error={error && autoFocusEndDate}
                variant={InputBaseVariant.SIMPLE_LINE}
                autoFocus={autoFocusEndDate}
                onBlur={onInputBlur}
                onClick={onEndDateClick}
                onFocus={onEndDateFocus}
                onChange={onEndDateChange}
                placeholder={endDatePlaceholderText}
                value={endDate}/>
        </Box>
    )
};

SimpleLineRangePickerInputView.propTypes = {
    autoFocusStartDate: false,
    autoFocusEndDate: false,
    disabled: false,
    initialEndDate: '',
    initialStartDate: '',
    error: false,
    fullWidth: false,
    readOnly: false,
};

SimpleLineRangePickerInputView.propTypes = {
    autoFocusEndDate: PropTypes.bool,
    autoFocusStartDate: PropTypes.bool,
    childRef: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    endDateInputRef: PropTypes.object,
    endDateOnBlur: PropTypes.func,
    onEndDateChange: PropTypes.func,
    onEndDateClick: PropTypes.func,
    onEndDateFocus: PropTypes.func,
    endDatePlaceholderText: PropTypes.string,
    fullWidth: PropTypes.bool,
    initialEndDate: PropTypes.string,
    initialStartDate: PropTypes.string,
    readOnly: PropTypes.bool,
    startDateInputRef: PropTypes.object,
    onStartDateChange: PropTypes.func,
    onStartDateClick: PropTypes.func,
    onStartDateFocus: PropTypes.func,
    startDatePlaceholderText: PropTypes.string,
};

export default withStyles(customStyles, {name: 'MuiSimpleLineRangePicker'})(SimpleLineRangePickerInputView);