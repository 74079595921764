import React, {Fragment, useState} from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from 'prop-types';

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import Grid from "../../components/Grid/Grid.web";
import FieldTitleAndBody from "../FieldTitleAndBody/FieldTitleAndBody";
import PriceBreakdown from "../PriceBreakdown/PriceBreakdown";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import {FrequencyString} from "../../helpers/LanguageHelper";
import Schedule from "../../components/Schedule/Schedule.web";
import Button from "../../components/Button/Button.web";
import MenuButton from "../../components/MenuButton/MenuButton.web";
import BidDetailsActionList from "./BidDetailsActionList";
import {AlignItems, Display, JustifyContent, Position} from "worksmith/enums/CSSEnums";
import Text, {TextColor, TextVariant} from "../../components/Text/Text.web";
import AcceptAndDeferModal from "../BidAndWalkthroughModals/AcceptAndDeferModal";
import SelectScheduleModal from "../BidAndWalkthroughModals/SelectScheduleModal";
import CreateScheduleOptionsDialog from "../ScheduleOptionsForm/CreateScheduleOptionsDialog";
import RequestApprovalModal from "../BidAndWalkthroughModals/RequestApprovalModal";
import RequestARevisionModal from "./Modals/RequestARevisionModal";
import DeclineBidDialog from "../DeclineBid/DeclineBidDialog";
import CircularProgress from "../../components/Loader/CircularProgress";
import BidStatus from "worksmith/enums/api/proposal/BidStatus";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import {withTheme} from "../../components/Theme/ThemeProvider";
import NewRequestStatus from "worksmith/enums/api/proposal/NewRequestStatus";
import RequestedAndSuggestedFrequency
    from "worksmith/composite-components/RequestedAndSuggestedFrequency/RequestedAndSuggestedFrequency";
import {formatTimestamp, ObjectsHaveIdenticalValue, ValueIsSet} from "../../helpers/GenericHelpers";
import {WithContext} from "../../../context/GlobalContext";

const BidDetailsView = (props) => {
    const {
        context,
        acceptBid,
        acceptAndSchedule,
        additionalApprovalAmount,
        additionalTerms,
        bidId,
        bidItems,
        bidSubmittedActivity,
        canAcceptBid,
        cancelRequest,
        canRequestRevision,
        createdTimestamp,
        decline,
        duration,
        frequency,
        hideActionButton,
        isCompleted,
        isAdmin,
        isRegionalManager,
        isEstimate,
        latestRevision,
        loading,
        request,
        requestApproval,
        requestedFrequency,
        requestRevision,
        requestedRevision,
        scheduleHandler,
        status,
        totalRetailPrice,
        vendorLocation
    } = props;
    const theme = useTheme();
    const onMobile =  useMediaQuery(theme.breakpoints.down('md'));
    const [showActionMenu, setShowActionMenu] = useState(false);
    const [showAcceptAndScheduleModal, setShowAcceptAndScheduleModal] = useState(false);
    const [showAcceptAndSubmitSchedulesModal, setShowAcceptAndSubmitSchedulesModal] = useState(false);
    const [showAcceptAndDeferModal, setShowAcceptAndDeferModal] = useState(false);
    const [showRequestRevisionModal, setShowRequestRevisionModal] = useState(false);
    const [showRequestApprovalModal, setShowRequestApprovalModal] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);

    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;

    if (loading) {
        return (
            <Box
                display={Display.FLEX}
                justify={JustifyContent.CENTER}
                height={400}
                width={'100%'}>
                <CircularProgress/>
            </Box>
        );
    }

    let hasScheduleOptions = scheduleHandler && scheduleHandler.openScheduleOptions.filter(schedule => !schedule.isExpired).length > 0;
    const showActionsAndModals = !hideActionButton
        && status !== BidStatus.ACCEPTED
        && request.type !== RfpType.PRICING_ONLY
        && !(request.newStatus === NewRequestStatus.CORPORATE_PRICING_REVIEW && status === BidStatus.NEEDS_CLIENT_INPUT);

    return (
        <Fragment>
            {
                showActionsAndModals ?
                    <Fragment>
                    <AcceptAndDeferModal
                        accept={acceptBid}
                        closeModal={() => setShowAcceptAndDeferModal(false)}
                        open={showAcceptAndDeferModal}/>
                    {
                       scheduleHandler ?
                           <SelectScheduleModal
                               closeModal={() => setShowAcceptAndScheduleModal(false)}
                               scheduleHandler={scheduleHandler}
                               open={showAcceptAndScheduleModal}
                               selectSchedules={acceptAndSchedule}/>
                               :
                           null
                    }
                    <CreateScheduleOptionsDialog
                        duration={duration}
                        frequency={frequency}
                        title={ValueIsSet(frequency) ? "Submit Start Date" : "Submit Availability"}
                        numberOfSchedules={2}
                        numberOfRequiredSchedules={1}
                        open={showAcceptAndSubmitSchedulesModal}
                        onSubmit={acceptBid}
                        useTicketing={request.serviceLine.useTicketing}
                        onCancel={() => setShowAcceptAndSubmitSchedulesModal(false)}/>
                    <RequestApprovalModal
                        requestApproval={requestApproval}
                        closeModal={() => setShowRequestApprovalModal(false)}
                        open={showRequestApprovalModal}
                        vendorLocation={vendorLocation}/>
                    <RequestARevisionModal
                        requestRevision={requestRevision}
                        open={showRequestRevisionModal}
                        closeModal={() => setShowRequestRevisionModal(false)}/>
                    <DeclineBidDialog
                        bidId={bidId}
                        cancelRequest={cancelRequest}
                        declineBid={decline}
                        isEstimate={isEstimate}
                        onCancel={() => setShowDeclineModal(false)}
                        open={showDeclineModal}/>
                    </Fragment>
                :
                null
            }

            <Box
                position={Position.RELATIVE}
                width={'100%'}>
                {
                    showActionsAndModals ?
                        <Box
                            position={onMobile ? Position.Relative : Position.ABSOLUTE}
                            right={0}
                            top={4}
                            mb={onMobile ? 2 : 0}>
                            <MenuButton
                                Button={
                                    <Button
                                            testId={"bidDetailsActionButton"}
                                            primary
                                            disabled={loading}
                                            endIcon={<ArrowDropDownIcon/>}
                                            onClick={() => setShowActionMenu(true)}>
                                        ACTIONS
                                    </Button>
                                }
                                onClose={() => setShowActionMenu(false)}
                                open={showActionMenu}>
                                <BidDetailsActionList
                                    canAcceptBid={canAcceptBid}
                                    canDecline={request.serviceLine.useTicketing}
                                    isAdmin={isAdmin}
                                    isRegionalManager={isRegionalManager}
                                    isInCorporateReview={status === BidStatus.PENDING_ADMIN || status === BidStatus.PENDING_REGIONAL_MANAGER}
                                    isPricingOnly={request.type === RfpType.PRICING_ONLY}
                                    canRequestRevision={canRequestRevision}
                                    hasOpenRevision={(requestedRevision && !latestRevision)}
                                    hasScheduleOptions={hasScheduleOptions}
                                    setShowActionMenu={setShowActionMenu}
                                    setShowAcceptAndDeferModal={setShowAcceptAndDeferModal}
                                    setShowAcceptAndScheduleModal={setShowAcceptAndScheduleModal}
                                    setShowAcceptAndSubmitSchedulesModal={setShowAcceptAndSubmitSchedulesModal}
                                    setShowDeclineModal={setShowDeclineModal}
                                    setShowRequestApprovalModal={setShowRequestApprovalModal}
                                    setShowRequestRevisionModal={setShowRequestRevisionModal}
                                    />
                            </MenuButton>
                        </Box>
                        :
                        null
                }
                <Grid
                    container
                    spacing={4}>
                    {
                        latestRevision ?
                            <FieldTitleAndBody
                                key={'latestRevision'}
                                xs={10}
                                item
                                title={'Latest Revision by Vendor'}
                                body={latestRevision}/>
                            :
                            null
                    }
                    {
                        requestedRevision ?
                            <FieldTitleAndBody
                                key={'requestedRevision'}
                                xs={10}
                                item
                                title={'You Requested a Revision'}
                                titleColor={TextColor.ERROR}
                                body={requestedRevision}/>
                            :
                            null
                    }
                    {
                    scheduleHandler && scheduleHandler.openScheduleOptions.length > 0 ?
                        <FieldTitleAndBody
                            key={'availability'}
                            xs={10}
                            item
                            title={'Availability'}
                            body={
                               <Grid container>
                                   {
                                       scheduleHandler.openScheduleOptions.map((schedule) => (
                                           <Grid item xs={10} key={schedule.id}>
                                               <Schedule
                                                   schedule={schedule.schedule}
                                                   textVariant={TextVariant.BODY_1}/>
                                               {
                                                   schedule.isExpired ?
                                                       <Text color={TextColor.ERROR}>*Availability expired</Text>
                                                       :
                                                       null
                                               }
                                           </Grid>
                                       ))
                                   }
                               </Grid>
                            }/>
                            :
                        null
                }
                {
                    frequency ?
                        <FieldTitleAndBody
                            key={'frequency'}
                            xs={10}
                            item
                            title={'Frequency'}
                            body={(ValueIsSet(requestedFrequency) && !ObjectsHaveIdenticalValue(requestedFrequency, frequency)) ?
                                <RequestedAndSuggestedFrequency
                                    requestedFrequency={requestedFrequency}
                                    suggestedFrequency={frequency}
                                /> :
                                FrequencyString(props.frequency)}/>
                            : null
                }
                <FieldTitleAndBody
                    key={'terms'}
                    item
                    xs={10}
                    title={'Terms & Conditions'}
                    body={
                        additionalTerms ?
                            additionalTerms
                            :
                            'None'
                    }
                />
                {!hidePricingForNonAdminUsers &&
                    <FieldTitleAndBody
                        key={'priceBreakdown'}
                        item
                        xs={12}
                        title={'Price Breakdown'}
                        body={
                            <PriceBreakdown
                                loading={loading}
                                items={bidItems}
                                activityTotal={totalRetailPrice}
                                additionalApprovalAmount={additionalApprovalAmount}/>
                        }
                    />
                }
                {!isCompleted && ValueIsSet(bidSubmittedActivity) ?
                    <Grid container justify={JustifyContent.FLEX_END} alignItems={AlignItems.FLEX_END} xs={12}><Text> {'Submitted by ' + bidSubmittedActivity.user.displayName + ' on ' + formatTimestamp(bidSubmittedActivity.createdTimestamp)}</Text></Grid>
                    : !isCompleted && ValueIsSet(createdTimestamp) ?
                        <Grid container justify={JustifyContent.FLEX_END} alignItems={AlignItems.FLEX_END} xs={12}><Text> {'Submitted on ' + formatTimestamp(createdTimestamp)}</Text></Grid>
                        : null}
                </Grid>
            </Box>
        </Fragment>
    )
};

BidDetailsView.propTypes = {
    acceptBid: PropTypes.func,
    acceptAndSchedule: PropTypes.func,
    additionalApprovalAmount: PropTypes.number,
    additionalTerms: PropTypes.string,
    bidId: PropTypes.number,
    bidItems: PropTypes.arrayOf(PropTypes.shape({
        isDoable: PropTypes.bool,
        lineItemType: PropTypes.shape({
            name: PropTypes.string
        }),
        quantity: PropTypes.number,
        name: PropTypes.string,
        unitRetailPrice: PropTypes.number
    })),
    canAcceptBid: PropTypes.bool,
    cancelRequest: PropTypes.func,
    canRequestRevision: PropTypes.bool,
    decline: PropTypes.func,
    duration: PropTypes.number,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    hideActionButton: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isRegionalManager: PropTypes.bool,
    isEstimate: PropTypes.bool,
    latestRevision: PropTypes.string,
    loading: PropTypes.bool,
    request: PropTypes.shape({
        id: PropTypes.number,
        newStatus: CustomPropTypes.enum(NewRequestStatus),
        type: CustomPropTypes.enum(RfpType)
    }),
    requestApproval: PropTypes.func,
    requestedFrequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    requestRevision: PropTypes.func,
    requestedRevision: PropTypes.string,
    scheduleHandler: PropTypes.shape({
        id: PropTypes.number,
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        }))
    }),
    status: CustomPropTypes.enum(BidStatus),
    totalRetailPrice: PropTypes.number,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    })
};

export default WithContext(withTheme(BidDetailsView));