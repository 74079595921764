import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Skeleton as MaterialSkeleton} from "@mui/lab";
import PropTypes from 'prop-types';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {JavascriptType} from "worksmith/enums/GenericEnums";

export const SkeletonAnimation = Object.freeze({
    PULSE: 'pulse',
    WAVE: 'wave',
    NONE: false
});

export const SkeletonVariant = Object.freeze({
    TEXT: 'text',
    RECTANGLE: 'rect',
    CIRCLE: 'circular'
});

const StyledSkeleton = styled(MaterialSkeleton)`
  ${({chip}) => chip ? 'border-radius: 18px' : null}
`;

const Skeleton = (props) => {
    const {
        animation,
        chip,
        height,
        margin,
        numberOfLines,
        variant,
        width
    } = props;

    const extraStyling = {
    };

    if (ValueIsSet(margin))
        extraStyling.margin = typeof margin === JavascriptType.STRING ? margin : `${margin}px`;

    return (
        numberOfLines ?
            <Fragment>
                {
                    new Array(numberOfLines).fill(null).map((empty, index) => {
                        return <StyledSkeleton key={index}
                                          animation={animation}
                                          chip={chip}
                                          height={height}
                                          variant={SkeletonVariant.TEXT}
                                          width={width}/>
                    })
                }
            </Fragment>
            :
            <StyledSkeleton animation={animation}
                              chip={chip}
                              height={height}
                              variant={variant}
                            style={extraStyling}
                              width={width}/>
    )
};

Skeleton.propTypes = {
    animation: CustomPropTypes.enum(SkeletonAnimation),
    chip: PropTypes.bool,
    height: CustomPropTypes.stringOrNumber,
    numberOfLines: PropTypes.number,
    variant: CustomPropTypes.enum(SkeletonVariant),
    width: CustomPropTypes.stringOrNumber
};

Skeleton.defaultProps = {
    animation: SkeletonAnimation.PULSE,
    variant: SkeletonVariant.TEXT
};

export default Skeleton;
