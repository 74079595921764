import React, {Component} from 'react';
import PropTypes from 'prop-types';

import UserService from "worksmith/services/api/UserService";
import * as WebSessionHelper from "worksmith/helpers/WebSessionHelper";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";

const userService = new UserService();

class MobileAppRedirectModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            redirectLink: null
        };
    }

    componentDidMount() {
        let {ticketingOnly, userId} = this.props;
        let isIPhone = !!navigator.userAgent.match(/iphone/ig) || false;
        let isAndroid = !!navigator.userAgent.match(/android/ig) || false;

        if(WebSessionHelper.showRedirectToAppModal() !== "false"
            && !ticketingOnly
            && (isIPhone || isAndroid)) {

            userService.getUserDeviceTypes(userId).then(deviceTypes => {
                let redirectLink = null;

                if(isIPhone){
                    if(deviceTypes.includes('ios')){
                        redirectLink = "worksmithapp://app";
                    } else {
                        redirectLink = "itms-apps://itunes.apple.com/us/app/worksmith-vendors/id1444807706?ls=1&mt=8";
                    }
                } else if(isAndroid){
                    if(deviceTypes.includes('android')){
                        redirectLink = "intent://#Intent;scheme=worksmithapp;package=com.worksmithmobileapp;end";
                    } else {
                        redirectLink = "http://play.google.com/store/apps/details?id=com.worksmithmobileapp";
                    }
                }

                this.setState({redirectLink: redirectLink, open: true});
            });
        }

    }

    onClose = () => {
        WebSessionHelper.setShowRedirectToAppModal(false);
        this.setState({open: false});
    };

    goToApp = () => {
        const {redirectLink} = this.state;
        window.location.replace(redirectLink);
        this.setState({open: false});
    };

    render() {
        const {onClose, goToApp} = this;
        const {open} = this.state;

        return (
            <AlertDialog acceptText={'Yes, Go to App'}
                         cancelText={'No'}
                         open={open}
                         onAccept={goToApp}
                         onCancel={onClose}
                         onClose={onClose}
            >
                Would you like to use the mobile app instead?
            </AlertDialog>
        )
    }
}

MobileAppRedirectModal.propTypes = {
    ticketingOnly: PropTypes.bool.isRequired,
    userId:        PropTypes.number.isRequired
};

export default MobileAppRedirectModal;