import React from "react";
import PropTypes from 'prop-types';
import {DatePicker} from 'antd';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ComponentSize} from "worksmith/enums/ComponentSize";

const {MonthPicker: AntdMonthPicker} = DatePicker;

const MonthPicker = (props) =>  {
    let {
        className,
        isDateDisabled,
        onChange,
        size
    } = props;

    return <AntdMonthPicker
                className={className}
                disabledDate={isDateDisabled}
                onChange={onChange}
                size={size}/>;
};

MonthPicker.propTypes = {
    isDateDisabled: PropTypes.func, // function that takes a moment and returns a boolean for whether it is disabled or not
    onChange: PropTypes.func,
    size: CustomPropTypes.enum(ComponentSize)
};

export default MonthPicker;