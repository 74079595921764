import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LoginPageView from "./LoginPageView";
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import MarketingService from 'worksmith/services/api/MarketingService';

import {WithContext} from "../../context/GlobalContext";
import {ValueIsSet} from "../../shared/helpers/Helpers";

let userService = new UserService();
let authTokenManager = new AuthTokenManager();
const marketingService = new MarketingService();

class LoginPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            errorMessage: null,
            submitting: false
        };
    }

    onSubmit = (value) => {
        let {onLogin} = this.props;
        let {marketingParams} = this.props.context;

        this.setState({submitting: true});
        userService.validateLogin(value).then((data) => {
            authTokenManager.storeAuthData(data);

            return data;
        }).then((data) => {
            if (ValueIsSet(marketingParams))
                marketingService.trackLogin(data.userId,
                    marketingParams.utm_campaign,
                    marketingParams.utm_content,
                    marketingParams.utm_medium,
                    marketingParams.utm_term,
                    marketingParams.utm_source)
                .catch(() => {
                    console.log("Error occurred while saving tracking data");
                });

            onLogin();
            this.setState({submitting: false});
        }).catch(() => {
            this.setState({errorMessage: 'Invalid username / password combination', submitting: false})
        })
    };
    
    render() {
        let {onSubmit} = this;
        let {errorMessage, submitting} = this.state;
        let {} = this.props;

        return (
            <LoginPageView
                errorMessage={errorMessage}
                submitting={submitting}
                onSubmit={onSubmit}/>
        )
    }
}

LoginPage.propTypes = {
    onLogin: PropTypes.func.isRequired
};

export default WithContext(LoginPage);