import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {AlignItems} from "../../../../enums/CSSEnums";
import Button, {ButtonVariant} from "../../../../components/Button/Button";
import {convertMilitaryStringToAMPMString} from "../../../../helpers/TimeHelpers"
import Grid from "../../../../components/Grid/Grid";
import Icon from "../../../../components/Icon/Icon";
import {IconType} from "../../../../enums/MaterialEnums";
import Text, {TextDisplay, TextVariant} from "../../../../components/Text/Text";

const LocationHoursView = ({locationHoursFormFields, readOnly, toggleEdit}) => {
    const openHours = locationHoursFormFields.sort((a,b) => a.dayOfWeekInteger - b.dayOfWeekInteger).map(hour => (
        <Grid container xs={12} key={hour.day}>
            <Grid item xs={2} md={1}>
                <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>
                    {hour.day.substr(0,3)}
                </Text>
            </Grid>
            {hour.closed === true || (hour.openTime === null && hour.closeTime === null) ?
                <Grid container item xs={8} md={3}>
                    <Grid item xs={4}>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>
                            Closed
                        </Text>
                    </Grid>
                </Grid>
            :
                <Grid container item xs={8} md={3}>
                    <Grid item xs={4}>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>
                            {convertMilitaryStringToAMPMString(hour.openTime)}
                        </Text>
                    </Grid>
                    <Grid item xs={1}>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>
                            -
                        </Text>
                    </Grid>
                    <Grid item xs={4}>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>
                            {convertMilitaryStringToAMPMString(hour.closeTime)}
                        </Text>
                    </Grid>
                </Grid>
            }

        </Grid>
    ))
    return (
        <Grid container alignItems={AlignItems.FLEX_START}>
            <Grid container item xs={12} alignItems={AlignItems.FLEX_START}>
                {openHours}
            </Grid>
            {
                !readOnly ?
                    <Grid container item xs={8} margin={'32px 0 0 0'}>
                        <Grid item xs={2}>
                            <Button fullWidth variant={ButtonVariant.OUTLINED} startIcon={<Icon name={IconType.EDIT} />} onClick={toggleEdit}>EDIT</Button>
                        </Grid>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
};

export default LocationHoursView;

LocationHoursView.propTypes = {
    readOnly: PropTypes.bool,
    toggleEdit: PropTypes.func,
    locationHoursFormFields: PropTypes.arrayOf(PropTypes.shape({
        closeTime: PropTypes.string,
        day: PropTypes.string,
        dayOfWeekInteger: PropTypes.number,
        openTime: PropTypes.string,
        openHours: PropTypes.bool,
        closed: PropTypes.bool,
        id: PropTypes.number.isRequired,
    }))
}
