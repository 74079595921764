import React from 'react';
import PropTypes from 'prop-types';

import ActionableModalTemplate from 'worksmith/components/Modal/Templates/ActionableModalTemplate';
import Button, {ButtonVariant} from 'worksmith/components/Button/Button';
import Modal from 'worksmith/components/Modal/Modal';
import Grid from "worksmith/components/Grid/Grid";
import Select from "worksmith/components/Inputs/Select/Select";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import TextField from "worksmith/components/Inputs/TextField/TextField";
import {Debounce} from "worksmith/helpers/GenericHelpers";
import {ValueIsSet} from "../../../shared/helpers/Helpers";
import Loader from "worksmith/components/Loader/Loader";


const CancelRequestView = ({
           cancelRequestModalOpen,
           cancellationReason,
           cancellationReasonOptions,
           cancellationReasonSubOptions,
           closeCancelRequestModal,
           handleSubmit,
           isSubmitting,
           loading,
           onCancellationReasonChange,
           onCancellationSubOptionChange,
           onCustomCancellationReasonChange,
           requestRevision,
           requireCustomReason,
           showRequestRevision,
           showCustomReasonError,
           showOptionError,
           showSubOptionError,
           showSubOptions}) => {


    return (
        <Modal onClose={closeCancelRequestModal} open={cancelRequestModalOpen}>
            <ActionableModalTemplate
                title={'Cancel Request?'}
                subtitle={

                    <Text color={TextColor.ERROR}>Hold on - has work been completed for this request? If so, please do not cancel and wait for the vendor to close out the work that was complete.</Text>
                }
                width={'700px'}
                body={
                    <>
                        {!loading ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} margin={'1em 0 0 0'}>
                                    <Select
                                        showRequiredAsterisk
                                        error={showOptionError}
                                        includeNullOption
                                        fullWidth
                                        label={'Reason'}
                                        options={cancellationReasonOptions}
                                        onChange={onCancellationReasonChange}/>
                                </Grid>
                                {
                                    requireCustomReason ?
                                        <Grid item xs={12}>
                                            <TextField
                                                showRequiredAsterisk
                                                error={showCustomReasonError}
                                                fullWidth
                                                label={'Description'}
                                                multiline
                                                onChange={Debounce(onCustomCancellationReasonChange, 50)}/>
                                        </Grid>
                                        :
                                        null
                                }
                                {
                                    showSubOptions && ValueIsSet(cancellationReason) ?
                                        <Grid item xs={12}>
                                            <Select
                                                showRequiredAsterisk
                                                error={showSubOptionError}
                                                includeNullOption
                                                fullWidth
                                                label={'Description'}
                                                options={cancellationReasonSubOptions[cancellationReason.cancellationReasonId]}
                                                onChange={onCancellationSubOptionChange}/>
                                        </Grid>
                                        :
                                        null
                                }
                                {
                                    showRequestRevision ?
                                        <Grid item xs={12}>
                                            <Text
                                                variant={TextVariant.BODY_1}
                                                color={TextColor.SECONDARY}>
                                                Instead of canceling and closing the request would you like to ask the vendor to revise the price?
                                            </Text>
                                        </Grid>
                                        :
                                        null
                                }
                            </Grid>
                            :
                            <Loader/>
                        }
                    </>

                }
                buttons={
                    showRequestRevision ?
                        [<Button variant={ButtonVariant.CONTAINED} secondary disabled={isSubmitting} onClick={handleSubmit}>Cancel Request</Button>,
                        <Button variant={ButtonVariant.CONTAINED} primary isSubmitting={isSubmitting} onClick={requestRevision}>Request Revision</Button>]
                        :
                        [<Button variant={ButtonVariant.OUTLINED} disabled={isSubmitting} onClick={closeCancelRequestModal}>Back</Button>,
                        <Button variant={ButtonVariant.CONTAINED} primary isSubmitting={isSubmitting} onClick={handleSubmit}>Cancel Request</Button>]
                }
            />
        </Modal>
    );
};

CancelRequestView.propTypes = {
    requestId: PropTypes.number,
    openCancelRequestModal: PropTypes.func,
};

export default CancelRequestView;
