import React from 'react';
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';

import {AlignItems, Display, FlexDirection, JustifyContent} from "../../enums/CSSEnums";
import Skeleton, {SkeletonVariant} from "../../components/Skeleton/Skeleton";
import {withTheme} from "../../components/Theme/ThemeProvider";

const ConversationHeaderSkeleton = () => {
    const theme = useTheme();
    const onMobile =  useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            width={'100%'}
            display={Display.FLEX}
            justifyContent={JustifyContent.SPACE_BETWEEN}
            mb={1}>
            <Box>
                <Skeleton variant={SkeletonVariant.TEXT} width={onMobile ? '8rem' : '13rem'}/>
                <Skeleton variant={SkeletonVariant.TEXT} width={onMobile ? '4rem' : '9rem'}/>
            </Box>
            <Box
                display={Display.FLEX}
                flexDirection={FlexDirection.COLUMN}
                justifyContent={JustifyContent.CENTER}
                alignItems={AlignItems.FLEX_END}>
                <Skeleton variant={SkeletonVariant.TEXT} width={onMobile ? '5rem' : '6rem'}/>
            </Box>
        </Box>
    )
};

export default withTheme(ConversationHeaderSkeleton);