import React from 'react'
import PropTypes from 'prop-types';

import CollapseUserLocations from "./CollapseUserLocations";
import Grid from "worksmith/components/Grid/Grid";
import Table from "worksmith/components/Table/Table";
import Text from "worksmith/components/Text/Text";



const UsersPageList = ({
    currentPage,
    handleChangePage,
    loading,
    totalDataLength,
    users,
    usersPerPage
}) => {


    let columns = [
        {
            title: 'First Name',
            cellValueFunction: (data) => {
                return (
                    <Grid container routerLink={`/users/${data.id}`}>
                        <Grid item xs={12}>
                            <Text>
                                {data.firstName}
                            </Text>
                        </Grid>
                    </Grid>
                )
            }
        },
        {
            title: "Last Name",
            cellValueFunction: (data) => {
                return (
                    <Grid container routerLink={`/users/${data.id}`}>
                        <Grid item xs={12}>
                            <Text>{data.lastName}</Text>
                        </Grid>
                    </Grid>

                )
            },
        },
        {
            title: 'Email',
            cellValueFunction: (data) => {
                return (
                    <Grid container routerLink={`/users/${data.id}`}>
                        <Grid item>
                            <Text>
                                {data.email}
                            </Text>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            title: 'Location(s)',
            cellValueFunction: (data) => {
                return (
                    <CollapseUserLocations data={data} />
                )
            },
        }
    ];


    return (
        <Grid container xs={12}>
            <Table
                columns={columns}
                data={users}
                handleChangePage={handleChangePage}
                isPaginated={true}
                loading={loading}
                page={currentPage}
                rowsPerPage={usersPerPage}
                rowsPerPageOptions={[usersPerPage]}
                showTopTablePagination
                totalDataLength={totalDataLength}
            />
        </Grid>
    )
};

export default UsersPageList;

UsersPageList.prototype = {
    currentPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    loading: PropTypes.bool,
    totalDataLength: PropTypes.number,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            clientLocation: PropTypes.arrayOf(
                PropTypes.shape({
                    clientLocation: PropTypes.arrayOf(
                        PropTypes.shape({
                        id: PropTypes.number,
                        title: PropTypes.string,
                        state: PropTypes.string,
                        city: PropTypes.string
                    })),
                    id: PropTypes.number,
                    role: PropTypes.string
                })
            ),
            clientRoles: PropTypes.array,
            email: PropTypes.string,
            firstName: PropTypes.string,
            id: PropTypes.number,
            lastName: PropTypes.string,
            username: PropTypes.string
        })),
    usersPerPage: PropTypes.number,
};