import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import Menu from "antd/lib/menu";
import Popconfirm from "antd/lib/popconfirm";
import Modal from "react-modal";
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";

import WSButton from "../Button";
import ChangeRequestCounterForm from "../forms/change-requests/ChangeRequestCounterForm";
import Loader from "../Loader";
import Schedule from "worksmith/components/Schedule/Schedule.web";
import {formatTimestamp, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Grid from "worksmith/components/Grid/Grid.web";
import ClientRoutes from "../../ClientRoutes";
import {Color} from "worksmith/enums/Color"
import {TextDecoration} from "worksmith/enums/CSSEnums";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web"
import ChangeRequestStatus from "worksmith/enums/api/cr/ChangeRequestStatus";
import UserType from "../../shared/enums/api/user/UserType";
import {isExpired} from "worksmith/helpers/ScheduleHelper";
import CreateScheduleOptionsDialog
    from "worksmith/composite-components/ScheduleOptionsForm/CreateScheduleOptionsDialog";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";

const TextLink = styled(Link)`
    color: ${Color.BLACK};
    text-decoration: ${TextDecoration.UNDERLINE};
`;

const Th = styled.th`
    color: ${Color.TEXT_SECONDARY} !important;
    font-weight: normal !important; 
`;


const ScheduleChangesView = (props) => {
    let {
       acceptChangeRequest,
       cancelChangeRequest,
       changeRequestCounterModalVisible,
       counterChangeRequestModal,
       counteringChangeRequest,
       hideCounterModal,
       loading,
       needsClientChangeRequests,
       needsVendorChangeRequests,
       counterChangeRequest,
       setShowScheduleOptionsDialog
    } = props;

    const disabled = (changeRequest) => {
       return changeRequest.status !== ChangeRequestStatus.NEEDS_CLIENT_INPUT
           && changeRequest.creatorType !== UserType.CLIENT;
    };

    const dropdownMenu = (changeRequest) => {
        let needsClientInput = changeRequest.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT;
        let createdByClient = changeRequest.creatorType === UserType.CLIENT;
        let allSchedulesExpired = changeRequest.requestedSchedules.every(isExpired);
        return (
            <Menu>
                {needsClientInput && !allSchedulesExpired ?
                    <Menu.Item key="1">
                        <Popconfirm placement="top" title={'Are you sure you want to accept this change?'}
                                    onConfirm={() => acceptChangeRequest(changeRequest.id)} okText="Yes" cancelText="No">
                            <Text>Accept Change</Text>
                        </Popconfirm>
                    </Menu.Item> : null}
                {needsClientInput ?
                    <Menu.Item key="2">
                        <span onClick={() => counterChangeRequestModal(changeRequest)}>
                            <Text>Provide Alternate Schedule</Text>
                        </span>
                    </Menu.Item> : null}
                {!needsClientInput && allSchedulesExpired ?
                    <Menu.Item key="3">
                        <span onClick={() => counterChangeRequestModal(changeRequest)}>
                            <Text>Provide Alternate Schedule</Text>
                        </span>
                    </Menu.Item> : null}
                {createdByClient ?
                    <Menu.Item key="4">
                        <Popconfirm placement="top" title={'Are you sure you want to cancel?'}
                                    onConfirm={() => cancelChangeRequest(changeRequest.id)} okText="Yes" cancelText="No">
                            <Text>Cancel Change Request</Text>
                        </Popconfirm>
                    </Menu.Item> : null}
            </Menu>
        )
    };

    if (!loading) {
        return (
            <Fragment>
                {changeRequestCounterModalVisible ?
                <CreateScheduleOptionsDialog
                    title={counteringChangeRequest.isRecurring ? "Propose New Recurring Schedule" : "Propose New Visit Time"}
                    open={changeRequestCounterModalVisible}
                    onSubmit={(schedules) => {
                        counterChangeRequest(schedules);
                    }}
                    adjustableFrequency={counteringChangeRequest.isRecurring}
                    clientLocation={counteringChangeRequest.clientLocation}
                    obligation={counteringChangeRequest.obligation}
                    obligationChangeRequest={counteringChangeRequest}
                    obligationTemplate={counteringChangeRequest.obligationTemplate}
                    templateChangeRequest={counteringChangeRequest}
                    duration={counteringChangeRequest.isRecurring ?
                        counteringChangeRequest.obligationTemplate.recurringScheduleTemplate.duration :
                        counteringChangeRequest.currentSchedule.duration}
                    frequency={counteringChangeRequest.isRecurring ?
                        counteringChangeRequest.obligationTemplate.recurringScheduleTemplate.frequency :
                        null}
                    onCancel={() => setShowScheduleOptionsDialog(false)}
                    numberOfSchedules={1}
                    serviceLine={counteringChangeRequest.serviceLine}
                    showCurrentSchedule
                    scheduleOptionType={counteringChangeRequest.isRecurring ? ScheduleOptionType.RECURRING_SERVICE : ScheduleOptionType.RECURRING_VISIT}
                    userType={UserType.CLIENT}
                    vendor={counteringChangeRequest.vendorLocation.vendor.nickname}
                /> : null}

            <section className={'ws-section'}>
                <p className="subheader">Schedule Changes <small className={'hidden-md-up'}><em>(scroll right to see more)</em></small></p>

                <Text semiBold variant={TextVariant.H6}>Needs Your Input</Text>
                <div className="table-responsive">
                    {needsClientChangeRequests.length ?
                        <table className="table ws-table">
                            <thead>
                                <tr>
                                    <Th style={{width: '20%'}}>Request</Th>
                                    <Th style={{width: '15%'}}>Vendor</Th>
                                    <Th style={{width: '15%'}}>Location</Th>
                                    <Th>Current Schedule</Th>
                                    <Th>Requested Schedule</Th>
                                    <Th style={{width: '15%'}}>Action(s)</Th>
                                </tr>
                            </thead>
                            <tbody>
                            {needsClientChangeRequests.map(function (changeRequest, idx) {
                                const tooltip = "Submitted by " + changeRequest.createdBy.displayName + " on " + formatTimestamp(changeRequest.createdTimestamp);

                                return (
                                    <tr key={idx}>
                                        <td style={{width: '20%'}}>
                                            {
                                                ValueIsSet(changeRequest.request) ?
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Text>
                                                                <TextLink style={{color: Color.BLACK}}
                                                                    to={ValueIsSet(changeRequest.obligationTemplate) && ValueIsSet(changeRequest.obligationTemplate.id )?
                                                                        ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(changeRequest.serviceLine.id, changeRequest.clientLocation.id, false, changeRequest.obligationTemplate.id)
                                                                        :
                                                                        ValueIsSet(changeRequest.obligation) && ValueIsSet(changeRequest.obligation.obligationTemplate) && ValueIsSet(changeRequest.request.typeName === "Recurring")?
                                                                            ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(changeRequest.serviceLine.id, changeRequest.clientLocation.id, false, changeRequest.obligation.obligationTemplate.id)
                                                                            :
                                                                            ClientRoutes.SINGLE_REQUESTS(changeRequest.request.id)
                                                                    }
                                                                >
                                                                    #{changeRequest.request.id}
                                                                </TextLink>
                                                                - {changeRequest.request.typeName} {changeRequest.serviceLine.name}
                                                            </Text>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Text color={TextColor.TEXT_SECONDARY}>{
                                                                ValueIsSet(changeRequest.request.description) && changeRequest.request.description.length > 150 ?
                                                                    changeRequest.request.description.substr(0,149) + "..."
                                                                    :
                                                                    changeRequest.request.description
                                                            }</Text>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    null
                                            }
                                        </td>
                                        <td style={{width: '15%'}}><Text>{changeRequest.vendorLocation.vendor.nickname}</Text></td>
                                        <td style={{width: '15%'}}><Text>{changeRequest.clientLocation.title}</Text></td>
                                        <td>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Text bold>{changeRequest.obligationType}</Text>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Schedule schedule={changeRequest.currentSchedule}/>
                                                </Grid>
                                            </Grid>
                                        </td>
                                        <td>
                                            <Tooltip placement={TooltipPlacement.BOTTOM} title={ValueIsSet(tooltip) ? tooltip : null}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Text bold>{changeRequest.obligationType}</Text>
                                                </Grid>

                                                { changeRequest.requestedSchedules.map((schedule, index) => {
                                                    return (
                                                            <Grid key={index} item xs={12}>
                                                                <Schedule schedule={schedule}/>
                                                            </Grid>
                                                        )
                                                })}
                                            </Grid>
                                            </Tooltip>
                                        </td>
                                        <td style={{width: '15%'}}>
                                            <Dropdown overlay={dropdownMenu(changeRequest)}>
                                                <Button style={{marginLeft: 8}}>
                                                    Action <Icon type="down"/>
                                                </Button>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> :
                        <p>You have no schedule changes that need your input at this time.</p>}
                </div>

                <Text semiBold variant={TextVariant.H6}>Needs Vendor Input</Text>
                <div className="table-responsive">
                    {needsVendorChangeRequests.length ?
                        <table className="table ws-table">
                            <thead>
                            <tr>
                                <Th style={{width: '20%'}}>Request</Th>
                                <Th style={{width: '15%'}}>Vendor</Th>
                                <Th style={{width: '15%'}}>Location</Th>
                                <Th>Current Schedule</Th>
                                <Th>Requested Schedule</Th>
                                <Th style={{width: '15%'}}>Action(s)</Th>
                            </tr>
                            </thead>
                            <tbody>
                            {needsVendorChangeRequests.map(function (changeRequest, idx) {
                                return (
                                    <tr key={idx}>
                                        <td style={{width: '20%'}}>
                                            {
                                                ValueIsSet(changeRequest.request) ?
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Text>
                                                                <TextLink style={{color: Color.BLACK}}
                                                                          to={ValueIsSet(changeRequest.obligationTemplate) && ValueIsSet(changeRequest.obligationTemplate.id )?
                                                                              ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(changeRequest.serviceLine.id, changeRequest.clientLocation.id, false, changeRequest.obligationTemplate.id)
                                                                              :
                                                                              ValueIsSet(changeRequest.obligation) && ValueIsSet(changeRequest.obligation.obligationTemplate) && ValueIsSet(changeRequest.request.typeName === "Recurring")?
                                                                                  ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(changeRequest.serviceLine.id, changeRequest.clientLocation.id, false, changeRequest.obligation.obligationTemplate.id)
                                                                                  :
                                                                                  ClientRoutes.SINGLE_REQUESTS(changeRequest.request.id)
                                                                          }
                                                                >
                                                                    #{changeRequest.request.id}
                                                                </TextLink>
                                                                - {changeRequest.request.typeName} {changeRequest.serviceLine.name}
                                                            </Text>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Text color={TextColor.TEXT_SECONDARY}>{
                                                                ValueIsSet(changeRequest.request.description) && changeRequest.request.description.length > 150 ?
                                                                    changeRequest.request.description.substr(0,149) + "..."
                                                                    :
                                                                    changeRequest.request.description
                                                            }</Text>
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    null
                                            }
                                        </td>
                                        <td style={{width: '15%'}}><Text>{changeRequest.vendorLocation.vendor.nickname}</Text></td>
                                        <td style={{width: '15%'}}><Text>{changeRequest.clientLocation.title}</Text></td>
                                        <td>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Text bold>{changeRequest.obligationType}</Text>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Schedule schedule={changeRequest.currentSchedule}/>
                                                </Grid>
                                            </Grid>
                                        </td>
                                        <td>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Text bold>{changeRequest.obligationType}</Text>
                                                </Grid>

                                                { changeRequest.requestedSchedules.map((schedule, index) => {
                                                    return (
                                                        <Grid key={index} item xs={12}>
                                                            <Schedule schedule={schedule}/>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </td>
                                        <td style={{width: '15%'}}>
                                            <Dropdown overlay={dropdownMenu(changeRequest)} disabled={disabled(changeRequest)}>
                                                <Button style={{marginLeft: 8}} disabled={disabled(changeRequest)}>
                                                    Action <Icon type="down"/>
                                                </Button>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> :
                        <p>You have no schedule changes that need vendor input at this time.</p>}
                </div>
            </section>
            </Fragment>
        )
    } else {
        return (
            <section className="ws-section">
                <Loader/>
            </section>
        )
    }
};

ScheduleChangesView.propTypes = {
    acceptChangeRequest: PropTypes.func.isRequired,
    cancelChangeRequest: PropTypes.func.isRequired,
    changeRequestCounterModalVisible: PropTypes.bool.isRequired,
    counterChangeRequestModal: PropTypes.func.isRequired,
    counteringChangeRequest: PropTypes.object,
    hideCounterModal: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    needsVendorChangeRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
    needsClientChangeRequests: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ScheduleChangesView;