import Text, {TextColor, TextVariant} from "../../components/Text/Text.web";
import Grid from "../../components/Grid/Grid.web";
import React from "react";
import {FrequencyString} from "../../helpers/LanguageHelper";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import PropTypes from 'prop-types';
import RecurrenceType from "../../enums/api/task/RecurrenceType";

const RequestedAndSuggestedFrequency = (props) => {
    let{
        requestedFrequency,
        suggestedFrequency
    } = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Text
                    variant={TextVariant.BODY_1}
                    color={TextColor.TEXT_PRIMARY}>
                    Requested - {FrequencyString(requestedFrequency)}
                </Text>
            </Grid>
            <Grid item xs={12}>
                <Text
                    variant={TextVariant.BODY_1}
                    color={TextColor.SECONDARY}>
                    Vendor proposed - {FrequencyString(suggestedFrequency)}
                </Text>
            </Grid>
        </Grid>
    )
};

RequestedAndSuggestedFrequency.propTypes = {
    requestedFrequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    suggestedFrequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    })
};

export default RequestedAndSuggestedFrequency;