export const GraphQLObjectType = Object.freeze({
    USER: {
        queryName: 'user',
        searchParamName: 'UserSearchParams'
    },
    BOOK_OF_BUSINESS: {
        queryName: 'bookOfBusiness',
        searchParamName: 'BookOfBusinessSearchParams'
    },
    BUSINESS_UNIT_OPTIONS: {
        queryName: 'businessUnitOptions',
        searchParamName: 'BusinessUnitOptionsSearchParams'
    },
    CLIENT: {
        queryName: 'client',
        searchParamName: 'ClientSearchParams'
    },
    CLIENT_LOCATION: {
        queryName: 'clientLocation',
        searchParamName: 'ClientLocationSearchParams'
    },
    CLIENT_LOCATION_WARRANTY: {
        queryName: 'clientLocationWarranty',
        searchParamName: 'ClientLocationWarrantySearchParams'
    },
    CHANGE_REQUEST: {
        queryName: 'changeRequest',
        searchParamName: 'ChangeRequestSearchParams'
    },
    CONVERSATION: {
        queryName: 'conversation',
        searchParamName: 'ConversationSearchParams'
    },
    REQUEST: {
        queryName: 'request',
        searchParamName: 'RequestSearchParams'
    },
    REQUEST_COMPLETION_TRACKER: {
        queryName: 'requestCompletionTracker',
        searchParamName: 'RequestSearchParams'
    },
    RFP_NOTE: {
        queryName: 'rfpNote',
        searchParamName: 'RfpNoteSearchParams'
    },
    VENDOR_OPPORTUNITY_NOTE: {
        queryName: 'vendorOpportunityNote',
        searchParamName: 'VendorOpportunityNoteSearchParams'
    },
    RFP_CAMPAIGN: {
        queryName: 'rfpCampaign',
        searchParamName: 'RfpCampaignSearchParams'
    },
    BID: {
        queryName: 'bid',
        searchParamName: 'BidSearchParams'
    },
    WALKTHROUGH: {
        queryName: 'walkthrough',
        searchParamName: 'WalkthroughSearchParams'
    },
    REQUEST_COMMENT: {
        queryName: 'requestComment',
        searchParamName: 'RequestCommentSearchParams'
    },
    REQUEST_STATISTICS: {
        queryName: 'requestStatistics',
        searchParamName: 'RequestSearchParams'
    },
    OPPORTUNITY_STATISTICS: {
        queryName: 'opportunityStatistics',
        searchParamName: 'OpportunitySearchParams'
    },
    MALL: {
        queryName: 'mall',
        searchParamName: 'MallSearchParams'
    },
    ASSIGNMENT: {
        queryName: 'assignment',
        searchParamName: 'AssignmentSearchParams'
    },
    OPPORTUNITY: {
        queryName: 'opportunity',
        searchParamName: 'OpportunitySearchParams'
    },
    VENDOR_LOCATION: {
        queryName: 'vendorLocation',
        searchParamName: 'VendorLocationSearchParams'
    },
    ZIP_CODE: {
        queryName: 'zipCode',
        searchParamName: 'ZipCodeSearchParams'
    },
    VENDOR_LOCATION_ZIP_CODE : {
        queryName: 'vendorLocationZipCode',
        searchParamName: 'VendorLocationZipCodeSearchParams'
    },
    OBLIGATION: {
        queryName: 'obligation',
        searchParamName: 'ObligationSearchParams'
    },
    OBLIGATION_TEMPLATE: {
        queryName: 'obligationTemplate',
        searchParamName: 'ObligationTemplateSearchParams'
    },
    OBLIGATION_TEMPLATE_COUNTS_BY_SERVICE_LINE: {
        queryName: 'obligationTemplateCountsByServiceLine',
        searchParamName: 'ObligationTemplateSearchParams'
    },
    OBLIGATION_ITEM_GROUP: {
        queryName: 'obligationItemGroup',
        searchParamName: 'ObligationItemGroupSearchParams'
    },
    REQUEST_COUNTS_BY_SERVICE_LINE: {
        queryName: 'requestCountsByServiceLine',
        searchParamName: 'RequestSearchParams'
    },
    FAST_OBLIGATION_TEMPLATE_INFO: {
        queryName: 'fastObligationTemplateInfo',
        searchParamName: 'ObligationTemplateSearchParams'
    },
    BILLING_PROFILE: {
        queryName: 'billingProfile',
        searchParamName: 'BillingProfileSearchParams',
        argumentsName: 'BillingProfileArguments'
    },
    INVOICE_ACTIVITY: {
        queryName: 'invoiceActivity',
        searchParamName: 'InvoiceActivitySearchParams',
        argumentsName: 'InvoiceActivityArguments'
    },
    VENDOR_SERVICE_LINE_CONFIGURATION_SETTING: {
        queryName: 'vendorServiceLineConfigurationSetting',
        searchParamName: 'VendorServiceLineConfigurationSettingSearchParams'
    },
    CLIENT_CONTRACT: {
        queryName: 'clientContract',
        searchParamName: 'ClientContractSearchParams'
    },
    CLIENT_CONTRACT_ITEM: {
        queryName: 'clientContractItem',
        searchParamName: 'ClientContractItemSearchParams'
    },
    CLIENT_LOCATION_CONTRACT: {
        queryName: 'clientLocationContract',
        searchParamName: 'ClientLocationContractSearchParams'
    },
    CLIENT_LOCATION_CONTRACT_ITEM: {
        queryName: 'clientLocationContractItem',
        searchParamName: 'ClientLocationContractItemSearchParams'
    },
    VENDOR_LOCATION_CONTRACT_ITEM: {
        queryName: 'vendorLocationContractItem',
        searchParamName: 'VendorLocationContractItemSearchParams'
    },
    SERVICE_LINE: {
        queryName: 'serviceLine',
        searchParamName: 'ServiceLineSearchParams'
    },
    CONTRACT_ITEM: {
        queryName: 'contractItem',
        searchParamName: 'ContractItemSearchParams'
    },
    SERVICE_LEVEL_AGREEMENT: {
        queryName: 'serviceLevelAgreement',
        searchParamName: 'ServiceLevelAgreementSearchParams'
    },
    SERVICE_LEVEL_AGREEMENT_CONFIG: {
        queryName: "serviceLevelAgreementConfig",
        searchParamName: "ServiceLevelAgreementConfigSearchParams"
    },
    SERVICE_LEVEL_AGREEMENT_ITEM: {
        queryName: "serviceLevelAgreementItem",
        searchParamName: "ServiceLevelAgreementItemSearchParams"
    },
    STANDARD_OPERATING_PROCEDURE: {
        queryName: "standardOperatingProcedure",
        searchParamName: "StandardOperatingProcedureSearchParams"
    },
    VENDOR_BIO: {
        queryName: 'vendorBio',
        searchParamName: 'VendorBioSearchParams'
    },
    VENDOR_STATS: {
        queryName: 'vendorStats',
        searchParamName: 'VendorStatsSearchParams'
    },
    TEAM_STATS: {
        queryName: 'teamStats',
        searchParamName: 'TeamStatsSearchParams'
    },
    VENDOR_TIMESHEET: {
        queryName: 'vendorTimesheet',
        searchParamName: 'VendorTimesheetSearchParams'
    },
    BILL_DOT_COM_VENDOR: {
        queryName: 'billDotComVendor',
        searchParamName: null //Currently we only grab one of these at a time by vendor ID, so there is no need for search params
    },
    BILL_DOT_COM_BILL: {
        queryName: 'billDotComBill',
        searchParamName: 'BillDotComBillSearchParams'
    },
    ITEMIZED_DETAILS_INVOICE: {
        queryName: 'itemizedDetailsInvoiceObject',
        searchParamName: 'itemizedDetailsInvoiceSearchParams'
    },
    BILL_DOT_COM_VENDOR_CREDIT: {
        queryName: 'billDotComVendorCredit',
        searchParamName: 'BillDotComVendorCreditSearchParams'
    },
    PAYMENT_PROFILE: {
        queryName: 'paymentProfile',
        searchParamName: 'PaymentProfileSearchParams'
    },
    CHANGE_ORDER_REQUEST: {
        queryName: 'changeOrderRequest',
        searchParamName: 'ChangeOrderRequestSearchParams'
    },
    CANCELLATION_REASON: {
        queryName: "cancellationReason",
        searchParamName: "CancellationReasonSearchParams"
    },
    RECEIVABLE: {
        queryName: 'receivable',
        searchParamName: 'ReceivableSearchParams'
    },
    STATEMENT_ITEM: {
        queryName: 'statementItem',
        searchParamName: 'StatementItemSearchParams'
    },
    RATE_CARD: {
        queryName: 'rateCard',
        searchParamName: 'RateCardSearchParams'
    },
    RATE_CARD_ENTRY: {
        queryName: 'rateCardEntry',
        searchParamName: 'RateCardEntrySearchParams'
    },
    CURRENT_PERIOD: {
        queryName: 'currentPeriod'
    },
    CLIENT_CONTRACT_CONFIG: {
        queryName: 'clientContractConfig',
        searchParamName: 'ClientContractConfigSearchParams'
    },
    VENDOR: {
        queryName: 'vendor',
        searchParamName: 'VendorSearchParams'
    },
    VENDOR_ONSITE_LOG: {
        queryName: 'vendorOnsiteLog',
        searchParamName: 'VendorOnsiteLogSearchParams'
    },
    RFP_QUESTION: {
        queryName: 'rfpQuestion',
        searchParamName: 'RfpQuestionSearchParams'
    },
    EXPLO_DASHBOARD: {
        queryName: 'exploDashboard',
        searchParamName: 'ExploDashboardSearchParams'
    },
    OBLIGATION_SCHEDULE_OBJECT:{
        queryName: 'obligationScheduleObject',
        searchParamName: 'obligationScheduleObjectSearchParams'
    },
    AGGREGATOR_RESOURCE: {
        queryName: 'aggregatorResource',
        searchParamName: 'AggregatorResourceSearchParams'
    },
    AGGREGATOR_RESOURCE_PAGE_SORT_BY_DISTANCE: {
        queryName: 'aggregatorResourceSortByDistance',
        searchParamName: 'AggregatorResourceSearchParams',
        argumentsName: 'AggregatorResourceSortByDistancePageArguments'
    },
    AGGREGATOR_RESOURCE_CHAT: {
        queryName: 'aggregatorResourceChat',
        searchParamName: 'AggregatorResourceChatSearchParams'
    },
    AGGREGATOR_RESOURCE_CHAT_MESSAGE: {
        queryName: 'aggregatorResourceChatMessage',
        searchParamName: 'AggregatorResourceChatMessageSearchParams'
    },
    RESOURCE_PAYMENT_INFO: {
        queryName: 'resourcePaymentInfo',
        searchParamName: 'ResourcePaymentInfoSearchParams'
    },
    RESOURCE_ASSIGNMENT: {
        queryName: 'resourceAssignment',
        searchParamName: 'ResourceAssignmentSearchParams'
    },
    VENDOR_DETAILS_OBJECT:{
        queryName: 'vendorDetailsObject',
        searchParamName:'vendorDetailsObjectSearchParams'
    },
    HOURS_WORKED:{
        queryName: 'hoursWorked',
        searchParamName:'HoursWorkedSearchParams'
    },
    ORDER_MANIFEST_OBJECT:{
        queryName: 'orderManifest',
        searchParamName:'ObligationSearchParams'
    },
    PAYABLE:{
        queryName: 'payable',
        searchParamName: 'PayableSearchParams'
    }
});
