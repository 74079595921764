import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ChangeOrderRequest from "worksmith/components/ChangeOrderRequest/ChangeOrderRequest";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

class ChangeOrderRequestModalView extends Component {
    constructor(props){
        super(props);

        this.viewRef = React.createRef();
    }

    onSubmit = () => {
        this.viewRef.current.onSubmit();
    };

    onValidate = () => {
        return this.viewRef.current.onValidate();
    };

    render() {
        const {viewRef} = this;
        const {
            disableAccept,
            enableAccept,
            obligationId,
            onAccept,
            acceptDisabled,
            isEdit,
            overrideClientApproval,
            onCancel,
            onClose,
            open,
            reloadPage,
            showSuccessErrorModal,
            portalType
        } = this.props;

        let acceptText = overrideClientApproval ? "Update Estimate" : isEdit ? "Update Change Order" : "Open Change Order";

        return (
            <AlertDialog acceptText={acceptText}
                         cancelText={"Cancel"}
                         disablePortal
                         open={open}
                         keepMounted
                         onAccept={onAccept}
                         acceptDisabled={acceptDisabled}
                         onCancel={onCancel}
                         onClose={onClose}>
                <ChangeOrderRequest obligationId={obligationId}
                                    enableAccept={enableAccept}
                                    disableAccept={disableAccept}
                                    isEdit={isEdit}
                                    overrideClientApproval={overrideClientApproval}
                                    ref={viewRef}
                                    reloadPage={reloadPage}
                                    showSuccessErrorModal={showSuccessErrorModal}
                                    portalType={portalType}
                />
            </AlertDialog>
        )
    }
}

ChangeOrderRequestModalView.propTypes = {
    acceptDisabled:          PropTypes.bool,
    disableAccept:           PropTypes.func,
    enableAccept:            PropTypes.func,
    isEdit:                  PropTypes.bool,
    overrideClientApproval: PropTypes.bool,
    obligationId:            CustomPropTypes.stringOrNumber.isRequired,
    onAccept:                PropTypes.func.isRequired,
    onCancel:                PropTypes.func.isRequired,
    onClose:                 PropTypes.func.isRequired,
    open:                    PropTypes.bool.isRequired,
    reloadPage:              PropTypes.func,
    portalType:              CustomPropTypes.enum(PortalType)
};

export default ChangeOrderRequestModalView;