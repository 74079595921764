import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ObligationService from "../../services/api/ObligationService";
import {DialogSize} from "worksmith/enums/DialogSize";
import AlertDialog from "../../components/Dialog/AlertDialog";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";

const obligationService = new ObligationService();

class ClientSignOffModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

    }

    signOff = () => {
        const {onCompletion, obligationId} = this.props;


        obligationService.confirmJob(obligationId)
            .then(() => {
                if (onCompletion) {
                    onCompletion();
                }
                this.setState({loading: false});
                DisplaySuccessNotification("Sign Off Confirmed!");
            }, () => {
                if (onCompletion) {
                    onCompletion();
                }
                DisplayErrorNotification("Failed to Sign Off")});

    };

    setLoading = (loading) => {
        this.setState({loading: loading});
    };

    render() {
        let {signOff, setLoading} = this;
        const {loading} = this.state;
        const {onCancel, open} = this.props;

        return (
            <AlertDialog
                acceptDisabled={loading}
                acceptText={'Yes, Sign Off'}
                cancelText={'No, Go Back'}
                onCancel={onCancel}
                onAccept={signOff}
                onAcceptIgnoreDebounce={() => setLoading(true)}
                size={DialogSize.LARGE}
                title={'Are You Sure?'}
                open={open}>
                <Text variant={TextVariant.BODY_1}>
                    Are you sure you want to sign off on this job?
                </Text>
            </AlertDialog>
        )
    }
}

ClientSignOffModal.propTypes = {
    obligationId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onCompletion: PropTypes.func.isRequired,
    open: PropTypes.bool
};

export default ClientSignOffModal;