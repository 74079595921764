import React from "react";
import PropTypes from "prop-types";
import Lightbox from "yet-another-react-lightbox";
import 'yet-another-react-lightbox/dist/styles.css';
import {Zoom, Video, Inline} from "yet-another-react-lightbox/dist/plugins";

import {createLightBoxSlidesFromAttachments, renderLightBoxSlideThatAreNotImagesOrVideos} from "worksmith/components/Lightbox/LightBoxHelpers";
import Download from 'worksmith/components/Lightbox/Plugins/Download';


const LightBox = ({
                      attachments,
                      initialSlideIndex,
                      isOpen,
                      onCloseRequest,
                      inLine
}) => {

    const attachmentSlides = createLightBoxSlidesFromAttachments(attachments);

    let plugins = [Video, Zoom, Download];
    if(inLine)
        plugins.push(Inline)

    return (
        <Lightbox
            styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
            open={isOpen}
            close={onCloseRequest}
            index={initialSlideIndex}
            slides={attachmentSlides}
            plugins={plugins}
            render={renderLightBoxSlideThatAreNotImagesOrVideos()}
        />
    );
};

LightBox.propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.shape({
        fileName: PropTypes.string,
        singleUseUrl: PropTypes.string,
    })),
    isOpen: PropTypes.bool,
    initialSlideIndex: PropTypes.number,
    onCloseRequest: PropTypes.func,
};

export default LightBox;

