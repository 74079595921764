import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ListItemSecondaryAction, ListItemText} from "@mui/material";
import MaterialListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import {withTheme} from "../Theme/ThemeProvider";
import Avatar from "../Avatar/Avatar.web";

const ListItem = (props) => {
    let {
        avatar,
        button,
        component,
        dense,
        disabled,
        disableGutters,
        disableRipple,
        disableTouchRipple,
        divider,
        icon,
        onClick,
        primaryAction,
        primaryTextProps,
        secondaryAction,
        secondaryTextProps,
        selected,
        style,
        text,
        textSecondary,
        to
    } = props;

    return (
        <MaterialListItem
            button={button}
            component={to ? Link : component}
            dense={dense}
            disabled={disabled}
            disableGutters={disableGutters}
            disableRipple={disableRipple}
            disableTouchRipple={disableTouchRipple}
            divider={divider}
            onClick={onClick}
            selected={selected}
            style={style}
            to={to}
        >
            {
               ValueIsSet(icon) ?
                   <ListItemIcon>
                       {
                           React.cloneElement(
                               icon,
                               {edge: 'start'},
                               null
                           )
                       }
                   </ListItemIcon>
                   :
                   null
            }
            {
                ValueIsSet(avatar) ?
                    <ListItemAvatar>
                        <Avatar src={avatar.src} alt={avatar.alt}/>
                    </ListItemAvatar>
                    :
                    null
            }
            {
                ValueIsSet(primaryAction) ?
                    primaryAction
                    :
                    null
            }
            {
                ValueIsSet(text) ?
                    <ListItemText
                        primary={text}
                        secondary={textSecondary}
                        primaryTypographyProps={primaryTextProps}
                        secondaryTypographyProps={secondaryTextProps}/>
                    :
                    null
            }
            {
                ValueIsSet(secondaryAction) ?
                    <ListItemSecondaryAction>
                        {
                            React.cloneElement(
                                secondaryAction,
                                {edge: 'end'},
                                null
                            )
                        }
                        {}
                    </ListItemSecondaryAction>
                    :
                    null
            }
        </MaterialListItem>
    )
};

ListItem.propTypes = {
    avatar: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }),
    button: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    dense: PropTypes.bool,
    disabled: PropTypes.bool,
    disableGutters: PropTypes.bool,
    disableRipple: PropTypes.bool,
    disableTouchRipple: PropTypes.bool,
    divider: PropTypes.bool,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    primaryAction: PropTypes.node,
    primaryTextProps: PropTypes.object,
    secondaryAction: PropTypes.node,
    secondaryTextProps: PropTypes.object,
    selected: PropTypes.bool,
    style: PropTypes.object,
    text: PropTypes.string,
    to: PropTypes.string,
    textSecondary: PropTypes.string
};

export default withTheme(ListItem);
