import React from 'react';
import PropTypes from 'prop-types';
import MaterialCard from '@mui/material/Card';
import {withTheme} from '../Theme/ThemeProvider';

import {ThemeConstants} from "../Theme/ThemeProvider";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {JavascriptType} from "worksmith/enums/GenericEnums";

const Card = (props) => {
    let {
        outlined,
        padding,
        raised,
        square,
        width
    } = props;

    const customStyling = {
        padding: (ThemeConstants.spacingUnit * padding) + 'px'
    };

    if(ValueIsSet(width))
        customStyling.width = typeof width === JavascriptType.NUMBER ? width + 'px' : width;

    return (
        <MaterialCard raised={raised}
                      square={square}
                      className={props.className}
                      style={customStyling}
                      variant={outlined ? "outlined" : false}
        >
            {props.children}
        </MaterialCard>
    )
};

Card.propTypes = {
    elevation: PropTypes.number,            //Number between 1 and 24
    padding: PropTypes.number,              //Similar to spacing on Grid, value is multiplied by standard spacing unit to determine padding
    raised: PropTypes.bool,
    square: PropTypes.bool,                 //If true, rounded corners are disabled
    outlined: PropTypes.bool,               //Light gray border. No elevation and not raised
    width: CustomPropTypes.stringOrNumber   //A value of 5 would be the same as '5px'
};

Card.defaultProps = {
    elevation: 1,
    padding: 1,
    raised: false,
    square: false,
    width: 'max-content'
};

export default withTheme(Card);