// This file was generated from com.worksmith.conversation.controller.ConversationController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ConversationService extends Http {
    constructor() {
        super('/conversation')
    }
    
    createOneTimeAutomatedResponse(entityId, type, message) {
        let params = {
            entityId, 
            type
        };
    
        return this.callApi( `createOneTimeAutomatedResponse`, POST, message, params, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findById(id) {
        return this.callApi( `findById/${id}`, GET, null, null, null, true);
    }
    
    findConversationByOpportunity(opportunityId) {
        return this.callApi( `findConversationByOpportunity/${opportunityId}`, POST, null, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    markRead(id) {
        return this.callApi( `${id}/markRead`, POST, null, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }

    writeMessage(id, message, isScopeClarificationMessage, attachments) {
        let params = {
            isScopeClarificationMessage
        };
        if (attachments === null){
            attachments = [];
        }
        return this.callApi( `${id}/writeMessage`, POST, message, params, null, true, attachments);
    }
    
    
}

export default ConversationService;
