import React from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';

import {Color, DataVisualizationColor, ErrorColor, PrimaryColor, SecondaryColor} from "worksmith/enums/Color";

//Docs for creating a theme https://material-ui.com/customization/themes/

export const FontFamily = 'Open Sans';

export const ThemeConstants = Object.freeze({
    spacingUnit: 8
});

//see list of defaults here: https://material-ui.com/customization/default-theme/
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1025,
            lg: 1200,
            xl: 1920
        }
    },
    overrides: {
        MuiPrivateNotchedOutline: {
            legend: {
                borderBottom: 'none'
            }
        },
        // these override the MaterialDatePicker component
        MuiPickersDay: {
            current: {
                border: '1px solid #000'
            },
            daySelected: {
                backgroundColor: SecondaryColor.main
            }
        },
    },
    palette: {
        primary: PrimaryColor,
        secondary: SecondaryColor,
        error: ErrorColor
    },
    typography: {
        fontFamily: FontFamily,
        h6: {
            fontWeight: 600
        },
        subtitle2: {
            fontWeight: 600
        }
    },
});

export const withTheme = (Component, additionalStyles) => {
    return React.forwardRef((props, ref) => (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={{...theme, ...additionalStyles}}>
                <Component ref={ref} {...props} theme={theme}/>
            </ThemeProvider>
        </StyledEngineProvider>
    ));
};

//region Data Visualization Theme

const baseProps = {
    width: 350,
    height: 350,
    padding: 50
};

const baseLabelStyles = {
    fontFamily: FontFamily,
    fontSize: 12,
    letterSpacing: 'normal',
    padding: 8,
    fill: PrimaryColor.main,
    stroke: "transparent",
    strokeWidth: 0
};

const centeredLabelStyles = {
    textAnchor: "middle",
    ...baseLabelStyles
};

const strokeLinecap = "round";
const strokeLinejoin = "round";

const dataColors = {
    WHITE: Color.WHITE,
    OFF_WHITE: Color.OFF_WHITE,
    GREY: Color.DARK_GREY,
    PRIMARY: PrimaryColor.main,
    PRIMARY_300: PrimaryColor["300"],
    PRIMARY_50: PrimaryColor["50"],
    SCALE: Object.values(DataVisualizationColor)
};

export const DataVisualizationTheme = Object.freeze({
    "area": {
        "style": {
            "data": {"fill": dataColors.GREY},
            "labels": baseLabelStyles
        },
        ...baseProps
    },
    "dependentAxis": {
        "style": {
            "axis": {
                "fill": "transparent",
                "stroke": dataColors.PRIMARY_300,
                "strokeWidth": 2,
                "strokeLinecap": strokeLinecap,
                "strokeLinejoin": strokeLinejoin
            },
            "axisLabel": centeredLabelStyles,
            "grid": {
                "fill": "none",
                "stroke": dataColors.PRIMARY_50,
                "strokeLinecap": strokeLinecap,
                "strokeLinejoin": strokeLinejoin,
                "pointerEvents": "painted"
            },
            "ticks": {
                "fill": "transparent",
                "size": 5,
                "stroke": dataColors.PRIMARY_300,
                "strokeWidth": 1,
                "strokeLinecap": strokeLinecap,
                "strokeLinejoin": strokeLinejoin
            },
            "tickLabels": baseLabelStyles
        },
        ...baseProps
    },
    "independentAxis": {
        "style": {
            "axis": {
                "fill": "transparent",
                "stroke": dataColors.PRIMARY_300,
                "strokeWidth": 2,
                "strokeLinecap": strokeLinecap,
                "strokeLinejoin": strokeLinejoin
            },
            "axisLabel": centeredLabelStyles,
            "grid": {
                "fill": "none",
                "stroke": "transparent"
            },
            "ticks": {
                "fill": "transparent",
                "size": 5,
                "stroke": dataColors.PRIMARY_300,
                "strokeWidth": 1,
                "strokeLinecap": strokeLinecap,
                "strokeLinejoin": strokeLinejoin
            },
            "tickLabels": baseLabelStyles
        },
        ...baseProps
    },
    "bar": {
        "style": {
            "data": {"fill": dataColors.PRIMARY, "padding": 8, "strokeWidth": 0},
            "labels": baseLabelStyles
        },
        ...baseProps
    },
    "boxplot": {
        "style": {
            "max": {"padding": 8, "stroke": dataColors.PRIMARY, "strokeWidth": 1},
            "maxLabels": baseLabelStyles,
            "median": {"padding": 8, "stroke": dataColors.PRIMARY, "strokeWidth": 1},
            "medianLabels": baseLabelStyles,
            "min": {"padding": 8, "stroke": dataColors.PRIMARY, "strokeWidth": 1},
            "minLabels": baseLabelStyles,
            "q1": {"padding": 8, "fill": dataColors.PRIMARY},
            "q1Labels": baseLabelStyles,
            "q3": {"padding": 8, "fill": dataColors.PRIMARY},
            "q3Labels": baseLabelStyles
        }, "boxWidth": 20,
        ...baseProps
    },
    "candlestick": {
        "style": {
            "data": {"stroke": dataColors.PRIMARY},
            "labels": baseLabelStyles
        }, "candleColors": {"positive": dataColors.WHITE, "negative": dataColors.PRIMARY},
        ...baseProps
    },
    "chart": {
        ...baseProps
    },
    "errorbar": {
        "borderWidth": 8,
        "style": {
            "data": {"fill": "transparent", "opacity": 1, "stroke": dataColors.PRIMARY, "strokeWidth": 2},
            "labels": baseLabelStyles
        },
        "width": 350,
        "height": 350,
        "padding": 50
    },
    "group": {
        "colorScale": dataColors.SCALE,
        "width": 350,
        "height": 350,
        "padding": 50
    },
    "legend": {
        "colorScale": dataColors.SCALE,
        "gutter": 10,
        "orientation": "vertical",
        "titleOrientation": "top",
        "style": {
            "data": {"type": "circle"},
            "labels": baseLabelStyles,
            "title": {
                "fontFamily": FontFamily,
                "fontSize": 12,
                "letterSpacing": "normal",
                "padding": 5,
                "fill": dataColors.PRIMARY,
                "stroke": "transparent",
                "strokeWidth": 0
            }
        }
    },
    "line": {
        "style": {
            "data": {"fill": "transparent", "opacity": 1, "stroke": dataColors.PRIMARY, "strokeWidth": 2},
            "labels": baseLabelStyles
        },
        ...baseProps
    },
    "pie": {
        "colorScale": dataColors.SCALE,
        "style": {
            "data": {"padding": 8, "stroke": dataColors.PRIMARY_50, "strokeWidth": 1},
            "labels": {
                "fontFamily": FontFamily,
                "fontSize": 12,
                "letterSpacing": "normal",
                "padding": 20,
                "fill": dataColors.PRIMARY,
                "stroke": "transparent",
                "strokeWidth": 0
            }
        },
        "width": 350,
        "height": 350,
        "padding": 50
    },
    "scatter": {
        "style": {
            "data": {"fill": dataColors.PRIMARY, "opacity": 1, "stroke": "transparent", "strokeWidth": 0},
            "labels": baseLabelStyles
        },
        ...baseProps
    },
    "stack": {
        "colorScale": dataColors.SCALE,
        "width": 350,
        "height": 350,
        "padding": 50
    },
    "tooltip": {
        "style": {
            "border-style": 'none',
            "fontFamily": FontFamily,
            "fontSize": 30,
            "letterSpacing": "normal",
            "padding": 5,
            "fill": dataColors.PRIMARY,
            "stroke": "transparent",
            "strokeWidth": 0,
            "pointerEvents": "none"
        },
        "flyoutStyle": {"stroke": dataColors.GREY, "strokeWidth": 1, "fill": dataColors.OFF_WHITE, "pointerEvents": "none"},
        "cornerRadius": 5,
        "pointerLength": 10
    },
    "voronoi": {
        "style": {
            "data": {"fill": "transparent", "stroke": "transparent", "strokeWidth": 0},
            "labels": {
                "fontFamily": FontFamily,
                "fontSize": 12,
                "letterSpacing": "normal",
                "padding": 5,
                "fill": dataColors.PRIMARY,
                "stroke": "transparent",
                "strokeWidth": 0,
                "pointerEvents": "none"
            },
            "flyout": {"stroke": dataColors.GREY, "strokeWidth": 1, "fill": dataColors.OFF_WHITE, "pointerEvents": "none"}
        },
        ...baseProps
    }
});

//endregion
