import React, {Component} from 'react';
import ObligationItemEstimateService from 'worksmith/services/api/ObligationItemEstimateService';
import ClientService from 'worksmith/services/api/ClientService';
import ClientLocationService from 'worksmith/services/api/ClientLocationService';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification'
import Loader from "./Loader";
import Modal from 'react-modal';
import WSButton from '../components/Button';
import ProceedPartiallyEstimateForm from './forms/ProceedPartiallyEstimateForm';
import {FormattedNumber} from 'react-intl';
const t = require('tcomb-form');

const estimateService = new ObligationItemEstimateService();
const clientService = new ClientService();
const clientLocationService = new ClientLocationService();

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

class EstimateList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            estimates: [],
            loading: false,
            value: {}
        };

        this.proceedWithEstimate = this.proceedWithEstimate.bind(this);
        this.partiallyProceedModal = this.partiallyProceedModal.bind(this);
        this.proceedPartially = this.proceedPartially.bind(this);
        this.declineEstimate = this.declineEstimate.bind(this);
        this.getEstimates = this.getEstimates.bind(this);
    }

    componentDidMount() {
        this.getEstimates();
    }

    getEstimates(){
        let _this = this;
        this.setState({ loading: true });

        let promise;

        if(this.state.user.clientRoles != null) {
            promise = clientService.getEstimates(this.state.user.clientRoles[0].client.id, 'pending');
        } else {
            promise = clientLocationService.getEstimates(this.state.user.clientLocationRoles[0].clientLocation.id, 'pending');
        }

        promise.then(function (data) {
            let estimates = data;

            _this.setState({estimates, loading: false});
        })
    }

    proceedWithEstimate(estimateId) {

        let _this = this;

        let estimate = { id: estimateId };
        estimateService.proceed(estimateId, estimate).then(function(data) {
            notification['success']({
                message: 'Estimate approved!',
            });

            _this.getEstimates();
        })
    }

    partiallyProceedModal(estimate) {
        this.setState({ partiallyProceedModalVisible: true, partiallyProceedingEstimate: estimate });
    }

    proceedPartially(estimate) {

        let _this = this;

        estimateService.proceedPartially(estimate.id, estimate).then(function(data) {
            notification['success']({
                message: 'Partially proceeding with work!',
            });

            _this.setState({partiallyProceedModalVisible: false});

            _this.getEstimates();
        })
    }

    declineEstimate(estimateId) {
        let _this = this;

        let estimate = { id: estimateId };
        estimateService.decline(estimateId, estimate).then(function(data) {
            notification['success']({
                message: 'Estimate declined!',
            });

            _this.getEstimates();
        })
    }

    render() {
        let {loading} = this.state;

        const dropdownMenu = (estimate) => {
            return (
                <Menu>
                    <Menu.Item key="1">
                        <Popconfirm placement="top" title={'Are you sure you want the vendor to proceed with the work?'}
                                    onConfirm={() => this.proceedWithEstimate(estimate.id)} okText="Yes" cancelText="No">
                            Proceed with Work
                        </Popconfirm>
                    </Menu.Item>
                    <Menu.Item key="2">
                            <span onClick={() => this.partiallyProceedModal(estimate)}>Partially Proceed with Work</span>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Popconfirm placement="top" title={'Are you sure you want to decline this work and have the items sent back?'}
                                    onConfirm={() => this.declineEstimate(estimate.id)} okText="Yes" cancelText="No">
                            Decline Work
                        </Popconfirm>
                    </Menu.Item>
                </Menu>
            )
        };

        return (
            this.state.estimates.length > 0 ?
                this.state.loading ?
                    <Loader/> :
                    <section className={'ws-section'}>
                        <p className="subheader">Estimates Needing Approval <small className={'hidden-md-up'}></small></p>
                        <Modal
                        isOpen={this.state.partiallyProceedModalVisible}
                        contentLabel="Modal"
                        style={customStyles}>

                        <div className="row">
                            <div className="col-md-12 text-right">
                                <WSButton
                                    message={'Close'}
                                    type={'link'}
                                    onPress={() => this.setState({partiallyProceedModalVisible: false})}/>
                            </div>
                        </div>

                        <ProceedPartiallyEstimateForm handleSubmit={this.proceedPartially} proceedingPartiallyEstimate={this.state.partiallyProceedingEstimate} />
                    </Modal>

                        <div className="table-responsive">
                            <table className="table ws-table">
                                <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Vendor</th>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Price</th>
                                    <th>Action(s)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.estimates.map(function (estimate, idx) {
                                    return (
                                        <tr key={idx}>
                                            <td>{estimate.clientLocation.name}</td>
                                            <td>{estimate.vendor.name}</td>
                                            <td>{estimate.obligationItemSummary.title}</td>
                                            <td>{estimate.description}</td>
                                            <td><FormattedNumber
                                                value={estimate.retailPrice}
                                                style="currency"
                                                currency="USD" /></td>
                                            <td>
                                                <Dropdown overlay={dropdownMenu(estimate)}>
                                                    <Button style={{marginLeft: 8}}>
                                                        Action <Icon type="down"/>
                                                    </Button>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </section>
            : null
        )
    }
}

export default EstimateList;
