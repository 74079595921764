import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ClientType, Color} from "../Enums";
import {AppStore, GooglePlayStore} from "../Config";
import {AlignItems, Display, JustifyContent, TextDecoration} from "../CSSEnums";

const Container = styled.div`
    flex: 1;
    display: ${Display.FLEX};
    justify-content: ${JustifyContent.SPACE_BETWEEN};
    margin-top: 3.5em;
    margin-bottom: 1.5em;
    
    @media print {
        display: none;
    }
`;

const TermsOfUse = styled.div`
    color: ${Color.BLUE};
    text-decoration: ${TextDecoration.UNDERLINE};
    flex: 10;
`;

const AppLinks = styled.div`
    align-items: ${AlignItems.FLEX_END};
    flex: 1;
    display: ${Display.FLEX};
    justify-content: ${JustifyContent.SPACE_BETWEEN};
`;

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let {serviceLineConfigurationType} = this.props;

        return (
            <div className='container-fluid row col-md-10 offset-md-1 col-xs-12'>
                <Container>
                    <TermsOfUse>
                        <a href="https://clients.worksmith.com/#/serviceAgreement">
                            Terms of Use
                        </a>
                    </TermsOfUse>
                    { serviceLineConfigurationType !== ClientType.DRY_CLEANING_ONLY ?
                        <AppLinks>
                            <a href={'https://itunes.apple.com/us/app/worksmith-vendors/id1444807706?ls=1&mt=8'}
                               style={{'marginRight': '20px'}}>
                                <img style={{height: '40px', width: '120px'}} src={AppStore.image}
                                     alt="Apple Store"/>
                            </a>
                            <a href={'https://play.google.com/store/apps/details?id=com.worksmithmobileapp'}>
                                <img style={{height: '40px', width: '120px'}} src={GooglePlayStore.image}
                                     alt="Google Play Store"/>
                            </a>
                        </AppLinks> : null }
                </Container>
            </div>
        );
    }
}

Footer.propTypes = {
    serviceLineConfigurationType: PropTypes.string.isRequired
};

export default Footer;