import React, {Component} from 'react';
import moment from 'moment';
import ContractScopeOfWork from "worksmith/components/Contract/ContractScopeOfWork";
import * as custom from "worksmith/helpers/CustomTcombTypes";
import {ErrorText} from "../../components/styledComponents/Basic";
import ConfirmRequestModal from "./ConfirmRequestModal";
import {GetKeyByValue, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import FileUpload from 'worksmith/components/FileUpload/FileUpload';
import Button from "worksmith/components/Button/Button.web";
import styled from "styled-components";
import {
    AutoAcceptWalkthroughsDuringBusinessHours,
    RequestType,
    ServiceLine,
    ServiceLineType,
    WalkthroughPreference
} from "../../Enums";
import {isAutomationTargeted} from "worksmith/helpers/AutomationHelper";
import notification from "antd/lib/notification";
import RequestForProposalService from "worksmith/services/api/RequestForProposalService";
import ClientLocationService from "worksmith/services/api/ClientLocationService";
import PropTypes from "prop-types";
import {ContractItemName} from "worksmith/enums/ContractEnums";
import Grid from "worksmith/components/Grid/Grid.web";
import RequestChecklistModal from "worksmith/components/Contract/RequestChecklist/RequestChecklistModal";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import ClientRoutes from "../../ClientRoutes";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";
import FrequencyPicker from "worksmith/components/FrequencyPicker/FrequencyPicker.web";
import LocationProfileQuestionsModal from "./LocationProfileQuestionsModal";
import Tooltip, {TooltipPlacement} from 'worksmith/components/Tooltip/Tooltip.web';
import Icon from "worksmith/components/Icon/Icon.web";
import {FunctionalColor} from "../../lib-worksmith/enums/Color";
import {IconFontSize} from "../../lib-worksmith/components/Icon/Icon.web";
import Text, {TextVariant} from "../../lib-worksmith/components/Text/Text.web";
import Select, {SelectType} from "worksmith/components/Inputs/Select/Select";
import Snackbar from "worksmith/components/Snackbar/Snackbar";
import {TestIds} from "worksmith/enums/CypressTestClient"
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const t = require('tcomb-form');
const Form = t.form.Form;
const requestForProposalService = new RequestForProposalService();
const clientLocationService = new ClientLocationService();


const AttachmentHelpText = styled.div`
    color: #1A9BF1;
    font-size: 0.875rem !important;
    font-style: italic
    display: ${props => props.display};
    text-align: left;
`;

const FrequencyContainer = styled.div`
    padding-bottom: 25px;
`;

class NewContractRequestForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attachmentsUploaded: false,
            attachmentsUploadError: false,
            confirmationModalVisible: false,
            errorList: [],
            loading: false,
            files: [],
            profileModalOpen: false,
            profileQuestionTopics: [],
            requestSubmitError: false,
            requestSubmitted: false,
            value: {
                requestType: null,
                emergency: null,
                requestSchedulingOption: (props.defaultRequestSchedulingOption ? props.defaultRequestSchedulingOption : "within 2 weeks"),
                walkthroughPreference: (props.defaultWalkthroughPreference ? props.defaultWalkthroughPreference : "Walkthrough Optional"),
                autoAcceptWalkthroughsDuringBusinessHours: (props.autoAcceptWalkthroughsDuringBusinessHours ? props.autoAcceptWalkthroughsDuringBusinessHours : "true"),
                onDate: [moment().format('YYYY'), null, null],
                itemizedBidRequired: true,
                excludedVendors: [],
                standardItems: [],
            },
            snackBar: false,
            snackBarMessage: ''
        };

        this.frequency = {
            recurrenceCount: 1,
            recurrenceType: RecurrenceType.DAILY,
            repeatEvery: 1
        };
    }

    getFileData = (fileData) => {
        this.setState({files: fileData});
    };


    validateOpenModal = modalStateKey => {
        let {selectedServiceLine} = this.props;
        let {numberOfRequestPhotosRequired, numberOfEmergencyRequestPhotosRequired} = this.props.client.configurationSettings;

        if (this.refs.form.validate().isValid()) {

            if( !this.state.emergency && ValueIsSet(numberOfRequestPhotosRequired) && this.state.files.length < numberOfRequestPhotosRequired){
                this.setState({loading: false, [modalStateKey]: false});
                DisplayErrorNotification('At least ' +  numberOfRequestPhotosRequired + ' ' + Pluralize(numberOfRequestPhotosRequired, 'photo is', 'photos are')  + ' required to submit your request!').then();
                return;
            }else if( this.state.emergency && ValueIsSet(numberOfEmergencyRequestPhotosRequired) && this.state.files.length < numberOfEmergencyRequestPhotosRequired) {
                this.setState({loading: false, [modalStateKey]: false});
                DisplayErrorNotification('At least ' +  numberOfEmergencyRequestPhotosRequired + ' ' + Pluralize(numberOfEmergencyRequestPhotosRequired, 'photo is', 'photos are')  + ' required to submit your emergency request!').then();
                return;
            }else{
                if (isAutomationTargeted(GetKeyByValue(ServiceLineType, parseInt(selectedServiceLine.value)), true)) {
                    if (this.state.files.length === 0) {
                        this.setState({loading: false, [modalStateKey]: false});
                        DisplayErrorNotification('At least one photo is required for ' + ServiceLine[GetKeyByValue(ServiceLineType, parseInt(selectedServiceLine.value))].toLowerCase() + ' requests!').then();
                        return;
                    }
                }
            }
            this.setState({[modalStateKey]: true});

        } else
            notification['error']({
                message: 'Please complete all required fields!',
                style: {'zoom': '150%'}
            });
    };

    closeProfileModal = () => {
        this.setState({ profileModalOpen: false, confirmationModalVisible: true})
    };

    openProfileModal = async () => {
        let {validateSubmitButton} = this;

        let errorList = await validateSubmitButton();
        this.setState({errorList: errorList});

        if (errorList.length === 0) {
            const {locationProfileData, selectedServiceLine} = this.props;
            const serviceLinesForProfileModal =
                selectedServiceLine.value === ServiceLineType.ELECTRICAL_WORK ||
                selectedServiceLine.value === ServiceLineType.HANDYMAN ||
                selectedServiceLine.value === ServiceLineType.LIGHTING ||
                selectedServiceLine.value === ServiceLineType.PAINTING ||
                selectedServiceLine.value === ServiceLineType.PEST_CONTROL ||
                selectedServiceLine.value === ServiceLineType.WINDOW_WASHING

            if (!serviceLinesForProfileModal) { // if no relevant service lines, skip profile modal and move to
                this.openConfirmationModal()
            }

            const noLadderInfo = !locationProfileData.clientLocationOperationDetails.hasLadderOnSite && !locationProfileData.clientLocationOperationDetails.ladderHeightFt
            const noIndustryInfo = !locationProfileData.industry
            const noCeilingHeightInfo = locationProfileData.clientLocationFloors.length === 0 || !locationProfileData.clientLocationFloors[0].ceilingHeightFt

            switch (selectedServiceLine.value) {
                case ServiceLineType.PEST_CONTROL:
                    if (noIndustryInfo) {
                        this.setState({profileQuestionTopics: ['industry']})
                        this.validateOpenModal('profileModalOpen')
                    } else {
                        this.openConfirmationModal()
                    }
                    break;
                case ServiceLineType.HANDYMAN:
                    if (noLadderInfo) {
                        this.setState({profileQuestionTopics: ['ladder']})
                        this.validateOpenModal('profileModalOpen')
                    } else {
                        this.openConfirmationModal()
                    }
                    break;
                case ServiceLineType.WINDOW_WASHING:
                    if (noLadderInfo) {
                        this.setState({profileQuestionTopics: ['ladder']})
                        this.validateOpenModal('profileModalOpen')
                    } else {
                        this.openConfirmationModal()
                    }
                    break;
                case ServiceLineType.ELECTRICAL_WORK:
                    if (noLadderInfo || noCeilingHeightInfo) {
                        let updatedTopics = []
                        if (noLadderInfo) {
                            updatedTopics.push('ladder')
                        }
                        if (noCeilingHeightInfo) {
                            updatedTopics.push('ceilingHeight')
                        }
                        this.setState({profileQuestionTopics: updatedTopics})
                        this.validateOpenModal('profileModalOpen')
                    } else {
                        this.openConfirmationModal()
                    }
                    break;
                case ServiceLineType.LIGHTING:
                    if (noLadderInfo || noCeilingHeightInfo) {
                        let updatedTopics = []
                        if (noLadderInfo) {
                            updatedTopics.push('ladder')
                        }
                        if (noCeilingHeightInfo) {
                            updatedTopics.push('ceilingHeight')
                        }
                        this.setState({profileQuestionTopics: updatedTopics})
                        this.validateOpenModal('profileModalOpen')
                    } else {
                        this.openConfirmationModal()
                    }
                    break;
                case ServiceLineType.PAINTING:
                    if (noLadderInfo || noCeilingHeightInfo) {
                        let updatedTopics = []
                        if (noLadderInfo) {
                            updatedTopics.push('ladder')
                        }
                        if (noCeilingHeightInfo) {
                            updatedTopics.push('ceilingHeight')
                        }
                        this.setState({profileQuestionTopics: updatedTopics})
                        this.validateOpenModal('profileModalOpen')
                    } else {
                        this.openConfirmationModal()
                    }
                    break;
            }
        }
    };

    openConfirmationModal = () => {
        this.setState({
            profileModalOpen: false
        });
        this.validateOpenModal('confirmationModalVisible')
    };

    onFrequencyChanged = (newFrequency) => {
        this.frequency = newFrequency;
    };

    onChange(value) {
        if (value) {
            let dateChangedAndNotNull = value.onDate && value.onDate !== this.state.value.onDate;

            if (dateChangedAndNotNull) {
                let enteredDate = value.onDate;
                this.state.isEmergencyRequestDate = !!custom.isEmergencyRequest(enteredDate);
            }

            this.setState({value});
        }
    }

    validateSubmitButton = async () => {
        let {value} = this.state;
        let errorList = [];

        if(!ValueIsSet(value.requestType)){
            errorList.push("requestType");
            this.setState({snackBar: true, snackBarMessage: "Please select a Request Type"})
        }

        return errorList;
    };

    onSubmit = async () => {
        let {history, selectedLocationId, selectedServiceLine, serviceLineSpecificQuestionAnswers} = this.props;
        let value = this.state.value;

        this.setState({loading: true});
        let locationId = parseInt(selectedLocationId, 10);

        let submitData = {
            customAnswers: serviceLineSpecificQuestionAnswers,
            description: value.description,
            location: {
                id: locationId
            },
            serviceLine: {
                id: parseInt(selectedServiceLine.value, 10)
            },
            emergency: value.emergency,
            timePreference: value.timePreference,
            type: value.requestType,
            walkthroughPreference: value.walkthroughPreference,
            requestSchedulingOption: value.requestSchedulingOption,
            autoAcceptWalkthroughsDuringBusinessHours: value.autoAcceptWalkthroughsDuringBusinessHours,
            poNumber: value.poNumber,
            itemizedBidRequired: true,
            excludedVendors: [],
            rfpItems: [],
            worksmithNTE: value.worksmithNTE,
            attachmentCount: ValueIsSet(this.state.files) ? this.state.files.length : 0
        };

        try {
            const mediaData = this.state.files.filter(file => !file.isMediaFile);
            await clientLocationService.addAttachments(locationId, mediaData, "");
        } catch (err) {
            console.error(err)
            await DisplayErrorNotification("Error uploading attachments")
        }

        submitData.onDate = moment(value.onDate).toISOString();
        if (this.state.value.requestType === RequestType.RECURRING) {
            submitData.frequency = this.frequency;
        }

        requestForProposalService.insertWithAttachments(submitData, this.state.files).then((rfp) => {
            let rfpId = rfp.id;
            this.setState({requestSubmitted: true, loading: false, attachmentsUploaded: true});
            return rfpId;
        }).then((id) => {
            setTimeout(() => history.push(ClientRoutes.SINGLE_REQUESTS(id)), 1000);
        }).catch(() => {
            this.setState({loading: false, requestSubmitError: true});
            return 0;
        });

    };

    render() {
        let {clientId, locationTitle, locationProfileData, selectedLocationId, selectedServiceLine, activeContractItemsForServiceLine, client} = this.props;
        let {value, errorList, snackBar, snackBarMessage} = this.state;
        let {onFrequencyChanged} = this;
        let {autoAcceptWalkthroughsDuringBusinessHours, numberOfRequestPhotosRequired, numberOfEmergencyRequestPhotosRequired} = this.props.client.configurationSettings;
        let ContractRequestFormFields = {};

        let order = [];
        let optionFields = {};

        let hourlyRate = activeContractItemsForServiceLine.find(locationContractItem => locationContractItem.contractItem.name === ContractItemName.HOURLY_RATE);
        hourlyRate = ValueIsSet(hourlyRate) ?
            Math.trunc(hourlyRate.unitRetailPrice)
            :
            null;

        let emergencyHourlyRate = activeContractItemsForServiceLine.find(locationContractItem => locationContractItem.contractItem.name === ContractItemName.EMERGENCY_RATE);
        emergencyHourlyRate = ValueIsSet(emergencyHourlyRate) ?
            Math.trunc(emergencyHourlyRate.unitRetailPrice)
            :
            null;

        let tripCharge = activeContractItemsForServiceLine.find(locationContractItem => locationContractItem.contractItem.name === ContractItemName.TRIP_CHARGE);
        tripCharge = ValueIsSet(tripCharge) ?
            Math.trunc(tripCharge.unitRetailPrice)
            :
            null;

        let emergencyTripCharge = activeContractItemsForServiceLine.find(locationContractItem => locationContractItem.contractItem.name === ContractItemName.EMERGENCY_TRIP_CHARGE);
        emergencyTripCharge = ValueIsSet(emergencyTripCharge) ?
            Math.trunc(emergencyTripCharge.unitRetailPrice)
            :
            null;

        let CompletionTypeOptions = [
            {
                label: <Text bold>One Time</Text>,
                value: RequestType.ON_DEMAND,
                testId: TestIds.ON_DEMAND,
                helperText: <Text>A non-emergency request</Text>
            },
            {
                label: <Text bold>Emergency</Text>,
                value: 'emergency',
                testId: TestIds.EMERGENCY,
                helperText:
                    (
                        <Grid container item>
                            <Grid item height={'100%'}>
                                <Text>Serious or dangerous issue requiring immediate attention</Text>
                            </Grid>
                            <Grid item width={'20px'}>
                            </Grid>
                            <Grid item xs={11}>
                                <Grid container>
                                    <Grid item>
                                        <Text>Expected Response Time: Within 4 hours</Text>
                                    </Grid>
                                    <Grid item width={'30px'}>
                                        <Tooltip placement={TooltipPlacement.BOTTOM}
                                                 title={"You should expect to hear from the vendor within 4 hours.  This does not guarantee that the work will be completed within that timeframe."}>
                                            <Icon name={'HelpOutline'}
                                                  iconColor={FunctionalColor.GRAY}
                                                  fontSize={IconFontSize.INHERIT}
                                                  verticalCentered/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                {
                                    emergencyHourlyRate ?
                                        <Text>{`Rate: \$${emergencyHourlyRate}/hr${emergencyTripCharge ? `, \$${emergencyTripCharge} Trip Charge` : ``}`}</Text>
                                        :
                                        null
                                }
                            </Grid>
                        </Grid>
                    )
            },
            {
                label: <Text bold>Recurring</Text>,
                value: RequestType.RECURRING,
                testId: TestIds.RECURRING,
                helperText: <Text variant={TextVariant.BODY_2}>Marking the job complete will close the opportunity meaning the client's issue has been resolved.</Text>
            }
        ];

        let requestTypeFieldTemplate = () => {
            let {value} = this.state;
            return (
                    <Grid container margin={'0 0 25px 0'}>
                        <Select
                            includeNullOption
                            showRequiredAsterisk
                            label={<Text variant={TextVariant.BODY_2}>Request Type</Text>}
                            error={errorList.includes('requestType')}
                            primary
                            initialValue={null}
                            onChange={requestType => {
                              if(requestType === RequestType.ON_DEMAND){
                                  value.emergency = false;
                                  value.requestType = RequestType.ON_DEMAND;
                              }else if(requestType === RequestType.RECURRING){
                                  value.emergency = false;
                                  value.requestType = RequestType.RECURRING;
                              }else if(requestType === 'emergency'){
                                  value.emergency = true;
                                  value.requestType = RequestType.ON_DEMAND;
                              }
                              this.setState(value)
                            }}
                            options={CompletionTypeOptions}
                            type={SelectType.RADIO_BUTTONS}
                            fullWidth
                        />
                    </Grid>
            )
        };

        optionFields.isEmergency = {
            label: "Request Type",
            template: requestTypeFieldTemplate,
        };

        ContractRequestFormFields.isEmergency = t.list(
            t.struct({
                isEmergency: t.maybe(t.String),
            }));

        order.push("isEmergency");

        if (value.emergency === false) {

            ContractRequestFormFields.requestSchedulingOption = t.enums({
                "within a week": "Within a Week",
                "within 2 weeks": "Within 2 Weeks",
                "on a specific date": "On a Specific Date"
            });
            optionFields.requestSchedulingOption = {
                label: "When would you like the request to be completed by?",
                nullOption: false
            };

            order.push("requestSchedulingOption");

            if (value.requestSchedulingOption === 'on a specific date') {

                ContractRequestFormFields.onDate = custom.RequestDate;
                optionFields.onDate = {
                    label: 'Preferred ' + (value.requestType === RequestType.RECURRING ? 'Start Date' : 'Date of Completion'),
                    help: this.state.isEmergencyRequestDate ?
                        <i>Target completion dates of same-day, next-day, holidays, or weekends may incur expedited
                            fees
                            for after-hours labor.</i> : '',
                    minimumDate: moment().startOf('day').toDate()
                };
                order.push("onDate");
            }

            if (value.requestType === RequestType.RECURRING) {
                let frequencyPicker = (locals) => {
                    return (
                        <FrequencyContainer>
                            <label style={{'paddingTop': '15px'}}>{locals.label}</label>
                            <FrequencyPicker initialFrequency={this.frequency} onChange={onFrequencyChanged}/>
                        </FrequencyContainer>
                    );
                };

                ContractRequestFormFields.frequency = t.maybe(t.String);
                optionFields.frequency = {
                    label: 'Frequency',
                    template: frequencyPicker
                };
                order.push("frequency");
            }

            ContractRequestFormFields.timePreference = t.enums({
                "Before Business Hours": "Before Business Hours",
                "During Business Hours": "During Business Hours",
                "After Business Hours": "After Business Hours",
            });
            optionFields.timePreference = {
                label: 'Time Preference',
                nullOption: {value: '', text: 'Select a Time Preference'},
                error: <ErrorText>Required Field</ErrorText>,
            };
            order.push("timePreference");
        }

        if (autoAcceptWalkthroughsDuringBusinessHours === AutoAcceptWalkthroughsDuringBusinessHours.SOMETIMES && value.walkthroughPreference !== WalkthroughPreference.PROHIBITED) {
            ContractRequestFormFields.autoAcceptWalkthroughsDuringBusinessHours = t.enums({
                "true": "Yes",
                "false": "No"
            });
            optionFields.autoAcceptWalkthroughsDuringBusinessHours = {
                label: 'Automatically Accept Complimentary Walkthroughs During Business Hours',
                error: <ErrorText>Required Field</ErrorText>,
                factory: t.form.Radio
            };
            order.push("autoAcceptWalkthroughsDuringBusinessHours");
        }

        ContractRequestFormFields.poNumber = t.maybe(t.String);
        optionFields.poNumber = {
            label: "PO Number (optional)",
            type: 'text'
        };
        order.push("poNumber");

        ContractRequestFormFields.description = t.refinement(t.String, (s) => s.length > 9);
        optionFields.description = {
            type: 'textarea',
            label: selectedServiceLine.requestDescription || 'Description',
            error: <ErrorText>A minimum of 10 characters is required</ErrorText>
        };
        order.push("description");

        let OnDemand = t.struct(ContractRequestFormFields);
        let formOptions = {
            order: order,
            fields: optionFields
        };

        let isLocksmith = Object.is(parseInt(selectedServiceLine.value, 10), ServiceLineType.LOCKSMITH);
        let isDoorRepair = Object.is(parseInt(selectedServiceLine.value, 10), ServiceLineType.DOOR_REPAIR);
        let numPhotosRequired = 0;
        if(ValueIsSet(this.state.emergency) && this.state.emergency && ValueIsSet(numberOfEmergencyRequestPhotosRequired)){
            numPhotosRequired = numberOfEmergencyRequestPhotosRequired;
        }
        else if(ValueIsSet(this.state.emergency) && !this.state.emergency && ValueIsSet(numberOfRequestPhotosRequired)){
            numPhotosRequired = numberOfRequestPhotosRequired
        }
        else {
            numPhotosRequired = isAutomationTargeted(GetKeyByValue(ServiceLineType, parseInt(selectedServiceLine.value)), true) ? 1 : 0;
        }

        return <div>
            <ConfirmRequestModal
                attachmentsUploaded={this.state.attachmentsUploaded}
                attachmentsUploadError={this.state.attachmentsUploadError}
                handleOk={this.onSubmit}
                handleCancel={() => {
                    this.setState({confirmationModalVisible: false, requestSubmitError: false})
                }}
                immediatelySubmit={true}
                visible={this.state.confirmationModalVisible}
                requestSubmitted={this.state.requestSubmitted}
                requestSubmitError={this.state.requestSubmitError}
                submitting={this.state.loading}/>

            <LocationProfileQuestionsModal
                closeProfileModal={this.closeProfileModal}
                locationId={locationProfileData && locationProfileData.id}
                locationProfileData={locationProfileData}
                openConfirmationModal={this.openConfirmationModal}
                profileModalOpen={this.state.profileModalOpen}
                profileQuestionTopics={this.state.profileQuestionTopics}
                selectedServiceLine={selectedServiceLine.value}
            />

            <ContractScopeOfWork locationId={selectedLocationId} serviceLineId={selectedServiceLine.value} canEdit={false} buttonText={"Scope Of Work"}/>
            <br/>
            <br/>
            <RequestChecklistModal clientId={clientId} locationTitle={locationTitle} serviceLineId={selectedServiceLine.value} serviceLineName={selectedServiceLine.text}/>

            <Form ref="form"
                  type={OnDemand}
                  value={value}
                  onChange={(value) => this.onChange(value)}
                  options={formOptions}
            />

            <AttachmentHelpText display={(isLocksmith || isDoorRepair) ? 'block' : 'none'}>
                {ValueIsSet(numPhotosRequired) && numPhotosRequired > 0 ?
                    <Text>
                        {'At least ' + numPhotosRequired + ' ' + Pluralize(numPhotosRequired, 'photo is', 'photos are') + ' required! Helpful photos include:'}
                    </Text>
                    : <div>Helpful photos include:</div>
                }
                <div>For doors - front and back of door, the interior/exterior edges, the door jamb, the hinges, and
                    the latch hole
                </div>
                <div>For locks - closeups of the hardware, the brand, the model number, and the keys</div>
            </AttachmentHelpText>
            <FileUpload
                allowMediaLibrary
                locationId={selectedLocationId}
                onChange={this.getFileData}
                multiple
                title={'Photos & Attachments'}
                tooltipPlacement={TooltipPlacement.BOTTOM_START}
            />
            <div className="text-right">
                <Button
                    primary
                    onClick={this.openProfileModal}>
                    Submit Request
                </Button>
            </div>
            <Snackbar
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                autoHideDuration={3000}
                includeIcon={false}
                isOpen={snackBar}
                message={snackBarMessage}
                onClose={() => this.setState({snackBar: false})}
            />
        </div>

    }
}

NewContractRequestForm.propTypes = {
    clientId: CustomPropTypes.stringOrNumber,
    locationTitle: PropTypes.string,
    locationProfileData: PropTypes.shape({
        clientLocationAttachments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            singleUseUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })),
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            deleted: PropTypes.bool
        })),
        clientLocationOperationDetails: PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            hasLadderOnSite: PropTypes.bool,
            ladderHeightFt: PropTypes.number,
        }),
        id: PropTypes.number,
        industry: PropTypes.string,
    }),
    selectedLocationId: PropTypes.string,
    selectedServiceLine: PropTypes.object,
    activeContractItemsForServiceLine: PropTypes.array,
    serviceLineSpecificQuestionAnswers: PropTypes.array,
    history: PropTypes.object
};
export default NewContractRequestForm;