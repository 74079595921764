import React, {useState} from 'react';
import PropTypes from 'prop-types';

import AlertDialog from "../../components/Dialog/AlertDialog";


const AcceptAndDeferModal = (props) => {
    const {
        accept,
        isWalkthrough,
        closeModal,
        open
    } = props;
    const [loading, setLoading] = useState(false);

    return (
        <AlertDialog
            acceptDisabled={loading}
            acceptText={'Yes, Continue'}
            body={`This will accept pricing and notify the Local Manager to schedule the ${isWalkthrough ? `walkthrough`: `job`}. Continue?`}
            cancelText={'Cancel'}
            debounceAccept
            onAccept={() => {
                accept();
                closeModal();
            }}
            onAcceptIgnoreDebounce={() => setLoading(true)}
            onCancel={closeModal}
            open={open}
            title={'Defer Scheduling to Local Manager?'}/>
    )
};

AcceptAndDeferModal.propTypes = {
    accept: PropTypes.func,
    isWalkthrough: PropTypes.bool,
    closeModal: PropTypes.func,
    open: PropTypes.bool
};

export default AcceptAndDeferModal;