import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import JobCompletionDetailsDialogView
    from "worksmith/composite-components/JobCompletionDetails/JobCompletionDetailsDialogView";
import GraphQLServiceClass from 'worksmith/services/graphql/GraphQLServiceClass';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Loader from "worksmith/components/Loader/Loader";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import UserType from "worksmith/enums/api/user/UserType";
import {DialogSize} from "worksmith/enums/DialogSize";
import {LoaderType} from "worksmith/enums/LoaderType";

const graphqlServiceClass = new GraphQLServiceClass();

class JobCompletionDetailsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            obligation: {}
        };
    }

    componentDidMount() {
        let {obligationId} = this.props;
        graphqlServiceClass.findOneById(obligationId, GraphQLObjectType.OBLIGATION, obligationObject).then((data) => {
            this.setState({obligation: data, loading: false});
        });
    }

    render() {
        let {downloadCompletionDetailsZip} = this;
        const {loading, obligation} = this.state;
        const {
            hidePricingForNonAdminClientUsers,
            onAccept,
            open,
            userType
        } = this.props;

        return (
            <Fragment>
                <AlertDialog
                    open={open}
                    title={'Completion Details'}
                    onCancel={onAccept}
                    cancelText={'Close'}
                    size={DialogSize.SMALL}
                >
                    {loading ? <Loader loaderType={LoaderType.LOGO}/> :
                        <JobCompletionDetailsDialogView
                            hidePricingForNonAdminClientUsers={hidePricingForNonAdminClientUsers}
                            obligation={obligation}
                            userType={userType}
                            onCancel={onAccept}
                            downloadZip={downloadCompletionDetailsZip}
                        />
                    }
                </AlertDialog>
            </Fragment>
        )
    }
}

JobCompletionDetailsDialog.propTypes = {
    hidePricingForNonAdminClientUsers: PropTypes.bool,
    obligationId: PropTypes.number.isRequired,
    onAccept: PropTypes.bool,
    open: PropTypes.bool,
    userType: CustomPropTypes.enum(UserType)
};

export default JobCompletionDetailsDialog;

const obligationObject = `
    id
    arrivalEndTime
    arrivalStartTime
    assessmentOfIssue
    updatedArrivalTime
    clientOnsiteContact
    completedTimestamp
    completionDate
    obligationDate
    leadTechnician
    obligationAttachments { 
        singleUseUrl
        name
    }
    obligationItems {
        lineItemType {
            name
        }
        quantity
        title
        unitRetailPrice
        unitWholesalePrice
    }
    obligationTemplate {
        schedule {
            recurrenceType
        }
    }
    serviceLine {
        name
    }
    requestId
    summaryOfWorkCompleted
    totalRetailPrice
    totalFulfillmentCost
`;