import ServiceLineType from "worksmith/enums/api/psldb/ServiceLineType";

const automationTargetedServiceLines = [
    ServiceLineType.APPLIANCE_REPAIR,
    ServiceLineType.COMMERCIAL_CLEANING,
    ServiceLineType.DOOR_REPAIR,
    ServiceLineType.ELECTRICAL_WORK,
    ServiceLineType.FLOORING_REPAIR,
    ServiceLineType.HANDYMAN,
    ServiceLineType.HVAC,
    ServiceLineType.LIGHTING,
    ServiceLineType.LOCKSMITH,
    ServiceLineType.PAINTING,
    ServiceLineType.PEST_CONTROL,
    ServiceLineType.PLUMBING,
    ServiceLineType.WINDOW_WASHING
];

const automationTargetedServiceLinesRequiringPhotos = [
    ServiceLineType.APPLIANCE_REPAIR,
    ServiceLineType.DOOR_REPAIR,
    ServiceLineType.HANDYMAN,
    ServiceLineType.LIGHTING,
    ServiceLineType.LOCKSMITH
];

export const isAutomationTargeted = (serviceLine, checkingForPhotoRequirement, opts) => {
    if (checkingForPhotoRequirement)
        return automationTargetedServiceLinesRequiringPhotos.includes(serviceLine);
    else
        return automationTargetedServiceLines.includes(serviceLine);
};