import React, {Component} from 'react';
import t from 'tcomb-form';
import Button from "../../Button";
import ObligationService from 'worksmith/services/api/ObligationService';
import ScheduleHandlerService from 'worksmith/services/api/ScheduleHandlerService';
import moment from 'moment';
import notification from 'antd/lib/notification';
import DatePicker from 'antd/lib/date-picker';
import TimePicker from 'antd/lib/time-picker';
import {ScheduleType} from "../../../Enums";

const obligationService = new ObligationService();
const scheduleHandlerService = new ScheduleHandlerService();
const Form = t.form.Form;


class SingleJobChangeRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            obligation: props.obligation,
            loading: false,
            newDate: null,
            startTime: null,
            endTime: null,
            newPickupDate: null,
            newReturnDate: null,
            recurringRequest: !!props.obligation.obligationTemplateId
        };

        this.submit = this.submit.bind(this);
        this.onChangeStartTime = this.onChangeStartTime.bind(this);
        this.onChangeEndTime = this.onChangeEndTime.bind(this);
        this.onChangeNewDate = this.onChangeNewDate.bind(this);
        this.onChangePickupDate = this.onChangePickupDate.bind(this);
        this.onChangeReturnDate = this.onChangeReturnDate.bind(this);
    }

    onChangeStartTime(time) {
        this.setState({ startTime: time });
    }

    onChangeEndTime(time) {
        this.setState({ endTime: time });
    }

    onChangeNewDate(date) {
        this.setState({ newDate: date });
    }

    onChangePickupDate(date){
        this.setState({ newPickupDate : date})
    }
    onChangeReturnDate(date){
        this.setState({ newReturnDate : date})
    }

    submit() {
        let _this = this;
        let {
            obligation,
            recurringRequest,
            newPickupDate,
            newReturnDate,
            newDate,
            endTime,
            startTime
        } = this.state;

        this.setState({loading: true});

        let schedule = getSchedule();

        let submitData = {
            obligation: {
                id: obligation.obligationId ? obligation.obligationId : obligation.id
            },
            scheduleHandler:{
                scheduleOptions: [
                    schedule
                ]

            },
            type: 'time'
        };


        function getSchedule(){
            if(recurringRequest && obligation.serviceLine.id === 1){
                return {
                    date: moment(newPickupDate).format('YYYY-MM-DD'),
                    returnDate: moment(newReturnDate).format('YYYY-MM-DD'),
                    type: ScheduleType.ONE_TIME
                }
            }else if(recurringRequest && obligation.serviceLine.id !== 1){
                return {
                    date: moment(newDate).format('YYYY-MM-DD'),
                    arrivalEndTime: moment(endTime).seconds(0).format("HH:mm:ss"),
                    arrivalStartTime: moment(startTime).seconds(0).format("HH:mm:ss"),
                    type: ScheduleType.ONE_TIME
                }
            } else {
                return {
                    date: moment(newDate).format('YYYY-MM-DD'),
                    arrivalEndTime: moment(endTime).seconds(0).format("HH:mm:ss"),
                    arrivalStartTime: moment(startTime).seconds(0).format("HH:mm:ss"),
                    type: ScheduleType.ONE_TIME
                }
            }
        }

        obligationService.addChangeRequest(obligation.obligationId ? obligation.obligationId : obligation.id, submitData, false, false, false).then(function(data){
            _this.props.onSuccess();
            notification['success']({
                message: 'Request for schedule change added!'
            })
        }).catch(function() {
            alert("Error submitting request for schedule change. Make sure you have entered a new date, a start time, and an end time. Please try again.");
            _this.setState({loading: false});
        });
    }

    render() {
        let {recurringRequest, obligation} = this.state;
        let _this = this;

        function disabledMinutes() {
            let hidden = [];

            for(var i = 0; i < 60; i++ ){
                if(i % 15 !== 0){
                    hidden.push(i);
                }
            }

            return hidden;
        }

        let validateForm = () => {
            let {newDate, newPickupDate, newReturnDate, startTime, endTime} = _this.state;
            if(recurringRequest && obligation.serviceLine.id !== 1){
                if(!newDate || !startTime || !endTime){
                    return true;
                }
            }

            if(recurringRequest && obligation.serviceLine.id === 1){
                if(!newPickupDate || !newReturnDate){
                    return true;
                }
            }
        };


        return (
            <div className={'row'}>
                <div className="col-md-6 offset-md-3 ws-section--info">
                    {recurringRequest ? null :
                        <div>
                            <div className="form-group">
                                <label>New Date</label><br />
                                <DatePicker
                                    size="large"
                                    placeholder={' '}
                                    value={this.state.newDate}
                                    onChange={this.onChangeNewDate} />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label style={{paddingRight: 10}}>Arrival Window Start</label>
                                        <TimePicker
                                            use12Hours
                                            value={this.state.startTime}
                                            placeholder={' '}
                                            size="large"
                                            hideDisabledOptions={true}
                                            disabledMinutes={disabledMinutes}
                                            format="h:mm A"
                                            onChange={this.onChangeStartTime} />
                                    </div>
                                    <label style={{paddingRight: 10}}>Arrival Window End</label>
                                    <TimePicker
                                        use12Hours
                                        value={this.state.endTime}
                                        placeholder={' '}
                                        size="large"
                                        hideDisabledOptions={true}
                                        disabledMinutes={disabledMinutes}
                                        format="h:mm A"
                                        onChange={this.onChangeEndTime} />
                                </div>
                            </div>
                        </div>}

                    {recurringRequest && obligation.serviceLine.id !== 1 ?
                        <div>
                            <div className="form-group">
                                <label>New Date</label><br />
                                <DatePicker
                                    size="large"
                                    placeholder={' '}
                                    value={this.state.newDate}
                                    onChange={this.onChangeNewDate} />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label style={{paddingRight: 10}}>Arrival Window Start</label>
                                        <TimePicker
                                            use12Hours
                                            value={this.state.startTime}
                                            placeholder={' '}
                                            size="large"
                                            hideDisabledOptions={true}
                                            disabledMinutes={disabledMinutes}
                                            format="h:mm A"
                                            onChange={this.onChangeStartTime} />
                                    </div>
                                    <label style={{paddingRight: 10}}>Arrival Window End</label>
                                    <TimePicker
                                        use12Hours
                                        value={this.state.endTime}
                                        placeholder={' '}
                                        size="large"
                                        hideDisabledOptions={true}
                                        disabledMinutes={disabledMinutes}
                                        format="h:mm A"
                                        onChange={this.onChangeEndTime} />
                                </div>
                            </div>
                        </div> : null}

                    {recurringRequest && obligation.serviceLine.id === 1 ?
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>New Pickup/Ship Date</label><br />
                                    <DatePicker
                                        size="large"
                                        placeholder={' '}
                                        value={this.state.newPickupDate}
                                        onChange={this.onChangePickupDate} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label>New Return Date</label><br />
                                <DatePicker
                                    size="large"
                                    placeholder={' '}
                                    value={this.state.newReturnDate}
                                    onChange={this.onChangeReturnDate} />
                            </div>
                        </div> : null }

                    <div className="text-right margin-top-sm">
                        <Button
                            type={'success'}
                            disabled={validateForm() || this.state.loading}
                            message={this.state.loading ? "Submitting..." : "Submit Request For Schedule Change"}
                            onPress={this.submit}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleJobChangeRequestForm;