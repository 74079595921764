import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import ArrivalForm from "./ArrivalForm";
import ArrivalView from "./ArrivalView";

import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {Color} from 'worksmith/enums/Color';
import Accordion from 'worksmith/components/Accordion/Accordion';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import Text, {TextVariant} from "worksmith/components/Text/Text";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientLocationService from "worksmith/services/api/ClientLocationService";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientLocationService = new ClientLocationService();

const ArrivalPanel = ({arrivalInfo, handleCompletePanel, readOnly}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [arrivalFormFields, setArrivalFormFields] = useState({})

    const arrivalInfoComplete = arrivalFormFields.parkingDirections && arrivalFormFields.serviceEntranceDescription

    let formFields = {
        parkingDirections: arrivalFormFields.parkingDirections ? arrivalFormFields.parkingDirections : '',
        serviceEntranceDescription: arrivalFormFields.serviceEntranceDescription ? arrivalFormFields.serviceEntranceDescription : '',
        additionalAccessInfo: arrivalFormFields.additionalAccessInfo ? arrivalFormFields.additionalAccessInfo : ''
    }

    const refetchArrivalData = async () => {
        const arrivalData = await asyncGraphQLServiceClass.findOneById(arrivalInfo.locationId, GraphQLObjectType.CLIENT_LOCATION, arrivalFields);
        setArrivalFormFields({
            parkingDirections: arrivalData.clientLocationOperationDetails.parkingDirections ? arrivalData.clientLocationOperationDetails.parkingDirections : '',
            serviceEntranceDescription: arrivalData.clientLocationOperationDetails.serviceEntranceDescription ? arrivalData.clientLocationOperationDetails.serviceEntranceDescription : '',
            additionalAccessInfo: arrivalData.clientLocationOperationDetails.additionalAccessInfo ? arrivalData.clientLocationOperationDetails.additionalAccessInfo : ''
        })
    }

    const submitArrivalInfo = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let submitData = {};
        submitData.clientLocationOperationDetails = {
            id: arrivalInfo.id,
            locationId: arrivalInfo.locationId,
            parkingDirections: formFields.parkingDirections,
            serviceEntranceDescription: formFields.serviceEntranceDescription,
            additionalAccessInfo: formFields.additionalAccessInfo,
        }
        submitData.id = arrivalInfo.locationId;

        try {
            await clientLocationService.update(submitData);
            await refetchArrivalData();
            setIsEditing(false);
            setIsSubmitting(false);
        } catch (err) {
            console.error(err)
            setIsEditing(false);
            setIsSubmitting(false);
        }
    }

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    }

    const handleChange = (formField, value) => {
        formFields[formField] = value
    }

    useEffect(() => {
        arrivalInfoComplete ? handleCompletePanel('arrival', true) : handleCompletePanel('arrival', false);
    }, [arrivalFormFields])

    useEffect(() => {
        if (arrivalInfo) {
            setArrivalFormFields({
                parkingDirections: arrivalInfo.parkingDirections ? arrivalInfo.parkingDirections : '',
                serviceEntranceDescription: arrivalInfo.serviceEntranceDescription ? arrivalInfo.serviceEntranceDescription : '',
                additionalAccessInfo: arrivalInfo.additionalAccessInfo ? arrivalInfo.additionalAccessInfo : ''
            })
        }
    }, [])


    const panelSummary = (
        <Grid container>
            <Grid container item xs={11} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H6}>
                    {"Arrival Instructions"}
                </Text>
            </Grid>
            <Grid container item xs={1} alignItems={AlignItems.CENTER} justify={JustifyContent.CENTER}>
                {!readOnly ? <Icon name={arrivalInfoComplete ? IconType.CHECK_CIRCLE_OUTLINED : IconType.REPORT_PROBLEM_OUTLINED} iconColor={arrivalInfoComplete ? Color.GREEN : Color.RED} /> : null}
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            summary={panelSummary}
            details={isEditing && !readOnly ?
                <ArrivalForm
                    arrivalFormFields={arrivalFormFields}
                    handleChange={handleChange}
                    isSubmitting={isSubmitting}
                    submitArrivalInfo={submitArrivalInfo}
                    toggleEdit={toggleEdit}
                />
                :
                <ArrivalView
                    arrivalFormFields={arrivalFormFields}
                    readOnly={readOnly}
                    toggleEdit={toggleEdit}
                />

            }
            elevation={0}
            large
            width={'95%'}
        />
    )
};

export default ArrivalPanel;

ArrivalPanel.propTypes = {
    arrivalInfo: PropTypes.shape({
        id: PropTypes.number.isRequired,
        locationId: PropTypes.number.isRequired,
        parkingDirections: PropTypes.string,
        serviceEntranceDescription: PropTypes.string,
        additionalAccessInfo: PropTypes.string
    }),
    handleCompletePanel: PropTypes.func,
    readOnly: PropTypes.bool
}

const arrivalFields = `
    clientLocationOperationDetails {
        parkingDirections
        serviceEntranceDescription
        additionalAccessInfo
    }
`


