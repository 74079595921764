import React, {Component} from 'react';
import * as ReactDOM from "react-dom";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputLabel from "@mui/material/InputLabel";
import MaterialSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import {MultiselectType} from "./Multiselect.web";
import {ThemeConstants} from "../../Theme/ThemeProvider";
import {ValueIsSet} from "../../../helpers/GenericHelpers";
import {Display} from "../../../enums/CSSEnums";
import Chip from "../../Chip/Chip.web";
import CheckboxList from "../../Checkbox/CheckboxList";
import InputAdornment from "@mui/material/InputAdornment";

const ChipsDiv = styled.div`
    display: ${Display.FLEX};
    flex-wrap: wrap;
`;

const SelectedOption = styled(Chip)`
    margin: ${ThemeConstants.spacingUnit / 4}px;
`;

class MultiselectView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelWidth: 0,
        };

        this.inputLabelRef = React.createRef();
    }

    componentDidMount() {
        if (ValueIsSet(this.props.label))
            this.setState({labelWidth: ReactDOM.findDOMNode(this.inputLabelRef.current).offsetWidth});
    }

    getValueLabel = (value) => {
        let {options} = this.props;

        let label = null;

        options.forEach((option) => {
            if (label === null && option.value === value) {
                label = option.label;
            }
        });

        return label;
    };

    renderValue = (value) => {
        let {getValueLabel} = this;
        let {onDelete, size, showDeleteChipIcon} = this.props;

        return (
            <ChipsDiv>
                {value.map((item) => (
                    <SelectedOption
                        key={item}
                        label={getValueLabel(item)}
                        onDelete={showDeleteChipIcon ? (e) => onDelete(e, item) : null}
                        size={size}
                    />
                ))}
            </ChipsDiv>
        )
    };

    render() {
        let {renderValue, inputLabelRef} = this;
        let {labelWidth} = this.state;
        let {
            radioStyle,
            disabled,
            includeSelectAll,
            selectAllLabel,
            selectAll,
            startAdornment,
            startAdornmentProps,
            error,
            fullWidth,
            groupsValue,
            helperText,
            label,
            name,
            nameless,
            onCheckboxChange,
            onSelectChange,
            options,
            placeholder,
            size,
            type,
            variant,
            value
        } = this.props;

        if (type === MultiselectType.DROPDOWN) {
            return (
                <FormControl disabled={disabled}
                             error={error}
                             fullWidth={fullWidth}
                             style={{minWidth: '197px'}}
                             size={size}
                             variant={variant}>
                    {ValueIsSet(label) ?
                        <InputLabel ref={inputLabelRef}>{label}</InputLabel>
                        :
                        null
                    }
                    <MaterialSelect
                                    startAdornment={
                                        ValueIsSet(startAdornment) ? (
                                            <InputAdornment position={'start'} {...startAdornmentProps}>
                                                {startAdornment}
                                            </InputAdornment>
                                        ) : null
                                    }
                                    labelWidth={labelWidth}
                                    name={nameless ? undefined : ValueIsSet(name) ? name : label}
                                    multiple
                                    onChange={onSelectChange}
                                    placeholder={placeholder}
                                    renderValue={renderValue}
                                    value={value}
                                    variant={variant}>
                        {options.map((option) =>
                            <MenuItem value={option.value}>
                                {option.label}
                            </MenuItem>
                        )}
                    </MaterialSelect>
                    {ValueIsSet(helperText) ?
                        <FormHelperText>{helperText}</FormHelperText>
                        :
                        null
                    }
                </FormControl>
            )
        } else if (type === MultiselectType.CHECKBOX) {
            return (
                <CheckboxList
                        radioStyle={radioStyle}
                        includeSelectAll={includeSelectAll}
                        onChange={onCheckboxChange}
                        options={options}
                        selectAll={selectAll}
                        selectAllLabel={selectAllLabel}
                        selectedGroups={groupsValue}
                        selectedOptions={value}/>
            )
        }
    }
}

MultiselectView.propTypes = {
    radioStyle: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    includeSelectAll: PropTypes.bool,                       // This prop only works on the CheckboxType
    selectAll: PropTypes.func,                              // This prop only works on the CheckboxType
    selectAllLabel: PropTypes.string,                       // This prop only works on the CheckboxType
    fullWidth: PropTypes.bool,
    groupsValue: PropTypes.arrayOf(PropTypes.string),
    helperText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    nameless: PropTypes.bool,
    onSelectChange: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onDelete: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    placeholder: PropTypes.string,
    startAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    showDeleteChipIcon: PropTypes.bool,
    size: PropTypes.string,
    // type: CustomPropTypes.enum(MultiselectType),
    // variant: CustomPropTypes.enum(MultiselectVariant),
    value: PropTypes.any,
};

export default MultiselectView;
