export const MomentFormat = Object.freeze({
    MonthDayYear: 'MM-DD-YYYY',                             //EX: 12-25-2018
    MonthDayYearSlash: 'MM/DD/YYYY',                        //EX: 05/06/2018
    DayMonthYearSlash: 'DD/MM/YYYY',                        //EX: 05/06/2018
    MonthDayYearNoZeroSlash: 'M/D/YY',                      //EX: 4/6/18
    MonthDayYearTimeSlash: 'MM/DD/YYYY HH:mm A',            //EX: 12/25/2018 12:47 PM
    MonthDayYearTimeSlashWithAt: 'MM/DD/YYYY [at] h:mm A',  //EX: 12/25/2018 at 1:32 PM
    MonthDayYearStandardTimeSlash: 'MM/DD/YYYY h:mm A',     //EX: 12/25/2018 1:32 PM
    ISODate: 'YYYY-MM-DD',                                  //EX: 2018-01-11
    MonthDay: 'MMM Do',                                     //EX: Sep 19th
    MonthDaySlash: 'MM/DD',                                 //EX: 09/19
    Dom: 'Do',                                              //EX: 21st
    FullDow: 'dddd',                                        //EX: Thursday
    DowMonthDayYear: 'ddd, MMM D YYYY',                     //EX: Thu, May 1 2018
    DowMonthDom: 'ddd, MMM Do',                             //EX: Thu, Nov 1st
    DowMonthDay: 'ddd, MMM D',                              //EX: Thu, Nov 1
    DowMonthDayYearSlash: 'ddd MM/DD/YY',                   //EX: Thu 02/01/2020
    FullDowMonthDayYearSlash: 'dddd MM/DD/YY',              //EX: Thursday 02/01/2020
    FullDowFullMonthDom: 'dddd, MMMM Do',                   //EX: Thursday, November 1st
    DateTime: 'MM/DD/YY h:mmA',                             //EX: 09/18/18 12:47PM
    DateTimeLeadingZero: 'MM/DD/YYYY hh:mm A',              //EX: 09/18/2018 05:47 PM
    DateAtTime: 'MM/DD/YY [at] h:mmA',                      //EX: 09/18/18 at 12:47PM
    FullDayFullMonthDomYear: 'dddd, MMMM Do YYYY',          //EX: Friday, December 28th 2018
    FullDayFullMonthDomYearComma: 'dddd, MMMM D, YYYY',     //EX: Friday, December 28, 2018
    FullMonthDomYear: 'MMMM Do, YYYY',                      //EX: December 28th, 2018
    FullDateTime: 'MMMM Do YYYY h:mm a',                    //EX: December 28th 2018 3:46 pm
    AbvFullDateTime: 'MMM Do, YYYY h:mm a',                 //EX: Dec 28th, 2018 3:46 pm
    MonthDomYear: 'MMM D, YYYY',                            //EX: Jan 1, 2019
    FullMonthYear: 'MMMM YYYY',                             //EX: January 2019
    FullMonthYearWithoutSpace: 'MMMMYYYY',                  //EX: January2019
    FullMonth: 'MMMM',                                      //EX: January
    LocalDateTime: 'YYYY-MM-DDTHH:mm:ss',                   //EX: 2022-03-31T00:30:00 (can be used when backend needs a LocalDateTime)
    TimeLeadingZeroDayMonth: 'hh:mm A MM/DD',               //EX: 05:47 PM 09/18
    HoursMinutesSeconds: 'HH:mm:ss',                        //EX: 14:23:14 (can be used when backend needs a LocalTime)
    HoursMinutes: 'HH:mm:00',                               //EX: 14:23:00
    LeadingZeroStandardTime: 'hh:mm A',                     //EX: 01:32 PM
    StandardTime: 'h:mm A',                                 //EX: 1:32 PM
    MilitaryTime: 'HH:mm',                                  //EX: 09:30
    Time: 'LT',
    TimeDate: 'hh:mm A MM/DD'                               //EX: 12/25/2018 12:47 PM
});

export const CalendarFormat = Object.freeze({
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: 'dddd, MMM Do'
});
