import NewRequestStatus from "../enums/api/proposal/NewRequestStatus";
import RfpType from "../enums/api/proposal/RfpType";
import RequestDisplayType from "../enums/RequestDisplayType";


export const DisplayStatusFromNewStatus = (newStatus) => {
    let status;

    if (newStatus === NewRequestStatus.MARKET) {
        status = "contacting vendors";
    } else {
        status = newStatus;
    }

    return status;
};


export const DisplayTypeFromTypeAndEmergency = (type, emergency) => {
    let displayType;

    switch(type) {
        case RfpType.ON_DEMAND:
            displayType = RequestDisplayType.ONE_TIME;
            break;
        case RfpType.PRICING_ONLY:
            displayType = RequestDisplayType.PRICING_ONLY;
            break;
        case RfpType.RECURRING:
            displayType = RequestDisplayType.RECURRING;
            break;
        default:
            displayType = type;
            break;
    }

    if (emergency) {
        displayType = RequestDisplayType.EMERGENCY;
    }

    return displayType;
};
