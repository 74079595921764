import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import Text, {TextVariant} from "../../components/Text/Text.web";
import Grid from "../../components/Grid/Grid.web";
import {Display, JustifyContent} from "../../enums/CSSEnums";
import Box from "@mui/material/Box";
const graphQLService = new AsyncGraphQLServiceClass();

const VendorPhoneNumbers = (props) => {
    const {vendorId} = props;
    const [vendorUsers, setVendorUsers] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
            const getUsers = async () => {
                if (vendorId) {
                    let userSearchParams = {
                        vendorIds: [vendorId]
                    }

                    const users = await graphQLService.findAll(userSearchParams,
                        GraphQLObjectType.USER,
                        UserGraphQLString);

                    let vendorUsers = ValueIsSet(users)  ?
                        users.filter((user) => (!ValueIsSet(user.email) || !user.email.includes("@worksmith")) && ValueIsSet(user.phone))
                        :
                        [];

                    setVendorUsers(vendorUsers);
                } else {
                    setVendorUsers([]);
                }
                setLoading(false);
            }

            getUsers();
        }, []);


    return (
        loading ?
            <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                <CircularProgress/>
            </Box>
            :
            <Grid container spacing={2}>
                {vendorUsers.length > 0 ?
                    vendorUsers.map(user => (
                        <Grid key={user.id} item xs={12}>
                            <Text bold variant={TextVariant.BODY_1}>{user.name}</Text>
                            <Text variant={TextVariant.BODY_1}>{user.phone}</Text>
                        </Grid>
                    ))
                    :
                    <Text variant={TextVariant.BODY_1}>There is no contact information available for this vendor, please use the chat to contact them.</Text>
                }
            </Grid>
    )
};

const UserGraphQLString = `
        id
        name 
        phone
        email 
`;

VendorPhoneNumbers.propTypes = {
    vendorId: PropTypes.number.isRequired
};

export default VendorPhoneNumbers;