import {Color} from "../enums/Color";
import { useTheme } from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import BreakpointEnum from "worksmith/enums/BreakpointEnum";

export const HexToRGB = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16));

export const RGBAfterOpacity = (r, g, b, o) => {
    let newColors = [];

    newColors.push(255 - o * (255 - r));
    newColors.push(255 - o * (255 - g));
    newColors.push(255 - o * (255 - b));

    return newColors;
};

export const RGBWithOpacity = (r, g, b, o) => {
    return "rgb(" + r + "," + g + "," + b + "," + o + ")";
};

export const getRGBFromHex = (hex, opacity) => {
    return RGBWithOpacity(...HexToRGB(hex), opacity);
};

export const ShouldTextBeBlack = (r, g, b) => (r * 0.299 + g * 0.587 + b * 0.114) > 186;

export const GetTextColorFromBackgroundColor = (backgroundHex) => {
    return ShouldTextBeBlack(...HexToRGB(backgroundHex)) ? Color.BLACK : Color.WHITE;
};

export const useDeviceSize = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointEnum.SM));
    const isTablet = useMediaQuery(theme.breakpoints.down(BreakpointEnum.MD));
    const isDesktop = useMediaQuery(theme.breakpoints.up(BreakpointEnum.LG));

    return [isMobile, isTablet, isDesktop];
};

export const setVhNotIncludingUrlBar = () => { // using 100vh with standard css vh will result in part of the page
    // being covered by the url bar on mobile browser.
    // The --vhNotIncludingUrlBar custom css property can be used instead to fix this issue.
    document.documentElement.style.setProperty('--vhNotIncludingUrlBar', `${window.innerHeight * 0.01}px`);

    // Make the css property update when the screen height is updated.
    window.addEventListener('resize', () => {
        document.documentElement.style.setProperty('--vhNotIncludingUrlBar', `${window.innerHeight * 0.01}px`);
    });
}
