import React, {useState} from 'react';
import PropTypes from "prop-types";

import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import Divider from "worksmith/components/Divider/Divider";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import TextField, {TextFieldSize} from "worksmith/components/Inputs/TextField/TextField";
import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {alphabetically, Debounce, StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {IconType} from "worksmith/enums/MaterialEnums";


const HugoBossAlterationsStandardItemsEdit = ({
                                                  alterations,
                                                  category,
                                                  handleCancel,
                                                  handleSave,
                                                  saving,
                                                  setAlterations,
                                                  setCategory,
                                              }) => {

    const [addingItem, setAddingItem] = useState(false);


    const handleUpdateCategory = (value) => {
        if (StringHasText(value)) {
            setAlterations(prevState => {
                return prevState.map(alteration => {
                    return {...alteration, category: value, changed: true, error: null}
                })
            });
            setCategory({name: value, error: null})
        } else {
            setCategory({name: value, error: "*Input required"})
        }
    };

    const handleUpdateAlteration = (name, id) => {
        setAlterations(prevState => {
             return prevState.map(alteration => {
                if (alteration.id === id) {
                    alteration.changed = true;
                    // Check if inputs values contain text. If not add error field to item.
                    if (StringHasText(name)){
                        alteration.name = name;
                        alteration.error = null;
                        return alteration
                    } else {
                        alteration.name = name;
                        alteration.error = "*Input required";
                        return alteration
                    }
                } else return alteration;
            })
        });
    };

    const handleAddNewAlteration = () => {
        setAddingItem(true);
        setAlterations([...alterations, {id: 'newAlteration' + alterations.length, number: alterations[0].number, category: category.name, name: null}])
    };


    const checkIfAllFieldsAreValidAndSave = (alterations) => {
        let isValid = true;

        let errorAlterations = alterations.map(item => {
            if (!StringHasText(item.category)) {
                isValid = false;
                setCategory(prevState => ({...prevState, error: "*Input required"}))
            }
            if (!StringHasText(item.name)) {
                isValid = false;
                return {...item, error: "*Input required"};
            } else {
                return {...item};
            }
        });

        if (isValid) {
            handleSave(alterations);
        } else {
            setAlterations(errorAlterations);
        }
    };


    return (alterations &&
        <Grid container justify={JustifyContent.FLEX_START} alignItems={AlignItems.FLEX_START} padding={'8px 0 0 8px'}>
            <Grid item xs={12} margin={'0 0 20px 0'}>
                <TextField
                    label={'Garment name'}
                    initialValue={category.name === 'NewCategory' ? '' : category.name}
                    size={TextFieldSize.SMALL}
                    onChange={Debounce((name) => handleUpdateCategory(name), 400)}
                    error={ValueIsSet(category.error)}
                    helperText={category.error}
                />
            </Grid>
            {alterations.filter(item => item.name !== "Multiple Alterations").sort(alphabetically(true)).map(item => {
                return (
                    <Grid item xs={3} key={item.id} margin={'0 0 20px 0'}>
                        <TextField
                            label={'Alteration'}
                            initialValue={item.name}
                            size={TextFieldSize.SMALL}
                            onChange={Debounce((name) => handleUpdateAlteration(name, item.id), 400)}
                            error={ValueIsSet(item.error)}
                            helperText={item.error}
                        />
                    </Grid>
                )
            })}
            {!addingItem &&
                <Grid item xs={3} padding={'11px 0 20px 8px'}>
                    <Button
                        onClick={() => handleAddNewAlteration()}
                        variant={ButtonVariant.OUTLINED}
                        startIcon={<Icon name={IconType.ADD}/>}
                        primary
                    >
                        Add Alteration
                    </Button>
                </Grid>
            }
            <Grid container item xs={12} padding={0} margin={0}>
                <Grid item xs={2}>
                    <Button
                        onClick={() => handleCancel(alterations[0])}
                        variant={ButtonVariant.CONTAINED}
                        fullWidth
                    >
                        Cancel
                    </Button>

                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={() => checkIfAllFieldsAreValidAndSave(alterations)}
                        variant={ButtonVariant.CONTAINED}
                        primary
                        fullWidth
                        disabled={ValueIsSet(category.error) || alterations.some(item => ValueIsSet(item.error))}
                    >
                        {saving ? <CircularProgress size={24}/> : 'Save'}
                    </Button>
                </Grid>
                <Grid item xs={12} margin={'10px 0 20px 0'}>
                    <Divider/>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default HugoBossAlterationsStandardItemsEdit;

HugoBossAlterationsStandardItemsEdit.propTypes = {
    initialAlterations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
        category: PropTypes.string,
        name: PropTypes.string,
    })),
    initialCategory: PropTypes.string,
    handleCancel: PropTypes.func,
    handleSave: PropTypes.func,
    saving: PropTypes.bool,
    setAlterations: PropTypes.func,
    setCategory: PropTypes.func,
};