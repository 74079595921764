import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

class GraphQLStatisticsService extends Http {
    constructor(){
        super('/graphql')
    }

    /**
     * @param statisticsParams      nonnull     filter requests before reporting statistics
     * @param objectType            nonnull     One of GraphQLObjectType enum
     * @param requestedFields       nonnull     String: space separated name of fields you want returned on the objects
     * @returns Promise(Object)     nullable    Object of the type requested, null if it couldn't be found or user didn't have permissions to view
     */
    findStatistics(statisticsParams, objectType, requestedFields) {
        return this.callApi('',
            RequestMethod.POST,
            {
                variables: {
                    statisticsParams: statisticsParams
                },
                query: 'query ($statisticsParams: ' + objectType.searchParamName + ') { ' + objectType.queryName + '(statisticsParams: $statisticsParams) { ' + requestedFields + ' } }'
            },
            null,
            null,
            true
        ).then((data) => {
            return data.data[objectType.queryName];
        });
    }
}

export default GraphQLStatisticsService;