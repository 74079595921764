import React from 'react';
import PropTypes from "prop-types";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import Text from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Link from "worksmith/components/Link/Link";
import {Color} from "worksmith/enums/Color";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const DuplicateRequestModal = (props) => {
    const {
        duplicateRequests,
        linkString,
        onClose,
        open
    } = props;

    let title = "Possible Duplicate Request";
    let acceptText = "OK";

    let duplicateTexts =    duplicateRequests.map( request => {
                                let linkId;
                                ValueIsSet(request.assignment) ? linkId = request.assignment.id : linkId = request.id;
                                if (request.type === RfpType.ON_DEMAND && !request.emergency){
                                    return (<Text>
                                        <Link color={Color.BLACK} onClick={() => window.open('#/' + linkString + linkId)}>{request.id}</Link>
                                        {' - ONE TIME - ' + request.description}
                                    </Text>);
                                }
                                else if(request.type === RfpType.ON_DEMAND && request.emergency){
                                    return (<Text>
                                        <Link color={Color.BLACK} onClick={() => window.open('#/' + linkString + linkId)}>{request.id}</Link>
                                        {' - EMERGENCY - ' + request.description}
                                    </Text>);
                                }
                                else if(request.type === RfpType.RECURRING){
                                    return (<Text>
                                        <Link color={Color.BLACK} onClick={() => window.open('#/' + linkString + linkId)}>{request.id}</Link>
                                        {' - RECURRING - ' + request.description}
                                    </Text>);
                                }
                            });

    let children =      <Grid>
                            <Text>There are already open requests in this service line at this location:{'\n\n'}</Text>
                            {duplicateTexts.map(request => request)}
                            <Text>{'\n'}Please only submit if this is a NEW request.</Text>
                        </Grid>

    let customButton =      [<Button
                                primary
                                onClick={onClose}
                                debounce={true}
                                variant={ButtonVariant.TEXT}>
                                {acceptText}
                            </Button>];

    return(
        <AlertDialog
            children={children}
            customButtons={customButton}
            fullWidth={true}
            open={open}
            title={title}>
        </AlertDialog>
    )
};

DuplicateRequestModal.propTypes = {
    duplicateRequests: PropTypes.arrayOf(PropTypes.element),
    linkString: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

export default DuplicateRequestModal;