import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Menu from "../Menu/Menu.web";
import {HorizontalOrigin, VerticalOrigin} from "../Popover/Popover.web";

class MenuButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorElement: null
        }

        this.buttonRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({anchorElement: this.buttonRef.current});
    }

    render() {
        const {
            anchorElement
        } = this.state;

        const {
            Button,
            onClose,
            open
        } = this.props;

        return (
            <Fragment>
                {React.cloneElement(Button, {ref: this.buttonRef})}
                <Menu open={open && !!anchorElement}
                      anchorOrigin={{
                          horizontal: HorizontalOrigin.RIGHT,
                          vertical: VerticalOrigin.BOTTOM
                      }}
                      transformOrigin={{
                          horizontal: HorizontalOrigin.RIGHT,
                          vertical: VerticalOrigin.TOP
                      }}
                      onClose={onClose}
                      anchorElement={anchorElement}
                        >
                    {this.props.children}
                </Menu>
            </Fragment>
        )
    }
};

MenuButton.propTypes = {
    Button: PropTypes.element.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default MenuButton;