import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'

import ProfileSkeleton from "worksmith/composite-components/ClientLocationProfile/ProfileSkeleton";
import AccessInfoPanel from "worksmith/composite-components/ClientLocationProfile/accessInfo/AccessInfoPanel";
import GeneralInfoPanel from "worksmith/composite-components/ClientLocationProfile/generalInfo/GeneralInfoPanel";
import LocationSpecificationsPanel
    from "worksmith/composite-components/ClientLocationProfile/locationSpecifications/LocationSpecificationsPanel";
import MediaLibraryPanel from "worksmith/composite-components/ClientLocationProfile/mediaLibrary/MediaLibraryPanel";
import UsersPanel from "worksmith/composite-components/ClientLocationProfile/users/UsersPanel";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientService from 'worksmith/services/api/ClientService';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Tabs from "worksmith/components/Tab/Tabs";
import StandardPricingPage
    from "worksmith/composite-components/ClientLocationProfile/standardPricing/StandardPricingPage";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";


const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientService = new ClientService();

const ProfileHeader = styled.section`
    h1 {
        padding: 48px 0;
    }
    
`;

const ClientLocationProfilePage = ({clientId, locationId, userIsAdmin, clientDashboardType, isSelfService}) => {

    const [location, setLocation] = useState(null);
    const [clientNTEData, setClientNTEData] = useState(null);
    const [mallsList, setMallsList] = useState([{name: '+ Add New Mall', value: '+ Add New Mall'}]);
    const [loading, setLoading] = useState(true);

    const getClientNTE = () => {
        return clientService.getNTEConfigs(clientId);
    };

    const fetchLocationData = () => {
        return asyncGraphQLServiceClass.findOneById(locationId, GraphQLObjectType.CLIENT_LOCATION, locationFields);
    };

    const fetchMallData = () => {
        return asyncGraphQLServiceClass.findAll({}, GraphQLObjectType.MALL, mallFields, 'name');
    };

    useEffect(() => {
        let NTEPromise = getClientNTE();
        let locationPromise = fetchLocationData();
        let mallPromise = fetchMallData();

        const fetchInitialData = async () => {
            const [NTEData, locationData, mallData] = await Promise.all([NTEPromise, locationPromise, mallPromise]);
            setLocation(locationData);
            setClientNTEData(NTEData);
            setMallsList([
                ...mallsList,
                ...mallData
            ]);
            setLoading(false)
        };

        fetchInitialData()
    }, []);

    const tabs = [
        'Profile'
    ];

    const contents = [
        <>
            <GeneralInfoPanel readOnly={!userIsAdmin} location={location} mallsList={mallsList}/>
            <AccessInfoPanel location={location} />
            <LocationSpecificationsPanel location={location} />
            <MediaLibraryPanel location={location} />
            <UsersPanel location={location} clientNTEs={clientNTEData} />
        </>
    ];

    if (isSelfService) {
        tabs.push('Standard Pricing');
        contents.push(
            <StandardPricingPage
                clientId={clientId}
                locationId={locationId}
                currency={ValueIsSet(location) ? location.currency : null}
                userIsAdmin={userIsAdmin}
                clientDashboardType={clientDashboardType}
            />
        );
    }


        if (!loading && location) {
            return (
                <div>
                    <ProfileHeader>
                        <h1>{location.title} Location Profile</h1>
                    </ProfileHeader>
                    <Tabs titleList={tabs}>
                        {contents}
                    </Tabs>
                </div>
            );
        } else {
            return <ProfileSkeleton />
        }
};

export default ClientLocationProfilePage;

ClientLocationProfilePage.propTypes = {
    clientId: PropTypes.number,
    locationId: PropTypes.string
};

const mallFields = `
    id
    name
`;

const locationFields = `
    addressLineOne
    addressLineTwo
    city
    country
    countryCode
    currency
    client {
        adminUsers {
            clientRole
            companyName
            displayName
            email
            phone
            clientLocationRoles {
                clientLocation {
                    locationCode
                    client {
                        officialName
                    }
                }
            }
        }
        id
        isSelfService
    }
    clientLocationAttachments {
        id
        singleUseUrl
        title
    }
    clientLocationFloors {
        id
        locationId
        level
        title
        description
        ceilingHeightFt
        bathroomsCount
        kitchensCount
        fittingRoomsCount
        deleted
    }
    email
    industry
    otherIndustry
    isShopInShop
    mall {
        id
        name
        phone
        propertyOwner
        mallDirectory
        requirements
        coi
        address
    }
    isInMall
    openingDate
    regionalManagers {
        clientRole
        companyName
        displayName
        email
        phone
    }
    locationManagers {
        clientRole
        companyName
        displayName
        email
        phone
    }
    clientLocationOperationDetails {
        id
        locationId
        unionLaborRequirement
        occurrenceInsuranceRequirement
        aggregateInsuranceRequirement
        additionalInsuranceDetails
        locationSquareFootage
        hasLadderOnSite
        ladderHeightFt
        parkingDirections
        serviceEntranceDescription
        additionalAccessInfo
        vendorNotes
    }
    locationHours {
        id
        dayOfWeekInteger
        openTime
        closeTime
        closed
    }
    id
    locationCode
    phone
    state
    title
    zip
`;

ClientLocationProfilePage.propTypes = {

};