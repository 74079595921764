import React, {useState} from 'react';
import PropTypes from 'prop-types';

import ActionableModalTemplate from 'worksmith/components/Modal/Templates/ActionableModalTemplate';
import Button, {ButtonVariant} from 'worksmith/components/Button/Button';
import Modal from 'worksmith/components/Modal/Modal';
import Grid from "worksmith/components/Grid/Grid";
import {AlignItems} from "worksmith/enums/CSSEnums";
import Checkbox from "worksmith/components/Checkbox/Checkbox";


const MuteNotificationsModal = ({
                                    handleSubmit,
                                    hideMuteClientNotificationCheckbox,
                                    hideMuteVendorNotificationCheckbox,
                                    showModal,
                                    showMuteTechnicianNotificationCheckbox,
                                    subtitle,
}) => {

    const [muteVendorNotifications, setMuteVendorNotifications] = useState(false);
    const [muteClientNotifications, setMuteClientNotifications] = useState(false);
    const [muteTechnicianNotifications, setMuteTechnicianNotifications] = useState(false);


    return (
        <Modal open={showModal}>
            <ActionableModalTemplate
                buttons={[
                    <Button variant={ButtonVariant.CONTAINED} primary onClick={() => handleSubmit(muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications)}>Submit</Button>
                ]}
                body={
                    <Grid container item xs={12} alignItems={AlignItems.FLEX_START}>
                        {!hideMuteClientNotificationCheckbox &&
                            <Grid item xs={12}>
                                <Checkbox
                                    label={'Mute Client Notifications'}
                                    onChange={value => setMuteClientNotifications(value)}
                                    primary
                                />
                            </Grid>
                        }
                        {!hideMuteVendorNotificationCheckbox &&
                            <Grid item xs={12}>
                                <Checkbox
                                    label={'Mute Vendor Notifications'}
                                    onChange={value => setMuteVendorNotifications(value)}
                                    primary
                                />
                            </Grid>
                        }
                        {showMuteTechnicianNotificationCheckbox &&
                            <Grid item xs={12}>
                                <Checkbox
                                    label={'Mute Technician Notifications'}
                                    onChange={value => setMuteTechnicianNotifications(value)}
                                    primary
                                />
                            </Grid>
                        }
                    </Grid>
                }
                title={'Mute notifications?'}
                subtitle={subtitle ? subtitle : 'The client and vendor will receive a notification (unless their notification settings are turned off). Check the boxes below if you do not want to send a notification.'}
                width={'700px'}
            />
        </Modal>
    );
};

MuteNotificationsModal.propTypes = {
    handleSubmit: PropTypes.func,
    hideMuteClientNotificationCheckbox: PropTypes.bool,
    hideMuteVendorNotificationCheckbox: PropTypes.bool,
    showModal: PropTypes.bool,
    showMuteTechnicianNotificationCheckbox: PropTypes.bool,
    subtitle: PropTypes.string,
};

export default MuteNotificationsModal;