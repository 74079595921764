import React from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {Display, FlexDirection} from "../../enums/CSSEnums";
import Grid from "../../components/Grid/Grid.web";
import FieldTitleAndBody from "../FieldTitleAndBody/FieldTitleAndBody";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import Schedule from "../../components/Schedule/Schedule.web";
import PriceBreakdown from "../PriceBreakdown/PriceBreakdown";
import {TextVariant} from "../../components/Text/Text.web";
import Box from "@mui/material/Box";
import ProposalHeader from "../ProposalHeader/ProposalHeader";
import ObligationTemplateDetailsSkeleton
    from "worksmith/composite-components/ObligationTemplateDetails/ObilgationTemplateDetailsSkeleton";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {WithContext} from "../../../context/GlobalContext";

const ObligationTemplateDetailsView = (props) => {
    const {
        context,
        hasPreferredVendor,
        itemTemplates,
        loading,
        schedule,
        totalRetailPrice,
        vendorLocation
    } = props;

    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;

    if (loading) {
        return(
            <ObligationTemplateDetailsSkeleton/>
        )
    }

    return (
        <Box display={Display.FLEX} flexDirection={FlexDirection.COLUMN}>
            <Box display={Display.FLEX} mb={4}>
                <ProposalHeader
                    hasPreferredVendor={hasPreferredVendor}
                    isObligation
                    totalRetailPrice={totalRetailPrice}
                    vendorLocation={vendorLocation}/>
            </Box>
            <Grid
                direction={FlexDirection.COLUMN}
                container
                spacing={4}
            >
                <FieldTitleAndBody
                    item
                    title={'Service Date'}
                    body={
                        <Schedule loading={loading} schedule={schedule} textVariant={TextVariant.BODY_1}/>
                    }
                />
                {!hidePricingForNonAdminUsers &&
                    <FieldTitleAndBody
                        item
                        title={'Price Breakdown'}
                        body={
                            <PriceBreakdown
                                loading={loading}
                                items={itemTemplates}
                                activityTotal={totalRetailPrice}/>
                        }
                    />
                }
            </Grid>
        </Box>
    )
};

ObligationTemplateDetailsView.propTypes = {
    loading: PropTypes.bool,
    hasPreferredVendor: PropTypes.bool,
    itemTemplates: PropTypes.arrayOf(PropTypes.shape({
        lineItemType: PropTypes.shape({
            name: PropTypes.string
        }),
        quantity: PropTypes.number,
        title: PropTypes.string,
        unitRetailPrice: PropTypes.number
    })),
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    totalRetailPrice: PropTypes.number,
    vendorLocation: PropTypes.shape({
        rating: PropTypes.number,
        ratingsCount: PropTypes.number,
        vendor: PropTypes.shape({
            officialName: PropTypes.string,
            qualifiesForPremiumBadge: PropTypes.bool,
            vendorBio: PropTypes.shape({
                aboutUs: PropTypes.string
            })
        })
    }).isRequired,
};

export default WithContext(ObligationTemplateDetailsView);