import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {merge} from "lodash";

import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import RequestStateView from "./RequestStateView";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import NewRequestStatus from "worksmith/enums/api/proposal/NewRequestStatus";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import ClientLocationRole from "worksmith/enums/api/user/ClientLocationRole";

const graphQLService = new GraphQLServiceClass();

class RequestState extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partialRequestLoading: ValueIsSet(props.partialRequestLoading) ? props.partialRequestLoading : false,
            fullRequestLoading: ValueIsSet(props.fullRequestLoading) ? props.fullRequestLoading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null
        }
    }

    componentDidMount = () => {
        const {request} = this.state;
        const {requestId} = this.props;

        if (!ValueIsSet(request) && requestId) {
            this.getRequestFromId(requestId);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.getRequestFromId(this.props.requestId);
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        newState.fullRequestLoading = props.fullRequestLoading || state.fullRequestLoading;

        if (props.request && !props.fullRequestLoading) {
            newState.request = DeepCopy(props.request);
            newState.fullRequestLoading = false;
            newState.partialRequestLoading = props.partialRequestLoading;
        }


        return newState;
    };

    getRequestFromId = (requestId) => {
        if (!this.state.fullRequestLoading) {
            this.setState({fullRequestLoading: true});
        }

        graphQLService.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            RequestStateFullGraphQLObject
        ).then((data) => {
            this.setState({fullRequestLoading: false, request: data});
        })
    };

    reloadFullRequest = () => {
        const {reloadFullRequest, requestId} = this.props;

        if (reloadFullRequest) {
            reloadFullRequest();
        } else {
           this.getRequestFromId(requestId);
        }
    };

    reloadPartialRequest = async () => {
        const {reloadPartialRequest, requestId, request} = this.props;

        if (reloadPartialRequest) {
            reloadPartialRequest();
        } else {
            this.setState({partialRequestLoading: true});
            let data = graphQLService.findOneById(
                requestId,
                GraphQLObjectType.REQUEST,
                RequestStatePartialGraphQLObject)
                .then(() => {
                    this.setState({request: merge(request, data), partialRequestLoading: false});
                })
        }
    };

    render() {
        const {reloadFullRequest, reloadPartialRequest} = this;
        const {chatRef, clientSettings, startLoad, user} = this.props;
        const {fullRequestLoading, partialRequestLoading, request} = this.state;

        return (
            <RequestStateView
                chatRef={chatRef}
                clientSettings={clientSettings}
                fullRequestLoading={fullRequestLoading}
                partialRequestLoading={partialRequestLoading}
                reloadFullRequest={reloadFullRequest}
                reloadPartialRequest={reloadPartialRequest}
                request={request}
                startLoad={startLoad}
                user={user}/>
        )
    }
}

RequestState.defaultProps = {
    isSingleLocationUser: false,
    loading: false,
    request: null
};

// Pass in a full request or the requestId so that the component can fetch the correct data
RequestState.propTypes = {
    chatRef: PropTypes.object,
    clientSettings: PropTypes.object,
    fullRequestLoading: PropTypes.bool,
    partialRequestLoading: PropTypes.bool,
    reloadFullRequest: PropTypes.func.isRequired,
    reloadPartialRequest: PropTypes.func.isRequired,
    request: PropTypes.shape({
        clientLocation: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string
        }),
        emergency: PropTypes.bool,
        newStatus: CustomPropTypes.enum(NewRequestStatus),
        serviceLine: PropTypes.shape({
            name: PropTypes.string
        }),
        type: CustomPropTypes.enum(RfpType)
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func,
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
        clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
            clientLocation: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: CustomPropTypes.enum(ClientLocationRole),
        })),
    }).isRequired,
};

export const RequestStatePartialGraphQLObject = `
    commentCount
    hasPendingChangeRequest
    newStatus
`;

export const RequestStateFullGraphQLObject = `
    id
    clientLocation {
        id
        title
    }
    emergency
    hasPendingChangeRequest
    newStatus
    serviceLine {
        id
        name
    }
    type
`;

export default RequestState;