import React from 'react';
import Skeleton from '@mui/lab/Skeleton'

import Accordion from "../../components/Accordion/Accordion";
import Grid from "../../components/Grid/Grid";


const panelSummary = (
    <Grid container>
        <Grid container item xs={2} md={1} alignItems={'center'}>
            <Skeleton animation={'wave'} variant="circular" width={30} height={30}/>
        </Grid>
        <Grid container item xs={9} md={11} alignItems={'center'}>
            <Skeleton animation={'wave'} variant={'text'} width={250} />
        </Grid>
    </Grid>
);

const ProfileSkeleton = ({readOnly}) => (
    <>
            <Accordion
                animation={'wave'}
                summary={panelSummary}
                large
                expandIcon={null}
            />
            <Accordion
                animation={'wave'}
                summary={panelSummary}
                large
                expandIcon={null}
            />
            <Accordion
                animation={'wave'}
                summary={panelSummary}
                large
                expandIcon={null}
            />
            <Accordion
                animation={'wave'}
                summary={panelSummary}
                large
                expandIcon={null}
            />
            {!readOnly ?
                <Accordion
                    animation={'wave'}
                    summary={panelSummary}
                    large
                    expandIcon={null}
                />
                :
                null
            }
    </>
);

export default ProfileSkeleton;
