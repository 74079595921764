import React from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import Icon from "worksmith/components/Icon/Icon";
import {Color} from "worksmith/enums/Color";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Box from "@mui/material/Box";
import AlertExpandableSection from "worksmith/components/ExpandableSection/AlertExpandableSection";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";


const OpenConcernExpansionPanel = ({concern, sidePanel}) => {

    return (
        <AlertExpandableSection
            collapsibleProps={{
                unmountOnExit: false
            }}
            title={ <Grid container margin={sidePanel ? "0 0 0 1rem" : "0 0 0 0" }>
                         <Icon iconColor={Color.RED} name={"ErrorOutline"}/>
                         <Text variant={sidePanel ? TextVariant.BODY_1 : TextVariant.H6} color={TextColor.ERROR}> Concern Raised</Text>
                     </Grid>}>
            <Box mt={3} ml={1} mr={2} >
                <Grid container margin={"0 0 0 1rem"}>
                    <Grid xs={12} margin={"0 0 1rem 0"}>
                        <Text color={TextColor.ERROR}>Billing is on hold until the concern is resolved.</Text>
                        <Text color={TextColor.ERROR}>Worksmith will respond via email.</Text>
                    </Grid>
                    <Grid xs={12} margin={"0 0 0 0"}>
                        <Text color={TextColor.TEXT_SECONDARY}>{ValueIsSet(concern.creatorUser) ? concern.creatorUser.displayName : ""}</Text>
                        <Text color={TextColor.TEXT_SECONDARY}>{moment(concern.createdTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)}</Text>
                    </Grid>
                    <Grid xs={12} margin={"0 1rem 0 0"}>
                        <Text>{concern.description}</Text>
                    </Grid>
                </Grid>
            </Box>
        </AlertExpandableSection>
    );
};

OpenConcernExpansionPanel.propTypes = {
    concern: PropTypes.shape({
        creatorUser: PropTypes.object,
        createdTimestamp: PropTypes.string,
        description: PropTypes.string
    })
};

export default OpenConcernExpansionPanel;