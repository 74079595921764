import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ValueIsSet} from "../../../shared/helpers/Helpers";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";
import RequestForProposalService from "worksmith/services/api/RequestForProposalService";
import CancelRequestView from "worksmith/composite-components/CancelRequest/CancelRequestView";
import RequestARevisionModal from "worksmith/composite-components/BidDetails/Modals/RequestARevisionModal";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";


const graphQlService = new AsyncGraphQLServiceClass();
const requestService = new RequestForProposalService();


const CancelRequest = ({cancelRequestModalOpen, closeCancelRequestModal, internal, openBidCountProp, requestId}) => {

    const [loading, setLoading] = useState(false);
    const [cancellationReason, setCancellationReason] = useState({});
    const [bid, setBid] = useState(null);
    const [openBidCount, setOpenBidCount] = useState(null);
    const [cancellationReasonOptions, setCancellationReasonOptions] = useState({});
    const [cancellationReasonSubOptions, setCancellationReasonSubOptions] = useState({});
    const [showSubOptions, setShowSubOptions] = useState(false);
    const [requireCustomReason, setRequireCustomReason] = useState(false);
    const [showRequestRevision, setShowRequestRevision] = useState(false);
    const [openRequestRevisionModal, setOpenRequestRevisionModal] = useState(false);
    const [customReason, setCustomReason] = useState('');
    const [showCustomReasonError, setShowCustomReasonError] = useState(false);
    const [showSubOptionError, setShowSubOptionError] = useState(false);
    const [showOptionError, setShowOptionError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const findCancellationReasons = () => {
        setLoading(true);

        let searchParams = {types: ['REQUEST']};

        if(!internal){
            searchParams.internal = false;
        }

        graphQlService.findAll(
            searchParams,
            GraphQLObjectType.CANCELLATION_REASON,
            `
                id
                description
                cancellationReasonSubOptions {
                    id
                    description
                }
                `,
            `id`,
            GraphQLSortDirection.DESCENDING
        ).then((cancellationReasons) => {
            let cancellationReasonOptions = cancellationReasons.map(reason => (
                {label: reason.description, value: reason, cancellationReasonSubOptions: reason.cancellationReasonSubOptions}
            ));

            let cancellationReasonSubOptions = {};

            cancellationReasons.forEach(reason => {
                if (reason.cancellationReasonSubOptions.length > 0) {
                    cancellationReasonSubOptions[reason.id] = reason.cancellationReasonSubOptions.map(subOption => (
                        {label: subOption.description, value: subOption}
                    ))
                }
            });

            setCancellationReasonOptions(cancellationReasonOptions);
            setCancellationReasonSubOptions(cancellationReasonSubOptions);

        }).catch((error) => {
            // noinspection JSIgnoredPromiseFromCall
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const findOpenBidCount = () => {
        //The open bid count is needed for the case where price is too high and we need to determine if we should allow them to request a revision
        setOpenBidCount(openBidCountProp);

        if(openBidCountProp == null){
            setLoading(true);
            graphQlService.findOneById(
                requestId,
                GraphQLObjectType.REQUEST,
                `
                openBidCount
                `
            ).then((data) => {
                setOpenBidCount(data.openBidCount);
            }).catch((error) => {
                // noinspection JSIgnoredPromiseFromCall
                console.error(error);
            }).finally(() => {
                setLoading(false);
            });

        }
    };


    useEffect(() => {
        findCancellationReasons();
    }, []);

    useEffect(() => {
        findOpenBidCount();
    }, []);

    useEffect(() => {

        if(openBidCount === 1 && bid === null){

            setLoading(true);

            let searchParams = {
                isCanceled: false,
                isDeclined: false,
                rfpId: requestId
            };

            graphQlService.findAll(
                searchParams,
                GraphQLObjectType.BID,
                `id
                 requestedRevision
                 latestRevision
                 canRequestRevision`
            ).then((data) => {
                setBid(data[0]);
            }).catch((error) => {
                // noinspection JSIgnoredPromiseFromCall
                console.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }

    }, [openBidCount]);


    const onCancellationReasonChange = (selectedReason) => {
        resetState();

        setCancellationReason({description: selectedReason.description, cancellationReasonId: selectedReason.id, cancellationReasonSubOptionId: null});

        if(ValueIsSet(selectedReason.cancellationReasonSubOptions) && selectedReason.cancellationReasonSubOptions.length > 0){
            setShowSubOptions(true);
        }

        if (selectedReason.description === 'Other') {
            setRequireCustomReason(true);
        }

    };

    const onCancellationSubOptionChange = (selectedSubOption) => {
        setShowRequestRevision(false);

        setCancellationReason({
            cancellationReasonId: cancellationReason.cancellationReasonId,
            description: selectedSubOption.description,
            cancellationReasonSubOptionId: selectedSubOption.id
        });

        if(selectedSubOption.description === "Price is too high"){
            if(bid !== null && (bid.canRequestRevision && !(bid.requestedRevision && !bid.latestRevision))){
                setShowRequestRevision(true);
            }
        }

    };

    const resetState = () => {
        setShowSubOptions(false);
        setRequireCustomReason(false);
        setShowRequestRevision(false);
    };

    const resetErrors = () => {
        setShowCustomReasonError(false);
        setShowSubOptionError(false);
        setShowOptionError(false);
    };

    const onCustomCancellationReasonChange = (customReason) => {
        if(customReason !== ''){
            setCustomReason(customReason)
        }
    };

    const requestRevision = () => {
        setOpenRequestRevisionModal(true);
    };

    const handleSubmit = () => {
        resetErrors();

        if(!ValueIsSet(cancellationReason.cancellationReasonId)){
            setShowOptionError(true);
            return;
        }

        if(showSubOptions && !ValueIsSet(cancellationReason.cancellationReasonSubOptionId)){
            setShowSubOptionError(true);
            return;
        }

        if(requireCustomReason && customReason === ""){
            setShowCustomReasonError(true);
            return;
        }


        setIsSubmitting(true);
        requestService.cancel(
            requestId,
            [{
                cancellationReasonId: cancellationReason.cancellationReasonId,
                cancellationReasonSubOptionId: cancellationReason.cancellationReasonSubOptionId,
                description: customReason
            }],
            false,
            false,
            false,
        ).then(() => {
            DisplaySuccessNotification("Request Successfully Cancelled");
            window.location.reload();
        }).catch(error => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification(error.toString());
            console.error(error);
        }).finally(() => {
            setIsSubmitting(false);
            closeCancelRequestModal();
            resetState();
        });

    };

    return (
        <>
            <CancelRequestView
                cancelRequestModalOpen={cancelRequestModalOpen}
                cancellationReason={cancellationReason}
                cancellationReasonOptions={cancellationReasonOptions}
                cancellationReasonSubOptions={cancellationReasonSubOptions}
                closeCancelRequestModal={() => {closeCancelRequestModal(); resetState();}}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                loading={loading}
                onCancellationReasonChange={onCancellationReasonChange}
                onCancellationSubOptionChange={onCancellationSubOptionChange}
                onCustomCancellationReasonChange={onCustomCancellationReasonChange}
                requestRevision={requestRevision}
                requireCustomReason={requireCustomReason}
                showRequestRevision={showRequestRevision}
                showSubOptions={showSubOptions}
                showCustomReasonError={showCustomReasonError}
                showOptionError={showOptionError}
                showSubOptionError={showSubOptionError}
            />
            <RequestARevisionModal
                bidId={bid !== null ? bid.id : null}
                closeCancelDialog={() => {closeCancelRequestModal(); resetState();}}
                closeModal={() => setOpenRequestRevisionModal(false)}
                inCancelDialog={true}
                open={openRequestRevisionModal}/>
        </>

    );
};

CancelRequest.propTypes = {
    requestId: PropTypes.number.isRequired,
    cancelRequestModalOpen: PropTypes.func.isRequired,
    closeCancelRequestModal: PropTypes.func.isRequired,
};

CancelRequest.defaultProps = {
    internal: false,
    openBidCountProp: null
};

export default CancelRequest;
