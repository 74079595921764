import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTheme} from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

import {Display, JustifyContent, FlexDirection, TextAlign} from "worksmith/enums/CSSEnums";
import Grid from "worksmith/components/Grid/Grid.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import PriceBreakdownTable from "./PriceBreakdownTable";
import Text, {TextVariant} from "../../components/Text/Text.web";
import Currency from "../../components/Currency/Currency.web";
import BreakpointEnum from "../../enums/BreakpointEnum";
import Skeleton, {SkeletonVariant} from "../../components/Skeleton/Skeleton";
import {withTheme} from "../../components/Theme/ThemeProvider";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import UserType from "worksmith/enums/api/user/UserType";


const TotalRow = styled.div`
    text-align: ${TextAlign.RIGHT}
    justify-content: ${JustifyContent.FLEX_END}
    display: ${Display.FLEX};  
    flex-direction: ${FlexDirection.ROW}; 
`;

const PriceBreakdown = (props) => {
    let {additionalApprovalAmount, activityTotal, isEstimate, items, loading, title, userType} = props;
    const theme = useTheme();
    const textVariant = useMediaQuery(theme.breakpoints.down(BreakpointEnum.SM)) ?
                        TextVariant.BODY_2
                        :
                        TextVariant.BODY_1;

    if (loading) {
        return (
            <Skeleton variant={SkeletonVariant.RECTANGLE} width={'100%'} height={200}/>
        )
    }

    return(
        <Grid container spacing={2}>
            {
                title ?
                    <Grid item xs={12}>
                        <Text variant={TextVariant.SUBTITLE_1} semiBold>
                            {title}
                        </Text>
                    </Grid>
                    :
                    null
            }
            {
                ValueIsSet(items) && items.length > 0 ?
                    <Grid item xs={12}>
                        <PriceBreakdownTable items={items} textVariant={textVariant} userType={userType}/>
                    </Grid>
                    :
                    null
            }
            {
                additionalApprovalAmount > 0 ?
                    <Fragment>
                        <Grid item xs={12}>
                            <TotalRow>
                                <Box display={Display.INLINE} pr={2}>
                                    <Text variant={textVariant} semiBold>
                                        Itemized Total:
                                    </Text>
                                </Box>
                                <Text variant={textVariant} semiBold>
                                    <Currency amount={activityTotal}/>
                                </Text>
                            </TotalRow>
                        </Grid>
                        <Grid item xs={12}>
                            <TotalRow>
                                <Box display={Display.INLINE} pr={2}>
                                    <Text variant={textVariant} semiBold>
                                        Additional Approval:
                                    </Text>
                                </Box>
                                <Text variant={textVariant} semiBold>
                                    <Currency amount={additionalApprovalAmount}/>
                                </Text>
                            </TotalRow>
                        </Grid>
                        <Grid item xs={12}>
                            <TotalRow>
                                <Box display={Display.INLINE} pr={2}>
                                    <Text variant={textVariant} semiBold>
                                        Estimated Total:
                                    </Text>
                                </Box>
                                <Text variant={textVariant} semiBold>
                                    <Currency amount={activityTotal}/> - <Currency amount={activityTotal + additionalApprovalAmount}/>
                                </Text>
                            </TotalRow>
                        </Grid>
                    </Fragment>
                    :
                    <Grid item xs={12}>
                        <TotalRow>
                            <Box display={Display.INLINE} pr={2}>
                                <Text variant={textVariant} semiBold>
                                    {isEstimate ? 'Estimated Total:' : 'Total:'}
                                </Text>
                            </Box>
                            <Text variant={textVariant} semiBold>
                                <Currency amount={activityTotal}/>
                            </Text>
                        </TotalRow>
                    </Grid>
            }
        </Grid>
    )
}

PriceBreakdown.propTypes = {
    activityTotal: PropTypes.number,
    additionalApprovalAmount: PropTypes.number,
    isEstimate: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
        isDoable: PropTypes.bool,
        lineItemType: PropTypes.shape({
            name: PropTypes.string
        }),
        quantity: PropTypes.number,
        title: PropTypes.string,
        name: PropTypes.string,
        unitRetailPrice: PropTypes.number
    })),
    loading: PropTypes.bool,
    title: PropTypes.string,
    userType: CustomPropTypes.enum(UserType)
};

export default withTheme(PriceBreakdown);
