import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";

class SubmitChangeOrderConfirmationModal extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const {
            onAccept,
            acceptDisabled,
            onCancel,
            open,
            overrideClientApproval
        } = this.props;

        return (
            <AlertDialog acceptText={"Submit"}
                         cancelText={"Cancel"}
                         open={open}
                         acceptDisabled={acceptDisabled}
                         onAccept={onAccept}
                         onCancel={onCancel}
                         onClose={onCancel}>
                <Text variant={TextVariant.H6}>{!overrideClientApproval ? "Submit" : "Create"} Change Order?</Text>
                <Text variant={TextVariant.BODY_2}>{
                    !overrideClientApproval ?
                        "If accepted, this will replace your original pricing. If declined, your original pricing will stay in place. " +
                        "Reminder: Do NOT perform any additional work without approval!"
                        :
                        "This will update the Estimate. The vendor (if one is assigned) will receive a notification with the updated scope of work."
                }
                </Text>
            </AlertDialog>
        )
    }
}

SubmitChangeOrderConfirmationModal.propTypes = {
    onAccept:               PropTypes.func.isRequired,
    onCancel:               PropTypes.func.isRequired,
    open:                   PropTypes.bool.isRequired,
    overrideClientApproval: PropTypes.bool,
    acceptDisabled:         PropTypes.bool
};

export default SubmitChangeOrderConfirmationModal;