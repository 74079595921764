import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import {Tabs as MaterialTabs} from '@mui/material/';
import Tab from '@mui/material/Tab';
import {Color} from "worksmith/enums/Color";
import {withTheme} from '../Theme/ThemeProvider';
import {TextTransform} from "worksmith/enums/TextTransform";
import TabContainer from "worksmith/components/Tab/TabContainer";
import {ThemeColor} from "worksmith/enums/CSSEnums";
import {ValueIsSet} from "../../helpers/GenericHelpers";

const styles = () => ({
    root: {
        flexGrow: 1,
        width: '100%',
        color: Color.BLACK,
        backgroundColor: Color.WHITE
    },
    clearRoot: {
        flexGrow: 1,
        width: '100%',
        color: Color.BLACK,
        backgroundColor: Color.TRANSPARENT
    },
    label: {
        textTransform: TextTransform.NONE,
        fontSize: "1.2em",
        borderColor: Color.PRIMARY,
    },
});

export const IconPosition = Object.freeze({
    BOTTOM: 'bottom',
    END: 'end',
    START: 'start',
    TOP: 'top',
});

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue,
            tabStyling: this.determineTabStyling(props)
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.tabMinWidth !== this.props.tabMinWidth)
            this.state.tabStyling = this.determineTabStyling(nextProps);

        return true;
    }

    determineTabStyling = (props) => {
        const {tabMinWidth, tabFullWidth} = props;

        if (ValueIsSet(tabMinWidth))
            return {minWidth: tabMinWidth};
        else if (ValueIsSet(tabFullWidth))
            return {maxWidth: '100%'};
        else
            return undefined;
    };

    handleChange = (event, value) => {
        const { onChangeCallBack } = this.props;
        if(onChangeCallBack) {
            this.setState({value},onChangeCallBack(value));
        } else this.setState({value});
    };

    render() {
        const { classes, labelIcon, titleList, children, clearBackground} = this.props;
        const { value, tabStyling } = this.state;

        return (
            <div className={clearBackground ? classes.clearRoot : classes.root}>
                <MaterialTabs
                    value={value}
                    onChange={this.handleChange}
                    indicatorColor={ThemeColor.PRIMARY}>
                    {
                        ValueIsSet(titleList) ? titleList.map((tabTitle, index) => {
                            return <Tab style={{...tabStyling, outline: 'none'}} className={classes.label} key={index} label={tabTitle}/>
                        })
                        :
                        null
                    }
                </MaterialTabs>
                {ValueIsSet(children) ?
                    children.map((child, index) => {
                        return value === index && <TabContainer key={index}>{child}</TabContainer>;
                    })
                    :
                    null
                }
            </div>
        );
    }
}

Tabs.propTypes = {
    classes: PropTypes.object.isRequired,
    clearBackground: PropTypes.bool,
    initialValue: PropTypes.number,         //the index of the tab you want to start on
    onChangeCallBack: PropTypes.func,
    tabFullWidth: PropTypes.bool,            //each tab will take up available space to avoid stacking title text while not extending tab underline for shorter title(s)
    tabMinWidth: PropTypes.string,          //use this if you need to change the width of each tab
    titleList: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    )
};

Tabs.defaultProps = {
    clearBackground: false,
    initialValue: 0
};

export default withTheme(withStyles(styles)(Tabs));