import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import MenuItem from "../../components/Menu/MenuItem.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";

const BidDetailsActionList = React.forwardRef((props, ref) => {
    const {
        canAcceptBid,
        isAdmin,
        isRegionalManager,
        isInCorporateReview,
        canRequestRevision,
        canDecline,
        hasOpenRevision,
        hasScheduleOptions,
        setShowActionMenu,
        setShowAcceptAndDeferModal,
        setShowAcceptAndScheduleModal,
        setShowAcceptAndSubmitSchedulesModal,
        setShowDeclineModal,
        setShowRequestApprovalModal,
        setShowRequestRevisionModal,
    } = props;

    let actionMenuItems = []
    let refIsSet = false;

    if (!isInCorporateReview) {
        if (hasScheduleOptions) {
            refIsSet = true;
            actionMenuItems.push(
                (
                    <MenuItem
                        key={0}
                        ref={ref}
                        onClick={() => {
                            setShowActionMenu(false);

                            if (canAcceptBid) {
                                setShowAcceptAndScheduleModal(true);
                            } else {
                                setShowRequestApprovalModal(true);
                            }
                        }}>
                        Accept and select a time
                    </MenuItem>
                )
            );
        }

        actionMenuItems.push(
            (
                <MenuItem
                    key={1}
                    ref={refIsSet ? null : ref}
                    onClick={() => {
                        setShowActionMenu(false);

                        if (canAcceptBid) {
                            setShowAcceptAndSubmitSchedulesModal(true);
                        } else {
                            setShowRequestApprovalModal(true);
                        }
                    }}>
                    Accept and submit your availability
                </MenuItem>
            )
        );
        refIsSet = true;

        if (isAdmin || isRegionalManager) {
            actionMenuItems.push(
                <MenuItem
                    key={2}
                    onClick={() => {
                        setShowActionMenu(false);

                        if(canAcceptBid) {
                            setShowAcceptAndDeferModal(true);
                        } else {
                            setShowRequestApprovalModal(true);
                        }
                    }}>
                    <Text variant={TextVariant.INHERIT} noWrap={false}>
                        Accept, but send to the Local User to schedule this job
                    </Text>
                </MenuItem>
            )
        }
    }

    if (canRequestRevision && !hasOpenRevision) {
        actionMenuItems.push(
            <MenuItem
                ref={refIsSet ? null : ref}
                key={3}
                onClick={() => {
                    setShowActionMenu(false);
                    setShowRequestRevisionModal(true);
                }}>
                Request a revision
            </MenuItem>
        )
        refIsSet = true;
    }

    if (canDecline) {
        actionMenuItems.push(
            <MenuItem
                key={4}
                ref={refIsSet ? null : ref}
                onClick={() => {
                    setShowActionMenu(false);
                    setShowDeclineModal(true);
                }}>
                Decline
            </MenuItem>
        )
    }


    refIsSet = true;
    return (
        actionMenuItems
    )
});

BidDetailsActionList.defaultProps = {
    canDecline: false
};

BidDetailsActionList.propTypes = {
    canAcceptBid: PropTypes.bool,
    canDecline: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isRegionalManager: PropTypes.bool,
    isInCorporateReview: PropTypes.bool,
    canRequestRevision: PropTypes.bool,
    hasOpenRevision: PropTypes.bool,
    hasScheduleOptions: PropTypes.bool,
    setShowActionMenu: PropTypes.func.isRequired,
    setShowAcceptAndDeferModal: PropTypes.func,
    setShowAcceptAndScheduleModal: PropTypes.func,
    setShowAcceptAndSubmitSchedulesModal: PropTypes.func,
    setShowDeclineModal: PropTypes.func,
    setShowRequestApprovalModal: PropTypes.func,
    setShowRequestRevisionModal: PropTypes.func,
};

export default BidDetailsActionList;