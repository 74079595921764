import React, {useState} from 'react';


import PropTypes from "prop-types";
import {ModalStyle} from "../../../Enums";
import Modal from "../../presentational/modal/Modal";
import TicketForm from "../TicketForm";
import NewServiceForm from "./NewServiceForm";



const CombinedTicketingForm = ({newServiceModalRef, client, onSuccess}) => {

    const [obligation, setObligation] =  useState({});

    const ticketModalRef = React.createRef();

    const onObligationDataCompleted = (obligationData, openTicketForm) => {
        setObligation(obligationData);
        newServiceModalRef.current.close();
        if(openTicketForm){
            ticketModalRef.current.open();
        }
    };


    return(
        <>
            <Modal
                ref={newServiceModalRef}
                style={ModalStyle.DEFAULT}
                title="Add On-Demand Pick Up">
                <NewServiceForm
                    isSelfService={client.isSelfService}
                    controlObligationInsert
                    onSuccess={(obligationData, openTicketForm) => onObligationDataCompleted(obligationData, openTicketForm)}
                />
            </Modal>
            <Modal
                ref={ticketModalRef}
                title="New Ticket">
                <TicketForm
                    obligation={obligation}
                    controlObligationInsert
                    onSuccess={() => {
                    ticketModalRef.current.close();
                    onSuccess();
                }}/>
            </Modal>
        </>
    )
};

CombinedTicketingForm.propTypes = {
    client: PropTypes.shape,
    newServiceModalRef: PropTypes.shape,
    onSuccess: PropTypes.func,
};

export default CombinedTicketingForm