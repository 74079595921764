import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import BidListView from "./BidListView";

const graphQLService = new GraphQLServiceClass();

class BidList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            bids: []
        };
    }

    componentDidMount = () => {
        const {isEstimate, requestId} = this.props;

        let searchParams = {
            isCanceled: false,
            isDeclined: false,
            isEstimate: isEstimate,
            rfpId: requestId
        };

        graphQLService.findAll(
            searchParams,
            GraphQLObjectType.BID,
            BidListGraphQlString
        ).then((data) => {
            this.setState({loading: false, bids: data});
        })
    }

    render() {
        const {
            isAdmin,
            isRegionalManager,
            isEstimate,
            numberOfActive,
            onBidAction,
            startLoad
        } = this.props;
        const {loading, bids} = this.state;

        return (
            <BidListView
                bids={bids}
                isAdmin={isAdmin}
                isRegionalManager={isRegionalManager}
                isEstimate={isEstimate}
                numberOfActiveBids={numberOfActive}
                loading={loading}
                onBidAction={onBidAction}
                startLoad={startLoad}/>
        )
    }
}

BidList.propTypes = {
    isAdmin: PropTypes.bool,
    isRegionalManager: PropTypes.bool,
    isEstimate: PropTypes.bool,
    numberOfActive: PropTypes.number,
    onBidAction: PropTypes.func,
    requestId: PropTypes.number,
    startLoad: PropTypes.func
};

BidList.defaultProps = {
    isAdmin: false,
    isRegionalManager: false,
    isEstimate: false,
};

const BidListGraphQlString = `
    additionalApprovalAmount
    id
    opportunity {
        hasPreferredVendor
        vendorLocation {
            id
            rating
            ratingsCount
            vendor {
                id
                officialName
                qualifiesForPremiumBadge
            }
        }
    }
    totalRetailPrice
    status
`;

export default BidList;