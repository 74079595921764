import {ValueIsSet} from "./lib-worksmith/helpers/GenericHelpers";

const ClientRoutes = Object.freeze({
    SINGLE_REQUESTS: (requestId) => ValueIsSet(requestId) ? `requests\/${requestId}` : `requests`,
    SCHEDULE_CHANGES: "scheduleChanges",
    CONTRACT: "contract",
    CLIENT_LOCATION: (locationId) => 'locations/' + locationId,
    SINGLE_INVOICE: (invoiceId) => ValueIsSet(invoiceId) ? `invoices\/${invoiceId}` : `invoices`,
    RECURRING_SERVICES: "recurringServices",
    RECURRING_SERVICE_SERVICE_LINE: (serviceLineId) => ValueIsSet(serviceLineId) ? `recurringServices\/serviceLine\/${serviceLineId}` : 'recurringServices',
    CALENDAR_LIST_VIEW: "/calendar/listView",
    CALENDAR_LIST_VIEW_INITIAL_TAB: (initialTab) => ValueIsSet(initialTab) ? `/calendar/listView/initialTab/${initialTab}` : "/calendar/listView",
    RECURRING_SERVICE_SERVICE_LINE_LOCATION: (serviceLineId, locationId) => ValueIsSet(serviceLineId, locationId) ? `recurringServices\/serviceLine\/${serviceLineId}\/location\/${locationId}` : 'recurringServices',
    RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB: (serviceLineId, locationId, anchorToChat, obligationTemplateId) => ValueIsSet(serviceLineId, locationId) ? `recurringServices\/serviceLine\/${serviceLineId}\/location\/${locationId}` +
        ((ValueIsSet(anchorToChat) || ValueIsSet(obligationTemplateId)) ? "?" : "" )+
        (ValueIsSet(anchorToChat)? "anchorToChat=" + anchorToChat : "") +
        ((ValueIsSet(anchorToChat) && ValueIsSet(obligationTemplateId)) ? "&" : "") +
        (ValueIsSet(obligationTemplateId)? "obligationTemplateId=" + obligationTemplateId : "") :
        'recurringServices',
    RECURRING_SERVICES_SINGLE_LOCATION_OBLIGATION_TAB_AND_VISIT_STATUS_SORT: (serviceLineId, locationId, visitStatus, obligationTemplateId) => ValueIsSet(serviceLineId, locationId) ? `recurringServices\/serviceLine\/${serviceLineId}\/location\/${locationId}` +
        ((ValueIsSet(visitStatus) || ValueIsSet(obligationTemplateId)) ? "?" : "" )+
        (ValueIsSet(visitStatus)? "visitStatus=" + visitStatus : "") +
        ((ValueIsSet(visitStatus) && ValueIsSet(obligationTemplateId)) ? "&" : "") +
        (ValueIsSet(obligationTemplateId)? "obligationTemplateId=" + obligationTemplateId : "") :
        'recurringServices',
    STANDARD_ITEMS_PAGE: 'standard-items',
});

export default ClientRoutes;