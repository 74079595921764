import React from 'react';
import FrequencyPropType from "../../custom-prop-types/FrequencyPropType";
import {FrequencyString} from "../../helpers/LanguageHelper";

function FrequencyView(props) {
    return (
        <span>{FrequencyString(props.frequency)}</span>
    )
}

FrequencyView.propTypes = {
    frequency: FrequencyPropType.isRequired
};

export default FrequencyView;