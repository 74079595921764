import React, {Component} from 'react';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import TextField, {TextFieldVariant} from "worksmith/components/Inputs/TextField/TextField.web";
import {GroupBy} from "worksmith/helpers/GenericHelpers"
import {Display, FlexDirection} from "worksmith/enums/CSSEnums";
import LocationContractListView from "./view/LocationContractListView";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {StateFullName} from "worksmith/enums/StateFullName";
import Box from "@mui/material/Box";
import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import Loader from "../../components/Loader";

const SearchPaddingContainer = styled.div`
    padding-top: 3em;
    padding-bottom: 2em;
`;

class LocationContractPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rateCardsByState: this.sortLocationsByState(this.props.rateCardLocations)
        };
    }

    filterLocations = (searchTerm) => {
        let { rateCardLocations } = this.props;
        let tempRateCardLocations = [];

        if(searchTerm.length > 0 && searchTerm.length <= 2) return;
        else {
            tempRateCardLocations = rateCardLocations.filter(rateCard =>
                (StateFullName[rateCard.clientLocation.state] ?
                    StateFullName[rateCard.clientLocation.state].toLowerCase().includes(searchTerm.toLowerCase()) :
                    rateCard.clientLocation.state.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    rateCard.clientLocation.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    rateCard.clientLocation.title.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        this.setState({rateCardsByState: this.sortLocationsByState(tempRateCardLocations)})
    };

    sortLocationsByState = (rateCards) => {
        let toSort = GroupBy(rateCards, function(rateCard){return rateCard.clientLocation.state});
        let toReturn = {};
        Object.keys(toSort).sort().forEach(key => {
            toReturn[key] = toSort[key]
        });
        return toReturn;
    };


    render() {
        let {rateCardsByState} = this.state;
        let { contractStartDate, contractEndDate } = this.props;
        let { filterLocations } = this;
        let searchText = 'Search a state, city, or store';

            return (
            <Grid container spacing={4} direction={FlexDirection.ROW}>
                <Grid item md={4} xs={12}>
                    <SearchPaddingContainer>
                        <TextField
                            fullWidth
                            placeholder={searchText}
                            onChange={filterLocations}
                            endAdornment={
                                <InputAdornment position='end'>
                                        <SearchIcon/>
                                </InputAdornment>
                            }
                            variant={TextFieldVariant.SIMPLE_LINE}/>
                    </SearchPaddingContainer>
                </Grid>
                <Grid container item xs={12} direction={FlexDirection.COLUMN} alignItems={JustifyContent.FLEX_START} spacing={0} margin={0}>
                        <Grid item xs={12} spacing={0} margin={0}>
                            <Box display={Display.FLEX} alignItems={AlignItems.CENTER}>
                                    <Text variant={TextVariant.SUBTITLE_2}>Effective Dates:    </Text>
                                    <Text variant={TextVariant.SUBTITLE_1}>{contractStartDate} - {contractEndDate}</Text>
                            </Box>
                        </Grid>
                    <Grid item container xs={12} display={Display.FLEX} spacing={0} margin={0}>
                        <Grid item xs={12}>
                        <LocationContractListView locationContractsByState={rateCardsByState}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            );
    }
}


LocationContractPage.propTypes = {
    contractEndDate: PropTypes.string.isRequired,
    contractStartDate: PropTypes.string.isRequired,
    rateCardLocations: PropTypes.arrayOf(PropTypes.shape({
        clientLocation: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string
        })
    }))
};

export default LocationContractPage;