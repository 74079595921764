import React from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import Currency from "worksmith/components/Currency/Currency.web";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {Color} from "worksmith/enums/Color";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const SingleInvoiceLineSummary = (props) => {
    const {
        amount,
        date,
        dueDate,
        invoiceNumber,
        linkToInvoice,
        location,
        openBalance,
        type
    } = props;

    return (
        <Grid container>
            <Grid item xs={12} sm={1}>
                <Text variant={TextVariant.BODY_2}>
                    {date.format('MM/DD/YYYY')}
                </Text>
            </Grid>
            <Grid item xs={6} sm={2}>
                <Text variant={TextVariant.BODY_2}>
                    {type}
                </Text>
            </Grid>
            <Grid item xs={6} sm={2}>
                <Text variant={TextVariant.BODY_2}>
                    {ValueIsSet(linkToInvoice) ?
                    <a style={{color: Color.BLUE, textDecoration: 'underline'}} onClick={linkToInvoice}>
                        {invoiceNumber}
                    </a>
                    : invoiceNumber}
                </Text>
            </Grid>
            <Grid item xs={12} sm={1}>
                <Text variant={TextVariant.BODY_2}>
                    {location}
                </Text>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Text variant={TextVariant.BODY_2}>
                    {dueDate.format('MM/DD/YYYY')}
                </Text>
            </Grid>
            <Grid item xs={6} sm={2}>
                <Text variant={TextVariant.BODY_2}>
                    <Currency amount={amount}/>
                </Text>
            </Grid>
            <Grid item xs={6} sm={2}>
                <Text variant={TextVariant.BODY_2}>
                    <Currency amount={openBalance}/>
                </Text>
            </Grid>
        </Grid>
    )
};

SingleInvoiceLineSummary.propTypes = {
    amount:        PropTypes.number.isRequired,
    date:          CustomPropTypes.moment.isRequired,
    dueDate:       CustomPropTypes.moment.isRequired,
    invoiceNumber: PropTypes.string.isRequired,
    linkToInvoice: PropTypes.func,
    location:      PropTypes.string.isRequired,
    openBalance:   PropTypes.number.isRequired,
    type:          PropTypes.string.isRequired
};

export default SingleInvoiceLineSummary;