import React, {Component} from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import moment from 'moment';
import StatusBadge from "../../StatusBadge";
import {FormattedNumber} from 'react-intl';
import Loader from "../../Loader";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Table from "antd/lib/table";
import Switch from "antd/lib/switch";
import ObligationTemplateService from 'worksmith/services/api/ObligationTemplateService';
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import {FrequencyString} from "../../../shared/helpers/LanguageHelper";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const FormItem = Form.Item;
const Search = Input.Search;
const obligationTemplateService = new ObligationTemplateService();

class RecurringJobsListView extends Component {

    constructor(props) {
        super(props);


        this.state = {
            locationId: props.locationId,
            serviceLineId: null,
            recurringServices: [],
            filteredServices: [],
            openOnly: true,
            loading: false,
            filter: null,
            showActiveServicesOnly: props.showActiveServicesOnly ? props.showActiveServicesOnly : false,
            hidePricing: props.hidePricing ? props.hidePricing : false,
            hideStartDate: props.hideStartDate ? props.hideStartDate : false,
            hideServiceLine: props.hideServiceLine ? props.hideServiceLine : false,
            hideDescription: props.hideDescription ? props.hideDescription : false
        };

        this.getRecurringServices = this.getRecurringServices.bind(this);
        this.renderJobsTable = this.renderJobsTable.bind(this);
        this.toggleOpenOnly = this.toggleOpenOnly.bind(this);
        this.handleTextSearch = this.handleTextSearch.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.locationId!==prevState.locationId){
            return { locationId: nextProps.locationId};
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.locationId!==this.props.locationId){
            this.setState({locationId: this.props.locationId});
            this.getRecurringServices(this.state.openOnly);
        }
    }

    componentDidMount(){
        this.getRecurringServices(this.state.openOnly);
    }

    getRecurringServices(openOnly) {
        let _this = this;
        _this.setState({loading: true});

        let params = {
            clientIds: [this.props.clientId],
            clientLocationIds: ValueIsSet(this.state.locationId) ? [this.state.locationId] : null,
            statuses : openOnly ? ObligationTemplateStatus.getOpenStatuses() : undefined
        };

        try {
            obligationTemplateService.summaries(null, null, null, params.clientIds, params.clientLocationIds, null, null, params.statuses).then(data => {
                let recurringServices = data;
                _this.setState({recurringServices, filteredServices: recurringServices, filter: null, loading: false});
            });
        } catch (err) {
            _this.setState({loading: false});
            console.error(err);
        }
    }

    toggleOpenOnly() {
        let openOnly = !this.state.openOnly;
        this.getRecurringServices(openOnly);
        this.setState({ openOnly });
    }

    handleTextSearch(e) {
        let searchValue = e.target.value;
        let filteredServices = this.state.recurringServices;

        if(searchValue != null && searchValue.length > 0) {
            let lowerCaseFilter = searchValue.toLowerCase();

            filteredServices = filteredServices.filter(function(recurringService) {

                if(recurringService.scopeOfWork != null && recurringService.scopeOfWork.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(recurringService.serviceLine.name.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(recurringService.status.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(FrequencyString(recurringService.frequency).toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(recurringService.title.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                return false;
            })
        }

        this.setState({filter: searchValue, filteredServices});
    }

    renderJobsTable() {
        let _this = this;
        let { filteredServices, recurringServices, showActiveServicesOnly, hidePricing, hideStartDate, hideServiceLine, hideDescription } = this.state;

        let columns = [];

        if(!showActiveServicesOnly) {
            columns.push({
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: text => <StatusBadge status={text}/>
            });
        }

        if(!hideServiceLine) {
            columns.push({
                title: 'Service Line',
                dataIndex: 'serviceLine.name',
                key: 'serviceLine'
            });
        }

        columns.push({
            title: 'Vendor',
            dataIndex: 'vendorLocation.name',
            render: (text, record) => ValueIsSet(record.vendorContact) ?
                            <div>{record.vendorLocation.name} <br/> {record.vendorContact.displayName} <br/> {record.vendorContact.phone}</div>
                            :
                            record.vendorLocation.name,
            key: 'vendor'
        });

        if(!hideDescription) {
           columns.push({
               title: 'Description',
               dataIndex: 'scopeOfWork',
               key: 'scopeOfWork',
               render: (text, record) => text || record.title || '-'
           });
        }


        columns.push({
            title: 'Schedule',
            dataIndex: 'frequency',
            key: 'frequency',
            render: frequency => FrequencyString(frequency),
        });


        if(!hideStartDate) {
            columns.push({
                    title: 'Start Date',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    render: text => moment(text).format('MM/DD/YYYY'),
                });
        }

        columns.push({
            title: 'Next Service',
            dataIndex: 'nextServiceDate',
            key: 'nextServiceDate',
            render: text => text != null ? moment(text).format('MM/DD/YYYY') : '-',
        });


        if(!hidePricing) {
            columns.push({
                title: 'Price / Visit',
                dataIndex: 'retailPrice',
                key: 'retailPrice',
                render: text => <FormattedNumber
                    value={text}
                    style="currency"
                    currency="USD" />
            });
        }

        return <Table dataSource={filteredServices} columns={columns} scroll={{x: 800}} size="small" pagination={{
            total:filteredServices.length,
            showTotal: total => `Filtered to ${total} of ${recurringServices.length} recurring services`,
            pageSize:20,
            defaultCurrent:1}} />;
    }
    
    render(){
        let {loading, openOnly, showActiveServicesOnly} = this.state;
        let recurringServices = this.state.filteredServices;

        return (
            <div>
                <Row className="margin-bottom-sm">
                    { showActiveServicesOnly ?
                        <Col span={12}></Col> :
                        <Col span={12}>
                            <label>Show only active services: </label> <Switch checked={openOnly} onChange={() => { this.toggleOpenOnly(); }} />
                        </Col>
                    }
                    <Col sm={{offset: 6, span:6}} xs={{offset:2, span:10}} className="text-right">
                        <Search size="large" placeholder="Search" onChange={this.handleTextSearch} />
                    </Col>
                </Row>
                { loading ?
                    <Loader/>
                    :<Row>
                        <Col>
                            { recurringServices.length > 0 ?
                                <div>
                                    {this.renderJobsTable()}
                                </div>
                                :
                                <p>There are no recurring services to show.</p> }
                        </Col>
                    </Row> }
            </div>
        );
    }
}

export default RecurringJobsListView;
