import React from 'react';
import PropTypes from 'prop-types';
import Text from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import {getRecurringString} from "worksmith/helpers/LanguageHelper";
import {TextVariant} from "../../components/Text/Text.web";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../enums/api/task/RecurrenceType";


const ObligationTemplateCurrentScheduleSummary = (props) => {
    const {obligationTemplate} = props;

    return <Grid container item spacing={4} xs={12}>
                <Grid item xs={6}>
                    <Text variant={TextVariant.BODY_1}>Current frequency: {
                        getRecurringString(
                            obligationTemplate.schedule.date,
                            obligationTemplate.schedule.recurrenceType,
                            obligationTemplate.schedule.repeatEvery,
                            obligationTemplate.schedule.dayOfMonth,
                            obligationTemplate.schedule.daysOfWeek)
                    }</Text>
                </Grid>
           </Grid>

};

ObligationTemplateCurrentScheduleSummary.propTypes = {
    obligationTemplate: PropTypes.shape({
        recurringScheduleTemplate: PropTypes.shape({
            frequency: PropTypes.shape({
                recurrenceCount: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number
            }),
        }),
        schedule: PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        })
    }),
};

export default ObligationTemplateCurrentScheduleSummary;