import React from 'react'
import moment from "moment";
import PropTypes from "prop-types";

import {getDayOfWeekStringFromNumber} from "worksmith/helpers/LanguageHelper";
import Grid from "worksmith/components/Grid/Grid";
import {JustifyContent, TextDecoration} from "worksmith/enums/CSSEnums";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";

const SelectedCustomScheduleList = ({handleCustomScheduleOpen, schedule, scheduleDows}) => {

    const Title = (props) => <Text variant={TextVariant.BODY_1} semiBold {...props} />;

    let containsScheduleDowsDayOfWeek = ValueIsSet(scheduleDows) && scheduleDows.every(dow => ValueIsSet(dow.dayOfWeek));
    let containsSavedCustomSchedule = ValueIsSet(schedule.daysOfWeek) && schedule.daysOfWeek.every(dow => ValueIsSet(dow.arrivalStartTime));
    let containsScheduleDayOfWeekNumberFormat = ValueIsSet(schedule.customScheduleArrivalWindows) && schedule.customScheduleArrivalWindows.every(dow => ValueIsSet(dow.dayOfWeek.number));
    let containsScheduleDayOfWeekFormat = ValueIsSet(schedule.customScheduleArrivalWindows) && schedule.customScheduleArrivalWindows.every(dow => ValueIsSet(dow.arrivalStartTime));

    return <>
        {containsScheduleDowsDayOfWeek &&
            scheduleDows.sort((dayA, dayB) => dayA.dayOfWeek - dayB.dayOfWeek).map((day, index) => {
                return (
                    <Grid container item xs={12} key={index} margin={"10px 0 10px 0"} width={'300px'} justify={JustifyContent.SPACE_BETWEEN}>
                        <Grid item xs={4}>
                            <Text variant={TextVariant.BODY_1}>
                                {getDayOfWeekStringFromNumber(day.dayOfWeek)}
                            </Text>
                        </Grid>
                        <Grid item xs={7}>
                            <Text variant={TextVariant.BODY_1}>
                                {moment(day.arrivalStartTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)} - {moment(day.arrivalEndTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)}
                            </Text>
                        </Grid>
                    </Grid>
                );
            })
        }
        {containsScheduleDayOfWeekNumberFormat ?
            schedule.customScheduleArrivalWindows.sort((dayA, dayB) => dayA.dayOfWeek.number - dayB.dayOfWeek.number).map((day, index) => {
                return (
                    <Grid container item xs={12} key={index} margin={"10px 0 10px 0"} width={'300px'} justify={JustifyContent.SPACE_BETWEEN}>
                        <Grid item xs={4}>
                            <Text variant={TextVariant.BODY_1}>
                                {getDayOfWeekStringFromNumber(day.dayOfWeek.number)}
                            </Text>
                        </Grid>
                        <Grid item xs={7}>
                            <Text variant={TextVariant.BODY_1}>
                                {moment(day.arrivalStartTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)} - {moment(day.arrivalEndTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)}
                            </Text>
                        </Grid>
                    </Grid>
                );
            })
            :
            containsScheduleDayOfWeekFormat &&
            schedule.customScheduleArrivalWindows.sort((dayA, dayB) => dayA.dayOfWeek - dayB.dayOfWeek).map((day, index) => {
                return (
                    <Grid container item xs={12} key={index} margin={"10px 0 10px 0"} width={'300px'} justify={JustifyContent.SPACE_BETWEEN}>
                        <Grid item xs={4}>
                            <Text variant={TextVariant.BODY_1}>
                                {getDayOfWeekStringFromNumber(day.dayOfWeek)}
                            </Text>
                        </Grid>
                        <Grid item xs={7}>
                            <Text variant={TextVariant.BODY_1}>
                                {moment(day.arrivalStartTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)} - {moment(day.arrivalEndTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)}
                            </Text>
                        </Grid>
                    </Grid>
                );
            })
        }
        {(ValueIsSet(schedule.repeatOnDays)) && schedule.repeatOnDays.length > 1 &&
            <>
                <Title>Custom Schedule</Title>
                {schedule.repeatOnDays.map((day, index) => {
                    return (
                        <Grid container item xs={12} margin={'20px 0 0 0'} key={index}>
                            <Grid item xs={4}>
                                <Text variant={TextVariant.BODY_1}>
                                    {day.dayOfWeekEnum}
                                </Text>
                            </Grid>
                            <Grid item xs={8}>
                                <Text variant={TextVariant.BODY_1}>
                                    {moment(day.arrivalStartTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)} - {moment(day.arrivalEndTime, MomentFormat.StandardTime).format(MomentFormat.StandardTime)}
                                </Text>
                            </Grid>
                        </Grid>
                    )
                })}
            </>
        }

        { (containsSavedCustomSchedule  && handleCustomScheduleOpen) &&
            <Grid item xs={12} margin={'20px 0 0 0'}>
                <Text variant={TextVariant.BODY_1} link onClick={handleCustomScheduleOpen} color={TextColor.SECONDARY} textDecoration={TextDecoration.UNDERLINE}>
                    Edit Custom Scheduling
                </Text>
            </Grid>
        }
    </>;
};

SelectedCustomScheduleList.propTypes = {
    handleCustomScheduleOpen: PropTypes.func,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string.isRequired,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    scheduleDows: PropTypes.arrayOf(PropTypes.shape({
        dayOfWeek: PropTypes.number,
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
    })),
}

export default SelectedCustomScheduleList;