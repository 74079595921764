import React, { useState, useEffect } from 'react';
import {groupBy, without} from 'lodash'
import PropTypes from 'prop-types'

import LocationHoursView from "./LocationHoursView";
import LocationHoursForm from "./LocationHoursForm";

import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {Color} from 'worksmith/enums/Color'
import {convertMilitaryStringToAMPMString} from "worksmith/helpers/TimeHelpers"
import {getDayOfWeekStringFromNumber, getDayOfWeekNumberFromString} from "worksmith/helpers/LanguageHelper"
import Accordion from 'worksmith/components/Accordion/Accordion'
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import {newTimeBlocks} from "worksmith/helpers/Constants";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientLocationService from "worksmith/services/api/ClientLocationService";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientLocationService = new ClientLocationService();

const LocationHoursPanel = ({location, handleCompletePanel, readOnly}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSubtitle, setShowSubtitle] = useState(false);
    const [locationHoursFormFields, setLocationHoursFormFields] = useState([]);
    const [timeBlocks, setTimeBlocks] = useState([])

    const locationHoursInfoComplete = locationHoursFormFields.length > 0;

    const refetchHoursData = () => {
        return asyncGraphQLServiceClass.findOneById(location.id, GraphQLObjectType.CLIENT_LOCATION, hoursFields);
    }

    const submitLocationHoursInfo = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let submitData = {};
        submitData.id = location.id;
        submitData.locationHours = locationHoursFormFields.map(hour => ({
            id: hour.id,
            dayOfWeek: hour.day,
            openTime: hour.openTime,
            closeTime: hour.closeTime,
            closed: (hour.openTime === "" && hour.closeTime === "") || (hour.openTime === null && hour.closeTime === null)
        }));

        try {
            await clientLocationService.update(submitData);
            await refetchHoursData();
            setIsEditing(false);
            setIsSubmitting(false);
        } catch (err) {
            console.error(err)
            setIsSubmitting(false);
        }
    }

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    }

    const handleAddLocationHours = () => {
        const newTimeBlock = timeBlocks.length > 0 ? newTimeBlocks[timeBlocks.length] : newTimeBlocks[0]

        setTimeBlocks([...timeBlocks, newTimeBlock])
    }

    const handleRemoveLocationHours = (idx) => {
        const newTimeBlocks = timeBlocks.filter((block, i) => i !== idx)
        setTimeBlocks(newTimeBlocks)
    }

    const handleChange = (formField, value, block) => {
        const currentBlocks = timeBlocks.slice()
        let updatedBlocks
        let updatedHours

        if (formField === 'day') {
            updatedBlocks = currentBlocks.map(timeBlock => {
                const daysInBlock = timeBlock.days.map(blk => blk.day)
                if (timeBlock.time !== block.time) {
                    const dayIndex = daysInBlock.indexOf(value)
                    if (dayIndex !== -1) {
                        return {
                            time: timeBlock.time,
                            days: without(timeBlock.days, timeBlock.days[dayIndex])
                        }
                    }
                } else {
                    const dayIndex = daysInBlock.indexOf(value)
                    if (dayIndex !== -1) {
                        return {
                            time: timeBlock.time,
                            days: without(timeBlock.days, timeBlock.days[dayIndex])
                        }
                    } else {
                        if (timeBlock.days.length > 0) {
                            timeBlock.days.push({
                                closeTime: timeBlock.days[0].closeTime,
                                day: value,
                                dayOfWeekInteger: getDayOfWeekNumberFromString(value),
                                openTime: timeBlock.days[0].openTime,
                                openHours: timeBlock.time,
                                closed: false
                            })
                        } else {
                            timeBlock.days = [{
                                    closeTime: "",
                                    openTime: "",
                                    openHours: "",
                                    day: "",
                                    dayOfWeekInteger: null,
                                    closed: false
                                }]

                        }
                        return {
                            time: timeBlock.time,
                            days: timeBlock.days
                        }
                    }
                }

                return {
                    time: timeBlock.time,
                    days: timeBlock.days
                }
            })
            updatedHours = updatedBlocks.map(block => Object.values(block.days)).flat().filter(blk => blk.day !== "")
        } else {
            let newBlock = block.days.map(blk => ({
                ...blk,
                [formField]: value
            }))

            if(formField === 'closed'){
                newBlock = block.days.map(blk => ({
                    ...blk,
                    [formField]: value,
                    closeTime: value ? "" : "21:00",
                    openTime: value ? "" : "10:00",
                }))
            }
            const newHourWindow = formField === 'closed' ?
                "Closed"
                :
                `${convertMilitaryStringToAMPMString(newBlock[0].openTime)} - ${convertMilitaryStringToAMPMString(newBlock[0].closeTime)}`


            newBlock = newBlock.map(block => ({
                ...block,
                openHours: newHourWindow
            }))
            const update = {
                time: newHourWindow,
                days: newBlock
            }
            updatedBlocks = currentBlocks.map(timeBlock => {
                if (timeBlock.time === block.time) {
                    return update
                }
                return timeBlock
            })
            updatedHours = updatedBlocks.map(block => Object.values(block.days)).flat().filter(blk => blk.day !== "")
        }

        setLocationHoursFormFields(updatedHours)
        setTimeBlocks(updatedBlocks)
    }

    const handleExpand = (e, expanded) => {
        setShowSubtitle(expanded)
    }

    useEffect(() => {
        locationHoursInfoComplete ? handleCompletePanel('hours', true) : handleCompletePanel('hours', false);
    }, [locationHoursFormFields])

    useEffect(() => {
        if (location && location.locationHours) {
            const hoursFields = location.locationHours.map(hours => (
                {
                    closeTime: hours.closeTime,
                    day: getDayOfWeekStringFromNumber(hours.dayOfWeekInteger),
                    dayOfWeekInteger: hours.dayOfWeekInteger,
                    openTime: hours.openTime,
                    openHours:
                        hours.openTime && hours.closeTime ? `${convertMilitaryStringToAMPMString(hours.openTime)} - ${convertMilitaryStringToAMPMString(hours.closeTime)}` : '—',
                    closed: hours.closed,
                    id: hours.id
                }
            ))
            setLocationHoursFormFields(hoursFields)
            const newBlocks = Object.entries(groupBy(hoursFields, 'openHours')).map(block => ({
                time: block[0],
                days: block[1]
            }))
            setTimeBlocks(newBlocks)
        }
    }, [location])

    const panelSummary = (
        <Grid container>
            <Grid container item xs={11} alignItems={AlignItems.CENTER}>
                <div>
                    <Text variant={TextVariant.H6}>
                        Location Hours
                    </Text>
                    {
                        showSubtitle && !readOnly ?
                            <Text color={TextColor.TEXT_SECONDARY} gutterBottom variant={TextVariant.BODY_1}>
                                Indicate your location's regular hours of operation
                            </Text>
                            :
                            null
                    }
                </div>
            </Grid>
            <Grid container item xs={1} alignItems={AlignItems.CENTER} justify={JustifyContent.CENTER}>
                {!readOnly ? <Icon name={locationHoursInfoComplete ? IconType.CHECK_CIRCLE_OUTLINED : IconType.REPORT_PROBLEM_OUTLINED} iconColor={locationHoursInfoComplete ? Color.GREEN : Color.RED} /> : null}
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            summary={panelSummary}
            details={isEditing && !readOnly ?
                <LocationHoursForm
                    handleAddLocationHours={handleAddLocationHours}
                    handleChange={handleChange}
                    handleRemoveLocationHours={handleRemoveLocationHours}
                    isSubmitting={isSubmitting}
                    submitLocationHoursInfo={submitLocationHoursInfo}
                    timeBlocks={timeBlocks}
                    toggleEdit={toggleEdit}
                />
                :
                <LocationHoursView
                    locationHoursFormFields={locationHoursFormFields}
                    readOnly={readOnly}
                    toggleEdit={toggleEdit}
                />

            }
            elevation={0}
            large
            onChange={handleExpand}
            width={'95%'}
        />
    )
};

export default LocationHoursPanel;

LocationHoursPanel.propTypes = {
    location: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        client: PropTypes.shape({
            adminUsers: PropTypes.arrayOf(PropTypes.shape({
                clientRole: PropTypes.string,
                companyName: PropTypes.string,
                displayName: PropTypes.string,
                email: PropTypes.string.isRequired,
                phone: PropTypes.string,
                clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
                    clientLocation: PropTypes.shape({
                        locationCode: PropTypes.string,
                        client: PropTypes.shape({
                            officialName: PropTypes.string.isRequired
                        }).isRequired
                    })
                })).isRequired
            })),
            id: PropTypes.number.isRequired
        }).isRequired,
        clientLocationAttachments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            singleUseUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })),
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
        email: PropTypes.string,
        industry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: PropTypes.string
        }),
        openingDate: PropTypes.string,
        regionalManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        locationManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        clientLocationOperationDetails: PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            unionLaborRequirement: PropTypes.string,
            occurrenceInsuranceRequirement: PropTypes.number,
            aggregateInsuranceRequirement: PropTypes.number,
            additionalInsuranceDetails: PropTypes.string,
            locationSquareFootage: PropTypes.number,
            hasLadderOnSite: PropTypes.bool,
            ladderHeightFt: PropTypes.number,
            parkingDirections: PropTypes.string,
            serviceEntranceDescription: PropTypes.string,
            additionalAccessInfo: PropTypes.string
        }),
        locationHours: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            dayOfWeekInteger: PropTypes.number,
            openTime: PropTypes.string,
            closeTime: PropTypes.string,
            closed: PropTypes.bool,
        })),
        id: PropTypes.number,
        locationCode: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired
    }),
    handleCompletePanel: PropTypes.func,
    readOnly: PropTypes.bool
}

const hoursFields = `
    locationHours {
        id
        dayOfWeekInteger
        openTime
        closeTime
        closed
    }
    id
    locationCode
`

