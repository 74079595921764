import React from 'react';
import PropTypes from 'prop-types';

import Button, {ButtonVariant} from "../Button/Button.web";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Grid from "../Grid/Grid.web";
import {JustifyContent} from "../../enums/CSSEnums";

const PopoverFieldButtons = ({buttonJustify, onClear, onClose}) => {

    return (
        <Grid container justify={buttonJustify}>
            <Grid item xs={4}>
                <Button
                    fullWidth
                    onClick={onClear}
                    primary
                    variant={ButtonVariant.TEXT}>
                    Clear
                </Button>
            </Grid>
            <Grid item xs={7}>
                <Button
                    fullWidth
                    onClick={onClose}
                    primary
                    variant={ButtonVariant.OUTLINED}>
                    Show Results
                </Button>
            </Grid>
        </Grid>
    );
};

PopoverFieldButtons.defaultProps = {
    buttonJustify: JustifyContent.FLEX_START
};

PopoverFieldButtons.propTypes = {
    buttonJustify: CustomPropTypes.enum(JustifyContent),
    onClear: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default PopoverFieldButtons;