export const MalaysiaProvince = Object.freeze({
    "JHR": "Johor (JHR)",
    "KDH": "Kedah (KDH)",
    "KTN": "Kelantan (KTN)",
    "MLK": "Malacca (MLK)",
    "NSN": "Negeri Sembilan (NSN)",
    "PHG": "Pahang (PHG)",
    "PNG": "Penang (PNG)",
    "PRK": "Perak (PRK)",
    "PLS": "Perlis (PLS)",
    "SBH": "Sabah (SBH)",
    "SWK": "Sarawak (SWK)",
    "SGR": "Selangor (SGR)",
    "TRG": "Terengganu (TRG)",
    "KUL": "Kuala Lumpur (KUL)",
    "LBN": "Labuan (LBN)",
    "PJY": "Putrajaya (PJY)"
});