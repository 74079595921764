import React, {Component} from 'react';
import ReactSelect from 'react-select';
import UserService from 'worksmith/services/api/UserService';
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';
import VendorService from 'worksmith/services/api/VendorService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import WSButton from '../../components/Button';
import moment from 'moment';

import Loader from "../../components/Loader";
import Table from "antd/lib/table";
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Input from "antd/lib/input";

const userService = new UserService();
const obligationItemGroupService = new ObligationItemGroupService();
const vendorService = new VendorService();
const authTokenManager = new AuthTokenManager();

class BurberryBulkShipmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ticketType: 'open',
            locationId: null,
            showFilter: false,
            hasSingleLocation: true,
            locations: [],
            allTickets: [],
            selectedRowKeys: [],
            shippingNumber:'',
            shippingSubmitted: false,
            vendor: null,
            searchParams: {
                status: 'awaiting shipment',
                vendorId: props.match.params.id
            },
            sort: {
                order: 'descend',
                columnKey: 'createdTimestamp'
            }
        }

        this.getUserLocations = this.getUserLocations.bind(this);
        this.getTickets = this.getTickets.bind(this);
        this.getVendor = this.getVendor.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.renderTicketsTable = this.renderTicketsTable.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onShippingNumberChange = this.onShippingNumberChange.bind(this);
        this.shipItems = this.shipItems.bind(this);
    }

    componentWillMount() {
        let _this = this;
        this.getUserLocations().then(function() {
            _this.getVendor();
            _this.getTickets();
        });
    }

    getUserLocations() {

        let _this = this;

        return userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId()).then(function (data) {
            let locationsData = data;

            var locations = locationsData.map((location)=>
            { return {value: location.id, label: location.title} });

            if (locations.length === 1) {
                _this.setState({ hasSingleLocation: true })
            }

            let searchParams = _this.state.searchParams;
            searchParams.clientLocationId = locations[0].value;

            _this.setState({ searchParams, locations });
        });
    }

    getVendor() {
        let _this = this;

        return vendorService.findSimpleVendor(this.state.searchParams.vendorId).then(function (data) {
            _this.setState({vendor: data});
        });
    }

    getTickets() {
        let _this = this;
        _this.setState({loading: true});

        let { searchParams } = _this.state;

        obligationItemGroupService.findClientSummaries(searchParams).then(function (data) {
            _this.setState({ allTickets: data });
            _this.setState({loading: false});
        })
    }

    onLocationChange(location) {
        let { searchParams } = this.state;
        searchParams.clientLocationId = location.value;
        this.setState({searchParams});

        this.getTickets();
    }

    handleSortChange(pagination, filters, sorter) {
        this.setState({sort: sorter});
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }

    onShippingNumberChange(e) {
        let shippingNumber = e.target.value;
        this.setState({shippingNumber});
    }

    shipItems() {
        let _this = this;
        _this.setState({shippingSubmitted : true});
        let { selectedRowKeys, shippingNumber } = this.state;

        let submitData = {
            ticketIds: selectedRowKeys,
            trackingNumber: shippingNumber
        };

        obligationItemGroupService.shipItems(submitData).then(function (data) {
            let tickets = data;

            //To be used when printing these tickets
            tickets.forEach(function(ticket) {
                ticket.vendorLocation = {
                    name: _this.state.vendor.name
                };
            })

            window.localStorage.setItem('selectedTickets', JSON.stringify(tickets));
            window.open("/#/printTickets");
            _this.setState({ selectedRowKeys: [], shippingNumber: '' });
            _this.getTickets();
            _this.setState({shippingSubmitted : false});
        })
    }

    renderTicketsTable() {
        let _this = this;
        let { allTickets, sort, selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const columns = [{
                title: 'WS Item',
                dataIndex: 'id',
                key: 'itemNumber'
        }, {
            title: 'Request #',
            dataIndex: 'burberryRequestNumber',
            key: 'requestNumber'
        }, {
            title: 'Entry Date',
            dataIndex: 'createdTimestamp',
            key: 'createdTimestamp',
            render: text => moment(text).format('MM/DD/YYYY'),
            sorter: (a, b) => moment(b.createdTimestamp).diff(moment(a.createdTimestamp), 'days'),
            sortOrder: sort.columnKey === 'createdTimestamp' && sort.order
        }, {
            title: 'Associate',
            dataIndex: 'creator.name',
            key: 'creator',
            render: text => text != null ? text : '-'
        }, {
            title: 'Client Name',
            dataIndex: 'customerName',
            key: 'customerName'
        }, {
            title: 'Service Line',
            dataIndex: 'serviceLine.name',
            key: 'seviceLine'
        }, {
            title: 'Vendor',
            dataIndex: 'vendor.name',
            key: 'vendor'
        }, {
            title: 'Speed of Service',
            dataIndex: 'urgencyType',
            key: 'serviceDuration',
            render: (text, record) => text.toUpperCase() + " - " + record.turnaroundDays + " days"
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        }];

        return <Table dataSource={allTickets} rowSelection={rowSelection} rowKey="id" columns={columns} scroll={{x: 800}} size="small" onChange={this.handleSortChange} pagination={false} />;
    }

    render() {

        let _this = this;
        let { loading, allTickets, locations, searchParams, selectedRowKeys, shippingNumber, shippingSubmitted } = this.state;

        return (
            <section className="ws-section">
                { locations.length > 1 ?
                    <Row className="margin-bottom-lg">
                        <Col>
                            <ReactSelect options={locations}
                                         onChange={this.onLocationChange}
                                         value={searchParams.clientLocationId}
                                         clearable={false}/>
                        </Col>
                    </Row> : null }
                <Row>
                    <Col>
                        { loading ?
                            <Loader/>
                            :<Row>
                                <Col>
                                    { allTickets.length > 0 ?
                                        <div>
                                            {this.renderTicketsTable()}
                                            {this.state.showShippingNumberField ?
                                                <div style={{'width':'400px', 'float': 'right'}}>
                                                    <Row className="margin-top-md" gutter={6}>
                                                        <Col style={{ 'textAlign': 'right'}}>
                                                            <Input placeholder="Please enter your shipping number" onChange={this.onShippingNumberChange}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="margin-top-md" gutter={6}>
                                                        <Col span={12} style={{ 'textAlign': 'right'}}>
                                                            <WSButton
                                                                message={'Go Back'}
                                                                type={'primary'}
                                                                outline={true}
                                                                disabled={shippingSubmitted}
                                                                onPress={() => _this.setState({showShippingNumberField: false})}/>
                                                        </Col>
                                                        <Col span={12} style={{ 'textAlign': 'right'}}>
                                                            <WSButton
                                                                message={'Mark items as shipped'}
                                                                type={'primary'}
                                                                disabled={shippingNumber.length == 0 || shippingSubmitted }
                                                                onPress={_this.shipItems}/>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                <Row className="margin-top-md" gutter={6}>
                                                    <Col style={{ 'textAlign': 'right'}}>
                                                        <WSButton
                                                            message={'Next'}
                                                            type={'primary'}
                                                            disabled={selectedRowKeys.length == 0}
                                                            onPress={() => _this.setState({showShippingNumberField: true})}/>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                        :
                                        <p>There are no tickets to show.</p> }
                                </Col>
                            </Row> }
                    </Col>
                </Row>
            </section>
        )
    }
}

export default BurberryBulkShipmentList;
