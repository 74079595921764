import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import Text, {LinkUnderline, TextColor, TextVariant} from "../../../../components/Text/Text.web";
import CreateScheduleOptions from "../../../ScheduleOptionsForm/CreateScheduleOptions";
import Grid from "../../../../components/Grid/Grid.web";
import Schedule from "../../../../components/Schedule/Schedule.web";
import UserType from "../../../../enums/api/user/UserType";
import {Display} from "worksmith/enums/CSSEnums";
import {getReschedulingSubStatusFromCreatorType} from "./ReschedulingView";

const ReschedulingElapsedView = (props) => {
    const {
        creatorType,
        duration,
        frequency,
        loading,
        proposeSchedules,
        schedule,
        showCancelChangeRequestModal,
        useTicketing,
        vendorLocation
    } = props;

    return (
        <RequestStateLayout
            description={
                <Text variant={TextVariant.BODY_1}>
                    {`The availability submitted by you or ${vendorLocation.vendor.officialName} has expired. Please submit new availability.`}
                    {
                        creatorType === UserType.CLIENT ?
                            <Fragment>
                                {` No longer need to reschedule? Click here to `}
                                <Text
                                    link
                                    onClick={showCancelChangeRequestModal}
                                    display={Display.INLINE}
                                    variant={TextVariant.INHERIT}
                                    underline={LinkUnderline.ALWAYS}
                                    semiBold
                                    color={TextColor.SECONDARY}>
                                    {`keep your current time.`}
                                </Text>
                            </Fragment>
                            :
                            null
                    }
                </Text>
            }
            body={
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Text variant={TextVariant.BODY_1} color={TextColor.PRIMARY} semiBold>
                            Currently Scheduled For:
                        </Text>
                        <Schedule
                           textVariant={TextVariant.BODY_2}
                           schedule={schedule}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CreateScheduleOptions
                            spacing={3}
                            duration={duration}
                            frequency={frequency}
                            onSubmit={(schedules) => proposeSchedules(schedules)}
                            numberOfSchedules={1}
                            numberOfRequiredSchedules={1}
                            useTicketing={useTicketing}/>
                    </Grid>
                </Grid>
            }
            loading={loading}
            status={'Rescheduling - ' + getReschedulingSubStatusFromCreatorType(creatorType)}
            statusColor={TextColor.ERROR}/>
    )
};

ReschedulingElapsedView.propTypes = {
    creatorType: PropTypes.oneOf([
        UserType.CLIENT,
        UserType.VENDOR,
    ]),
    duration: PropTypes.number,
    frequency: PropTypes.shape({     // If frequency is passed in, the schedules created will be recurring
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    loading: PropTypes.bool,
    proposeSchedules: PropTypes.func,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    useTicketing: PropTypes.bool,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

export default ReschedulingElapsedView;