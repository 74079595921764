import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LineItemFields from "worksmith/components/LineItem/LineItemForm/LineItemFields";
import {TextColor, TextVariant} from "worksmith/components/Text/Text";
import Text from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import {TextAlign} from "worksmith/enums/CSSEnums";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const Container = styled.div`
    max-width: 860px;
`;

const Card = styled.div`
    border: 1px solid silver;
    padding: 2em 1em;
`;

const LineItemFieldsCardView = (props) => {
    const {
        allowExcludeRevShare,
        effectiveWholesaleRate,
        errors,
        index,
        lineItemTypes,
        initialLineItem,
        onChange,
        onRemove,
        portalType,
    } = props;

    return (
        <Container>
            <Grid container>

                <Grid item md={10} xs={6}>
                    <Text variant={TextVariant.SUBTITLE_2}>Item {index+1}</Text>
                </Grid>

                {index === 0 && <Fragment>
                    <Grid item md={2} xs={6} textAlign={TextAlign.RIGHT}>
                        <Text variant={TextVariant.SUBTITLE_2}>*Required</Text>
                    </Grid>
                </Fragment>}

                { index !== 0 && <Grid item md={2} xs={6} textAlign={TextAlign.RIGHT}>
                    <Button variant={ButtonVariant.TEXT} onClick={() => onRemove(index)}>
                        <Text color={TextColor.ERROR}> X </Text> Remove
                    </Button>
                </Grid>}

                <Grid item xs={12}>
                    <Card>
                        <LineItemFields allowExcludeRevShare={allowExcludeRevShare}
                                        effectiveWholesaleRate={effectiveWholesaleRate}
                                        errors={errors}
                                        index={index}
                                        initialLineItem={initialLineItem}
                                        lineItemTypes={lineItemTypes}
                                        onChange={onChange}
                                        portalType={portalType}
                                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
};

LineItemFieldsCardView.propTypes = {
    allowExcludeRevShare:   PropTypes.bool,
    effectiveWholesaleRate: PropTypes.number.isRequired,
    errors:                 PropTypes.shape({
                                description:     PropTypes.bool,
                                quantity:        PropTypes.bool,
                                unitRetailPrice: PropTypes.bool
                            }),
    index:                  PropTypes.number.isRequired,
    initialLineItem:        PropTypes.object.isRequired,
    lineItemTypes:          PropTypes.arrayOf(PropTypes.shape({
                                id:   PropTypes.number.isRequired,
                                name: PropTypes.string.isRequired
                            })).isRequired,
    onChange:               PropTypes.func.isRequired,
    onRemove:               PropTypes.func,
    portalType:             CustomPropTypes.enum(PortalType)
};

export default LineItemFieldsCardView;