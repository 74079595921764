import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import {AlignItems, Display, JustifyContent} from "worksmith/enums/CSSEnums";
import {IconFontSize} from "worksmith/components/Icon/Icon.web";
import Collapsible from "worksmith/components/Collapse/Collapsible.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";

const BoxWithHover = styled(Box)`
    :hover {
        cursor: pointer;
    }
`;

class ExpandableSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.initialExpanded ? props.initialExpanded : false
        };
    }

    onClick = () => {
        let {expanded} = this.state;
        let {onClick} = this.props;

       this.setState({expanded: !expanded}, () => ValueIsSet(onClick) ? onClick() : null);
    };

    render() {
        let {onClick} = this;
        let {expanded} = this.state;
        let {
            collapsibleProps,
            count,
            iconSize,
            subtitle,
            title
        } = this.props;

        return (
            <Fragment>
                <BoxWithHover
                    pt={1}
                    pb={1}
                    display={Display.FLEX}
                    justifyContent={JustifyContent.SPACE_BETWEEN}
                    onClick={onClick}>
                    <Box>
                        <Box>
                            {
                                count ?
                                    <Box display={Display.INLINE} mr={1}>
                                        <Text variant={TextVariant.H6}>
                                            {count}
                                        </Text>
                                    </Box>
                                    :
                                    null
                            }
                            <Text variant={TextVariant.H6}>
                                {title}
                            </Text>
                        </Box>
                        {
                            subtitle ?
                                typeof subtitle === JavascriptType.STRING ?
                                    <Text variant={TextVariant.BODY_1}>
                                        {subtitle}
                                    </Text>
                                    :
                                     subtitle
                                :
                                null
                        }
                    </Box>
                    <Box display={Display.FLEX} alignItems={AlignItems.CENTER}>
                        {
                            expanded ?
                                <ExpandLess fontSize={iconSize}/>
                                :
                                <ExpandMore fontSize={iconSize}/>
                        }

                    </Box>
                </BoxWithHover>
                {
                    ValueIsSet(this.props.children) ?
                        <Collapsible
                            expanded={expanded}
                            {...collapsibleProps}>
                            {this.props.children}
                        </Collapsible>
                        :
                        null
                }
            </Fragment>
        )
    }
}

ExpandableSection.defaultProps = {
    collapsibleProps: {
        mountOnEnter: true,
        unmountOnExit: true
    },
    iconSize: IconFontSize.DEFAULT,
};

ExpandableSection.propTypes = {
    count: PropTypes.number,
    collapsibleProps: PropTypes.object,
    iconSize: CustomPropTypes.enum(IconFontSize),
    initialExpanded: PropTypes.bool,
    onClick: PropTypes.func,
    subtitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
};

export default ExpandableSection;