// This file was generated from com.worksmith.task.controller.ObligationItemEstimateController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ObligationItemEstimateService extends Http {
    constructor() {
        super('/obligationItemEstimates')
    }
    
    decline(id, estimate) {
        return this.callApi( `${id}/decline`, PATCH, estimate, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    proceed(id, estimate) {
        return this.callApi( `${id}/proceed`, PATCH, estimate, null, null, true);
    }
    
    proceedPartially(id, estimate) {
        return this.callApi( `${id}/proceedPartially`, PATCH, estimate, null, null, true);
    }
    
    reopen(id) {
        return this.callApi( `${id}/reopen`, PATCH, null, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default ObligationItemEstimateService;
