import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {ProgressVariant} from "../../enums/ProgressVariant";
import {ComponentColor} from "../../enums/Color";
import {withTheme} from '../Theme/ThemeProvider';
import MaterialCircularProgress from '@mui/material/CircularProgress';

const CircularProgress = (props) => {
   let {
      color,
      disableShrink,
      size,
      style,
      thickness,
      value,
      variant
   } = props;

   return (
       <MaterialCircularProgress
         color={color}
         disableShrink={disableShrink}
         size={size}
         style={style}
         thickness={thickness}
         value={value}
         variant={variant}/>
   )
};

CircularProgress.propTypes = {
   color: CustomPropTypes.enum(ComponentColor),
   disableShrink: PropTypes.bool,
   size: PropTypes.number,
   style: PropTypes.object,
   thickness: PropTypes.number,
   value: PropTypes.number,
   variant: CustomPropTypes.enum(ProgressVariant)
};

export default withTheme(CircularProgress);
