import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import MonthPicker from "worksmith/antd-components/MonthPicker/MonthPicker.web.js";
import {JustifyContent, TextAlign} from "worksmith/enums/CSSEnums";
import ServiceLevelAgreementService from "worksmith/services/api/ServiceLevelAgreementService";
import moment from "moment";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import styled from "styled-components";
import {DialogSize} from "../../enums/DialogSize";


const slaService = new ServiceLevelAgreementService();

let RadioGroupContainer = styled.div`
    padding-left: 20px;
`;

let  DialogContentWrapper = styled.div`
    padding-bottom: 48px;
`;

class SLAComplianceExportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            singleMonthSelection: null,
            rangeStartSelection: null,
            rangeEndSelection: null,
            dateSelectionType: null
        }
    }

    onStartDateChange = (date) => {
        const {rangeStartSelection} = this.state;
        if (rangeStartSelection == null || rangeStartSelection.format('YYYY-MM-DD') !== date.format('YYYY-MM-DD')) {
            this.setState({rangeStartSelection: date.startOf('month')})
        }
    };

    onEndDateChange = (date) => {
        const {rangeEndSelection} = this.state;
        if (rangeEndSelection == null || rangeEndSelection.format('YYYY-MM-DD') !== date.format('YYYY-MM-DD')) {
            this.setState({rangeEndSelection: date.endOf('month')})
        }
    };

    onSingleMonthChange = (date) => {
        const {singleMonthSelection} = this.state;
        if (singleMonthSelection == null || singleMonthSelection.format('YYYY-MM-DD') !== date.format('YYYY-MM-DD')) {
            this.setState({singleMonthSelection: date.startOf('month')})
        }
    };

    onDateSelectionTypeChange = (selection) => {
        this.setState({dateSelectionType: selection.target.value, singleMonthSelection: null, rangeStartSelection: null, rangeEndSelection: null})
    };

    onExport = () => {
        const {singleMonthSelection, rangeStartSelection, rangeEndSelection, dateSelectionType} = this.state;
        const {clientId, onClose} = this.props;
        let exportStartDate = null;
        let exportEndDate = null;
        if (dateSelectionType === DateSelectionType.MONTH_RANGE) {
            exportEndDate = rangeEndSelection.endOf('month').format('YYYY-MM-DD');
            exportStartDate = rangeStartSelection.startOf('month').format('YYYY-MM-DD');
        } else if (dateSelectionType === DateSelectionType.SINGLE_MONTH) {
            exportStartDate = singleMonthSelection.startOf('month').format('YYYY-MM-DD');
            exportEndDate = singleMonthSelection.endOf('month').format('YYYY-MM-DD');
        }

        slaService.getSLASummaryReport(clientId, exportStartDate, exportEndDate).then((data) => {
            let hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
            hiddenElement.target = '_blank';
            hiddenElement.download = "Sla-Summary-" + moment().format('MM-DD-YYYY') + ".csv";
            hiddenElement.click();
        });

        onClose();
    };

    render() {
        const {onStartDateChange, onEndDateChange, onSingleMonthChange, onExport, onDateSelectionTypeChange} = this;
        const {open, onCancel, onClose, slaPeriodStartDate, slaPeriodEndDate} = this.props;
        const {singleMonthSelection, rangeStartSelection, rangeEndSelection, dateSelectionType} = this.state;

        return (
            <AlertDialog acceptText={"Export"}
                         cancelText={"Close"}
                         disablePortal
                         acceptDisabled={singleMonthSelection == null && (rangeStartSelection == null || rangeEndSelection == null)}
                         open={open}
                         keepMounted={false}
                         onAccept={onExport}
                         onCancel={onCancel}
                         onClose={onClose}
                         size={DialogSize.SMALL}
                         zIndex={130}
            >
                <DialogContentWrapper>
                <Grid container spacing={4}>
                    <Grid item xs={12}><Text variant={TextVariant.H6}>Export Compliance Data</Text></Grid>
                    <RadioGroupContainer>
                        <RadioGroup
                            name="dateSelectionType"
                            value={dateSelectionType}
                            onChange={onDateSelectionTypeChange}
                        >
                        <Grid container item spacing={4}>
                            <Grid container item spacing={1}>
                                <Grid container item xs={8} justify={JustifyContent.FLEX_START}>
                                    <Grid item xs={12}>
                                        <FormControlLabel value={DateSelectionType.SINGLE_MONTH} control={<Radio/>} label={<Text variant={TextVariant.SUBTITLE_1}>For one month</Text>}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <MonthPicker
                                        isDateDisabled={(date) => !moment(date).isBetween(slaPeriodStartDate, slaPeriodEndDate)}
                                        onChange={onSingleMonthChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid container item xs={8} justify={JustifyContent.FLEX_START}>
                                    <Grid item xs={12}>
                                        <FormControlLabel value={DateSelectionType.MONTH_RANGE} control={<Radio/>} label={<Text variant={TextVariant.SUBTITLE_1}>For a month range</Text>}/>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={10} justify={JustifyContent.FLEX_START}>
                                    <Grid item md={5} xs={12}>
                                        <MonthPicker
                                            isDateDisabled={(date) => !moment(date).isBetween(slaPeriodStartDate, slaPeriodEndDate)}
                                            onChange={onStartDateChange}
                                        />
                                    </Grid>
                                    <Grid item xs={1} textAlign={TextAlign.CENTER}><Text>-</Text></Grid>
                                    <Grid item md={5} xs={12}>
                                        <MonthPicker
                                            isDateDisabled={(date) => !moment(date).isBetween(slaPeriodStartDate, slaPeriodEndDate)}
                                            onChange={onEndDateChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                    </RadioGroupContainer>
                </Grid>
                </DialogContentWrapper>
            </AlertDialog>
        );
    };
};

const DateSelectionType = {
    SINGLE_MONTH: "singleMonth",
    MONTH_RANGE: "monthRange"
};

SLAComplianceExportModal.propTypes = {
    clientId: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    slaPeriodStartDate: PropTypes.object,
    slaPeriodEndDate: PropTypes.object
};


export default SLAComplianceExportModal;