import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import Accordion from "worksmith/components/Accordion/Accordion";
import SingleRecurringServiceDetailsExpansion from "./SingleRecurringServiceDetailsExpansion";
import Grid from "worksmith/components/Grid/Grid.web";
import ObligationTemplatePropertiesGrid from "./ObligationTemplatePropertiesGrid";
import styled from "styled-components";
import {Color} from "worksmith/enums/Color";

const BorderBlock = styled.div`
    border-style: none none solid none;
    border-width: 0.1em;
    border-color: ${Color.LIGHT_GREY}
`;

const PaddedBlock = styled.div`
    padding-top: 1.0em;
    padding-bottom: 1.0em;
`;


const RecurringServicesDetailsExpansion = (props) => {
    let {cancelledObligationTemplates} = props;
    const [showExpandedPanel, setShowExpandedPanel] = useState(false);

    if(cancelledObligationTemplates.length < 1)
        return null;

    return <PaddedBlock>
        {cancelledObligationTemplates.length === 1 ?
            <Accordion
                expanded={showExpandedPanel}
                onChange={() => setShowExpandedPanel(!showExpandedPanel)}
                summary={<Text color={TextColor.TEXT_SECONDARY}> Cancelled Services</Text>}
                details={<ObligationTemplatePropertiesGrid displayVendorAndPriceDetails={true} obligationTemplate={cancelledObligationTemplates[0]}/>}
            />
            :
            <Accordion
                expanded={showExpandedPanel}
                onChange={() => setShowExpandedPanel(!showExpandedPanel)}
                summary={<Text color={TextColor.TEXT_SECONDARY}> Cancelled Services</Text>}
                details={
                    <Grid xs={8} container>
                        <Grid item xs={12}><BorderBlock/></Grid>
                        {props.cancelledObligationTemplates.map(obligationTemplate =>
                            <Grid item xs={12}><SingleRecurringServiceDetailsExpansion obligationTemplate={obligationTemplate}/></Grid>)}
                    </Grid>
                }
            />}
    </PaddedBlock>
};

RecurringServicesDetailsExpansion.propTypes ={
    cancelledObligationTemplates: PropTypes.arrayOf(PropTypes.object)
};

export default RecurringServicesDetailsExpansion;