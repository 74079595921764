import React, {Component} from 'react';
import PropTypes from 'prop-types';
import OneTimeScheduleInputView from "./OneTimeScheduleInputView";
import moment from "moment";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {InputBaseVariant} from "../../components/Inputs/InputBase/InputBase.web";
import {MomentFormat} from "../../enums/MomentFormat";

class OneTimeScheduleInput extends Component {
    constructor(props) {
        super(props);

        this.values = {
            date: null,
            endTime: moment('6:00 PM', MomentFormat.StandardTime),
            returnDate: null,
            startTime: moment('10:00 AM', MomentFormat.StandardTime),
        };

        if (props.initialSchedule) {
            this.values.date = props.initialSchedule.date;
            this.values.returnDate = props.initialSchedule.returnDate;

            if(props.initialSchedule.arrivalStartTime != null && props.initialSchedule.arrivalEndTime != null) {
                this.values.endTime = props.initialSchedule.arrivalEndTime;
                this.values.startTime = props.initialSchedule.arrivalStartTime;
            }
        }
    }

    onChange = () => {
       const {date, endTime, returnDate, startTime}  = this.values;

       this.props.onChange({date, arrivalStartTime: startTime, arrivalEndTime: endTime, returnDate: returnDate, type: 'one-time'});
    };

    onDateChange = (date) => {
        this.values.date = date;
        this.onChange();
    };

    onReturnDateChange = (returnDate) => {
        this.values.returnDate = returnDate;
        this.onChange();
    };

    onTimeRangeChange = (startTime, endTime) => {
        this.values.startTime = startTime;
        this.values.endTime = endTime;
        this.onChange();
    };

    render() {
        let {onDateChange, onReturnDateChange, onTimeRangeChange} = this;
        let {date, endTime, returnDate, startTime} = this.values;
        let {direction, includeReturnDate, spacing, title, variant} = this.props;

        return (
            <OneTimeScheduleInputView
                direction={direction}
                initialDate={date}
                initialEndTime={endTime}
                initialStartTime={startTime}
                includeReturnDate={includeReturnDate}
                onDateChange={onDateChange}
                onReturnDateChange={onReturnDateChange}
                onTimeRangeChange={onTimeRangeChange}
                initialReturnDate={returnDate}
                spacing={spacing}
                title={title}
                variant={variant}/>
        )
    }
}

OneTimeScheduleInput.defaultProps = {
    includeReturnDate: false,
    spacing: 3,
    title: 'Preference 1:',
    variant: InputBaseVariant.OUTLINED
};

OneTimeScheduleInput.propTypes = {
    includeReturnDate: PropTypes.bool,
    initialSchedule: PropTypes.shape({
        arrivalEndTime: PropTypes.instanceOf(moment),
        arrivalStartTime: PropTypes.instanceOf(moment),
        date: PropTypes.instanceOf(moment),
        returnDate: PropTypes.instanceOf(moment)
    }),
    onChange: PropTypes.func.isRequired,
    spacing: PropTypes.number,
    title: PropTypes.string,
    variant: CustomPropTypes.enum(InputBaseVariant)
};

export default OneTimeScheduleInput;