import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "../Button";
import FileUploader from "../FileUploader";
import {DeepCopy, ValueIsSet} from "../../Helpers";
import {Color} from "../../Enums";
import {WithContext} from "../../context/GlobalContext";

const t = require('tcomb-form');
const Form = t.form.Form;

const PositiveNonZero = t.subtype(t.Number, (n) => n > 0);
const NonNegative = t.subtype(t.Number, (n) => n >= 0);
const Dollars = t.subtype(t.String, (n) => Number(n.replace('$', '').replace(/,/g, '')) >= 0);

const ticketTypeFormOption = {
    fields: {
        value: {
            label: 'Ticket Type'
        }
    }
};

class FormBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submit: props.submit,
            submitButtonText: props.submitButtonText,
            submitButtonDisabledText: props.submitButtonDisabledText,
            formHeader: props.formHeader,
            injectedParentValuesThatSetChild: props.injectedParentValuesThatSetChild,
            injectedListOptions: props.injectedListOptions,
            injectedHelperQualifiers: props.injectedHelperQualifiers,
            injectedFunctionTriggers: props.injectedFunctionTriggers,
            showAttachments: props.showAttachments,
            generalErrorMessage: props.generalErrorMessage,
            fieldSettings: props.fieldSettings,
            fieldSettingsByGroupAndKey: {},
            fieldSettingsByGroupAndFieldId: {},
            fieldSettingsByGroup: {},
            value: props.startingValue ? Object.assign({}, props.startingValue) : {},
            ticketType: {
                value: (props.startingValue && props.startingValue.ticketType) ? props.startingValue.ticketType : null
            },
            fullStandardPricingsList: props.fullStandardPricingsList,
            standardPricings: props.standardPricings,
            validationErrorMessage: 'Please fill out the form before continuing.',
            showErrorMessage: false,
            files: [],
            ticketFormStruct: t.struct({}),
            ticketFormOptions: {},
            serviceLineName: props.serviceLineName,
            submitDisabled: props.submitDisabled,
            useSecondaryButton: props.useSecondaryButton,
            secondarySubmit: props.secondarySubmit,
            secondaryButtonText: props.secondaryButtonText,
            secondaryButtonDisabledText: props.secondaryButtonDisabledText,
            secondaryButtonRequiresValidation: props.secondaryButtonRequiresValidation,
            useItemsList: props.useItemsList !== null && props.useItemsList !== undefined ? props.useItemsList : true,
            addItemButtonName: props.addItemButtonName ? props.addItemButtonName : 'Add Item'
        };

        this.fieldIsShowing = {};
        this.ticketTypeForm = t.struct({});
        this.moreThanOneTicketType = true;
        this.itemsListFieldName = "obligationItems";

        this.setUpStartingValues = this.setUpStartingValues.bind(this);
        this.getFileData = this.getFileData.bind(this);
        this.submit = this.submit.bind(this);
        this.secondarySubmit = this.secondarySubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.selectTicketType = this.selectTicketType.bind(this);
        this.getFieldType = this.getFieldType.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.getCurrentListOptions = this.getCurrentListOptions.bind(this);
        this.getTemplateType = this.getTemplateType.bind(this);
        this.buildTicketForm = this.buildTicketForm.bind(this);
        this.setupNewField = this.setupNewField.bind(this);
        this.getCorrectTypeOption = this.getCorrectTypeOption.bind(this);
        this.determineFieldState = this.determineFieldState.bind(this);
        this.evaluateSingleParent = this.evaluateSingleParent.bind(this);
    }

    componentWillMount() {
        let {fieldSettings, ticketType, injectedParentValuesThatSetChild, injectedListOptions, value} = this.state;
        let _this = this;

        let fieldSettingsByGroupAndKey = {};
        let fieldSettingsByGroupAndFieldId = {};
        let fieldSettingsByGroup = {};

        fieldSettings.forEach(function(setting) {
            //This will allow the settings to be easily accessed in the item list template
            if (!fieldSettingsByGroupAndKey[setting.group])
                fieldSettingsByGroupAndKey[setting.group] = {};
            fieldSettingsByGroupAndKey[setting.group][setting.field.metadataName] = setting;

            if (!fieldSettingsByGroupAndFieldId[setting.group])
                fieldSettingsByGroupAndFieldId[setting.group] = {};
            fieldSettingsByGroupAndFieldId[setting.group][setting.field.id] = setting;

            if (setting.parentValuesThatSetChildValue && _this.isJsonString(setting.parentValuesThatSetChildValue))
                setting.parentValuesThatSetChildValue = JSON.parse(setting.parentValuesThatSetChildValue);

            if (setting.listOptions && _this.isJsonString(setting.listOptions))
                setting.listOptions = JSON.parse(setting.listOptions);

            if (setting.parentValuesThatShowChild && _this.isJsonString(setting.parentValuesThatShowChild))
                setting.parentValuesThatShowChild = JSON.parse(setting.parentValuesThatShowChild);

            if (setting.parentValuesThatHideChild && _this.isJsonString(setting.parentValuesThatHideChild))
                setting.parentValuesThatHideChild = JSON.parse(setting.parentValuesThatHideChild);

            if (setting.parentValuesThatDisableChild && _this.isJsonString(setting.parentValuesThatDisableChild))
                setting.parentValuesThatDisableChild = JSON.parse(setting.parentValuesThatDisableChild);

            if (setting.parentValuesThatEnableChild && _this.isJsonString(setting.parentValuesThatEnableChild))
                setting.parentValuesThatEnableChild = JSON.parse(setting.parentValuesThatEnableChild);

            //Field settings need to be organized into their respect groups so that the different form type options can be set
            if (!fieldSettingsByGroup[setting.group]) {
                fieldSettingsByGroup[setting.group] = {
                    settings: [],
                    listSettings: []
                }
            }

            if (setting.listItemField)
                fieldSettingsByGroup[setting.group].listSettings.push(setting);
            else
                fieldSettingsByGroup[setting.group].settings.push(setting);

            if (setting.type === 'Items')
                _this.itemsListFieldName = setting.field.metadataName;
        });

        //Have to call this a second time now that fieldSettingsByGroupAndKey is fully set up
        fieldSettings.forEach(function (setting) {
            if (injectedParentValuesThatSetChild && ((injectedParentValuesThatSetChild['defaultOptions'] && injectedParentValuesThatSetChild['defaultOptions'][setting.field.metadataName]) || (injectedParentValuesThatSetChild[setting.group] && injectedParentValuesThatSetChild[setting.group][setting.field.metadataName]))) {
                if (!setting.parentValuesThatSetChildValue)
                    setting.parentValuesThatSetChildValue = {};

                //If a special case exists for a specific field in a specific group it takes precedence
                if (injectedParentValuesThatSetChild[setting.group] && injectedParentValuesThatSetChild[setting.group][setting.field.metadataName]) {
                    for (let key in injectedParentValuesThatSetChild[setting.group][setting.field.metadataName]) {
                        if (injectedParentValuesThatSetChild[setting.group][setting.field.metadataName].hasOwnProperty(key))
                            setting.parentValuesThatSetChildValue[fieldSettingsByGroupAndKey[setting.group][key].field.id] = injectedParentValuesThatSetChild[setting.group][setting.field.metadataName][key];
                    }
                }
                else if (injectedParentValuesThatSetChild['defaultOptions'] && injectedParentValuesThatSetChild['defaultOptions'][setting.field.metadataName]) {
                    for (let key in injectedParentValuesThatSetChild['defaultOptions'][setting.field.metadataName]) {
                        if (injectedParentValuesThatSetChild['defaultOptions'][setting.field.metadataName].hasOwnProperty(key))
                            setting.parentValuesThatSetChildValue[fieldSettingsByGroupAndKey[setting.group][key].field.id] = injectedParentValuesThatSetChild['defaultOptions'][setting.field.metadataName][key];
                    }
                }
            }

            if (injectedListOptions && injectedListOptions[setting.field.metadataName]) {
                let combinedListOptions = {};

                let listOptions = setting.listOptions;
                let listOptionsToAdd = injectedListOptions[setting.field.metadataName];
                let mappedListOptionsToAdd = {
                    defaultOptions: {}
                };

                //The list options to add will come in using the field name as the key instead of the ID so we need to remap the list options to their respective field IDs
                for (let key in listOptionsToAdd) {
                    if (listOptionsToAdd.hasOwnProperty(key)) {
                        if (fieldSettingsByGroupAndKey[setting.group][key] !== null && fieldSettingsByGroupAndKey[setting.group][key] !== undefined)
                            mappedListOptionsToAdd[fieldSettingsByGroupAndKey[setting.group][key].field.id] = listOptionsToAdd[key];
                        else if (key === 'defaultOptions')
                            mappedListOptionsToAdd.defaultOptions = Object.assign(mappedListOptionsToAdd.defaultOptions, listOptionsToAdd.defaultOptions);
                        //If a field referenced in the list options isn't present then we will add the options that field would have filtered to the default options
                        else {
                            for (let innerKey in listOptionsToAdd[key]) {
                                if (listOptionsToAdd[key].hasOwnProperty(innerKey)) {
                                    mappedListOptionsToAdd.defaultOptions = Object.assign(mappedListOptionsToAdd.defaultOptions, listOptionsToAdd[key][innerKey]);
                                }
                            }
                        }
                    }
                }

                //Now we combine the original list options with the injected list options giving precedence to the injected list options to override any duplicates found in the original
                for (let key in listOptions) {
                    if (listOptions.hasOwnProperty(key)) {
                        if (key === 'defaultOptions') {
                            if (mappedListOptionsToAdd[key] !== null && mappedListOptionsToAdd[key] !== undefined)
                                combinedListOptions[key] = Object.assign(listOptions[key], mappedListOptionsToAdd[key]);
                            else
                                combinedListOptions[key] = listOptions[key];
                        } else {
                            combinedListOptions[key] = {};
                            for (let innerKey in listOptions[key]) {
                                if (listOptions[key].hasOwnProperty(innerKey)) {
                                    if (mappedListOptionsToAdd[key] !== null && mappedListOptionsToAdd[key] !== undefined) {
                                        if (mappedListOptionsToAdd[key][innerKey] !== null && mappedListOptionsToAdd[key][innerKey] !== undefined)
                                            combinedListOptions[key][innerKey] = Object.assign(listOptions[key][innerKey], mappedListOptionsToAdd[key][innerKey]);
                                        else
                                            combinedListOptions[key][innerKey] = listOptions[key][innerKey];
                                    } else {
                                        combinedListOptions[key] = listOptions[key];
                                    }
                                }
                            }
                        }
                    }
                }

                //We also need to go through the injected and add any new options to the combined list
                for (let key in mappedListOptionsToAdd) {
                    if (mappedListOptionsToAdd.hasOwnProperty(key)) {
                        if (combinedListOptions[key] === null || combinedListOptions[key] === undefined) {
                            combinedListOptions[key] = mappedListOptionsToAdd[key];
                        }
                    }
                }

                setting.listOptions = combinedListOptions;
            }
        });

        let ticketTypeEnums = {};
        let numberOfGroups = 0;
        let lastKey;

        for (let key in fieldSettingsByGroup) {
            fieldSettingsByGroup[key].settings.sort(function(a, b) {
                return parseInt(a.order, 10) - parseInt(b.order, 10);
            });

            fieldSettingsByGroup[key].listSettings.sort(function(a, b) {
                return parseInt(a.order, 10) - parseInt(b.order, 10);
            });

            ticketTypeEnums[key] = key;

            numberOfGroups++;
            lastKey = key;
        }

        if (numberOfGroups > 1) {
            //If we have more than one group of settings, then we will need to give the user the option to pick between ticket types
            _this.ticketTypeForm = t.struct({
                value: t.enums(ticketTypeEnums)
            });

            _this.moreThanOneTicketType = true;
        } else {
            _this.moreThanOneTicketType = false;

            value = _this.setUpStartingValues(fieldSettingsByGroup[lastKey]);

            ticketType.value = lastKey;
        }

        _this.setState({fieldSettingsByGroupAndKey, fieldSettingsByGroupAndFieldId, fieldSettingsByGroup, ticketType, fieldSettings, value}, () => {
            _this.buildTicketForm();
            let value = _this.state.value;
            let valueChanged = false;

            if (_this.state.useItemsList && !_this.moreThanOneTicketType && (!value[_this.itemsListFieldName] || value[_this.itemsListFieldName].length === 0) && !ValueIsSet(this.props.addItemButtonFunction))
                _this.addFunction();

            //This can't be correctly evaluated until the form has been built once
            //This is really only useful in the case where they only had one group option so the form is built for them
            //and even then it is only useful if they only had one group of options to begin with because this will run through all fields of all groups
            //in the case where there are multiple groups the values when hidden will be correctly set the first time a change is made to the form
            fieldSettings.forEach(function(setting) {
                if (!setting.listItemField && setting.valueWhenHidden !== null && setting.valueWhenHidden !== undefined && (setting.parentValuesThatShowChild || setting.parentValuesThatHideChild) && !_this.fieldIsShowing[setting.field.metadataName] && value[setting.field.metadataName] !== setting.valueWhenHidden) {
                    if (setting.valueWhenHidden.toLowerCase() === 'null') {
                        if (value[setting.field.metadataName] !== undefined) {
                            value[setting.field.metadataName] = undefined;
                            valueChanged = true;
                        }
                    }
                    else {
                        value[setting.field.metadataName] = setting.valueWhenHidden;
                        valueChanged = true;
                    }
                }
            });

            if (valueChanged)
                _this.setState({value}, () => _this.buildTicketForm());
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({generalErrorMessage: nextProps.generalErrorMessage, submitButtonDisabledText: nextProps.submitButtonDisabledText, submitDisabled: nextProps.submitDisabled, injectedParentValuesThatSetChild: nextProps.injectedParentValuesThatSetChild});
    }

    //Just checks if an object is empty
    isEmpty(obj) {
        for(let key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    setUpStartingValues(settings) {
        let {value} = this.state;
        let _this = this;

        settings.settings.forEach(function(setting) {
            if ((!_this.props.startingValue || _this.isEmpty(_this.props.startingValue)) && setting.startingValue !== null && setting.startingValue !== undefined) {
                value[setting.field.metadataName] = setting.startingValue;
            }
        });

        return value;
    }

    getFileData(fileData, index) {
        let {files} = this.state;
        files[index] = fileData;
        this.setState({files});
    }

    removeItem(button, index) {
        let {files} = this.state;
        files.splice(index, 1);
        this.setState({files});
        button.click();
    }

    submit() {
        let validation = this.refs.form.validate();

        if(validation.errors.length === 0) {
            this.setState({submitDisabled: true}, () => {
                this.state.submit(this.state.value, this.state.ticketType.value, this.state.files);
            });
        } else {
            this.setState({generalErrorMessage: "Please fill out the form before submitting."})
        }
    }

    secondarySubmit() {
        let {secondaryButtonRequiresValidation} = this.state;
        let _this = this;

        if (secondaryButtonRequiresValidation) {
            let validation = this.refs.form.validate();

            if(validation.errors.length === 0) {
                this.setState({submitDisabled: true}, () => {
                    _this.state.secondarySubmit(this.state.value, this.state.ticketType.value, this.state.files);
                });
            } else {
                _this.setState({generalErrorMessage: "Please fill out the form before submitting."})
            }
        } else {
            this.setState({submitDisabled: true}, () => {
                _this.state.secondarySubmit(this.state.value, this.state.ticketType.value, this.state.files);
            });
        }
    }

    isJsonString(string) {
        try {
            JSON.parse(string);
        } catch (e) {
            return false;
        }
        return true;
    }

    // updatedFieldInfo follows the pattern:
    // non-list item field: ['fieldName']
    // list item field: ['listName', index, 'fieldName']
    // new item added to item list: ['listName', index]
    onChange(value, updatedFieldInfo, skipFunctionTriggers) {
        let {fieldSettingsByGroupAndKey, ticketType, injectedFunctionTriggers} = this.state;
        const {client} = this.props.context;
        let {itemsListFieldName} = this;
        let _this = this;

        let listItemFieldUpdated = updatedFieldInfo ? !!updatedFieldInfo[2] : false;
        let updatedFieldName = updatedFieldInfo ? (listItemFieldUpdated ? updatedFieldInfo[2] : updatedFieldInfo[0]) : null;

        //This can be done in a better way assuming we have time to add new options, this handles the case where the customerCharge needs to be updated to reflect the standardPricing after being unhidden by the priceClientCharged field
        if ((updatedFieldName === 'priceClientCharged' && (value[updatedFieldInfo[0]][updatedFieldInfo[1]].priceClientCharged === 'Not Waived' || value[updatedFieldInfo[0]][updatedFieldInfo[1]].priceClientCharged === 'Client Charged'))) {
            // For SelfService clients only update the customerCharge if the standardItem is not a standardItem with multiple alterations.
            if (client.isSelfService) {
                if (!ValueIsSet(value[updatedFieldInfo[0]][updatedFieldInfo[1]].standardPricingIds)) {
                    value[updatedFieldInfo[0]][updatedFieldInfo[1]].customerCharge = fieldSettingsByGroupAndKey[ticketType.value].customerCharge.parentValuesThatSetChildValue[fieldSettingsByGroupAndKey[ticketType.value].standardPricingId.field.id][value[updatedFieldInfo[0]][updatedFieldInfo[1]].standardPricingId];
                }
            } else {
                value[updatedFieldInfo[0]][updatedFieldInfo[1]].customerCharge = fieldSettingsByGroupAndKey[ticketType.value].customerCharge.parentValuesThatSetChildValue[fieldSettingsByGroupAndKey[ticketType.value].standardPricingId.field.id][value[updatedFieldInfo[0]][updatedFieldInfo[1]].standardPricingId];
            }
        }

        if (!skipFunctionTriggers && injectedFunctionTriggers && injectedFunctionTriggers[updatedFieldName] && (injectedFunctionTriggers[updatedFieldName][value[updatedFieldName]] || (value[itemsListFieldName] && value[itemsListFieldName][updatedFieldInfo[1]] && injectedFunctionTriggers[updatedFieldName][value[itemsListFieldName][updatedFieldInfo[1]][updatedFieldName]]))) {
            let targetValue = listItemFieldUpdated ? value[itemsListFieldName][updatedFieldInfo[1]][updatedFieldName] : value[updatedFieldName];
            injectedFunctionTriggers[updatedFieldName][targetValue](DeepCopy(value), DeepCopy(this.state.value), updatedFieldInfo[1], (value) => {_this.onChange(value, updatedFieldInfo, true)});
        }

        //Leaving this in here for now so that hidden values of the form can be tracked in production
        console.log(value);
        
        this.setState({value}, () => {
            _this.buildTicketForm();

            let valueChanged = false;

            for (let key in fieldSettingsByGroupAndKey[ticketType.value]) {
                if (fieldSettingsByGroupAndKey[ticketType.value].hasOwnProperty(key)) {
                    let setting = fieldSettingsByGroupAndKey[ticketType.value][key];

                    if (updatedFieldName && setting.parentValuesThatSetChildValue && setting.parentValuesThatSetChildValue[fieldSettingsByGroupAndKey[setting.group][updatedFieldName].field.id]) {
                        let parentValuesThatSetChildValue = setting.parentValuesThatSetChildValue[fieldSettingsByGroupAndKey[setting.group][updatedFieldName].field.id];
                        let updatedFieldValue = listItemFieldUpdated ? value[updatedFieldInfo[0]][updatedFieldInfo[1]][updatedFieldName] : value[updatedFieldName];
                        updatedFieldValue = typeof updatedFieldValue === 'string' ? updatedFieldValue.toLowerCase() : updatedFieldValue;

                        // onChange will have to be called recursively if any fields are changed because of parentValuesThatSetChildValue to handle any cascading field value changes
                        if (parentValuesThatSetChildValue[updatedFieldValue] || parentValuesThatSetChildValue[updatedFieldValue] == '0') {
                            //inner -> inner
                            if (setting.listItemField && listItemFieldUpdated) {
                                value[updatedFieldInfo[0]][updatedFieldInfo[1]][setting.field.metadataName] = parentValuesThatSetChildValue[updatedFieldValue];
                                _this.onChange(value, [updatedFieldInfo[0], updatedFieldInfo[1], setting.field.metadataName]);
                                valueChanged = true;
                            }
                            //inner -> outer
                            else if (setting.listItemField && !listItemFieldUpdated && value[_this.itemsListFieldName] && value[_this.itemsListFieldName].length > 0) {
                                value[_this.itemsListFieldName].forEach(function (item, i) {
                                    item[setting.field.metadataName] = parentValuesThatSetChildValue[updatedFieldValue];
                                    _this.onChange(value, [_this.itemsListFieldName, i, setting.field.metadataName]);
                                });

                                valueChanged = true;
                            }
                            //outer -> inner
                            else if (!setting.listItemField && listItemFieldUpdated) {
                                let valueShouldBeUpdated = false;

                                if (setting.parentListItemValuesMustAllPass) {
                                    valueShouldBeUpdated = value[_this.itemsListFieldName].every(function (item) {
                                        return item[updatedFieldName] == updatedFieldValue;
                                    })
                                } else {
                                    valueShouldBeUpdated = value[_this.itemsListFieldName].some(function (item) {
                                        return item[updatedFieldName] == updatedFieldValue;
                                    })
                                }

                                if (valueShouldBeUpdated && value[_this.itemsListFieldName] && value[_this.itemsListFieldName].length > 0) {
                                    value[setting.field.metadataName] = parentValuesThatSetChildValue[updatedFieldValue];
                                    _this.onChange(value, [setting.field.metadataName]);
                                    valueChanged = true;
                                }
                            }
                            //outer -> outer
                            else if (!setting.listItemField && !listItemFieldUpdated) {
                                value[setting.field.metadataName] = parentValuesThatSetChildValue[updatedFieldValue];
                                _this.onChange(value, [setting.field.metadataName]);
                                valueChanged = true;
                            }
                        }
                    }

                    //hidden values for item list fields will be handled in the itemTemplate
                    if (!setting.listItemField && setting.valueWhenHidden !== null && setting.valueWhenHidden !== undefined && (setting.parentValuesThatShowChild || setting.parentValuesThatHideChild) && !_this.fieldIsShowing[setting.field.metadataName] && value[setting.field.metadataName] !== setting.valueWhenHidden) {
                        if (setting.valueWhenHidden.toLowerCase() === 'null') {
                            if (value[setting.field.metadataName] !== undefined) {
                                value[setting.field.metadataName] = undefined;
                                valueChanged = true;
                            }
                        }
                        else {
                            value[setting.field.metadataName] = setting.valueWhenHidden;
                            valueChanged = true;
                        }
                    }

                    if (setting.type === 'Dollars') {
                        if (setting.listItemField && value[_this.itemsListFieldName] && value[_this.itemsListFieldName].length > 0) {
                            value[_this.itemsListFieldName].forEach(function (item) {
                                if (item[setting.field.metadataName] !== null && item[setting.field.metadataName] !== undefined && typeof item[setting.field.metadataName] === 'string')
                                    item[setting.field.metadataName] = item[setting.field.metadataName].replace('$', '').replace(/,/g, '');
                            })
                        } else if (value[_this.itemsListFieldName] !== null && value[_this.itemsListFieldName] !== undefined && typeof value[_this.itemsListFieldName] === 'string'){
                            value[_this.itemsListFieldName] = value[_this.itemsListFieldName].replace('$', '').replace(/,/g, '');
                        }
                    }
                }
            }

            //Case where a new item was added to the list of items
            if (!listItemFieldUpdated && updatedFieldInfo && (updatedFieldInfo[1] || updatedFieldInfo[1] == 0))
                valueChanged = true;

            //If value is changed because of newly hidden field we have to rebuild and reevaluate the form
            if (valueChanged)
                _this.onChange(value);
        });
    }

    selectTicketType(ticketTypeValue) {
        let {fieldSettingsByGroup, serviceLineName, ticketType, value, useItemsList} = this.state;
        let _this = this;

        let formValue = {};

        //This is the case where the ticket was originally created by the vendor, so the client needs to pick a ticket type on their end, but we want to retain any info the vendor input
        if (serviceLineName !== null && serviceLineName !== undefined) {
            if (ticketType.value === serviceLineName && !fieldSettingsByGroup[ticketType.value]) {
                formValue = value;
            }
        }

        this.setState({ticketType: {value: ticketTypeValue}, value: formValue}, () => {
            _this.onChange(_this.setUpStartingValues(fieldSettingsByGroup[ticketTypeValue]));
            if (useItemsList && !ValueIsSet(this.props.addItemButtonFunction))
                _this.addFunction();
        });
    }

    fieldHasRowAndColumnSet(fieldSetting) {
        return (fieldSetting.row || fieldSetting.row === 0) && (fieldSetting.column || fieldSetting.column === 0)
    }

    getFieldType(fieldSetting, hidden) {
        let _this = this;

        let type = fieldSetting.type;
        let status = fieldSetting.status;
        let listOptions = fieldSetting.listOptions ? fieldSetting.listOptions : {};
        let minLength = fieldSetting.minLength;
        let maxLength = fieldSetting.maxLength;
        let group = fieldSetting.group;

        let fieldType;

        switch(type) {
            case 'Text Area':
            case 'String':
            case 'Password':
                fieldType = t.String;
                break;
            case 'Positive Non-zero Number':
                fieldType = PositiveNonZero;
                break;
            case 'Non-negative Number':
                fieldType = NonNegative;
                break;
            case 'Dollars':
                fieldType = Dollars;
                break;
            case 'Any Number':
                fieldType = t.Number;
                break;
            case 'Boolean':
                fieldType = t.Boolean;
                break;
            case 'List':
                fieldType = t.enums(_this.getCurrentListOptions(listOptions, group));
                break;
            case 'Date':
                fieldType = t.Date;
                break;
            default:
                fieldType = t.String;
                break;
        }

        if (minLength) {
            fieldType = t.subtype(fieldType, (n) => n.toString().length >= minLength);
        }

        if (maxLength) {
            fieldType = t.subtype(fieldType, (n) => n.toString().length <= maxLength);
        }

        switch(status) {
            case 'Not Used':
                return;
            case 'Required':
                //If a field is hidden we can't expect it to be required
                //If a hidden field still needs to have a specific value when hidden use valueWhenHidden or parentValuesThatSetChildValue
                if (hidden)
                    return t.maybe(fieldType);
                return fieldType;
            case 'Not Required':
            default:
                return t.maybe(fieldType);
        }
    }

    getCurrentListOptions(listOptions, group) {
        let {fieldSettingsByGroupAndFieldId} = this.state;
        let _this = this;
        let currentListOptions = {};

        for (let key in listOptions) {
            if (listOptions.hasOwnProperty(key)) {
                if (key === 'defaultOptions')
                    currentListOptions = Object.assign(currentListOptions, listOptions.defaultOptions);
                else {
                    let targetValue = _this.state.value[fieldSettingsByGroupAndFieldId[group][key].field.metadataName];
                    for (let innerKey in listOptions[key]) {
                        if (listOptions[key].hasOwnProperty(innerKey)) {
                            if (targetValue !== null && targetValue !== undefined && targetValue.toString().toLowerCase() === innerKey.toString().toLowerCase()) {
                                currentListOptions = Object.assign(currentListOptions, listOptions[key][innerKey]);
                            }
                        }
                    }
                }
            }
        }

        return currentListOptions;
    }

    getTemplateType(type) {
        switch(type) {
            case 'Text Area':
            case 'String':
            case 'Positive Non-zero Number':
            case 'Non-negative Number':
            case 'Any Number':
            case 'Password':
                return {
                    template: Form.templates.textbox,
                    renderFunctionName: "renderInput"
                };
            case 'Boolean':
                return {
                    template: Form.templates.checkbox,
                    renderFunctionName: "renderCheckbox"
                };
            case 'List':
                return {
                    template: Form.templates.select,
                    renderFunctionName: "renderSelect"
                };
            case 'Date':
                return {
                    template: Form.templates.date,
                    renderFunctionName: "renderDate"
                };
            default:
                return {
                    template: Form.templates.textbox,
                    renderFunctionName: "renderInput"
                };
        }
    }

    buildTicketForm() {
        let {fieldSettingsByGroup, ticketType, fullStandardPricingsList} = this.state;
        let _this = this;
        let ticketFormStruct = {};
        let ticketFormOptions = {
            auto: 'none',
            fields: {}
        };

        let selectedTicketTypeSettings = fieldSettingsByGroup[ticketType.value];

        if (selectedTicketTypeSettings) {
            //Go through the settings for each field in the selected group and build the fields as dictated by the settings
            //When we hit the 'Items' field we will have to build the inner fields for the list items
            selectedTicketTypeSettings.settings.forEach(function (fieldSetting) {
                if (fieldSetting.type === 'Items') {
                    let listFormStruct = {};
                    let listFormOptions = {
                        auto: 'none',
                        fields: {}
                    };

                    selectedTicketTypeSettings.listSettings.forEach(function (setting) {
                        if (setting.field.metadataName !== 'standardPricingId' || (fullStandardPricingsList && fullStandardPricingsList.length > 0))
                            // prevents the "Assigned Tailor" field from being rendered when the select client location does not have any location tailors.
                            if (!(setting.field.metadataName === 'individualItemAssignedTailor' && _this.props.locationTailors.length === 0)) {
                                _this.setupNewField(listFormStruct, listFormOptions, setting);
                            }
                    });

                    listFormOptions.template = _this.itemTemplate;

                    ticketFormStruct[fieldSetting.field.metadataName] = t.list(t.struct(listFormStruct));
                    ticketFormOptions.fields[fieldSetting.field.metadataName] = {
                        template: _this.itemListTemplate,
                        disableOrder: true,
                        disableAdd: true,
                        label: fieldSetting.customName ? fieldSetting.customName : fieldSetting.field.name,
                        item: listFormOptions
                    }

                } else if (fieldSetting.status !== 'Not Used') {
                    _this.setupNewField(ticketFormStruct, ticketFormOptions, fieldSetting);
                }
            });

            ticketFormStruct = t.struct(ticketFormStruct);

            _this.setState({ticketFormStruct, ticketFormOptions});
        }
    };

    setupNewField(ticketFormStruct, ticketFormOptions, fieldSetting) {
        let _this = this;
        let {isSelfService} = this.props;

        // If the client_ticket_field_option "individualItemAssignedTailor" is set up populate the options with the locationTailors for the selected client location.
        if (fieldSetting.field.metadataName === "individualItemAssignedTailor") {
            let tailorOptions = {};
            _this.props.locationTailors.forEach(tailor => {
                return tailorOptions[tailor.id] = tailor.name;
            });
            fieldSetting.listOptions = {
                defaultOptions: tailorOptions
            };
        }

        let typeOption = _this.getCorrectTypeOption(fieldSetting);
        let fieldType = _this.getFieldType(fieldSetting, typeOption === 'hidden');

        if (typeOption !== 'hidden' || fieldType === t.String) {
            //Create the individual field
            ticketFormStruct[fieldSetting.field.metadataName] = fieldType;

            let fieldName = fieldSetting.customName ? fieldSetting.customName : fieldSetting.field.name;

            let fieldTemplate;
            if ((fieldSetting.columnWidth || fieldSetting.preQualifierString || fieldSetting.postQualifierString || fieldSetting.type === 'Header')) {
                let fieldWidth = fieldSetting.columnWidth ? (_this.fieldHasRowAndColumnSet(fieldSetting) ? (fieldSetting.postQualifierString ? 6 : 12) : parseInt(fieldSetting.columnWidth, 10)) : (fieldSetting.postQualifierString ? 6 : 12);
                let postQualifierWidth = 12 - fieldWidth;

                let templateInfo = _this.getTemplateType(fieldSetting.type);

                let renderObject = {};
                renderObject[templateInfo.renderFunctionName] = (locals) => {
                    return (
                        fieldSetting.type === 'Header' ?
                        <label style={{'margin-top': '15px'}}>
                            <h5>{fieldSetting.customName ? fieldSetting.customName : fieldSetting.field.name}</h5>
                        </label>
                            :
                        <div className={'row'}>
                            <div className={'col-md-' + fieldWidth.toString()}>
                                {fieldSetting.preQualifierString ?
                                    <div style={{display: 'table', width: '100%'}}>
                                        <span style={{
                                            display: 'table-cell',
                                            width: fieldSetting.preQualifierString.length + 'rem'
                                        }}>{fieldSetting.preQualifierString}</span>
                                        <input style={{display: 'table-cell'}} {...locals.attrs}/>
                                    </div>
                                    :
                                    fieldSetting.type !== "Boolean" ?
                                        <input {...locals.attrs}/>
                                        :
                                        <label>
                                            <input type={'checkbox'} {...locals.attrs}/>
                                            {locals.label}
                                        </label>
                                }
                            </div>
                            <div className={'col-md-' + postQualifierWidth.toString()} style={{
                                'marginLeft': '-20px',
                                'paddingTop': '5px'
                            }}>{fieldSetting.postQualifierString}</div>
                        </div>
                    )
                };

                fieldTemplate = templateInfo.template.clone(renderObject);
            }

            let isDisabled = false;
            if (!fieldSetting.listItemField && (fieldSetting.parentValuesThatDisableChild || fieldSetting.parentValuesThatEnableChild))
                isDisabled = !_this.determineFieldState(fieldSetting, null, 2);

            //Set up the individual field options
            ticketFormOptions.fields[fieldSetting.field.metadataName] = {
                label: fieldSetting.hideLabel || fieldSetting.type === 'Header' ? null : (fieldSetting.status === 'Required' ? fieldName + ' *' : fieldName),
                error: <div style={{color: Color.RED}}>{fieldSetting.validationMessage}</div>,
                help: <i>{fieldSetting.helpMessage}</i>,
                disabled: fieldSetting.status === 'Disabled' ? true : isDisabled,
                type: typeOption,
                attrs: {
                    placeholder: (fieldSetting.placeholderString && fieldSetting.status === 'Required') ? fieldSetting.placeholderString + ' *' : fieldSetting.placeholderString
                },
                template: fieldTemplate
            };

            //Custom override of form fields for isSelfService
            if(fieldSetting.type === 'Date' && isSelfService){
                ticketFormOptions.fields[fieldSetting.field.metadataName].order = ['YY', 'M', 'D'];
                if(fieldSetting.field.metadataName === "transactionDate") {
                    ticketFormOptions.fields[fieldSetting.field.metadataName].label = "Transaction Date (YYYY/MM/DD)"
                }
            }

            if (fieldSetting.type === 'List') {
                let optionsObject = this.getCurrentListOptions(
                    fieldSetting.listOptions ? fieldSetting.listOptions : {},
                    fieldSetting.group
                );


                ticketFormOptions.fields[fieldSetting.field.metadataName].options = Object.keys(optionsObject).reduce((optionsArray, nextOption) => {
                    let tempObject = {
                        value: nextOption,
                        text: optionsObject[nextOption]
                    };
                    optionsArray.push(tempObject);
                    return optionsArray;
                }, []).sort((a, b) => a.text.localeCompare(b.text));
            }
        }
    }

    getCorrectTypeOption(fieldSetting) {
        let _this = this;

        //The type to display if it is determined the field shouldn't be hidden
        let notHiddenType;

        switch (fieldSetting.type) {
            case 'Text Area':
                notHiddenType = 'textarea';
                break;
            case 'Password':
                notHiddenType = 'password';
                break;
            default:
                notHiddenType = '';
                break;
        }

        //The case where this field should be hidden for all cases
        if (fieldSetting.status === 'Hidden')
            return 'hidden';

        //The case where the field checks its parent to know if it should be hidden or not
        //If this setting is for a field in the list of items, don't handle it here. It will be handled in the item template
        else if ((fieldSetting.parentValuesThatShowChild || fieldSetting.parentValuesThatHideChild) && !fieldSetting.listItemField) {
            if (_this.determineFieldState(fieldSetting, null, 1))
                return notHiddenType;
            else
                return 'hidden';
        }

        //The case where no rules are set for hiding the field
        return notHiddenType;
    }

    // There are four types of child to parent associations we can have set up.
    // Assuming 'outer' are the fields outside the item list and 'inner' are the fields inside the item list we can have: outer->outer, outer->inner, inner->inner, inner->outer
    // localsValue only needs to be passed in if the child field is part of a list item
    // NOTE: if you want to use this function to check the visibility of a list item field that has another list item field as a parent it has to be called form the item template so the locals can be passed in
    determineFieldState(childSetting, localsValue, stateToDetermine) {
        let {fieldSettingsByGroupAndFieldId} = this.state;
        let _this = this;
        let results = [];

        let parentOptions;
        switch (stateToDetermine) {
            //in this case this function returns true if the child should be shown
            case 1:
                parentOptions = childSetting.parentValuesThatShowChild ? childSetting.parentValuesThatShowChild : childSetting.parentValuesThatHideChild;
                break;
            //in this case the function returns true if the child should be enabled
            case 2:
                parentOptions = childSetting.parentValuesThatDisableChild ? childSetting.parentValuesThatDisableChild : childSetting.parentValuesThatEnableChild;
                break;
        }

        for (let key in parentOptions) {
            if (parentOptions.hasOwnProperty(key)) {
                let parentSetting = fieldSettingsByGroupAndFieldId[childSetting.group][key];

                let parentValuesThatSetPositiveState;
                let parentValuesThatSetNegativeState;

                switch (stateToDetermine) {
                    //in this case this function returns true if the child should be shown
                    case 1:
                        parentValuesThatSetPositiveState = childSetting.parentValuesThatShowChild ? childSetting.parentValuesThatShowChild[parentSetting.field.id] : null;
                        parentValuesThatSetNegativeState = childSetting.parentValuesThatHideChild ? childSetting.parentValuesThatHideChild[parentSetting.field.id] : null;
                        break;
                    //in this case the function returns true if the child should be enabled
                    case 2:
                        parentValuesThatSetPositiveState = childSetting.parentValuesThatEnableChild ? childSetting.parentValuesThatEnableChild[parentSetting.field.id] : null;
                        parentValuesThatSetNegativeState = childSetting.parentValuesThatDisableChild ? childSetting.parentValuesThatDisableChild[parentSetting.field.id] : null;
                        break;
                }

                //inner -> inner
                if (childSetting.listItemField && parentSetting.listItemField)
                    results.push(_this.evaluateSingleParent(childSetting, parentSetting, localsValue, false, parentValuesThatSetPositiveState, parentValuesThatSetNegativeState));

                //outer -> inner
                else if (!childSetting.listItemField && parentSetting.listItemField)
                    results.push(_this.evaluateSingleParent(childSetting, parentSetting, _this.state.value, true, parentValuesThatSetPositiveState, parentValuesThatSetNegativeState));

                //inner -> outer
                //outer -> outer
                else
                    results.push(_this.evaluateSingleParent(childSetting, parentSetting, _this.state.value, false, parentValuesThatSetPositiveState, parentValuesThatSetNegativeState));
            }
        }

        let show = false;

        if (childSetting.compareParentsWithAND)
            show = results.every(function(result) {
                return result;
            });
        else
            show = results.some(function(result) {
                return result;
            });

        _this.fieldIsShowing[childSetting.field.metadataName] = show;
        return show;
    }

    // The outerToInner parameter should be true if we are checking an outer->inner association because it needs to be handled differently
    evaluateSingleParent(childSetting, parentSetting, parentValueLocation, outerToInner, parentValuesThatSetPositiveState, parentValuesThatSetNegativeState) {
        let _this = this;

        if (!outerToInner) {
            if (parentValueLocation[parentSetting.field.metadataName]) {
                if (parentValuesThatSetPositiveState) {
                    if (parentValueLocation[parentSetting.field.metadataName] !== null && parentValueLocation[parentSetting.field.metadataName] !== undefined) {
                        if (typeof parentValueLocation[parentSetting.field.metadataName] === 'string')
                            return parentValuesThatSetPositiveState.toLowerCase().split(",").indexOf(parentValueLocation[parentSetting.field.metadataName].toLowerCase()) !== -1;
                        else
                            return parentValuesThatSetPositiveState.split(",").indexOf(parentValueLocation[parentSetting.field.metadataName].toString()) !== -1;
                    }
                    else
                        return false;
                } else if (parentValuesThatSetNegativeState) {
                    if (parentValueLocation[parentSetting.field.metadataName] !== null && parentValueLocation[parentSetting.field.metadataName] !== undefined) {
                        if (typeof parentValueLocation[parentSetting.field.metadataName] === 'string')
                            return parentValuesThatSetNegativeState.toLowerCase().split(",").indexOf(parentValueLocation[parentSetting.field.metadataName].toLowerCase()) === -1;
                        else
                            return parentValuesThatSetNegativeState.split(",").indexOf(parentValueLocation[parentSetting.field.metadataName].toString()) === -1;
                    }
                    else
                        return true;
                }
            }
        } else {
            let valuesThatShowCheck = (item) => {
                if (item[parentSetting.field.metadataName] !== null && item[parentSetting.field.metadataName] !== undefined) {
                    if (typeof item[parentSetting.field.metadataName] === 'string')
                        return parentValuesThatSetPositiveState.toLowerCase().split(",").indexOf(item[parentSetting.field.metadataName].toLowerCase()) !== -1;
                    else
                        return parentValuesThatSetPositiveState.split(",").indexOf(item[parentSetting.field.metadataName].toString()) !== -1;
                }
                else
                    return false;
            };

            let valuesThatHideCheck = (item) => {
                if (item[parentSetting.field.metadataName] !== null && item[parentSetting.field.metadataName] !== undefined) {
                    if (typeof item[parentSetting.field.metadataName] === 'string')
                        return parentValuesThatSetNegativeState.toLowerCase().split(",").indexOf(item[parentSetting.field.metadataName].toLowerCase()) === -1;
                    else
                        return parentValuesThatSetNegativeState.split(",").indexOf(item[parentSetting.field.metadataName].toString()) === -1;
                }
                else
                    return true;
            };

            let itemsList =  _this.state.value[_this.itemsListFieldName];

            //There are two ways an outer->inner can be checked. It can either be true only if all parent values in the list pass, or if at least one parent value in the list passes
            if (parentValuesThatSetPositiveState) {
                if (itemsList && itemsList.length > 0) {
                    if (childSetting.parentListItemValuesMustAllPass) {
                        return itemsList.every(valuesThatShowCheck);
                    } else {
                        return itemsList.some(valuesThatShowCheck);
                    }
                } else {
                    return false;
                }
            } else if (parentValuesThatSetNegativeState) {
                if (itemsList && itemsList.length > 0) {
                    //This case has to be thought of a little backwards
                    //using 'some' means that if any of the values isn't the correct value then we don't have to hide the field (could also be some other state)
                    //and this method should return true if we want to show the field, so we use 'some' if parentListItemValuesMustAllPass is true
                    if (childSetting.parentListItemValuesMustAllPass) {
                        return itemsList.some(valuesThatHideCheck);
                    } else {
                        return itemsList.every(valuesThatHideCheck);
                    }
                } else {
                    return true;
                }
            }
        }

        // If we get to this point and the parent being checked against is the standardPricingId, then that means there were no standard pricings found
        // so the child shouldn't rely on the value of the standardPricingId because it will never be set
        if (parentSetting.field.metadataName === "standardPricingId")
            return true;

        return parentValuesThatSetNegativeState;
    }

    addFunction = () => {
        let {fieldSettingsByGroup, ticketType, files} = this.state;
        let _this = this;
        let value = _this.state.value;

        files.push([]);

        if (!value[_this.itemsListFieldName]) {
            value[_this.itemsListFieldName] = [];
        }

        //The length of the items list here should be the index of the newly added item
        let addedItemIndex = value[_this.itemsListFieldName].length;

        let newItem = {
            quantity: '1'
        };

        fieldSettingsByGroup[ticketType.value].listSettings.forEach(function(setting) {
            if ((!_this.props.startingValue || _this.isEmpty(_this.props.startingValue)) && setting.startingValue !== null && setting.startingValue !== undefined) {
                newItem[setting.field.metadataName] = setting.startingValue;
            }
        });

        //This really shouldn't have to be done but because Alterations and Dry cleaning aren't separated
        //and Burberry wants to dictate the service type on the ticket level and not the item level,
        //this has to be done until Dry Cleaning and Alterations are separated out
        if (value.serviceType) {
            if (value.serviceType === 'Dry Clean') {
                newItem.lineItemTypeId = 1;
                newItem.customerCharge = '0';
            }
            else if (value.serviceType === 'Alteration') {
                newItem.lineItemTypeId = 2;
            }
        }

        value[_this.itemsListFieldName].push(newItem);

        _this.setState({value, valid: false, files}, function() {
            _this.onChange(_this.state.value, [_this.itemsListFieldName, addedItemIndex]);
        });

    };

    itemListTemplate = (locals) => {
        let {showAttachments, addItemButtonName} = this.state;
        let {removeItem} = this;
        let {disableAdd, disableRemove} = this.props;
        let _this = this;
        return(
            <div className="margin-bottom-md">
                <h4>{locals.label}</h4>
                {
                    locals.items.map(function (item, i) {
                        return (
                            <div key={item.key}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        {item.input}
                                    </div>
                                </div>
                                { showAttachments ?
                                    <div>
                                        <label>Image Attachments</label>
                                        <div className="ws-section">
                                            <FileUploader getFileData={(fileData) => _this.getFileData(fileData, i)}/>
                                        </div>
                                    </div> : null}
                                {disableRemove ?
                                    null
                                    :
                                    <div className="row">
                                        <div className="col-sm-3">
                                            {
                                                item.buttons.map(function (button, buttonIndex) {
                                                    return (
                                                        <button className="ws-btn ws-btn-warning btn-block" key={buttonIndex} onClick={() => removeItem(button, i)}>{button.label}</button>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                <hr/>
                            </div>
                        )
                    })
                }
                {disableAdd ?
                    null
                    :
                    <button className="ws-btn ws-btn-primary btn-block margin-top-lg" onClick={ValueIsSet(this.props.addItemButtonFunction) ? this.props.addItemButtonFunction : _this.addFunction}>{addItemButtonName}</button>
                }
            </div>
        )
    };

    itemTemplate = (locals) => {
        let {fieldSettingsByGroupAndKey, injectedHelperQualifiers, ticketType} = this.state;
        const {client} = this.props.context;
        let _this = this;

        let inputList = [];

        for (let key in locals.inputs) {
            if (locals.inputs.hasOwnProperty(key))
                inputList.push(locals.inputs[key]);
        }

        let formattedFields = [];
        let formattedFieldsLargestWidth = [];

        let nonFormattedFields = [];

        inputList.forEach(function(input) {
            let fieldSetting = fieldSettingsByGroupAndKey[ticketType.value][input.key];
            let visibility = (fieldSetting.parentValuesThatShowChild || fieldSetting.parentValuesThatHideChild) ? (_this.determineFieldState(fieldSetting, locals.value, 1) ? 'visible' : 'hidden') : 'visible';

            if (visibility !== 'visible' && fieldSetting.valueWhenHidden !== null && fieldSetting.valueWhenHidden !== undefined) {
                if (fieldSetting.valueWhenHidden.toLowerCase() === 'null') {
                    if (locals.value[input.key] !== undefined) {
                        locals.value[input.key] = undefined;
                    }
                }
                else
                    locals.value[input.key] = fieldSetting.valueWhenHidden;
            }

            if (injectedHelperQualifiers) {
                let helperQualifiers;
                if (injectedHelperQualifiers[fieldSetting.group] && injectedHelperQualifiers[fieldSetting.group][fieldSetting.field.metadataName])
                    helperQualifiers = injectedHelperQualifiers[fieldSetting.group][fieldSetting.field.metadataName];
                else if (injectedHelperQualifiers['defaultOptions'] && injectedHelperQualifiers['defaultOptions'][fieldSetting.field.metadataName])
                    helperQualifiers = injectedHelperQualifiers['defaultOptions'][fieldSetting.field.metadataName];

                if (helperQualifiers !== null && helperQualifiers !== undefined) {
                    for (let key in helperQualifiers) {
                        if (helperQualifiers.hasOwnProperty(key)) {
                            let parentField = fieldSettingsByGroupAndKey[fieldSetting.group][key];
                            for (let innerKey in helperQualifiers[key]) {
                                if (helperQualifiers[key].hasOwnProperty(innerKey)) {
                                    let parentValue = parentField.listItemField ? locals.value[parentField.field.metadataName] : _this.state.value[parentField.field.metadataName];
                                    if (!client.isSelfService && parentValue !== null && parentValue !== undefined && parentValue.toString().toLowerCase() === innerKey.toString().toLowerCase())
                                        input.props.options.help = fieldSetting.helpMessage ? <i>{fieldSetting.helpMessage} {helperQualifiers[key][innerKey]}</i> : <i>{helperQualifiers[key][innerKey]}</i>
                                }
                            }
                        }
                    }
                }
            }

            if (fieldSetting.parentValuesThatDisableChild || fieldSetting.parentValuesThatEnableChild)
                input.props.options.disabled = !_this.determineFieldState(fieldSetting, locals.value, 2);

            if (_this.fieldHasRowAndColumnSet(fieldSetting)) {
                let row = parseInt(fieldSetting.row, 10);
                let column = parseInt(fieldSetting.column, 10);

                if (!formattedFields[row])
                    formattedFields[row] = [];
                if (!formattedFields[row][column])
                    formattedFields[row][column] = [];

                if (!formattedFieldsLargestWidth[row])
                    formattedFieldsLargestWidth[row] = [];
                if (!formattedFieldsLargestWidth[row][column])
                    formattedFieldsLargestWidth[row][column] = parseInt(fieldSetting.columnWidth, 10);

                let columnWidth = parseInt(fieldSetting.columnWidth, 10);
                if (columnWidth > formattedFieldsLargestWidth[row][column])
                    formattedFieldsLargestWidth[row][column] = columnWidth;

                formattedFields[row][column].push(
                    <div key={fieldSetting.id} style={{visibility: visibility}}>
                        {input}
                    </div>
                )
            } else {
                nonFormattedFields.push(
                    <div key={fieldSetting.id} style={{visibility: visibility}}>
                        {input}
                    </div>
                );
            }
        });

        return (
            <div>
                {
                    formattedFields.map(function(row, ri) {
                        return (
                            <div className="row">
                                {
                                    row.map(function (column, ci) {
                                        return (
                                            <div key={ci} className={"col-md-" + formattedFieldsLargestWidth[ri][ci].toString()}>
                                                {
                                                    column.map(function(field) {
                                                        return (
                                                            <div key={field.key}>
                                                                {field}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                {
                    nonFormattedFields.map(function(field) {
                        return (
                            <div key={field.key}>
                                {field}
                            </div>
                        )
                    })
                }
            </div>
        )
    };

    render() {
        let {formHeader, ticketType, ticketFormStruct, ticketFormOptions, value, generalErrorMessage, submitDisabled, submitButtonText, submitButtonDisabledText, useSecondaryButton, secondaryButtonText, secondaryButtonDisabledText} = this.state;
        let {selectTicketType, submit, secondarySubmit, onChange} = this;
        let _this = this;

        return (
            <React.Fragment>
                <div id={'form-modal'}/>
                <div>
                    {formHeader ? formHeader : null}
                    { _this.moreThanOneTicketType ?
                        <Form ref="form"
                              type={_this.ticketTypeForm}
                              onChange={(value) => selectTicketType(value.value)}
                              value={ticketType}
                              options={ticketTypeFormOption}/> : null
                    }
                    {ticketType.value ?
                        <div>
                            <Form ref="form"
                                  type={ticketFormStruct}
                                  value={value}
                                  onChange={(value, updatedFieldName) => onChange(value, updatedFieldName)}
                                  options={ticketFormOptions}/>
                            { generalErrorMessage ?
                                <div>
                                    <div className="alert alert-danger text-right">
                                        { generalErrorMessage }
                                    </div>
                                </div> : null
                            }
                            <div className={useSecondaryButton ? 'row' : null}>
                                { useSecondaryButton ?
                                    <div className="col-md-6">
                                        <Button outline disabled={submitDisabled} type={'primary'} message={submitDisabled ? secondaryButtonDisabledText : secondaryButtonText}
                                                onPress={() => secondarySubmit()}/>
                                    </div>
                                    : null
                                }
                                <div className={useSecondaryButton ? "col-md-6 text-right" : "text-right"}>
                                    <Button disabled={submitDisabled} type={'primary'} message={submitDisabled ? submitButtonDisabledText : submitButtonText}
                                        onPress={() => submit()}/>
                                </div>
                            </div>
                        </div> : null
                    }
                </div>
            </React.Fragment>
        )
    }
}

FormBuilder.propTypes = {
    addItemButtonFunction: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.bool,
    isSelfService: PropTypes.bool,
    submit: PropTypes.func.isRequired,
    fieldSettings: PropTypes.array.isRequired,
    submitDisabled: PropTypes.bool.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    submitButtonDisabledText: PropTypes.string.isRequired
};

FormBuilder.defaultProps = {
    isSelfService: false
};

export default WithContext(FormBuilder);