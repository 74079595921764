import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import AlertDialog from "./AlertDialog";
import TextField from "../Inputs/TextField/TextField.web";
import {Debounce} from "../../helpers/GenericHelpers";
import {DialogSize} from "worksmith/enums/DialogSize";

class TextFieldDialog extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            valid: props.textValueRequired ? false : true
        };

        this.textFieldValue = '';
    }

    onTextFieldChange = (value) => {

        this.textFieldValue = value;

        if (this.props.textValueRequired) {
            this.setState({valid: value.trim() !== ''});
        }
    };

    setLoading = (loading) => {
       this.setState({loading})
    };

    accept = () => {
       this.props.onAccept(this.textFieldValue);
    };

    render() {
        let {accept, onTextFieldChange, setLoading} = this;
        const {loading, valid} = this.state;
        const {acceptText,
               cancelText,
               children,
               description,
               acceptDisabled,
               onCancel,
               modalSize,
               open,
               textFieldTestId,
               textFieldPlaceholder,
               textFieldRows,
               title
               } = this.props;

        let debouncedOnChange = Debounce(onTextFieldChange, 50);

        return (
            <AlertDialog
                acceptDisabled={!valid || loading || acceptDisabled}
                acceptText={acceptText}
                body={description}
                cancelText={cancelText}
                debounceAccept
                fullWidth
                fullScreenOnMobile
                onAcceptIgnoreDebounce={() => setLoading(true)}
                onAccept={accept}
                onCancel={onCancel}
                size={modalSize}
                title={title}
                open={open}>
                <TextField
                    autoFocus
                    disabled={loading}
                    fullWidth
                    multiline
                    onChange={debouncedOnChange}
                    placeholder={textFieldPlaceholder}
                    rows={textFieldRows}
                    testId={textFieldTestId}
                />
                {children}
            </AlertDialog>
        )
    }
}

TextFieldDialog.defaultProps = {
    acceptText: 'Submit',
    cancelText: 'Cancel',
    textValueRequired: true,
    textFieldRows: 3,
    acceptDisabled: false
};

TextFieldDialog.propTypes = {
    acceptText: PropTypes.string,
    cancelText: PropTypes.string,
    description: PropTypes.string,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    acceptDisabled: PropTypes.bool,
    modalSize: PropTypes.oneOf(Object.values(DialogSize)),
    open: PropTypes.bool.isRequired,
    textFieldTestId: PropTypes.string,
    textFieldPlaceholder: PropTypes.string,
    textFieldRows: PropTypes.number,
    textValueRequired: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

export default TextFieldDialog;