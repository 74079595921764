import React, {useState} from 'react';
import PropTypes from 'prop-types'

import ArrivalPanel from "./arrivalInstructions/ArrivalPanel";
import InsurancePanel from "./insurance/InsurancePanel";
import LocationHoursPanel from "./hours/LocationHoursPanel";

import {AlignItems, JustifyContent} from "../../../enums/CSSEnums";
import {Color} from '../../../enums/Color'
import Accordion from '../../../components/Accordion/Accordion'
import Grid from "../../../components/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import Text, {TextVariant} from "../../../components/Text/Text";
import {IconType} from "../../../enums/MaterialEnums";

const AccessInfoPanel = ({location, readOnly, whiteLabelClient}) => {
    const [insuranceInfoComplete, setInsuranceInfoComplete] = useState(false);
    const [arrivalInfoComplete, setArrivalInfoComplete] = useState(false);
    const [hoursInfoComplete, setHoursInfoComplete] = useState(false);

    const accessInfoComplete =
        insuranceInfoComplete &&
        arrivalInfoComplete &&
        hoursInfoComplete

    const handleCompletePanel = (panel, value) => {
        switch (panel) {
            case "insurance":
                setInsuranceInfoComplete(value);
                break;
            case "arrival":
                setArrivalInfoComplete(value);
                break;
            case "hours":
                setHoursInfoComplete(value);
                break;
            default:
                return;
        }
    }

    const panelSummary = (
        <Grid container>
            <Grid container item xs={2} md={1} alignItems={AlignItems.CENTER}>
                <Icon name={"VpnKey"} />
            </Grid>
            <Grid container item xs={9} md={10} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H5}>
                    {"Access Information"}
                </Text>
            </Grid>
            <Grid container item xs={1} alignItems={AlignItems.CENTER} justify={JustifyContent.CENTER}>
                {!readOnly ? <Icon name={accessInfoComplete ? IconType.CHECK_CIRCLE_OUTLINED : IconType.REPORT_PROBLEM_OUTLINED} iconColor={accessInfoComplete ? Color.GREEN : Color.RED} /> : null}
            </Grid>
        </Grid>
    );

    return (
            <Accordion
                summary={panelSummary}
                details={
                    <Grid container>
                        <InsurancePanel
                            insuranceInfo={location.clientLocationOperationDetails}
                            handleCompletePanel={handleCompletePanel}
                            readOnly={readOnly}
                            whiteLabelClient={whiteLabelClient}
                        />
                        <LocationHoursPanel
                            handleCompletePanel={handleCompletePanel}
                            location={location}
                            readOnly={readOnly}
                        />
                        <ArrivalPanel
                            arrivalInfo={location.clientLocationOperationDetails}
                            handleCompletePanel={handleCompletePanel}
                            readOnly={readOnly}
                        />
                    </Grid>
                }
                large
            />
    )
};

export default AccessInfoPanel;

AccessInfoPanel.propTypes = {
    readOnly: PropTypes.bool,
    location: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        client: PropTypes.shape({
            adminUsers: PropTypes.arrayOf(PropTypes.shape({
                clientRole: PropTypes.string,
                companyName: PropTypes.string,
                displayName: PropTypes.string,
                email: PropTypes.string.isRequired,
                phone: PropTypes.string,
                clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
                    clientLocation: PropTypes.shape({
                        locationCode: PropTypes.string,
                        client: PropTypes.shape({
                            officialName: PropTypes.string.isRequired
                        }).isRequired
                    })
                })).isRequired
            })),
            id: PropTypes.number.isRequired
        }).isRequired,
        clientLocationAttachments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            singleUseUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })),
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
        email: PropTypes.string,
        industry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: PropTypes.string
        }),
        openingDate: PropTypes.string,
        regionalManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        locationManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        clientLocationOperationDetails: PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            unionLaborRequirement: PropTypes.string,
            occurrenceInsuranceRequirement: PropTypes.number,
            aggregateInsuranceRequirement: PropTypes.number,
            additionalInsuranceDetails: PropTypes.string,
            locationSquareFootage: PropTypes.number,
            hasLadderOnSite: PropTypes.bool,
            ladderHeightFt: PropTypes.number,
            parkingDirections: PropTypes.string,
            serviceEntranceDescription: PropTypes.string,
            additionalAccessInfo: PropTypes.string
        }),
        locationHours: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            dayOfWeekInteger: PropTypes.number,
            openTime: PropTypes.string,
            closeTime: PropTypes.string,
            closed: PropTypes.bool,
        })),
        id: PropTypes.number,
        locationCode: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired
    }),
    whiteLabelClient: PropTypes.bool
}