// This file was generated from com.worksmith.feedback.controller.FeedbackRequestController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class FeedbackRequestService extends Http {
    constructor() {
        super('/feedbackRequests')
    }
    
    addFeedback(id, feedback) {
        return this.callApi( `${id}/feedbacks`, POST, feedback, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    generateMonthlyFeedbackRequests(requestMonth) {
        let params = {
            requestMonth
        };
    
        return this.callApi( `generateMonthly`, GET, null, params, null, true);
    }
    
    getClientFeedbackRequestCards(id) {
        return this.callApi( `${id}/getClientFeedbackRequestDetails`, GET, null, null, null, true);
    }
    
    getCompletionAttachments(id) {
        return this.callApi( `${id}/getCompletionAttachments`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    searchClientFeedbackRequestCards(params) {
        return this.callApi( `searchClientFeedbackRequestCards`, POST, params, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default FeedbackRequestService;
