// This file was generated from com.worksmith.cr.enums.ChangeRequestApprovalType
// noinspection JSUnusedGlobalSymbols
const ChangeRequestApprovalType = Object.freeze({
    CLIENT: 'CLIENT',
    VENDOR: 'VENDOR',
    VENDOR_COMPLETION: 'VENDOR_COMPLETION',
    NONE: 'NONE'
});

export default ChangeRequestApprovalType;
