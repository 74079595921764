import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import MaterialSnackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Paper from '@mui/material/Paper';

import IconButton from '../Button/IconButton.web.js';
import {AlignItems, JustifyContent} from 'worksmith/enums/CSSEnums';
import Grid from '../Grid/Grid.web.js';
import {withTheme} from '../Theme/ThemeProvider';
import {Color} from 'worksmith/enums/Color';

const Snackbar = (props) => {

    const {
        anchorOrigin,
        autoHideDuration,
        isOpen,
        message,
        onClose
    } = props;

    const SnackbarContentMessage = (text) =>
        <Grid
            alignItems={AlignItems.CENTER}
            container
            justify={JustifyContent.CENTER}
        >
            <Grid item>
                {text}
            </Grid>
        </Grid>
    ;

    return (
        <Paper
            style={{position: 'absolute !important'}}
            fullScreen
            elevation={2}
        >
            <MaterialSnackbar
                anchorOrigin={anchorOrigin}
                autoHideDuration={autoHideDuration}
                open={isOpen}
                onClose={onClose}
            >
                <SnackbarContent
                    action={[
                        <IconButton
                            key={'close'}
                            onClick={onClose}
                            size={'small'}>
                            <CloseIcon style={{color: Color.WHITE}}/>
                        </IconButton>
                    ]}
                    message={typeof message === 'object' ? message : SnackbarContentMessage(message)}
                    onClose={onClose}
                    style={{backgroundColor: Color.SNACKBAR}}
                />
            </MaterialSnackbar>
        </Paper>
    );
};

Snackbar.propTypes = {
    anchorOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOf(['left', 'center', 'right']),
        vertical: PropTypes.oneOf(['top', 'bottom']),
    }),
    autoHideDuration: PropTypes.number, // This number is in milliseconds 1000ms = 1s
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.oneOfType(PropTypes.string,PropTypes.object).isRequired,
    onClose: PropTypes.func.isRequired
};

Snackbar.defaultProps = {
    anchorOrigin: {horizontal: 'left', vertical: 'bottom'},
    autoHideDuration: 6000
};

export default withTheme(Snackbar);