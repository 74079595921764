// This file was generated from com.worksmith.task.enums.TicketStatus
// noinspection JSUnusedGlobalSymbols
const TicketStatus = Object.freeze({
    AWAITING_PICKUP: 'awaiting pickup',
    PICKED_UP_BY_VENDOR: 'picked up by vendor',
    DROPPED_OFF_BY_VENDOR: 'dropped off by vendor',
    AWAITING_SHIPMENT: 'awaiting shipment',
    SHIPPED_TO_VENDOR: 'shipment to vendor processed',
    RETURNED_FROM_VENDOR: 'return shipped from vendor',
    AWAITING_TAILOR_ASSIGNMENT: 'awaiting tailor assignment',
    TAILOR_IN_PROGRESS: 'in progress',
    WORK_COMPLETED_BY_TAILOR: 'completed, waiting for outreach',
    WORK_CONFIRMED: 'work confirmed, waiting for outreach',
    AT_STORE: 'checked in at store',
    WAITING_FOR_CLIENT_PICKUP: 'waiting for client pickup',
    SENT_TO_CUSTOMER: 'sent to customer',
    PICKED_UP_BY_CUSTOMER: 'picked up by customer',
    SENT_TO_GUEST: 'sent to guest',
    PICKED_UP_BY_GUEST: 'picked up by guest',
    getCompletedStatuses: function() {
        return [this.SENT_TO_CUSTOMER, this.SENT_TO_GUEST, this.PICKED_UP_BY_CUSTOMER, this.PICKED_UP_BY_GUEST];
    },
    getDroppedOffStatuses: function() {
        return [this.SENT_TO_CUSTOMER, this.DROPPED_OFF_BY_VENDOR, this.RETURNED_FROM_VENDOR, this.AT_STORE, this.SENT_TO_CUSTOMER, this.SENT_TO_GUEST, this.PICKED_UP_BY_CUSTOMER, this.PICKED_UP_BY_GUEST, this.WORK_COMPLETED_BY_TAILOR, this.WORK_CONFIRMED, this.WAITING_FOR_CLIENT_PICKUP];
    },
    getPendingStatuses: function() {
        return [this.AWAITING_PICKUP, this.PICKED_UP_BY_VENDOR, this.AWAITING_SHIPMENT, this.SHIPPED_TO_VENDOR, this.AWAITING_TAILOR_ASSIGNMENT, this.TAILOR_IN_PROGRESS];
    },
    getReturnedFromVendorStatuses: function() {
        return [this.DROPPED_OFF_BY_VENDOR, this.RETURNED_FROM_VENDOR, this.AT_STORE, this.SENT_TO_CUSTOMER, this.SENT_TO_GUEST, this.PICKED_UP_BY_CUSTOMER, this.PICKED_UP_BY_GUEST, this.WORK_COMPLETED_BY_TAILOR, this.WAITING_FOR_CLIENT_PICKUP, this.WORK_CONFIRMED];
    }
});

export default TicketStatus;
