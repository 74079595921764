import React, {Component} from 'react';
import UserService from 'worksmith/services/api/UserService';
import ClientService from 'worksmith/services/api/ClientService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import moment from 'moment';
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import EditUserForm from "../../components/forms/EditUserForm";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {CountryCode} from "worksmith/enums/api/country/CountryCode";

const userService = new UserService();
const clientService = new ClientService();
const authTokenManager = new AuthTokenManager();

class SingleUserPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            userId: props.match.params.id,
            user: {},
            loading: true,
            editable: false,
            isSelfService : false,
        };

        this.toggleEditable = this.toggleEditable.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.getUser = this.getUser.bind(this);
    }

    componentDidMount(){
        this.getUser();
    }

    toggleEditable(){
        if (this.state.editable)
            window.location.reload();
        this.setState({ editable: !this.state.editable});
    }

    getUser(){
        let _this = this;
        let {userId} = this.state;

        userService.findOne(authTokenManager.getUserId()).then(function(data){
            let currentUser = data;
            let clientId = currentUser.clientRoles[0].client.id;

            clientService.findUserByClient(clientId, userId).then(function(data){
                let user = data;

                _this.setState({ user,
                    isSelfService: user.clientRoles != null && user.clientRoles.length > 0 ? user.clientRoles[0].isSelfServiceClient : null,
                    loading: false,
                    editable: false  });
            })
        });
    }

    handleSuccess(){
        this.getUser();
    }

    render(){
        let {user, loading, editable, isSelfService} = this.state;

        if(!loading){
            return(
                <div>
                    <section className="ws-section">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="page-header">{`${user.firstName} ${user.lastName} ${ValueIsSet(user.clientRoles) && user.clientRoles[0].isAccountant ? ' - Accountant' : ''}`}</h1>
                            </div>
                            <div className="col-md-6">
                                {editable ? <div className="text-right"><Button type={'warning-plain'} icon={'ban'} message={'Cancel Editing'} onPress={() => this.toggleEditable()}/></div> : null}
                                {!editable ? <div className="text-right"><Button type={'plain'} icon={'settings'} message={'Edit'} onPress={() => this.toggleEditable()}/></div> : null}
                            </div>
                        </div>
                        <hr className={'margin-top-sm'}/>
                        {editable ? <EditUserForm user={user} isSelfService={isSelfService} history={this.props.history} onSuccess={this.handleSuccess} /> : <div>
                            <p className="section-accent">Username:</p>
                            <p>{user.username}</p>

                            {
                                ValueIsSet(user.clientLocationRoles) ?
                                    <div>
                                        <p className="section-accent">Location(s):</p>
                                        {user.clientLocationRoles.map(function (role, idx) {
                                            return (
                                                <p key={idx}>{role.clientLocation.name || 'N/A'}</p>
                                            )
                                        })}
                                    </div>
                                    :
                                    null
                            }

                            <p className="section-accent">First Name:</p>
                            <p>{user.firstName}</p>

                            <p className="section-accent">Last Name:</p>
                            <p>{user.lastName}</p>

                            <p className="section-accent">Phone:</p>
                            <p>{(user.phone !== '' && user.phone != null) ?
                                (user.countryCode !== '' && user.countryCode != null) ?
                                    CountryCode[user.countryCode] + " " +  user.phone
                                    :
                                    user.phone
                                :
                                'None' }</p>

                            <p className="section-accent">Email:</p>
                            <p>{user.email}</p>

                            <p className="section-accent">Created:</p>
                            <p>{ moment(user.createdtimestamp).format('MM/DD/YYYY') }</p>
                        </div>}
                    </section>

                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

export default SingleUserPage;