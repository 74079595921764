import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InvoiceSearchAndExportFields from "./InvoiceSearchAndExportFields";
import InvoiceOverviewList from "./InvoiceOverviewList";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";

const SearchSection = styled.div`
    margin: 2em;
`;

export const InvoiceSearchType = Object.freeze({
    ALL_UNPAID: 'All Unpaid Invoices',
    PAST_DUE:   'Past Due Invoices',
    PAYMENTS:   'Payments'
});

class InvoiceSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            endDate:      null,
            filterString: '',
            startDate:    null,
            type:         InvoiceSearchType.ALL_UNPAID
        };

        this.filterString = '';
    }

    onTypeChange = (type) => {
        this.setState({type, filterString: ''});
    };

    onDatesChange = (startDate, endDate) => {
        this.setState({startDate, endDate});
    };

    onFilterChange = (value) => {
        this.filterString = value;
    };

    onSearch = (event) => {
        if(event.keyCode === 13)  //This is the key code for enter, so once enter is pressed the state changes
            this.setState({filterString: this.filterString});

    };

    onSearchClick = () => {
        this.setState({filterString: this.filterString});
    };

    render() {
        const {onDatesChange, onFilterChange, onSearch, onSearchClick, onTypeChange} = this;
        const {endDate, filterString, startDate, type} = this.state;
        const {clientId, linkToInvoice} = this.props;

        return (
            <Fragment>
                <SearchSection>
                    <InvoiceSearchAndExportFields clientId={clientId}
                                                  initialFilterString={filterString}
                                                  initialType={type}
                                                  onDatesChange={onDatesChange}
                                                  onFilterChange={onFilterChange}
                                                  onSearch={onSearch}
                                                  onSearchClick={onSearchClick}
                                                  onTypeChange={onTypeChange}/>
                </SearchSection>
                <InvoiceOverviewList clientId={clientId}
                                     endDate={endDate}
                                     filterString={filterString}
                                     linkToInvoice={linkToInvoice}
                                     startDate={startDate}
                                     type={type} />
            </Fragment>
        )
    }
}

InvoiceSearch.propTypes ={
    clientId:      CustomPropTypes.stringOrNumber.isRequired,
    linkToInvoice: PropTypes.func.isRequired
};

export default InvoiceSearch;