import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RequestSummaryView from "./RequestSummaryView";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import RequestForProposalService from "../../services/api/RequestForProposalService";

const graphQLService = new GraphQLServiceClass();
const requestForProposalService = new RequestForProposalService();

class RequestSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.fullRequestLoading) ? props.fullRequestLoading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null
        };
    }

    componentDidMount = () => {
        const {request} = this.state;
        const {requestId} = this.props;

        if (!ValueIsSet(request) && requestId) {
            this.getRequestFromId(requestId)
        }
    };

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request) {
            newState.request = DeepCopy(props.request);
        }

        if (ValueIsSet(props.fullRequestLoading)) {
            newState.loading = props.fullRequestLoading;
        }

        return newState;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.getRequestFromId(this.props.requestId);
        }
    }

    getRequestFromId = (requestId) => {
        this.setState({loading: true});

        graphQLService.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            RequestSummaryGraphQLObject
        ).then((data) => {
            this.setState({loading: false, request: data});
        })
    };

    sendWorksmithComment = (comment) => {
        return requestForProposalService.addComment(this.props.request.id, {
            rfpId: this.props.request.id,
            comment: comment
        });
    };

    render() {
        const {loading, request} = this.state;
        const {onRequestCancel, editRequest, partialRequestLoading, user} = this.props;

        return (
            <RequestSummaryView
                onRequestCancel={onRequestCancel}
                editRequest={editRequest}
                loading={loading}
                partialRequestLoading={partialRequestLoading}
                sendWorksmithComment={this.sendWorksmithComment}
                request={request}
                user={user}/>
        )
    }
}

RequestSummary.defaultProps = {
    partialRequestLoading: false,
    fullRequestLoading: false,
    request: null
};

// Pass in a full request or the requestId so that the component can fetch the correct data
RequestSummary.propTypes = {
    editRequest: PropTypes.func.isRequired,
    fullRequestLoading: PropTypes.bool,
    partialRequestLoading: PropTypes.bool,
    onRequestCancel: PropTypes.func.isRequired,
    request: PropTypes.shape({
        createdTimestamp: PropTypes.string,
        creator: PropTypes.shape({
            displayName: PropTypes.string
        }),
        customAnswers: PropTypes.arrayOf(
            PropTypes.shape({
                answer: PropTypes.string,
                question: PropTypes.shape({
                    id: PropTypes.number,
                    question: PropTypes.string
                })
            })
        ),
        description: PropTypes.string,
        emergency: PropTypes.bool,
        id: PropTypes.number,
        newStatus: PropTypes.string,
        openBidCount: PropTypes.string,
        poNumber: PropTypes.string,
        rfpAttachments: PropTypes.arrayOf(PropTypes.object),
        target:  PropTypes.shape({
            type: PropTypes.string,
            date: PropTypes.string
        }),
        timePreference: PropTypes.string,
        type: PropTypes.string,
        walkthroughPreference: PropTypes.string,
        worksmithNTE: null,
    }),
    requestId: PropTypes.number,
    user: PropTypes.shape({
        id: PropTypes.number
    }).isRequired
};

export const RequestSummaryGraphQLObject = `
    commentCount
    createdTimestamp
    creator {
        id
        displayName
    }
    customAnswers {
        id
        answer
        question {
            id
            question
        }
    } 
    emergency
    description
    id
    newStatus
    openBidCount
    poNumber
    rfpAttachments {
        id
        singleUseUrl
        fileName
    }
    target{
        type
        date                              
    }
    timePreference
    walkthroughPreference
    worksmithNTE
`;

export default RequestSummary;