// This file was generated from com.worksmith.task.enums.ObligationStatus
// noinspection JSUnusedGlobalSymbols
const ObligationStatus = Object.freeze({
    PENDING: 'pending',
    CANCELED: 'canceled',
    COMPLETED: 'completed',
    getNonCanceledStatuses: function() {
        return [this.PENDING, this.COMPLETED];
    }
});

export default ObligationStatus;
