import React, {Component} from 'react';
import moment from 'moment';
import SingleTicket from "./SingleTicket";
import ObligationService from 'worksmith/services/api/ObligationService';
const obligationService = new ObligationService();

class PickupDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            client: props.client,
            obligation: props.obligation,
            settings: props.settings,
        };

        this.handleCanceledTicket = this.handleCanceledTicket.bind(this);
    }

    handleCanceledTicket(){
        let _this = this;
        obligationService.getSummary(this.state.obligation.id).then(function(data){
            let obligation = data;
            _this.setState({ obligation });
        })
    }

    render() {
        let {user, obligation, settings, client} = this.state;
        let _this = this;
        return (
            <div>
                <section className="ws-section">
                    <div>
                        <h2>{obligation.clientLocation.name}</h2>
                    </div>
                    <div>
                        <h3>{obligation.clientLocation.addressLineOne}
                            {obligation.clientLocation.addressLineTwo ?
                                <span>{obligation.clientLocation.addressLineTwo}</span> : null}
                        </h3>
                    </div>
                    <div>{obligation.clientLocation.city}, {obligation.clientLocation.state} {obligation.clientLocation.zip}</div>
                    <div>Service by <strong>{obligation.vendorLocation.name}</strong></div>
                    <div>Pickup/Ship Date <strong>{moment(obligation.obligationDate).format('MM/DD/YYYY')}</strong></div>
                    <div>Return Date <strong>{moment(obligation.metadata['Return date']).format('MM/DD/YYYY')}</strong></div>
                </section>

                {obligation.tickets.map(function (ticket, idx) {
                    return (
                        <SingleTicket key={idx} ticket={ticket} settings={settings} isSingleTicket={false} printable={false} user={user} obligation={obligation} client={client} onCancel={() => _this.handleCanceledTicket()}/>
                    )
                })}
            </div>
        )
    }
}

export default PickupDetails;