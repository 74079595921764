import React, {Component} from "react";
import {ErrorText} from "../../components/styledComponents/Basic";
import Select from "antd/lib/select";
import {ValueIsSet} from "../../lib-worksmith/helpers/GenericHelpers";
import * as PropTypes from "prop-types";

const t = require('tcomb-form');
const Form = t.form.Form;
const Option = Select.Option;

class NewRequestDefaultFields extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: {}
        }

    }

    render() {

        let {checklist, checklistReviewed, locations, serviceLines, onChange, value, getRfpSpecificQuestionFormFields} = this.props;
        let DefaultFormFields = {};
        let order = [];
        let optionFields = {};

        if (ValueIsSet(locations) && ValueIsSet(serviceLines)) {
            if (Object.keys(locations).length > 1) {
                let locationIds = locations.map(location => {
                    return location.value
                });
                DefaultFormFields.location = t.enums.of(locationIds.toString().replace(/,/g, ' '));
                optionFields.location = {
                    nullOption: {value: '', text: 'Which location is this request for?'},
                    options: locations ? locations : [],
                    error: <ErrorText>Required Field</ErrorText>
                };
                order.push("location");
            }

            let serviceLineIds = serviceLines.map(serviceLine => {
                return serviceLine.value
            });
            DefaultFormFields.serviceLine = t.enums.of(serviceLineIds.toString().replace(/,/g, ' '));
            optionFields.serviceLine = {
                label: 'Service Line',
                nullOption: {value: '', text: 'Select a Service Line'},
                options: serviceLines ? serviceLines : [],
                error: <ErrorText>Required Field</ErrorText>
            };
            order.push("serviceLine");
        }

        let rfpSpecificFormInfo = getRfpSpecificQuestionFormFields(value);

        if(!ValueIsSet(checklist) || checklistReviewed){
            optionFields = {...rfpSpecificFormInfo.optionFields, ...optionFields};
            DefaultFormFields = {...rfpSpecificFormInfo.serviceLineSpecificFormFields, ...DefaultFormFields};
            order = order.concat(rfpSpecificFormInfo.order);
        }

        let OnDemand = t.struct(DefaultFormFields);
        let formOptions = {
            order: order,
            fields: optionFields
        };

        return (
            <Form type={OnDemand}
                  value={value}
                  onChange={(value) => onChange(value)}
                  options={formOptions}
            />
        );
    };

}

NewRequestDefaultFields.propTypes = {
    checklist: PropTypes.string,
    checklistReviewed: PropTypes.bool,
    locations: PropTypes.arrayOf(PropTypes.object),
    serviceLines: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    value: PropTypes.object,
    getRfpSpecificQuestionFormFields: PropTypes.func
};
export default NewRequestDefaultFields;
