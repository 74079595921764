// This file was generated from com.worksmith.task.controller.ObligationItemController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ObligationItemService extends Http {
    constructor() {
        super('/obligationItems')
    }
    
    addAttachments(id, attachments) {
        return this.callApi( `${id}/attachments/multiple`, POST, null, null, null, true, attachments);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteAttachment(id, attachmentId) {
        return this.callApi( `${id}/attachments/${attachmentId}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getAttachments(id) {
        return this.callApi( `${id}/attachments`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default ObligationItemService;
