import React from 'react';
import PropTypes from 'prop-types';
import {ConvertToDurationString} from "../../helpers/LanguageHelper";

const Duration = (props) => {
    let {duration} = props;

    return (
        <span>{ConvertToDurationString(duration)}</span>
    )
};

Duration.propTypes = {
    duration: PropTypes.number
};

export default Duration;