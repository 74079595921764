import moment from "moment";
import 'moment-timezone';
import {ValueIsSet} from "./GenericHelpers";
import TimeZone from 'worksmith/enums/api/core/constants/TimeZone';

export const ToHoursAndMinutes = (value) => {
    return {
        hour : Math.floor( value / 60 ),
        minute : value % 60
    }
};

//  pad the hour and minute values with zeroes if they are single digits (ex. {hour: 5, minute: 33} can convert to 05:33).
export const padToTwoDigits = (num) => {
    return num.toString().padStart(2, '0');
};

export const ToMinutes = (hour, minute) => {
    return hour * 60 + minute;
};

export const DateIsInThePast = (date) => {
    return moment(date).isBefore(moment(), 'day')
};

const timeFormat = 'HH:mm:00';
export const InvalidTimeInterval = (startTime, endTime) => {
    return moment(endTime, timeFormat).isBefore(moment(startTime, timeFormat));
};

export const isWithinTimePeriod = (dateTime, amount, unitOfTime) => {
    if(ValueIsSet(dateTime)) {
        return moment().add(amount, unitOfTime).isAfter(dateTime);
    }
    return false;
};

export const convert24HourNumberToMomentTime = (number) => {
    return moment(Math.trunc(number) + ":" + ((number % 1) * 60), 'HH:mm')
};

export const convertMomentTimeTo24HourNumber = (time) => {
    return parseFloat(time.format('HH')) + (parseFloat(time.format('mm')) / 60);
};

export const convertMilitaryStringToAMPMString = (string) => {
    return moment(string, ["HH:mm:ss"]).format("h:mm A")
};

export const convertYMDToMDY = (value) => {
    return moment(value).format('l')
};

export const convertToLocalTime = (value, timezone = TimeZone.NEW_YORK) => {
    const originalTimezone = moment.tz(value, timezone);
    const localTimeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    return originalTimezone.clone().tz(localTimeZone);
};

export const convertLocalTimeToEnteredTimeZone = (value, timezone = TimeZone.NEW_YORK) => {
    const localTimeZone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    const originalTimezone = moment.tz(value, localTimeZone);
    return originalTimezone.clone().tz(timezone);
};

export const convertToEasternTime = (value) => {
    return moment.tz(value, TimeZone.NEW_YORK);
};
