import React from 'react';
import {WithContext} from "../../context/GlobalContext";
import InvoicesOverviewPage from "worksmith/composite-components/ClientInvoice/Overview/InvoicesOverviewPage";
import {withRouter} from "react-router-dom";
import ClientRoutes from "../../ClientRoutes";

const InvoicesOverview = (props) => {
    const {billingProfiles, id, isPaused} = props.context.client;

    const linkToInvoice = (receivableId) => {
        props.history.push(ClientRoutes.SINGLE_INVOICE(receivableId));
    };

    return (
        <InvoicesOverviewPage clientId={id}
                              billingProfiles={billingProfiles}
                              linkToInvoice={linkToInvoice}
                              isPaused={isPaused}/>
    )
};

export default WithContext(withRouter(InvoicesOverview));