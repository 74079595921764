import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {withTheme} from '../Theme/ThemeProvider';
import {TextAlign, WordBreak} from "../../enums/CSSEnums";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import {TextDecoration} from "worksmith/enums/CSSEnums";
import {Color} from "worksmith/enums/Color";
import {TextTransform} from "../../enums/TextTransform";
import Skeleton from "worksmith/components/Skeleton/Skeleton";
import styled from 'styled-components';

//This list of variants matches Material IO https://material.io/design/typography/the-type-system.html#type-scale
export const TextVariant = Object.freeze({
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
    SUBTITLE_1: 'subtitle1',
    SUBTITLE_2: 'subtitle2',
    BODY_1: 'body1',
    BODY_2: 'body2',
    CAPTION: 'caption',
    BUTTON: 'button',
    OVERLINE: 'overline',
    INHERIT: 'inherit'
});

export const LinkUnderline = Object.freeze({
    NONE: 'none',
    HOVER: 'hover',
    ALWAYS: 'always'
});

export const TextColor = Object.freeze({
    DEFAULT: 'default',
    ERROR: 'error',
    INHERIT: 'inherit',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TEXT_PRIMARY: 'textPrimary',
    TEXT_SECONDARY: 'textSecondary',
    WHITE: 'white'
});

export const TextDisplay = Object.freeze({
    BLOCK: 'block',
    INITIAL: 'initial',
    INLINE: 'inline',
    INLINE_BLOCK: 'inline-block'
});

export const TextVerticalAlign = Object.freeze({
    BOTTOM: 'bottom',
    MIDDLE: 'middle',
    TOP: 'top',
    SUPER: 'super'
});

const StyledTypography = styled(Typography)`
    cursor: ${(props) => props.cursorProperty ? props.cursorProperty : 'inherit'};
    &:hover {
        text-decoration: ${(props) => props.underlineOnHover ? 'underline' : 'inherit'};
    }    
`;

const BlueHoverLink = styled(Link)`
  &:hover {
    color: ${props => props.hoverColor ? props.hoverColor : '#0C4D58'};
  }
`;

const BlueRouterLink = styled(RouterLink)`
  color: ${props => props.color}; 
  &:hover {
    color: ${props => props.hoverColor ? props.hoverColor : '#0C4D58'};
  }
`;

const Text = (props) => {
    let {
        align,
        bold,
        color,
        component,
        cursorProperty,
        display,
        fontSize,
        gutterBottom,
        hoverColor,
        italic,
        light,
        lineHeight,
        link,
        noWrap,
        onClick,
        onMouseLeave,
        onMouseOver,
        paragraph,
        renderSkeleton,
        routerLink,
        semiBold,
        SkeletonProps,
        textDecoration,
        textTransform,
        underline,
        upperCase,
        variant,
        wordBreak,
        href,
        rel,
        target,
        newTab,
        testId,
        verticalAlign,
        underlineOnHover,
    } = props;

    let extraStyling = {};

    if (bold || semiBold || light) {
        extraStyling.fontWeight = bold ? 'bold'
            : semiBold ? '600'
                : light ? '300'
                    : null;
    }

    if (!noWrap) {
        extraStyling.whiteSpace= 'pre-wrap';
    }

    if (italic) {
        extraStyling.fontStyle = 'italic';
    }

    if (textDecoration) {
        extraStyling.textDecoration = textDecoration;
    }

    if (verticalAlign) {
        extraStyling.verticalAlign = verticalAlign;
    }

    if (lineHeight) {
        extraStyling.lineHeight = lineHeight;
    }

    if (underline) {
        extraStyling.textDecoration = 'underline';
    }

    if (textTransform) {
        extraStyling.textTransform = textTransform;
    } else if (upperCase) {
        extraStyling.textTransform = TextTransform.UPPERCASE;
    }

    if (color === TextColor.WHITE) {
        extraStyling.color = Color.WHITE;
    }

    if(wordBreak){
        extraStyling.wordBreak = wordBreak;
    }

    if(ValueIsSet(fontSize)){
        extraStyling.fontSize = fontSize;
    }

    if(newTab) {
        target = '_blank';
        rel = 'noreferrer noopener';
    }

    return (
        renderSkeleton ?
            <Skeleton width={'100%'} {...SkeletonProps}/>
            :
            <StyledTypography align={align}
                        color={color}
                        component={component}
                        className={props.className}
                        cursorProperty={cursorProperty}
                        display={display}
                        gutterBottom={gutterBottom}
                        noWrap={noWrap}
                        onClick={onClick}
                        onMouseLeave={onMouseLeave}
                        onMouseOver={onMouseOver}
                        paragraph={paragraph}
                        variant={variant}
                        style={extraStyling}
                        data-testid={testId}
                        underlineOnHover={underlineOnHover}
            >
                {
                    ValueIsSet(link) && link ?
                        <BlueHoverLink hoverColor={hoverColor} to={href} href={href} rel={rel} target={target} underline={underline} color={TextColor.INHERIT}>
                            {props.children}
                        </BlueHoverLink>
                        :
                        ValueIsSet(routerLink) ?
                            <BlueRouterLink hoverColor={hoverColor} to={href} href={href} rel={rel} target={target} underline={underline} color={TextColor.INHERIT}>
                                {props.children}
                            </BlueRouterLink>
                            :
                            props.children
                }
            </StyledTypography>
    )
};

Text.propTypes = {
    align: CustomPropTypes.enum(TextAlign),
    bold: PropTypes.bool,
    color: CustomPropTypes.enum(TextColor),
    component: CustomPropTypes.enum(TextVariant),
    cursorProperty: CustomPropTypes.enum(PointerEvent),
    display: CustomPropTypes.enum(TextDisplay),
    fontSize: PropTypes.string,
    gutterBottom: PropTypes.bool,                   //applies a bottom margin
    hoverColor: PropTypes.string,
    italic: PropTypes.bool,
    light: PropTypes.bool,
    lineHeight: PropTypes.string,
    link: PropTypes.bool,                         // this prop will change the component to a link
    newTab: PropTypes.bool,                       // opens link in new tab
    noWrap: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseLeave: PropTypes.func,                   //this allows support for wrapping buttons with Tooltips
    onMouseOver: PropTypes.func,                    //this allows support for wrapping buttons with Tooltips
    paragraph: PropTypes.bool,                      //applies a bottom margin, larger than gutterBottom, matches <p> tag
    renderSkeleton: PropTypes.bool,
    routerLink: PropTypes.bool,                    // used for react-router links
    semiBold: PropTypes.bool,
    SkeletonProps: PropTypes.object,                //Props to be passed to the Skeleton component if renderSkeleton is true
    textDecoration: CustomPropTypes.enum(TextDecoration),
    textTransform: CustomPropTypes.enum(TextTransform),
    upperCase: PropTypes.bool,
    underline: CustomPropTypes.enum(LinkUnderline),
    variant: CustomPropTypes.enum(TextVariant),
    verticalAlign: CustomPropTypes.enum(TextVerticalAlign),
    wordBreak: CustomPropTypes.enum(WordBreak),
    href: PropTypes.string,
    target: PropTypes.string,
    testId: PropTypes.string,
    underlineOnHover: PropTypes.bool,
};

Text.defaultProps = {
    display: TextDisplay.BLOCK,
    variant: TextVariant.BODY_2,
    color: TextColor.TEXT_PRIMARY
};

export default withTheme(Text);
