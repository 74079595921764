import React, {Component} from 'react';
import UserService from 'worksmith/services/api/UserService';
import ClientService from 'worksmith/services/api/ClientService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import Button from "../Button";
import Loader from "../../components/Loader";
import Modal from 'antd/lib/modal';
import {ErrorText} from '../styledComponents/Basic';

import notification from "antd/lib/notification";
import Tabs from "antd/lib/tabs";
import 'react-select/dist/react-select.css';
import * as custom from 'worksmith/helpers/CustomTcombTypes';
import {withRouter} from "react-router-dom";
import {CountryCodeWithAbbreviation} from "worksmith/enums/api/country/CountryCodeWithAbbreviation";

const userService = new UserService();
const clientService = new ClientService();
const t = require('tcomb-form');
const Form = t.form.Form;
const TabPane = Tabs.TabPane;
const helpLink = "https://support.office.com/en-us/article/Import-or-export-text-txt-or-csv-files-5250ac4c-663c-47ce-937b-339e391393ba";
const authTokenManager = new AuthTokenManager();


class NewUserForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            key: "localOrRegional",
            file: null,
            loading: false,
            fetchingLocations: true,
            newUserValue: {},
            fileMask: {
                userName:1,
                firstName:2,
                lastName:3,
                email:4,
                phone:5,
            },
            validMask: true,
            readyToSubmit: false,
            hasHeader: false,
            usersPreview:[],
            value: {
                locations: [],
                locationIds: []
            },
            invalidFields: [''] // defaulting to empty string for .length
        };

        this.createAccountantUserFormRef = React.createRef();
        this.createLocalUserFormRef = React.createRef();

        this.onChange = this.onChange.bind(this);
        this.fetchLocations = this.fetchLocations.bind(this);
        this.getFileData = this.getFileData.bind(this);
        this.onChangeFileMask = this.onChangeFileMask.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.setUserCount = this.props.props.setUserCount;
        this.skipStep = this.props.props.skipStep;
    }

    getFileData(fileData){
        this.setState({file: fileData});
    }

    handleStepChange(step){
        this.setState({ step });
    }

    //validate that no duplicat columns are selected
    onChangeFileMask() {
        let _this = this;
        let fileMask = _this.state.fileMask;
        // validate the required fields
        _this.state.validMask = fileMask.userName && !isNaN(fileMask.userName) &&
            fileMask.firstName && !isNaN(fileMask.firstName) &&
            fileMask.lastName && !isNaN(fileMask.lastName) &&
            fileMask.email && !isNaN(fileMask.email) &&
            fileMask.phone && !isNaN(fileMask.phone);
        // insure no duplicate mask keys
        Object.keys(fileMask).forEach(function(entry) {
            var temp = fileMask[entry];
            var count = 0;
            Object.keys(fileMask).forEach(function(element){
                if(fileMask[element] == temp) {
                    count++;
                };
            });
            if (count > 1 ){
                _this.state.validMask = false;
                return;
            }
            count = 0;
        });
    }

    // validate that a location has been assigned to each user
    onChangeLocation() {
        let _this = this;
        this.setState({readyToSubmit : true});
        for ( var i = 0; i <  _this.state.usersPreview.length; i++ ) {
            var location = _this.state.usersPreview[i].location;
            if ( typeof location == 'undefined'  ) {
                this.setState({readyToSubmit : false});
                return;
            }
        }
    }

    // Save the users
    saveUsers() {
        let _this = this;
        _this.setState({loading: true});
        let user = this.state.user;

        userService.saveUsers(_this.state.usersPreview).then(function(){
            // used to indicate that we do not need to show the create new user
            // dialog on the homepage.
            if (_this.setUserCount) {
                _this.setUserCount(2);
            }
            notification['success']({
                message: 'user added!'
            });
            _this.setState({loading: false});
        });

        if (_this.skipStep) {
            _this.skipStep();
        }
        _this.setState({loading: false});
        _this.setState({key: "1"});
    }

    componentDidMount() {
        let _this = this;

        this.fetchLocations().then(function (data) {
            let locations = data;
            let locationsToObject;
            let locationIds = [];
            if(locations.length){
                locationsToObject =  locations.reduce(function (array, location) {
                    let locationObj = {
                        value: location.id,
                        text: location.title
                    };
                    array.push(locationObj);
                    locationIds.push(location.id);
                    return array;
                }, []);
                _this.setState({
                    fetchingLocations: false,
                    value: {
                        locations: locationsToObject,
                        locationIds: locationIds
                    }
                });
            }
        })
    }

submit = (isAccountant) => {
        let _this = this;
        let {value} = this.state;
        let {isSelfService} = this.props.client;

        let formRef = isAccountant ? this.createAccountantUserFormRef : this.createLocalUserFormRef;

        if (formRef.current.validate().isValid()) {
            this.setState({loading: true});

            this.setState({newUserValue: value});

            userService.findOne(authTokenManager.getUserId()).then(function (userData) {
                let user = userData;
                let clientId = user.clientRoles[0].client.id;
                let locationId = value.location;

                let submitData = {
                    firstName: value.firstName,
                    lastName: value.lastName,
                    username: value.username,
                    ...(isSelfService) && {countryCode: value.countryCode},
                    phone: value.phone,
                    email: value.email
                };

                let promise = isAccountant ?
                    clientService.addNewUserToClient(clientId, true, submitData)
                    :
                    clientService.addNewUserToLocation(clientId, locationId, value.addAsRegionalManager ? value.addAsRegionalManager : false, submitData);

                promise.then(function (data) {
                    let user = data;

                    //Reset form
                    value.firstName = undefined;
                    value.lastName = undefined;
                    value.username = undefined;
                    value.countryCode = undefined;
                    value.phone = undefined;
                    value.email = undefined;
                    value.location = undefined;
                    value.addAsRegionalManager = false;

                    _this.setState({loading: false, value: value, invalidFields: ['']});

                    Modal.success({
                        title: "Success!",
                        content: <p>User Successfully Added!</p>
                    });

                    if(isAccountant)
                        _this.props.history.push(`/users/${user.id}`);

                    _this.setState({key: "localOrRegional"});
                    _this.handleStepChange(0);
                    if (_this.skipStep) {
                        _this.skipStep();
                    }
                    if (_this.setUserCount) {
                        _this.setUserCount(2);
                    }

                }).catch(function (error) {
                    alert(`${error}`)
                });
            });
        }
    };

    onChange(value) {
        this.setState({ value })
    }

    fetchLocations() {
        return userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId());
    }

    render() {
        let {value, fetchingLocations} = this.state;
        let {isSelfService} = this.props.client;

        let NewLocalOrRegionalUser = t.struct({
            location: t.enums.of(value.locationIds.toString().replace(/,/g, ' ')),
            addAsRegionalManager: t.Boolean,
            firstName: t.String,
            lastName: t.String,
            username: t.String,
            ...(isSelfService) && {countryCode: t.maybe(t.enums(CountryCodeWithAbbreviation))},
            phone: t.maybe(t.String),
            email: custom.Email
        });

        let NewAccountantUser = t.struct({
            firstName: t.String,
            lastName: t.String,
            username: t.String,
            ...(isSelfService) && {countryCode: t.maybe(t.String)},
            phone: t.maybe(t.String),
            email: custom.Email
        });



        let localOrRegionalUserFormOptions = {
            fields: {
                location: {
                    nullOption: {value: '', text: fetchingLocations ? 'Loading...' : 'Which location is this user for?'},
                    options: value.locations,
                    error: <ErrorText>Required Field</ErrorText>
                },
                firstName: {
                    error: <ErrorText>Required Field</ErrorText>
                },
                lastName: {
                    error: <ErrorText>Required Field</ErrorText>
                },
                username: {
                    error: <ErrorText>Required Field</ErrorText>
                }
            }
        };

        let accountantUserFormOptions = {
            fields: {
                firstName: {
                    error: <ErrorText>Required Field</ErrorText>
                },
                lastName: {
                    error: <ErrorText>Required Field</ErrorText>
                },
                username: {
                    error: <ErrorText>Required Field</ErrorText>
                }
            }
        };



        let makeItem = function (elementArray) {
            var keys = Object.keys(elementArray);
            var options = [];
            for (var key in keys) {
                options.push({value: keys[key], label: elementArray[keys[key]]});
            }
            return options;
        };

        if ( !this.state.loading ) {
            return (
                <div>
                    <Tabs style={{'textAlign': 'center'}} activeKey={this.state.key} onChange={ (activeKey)=>this.setState({key: activeKey})}>
                        <TabPane style={{'textAlign': 'left'}} tab="Local & Regional" key="localOrRegional">
                            <Form
                                ref={this.createLocalUserFormRef}
                                type={NewLocalOrRegionalUser}
                                value={this.state.value}
                                onChange={this.onChange}
                                options={localOrRegionalUserFormOptions}
                            />
                            <div className="textRight">
                                <Button style={{'width':'155px', 'height':'40px','textAlign':'center'}} type={'primary'} message={'Save User'}
                                        onPress={() => this.submit(false)}/>
                            </div>
                        </TabPane>
                        {!isSelfService ?
                            <TabPane style={{'textAlign': 'left'}} tab="Accountant" key="accountant">
                                <Form
                                    ref={this.createAccountantUserFormRef}
                                    type={NewAccountantUser}
                                    value={this.state.value}
                                    onChange={this.onChange}
                                    options={accountantUserFormOptions}
                                />
                                <div className="textRight">
                                    <Button style={{'width':'155px', 'height':'40px','textAlign':'center'}} type={'primary'} message={'Save User'}
                                            onPress={() => this.submit(true)}/>
                                </div>
                            </TabPane>
                        : null}
                    </Tabs>
                </div>
            );
        }else {
            return <div>
                <div style={{'text-align': 'center'}}>This could take a few moments...</div>
                <Loader />
            </div>;
        }
    }
}

export default withRouter(NewUserForm);

