import * as PropTypes from "prop-types";
import styled from "styled-components";
import React from "react";
import {Color, FunctionalColor} from "worksmith/enums/Color";
import {Link} from "react-router-dom";
import ClientRoutes from "../../ClientRoutes";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import {TextAlign} from "worksmith/enums/CSSEnums";
import Icon from "worksmith/components/Icon/Icon.web"
import RequestsOrObligationSummary from "./RequestsOrObligationSummary";

const ServiceLineLocationSummary = (props) =>{
    let {serviceLine, obligationTemplates, location, requests} = props;
    let numberOfServices = requests.length + obligationTemplates.length;

    return <NoUnderlineLink to={'/' + ClientRoutes.RECURRING_SERVICE_SERVICE_LINE_LOCATION(serviceLine.id, location.id)}>
            <OptionGrid>
                <BoldOptionText align={TextAlign.LEFT} variant={TextVariant.BODY_1}>{location.title}</BoldOptionText>
                <OptionBlock>
                    {numberOfServices === 0 ? <RequestsOrObligationSummary requests={requests} obligationTemplates={obligationTemplates}/> : null }
                    {obligationTemplates.length > 0 ? <RequestsOrObligationSummary requests={[]} obligationTemplates={obligationTemplates}/> : null }
                    {requests.length > 0 ? <RequestsOrObligationSummary requests={requests} obligationTemplates={[]}/> : null }
                </OptionBlock>
                <ArrowIcon iconColor={FunctionalColor.BLACK} name={'KeyboardArrowRight'}/>
            </OptionGrid>

    </NoUnderlineLink>
};

const OptionBlock = styled.div`
    display: inline;
    font-size: 1em;
    margin: 1em;
    grid-column: 2;
`;

const OptionText = styled(Text)`
    padding-top: 0.5em
    font-size: 1em;
    margin: 1em;
    grid-column: 2;
`;

const BoldOptionText = styled(OptionText)`
    font-weight: bold;
    grid-column: 1;
`;

const OptionGrid = styled.div`
    padding-left: 0.2em;
    padding-right: 0.2em;
    display: grid;
    grid-template-columns:35% 55% 5% 5%;
    width: 100%;
    :hover {
        background-color: ${Color.OPTION_HOVER_BLUE};       
    }
    @media only screen and (max-width: 900px) {
        width: 80%;
        grid-template-columns: 15% 75% 10%;
    }        
`;

const ArrowIcon = styled(Icon)`
    margin-left: 0.35em;
    margin-top: 0.4em;
    grid-column: 4;
`;
const NoUnderlineLink = styled(Link)`   
    :hover {    
        text-decoration: none;
    }
`;

ServiceLineLocationSummary.propTypes = {
    requests: PropTypes.arrayOf(PropTypes.object),
    obligationTemplates: PropTypes.arrayOf(PropTypes.object),
    serviceLine: PropTypes.object,
    location: PropTypes.object
};

export default ServiceLineLocationSummary;