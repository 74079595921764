import {ValueIsSet} from "../helpers/Helpers";

export const AdminRoutes = Object.freeze({
    ASSIGNMENT_PAGE: (assignmentId) => ValueIsSet(assignmentId) ? '/app#/marketplace-operations/assignment/' + assignmentId : '/app#/marketplace-operations/assignment',
    CAMPAIGN_REQUESTS: (campaignId) => ValueIsSet(campaignId) ? '/app#/campaigns/' + campaignId + '/requests' : '/app#/campaigns/requests',
    CLIENT_PAGE: (clientId) => ValueIsSet(clientId) ? '/app#/clients/' + clientId : '/app#/clients',
    CLIENT_LOCATION_PAGE: (clientId, clientLocationId) => ValueIsSet(clientId) && ValueIsSet(clientLocationId) ? '/app#/clients/' + clientId + '/locations/' + clientLocationId : '/app#/clients/locations',
    VENDOR_PAGE: (vendorId) => ValueIsSet(vendorId) ? '/app#/vendors/edit/' + vendorId : '/app#/vendors',
});

export const AssignmentStatus = Object.freeze({
    PENDING: 'pending',
    COMPLETED: 'completed',
    CANCELED: 'canceled'
});

export const AssignmentType = Object.freeze({
    REQUEST: 'request',
    SUPPORT_TICKET: 'Support Ticket',
    CHANGE_REQUEST: 'Change Request'
});

export const BlacklistAddedByType = Object.freeze({
    VENDOR: ("VENDOR"),
    CLIENT: ("CLIENT")
});

export const ChangeRequestApprovalType = Object.freeze({
    CLIENT: "CLIENT",
    VENDOR: "VENDOR",
    NONE: "NONE",
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const LocalStorageKeys = Object.freeze({
    ACCESS_TOKEN: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    USER_ID: 'userId',
    PORTAL_TYPE: 'portalType',
    ENVIRONMENT: 'environment',
    BASE_API_URL: 'baseApiURL',
    REQUESTS_PAGE_SEARCH_PARAMETERS: 'requestsPageSearchParameters'
});

export const ChangeRequestStatus = Object.freeze({
    NEEDS_CLIENT_INPUT: "needs client input",
    NEEDS_VENDOR_INPUT: "needs vendor input",
    CANCELED: "canceled",
    ACCEPTED: "accepted",
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const Color = Object.freeze({
    RED: '#DD2E2B',
    ORANGE: '#F78B21',
    GREEN: '#6DAD3D',
    TURQUOISE: '#156F83',
    BLUE: '#3F7BBF',
    NAVY: '#293D4B',
    BLACK: '#171616',
    DARK_GREY: '#3D3D3D',
    BORDER_GREY: '#979797',
    MEDIUM_GREY: '#B2B2B2',
    GREY: '#D9D9D9',
    OFF_WHITE: '#F8F8F8',
    WHITE: '#FFFFFF',
    TRANSPARENT: 'transparent'
});

export const JavascriptType = Object.freeze({
    BOOLEAN: 'boolean',
    FUNCTION: 'function',
    NUMBER: 'number',
    OBJECT: 'object',
    STRING: 'string',
    SYMBOL: 'symbol',
    UNDEFINED: 'undefined'
});

export const ModalStyle = Object.freeze({
    DEFAULT: {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: '1021',
        },
        content: {
            position: 'absolute',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            border: '1px solid ' + Color.GREY,
            background: Color.WHITE,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
        }
    },
    AUTOSIZED: {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: '1021',
        },
        content: {
            position: 'relative',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            border: '1px solid ' + Color.GREY,
            background: Color.WHITE,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: 'fit-content',
            transform: 'translate(-50%, -50%)',
        }
    },
});

export const MomentFormat = Object.freeze({
    MonthDayYear: 'MM-DD-YYYY',                         //EX: 12-25-2018
    DowMonthDayYearSlash: 'ddd, M/DD/YY',               //EX: Thu, 12/1/2018
    DowMonthDayYearTimeSlash: 'ddd, M/DD/YY h:mmA',     //EX: Thu, 12/1/2018 12:34PM
    MonthDayYearSlash: 'MM/DD/YYYY',                    //EX: 12/25/2018
    ISODate: 'YYYY-MM-DD',                              //EX: 2018-01-11
    MonthDay: 'MMM Do',                                 //EX: Sep 19th
    Dom: 'Do',                                          //EX: 21st
    FullDow: 'dddd',                                    //EX: Thursday
    DowMonthDayYear: 'ddd, MMM D YYYY',                 //EX: Thu, May 1 2018
    DowMonthDom: 'ddd, MMM Do',                         //EX: Thu, Nov 1st
    FullDowFullMonthDom: 'dddd, MMMM Do',               //EX: Thursday, November 1st
    DateTime: 'MM/DD/YY h:mmA',                         //EX: 09/18/18 12:47PM
    FullDayFullMonthDomYear: 'dddd, MMMM Do YYYY',      //EX: Friday, December 28th 2018
    FullDateTime: 'MMMM Do YYYY h:mm a',                //EX: December 28th 2018 3:46 pm
    MonthDomYear: 'MMM D, YYYY',                        //EX: Jan 1, 2019
    FullMonthYear: 'MMMM YYYY',                         //EX: January 2019
    FullMonthYearWithoutSpace: 'MMMMYYYY',              //EX: January2019
    FullMonth: 'MMMM',                                  //EX: January
    HoursMinutesSeconds: 'HH:mm:ss',                    //EX: 14:23:14 (can be used when backend needs a LocalTime)
    HoursMinutes: 'HH:mm:00',                           //EX: 14:23:00
    StandardTime: 'h:mm A',                             //EX: 1:32 PM
    MilitaryTime: 'HH:mm',                              //EX: 09:30
    Time: 'LT'
});

export const NewRequestStatus = Object.freeze({
    MARKET: 'market',
    SCHEDULING: 'scheduling',
    CONFIRMED: 'confirmed',
    COMPLETED: 'completed',
    CORPORATE_REVIEW: 'corporate review',
    CORPORATE_PRICING_REVIEW: 'corporate pricing review',
    CANCELED: 'canceled',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const NTEType = Object.freeze({
    REGIONAL: 'regional',
    ADMIN: 'admin',
    WORKSMITH: 'worksmith'
});

export const PayableItemGroupJobType = Object.freeze({
    RECURRING: "Recurring",
    ONE_TIME: "One Time",
    WALKTHROUGH: "Walkthrough",
    MISCELLANEOUS: "Miscellaneous"
});

export const PayableItemGroupType = Object.freeze({
    TICKET: "TICKET",
    OBLIGATION: "OBLIGATION",
    LINE_ITEM: "LINE_ITEM"
});

export const RequestMethod = Object.freeze({
    DELETE: 'DELETE',
    GET: 'GET',
    HEAD: 'HEAD',
    OPTIONS: 'OPTIONS',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT',
});

export const RequestType = Object.freeze({
    RECURRING: 'recurring',
    ON_DEMAND: 'on-demand',
    PRICING_ONLY: 'pricing-only'
});

export const ScheduleStatus = Object.freeze({
    ACCEPTED: ("ACCEPTED"),
    CANCELED: ("CANCELED"),
    DECLINED: ("DECLINED"),
    NEEDS_CLIENT: ("NEEDS_CLIENT"),
    NEEDS_VENDOR: ("NEEDS_VENDOR"),
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const ScheduleType = Object.freeze({
    ONE_TIME: ("one-time"),
    RECURRING: ("recurring"),
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const ServiceLine = Object.freeze({
    APPLIANCE_REPAIR: 'Appliance Repair',
    BEVERAGE_SERVICE: 'Beverage Services',
    CARPET_CLEANING: 'Carpet Cleaning',
    COMMERCIAL_CLEANING: 'Commercial Cleaning',
    DOOR_REPAIR: 'Door Repair',
    DRY_CLEANING_AND_ALTERATIONS: 'Dry Cleaning and Alterations',
    ELECTRICAL_WORK: 'Electrical Work',
    EVENT_RENTALS: 'Event Rentals',
    FIRE_AND_SAFETY_INSPECTION: 'Fire and Safety Inspection',
    FLOORING_REPAIR: 'Flooring Repair',
    HANDYMAN: 'Handyman',
    HURRICANE_PREPARATION: 'Hurricane Preparation',
    HVAC: 'HVAC',
    LANDSCAPING: 'Landscaping',
    LEATHER_REPAIR: 'Leather Repair',
    LIGHTING: 'Lighting',
    LOCKSMITH: 'Locksmith',
    MOLD_INSPECTION: 'Mold Inspection',
    OTHER: 'Other',
    PAINTING: 'Painting',
    PEST_CONTROL: 'Pest Control',
    PLUMBING: 'Plumbing',
    REFRIGERATION_SERVICES: 'Refrigeration Services',
    SEWING_MACHINE_REPAIR: 'Sewing Machine Repair',
    SNOW_REMOVAL: 'Snow Removal',
    TRASH_REMOVAL: 'Trash Removal',
    TRENCHCOAT_REPROOFING_AND_LINING: 'Trenchcoat Reproofing & Lining',
    UPHOLSTERY: 'Upholstery',
    WATCH_REPAIR: 'Watch Repair',
    WINDOW_WASHING: 'Window Washing',
    ZIPPER_REPAIR: 'Zipper Repair'
});

export const TimePreference = Object.freeze({
    NO_PREFERENCE: "No Preference",
    BEFORE_HOURS: "Before Business Hours",
    DURING_HOURS: "During Business Hours",
    AFTER_HOURS: "After Business Hours"
});

export const UpdatableRequestFields = Object.freeze({
    ASSIGNEE: 'Assignee',
    // ATTACHMENT: 'Attachment', //V2?
    DESCRIPTION: 'Description',
    ON_HOLD: 'On Hold',
    TARGET_DATE: 'Target Date',
    TIME_PREFERENCE: 'Time Preference',
    WALKTHROUGH_PREFERENCE: 'Walkthrough Preference'
});

export const WalkthroughPreference = Object.freeze({
    REQUIRED: 'Walkthrough Required',
    PROHIBITED: 'Walkthrough Prohibited',
    OPTIONAL: 'Walkthrough Optional'
});
