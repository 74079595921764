import React, {useState} from 'react';
import PropTypes from 'prop-types';

import AlertDialog from "../../components/Dialog/AlertDialog";

const RequestApprovalModal = (props) => {
    const {
        requestApproval,
        closeModal,
        open,
        vendorLocation,
    } = props;
    const [loading, setLoading] = useState(false);

    return (
            <AlertDialog
                acceptDisabled={loading}
                acceptText={'Yes, Continue'}
                body={`${vendorLocation.vendor.officialName}'s price is over your approved limit and requires approval. Send a request for approval?`}
                cancelText={'No, Back'}
                debounceAccept
                onAccept={() => {
                    closeModal();
                    requestApproval();
                }}
                onAcceptIgnoreDebounce={() => setLoading(true)}
                onCancel={closeModal}
                open={open}
                title={'Price Requires Approval?'}/>
    )
};

RequestApprovalModal.propTypes = {
    requestApproval: PropTypes.func,
    closeModal: PropTypes.func,
    open: PropTypes.bool,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    })
};

export default RequestApprovalModal;