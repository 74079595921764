import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import StandardPricingPageView from "worksmith/composite-components/ClientLocationProfile/standardPricing/StandardPricingPageView";
import StandardPricingService from "worksmith/services/api/StandardPricingService";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";
import ClientDashboardType from "worksmith/enums/api/cpm/ClientDashboardType";

const graphQLService = new GraphQLServiceClass();
const standardPricingService = new StandardPricingService();


const StandardPricingPage = ({
                                 clientDashboardType,
                                 clientId,
                                 currency,
                                 locationId,
                                 userIsAdmin,
}) => {

    const [selectedServiceLine, setSelectedServiceLine] = useState(null);
    const [standardItemsPricing, setStandardItemsPricing] = useState(null);
    const [selfServiceClientVendorRelationships, setSelfServiceClientVendorRelationships] = useState([]);
    const [loading, setLoading] = useState(false);


    const getClientSelectedServiceLineInfo = () => {
        setLoading(true);
        graphQLService.findOneById(
            clientId,
            GraphQLObjectType.CLIENT,
            clientQueryFields,
            {clientId: clientId}, '$clientId: Long'
        ).then(client => {
            setSelectedServiceLine(client.selectedServiceLines.filter(serviceLine => serviceLine.useTicketing));
        }).catch(error => {
            console.error("Error fetching Client selected service lines: ", error);
            DisplayErrorNotification("Error fetching Client selected service lines");
        }).finally(() => {
            setLoading(false);
        });
    };

    const getClientStandardItemsPricing = async () => {
        try {
            let itemTypesPromise = await standardPricingService.findByClientLocationId(locationId);
            setStandardItemsPricing(itemTypesPromise);
        }
        catch (error) {
            console.error("Error fetching standardPricing :", error);
            await DisplayErrorNotification("Error fetching standard pricing");
        }
    };

    const getAllSelfServiceClientVendorRelationshipOptions = () => {
        graphQLService.findAll(
                {selfServiceClientId: clientId},
                GraphQLObjectType.VENDOR,
                vendorFields,
                `nickname`
            ).then(res => {
            setSelfServiceClientVendorRelationships(res.map(item => {
                return {value: item.vendorLocations[0].id, label: item.nickname}
            }));

        }).catch(error => {
            console.error("Error fetching preferred vendors: ", error);
            DisplayErrorNotification("Error fetching preferred vendors");
        });
    };

    useEffect(() => {
        getClientSelectedServiceLineInfo();
        getClientStandardItemsPricing();
        getAllSelfServiceClientVendorRelationshipOptions();
    }, []);


    return (
        <StandardPricingPageView
            clientDashboardType={clientDashboardType}
            currency={currency}
            getClientStandardItemsPricing={getClientStandardItemsPricing}
            loading={loading}
            locationId={locationId}
            selfServiceClientVendorRelationships={selfServiceClientVendorRelationships}
            selectedServiceLine={selectedServiceLine}
            standardItemsPricing={standardItemsPricing}
            userIsAdmin={userIsAdmin}
        />
    )
};



export default StandardPricingPage;

StandardPricingPage.propTypes = {
    clientDashboardType: CustomPropTypes.enum(ClientDashboardType),
    clientId: PropTypes.number,
    currency: CustomPropTypes.enum(CurrencySymbolEnum),
    locationId: PropTypes.number,
    userIsAdmin: PropTypes.bool,
};


const clientQueryFields = `
    id
    selectedServiceLines {
        id
        lineItemTypes {
            id
            name
            isAvailableForStandardItems
            standardItemsForClient(clientId: $clientId) {
                id
                name
                client {
                    id
                }
            }
        }
        name
        useTicketing
    }
`;

const vendorFields = `
    id
    nickname
    vendorLocations {
        id
    }
`;
