import React from 'react';
import PropTypes from 'prop-types';
import MaterialLinearProgress from '@mui/material/LinearProgress';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {PickColor} from "worksmith/helpers/ThemeHelpers";
import {withTheme} from 'worksmith/components/Theme/ThemeProvider';

export const LinearProgressVariant = Object.freeze({
    DETERMINATE: 'determinate',
    INDETERMINATE: 'indeterminate',
    BUFFER: 'buffer',
    QUERY: 'query'
});

const LinearProgress = (props) => {
    let {
        primary,
        secondary,
        value,
        valueBuffer,
        variant
    } = props;

    return <MaterialLinearProgress
                color={PickColor(primary, secondary)}
                value={value}
                valueBuffer={valueBuffer}
                variant={variant}
            />
};

LinearProgress.propTypes = {
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    value: PropTypes.number,
    valueBuffer: PropTypes.number,
    variant: CustomPropTypes.enum(LinearProgressVariant)
};

LinearProgress.defaultProps = {
    primary: true,
    secondary: false,
    value: 0,
    valueBuffer: 0,
    variant: LinearProgressVariant.DETERMINATE
};

export default withTheme(LinearProgress);
