import React, {Component} from 'react';

import notification from 'antd/lib/notification'
import ChangeRequestService from 'worksmith/services/api/ChangeRequestService';
import {WithContext} from "../../context/GlobalContext";
import ScheduleChangesView from "./ScheduleChangesView";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import ChangeRequestStatus from "worksmith/enums/api/cr/ChangeRequestStatus";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {isExpired} from "worksmith/helpers/ScheduleHelper";
import ChangeRequestType from "../../shared/enums/api/cr/ChangeRequestType";

const changeRequestService = new ChangeRequestService();
const graphQLService = new GraphQLServiceClass();

class ScheduleChanges extends Component {
    constructor(props) {
        super(props);

        this.state = {
            needsClientChangeRequests: [],
            needsVendorChangeRequests: [],
            counteringChangeRequest: null,
            changeRequestCounterModalVisible: false,
            loading: true
        };
    }

    componentDidMount() {
       this.getChangeRequests();
    }

    getChangeRequests = () => {
        let {} = this.state;
        let {clientLocations, showCampaigns}  = this.props.context;
        this.setState({ loading: true });

        let associatedWithCampaign = showCampaigns ? null : false;

        let searchParams = {
            associatedWithCampaign,
            statuses: [ChangeRequestStatus.NEEDS_VENDOR_INPUT, ChangeRequestStatus.NEEDS_CLIENT_INPUT],
            clientLocationIds: clientLocations.map(location => location.id)
        };

        return graphQLService
                .findAll(
                    searchParams,
                    GraphQLObjectType.CHANGE_REQUEST,
                    changeRequestQueryFields)
            .then((data) => {
                let needsVendorChangeRequests = data.filter(cr => cr.status === ChangeRequestStatus.NEEDS_VENDOR_INPUT && !cr.requestedSchedules.every(isExpired));
                let needsClientChangeRequests = data.filter(cr => cr.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT || cr.requestedSchedules.every(isExpired));
                this.setState({needsClientChangeRequests, needsVendorChangeRequests, loading: false});
        });
    };

    counterChangeRequest = (schedules) => {
        let {counteringChangeRequest} = this.state;

        let changeRequest = {
            id: counteringChangeRequest.id,
            scheduleHandler: {
                scheduleOptions: schedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        if(counteringChangeRequest.isRecurring) {
            changeRequest.obligationTemplate = {
                id: counteringChangeRequest.obligationTemplate.id
            };
        } else {
            changeRequest.obligation = {
                id: counteringChangeRequest.obligation.id
            };
        }


        changeRequestService.counterChangeRequest(counteringChangeRequest.id, changeRequest).then(() => {
            this.getChangeRequests();
            this.setShowScheduleOptionsDialog(false);
            notification['success']({
                message: 'New schedule submitted!'
            })
        },
            () => notification['warning']({
                message: 'Error submitting new schedule.'
            })
        );
    };

    acceptChangeRequest = (changeRequestId) => {
        this.setState({ loading: true });
        changeRequestService.accept(changeRequestId).then(() => {
            this.getChangeRequests();
            notification['success']({
                message: 'New schedule confirmed!'
            })
        }).catch(error => {
            console.error("Failed to accept Change Request -" + error);
            notification['warning']({
                message: 'Error accepting new schedule.'
            })
        }).finally(() => {
            this.setState({ loading: false });
        })
    };

    counterChangeRequestModal = (changeRequest) => {
        this.setState({ changeRequestCounterModalVisible: true, counteringChangeRequest: changeRequest });
    };

    cancelChangeRequest = (changeRequestId) => {

        changeRequestService.cancel(changeRequestId).then((data) => {
            this.getChangeRequests();
            notification['success']({
                message: 'Request Canceled!'
            })
        })
    };

    setShowScheduleOptionsDialog = (showScheduleOptionsDialog) => {
        this.setState({changeRequestCounterModalVisible: showScheduleOptionsDialog});
    };

    hideCounterModal = () => {
        this.setState({changeRequestCounterModalVisible: false}, ()=> this.getChangeRequests());
    };

    render() {
        let {changeRequestCounterModalVisible, counteringChangeRequest, loading, needsClientChangeRequests, needsVendorChangeRequests} = this.state;
        let {acceptChangeRequest, cancelChangeRequest, counterChangeRequestModal, hideCounterModal, counterChangeRequest, setShowScheduleOptionsDialog} = this;

        return (
            <ScheduleChangesView
                acceptChangeRequest={acceptChangeRequest}
                cancelChangeRequest={cancelChangeRequest}
                changeRequestCounterModalVisible={changeRequestCounterModalVisible}
                counterChangeRequestModal={counterChangeRequestModal}
                counteringChangeRequest={counteringChangeRequest}
                hideCounterModal={hideCounterModal}
                counterChangeRequest={counterChangeRequest}
                loading={loading}
                needsClientChangeRequests={needsClientChangeRequests}
                needsVendorChangeRequests={needsVendorChangeRequests}
                setShowScheduleOptionsDialog={setShowScheduleOptionsDialog}
            />
        )

    }
}

const changeRequestQueryFields = `
    commonId
    clientLocation {
        id
        title
        addressLineOne
        addressLineTwo
        city
        state
        zip
    }
    createdTimestamp
    createdBy {
        displayName
    }
    creatorType
    currentSchedule {
        arrivalEndTime
        arrivalStartTime
        date
        dayOfMonth
        daysOfWeek
        duration
        recurrenceType 
        repeatEvery
        returnDate
       scheduleDows {
            dayOfWeek
            arrivalStartTime
            arrivalEndTime
        }
    }
    id
    isRecurring
    obligation {
        id
        obligationDate
        obligationTemplate {
            id
        }
        arrivalStartTime
        arrivalEndTime
        duration
        request {
            id
            assignment {
                id 
            }
        } 
    }
    obligationTemplate {
        id
        requestForProposal {
            id
            assignment {
                id 
            }
        } 
        recurringScheduleTemplate {
            readableScheduleString
            duration
            frequency{
                recurrenceType
                recurrenceCount
                repeatEvery
            }
        }
        schedule{
            arrivalStartTime
            arrivalEndTime
            readableString
            date
            dayOfMonth
            daysOfWeek
            duration
            recurrenceType
            repeatEvery
            returnDate
            scheduleDows {
                dayOfWeek
                arrivalStartTime
                arrivalEndTime
            }
        }
    }
    obligationType
    opportunity {
        id
        request {
            description
        }
    }
    request {
        id
        description
        typeName
    }
    requestedSchedules {
        arrivalEndTime
        arrivalStartTime
        date
        dayOfMonth
        daysOfWeek
        duration
        recurrenceType 
        repeatEvery
        returnDate
        scheduleDows {
            dayOfWeek
            arrivalStartTime
            arrivalEndTime
        }        
    } 
    scheduleHandler {
        id
        openSchedules {
            arrivalEndTime
            arrivalStartTime
            date
            dayOfMonth
            daysOfWeek
            duration
            recurrenceType 
            repeatEvery
            scheduleDows {
                dayOfWeek
                arrivalStartTime
                arrivalEndTime
            }
        }
    }
    serviceLine {
        id
        name
        useTicketing
    }
    status
    vendorLocation {
        vendor {
            nickname
        }
    }
`;

export default WithContext(ScheduleChanges);