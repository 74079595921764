// This file was generated from com.worksmith.psldb.enums.ServiceLineType
// noinspection JSUnusedGlobalSymbols
const ServiceLineType = Object.freeze({
    DRY_CLEANING_AND_ALTERATIONS: 'DRY_CLEANING_AND_ALTERATIONS',
    COMMERCIAL_CLEANING: 'COMMERCIAL_CLEANING',
    HANDYMAN: 'HANDYMAN',
    HVAC: 'HVAC',
    PLUMBING: 'PLUMBING',
    PAINTING: 'PAINTING',
    ELECTRICAL_WORK: 'ELECTRICAL_WORK',
    LOCKSMITH: 'LOCKSMITH',
    PEST_CONTROL: 'PEST_CONTROL',
    LANDSCAPING: 'LANDSCAPING',
    OTHER: 'OTHER',
    UPHOLSTERY: 'UPHOLSTERY',
    TRASH_REMOVAL: 'TRASH_REMOVAL',
    FLOOR_CLEANING: 'FLOOR_CLEANING',
    SNOW_REMOVAL: 'SNOW_REMOVAL',
    FIRE_AND_SAFETY_INSPECTION: 'FIRE_AND_SAFETY_INSPECTION',
    MOLD_INSPECTION: 'MOLD_INSPECTION',
    WINDOW_WASHING: 'WINDOW_WASHING',
    APPLIANCE_REPAIR: 'APPLIANCE_REPAIR',
    SEWING_MACHINE_REPAIR: 'SEWING_MACHINE_REPAIR',
    FLOORING_REPAIR: 'FLOORING_REPAIR',
    EVENT_RENTALS: 'EVENT_RENTALS',
    BEVERAGE_SERVICES: 'BEVERAGE_SERVICES',
    DOOR_REPAIR: 'DOOR_REPAIR',
    HURRICANE_PREPARATION: 'HURRICANE_PREPARATION',
    ZIPPER_REPAIR: 'ZIPPER_REPAIR',
    WATCH_REPAIR: 'WATCH_REPAIR',
    TRENCHCOAT_REPROOFING_AND_LINING: 'TRENCHCOAT_REPROOFING_AND_LINING',
    REFRIGERATION_SERVICES: 'REFRIGERATION_SERVICES',
    LIGHTING: 'LIGHTING',
    LEATHER_REPAIR: 'LEATHER_REPAIR',
    WATCH_PARTS: 'WATCH_PARTS',
    FLOWER_AND_PLANT_DELIVERY: 'FLOWER_AND_PLANT_DELIVERY',
    CATERING_SERVICES: 'CATERING_SERVICES',
    MOVING: 'MOVING',
    SECURITY: 'SECURITY',
    COURIER_SERVICES: 'COURIER_SERVICES',
    EXTRA_LABOR: 'EXTRA_LABOR',
    SIGNAGE: 'SIGNAGE',
    POWER_WASHING: 'POWER_WASHING',
    GLASS_AND_MIRROR_REPAIR: 'GLASS_AND_MIRROR_REPAIR',
    IT_SETUP: 'IT_SETUP',
    GREASE_TRAP_SERVICE: 'GREASE_TRAP_SERVICE',
    DENIM_REPAIR: 'DENIM_REPAIR',
    FUR_REPAIR: 'FUR_REPAIR',
    REWEAVING: 'REWEAVING',
    SHOE_REPAIR: 'SHOE_REPAIR',
    VINYL_AND_GRAPHICS: 'VINYL_AND_GRAPHICS',
    VISUAL_MERCHANDISING: 'VISUAL_MERCHANDISING',
    VISUAL_MERCHANDISING_FREELANCE: 'VISUAL_MERCHANDISING_FREELANCE',
    BARTENDER_MIXOLOGIST: 'BARTENDER_MIXOLOGIST',
    PHOTOGRAPHY: 'PHOTOGRAPHY',
    EVENT_ENTERTAINMENT: 'EVENT_ENTERTAINMENT',
    CUSTOM_GIFTING_FOR_EVENTS: 'CUSTOM_GIFTING_FOR_EVENTS',
    CLOTHING_REPAIR: 'CLOTHING_REPAIR',
    COVID_STANDARD: 'COVID_STANDARD',
    COVID_REOPENING: 'COVID_REOPENING',
    COVID_ADVANCED: 'COVID_ADVANCED',
    COVID_SIGNAGE: 'COVID_SIGNAGE',
    WINDOW_BOARDING: 'WINDOW_BOARDING',
    AIR_COMPRESSOR: 'AIR_COMPRESSOR',
    AIR_DRYER: 'AIR_DRYER',
    AIR_HOSE_REEL: 'AIR_HOSE_REEL',
    ASPHAULT_REPAIR: 'ASPHAULT_REPAIR',
    BUG_SPRAY_: 'BUG_SPRAY_',
    CABINETRY: 'CABINETRY',
    CAR_WASH_EQUIPMENT: 'CAR_WASH_EQUIPMENT',
    CEMENT_REPAIR: 'CEMENT_REPAIR',
    COATS_TIRE_MACHINE_REPAIR: 'COATS_TIRE_MACHINE_REPAIR',
    DUCT_CLEANING: 'DUCT_CLEANING',
    EMISSIONS_MACHINE: 'EMISSIONS_MACHINE',
    FENCE_REPAIR: 'FENCE_REPAIR',
    FERTILIZER: 'FERTILIZER',
    GARAGE_DOOR_REPAIR: 'GARAGE_DOOR_REPAIR',
    HUNTER_ALIGNMENT_RACK: 'HUNTER_ALIGNMENT_RACK',
    HUNTER_BRAKE_LATHE: 'HUNTER_BRAKE_LATHE',
    HUNTER_TIRE_MACHINE_REPAIR: 'HUNTER_TIRE_MACHINE_REPAIR',
    INGROUND_ABOVE_GROUND_HOIST: 'INGROUND_ABOVE_GROUND_HOIST',
    LUBE_PIT_LIFT: 'LUBE_PIT_LIFT',
    MENU_BOARD_DISPLAY: 'MENU_BOARD_DISPLAY',
    OIL_PUMPS_LINES_GUNS: 'OIL_PUMPS_LINES_GUNS',
    OIL_WATER_SEPERATOR_SERVICE: 'OIL_WATER_SEPERATOR_SERVICE',
    PRESSURE_WASHER_REPAIR: 'PRESSURE_WASHER_REPAIR',
    ROOF_REPAIR: 'ROOF_REPAIR',
    SECURITY_SYSTEM_CAMERA_DVR: 'SECURITY_SYSTEM_CAMERA_DVR',
    SIGN_REPAIR: 'SIGN_REPAIR',
    SPRINKLER_REPAIR: 'SPRINKLER_REPAIR',
    STRIPING_PARKING_LOT: 'STRIPING_PARKING_LOT',
    TOOL_REPAIR_TORQUE_WRENCH: 'TOOL_REPAIR_TORQUE_WRENCH',
    VACUUM_REPAIR: 'VACUUM_REPAIR',
    VPS_MACHINE: 'VPS_MACHINE',
    WEED_SPRAY: 'WEED_SPRAY',
    YARD_CARE: 'YARD_CARE',
    ZEP_EQUIPMENT: 'ZEP_EQUIPMENT',
    ELEVATOR_REPAIR: 'ELEVATOR_REPAIR',
    FITNESS_EQUIPMENT_REPAIR: 'FITNESS_EQUIPMENT_REPAIR',
    getDefaultServiceLines: function() {
        return [this.LIGHTING, this.COVID_SIGNAGE, this.WINDOW_BOARDING, this.EXTRA_LABOR, this.HVAC, this.LOCKSMITH, this.DRY_CLEANING_AND_ALTERATIONS, this.HANDYMAN, this.ELECTRICAL_WORK, this.COVID_ADVANCED, this.WINDOW_WASHING, this.PLUMBING, this.COVID_STANDARD, this.DOOR_REPAIR, this.FLOOR_CLEANING, this.PEST_CONTROL, this.COVID_REOPENING, this.FIRE_AND_SAFETY_INSPECTION, this.COMMERCIAL_CLEANING];
    }
});

export default ServiceLineType;
