import React from 'react';
import PropTypes from 'prop-types';
import MaterialMenuItem from '@mui/material/MenuItem';
import {withTheme} from '../Theme/ThemeProvider';

const MenuItem = React.forwardRef((props, ref) => {
    const onClick = (event) => {
        event.stopPropagation();
        props.onClick();
    };

    return (
        <MaterialMenuItem ref={ref} onClick={onClick} disabled={props.disabled} data-testid={props.testId}>
            {props.children}
        </MaterialMenuItem>
    )
});

MenuItem.propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    testId: PropTypes.string
};

export default withTheme(MenuItem);