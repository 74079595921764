import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import Button from "worksmith/components/Button/Button";
import Grid from "worksmith/components/Grid/Grid";
import IconButton from "worksmith/components/Button/IconButton";
import Text from "worksmith/components/Text/Text";
import TextField from "worksmith/components/Inputs/TextField/TextField";
import {Color} from "worksmith/enums/Color";
import {Debounce, globalSnackbarTrigger} from "worksmith/helpers/GenericHelpers";
import {Display} from "worksmith/enums/CSSEnums";
import {IconType} from "worksmith/enums/MaterialEnums";

export const vendorOrInHouseTailorType = Object.freeze({
    'ADD_VENDOR': 'Add a Vendor',
    'IN_HOUSE_TAILOR': 'In-House Tailor'
});

const AddInHouseTailorRelationship = ({error, handleChange, helperText, listOfTailors}) => {

    const nameOfTailor = useRef('');
    const textFieldRef = useRef();

    const handleAddTailor = (value) => {
        if (listOfTailors.some(tailor => tailor.name === value)) {
            globalSnackbarTrigger("A tailor with the name entered already exists.")
        } else {
            const newListOfTailors = [...listOfTailors, {id: ("new tailor " + listOfTailors.length + 1), name: value}];
            textFieldRef.current.clear();
            handleChange('inHouseTailorRelationships', newListOfTailors);
        }
    };

    const handleDeleteTailor = (tailorToDelete) => {
        const newListOfTailors = listOfTailors.filter(tailor => tailor.name !== tailorToDelete.name);
        handleChange('inHouseTailorRelationships', newListOfTailors);
    };


    return (
        <Grid container xs={12} margin={0} rowSpacing={2}>
            <Grid item xs={12}>
                <TextField
                    ref={textFieldRef}
                    error={error}
                    helperText={helperText}
                    fullWidth
                    label={'Full Name'}
                    onChange={Debounce((value) => nameOfTailor.current = value)}
                />
            </Grid>
            <Grid item>
                <Button
                    disabled={false}
                    debounce
                    secondary
                    onClick={() => handleAddTailor(nameOfTailor.current)}
                >
                    Add
                </Button>
            </Grid>
            {listOfTailors.map(tailor => {
                return (
                    <Box key={tailor.name} display={Display.FLEX} width={'100%'} sx={{backgroundColor: Color.LIGHT_GREY, margin: '8px 0 0 8px', padding: '8px'}}>
                        <Grid container padding={'8px'}>
                            <Grid item xs={12}>
                                <Text bold>{tailor.name}</Text>
                            </Grid>
                        </Grid>
                        <Box sx={{right: '0'}}><IconButton iconName={IconType.CLOSE} onClick={() => handleDeleteTailor(tailor)}/></Box>
                    </Box>
                )
            })}
        </Grid>
    );
};

AddInHouseTailorRelationship.propTypes = {
    error: PropTypes.bool,
    handleChange: PropTypes.func,
    helperText: PropTypes.string,
    listOfTailors: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }))
};

export default AddInHouseTailorRelationship;