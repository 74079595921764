import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';

import AgendaView from './AgendaView';
import MWDView from './MWDView'

import {AlignItems, Display} from 'worksmith/enums/CSSEnums';
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import AuthTokenManager from "worksmith/services/utilities/AuthTokenManager";
import BigCalendar from 'worksmith/composite-components/BigCalendar/BigCalendar';
import {Color, FunctionalColor, PrimaryColor, SecondaryColor} from 'worksmith/enums/Color';
import {FontFamily} from 'worksmith/components/Theme/ThemeProvider';
import {GraphQLObjectType} from 'worksmith/enums/GraphQLObjectType';
import Grid from 'worksmith/components/Grid/Grid';
import Icon, {IconFontSize} from 'worksmith/components/Icon/Icon';
import {IconType} from "worksmith/enums/MaterialEnums";
import Loader from 'worksmith/components/Loader/Loader';
import {LoaderType} from "worksmith/enums/LoaderType";
import {MomentFormat} from 'worksmith/enums/MomentFormat';
import Select, {SelectViewSize} from 'worksmith/components/Inputs/Select/Select';
import Text, {TextVariant} from 'worksmith/components/Text/Text';
import UserService from "worksmith/services/api/UserService";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const graphQLService = new AsyncGraphQLServiceClass();
const userService = new UserService();
const authTokenManager = new AuthTokenManager();

const StyledContainer = styled.div`
  padding: 16px;
  max-width: 1200px;
  
  h1 {
    padding-bottom: 16px;
  }
`

const StyledLegendSquare = styled.span`
  width: 1rem;
  height: 1rem;
  margin-right: 8px;
  background: ${({color}) => color};
`

const StyledFilter = styled(Grid)`
  opacity: ${({loading}) => loading ? 0.5 : 1};
  legend {
    border: none;
  }
`

const StyledCalendar = styled.div`
  opacity: ${({loading}) => loading ? 0.5 : 1};
  font-family: ${FontFamily};
  
  .rbc-agenda-event-cell {
    color: ${Color.WHITE};
  }

  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    vertical-align: middle;  
  }

  .rbc-agenda-date-cell, .rbc-agenda-time-cell {
    color: ${FunctionalColor.BLACK};
    background: ${Color.WHITE};
  }

  .rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
    overflow: visible;
    display: block;
    white-space: nowrap;
  }

  .rbc-current-time-indicator {
    background-color: ${Color.RED};
  }

  .rbc-event-label {
    display: none;
  }
`

export const ObligationStatusOptions = Object.freeze([
    {
        label: "Rescheduling",
        value: "RESCHEDULING",
    },
    {
        label: "Scheduled",
        value: "SCHEDULED",
    },
    {
        label: "Completed",
        value: "COMPLETED",
    }
]);

const MonthHeader = (props) => {
    const {handleDayView, label} = props
    return (
        <a onClick={handleDayView}>{label}</a>
    )
}

const AgendaTimeColumn = ({event}) => (
    // it looks like the combo of event.allDay being true for all agenda events leads to an invalid agendaEnd time if the event is truly all day
    !moment(event.agendaEnd).isValid() ?
    <span>12:00 am - 11:59 pm</span>
    :
    <span>{moment(event.agendaStart).format(MomentFormat.StandardTime)} - {moment(event.agendaEnd).format(MomentFormat.StandardTime)}</span>
);


const calendarComponents = {
    event: MWDView,
    agenda: {
        event: AgendaView,
        time: AgendaTimeColumn
    },
    month: {
        dateHeader: MonthHeader
    }
};

const Filter = {
    ALL: 'All',
    CLIENT: 'client',
    SERVICE_LINE: 'serviceLine'
};


const ClientCalendarPage = ({clientId, user}) => {

    const [calendarEvents, setCalendarEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]); // acts as a copy of calendar events so that we can keep track of all events even if filters are applied
    const [locationFilter, setLocationFilter] = useState(Filter.ALL);
    const [serviceLineFilter, setServiceLineFilter] = useState(Filter.ALL);

    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [loadedStartDate, setLoadedStartDate] = useState(null);
    const [loadedEndDate, setLoadedEndDate] = useState(null);


    // set this initially to allow first option in dropdown to be All, then add on rest of locations from api call
    const [clientLocations, setClientLocations] = useState([{
        label: Filter.ALL,
        value: Filter.ALL
    }]);

    const [serviceLines, setServiceLines] = useState([{
        label: Filter.ALL,
        value: Filter.ALL
    }]);

    const [loading, setLoading] = useState(true);

    const [view, setView] = useState('agenda'); // ensures default view is set to agenda and won't reset if api call is made

    const fetchClientServiceLines = async () => {
        try {
            let serviceLineList = [];
            const serviceLineData = await graphQLService.findOneById(
                clientId,
                GraphQLObjectType.CLIENT,
                ServiceLineFields
            );

            serviceLineData.obligationServiceLines.forEach(serviceLine => {
                return serviceLineList.push({
                    label: serviceLine.name,
                    value: serviceLine.id
                })
            });
            setServiceLines([...serviceLines, ...serviceLineList]);
        } catch {
            await DisplayErrorNotification("Error fetching service lines");
        }
    };

    const fetchObligations = async (params) => {
        setLoading(true);
        try {
            let clientLocationIdList = [];
            let searchParams = {};

            //admin users
            if (user.clientRoles && user.clientRoles.length > 0) {
                searchParams = {
                    clientId: params.clientId,
                    startDate: params.startDate,
                    endDate: params.endDate
                };
            //regional/local users
            } else if (user.clientLocationRoles && user.clientLocationRoles.length > 0){
                clientLocationIdList = user.clientLocationRoles.map((roles) => {
                    return roles.clientLocation.id;
                });
                searchParams = {
                    clientId: params.clientId,
                    clientLocationIdList: clientLocationIdList,
                    startDate: params.startDate,
                    endDate: params.endDate
                };
            }

            const obligationData = await graphQLService.findAll(
                searchParams,
                GraphQLObjectType.OBLIGATION_SCHEDULE_OBJECT,
                ObligationFields
            );
            const obligationStartDate = loadedStartDate === null || moment(params.startDate).isBefore(moment(loadedStartDate)) ? params.startDate : loadedStartDate;
            const obligationEndDate = loadedEndDate === null || moment(params.startDate).isAfter(moment(loadedEndDate)) ? params.endDate : loadedEndDate;
            setLoadedStartDate(obligationStartDate);
            setLoadedEndDate(obligationEndDate);
            setCalendarEvents([...calendarEvents, ...obligationData]);
            setLoading(false);
        } catch (err) {
            await DisplayErrorNotification("Error fetching obligations");
            setLoading(false);
        }
    };

    const fetchLocations = async () => {
        try {
            let locationList = [];
            const locationData = await userService.getClientLocationsForUser(authTokenManager.getUserId())
            locationData.forEach((location) => {
                return locationList.push({
                    value: location.id,
                    label: location.title
                })
            });
            setClientLocations([...clientLocations, ...locationList]);
        } catch {
            await DisplayErrorNotification("Error fetching locations");
        }

    };

    const onMonthChange = async (selected) => {
        let startDate = moment(selected).startOf('month').subtract(6, 'days').format('YYYY-MM-DD');
        let endDate = moment(selected).endOf('month').add(6, 'days').format('YYYY-MM-DD');

        let coveredDateRanges = 0;

        if (!moment(startDate).isBefore(moment(loadedStartDate))) {
            startDate = moment(loadedEndDate).add(1, 'days').format('YYYY-MM-DD');
            coveredDateRanges++;
        }

        if (!moment(endDate).isAfter(moment(loadedEndDate))) {
            endDate = moment(loadedStartDate).subtract(1, 'days').format('YYYY-MM-DD');
            coveredDateRanges++;
        }

        // checks to see if we need to refetch data when selected date goes beyond the range of dates we've already pulled in
        if (coveredDateRanges !== 2) {
            let params = {clientId, startDate, endDate};
            await fetchObligations(params);
            setSelectedDate(moment(selected).toDate());
        } else {
            setSelectedDate(moment(selected).toDate());
        }
    };

    const transformObligationsForCalendar = (obligations) => {
        const red = Color.LEGEND_EMERGENCY;
        const teal = SecondaryColor['700'];
        const blue = PrimaryColor['500'];
        const gray = PrimaryColor['100'];

        let transformedEvents = [];

        obligations.forEach(item => {
            let allDay = false;
            let startTime;
            let endTime;
            let eventColor;

            if (item.arrivalStartTime === null && item.arrivalEndTime === null) {
                allDay = true;
                startTime = moment(item.obligationDate).format();
            } else if (item.arrivalEndTime === null) {
                startTime = moment(item.obligationDate + " " + item.arrivalStartTime).format();
            } else if (item.arrivalStartTime === null) {
                startTime = moment(item.obligationDate + " " + item.arrivalEndTime).format();
            } else {
                startTime = moment(item.obligationDate + " " + item.arrivalStartTime).format();
                endTime = moment(item.obligationDate + " " + item.arrivalEndTime).format();
            }

            if (item.isForRecurringService) {
                eventColor = blue;
            } else if (item.emergency) {
                eventColor = red;
            } else if (item.isWalkthrough) {
                eventColor = gray;
            } else {
                eventColor = teal;
            }

            const newCalendarItem = {
                agendaEnd: new Date(endTime),
                agendaStart: new Date(startTime),
                allDay: view === 'agenda' ? true : allDay,
                clientLocationId: item.clientLocationId,
                clientLocationTitle: item.clientLocationTitle,
                clientLocationNickname: item.clientLocationNickname,
                color: eventColor,
                completionDate: item.completionDate,
                end: view === 'agenda' ? moment(item.obligationDate).endOf('day') : new Date(endTime),
                isFirstObligationInRecurringService: item.isFirstObligationInRecurringService,
                isRescheduling: item.isRescheduling,
                isWalkthrough: item.isWalkthrough,
                obligationDate: item.obligationDate,
                obligationTemplateId: item.obligationTemplateId,
                requestId: item.requestId,
                serviceLineName: item.serviceLineName,
                serviceLineId: item.serviceLineId,
                start: view === 'agenda' ? moment(item.obligationDate).startOf('day') : new Date(startTime),
                view
            };
            transformedEvents.push(newCalendarItem);
        });
        return transformedEvents;
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style: {backgroundColor: event.color, height: '24px', overflow: 'hidden'}
        }
    };

    const showMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setView('day');
    };

    const selectSlot = (slot) => {
        setSelectedDate(moment(slot.start).toDate());
    };

    // this makes sure that the foundational calendarEvents state is populated with everything each time new data is fetched
    // while also checking for any active filters so that the calendar shows events with filters still applied
    useEffect(() => {
        let filtered = calendarEvents;
        if(locationFilter) {
            if(locationFilter !== Filter.ALL) {
                filtered = filtered.filter(event => event.clientLocationId === locationFilter);
            } else {
                filtered = filtered.filter(event => event.clientLocationId !== locationFilter);
            }
        }

        if(serviceLineFilter) {
            if(serviceLineFilter !== Filter.ALL) {
                filtered = filtered.filter(event => event.serviceLineId === serviceLineFilter);
            } else {
                filtered = filtered.filter(event => event.serviceLineId !== serviceLineFilter);
            }
        }
        setFilteredEvents(filtered);
    }, [calendarEvents, locationFilter, serviceLineFilter]);

    useEffect(() => {
        let startDate = moment().startOf('month').subtract(6, 'days').format('YYYY-MM-DD');
        let endDate = moment().endOf('month').add(6, 'days').format('YYYY-MM-DD');
        const params ={
            clientId,
            startDate,
            endDate
        };

        fetchClientServiceLines();
        fetchLocations();
        fetchObligations(params);
    }, []);

    return (
        <StyledContainer>
            <Text variant={TextVariant.H5}>Calendar</Text>
            <Grid container spacing={2} margin={'40px 0 16px 0'} alignItems={AlignItems.FLEX_START}>
                <StyledFilter loading={loading} container item spacing={2} xs={12} md={4} lg={5}>
                    <Grid item xs={12} lg={8} margin={'0 0 8px 0'}>
                        <Select
                            initialValue={locationFilter}
                            label={"Location"}
                            fullWidth
                            viewSize={SelectViewSize.SMALL}
                            options={clientLocations}
                            disabled={loading}
                            onChange={(value) => setLocationFilter(value)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Select
                            initialValue={serviceLineFilter}
                            label={"Service Line"}
                            fullWidth
                            viewSize={SelectViewSize.SMALL}
                            options={serviceLines}
                            disabled={loading}
                            onChange={(value) => setServiceLineFilter(value)}
                        />
                    </Grid>
                </StyledFilter>
                <Grid container item xs={12} md={8} lg={7} alignItems={AlignItems.FLEX_START}>
                    <Grid container item xs={12} md={4}>
                        <Grid container item xs={12} margin={'0 0 12px 0'}>
                            <StyledLegendSquare color={Color.LEGEND_EMERGENCY}/>
                            <Text display={Display.INLINE} variant={TextVariant.BODY_2}>Emergency</Text>
                        </Grid>
                        <Grid container item xs={12} margin={'0 0 12px 0'}>
                            <StyledLegendSquare color={SecondaryColor['700']}/>
                            <Text display={Display.INLINE} variant={TextVariant.BODY_2}>One-Time</Text>
                        </Grid>
                        <Grid container item xs={12} margin={'0 0 12px 0'}>
                            <StyledLegendSquare color={PrimaryColor['500']}/>
                            <Text display={Display.INLINE} variant={TextVariant.BODY_2}>Recurring Service</Text>
                        </Grid>
                        <Grid container item xs={12} margin={'0 0 12px 0'}>
                            <StyledLegendSquare color={PrimaryColor['100']}/>
                            <Text display={Display.INLINE} variant={TextVariant.BODY_2}>Walkthrough</Text>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={8}>
                        <Grid container item xs={12} margin={'0 0 12px 0'}>
                            <Icon fontSize={IconFontSize.SMALL} name={IconType.CHECK_CIRCLE} iconColor={FunctionalColor.BLACK} left/>
                            <Text display={Display.INLINE} variant={TextVariant.BODY_2}>Completed</Text>
                        </Grid>
                        <Grid container item xs={12} margin={'0 0 12px 0'}>
                            <Icon fontSize={IconFontSize.SMALL} name={IconType.UPDATE} iconColor={FunctionalColor.BLACK} left/>
                            <Text display={Display.INLINE} variant={TextVariant.BODY_2}>Rescheduling the job or recurring service</Text>
                        </Grid>
                        <Grid container item xs={12} margin={'0 0 12px 0'}>
                            <Icon fontSize={IconFontSize.SMALL} name={IconType.LOOKS_ONE} iconColor={FunctionalColor.BLACK} left/>
                            <Text display={Display.INLINE} variant={TextVariant.BODY_2}>The 1st visit of the recurring service</Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <StyledCalendar loading={loading}>
                {loading ? <Loader loaderType={LoaderType.DOTS}/> : null}
                <BigCalendar
                    components={calendarComponents}
                    date={selectedDate}
                    events={transformObligationsForCalendar(filteredEvents)}
                    eventPropGetter={eventStyleGetter}
                    length={6}
                    onNavigate={(selected) => onMonthChange(selected)}
                    onSelectSlot={selectSlot}
                    onShowMore={showMore}
                    onView={(selected) => setView(selected)}
                    view={view}
                />
            </StyledCalendar>
        </StyledContainer>

    )
};

export default ClientCalendarPage


ClientCalendarPage.propTypes = {
    clientId: PropTypes.number,
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
        })),
        clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
            clientLocation: PropTypes.shape({
                title: PropTypes.string,
            }),
        })),
    }),
};

const ServiceLineFields = `
    id
    obligationServiceLines{
        id
        name
    }
`;

const ObligationFields = `
  arrivalEndTime
  arrivalStartTime
  clientId
  clientNickname
  clientLocationId
  clientLocationTitle
  id
  obligationTemplateId
  obligationDate
  serviceLineId
  serviceLineName
  status
  completionDate
  emergency
  requestId
  requestType
  requestStatus
  isForRecurringService
  isRescheduling
  isWalkthrough
  isFirstObligationInRecurringService
`;
