import React, {Component, Fragment} from 'react';
import UserService from 'worksmith/services/api/UserService';
import ClientService from 'worksmith/services/api/ClientService';
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import moment from 'moment';
import {Link, withRouter} from 'react-router-dom';
import Loader from "../../components/Loader";
import styled from 'styled-components';

import {DatePicker} from 'antd';
import Button from '../../components/Button';
import WSButton from '../../components/Button';
import Table from "antd/lib/table";
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import RecurringJobsListView from "../../components/jobs/recurring/RecurringJobsListView";
import WorksmithSelect from "../../components/WorksmithSelect";
import WorksmithCsvLink from "../../components/WorksmithCsvLink";
import {WithContext} from "../../context/GlobalContext";
import {ClientDashboardType, ClientUserGroups} from "../../Enums";
import {Display, JustifyContent} from "../../CSSEnums";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {Color} from "worksmith/enums/Color";
import {CalculateClientDisplayStatus} from "worksmith/helpers/TicketStatusHelper";
import CombinedTicketingForm from "../../components/forms/obligations/CombinedTicketingForm";
import TicketVendorDeliveryType from "worksmith/enums/api/task/TicketVendorDeliveryType";

const clientService = new ClientService();

const csvRef = React.createRef();

const Option = Select.Option;
const Search = Input.Search;

const userService = new UserService();
const obligationItemGroupService = new ObligationItemGroupService();
const authTokenManager = new AuthTokenManager();

const monthDayYear = "M/D/Y";

const TicketsToolbarGrid = styled.div`
  display: grid;
  grid-template-rows: 100%;
  margin: .5em 0 .5em 0;
  grid-template-columns: 20% 40% 10% 10% 20%;
  grid-template-areas: 'ticketStatus dateRange updateRange . search';
    @media (max-width: 48em) {
        margin-bottom: 2em;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        grid-template-areas: 
            'ticketStatus'
            'dateRange' 
            'updateRange'
            '.' 
            'search';    
    }

`;

const WarningText = styled.div`
    font-weight: bold;
    color: ${Color.RED};
`;

const TableHeader = styled.div`
    display: ${Display.FLEX};
    flex: 1;
    justify-content: ${JustifyContent.SPACE_BETWEEN};
`;


class BurberryDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingLocations: true,
            printing: false,
            ticketType: 'Open',
            hasSingleLocation: true,
            csvFileName: '',
            loadingCSV: false,
            locations: [
                {
                    id: 0,
                    title: 'All Locations'
                }
            ],
            allTickets: null,
            allFieldsOnTickets: null,
            filteredTickets: [],
            csvData: '',
            selectedRowKeys: [],
            filter: '',
            searchParams: {
                hasCompletedObligation: null,
                isPending: true,
                startDate: null,
                endDate: null
            },
            sort: {
                order: 'ascend',
                columnKey: 'createdTimestamp'
            },
            returnedButNotConfirmedStatuses: [
                'dropped off by vendor',
                'return shipped from vendor'
            ],
            newlyCreatedObligation: {},
            cancelServiceId: null,
            dryCleaningSettings: {}
        };

        this.getUserLocations = this.getUserLocations.bind(this);
        this.getTickets = this.getTickets.bind(this);
        this.filterTickets = this.filterTickets.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.renderTicketsTable = this.renderTicketsTable.bind(this);
        this.handleTextSearch = this.handleTextSearch.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.onTicketTypeChange = this.onTicketTypeChange.bind(this);
        this.compareStringNumbers = this.compareStringNumbers.bind(this);
        this.compareNullableDates = this.compareNullableDates.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.printTickets = this.printTickets.bind(this);
        this.isConfirmedByStore = this.isConfirmedByStore.bind(this);
        this.cancelService = this.cancelService.bind(this);
        this.onCancelServiceSuccess = this.onCancelServiceSuccess.bind(this);

        this.newServiceModalRef = React.createRef();
        this.cancelServiceModalRef = React.createRef();
    }

    componentWillMount() {
        let _this = this;
        let {client} = this.props;
        let {searchParams} = this.state;

        clientService.getSingleDryCleaningFormConfiguration(client.id).then((data) => {
            searchParams.hasCompletedObligation = data.filterTicketsWithCompletedTaskOnDashboard ? false : null;
            this.setState({dryCleaningSettings: data, searchParams: searchParams}, () => {
                this.getUserLocations().then(function() {
                    _this.getTickets();
                });
            });
        });
    }

    generateCsvFileName = () => {
        let {searchParams, ticketType} = this.state;

        let csvFileName = ticketType + " Tickets";

        if (searchParams.endDate && searchParams.startDate) {
           csvFileName = csvFileName + " " + moment(searchParams.startDate).format('MM-DD-YYYY') + ' - ' + moment(searchParams.endDate).format('MM-DD-YYYY');
        } else if (searchParams.startDate) {
           csvFileName = csvFileName + " After " + moment(searchParams.startDate).format('MM-DD-YYYY');
        } else if (searchParams.endDate) {
           csvFileName = csvFileName + " Before " + moment(searchParams.endDate).format('MM-DD-YYYY');
        }

        this.setState({csvFileName});
    };

    isConfirmedByStore(ticket) {
        return ticket.isReturnedToStore && this.state.returnedButNotConfirmedStatuses.indexOf(ticket.status) < 0;
    }

    getUserLocations() {

        let _this = this;

        return userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId()).then(function (data) {
            let locationsData = data;
            let hasSingleLocation = true;

            if (locationsData.length > 1) {
                hasSingleLocation = false;
            }

            let searchParams = _this.state.searchParams;
            searchParams.clientLocationId = ValueIsSet(locationsData[0]) ? locationsData[0].id : null;

            let locations = _this.state.locations.concat(locationsData);

            _this.setState({ searchParams, locations: locations, hasSingleLocation: hasSingleLocation, loadingLocations: false });
        });
    }

    getTickets() {
        let _this = this;

        _this.setState({loading: true, selectedRowKeys: []});

        let { searchParams } = _this.state;

        this.generateCsvFileName();
        obligationItemGroupService.findClientSummaries(searchParams).then(function (data) {
            _this.setState({ allTickets: data});

            let allFieldsOnTickets = new Set([]);

            data.forEach((ticket) => {
                Object.getOwnPropertyNames(ticket).forEach((name) => {
                    //Only add fields from the base ticket fields if at least one ticket is using that value
                    if (ValueIsSet(ticket[name]))
                        allFieldsOnTickets.add(name);
                });

                Object.getOwnPropertyNames(ticket.metadata).forEach((name) => {
                    allFieldsOnTickets.add(name);
                });

                ticket.displayStatus = CalculateClientDisplayStatus(ticket, ticket.vendor.name);
            });

            _this.setState({allFieldsOnTickets: allFieldsOnTickets});
        })
    }

    cancelService(id) {
        this.setState({cancelServiceId: id});
        this.cancelServiceModalRef.current.open();
    }

    onCancelServiceSuccess() {
        this.cancelServiceModalRef.current.close();
    }

    filterTickets() {
        let filteredTickets = this.state.allTickets;

        this.setState({ filteredTickets });
        this.renderTicketsTable();
    }

    onLocationChange(location) {
        let { searchParams } = this.state;

        if(location === 0) {
            searchParams.clientLocationId = null;
        } else {
            searchParams.clientLocationId = location;
        }

        this.setState({searchParams});

        this.getTickets();
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    };

    startDateChanged = (date) => {
        let { searchParams } = this.state;
        searchParams.startDate = date;
        this.setState({searchParams});
    };

    endDateChanged = (date) => {
        let { searchParams } = this.state;
        searchParams.endDate = date;
        this.setState({searchParams});
    };

    updateDateRange = () => {
        this.getTickets();
    };

    handleTextSearch(e) {
        let searchValue = e.target.value;
        let filteredTickets = this.state.allTickets;

        if(searchValue != null && searchValue.length > 0) {
            let lowerCaseFilter = searchValue.toLowerCase();

            filteredTickets = filteredTickets.filter(function(ticket) {

                if(ticket.burberryRequestNumber != null && ticket.burberryRequestNumber.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(ticket.customerName != null && ticket.customerName.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(ticket.id.toString().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(ticket.creator != null && ticket.creator.name.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                if(ticket.vendor.name.toLowerCase().indexOf(lowerCaseFilter) >= 0)
                    return true;

                return ticket.serviceLine.name.toLowerCase().indexOf(lowerCaseFilter) >= 0;


            })
        }

        this.setState({filter: searchValue, filteredTickets});
    }

    handleSortChange(pagination, filters, sorter) {
        this.setState({sort: sorter});
    }

    onTicketTypeChange(value) {
        let {dryCleaningSettings} = this.state;

        if(this.state.ticketType === value)
            return;

        let { searchParams } = this.state;
        //Only return pending tickets if the ticket type is open

        if(value === 'Open') {
            if (dryCleaningSettings.filterTicketsWithCompletedTaskOnDashboard)
                searchParams.hasCompletedObligation = false;

            searchParams.isPending = true;
            searchParams.endDate = null;
            searchParams.startDate = null;
        } else if (value === 'Closed') {
            if (dryCleaningSettings.filterTicketsWithCompletedTaskOnDashboard) {
                searchParams.hasCompletedObligation = true;
                searchParams.isPending = null;
            } else {
                searchParams.isPending = false;
            }
        } else {
            searchParams.hasCompletedObligation = null;
            searchParams.isPending = null;
        }

        this.setState({ ticketType: value, searchParams });

        this.getTickets();
    }

    compareStringNumbers(a, b) {
        if(!isNaN(a) && !isNaN(b)) {
            return Number(a) - Number(b);
        } else if (!isNaN(a)) {
            return -1;
        } else if (!isNaN(b)) {
            return 1;
        } else {
            return b.localeCompare(a)
        }
    }

    compareNullableDates(a, b) {
        if(a != null && b != null) {
            return moment(a).diff(moment(b), 'days');
        } else if (a == null) {
            return -1;
        } else if (b == null) {
            return 1;
        } else {
            return 0
        }
    }

    downloadTicketsCsv = () => {
        let _this=this;
        let {selectedRowKeys} = this.state;

        this.setState({loadingCSV: true});
        obligationItemGroupService.getClientSummariesCsv(selectedRowKeys).then(function(data){
            _this.setState({csvData: data, loadingCSV: false}, () => {
                csvRef.current.clickLink();
            });
        });
    };

    printTickets() {
        let _this = this;
        _this.setState({printing : true});
        let { selectedRowKeys } = this.state;

        let vendorMap = {};
        _this.state.filteredTickets.filter(function(item) {
            if(selectedRowKeys.indexOf(item.id) >= 0) {
                vendorMap[item.id] = item.vendor.name;
            }
        });

        obligationItemGroupService.findAllByIds(selectedRowKeys).then(function (data) {
            let tickets = data;

            //To be used when printing these tickets
            tickets.forEach(function(ticket) {
                ticket.vendorLocation = {
                    name: vendorMap[ticket.id]
                };
            });

            window.localStorage.setItem('selectedTickets', JSON.stringify(tickets));
            window.open("/#/printTickets");
            _this.setState({ selectedRowKeys: [], printing: false });
        });
    }

    renderTicketsTable() {
        let _this = this;
        let { filteredTickets, allTickets, sort, selectedRowKeys, allFieldsOnTickets, searchParams} = this.state;
        let {clientDashboardType} = this.props.context;
        let {isSelfService} = this.props.client;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        // See ObligationItemGroupService for CSV generation based on this table
        const columnOptions = {
            groupId: {
                title: 'Transaction Number',
                dataIndex: 'groupId',
                key: 'groupId',
                render: (text, record) => <Link to={`/single-ticket/${record.obligationId}/${record.id}`}>{ValueIsSet(text) ? text : '--'}</Link>,
                sorter: (a, b) => a.id - b.id,
                sortOrder: sort.columnKey === 'groupId' && sort.order
            },
            itemNumber: {
                title: 'WS Item',
                dataIndex: 'id',
                key: 'itemNumber',
                render: (text, record) => <Link to={`/single-ticket/${record.obligationId}/${record.id}`}>{text}</Link>,
                sorter: (a, b) => a.id - b.id,
                sortOrder: sort.columnKey === 'itemNumber' && sort.order
            },
            requestNumber: {
                title: 'Request #',
                dataIndex: 'burberryRequestNumber',
                key: 'requestNumber',
                sorter: (a, b) => _this.compareStringNumbers(a.burberryRequestNumber, b.burberryRequestNumber),
                sortOrder: sort.columnKey === 'requestNumber' && sort.order
            },
            createdTimestamp: {
                title: 'Entry Date',
                dataIndex: 'createdTimestamp',
                key: 'createdTimestamp',
                render: text => moment(text).format(isSelfService ? 'YYYY/MM/DD' : 'MM/DD/YYYY'),
                sorter: (a, b) => moment(a.createdTimestamp).diff(moment(b.createdTimestamp), 'days'),
                sortOrder: sort.columnKey === 'createdTimestamp' && sort.order
            },
            creator: {
                title: 'Associate',
                dataIndex: 'creator.name',
                key: 'creator',
                render: text => text != null ? text : '-',
                sorter: (a, b) => a.creator.name.localeCompare(b.creator.name),
                sortOrder: sort.columnKey === 'creator' && sort.order
            },
            associate: {
                title: 'Associate',
                dataIndex: 'metadata.associate',
                key: 'associate',
                render: text => text != null ? text : '-',
                sorter: (a, b) => a.metadata.associate.localeCompare(b.metadata.associate),
                sortOrder: sort.columnKey === 'associate' && sort.order
            },
            customerName: {
                title: 'Client Name',
                dataIndex: 'customerName',
                key: 'customerName',
                sorter: (a, b) => a.customerName.localeCompare(b.customerName),
                sortOrder: sort.columnKey === 'customerName' && sort.order
            },
            clientName: {
                title: 'Client Name',
                dataIndex: 'metadata.clientName',
                key: 'clientName',
                sorter: (a, b) => a.metadata.clientName.localeCompare(b.metadata.clientName),
                sortOrder: sort.columnKey === 'clientName' && sort.order
            },
            hbeNumber: {
                title: 'HBE Number',
                dataIndex: 'metadata.hbeNumber',
                key: 'hbeNumber',
                sorter: (a, b) => a.metadata.clientName.localeCompare(b.metadata.hbeNumber),
                sortOrder: sort.columnKey === 'hbeNumber' && sort.order
            },
            guestName: {
                title: 'Guest Name',
                dataIndex: 'metadata.guestName',
                key: 'guestName',
                sorter: (a, b) => a.metadata.guestName.localeCompare(b.metadata.guestName),
                sortOrder: sort.columnKey === 'guestName' && sort.order
            },
            guestRolId: {
                title: 'Guest ROI ID',
                dataIndex: 'metadata.guestRolId',
                key: 'guestRolId',
                sorter: (a, b) => a.metadata.guestRolId.localeCompare(b.metadata.guestRolId),
                sortOrder: sort.columnKey === 'guestRolId' && sort.order
            },
            employeeName: {
                title: 'Employee Name',
                dataIndex: 'metadata.employeeName',
                key: 'employeeName',
                sorter: (a, b) => a.metadata.employeeName.localeCompare(b.metadata.employeeName),
                sortOrder: sort.columnKey === 'employeeName' && sort.order
            },
            serviceLine: {
                title: 'Service Line',
                dataIndex: 'serviceLine.name',
                key: 'seviceLine',
                sorter: (a, b) => a.serviceLine.name.localeCompare(b.serviceLine.name),
                sortOrder: sort.columnKey === 'serviceLine' && sort.order
            },
            vendor: {
                title: 'Vendor',
                dataIndex: 'vendor.name',
                key: 'vendor',
                render: (text, record) =>ValueIsSet(record.vendorContact) ? <div>{record.vendor.name} <br/> {record.vendorContact.displayName} <br/> {record.vendorContact.phone}</div> : record.vendor.name,
                sorter: (a, b) => a.vendor.name.localeCompare(b.vendor.name),
                sortOrder: sort.columnKey === 'vendor' && sort.order
            },
            serviceDuration: {
                title: 'Speed of Service',
                dataIndex: 'urgencyType',
                key: 'serviceDuration',
                render: (text, record) => moment().isAfter(moment(record.returnDate)) ?
                    <WarningText>{(text ? text.toUpperCase() : 'STANDARD') + " - " + record.turnaroundDays + " days"}</WarningText> : (text ? text.toUpperCase() : 'STANDARD') + " - " + record.turnaroundDays + " days",
                sorter: (a, b) => a.urgencyType.localeCompare(b.urgencyType),
                sortOrder: sort.columnKey === 'serviceDuration' && sort.order
            },
            lastOutreach: {
                title: 'Last Outreach',
                dataIndex: 'lastOutreach',
                key: 'lastOutreach',
                render: (text, record) => text != null ? moment(text).format('MM/DD/YYYY hh:mm a') : this.isConfirmedByStore(record) ?
                    <WarningText>No Outreach</WarningText> : 'No Outreach',
                sorter: (a, b) => _this.compareNullableDates(a, b),
                sortOrder: sort.columnKey === 'lastOutreach' && sort.order
            },
            name: {
                title: 'Customer Name',
                dataIndex: 'customerName',
                key: 'customerName',
                sorter: (a, b) => a.customerName.localeCompare(b.customerName),
                sortOrder: sort.columnKey === 'customerName' && sort.order
            },
            pickupDate: {
                title: 'Pick Up',
                dataIndex: 'date',
                key: 'date',
                render: (text, record) => record.vendorDeliveryType === TicketVendorDeliveryType.VENDOR_PICKUP ? moment(text).format(isSelfService ? 'YYYY/MM/DD' : 'MM/DD/YYYY') : null,
                sorter: (a, b) => moment(a.date).diff(moment(b.date), 'days'),
                sortOrder: sort.columnKey === 'date' && sort.order
            },
            returnDate: {
                title: 'Drop Off / Due Date',
                dataIndex: 'returnDate',
                key: 'returnDate',
                render: (text) => moment(text).format(isSelfService ? 'YYYY/MM/DD' : 'MM/DD/YYYY'),
                sorter: (a, b) => moment(a.returnDate).diff(moment(b.returnDate), 'days'),
                sortOrder: sort.columnKey === 'returnDate' && sort.order
            },
            status: {
                title: 'Status',
                dataIndex: 'displayStatus',
                key: 'status',
                sorter: (a, b) => a.status.localeCompare(b.status),
                sortOrder: sort.columnKey === 'status' && sort.order
            },
            ticketType: {
                title: 'Ticket Type',
                dataIndex: 'ticketType',
                key: 'ticketType',
                sorter: (a, b) => a.ticketType.localeCompare(b.ticketType),
                sortOrder: sort.columnKey === 'ticketType' && sort.order
            },
            clientLocation: {
                title: 'Location',
                dataIndex: 'clientLocation.name',
                key: 'clientLocation',
                sorter: (a, b) => a.clientLocation.name.localeCompare(b.clientLocation.name),
                sortOrder: sort.columnKey === 'clientLocation' && sort.order
            }
        };

        let columns = [];
        switch (clientDashboardType) {
            case ClientDashboardType.BURBERRY:
                columns = [
                    columnOptions.itemNumber,
                    columnOptions.requestNumber,
                    columnOptions.createdTimestamp,
                    columnOptions.creator,
                    columnOptions.customerName,
                    columnOptions.serviceLine,
                    columnOptions.vendor,
                    columnOptions.serviceDuration,
                    columnOptions.lastOutreach,
                    columnOptions.pickupDate,
                    columnOptions.returnDate,
                    columnOptions.status
                ];
                break;
            case ClientDashboardType.HUGO_BOSS:
                columns = [
                    columnOptions.itemNumber,
                    columnOptions.createdTimestamp,
                    columnOptions.creator,
                    columnOptions.clientName,
                    columnOptions.serviceLine,
                    columnOptions.vendor,
                    columnOptions.serviceDuration,
                    columnOptions.pickupDate,
                    columnOptions.returnDate,
                    columnOptions.status
                ];

                if (isSelfService) {
                    // replace itemNumber column with groupId
                    columns.splice(0, 1, columnOptions.groupId);
                    // add column for hbeNumber
                    columns.splice(4, 0, columnOptions.hbeNumber);
                }

                break;
            case ClientDashboardType.DEFAULT_NEW:
            case ClientDashboardType.INTERMIX:
                columns = [
                    columnOptions.itemNumber,
                    columnOptions.ticketType,
                    columnOptions.createdTimestamp,
                    columnOptions.serviceLine,
                    columnOptions.vendor,
                    columnOptions.pickupDate,
                    columnOptions.returnDate,
                    columnOptions.status
                ];

                if (ValueIsSet(allFieldsOnTickets) && allFieldsOnTickets.has('associate'))
                    columns.push(columnOptions.associate);

                if (ValueIsSet(allFieldsOnTickets) && allFieldsOnTickets.has('clientName'))
                    columns.splice(2, 0, columnOptions.clientName);

                if (ValueIsSet(allFieldsOnTickets) && allFieldsOnTickets.has('employeeName'))
                    columns.splice(2, 0, columnOptions.employeeName);

                if (ValueIsSet(allFieldsOnTickets) && allFieldsOnTickets.has('guestName'))
                    columns.splice(2, 0, columnOptions.guestName);

                if (ValueIsSet(allFieldsOnTickets) && allFieldsOnTickets.has('guestRolId'))
                    columns.splice(2, 0, columnOptions.guestRolId);

                if (ValueIsSet(allFieldsOnTickets) && allFieldsOnTickets.has('customerName'))
                    columns.splice(2, 0, columnOptions.name);

                //If it's filtered to all locations show the location
                if(searchParams.clientLocationId === 0 || !searchParams.clientLocationId) {
                    columns.splice(2, 0, columnOptions.clientLocation);
                }

                break;
        }

        return <Table dataSource={filteredTickets} rowSelection={rowSelection} onRowClick={(row) => this.props.history.push(`/single-ticket/${row.obligationId}/${row.id}`) } rowKey="id" columns={columns} scroll={{x: 800}} size="small" onChange={this.handleSortChange} pagination={{
            total:filteredTickets.length,
            showTotal: total => `Filtered to ${total} of ${allTickets.length} tickets`,
            pageSize:20,
            defaultCurrent:1}} />;
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.allTickets!==this.state.allTickets){
            this.filterTickets();
            this.setState({loading: false});
        }
    }

    render() {

        let _this = this;
        let {startDateChanged, endDateChanged, updateDateRange, downloadTicketsCsv, onLocationChange, handleTextSearch, onTicketTypeChange} = this;
        let { loading, loadingLocations, hasSingleLocation, loadingCSV, csvData, csvFileName, allTickets, locations, ticketType, selectedRowKeys, printing, dryCleaningSettings} = this.state;
        let locationId = this.state.searchParams.clientLocationId;
        let {clientDashboardType, user} = this.props.context;
        let {isSelfService} = this.props.client;
        let {client} = this.props;

        if (loadingLocations)  {
            return (
                <Loader/>
            )
        } else {
            return (
                <div>
                    {!hasSingleLocation ?
                        <section className="ws-section">
                            <Row className="margin-bottom-sm">
                                <Col>
                                    <div style={{marginBottom: '.5em', fontSize: '18px'}}>Location</div>
                                    <WorksmithSelect
                                        options={locations}
                                        onSelect={onLocationChange}
                                        defaultValue={locations[1].id}
                                        style={{width: '100%', fontSize: '16px'}}
                                        valueFieldName={"id"}
                                        optionFieldName={"title"}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    />
                                </Col>
                            </Row>
                        </section>
                        :
                        null
                    }
                    <section className="ws-section">
                        <TableHeader>
                            <div style={{fontSize: '18px'}}>Tickets</div>
                            {clientDashboardType === ClientDashboardType.HUGO_BOSS || clientDashboardType === ClientDashboardType.INTERMIX || clientDashboardType === ClientDashboardType.DEFAULT_NEW ?
                                <Fragment>
                                    <div>
                                        <Button type={'success'} message={'Add On-Demand Pick Up'}
                                                onPress={() => this.newServiceModalRef.current.open()}/>
                                    </div>
                                    <CombinedTicketingForm
                                        newServiceModalRef={this.newServiceModalRef}
                                        client={client}
                                        onSuccess={() => this.getTickets()}
                                    />
                                </Fragment>
                                :
                                null
                            }
                        </TableHeader>
                        <TicketsToolbarGrid>
                            <div style={{'gridArea': 'ticketStatus', marginRight: '2.5%'}}>
                                <div style={{fontSize: '12px'}}>Ticket Type</div>
                                <Select value={ticketType} onChange={onTicketTypeChange} style={{width: '100%'}}>
                                    <Option value="Open">Open Tickets</Option>
                                    <Option value="Closed">Closed Tickets</Option>
                                    <Option value="All">All Tickets</Option>
                                </Select>
                            </div>
                            {ticketType === 'Closed' || ticketType === 'All' ?
                                <Fragment>
                                    <div style={{'gridArea': 'dateRange'}}>
                                        <div style={{fontSize: '12px'}}>Select Pick Up Date Range</div>
                                        <DatePicker
                                            placeholder="Start Date"
                                            format={monthDayYear}
                                            onChange={startDateChanged}
                                            style={{'marginRight': '.5%', width: '49%'}}
                                        />
                                        <DatePicker
                                            placeholder="End Date"
                                            format={monthDayYear}
                                            onChange={endDateChanged}
                                            style={{'marginLeft': '.5%', width: '49%'}}
                                        />
                                    </div>
                                    <div style={{'gridArea': 'updateRange', marginTop: '1.1em'}}>
                                        <Button onPress={updateDateRange} style={{width: '100%'}} message={"Search"}
                                                size={'sm'} type={"primary"}/>
                                    </div>
                                </Fragment>
                                :
                                null
                            }
                            <div style={{'gridArea': 'search', marginTop: '1.1em'}}>
                                <Search size="large" placeholder="Search" onChange={handleTextSearch}/>
                            </div>
                        </TicketsToolbarGrid>
                        <Row>
                            <Col>
                                {loading ?
                                    <Loader/>
                                    : <Row>
                                        <Col>
                                            {ValueIsSet(allTickets) && allTickets.length > 0 ?
                                                <div>
                                                    {this.renderTicketsTable()}
                                                    <div style={{textAlign: 'right'}}>
                                                        {ClientUserGroups.userInGroup(dryCleaningSettings.showExportCSVFor, user) ?
                                                            <Button
                                                                style={{marginRight: '.75em'}}
                                                                type={'primary'}
                                                                message={loadingCSV ? 'loading ...' : 'Export Selected to CSV'}
                                                                disabled={selectedRowKeys.length === 0 || loadingCSV}
                                                                onPress={downloadTicketsCsv}/>
                                                            :
                                                            null
                                                        }
                                                            <WorksmithCsvLink ref={csvRef} filename={csvFileName} data={csvData} style={{display:'none'}}/>
                                                        <WSButton
                                                            message={'Print Selected Tickets'}
                                                            type={'primary'}
                                                            disabled={selectedRowKeys.length === 0 || printing}
                                                            onPress={() => _this.printTickets()}/>
                                                    </div>
                                                </div>
                                                :
                                                <p>There are no tickets to show.</p>}
                                        </Col>
                                    </Row>}
                            </Col>
                        </Row>
                    </section>
                    {!isSelfService ?
                        <section className="ws-section">
                            <div style={{fontSize: '18px'}}>Scheduled Services</div>
                            <Row className="margin-bottom-sm">
                                <Col span={24}>
                                    <RecurringJobsListView clientId={this.props.client.id}
                                                           locationId={locationId}
                                                           showActiveServicesOnly={true}
                                                           hidePricing={true}
                                                           hideStartDate={true}
                                                           hideServiceLine={true}
                                                           hideDescription={true}/>
                                </Col>
                            </Row>
                        </section>
                    : null}
                </div>
            )
        }
    }
}

export default WithContext(withRouter(BurberryDashboard));
