import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    BytesToMegabytes,
    DeepCopyFiles,
    MaxCombinedFileSize,
    MaxFileSize,
    TruncateFloat,
    ValueIsSet
} from "worksmith/helpers/GenericHelpers";
import FileUploadView from "./FileUploadView";
import Grid from "worksmith/components/Grid/Grid";
import MaxFileSizeTextAndToolTip from "worksmith/components/FileUpload/MaxFileSizeTextAndToolTip";
import {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import Button from "worksmith/components/Button/Button";
import {convertToHEICToJPG} from "worksmith/helpers/WebHelper";
import {DisplayErrorNotification} from "../../helpers/SweetAlertHelpers";

const updateFilesUrlAndName = (files) => {
    const attachments =  files.map(file => {
        return {
            ...file,
            name: ValueIsSet(file.name) ? file.name : ValueIsSet(file.fileName) ? file.fileName : file.title,
            url: ValueIsSet(file.url) ? file.url : file.singleUseUrl,
        }

    });
    return attachments
};

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: updateFilesUrlAndName(props.initialFiles),
            lightBoxOpen: false,
            lightBoxInitialIndex: 0,
            loadingPreviewImage: false,
        };

        this.totalSize = 0;
    }

    shouldComponentUpdate(nextProps) {
        if(nextProps.initialFiles !== this.props.initialFiles)
            this.state.files = nextProps.initialFiles;
        return true;
    }

    addFiles = async (event) => {
        this.setState({loadingPreviewImage: true});
        const {generatePreviewUrlForFiles, sizeValidation} = this;
        let   {totalSize} = this;
        const {onChange} = this.props;
        const {files} = this.state;

        const newFiles = Array.from(event.target.files);
        const filesCopy = DeepCopyFiles(files).concat(newFiles);
        let cleanedFiles = [];

        filesCopy.forEach(file => {
            totalSize += file.size;
            if(!sizeValidation(file)) {
                totalSize -= file.size;
                // noinspection JSIgnoredPromiseFromCall
                DisplayErrorNotification(file.name + ' cannot have a size exceeding 15MB. size: ' + TruncateFloat(BytesToMegabytes(file.size), 2) + 'MB');
            } else if(BytesToMegabytes(totalSize) > MaxCombinedFileSize) {
                totalSize -= file.size;
                // noinspection JSIgnoredPromiseFromCall
                DisplayErrorNotification('The total file size cannot have a size exceeding 60MB. size: ' + TruncateFloat(BytesToMegabytes(file.size), 2) + 'MB');
            } else {
                cleanedFiles.push(file);
            }
        });

        cleanedFiles = await convertToHEICToJPG(filesCopy);

        cleanedFiles = generatePreviewUrlForFiles(cleanedFiles);
        if (ValueIsSet(onChange)) {
            onChange(cleanedFiles);
        }
        this.setState({
            files: cleanedFiles,
            loadingPreviewImage: false
        });
    };

    addMediaLibraryFiles = (mediaFiles) => {
        const markedAsMediaFiles = mediaFiles.map(file => {
            file.isMediaFile = true
            return file
        })
        const filesCopy = DeepCopyFiles(this.state.files).concat(markedAsMediaFiles);
        const previewFiles = this.generatePreviewUrlForFiles(filesCopy);
        this.props.onChange(previewFiles);
        this.setState({files: previewFiles})
    }

    bytesToMegaBytes = (byteSize) => {
        return byteSize/1000000;
    };

    generatePreviewUrlForFiles = (fileArray) => {
        return fileArray.map(file => {
            file.url = URL.createObjectURL(file);
            return file;
        });
    };

    removeFile = (fileIndex) => {
        const {generatePreviewUrlForFiles} = this;
        let   {totalSize} = this;
        const {onChange} = this.props;
        const {files} = this.state;
        let filesCopy = DeepCopyFiles(files);

        totalSize -= filesCopy[fileIndex].size;
        filesCopy.splice(fileIndex, 1);
        filesCopy = generatePreviewUrlForFiles(filesCopy);
        if (ValueIsSet(onChange)) {
            onChange(filesCopy);
        }
        this.setState({files: filesCopy});
    };

    sizeValidation = (file) => {
        const {bytesToMegaBytes} = this;
        return bytesToMegaBytes(file.size) < MaxFileSize;
    };

    onLightBoxClose = () => {
        this.setState({lightBoxOpen: false});
    };

    onFilePreviewClick = (fileIndex) => {
        this.setState({lightBoxOpen: true, lightBoxInitialIndex: fileIndex});
    };

    render() {
        const {addFiles, addMediaLibraryFiles, removeFile, onLightBoxClose, onFilePreviewClick} = this;
        const {files, lightBoxOpen, lightBoxInitialIndex, loadingPreviewImage} = this.state;
        const {
            acceptedFileFormats,
            addOnly,
            allowMediaLibrary,
            centerAddAttachmentButton,
            disabled,
            error,
            showFileName,
            hideMaxFileSizeInfo,
            limitToOneFile,
            locationId,
            maxFileSizeCaptionsUnderAddAttachmentButton,
            multiple,
            noPreviewMargin,
            processing,
            readOnly,
            showUploadButtonAbovePreview,
            submitId,
            submitFileAttachmentInsideComponent,
            title,
            tooltipPlacement,
            uploadButtonBody
        } = this.props;

        return (
            <>
                { (!readOnly && !hideMaxFileSizeInfo) &&
                    <Grid item xs={12}>
                        <MaxFileSizeTextAndToolTip title={title} tooltipPlacement={tooltipPlacement}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <FileUploadView
                        acceptedFileFormats={acceptedFileFormats}
                        addFiles={addFiles}
                        addMediaLibraryFiles={addMediaLibraryFiles}
                        centerAddAttachmentButton={centerAddAttachmentButton}
                        disabled={disabled}
                        error={error}
                        files={files}
                        showFileName={showFileName}
                        limitToOneFile={limitToOneFile}
                        loadingPreviewImage={loadingPreviewImage}
                        locationId={locationId}
                        maxFileSizeCaptionsUnderAddAttachmentButton={maxFileSizeCaptionsUnderAddAttachmentButton}
                        multiple={multiple}
                        readOnly={readOnly}
                        addOnly={addOnly}
                        noPreviewMargin={noPreviewMargin}
                        removeFile={removeFile}
                        uploadButtonBody={uploadButtonBody}
                        showUploadButtonAbovePreview={showUploadButtonAbovePreview}
                        lightBoxOpen={lightBoxOpen}
                        lightBoxInitialIndex={lightBoxInitialIndex}
                        onLightBoxClose={onLightBoxClose}
                        onFilePreviewClick={onFilePreviewClick}
                        allowMediaLibrary={allowMediaLibrary}
                    />
                </Grid>
                {(submitFileAttachmentInsideComponent && submitId) &&
                    <Grid>
                        <Button primary isSubmitting={processing} onClick={() => submitFileAttachmentInsideComponent(submitId, files)}>Save</Button>
                    </Grid>
                }
            </>
        )
    }
}

FileUpload.propTypes = {
    acceptedFileFormats: PropTypes.string,                              // takes a string containing one or more file type specifiers as its value, separated by commas. (ex. '.jpg, .jpeg, .png' or 'image/*,.pdf')
    allowMediaLibrary: PropTypes.bool,
    centerAddAttachmentButton: PropTypes.bool,
    disabled:     PropTypes.bool,
    error: PropTypes.bool,
    hideMaxFileSizeInfo: PropTypes.bool,
    initialFiles: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string
    })),
    limitToOneFile: PropTypes.bool,                                     //this will allow only one file to be uploaded at any time
    locationId: PropTypes.number,
    maxFileSizeCaptionsUnderAddAttachmentButton: PropTypes.bool,
    multiple:     PropTypes.bool,
    noPreviewMargin:     PropTypes.bool,
    onChange:     PropTypes.func,
    processing:   PropTypes.bool,
    readOnly:     PropTypes.bool,
    showFileName:     PropTypes.bool,
    submitId: PropTypes.number,                                         // the id value that will be passed to the submitWithSaveButton function
    submitFileAttachmentInsideComponent: PropTypes.func,
    uploadButtonBody: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    showUploadButtonAbovePreview: PropTypes.bool,
    title: PropTypes.string,                                            //title text presented before the "Max File Size" info text
    tooltipPlacement: PropTypes.oneOf(Object.values(TooltipPlacement)), //tooltip placement for the "Max File Size" info text
};

FileUpload.defaultProps = {
    addOnly: false,
    readOnly:  false,
    limitToOneFile: false,
    initialFiles: [],
    multiple:     true,
    showUploadButtonAbovePreview: false,
    error: false,
    tooltipPlacement: TooltipPlacement.TOP_START,
};

export default FileUpload;
