import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from 'styled-components'

import Button, {ButtonSize} from "worksmith/components/Button/Button.web";
import ChangeRequestType from "../../shared/enums/api/cr/ChangeRequestType";
import CreateScheduleOptionsDialog
    from "worksmith/composite-components/ScheduleOptionsForm/CreateScheduleOptionsDialog";
import {globalSnackbarTrigger, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import MenuItem from "worksmith/components/Menu/MenuItem.web";
import MenuButton from "worksmith/components/MenuButton/MenuButton.web";
import ObligationTemplateService from "worksmith/services/api/ObligationTemplateService";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import UserType from "../../shared/enums/api/user/UserType";
import {DisplayErrorNotification, DisplaySuccessNotification} from "worksmith/helpers/SweetAlertHelpers";

const obligationTemplateService = new ObligationTemplateService();

const PaddedBlock = styled.div`
    margin-bottom: 1em;
`;

const RecurringServiceActionButton = (props) => {

    const createChangeRequest = (schedules) => {
        let {obligationTemplate, reloadChangeRequest} = props;

        let changeRequest = {
            scheduleHandler: {
                scheduleOptions: schedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        changeRequest.obligationTemplate = {
            id: obligationTemplate.id
        };
        obligationTemplateService
            .addChangeRequest(obligationTemplate.id, changeRequest, false, false, false)
            .then(
                () => {
                    globalSnackbarTrigger("New recurring schedule requested.");
                    reloadChangeRequest();
                    setShowScheduleOptionsDialog(false);
                },
                () => DisplayErrorNotification("Failed to request a new visit time")
            );
    };

    let {obligationTemplate, openChangeRequest, cancelChangeRequest, reloadChangeRequest} = props;
    let showButton = !ValueIsSet(openChangeRequest) || openChangeRequest.initiatedByClient;
    let [showActionMenu, setShowActionMenu] = useState(false);
    let [showScheduleOptionsDialog, setShowScheduleOptionsDialog] = useState(false);

    let menuItems = [];
    if(ValueIsSet(openChangeRequest))
        menuItems.push(<MenuItem onClick={() => {
            cancelChangeRequest(openChangeRequest.id);
            reloadChangeRequest();
        }}>
            Cancel Request to Reschedule
        </MenuItem>);
    else
        menuItems.push(<MenuItem onClick={() => {
            setShowActionMenu(false);
            setShowScheduleOptionsDialog(true);
        }}>Request New Recurring Schedule</MenuItem>);

    return showButton ? <Fragment>
        <PaddedBlock>
            <MenuButton
                Button={
                    <Button primary
                            size={ButtonSize.MEDIUM}
                            endIcon={<ArrowDropDownIcon style={{fontSize: 30}}/>}
                            onClick={() => setShowActionMenu(true)}>
                        ACTIONS
                    </Button>
                }
                onClose={() => setShowActionMenu(false)}
                open={showActionMenu}>
                {menuItems}
            </MenuButton>
        </PaddedBlock>
        <CreateScheduleOptionsDialog
            adjustableFrequency
            title={"Propose New Recurring Schedule"}
            open={showScheduleOptionsDialog}
            onSubmit={(schedules) => {
                createChangeRequest(schedules);
            }}
            clientLocation={obligationTemplate.clientLocation}
            obligationTemplate={obligationTemplate}
            templateChangeRequest={openChangeRequest}
            onCancel={() => setShowScheduleOptionsDialog(false)}
            numberOfSchedules={1}
            readableScheduleString={obligationTemplate.recurringScheduleTemplate.readableScheduleString}
            serviceLine={obligationTemplate.serviceLine}
            showCurrentSchedule
            scheduleOptionType={ScheduleOptionType.RECURRING_SERVICE}
            frequency={obligationTemplate.recurringScheduleTemplate.frequency}
            userType={UserType.CLIENT}
            vendor={obligationTemplate.vendor.officialName}
            duration={obligationTemplate.recurringScheduleTemplate.duration}/>
    </Fragment> : null;
};

RecurringServiceActionButton.propTypes = {
    openChangeRequest: PropTypes.object,
    obligationTemplate: PropTypes.object.isRequired,
    reloadChangeRequest: PropTypes.func,
    cancelChangeRequest: PropTypes.func,
};



export default RecurringServiceActionButton;