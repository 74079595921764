import {Color} from "../../enums/Color";
import {Position} from "../../enums/CSSEnums";
import styled from 'styled-components';

const Section = styled.div`
    position: ${Position.RELATIVE};
    background-color: ${Color.WHITE};
    padding: 15px;
    border: 1px solid ${Color.LIGHT_GREY};
    margin: 1rem 0;
`;

export default Section;
