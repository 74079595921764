import React, {Fragment, useState} from 'react';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import Grid from "../../components/Grid/Grid.web";
import FieldTitleAndBody from "../FieldTitleAndBody/FieldTitleAndBody";
import PriceBreakdown from "../PriceBreakdown/PriceBreakdown";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import Schedule from "../../components/Schedule/Schedule.web";
import Button from "../../components/Button/Button.web";
import MenuButton from "../../components/MenuButton/MenuButton.web";
import WalkthroughDetailsActionList from "./WalkthroughDetailsActionList";
import {Display, JustifyContent, Position} from "../../enums/CSSEnums";
import Text, {TextColor, TextVariant} from "../../components/Text/Text.web";
import AcceptAndDeferModal from "../BidAndWalkthroughModals/AcceptAndDeferModal";
import SelectScheduleModal from "../BidAndWalkthroughModals/SelectScheduleModal";
import CreateScheduleOptionsDialog from "../ScheduleOptionsForm/CreateScheduleOptionsDialog";
import RequestApprovalModal from "../BidAndWalkthroughModals/RequestApprovalModal";
import UserType from "../../enums/api/user/UserType";
import {formatTimestamp, ValueIsSet} from "../../helpers/GenericHelpers";
import ObligationStatus from "../../enums/api/task/ObligationStatus";
import WalkthroughStatus from "../../enums/api/proposal/WalkthroughStatus";
import WalkthroughScheduledAndCompletedFields from "./WalkthroughScheduledAndCompletedFields";
import SchedulingView from "../RequestState/RequestStateStatus/Scheduling/SchedulingView";
import ReschedulingView from "../RequestState/RequestStateStatus/Rescheduling/ReschedulingView";
import TextFieldDialog from "../../components/Dialog/TextFieldDialog";
import CircularProgress from "../../components/Loader/CircularProgress";
import {withTheme} from "../../components/Theme/ThemeProvider";
import Icon from "../../components/Icon/Icon";
import {IconType} from "../../enums/MaterialEnums";
import {AlignItems} from "../../../CSSEnums";
import {TestIds} from "worksmith/enums/CypressTestClient"
import {WithContext} from "../../../context/GlobalContext";
import BidStatus from "worksmith/enums/api/proposal/BidStatus";

const WalkthroughDetailsView = (props) => {
    const {
        context,
        acceptWalkthrough,
        acceptAndSchedule,
        acceptChangeRequest,
        activeObligation,
        additionalTerms,
        canAcceptWalkthrough,
        cancelChangeRequest,
        cancelWalkthroughObligation,
        cancelWalkthroughBid,
        createChangeRequest,
        createdTimestamp,
        decline,
        duration,
        hideActionButton,
        isCompleted,
        isAdmin,
        loading,
        makeScheduleCounterOffer,
        requestApproval,
        scheduleWalkthrough,
        scheduleHandler,
        status,
        totalRetailPrice,
        vendorLocation,
        walkthroughItems,
        walkthroughSubmittedActivity
    } = props;
    const theme = useTheme();
    const onMobile =  useMediaQuery(theme.breakpoints.down('md'));
    const [showActionMenu, setShowActionMenu] = useState(false);
    const [showAcceptAndScheduleModal, setShowAcceptAndScheduleModal] = useState(false);
    const [showAcceptAndSubmitSchedulesModal, setShowAcceptAndSubmitSchedulesModal] = useState(false);
    const [showAcceptAndDeferModal, setShowAcceptAndDeferModal] = useState(false);
    const [showRequestApprovalModal, setShowRequestApprovalModal] = useState(false);
    const [showRequestToRescheduleModal, setShowRequestToRescheduleModal] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;

    if (loading) {
        return (
            <Box
                display={Display.FLEX}
                justifyContent={JustifyContent.CENTER}
                height={400}
                width={'100%'}>
                <CircularProgress/>
            </Box>
        );
    }

    let hasScheduleOptions = scheduleHandler && scheduleHandler.openScheduleOptions.filter(schedule => !schedule.isExpired).length > 0;
    let statusDependentView = null;
    let isScheduling = false;
    let isRescheduling = false;
    const isPricedWalkthrough = walkthroughItems.length > 0;

    if (status === WalkthroughStatus.ACCEPTED && !activeObligation) {
        isScheduling = true;
        statusDependentView = (
            <Grid item xs={10}>
                <SchedulingView
                    acceptSchedule={scheduleWalkthrough}
                    duration={duration}
                    proposeSchedules={makeScheduleCounterOffer}
                    scheduleHandler={scheduleHandler}
                    vendorLocation={vendorLocation}/>
            </Grid>
        )
    } else if (activeObligation && activeObligation.pendingChangeRequest) {
        isRescheduling = true;
        statusDependentView = (
            <Grid item xs={10}>
                <ReschedulingView
                    acceptChangeRequest={acceptChangeRequest}
                    cancelChangeRequest={cancelChangeRequest}
                    creatorType={activeObligation.pendingChangeRequest.creatorType}
                    proposeSchedules={makeScheduleCounterOffer}
                    schedule={activeObligation.schedule}
                    scheduleHandler={activeObligation.pendingChangeRequest.scheduleHandler}
                    vendorLocation={vendorLocation}/>
            </Grid>
        )

    } else if (activeObligation && !activeObligation.pendingChangeRequest) {
        statusDependentView = (
            <WalkthroughScheduledAndCompletedFields
                activeObligation={activeObligation}/>
        )
    } else if (scheduleHandler) {
       statusDependentView = (
           <FieldTitleAndBody
               key={'availability'}
               xs={12}
               item
               title={'Availability'}
               body={
                   <Grid container>
                       {
                           scheduleHandler.openScheduleOptions.map((schedule) => (
                               <Grid item xs={10} key={schedule.id}>
                                   <Schedule
                                       schedule={schedule.schedule}
                                       textVariant={TextVariant.BODY_1}/>
                                   {
                                       schedule.isExpired ?
                                           <Text color={TextColor.ERROR}>*Availability expired</Text>
                                           :
                                           null
                                   }
                               </Grid>
                           ))
                       }
                   </Grid>
               }/>
       );
    }

    // hide the actions button if in corporate review because there are no options
    let isInCorporateReview = status === BidStatus.PENDING_ADMIN || status === BidStatus.PENDING_REGIONAL_MANAGER
    let showActionsAndModals = !hideActionButton && !isInCorporateReview
                               && (!activeObligation || activeObligation.status !== ObligationStatus.COMPLETED);



    return (
        <Fragment>
            {
                showActionsAndModals ?
                    <Fragment>
                    <AcceptAndDeferModal
                        accept={acceptWalkthrough}
                        closeModal={() => setShowAcceptAndDeferModal(false)}
                        open={showAcceptAndDeferModal}/>
                    <SelectScheduleModal
                        closeModal={() => setShowAcceptAndScheduleModal(false)}
                        scheduleHandler={scheduleHandler}
                        open={showAcceptAndScheduleModal}
                        selectSchedules={acceptAndSchedule}/>
                    <CreateScheduleOptionsDialog
                        title={
                            showAcceptAndSubmitSchedulesModal ?
                                'Submit Availability'
                                :
                                'Propose New Visit Time'
                        }
                        numberOfSchedules={showAcceptAndSubmitSchedulesModal ? 2 : 1}
                        open={showAcceptAndSubmitSchedulesModal || showRequestToRescheduleModal}
                        onSubmit={(schedules) => {
                            if(showAcceptAndSubmitSchedulesModal) {
                                setShowAcceptAndSubmitSchedulesModal(false);
                                acceptWalkthrough(schedules);
                            } else {
                                setShowRequestToRescheduleModal(false);
                                createChangeRequest(schedules);
                            }
                        }}
                        onCancel={
                            showAcceptAndSubmitSchedulesModal ?
                                () => setShowAcceptAndSubmitSchedulesModal(false)
                                :
                                () => setShowRequestToRescheduleModal(false)
                        }/>
                    <RequestApprovalModal
                        requestApproval={requestApproval}
                        closeModal={() => setShowRequestApprovalModal(false)}
                        open={showRequestApprovalModal}
                        vendorLocation={vendorLocation}/>
                    <TextFieldDialog
                        description={
                            showDeclineModal ?
                            'Let the vendor know why you are declining the walkthrough. A detailed reason will help the vendor take the appropriate next step.'
                                :
                            'Let the vendor know why you are cancelling the walkthrough.'
                        }
                        onAccept={(textValue) => {
                            if (showDeclineModal){
                                setShowDeclineModal(false);
                                decline(textValue);
                            }
                            else if (showCancelModal) {
                                setShowCancelModal(false);
                                if(isScheduling)
                                    cancelWalkthroughBid(textValue);
                                else
                                    cancelWalkthroughObligation(textValue);
                            }
                        }}
                        onCancel={
                            showDeclineModal ?
                                () => setShowDeclineModal(false)
                                    :
                                () => setShowCancelModal(false)
                        }
                        open={showCancelModal || showDeclineModal}
                        title={showDeclineModal ? 'Decline Walkthrough?' : 'Cancel Walkthrough?'}
                        textFieldTestId={TestIds.DECLINE_WT_TEXT_AREA}/>
                    </Fragment>
                :
                null
            }

            <Box
                position={Position.RELATIVE}
                width={'100%'}>
                {
                    showActionsAndModals ?
                        <Box
                            position={onMobile ? Position.Relative : Position.ABSOLUTE}
                            right={0}
                            top={isPricedWalkthrough ? 102 : 4}
                            mb={onMobile ? 2 : 0}>
                            <MenuButton
                                Button={
                                    <Button
                                            testId={'walkthroughActionButton'}
                                            primary
                                            disabled={loading}
                                            endIcon={<ArrowDropDownIcon/>}
                                            onClick={() => setShowActionMenu(true)}>
                                        ACTIONS
                                    </Button>
                                }
                                onClose={() => setShowActionMenu(false)}
                                open={showActionMenu}>
                                <WalkthroughDetailsActionList
                                    canAcceptWalkthrough={canAcceptWalkthrough}
                                    isAdmin={isAdmin}
                                    isRescheduling={isRescheduling}
                                    isScheduled={
                                        activeObligation
                                        && !activeObligation.pendingChangeRequest
                                        && activeObligation.status === ObligationStatus.PENDING
                                    }
                                    isInCorporateReview={isInCorporateReview}
                                    isScheduling={isScheduling}
                                    hasScheduleOptions={hasScheduleOptions}
                                    setShowActionMenu={setShowActionMenu}
                                    setShowAcceptAndDeferModal={setShowAcceptAndDeferModal}
                                    setShowAcceptAndScheduleModal={setShowAcceptAndScheduleModal}
                                    setShowAcceptAndSubmitSchedulesModal={setShowAcceptAndSubmitSchedulesModal}
                                    setShowDeclineModal={setShowDeclineModal}
                                    setShowCancelModal={setShowCancelModal}
                                    setShowRequestToRescheduleModal={setShowRequestToRescheduleModal}
                                    setShowRequestApprovalModal={setShowRequestApprovalModal}
                                    />
                            </MenuButton>
                        </Box>
                        :
                        null
                }
                <Grid
                    container
                    spacing={4}>
                    {isPricedWalkthrough ?
                        <Grid container item>
                            <Grid item xs={1} alignItems={AlignItems.CENTER}>
                                <Icon name={IconType.LOCAL_ATM}/>
                            </Grid>
                            <Grid item xs={11} alignItems={AlignItems.FLEX_START}>
                                <Text variant={TextVariant.BODY_1}>It is standard in this industry to charge a
                                    diagnostic fee to assess these types of issues. The vendor may be able to fix the
                                    problem while diagnosing, or submit an Estimate for additional work
                                    afterwards.</Text>
                            </Grid>
                        </Grid>
                        :
                        null
                    }
                {
                   statusDependentView
                }
                <FieldTitleAndBody
                    key={'terms'}
                    item
                    xs={10}
                    title={'Terms & Conditions'}
                    body={
                        additionalTerms ?
                            additionalTerms
                            :
                            'None'
                    }/>
                    {
                        (walkthroughItems && walkthroughItems.length > 0 && !hidePricingForNonAdminUsers) ?
                            <FieldTitleAndBody
                                key={'priceBreakdown'}
                                item
                                xs={12}
                                title={'Price Breakdown'}
                                body={
                                    <PriceBreakdown
                                        items={ValueIsSet(activeObligation) ? activeObligation.obligationItems : walkthroughItems}
                                        activityTotal={ValueIsSet(activeObligation) ? activeObligation.totalRetailPrice : totalRetailPrice}/>
                                }/>
                                :
                            null
                    }
                    {!isCompleted && ValueIsSet(walkthroughSubmittedActivity) ?
                        <Grid container justify={JustifyContent.FLEX_END} alignItems={AlignItems.FLEX_END} xs={12}><Text> {'Submitted by ' + walkthroughSubmittedActivity.user.displayName + ' on ' + formatTimestamp(walkthroughSubmittedActivity.createdTimestamp)}</Text></Grid>
                        : !isCompleted && ValueIsSet(createdTimestamp) ?
                            <Grid container justify={JustifyContent.FLEX_END} alignItems={AlignItems.FLEX_END} xs={12}><Text> {'Submitted on ' + formatTimestamp(createdTimestamp)}</Text></Grid>
                            : null}
                </Grid>
            </Box>
        </Fragment>
    )
};

WalkthroughDetailsView.propTypes = {
    acceptWalkthrough: PropTypes.func,
    acceptAndSchedule: PropTypes.func,
    acceptChangeRequest: PropTypes.func,
    activeObligation: PropTypes.shape({
        id: PropTypes.number,
        status: CustomPropTypes.enum(ObligationStatus),
        schedule: PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            duration: PropTypes.number,
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }),
        pendingChangeRequest: PropTypes.shape({
            id: PropTypes.number,
            creatorType: CustomPropTypes.enum(UserType),
            scheduleHandler: PropTypes.shape({
                id: PropTypes.number,
                openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number,
                    isExpired: PropTypes.bool,
                    schedule: PropTypes.shape({
                        arrivalEndTime: PropTypes.string,
                        arrivalStartTime: PropTypes.string,
                        date: PropTypes.string,
                        dayOfMonth: PropTypes.bool,
                        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                        duration: PropTypes.number,
                        recurrenceType: CustomPropTypes.enum(RecurrenceType),
                        repeatEvery: PropTypes.number,
                        returnDate: PropTypes.string
                    }),
                    status: PropTypes.string
                }))
            }),
        }),
        obligationAttachments: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            singleUseUrl: PropTypes.string
        })),
        summaryOfWorkCompleted: PropTypes.string,
    }),
    additionalTerms: PropTypes.string,
    canAcceptWalkthrough: PropTypes.bool,
    cancelChangeRequest: PropTypes.func,
    cancelWalkthroughObligation: PropTypes.func,
    cancelWalkthroughBid: PropTypes.func,
    createChangeRequest: PropTypes.func,
    decline: PropTypes.func,
    duration: PropTypes.number,
    hideActionButton: PropTypes.bool,
    isAdmin: PropTypes.bool,
    loading: PropTypes.bool,
    makeScheduleCounterOffer: PropTypes.func,
    requestApproval: PropTypes.func,
    scheduleHandler: PropTypes.shape({
        id: PropTypes.number,
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        }))
    }),
    scheduleWalkthrough: PropTypes.func,
    status: CustomPropTypes.enum(WalkthroughStatus),
    totalRetailPrice: PropTypes.number,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
    walkthroughItems: PropTypes.arrayOf(PropTypes.shape({
        lineItemType: PropTypes.shape({
            name: PropTypes.string
        }),
        quantity: PropTypes.number,
        name: PropTypes.string,
        unitRetailPrice: PropTypes.number
    }))
};

export default WithContext(withTheme(WalkthroughDetailsView));