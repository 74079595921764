import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ComplianceIcon from "worksmith/components/Assets/ComplianceIcons/ComplianceIcon";
import EditComplianceModal from "worksmith/components/CompliancePage/EditComplianceModal";
import Button from "worksmith/components/Button/Button.web";
import {ButtonVariant} from "worksmith/components/Button/Button";
import Grid from "worksmith/components/Grid/Grid";

const EditComplianceStatusButton = (props) => {
    const {compliant, resolved, onCloseEditComplianceModal, serviceLevelAgreementItemId} = props;
    const [open, setOpen] = React.useState(false);

    return (
        <div style={{cursor:'pointer'}}>
            <Grid container>
                <Grid item width={'26px'}>
                    <ComplianceIcon compliant={compliant} resolved={resolved} margin={"0 0px -8px 0"}/>
                </Grid>
                <Grid item>
                    <Button onClick={() => setOpen(true)} primary variant={ButtonVariant.TEXT}>EDIT</Button>
                </Grid>
            </Grid>

            {
                open ?
                    <EditComplianceModal open={open}
                                         serviceLevelAgreementItemId={serviceLevelAgreementItemId}
                                         onClose={(statusWasChanged) => {
                                             setOpen(false);
                                             onCloseEditComplianceModal(statusWasChanged);
                                         }}/>
                 :
                    null
            }
        </div>
    )
};

EditComplianceStatusButton.propTypes = {
    compliant: PropTypes.bool.isRequired,
    resolved: PropTypes.bool.isRequired,
    onCloseEditComplianceModal: PropTypes.func,
    serviceLevelAgreementItemId: PropTypes.number.isRequired
};

export default EditComplianceStatusButton