import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AsyncGraphQLServiceClass from "../../../../services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import RequestForProposalService from "../../../../services/api/RequestForProposalService";
import ContactingVendorsView from "./ContactingVendorsView";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";

const graphQLServiceClass = new AsyncGraphQLServiceClass();
const requestService = new RequestForProposalService();

class ContactingVendors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null
        };
    }

    componentDidMount = async () => {
        const {requestId} = this.props;

        if (requestId) {
            let data = await this.getRequestFromId(requestId);
            this.setState({loading: false, request: data});
        }
    };

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            newState.request = DeepCopy(props.request);
        }

        return newState;
    };

    releaseToNetwork = () => {
        const {request} = this.state;
        const {onReleaseToNetwork, requestId, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        requestService.openRequestToDefaultMarket(request.id)
            .then(() => {
                onReleaseToNetwork();
            });
    };

    getRequestFromId = async (requestId) => {

        return await graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            ContactingVendorsGraphQLString);
    };

    render() {
        const {releaseToNetwork} = this;
        const {request, loading} = this.state;
        const {chatRef, isAdmin} = this.props;

        return (
            <ContactingVendorsView
                chatRef={chatRef}
                isAdmin={isAdmin}
                loading={loading || this.props.loading}
                releaseToNetwork={releaseToNetwork}
                request={request}/>
        );
    }
}

ContactingVendors.propTypes = {
    chatRef: PropTypes.object,
    isAdmin: PropTypes.bool,
    onReleaseToNetwork: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    request: PropTypes.shape({
        id: PropTypes.number,
        market: PropTypes.string,
        hasPreferredVendor: PropTypes.bool,
        numberOfActiveBids: PropTypes.number,
        numberOfActiveEstimates: PropTypes.number,
        numberOfActiveWalkthroughs: PropTypes.number,
        targetVendorLocation: PropTypes.shape({
            vendor: PropTypes.shape({
                officialName: PropTypes.string
            })
        }),
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func
};

const ContactingVendorsGraphQLString = `
    id
    market
    hasPreferredVendor
    numberOfActiveBids
    numberOfActiveEstimates
    numberOfActiveWalkthroughs
    opportunityConversations {
        id
        hasUnreadScopeClarificationMessage
    }
    targetVendorLocation {
        id
        vendor {
            officialName
        }
    }
    primaryVendorRateCard{
        isRequestedVendor
    }
`;

export default ContactingVendors;