import React, {Component} from 'react';
import moment from 'moment';

import t from 'tcomb-form';
const Form = t.form.Form;

const formLayout = (locals) => {
    return (
        <div className={'d-flex flex-row'}>
            <div className={'flex-1 margin-right-md'}>{locals.inputs.openTime}</div>
            <div className={'flex-1'}>{locals.inputs.closeTime}</div>
            <div className={'flex-1 margin-left-lg'}>{locals.inputs.closed}</div>
        </div>
    );
};

let OpenCloseOptions = {
    template: formLayout,
};

class StoreHours extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: OpenCloseOptions,
            hoursEnum: {},
            locationHours: props.locationHours
        };
        this.onHourSetChange = this.onHourSetChange.bind(this);
    }

    componentDidMount() {
        let struct = [];

        // create AM hours in 15 minute increments
        struct.push('12:00 AM');
        struct.push('12:15 AM');
        struct.push('12:30 AM');
        struct.push('12:45 AM');
        for (let hour = 1; hour <= 11; hour++) {
            struct.push(`${hour}:00 AM`);
            for (let minute = 1; minute < 60; minute++) {
                if (minute % 15 === 0 && minute !== 0) {
                    let amTimeString = `${hour}:${minute} AM`;
                    struct.push(amTimeString);
                }
            }
        }

        // create PM hours in 15 minute increments
        struct.push('12:00 PM');
        struct.push('12:15 PM');
        struct.push('12:30 PM');
        struct.push('12:45 PM');
        for (let hour = 1; hour <= 11; hour++) {
            struct.push(`${hour}:00 PM`);
            for (let minute = 0; minute < 60; minute++) {
                if (minute % 15 === 0 && minute !== 0) {
                    let pmTimeString = `${hour}:${minute} PM`;
                    struct.push(pmTimeString);
                }
            }
        }

        // create struct to accommodate tcomb-form structure
        let hoursEnum = {};
        struct.forEach(function (hour) {
            hoursEnum[moment(hour, 'HH:mm:ss A').format('HH:mm:ss')] = hour;
        });

        this.setState({hoursEnum})
    }

    onHourSetChange(value) {
        //Get the actual date property
        let key = Object.keys(value)[0];

        let day = value[key];

        if(!day.closed && day.openTime == null) {
            day.openTime = '09:00:00';
        }

        if(!day.closed && day.closeTime == null) {
            day.closeTime = '17:00:00';
        }

        value[key] = day;

        let locationHours = this.state.locationHours;
        locationHours = Object.assign({}, locationHours, value);
        this.setState({locationHours : locationHours});
        this.props.propagateHourChange(locationHours);
    }

    generateOpenCloseStruct(value) {
        let openCloseStructObject = {
            closed: t.Boolean
        };

        if(value.openTime === '' && value.closeTime === ''){
            value.openTime = '09:00:00';
            value.closeTime = '17:00:00';
            value.closed = true;
        }

        if(!value.closed) {
            openCloseStructObject.openTime = t.enums(this.state.hoursEnum);
            openCloseStructObject.closeTime = t.enums(this.state.hoursEnum);
        }

        return t.struct(openCloseStructObject);
    }

    render() {
        return (
            <div>
                {/* Sunday */}
                <div className="row">
                    <div className="col-md-2">
                        <p>Sunday</p>
                    </div>
                    <div className="col-md-4">
                        <Form type={this.generateOpenCloseStruct(this.state.locationHours.sunday)} onChange={(value) => this.onHourSetChange({sunday: value})}
                              options={this.state.options} value={this.state.locationHours.sunday}/>
                    </div>
                </div>
                {/* Monday */}
                <div className="row">
                    <div className="col-md-2">
                        <p>Monday</p>
                    </div>
                    <div className="col-md-4">
                        <Form type={this.generateOpenCloseStruct(this.state.locationHours.monday)} onChange={(value) => this.onHourSetChange({monday: value})}
                              options={this.state.options} value={this.state.locationHours.monday}/>
                    </div>
                </div>

                {/* Tuesday */}
                <div className="row">
                    <div className="col-md-2">
                        <p>Tuesday</p>
                    </div>
                    <div className="col-md-4">
                        <Form type={this.generateOpenCloseStruct(this.state.locationHours.tuesday)} onChange={(value) => this.onHourSetChange({tuesday: value})}
                              options={this.state.options} value={this.state.locationHours.tuesday}/>
                    </div>
                </div>

                {/* Wednesday */}
                <div className="row">
                    <div className="col-md-2">
                        <p>Wednesday</p>
                    </div>
                    <div className="col-md-4">
                        <Form type={this.generateOpenCloseStruct(this.state.locationHours.wednesday)} onChange={(value) => this.onHourSetChange({wednesday: value})}
                              options={this.state.options} value={this.state.locationHours.wednesday}/>
                    </div>
                </div>

                {/* Thursday */}
                <div className="row">
                    <div className="col-md-2">
                        <p>Thursday</p>
                    </div>
                    <div className="col-md-4">
                        <Form type={this.generateOpenCloseStruct(this.state.locationHours.thursday)} onChange={(value) => this.onHourSetChange({thursday: value})}
                              options={this.state.options} value={this.state.locationHours.thursday}/>
                    </div>
                </div>

                {/* Friday */}
                <div className="row">
                    <div className="col-md-2">
                        <p>Friday</p>
                    </div>
                    <div className="col-md-4">
                        <Form type={this.generateOpenCloseStruct(this.state.locationHours.friday)} onChange={(value) => this.onHourSetChange({friday: value})}
                              options={this.state.options} value={this.state.locationHours.friday}/>
                    </div>
                </div>

                {/* Saturday */}
                <div className="row">
                    <div className="col-md-2">
                        <p>Saturday</p>
                    </div>
                    <div className="col-md-4">
                        <Form type={this.generateOpenCloseStruct(this.state.locationHours.saturday)} onChange={(value) => this.onHourSetChange({saturday: value})}
                              options={this.state.options} value={this.state.locationHours.saturday}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default StoreHours;