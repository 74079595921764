import React, {Component} from 'react';
import MaterialTextField from '@mui/material/TextField/index';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomPropTypes from 'worksmith/custom-prop-types/CustomPropTypes';
import {ValueIsSet} from 'worksmith/helpers/GenericHelpers';
import {withTheme} from '../../Theme/ThemeProvider';
import CharacterCount from 'worksmith/components/Inputs/TextField/CharacterCount';
import {IconFontSize} from "worksmith/components/Icon/Icon.web";
import IconButton from "worksmith/components/Button/IconButton.web";
import {Color} from 'worksmith/enums/Color';

export const TextFieldVariant = Object.freeze({
    SIMPLE_LINE: 'standard',
    OUTLINED: 'outlined',
    FILLED: 'filled'
});

export const TextFieldType = Object.freeze({
    EMAIL: 'email',
    NUMBER: 'number',
    PASSWORD: 'password',
    SEARCH: 'search',
    TEXT: 'text',
    DATE: 'date',
    PHONE: 'tel',
    TIME: 'time'
});

export const TextFieldSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
});

// this container allows the required asterisk to be positioned absolutely
// the inline check ensures that textarea elements will take the full container width while standard input elements will still allow other elements to sit on the same line
const StyledTextFieldContainer = styled.div`
  position: relative;
  display: ${({inline}) => inline ? 'inline-block' : 'block'};
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : null};
`

const StyledTextField = styled(MaterialTextField)`
  && {
    max-width: ${({fullWidth}) => fullWidth ? null : '700px'}; // request from product for all text inputs moving forward
  }
`

const StyledAsterisk = styled.span`
    color: ${Color.RED};
    position: absolute;
    font-family: 'Open Sans';
    top: -7px;
    left: -12.5px;
`

class TextField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue || '', //always initialize value in state because if it isn't set the text field will be treated as uncontrolled and throw an error in the console
            length: props.initialValue ?
                props.initialValue.toString().length
                :
                0,
        };

        this.textFieldRef = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.initialValue && prevProps.initialValue !== this.props.initialValue) {
            this.setState({
                value: this.props.initialValue
            })
        }
    };

    onChange = (event) => {
        let { type,maxNumericalValue,minNumericalValue } = this.props;

        if(type === TextFieldType.NUMBER){
            if((maxNumericalValue != null && event.target.value > maxNumericalValue) ||
               (minNumericalValue != null && event.target.value < minNumericalValue))
                return;
        }

        this.setState(
            {value: event.target.value, length: event.target.value.toString().length}, () => {
                if (ValueIsSet(this.props.onChange)) {
                    this.props.onChange(this.state.value);
                }
            }
        );

    };

    update = (newVal) => {
        this.setState({value: newVal, length: newVal.toString().length});
    };

    clear = (resetFocus, skipOnChange) => {

        if (skipOnChange) {
            this.setState({value: '', length: 0});
        } else {
            this.setState({value: '', length: 0}, () => {
                    if (ValueIsSet(this.props.onChange))
                        this.props.onChange(this.state.value);

                    if (ValueIsSet(this.props.onClear))
                        this.props.onClear();

                    if (resetFocus) {
                        if (this.props.inputRef) {
                            this.props.inputRef.current.focus()
                        } else {
                            this.textFieldRef.current.focus()
                        }
                    }
                }
            );
        }
    };

    render() {
        const {
            autoFocus,
            backgroundColor,
            disabled,
            displayCharacterCount,
            endAdornment,
            endAdornmentProps,
            error,
            fullWidth,
            helperText,
            includeClearButton,
            inline,
            inputClassName,
            InputProps,
            inputRef,
            label,
            maxLength,
            minLength,
            multiline,
            name,
            nameless,
            onBlur,
            onClick,
            onClear,
            onFocus,
            onKeyUp,
            placeholder,
            required,
            rows,
            rowsMax,
            showRequiredAsterisk,
            size,
            startAdornment,
            startAdornmentProps,
            testId,
            type,
            variant
        } = this.props;

        const {
            clear,
            onChange,
            textFieldRef
        } = this;

        const {
            length,
            value
        } = this.state;

        let customEndAdornment = null;
        if (ValueIsSet(endAdornment)) {
            customEndAdornment = endAdornment;
        } else if (includeClearButton && value !== '') {
            customEndAdornment = (
               <IconButton
                   iconName={'Cancel'}
                   primary
                   onClick={() => clear(true)}
                   size={IconFontSize.SMALL}/>
           )
        }

        let textLengthError = false;
        if(((ValueIsSet(maxLength) && length > maxLength) || (ValueIsSet(minLength) && length < minLength)) && length !== 0)
            textLengthError = true;


        return (
            <StyledTextFieldContainer backgroundColor={backgroundColor} inline={inline}>
                {ValueIsSet(showRequiredAsterisk) ? <StyledAsterisk>*</StyledAsterisk> : null}
                <StyledTextField
                    inputRef={inputRef ? inputRef : textFieldRef}
                    disabled={disabled}
                    error={textLengthError|| error}
                    fullWidth={fullWidth}
                    className={this.props.className}
                    helperText={
                        displayCharacterCount ?
                            <CharacterCount helperText={helperText} length={length} maxLength={maxLength} minLength={minLength}/>
                            :
                            helperText
                    }
                    label={label}
                    multiline={multiline}
                    name={nameless ? undefined : ValueIsSet(name) ? name : label}
                    onBlur={onBlur}
                    onChange={onChange}
                    onClick={onClick}
                    onFocus={onFocus}
                    onKeyUp={onKeyUp}
                    required={required}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    rows={rows}
                    maxRows={rowsMax}
                    inputProps={{
                        maxLength: maxLength,
                        className: inputClassName,
                        "data-testid": testId
                    }}
                    InputProps={{
                        startAdornment: ValueIsSet(startAdornment) ? (
                            <InputAdornment position={'start'} {...startAdornmentProps}>
                                {startAdornment}
                            </InputAdornment>
                        ) : null,
                        endAdornment: ValueIsSet(customEndAdornment) ? (
                            <InputAdornment position={'end'} {...endAdornmentProps}>
                                {customEndAdornment}
                            </InputAdornment>
                        ) : null,
                        onFocus: onFocus,
                        onBlur: onBlur,
                        ...InputProps
                    }}
                    type={type}
                    value={value}
                    variant={variant}
                    size={size}
                />
            </StyledTextFieldContainer>
        )

    }

}

TextField.propTypes = {
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    displayCharacterCount: PropTypes.bool,
    endAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    endAdornmentProps: PropTypes.object,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    includeClearButton: PropTypes.bool,                     //Cannot be used with endAdornment
    inline: PropTypes.bool,                                 //Allows other elements to sit on same row as text field
    initialValue: CustomPropTypes.stringOrNumber,
    inputClassName: PropTypes.string,
    InputProps: PropTypes.object,                           //Properties applied to the underlying Material UI Input component
    inputRef: PropTypes.object,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    nameless: PropTypes.bool,                               //Only set if you don't want the input to be name. Useful if you don't want the field to auto-suggest input values
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMinValidLengthExceeded: PropTypes.func,               //This will fire when minValidLength is exceeded
    onMinValidLengthSubceeded: PropTypes.func,              //This will fire when the length falls below the minimum valid length
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: CustomPropTypes.stringOrNumber,                   //Number of rows to display when multiline option is set to true
    rowsMax: CustomPropTypes.stringOrNumber,                //Maximum number of rows to display when multiline option is set to true
    showRequiredAsterisk: PropTypes.bool,
    startAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    startAdornmentProps: PropTypes.object,
    type: CustomPropTypes.enum(TextFieldType),
    testId: PropTypes.string,
    variant: CustomPropTypes.enum(TextFieldVariant),
    size: CustomPropTypes.enum(TextFieldSize),
    autoFocus: PropTypes.bool
};

TextField.defaultProps = {
    includeClearButton: false,
    multiline: false,
    type: TextFieldType.TEXT,
    variant: TextFieldVariant.OUTLINED
};

export default withTheme(TextField);
