import React, {Component} from 'react';
import t from 'tcomb-form';
import Button from "../Button";

const Form = t.form.Form;

class ProceedPartiallyEstimateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            estimate: props.proceedingPartiallyEstimate,
            value: {},
            loading: false,
            handleSubmit: props.handleSubmit
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        this.setState({value});
    }

    onSubmit() {
        let _this = this;

        let { estimate, value } = this.state;
        estimate.decisionDetails = value.decisionDetails;

        _this.setState({loading: true});
        this.state.handleSubmit(this.state.estimate);
    }

    render() {
        let {loading, estimate } = this.state;

        const EstimateFormStruct = t.struct({
            decisionDetails: t.String,
        });

        const EstimateFormOptions = {
            fields: {
                decisionDetails: {
                    type: 'textarea',
                    label: 'What would you like to proceed with?'
                }
            }
        };

        return (
            <div className="ibox-content">
                <section id="proceedPartially">
                        <Form type={EstimateFormStruct} options={EstimateFormOptions} value={this.state.value}
                              onChange={this.onChange}/>

                        <div>
                            <Button type={'success'} message={loading ? 'Processing...' : 'Submit'}
                                    onPress={() => this.onSubmit() }/>
                        </div>
                </section>
            </div>
        )
    }
}

export default ProceedPartiallyEstimateForm;