import React from 'react'
import PropTypes from "prop-types";

import AlertDialog from "worksmith/components/Dialog/AlertDialog";


const DeleteCustomScheduleWarningDialog = ({customScheduleWarningDialogOpen, handleCustomScheduleChecked, handleCustomScheduleWarningDialogOpen}) => {

    const handleAccept = () => {
        handleCustomScheduleChecked(false);
        handleCustomScheduleWarningDialogOpen(false, true);
    };

    return (
        <>
            <AlertDialog
                acceptDisabled={false}
                acceptText={"Yes, Delete"}
                cancelText={"No, Go Back"}
                debounceAccept
                fullScreenOnMobile
                onAccept={handleAccept}
                onCancel={() => handleCustomScheduleWarningDialogOpen(false)}
                open={customScheduleWarningDialogOpen}
                title={"Are you sure you want to delete the custom schedule?"}>
            </AlertDialog>
        </>
    )
};

DeleteCustomScheduleWarningDialog.propTypes = {
    customScheduleWarningDialogOpen: PropTypes.bool,
    handleCustomScheduleChecked: PropTypes.func,
    handleCustomScheduleWarningDialogOpen: PropTypes.func,
};


export default DeleteCustomScheduleWarningDialog;