import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AsyncGraphQLServiceClass from "../../../../services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import SchedulingView from "./SchedulingView";
import UserAffiliationType from "../../../../enums/api/user/UserAffiliationType";
import BidService from "../../../../services/api/BidService";
import ScheduleHandlerService from "../../../../services/api/ScheduleHandlerService";

const graphQLServiceClass = new AsyncGraphQLServiceClass();
const bidService = new BidService();
const scheduleHandlerService = new ScheduleHandlerService();

class Scheduling extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null
        };
    }

    componentDidMount = async () => {
        const {requestId} = this.props;

        if (requestId) {
            let data = await this.getRequestFromId(requestId);
            this.setState({loading: false, request: data});
        }
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            newState.request = DeepCopy(props.request);
        }

        return newState;
    };

    getRequestFromId = async (requestId) => {

        return await graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            SchedulingGraphQLString)
    };

    acceptSchedule= (scheduleId) => {
        const {request} = this.state;
        const {onSchedule, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        bidService
            .acceptSchedule(request.acceptedOpportunity.activeBid.id, scheduleId)
            .then(() => {
                onSchedule();
            });
    };

    proposeSchedules = (schedules) => {
        const {request} = this.state;
        const {onProposeSchedule, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        let proposeScheduleParams = {
            scheduleHandlerId: request.acceptedOpportunity.activeBid.scheduleHandler.id,
            schedules: schedules
        }

        scheduleHandlerService
            .makeCounterOffer(proposeScheduleParams)
            .then(() => {
                onProposeSchedule();
            });
    };

    render() {
        const {acceptSchedule, proposeSchedules} = this;
        const {request, loading} = this.state;
        const {user} = this.props;

        let acceptedBy = null;
        let duration = null;
        let frequency = null;
        let scheduleHandler = null;
        let vendorLocation = null;
        let useTicketing = null;

        if (!loading) {
           acceptedBy = request.bidAcceptedActivity.user
           duration = request.acceptedOpportunity.activeBid.duration;
           frequency = request.acceptedOpportunity.activeBid.frequency;
           scheduleHandler = request.acceptedOpportunity.activeBid.scheduleHandler;
           useTicketing = request.serviceLine.useTicketing;
           vendorLocation = request.acceptedOpportunity.vendorLocation;
        }

        return (
            <SchedulingView
                acceptedBy={acceptedBy}
                acceptSchedule={acceptSchedule}
                duration={duration}
                frequency={frequency}
                loading={loading || this.props.loading}
                proposeSchedules={proposeSchedules}
                scheduleHandler={scheduleHandler}
                user={user}
                useTicketing={useTicketing}
                vendorLocation={vendorLocation}/>
        )
    }
}

Scheduling.propTypes = {
    loading: PropTypes.bool,
    onSchedule: PropTypes.func.isRequired,
    onProposeSchedule: PropTypes.func.isRequired,
    request: PropTypes.shape({
        acceptedOpportunity: PropTypes.shape({
            activeBid: PropTypes.shape({
                duration: PropTypes.number,
                frequency: PropTypes.shape({
                    recurrenceCount: PropTypes.number,
                    recurrenceType: CustomPropTypes.enum(RecurrenceType),
                    repeatEvery: PropTypes.number,
                }),
                id: PropTypes.number,
                scheduleHandler: PropTypes.shape({
                    id: PropTypes.number,
                    openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
                        id: PropTypes.number,
                        isExpired: PropTypes.bool,
                        schedule: PropTypes.shape({
                            arrivalEndTime: PropTypes.string,
                            arrivalStartTime: PropTypes.string,
                            date: PropTypes.string,
                            dayOfMonth: PropTypes.bool,
                            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                            duration: PropTypes.number,
                            recurrenceType: CustomPropTypes.enum(RecurrenceType),
                            repeatEvery: PropTypes.number,
                            returnDate: PropTypes.string
                        }),
                        status: PropTypes.string
                    })),
                }),
            }),
            vendorLocation: PropTypes.shape({
                vendor: PropTypes.shape({
                    officialName: PropTypes.string
                })
            })
        }),
        bidAcceptedActivity: PropTypes.shape({
            user: PropTypes.shape({
                id: PropTypes.number,
                displayName: PropTypes.string,
                affiliationType: CustomPropTypes.enum(UserAffiliationType)
            })
        }),
        serviceLine: PropTypes.shape({
            useTicketing: PropTypes.bool
        }),
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func,
    user: PropTypes.shape({
        id: PropTypes.number,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
    })
};

const SchedulingGraphQLString = `
    serviceLine {
        useTicketing
    }
    bidAcceptedActivity {
        user {
            id
            displayName
            affiliationType
        }
    }
    acceptedOpportunity {
        activeBid {
            duration
            frequency {
                recurrenceCount
                recurrenceType
                repeatEvery
            }
            id
            scheduleHandler {
                id
                openScheduleOptions {
                    id
                    isExpired
                    status
                    schedule {
                        arrivalEndTime
                        arrivalStartTime
                        date
                        dayOfMonth
                        daysOfWeek
                        duration
                        recurrenceType
                        repeatEvery
                        returnDate
                    }
                }
            }
        }
        vendorLocation {
            vendor {
                officialName
            }
        }
    }
`;

export default Scheduling;