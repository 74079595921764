import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WorksmithLogo from "worksmith/components/Assets/WorksmithLogo";
import {Color} from 'worksmith/enums/Color';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import {AlignItems, JustifyContent, TextAlign} from "worksmith/enums/CSSEnums";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Currency from "worksmith/components/Currency/Currency.web";
import moment from "moment";
import InvoiceItemList from "./InvoiceItemList";
import WorksmithLogoWithText from "worksmith/components/Assets/WorksmithLogoWithText";

const HeaderSection = styled.div`
    margin: 1.5em 1.5em 3em 1.5em;
`;

const LocationSection = styled.div`
    margin: 1.5em;
`;

const Title = styled.div`
    margin-bottom: .5em;
`;

const InvoiceTermsSection = styled.div`
    padding: 1em 1.5em;
    background-color: ${Color.LIGHT_GREY};
`;

const InvoiceItemListSection = styled.div`
    margin: 2em 0;
`;

const WarningSection = styled.div`
    padding: 1em 1.5em;
    background-color: ${Color.LIGHT_GREY};
`;

const AccountingInformationSection = styled.div`
    margin: 3em 1.5em 1.5em 1.5em;
`;

const AccountingGroup = styled.div`
    margin-bottom: 1em;
`;

const InvoiceView = (props) => {
    const {
        linkTo,
        loading,
        receivable,
    } = props;

    //This is done to take advantage of the skeleton rendering. If this is not done it will hard fail
    const clientName = ValueIsSet(receivable) ? receivable.billingProfile.client.nickname.toUpperCase() : null;
    const billing = {
        addressLineOne: ValueIsSet(receivable) ? receivable.billingProfile.addressLineOne : '',
        addressLineTwo: ValueIsSet(receivable) ? receivable.billingProfile.addressLineTwo : '',
        city:           ValueIsSet(receivable) ? receivable.billingProfile.city : '',
        name:           ValueIsSet(receivable) ? receivable.billingProfile.name : '',
        state:          ValueIsSet(receivable) ? receivable.billingProfile.state : '',
        terms:          ValueIsSet(receivable) ? receivable.billingProfile.paymentTerms : 0,
        zip:            ValueIsSet(receivable) ? receivable.billingProfile.zip : ''
    };
    const location = {
        addressLineOne: ValueIsSet(receivable) ? receivable.clientLocation.addressLineOne : '',
        addressLineTwo: ValueIsSet(receivable) ? receivable.clientLocation.addressLineTwo : '',
        city:           ValueIsSet(receivable) ? receivable.clientLocation.city : '',
        locationCode:   ValueIsSet(receivable) ? receivable.clientLocation.locationCode : '',
        state:          ValueIsSet(receivable) ? receivable.clientLocation.state : '',
        title:          ValueIsSet(receivable) ? receivable.clientLocation.title : '',
        zip:            ValueIsSet(receivable) ? receivable.clientLocation.zip : ''
    };
    const invoiceDate      = ValueIsSet(receivable) ? moment(receivable.receivableDate).format('MM/DD/YYYY') : '';
    const dueDate          = ValueIsSet(receivable) ? moment(receivable.receivableDate).add(billing.terms === 0 ? 1 : billing.terms,'days').format('MM/DD/YYYY') : '';
    const receivableNumber = ValueIsSet(receivable) ? receivable.receivableNumber : 0;
    const poNumber         = ValueIsSet(receivable) ? receivable.poNumber : 0;
    const receivableItems  = ValueIsSet(receivable) ? receivable.activeReceivableItems : null;
    const total            = ValueIsSet(receivable) ? receivable.total : 0;
    const subTotal         = ValueIsSet(receivable) ? receivable.netRetail : 0;
    const effectiveTax     = ValueIsSet(receivable) ? receivable.effectiveTax : 0;
    const totalDiscount    = ValueIsSet(receivable) ? receivable.totalDiscount : 0;

    return (
        <Fragment>
            <HeaderSection>
                <Grid container justify={JustifyContent.SPACE_BETWEEN}>
                    <Grid item>
                        <WorksmithLogoWithText color={Color.PRIMARY} width={'100px'} height={'100px'}/>
                    </Grid>
                    <Grid item>
                        <Text variant={TextVariant.H3}> INVOICE </Text>
                        <div style={{marginLeft: '1em'}}>
                            <Text renderSkeleton={loading} variant={TextVariant.SUBTITLE_1}>#{receivableNumber}</Text>
                        </div>
                    </Grid>
                </Grid>
            </HeaderSection>
            <LocationSection>
                <Grid container alignItems={AlignItems.FLEX_START}>
                    <Grid item xs={6} sm={2}>
                        <Title>
                            <Text renderSkeleton={loading} variant={TextVariant.H6}>{clientName}</Text>
                        </Title>
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{billing.name}</Text>
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{billing.addressLineOne}</Text>
                        {ValueIsSet(billing.addressLineTwo) ? <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{billing.addressLineTwo}</Text> : null}
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{billing.city}, {billing.state} {billing.zip}</Text>
                    </Grid>
                    <Grid container item alignItems={AlignItems.FLEX_START} xs={6} sm={7}>
                        <Grid item xs={12} sm={3}>
                            <Title>
                                <Text variant={TextVariant.SUBTITLE_2}>Location Title</Text>
                            </Title>
                            <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>
                                {location.title}
                            </Text>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Title>
                                <Text variant={TextVariant.SUBTITLE_2}>Location Code</Text>
                            </Title>
                            <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>
                                {location.locationCode}
                            </Text>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Title>
                                <Text variant={TextVariant.SUBTITLE_2}>Address</Text>
                            </Title>
                            <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>
                                <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{location.addressLineOne}</Text>
                                {ValueIsSet(location.addressLineTwo) ? <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{location.addressLineTwo}</Text> : null}
                                <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{location.city}, {location.state} {location.zip}</Text>
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} textAlign={TextAlign.RIGHT}>
                        <Text variant={TextVariant.H5}>Invoice Amount</Text>
                        <Text variant={TextVariant.H5}>
                            <Currency amount={total}/>
                        </Text>
                    </Grid>
                </Grid>
            </LocationSection>
            <InvoiceTermsSection>
                <Grid container>
                    <Grid item xs={6} sm={3}>
                        <Title>
                            <Text variant={TextVariant.SUBTITLE_2}>Invoice Date</Text>
                        </Title>
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{invoiceDate}</Text>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Title>
                            <Text variant={TextVariant.SUBTITLE_2}>Terms</Text>
                        </Title>
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{billing.terms === 0 ? 'Due Upon Receipt' : billing.terms + ' Days'}</Text>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Title>
                            <Text variant={TextVariant.SUBTITLE_2}>Due Date</Text>
                        </Title>
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{dueDate}</Text>
                    </Grid>
                    {poNumber ? <Grid item xs={6} sm={3}>
                        <Title>
                            <Text variant={TextVariant.SUBTITLE_2}>P.O#</Text>
                        </Title>
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{poNumber}</Text>
                    </Grid> : null}
                </Grid>
            </InvoiceTermsSection>
            <InvoiceItemListSection>
                <InvoiceItemList effectiveTax={effectiveTax}
                                 linkTo={linkTo}
                                 loading={loading}
                                 receivableItems={receivableItems}
                                 subTotal={subTotal}
                                 total={total}
                                 totalDiscount={totalDiscount}/>
            </InvoiceItemListSection>
            <WarningSection>
                <Text variant={TextVariant.H6}>Invoices will incur a 1.5% late fee for every 30 days they remain outstanding</Text>
            </WarningSection>
            <AccountingInformationSection>
                <AccountingGroup>
                    <Text variant={TextVariant.SUBTITLE_2}>Payment Options</Text>
                    <Text variant={TextVariant.BODY_2}>Wire Transfer or ACH Payment is required</Text>
                </AccountingGroup>

                <AccountingGroup>
                    <Text variant={TextVariant.SUBTITLE_2}>Please send remittance advice to</Text>
                    <a href={"mailto:ar@worksmith.com"}>ar@worksmith.com</a>
                </AccountingGroup>

                <AccountingGroup>
                    <Text variant={TextVariant.SUBTITLE_2}>Remit funds to</Text>
                    <Text variant={TextVariant.BODY_2}>Worksmith, Inc.</Text>
                    <Text variant={TextVariant.BODY_2}>c/o Wells Fargo Bank</Text>
                </AccountingGroup>

                <AccountingGroup>
                    <Text variant={TextVariant.BODY_2}>Account Number: 9946984847</Text>
                    <Text variant={TextVariant.BODY_2}>Routing Number for ACH: 111900659</Text>
                    <Text variant={TextVariant.BODY_2}>Routing Number for Wires: 121000248</Text>
                    <Text variant={TextVariant.BODY_2}>Swift Code: WFBIUS6S</Text>
                </AccountingGroup>
            </AccountingInformationSection>
        </Fragment>
    );
};

InvoiceView.propTypes = {
    linkTo:     PropTypes.func.isRequired,
    loading:    PropTypes.bool.isRequired,
    receivable: PropTypes.object
};

export default InvoiceView;
