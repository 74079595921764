import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import {AlignItems, BorderStyle, Display, FlexDirection, JustifyContent, TextAlign} from "../../CSSEnums";
import {Color, LoggedOutRoutes} from "../../Enums";
import {ExternalLinks} from "worksmith/enums/ExternalLinks";
import Link from "worksmith/components/Link/Link";

const Container = styled.div`
    display: ${Display.FLEX};
    flex: 1;
    justify-content: ${JustifyContent.CENTER};
`;

const WhiteBlock = styled.div`
    display: ${Display.FLEX};
    flex-direction: ${FlexDirection.COLUMN};
    background-color: ${Color.WHITE};
    border-style: ${BorderStyle.SOLID};
    border-width: 2px;
    border-color: ${Color.OFF_WHITE};
    justify-content: ${JustifyContent.CENTER};
    margin-top: 100px;

    height: 500px;
    width: 750px;
`;

const FlexContainer = styled.div`
    display: ${Display.FLEX};
    justify-content: ${JustifyContent.CENTER};
    align-items: ${AlignItems.CENTER};
    flex: 1;
`;

class ForgotPasswordPageView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null
        }
    }

    onEmailChange = (event) => {
        this.setState({email: event.target.value});
    };

    render() {
        let {onEmailChange} = this;
        let {onResetPassword, submitting, successfulReset} = this.props;
        let {email} = this.state;

        return (
            <Container>
                <WhiteBlock>
                    <FlexContainer>
                        <img style={{height: '150px', width: 'auto', marginTop: '70px'}}
                             src={'https://cdn.worksmith.com/images/logos/logo-mark-branded.png'} alt={'logo'}/>
                    </FlexContainer>

                    <FlexContainer>
                        <form style={{width: '300px'}} onSubmit={(event) => {
                            event.preventDefault();
                            onResetPassword(email);
                        }}>
                            <input
                                type={'text'}
                                required
                                className={'form-control'}
                                value={this.state.email}
                                name={'username'}
                                onChange={onEmailChange}
                                placeholder={'Username'}
                                style={{marginBottom: '15px'}}/>
                            {successfulReset ?
                                <Fragment>
                                    <div>
                                        Check your email for your new password.
                                        Click <Link href={LoggedOutRoutes.LOGIN} style={{textAlign: TextAlign.RIGHT, marginBottom: '30px', color: Color.BLUE}}>here</Link> to return to login.
                                        If you did not receive an email with your new password, please submit a support ticket <Link href={ExternalLinks.ZENDESK_SUPPORT_TICKET} style={{textAlign: TextAlign.RIGHT, marginBottom: '30px', color: Color.BLUE}}>here</Link>.
                                    </div>
                                </Fragment>
                                :
                                null
                            }
                            <button
                                type={'submit'}
                                className={'ws-btn ws-btn-primary'}
                                disabled={submitting}
                                style={{width: '100%', marginBottom: '15px', marginTop: '10px', height: '34px', fontSize: '12px'}}>
                                {submitting ? 'Resetting...' : 'Reset Password'}
                            </button>
                        </form>
                    </FlexContainer>
                </WhiteBlock>
            </Container>
        )
    }
}

ForgotPasswordPageView.propTypes = {
    onResetPassword: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    successfulReset: PropTypes.bool
};

export default ForgotPasswordPageView;