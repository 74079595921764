import React from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Box from "@mui/material/Box";
import AlertExpandableSection from "worksmith/components/ExpandableSection/AlertExpandableSection";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import styled from 'styled-components';
import {Color} from "worksmith/enums/Color";
import Divider from "worksmith/components/Divider/Divider";


const ResolvedConcernExpansionPanel = ({concerns, sidePanel}) => {

    return (
        <AlertExpandableSection
            collapsibleProps={{
                unmountOnExit: false
            }}
            title={ <Grid container margin={sidePanel ? "0 0 0 1rem" : null}>
                        <Text variant={sidePanel ? TextVariant.BODY_1 : TextVariant.H6} color={sidePanel ? TextColor.TEXT_SECONDARY : TextColor.PRIMARY}> Resolved Concerns</Text>
                    </Grid>}>
            <Box mt={3} ml={1} mr={2} >
                <Grid container margin={"0 0 0 1rem"}>
                    {concerns.map((concern, idx) => {
                        return (<Grid container margin={ idx !== 0 ? "1rem 0 0 0" : "0 0 0 0"}>
                            <Grid margin={"1rem 0 0.5rem 0"}>
                                <PaddingBottomText color={TextColor.ERROR} semiBold>CONCERN</PaddingBottomText>
                                <Text color={TextColor.TEXT_SECONDARY}>{ValueIsSet(concern.resolvedUser) ? concern.resolvedUser.displayName : ""}</Text>
                                <Text color={TextColor.TEXT_SECONDARY}>{moment(concern.resolvedTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)}</Text>
                            </Grid>
                            {sidePanel ?
                                <GrayGrid xs={12} margin={"0 1rem 0 0"}>
                                    <MarginText margin={'10'}>{concern.description}</MarginText>
                                </GrayGrid>
                                :
                                <Grid xs={12} margin={"0 1rem 0 0"}>
                                    <Text color={TextColor.TEXT_PRIMARY}>{concern.description}</Text>
                                </Grid>}

                            <Grid margin={"1rem 0 0.5rem 0"}>
                                <PaddingBottomText color={TextColor.TEXT_PRIMARY} semiBold>RESOLUTION</PaddingBottomText>
                                <Text color={TextColor.TEXT_PRIMARY}>{ValueIsSet(concern.resolvedUser) ? concern.resolvedUser.displayName : ""}</Text>
                                <Text color={TextColor.TEXT_PRIMARY}>{moment(concern.resolvedTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)}</Text>
                            </Grid>
                            {ValueIsSet(concern.resolutionNotes) ?
                                (
                                    sidePanel ?
                                        <GrayGrid xs={12} margin={"0 1rem 0 0"}>
                                            <MarginText margin={'10'}>{concern.resolutionNotes}</MarginText>
                                        </GrayGrid>
                                        :
                                        <Grid xs={12} margin={"0 1rem 0 0"}>
                                            <Text color={TextColor.TEXT_PRIMARY}>{concern.resolutionNotes}</Text>
                                        </Grid>
                                )
                                :
                                null
                            }
                            {
                                idx !== concerns.length-1 ?
                                    <Grid item xs={12} margin={"1rem 0.5rem 0rem 0.5rem"}>
                                        <Divider/>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>)
                    })}
                </Grid>
            </Box>
        </AlertExpandableSection>
    );
};

ResolvedConcernExpansionPanel.propTypes = {
    concerns: PropTypes.shape({
        resolvedUser: PropTypes.object,
        resolvedTimestamp: PropTypes.string,
        description: PropTypes.string
    }),
    sidePanel: PropTypes.bool
};

const GrayGrid = styled(Grid)`
  background-color: ${Color.GREY};
  border-radius: 15px;
  overflow-wrap: break-word;
  padding-right: 5px;
  
`;

const MarginText = styled(Text)`
    ${props =>
    `margin: ${props.margin}px ${props.margin}px ${props.margin}px ${props.margin}px !important;`
    }
`;

const PaddingBottomText = styled(Text)`
    margin-bottom: 10px !important;
`;

export default ResolvedConcernExpansionPanel;