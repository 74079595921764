import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import NewRequestStatus from "worksmith/enums/api/proposal/NewRequestStatus";
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextColor, TextDisplay, TextVariant} from "worksmith/components/Text/Text.web";
import {AlignItems, FlexDirection, Height, JustifyContent, TextAlign} from "worksmith/enums/CSSEnums";
import {TextTransform} from "worksmith/enums/TextTransform";
import RequestTypeBar from "../RequestTypeBar/RequestTypeBar";
import {DisplayStatusFromNewStatus, DisplayTypeFromTypeAndEmergency} from "worksmith/helpers/RequestLanguageHelper";
import {Pluralize} from "worksmith/helpers/LanguageHelper";

const GridWithHover = styled(Grid)`
    height: 3em;
`;

const RequestCard= (props) => {
    let {
        hideLocationName,
        mini,
        request,
    } = props;

    return (
        <GridWithHover container spacing={0} withHover padding={'0 4px 0 0'}>
            <Grid item container xs={4} md={3} height={Height.INHERIT} spacing={0}>
                <Grid item xs={1} height={Height.INHERIT}>
                    <RequestTypeBar type={request.type} emergency={request.emergency}/>
                </Grid>
                <Grid item xs={11}>
                    <Text variant={TextVariant.CAPTION} upperCase>{DisplayTypeFromTypeAndEmergency(request.type, request.emergency)}</Text>
                    {
                        hideLocationName ?
                            <Text variant={TextVariant.CAPTION}>{request.id}</Text>
                            :
                            <Text variant={TextVariant.CAPTION}>{request.serviceLine.name}</Text>
                    }
                </Grid>
            </Grid>
            <Grid item xs={5} md={6}>
                {
                    hideLocationName ?
                        <Fragment>
                            <Text noWrap variant={TextVariant.BODY_2}>{request.serviceLine.name}</Text>
                            <Text noWrap variant={TextVariant.CAPTION}>{request.description}</Text>
                        </Fragment>
                        :
                        <Fragment>
                            <Text noWrap variant={TextVariant.BODY_2}>{request.clientLocation.title}</Text>
                            <Text noWrap variant={TextVariant.CAPTION}>{request.id} - {request.description}</Text>
                        </Fragment>
                }
            </Grid>
            <Grid item container xs={3} alignItems={AlignItems.FLEX_END} justify={JustifyContent.CENTER} direction={FlexDirection.COLUMN}>
                <Grid item>
                    <Text variant={TextVariant.CAPTION} align={TextAlign.RIGHT} textTransform={TextTransform.CAPITALIZE}>{DisplayStatusFromNewStatus(request.newStatus)}</Text>
                </Grid>
                {(request.newStatus === NewRequestStatus.MARKET && request.activeBidAndWalkthroughCount > 0) || request.unreadMessageAndCommentCount > 0 ?
                    <Grid item>
                        <Text variant={TextVariant.CAPTION} align={TextAlign.RIGHT}>
                            {request.newStatus === NewRequestStatus.MARKET && request.activeBidAndWalkthroughCount > 0 ?
                                <Fragment>
                                    <Text bold variant={TextVariant.CAPTION} display={TextDisplay.INLINE} color={TextColor.ERROR}>•</Text> {request.activeBidAndWalkthroughCount + Pluralize(request.activeBidAndWalkthroughCount,' Response', ' Responses')}
                                </Fragment>
                                :
                                null
                            }
                            {request.newStatus === NewRequestStatus.MARKET && request.activeBidAndWalkthroughCount > 0 && request.unreadMessageAndCommentCount > 0 ?
                                ' | '
                                :
                                null
                            }
                            {request.unreadMessageAndCommentCount > 0 ?
                                <Fragment>
                                    <Text bold variant={TextVariant.CAPTION} display={TextDisplay.INLINE} color={TextColor.ERROR}>•</Text> New Messages
                                </Fragment>
                                :
                                null
                            }
                        </Text>
                    </Grid>
                    :
                    null
                }
            </Grid>
        </GridWithHover>
    );
};


RequestCard.defaultProps = {
    hideLocationName: false
};

RequestCard.propTypes = {
    hideLocationName: PropTypes.bool,
    request: PropTypes.shape({
        clientLocation: PropTypes.shape({
            title: PropTypes.string.isRequired
        }),
        emergency: PropTypes.bool,
        id: PropTypes.number.isRequired,
        serviceLine: PropTypes.shape({
            name: PropTypes.string.isRequired
        }),
        newStatus: CustomPropTypes.enum(NewRequestStatus),
        type: CustomPropTypes.enum(RfpType)
    }).isRequired,
    style: PropTypes.object
};

export const RequestCardGraphQLObject = `
    clientLocation {
        id
        title
    }
    serviceLine {
        id
    }
    description
    id
    emergency
    newStatus
    associatedObligationTemplate {
        id
    }
    activeBidAndWalkthroughCount
    unreadMessageAndCommentCount
    isRecurring
    serviceLine {
        name
    }
    type
`;

export default RequestCard;