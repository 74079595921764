import React, {Fragment, useState} from 'react';
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RfpStatus from "../../../../enums/api/proposal/RfpStatus";
import {TextColor} from "worksmith/components/Text/Text.web";
import RequestStateLayout from "../RequestStateLayout";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import ClientLocationRole from "worksmith/enums/api/user/ClientLocationRole";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import {Display} from "worksmith/enums/CSSEnums";
import ProposalHeader from "../../../ProposalHeader/ProposalHeader";
import BidDetailsView from "../../../BidDetails/BidDetailsView";
import Button from "../../../../components/Button/Button.web";
import RequestARevisionModal from "worksmith/composite-components/BidDetails/Modals/RequestARevisionModal";
import AcceptAndDeferModal from "worksmith/composite-components/BidAndWalkthroughModals/AcceptAndDeferModal";
import CreateScheduleOptionsDialog
    from "worksmith/composite-components/ScheduleOptionsForm/CreateScheduleOptionsDialog";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import SelectScheduleModal from "worksmith/composite-components/BidAndWalkthroughModals/SelectScheduleModal";
import MenuItem from "worksmith/components/Menu/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuButton from "worksmith/components/MenuButton/MenuButton";

const CorporateBidReviewView = (props) => {
    const {acceptBid, acceptAndSchedule, bidId, duration, frequency, loading, requestRevision, request, user} = props;
    const [showActionMenu, setShowActionMenu] = useState(false);
    const [showRequestRevisionModal, setShowRequestRevisionModal] = useState(false);
    const [showAcceptAndDeferModal,setShowAcceptAndDeferModal] = useState(false);
    const [showAcceptAndSubmitSchedulesModal, setShowAcceptAndSubmitSchedulesModal] = useState(false);
    const [showAcceptAndScheduleModal, setShowAcceptAndScheduleModal] = useState(false);

    if (loading) {
        return (
            <RequestStateLayout loading={loading} status={''}/>
        )
    }

    let description = '';
    let textColor = TextColor.PRIMARY;
    let isClientAdmin = user.clientRoles && user.clientRoles.length > 0;

    let canAcceptPricing = false;
    if (!loading && request.approvalRequestedOpportunity) {
        let userRoleAtRequestLocation = user.clientLocationRoles ?
            user.clientLocationRoles.filter((role) => role.clientLocation.id === request.clientLocation.id)
            :
            [];
        let isRegionalMangerForRequestLocation = userRoleAtRequestLocation.length > 0 && userRoleAtRequestLocation[0].role === ClientLocationRole.REGIONAL_MANAGER;
        let approvalRequestedActivity = request.approvalRequestedActivity;

        if ((request.status === RfpStatus.PENDING_ADMIN_BID && isClientAdmin)
            || (request.status === RfpStatus.PENDING_REGIONAL_MANAGER_BID
                && (isClientAdmin || isRegionalMangerForRequestLocation))) {

            canAcceptPricing = true;
            description = approvalRequestedActivity ?
                `Your approval is required for this pricing. Please review, then accept or request a revision. Note that you will not be able take any other actions on this request until you respond to the suggested pricing.`
                :
                `A local user would like to accept pricing that is above their approved threshold. Please review, then accept or request a revision. Note that you will not be able take any other actions on this request until you respond to the suggested pricing.`;

            textColor = TextColor.ERROR;
        } else {

            if (approvalRequestedActivity) {
                description = approvalRequestedActivity.user.id === user.id ?
                    `The pricing you selected is above your approved threshold and requires authorization. You will be notified once it has been accepted.`
                    :
                    `Approval requested for pricing above your approved threshold. You will be notified once it has been accepted.`;
            } else {
                description = `A user has requested approval on pricing that is above their approved threshold. You will be notified once it has been accepted.`;
            }
        }
    }

    let bid = request.approvalRequestedOpportunity.activeBidOrWalkthrough;

    return (
        <RequestStateLayout
            description={description}
            status={TitleCase(NewRequestStatus.CORPORATE_PRICING_REVIEW)}
            statusColor={textColor}
            body={
                canAcceptPricing ?
                    <Fragment>
                        <Box display={Display.FLEX} mb={4}>
                            <ProposalHeader
                                additionalApprovalAmount={bid.additionalApprovalAmount}
                                hasPreferredVendor={request.approvalRequestedOpportunity.hasPreferredVendor}
                                isObligation
                                loading={loading}
                                totalRetailPrice={bid.totalRetailPrice}
                                vendorLocation={request.approvalRequestedOpportunity.vendorLocation}/>
                        </Box>
                        <BidDetailsView
                            hideActionButton
                            additionalApprovalAmount={bid.additionalApprovalAmount}
                            additionalTerms={bid.additionalTerms}
                            bidItems={bid.bidItems}
                            duration={bid.duration}
                            frequency={bid.frequency}
                            scheduleHandler={bid.scheduleHandler}
                            totalRetailPrice={bid.totalRetailPrice}/>
                        <Box display={Display.INLINE_FLEX} mt={3}>
                            <Box mr={2}>
                                <MenuButton
                                    Button={
                                        <Button
                                            primary
                                            disabled={loading}
                                            testId={"corporateBidReviewActionButton"}
                                            endIcon={<ArrowDropDownIcon/>}
                                            onClick={() => setShowActionMenu(true)}>
                                            ACTIONS
                                        </Button>
                                    }
                                    onClose={() => setShowActionMenu(false)}
                                    open={showActionMenu}>
                                    { ValueIsSet(bid) && bid.scheduleHandler !== null && bid.scheduleHandler.openScheduleOptions.length > 0 ?
                                            <MenuItem onClick={() => {
                                            setShowActionMenu(false);
                                            setShowAcceptAndScheduleModal(true);
                                        }}>
                                            Accept and arrange service time
                                        </MenuItem>
                                        :
                                        null}
                                    <MenuItem onClick={() => {
                                        setShowActionMenu(false);
                                        setShowAcceptAndSubmitSchedulesModal(true);
                                    }}>
                                        Accept and submit new availability
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setShowActionMenu(false);
                                        setShowAcceptAndDeferModal(true);
                                    }}>
                                        Accept and defer scheduling
                                    </MenuItem>
                                    {!bid.isWalkthrough && <MenuItem onClick={() => {
                                        setShowActionMenu(false);
                                        setShowRequestRevisionModal(true);
                                    }}>
                                        Request a revision
                                    </MenuItem>}
                                </MenuButton>
                            </Box>
                        </Box>
                        <RequestARevisionModal
                            requestRevision={requestRevision}
                            canRequestRevision={bid.canRequestRevision}
                            open={showRequestRevisionModal}
                            closeModal={() => setShowRequestRevisionModal(false)}/>
                        <AcceptAndDeferModal
                            accept={acceptBid}
                            closeModal={() => setShowAcceptAndDeferModal(false)}
                            open={showAcceptAndDeferModal}/>
                        <CreateScheduleOptionsDialog
                            duration={duration}
                            frequency={frequency}
                            title={ValueIsSet(frequency) ? "Submit Start Date" : "Submit Availability"}
                            numberOfSchedules={2}
                            numberOfRequiredSchedules={1}
                            open={showAcceptAndSubmitSchedulesModal}
                            onSubmit={acceptBid}
                            useTicketing={request.serviceLine.useTicketing}
                            onCancel={() => setShowAcceptAndSubmitSchedulesModal(false)}/>
                        <SelectScheduleModal
                            closeModal={() => setShowAcceptAndScheduleModal(false)}
                            scheduleHandler={bid.scheduleHandler}
                            open={showAcceptAndScheduleModal}
                            selectSchedules={acceptAndSchedule}/>
                    </Fragment>
                    :
                    null
            }
        />
    )
};

CorporateBidReviewView.propTypes = {
    acceptBid: PropTypes.func,
    cancelRequest: PropTypes.func,
    declineBid: PropTypes.func,
    loading: PropTypes.bool,
    request: PropTypes.shape({
        status: CustomPropTypes.enum(RfpStatus),
        approvalRequestedActivity: PropTypes.shape({
            user: PropTypes.shape({
                id: PropTypes.number,
                displayName: PropTypes.string
            })
        }),
        approvalRequestedOpportunity: PropTypes.shape({
            activeBidOrWalkthrough: PropTypes.shape({
                additionalApprovalAmount: PropTypes.number,
                additionalTerms: PropTypes.string,
                bidItems: PropTypes.arrayOf(PropTypes.shape({
                    lineItemType: PropTypes.shape({
                        name: PropTypes.string
                    }),
                    quantity: PropTypes.number,
                    name: PropTypes.string,
                    unitRetailPrice: PropTypes.number
                })),
                duration: PropTypes.number,
                id: PropTypes.number,
                frequency: PropTypes.shape({
                    recurrenceCount: PropTypes.number,
                    recurrenceType: CustomPropTypes.enum(RecurrenceType),
                    repeatEvery: PropTypes.number,
                }),
                scheduleHandler: PropTypes.shape({
                    id: PropTypes.number,
                    openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
                        id: PropTypes.number,
                        isExpired: PropTypes.bool,
                        schedule: PropTypes.shape({
                            arrivalEndTime: PropTypes.string,
                            arrivalStartTime: PropTypes.string,
                            date: PropTypes.string,
                            dayOfMonth: PropTypes.bool,
                            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                            duration: PropTypes.number,
                            recurrenceType: CustomPropTypes.enum(RecurrenceType),
                            repeatEvery: PropTypes.number,
                            returnDate: PropTypes.string
                        }),
                        status: PropTypes.string
                    }))
                }),
                totalRetailPrice: PropTypes.number,
            }),
            hasPreferredVendor: PropTypes.bool,
            vendorLocation: PropTypes.shape({
                rating: PropTypes.number,
                ratingsCount: PropTypes.number,
                vendor: PropTypes.shape({
                    officialName: PropTypes.string,
                    qualifiesForPremiumBadge: PropTypes.bool,
                })
            }),
        }),
        clientLocation: PropTypes.shape({
            id: PropTypes.number
        })
    }),
    requestId: PropTypes.number,
    user: PropTypes.shape({
        id: PropTypes.number,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
        clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
            clientLocation: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: CustomPropTypes.enum(ClientLocationRole),
        })),
    })

};

export default CorporateBidReviewView;