import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Message from "../Conversation/Message/Message";
import moment from "moment";
import AuthTokenManager from "worksmith/services/utilities/AuthTokenManager";
import Text, {TextColor} from "../Text/Text";

const authTokenManager = new AuthTokenManager();

const NoteContainer = styled.div`
    margin-bottom: 1.5em;
`;

class InternalNotesList extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        let {notes} = this.props;

        let currentUserId = authTokenManager.getUserId();

        return (
            <Fragment>
                {
                    notes
                        .sort((a, b) => moment(a.createdTimestamp) - moment(b.createdTimestamp))
                        .map((note) => (
                            <NoteContainer key={note.id}>
                                <Message
                                    author={note.author.displayName}
                                    content={note.note}
                                    createdTimestamp={moment(note.createdTimestamp).format('MMMM Do YYYY h:mm a')}
                                    shiftRight={note.author.id == currentUserId}
                                />
                            </NoteContainer>
                        ))
                }
            </Fragment>
        )

    }
}

InternalNotesList.propTypes = {
    notes: PropTypes.arrayOf(PropTypes.shape({
        author: PropTypes.shape({
            id: PropTypes.number,
            displayName: PropTypes.string
        }),
        note: PropTypes.string,
        createdTimestamp: PropTypes.string,
        id: PropTypes.number,
    })).isRequired
};

export default InternalNotesList;