import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

import AddressPropType from "worksmith/custom-prop-types/AddressPropType";
import {AlignItems} from "worksmith/enums/CSSEnums";
import AutoComplete from "worksmith/components/Inputs/Autocomplete/Autocomplete";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Checkbox from "worksmith/components/Checkbox/Checkbox";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import DatePicker from "worksmith/components/DatePicker/DatePicker";
import Grid from "worksmith/components/Grid/Grid";
import {industryOptions} from "../../../helpers/Constants";
import {StateFullName} from "worksmith/enums/StateFullName"
import Select from "worksmith/components/Inputs/Select/Select";
import Text, {TextColor, TextDisplay, TextVariant} from "worksmith/components/Text/Text";
import TextField, {TextFieldType} from "worksmith/components/Inputs/TextField/TextField";
import CountrySelect from "worksmith/components/Inputs/Select/CountrySelect";
import {Currency} from "worksmith/enums/api/country/Currency";
import StateProvinceSelect from "worksmith/components/Inputs/Select/StateProvinceSelect";
import {Country} from "worksmith/enums/api/country/Country";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {CountriesCombined, CountryStateProvince} from "worksmith/enums/api/country/CountryStateProvince";


const GeneralInfoForm =
    ({
         generalInfoFormFields,
         handleChange,
         isSubmitting,
         mallOptions,
         submitGeneralInfo,
         isSelfService,
         toggleEdit
    }) => {

    const [country, setCountry] = useState(ValueIsSet(generalInfoFormFields.country) ? generalInfoFormFields.country : '');

    const currencyOptions = Object.entries(Currency).map(([key, value]) => ({
        value: key,
        label: value
    }));

    return (
        <Grid container alignItems={AlignItems.FLEX_START} spacing={3}>
            <Grid spacing={3} container item xs={12} md={6} alignItems={AlignItems.FLEX_START}>
                <Grid item xs={12} md={10}>
                    <TextField
                        fullWidth
                        initialValue={generalInfoFormFields.title}
                        label={'Location name'}
                        name={'title'}
                        onChange={value => handleChange('title', value)}
                        type={TextFieldType.TEXT}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField
                        fullWidth
                        initialValue={generalInfoFormFields.locationCode}
                        label={'Location ID code'}
                        name={'locationCode'}
                        onChange={value => handleChange('locationCode', value)}
                        type={TextFieldType.TEXT}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <DatePicker
                        dateInputFormat={{
                            format: 'MM/DD/YYYY',
                            placeholder: 'mm/dd/yyyy',
                        }}
                        fullWidth
                        initialDate={generalInfoFormFields.openingDate ? moment(generalInfoFormFields.openingDate) : null}
                        label={'Opening date'}
                        name={'openingDate'}
                        onChange={value => handleChange('openingDate', value)}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Select
                        fullWidth
                        initialValue={generalInfoFormFields.industry}
                        label={'Select your industry'}
                        name={'industry'}
                        onChange={value => handleChange('industry', value)}
                        options={industryOptions}
                    />
                </Grid>
                {generalInfoFormFields.industry === 'Other' ?
                    <Grid item xs={12} md={10}>
                        <TextField
                            fullWidth
                            initialValue={generalInfoFormFields.otherIndustry}
                            label={'What industry'}
                            name={'otherIndustry'}
                            onChange={value => handleChange('otherIndustry', value)}
                            type={TextFieldType.TEXT}
                        />
                    </Grid>
                    :
                    null
                }
                <Grid item xs={12} md={10}>
                    <TextField
                        disabled={generalInfoFormFields.noPhone}
                        fullWidth
                        initialValue={generalInfoFormFields.phone}
                        label={'Location phone number'}
                        name={'phone'}
                        onChange={value => handleChange('phone', value)}
                        type={TextFieldType.PHONE}
                    />
                    <Checkbox
                        checked={generalInfoFormFields.noPhone}
                        label={
                            <Text
                                color={'primary'}
                                semiBold
                                variant={TextVariant.CAPTION}
                            >
                                Location does not have a phone number
                            </Text>
                        }
                        onChange={value => handleChange('noPhone', !generalInfoFormFields.noPhone)}
                        primary
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField
                        fullWidth
                        initialValue={generalInfoFormFields.email}
                        label={'Location email'}
                        name={'email'}
                        onChange={value => handleChange('email', value)}
                        type={TextFieldType.EMAIL}
                    />
                </Grid>
            </Grid>

            <Grid spacing={3} container item xs={12} md={6} alignItems={AlignItems.FLEX_START}>
                {isSelfService ?
                    <>
                        <Grid item xs={6} md={5}>
                            <CountrySelect
                                label={"Country"}
                                initialValue={country}
                                onChange={(country) => {
                                    setCountry(country);
                                    handleChange('country', country);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={5}>
                            <Select
                                includeNullOption
                                initialValue={generalInfoFormFields.currency}
                                label={'Currency'}
                                options={currencyOptions}
                                onChange={(currency) => handleChange('currency', currency)}
                            />
                        </Grid>
                    </>
                : null}
                <Grid item xs={12} md={10}>
                    <TextField
                        fullWidth
                        initialValue={generalInfoFormFields.addressLineOne}
                        label={'Address Line 1'}
                        name={'addressLineOne'}
                        onChange={value => handleChange('addressLineOne', value)}
                        type={TextFieldType.TEXT}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField
                        fullWidth
                        initialValue={generalInfoFormFields.addressLineTwo}
                        label={'Address Line 2'}
                        name={'addressLineTwo'}
                        onChange={value => handleChange('addressLineTwo', value)}
                        type={TextFieldType.TEXT}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField
                        fullWidth
                        initialValue={generalInfoFormFields.city}
                        label={'City'}
                        name={'city'}
                        onChange={value => handleChange('city', value)}
                        type={TextFieldType.TEXT}
                    />
                </Grid>
                <Grid container item spacing={3} xs={12} padding={'24px 0 12px 24px'}>
                    <Grid item>
                        <StateProvinceSelect
                            country={isSelfService ? country : CountriesCombined.USA_CANADA}
                            initialValue={generalInfoFormFields.state}
                            label={'State/Province'}
                            onChange={value => handleChange('state', value)}
                        />
                    </Grid>
                    <Grid item xs={true} width={'100px'}>
                        <TextField
                            initialValue={generalInfoFormFields.zip}
                            label={'Zipcode'}
                            name={'zip'}
                            onChange={value => handleChange('zip', value)}
                            type={TextFieldType.TEXT}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Text
                        color={'primary'}
                        gutterBottom
                        variant={TextVariant.BODY_1}
                    >
                        Is this location in a mall?
                    </Text>
                    <Grid container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => handleChange('isInMall', true)}
                                primary
                                variant={generalInfoFormFields.isInMall ? ButtonVariant.CONTAINED : ButtonVariant.OUTLINED}
                            >
                                YES
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleChange('isInMall', false)}
                                primary
                                variant={generalInfoFormFields.isInMall === false ? ButtonVariant.CONTAINED : ButtonVariant.OUTLINED}
                            >
                                NO
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    generalInfoFormFields.isInMall ?
                        <Grid item xs={12} md={10}>
                            <AutoComplete
                                blurOnSelect
                                fullWidth
                                initialSelectedItem={{
                                    label: generalInfoFormFields.mall && generalInfoFormFields.mall.name,
                                    value: generalInfoFormFields.mall && generalInfoFormFields.mall.name
                                }}
                                label={'Which mall?'}
                                name={'mall'}
                                onSelect={value => handleChange('mall', value)}
                                options={mallOptions}
                            />
                        </Grid>
                        :
                        null
                }
                <Grid item xs={12}>
                    <Text
                        color={'primary'}
                        gutterBottom
                        variant={TextVariant.BODY_1}
                    >
                        Is this location a shop-in-shop? (i.e. a Starbucks inside a bookstore)
                    </Text>
                    <Grid container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => handleChange('isShopInShop', true)}
                                primary
                                variant={generalInfoFormFields.isShopInShop ? ButtonVariant.CONTAINED : ButtonVariant.OUTLINED}
                            >
                                YES
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleChange('isShopInShop', false)}
                                primary
                                variant={generalInfoFormFields.isShopInShop === false ? ButtonVariant.CONTAINED : ButtonVariant.OUTLINED}
                            >
                                NO
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} margin={'32px 0 0 0'}>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} onClick={toggleEdit}>CANCEL</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} primary onClick={submitGeneralInfo}>{isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}</Button>
                </Grid>
                <Grid item xs={8}>
                    <Text display={TextDisplay.INLINE}
                          color={TextColor.TEXT_SECONDARY}>
                        {"Note: Changing the location address will update the geofence."}
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    )
};

GeneralInfoForm.defaultProps = {
    isSelfService: false
};

GeneralInfoForm.propTypes = {
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    submitGeneralInfo: PropTypes.func,
    generalInfoFormFields: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        email: PropTypes.string,
        isInMall: PropTypes.bool,
        id: PropTypes.number,
        industry: PropTypes.string,
        otherIndustry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        locationCode: PropTypes.string,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: AddressPropType
        })
    }),
    mallOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: AddressPropType
        })
    })),
    toggleEdit: PropTypes.func
};

export default GeneralInfoForm;

