import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import JobDetailsView from "./JobDetailsView";

const graphQLService = new GraphQLServiceClass();

class JobDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            obligation: {}
        };
    }

    componentDidMount = () => {
        const {obligationId} = this.props;

        graphQLService.findOneById(
            obligationId,
            GraphQLObjectType.OBLIGATION,
            JobDetailsGraphQlString
        ).then((data) => {
            this.setState({loading: false, obligation: data});
        })
    };

    render() {
        const {loading, obligation} = this.state;
        const {useTicketing} = this.props;

        return (
            <JobDetailsView
                additionalApprovalAmount={obligation.additionalApprovalAmount}
                assessmentOfIssue={obligation.assessmentOfIssue}
                bid={obligation.bid}
                clientOnsiteContact={obligation.clientOnsiteContact}
                completionDate={obligation.completionDate}
                leadTechnician={obligation.leadTechnician}
                loading={loading}
                obligationAttachments={obligation.obligationAttachments}
                obligationId={obligation.id}
                obligationDate={obligation.obligationDate}
                obligationItems={obligation.obligationItems}
                requestId={obligation.requestId}
                schedule={obligation.schedule}
                scopeOfWork={obligation.scopeOfWork}
                status={obligation.status}
                summaryOfWorkCompleted={obligation.summaryOfWorkCompleted}
                totalRetailPrice={obligation.totalRetailPrice}
                useTicketing={useTicketing}
                updatedArrivalTime={obligation.updatedArrivalTime}
                vendorLocation={obligation.vendorLocation}
            />
        )
    }
}

JobDetails.propTypes = {
    obligationId: PropTypes.number,
    useTicketing: PropTypes.bool
};

const JobDetailsGraphQlString = `
    id
    additionalApprovalAmount
    updatedArrivalTime
    assessmentOfIssue
    bid {
        additionalTerms
    }
    clientOnsiteContact
    completionDate
    hasPreferredVendor
    leadTechnician
    obligationAttachments {
        name
        singleUseUrl
    }
    obligationDate
    obligationItems {
        lineItemType {
            name
        }
        quantity
        title
        unitRetailPrice
    }
    requestId
    schedule {
        arrivalEndTime
        arrivalStartTime
        date
        returnDate
    }
    scopeOfWork
    status
    summaryOfWorkCompleted
    totalRetailPrice
    vendorLocation {
        id
        rating
        ratingsCount
        vendor {
            id
            officialName
            qualifiesForPremiumBadge
        }
    }
`;

export default JobDetails;