import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import BreakpointEnum from "worksmith/enums/BreakpointEnum";
import {
    PricingProposalFormFields,
    PricingProposalFormVariant
} from "worksmith/components/PricingProposalForm/PricingProposalForm";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import LocationAndRequestDetails from "worksmith/components/LocationSummary/LocationAndRequestDetails";
import LineItemFieldsGroup from "worksmith/components/LineItem/LineItemForm/LineItemFieldsGroup";
import TextField from "worksmith/components/Inputs/TextField/TextField";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import styled from "styled-components";
import {Display, FlexDirection} from "worksmith/enums/CSSEnums";
import {withTheme} from "../Theme/ThemeProvider";
import PortalType from "worksmith/enums/api/user/PortalType";

const Body = styled.div`
    display: ${props => props.stackFields ? Display.BLOCK : Display.FLEX};
    flex-direction: ${FlexDirection.ROW_REVERSE};
`;

const TitleSection = styled.div`
    border-bottom: 1px solid silver;
    padding-bottom: 1em;
`;

const LeftSection = styled.div`
    flex: 9;
    ${props => props.stackFields ? 'border-top: 1px solid silver;' : 'border-right: 1px solid silver;' }
`;

const RightSection = styled.div`
    flex: 3;
    margin-left: ${props => props.stackFields ? 0 : '1em'};
    margin-bottom: 1em;
`;

const Field =styled.div`
    border-bottom: 1px solid silver;
    padding: 0 1em 1em 0;
    margin: 1em 0;
`;

const TopMargin = styled.div`
    margin-top: 1em;
`;

const PricingProposalFromView = (props) => {
    const {
        allowAdditionalApproval,
        clientLocation,
        effectiveWholesaleRate,
        errors,
        initialAdditionalApproval,
        initialLineItems,
        initialTermsAndConditions,
        isEdit,
        overrideClientApproval,
        isForRecurringService,
        lineItemTypes,
        onChange,
        request,
        titleIdentifier,
        portalType,
        variant,
    } = props;

    const theme = useTheme();
    const stackFields = useMediaQuery(theme.breakpoints.down(BreakpointEnum.SM));

    let title = null;
    let subTitle = null;
    let warningText = null;

    let titleActionString = overrideClientApproval ? "Create"
        : isEdit ? "Edit"
            : "Request a";

    // noinspection JSRedundantSwitchStatement
    switch (variant) {
        case PricingProposalFormVariant.CHANGE_ORDER:
            title = titleActionString + ' Change Order for ' + titleIdentifier;
            if(overrideClientApproval)
                subTitle = 'This will update the Estimate. The vendor (if one is assigned) will receive a notification with the updated scope of work. ';
            else
                subTitle = 'Revise your pricing to reflect the new scope of work and any charges from your initial visit. If Accepted, we will replace your original pricing with this one. If declined, your original pricing will resurface. Reminder: Do NOT do any additional work without approval!';
            warningText = isForRecurringService ? "*Please note that this change order will only apply to this single visit." : null;
            break;
    }

    return (
        <Fragment>
            {(ValueIsSet(title) || ValueIsSet(subTitle)) && <TitleSection>
                {ValueIsSet(title) && <Text variant={TextVariant.H5}>{title}</Text>}
                {ValueIsSet(subTitle) && <Text variant={TextVariant.CAPTION}>{subTitle}</Text>}
                {ValueIsSet(warningText) && <Text variant={TextVariant.CAPTION} color={TextColor.ERROR}>{warningText}</Text>}
            </TitleSection>}
            <Body stackFields={stackFields}>
                <RightSection stackFields={stackFields}>
                    <TopMargin>
                        <LocationAndRequestDetails
                            address={clientLocation.addressLineOne + "\n" + clientLocation.addressLineTwo}
                            cityStateZip={clientLocation.city + ", " + clientLocation.state + " " + clientLocation.zip}
                            description={request.description}
                            locationHours={clientLocation.locationHours}
                            target={request.target}
                            timePreference={request.timePreference}/>
                    </TopMargin>
                </RightSection>
                <LeftSection stackFields={stackFields}>
                    <Field>
                        <LineItemFieldsGroup allowAdditionalApproval={allowAdditionalApproval}
                                             effectiveWholesaleRate={effectiveWholesaleRate}
                                             errors={errors.lineItems}
                                             initialAddtionalApproval={initialAdditionalApproval}
                                             initialLineItems={initialLineItems}
                                             lineItemTypes={lineItemTypes}
                                             onAdditionalApprovalChange={(additionalApproval) => onChange(PricingProposalFormFields.ADDITIONAL_APPROVAL, additionalApproval)}
                                             onLineItemsChange={(lineItems) => onChange(PricingProposalFormFields.LINE_ITEMS, lineItems)}
                                             portalType={portalType}
                        />
                    </Field>

                    <Field>
                    </Field>

                    <Field>
                        <Text variant={TextVariant.H6}>Terms & Conditions</Text>
                        <Text variant={TextVariant.CAPTION}>Provide any additional terms the client should be aware
                            of before accepting your bid. Do NOT put any pricing information in this section.</Text>
                        <TopMargin>
                            <TextField error={ValueIsSet(errors) && errors.scopeOfWork}
                                       fullWidth
                                       initialValue={initialTermsAndConditions}
                                       label={'Terms & Conditions'}
                                       multiline
                                       onChange={(value) => onChange(PricingProposalFormFields.TERMS_AND_CONDITIONS, value)}
                                       placeholder={"E. g. Is there a warranty on labor and materials? What is the scope of the work? Will the job take more than a day to complete?"}
                                       rows={3}
                                       rowsMax={5}/>
                        </TopMargin>
                    </Field>
                </LeftSection>
            </Body>
        </Fragment>
    );

};

PricingProposalFromView.propTypes = {
    allowAdditionalApproval:    PropTypes.bool,
    clientLocation:             PropTypes.object.isRequired,
    effectiveWholesaleRate:     PropTypes.number.isRequired,
    errors:                     PropTypes.object,
    initialAdditionalApproval:  PropTypes.number,
    initialLineItems:           PropTypes.arrayOf(PropTypes.object).isRequired,
    initialTermsAndConditions:  PropTypes.string,
    isEdit:                     PropTypes.bool,
    overrideClientApproval:    PropTypes.bool,
    isForRecurringService:      PropTypes.bool,
    lineItemTypes:              PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange:                   PropTypes.func.isRequired,
    request:                    PropTypes.object,
    titleIdentifier:            CustomPropTypes.stringOrNumber,
    portalType:                 CustomPropTypes.enum(PortalType),
    variant:                    PropTypes.string.isRequired
};

export default withTheme(PricingProposalFromView);