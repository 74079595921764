import React, {Fragment} from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';

import IconButton from "../Button/IconButton.web";
import {Position} from "../../enums/CSSEnums";

const PositionedIconButton = styled(IconButton)`
    ${props => props.prev ?
        `
        position: ${Position.ABSOLUTE} !important;
        top: 10px;
        left: 22px;
        `
    : null
    }
    
    ${props => props.next ?
        `
        position: ${Position.ABSOLUTE} !important;
        top: 10px;
        right: 22px;
        `
    : null
}
`;



const MonthNavigationButton = (props) => {
    let {
        ariaLabel,
        disabled,
        iconName,
        next,
        onClick,
        onKeyUp,
        onMouseUp,
        prev,
    } = props;

    return (
        <PositionedIconButton
            iconName={iconName}
            disabled={disabled}
            next={next}
            onClick={onClick}
            onKeyUp={onKeyUp}
            onMouseUp={onMouseUp}
            prev={prev}/>
    )
};

MonthNavigationButton.defaultProps = {
    isVisible: true
};

MonthNavigationButton.propTypes = {
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    iconName: PropTypes.string.isRequired,
    next: PropTypes.bool,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMouseUp: PropTypes.func,
    prev: PropTypes.bool,
};

export default MonthNavigationButton;