import PropTypes from 'prop-types';
import React, {Fragment} from 'react';

import Grid from 'worksmith/components/Grid/Grid';
import Text, {TextColor} from 'worksmith/components/Text/Text';
import {JavascriptType} from 'worksmith/enums/GenericEnums';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";

export const PaginatedListVariant = Object.freeze({
    TABLE: 'table',
    ROW: 'row'
});

const Border = styled.div`
    border: 0.5px solid lightGray
    margin-top: -1em;
`;

const PaginatedListView = (props) => {

    const {
        columns,
        data,
        getKeyFromItem,
        onClick,
        renderItem,
        variant,
        titleBorder,
    } = props;

    return (
        variant === PaginatedListVariant.TABLE ?
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => {
                                    if (typeof column.title === JavascriptType.STRING) {
                                        return (
                                            <TableCell>
                                                <Text color={TextColor.TEXT_SECONDARY}>
                                                    {column.title}
                                                </Text>
                                            </TableCell>
                                        )
                                    } else {
                                        return (
                                            <TableCell>
                                                {column.title}
                                            </TableCell>
                                        )
                                    }
                                })
                            }
                        </TableRow>
                    </TableHead>
                    {titleBorder ? <Border/> : null}
                    <TableBody>
                        {
                            data.map((row, index) => {
                                return (
                                    <TableRow
                                        style={onClick ? {cursor: 'pointer'} : null}
                                        key={getKeyFromItem(row)}
                                        hover
                                        onClick={() => onClick ? onClick(index) : null}
                                    >
                                        {
                                            columns.map((column) => {
                                                return (
                                                    <TableCell>
                                                        {row[column.key]}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Grid>
            :
            <Fragment>
                {titleBorder ? <Grid item xs={12}><Border/></Grid> : null}
                {data.map((item, index) => {
                    return (
                        <Grid item xs={12} key={getKeyFromItem(item)}>
                            {renderItem(item, index)}
                        </Grid>
                    )
                })}
            </Fragment>
    )
};

PaginatedListView.defaultProps = {
    variant: PaginatedListView.ROW
};

PaginatedListView.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
            key: PropTypes.string.isRequired
        })
    ).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
                PropTypes.number,
                PropTypes.object,
            ]))
    ).isRequired,
    getKeyFromItem: PropTypes.func,
    onClick: PropTypes.func,
    renderItem: PropTypes.func,
    variant: PropTypes.oneOf(Object.values(PaginatedListVariant)),
    titleBorder: PropTypes.bool
};

export default PaginatedListView;