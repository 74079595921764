import React from 'react';
import PropTypes from 'prop-types';
import {DialogSize} from "../../enums/DialogSize";
import TextFieldDialog from "../Dialog/TextFieldDialog";
import Text from "worksmith/components/Text/Text.web";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";

const EditComplianceModalView = (props) => {
    const {open, onCloseModal, onAccept, title, isCurrentlyCompliant, selectedCompliance, setNewComplianceStatus, slaActivities} = props;

    let description = <div>
        <Text>Changing the status of a request or task will overwrite the compliance rates</Text>
        {
            slaActivities.map(activity =>
            <div style={{paddingTop: '12px', paddingBottom: '12px'}}>
                <Text light> {moment(activity.createdTimestamp).format(MomentFormat.MonthDayYearSlash)} - {activity.user.displayName} - {activity.activity}</Text>
                <Text italic> {activity.notes}</Text>
            </div>
            )
        }


    </div>;

    return (
        <TextFieldDialog title={title}
                         description={description}
                         open={open}
                         onAccept={onAccept}
                         onCancel={onCloseModal}
                         acceptText={"UPDATE STATUS"}
                         cancelText={"CLOSE"}
                         acceptDisabled={selectedCompliance === null}
                         modalSize={DialogSize.MEDIUM}
                         textFieldPlaceholder={"Leave a message to client about the change of status"}
                         textFieldRows={7}>

            <RadioGroup value={selectedCompliance} onChange={(event, value) => setNewComplianceStatus(event, value)} style={{marginTop: '24px'}}>
                <FormControlLabel value={!isCurrentlyCompliant} control={<Radio/>}
                                  label={"Change to " + (isCurrentlyCompliant ? "noncompliant" : "compliant")}/>
                <FormControlLabel value={isCurrentlyCompliant}
                                  control={<Radio/>} label={"Leave " + (isCurrentlyCompliant ? "compliant" : "noncompliant")}/>
            </RadioGroup>

        </TextFieldDialog>
    )
};

EditComplianceModalView.propTypes = {
    loading: PropTypes.bool,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func,
    setNewComplianceStatus: PropTypes.func,
    isCurrentlyCompliant: PropTypes.bool,
    selectedCompliance: PropTypes.bool,
    slaActivities: PropTypes.arrayOf(PropTypes.object)
};

export default EditComplianceModalView