import moment from 'moment/moment'


export const ServiceLines = {
    // The test client's selectable service lines will be set to this list of service lines via the "updateClientSettings" test
    HANDYMAN: '3',
    PAINTING: '6',
    ELECTRICAL: '7',
    FIRE_AND_SAFETY: '16',
    DOOR_REPAIR: '24',
};

export const Locations = {
    ALHAMBRA: "8941",
    BEVERLY_CENTER: "8958",
    SALINAS: "8953"
}

export const Usernames = {
    RANDOM: `${(Math.random() * 1000000).toFixed()}-${(Math.random() * 1000).toFixed()}@yogaworks.com`, // here in case MM_DD has already been used
    MM_DD: `${moment().format('MM.DD')}@yogaworks.com`,
    MINISO: `worksmith+miniso@worksmith.com`,
    PROTEGIS: "protegis@worksmith.com",
    RCHANDYMAN: `rchandyman1services@gmail.com`,
    PRISTINE: "vf@pristinenational.com",
}

export const VendorIds = {
    NATIONAL_TRADE_SERVICES: 7517,
    PRISTINE: 6102,
    PROTEGIS: 4272,
    DAYLIGHT_ELECTRIC: 2779,
    RCHANDYMAN: 4525
}

export const Components = {
    REQUEST_CARD: "RequestCard",
    SELECT: "Select"
}

export const Labels = {
    LOCATION: 'Location',
    SERVICE_LINE: 'Service Line',
    ISSUE_LOCATION: 'Where is the issue located? *',
    TIME_PREFERENCE: 'Time Preference',
    ADD_REGIONAL_MANAGER: 'Add as regional manager',
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    USERNAME: 'Username',
    COUNTRY_CODE: 'Country code',
    EMAIL: 'Email'
}

export const Text = {
    CONTINUE_TO_REQUEST: 'CONTINUE TO REQUEST',
    DECLINE: "Decline",
    PENDING: "pending",
    BIDS: "Bids",
    WALKTHROUGHS: "Walkthroughs",
    ESTIMATES: "Estimates",
    REQUEST_SUBMITTED: 'Request Submitted',
    SUCCESS: 'Success!'
}

export const TestIds = {
    EMERGENCY: 'emergency-request',
    ON_DEMAND: 'on-demand-request',
    RECURRING: 'recurring-request',
    BID_ACTION_BUTTON: 'bidDetailsActionButton',
    CORPORATE_BID_REVIEW_ACTION_BUTTON: 'corporateBidReviewActionButton',
    WT_ACTION_BUTTON: 'walkthroughActionButton',
    DECLINE_WT_TEXT_AREA: 'declineWtTextArea'
}

export const ElementTypes = {
    BUTTON: 'button[type="button"]',
    BUTTON_SUBMIT: 'button[type="submit"]',
    TEXT_AREA_DESCRIPTION: 'textarea[name=description]',
    FILE_UPLOAD: 'input[type=file]',
    SELECT_TIME: 'select[name=timePreference]',
    USERNAME: 'input[name=username]',
    PASSWORD: 'input[type="password"]',
    EXPANSION_PANEL: 'div[role="button"]'
}

export const ButtonText = {
    SUBMIT: "Submit",
    SUBMIT_REQUEST: "Submit Request",
    SAVE_USER: "Save User",
    ACCEPT: "Accept",
    ACTIONS: "ACTIONS",
    SELECT: "Select",
    RELEASE_TO_MARKET: "Release To Market",
    YES_RELEASE: "Yes, Release"
}

export const Frequency = {
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month'
}

export const TimePreference = {
    BEFORE: 'Before Business Hours',
    DURING: 'During Business Hours',
    AFTER: 'After Business Hours'
}

export const RequestTypes = {
    ON_DEMAND: "on-demand",
    RECURRING: "recurring"
}