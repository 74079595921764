import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Button, {ButtonVariant} from "worksmith/components/Button/Button.web";
import JSZip from "jszip";
import axios from "axios";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import moment from "moment";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";

const download = (zip, filename) => {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:application/zip; base64,' + zip;
    hiddenElement.target = '_blank';
    hiddenElement.download = filename+'.zip';
    hiddenElement.click();
};

const buildFolderName = (requestId, obligationId) => {
    let toReturn = 'Completion_Details';
    if(ValueIsSet(requestId)){
        toReturn += "_"+requestId;
    }
    if(ValueIsSet(obligationId)){
        toReturn += "_"+obligationId;
    }
    return toReturn;
};

const buildCompletionDetails = (leadTechnician, summaryOfWorkCompleted, updatedArrivalTime) => {
    let toReturn = '';
    if(ValueIsSet(updatedArrivalTime)){
        toReturn += "Arrival time: "+moment(updatedArrivalTime, MomentFormat.HoursMinutes).format(MomentFormat.StandardTime)+"\n";
    }
    if(ValueIsSet(leadTechnician)){
        toReturn += "Lead Technician: "+leadTechnician+"\n";
    }
    if(ValueIsSet(summaryOfWorkCompleted)){
        toReturn += "Summary of Work Completed: "+summaryOfWorkCompleted+"\n";
    } else toReturn += "Summary of Work Completed: none provided by the vendor\n";

    return toReturn;
};

//We now use a backend endpoint to handle completion details download for clients: /getJobCompletionDownload
const downloadCompletionDetailsZip = (obligationId, requestId, leadTechnician, obligationAttachments, summaryOfWorkCompleted, updatedArrivalTime) => {
    let zip = new JSZip();
    let zipRoot = zip.folder(buildFolderName(requestId, obligationId));
    if(ValueIsSet(leadTechnician) || ValueIsSet(summaryOfWorkCompleted) || ValueIsSet(updatedArrivalTime)) {
        zipRoot.file("CompletionDetails.txt", buildCompletionDetails(leadTechnician, summaryOfWorkCompleted, updatedArrivalTime));
    }
    let img = zipRoot.folder("attachments");
    const s3Files = obligationAttachments.map(attachment => {
        return axios({
            url: attachment.singleUseUrl,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                'Cache-Control': 'no-cache'
            }
        }).then((res) => {
            img.file(attachment.name, res.data);
        });
    });
    Promise.all(s3Files).then(() => {
        zip.generateAsync({type:"base64"}).then((zipResult) => {
            download(zipResult, buildFolderName(requestId, obligationId));
        });
    });
};

const DownloadCompletionDetailsButton = (props) => {
    let {obligationId, requestId, leadTechnician, obligationAttachments, summaryOfWorkCompleted, updatedArrivalTime} = props;

    return (
        <Fragment>
            <Button primary
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => downloadCompletionDetailsZip(obligationId, requestId, leadTechnician, obligationAttachments, summaryOfWorkCompleted, updatedArrivalTime)}>
                Download
            </Button>
        </Fragment>
    )
};

DownloadCompletionDetailsButton.propTypes = {
        obligationId: PropTypes.number,
        leadTechnician: PropTypes.string,
        obligationAttachments: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            singleUseUrl: PropTypes.string
        })),
        requestId: PropTypes.number,
        summaryOfWorkCompleted: PropTypes.string,
        updatedArrivalTime: CustomPropTypes.moment
};

export default DownloadCompletionDetailsButton;