import React from 'react';
import PropTypes from 'prop-types';
import Text from "worksmith/components/Text/Text";
import styled from 'styled-components';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Box from "@mui/material/Box";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";

const UserTypeTitle = styled(Text)`
    padding-top: 1em;
`;

const UserContainer = styled.div`
    padding-bottom: 1em;
`;



const ClientContactPhoneNumbersView = (props) => {
    const {locationPhone, locationEmail, adminUsers, regionalUsers, localUsers, displayEmailAddresses, isForNonWorksmithClient, clientId, linkToClientPage} = props;

    let getUserDisplayComponent = (user) => {
        return <UserContainer>
            <Text>{user.name}</Text>
            <Text>{user.phone}</Text>
            {displayEmailAddresses ?
                <Text>{user.email}</Text>
                :
                null
            }
        </UserContainer>;
    };

    let showAdminUsers = regionalUsers.length === 0 && localUsers.length === 0;
    let noNumbers = !ValueIsSet(locationPhone) && adminUsers.length === 0 && regionalUsers.length === 0 && localUsers.length === 0;

    if (noNumbers) {
        return (
            <Box sx={'padding: 10px 0px'}>
                <Text>No phone numbers available for this client.</Text>
            </Box>
        );
    } else {
        return (
            <div>
                {locationPhone !== null &&
                <UserContainer>
                    <Text semiBold>Location Phone Number</Text>
                    <Text>{locationPhone}</Text>
                    {
                        displayEmailAddresses && ValueIsSet(locationEmail) ?
                        <Text>{locationEmail}</Text>
                            :
                            null
                    }
                </UserContainer>
                }
                {showAdminUsers ?
                    <div>
                        {
                            adminUsers.length !== 0 &&
                            <UserTypeTitle semiBold>Admin Users</UserTypeTitle>
                        }
                        {
                            adminUsers.map((user) =>
                                getUserDisplayComponent(user)
                            )
                        }
                    </div>
                    :
                    <div>
                        {localUsers.length !== 0 && <div>
                            <UserTypeTitle semiBold>Local Users</UserTypeTitle>
                            {
                                localUsers.map((user) =>
                                    getUserDisplayComponent(user)
                                )
                            }
                        </div>}
                        {regionalUsers.length !== 0 && <div>
                            <UserTypeTitle semiBold>Regional Users</UserTypeTitle>
                            {
                                regionalUsers.map((user) =>
                                    getUserDisplayComponent(user)
                                )
                            }
                        </div>}
                    </div>
                }
                {
                    isForNonWorksmithClient ?
                        <Button variant={ButtonVariant.OUTLINED}
                                onClick={() => linkToClientPage(clientId)}>
                            <Icon name={IconType.ADD}/>
                            <Text> ADD NEW USER </Text>
                        </Button>
                        :
                        null
                }
            </div>
        );
    }
    ;
}
ClientContactPhoneNumbersView.propTypes = {
    locationPhone: PropTypes.string.isRequired,
    locationEmail: PropTypes.string,
    adminUsers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            phone: PropTypes.string
        })
    ),
    regionalUsers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            phone: PropTypes.string
        })
    ),
    localUsers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            phone: PropTypes.string
        })
    ),
    displayEmailAddresses: PropTypes.bool,
    isForNonWorksmithClient: PropTypes.bool,
    clientId: PropTypes.number,
    linkToClientPage: PropTypes.func
};

export default ClientContactPhoneNumbersView;