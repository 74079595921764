import React from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import {AlignItems, Display} from "../../enums/CSSEnums";
import Text, {TextDisplay, TextVariant} from "../Text/Text";
import styled from "styled-components";
import {Color} from "../../enums/Color";

const AlertCircle = styled.div`
    display: ${Display.INLINE_BLOCK};
    width: 7px;
    height: 7px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${Color.RED};
`;

const Alert = (props) => {
    const {count, hideAlertCircle, label} = props;

    return (
        <Box
            display={Display.INLINE_FLEX}
            alignItems={AlignItems.CENTER}>
            {
                hideAlertCircle ?
                    null
                    :
                    <AlertCircle/>
            }
            <Text variant={TextVariant.BODY_1} display={TextDisplay.INLINE}>
                {
                    count ?
                        count + ' ' + label
                        :
                        label
                }
            </Text>
        </Box>
    )
};

Alert.defaultProps = {
    hideAlertCircle: false
}

Alert.propTypes = {
    count: PropTypes.number.isRequired,
    hideAlertCircle: PropTypes.bool,
    label: PropTypes.string.isRequired
};

export default Alert;