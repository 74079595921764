import React, {Component, Fragment} from 'react';
import {Select as AntdSelect} from 'antd';
import PropTypes from 'prop-types';

import PropTypeError from "worksmith/custom-prop-types/CustomPropTypes";

const Option = AntdSelect.Option;

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionComponents: []
        };
    }

    componentDidMount = () => {
         let optionComponents= this.getOptionsFromArray(this.props.options);
         this.setState({optionComponents});
    };

    shouldComponentUpdate = (nextProps) => {
        if (nextProps.options !== this.props.options)
           this.state.optionComponents = this.getOptionsFromArray(nextProps.options);

        return true;
    };

    getOptionsFromArray = (options) => {
        let {valueFieldName, optionFieldName, optionIsDisabled} = this.props;
        return options.map((option, index) =>
            <Option
                value={option[valueFieldName]}
                disabled={optionIsDisabled ? optionIsDisabled(option) : false}
                key={index}
            >
                {option[optionFieldName]}
            </Option>);
    };


    render() {
        let {props} = this;
        let {optionComponents} = this.state;
        let {label} = this.props;

        return(
            <Fragment>
                {label ? <div>{label}</div> : null}
                <AntdSelect {...props}>
                    {optionComponents}
                </AntdSelect>
            </Fragment>
        )
    }
}

Select.defaultProps = {
    valueFieldName: 'value',
    optionFieldName: 'option'
};

Select.propTypes = {
    options: PropTypes.array.isRequired,
    valueFieldName: function(props, propName, componentName) {
        if(props.options.size !== 0 && (props.options[0])[props[propName]] === undefined && typeof props[propName] !== "string" ) {
            return new PropTypeError(propName, componentName,propName + ' does not exist in options object.')
        }
    },
    optionFieldName: function(props, propName, componentName) {
        if(props.options.size !== 0 && props.options[0][props[propName]] === undefined && typeof props[propName] !== "string" ) {
            return new PropTypeError(propName, componentName,propName + ' does not exist in options object.')
        }
    },
    onSelect: PropTypes.func.isRequired,
    optionIsDisabled: PropTypes.func,
    label: PropTypes.string
};

export default Select;
