import React, {useState} from "react";
import PropTypes from "prop-types";
import Lightbox from "yet-another-react-lightbox";
import 'yet-another-react-lightbox/dist/styles.css';
import {Inline, Zoom} from "yet-another-react-lightbox/dist/plugins";
import Video from "yet-another-react-lightbox/dist/plugins/video";

import Button, {ButtonSize} from "worksmith/components/Button/Button";
import Download from 'worksmith/components/Lightbox/Plugins/Download';
import Icon from "worksmith/components/Icon/Icon";
import IconButton, {IconButtonSize} from "worksmith/components/Button/IconButton";
import {Color, FunctionalColor} from 'worksmith/enums/Color';
import {IconType} from "worksmith/enums/MaterialEnums";
import {
    createLightBoxSlidesFromAttachments,
    downloadAttachment,
    LightBoxFileTypes,
    renderLightBoxSlideThatAreNotImagesOrVideos
} from "worksmith/components/Lightbox/LightBoxHelpers";


const CarouselInlineWithLightBox = ({attachments}) => {
    const [slideIndex, setSlideIndex] = useState(-1);

    const attachmentSlides = createLightBoxSlidesFromAttachments(attachments);

    const renderCarouselSlides = () => {
        return (
            {
                slide: ({slideNumber, type, src, width, height, title}, offset, rect) => {
                    return type === LightBoxFileTypes.YOUTUBE ? (
                        <iframe
                            width={Math.min(
                                width,
                                rect.width,
                                (width * rect.height) / height
                            )}
                            height={Math.min(
                                height,
                                rect.height,
                                (height * rect.width) / width
                            )}
                            src={src}
                            title={title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    ) : type === LightBoxFileTypes.NON_IMAGE_OR_VIDEO_FILE ? (
                        <Button primary
                                size={ButtonSize.MEDIUM}
                                endIcon={<Icon name={IconType.GET_APP}/>}
                                onClick={() => downloadAttachment(src, title)}>
                            {title}
                        </Button>
                    ) : type === LightBoxFileTypes.IMAGE ? (
                        <>
                            <object data={src} type={"image/png"} className={'yarl__slide_image'} onClick={() => setSlideIndex(slideNumber)} style={{cursor: 'pointer'}}>
                                <IconButton iconName={IconType.IMAGE_NOT_SUPPORTED} size={IconButtonSize.LARGE} iconColor={FunctionalColor.RED} onClick={() => setSlideIndex(slideNumber)}/>
                            </object>
                        </>
                    ) : null;
                }
            }
        )
    };

    return (
        <>
            {/*Carousel created from a Lightbox using the inline plugin. */}
            <Lightbox
                styles={{ container: { backgroundColor: `${Color.WHITE}` }, button: {color: 'black', filter: 'contrast(0.1)'} }}
                on={{ click: (index) => setSlideIndex(index)}}
                slides={attachmentSlides}
                plugins={[Video, Inline]}
                inline={{
                    style: {
                        width: "100%",
                        aspectRatio: "3 / 2"
                    }
                }}
                render={renderCarouselSlides()}
            />
            <Lightbox
                styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
                open={slideIndex >= 0 }
                close={() => setSlideIndex(-1)}
                index={slideIndex}
                slides={attachmentSlides}
                plugins={[Video, Zoom, Download]}
                render={renderLightBoxSlideThatAreNotImagesOrVideos()}
            />
        </>
    );
};

CarouselInlineWithLightBox.propTypes = {
    attachments: PropTypes.arrayOf(PropTypes.shape({
        fileName: PropTypes.string,
        singleUseUrl: PropTypes.string,
    })),
};

export default CarouselInlineWithLightBox;