import React from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RequestStateLayout from "../RequestStateLayout";
import {TitleCase} from "../../../../helpers/LanguageHelper";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import ScheduleStatus from "../../../../enums/api/proposal/ScheduleStatus";
import SchedulingClientSubmittedAvailability from "./SchedulingClientSubmittedAvailability";
import SchedulingVendorSubmittedAvailability from "./SchedulingVendorSubmittedAvailability";
import SchedulingElapsedView from "./SchedulingElapsedView";
import SchedulingDeferredView from "./SchedulingDeferredView";
import UserAffiliationType from "../../../../enums/api/user/UserAffiliationType";

// KEEP IN MIND THAT THIS VIEW IS USED FOR WALKTHROUGH DETAILS
const SchedulingView = (props) => {
    const {
        acceptedBy,
        acceptSchedule,
        duration,
        frequency,
        loading,
        proposeSchedules,
        scheduleHandler,
        vendorLocation,
        useTicketing,
        user
    } = props;


    if (loading) {
        return (
            <RequestStateLayout
                status={TitleCase(NewRequestStatus.SCHEDULING)} loading={true}/>
        )
    }

    let hasOpenSchedules = scheduleHandler.openScheduleOptions.length > 0;
    let hasActiveSchedules = scheduleHandler.openScheduleOptions.filter(schedule => !schedule.isExpired).length > 0;
    let scheduleState = hasOpenSchedules ? scheduleHandler.openScheduleOptions[0].status : null;

    if (!hasOpenSchedules) {
        // return Scheduling Deferred View
        return (
            <SchedulingDeferredView
                acceptedBy={acceptedBy}
                duration={duration}
                frequency={frequency}
                proposeSchedules={proposeSchedules}
                scheduleHandler={scheduleHandler}
                user={user}
                useTicketing={useTicketing}
                vendorLocation={vendorLocation}/>
        );
    } else if (!hasActiveSchedules) {
        // return Schedules Elapsed
        return (
            <SchedulingElapsedView
                acceptSchedule={acceptSchedule}
                duration={duration}
                frequency={frequency}
                proposeSchedules={proposeSchedules}
                scheduleHandler={scheduleHandler}
                useTicketing={useTicketing}
                vendorLocation={vendorLocation}/>
        );
    } else if (hasActiveSchedules && scheduleState === ScheduleStatus.NEEDS_CLIENT) {
        // return Vendor Submitted Availability
        return (
            <SchedulingVendorSubmittedAvailability
                acceptSchedule={acceptSchedule}
                duration={duration}
                frequency={frequency}
                proposeSchedules={proposeSchedules}
                scheduleHandler={scheduleHandler}
                useTicketing={useTicketing}/>
        );
    } else if (hasActiveSchedules && scheduleState === ScheduleStatus.NEEDS_VENDOR) {
        // The client has submitted availability and is waiting for the Vendor Response
        return (
            <SchedulingClientSubmittedAvailability
                scheduleHandler={scheduleHandler}/>
        );
    } else {
       // This case should never be hit according to our business rules around scheduling
       return (
           <RequestStateLayout
               status={TitleCase(NewRequestStatus.SCHEDULING)}/>
       )
    }


}

SchedulingView.propTypes = {
    acceptedBy: PropTypes.shape({
        id: PropTypes.number,
        displayName: PropTypes.string,
        affiliationType: CustomPropTypes.enum(UserAffiliationType)
    }),
    acceptSchedule: PropTypes.func,
    duration: PropTypes.number,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    loading: PropTypes.bool,
    proposeSchedules: PropTypes.func,
    scheduleHandler: PropTypes.shape({
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        })),
    }),
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
    user: PropTypes.shape({
        id: PropTypes.number,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
    })
};

export default SchedulingView;