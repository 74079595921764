import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useMediaQuery, useTheme} from "@mui/material";

import Text, {TextVariant} from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import LineItemBreakdownList from "worksmith/components/LineItem/LineItemBreakdownList";
import Rating from "worksmith/components/Rating/Rating";
import Currency from "worksmith/components/Currency/Currency.web";
import {TextAlign} from "worksmith/enums/CSSEnums";
import BreakpointEnum from "worksmith/enums/BreakpointEnum";
import {withTheme} from "../Theme/ThemeProvider";

const Spacing = styled.div`
    margin: 10px;
`;

const ChangeOrderRequestReviewView = (props) => {
    const {additionalApproval, hideTitle, hideVendorInformation, lineItems, scopeOfWork, vendor} = props;

    const totalRetail = lineItems.reduce((total, nextItem) => total + (nextItem.unitRetailPrice * nextItem.quantity), 0);
    const totalAdjustedRetail =  additionalApproval ? totalRetail + additionalApproval : totalRetail;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(BreakpointEnum.XS));

    return (
        <Grid container textAlign={TextAlign.LEFT}>
            {
                !hideTitle ?
                    <Fragment>
                        <Grid item xs={12}>
                            <Text variant={TextVariant.H5}>Change Order</Text>
                            <Text variant={TextVariant.CAPTION}>
                                {vendor.nickname} submitted a change order to reflect any additions,
                                omissions, or substitutions to the original scope of work. If approved, this will replace the original
                                scope of work and pricing. Declining will keep the original scope of work and pricing in place.
                            </Text>
                        </Grid>
                        <Spacing/>
                    </Fragment>
                    :
                    null
            }
            <Grid
                container
                item
                xs={12}>
                {
                    !hideVendorInformation ?
                        <Grid item xs={12} sm={6}>
                            <Text variant={TextVariant.H5}> {vendor.nickname} </Text>
                            <Rating showValue value={vendor.rating}/>
                        </Grid>
                        :
                        null
                }
                <Grid item xs={12} sm={6} textAlign={!isSmall && !hideVendorInformation ? TextAlign.RIGHT : TextAlign.LEFT}>
                    <Text variant={TextVariant.SUBTITLE_2}>Total Estimated Price</Text>
                    <Text variant={TextVariant.BODY_2}>
                        <Currency amount={totalRetail}/> {totalAdjustedRetail !== totalRetail ?<Fragment>- <Currency amount={totalAdjustedRetail}/></Fragment> : null}
                    </Text>
                </Grid>
            </Grid>
            <Spacing/>
            <Grid item xs={12}>
                <Text variant={TextVariant.SUBTITLE_2}>Terms</Text>
                <Text variant={TextVariant.BODY_2}>{ValueIsSet(scopeOfWork) ? scopeOfWork : 'No Additional Terms'}</Text>
            </Grid>
            <Spacing/>
            <Grid item xs={12}>
                <Text variant={TextVariant.SUBTITLE_2}>Price breakdown</Text>
                <LineItemBreakdownList additionalApproval={additionalApproval} lineItems={lineItems}/>
            </Grid>
        </Grid>
    )
};

ChangeOrderRequestReviewView.propTypes = {
    additionalApproval: PropTypes.number,
    hideTitle:          PropTypes.bool,
    hideVendorInformation: PropTypes.bool,
    lineItems:          PropTypes.arrayOf(PropTypes.shape({
        lineItemType: PropTypes.shape({
            name: PropTypes.string
        }),
        description: PropTypes.string,
        id: PropTypes.number,
        quantity: PropTypes.number,
        unitRetailPrice: PropTypes.number
    })).isRequired,
    scopeOfWork:        PropTypes.string,
    vendor:             PropTypes.object
};

export default withTheme(ChangeOrderRequestReviewView);