import React, {Fragment} from 'react';
import moment from "moment";
import PropTypes from 'prop-types';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";
import {AlignItems, FlexDirection} from "../../enums/CSSEnums";
import Grid from "../Grid/Grid.web";
import Text, {TextColor, TextVariant} from "../Text/Text.web";
import {MomentFormat} from "../../enums/MomentFormat";
import {ConvertToDurationString, getRecurringString} from "worksmith/helpers/LanguageHelper";
import Skeleton, {SkeletonVariant} from "../Skeleton/Skeleton";

const ScheduleSkeleton = (props) => {
    let {alignItems, direction, itemSpacing, spacing} = props;

    return (
        <Grid container
              direction={direction}
              alignItems={alignItems}
              spacing={spacing}>
            <Grid item>
                <Grid item container direction={direction} spacing={itemSpacing} alignItems={alignItems}>
                    <Grid item>
                        <Skeleton variant={SkeletonVariant.TEXT} width={'5rem'}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={direction} spacing={itemSpacing} alignItems={alignItems}>
                    <Grid item>
                        <Skeleton variant={SkeletonVariant.TEXT} width={'5rem'}/>
                    </Grid>
                    <Grid item>
                        <Skeleton variant={SkeletonVariant.TEXT} width={'8rem'}/>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item>
                <Skeleton variant={SkeletonVariant.TEXT} width={'5rem'}/>
            </Grid>
        </Grid>
    )
};

ScheduleSkeleton.propTypes = {
    alignItems: CustomPropTypes.enum(AlignItems),
    direction: CustomPropTypes.enum(FlexDirection),
    itemSpacing: PropTypes.number,
    spacing: PropTypes.number,
};

ScheduleSkeleton.defaultProps = {
    direction: FlexDirection.COLUMN,
    spacing: 1
};

export default ScheduleSkeleton;