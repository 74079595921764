import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import Divider, {DividerVariant} from "worksmith/components/Divider/Divider.web";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {InvoiceSearchType} from "./InvoiceSearch";
import {GroupBy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Skeleton, {SkeletonVariant} from "worksmith/components/Skeleton/Skeleton";
import Pagination from "worksmith/components/Pagination/Pagination";
import {JustifyContent} from "worksmith/enums/CSSEnums";
import moment from "moment";
import TotalsSummary from "./TotalsSummary";
import InvoiceSections from "./InvoiceSections";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {DisplayErrorNotification} from "../../../../helpers/SweetAlertHelpers";

const ListHeader = styled.div`
    margin: 2em 2em 2em 4em;
`;

const graphQLService = new GraphQLServiceClass();

export const PastDueRanges = Object.freeze({
    ONE_TO_THIRTY: '1 - 30 days past due',
    THIRTY_ONE_TO_SIXTY: '31 - 60 days past due',
    SIXTY_ONE_TO_NINETY: '61 - 90 days past due',
    NINETY_ONE_OR_MORE: '91 or more days past due',
});

const Section = styled.div`
    margin: .5em 2em;
`;

class InvoiceOverviewList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            invoiceGroupings: {},
            itemsPerPage: 50,
            loading: true,
            totalItems: 1,
            totalPages: 1,
            totalsSummary: {}
        };
    }

    componentDidMount() {
        this.getStatementItems(1);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.type !== this.props.type
            || prevProps.filterString !== this.props.filterString)
            this.getStatementItems(1);

        if(this.props.type === InvoiceSearchType.PAYMENTS && (
            prevProps.startDate !== this.props.startDate ||
                prevProps.endDate !== this.props.endDate))
            this.getStatementItems(1);
    }

    getStatementItems = (page) => {
        const {setGroupings} = this;
        const {itemsPerPage} = this.state;
        const {clientId, endDate, filterString, startDate, type} = this.props;
        let searchParams = {
            clientId,
            endDate: type === InvoiceSearchType.PAYMENTS && ValueIsSet(endDate) ? endDate.format('YYYY-MM-DD') : null,
            filterString,
            isPayment: null,
            isUnpaid: null,
            isPastDue: null,
            startDate: type === InvoiceSearchType.PAYMENTS && ValueIsSet(startDate) ? startDate.format('YYYY-MM-DD') : null
        };

        switch(type){
            case InvoiceSearchType.ALL_UNPAID:
                searchParams.isUnpaid = true;
                break;
            case InvoiceSearchType.PAST_DUE:
                searchParams.isPastDue = true;
                break;
            case InvoiceSearchType.PAYMENTS:
                searchParams.isPayment = true;
                break;
        }

        graphQLService.findPageWithPageFields(
            page-1,
            itemsPerPage,
            'transactionDate',
            GraphQLSortDirection.ASCENDING,
            searchParams,
            GraphQLObjectType.STATEMENT_ITEM,
            InvoiceOverviewList.statementItemFields,
            InvoiceOverviewList.statementItemPageFields
        ).then(data => {
            const groupings = type === InvoiceSearchType.PAYMENTS ? {payments: GroupBy(data.content,'transactionDate')} : setGroupings(data.content);
            this.setState({currentPage: data.currentPage+1,
                invoiceGroupings: groupings,
                loading: false,
                totalItems: data.totalElements,
                totalPages: data.totalPages,
                totalsSummary: {
                    creditAmount: data.creditAmountTotal,
                    creditOpenBalance: data.creditOpenBalanceTotal,
                    totalAmount: data.totalAmount,
                    totalOpenBalance: data.totalOpenBalance,
                    outstandingAmount: data.outstandingAmountTotal,
                    outstandingOpenBalance: data.outstandingOpenBalanceTotal,
                    oneToThirtyDaysPastDueAmount: data.oneToThirtyDaysPastDueAmountTotal,
                    oneToThirtyDaysPastDueOpenBalance: data.oneToThirtyDaysPastDueOpenBalanceTotal,
                    thirtyOneToSixtyDaysPastDueAmount: data.thirtyOneToSixtyDaysPastDueAmountTotal,
                    thirtyOneToSixtyDaysPastDueOpenBalance: data.thirtyOneToSixtyDaysPastDueOpenBalanceTotal,
                    sixtyOneToNinetyDaysPastDueAmount: data.sixtyOneToNinetyDaysPastDueAmountTotal,
                    sixtyOneToNinetyDaysPastDueOpenBalance: data.sixtyOneToNinetyDaysPastDueOpenBalanceTotal,
                    ninetyOneOrMoreDaysPastDueAmount: data.ninetyOneOrMoreDaysPastDueAmountTotal,
                    ninetyOneOrMoreDaysPastDueOpenBalance: data.ninetyOneOrMoreDaysPastDueOpenBalanceTotal,
                }});
        }).catch(error => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification(error.toString());
        })
    };

    setGroupings = (statementItems) => {
        let groupings = {
            outstanding: [],
            pastDue: {
                '91 or more days past due': [],
                '61 - 90 days past due': [],
                '31 - 60 days past due': [],
                '1 - 30 days past due': []
            }
        };
        statementItems.forEach(statementItem => {
            let dueDate = moment(statementItem.dueDate);
            let daysDifference = moment().diff(dueDate, 'days');
            if(daysDifference <= 0)
                groupings.outstanding.push(statementItem);
            else if(daysDifference > 0 && daysDifference <= 30)
                groupings.pastDue[PastDueRanges.ONE_TO_THIRTY].push(statementItem);
            else if(daysDifference >= 31 && daysDifference <= 60)
                groupings.pastDue[PastDueRanges.THIRTY_ONE_TO_SIXTY].push(statementItem);
            else if(daysDifference >= 61 && daysDifference <= 90)
                groupings.pastDue[PastDueRanges.SIXTY_ONE_TO_NINETY].push(statementItem);
            else
                groupings.pastDue[PastDueRanges.NINETY_ONE_OR_MORE].push(statementItem);
        });

        groupings.outstanding = GroupBy(groupings.outstanding, 'transactionDate');

        return groupings;
    };

    render() {
        const {getStatementItems} = this;
        const {
            currentPage,
            invoiceGroupings,
            itemsPerPage,
            loading,
            totalItems,
            totalPages,
            totalsSummary
        } = this.state;
        const {linkToInvoice, type} = this.props;

        const startingItem       = 1 + (currentPage-1) * itemsPerPage;
        const possibleEndingItem = (currentPage-1) * itemsPerPage + itemsPerPage;
        const endingItem         = possibleEndingItem < totalItems ? possibleEndingItem : totalItems;

        let pastDueSection       = null;
        let outstandingSection   = null;
        let paymentsSection      = null;

        if(!loading) {
            if(ValueIsSet(invoiceGroupings.outstanding) && Object.entries(invoiceGroupings.outstanding).length > 0)
                outstandingSection = <InvoiceSections linkToInvoice={linkToInvoice} sectionTitle={'OUTSTANDING'} sectionGroupings={invoiceGroupings.outstanding}/>;

            if(ValueIsSet(invoiceGroupings.payments) && Object.entries(invoiceGroupings.payments).length !== 0)
                paymentsSection = <InvoiceSections linkToInvoice={linkToInvoice} sectionGroupings={invoiceGroupings.payments}/>;

            if(ValueIsSet(invoiceGroupings.pastDue) &&
                (invoiceGroupings.pastDue[PastDueRanges.ONE_TO_THIRTY].length !== 0 ||
                invoiceGroupings.pastDue[PastDueRanges.THIRTY_ONE_TO_SIXTY].length !== 0 ||
                invoiceGroupings.pastDue[PastDueRanges.SIXTY_ONE_TO_NINETY].length !== 0 ||
                invoiceGroupings.pastDue[PastDueRanges.NINETY_ONE_OR_MORE].length !== 0)) {
                pastDueSection = <InvoiceSections linkToInvoice={linkToInvoice} sectionTitle={'PAST DUE'} sectionGroupings={invoiceGroupings.pastDue}/>;
            }
        }

        return (
            <Fragment>
                {loading ?
                    <Skeleton variant={SkeletonVariant.RECTANGLE} height={'500px'}/>
                    :
                     ValueIsSet(pastDueSection) || ValueIsSet(paymentsSection) || ValueIsSet(outstandingSection) ?
                        <Fragment>
                            <ListHeader>
                                <Grid container>
                                    <Grid item sm={1}>
                                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>
                                            Date
                                        </Text>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>
                                            Transaction Type
                                        </Text>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>
                                            Invoice Number
                                        </Text>
                                    </Grid>
                                    <Grid item sm={1}>
                                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>
                                            Location
                                        </Text>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>
                                            Due Date
                                        </Text>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>
                                            Amount
                                        </Text>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>
                                            Open Balance
                                        </Text>
                                    </Grid>
                                </Grid>
                            </ListHeader>
                            <Divider variant={DividerVariant.FULL_WIDTH}/>
                            <Fragment>
                                {pastDueSection}
                                {outstandingSection}
                                {paymentsSection}
                            </Fragment>

                            {type !== InvoiceSearchType.PAYMENTS ? <TotalsSummary totalsSummary={totalsSummary} type={type}/> : null}

                            <Grid container justify={JustifyContent.FLEX_END}>
                                <Pagination currentPage={currentPage}
                                            onPageChange={getStatementItems}
                                            startingItem={startingItem}
                                            endingItem={endingItem}
                                            totalItems={totalItems}
                                            totalPages={totalPages}/>
                            </Grid>
                        </Fragment>
                         :
                         <Section>
                             <Text variant={TextVariant.H5}>There are no invoices.</Text>
                         </Section>
                }
            </Fragment>
        );
    }
}

InvoiceOverviewList.propTypes = {
    clientId:      CustomPropTypes.stringOrNumber.isRequired,
    endDate:       CustomPropTypes.moment,
    filterString:  PropTypes.string,
    linkToInvoice: PropTypes.func.isRequired,
    startDate:     CustomPropTypes.moment,
    type:          PropTypes.string
};

InvoiceOverviewList.statementItemFields = `
    amount
    dueDate
    id
    openBalance
    receivable {
        clientLocation {
            title
        }
        id
    }
    referenceNumber
    transactionDate
    transactionType
`;

InvoiceOverviewList.statementItemPageFields = `
    creditAmountTotal
    creditOpenBalanceTotal
    outstandingAmountTotal
    outstandingOpenBalanceTotal
    oneToThirtyDaysPastDueAmountTotal
    oneToThirtyDaysPastDueOpenBalanceTotal
    thirtyOneToSixtyDaysPastDueAmountTotal
    thirtyOneToSixtyDaysPastDueOpenBalanceTotal
    sixtyOneToNinetyDaysPastDueAmountTotal
    sixtyOneToNinetyDaysPastDueOpenBalanceTotal
    ninetyOneOrMoreDaysPastDueAmountTotal
    ninetyOneOrMoreDaysPastDueOpenBalanceTotal
    totalAmount
    totalOpenBalance
`;

export default InvoiceOverviewList;