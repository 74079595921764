import React from 'react';
import PropTypes from 'prop-types';

import {AlignItems} from "../../../../enums/CSSEnums";
import Button from "../../../../components/Button/Button";
import CircularProgress from "../../../../components/Loader/CircularProgress";
import Grid from "../../../../components/Grid/Grid";
import Switch from "../../../../components/Switch/Switch";
import Text, {TextColor, TextVariant} from "../../../../components/Text/Text";
import TextField, {TextFieldType} from "../../../../components/Inputs/TextField/TextField";

const InsuranceForm = ({handleChange, insuranceFormFields, isSubmitting, submitInsuranceInfo, toggleEdit, whiteLabelClient}) => {

    return (
        <Grid container alignItems={AlignItems.FLEX_START}>
            <Grid container spacing={3} item xs={12} alignItems={AlignItems.FLEX_START}>
                <Grid xs={12}>
                    <Text gutterBottom variant={TextVariant.H6}>Is union labor required?</Text>
                    <Grid item>
                        <Switch
                            group
                            initialValue={insuranceFormFields.unionLaborRequirement === "ALL_TRADES"}
                            label={<Text variant={TextVariant.BODY_1}>Yes, for all trades</Text>}
                            onChange={() => handleChange('unionLaborRequirement', "ALL_TRADES")}
                            secondary
                        />
                    </Grid>
                    <Grid item>
                        <Switch
                            group
                            initialValue={insuranceFormFields.unionLaborRequirement === "MAJOR_TRADES_ONLY"}
                            label={ // first <Text> allows for label to align correctly
                                <>
                                    <Text variant={TextVariant.BODY_1}>{' '}</Text>
                                    <Text variant={TextVariant.BODY_1}>Yes, but only for major trades</Text>
                                    <Text variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY}>HVAC, electrical work, plumbing</Text>
                                </>
                            }
                            onChange={() => handleChange('unionLaborRequirement', "MAJOR_TRADES_ONLY")}
                            secondary
                        />
                    </Grid>
                    <Grid item>
                        <Switch
                            group
                            initialValue={insuranceFormFields.unionLaborRequirement === "NONE"}
                            label={<Text variant={TextVariant.BODY_1}>No, union labor is not required</Text>}
                            onChange={() => handleChange('unionLaborRequirement', "NONE")}
                            secondary
                        />
                    </Grid>
                </Grid>
                {!whiteLabelClient ?
                    <Grid container spacing={3} xs={12} md={8} margin={'16px 0 0 0'}>
                        <Grid item xs={12}>
                            <Text gutterBottom variant={TextVariant.H6}>Insurance Requirements</Text>
                            <Text gutterBottom variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY}>Note that
                                all Worksmith vendors must meet minimum COI requirements of $1M each occurrence, $2M
                                general aggregate.</Text>
                        </Grid>
                        <Grid container item xs={12} spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    initialValue={insuranceFormFields.occurrenceInsuranceRequirement}
                                    label={'Per Occurrence Amount'}
                                    name={'occurrenceInsuranceRequirement'}
                                    onChange={value => handleChange('occurrenceInsuranceRequirement', Number(value))}
                                    startAdornment={'$'}
                                    type={TextFieldType.NUMBER}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    initialValue={insuranceFormFields.aggregateInsuranceRequirement}
                                    label={'General Aggregate Amount'}
                                    name={'aggregateInsuranceRequirement'}
                                    onChange={value => handleChange('aggregateInsuranceRequirement', Number(value))}
                                    startAdornment={'$'}
                                    type={TextFieldType.NUMBER}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    initialValue={insuranceFormFields.additionalInsuranceDetails}
                                    label={'Other Insurance Information'}
                                    name={'additionalInsuranceDetails'}
                                    onChange={value => handleChange('additionalInsuranceDetails', value)}
                                    type={TextFieldType.TEXT}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    null
                }
            </Grid>
            <Grid container item xs={8} margin={'32px 0 0 0'}>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} onClick={toggleEdit}>CANCEL</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} primary onClick={submitInsuranceInfo}>{isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default InsuranceForm;

InsuranceForm.defaultProps = {
    whiteLabelClient: false
};

InsuranceForm.propTypes = {
    insuranceFormFields: PropTypes.shape({
        unionLaborRequirement: PropTypes.string,
        occurrenceInsuranceRequirement: PropTypes.number,
        aggregateInsuranceRequirement: PropTypes.number,
        additionalInsuranceDetails: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    submitInsuranceInfo: PropTypes.func,
    toggleEdit: PropTypes.func,
    whiteLabelClient: PropTypes.bool
}
