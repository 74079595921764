import React from 'react';
import PropTypes from 'prop-types';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Text from "worksmith/components/Text/Text";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import {withTheme} from "worksmith/components/Theme/ThemeProvider";
import ChangeOrderRequestService from "worksmith/services/api/ChangeOrderRequestService";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";

const changeOrderRequestService = new ChangeOrderRequestService();

const ChangeOrderRequestCancelModal = (props) => {
    const {changeOrderRequestId, open, onClose, reloadPage} = props;

    const cancelChangeOrder = () => {

        changeOrderRequestService.cancel(changeOrderRequestId).then(() => {
            if(ValueIsSet(reloadPage)){
                reloadPage()
            }
            // noinspection JSIgnoredPromiseFromCall
            DisplaySuccessNotification('Change Order Canceled!');
        }).catch((error) => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification('Error canceling change order: ' + error.toString());
        });
    }
    const submitCancelChangeOrder = () => {
        onClose();
        cancelChangeOrder();
    }

    return (
                        <AlertDialog
                            acceptText={"Yes, Cancel"}
                            cancelText={"No, Go Back"}
                            children={<Text>This change order request will be canceled and the price for the work will include only the original price.</Text>}
                            open={open}
                            onCancel={() => onClose()}
                            onAccept={() => {submitCancelChangeOrder()}}
                            title={'Cancel Change Order?'}>
                        </AlertDialog>
    )
};

ChangeOrderRequestCancelModal.propTypes = {
    changeOrderRequestId: PropTypes.number,
    open:                 PropTypes.bool,
    onClose:              PropTypes.func,
    reloadPage:           PropTypes.func
};

export default withTheme(ChangeOrderRequestCancelModal);