import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AsyncGraphQLServiceClass from "../../../../services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import RequestForProposalService from "../../../../services/api/RequestForProposalService";
import StoreUserCorporateReviewView from "./StoreUserCorporateReviewView";
import AdminCorporateReviewView from "./AdminCorporateReviewView";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";

const graphQLServiceClass = new AsyncGraphQLServiceClass();
const requestService = new RequestForProposalService();

class CorporateReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null
        };
    }

    async componentDidMount() {
        const {canApprove, requestId} = this.props;

        if (canApprove && requestId) {
            let data = await this.getRequestFromId(requestId);
            this.setState({loading: false, request: data});
        } else {
            this.setState({loading: false});
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            newState.request = DeepCopy(props.request);
        }

        return newState;
    };

    getRequestFromId = async (requestId) => {

        return await graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            CorporateReviewGraphQLString)
    };

    approveRequest = () => {
        const {request} = this.state;
        const {onApprove, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        requestService.approve(request.id)
            .then(() => {
                onApprove();
            })
    };

    render() {
        const {approveRequest} = this;
        const {request, loading} = this.state;
        const {canApprove, onCancel} = this.props;

        if (canApprove) {
            return (
                <AdminCorporateReviewView
                    loading={loading || this.props.loading}
                    approveRequest={approveRequest}
                    onCancel={onCancel}
                    request={request}/>
            )
        } else {
            return (
                <StoreUserCorporateReviewView loading={loading || this.props.loading}/>
            )
        }
    }
}

CorporateReview.propTypes = {
    canApprove: PropTypes.bool,
    onCancel: PropTypes.func,
    onApprove: PropTypes.func,
    loading: PropTypes.bool,
    request: PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        creator: PropTypes.shape({
            displayName: PropTypes.string
        })
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func,
};

const CorporateReviewGraphQLString = `
    createdTimestamp
    creator {
        affiliationType
        displayName
    }
    id
`;

export default CorporateReview;