import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LineItemFieldsCardView from "worksmith/components/LineItem/LineItemForm/LineItemFieldsCardView";
import {TextColor, TextVariant} from "worksmith/components/Text/Text";
import Text from "worksmith/components/Text/Text";
import Currency from "worksmith/components/Currency/Currency";
import {TextAlign} from "worksmith/enums/CSSEnums";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import TextField, {TextFieldType} from 'worksmith/components/Inputs/TextField/TextField';
import {lineItemSort, ValueOrDefault} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const AdditionalApproval = styled.div`
    margin: 1em;
`;

const CardSpacing = styled.div`
    margin: .7em 0;
`;

const LineItems = styled.div`
    margin: 1em;
    max-width: 860px;
`;

const TotalPricing = styled.div`
    margin: 1em;
`;

const LineItemFieldsGroupView = (props) => {

    const {
        additionalApproval,
        allowAdditionalApproval,
        effectiveWholesaleRate,
        errors,
        initialLineItems,
        lineItemTypes,
        onAdd,
        onAdditionalApprovalChange,
        onLineItemChange,
        onRemove,
        portalType,
        totalRetailPrice,
        totalWholesalePrice,
    } = props;

    const totalAdjustedRetailPrice    = Number(totalRetailPrice) + Number(additionalApproval);
    const totalAdjustedWholesalePrice = Number(totalWholesalePrice) + Number(additionalApproval) * Number(effectiveWholesaleRate);

    return (
        <Fragment>
            <Text variant={TextVariant.H6}>Pricing</Text>
            <Text variant={TextVariant.CAPTION}>Create itemized pricing including labor, all materials, and fees
                charged to the client</Text>

            <LineItems>
                {initialLineItems.sort(lineItemSort).map((initialLineItem, index) => <CardSpacing key={ValueOrDefault(initialLineItem.id, index)}>
                    <LineItemFieldsCardView effectiveWholesaleRate={effectiveWholesaleRate}
                                            errors={errors[index]}
                                            index={index}
                                            initialLineItem={initialLineItem}
                                            lineItemTypes={lineItemTypes}
                                            onChange={onLineItemChange}
                                            onRemove={onRemove}
                                            portalType={portalType}
                    />
                </CardSpacing>)}

                <Button variant={ButtonVariant.TEXT} onClick={onAdd} primary>
                    <Icon name={'AddCircleOutline'} left/>
                    <Text variant={TextVariant.SUBTITLE_2}
                          textAlign={TextAlign.CENTER}
                          color={TextColor.PRIMARY}>
                        Add Line Item
                    </Text>
                </Button>
            </LineItems>

            {allowAdditionalApproval &&
                <AdditionalApproval>
                    <Text variant={TextVariant.H6}>Request Additional Approval</Text>
                    <Text variant={TextVariant.CAPTION}>
                        This sets funds aside in case the job incurs additional costs.
                        If these funds are needed, you will be required to itemize and explain any added costs when
                        marking the job complete.
                        The default is $100, but you can request any amount
                    </Text>
                    <div style={{marginTop: '1em'}}>
                        <TextField initialValue={additionalApproval}
                                   label={'Amount'}
                                   startAdornment={'$'}
                                   type={TextFieldType.NUMBER}
                                   onChange={onAdditionalApprovalChange}/>
                        <Text variant={TextVariant.CAPTION}>Wholesale: <Currency amount={additionalApproval * effectiveWholesaleRate}/>
                        </Text>
                    </div>
                </AdditionalApproval>
            }

            <TotalPricing>
                <Grid container>
                    <Grid item xs={12}>
                        <Text variant={TextVariant.SUBTITLE_2}>TOTAL</Text>
                    </Grid>
                    {portalType === PortalType.ADMIN &&
                        <>
                            <Grid item md={2} xs={12}>
                                <Text
                                    variant={TextVariant.SUBTITLE_2}>{totalRetailPrice !== totalAdjustedRetailPrice && 'Estimated'} Retail
                                    Price</Text>
                            </Grid>
                            <Grid item md={10} xs={12}>
                                <Text variant={TextVariant.SUBTITLE_2}>
                                    <Currency amount={totalRetailPrice}/>
                                    {totalRetailPrice !== totalAdjustedRetailPrice &&
                                    <Fragment> - <Currency amount={totalAdjustedRetailPrice}/> </Fragment>}
                                </Text>
                            </Grid>
                        </>
                    }

                    <Grid item md={2} xs={12}>
                        <Text
                            variant={TextVariant.SUBTITLE_2}>{totalWholesalePrice !== totalAdjustedWholesalePrice && 'Estimated '}Wholesale
                            Price</Text>
                    </Grid>
                    <Grid item md={10} xs={12}>
                        <Text variant={TextVariant.SUBTITLE_2}>
                            <Currency amount={totalWholesalePrice}/>
                            {totalWholesalePrice !== totalAdjustedWholesalePrice &&
                            <Fragment> - <Currency amount={totalAdjustedWholesalePrice}/> </Fragment>}
                        </Text>
                    </Grid>
                </Grid>
            </TotalPricing>
        </Fragment>
    )
};

LineItemFieldsGroupView.propTypes = {
    additionalApproval:          CustomPropTypes.stringOrNumber,
    allowAdditionalApproval:     PropTypes.bool.isRequired,
    effectiveWholesaleRate:      PropTypes.number.isRequired,
    errors:                      PropTypes.arrayOf(PropTypes.object),
    initialLineItems:            PropTypes.arrayOf(PropTypes.object).isRequired,
    lineItemTypes:               PropTypes.arrayOf(PropTypes.shape({
                                    id: PropTypes.number.isRequired,
                                    name: PropTypes.string.isRequired
                                 })).isRequired,
    onAdd:                       PropTypes.func.isRequired,
    onAdditionalApprovalChange:  PropTypes.func,
    onLineItemChange:            PropTypes.func,
    onRemove:                    PropTypes.func
};

LineItemFieldsGroupView.defaultProps = {
    additionalApproval: 0.00
};

export default LineItemFieldsGroupView;