import React, {Component} from 'react';
import moment from 'moment';
import ObligationTemplateService from 'worksmith/services/api/ObligationTemplateService';
import ScheduleHandlerService from 'worksmith/services/api/ScheduleHandlerService';
import Button from "../../Button";
import DatePicker from 'antd/lib/date-picker';
import TimePicker from 'antd/lib/time-picker';
import notification from 'antd/lib/notification';
import {Color} from "../../../Enums";

const obligationTemplateService = new ObligationTemplateService();
const scheduleHandlerService = new ScheduleHandlerService();

const t = require('tcomb-form');
const Form = t.form.Form;


class RecurringScheduleChangeRequestForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            obligation: props.obligation,
            obligationTemplate: props.obligationTemplate,
            serviceLineId: props.serviceLineId,
            value: {
                daysOfWeek: {}
            },
            changeRequest: props.changeRequest,
            counter: props.counter, // is this a change request counter form?
            scheduleChangeRequestForm: t.struct({}),
            recurrenceTypeModifier: null,
            newDate: null,
            returnDate: null,
            endTime: null,
            startTime: null,
            loading: false,
            formValid: false
        };

        this.submit = this.submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setForm = this.setForm.bind(this);
        this.onChangeNewDate = this.onChangeNewDate.bind(this);
        this.onChangeStartTime = this.onChangeStartTime.bind(this);
        this.onChangeEndTime = this.onChangeEndTime.bind(this);
        this.onChangeNewReturnDate = this.onChangeNewReturnDate.bind(this);
    }

    onChangeNewDate(date) {
        this.setState({ newDate: date });
    }

    onChangeNewReturnDate(date) {
        this.setState({ returnDate: date });
    }

    onChangeStartTime(time) {
        this.setState({ startTime: time });
    }

    onChangeEndTime(time) {
        this.setState({ endTime: time });
    }

    onChange(value) {
        if(value){
            if(value.recurrenceType !== this.state.value.recurrenceType) {
                let modifier = null;
                if(value.recurrenceType === 'Daily') {
                    modifier = 'days';
                } else if (value.recurrenceType === 'Weekly') {
                    modifier = 'weeks';
                } else if (value.recurrenceType === 'Monthly') {
                    modifier = 'months';
                }

                this.setState({ recurrenceTypeModifier: modifier });

                this.setForm(value);
            }

            let validation = this.refs.form.validate();
            let formValid = validation.errors.length < 1;

            this.setState({ value, formValid });
        }
    }

    setForm(value) {
        var form = {};

        form.recurrenceType = t.enums({
            "Daily": "Daily",
            "Weekly": "Weekly",
            "Monthly": "Monthly"
        });

        const Positive = t.subtype(t.Number, (n) => n > 0);

        if(value.recurrenceType != null) {
            form.repeatEvery = Positive;

            if(value.recurrenceType === 'Weekly') {
                form.daysOfWeek = t.struct({
                    1: t.Boolean,
                    2: t.Boolean,
                    3: t.Boolean,
                    4: t.Boolean,
                    5: t.Boolean,
                    6: t.Boolean,
                    7: t.Boolean,
                });
            } else if (value.recurrenceType === 'Monthly') {
                form.repeatBy =  t.enums({
                    "dayOfMonth": "Day of the month",
                    "dayOfWeek": "Day of the week"
                });
            }
        }

        this.setState({scheduleChangeRequestForm: t.struct(form)});
    }

    componentDidMount() {
        this.setForm(this.state.value);
    }

    submit(){
        let _this = this;
        this.setState({loading: true});

        let {value, changeRequest, counter, endTime, startTime} = this.state;

        let obligationTemplateId = this.state.obligation ? this.state.obligation.obligationTemplateId : this.state.obligationTemplate.id;

        let schedule = {
            date: moment(this.state.newDate).format('YYYY-MM-DD'),
            returnDate: this.state.returnDate ? moment(this.state.returnDate).format('YYYY-MM-DD') : null,
            arrivalEndTime: endTime ? moment(this.state.endTime).minutes(0).format('HH:mm:ss') : null,
            arrivalStartTime: startTime ? moment(this.state.startTime).minutes(0).format('HH:mm:ss') : null,
            type: 'recurring',
            recurrenceType: value.recurrenceType,
            repeatEvery: value.repeatEvery
        };

        let submitData = {
            obligationTemplate: {
                id: obligationTemplateId
            },
            id: counter ? changeRequest.id : null,
            scheduleHandler: {
               scheduleOptions: [schedule]
            },
            type:'time'
        };

        if(value.recurrenceType === 'Weekly') {
            let daysOfWeek = [];
            let keys = Object.keys(value.daysOfWeek);
            for(var i = 0; i < keys.length; i++) {
                let dow = keys[i];
                if(value.daysOfWeek[dow] === true) {
                    daysOfWeek.push({ dayOfWeek: dow });
                }
            }

            schedule.daysOfWeek = daysOfWeek;
        } else if (value.recurrenceType === 'Monthly') {
            schedule.dayOfMonth = value.repeatBy === 'dayOfMonth';
        }

        if(counter){
           scheduleHandlerService.makeCounterOffer({scheduleHandlerId: changeRequest.scheduleHandler.id, schedules: [schedule]}).then(function(data){
                _this.setState({loading: false});
                _this.props.onSuccess();
                notification['success']({
                    message: 'Counter submitted!',
                });
            }).catch(function(error){
                alert("Error submitting change request counter.  Please try again.");
                _this.setState({loading: false});
            });
        } else {
            obligationTemplateService.addChangeRequest(obligationTemplateId, submitData, false, false, false).then(function(data){
                _this.setState({loading: false});
                _this.props.onSuccess();
                notification['success']({
                    message: 'Request for schedule change submitted!',
                });
            }).catch(function(error) {
                alert("Error submitting request for schedule change. Make sure you have entered a new date, a start time, and an end time. Please try again.");
                _this.setState({loading: false});
            });
        }

    }

    render() {
        let {formValid, recurrenceTypeModifier, serviceLineId} = this.state;

        const repeatEveryTemplate = Form.templates.textbox.clone({
            renderInput: (locals) => {
                return (
                    <div className={'row'} style={{'padding-left': '15px'}}>
                        <input className={'col-md-1'} style={{height: '30px', width: '50px'}} {...locals.attrs}/>
                        <div className={'col-md-2'}>{recurrenceTypeModifier}</div>
                    </div>
                )
            }
        });

        const daysOfWeekTemplate = (locals) => {
            return (
                <div>
                    <div className="row">
                        <div className={'col-md-12'}>
                            <label className="control-label">Repeat On</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className={'col-md-1'}>{locals.inputs[1]}</div>
                        <div className={'col-md-1'}>{locals.inputs[2]}</div>
                        <div className={'col-md-1'}>{locals.inputs[3]}</div>
                        <div className={'col-md-1'}>{locals.inputs[4]}</div>
                        <div className={'col-md-1'}>{locals.inputs[5]}</div>
                        <div className={'col-md-1'}>{locals.inputs[6]}</div>
                        <div className={'col-md-1'}>{locals.inputs[7]}</div>
                    </div>
                </div>
            );
        };


        let formOptions = {
            fields: {
                startDate: {
                    mode: 'date',
                    label: "I'd like this change to take effect on",
                    config: {
                        format: function (date) {
                            let formattedDate = moment(date).format("ddd, MMM Do, YYYY");
                            return String(formattedDate)
                        }
                    }
                },
                recurrenceType: {
                    label: "New Frequency"
                },
                repeatEvery: {
                    label: "Repeat every",
                    error: <div style={{color: Color.RED}}>This value should be a positive number</div>,
                    template: repeatEveryTemplate
                },
                daysOfWeek: {
                    template: daysOfWeekTemplate,
                    label: "Repeat on",
                    fields: {
                        7: { label: 'Sun' },
                        1: { label: 'Mon' },
                        2: { label: 'Tue' },
                        3: { label: 'Wed' },
                        4: { label: 'Thu' },
                        5: { label: 'Fri' },
                        6: { label: 'Sat' }
                    }
                },
            }
        };

        function disabledMinutes() {
            let hidden = [];

            for(var i = 0; i < 60; i++ ){
                if(i % 15 !== 0){
                    hidden.push(i);
                }
            }

            return hidden;
        }

        let validateForm = () => {
            if(serviceLineId !== 1){
                if(!this.state.newDate || !this.state.startTime || !this.state.endTime ){
                    return true;
                }
            }

            if(serviceLineId === 1){
                if(!this.state.newDate){
                    return true;
                }
            }

            if(this.state.value.recurrenceType && !this.state.value.repeatEvery){
                return true;
            }

            if(this.state.value.recurrenceType === 'Weekly' && !Object.keys(this.state.value.daysOfWeek).length){
                return true;
            }

            if(this.state.value.recurrenceType === 'Monthly' && !this.state.value.repeatBy){
                return true;
            }
        };


        return (
            <div>
                {this.state.value.recurrenceType && serviceLineId !== 1 ?
                    <div>
                        <div className="form-group">
                            <label>New Date</label><br />
                            <DatePicker
                                size="large"
                                placeholder={' '}
                                value={this.state.newDate}
                                onChange={this.onChangeNewDate} />
                        </div>

                        <div className="form-group">
                            <label>Arrival Window Start</label><br />
                            <TimePicker
                                use12Hours
                                value={this.state.startTime}
                                placeholder={' '}
                                size="large"
                                hideDisabledOptions={true}
                                disabledMinutes={disabledMinutes}
                                format="h:mm A"
                                onChange={this.onChangeStartTime} />
                        </div>
                        <div className="form-group">
                            <label>Arrival Window End</label><br />
                            <TimePicker
                                use12Hours
                                value={this.state.endTime}
                                placeholder={' '}
                                size="large"
                                hideDisabledOptions={true}
                                disabledMinutes={disabledMinutes}
                                format="h:mm A"
                                onChange={this.onChangeEndTime} />
                        </div>
                    </div> : null }

                {this.state.value.recurrenceType && serviceLineId === 1 ?
                    <div>
                        <div className="form-group">
                            <label>Start Date</label><br />
                            <DatePicker
                                size="large"
                                placeholder={' '}
                                value={this.state.newDate}
                                onChange={this.onChangeNewDate} />
                        </div>
                    </div> : null }


                <Form
                    ref="form"
                    type={this.state.scheduleChangeRequestForm}
                    value={this.state.value}
                    onChange={this.onChange}
                    options={formOptions}
                />
                <div className="text-right">
                    <Button
                        type={'primary'}
                        message={"Submit Service Schedule Change Request"}
                        disabled={validateForm() || !formValid || this.state.loading}
                        loading={this.state.loading}
                        onPress={this.submit}/>
                </div>
            </div>
        );
    }
}

export default RecurringScheduleChangeRequestForm;