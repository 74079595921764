import React from 'react';
import Proptypes from "prop-types";
import {ValueIsSet, ValuesAreSet} from "worksmith/helpers/GenericHelpers";
import AutoMarkedCompliantIcon from "worksmith/components/Assets/ComplianceIcons/AutoMarkedCompliantIcon";
import AutoMarkedNoncompliantIcon from "worksmith/components/Assets/ComplianceIcons/AutoMarkedNoncompliantIcon";
import ManuallyMarkedCompliantIcon from "worksmith/components/Assets/ComplianceIcons/ManuallyMarkedCompliantIcon";
import ManuallyMarkedNoncompliantIcon from "worksmith/components/Assets/ComplianceIcons/ManuallyMarkedNoncompliantIcon";

export const ComplianceIconType = {
    AUTO_MARKED_COMPLIANT: "AUTO_COMPLIANT",
    AUTO_MARKED_NONCOMPLIANT: "AUTO_NONCOMPLIANT",
    MANUALLY_MARKED_COMPLIANT: "MANUALLY_MARKED_COMPLIANT",
    MANUALLY_MARKED_NONCOMPLIANT: "MANUALLY_MARKED_NONCOMPLIANT"
};

const ComplianceIcon = (props) => {

    const {complianceIconType, styles, height, width, compliant, resolved} = props;
    let margin = '0 0 0 0';

    if (ValueIsSet(props.margin)) {
        margin = props.margin;
    }

    const autoMarkedCompliantIcon = <AutoMarkedCompliantIcon styles={styles} height={height} width={width} margin={margin}/>;
    const autoMarkedNoncompliantIcon = <AutoMarkedNoncompliantIcon styles={styles} height={height} width={width} margin={margin}/>;
    const manuallyMarkedCompliantIcon = <ManuallyMarkedCompliantIcon styles={styles} height={height} width={width} margin={margin}/>;
    const manuallyMarkedNoncompliantIcon = <ManuallyMarkedNoncompliantIcon styles={styles} height={height} width={width} margin={margin}/>;

    if(ValueIsSet(complianceIconType))
        switch (complianceIconType) {
            case ComplianceIconType.AUTO_MARKED_COMPLIANT:
                return autoMarkedCompliantIcon;
            case ComplianceIconType.AUTO_MARKED_NONCOMPLIANT:
                return autoMarkedNoncompliantIcon;
            case ComplianceIconType.MANUALLY_MARKED_COMPLIANT:
                return manuallyMarkedCompliantIcon;
            case ComplianceIconType.MANUALLY_MARKED_NONCOMPLIANT:
                return manuallyMarkedNoncompliantIcon;
            default:
                console.warn('Invalid Compliance Icon Type: ', complianceIconType);
                return null;
        }
    else if(ValueIsSet(compliant)){
        if(compliant && !ValueIsSet(resolved))
            return autoMarkedCompliantIcon;
        if(!compliant && !ValueIsSet(resolved))
            return autoMarkedNoncompliantIcon;
        if(ValueIsSet(resolved) && resolved)
            return manuallyMarkedCompliantIcon;
        if(ValueIsSet(resolved) && !resolved)
            return manuallyMarkedNoncompliantIcon
    }
    else
        console.warn('complianceIconType or compliant props must be set')
};

ComplianceIcon.propTypes = {
    complianceIconType: Proptypes.oneOf(Object.values(ComplianceIconType)), // pass in a compliance icon type or both compliant and resolved
    compliant: Proptypes.bool,
    resolved: Proptypes.bool,
    styles: Proptypes.string,
    height:  Proptypes.string,
    width: Proptypes.string,
    margin: Proptypes.string
};

export default ComplianceIcon;