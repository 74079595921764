import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import Accordion from "../../components/Accordion/Accordion";
import ProposalHeader from "../ProposalHeader/ProposalHeader";
import ProposalHeaderSkeleton from "../ProposalHeader/ProposalHeaderSkeleton";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import {Display, JustifyContent} from "../../enums/CSSEnums";
import CircularProgress from "../../components/Loader/CircularProgress";
import WalkthroughDetails from "../WalkthroughDetails/WalkthroughDetails";
import BidStatus from "worksmith/enums/api/proposal/BidStatus";

class WalkthroughListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expansionPanelStates: [],
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.loading && this.props.loading) {
            this.setState({
                expansionPanelStates: [],
            })
        }

        if (prevProps.loading && !this.props.loading) {
            let newExpansionPanelStates = [];
            this.props.walkthroughs.forEach((walkthrough) => {
                newExpansionPanelStates.push(
                    {open: true, hasBeenOpened: true}
                )
            });
            this.setState({expansionPanelStates: newExpansionPanelStates})
        }
    }

    onExpansionPanelClick = (index) => {
       const {expansionPanelStates} = this.state;

       let newExpansionPanelStates = expansionPanelStates.slice();

       newExpansionPanelStates[index].open = !newExpansionPanelStates[index].open;
       newExpansionPanelStates[index].hasBeenOpened = true;
       this.setState({expansionPanelStates: newExpansionPanelStates})
    }

    render() {
        const {onExpansionPanelClick} = this;
        const {expansionPanelStates} = this.state;
        const {
            isAdmin,
            loading,
            numberOfWalkthroughs,
            onWalkthroughAction,
            startLoad,
            walkthroughs
        } = this.props;

        let walkthroughExpansionPanels = [];

        if (loading) {
            if (!ValueIsSet(numberOfWalkthroughs)) {
                walkthroughExpansionPanels.push(
                    <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                        <CircularProgress/>
                    </Box>
                )
            } else {
                for (let loadingWalkthrough = 0; loadingWalkthrough < numberOfWalkthroughs; loadingWalkthrough++) {
                    walkthroughExpansionPanels.push(
                        <Accordion
                            key={loadingWalkthrough}
                            summary={<ProposalHeaderSkeleton/>}
                            details={<Fragment/>}
                            expanded={false}
                            expandIcon={false}/>
                    )
                }
            }
        } else {
            walkthroughExpansionPanels =
                walkthroughs.map((walkthrough, index) => (
                    <Accordion
                        key={walkthrough.id}
                        summary={
                            <ProposalHeader
                                additionalApprovalAmount={walkthrough.additionalApprovalAmount}
                                status={walkthrough.status}
                                totalRetailPrice={ValueIsSet(walkthrough.activeObligation) ? walkthrough.activeObligation.totalRetailPrice : walkthrough.totalRetailPrice}
                                vendorLocation={walkthrough.opportunity.vendorLocation}
                                walkthroughStatusBooleans={{
                                    isScheduling: walkthrough.isScheduling,
                                    isScheduled: walkthrough.isScheduled,
                                    isRescheduling: walkthrough.isRescheduling,
                                    isCompleted: walkthrough.isCompleted,
                                    isInCorporatePricingReview: walkthrough.status === BidStatus.PENDING_REGIONAL_MANAGER || walkthrough.status === BidStatus.PENDING_ADMIN

                                }}
                            />
                        }
                        details={
                            expansionPanelStates.length > 0
                            && expansionPanelStates[index].hasBeenOpened ?
                                <WalkthroughDetails
                                    walkthroughId={walkthrough.id}
                                    isAdmin={isAdmin}
                                    onWalkthroughAction={onWalkthroughAction}
                                    startLoad={startLoad}/>
                                    :
                                <Fragment/>
                        }
                        onChange={() => {
                            onExpansionPanelClick(index);
                        }}
                        elevation={2}
                        expanded={expansionPanelStates.length > 0 && expansionPanelStates[index].open}
                        expandIcon={false}/>
                ))
        }


        return (
            walkthroughExpansionPanels
        )
    }
};

WalkthroughListView.propTypes = {
    walkthroughs: []
}

WalkthroughListView.propTypes = {
    isAdmin: PropTypes.bool,
    loading: PropTypes.bool,
    numberOfWalkthroughs: PropTypes.number,
    onWalkthroughAction: PropTypes.func,
    startLoad: PropTypes.func,
    walkthroughs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        isScheduling: PropTypes.bool,
        isScheduled: PropTypes.bool,
        isRescheduling: PropTypes.bool,
        isCompleted: PropTypes.bool,
        opportunity: PropTypes.shape({
            vendorLocation: PropTypes.shape({
                rating: PropTypes.number,
                ratingsCount: PropTypes.number,
                vendor: PropTypes.shape({
                    officialName: PropTypes.string,
                    qualifiesForPremiumBadge: PropTypes.bool,
                    vendorBio: PropTypes.shape({
                        aboutUs: PropTypes.string
                    })
                })
            }).isRequired,
        }),
        totalRetailPrice: PropTypes.number.isRequired
    }))
};

export default WalkthroughListView;