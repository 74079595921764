import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import Text, {LinkUnderline, TextColor, TextVariant} from "../../../../components/Text/Text.web";
import Schedule from "../../../../components/Schedule/Schedule.web";
import Grid from "../../../../components/Grid/Grid.web";
import {Display, JustifyContent} from "worksmith/enums/CSSEnums";
import Button, {ButtonSize} from "../../../../components/Button/Button.web";
import CreateScheduleOptionsDialog from "../../../ScheduleOptionsForm/CreateScheduleOptionsDialog";
import UserType from "worksmith/enums/api/user/UserType";
import {getReschedulingSubStatusFromCreatorType} from "./ReschedulingView";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers"

const createOptionsGridItem = (key, body, onClick) => (
    <Grid
        container
        item
        justify={JustifyContent.SPACE_BETWEEN}
        xs={12}
        key={key}>
        <Grid item xs={12} sm={8}>
            {body}
        </Grid>
        <Grid item xs={12} sm={4}>
            <Button
                primary
                onClick={onClick}
                size={ButtonSize.SMALL}>
                Select
            </Button>
        </Grid>
    </Grid>
);

class ReschedulingVendorSubmittedAvailability extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counterOfferModalOpen: false
        }
    }

    showCounterOfferModal = () => {
        this.setState({counterOfferModalOpen: true});
    };

    hideCounterOfferModal = () => {
        this.setState({counterOfferModalOpen: false});
    };

    render() {
        const {hideCounterOfferModal, showCounterOfferModal} = this;
        const {counterOfferModalOpen} = this.state;
        const {
            acceptChangeRequest,
            creatorType,
            duration,
            frequency,
            loading,
            proposeSchedules,
            schedule,
            scheduleHandler,
            showCancelChangeRequestModal,
            useTicketing,
            vendorLocation,
        } = this.props;

        let description = (
            <Text variant={TextVariant.BODY_1}>
                {`${vendorLocation.vendor.officialName} proposed a different time. Please accept a time or propose a new one.`}
                {
                    creatorType === UserType.CLIENT ?
                        <Fragment>
                        {` No longer need to reschedule? Click here to `}
                        <Text
                            link
                            onClick={showCancelChangeRequestModal}
                            display={Display.INLINE}
                            variant={TextVariant.INHERIT}
                            underline={LinkUnderline.ALWAYS}
                            semiBold
                            color={TextColor.SECONDARY}>
                            {`keep your current time.`}
                        </Text>
                        </Fragment>
                        :
                        null
                }

            </Text>
        )

        return (
            <Fragment>

            <CreateScheduleOptionsDialog
                duration={duration}
                frequency={frequency}
                title={ValueIsSet(frequency) ? "Propose New Recurring Schedule" : "Propose New Visit Time"}
                numberOfSchedules={1}
                numberOfRequiredSchedules={1}
                onSubmit={(schedules) => {
                    hideCounterOfferModal();
                    proposeSchedules(schedules);
                }}
                onCancel={hideCounterOfferModal}
                userType={UserType.CLIENT}
                open={counterOfferModalOpen}
                useTicketing={useTicketing}/>

            <RequestStateLayout
                description={description}
                loading={loading}
                body={
                    <Grid container spacing={3}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Text variant={TextVariant.BODY_1} color={TextColor.PRIMARY} semiBold>
                                    Currently Scheduled For:
                                </Text>
                            </Grid>
                            <Grid item xs={12}>
                                <Schedule
                                    textVariant={TextVariant.BODY_2}
                                    schedule={schedule}
                                />
                            </Grid>

                        </Grid>
                        <Grid item container spacing={2} maxWidth={500} xs={12}>
                            <Grid item xs={12}>
                                <Text variant={TextVariant.BODY_1} color={TextColor.SECONDARY} semiBold>
                                    Requested to Reschedule For:
                                </Text>
                            </Grid>
                            {
                                scheduleHandler.openScheduleOptions.length > 0 ?
                                    createOptionsGridItem(
                                        scheduleHandler.openScheduleOptions[0].id,
                                        <Schedule
                                            schedule={scheduleHandler.openScheduleOptions[0].schedule}/>,
                                        acceptChangeRequest
                                    )
                                    :
                                    null
                            }
                            {
                                createOptionsGridItem(
                                    0,
                                    <Text>Propose a different time</Text>,
                                    showCounterOfferModal
                                )
                            }
                        </Grid>
                    </Grid>
                }
                status={'Rescheduling - ' + getReschedulingSubStatusFromCreatorType(creatorType)}
                statusColor={TextColor.ERROR}/>
            </Fragment>
        )
    }
};

ReschedulingVendorSubmittedAvailability.propTypes = {
    acceptChangeRequest: PropTypes.func.isRequired,
    creatorType: PropTypes.oneOf([
        UserType.CLIENT,
        UserType.VENDOR,
    ]),
    duration: PropTypes.number,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    loading: PropTypes.bool,
    proposeSchedules: PropTypes.func.isRequired,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    scheduleHandler: PropTypes.shape({
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        })),
    }),
    showCancelChangeRequestModal: PropTypes.func.isRequired,
    useTicketing: PropTypes.bool,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }).isRequired,
};

export default ReschedulingVendorSubmittedAvailability;