import {Pluralize} from "worksmith/helpers/LanguageHelper";

export const ContractItemName = Object.freeze({
    HOURLY_RATE: 'Hourly Rate',
    EMERGENCY_RATE: 'Emergency Rate',
    HOLIDAY_RATE: 'Holiday Rate',
    TRIP_CHARGE: 'Trip Charge',
    EMERGENCY_TRIP_CHARGE: 'Emergency Trip Charge',
    DIAGNOSTIC: 'Diagnostic',
    PREVENTATIVE_MAINTENANCE: 'Preventative Maintenance',
    RECURRING_SERVICE: 'Recurring Service',
    WALKTHROUGH: 'Walkthrough',
    FEE: 'Fee',
    MATERIALS: 'Materials',
    PRICE_PER_VISIT: 'Price per Visit',
    ON_DEMAND_VISIT: 'On Demand Visit',
    PRICE_PER_VISIT_MONTHLY: 'Price per Visit (monthly)',
    PRICE_PER_VISIT_BI_MONTHLY: 'Price per Visit (bimonthly)',
    PRICE_PER_VISIT_QUARTERLY: 'Price per Visit (quarterly)',
    PACKAGE_RTU_5_TO: 'Package RTU (>5 ton)',
    PACKAGE_RTU_10_TON: 'Package RTU (>10 ton)',
    PACKAGE_RTU_15_TON: 'Package RTU (>15 ton)',
    SPLIT_SYSTEM_5_TON: 'Split System (>5 ton)',
    SPLIT_SYSTEM_10_TON: 'Split System (>10 ton)',
    SPLIT_SYSTEM_15_TON: 'Split System (>15 ton)',
    ADDITIONAL_UNIT_5_TON: 'Each Additional Unit (>5 ton)',
    ADDITIONAL_UNIT_10_TON: 'Each Additional Unit (>10 ton)',
    ADDITIONAL_UNIT_15_TON: 'Each Additional Unit (>15 ton)'
});

export const ContractItemType = Object.freeze({
    HOURLY_LABOR: 'Hourly Labor',
    FIXED_RATE_CONTRACT: 'Fixed Rate Contract'
});

export const ServiceLevelAgreementType = Object.freeze({
    REQUEST_ACKNOWLEDGEMENT: 'REQUEST_ACKNOWLEDGEMENT',
    EMERGENCY_REQUEST_COMPLETION: 'EMERGENCY_REQUEST_COMPLETION',
    ON_DEMAND_REQUEST_COMPLETION: 'ON_DEMAND_REQUEST_COMPLETION',
    RECURRING_JOB_COMPLETION: 'RECURRING_JOB_COMPLETION'
});

export const ServiceLeveAgreementTypeCombonation = Object.freeze({
    ON_DEMAND_AND_RECURRING_COMPLETION: 'ON_DEMAND_AND_RECURRING_COMPLETION',
});

export const SlaComplianceText = Object.freeze({
    ON_DEMAND_AND_RECURRING_COMPLETION: (timeFrame) => {return "The on-demand & recurring jobs occurred within " + timeFrame + " " + Pluralize(timeFrame,"day") + " of the scheduled date."},
    EMERGENCY_REQUEST_SCHEDULING: (timeFrame) => {return "Emergency jobs scheduled within " + timeFrame + " " + Pluralize(timeFrame,"hour") + " of request submission."},
    EMERGENCY_REQUEST_RESPONSE: (timeFrame) => {return "Emergency jobs may be scheduled (received a response) within " + timeFrame + " " + Pluralize(timeFrame,"hour") + " of request submission."},
    REQUEST_ACKNOWLEDGEMENT: (timeFrame) => {return "Worksmith acknowledged receipt within " + timeFrame + " " + Pluralize(timeFrame,"hour") + " of request submission."}
});

export const ServiceLevelAgreementDefault = Object.freeze({
    REQUEST_ACKNOWLEDGEMENT: 24,
    EMERGENCY_REQUEST_COMPLETION: 24,
    EMERGENCY_REQUEST_SCHEDULING: 24,
    EMERGENCY_REQUEST_RESPONSE: 24,
    ON_DEMAND_REQUEST_COMPLETION: 3,
    RECURRING_JOB_COMPLETION: 3,
});

export const ServiceLevelAgreementConfigDefault = Object.freeze({
    REMEDY: "Service Level Agreements (SLAs) are set by your contract and monitored automatically by the Worksmith system. " +
        "However, from time to time the Worksmith team may manually override these metrics to account for reasonable circumstances " +
        "(ordering materials, mutually agreed upon delays, etc.). When this happens your team will be notified.",
    MINIMUM_SAMPLE_SIZE: 5,
    COMPLIANCE_RATE: 70
});

export const ContractItemScopeOfWorkText = Object.freeze({
    HOURLY_RATE: 'Hourly Rate is the cost per technician per hour needed to complete your job. Hourly rates may vary ' +
        'based on the specialization of the technician, complexity of the job, and other factors. However, your retail ' +
        'pricing will NOT change from job to job as it has been locked in by your Rate Card. ',

    EMERGENCY_RATE: 'Emergency Hourly Rate is the cost per technician per hour needed to complete your job. Hourly ' +
        'rates may vary based on the specialization of the technician, complexity of the job, and even the finish out of ' +
        'your business location. However, your retail pricing will NOT change from job to job as it has been locked in ' +
        'by your Rate Card. \n' +
        '\n' +
        'Emergency Hourly Rate is often higher than a standard hourly rate, as the emergency nature of the job causes ' +
        'the technician to adjust other jobs on their schedule, and sometimes work after hours, to ensure your job is ' +
        'completed as soon as possible. \n',

    TRIP_CHARGE: 'A Trip Charge is a flat fee a vendor may charge to cover the cost of dispatching technicians and ' +
        'traveling to your location. A vendor determines this fee based on the distance to your location and the ' +
        'overall accessibility of your retail location.\n' +
        '\n' +
        'A vendor may apply multiple trip charges if, for example, more than one visit is required, more than one ' +
        'vehicle or technician is required due to the complexity of the job or other reasons which should be detailed ' +
        'when your estimate is submitted. \n',

    EMERGENCY_TRIP_CHARGE: 'The Emergency Trip Charge is a flat fee a vendor may charge to cover the cost of dispatching' +
        ' technicians and traveling to your location. This fee is determined by the distance to your location and the ' +
        'overall accessibility of your retail location. This rate is often higher than a standard trip charge, as the ' +
        'emergency nature of the job causes the technician to adjust other jobs on their schedule, and sometimes work ' +
        'after hours, to ensure your job is completed as soon as possible. \n' +
        '\n' +
        'A vendor may apply multiple trip charges if, for example, more than one visit is required, more than one ' +
        'vehicle or technician is required due to the complexity of the job or other reasons which should be detailed ' +
        'when your estimate is submitted. \n',
});