import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {AddCircle, DescriptionOutlined, Slideshow} from '@mui/icons-material';
import styled from "styled-components";

import {Position} from "worksmith/enums/CSSEnums";
import {Color, PrimaryColor} from 'worksmith/enums/Color';
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import IconButton from 'worksmith/components/Button/IconButton.web';
import {IconType} from 'worksmith/enums/MaterialEnums';

//region STYLED COMPONENTS
const Container = styled.div`
    width: 5.5em;
    position:  ${Position.RELATIVE};
    margin-top: ${({noPreviewMargin}) => noPreviewMargin ? 0 : '1em'};
`;

const Preview = styled.img`
    width:         5.5em;
    height:        5.5em;
    border-radius: 16px;
    border: 1px solid ${Color.OFF_WHITE};
    overflow: hidden;
`;

const PreviewEmpty = styled.div`
    width:         5.5em;
    height:        5.5em;
    border-radius: 4px;
`;

const Name = styled.div`
    overflow:      hidden;
    white-space:   nowrap;
    text-overflow: ellipsis;
`;

const RemoveButton = styled(IconButton)`
    && {
      position: ${Position.ABSOLUTE};
      top:    -.35em;
      right:      -.35em;
      transform: rotate(45deg);
      
      svg {
        background-color: ${Color.MEDIUM_GREY};
        fill: ${Color.WHITE};
        border-radius: 9999px;
      }
    }
`;
//endregion STYLED COMPONENTS

class FilePreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageFailedToRender: false,
            isVideoFile: false
        };
    }

    componentDidMount() {
        const {addedFile} = this.props;
        const videoExtensions = ['.mp4', '.mov', '.ogg', '.wmv', '.webm', '.avi', '.qt'];
        if(ValueIsSet(addedFile.name)){
            videoExtensions.forEach(extension => addedFile.name.toLowerCase().includes(extension) ? this.setState({isVideoFile: true}) : null);
        }
    }

    render() {

        const {addedFile, showFileName, index, removeFile, onFilePreviewClick, noPreviewMargin} = this.props;
        const {imageFailedToRender, isVideoFile} = this.state;

        return (
            <Container noPreviewMargin={noPreviewMargin}>
                {
                    !imageFailedToRender ?
                        <Preview src={ValueIsSet(addedFile.url) ? addedFile.url : addedFile.singleUseUrl}
                                 onClick={() => onFilePreviewClick(index)}
                                 onError={() => this.setState({imageFailedToRender: true})}/>
                        :
                        <PreviewEmpty>
                            {isVideoFile ?
                                <Slideshow
                                    onClick={() => onFilePreviewClick(index)}
                                    style={{'fontSize': '5em', 'color': PrimaryColor['400']}}
                                />
                                :
                                <DescriptionOutlined
                                    onClick={() => onFilePreviewClick(index)}
                                    style={{'fontSize': '5em', 'color': PrimaryColor['400']}}
                                />
                            }
                        </PreviewEmpty>
                }
                { ValueIsSet(addedFile.name) && showFileName ?
                        <Tooltip placement={TooltipPlacement.BOTTOM} title={addedFile.name}>
                            <Name>{addedFile.name}</Name>
                        </Tooltip>
                    :
                        null}
                {removeFile ?
                <RemoveButton iconName={IconType.ADD_CIRCLE_OUTLINE}
                              onClick={() => removeFile(index)}/>
                              :
                    null
                }
            </Container>
        )
    }
};

FilePreview.propTypes = {
    addedFile:  PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
    }),
    showFileName: PropTypes.bool,
    index:      PropTypes.number.isRequired,
    noPreviewMargin:      PropTypes.bool,
    removeFile: PropTypes.func,
    onFilePreviewClick: PropTypes.func
};

export default FilePreview;
