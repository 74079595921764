import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Text, {TextDisplay} from "worksmith/components/Text/Text.web";
import styled from 'styled-components';
import Grid from "worksmith/components/Grid/Grid.web";
import Accordion from "worksmith/components/Accordion/Accordion";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import ObligationTemplatePropertiesGrid from "./ObligationTemplatePropertiesGrid";
import {Color} from "worksmith/enums/Color";

const SpacedText = styled(Text)`
    padding-top: 0.5em;
    padding-bottom: 0.5em;

`;

const BorderBlock = styled.div`
    border-style: none none solid none;
    border-width: 0.1em;
    border-color: ${Color.LIGHT_GREY}
`;

const SingleRecurringServiceDetailsExpansion = (props) => {
    let {obligationTemplate} = props;
    const [showExpandedPanel, setShowExpandedPanel] = useState(false);

    return <BorderBlock>
        <Accordion
            elevation={0}
            expanded={showExpandedPanel}
            onChange={() => setShowExpandedPanel(!showExpandedPanel)}
            summary={
                <Grid spacing={0} xs={6} container>
                    <Grid item xs={6}>
                        <SpacedText semiBold display={TextDisplay.inline}>Cancelled on</SpacedText>
                    </Grid>
                    <Grid item xs={6}>
                        <SpacedText
                            display={TextDisplay.inline}>{moment(obligationTemplate.updateTimestamp).format(MomentFormat.MonthDayYearSlash)}</SpacedText>
                    </Grid>
                </Grid>
            }
            details={<ObligationTemplatePropertiesGrid skipFirstLine={true} displayVendorAndPriceDetails={true} obligationTemplate={obligationTemplate}/>}
        />
    </BorderBlock>
};
SingleRecurringServiceDetailsExpansion.propTypes ={
    obligationTemplate: PropTypes.object
};

export default SingleRecurringServiceDetailsExpansion;