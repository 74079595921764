import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import Grid from "worksmith/components/Grid/Grid.web";
import Button, {ButtonSize, ButtonVariant} from "worksmith/components/Button/Button.web";
import {withRouter} from "react-router-dom";

const CenterBlock = styled.div`
    margin: auto;
    width: 60%;
    padding-top: 10em;`
;

const MainText = styled(Text)`
    padding-top: 1em;
`;

const BufferedGrid = styled(Grid)`
    padding-top: 4em;
    padding-left: 8em;
`;

const DelinquentClientRedirect = (props) =>
    <CenterBlock>
        <Text variant={TextVariant.H4} color={TextColor.TEXT_PRIMARY}>Your Account is Locked</Text>
        {props.isAdministrator ?
            <Fragment>
                <MainText variant={TextVariant.BODY_1} color={TextColor.TEXT_PRIMARY}>
                    All Scheduled Services have been paused and your account has been locked due to outstanding payment.
                    Once reconciled, you will re-gain access to your account and can reschedule any missed work.
                </MainText>
                <MainText variant={TextVariant.BODY_1} color={TextColor.TEXT_PRIMARY}>
                    Please review your outstanding invoices as soon as possible.  For any discrepancies, email <a href={'mailto:accounting@worksmith.com'}>accounting@worksmith.com</a>
                </MainText>
            </Fragment>
        :
            <MainText>
                All scheduled services have been paused and your account has been locked. Please contact your Administrator for more information.
            </MainText>}
        <BufferedGrid container>
            <Grid item xs={12} sm={4}>
                <Button primary size={ButtonSize.LARGE} variant={ButtonVariant.OUTLINED} onClick={() => props.history.push('/logout')}>Log Out</Button>
            </Grid>
            {props.isAdministrator ?
            <Grid item xs={12} sm={4}>
                <Button primary size={ButtonSize.LARGE} onClick={() => props.history.push('/invoices')}>View Invoices</Button>
            </Grid>
            : null}
        </BufferedGrid>
    </CenterBlock>;

DelinquentClientRedirect.propTypes = {
    isAdministrator: PropTypes.bool
};

export default withRouter(DelinquentClientRedirect);