import React, { memo} from 'react';
import PropTypes from 'prop-types';
import RequestStateLayout from "../RequestStateLayout";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import NewRequestStatus from "worksmith/enums/api/proposal/NewRequestStatus";
import {TextColor} from "worksmith/components/Text/Text.web";

const StoreUserCorporateReviewView = (props) => {
    const {loading} = props;

    return (
        <RequestStateLayout description={"An Admin must review this request before it can released to the vendor network."}
                            loading={loading}
                            status={TitleCase(NewRequestStatus.CORPORATE_REVIEW)}
                            statusColor={TextColor.PRIMARY}/>
    )
};

StoreUserCorporateReviewView.defaultProps = {
    loading: true
}

StoreUserCorporateReviewView.propTypes = {
    loading: PropTypes.bool
};

export default memo(StoreUserCorporateReviewView);