import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {FlexDirection} from "../../enums/CSSEnums";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import moment from "moment";
import Text, {TextColor, TextVariant} from "../../components/Text/Text.web";
import DatePicker from "../../components/DatePicker/DatePicker.web";
import Grid from "../../components/Grid/Grid.web";
import {InputBaseVariant} from "../../components/Inputs/InputBase/InputBase.web";
import TimeRangeField from "../../components/TimeRangeField/TimeRangeField";
import {HorizontalOrigin, VerticalOrigin} from "../../components/Popover/Popover.web";

const OneTimeScheduleInputView = (props) => {
    const {
        initialDate,
        initialEndTime,
        initialReturnDate,
        initialStartTime,
        includeReturnDate,
        onDateChange,
        onReturnDateChange,
        onTimeRangeChange,
        spacing,
        variant,
        title
    } = props;


    return (
        <Grid container
              item
              spacing={spacing}>
            {
                title ?
                    <Grid item xs={12} sm={12}>
                        <Text color={TextColor.TEXT_SECONDARY} variant={TextVariant.BODY_1}>{title}</Text>
                    </Grid>
                    :
                    null
            }
            <Grid item xs={12} sm={6}>
                <DatePicker label={includeReturnDate ? "Pickup Date" : "Date"}
                            fullWidth={true}
                            initialDate={initialDate}
                            inputVariant={variant}
                            isOutsideRange={(date) => date.isBefore(moment(), 'days')}
                            onChange={onDateChange}
                            popoverStartingSide={HorizontalOrigin.LEFT}
                            popoverVerticalAnchorOrigin={VerticalOrigin.TOP}
                            popoverVerticalTransformOrigin={200}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TimeRangeField onChange={onTimeRangeChange}
                                initialEndTime={initialEndTime}
                                initialStartTime={initialStartTime}
                                label={"Arrival Window: "}/>
            </Grid>
            {
                includeReturnDate ?
                    <Grid item xs={12} sm={6}>
                        <DatePicker label={"Return Date"}
                                    fullWidth={true}
                                    initialDate={initialReturnDate}
                                    inputVariant={variant}
                                    isOutsideRange={(date) => date.isBefore(moment(), 'days')}
                                    onChange={onReturnDateChange}
                                    popoverStartingSide={HorizontalOrigin.LEFT}/>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
};

OneTimeScheduleInputView.defaultProps = {
    direction: FlexDirection.ROW,
    includeReturnDate: false,
    showDuration: false,
    spacing: 3,
    variant: InputBaseVariant.OUTLINED
};

OneTimeScheduleInputView.propTypes = {
    initialDate: PropTypes.instanceOf(moment),
    initialEndTime: PropTypes.instanceOf(moment),
    initialReturnDate: PropTypes.instanceOf(moment),
    initialStartTime: PropTypes.instanceOf(moment),
    includeReturnDate: PropTypes.bool,
    onDateChange: PropTypes.func.isRequired,
    onReturnDateChange: PropTypes.func,
    onTimeRangeChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    variant: CustomPropTypes.enum(InputBaseVariant)
};

export default OneTimeScheduleInputView;