export const Display = Object.freeze({
    BLOCK: 'block',
    CONTENTS: 'contents',
    FLEX: 'flex',
    GRID: 'grid',
    INHERIT: 'inherit',
    INITIAL: 'initial',
    INLINE: 'inline',
    INLINE_BLOCK: 'inline-block',
    INLINE_FLEX: 'inline-flex',
    INLINE_GRID: 'inline-grid',
    INLINE_TABLE: 'inline-table',
    LIST_ITEM: 'list-item',
    NONE: 'none',
    RUN_IN: 'run-in',
    TABLE: 'table',
    TABLE_CAPTION: 'table-caption',
    TABLE_COLUMN_GROUP: 'table-column-group',
    TABLE_HEADER_GROUP: 'table-header-group',
    TABLE_FOOTER_GROUP: 'table-footer-group',
    TABLE_ROW_GROUP: 'table-row-group',
    TABLE_CELL: 'table-cell',
    TABLE_COLUMN: 'table-column',
    TABLE_ROW: 'table-row'
});

export const Margin = Object.freeze({
    AUTO: 'auto'
});

export const Position = Object.freeze({
    STATIC: 'static',
    RELATIVE: 'relative',
    FIXED: 'fixed',
    ABSOLUTE: 'absolute',
    STICKY: 'sticky'
});

export const JustifyContent = Object.freeze({
    FLEX_START: 'flex-start',
    CENTER: 'center',
    FLEX_END: 'flex-end',
    SPACE_AROUND: 'space-around',
    SPACE_BETWEEN: 'space-between',
    SPACE_EVENLY: 'space-evenly'
});

export const AlignItems = Object.freeze({
    BASELINE: 'baseline',
    FLEX_START: 'flex-start',
    CENTER: 'center',
    FLEX_END: 'flex-end',
    STRETCH: 'stretch',
    UNSET: 'unset',
    INITIAL: 'initial'
});

export const TextAlign = Object.freeze({
    CENTER: 'center',
    INHERIT: 'inherit',
    JUSTIFY: 'justify',
    LEFT: 'left',
    RIGHT: 'right',
});

export const TextDecoration = Object.freeze({
    NONE: 'none',
    UNDERLINE: 'underline',
    LINE_THROUGH: 'line-through',
    UNDERLINE_LINE_THROUGH: 'underline line-through'
});

export const WordBreak = Object.freeze({
    NORMAL: 'normal',
    BREAK_ALL: 'break-all',
    KEEP_ALL: 'keep-all',
    BREAK_WORD: 'break-word'
});

export const OverflowOption = Object.freeze({
    AUTO: 'auto',
    HIDDEN: 'hidden',
    SCROLL: 'scroll',
    VISIBLE: 'visible'
});

export const ThemeColor = Object.freeze({
    PRIMARY: "primary",
    SECONDARY: "secondary"
});

export const PositionType = Object.freeze({
    ABSOLUTE: 'absolute',
    RELATIVE: 'relative'
});

export const ImageResizeMode = Object.freeze({
    CENTER: 'center',
    CONTAIN: 'contain',
    COVER: 'cover',
    REPEAT: 'repeat',
    STRETCH: 'stretch'
});

export const TablePadding = Object.freeze({
   CHECKBOX: "checkbox",
   BUTTON: "button",
   NONE:"none"
});

export const FlexDirection = Object.freeze({
    COLUMN: 'column',
    COLUMN_REVERSE: 'column-reverse',
    ROW: 'row',
    ROW_REVERSE: 'row-reverse'
});

export const FlexWrap = Object.freeze({
    NO_WRAP: 'nowrap',
    WRAP: 'wrap',
    WRAP_REVERSE: 'wrap-reverse'
});

export const Height = Object.freeze({
    AUTO: 'auto',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

export const Cursor = Object.freeze({
    ALIAS: 'alias',
    ALL_SCROLL: 'all-scroll',
    AUTO: 'auto',
    CELL: 'cell',
    CONTEXT_MENU: 'context-menu',
    COL_RESIZE: 'col-resize',
    COPY: 'copy',
    CROSSHAIR: 'crosshair',
    DEFAULT: 'default',
    E_RESIZE: 'e-resize',
    EW_RESIZE: 'ew-resize',
    GRAB: 'grab',
    GRABBING: 'grabbing',
    HELP: 'help',
    MOVE: 'move',
    N_RESIZE: 'n-resize',
    NE_RESIZE: 'ne-resize',
    NESW_RESIZE: 'nesw-resize',
    NS_RESIZE: 'ns-resize',
    NW_RESIZE: 'nw-resize',
    NWSE_RESIZE: 'nwse-resize',
    NO_DROP: 'no-drop',
    NONE: 'none',
    NOT_ALLOWED: 'not-allowed',
    POINTER: 'pointer',
    PROGRESS: 'progress',
    ROW_RESIZE: 'row-resize',
    S_RESIZE: 's-resize',
    SE_RESIZE: 'se-resize',
    SW_RESIZE: 'sw-resize',
    TEXT: 'text',
    //URL: A comma separated list of URLs to custom cursors. Note: Always specify a generic cursor at the end of the list, in case none of the URL-defined cursors can be used
    VERTICAL_TEXT: 'vertical-text',
    W_RESIZE: 'w-resize',
    WAIT: 'wait',
    ZOOM_IN: 'zoom-in',
    ZOOM_OUT: 'zoom-out',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});
