import React from 'react';
import PropTypes from 'prop-types'

import AdminPanel from "./admin/AdminPanel"
import LocalPanel from "./local/LocalPanel"
import RegionalPanel from "./regional/RegionalPanel"

import {AlignItems} from "../../../enums/CSSEnums";
import Accordion from '../../../components/Accordion/Accordion'
import Text, {TextVariant} from "../../../components/Text/Text";
import Grid from "../../../components/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import {IconType} from "../../../enums/MaterialEnums";
import {ValueIsSet} from "../../../../Helpers";

const UsersPanel = ({clientNTEs, location}) => {

    const users = {
        LOCAL: location.locationManagers,
        REGIONAL: location.regionalManagers,
        ADMIN: location.client.adminUsers
    };

    const createViewFields = (userLevel) => {
        let NTEs = null;
        if(userLevel === "LOCAL"){
            NTEs = clientNTEs.filter(nte => nte.type === "REGIONAL")[0];
        }else if (userLevel === "REGIONAL"){
            NTEs = clientNTEs.filter(nte => nte.type === "ADMIN")[0];
        }

        return users[userLevel].map(field => ({
            location: field.companyName + ' - ' + '(' + location.locationCode + ')',
            userColumn: field.displayName + "\n" + (field.email ? field.email : '') + "\n" + (field.phone ? field.phone : ''),
            approvalThreshold: ValueIsSet(NTEs) && NTEs.NTEValue ? NTEs.NTEValue : null
        }))
    };

    const localViewFields = createViewFields("LOCAL");
    const regionalViewFields = createViewFields("REGIONAL");
    const adminViewFields = createViewFields("ADMIN");

    const panelSummary = (
        <Grid container>
            <Grid container item xs={2} md={1} alignItems={AlignItems.CENTER}>
                <Icon name={IconType.ACCOUNT_CIRCLE} />
            </Grid>
            <Grid container item xs={9} md={11} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H5}>
                    {"Users"}
                </Text>
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            summary={panelSummary}
            details={
                <Grid container>
                    <LocalPanel localViewFields={localViewFields}/>
                    <RegionalPanel regionalViewFields={regionalViewFields}/>
                    <AdminPanel adminViewFields={adminViewFields}/>
                </Grid>
            }
            large
        />
    )
};

export default UsersPanel;

UsersPanel.propTypes = {
    location: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        client: PropTypes.shape({
            adminUsers: PropTypes.arrayOf(PropTypes.shape({
                clientRole: PropTypes.string,
                companyName: PropTypes.string,
                displayName: PropTypes.string,
                email: PropTypes.string.isRequired,
                phone: PropTypes.string,
                clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
                    clientLocation: PropTypes.shape({
                        locationCode: PropTypes.string,
                        client: PropTypes.shape({
                            officialName: PropTypes.string.isRequired
                        }).isRequired
                    })
                })).isRequired
            })),
            id: PropTypes.number.isRequired
        }).isRequired,
        clientLocationAttachments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            singleUseUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })),
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
        email: PropTypes.string,
        industry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: PropTypes.string
        }),
        openingDate: PropTypes.string,
        regionalManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        locationManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        clientLocationOperationDetails: PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            unionLaborRequirement: PropTypes.string,
            occurrenceInsuranceRequirement: PropTypes.number,
            aggregateInsuranceRequirement: PropTypes.number,
            additionalInsuranceDetails: PropTypes.string,
            locationSquareFootage: PropTypes.number,
            hasLadderOnSite: PropTypes.bool,
            ladderHeightFt: PropTypes.number,
            parkingDirections: PropTypes.string,
            serviceEntranceDescription: PropTypes.string,
            additionalAccessInfo: PropTypes.string
        }),
        locationHours: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            dayOfWeekInteger: PropTypes.number,
            openTime: PropTypes.string,
            closeTime: PropTypes.string,
            closed: PropTypes.bool,
        })),
        id: PropTypes.number,
        locationCode: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired
    }),
    clientNTEs: PropTypes.arrayOf(PropTypes.shape({
        NTEValue: PropTypes.number,
        client: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        id: PropTypes.number,
        type: PropTypes.string,
        serviceLine: PropTypes.string
    }))
}
