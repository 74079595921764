import axios from 'axios';

import Http from 'worksmith/services/Http/Http';
import {RequestMethod} from "../../Enums";
import {config} from "../../env";

class UtilityServiceClass extends Http {
    constructor(){
        super('');
    }

    howTo(){
        const options = {
            url: 'https://worksmith.zendesk.com/api/v2/help_center/articles/227608247.json',
            method: RequestMethod.GET,
            headers: {
                'Authorization': `Basic c2hlaGxAd29ya3NtaXRoLmNvbS90b2tlbjpNNjQ5ekNLUkJsOGM5QWdiWUlyWlJ2aVJGZGNhRzBQdlRqRU1BZDl4`
            }
        };

        return axios(options);
    }

    logUserOutOfAuth0(){
        const auth0ClientId = config.AUTH0_CLIENT_ID;
        const auth0Domain = config.AUTH0_DOMAIN;
        const samlRedirectUrl = config.SAML_REDIRECT_URL;

        window.location = 'https://'+auth0Domain+'/v2/logout'
            + '?federated'
            + '&client_id=' + auth0ClientId
            + '&returnTo=' + encodeURIComponent(samlRedirectUrl);
    };
}

export default UtilityServiceClass;
