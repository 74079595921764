import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from "../../components/Menu/MenuItem.web";

const WalkthroughDetailsActionList = React.forwardRef((props, ref) => {
    const {
        canAcceptWalkthrough,
        isAdmin,
        isInCorporateReview,
        isRescheduling,
        isScheduled,
        isScheduling,
        hasScheduleOptions,
        setShowActionMenu,
        setShowAcceptAndDeferModal,
        setShowAcceptAndScheduleModal,
        setShowAcceptAndSubmitSchedulesModal,
        setShowCancelModal,
        setShowDeclineModal,
        setShowRequestApprovalModal,
        setShowRequestToRescheduleModal
    } = props;

    let actionMenuItems = []

    if(!isInCorporateReview) {
        if (!isScheduling && !isScheduled && !isRescheduling) {

            if (hasScheduleOptions) {
                actionMenuItems.push(
                    (
                        <MenuItem
                            key={0}
                            ref={ref}
                            onClick={() => {
                                setShowActionMenu(false);

                                if (canAcceptWalkthrough) {
                                    setShowAcceptAndScheduleModal(true);
                                } else {
                                    setShowRequestApprovalModal(true);
                                }
                            }}>
                            Accept and select a time
                        </MenuItem>
                    )
                );
            }

            actionMenuItems.push(
                (
                    <MenuItem
                        key={1}
                        ref={hasScheduleOptions ? null : ref}
                        onClick={() => {
                            setShowActionMenu(false);

                            if (canAcceptWalkthrough) {
                                setShowAcceptAndSubmitSchedulesModal(true);
                            } else {
                                setShowRequestApprovalModal(true);
                            }
                        }}>
                        Accept and submit your availability
                    </MenuItem>
                )
            );

            if (isAdmin) {
                actionMenuItems.push(
                    <MenuItem
                        key={2}
                        onClick={() => {
                            setShowActionMenu(false);
                            setShowAcceptAndDeferModal(true);
                        }}>
                        Accept, but send to the Local User to schedule this job
                    </MenuItem>
                )
            }

            actionMenuItems.push(
                <MenuItem
                    key={3}
                    onClick={() => {
                        setShowActionMenu(false);
                        setShowDeclineModal(true);
                    }}>
                    Decline
                </MenuItem>
            )
        }

        if (isScheduled) {
            actionMenuItems.push(
                <MenuItem
                    key={4}
                    onClick={() => {
                        setShowActionMenu(false);
                        setShowRequestToRescheduleModal(true);
                    }}
                    ref={ref}>
                    Request to Reschedule
                </MenuItem>
            )
        }

        if (isScheduled || isScheduling || isRescheduling) {
            actionMenuItems.push(
                <MenuItem
                    ref={isScheduling ? ref : null}
                    key={5}
                    onClick={() => {
                        setShowActionMenu(false);
                        setShowCancelModal(true);
                    }}>
                    Cancel Walkthrough
                </MenuItem>
            )
        }
    }


    return (
        actionMenuItems
    )
});

WalkthroughDetailsActionList.propTypes = {
    canAcceptWalkthrough: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isScheduled: PropTypes.bool,
    isScheduling: PropTypes.bool,
    hasScheduleOptions: PropTypes.bool,
    setShowActionMenu: PropTypes.func.isRequired,
    setShowAcceptAndDeferModal: PropTypes.func,
    setShowAcceptAndScheduleModal: PropTypes.func,
    setShowAcceptAndSubmitSchedulesModal: PropTypes.func,
    setShowCancelModal: PropTypes.func,
    setShowDeclineModal: PropTypes.func,
    setShowRequestApprovalModal: PropTypes.func,
    setShowRequestToRescheduleModal: PropTypes.func,
};

export default WalkthroughDetailsActionList;