import React, {Component} from 'react';
import Loader from "../../components/Loader";
import UserService from 'worksmith/services/api/UserService';
import ClientService from 'worksmith/services/api/ClientService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import Modal from 'react-modal'
import Toggle from 'react-toggle'
import Select from 'react-select'
import Tooltip from 'react-tooltip'
import "react-toggle/style.css"
import 'react-select/dist/react-select.css';
import Button from "../../components/Button";
import numeral from 'numeral';
import {WorksmithConstants} from '../../Enums'
import NTESettingsList from "../../components/NTESettingsList";
import ClientNtePage from "worksmith/composite-components/ClientNte/ClientNtePage";

const userService = new UserService();
const clientService = new ClientService();
const authTokenManager = new AuthTokenManager();

class ClientApprovalSettings extends Component {
    constructor(props){
        super(props);

        this.state = {
            requiresApprovalToSubmit: null,
            adminNTE: null,
            regionalManagerNTE: null,
            prohibitLocationManagerSubmissions: null,
            client: {},
            loading: true,
            modalOpen: false,
            isAdmin: props.isAdmin
        };

        this.updateRequestApproval = this.updateRequestApproval.bind(this);
        this.updateAdminBidThreshold = this.updateAdminBidThreshold.bind(this);
        this.updateRegionalManagerBidThreshold = this.updateRegionalManagerBidThreshold.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.loadClient = this.loadClient.bind(this);
    }

    componentDidMount(){
        let _this = this;

        userService.findOne(authTokenManager.getUserId()).then(function (data) {
            let user = data;
            let clientId;

            if (user.clientRoles !== null) {
                clientId = user.clientRoles[0].client.id;
            } else {
                clientId = user.clientLocationRoles[0].clientId;
            }

            _this.loadClient(clientId);
        })
    }

    loadClient(clientId) {
        let _this = this;
        clientService.findOne(clientId).then(function(data){
            let client = data;

            // Getting individual state items from settings
            let {
                requiresApprovalToSubmit,
                prohibitLocationManagerSubmissions
            } = client.settings;

            let adminNTE = client.adminNTE;
            let regionalManagerNTE = client.regionalManagerNTE;

            // Setting individual state items from settings
            _this.setState({
                client,
                requiresApprovalToSubmit,
                adminNTE,
                regionalManagerNTE,
                prohibitLocationManagerSubmissions,
                loading: false
            })
        })
    }

    updateRequestApproval(newValue) {
        this.state.requiresApprovalToSubmit = newValue.value;
        this.forceUpdate();
    }

    updateAdminBidThreshold(event) {
        this.state.adminNTE= event.target.value;
        this.forceUpdate();
    }

    updateRegionalManagerBidThreshold(event) {
        this.state.regionalManagerNTE = event.target.value;
        this.forceUpdate();
    }

    handleUpdate(){
        let _this = this;
        let settings = {
            requiresApprovalToSubmit: this.state.requiresApprovalToSubmit,
            prohibitLocationManagerSubmissions: this.state.prohibitLocationManagerSubmissions
        };

        let clientData = {
            id: this.state.client.id,
            settings: settings,
            adminNTE: this.state.adminNTE === null || this.state.adminNTE === '' ?
                WorksmithConstants.UNSET : this.state.adminNTE,
            regionalManagerNTE: this.state.regionalManagerNTE === null || this.state.regionalManagerNTE === '' ?
                WorksmithConstants.UNSET : this.state.regionalManagerNTE
        };
        
        clientService.update(clientData).then(function(){
            // handle success / error
            _this.setState({modalOpen: true});
            _this.loadClient(_this.state.client.id);
        }).catch(function(error){
            alert(`${error}`);
        })
    }

    render(){
        let {loading, isAdmin, client} = this.state;

        let _this = this;

        let requestApprovalOption = [
            { value: 0, label: 'No Approval Required' },
            { value: 1, label: 'Require Regional Manager Approval' },
            { value: 2, label: 'Require Admin Approval' }
        ];

        if(!loading){
            return( isAdmin ?
                <div>
                    <Tooltip type="info" effect="float"/>
                    <Modal isOpen={this.state.modalOpen} style={{content: {
                        border: '0',
                        borderRadius: '4px',
                        minHeight: '10rem',
                        minWidth: '20rem',
                        left: '50%',
                        top: '50%',
                        padding: '2rem',
                        position: 'absolute',
                        transform: 'translate(-50%,-50%)',
                    }}}>
                        <div style={{'text-align': 'center', 'display': 'block', 'justify-content': 'center'}}>
                            <h1>Update Successful!</h1>
                            <Button type={'success'} message={'OK'} onPress={() => this.setState({modalOpen: false})}/>
                        </div>
                    </Modal>
                    <section className='ws-section'>
                        <h1 className="page-header">Approval Settings</h1>
                    </section>

                    <section className="ws-section">
                        <form>
                            <table className="table table-bordered notifications-preferences">
                                <thead id={'jobRequestApprovalSettingsHeader'}>
                                    <tr>
                                        <th>Job Request Approval Settings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <span>Level of approval before job request is sent out to network</span> <i className={'icon-question'} style={{'padding-left': '5px'}} data-tip={"If approval is needed before job request is sent out to network, select which role needs to approve."}/>
                                        </label>
                                        <Select options={requestApprovalOption}
                                                onChange={this.updateRequestApproval}
                                                value={this.state.requiresApprovalToSubmit}
                                                clearable={false}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <Toggle
                                                defaultChecked={this.state.client.settings.prohibitLocationManagerSubmissions}
                                                name={'requestCreated'}
                                                onChange={() => this.setState({ prohibitLocationManagerSubmissions: !this.state.prohibitLocationManagerSubmissions })} />
                                            <span className={'pad-left-sm'}>Only administrators can submit requests</span>
                                        </label>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered notifications-preferences">
                                <thead id={'multiTierApprovalLevelsHeader'}>
                                <tr>
                                    <th>Multi-tier Approval Levels</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <span>Regional Users are authorized to approve up to this amount:</span>
                                        </label>
                                        <input type={'number'}
                                               placeholder={'No Threshold'}
                                               value={this.state.adminNTE}
                                               onChange={this.updateAdminBidThreshold}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <span>Local Users are authorized to approve up to this amount:</span>
                                        </label>
                                        <input type={'number'}
                                               placeholder={'No Threshold'}
                                               value={this.state.regionalManagerNTE}
                                               onChange={this.updateRegionalManagerBidThreshold}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="text-right">
                                <button className="ws-btn ws-btn-success" type="button" onClick={() => this.handleUpdate()}>Update Settings</button>
                            </div>
                            <br/>
                            {client.hasWorksmithNTE ?
                                <table className="table table-bordered notifications-preferences">
                                    <thead>
                                    <tr>
                                        <th>NTE Settings</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <ClientNtePage clientId={client.id} readOnly={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                : null }
                        </form>
                    </section>
                </div>
                    :
                    <div>
                        <section className='ws-section'>
                            <h1 className="page-header">Approval Settings</h1>
                        </section>
                        <section className='ws-section'>
                            <table className="table table-bordered notifications-preferences">
                                <thead id={'jobRequestApprovalSettingsHeader'}>
                                <tr>
                                    <th>Job Request Approval Settings</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <span className={'pad-left-sm'}>Level of approval before job request is sent out to network: </span>
                                        </label>
                                        {requestApprovalOption.find(function(x) {return x.value == _this.state.requiresApprovalToSubmit}).label}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <span className={'pad-left-sm'}>Only administrators can submit requests: </span>
                                        </label>
                                        {this.state.prohibitLocationManagerSubmissions ? 'Yes' : 'No'}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered notifications-preferences">
                                <thead id={'multiTierApprovalLevelsHeader'}>
                                <tr>
                                    <th>Multi-tier Approval Levels</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <span>Regional Users are authorized to approve up to this amount:</span>
                                        </label>
                                        {this.state.adminNTE === null ? 'No Threshold' : numeral(this.state.adminNTE).format('$0,00.00')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                            <span>Local Users are authorized to approve up to this amount:</span>
                                        </label>
                                        {this.state.regionalManagerNTE === null ? 'No Threshold' : numeral(this.state.regionalManagerNTE).format('$0,00.00')}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
            )
        } else {
            return <Loader />
        }
    }
}

export default ClientApprovalSettings;