import React, {Component} from 'react';
import Badge from './Badge';
import {NewRequestStatus, RfpStatus} from "../Enums";

class StatusBadge extends Component {
   render(){
        let type = this.props.type;
        let message = "";

        if (!type) {
            switch (this.props.status) {
                case RfpStatus.NEW:
                    type = 'primary';
                    break;
                case RfpStatus.SOURCING:
                case NewRequestStatus.MARKET:
                case RfpStatus.WALKTHROUGH_SCHEDULED:
                case "needs vendor input":
                case NewRequestStatus.CORPORATE_PRICING_REVIEW:
                case RfpStatus.PENDING_REGIONAL_MANAGER_BID:
                case RfpStatus.PENDING_ADMIN_BID:
                    type = 'warning';
                    break;
                case RfpStatus.NEEDS_CLIENT_INPUT:
                    type = 'danger';
                    break;
                case RfpStatus.SCHEDULED:
                case NewRequestStatus.SCHEDULED:
                case NewRequestStatus.SCHEDULING:
                case "active":
                    type = 'info';
                    break;
                case RfpStatus.COMPLETED:
                    type = 'success';
                    break;
                case RfpStatus.CANCELED:
                case "lapsed":
                    type = 'plain';
                    break;
                default:
                    type = 'plain';
            }
        } else if (['primary', 'warning', 'danger', 'info', 'success', 'plain'].indexOf(type) === -1)
            type = 'plain';
        
        return (
            <Badge type={type} message={this.props.status} hollow={this.props.hollow}/>
        )
    }
}

export default StatusBadge;
