import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import FormBuilder from "./FormBuilder";
import Loader from "../Loader";
import numeral from 'numeral';
import moment from 'moment';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import {getStatesEnum} from '../../components/Utilities';
import UserService from 'worksmith/services/api/UserService';
import ClientLocationService from 'worksmith/services/api/ClientLocationService';
import ObligationService from 'worksmith/services/api/ObligationService';
import ObligationItemService from 'worksmith/services/api/ObligationItemService';
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';
import LineItemTypeService from 'worksmith/services/api/LineItemTypeService';
import ClientService from 'worksmith/services/api/ClientService';
import {ObligationType, FormFieldNames, ClientDashboardType} from "../../Enums";
import {DeepCopy, ValueIsSet} from "../../Helpers";
import {WithContext} from "../../context/GlobalContext";
import TicketItemsForm from "./hugoBoss/TicketItemsForm";


const t = require('tcomb-form');
const Form = t.form.Form;
const clientLocationService = new ClientLocationService();
const obligationService = new ObligationService();
const obligationItemGroupService = new ObligationItemGroupService();
const obligationItemService = new ObligationItemService();
const lineItemTypeService = new LineItemTypeService();
const clientService = new ClientService();
const userService = new UserService();

const DRY_CLEANING_AND_ALTERATIONS_ID = 1;
const ZIPPER_REPAIR_ID = 26;
const WATCH_REPAIR_ID = 27;
const TRENCHCOAT_REPROOFING_ID = 28;

class TicketForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticketId: props.ticketId, //Only needs to be passed in when editing an existing ticket
            obligation: props.obligation,
            dryCleaningSettings: {},
            fieldSettings: props.fieldSettings ? DeepCopy(props.fieldSettings) : [],
            disableHeader: props.disableHeader,
            injectedParentValuesThatSetChild: {},
            injectedListOptions: {},
            injectedFunctionTriggers: {},
            injectedHelperQualifiers: {},
            fullStandardPricingsList: null,
            standardPricings: null,
            lineItemTypes: null,
            showErrorMessage: false,
            generalErrorMessage: null,
            initialValue: null,
            ticketIsBeingEdited: false,
            obligationItemsCombinedPricingItems: {}
        };

        this.standardPricingsGroupedByItemType = {};
        this.itemsWithMultipleAlterationsOption = {};

        this.formBuilderRef = React.createRef();

        this.initialSetup = this.initialSetup.bind(this);
        this.getDefaultFormFieldSettings = this.getDefaultFormFieldSettings.bind(this);
        this.getLineItemTypeObject = this.getLineItemTypeObject.bind(this);
        this.flattenObject = this.flattenObject.bind(this);
        this.determinePickupDate = this.determinePickupDate.bind(this);
        this.determineReturnDate = this.determineReturnDate.bind(this);
        this.obligationInsert = this.obligationInsert.bind(this);
    }

    componentWillMount() {
        let {ticketId} = this.state;
        let _this = this;

        if (ticketId) {
            obligationItemGroupService.getEditableTicket(ticketId).then(function (data) {
                //If items come back without a standard pricing ID, we need to set the ID to 0 so that they show the 'Other' option correctly in the form
                if (ValueIsSet(data) && ValueIsSet(data.obligationItems) && data.obligationItems.length > 0) {
                    data.obligationItems.forEach((item) => {
                        if (!ValueIsSet(item.standardPricingId))
                            item.standardPricingId = 0;
                    })
                }
                _this.setState({initialValue: data, ticketIsBeingEdited: true});
            })
        }
    }

    componentDidMount() {
        let {obligation, fieldSettings} = this.state;
        let _this = this;
        let {client} = this.props.context;

        //This is the case where we are creating a ticket for an obligation that already exists, or editing an existing ticket
        if (obligation.id) {
            clientService.getTicketFormOptions(client.id).then(function (data) {
                _this.setState({fieldSettings: data}, () => {

                    let fieldSettings = data;
                    obligationService.getSummary(obligation.id).then(function (data) {
                        _this.setState({obligation: data}, () => {
                            let {obligation} = _this.state;
                            Promise.all([
                                clientLocationService.getStandardPricingsByServiceLine(obligation.clientLocation.id, obligation.serviceLine.id, obligation.vendorLocation.id),
                                userService.search(null, null, null, obligation.clientLocation.id, null, null, null),
                                clientService.getSingleDryCleaningFormConfiguration(client.id)])
                                .then(function (data) {
                                    let standardPricingsByLocation = data[0];
                                    let locationUsers = data[1];
                                    let dryCleaningSettings = data[2];

                                    _this.setState({dryCleaningSettings: dryCleaningSettings}, () => {
                                        _this.initialSetup(dryCleaningSettings, fieldSettings, standardPricingsByLocation, obligation, locationUsers);
                                    });
                                });
                        });
                    })
                });
            });
        }
        //This is the case where we don't have an obligation created yet for the ticket
        else {
            Promise.all([
                clientLocationService.getStandardPricingsByServiceLine(obligation.clientLocation.id, obligation.serviceLine.id, obligation.vendorLocation.id),
                userService.search(null, null, null, obligation.clientLocation.id, null, null, null),
                clientService.getSingleDryCleaningFormConfiguration(client.id),
                clientService.getTicketFormOptions(client.id)])
                .then(function (data) {
                    let standardPricingsByLocation = data[0];
                    let locationUsers = data[1];
                    let dryCleaningSettings = data[2];

                    // if fieldSettings are passed in as props don't update the fieldSettings from fetched configuration.
                    let newFieldSettings = fieldSettings;
                    if (fieldSettings.length === 0 ) {
                        newFieldSettings = data[3];
                        _this.setState({fieldSettings: newFieldSettings});
                    }

                    _this.setState({dryCleaningSettings: dryCleaningSettings}, () => {
                        _this.initialSetup(dryCleaningSettings, newFieldSettings, standardPricingsByLocation, obligation, locationUsers);
                    });
                });
        }
    }

    initialSetup(dryCleaningSettings, fieldSettings, standardPricingsByLocation, obligation, locationUsers) {
        let standardPricings = [];
        let _this = this;

        let injectedParentValuesThatSetChild = {
            defaultOptions: {
                customerCharge: {
                    standardPricingId: {}
                },
                title: {
                    standardPricingId: {}
                },
                lineItemTypeId: {
                    standardPricingId: {}
                }
            }
        };

        let injectedListOptions = {
            standardPricingId: {
                serviceType: {}
            },
            state: {
                defaultOptions: getStatesEnum()
            },
            lineItemTypeId: {
                defaultOptions: {}
            },
            userToCC: {
                defaultOptions: {}
            }
        };

        let injectedHelperQualifiers = {
            defaultOptions: {
                customerCharge: {
                    standardPricingId: {}
                }
            }
        };

        locationUsers.forEach(function(user) {
            injectedListOptions.userToCC.defaultOptions[user.email] = user.firstName + ' ' + user.lastName;
        });

        let specialCustomerChargeMultipliers = dryCleaningSettings.standardItemChargeMultiplier ? JSON.parse(dryCleaningSettings.standardItemChargeMultiplier) : null;
        let injectedFunctionTriggers = {};
        let standardPricingsGroupedByItemType = {};
        let itemsWithMultipleAlterationsOption = [];

        //First pass through the standard items to parse out alteration types for common item types
        standardPricingsByLocation.forEach(function(pricing) {
            let nameArray = pricing.standardItem.name.split('-');

            if (!standardPricingsGroupedByItemType[nameArray[0]]) {
                standardPricingsGroupedByItemType[nameArray[0]] = {};
            }

            if (nameArray[nameArray.length - 1].replace(/ /g, '').toLowerCase() !== 'multiplealterations') {
                standardPricingsGroupedByItemType[nameArray[0]][nameArray[nameArray.length - 1]] = pricing;
            } else {
                itemsWithMultipleAlterationsOption.push(nameArray[0]);
                standardPricingsGroupedByItemType[nameArray[0]].standardPricingId = pricing.id;
            }
        });

        for (let i = 0; i < standardPricingsByLocation.length; i++) {
            let pricing = standardPricingsByLocation[i];
            let nameArray = pricing.standardItem.name.split('-');
            let isMultipleAlterationsOption = nameArray[nameArray.length - 1].replace(/ /g, '').toLowerCase() === 'multiplealterations';

            //If this is pricing for an item type that has a 'Multiple Alterations' option and isn't the 'Multiple Alterations' option itself
            //we can continue to the next iteration so that it isn't added ot the dropdown of standard items they can choose from
            if (itemsWithMultipleAlterationsOption.indexOf(nameArray[0]) !== -1 && !isMultipleAlterationsOption) {
                if (pricing.unitCustomerCharge !== null && pricing.unitCustomerCharge !== undefined) {
                    injectedHelperQualifiers.defaultOptions.customerCharge.standardPricingId[pricing.id] = numeral(pricing.unitCustomerCharge).format('$0,00.00')
                } else {
                    injectedHelperQualifiers.defaultOptions.customerCharge.standardPricingId[pricing.id] = " No Standard Set"
                }
                continue;
            }

            pricing.description = '';
            if (dryCleaningSettings.showStandardItemType)
                pricing.description = pricing.standardItem.lineItemType.name + ' - ';

            pricing.description += isMultipleAlterationsOption ? nameArray[0] : pricing.standardItem.name;

            standardPricings.push(pricing);

            injectedParentValuesThatSetChild.defaultOptions.customerCharge.standardPricingId[pricing.id] = typeof pricing.unitCustomerCharge === 'number' ? pricing.unitCustomerCharge.toString() : pricing.unitCustomerCharge;
            injectedParentValuesThatSetChild.defaultOptions.title.standardPricingId[pricing.id] = pricing.standardItem.name;
            injectedParentValuesThatSetChild.defaultOptions.lineItemTypeId.standardPricingId[pricing.id] = pricing.standardItem.lineItemType.id;

            if (specialCustomerChargeMultipliers) {
                for (let key in specialCustomerChargeMultipliers) {
                    if (specialCustomerChargeMultipliers.hasOwnProperty(key)) {
                        let adjustedPrice = pricing.unitCustomerCharge ? Math.round(pricing.unitCustomerCharge * specialCustomerChargeMultipliers[key] * 100) / 100 : null;
                        if (!injectedParentValuesThatSetChild[key]) {
                            injectedParentValuesThatSetChild[key] = {
                                customerCharge: {
                                    standardPricingId: {}
                                }
                            };
                        }
                        injectedParentValuesThatSetChild[key].customerCharge.standardPricingId[pricing.id] = (typeof adjustedPrice === 'number' ? adjustedPrice.toString() : adjustedPrice);

                        if (!injectedHelperQualifiers[key]) {
                            injectedHelperQualifiers[key] = {
                                customerCharge: {
                                    standardPricingId: {}
                                }
                            };
                        }
                        injectedHelperQualifiers[key].customerCharge.standardPricingId[pricing.id] = adjustedPrice ? numeral(adjustedPrice).format('$0,00.00') + " (Pricing reflects " + (100 - (specialCustomerChargeMultipliers[key] * 100)) + "% discount)" : " No Standard Set";
                    }
                }
            }

            if (!injectedListOptions.standardPricingId.serviceType[pricing.standardItem.lineItemType.name])
                injectedListOptions.standardPricingId.serviceType[pricing.standardItem.lineItemType.name] = {};

            injectedListOptions.standardPricingId.serviceType[pricing.standardItem.lineItemType.name][pricing.id] = pricing.description;
            if (pricing.unitCustomerCharge !== null && pricing.unitCustomerCharge !== undefined) {
                injectedHelperQualifiers.defaultOptions.customerCharge.standardPricingId[pricing.id] = numeral(pricing.unitCustomerCharge).format('$0,00.00')
            } else {
                injectedHelperQualifiers.defaultOptions.customerCharge.standardPricingId[pricing.id] = " No Standard Set"
            }

            if (isMultipleAlterationsOption) {
                if (!injectedFunctionTriggers[FormFieldNames.STANDARD_PRICING_ID]) {
                    injectedFunctionTriggers[FormFieldNames.STANDARD_PRICING_ID] = {};
                }

                if (standardPricingsGroupedByItemType[nameArray[0]]) {
                    injectedFunctionTriggers[FormFieldNames.STANDARD_PRICING_ID][pricing.id] = (nextValue, currentValue, itemIndex, callback) => {
                        let {obligationItemsCombinedPricingItems} = this.state;
                        let alterationTypes = standardPricingsGroupedByItemType[nameArray[0]];

                        let options = {};
                        Object.keys(alterationTypes).forEach(function(key) {
                            if (key !== 'standardPricingId')
                                options[key] = t.Boolean;
                        });

                        let formStruct = t.struct(options);
                        let formOptions = {};

                        let localValue = {};

                        let onOk = () => {
                            let totalCost = 0;
                            let description = '';

                            let selectedPricingIds = [];
                            Object.keys(localValue).forEach(function(key) {
                                if (localValue[key]) {
                                    totalCost += typeof alterationTypes[key].unitCustomerCharge === 'number' ? alterationTypes[key].unitCustomerCharge : Number(alterationTypes[key].unitCustomerCharge);
                                    description += '- ' + key + '\n';
                                    selectedPricingIds.push(alterationTypes[key].id);
                                }
                            });

                            if (selectedPricingIds.length > 0) {
                                obligationItemsCombinedPricingItems[itemIndex] = selectedPricingIds;
                                injectedParentValuesThatSetChild.defaultOptions.customerCharge.standardPricingId[pricing.id] = totalCost.toString();
                                nextValue.obligationItems[itemIndex][FormFieldNames.ALTERATIONS] = description;
                                _this.setState({injectedParentValuesThatSetChild}, callback(nextValue));
                                ReactDOM.render(null, document.getElementById('form-modal'));
                            } else {
                                Modal.warning({
                                    title: 'Please select at least one alteration!'
                                });
                            }
                        };

                        let onCancel = () => {
                            callback(currentValue);
                            ReactDOM.render(null, document.getElementById('form-modal'));
                        };

                        ReactDOM.render(
                            React.createElement(
                                Modal,
                                {
                                    title: <h3>{pricing.standardItem.name}</h3>,
                                    visible: true,
                                    onOk: onOk,
                                    onCancel: onCancel
                                },
                                [React.createElement(
                                    'p',
                                    {},
                                    'Choose which alterations need to be made to the garment.'
                                ), React.createElement(
                                    Form,
                                    {
                                        type: formStruct,
                                        value: localValue,
                                        onChange: (value, fieldInfo) => localValue = value,
                                        options: formOptions
                                    }
                                )]
                            ),
                            document.getElementById('form-modal')
                        )
                    };
                }
            }

            this.standardPricingsGroupedByItemType = standardPricingsGroupedByItemType;
            this.itemsWithMultipleAlterationsOption = itemsWithMultipleAlterationsOption;
        }

        let standardPricingsToObject = standardPricings.reduce(function (map, obj) {
            map[parseInt(obj.id, 10)] = obj.description;
            return map;
        }, {});

        let settingsToInclude = [];

        //Here we check to see if the client has any options set up that are specific to a client location, vendor location that is an in-house vendor pair
        fieldSettings.forEach(function(setting) {
            if (setting.serviceLineId == obligation.serviceLine.id && (setting.isInHouseVendor && obligation.vendorLocation.isBurberryVendor) && setting.vendorLocationId == obligation.vendorLocation.id && setting.clientLocationId == obligation.clientLocation.id)
                settingsToInclude.push(setting);
        });

        //If we didn't find any settings yet we check to see if the client has any options set up that are specific to a client location, vendor location pair
        if (settingsToInclude.length === 0) {
            fieldSettings.forEach(function (setting) {
                if (setting.serviceLineId == obligation.serviceLine.id && setting.vendorLocationId == obligation.vendorLocation.id && setting.clientLocationId == obligation.clientLocation.id && !setting.isInHouseVendor)
                    settingsToInclude.push(setting);
            });
        }

        //If we didn't find any settings yet we check to see if the client has any options set up that are specific to a client location for all in house vendors
        if (settingsToInclude.length === 0) {
            fieldSettings.forEach(function (setting) {
                if (setting.serviceLineId == obligation.serviceLine.id && (setting.isInHouseVendor && obligation.vendorLocation.isBurberryVendor) && setting.clientLocationId == obligation.clientLocation.id && !setting.vendorLocationId)
                    settingsToInclude.push(setting);
            });
        }

        //If we didn't find any settings yet we check to see if the client has any options set up that are specific to a vendor location that is an in-house vendors
        if (settingsToInclude.length === 0) {
            fieldSettings.forEach(function (setting) {
                if (setting.serviceLineId == obligation.serviceLine.id && (setting.isInHouseVendor && obligation.vendorLocation.isBurberryVendor) && setting.vendorLocationId == obligation.vendorLocation.id && !setting.clientLocationId)
                    settingsToInclude.push(setting);
            });
        }

        //If we didn't find any settings yet we check to see if the client has any options set up that are specific to a client location only
        if (settingsToInclude.length === 0) {
            fieldSettings.forEach(function (setting) {
                if (setting.serviceLineId == obligation.serviceLine.id && setting.clientLocationId == obligation.clientLocation.id && !setting.vendorLocationId && !setting.isInHouseVendor)
                    settingsToInclude.push(setting);
            });
        }

        //If we didn't find any settings yet we check to see if the client has any options set up that are specific to a vendor location only
        if (settingsToInclude.length === 0) {
            fieldSettings.forEach(function (setting) {
                if (setting.serviceLineId == obligation.serviceLine.id && setting.vendorLocationId == obligation.vendorLocation.id && !setting.clientLocationId && !setting.isInHouseVendor)
                    settingsToInclude.push(setting);
            });
        }

        //If we didn't find any settings yet we check to see if the client has any options set up that are specific to all in house vendors
        if (settingsToInclude.length === 0) {
            fieldSettings.forEach(function (setting) {
                if (setting.serviceLineId == obligation.serviceLine.id && (setting.isInHouseVendor && obligation.vendorLocation.isBurberryVendor) && !setting.clientLocationId && !setting.vendorLocationId)
                    settingsToInclude.push(setting);
            });
        }

        //If their aren't any settings to include yet then there aren't any options set up specifically for a vendor or in-house vendors, so we check for any options set up for the specific service line
        if (settingsToInclude.length === 0) {
            fieldSettings.forEach(function (setting) {
                if (setting.serviceLineId == obligation.serviceLine.id && !setting.isInHouseVendor && !setting.vendorLocationId && !setting.clientLocationId)
                    settingsToInclude.push(setting);
            });
        }

        //Determine what groups have been added in the current settings
        let includedGroups = settingsToInclude.reduce((set, setting) => {
            set.add(setting.group);
            return set;
        }, new Set());

        //If there are any global settings (settings not restricted to a specific location) that haven't had the groups included yet, include them now
        fieldSettings.forEach((setting) => {
            if (!ValueIsSet(setting.clientlocationId) && !includedGroups.has(setting.group))
                settingsToInclude.push(setting);
        });

        //If there still aren't any settings to include then there aren't any options set up yet for this client, so we go to the default form
        if (settingsToInclude.length < 1) {
            settingsToInclude = settingsToInclude.concat(this.getDefaultFormFieldSettings(obligation.serviceLine.name));
        }

        let fullStandardPricingsList = standardPricingsByLocation;

        lineItemTypeService.findGroupedByServiceLineId([obligation.serviceLine.id]).then(function(data) {
            let lineItemTypes = data[obligation.serviceLine.id].map(function(lineItemType) {
                return {
                    id: lineItemType.id,
                    name: lineItemType.name
                }
            });

            _this.setState({fieldSettings: settingsToInclude, lineItemTypes, standardPricings: standardPricingsToObject, fullStandardPricingsList, injectedParentValuesThatSetChild, injectedListOptions, injectedHelperQualifiers, injectedFunctionTriggers});
        });
    }

    getDefaultFormFieldSettings(serviceLineName) {
        let defaultSettings = [];

        let ticketNumberSetting = {
            field: {
                id: 1,
                name: 'Ticket Number',
                metadataName: 'groupId'
            },
            group: serviceLineName,
            status: 'Not Required',
            order: 1,
            type: 'String',
            listItemField: false,
            validationMessage: 'Field is invalid!'
        };
        defaultSettings.push(ticketNumberSetting);

        let nameSetting = {
            field: {
                id: 2,
                name: 'Customer Name',
                metadataName: 'name'
            },
            group: serviceLineName,
            status: 'Not Required',
            order: 2,
            type: 'String',
            listItemField: false,
            validationMessage: 'Field is invalid!'
        };
        defaultSettings.push(nameSetting);

        let deliveryTypeSetting = {
            field: {
                id: 3,
                name: 'Delivery Type',
                metadataName: 'deliveryType'
            },
            group: serviceLineName,
            status: 'Required',
            order: 3,
            type: 'List',
            listItemField: false,
            validationMessage: 'A delivery type must be selected!',
            listOptions: {
                "defaultOptions": {
                    "pick up from store": "pick up from store",
                    "deliver via direct ship": "Deliver via direct ship (e.g. UPS)",
                    "deliver via courier service": "Deliver via courier service"
                }
            }
        };
        defaultSettings.push(deliveryTypeSetting);

        let itemsSetting = {
            field: {
                id: 6,
                name: 'Items',
                metadataName: 'obligationItems'
            },
            group: serviceLineName,
            status: 'Not Required',
            order: 6,
            type: 'Items',
            listItemField: false,
            validationMessage: 'Field is invalid!'
        };
        defaultSettings.push(itemsSetting);

        let standardItemSetting = {
            field: {
                id: 7,
                name: 'Standard Item',
                metadataName: 'standardPricingId'
            },
            group: serviceLineName,
            status: 'Required',
            order: 7,
            type: 'List',
            listItemField: true,
            column: 0,
            row: 0,
            validationMessage: 'Standard Item must be selected!',
            listOptions: {
                "defaultOptions": {
                    "0": "Other"
                }
            }
        };
        defaultSettings.push(standardItemSetting);

        let itemNameSetting = {
            field: {
                id: 8,
                name: 'Item',
                metadataName: 'title'
            },
            group: serviceLineName,
            status: 'Required',
            order: 8,
            type: 'String',
            listItemField: true,
            column: 0,
            row: 0,
            columnWidth: 4,
            validationMessage: 'Item name must be set!',
            placeholderString: 'Name',
            hideLabel: true,
            parentValuesThatShowChild: {
                "7": "0"
            }
        };
        defaultSettings.push(itemNameSetting);

        let lineItemTypeSetting = {
            field: {
                id: 9,
                name: 'Line Item Type',
                metadataName: 'lineItemTypeId'
            },
            group: serviceLineName,
            status: 'Required',
            order: 9,
            type: 'List',
            listItemField: true,
            column: 0,
            row: 0,
            validationMessage: 'Line Item Type must be set!',
            hideLabel: true,
            parentValuesThatShowChild: {
                "7": "0"
            },
            // Bad solution, talk to Steve if this continues to be an issue
            listOptions: {"defaultOptions":{"1":"Dry Cleaning", "2":"Alterations", "3":"Laundered"}}
        };
        defaultSettings.push(lineItemTypeSetting);

        let descriptionSetting = {
            field: {
                id: 10,
                name: 'Description of service needed',
                metadataName: 'description'
            },
            group: serviceLineName,
            status: 'Not Required',
            order: 10,
            type: 'Text Area',
            listItemField: true,
            column: 1,
            row: 0,
            columnWidth: 5,
            validationMessage: 'A description must be given!'
        };
        defaultSettings.push(descriptionSetting);

        let quantitySetting = {
            field: {
                id: 11,
                name: 'Quantity',
                metadataName: 'quantity'
            },
            group: serviceLineName,
            status: 'Required',
            order: 11,
            type: 'Positive Non-zero Number',
            listItemField: true,
            column: 2,
            row: 0,
            columnWidth: 3,
            validationMessage: 'A quantity must be set!'
        };
        defaultSettings.push(quantitySetting);

        return defaultSettings;
    }

    formatSpecialFields(setting, nonMetaDataObject, metadata) {
        //If a boolean checkbox was never clicked than it won't have any value when returned from the form, so if it has no value we should set it to false
        if (setting.type === 'Boolean' && setting.status !== 'Not Used' && nonMetaDataObject[setting.field.metadataName] === undefined && !metadata[setting.field.metadataName])
            metadata[setting.field.metadataName] = false;
        //If a date has been entered as part of the form it will come back as an array of ints representing day, month, and year so it needs to be formatted before being returned
        else if (setting.type === 'Date') {
            if (nonMetaDataObject[setting.field.metadataName])
                nonMetaDataObject[setting.field.metadataName] = moment(nonMetaDataObject[setting.field.metadataName]).format('YYYY-MM-DD');
            else if (metadata[setting.field.metadataName])
                metadata[setting.field.metadataName] = moment(metadata[setting.field.metadataName]).format('YYYY-MM-DD');
        }
    }

    formatLoadedFields(setting, startingValue) {
        if (setting.group === startingValue.ticketType) {
            if (setting.type === 'Date') {
                if (!setting.listItemField && startingValue[setting.field.metadataName])
                    startingValue[setting.field.metadataName] = moment(startingValue[setting.field.metadataName]).toDate();

                else if (setting.listItemField && startingValue.obligationItems && startingValue.obligationItems[setting.field.metadataName])
                    startingValue.obligationItems[setting.field.metadataName] = moment(startingValue.obligationItems[setting.field.metadataName]).toDate();
            }
            else if (setting.type === 'Boolean') {
                if (!setting.listItemField && typeof startingValue[setting.field.metadataName] === 'string')
                    startingValue[setting.field.metadataName] = startingValue[setting.field.metadataName] !== 'false';

                else if (setting.listItemField && startingValue.obligationItems && typeof startingValue.obligationItems[setting.field.metadataName] === 'string')
                    startingValue.obligationItems[setting.field.metadataName] = startingValue.obligationItems[setting.field.metadataName] !== 'false';
            }
        }
    }

    getLineItemTypeObject(lineItemId) {
        let {lineItemTypes} = this.state;
        let lineItemType = {};
        lineItemTypes.forEach(function (item) {
            if (item.id === parseInt(lineItemId, 10)) {
                lineItemType = item;
            }
        });

        return lineItemType;
    }

    flattenObject(ob) {
        let _this = this;
        let toReturn = {};

        for (let i in ob) {
            if (!ob.hasOwnProperty(i)) continue;

            if ((typeof ob[i]) === 'object') {
                let flatObject = _this.flattenObject(ob[i]);
                for (let x in flatObject) {
                    if (!flatObject.hasOwnProperty(x) || x === 'id') continue;

                    toReturn[x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    };

    async determinePickupDate(serviceLineId, isInHouseTailor, clientLocationId, vendorLocationId) {
        if (serviceLineId == DRY_CLEANING_AND_ALTERATIONS_ID && !isInHouseTailor) {
            let data = await obligationService.findNextServiceDate(clientLocationId, serviceLineId, vendorLocationId);
            return data;
        } else {
            return {
                pickupDate: moment(),
                returnDate: moment().add(7, 'days')
            };
        }
    }

    determineReturnDate(pickupDate, urgency, serviceLineId, isInHouseTailor, returnDate, requestedReturnDate) {
        let {client} = this.props.context;

        if (serviceLineId == DRY_CLEANING_AND_ALTERATIONS_ID && isInHouseTailor) {
            if(requestedReturnDate !== undefined && requestedReturnDate !== null) {
                let requestedReturn = moment(requestedReturnDate, 'YYYY-MM-DD');

                if(requestedReturn.isAfter(moment()))
                    return requestedReturn;
            }

            if (urgency === 'Rush')
                return moment().add(2, 'days');
            else
                return moment().add(14, 'days');
        } else if (serviceLineId == DRY_CLEANING_AND_ALTERATIONS_ID) {
            let pickup = pickupDate.clone();

            let additionalDays = client.configurationSettings.dashboardType === ClientDashboardType.HUGO_BOSS ? 5 : 7;

            if (urgency === 'Rush')
                return pickup.add(3, 'days');
            else
                return returnDate === undefined || returnDate === null ? moment().add(additionalDays, 'days') : moment(returnDate);
        } else {
            if (urgency === 'Rush')
                return moment().add(3, 'days');
            else
                return moment().add(14, 'days');
        }
    }

    obligationInsert(newObligationData, submitData, files) {
        let _this = this;
        let {obligation} = this.state;
        obligationService.insert(newObligationData).then(data => {
            let returnedObligation = data;
            obligation.id = returnedObligation.id;

            submitData.obligationId = returnedObligation.id;
            submitData.obligationItems.forEach(item => {
                item.obligationId = returnedObligation.id;
            });

            obligationService.addItemGroup(obligation.id, submitData, files).then(data => {
                if (!files || files.length === 0) {
                    notification['success']({
                        message: 'ticket added!'
                    });
                    _this.props.onSuccess()
                } else {
                    let attachmentPromises = [];
                    data.obligationItems.sort((a, b) => {return a.id - b.id}).forEach(function(item, index) {
                        if (files[index].length > 0)
                            attachmentPromises.push(obligationItemService.addAttachments(item.id, files[index]));
                    });

                    Promise.all(attachmentPromises).then(function(data) {
                        notification['success']({
                            message: 'ticket added!'
                        });
                        _this.props.onSuccess()
                    });
                }
            });
        })
    }

    submit(value, ticketType, files) {
        let {obligation, fieldSettings, ticketIsBeingEdited, obligationItemsCombinedPricingItems, dryCleaningSettings} = this.state;
        let {getLineItemTypeObject, obligationInsert} = this;
        let _this = this;

        if (!value.obligationItems || value.obligationItems.length < 1)
            this.setState({generalErrorMessage: "At least one item must be added to the ticket!"});
        else {
            let modifiedObligationItems = [];
            let customerCharge = null;

            value.obligationItems.forEach(function(item, index) {
                let modifiedItem = {
                    id: item.id,
                    title: item.title,
                    quantity: item.quantity,
                    standardPricingId: item.standardPricingId != '0' ? item.standardPricingId : null,
                    obligationId: obligation.id ? obligation.id : null,
                    lineItemType: getLineItemTypeObject(item.lineItemTypeId),
                    description: item.description,
                    standardPricingIds: ValueIsSet(item.standardPricingIds) ? item.standardPricingIds : obligationItemsCombinedPricingItems[index]
                };

                let metadata = {};

                //Have to do a first pass in case customer charge needs to be adjusted based on other fields
                for (let key in item) {
                    if (item.hasOwnProperty(key)) {
                        if (key === 'priceClientCharged' && (item[key] !== 'Not Waived' && item[key] !== 'Client Charged'))
                            item.customerCharge = '0';
                    }
                }

                for (let key in item) {
                    if (item.hasOwnProperty(key) && key !== 'lineItemTypeId') {
                        if ((modifiedItem[key] === undefined) && key !== 'standardPricingId')
                            metadata[key] = item[key];

                        if (key === 'customerCharge') {
                            if (customerCharge === null)
                                customerCharge = 0;

                            customerCharge += Number(item.customerCharge || 0) * Number(item.quantity || 1)
                        }

                        // If the client_ticket_field-options "individualItemAssignedTailor" is set up the "Assign Tailor" metadata field value sets the vendorLocationTailor on the obligationItem.
                        // After vendorLocationTailor is set the metadata individualItemAssignedTailor field is deleted because we do not want the individualItemAssignedTailor value to be saved as metadata.
                        if (key === 'individualItemAssignedTailor') {
                                modifiedItem.vendorLocationTailor = {};
                            if (metadata.individualItemAssignedTailor === "") {
                                // Setting the vendorLocationTailor.id equal to 0 will update the obligationItem.vendorLocationTailor value to null.
                                modifiedItem.vendorLocationTailor.id = 0;
                            } else {
                                modifiedItem.vendorLocationTailor.id = parseInt(metadata.individualItemAssignedTailor);
                            }

                            delete metadata.individualItemAssignedTailor;
                        }
                    }
                }

                //Certain fields need to be formatted based on type so we need to do this since by default all metadata is treated the same
                fieldSettings.forEach(function (setting) {
                    if (setting.listItemField && setting.group === ticketType) {
                        _this.formatSpecialFields(setting, modifiedItem, metadata);
                    }
                });

                modifiedItem.metadata = metadata;
                modifiedObligationItems.push(modifiedItem);
            });

            let submitData = {
                id: value.id,
                obligationId: obligation.id ? obligation.id : null,
                groupId: value.groupId,
                ticketType: ticketType,
                name: dryCleaningSettings.showCustomNameFieldToVendors ? (value.name || value.employeeName || value.clientName) : value.name,
                deliveryType: value.deliveryType ? value.deliveryType : 'pick up from store',
                urgency: value.urgency,
                //because booleans start off as undefined and stay that way unless changed at least once we need to check if the value was ever set
                sendEmailReceipt: value.sendEmailReceipt ? value.sendEmailReceipt : false,
                sendUpdatesToCustomer: value.sendUpdatesToCustomer ? value.sendUpdatesToCustomer : false,
                obligationItems: modifiedObligationItems
            };

            let metadata = {};

            for (let key in value) {
                if (value.hasOwnProperty(key)) {
                    if (submitData[key] === undefined && key !== 'onSiteVisit')
                        metadata[key] = value[key];
                }
            }

            //Certain fields need to be formatted based on type so we need to do this since by default all metadata is treated the same
            fieldSettings.forEach(function (setting) {
                if (!setting.listItemField && setting.group === ticketType && setting.field.metadataName !== 'onSiteVisit') {
                    _this.formatSpecialFields(setting, submitData, metadata);
                }
            });

            if (customerCharge !== null)
                metadata.customerCharge = customerCharge;

            submitData.metadata = metadata;

            if (obligation.id) {
                if (!ticketIsBeingEdited) {
                    obligationService.addItemGroup(obligation.id, submitData, files).then(function (data) {
                        if (!files || files.length === 0) {
                            notification['success']({
                                message: 'ticket added!'
                            });
                            _this.props.onSuccess()
                        } else {
                            let attachmentPromises = [];
                            data.obligationItems.sort((a, b) => {return a.id - b.id}).forEach(function(item, index) {
                                if (files[index].length > 0)
                                    attachmentPromises.push(obligationItemService.addAttachments(item.id, files[index]));
                            });

                            Promise.all(attachmentPromises).then(function(data) {
                                notification['success']({
                                    message: 'ticket added!'
                                });
                                _this.props.onSuccess()
                            });
                        }
                    });
                } else {
                    obligationItemGroupService.update(submitData).then(function (data) {
                        notification['success']({
                            message: 'ticket updated!'
                        });
                        _this.props.onSuccess()
                    })
                }
            }
            //This is the case for tickets created using the Burberry dashboard
            else {
                if (_this.props.controlObligationInsert) {
                    const obligationInsertData = { ...obligation};
                    obligationInsertData.vendorLocation = null;
                    obligationInsertData.clientLocation = null;
                    obligationInsertData.serviceLine = null;

                    obligationInsert(obligationInsertData, submitData, files);
                } else {
                    let serviceLineId = obligation.serviceLine.id;
                    let isBurberryVendor = obligation.vendorLocation.isBurberryVendor;
                    let clientLocationId = obligation.clientLocation.id;
                    let vendorLocationId = obligation.vendorLocation.id;
                    let requestedReturnDate = metadata.datePromised;

                    _this.determinePickupDate(serviceLineId, isBurberryVendor, clientLocationId, vendorLocationId).then(function (pickupInfo) {
                        let pickupDate = moment(pickupInfo.pickupDate);
                        let returnDate = _this.determineReturnDate(pickupDate, value.urgency, serviceLineId, isBurberryVendor, pickupInfo.returnDate, requestedReturnDate);

                        let newObligationData = {
                            id: 0,
                            clientId: obligation.client.id,
                            serviceLineId: serviceLineId,
                            title: "Supplementary Service",
                            timeframe: {
                                startTime: null,
                                endTime: null
                            },
                            pricingType: 'Itemized',
                            status: 'pending',
                            obligationMetadata: {
                                "Return date": returnDate.format('YYYY-MM-DD')
                            },
                            obligationItemGroups: [],
                            storeProfileId: clientLocationId,
                            vendorLocationSummary: {id: vendorLocationId},
                            obligationDate: pickupDate.format('YYYY-MM-DD'),
                            type: value.onSiteVisit ? ObligationType.ON_SITE : ObligationType.NORMAL
                        };

                        obligationInsert(newObligationData, submitData, files);
                    });
                }
            }
        }
    }

    hugoBossAddItemMenu = () => {
        let {formBuilderRef} = this;
        let {value} = formBuilderRef.current.state;

        ReactDOM.render(
            <TicketItemsForm
                currentItemsValue={value.obligationItems}
                standardPricingsGroupedByItemType={this.standardPricingsGroupedByItemType}
                itemsWithMultipleAlterationsOption={this.itemsWithMultipleAlterationsOption}
                onSubmit={(obligationItems) => {
                    value.obligationItems = obligationItems;

                    formBuilderRef.current.onChange(value);
                }}
            />,
            document.getElementById('form-modal')
        )
    };

    render(){
        let {
            generalErrorMessage,
            fieldSettings,
            dryCleaningSettings,
            disableHeader,
            standardPricings,
            fullStandardPricingsList,
            obligation,
            injectedParentValuesThatSetChild,
            injectedListOptions,
            injectedHelperQualifiers,
            injectedFunctionTriggers,
            initialValue,
            ticketIsBeingEdited
        } = this.state;


        let _this = this;
        let {client} = this.props.context;
        let {formBuilderRef} = this;

        let usesHugoBossDashboard = client.configurationSettings.dashboardType === ClientDashboardType.HUGO_BOSS;

        let startingValue = {};
        if (ticketIsBeingEdited) {
            for (let key in initialValue) {
                if (initialValue.hasOwnProperty(key)) {
                    if (key === 'metadata') {
                        for (let metadataKey in initialValue.metadata) {
                            if (initialValue.metadata.hasOwnProperty(metadataKey)) {
                                startingValue[metadataKey] = initialValue.metadata[metadataKey];
                            }
                        }
                    }
                    else if (key === 'obligationItems') {
                        startingValue.obligationItems = [];
                        initialValue.obligationItems.forEach(function (item) {
                            let flattenedItem = _this.flattenObject(item);
                            startingValue.obligationItems.push(flattenedItem);
                        })
                    }
                    else
                        startingValue[key] = initialValue[key];
                }
            }

            fieldSettings.forEach(function (setting) {
                _this.formatLoadedFields(setting, startingValue);
            })
        } else if (usesHugoBossDashboard) {
            fieldSettings.forEach((setting) => {
                if(setting.type === 'Date') {
                    startingValue[setting.field.metadataName] = [moment().format('YYYY'), '', ''];
                }
            })
        }

        if(obligation && standardPricings && fullStandardPricingsList && fieldSettings.length > 0) {
            return (
                <section className={'ws-section'} style={_this.props.style}>
                    <FormBuilder submit={(value, ticketType, files) => this.submit(value, ticketType, files)}
                                 submitButtonText={'Submit Ticket'}
                                 submitButtonDisabledText={'Submitting...'}
                                 submitDisabled={false}
                                 generalErrorMessage={generalErrorMessage}
                                 fullStandardPricingsList={fullStandardPricingsList}
                                 standardPricings={standardPricings}
                                 fieldSettings={fieldSettings}
                                 injectedParentValuesThatSetChild={injectedParentValuesThatSetChild}
                                 injectedListOptions={injectedListOptions}
                                 injectedHelperQualifiers={injectedHelperQualifiers}
                                 injectedFunctionTriggers={injectedFunctionTriggers}
                                 isSelfService={obligation.isForSelfServiceClient}
                                 startingValue={startingValue}
                                 serviceLineName={obligation.serviceLine.name}
                                 showAttachments={dryCleaningSettings.allowTicketAttachments}
                                 addItemButtonName={ client.id == 140 ? "Add another piece of merchandise to this work order" : usesHugoBossDashboard ? "Add/Edit Items" : "Add Item" }
                                 addItemButtonFunction={usesHugoBossDashboard ? this.hugoBossAddItemMenu : undefined}
                                 locationTailors={obligation.vendorLocation.locationTailors}
                                 ref={formBuilderRef}
                                 formHeader={ disableHeader ? null :
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div>
                                                            <h2>{obligation.clientLocation.name}</h2>
                                                        </div>
                                                        <div>
                                                            <h3>{obligation.clientLocation.addressLineOne}
                                                                <span
                                                                    data-ng-if="obligation.clientLocation.addressLineTwo != null">{obligation.clientLocation.addressLineTwo}</span>
                                                            </h3>
                                                        </div>
                                                        <div>{obligation.clientLocation.city}, {obligation.clientLocation.state} {obligation.clientLocation.zip} <span data-ng-if="obligation.clientLocation.country != null">{obligation.clientLocation.country}</span></div>
                                                        <div>Service by <strong>{obligation.vendorLocation.name}</strong></div>
                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>
                                            }/>
                </section>
            )
        } else {
            return <Loader/>
        }
    }
}

TicketForm.propTypes = {
    obligation: PropTypes.object.isRequired
};

TicketForm.defaultProps = {
    onSuccess: () => null
};

export default WithContext(TicketForm);