import React from 'react';
import {useTheme} from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import {DayPickerSingleDateController} from "react-dates";
import PropTypes from 'prop-types';

import BreakpointEnum from "../../enums/BreakpointEnum";
import {withTheme} from "../Theme/ThemeProvider";

const SingleDateCalendarBreakpointWrapper = (props) => {
    const theme = useTheme();
    const useSingleCalendar = useMediaQuery(theme.breakpoints.down(BreakpointEnum.MD));

    return (
        <DayPickerSingleDateController
            numberOfMonths={useSingleCalendar ? 1 : 2}
            {...props}/>
    )
};

SingleDateCalendarBreakpointWrapper.defaultProps = {
    hideKeyboardShortcutsPanel: true
};

SingleDateCalendarBreakpointWrapper.propTypes = {
    date: PropTypes.instanceOf(moment),
    focused: PropTypes.bool,
    hideKeyboardShortcutsPanel: PropTypes.bool,
    initialVisibleMonth: PropTypes.func,
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,
    isOutsideRange: PropTypes.func,
    onDateChange: PropTypes.func.isRequired,
    noBorder: PropTypes.bool,
    onFocusChange: PropTypes.func,
    renderCalendarInfo: PropTypes.func,
    renderMonthElement: PropTypes.func,
    renderNavPrevButton: PropTypes.func,
    renderNavNextButton: PropTypes.func,
};

export default withTheme(SingleDateCalendarBreakpointWrapper);