export const ValueIsSet = (object) => {
    return object !== undefined && object !== null;
};

export const ObjectHasEntries = (object) => {
    return object.constructor === Object && Object.entries(object).length > 0;
};

export const Debounce = (fn, interval, ignoreFunction) => {
    let timeout;

    return function() {
        const functionCall = () => fn.apply(this, arguments);

        if (ignoreFunction)
            ignoreFunction();

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, interval);
    }
};

//This method allows for the copying of objects wih no connection by reference to the original object
//The downside to this method is that it only works on JSON safe objects (ex. if you copy an object that contains a moment, the copy will replace the moment with a date string)
//NOTE: If you are copying an array it is better to just use arrayName.slice()
export const DeepCopy = (object) => {
    return JSON.parse(JSON.stringify(object));
};

//This method allows for copying of objects, but any nested values will be copied by reference
//This copy is preferable to the DeepCopy as long as the nested values aren't going to cause an issue
//NOTE: If you are copying an array it is better to just use arrayName.slice()
export const ShallowCopy = (object) => {
    return Object.assign({}, object);
};

export const NumberToFixedDecimals = (number, decimals) => {
    return Number.parseFloat(number).toFixed(decimals);
};

export const GetNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

export const GetDollarString = (number, roundDown) => {
    if (number >= 0) {
        if (roundDown) {
            return '$' + GetNumberWithCommas(Math.floor(number));
        } else {
            return '$' + GetNumberWithCommas(NumberToFixedDecimals(number, 2));
        }
    } else {
        if (roundDown) {
            return '-$' + GetNumberWithCommas(Math.floor(number));
        } {
            return '-$' + GetNumberWithCommas(NumberToFixedDecimals(Math.abs(number), 2));
        }
    }
};

export const Uppercase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const StringHasCharacters = (string) => {
    return string.trim() !== '';
};

export const Coalesce = (...objects) => {
    //This for loop structure is the most efficient way of iterating through an array in JavaScript
    for (let i = 0, n = objects.length; i < n; i++) {
        if (ValueIsSet(objects[i]))
            return objects[i];
    }

    return null;
};

export const StringHasCurrencyFormat = (string) => {
    return /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{0,2})?$/.test(string);
};

export const StringHasNumberFormat = (string) => {
    return /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]*)?$/.test(string);
};

export const StringHasEmailFormat = (string) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(string);
};

export const DefaultSearchRegex = (string) => {
    return new RegExp('.*'+string.toLowerCase()+'.*','g');
};

export const Range = (min, max) => [...Array(max + 1).keys()].slice(min, max + 1);