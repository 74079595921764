import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContentText from "@mui/material/DialogContentText";
import styled from "styled-components";

import Button, {ButtonVariant} from '../Button/Button.web';
import {withTheme} from '../Theme/ThemeProvider';
import Text from '../Text/Text.web.js';
import {JavascriptType} from 'worksmith/enums/GenericEnums';
import {TextVariant} from 'worksmith/components/Text/Text';
import {ValueIsSet} from 'worksmith/helpers/GenericHelpers';
import CustomPropTypes from 'worksmith/custom-prop-types/CustomPropTypes';
import {TextColor} from "../Text/Text.web";
import {DialogSize} from "../../enums/DialogSize";

const AlertDialog = (props) => {

    const {
        acceptDisabled,
        acceptText,
        body,
        cancelDisabled,
        cancelText,
        children,
        customButtons,
        debounceAccept,
        disableBackdropClick,
        disableEscapeKeyDown,
        disablePortal,
        fullScreenOnMobile,
        fullScreen,
        fullWidth,
        open,
        keepMounted,
        onCancel,
        onClose,
        onAccept,
        onAcceptIgnoreDebounce,
        PaperProps,
        size,
        title,
        zIndex,
    } = props;


    const theme = useTheme();
    let onMobile =  useMediaQuery(theme.breakpoints.down('md'));

    let fullScreenDialog = fullScreen || (fullScreenOnMobile && onMobile);

    return (
        <StyledDialog
            fullScreen={fullScreenDialog}
            fullWidth={fullWidth}
            keepMounted={keepMounted}
            disableEscapeKeyDown={disableEscapeKeyDown}
            disablePortal={disablePortal}
            maxWidth={size}
            open={open}
            onClose={onClose ? onClose : onCancel}
            PaperProps={PaperProps}
            zIndex={zIndex}
            scroll={'body'}>
            {ValueIsSet(title) ? (
                <DialogTitle>
                    {typeof title === JavascriptType.STRING ? (
                        <Text variant={TextVariant.H6}>{title}</Text>
                    )
                    : title}
                </DialogTitle>
            )
            : null}
            <StyledDialogContent>
                {
                    body ?
                        (<Fragment>
                           <DialogContentText color={TextColor.TEXT_PRIMARY}>
                               {body}
                           </DialogContentText>
                           {children}
                       </Fragment>)
                :
                    children
                }
            </StyledDialogContent>
            <DialogActions>
                {ValueIsSet(customButtons) ?
                    customButtons
                    :
                    null
                }
                {ValueIsSet(onCancel)? (
                    <Button
                        primary
                        disabled={cancelDisabled}
                        onClick={onCancel}
                        variant={ButtonVariant.OUTLINED}>
                        {cancelText}
                    </Button>
                )
                : null}
                {ValueIsSet(onAccept) ? (
                    <Button
                        debounce={debounceAccept}
                        onClickIgnoreDebounce={onAcceptIgnoreDebounce}
                        disabled={acceptDisabled}
                        primary
                        onClick={onAccept}
                        variant={ButtonVariant.CONTAINED}>
                        {acceptText}
                    </Button>
                )
                : null}
            </DialogActions>
        </StyledDialog>
    );
};

const StyledDialog = styled(Dialog)`
    z-index: ${props => props.zIndex ? props.zIndex : null};
`;

const StyledDialogContent = styled(DialogContent)`
    padding-top: 10px !important;
`;

AlertDialog.propTypes = {
    body: PropTypes.string,
    cancelText: PropTypes.string,
    cancelDisabled: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    customButtons: PropTypes.arrayOf(PropTypes.element),
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    disablePortal: PropTypes.bool,
    debounceAccept: PropTypes.bool, // IMPORTANT: debounceAccept does not prevent all double click issues on the accept button
                                    // For the entire duration in which your onAccept function is running, either the dialog must be closed or
                                    // the acceptDisabled prop must be true in order to ensure double click issues cannot occur.
    fullWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullScreenOnMobile: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    keepMounted: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onAccept: PropTypes.func,
    onAcceptIgnoreDebounce: PropTypes.func,
    acceptDisabled: PropTypes.bool,
    PaperProps: PropTypes.object,
    size: CustomPropTypes.enum(DialogSize).isRequired,
    acceptText: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    zIndex: PropTypes.number,
};

AlertDialog.defaultProps = {
    acceptText: 'OK',
    cancelText: 'Cancel',
    customButtons: false,
    debounceAccept: true,
    disableBackdropClick: false,
    disableEscapeKeyDown: false,
    disablePortal: false,
    fullScreenOnMobile: false,
    keepMounted: false,
    size: DialogSize.MEDIUM,
};

export default withTheme(AlertDialog);