import React, {Component} from 'react';
import t from 'tcomb-form';

import SingleJobChangeRequestForm from "./change-requests/SingleJobChangeRequestForm";
import RecurringScheduleChangeRequestForm from "./change-requests/RecurringScheduleChangeRequestForm";

const Form = t.form.Form;



class NewChangeRequestForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: {},
            serviceTypeFormValue: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeServiceTypeForm = this.onChangeServiceTypeForm.bind(this);
    }

    onChange(value) {
        if (value) {
            this.setState({value: value});
        }
    }

    onChangeServiceTypeForm(serviceTypeFormValue) {
        this.setState({serviceTypeFormValue})
    }

    render(){
        let {obligation, obligationTemplate, serviceLineId} = this.props;

        let changeTypeOptions = {};
        if(obligation !== null)
            changeTypeOptions.time = "Request a new visit time";

        if((obligation && obligation.obligationTemplateId) || obligationTemplate)
            changeTypeOptions.schedule = "Request a new recurring schedule";

        let ServiceChangeTypeForm = t.struct({
            serviceChangeType: t.enums(changeTypeOptions),
        });

        let formOptions = {
            auto: 'none',
            fields: {
                serviceChangeType: {
                    label: null
                }
            }
        };

        let serviceChangeType = this.state.serviceTypeFormValue.serviceChangeType;
        let applicableForm;

        if(Object.keys(changeTypeOptions).length === 1){
            serviceChangeType = Object.keys(changeTypeOptions)[0];
        }

        if (serviceChangeType === 'time') {
            applicableForm = <SingleJobChangeRequestForm obligation={obligation}
                                                         onSuccess={this.props.onSuccess}
                                                         userType={this.props.userType}/>;
        } else if (serviceChangeType === 'schedule') {
            applicableForm = <RecurringScheduleChangeRequestForm obligation={obligation}
                                                                 onSuccess={this.props.onSuccess}
                                                                 obligationTemplate={obligationTemplate}
                                                                 serviceLineId={serviceLineId}
                                                                 userType={this.props.userType}/>;
        } else {
            applicableForm = null;
        }

        return(
            <div>
                {Object.keys(changeTypeOptions).length !== 1 ? <Form
                    ref="form"
                    type={ServiceChangeTypeForm}
                    options={formOptions}
                    value={this.state.serviceTypeFormValue}
                    onChange={this.onChangeServiceTypeForm}
                />
                :
                null}
                {applicableForm}
            </div>
        )
    }
}

export default NewChangeRequestForm;