import React, {Component} from 'react';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import Tabs from "worksmith/components/Tab/Tabs";
import LocationContractPage from "./LocationContractPage";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";

import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Loader from "../../components/Loader";
import ServiceLevelAgreement from "worksmith/components/ServiceLevelAgreement/ServiceLevelAgreement";
import {WithContext} from "../../context/GlobalContext";

const graphQLService = new GraphQLServiceClass();

class ContractPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rateCardLocations: [],
        };
    }

    componentDidMount() {
        this.loadRateCards();
    }

    loadRateCards = () => {
        let {clientLocations} = this.props.context;
        let locationIds = clientLocations.map(location => location.id);
        graphQLService.findAll(
            {"clientLocationIds":locationIds},
            GraphQLObjectType.RATE_CARD,
            rateCardObject
        ).then((data) => {
            let uniqueLocations = data.filter((data, index, self) => self.findIndex(t => t.clientLocation.id === data.clientLocation.id) === index);
            this.setState({rateCardLocations: uniqueLocations, loading: false});
        }).catch((error) => {
            console.log(error);
        });

    };


    render() {
        let {rateCardLocations, loading} = this.state;
        let {client, userRole} = this.props.context;
        let contractConfig = client.contractConfig;
        let serviceLevelAgreementConfig = client.serviceLevelAgreementConfig;
        let contractStartDate = ValueIsSet(contractConfig) && ValueIsSet(contractConfig.startDate) ? moment(contractConfig.startDate, MomentFormat.ISODate).format(MomentFormat.MonthDayYearSlash) : 'mm/dd/yyyy';
        let contractEndDate = ValueIsSet(contractConfig) && ValueIsSet(contractConfig.endDate) ? moment(contractConfig.endDate, MomentFormat.ISODate).format(MomentFormat.MonthDayYearSlash) : 'mm/dd/yyyy';

        let tabs = userRole === 'ADMIN' && ValueIsSet(serviceLevelAgreementConfig) ? ["Locations", "Service Level Agreements"] : ["Locations"];

        if (loading) return <Loader/>;
        else return (
            <Grid container spacing={4} margin={6}>
                <Grid item xs={12}><Text variant={TextVariant.H5}>Rate Cards</Text></Grid>
                <Grid container item xs={12}>
                    <Tabs titleList={tabs} clearBackground>
                        {[
                            <LocationContractPage
                            key={1}
                            rateCardLocations={rateCardLocations}
                            contractStartDate={contractStartDate}
                            contractEndDate={contractEndDate}/>,
                            userRole === 'ADMIN' && ValueIsSet(serviceLevelAgreementConfig) ?
                            <Grid key={2} container item spacing={6}>
                                    <Grid item xs={12}>
                                        <ServiceLevelAgreement
                                            clientId={client.id}
                                            slaConfigId={serviceLevelAgreementConfig.id}
                                            slaPeriodStartDate={moment(contractConfig.startDate).startOf('month')}
                                            slaPeriodEndDate={moment().subtract(1, 'month').endOf('month')}
                                            showNoncompliant={false}/>
                                    </Grid>
                            </Grid> : null
                        ]}
                    </Tabs>
                </Grid>
            </Grid>
        )
    }
}


    const rateCardObject =
        `
        clientLocation{
            id
            city
            state
            title
        }`;


export default WithContext(ContractPage);