import React, {Component, Fragment} from 'react';
import Modal from 'react-modal';
import Radio from 'antd/lib/radio';
import notification from 'antd/lib/notification';
import t from 'tcomb-form';
import Button from "./Button";
import UserService from 'worksmith/services/api/UserService';
import FeedbackRequestService from 'worksmith/services/api/FeedbackRequestService';
import moment from "moment";
import {ValueIsSet} from "../Helpers";
import Frequency from "../shared/components/Frequency/Frequency";
import {Color} from 'worksmith/enums/Color';
import Link from "worksmith/components/Link/Link";
import ClientRoutes from "../ClientRoutes";

const feedbackRequestService = new FeedbackRequestService();
const Form = t.form.Form;
const userService = new UserService();
const RadioGroup = Radio.Group;

class FeedbackRequestsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            loading: true,
            currentIndex: 0,
            value: {},
            radioValue: {},
            feedbackRequests: [],
            valid: false
        };

        this.submitFeedback = this.submitFeedback.bind(this);
        this.skipFeedback = this.skipFeedback.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.handleDismissFeedback = this.handleDismissFeedback.bind(this);
    }

    submitFeedback(currentFeedbackRequest) {
        let _this = this;
        let {radioValue} = this.state;
        let submitData = {
            rating: radioValue,
            feedbackRequest: currentFeedbackRequest
        };

        feedbackRequestService.addFeedback(currentFeedbackRequest.id, submitData).then(function(data){
            let { feedbackRequests, currentIndex } = _this.state;

            notification['success']({
                message: 'Feedback submitted!',
            });

            if (currentIndex + 1 >= feedbackRequests.length) {
                localStorage.setItem('showFeedback', moment());
                _this.setState({submitting: false, visible: false});
            } else {
                _this.setState({submitting: false, value: {}, radioValue: {}, valid: false, currentIndex: currentIndex + 1});
            }
        })
    }

    setSubmitting = () => {
     this.setState({submitting: true});
    };

    handleDismissFeedback(){
        localStorage.setItem('showFeedback', moment());
        this.setState({ visible: false });
    }

    skipFeedback() {
        let {feedbackRequests, currentIndex} = this.state;

        if (currentIndex + 1 >= feedbackRequests.length) {
            localStorage.setItem('showFeedback', moment());
            this.setState({visible: false});
        } else {
            this.setState({currentIndex: currentIndex + 1})
        }
    }

    onChangeRadio(e) {
        let valid = this.state.radioValue != null;

        this.setState({
            radioValue: e.target.value,
            valid: valid
        });
    }


    componentDidMount() {
        let _this = this;
        let yesterday = moment().subtract(24, 'hours');
        let localStorage = window.localStorage;
        let showFeedback = localStorage.getItem('showFeedback') ? moment(localStorage.getItem('showFeedback')) : null;

        userService.getOpenFeedbackRequests().then(function (data) {
            let feedbackRequests = data;
            // if showFeedback doesn't exist, add it as current timestamp. Show modal
            if(feedbackRequests.length && !showFeedback) {
                _this.setState({visible: true});
            }
            // if showFeedback exists, see if it was over 24 hours ago.
            //  if yes, set showFeedback to current timestamp, show modal
            else if(feedbackRequests.length && showFeedback && showFeedback.isBefore(yesterday) ){
                _this.setState({ visible: true })
            }
            // if showFeedback exists, see if it was over 24 hours ago.
            // if no, ignore, don't show modal
            else if(feedbackRequests.length && showFeedback && !showFeedback.isBefore(yesterday)) {
                return;
            }
            _this.setState({feedbackRequests, loading: false})
        })
    }

    render() {
        let {loading, feedbackRequests, currentIndex, submitting, visible, valid} = this.state;
        let {setSubmitting} = this;
        let currentFeedbackRequest = feedbackRequests[currentIndex];
        let canSubmit = valid;


        if (!loading && visible) {
            return (
                <div>
                    <Modal
                        isOpen={this.state.visible}
                        contentLabel="Feedback">
                        <div className="d-flex flex-row justify-content-between">
                            <h2 className="subhead">You have {this.state.feedbackRequests.length - this.state.currentIndex} vendors awaiting feedback</h2>
                            <div className={'text-right'}>

                            </div>
                        </div>

                        <div className="container-fluid margin-top-md">
                            <div className="d-flex flex-column">
                                <div className="row">
                                    <div className="col-md-6">
                                        {currentFeedbackRequest.obligation ?
                                            <div className="ws-section ws-section--info margin-top-none">
                                                <p className="section-accent">Vendor</p>
                                                <p><strong>{currentFeedbackRequest.obligation.vendorLocation.name}</strong></p>

                                                <p className="section-accent">Location</p>
                                                <p>{currentFeedbackRequest.obligation.clientLocation.name}</p>
                                                <div>
                                                    <small>
                                                        {currentFeedbackRequest.obligation.clientLocation.addressLineOne}
                                                        {currentFeedbackRequest.obligation.clientLocation.addressLineTwo ?
                                                            <span>, {currentFeedbackRequest.obligation.clientLocation.addressLineTwo}</span> : null}
                                                        {currentFeedbackRequest.obligation.clientLocation.city}, {
                                                        currentFeedbackRequest.obligation.clientLocation.state} {
                                                        currentFeedbackRequest.obligation.clientLocation.zip}
                                                    </small>
                                                </div>

                                                <p className="section-accent">Service Line</p>
                                                <p>{currentFeedbackRequest.obligation.serviceLine.name}</p>

                                                <p className="section-accent">Service Date</p>
                                                <p>{moment(currentFeedbackRequest.obligation.obligationDate).format("M/D/Y")}</p>

                                                <p className="section-accent">Scope of Work</p>
                                                <p>{currentFeedbackRequest.obligation.scopeOfWork || 'N/A'}</p>
                                            </div> : null}
                                        {currentFeedbackRequest.obligationTemplate ?
                                            <div className="ws-section ws-section--info margin-top-none">
                                                <p className="section-accent">Vendor</p>
                                                <p><strong>{currentFeedbackRequest.obligationTemplate.vendorLocation.name}</strong></p>

                                                <p className="section-accent">Location</p>
                                                <p className={'margin-bottom-none'}>{currentFeedbackRequest.obligationTemplate.clientLocation.name}</p>
                                                <p>
                                                    <small>
                                                        {currentFeedbackRequest.obligationTemplate.clientLocation.addressLineOne}
                                                        {currentFeedbackRequest.obligationTemplate.clientLocation.addressLineTwo ?
                                                            <span>, {currentFeedbackRequest.obligationTemplate.clientLocation.addressLineTwo}</span> : null}
                                                        {currentFeedbackRequest.obligationTemplate.clientLocation.city}, {
                                                        currentFeedbackRequest.obligationTemplate.clientLocation.state} {
                                                        currentFeedbackRequest.obligationTemplate.clientLocation.zip}
                                                    </small>
                                                </p>

                                                <p className="section-accent">Service Line</p>
                                                <p>{currentFeedbackRequest.obligationTemplate.serviceLine.name}</p>

                                                <p className="section-accent">Schedule</p>
                                                <p><Frequency frequency={currentFeedbackRequest.obligationTemplate.frequency}/>, {currentFeedbackRequest.obligationTemplate.timeframe}</p>

                                                <p className="section-accent">Scope of Work</p>
                                                <p>{currentFeedbackRequest.obligationTemplate.scopeOfWork || 'N/A'}</p>

                                            </div> : null}
                                    </div>

                                    <div className="col-md-6">
                                        <h4 className={'body-copy-header'}>Please rate the vendor on the service that they
                                            provided:</h4>
                                        <form id="feedbackForm" className="form-layout">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <span className="pull-left"><small>Lowest</small></span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <span className="pull-right"><small>Highest</small></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <RadioGroup onChange={this.onChangeRadio} value={this.state.radioValue}>
                                                        <Radio value={1}>1</Radio>
                                                        <Radio value={2}>2</Radio>
                                                        <Radio value={3}>3</Radio>
                                                        <Radio value={4}>4</Radio>
                                                        <Radio value={5}>5</Radio>
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                            { valid ?
                                                <Fragment>
                                                    <div className="row">
                                                        <div className="col-md-12 mt-3">
                                                            <Button type={'success'}
                                                                    debounce
                                                                    disabled={!canSubmit || submitting}
                                                                    message={'submit'}
                                                                    onPressIgnoreDebounce={setSubmitting}
                                                                    onPress={() => this.submitFeedback(currentFeedbackRequest)}/>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            : null }
                                        </form>
                                        {currentFeedbackRequest && currentFeedbackRequest.obligation && currentFeedbackRequest.obligation.isContestable ?
                                            <div className="mt-5">
                                                <p><strong>If you need assistance from Worksmith please <Link color={Color.BLUE} href={ currentFeedbackRequest.obligation.rfpIsRecurring ? "#/" + ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_OBLIGATION_TAB_AND_VISIT_STATUS_SORT(currentFeedbackRequest.obligation.serviceLine.id, currentFeedbackRequest.obligation.clientLocation.id, "completed", currentFeedbackRequest.obligation.obligationTemplateId) : "#/" + ClientRoutes.SINGLE_REQUESTS(currentFeedbackRequest.obligation.rfpId)} onClick={() => this.handleDismissFeedback()}>Raise a Concern</Link></strong></p>
                                            </div>
                                            :
                                            null}
                                        {currentFeedbackRequest && currentFeedbackRequest.obligationTemplate ?
                                            <div className="mt-5">
                                                <p><strong>If you need assistance from Worksmith please <Link color={Color.BLUE} href={"#/" + ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_OBLIGATION_TAB_AND_VISIT_STATUS_SORT(currentFeedbackRequest.obligationTemplate.serviceLine.id, currentFeedbackRequest.obligationTemplate.clientLocation.id, "completed", currentFeedbackRequest.obligationTemplate.id) + "&completed=true"} onClick={() => this.handleDismissFeedback()}>Raise a Concern</Link></strong></p>
                                            </div>
                                            :
                                            null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 text-center">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Button type={'warning'} message={`I'll do this later`} onPress={() => this.handleDismissFeedback()}/>
                                    </div>
                                    <div className="col-md-6">
                                        <Button type={'warning-outline'} message={'next review'} onPress={() => this.skipFeedback()}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )
        } else {
            return null
        }
    }
}

export default FeedbackRequestsModal;