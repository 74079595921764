import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";

import './CountryCodePhoneNumberInputStyle.css'
import {Debounce, StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {CountryCode} from "worksmith/enums/api/country/CountryCode";

export const convertPhoneNumberIntoNumberAndCountryCode = (formattedValue, country) => {
    // removes the Country Code from the phone number and trims any extra " "
    const phoneNumberWithNoCountryCode = formattedValue.replace(/^([\S]+)/, '').trim();
    const countryCode = country.countryCode.toUpperCase();
    return {phone: phoneNumberWithNoCountryCode, countryCode: countryCode, phoneWithCountryCode: formattedValue}
};

// The PhoneInput from "react-phone-input-2" uses a './CountryCodePhoneNumberInputStyle.css' style sheet. Changes to './CountryCodePhoneNumberInputStyle.css' will alter all instances of CountryCodePhoneNumberInput.

const CountryCodePhoneNumberInput = (props) => {

    const {
        borderColor,
        borderRadius,
        countryCode,
        error,
        height,
        label,
        onChange,
        onlyCountries,
        requiredAsterisk,
        specialLabel,
        initialValue,
    } = props;

    const [value, setValue] = useState(initialValue);

    const onChangeDebounce = Debounce(onChange, 500);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <Box>
            <Box>
                {label} {requiredAsterisk && <span>*</span>}
            </Box>
            <Box>
            <PhoneInput
                specialLabel={specialLabel}
                country={StringHasText(countryCode) ? countryCode : 'us'}
                inputStyle={{
                    borderColor: ValueIsSet(error) && "red",
                    background: "transparent",
                    height: height,
                    borderRadius: borderRadius,
                    border: `1px solid ${borderColor}`,
                    width: '100%'
                }}
                onlyCountries={onlyCountries}
                {...props}
                onChange={onChangeDebounce}
                value={CountryCode[countryCode] + " " + value}
            />
            {error && <p style={{color:'red'}} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">{error}</p> }
            </Box>
        </Box>
    )
};

CountryCodePhoneNumberInput.propTypes = {
    borderColor: PropTypes.string,
    borderRadius: PropTypes.string,
    countryCode: PropTypes.string,
    error: PropTypes.string,
    height: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,                               // The onChange function has four parameters ex((value, country, e, formattedValue) => {console.log(value, country, e, formattedValue)}). The onChange function is set up to Debounce with an interval of 500ms.
    onlyCountries: PropTypes.arrayOf(PropTypes.string),
    requiredAsterisk: PropTypes.bool,
    specialLabel: PropTypes.string,
    initialValue: PropTypes.string,
};

CountryCodePhoneNumberInput.defaultProps = {
    specialLabel: '',
    height: "56px",
    borderRadius: "4px",
    onlyCountries: ['ca','my','sg', 'th', 'us'],
};

export default CountryCodePhoneNumberInput;


