import React, {Component} from 'react';
import t from 'tcomb-form';
import moment from 'moment';
import notification from 'antd/lib/notification';
import DatePicker from 'antd/lib/date-picker';
import TimePicker from 'antd/lib/time-picker';
import Button from "../../Button";
import RecurringScheduleChangeRequestForm from "./RecurringScheduleChangeRequestForm";
import ScheduleHandlerService from 'worksmith/services/api/ScheduleHandlerService';
import {ScheduleType} from "../../../Enums";
import Schedule from "worksmith/components/Schedule/Schedule.web";
import {TextVariant} from "worksmith/components/Text/Text.web";
import {GetUniqueReactKey} from "worksmith/helpers/GenericHelpers";
import Box from "@mui/material/Box";

const scheduleHandlerService = new ScheduleHandlerService();

class ChangeRequestCounterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changeRequest: props.changeRequestToCounter,
            date: null,
            returnDate: null,
            startTime: null,
            endTime: null,
            loading: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        let {
            changeRequest,
            date,
            returnDate,
            startTime,
            endTime,
        } = this.state;

        this.setState({loading: true});

        let _this = this;

        let serviceLine = changeRequest.serviceLine;

        let schedule = {
            date: moment(date).format('YYYY-MM-DD'),
            type: ScheduleType.ONE_TIME,
        };

        if(serviceLine.useTicketing) {
            schedule.returnDate = moment(returnDate).format('YYYY-MM-DD');
        } else {
           schedule.arrivalStartTime = startTime.format('HH:mm:ss');
           schedule.arrivalEndTime = endTime.format('HH:mm:ss');
        }

        scheduleHandlerService.makeCounterOffer({scheduleHandlerId: changeRequest.scheduleHandler.id, schedules: [schedule]}).then(function(data){
            _this.props.onSuccess();
            _this.setState({loading: false});
            notification['success']({
                message: 'Counter submitted!',
            });
        });
    }

    isInvalid = () => {
        let {date, startTime, endTime, changeRequest, returnDate, loading} = this.state;

        if(loading) return true;

        if (!changeRequest.serviceLine.useTicketing) {
            if (date == null || startTime == null || endTime == null ) {
                return true;
            }

            if(startTime === endTime) {
                return true;
            }

            if (moment(startTime).isAfter(endTime)) {
                return true;
            }
        }

        if (changeRequest.serviceLine.useTicketing && date == null && returnDate == null) {
            return true;
        }

        if (moment(date).isBefore(moment().startOf('day'))) {
            return true;
        }
        if (moment(date).isBefore(moment().endOf('day')) && moment(startTime).isBefore(moment().startOf('hour'))) {
            return true;
        }
    };

    selectStartTime = (startTime) => {
        if (startTime.minute() % 15 !== 0) {
            startTime.minute(0)
        }
        this.setState({startTime});
    };

    selectEndTime = (endTime) => {
        if (endTime.minute() % 15 !== 0) {
            endTime.minute(0)
        }
        this.setState({endTime});
    };


    render() {
        let {changeRequest, loading} = this.state;

        function disabledMinutes(h) {
            let hidden = [];

            for (let i = 0; i < 60; i++) {
                if (i % 15 !== 0) {
                    hidden.push(i);
                }
            }

            return hidden;
        }

        return (
            <div className="ibox-content">
                <section id="currentChangeRequest">
                    <div>
                        <label>Location</label>
                        <p>
                            {changeRequest.clientLocation.title}<br/>
                            {changeRequest.clientLocation.addressLineOne}<br/>
                            {changeRequest.clientLocation.addressLineTwo ? <span>{changeRequest.clientLocation.addressLineTwo}</span> : null}
                            {changeRequest.clientLocation.city},
                            {changeRequest.clientLocation.state}
                            {changeRequest.clientLocation.zip}
                        </p>

                        <label>Vendor</label>
                        <p>
                            {changeRequest.vendorLocation.vendor.nickname}<br/>
                            {changeRequest.vendorLocation.description}
                        </p>

                        <label>Service Type</label>
                        <p>{changeRequest.serviceLine.name}</p>
                    </div>

                    <label>This service is currently scheduled for</label>
                    <Schedule schedule={changeRequest.currentSchedule} textVariant={TextVariant.BODY_1}/>

                    <label>The vendor has requested the following schedules</label>
                    {changeRequest.requestedSchedules.map((schedule) => {
                        return <Schedule key={GetUniqueReactKey} schedule={schedule} textVariant={TextVariant.BODY_1}/>
                    })}
                </section>
                <hr/>
                <section id="singleJobChange">
                    <h5>Your Suggestion</h5>
                    {!changeRequest.obligationTemplate ?
                        <div>
                            <div className="form-group">
                                <div>
                                    <label>{changeRequest.serviceLine.useTicketing ? 'New Pickup Date' : 'New Date'}</label>
                                </div>
                                <DatePicker
                                    size="large"
                                    placeholder={' '}
                                    value={this.state.date}
                                    onChange={(date) => this.setState({date})}/>
                            </div>

                            {changeRequest.serviceLine.useTicketing ?
                                <div>
                                    <div>
                                        <label>New Return Date</label>
                                    </div>
                                    <DatePicker
                                        size="large"
                                        placeholder={' '}
                                        value={this.state.returnDate}
                                        onChange={(returnDate) => this.setState({returnDate})}/>
                                </div> : null}

                            {!changeRequest.serviceLine.useTicketing ?
                                <div>
                                    <div>
                                        <div>
                                            <label>New Arrival Window Start</label>
                                        </div>
                                        <TimePicker
                                            use12Hours
                                            value={this.state.startTime}
                                            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                            placeholder={' '}
                                            size="large"
                                            hideDisabledOptions={true}
                                            disabledMinutes={disabledMinutes}
                                            format="h:mm A"
                                            onChange={this.selectStartTime}/>
                                    </div>

                                    <div>
                                        <div>
                                            <label>New Arrival Window End</label>
                                        </div>
                                        <TimePicker
                                            use12Hours
                                            value={this.state.endTime}
                                            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                            placeholder={' '}
                                            size="large"
                                            hideDisabledOptions={true}
                                            disabledMinutes={disabledMinutes}
                                            format="h:mm A"
                                            onChange={this.selectEndTime}/>
                                    </div>

                                </div> : null}

                            <Box mt={'15px'}>
                                <Button type={'success'} disabled={this.isInvalid()} message={loading ? 'Processing...' : 'Submit Alternative Time'}
                                        onPress={() => this.onSubmit()}/>
                            </Box>

                        </div> : null}
                </section>

                {changeRequest.obligationTemplate ? <RecurringScheduleChangeRequestForm onSuccess={this.props.onSuccess} counter={true} changeRequest={changeRequest} obligationTemplate={changeRequest.obligationTemplate}/> : null }
            </div>
        )
    }
}

export default ChangeRequestCounterForm;