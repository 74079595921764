import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import BurberryLoginPageView from "./BurberryLoginPageView";

class BurberryLoginPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        };
    }
    
    render() {
        let {} = this;
        let {} = this.state;
        let {} = this.props;
        
        return (
            <BurberryLoginPageView/>
        )
    }
}

BurberryLoginPage.propTypes = {
    
};

export default BurberryLoginPage;