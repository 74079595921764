import React from 'react';
import PropTypes from 'prop-types';

import Text from "worksmith/components/Text/Text";
import Tabs from "worksmith/components/Tab/Tabs";
import MessageVendorConversation from "../../components/Conversation/MessageVendorConversation";
import ClientContactPhoneNumbers from "./ClientContactPhoneNumbers";
import Icon, {IconFontSize} from "../../components/Icon/Icon";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import VendorPhoneNumbers from "./VendorPhoneNumbers";

const tabTitles = [
    <Text>Message <Icon name={'QuestionAnswer'} fontSize={IconFontSize.SMALL} translateTop={5}/></Text>,
    <Text>Phone Numbers <Icon name={'Phone'}  fontSize={IconFontSize.SMALL} translateTop={5}/></Text>
];

const ClientVendorConversation = (props) => {
    const {clientLocationId, conversationId, onMessageRead, vendorId, phoneNumbersTabOnly, displayEmailAddresses, linkToClientPage} = props;

    let tabs = [];

    if(!phoneNumbersTabOnly){
        tabs.push(<MessageVendorConversation
            conversationId={conversationId}
            clientLocationId={clientLocationId}
            onMessagesRead={onMessageRead}/>);
    }

    if (ValueIsSet(vendorId)) {
        tabs.push(<VendorPhoneNumbers vendorId={vendorId} displayEmailAddresses={displayEmailAddresses}/>);
    } else if (clientLocationId) {
        tabs.push(<ClientContactPhoneNumbers clientLocationId={clientLocationId} displayEmailAddresses={displayEmailAddresses} linkToClientPage={linkToClientPage}/>);
    }

    if(tabs.length === 0)
        return null;

    return (
        tabs.length > 1 ?
            <Tabs titleList={tabTitles}>
                {tabs}
            </Tabs>
            :
            tabs[0]
    );
};

ClientVendorConversation.propTypes = {
    clientLocationId: PropTypes.number,
    conversationId: PropTypes.number.isRequired,
    onMessageRead: PropTypes.func,
    vendorId: PropTypes.number,
    phoneNumbersTabOnly: PropTypes.bool,
    displayEmailAddresses: PropTypes.bool,
    linkToClientPage: PropTypes.func
};

export default ClientVendorConversation