export const Display = Object.freeze({
    BLOCK: 'block',
    CONTENTS: 'contents',
    FLEX: 'flex',
    GRID: 'grid',
    INHERIT: 'inherit',
    INITIAL: 'initial',
    INLINE: 'inline',
    INLINE_BLOCK: 'inline-block',
    INLINE_FLEX: 'inline-flex',
    INLINE_GRID: 'inline-grid',
    INLINE_TABLE: 'inline-table',
    LIST_ITEM: 'list-item',
    NONE: 'none',
    RUN_IN: 'run-in',
    TABLE: 'table',
    TABLE_CAPTION: 'table-caption',
    TABLE_COLUMN_GROUP: 'table-column-group',
    TABLE_HEADER_GROUP: 'table-header-group',
    TABLE_FOOTER_GROUP: 'table-footer-group',
    TABLE_ROW_GROUP: 'table-row-group',
    TABLE_CELL: 'table-cell',
    TABLE_COLUMN: 'table-column',
    TABLE_ROW: 'table-row'
});

export const FlexDirection = Object.freeze({
    ROW: 'row',
    ROW_REVERSE: 'row-reverse',
    COLUMN: 'column',
    COLUMN_REVERSE: 'column-reverse',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

//Only usable with Display: flex
//aligns the flexible container's items when the items do not use all available space on the main-axis (horizontally for default/row flexDirection, vertically for column flexDirection)
export const JustifyContent = Object.freeze({
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end',
    CENTER: 'center',
    SPACE_BETWEEN: 'space-between',
    SPACE_AROUND: 'space-around',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

//Only usable with Display: flex
//specifies the default alignment for items inside the flexible container (horizontally for column flexDirection, vertically for default/row flexDirection)
export const AlignItems = Object.freeze({
    STRETCH: 'stretch',
    CENTER: 'center',
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end',
    BASELINE: 'baseline',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

export const TextAlign = Object.freeze({
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
});

export const Float = Object.freeze({
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
});

export const TextDecoration = Object.freeze({
    OVERLINE: 'overline',
    LINE_THROUGH: 'line-through',
    UNDERLINE: 'underline',
    UNDERLINE_OVERLINE: 'underline overline',
    NONE: 'none'
});

export const Position = Object.freeze({
    STATIC: 'static',
    RELATIVE: 'relative',
    FIXED: 'fixed',
    ABSOLUTE: 'absolute',
    STICKY: 'sticky'
});

export const BorderStyle = Object.freeze({
    DOTTED: 'dotted',
    DASHED: 'dashed',
    SOLID: 'solid',
    DOUBLE: 'double',
    GROOVE: 'groove',
    RIDGE: 'ridge',
    INSET: 'inset',
    OUTSET: 'outset',
    NONE: 'none',
    HIDDEN: 'hidden'
});

export const Overflow = Object.freeze({
    VISIBLE: 'visible',
    HIDDEN: 'hidden',
    SCROLL: 'scroll',
    AUTO: 'auto'
});

export const TextOverflow = Object.freeze({
    CLIP: 'clip',
    ELLIPSIS: 'ellipsis',
    STRING: 'string',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

//Unlike border, the outline is drawn outside the element's border, and may overlap other content.
//Also, the outline is NOT a part of the element's dimensions; the element's total width and height is not affected by the width of the outline.
export const Outline = Object.freeze({
    DOTTED: 'dotted',
    DASHED: 'dashed',
    SOLID: 'solid',
    DOUBLE: 'double',
    GROOVE: 'groove',
    RIDGE: 'ridge',
    INSET: 'inset',
    OUTSET: 'outset',
    NONE: 'none',
    HIDDEN: 'hidden'
});

export const Cursor = Object.freeze({
    ALIAS: 'alias',
    ALL_SCROLL: 'all-scroll',
    AUTO: 'auto',
    CELL: 'cell',
    CONTEXT_MENU: 'context-menu',
    COL_RESIZE: 'col-resize',
    COPY: 'copy',
    CROSSHAIR: 'crosshair',
    DEFAULT: 'default',
    E_RESIZE: 'e-resize',
    EW_RESIZE: 'ew-resize',
    GRAB: 'grab',
    GRABBING: 'grabbing',
    HELP: 'help',
    MOVE: 'move',
    N_RESIZE: 'n-resize',
    NE_RESIZE: 'ne-resize',
    NESW_RESIZE: 'nesw-resize',
    NS_RESIZE: 'ns-resize',
    NW_RESIZE: 'nw-resize',
    NWSE_RESIZE: 'nwse-resize',
    NO_DROP: 'no-drop',
    NONE: 'none',
    NOT_ALLOWED: 'not-allowed',
    POINTER: 'pointer',
    PROGRESS: 'progress',
    ROW_RESIZE: 'row-resize',
    S_RESIZE: 's-resize',
    SE_RESIZE: 'se-resize',
    SW_RESIZE: 'sw-resize',
    TEXT: 'text',
    //URL: A comma separated list of URLs to custom cursors. Note: Always specify a generic cursor at the end of the list, in case none of the URL-defined cursors can be used
    VERTICAL_TEXT: 'vertical-text',
    W_RESIZE: 'w-resize',
    WAIT: 'wait',
    ZOOM_IN: 'zoom-in',
    ZOOM_OUT: 'zoom-out',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

export const TextTransform = Object.freeze({
    NONE: 'none',
    CAPITALIZE: 'capitalize',
    UPPERCASE: 'uppercase',
    LOWERCASE: 'lowercase',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

export const BoxSizing = Object.freeze({
    BORDER_BOX: 'border-box',
    CONTENT_BOX: 'content-box',
    INHERIT: 'inherit',
    INITIAL: 'initial',
    UNSET: 'unset'
});

export const ListStyleType = Object.freeze({
    CIRCLE: 'circle',
    DISC: 'disc',
    SQUARE: 'square',
    ARMENIAN: 'armenian',
    CJK_IDEOGRAPHIC: 'cjk-ideographic',
    DECIMAL: 'decimal',
    DECIMAL_LEADING_ZERO: 'decimal-leading-zero',
    GEORGIAN: 'georgian',
    HEBREW: 'hebrew',
    HIRAGANA: 'hiragana',
    HIRAGANA_IROHA: 'hiragana-iroha',
    KATAKANA: 'katakana',
    KATAKANA_IROHA: 'katakana-iroha',
    LOWER_ALPHA: 'lower-alpha',
    LOWER_GREEK: 'lower-greek',
    LOWER_LATIN: 'lower-latin',
    LOWER_ROMAN: 'lower-roman',
    UPPER_ALPHA: 'upper-alpha',
    UPPER_GREEK: 'upper-greek',
    UPPER_LATIN: 'upper-latin',
    UPPER_ROMAN: 'upper-roman',
    NONE: 'none',
    INHERIT: 'inherit'
});

export const WhiteSpace = Object.freeze({
    NOWRAP: 'nowrap',
    NORMAL: 'normal',
    PRE: 'pre'
});

export const MarginEnums = Object.freeze({
    LENGTH: 'length',
    AUTO: 'auto',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

export const JustifySelf= Object.freeze({
    AUTO: 'auto',
    NORMAL: 'normal',
    STRETCH: 'stretch',
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end',
    CENTER: 'center',
    START: 'start',
    END: 'end',
    SELF_START: 'self_start',
    SELF_END: 'self_end',
    LEFT: 'left',
    BASELINE: 'baseline',
    FIRST_BASELINE: 'first baseline',
    LAST_BASELINE: 'last baseline',
    SAFE_CENTER: 'safe center',
    UNSAFE_CENTER: 'unsafe center',
    INITIAL: 'initial',
    INHERIT: 'inherit',
    UNSET: 'unset'
});

export const VerticalAlign= Object.freeze({
    BASELINE: 'baseline',
    LENGTH: 'length',
    SUB: 'sub',
    SUPER: 'super',
    TOP: 'top',
    TEXT_TOP: 'text-top',
    MIDDLE: 'middle',
    BOTTOM: 'bottom',
    TEXT_BOTTOM: 'text-bottom',
    END: 'end',
    SELF_START: 'self_start',
    SELF_END: 'self_end',
    LEFT: 'left',
    INITIAL: 'initial',
    INHERIT: 'inherit'
});

export const Grid= Object.freeze({
    AUTO: 'auto'
});
