import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {withTheme} from '../../Theme/ThemeProvider';
import MultiselectView from "./MultiselectView.web";

export const MultiselectVariant = Object.freeze({
    SIMPLE_LINE: 'standard',
    OUTLINED: 'outlined'
});

export const MultiselectType = Object.freeze({
    DROPDOWN: 'dropdown',
    CHECKBOX: 'checkbox'
});

export const MultiselectSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium'
});

class Multiselect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: [],
            groupsValue: []
        };

       if (ValueIsSet(props.initialValue)) {
           this.state.value = props.initialValue.slice();
           this.state.groupsValue = this.getInitialGroupsFromInitialValues();
       }
    }

    getValue = () => {
        return {
            selectedOptions: this.state.value,
            selectedGroups: this.state.groupsValue
        }
    };

    onSelectChange = (event) => {
        this.setState(
            {value: event.target.value}, () => {
                if (ValueIsSet(this.props.onChange))
                    this.props.onChange(this.state.value)
            }
        );
    };

    //This onDelete is firing when the onDelete is triggered by the chip component rendered within the select
    onDelete = (event, deletedValue) => {
        event.preventDefault();
        let {value} = this.state;

        let filteredValues = value.filter(function(val, index, arr){
            return val !== deletedValue;
        });

        this.setState(
            {value: filteredValues}, () => {
                if (ValueIsSet(this.props.onChange))
                    this.props.onChange(filteredValues)
            }
        );
    };

    onCheckboxChange = (value, groupsValue) => {
        this.setState(
            {value: value, groupsValue: groupsValue}, () => {
                if (ValueIsSet(this.props.onChange))
                    this.props.onChange(this.state.value, this.state.groupsValue)
            }
        );
    };

    clear = () => {
        this.setState({value: [], groupsValue: []},
            () => {
                if (ValueIsSet(this.props.onChange))
                    this.props.onChange(this.state.value, this.state.groupsValue)
            })
    };

    selectAll = ()=> {
        let {value} = this.state;
        let {options} = this.props;

        if (value.length === options.length) {
            this.clear();
        } else {
            let allGroups = options.reduce((groups, currentOption) => {
                if (ValueIsSet(currentOption.group) && groups.indexOf(currentOption.group) === -1) {
                    groups.push(currentOption.group)
                }
                return groups;
            }, []);
            this.setState({value: options.map(o => o.value), groupsValue: allGroups},
                () => {
                    if (ValueIsSet(this.props.onChange))
                        this.props.onChange(this.state.value, this.state.groupsValue)
                });
        }
    };

    getInitialGroupsFromInitialValues = () => {
        let {initialValue, options} = this.props;
        let initialGroups = [];

        let initialSelectedOptions = options.filter(option => initialValue.indexOf(option.value) >= 0);

        initialSelectedOptions.forEach(selectedOption => {
           if (ValueIsSet(selectedOption.group) && initialGroups.indexOf(selectedOption.group) < 0) {
               let groupIsChecked = options.reduce((isChecked, currentOption) => {
                   if (isChecked) {
                       return (currentOption.group !== selectedOption.group || initialValue.indexOf(currentOption.value) >= 0)
                   } else {
                       return isChecked;
                   }
               }, true);
              if (groupIsChecked) {
                  initialGroups.push(selectedOption.group);
              }
           }
        });
        return initialGroups;
    };

    render() {
        let {onSelectChange, onCheckboxChange, onDelete, selectAll} = this;
        let {groupsValue, value} = this.state;
        let {
            radioStyle,
            disabled,
            includeSelectAll,
            selectAllLabel,
            startAdornment,
            startAdornmentProps,
            error,
            fullWidth,
            helperText,
            label,
            name,
            nameless,
            options,
            placeholder,
            type,
            showDeleteChipIcon,
            size,
            variant
        } = this.props;

        return (
            <MultiselectView
                radioStyle={radioStyle}
                disabled={disabled}
                includeSelectAll={includeSelectAll}
                selectAllLabel={selectAllLabel}
                startAdornment={startAdornment}
                startAdornmentProps={startAdornmentProps}
                error={error}
                fullWidth={fullWidth}
                groupsValue={groupsValue}
                helperText={helperText}
                label={label}
                name={name}
                nameless={nameless}
                onCheckboxChange={onCheckboxChange}
                onSelectChange={onSelectChange}
                onDelete={onDelete}
                options={options}
                placeholder={placeholder}
                selectAll={selectAll}
                size={size}
                showDeleteChipIcon={showDeleteChipIcon}
                type={type}
                value={value}
                variant={variant}/>
        )
    }
}

Multiselect.propTypes = {
    radioStyle: PropTypes.bool,
    disabled: PropTypes.bool,
    startAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    startAdornmentProps: PropTypes.object,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    initialValue: PropTypes.any,                            // Will auto-calculate off of initialGroupsValue
    includeSelectAll: PropTypes.bool,                       // This prop only works on the CheckboxType
    label: PropTypes.string,
    name: PropTypes.string,
    nameless: PropTypes.bool,                               //Only set if you don't want the input to be name. Useful if you don't want the field to auto-suggest input values
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            group: PropTypes.string,                        //This prop only works on the CheckboxType
            label: PropTypes.string,
            value: PropTypes.any
        })
    ),
    placeholder: PropTypes.string,
    selectAllLabel: PropTypes.string,                       // This prop only works on the CheckboxType
    showDeleteChipIcon: PropTypes.bool,                     //Only set to true you want the chip components of your select to have a delete Icon
    size: PropTypes.string,
    type: CustomPropTypes.enum(MultiselectType).isRequired,
    variant: CustomPropTypes.enum(MultiselectVariant)
};

Multiselect.defaultProps = {
    options: [],
    type: MultiselectType.DROPDOWN,
    variant: MultiselectVariant.OUTLINED,
    includeSelectAll: false,
    selectAllLabel: "Select all"
};

export default withTheme(Multiselect);
