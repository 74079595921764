import React from 'react';
import PropTypes from 'prop-types';
import MaterialGrid from '@mui/material/Grid';
import styled from 'styled-components';

import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {AlignItems, FlexDirection, FlexWrap, JustifyContent, TextAlign} from "worksmith/enums/CSSEnums";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import {Color} from "worksmith/enums/Color";
import {Link} from "react-router-dom";
import {TextDecoration} from "../../enums/CSSEnums";

const AllowableBreakpointValues = [
    false,
    true,
    'auto',
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12
];

const StyledGrid = styled(MaterialGrid)`
    ${props => props.withHover ? `
        &:hover {
            cursor: pointer;
            background-color: ${Color.OPTION_HOVER_BLUE};  
        }
    ` : null};
    ${props => props.overflowAuto ? `overflow: auto` : null};
    ${props => props.textDecoration ? `text-decoration: ${props.textDecoration}` : null};
    ${props => props.onClick ? `cursor: pointer` : null};
    ${props => props.backgroundColor ? `background-color: ${props.backgroundColor}` : null};
`;

const Grid = React.forwardRef((props, ref) => {
    let {
        alignItems,
        backgroundColor,
        className,
        columnSpacing,
        component,
        container,
        direction,
        height,
        id,
        item,
        ItemProps,
        justify,
        lg,
        margin,
        md,
        maxHeight,
        maxWidth,
        linkOpensInNewTab,
        onClick,
        onMouseOver,
        onMouseLeave,
        overflowAuto,
        padding,
        routerLink,
        rowSpacing,
        sm,
        spacing,
        textAlign,
        textDecoration,
        width,
        withHover,
        wrap,
        xl,
        xs,
        zeroMinWidth
    } = props;

    const extraStyling = {
        textAlign: textAlign
    };

    if (ValueIsSet(margin))
        extraStyling.margin = typeof margin === JavascriptType.STRING ? margin : `${margin}px`;

    if (ValueIsSet(height))
        extraStyling.height = typeof height === JavascriptType.STRING ? height : `${height}px`;

    if (ValueIsSet(maxWidth))
        extraStyling.maxWidth = typeof maxWidth === JavascriptType.STRING ? maxWidth : `${maxWidth}px`;

    if (ValueIsSet(maxHeight))
        extraStyling.maxHeight = typeof maxHeight === JavascriptType.STRING ? maxHeight : `${maxHeight}px`;

    if (ValueIsSet(width))
        extraStyling.width = typeof width === JavascriptType.STRING ? width : `${width}px`;

    if (ValueIsSet(padding))
        extraStyling.padding = typeof padding === JavascriptType.STRING ? padding : `${padding}px`;

    return (
        <StyledGrid alignItems={container ? alignItems : undefined}
                    backgroundColor={backgroundColor}
                    className={className}
                    component={routerLink ? Link : component}
                    container={container}
                    columnSpacing={columnSpacing}
                    direction={direction}
                    onMouseOver={onMouseOver}
                    onMouseLeave={onMouseLeave}
                    id={id}
                    item={item}
                    justifyContent={justify}
                    onClick={onClick}
                    ref={ref}
                    rowSpacing={rowSpacing}
                    spacing={container ? spacing : undefined}
                    style={extraStyling}
                    textDecoration={textDecoration}
                    to={routerLink}
                    rel={linkOpensInNewTab ? "noreferrer noopener" : null}
                    target={linkOpensInNewTab ? "_blank" : null}
                    withHover={withHover}
                    overflowAuto={overflowAuto}
                    wrap={container ? wrap : undefined}
                    zeroMinWidth={zeroMinWidth}
                    xs={xs}
                    sm={sm}
                    md={md}
                    lg={lg}
                    xl={xl}>
            {ValueIsSet(ItemProps) ?
                props.children.map(child => {
                    if (ValueIsSet(child) && child.props.item)
                        return React.cloneElement(child, ItemProps);
                    else
                        return child;
                })
                :
                props.children}
        </StyledGrid>
    )
});

Grid.propTypes = {
    alignItems: CustomPropTypes.enum(AlignItems),
    backgroundColor: CustomPropTypes.enum(Color),       //Background color of the grid element
    container: PropTypes.bool,
    direction: CustomPropTypes.enum(FlexDirection),
    height: CustomPropTypes.stringOrNumber,
    id: PropTypes.string,
    item: PropTypes.bool,
    ItemProps: PropTypes.object,                        //Props a Grid Container passes to all of its Grid Item children
    justify: CustomPropTypes.enum(JustifyContent),
    lg: PropTypes.oneOf(AllowableBreakpointValues),
    margin: CustomPropTypes.stringOrNumber,
    md: PropTypes.oneOf(AllowableBreakpointValues),
    maxHeight: CustomPropTypes.stringOrNumber,
    maxWidth: CustomPropTypes.stringOrNumber,
    linkOpensInNewTab: PropTypes.bool,                   //Opens page on a new tab
    onClick: PropTypes.func,
    padding: CustomPropTypes.stringOrNumber,
    routerLink: PropTypes.string,                        // if path passed in, will allow button to serve as react router link
    sm: PropTypes.oneOf(AllowableBreakpointValues),
    spacing: PropTypes.number,
    textDecoration: CustomPropTypes.enum(TextDecoration), //Change the text decoration on the entire grid element. This is helpful, if the grid element is clickable, and you want to remove the text-decoration : underlined.
    textAlign: CustomPropTypes.enum(TextAlign),         //Helpful for centering SVGs inside the grid
    width: CustomPropTypes.stringOrNumber,
    withHover: PropTypes.bool,
    wrap: CustomPropTypes.enum(FlexWrap),
    xl: PropTypes.oneOf(AllowableBreakpointValues),
    xs: PropTypes.oneOf(AllowableBreakpointValues),
    zeroMinWidth: PropTypes.bool                        //If true, it sets min-width: 0 on the item
};

Grid.defaultProps = {
    alignItems: AlignItems.CENTER,
    container: false,
    item: false,
    spacing: 1,
    withHover: false,
    wrap: FlexWrap.WRAP
};

export default Grid;
