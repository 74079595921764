import React, {useState} from 'react';
import PropTypes from "prop-types";

import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import Button, {ButtonSize, ButtonVariant} from "worksmith/components/Button/Button";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import ClientLocationService from "worksmith/services/api/ClientLocationService";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Grid from "worksmith/components/Grid/Grid";
import Icon, {IconColor} from "worksmith/components/Icon/Icon";
import IconButton, {IconButtonSize} from "worksmith/components/Button/IconButton";
import Select, {SelectViewSize} from "worksmith/components/Inputs/Select/Select";
import StandardPricingService from "worksmith/services/api/StandardPricingService";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import TextField, {TextFieldSize} from "worksmith/components/Inputs/TextField/TextField";
import {AlignItems} from "worksmith/enums/CSSEnums";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";
import {Debounce, globalSnackbarTrigger, StringHasText, ToCurrency, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import {IconType} from "worksmith/enums/MaterialEnums";

const standardPricingService = new StandardPricingService();
const clientLocationService = new ClientLocationService();


const StandardPricingItemEdit = ({
                                     allItemsPricings,
                                     alterations,
                                     currency,
                                     getClientStandardItemsPricing,
                                     initialItemPricings,
                                     isHugoBossAlteration,
                                     locationId,
                                     selfServiceClientVendorRelationships,
                                     serviceLineId,
                                     setEdit,
                                     setExpanded,
                                     setItemPricings,
                                     standardItem,
}) => {

    const [addingPricingItem, setAddingPricingItem] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editItems, setEditItems] = useState(initialItemPricings.map(item => {
        return {...item, unitRetailPrice: ToCurrency(item.unitRetailPrice)}
    }));
    const [deletedItems, setDeletedItems] = useState([]);
    const [showDeletedItemModal, setShowDeletedItemModal] = useState(false);


    const handleDelete = (id) => {
        setEditItems(prevState => prevState.filter(item => item.id !== id));
        if (id !== 0) {
            setDeletedItems(prevState => [...prevState, id]);
        }
    };

    const handleUpdateItem = (value, id) => {
        setEditItems((prevState) => {
            return prevState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        changed: true,
                        unitRetailPrice: StringHasText(value) ? value : '',
                        unitWholesalePrice: StringHasText(value) ? value : '',
                        error: StringHasText(value) ? null : "*Input required"
                    };
                } else {
                    return item;
                }
            });
        })
    };

    const handleUpdateCustomerCharge = (value, id) => {
        setEditItems((prevState) => {
            return prevState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        changed: true,
                        unitCustomerCharge: StringHasText(value) ? value : '',
                        customerChargeError: StringHasText(value) ? null : "*Input required"
                    };
                } else {
                    return item;
                }
            });
        })
    };

    const checkIfStandardPriceAlreadySetForVendor = (editItemsState, vendorLocationId) => editItemsState.some(vendor => vendor.vendorLocationId === vendorLocationId);

    const handleSelectVendor = (value, id) => {
        setEditItems((prevState) => {
            const standardPriceAlreadySetForVendor = checkIfStandardPriceAlreadySetForVendor(prevState, value);
            return prevState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        vendorName: standardPriceAlreadySetForVendor ? null : selfServiceClientVendorRelationships.find(vendor => vendor.value === value).label,
                        changed: true,
                        vendorLocationId: standardPriceAlreadySetForVendor ? null : value,
                        selectVendorError: standardPriceAlreadySetForVendor ? "Standard Price already set for selected vendor." : (ValueIsSet(value) ? null : "*Input required"),
                    };
                } else {
                    return item;
                }
            });
        })
    };

    const handleAddPricingItem = () => {
        setAddingPricingItem(true);
        setEditItems(prevState => [...prevState, {id: 0, clientLocationId: locationId, standardItem: standardItem, initialValue: '', newItem: true}])
    };

    const checkInputsAndSave = () => {
        if (editItems.some(item => !StringHasText(item.unitRetailPrice) || !ValueIsSet(item.vendorLocationId))) {
            setEditItems((prevState) => {
                return prevState.map((item) => {
                    return {
                        ...item,
                        error: StringHasText(item.unitRetailPrice) ? null : "*Input required",
                        selectVendorError: checkIfStandardPriceAlreadySetForVendor(prevState, item.vendorLocationId) ? "Standard Price already set for selected vendor." : ValueIsSet(item.vendorLocationId) ? null : "*Input required",
                    };
                });
            })
        } else {
            if (deletedItems.length > 0) {
                setShowDeletedItemModal(true);
            } else {
                handleSave();
            }
        }
    };

    const getPreferredVendorLocations = async (locationId, serviceLineId) => {
        return clientLocationService.getPreferredVendorLocation(locationId, serviceLineId).then((res) => {
            return res;
        }).catch(error => {
            console.error('Error fetching preferred vendors: ', error);
            DisplayErrorNotification("Error fetching preferred vendors");
            return error;
        })
    };

    const addPreferredVendors = async (clientLocationId, vendorIds, serviceLineId) => {
        return vendorIds.map(idOfVendor => {
            clientLocationService.addPreferredVendor(clientLocationId, idOfVendor, serviceLineId).then(res => {
                return res;
            }).catch(error => {
                console.error('Error adding preferred vendor: ', error);
                DisplayErrorNotification("Error adding preferred vendor");
                return error;
            })
        });
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            if (deletedItems.length > 0) {
                await standardPricingService.deleteStandardItems(deletedItems);
                setShowDeletedItemModal(false);
            }

            const changedItems = editItems.filter(item => item.changed);

            if (changedItems.length > 0 ) {
                if (isHugoBossAlteration) {
                    // finds the Hugo Boss Standard Item with "Multiple Alteration" in the name.
                    const multipleAlterationsItem = alterations.find(alteration => alteration.name === 'Multiple Alterations');
                    // Check if a StandardPricing item is created with the category name + ' - Multiple Alterations' in the name and matches the changed item's vendorLocationId. If non are found create the StandardPricing item with the 'Multiple Alterations' for the specific vendorLocationID
                    const StandardPricingMultipleAlterationsLineItemExists = changedItems.find(changedItem => allItemsPricings.some(itemPricing => itemPricing.standardItem.name.includes(changedItem.standardItem.category + " - Multiple Alterations") && changedItem.vendorLocationId === itemPricing.vendorLocationId));
                    if (!ValueIsSet(StandardPricingMultipleAlterationsLineItemExists)) {
                        const newItem = changedItems.find(item => item.newItem);
                        changedItems.unshift({
                            id: null,
                            clientLocationId: locationId,
                            vendorLocationId: newItem.vendorLocationId,
                            standardItem: {
                                id: multipleAlterationsItem.id,
                            }
                        })
                    }
                }
                const preferredVendorLocations =  await getPreferredVendorLocations(locationId, serviceLineId);

                // Creates a list of vendorLocationIds from the changedItems list that are not already preferred vendors for the client location.
                let vendorsToBecomePreferredVendor = [];
                changedItems.forEach(item => {
                    if (!vendorsToBecomePreferredVendor.includes(item.vendorLocationId) && !preferredVendorLocations.some(vendorLocation => vendorLocation.id === item.vendorLocationId)) {
                        vendorsToBecomePreferredVendor.push(item.vendorLocationId);
                    }
                });
                await addPreferredVendors(locationId, vendorsToBecomePreferredVendor, serviceLineId);

                await standardPricingService.saveItems(changedItems);
            }

            setItemPricings(editItems.map(item => {
                return {...item, newItem: false}
            }));
            setEdit(false);
            // Collapse parent expanded accordion
            setExpanded(null);
            globalSnackbarTrigger("Items updated.")
        } catch (error) {
            console.error("Error saving Standard Pricing: ", error);
            await DisplayErrorNotification("Error saving Standard Pricing");
        } finally {
            setSaving(false);
            getClientStandardItemsPricing();
        }
    };

    return (
        <>
            {editItems && editItems.map(itemPrice => (
                <Grid container item xs={12} key={itemPrice.id} alignItems={AlignItems.FLEX_START}>
                    <Grid item xs={'auto'}>
                        <IconButton
                            iconName={IconType.DELETE}
                            onClick={() => handleDelete(itemPrice.id)}
                            size={IconButtonSize.SMALL}
                            iconColor={IconColor.GREY}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={'Vendor Price'}
                            initialValue={itemPrice.unitRetailPrice}
                            size={TextFieldSize.SMALL}
                            onChange={Debounce((value) => handleUpdateItem(value, itemPrice.id), 400)}
                            error={ValueIsSet(itemPrice.error)}
                            helperText={itemPrice.error}
                            startAdornment={CurrencySymbolEnum[currency]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={'Customer Charge'}
                            initialValue={(itemPrice.unitCustomerCharge === 0 || itemPrice.unitCustomerCharge === null) ? '0.00' : itemPrice.unitCustomerCharge}
                            size={TextFieldSize.SMALL}
                            onChange={Debounce((value) => handleUpdateCustomerCharge(value, itemPrice.id), 400)}
                            error={ValueIsSet(itemPrice.customerChargeError)}
                            helperText={itemPrice.customerChargeError}
                            startAdornment={CurrencySymbolEnum[currency]}
                        />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    {itemPrice.newItem ?
                        <Grid item xs={4} padding={'8px 0 0 0'}>
                            <Select
                                fullWidth
                                initialValue={''}
                                label={'Select vendor'}
                                onChange={value => handleSelectVendor(value, itemPrice.id)}
                                options={selfServiceClientVendorRelationships}
                                viewSize={SelectViewSize.SMALL}
                                error={ValueIsSet(itemPrice.selectVendorError)}
                                helperText={itemPrice.selectVendorError}
                            />
                        </Grid>
                        :
                        <Grid item xs={4} padding={'0'}>
                            <Text variant={TextVariant.CAPTION} color={TextColor.TEXT_SECONDARY}>Vendor</Text>
                            <Text>{itemPrice.vendorName}</Text>
                        </Grid>
                    }
                </Grid>
            ))
            }
            {!addingPricingItem &&
                <Grid item xs={12} padding={'16px 0 0 8px'}>
                    <Button
                        onClick={() => handleAddPricingItem()}
                        variant={ButtonVariant.TEXT}
                        startIcon={<Icon name={IconType.ADD_CIRCLE_OUTLINE}/>}
                        secondary
                        size={ButtonSize.SMALL}
                    >
                        Add Price
                    </Button>
                </Grid>
            }
            <Grid container item xs={12} padding={'16px 0 0 0'} margin={0}>
                <Grid item xs={2}>
                    <Button
                        onClick={() => setEdit(false)}
                        variant={ButtonVariant.CONTAINED}
                        fullWidth
                        size={ButtonSize.SMALL}
                    >
                        Cancel
                    </Button>

                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={() => checkInputsAndSave()}
                        variant={ButtonVariant.CONTAINED}
                        primary
                        fullWidth
                        size={ButtonSize.SMALL}
                        disabled={editItems.some(item => ValueIsSet(item.error))}
                    >
                        {saving ? <CircularProgress size={20}/> : 'Save'}
                    </Button>
                </Grid>
            </Grid>
            {showDeletedItemModal &&
                <AlertDialog
                    title={"Delete pricing?"}
                    acceptText={saving ? <CircularProgress size={20}/> : "Yes, delete"}
                    cancelText={"No, cancel"}
                    open={showDeletedItemModal}
                    disableBackdropClick
                    onAccept={handleSave}
                    onCancel={() => {
                        setShowDeletedItemModal(false);
                        setEdit(false);
                    }}
                >
                    <Grid width={500}>
                        <Text>Existing tickets will not be affected, but you will not see this pricing as an option for any new tickets going forward.</Text>
                    </Grid>
                </AlertDialog>
            }
        </>
    )
};

export default StandardPricingItemEdit;

StandardPricingItemEdit.propTypes = {
    currency: CustomPropTypes.enum(CurrencySymbolEnum),
    getClientStandardItemsPricing: PropTypes.func,
    initialItemPricings: PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        updatedTimestamp: PropTypes.string,
        name: PropTypes.string,
        client: PropTypes.object,
        serviceLine: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        lineItemType: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    }),
    isHugoBossAlteration: PropTypes.bool,
    locationId: PropTypes.number,
    selfServiceClientVendorRelationships: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    setEdit: PropTypes.func,
    setExpanded: PropTypes.func, // Collapse parent expanded accordion
    setItemPricings: PropTypes.func,
    standardItems: PropTypes.arrayOf(PropTypes.shape({
        standardItemsForClient: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            client: PropTypes.shape({
                id: PropTypes.number
            })
        }))
    })),
};