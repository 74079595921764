import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ClientService from 'worksmith/services/api/ClientService';
import NTESettingsListView from "./NTESettingsListView";

const clientService = new ClientService();

//DEPRECATED, DO NOT USE 10/27/22 JS
class NTESettingsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            configs: [],
            loading: true
        };
    }

    componentDidMount() {
        const {clientId} = this.props;
        clientService.getWorksmithServiceLineNTEConfigurationSummaries(clientId).then((data) => {
           this.setState({configs: data, loading: false});
        });
    }

    render() {
        let {configs, loading} = this.state;

        return (
            <NTESettingsListView configs={configs} loading={loading}/>
        )
    }
}

NTESettingsList.propTypes = {
    clientId: PropTypes.number.isRequired
};

export default NTESettingsList;