import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import ReactDOM from 'react-dom';

import {withTheme} from "../Theme/ThemeProvider";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import Popover from "../Popover/Popover";
import Text, {TextColor} from "../Text/Text";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Color, PrimaryColor} from "../../enums/Color";
import {AlignItems, JustifyContent} from "../../enums/CSSEnums";
import {getRGBFromHex} from "../../helpers/StylingHelpers";
import Grid from "../Grid/Grid";
import IconButton from "../Button/IconButton";
import {IconColor, IconFontSize} from "../Icon/Icon";
import PopoverFieldButtons from "../Popover/PopoverFieldButtons";
import {Display} from "worksmith/enums/CSSEnums";
import Button from "worksmith/components/Button/Button";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";

class FilterChip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            left: null,
            top: null
        };
    }

    onClick = () => {
        let {top, left} = this.state;
        let {disabled, onPopoverOpen} = this.props;

        if (!disabled) {
            if(ValueIsSet(top) && ValueIsSet(left)) {
                this.onClose();
            } else {
                if (ValueIsSet(onPopoverOpen)) {
                    onPopoverOpen();
                }
                let parentRect = ReactDOM.findDOMNode(this).getBoundingClientRect();
                this.setState({top: parentRect.top + 42, left:parentRect.left + 5})
            }
        }
    };

    onClose = (apply) => {
        const {onApply} = this.props;

        if (apply) {
            onApply();
        }
        this.setState({top: null, left: null})
    };

    render() {
        let {onClick, onClose} = this;
        let {top, left} = this.state;
        let {active, buttonFilterChip, disabled, filterTitle, includeButtons, numberOfFilters, onClear, selected} = this.props;

        return (
            <Fragment>
                {buttonFilterChip ?
                    <Button
                        primary
                        disabled={disabled}
                        onClick={onClick}
                        active={active}
                    >
                        <Icon left name={IconType.TUNE}/>
                        Filters ({numberOfFilters})
                    </Button>
                    :
                    <StyledChip
                        disabled={disabled}
                        onClick={onClick}
                        active={active}>
                            <Fragment>
                                <Text color={disabled ? TextColor.TEXT_SECONDARY : TextColor.PRIMARY}>{filterTitle}<StyledFilterCount active={selected.length > 0}>{selected.length}</StyledFilterCount></Text>
                                <IconSection>
                                    <ArrowDropDownIcon color={disabled ? IconColor.DISABLED : IconColor.INHERIT}/>
                                </IconSection>
                            </Fragment>
                    </StyledChip>

                }
                <Popover
                    anchorPosition={{top: top, left: left}}
                    open={ValueIsSet(top) && ValueIsSet(left)}
                    onClose={() => onClose(false)}
                    PaperStyle={{width: "340px"}}>
                    <Grid container spacing={2}>
                        <Grid item container xs={12} justify={JustifyContent.SPACE_BETWEEN}>
                            <Grid item>
                                <Text semiBold>{filterTitle}</Text>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    disablePadding
                                    iconName="Close"
                                    onClick={() => onClose(false)}
                                    size="medium" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {this.props.children}
                        </Grid>
                        <Grid item xs={12}>
                            {
                                includeButtons ?
                                    <PopoverFieldButtons
                                        active={active}
                                        onClear={() => onClear(false)}
                                        onClose={() => onClose(true)}
                                    />
                                    : null
                            }
                        </Grid>
                    </Grid>
                </Popover>
            </Fragment>
        );
    }
}

FilterChip.defaultProps = {
    disabled: false,
    includeButtons: true
};

FilterChip.propTypes = {
    active: PropTypes.bool,
    activeTitle: PropTypes.string,
    buttonFilterChip: PropTypes.bool,
    disabled: PropTypes.bool,
    filterTitle: PropTypes.string.isRequired,
    includeButtons: PropTypes.bool,
    numberOfFilters: PropTypes.number,
    onApply: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired, // Clear can take a boolean for whether or not to apply the changes
    onPopoverOpen: PropTypes.func,
    selected: PropTypes.array
};

const StyledChip = styled.div`
    display: ${Display.INLINE_FLEX};
    height: ${({active}) => active ? '33px' : '32px'};
    border-radius: 18px;
    padding: 6px 6px 6px 16px;
    border: ${({active}) => active ? '0px' : `1px solid ${Color.GREY}`};
    justify-content: ${JustifyContent.CENTER};
    align-items: ${AlignItems.CENTER};
    cursor: ${({disabled}) => disabled ? null : 'pointer'};
    background-color: ${({active}) => active ? Color.OPTION_HOVER_BLUE : Color.WHITE};
`;

const IconSection = styled.div`
    margin: 0;    
    display: flex;
    justify-content: ${JustifyContent.CENTER};
    align-items: ${AlignItems.CENTER};
`;

const StyledFilterCount = styled.span`
  background-color: ${Color.WHITE};
  color: ${({active}) => active ? TextColor.PRIMARY : Color.WHITE};
  border-radius: 9999px;
  width: 22px;
  height: 22px;
  display: inline-flex;
  margin-left: 4px;
  align-items: center;
  justify-content: center;
`

export default withTheme(FilterChip);