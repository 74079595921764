import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Collapsible from "worksmith/components/Collapse/Collapsible.web";
import Text from "worksmith/components/Text/Text.web";
import Icon from "worksmith/components/Icon/Icon.web"
import Grid from "worksmith/components/Grid/Grid.web";

const Header = styled.div`
    cursor: pointer;
`;

const ExpandIcon = styled(Icon)`
    ${props => props.expanded ? 'transform: rotate(180deg);' : null}
`;

const CollapseBarView = (props) => {
    const {expanded, label, toggleExpand} = props;

    return (
        <Fragment>
            <Header onClick={toggleExpand}>
                <Grid container>
                    <Grid item><ExpandIcon centered expanded={expanded} name={'ExpandMore'}/></Grid>
                    <Grid item><Text>{label}</Text></Grid>
                </Grid>
            </Header>
            <Collapsible expanded={expanded}>
                {props.children}
            </Collapsible>
        </Fragment>
    )
};

CollapseBarView.propTypes = {
    expanded:   PropTypes.bool,
    label:      PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.element
                ]).isRequired,
    toggleExpand: PropTypes.func.isRequired
};

export default CollapseBarView;