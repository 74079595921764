import {LocalStorageKeys, RequestMethod} from '../utilities/HttpEnums';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Http from "../Http/Http.web";

class AsyncGraphQLServiceClass extends Http {
    constructor(){
        super('/graphql')
    }

    /**
     * @param id                    nonnull     ID of the object being searched for
     * @param objectType            nonnull     One of GraphQLObjectType enum
     * @param requestedFields       nonnull     String: space separated name of fields you want returned on the objects
     * @param additionalVariables   nullable
     * @param additionalVariablesString nullable
     * @returns Promise(Object)     nullable    Object of the type requested, null if it couldn't be found or user didn't have permissions to view
     */
    async findOneById(id, objectType, requestedFields, additionalVariables, additionalVariablesString) {
        let data = await this.callApi('',
            RequestMethod.POST,
            {
                variables: {
                    id: id,
                    ...additionalVariables
                },
                query: 'query ($id: Long!' + (ValueIsSet(additionalVariablesString) ? ', ' + additionalVariablesString : '')
                    +  ') { ' + objectType.queryName + '(id: $id) { ' + requestedFields + ' } }'
            },
            {
                queryType: 'findOneById',
                queryName: objectType.queryName,
                portalType: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.PORTAL_TYPE) : 'MobileApp',
                userId: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.USER_ID) : null
            },
            null,
            true
        );
        return data.data[objectType.queryName];
    }

    /**
     * @param page                  nonnull     Page number being requested, 0 indexed
     * @param size                  nonnull     Size of page being requested
     * @param sort                  nonnull     The column of the top level object that you want to sort on
     * @param direction             nonnull     Whether to sort ascending or descending. See GraphQLSortDirection
     * @param searchParams          nullable    Search params object matching the object type you are searching for
     * @param objectType            nonnull     One of GraphQLObjectType enum
     * @param requestedFields       nonnull     String: space separated name of fields you want returned on the objects in the page
     * @returns Promise(Page)       nonnull     A page object with the following shape: {
     *                                                                                      currentPage: number,
     *                                                                                      totalPages: number,
     *                                                                                      totalElements: number,
     *                                                                                      content: List(Type of object requested)
     *                                                                                  }
     */
    async findPage(page, size, sort, direction, searchParams, objectType, requestedFields) {
        return this.callApi('',
            RequestMethod.POST,
            {
                variables: {
                    page: page,
                    size: size,
                    sort: sort,
                    direction: direction,
                    searchParams: searchParams
                },
                query: 'query ($page: Int!, $size: Int!, $sort: String, $direction: String, $searchParams: ' + objectType.searchParamName + ') { ' + objectType.queryName + 'Page(page: $page, size: $size, sort: $sort, direction: $direction, searchParams: $searchParams) { currentPage totalPages totalElements content { ' + requestedFields + ' } } }'
            },
            {
                queryType: 'findPage',
                queryName: objectType.queryName,
                portalType: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.PORTAL_TYPE) : 'MobileApp',
                userId: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.USER_ID) : null
            },
            null,
            true
        ).then((data) => {
            return data.data[objectType.queryName + 'Page'];
        });
    }


    /**
     * @param page                  nonnull     Page number being requested, 0 indexed
     * @param size                  nonnull     Size of page being requested
     * @param sort                  nonnull     The advanced sort allows you to sort by a list of variables and direction in priority
     * @param searchParams          nullable    Search params object matching the object type you are searching for
     * @param objectType            nonnull     One of GraphQLObjectType enum
     * @param requestedFields       nonnull     String: space separated name of fields you want returned on the objects in the page
     * @returns Promise(Page)       nonnull     A page object with the following shape: {
     *                                                                                      currentPage: number,
     *                                                                                      totalPages: number,
     *                                                                                      totalElements: number,
     *                                                                                      content: List(Type of object requested)
     *                                                                                  }
     */
    async findPageWithAdvancedSorting(page, size, sort, searchParams, objectType, requestedFields) {
        return this.callApi('',
            RequestMethod.POST,
            {
                variables: {
                    page: page,
                    size: size,
                    sort: sort,
                    searchParams: searchParams
                },
                query: 'query ($page: Int!, $size: Int!, $sort: [GraphQLSort], $searchParams: ' + objectType.searchParamName + ') { ' + objectType.queryName + 'Page(page: $page, size: $size, sort: $sort, searchParams: $searchParams) { currentPage totalPages totalElements content { ' + requestedFields + ' } } }'
            },
            {
                queryType: 'findPage',
                queryName: objectType.queryName,
                portalType: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.PORTAL_TYPE) : 'MobileApp',
                userId: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.USER_ID) : null
            },
            null,
            true
        ).then((data) => {
            return data.data[objectType.queryName + 'Page'];
        });
    }

    /**
     * @param page                          nonnull     Page number being requested, 0 indexed
     * @param size                          nonnull     Size of page being requested
     * @param sort                          nonnull     The column of the top level object that you want to sort on
     * @param direction                     nonnull     Whether to sort ascending or descending. See GraphQLSortDirection
     * @param searchParams                  nullable    Search params object matching the object type you are searching for
     * @param objectType                    nonnull     One of GraphQLObjectType enum
     * @param requestedFields               nonnull     String: space separated name of fields you want returned on the objects in the page
     * @param dataFetcherArguments          nullable    Object: Key/value pairs that will be accessible in the datafetcher file in the api
     * @returns Promise(Page)               nonnull     A page object with the following shape: {
     *                                                                                      currentPage: number,
     *                                                                                      totalPages: number,
     *                                                                                      totalElements: number,
     *                                                                                      content: List(Type of object requested)
     *                                                                                  }
     */
    async findPageWithArguments(page, size, sort, direction, searchParams, objectType, requestedFields, dataFetcherArguments) {
        return this.callApi('',
            RequestMethod.POST,
            {
                variables: {
                    page: page,
                    size: size,
                    sort: sort,
                    direction: direction,
                    searchParams: searchParams,
                    arguments: dataFetcherArguments
                },
                query: 'query ($page: Int!, $size: Int!, $sort: String, $direction: String, $searchParams: ' + objectType.searchParamName + ', $arguments: ' + objectType.argumentsName + ' ) { ' + objectType.queryName + 'Page(page: $page, size: $size, sort: $sort, direction: $direction, searchParams: $searchParams, arguments: $arguments) { currentPage totalPages totalElements content { ' + requestedFields + ' } } }'
            },
            {
                queryType: 'findPageWithArguments',
                queryName: objectType.queryName,
                portalType: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.PORTAL_TYPE) : 'MobileApp',
                userId: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.USER_ID) : null
            },
            null,
            true
        ).then((data) => {
            return data.data[objectType.queryName + 'Page'];
        });
    }

    /**
     * @param searchParams                  nullable    Search params object matching the object type you are searching for
     * @param objectType                    nonnull     One of GraphQLObjectType enum
     * @param requestedFields               nonnull     String: space separated name of fields you want returned on the objects
     * @param sort                          nonnull     The column of the top level object that you want to sort on
     * @param direction                     nonnull     Whether to sort ascending or descending. See GraphQLSortDirection
     * @param additionalVariables           nullable    Variables used for filtering child types. Ex: {vendorLocationId: 1099}
     * @param additionalVariablesString     nullable    Displays the type of the additional variables. Ex: "$vendorLocationId: Long"
     * @returns Promise(List)       nonnull     List of the objects matching the requested type
     */
    async findAll(searchParams,
            objectType,
            requestedFields,
            sort,
            direction,
            additionalVariables,
            additionalVariablesString) {

        return this.callApi('',
            RequestMethod.POST,
            {
                variables: {
                    searchParams: searchParams,
                    sort: sort,
                    direction: direction,
                    ...additionalVariables
                },
                query: 'query ( $searchParams: ' + objectType.searchParamName + ', $sort: String, $direction: String'  +
                    (ValueIsSet(additionalVariablesString) ? ', ' + additionalVariablesString : '') +
                    ') { ' + objectType.queryName + 's(searchParams: $searchParams, sort: $sort, direction: $direction) { ' + requestedFields + ' } }'
            },
            {
                queryType: 'findAll',
                queryName: objectType.queryName,
                portalType: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.PORTAL_TYPE) : 'MobileApp',
                userId: typeof localStorage !== 'undefined' ? localStorage.getItem(LocalStorageKeys.USER_ID) : null
            },
            null,
            true
        ).then((data) => {
            return data.data[objectType.queryName + 's'];
        });
    }

//region Examples

    /* Example findOneById call:
        graphQlService.findOneById(397,
            GraphQLObjectType.CLIENT,
            `id
            nickname
            userRoles {
                role
                user {
                    id
                    email
                }
            }`
        ).then((data) => {
            console.log("Use Data", data);
        }); */


    /* Example findPage call:
        graphQlService.findPage(0,
            10,
            {clientIds: [140, 397]},
            GraphQLObjectType.CLIENT,
            `id
            nickname`
        ).then((data) => {
            console.log("Use Data", data);
        }); */


    /* Example findAll call:
        graphQlService.findAll(null,
            GraphQLObjectType.USER,
            'id email'
        ).then((data) => {
            console.log("Use Data", data);
        }); */

//endregion
}

export default AsyncGraphQLServiceClass;