import React from 'react';
import SingleInvoicePage from "worksmith/composite-components/ClientInvoice/SingleInvoice/SingleInvoicePage";
import {WithContext} from "../../context/GlobalContext";
import {withRouter} from "react-router-dom";
import ClientRoutes from "../../ClientRoutes";

const SingleInvoice = (props) => {
    const {id} = props.match.params;

    const linkTo = (requestId) => {
        props.history.push(ClientRoutes.SINGLE_REQUESTS(requestId));
    };

    return (
        <SingleInvoicePage id={id} linkTo={linkTo}/>
    )
};

export default withRouter(WithContext(SingleInvoice));