import React, {Component} from 'react';

import GraphQLServiceClass from 'worksmith/services/graphql/GraphQLServiceClass';
import {RequestSortColumns} from 'worksmith/enums/RequestSortColumns'
import {Display} from 'worksmith/enums/CSSEnums';
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import RequestFilter, {RequestStatusOptions} from "./RequestFilter";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import Grid from "worksmith/components/Grid/Grid.web";
import RequestCard, {RequestCardGraphQLObject} from "worksmith/composite-components/RequestCard/RequestCard.web";
import PaginatedList from "worksmith/components/Pagination/PaginatedList";
import CustomLink from "../../components/Link";
import ClientRoutes from "../../ClientRoutes";
import {WithContext} from "../../context/GlobalContext";
import moment from "moment";
import {LocalStorageKeys} from "../../shared/enums/Enums";
import Box from "@mui/material/Box";
import FullWidthPageContainer from "../../FullWidthPageContainer";
import RfpStatus from "worksmith/enums/api/proposal/RfpStatus";
import {validateEmail} from "worksmith/helpers/LanguageHelper";
import RequestForProposalService from "worksmith/services/api/RequestForProposalService";
import {DisplayErrorNotification, DisplaySuccessNotification} from "worksmith/helpers/SweetAlertHelpers";

const graphQLService = new GraphQLServiceClass();
const requestForProposalService = new RequestForProposalService();
const currentDate = moment().format(MomentFormat.MonthDayYear);


class RequestsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            requests: [],
            searchParams: null,
            totalRequests: 0,
            ...this.getInitialStateFromLocalStorage(props)
        };

        this.requestsPerPage = 20;

        let {user} = this.props;
        this.emailAddress = user.email;
    };


    getInitialStateFromLocalStorage = (props) => {
        const {clientLocations} = props.context;

        let storedPageState = localStorage.getItem(LocalStorageKeys.REQUESTS_PAGE_SEARCH_PARAMETERS);
        let currentPage = 0;
        let storedSearchParams;


        if (ValueIsSet(storedPageState)) {
            let pageState = JSON.parse(storedPageState);

            pageState.date = moment(pageState.date);

            // check to make sure the page state has not expired (expires in 2 hours)
            if (moment().diff(pageState.date, 'hours') < 2) {
                storedSearchParams = pageState.searchParams;

                if (ValueIsSet(storedSearchParams.createdStartDate) && ValueIsSet(storedSearchParams.createdEndDate)) {
                    storedSearchParams.createdStartDate = moment(storedSearchParams.createdStartDate);
                    storedSearchParams.createdEndDate = moment(storedSearchParams.createdEndDate);

                    if (!storedSearchParams.createdStartDate.isValid() || !storedSearchParams.createdEndDate.isValid()) {
                        storedSearchParams.createdStartDate = null;
                        storedSearchParams.createdEndDate = null;
                    }
                }
                currentPage = ValueIsSet(pageState.currentPage) ? pageState.currentPage : 0;

            } else {
                localStorage.removeItem(LocalStorageKeys.REQUESTS_PAGE_SEARCH_PARAMETERS);
            }
        }

        return ({
                isSingleLocationUser: clientLocations.length <= 1,
                searchParams: storedSearchParams ? storedSearchParams : this.getDefaultSearchParams(),
                currentPage: currentPage
            });
    };

    getDefaultSearchParams = () => {
        const {clientLocations, showCampaigns} = this.props.context;

        return({
            createdEndDate: null,
            createdStartDate: null,
            requestStatuses: RequestStatusOptions.reduce((selectedOptions, option) => {
                if (option.group === 'Open') {
                    selectedOptions.push(option.value);
                    return selectedOptions;
                }
                return selectedOptions;
            }, []),
            searchableRequestTypes: null,
            rfpId: null,
            vendorName: null,
            hasCampaign: showCampaigns ? null : false,
            clientLocationIds: clientLocations.map(location => location.id),
            serviceLineIds: null
        })
    };

    componentDidMount = () => {
        this.onFilterApply(this.state.searchParams, true)
    };

    saveToLocalStorage = () => {
        const {searchParams, currentPage}  = this.state;
        let stateToSave = {
            date: moment(),
            searchParams,
            currentPage
        };

        localStorage.setItem(LocalStorageKeys.REQUESTS_PAGE_SEARCH_PARAMETERS, JSON.stringify(stateToSave));
    };

    getRequestsAfterPageChange = (currentPage) => {
        const {searchParams} = this.state;
        this.setState({loading: true, currentPage});

        this.getRequests(searchParams, currentPage).then((data) => {
            this.setState({
                requests: data.content,
                totalRequests: data.totalElements,
                totalPages: data.totalPages
            }, this.saveToLocalStorage);
        }).catch(() => {
            this.handleGenericSearchError();
        }).finally(() => {
            this.setState({loading:false});
        });
    };

    onFilterApply = (searchParams, initialLoad) => {
        this.setState({loading: true, searchParams: searchParams});

        const currentPage = initialLoad ? this.state.currentPage : 0;
        // Remove stateProvince from the search params because stateProvince is not a search param for getRequest.
        let searchParamsClone = Object.assign({}, searchParams);
        delete searchParamsClone.stateProvince;

        this.getRequests(searchParamsClone, currentPage)
        .then((data) => {
            this.setState({
                currentPage: currentPage,
                requests: data.content,
                totalRequests: data.totalElements,
                totalPages: data.totalPages
            });
            if (!initialLoad) {
                this.saveToLocalStorage();
            }
        }).catch(() => {
            this.handleGenericSearchError();
        }).finally(() => {
            this.setState({loading:false});
        });
    };

    getRequests = (searchParams, currentPage) => {
        const {requestsPerPage} = this;

       return graphQLService.findPage(
           currentPage,
           requestsPerPage,
           RequestSortColumns.CREATED_TIMESTAMP,
           GraphQLSortDirection.DESCENDING,
           searchParams,
           GraphQLObjectType.REQUEST,
           RequestCardGraphQLObject
       )
    };

    handleGenericSearchError = () => {
        DisplayErrorNotification("Search failed. Please try a different search.");
    };


    handleEmailChange = (value) => {
        this.emailAddress = value;
    };

    handleDownload = () => {
        if(!validateEmail(this.emailAddress)){
            return DisplayErrorNotification("Email Not Valid");
        }
        DisplaySuccessNotification("Check your email in a few minutes!");

        requestForProposalService.exportRequestsCSV(currentDate, this.emailAddress, this.state.searchParams).then(() => {

        }).catch(() => {
            DisplayErrorNotification("Error exporting opportunity data");
        });

    };

    renderRequestCard = (item) => {
        let linkToRecurringServicePage = (item.newStatus === RfpStatus.SCHEDULED || item.newStatus ===  RfpStatus.COMPLETED)
                                        && item.isRecurring;

        let link = linkToRecurringServicePage ?
            ValueIsSet(item.associatedObligationTemplate) ?
                ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(item.serviceLine.id, item.clientLocation.id, false, item.associatedObligationTemplate.id)
                :
                ClientRoutes.RECURRING_SERVICE_SERVICE_LINE_LOCATION(item.serviceLine.id, item.clientLocation.id)
            : ClientRoutes.SINGLE_REQUESTS(item.id);

        return <CustomLink to={link}>
                <RequestCard
                request={item}
                hideLocationName={this.state.isSingleLocationUser}/>
            </CustomLink>;
    }
    ;

    render(){
        const {currentPage, loading, requests, searchParams, totalPages, totalRequests} = this.state;
        const {getRequestsAfterPageChange, renderRequestCard, handleDownload, handleEmailChange, requestsPerPage} = this;
        let {user} = this.props;

        return(
            <FullWidthPageContainer>
            <Box display={Display.FLEX} mt={5} width={{xs:'100%', sm: '550px', md: '700px', lg: '1200px', xl: '1450px'}}>
                <Grid container spacing={10}>
                    <Grid id={'requestSearchContainer'} item xs={12} lg={9}>
                        <Box mb={6}>
                            <Text variant={TextVariant.H5}>Requests</Text>
                        </Box>
                        <RequestFilter
                            initialSearchParams={searchParams}
                            onApply={this.onFilterApply}/>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <PaginatedList
                            loading={loading}
                            data={requests}
                            itemsPerPage={requestsPerPage}
                            getKeyFromItem={(request) => request.id}
                            handleEmailChange={handleEmailChange}
                            handleDownload={handleDownload}
                            showBottomPagination={(itemsOnPage) => itemsOnPage > 10}
                            showButtonEmailModal
                            totalPages={totalPages}
                            renderItem={renderRequestCard}
                            onPageChange={getRequestsAfterPageChange}
                            currentPage={currentPage}
                            totalElements={totalRequests}
                            userEmail={user.email}/>
                    </Grid>
                </Grid>
            </Box>
            </FullWidthPageContainer>
        )
    }
}

export default WithContext(RequestsPage);