import React from 'react';
import MaterialList from '@mui/material/List';
import PropTypes from 'prop-types';
import {withTheme} from "../Theme/ThemeProvider";

const List = (props) => {
    let {
        dense,
        disablePadding,
        subheader,
        style
    } = props;

    return (
        <MaterialList
            dense={dense}
            disablePadding={disablePadding}
            subheader={subheader}
            style={style}>
            {props.children}
        </MaterialList>
    )
};

List.defaultProps = {
    disablePadding: false
};

List.propTypes = {
    dense: PropTypes.bool,
    disablePadding: PropTypes.bool,
    subheader: PropTypes.element,
    style: PropTypes.object
};

export default withTheme(List);