import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import {Pluralize} from "worksmith/helpers/LanguageHelper";
import RequestStateLayout from "../RequestStateLayout";
import Text, {LinkUnderline, TextColor, TextVariant} from "../../../../components/Text/Text.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {Display} from "worksmith/enums/CSSEnums";
import RfpMarket from "../../../../enums/api/proposal/RfpMarket";
import AlertDialog from "../../../../components/Dialog/AlertDialog";
import ContactingPremiumVendors from "./ContactingPremiumVendors";
import Loader from "worksmith/components/Loader/Loader";

class ContactingVendorsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            releaseToNetworkModalOpen: false
        }
    }

    openReleaseToNetworkModal = () => {
        this.setState({releaseToNetworkModalOpen: true})
    };

    hideReleaseToNetworkModal = () => {
        this.setState({releaseToNetworkModalOpen: false})
    };

    render() {
        const {hideReleaseToNetworkModal, openReleaseToNetworkModal} = this;
        const {
            releaseToNetworkModalOpen
        } = this.state;
        const {
            chatRef,
            isAdmin,
            loading,
            releaseToNetwork,
            request
        } = this.props;

        let status = `Contacting Vendors`;
        let anyResponses = false;
        let description = '';
        let conversationHasUnreadScopeClarificationMessage = false;

        if (!loading) {

            if(request.opportunityConversations){
                request.opportunityConversations.forEach((conversation) => {
                    if(conversation.hasUnreadScopeClarificationMessage){
                        conversationHasUnreadScopeClarificationMessage = true;
                    }
                });
            }

            let responses = [
                request.numberOfActiveBids > 0,
                request.numberOfActiveEstimates > 0,
                request.numberOfActiveWalkthroughs > 0
            ];

            anyResponses = responses.filter(hasResponses => hasResponses).length > 0;

            description = anyResponses ?
                `Please review the vendor responses below. `:
                `Your request has been sent to the Worksmith vendor network. You will be notified when they respond.`;

            if (ValueIsSet(request.targetVendorLocation)) {
                switch (request.market) {
                    case RfpMarket.CONTRACT || RfpMarket.MANAGED_MARKETPLACE:
                        description = anyResponses ?
                            `Please review the vendor's response below. `:
                            `We're reaching out to your contracted vendor, ${request.targetVendorLocation.vendor.officialName}. You will be notified when they respond.`
                        status = 'Contacting ' + request.targetVendorLocation.vendor.officialName
                        break;
                    case RfpMarket.SINGLE_VENDOR:
                        description = (
                            <Text variant={TextVariant.BODY_1}>
                                {
                                    anyResponses ?
                                        `Please review the vendor's response below. `:
                                        `We're reaching out to your preferred vendor, ${request.targetVendorLocation.vendor.officialName}. You will be notified when they respond. `
                                }
                                {
                                    isAdmin ?
                                        <Text display={Display.INLINE} variant={TextVariant.INHERIT}>
                                            {`If you would like more vendors to see your request, you can `}
                                             <Text
                                                 link
                                                 onClick={openReleaseToNetworkModal}
                                                 display={Display.INLINE}
                                                 variant={TextVariant.INHERIT}
                                                 underline={LinkUnderline.ALWAYS}
                                                 semiBold
                                                 color={TextColor.SECONDARY}>
                                                 Release to Network
                                            </Text>.
                                        </Text>
                                    :
                                       null
                                }
                            </Text>
                        )
                        status = 'Contacting ' + request.targetVendorLocation.vendor.officialName;
                        break;
                    default:
                        break;
                }
            }

            if (responses.filter(hasResponses => hasResponses).length > 1) {
                status += ' - Multiple Responses'
            } else if (responses[0]) {
                status += ' - Pending ' + Pluralize(request.numberOfActiveBids, 'Bid', 'Bids')
            } else if (responses[1]) {
                status += ' - Pending ' + Pluralize(request.numberOfActiveEstimates, 'Estimate', 'Estimates')
            } else if (responses[2]) {
                status += ' - Pending ' + Pluralize(request.numberOfActiveWalkthroughs, 'Walkthrough', 'Walkthroughs')
            }

        }


        return (
            loading ?
                <Loader/>
                :
                request.market === RfpMarket.CONTRACT || request.market === RfpMarket.MANAGED_MARKETPLACE ?
                    <ContactingPremiumVendors
                        chatRef={chatRef}
                        request={request}
                        conversationHasUnreadScopeClarificationMessage={conversationHasUnreadScopeClarificationMessage}/>
                    :
                    releaseToNetworkModalOpen ?
                        <Fragment>
                            <AlertDialog
                                acceptText={'Yes, Approve'}
                                body={'This will release the request to the Worksmith vendor network. Continue?'}
                                cancelText={'No, Back'}
                                onAccept={() => {
                                    hideReleaseToNetworkModal();
                                    releaseToNetwork();
                                }}
                                onCancel={hideReleaseToNetworkModal}
                                title={'Release to Network?'}
                                open={releaseToNetworkModalOpen}/>
                            <RequestStateLayout description={description}
                                                loading={loading}
                                                status={status}
                                                statusColor={anyResponses ? TextColor.ERROR : TextColor.PRIMARY}/>
                        </Fragment>
                        :
                        null
        )
    }
};

ContactingVendorsView.propTypes = {
    chatRef: PropTypes.object,
    isAdmin: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    releaseToNetwork: PropTypes.func.isRequired,
    request: PropTypes.shape({
        market: PropTypes.string,
        hasPreferredVendor: PropTypes.bool,
        numberOfActiveBids: PropTypes.number,
        numberOfActiveEstimates: PropTypes.number,
        numberOfActiveWalkthroughs: PropTypes.number,
        targetVendorLocation: PropTypes.shape({
            vendor: PropTypes.shape({
                officialName: PropTypes.string
            })
        })
    })
};

export default ContactingVendorsView;