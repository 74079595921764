import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link as ReactRouterLink} from "react-router-dom";
import styled from "styled-components";

const NoDecorationLink = styled(ReactRouterLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


const Link = (props) => {
    let {to} = props;

    return (
       <NoDecorationLink to={to}>
           {props.children}
       </NoDecorationLink>
    )
};

Link.propTypes = {
    to: PropTypes.string.isRequired
};

export default Link;