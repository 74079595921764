import React, {Component} from 'react';
import {Color} from "../Enums";

class Badge extends Component {
    render(){
        let {message, type, hollow} = this.props;

        let color = '';

        switch (type) {
            case 'danger':
                color = Color.RED;
                break;
            case 'warning':
                color = Color.ORANGE;
                break;
            case 'success':
                color = Color.GREEN;
                break;
            case 'info':
                color = Color.BLUE;
                break;
            case 'primary':
                color = Color.NAVY;
                break;
            case 'plain':
                color = Color.GREY;
                break;
        }

        let style = {};
        if (hollow) {
            style['border'] = '1px solid ' + color;
            style['background'] = 'none';
            style['color'] = color;
        }

        return(
            <span style={style} className={"ws-badge ws-badge-" + type}>{message}</span>
        )
    }
}

export default Badge;