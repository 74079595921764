import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import Grid from "../../../../components/Grid/Grid.web";
import Text, {LinkUnderline, TextColor, TextVariant} from "../../../../components/Text/Text.web";
import Schedule from "../../../../components/Schedule/Schedule.web";
import CreateScheduleOptionsDialog from "../../../ScheduleOptionsForm/CreateScheduleOptionsDialog";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

class ScheduledDefaultView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createChangeRequestModalOpen: false
        };
    }

    showChangeRequestModal = () => {
        this.setState({createChangeRequestModalOpen: true});
    };

    hideChangeRequestModal = () => {
        this.setState({createChangeRequestModalOpen: false});
    };

    render() {
        let {hideChangeRequestModal, showChangeRequestModal} = this;
        const {createChangeRequestModalOpen} = this.state;
        const {
            createChangeRequest,
            duration,
            frequency,
            loading,
            schedule,
            useTicketing,
            vendorLocation
        } = this.props;


        return (
            <Fragment>
                <CreateScheduleOptionsDialog
                    duration={duration}
                    numberOfSchedules={1}
                    numberOfRequiredSchedules={1}
                    frequency={frequency}
                    useTicketing={useTicketing}
                    onCancel={hideChangeRequestModal}
                    open={createChangeRequestModalOpen}
                    onSubmit={(schedules) => {
                        hideChangeRequestModal();
                        createChangeRequest(schedules);
                    }}
                    title={ValueIsSet(frequency) ? "Propose New Recurring Schedule" : "Propose New Visit Time"}/>
                <RequestStateLayout
                    loading={loading}
                    body={(
                        <Grid
                            container
                            spacing={5}>
                            <Grid item xs={12}>
                                <Text variant={TextVariant.BODY_1} color={TextColor.PRIMARY} semiBold>
                                   Vendor
                                </Text>
                                <Text variant={TextVariant.BODY_1}>
                                    {vendorLocation.vendor.officialName}
                                </Text>
                            </Grid>
                            <Grid item xs={12}>
                                <Text variant={TextVariant.BODY_1} color={TextColor.PRIMARY} semiBold>
                                    Service Date
                                </Text>
                                <Schedule
                                    textVariant={TextVariant.BODY_1}
                                    schedule={schedule}/>
                            </Grid>
                            {!useTicketing ?
                            <Grid item xs={12}>
                                <Text
                                    variant={TextVariant.BODY_1}
                                    link
                                    onClick={showChangeRequestModal}
                                    underline={LinkUnderline.ALWAYS}
                                    semiBold
                                    color={TextColor.SECONDARY}>
                                    Request to Reschedule
                                </Text>
                            </Grid> : null}
                        </Grid>
                    )}
                    status={TitleCase(NewRequestStatus.SCHEDULED)}/>
            </Fragment>
        )
    }
}

ScheduledDefaultView.propTypes = {
    createChangeRequest: PropTypes.func.isRequired,
    duration: PropTypes.number,
    frequency: PropTypes.shape({     // If frequency is passed in, the schedules created will be recurring
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    loading: PropTypes.bool,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    useTicketing: PropTypes.bool,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

export default ScheduledDefaultView;