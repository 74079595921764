import React, {memo, useState} from 'react';
import PropTypes from "prop-types";

import Button, {ButtonSize, ButtonVariant} from "worksmith/components/Button/Button";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import StandardPricingItemEdit from "worksmith/composite-components/ClientLocationProfile/standardPricing/StandardPricingItemEdit";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";
import {CurrencySymbolFormat, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {IconType} from "worksmith/enums/MaterialEnums";


const StandardPricingItem = ({
                                 allItemsPricings,
                                 alterations,
                                 currency,
                                 getClientStandardItemsPricing,
                                 initialItemPricings,
                                 isHugoBossAlteration,
                                 locationId,
                                 selfServiceClientVendorRelationships,
                                 serviceLineId,
                                 setExpanded,
                                 standardItem,
                                 userIsAdmin,
}) => {

    const [edit, setEdit] = useState(false);
    const [itemPricings, setItemPricings] = useState(initialItemPricings);


    return (
        <Grid container>
            {!edit ?
                (itemPricings.length > 0) ?
                    itemPricings.map(itemPrice => (
                    <Grid container item xs={12} key={itemPrice.id}>
                        <Grid item xs={3}>
                            <Text variant={TextVariant.CAPTION} color={TextColor.TEXT_SECONDARY}>Vendor Price</Text>
                            <Text>{CurrencySymbolFormat(itemPrice.unitRetailPrice, '0,0.00', ValueIsSet(currency) ? currency : "USD")}</Text>
                        </Grid>
                        <Grid item xs={3}>
                            <Text variant={TextVariant.CAPTION} color={TextColor.TEXT_SECONDARY}>Customer Charge</Text>
                            <Text>{CurrencySymbolFormat(itemPrice.unitCustomerCharge, '0,0.00', ValueIsSet(currency) ? currency : "USD")}</Text>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <Text variant={TextVariant.CAPTION} color={TextColor.TEXT_SECONDARY}>Vendor</Text>
                            <Text>{itemPrice.vendorName}</Text>
                        </Grid>
                    </Grid>
                    ))
                    :
                    <Grid container item xs={12}>
                        <Grid item xs={3}>
                            <Text>No Standard Prices.</Text>
                        </Grid>
                    </Grid>
                :
                <StandardPricingItemEdit
                    allItemsPricings={allItemsPricings}
                    alterations={alterations}
                    currency={currency}
                    getClientStandardItemsPricing={getClientStandardItemsPricing}
                    initialItemPricings={itemPricings}
                    isHugoBossAlteration={isHugoBossAlteration}
                    locationId={locationId}
                    selfServiceClientVendorRelationships={selfServiceClientVendorRelationships}
                    serviceLineId={serviceLineId}
                    setEdit={setEdit}
                    setExpanded={setExpanded}
                    setItemPricings={setItemPricings}
                    standardItem={standardItem}
                />
            }
            {userIsAdmin &&
                !edit &&
                <Grid item xs={12} margin={'16px 0 0 0'}>
                    <Button
                        onClick={() => setEdit(true)}
                        variant={ButtonVariant.OUTLINED}
                        startIcon={<Icon name={IconType.EDIT}/>}
                        primary
                        size={ButtonSize.SMALL}
                    >
                        Edit
                    </Button>
                </Grid>
            }
        </Grid>
    )
};

export default memo(StandardPricingItem);

StandardPricingItem.propTypes = {
    alterations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
        category: PropTypes.string,
        name: PropTypes.string,
    })),
    currency: CustomPropTypes.enum(CurrencySymbolEnum),
    getClientStandardItemsPricing: PropTypes.func,
    initialItemPricings: PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        updatedTimestamp: PropTypes.string,
        name: PropTypes.string,
        client: PropTypes.object,
        serviceLine: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        lineItemType: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    }),
    isHugoBossAlteration: PropTypes.bool,
    locationId: PropTypes.number,
    selfServiceClientVendorRelationships: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    serviceLineId: PropTypes.number,
    setExpanded: PropTypes.func, // Collapse parent expanded accordion
    standardItems: PropTypes.arrayOf(PropTypes.shape({
        standardItemsForClient: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            client: PropTypes.shape({
                id: PropTypes.number
            })
        }))
    })),
    userIsAdmin: PropTypes.bool,
};