import styled from "styled-components";
import React from "react";
import {Color } from "worksmith/enums/Color";

import {Link} from "react-router-dom";
import ClientRoutes from "../../ClientRoutes";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";

import Icon from "worksmith/components/Icon/Icon.web";
import PropTypes from "prop-types";
import Grid from "worksmith/components/Grid/Grid.web";


const ArrowIcon = styled(Icon)`
    grid-column: 3;
    margin-top: 0.30em;
`;

const NoUnderlineLink = styled(Link)`   
    :hover {    
        text-decoration: none;
    }
    display:inline
`;

const BackToAllLocationsLink = (props) =>{

    let {serviceLine} = props;

    return <NoUnderlineLink to={'/' + ClientRoutes.RECURRING_SERVICE_SERVICE_LINE(serviceLine.id)}>
        <Grid container  sm={6} m={3}>
            <Grid item xs={1}>
                <ArrowIcon iconColor={Color.TEXT_SECONDARY} name={'KeyboardArrowLeft'}/>
            </Grid>
            <Grid item xs={11}>
                <Text variant={TextVariant.SUBTITLE_2} semiBold color={TextColor.TEXT_SECONDARY}>Back to all Locations</Text>
            </Grid>
        </Grid>
    </NoUnderlineLink>
};

BackToAllLocationsLink.propTypes = {
    serviceLine: PropTypes.object
};

export default BackToAllLocationsLink;