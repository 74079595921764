import ClientServiceLineConfigurationType from "worksmith/enums/api/cpm/ClientServiceLineConfigurationType";
import {IntercomClientDashboardType} from "worksmith/enums/IntercomClientDashboardType";
import ClientDashboardType from "worksmith/enums/api/cpm/ClientDashboardType";

export const getDashboardType = (serviceLineConfigurationType, clientConfigurationDashboardType) => {
    switch(serviceLineConfigurationType){
        case ClientServiceLineConfigurationType.NON_DRY_CLEANING_ONLY:
            return IntercomClientDashboardType.FM_ONLY;
        case ClientServiceLineConfigurationType.DRY_CLEANING_ONLY:
            if(clientConfigurationDashboardType === ClientDashboardType.DEFAULT)
                return IntercomClientDashboardType.OLD_DCA_ONLY;
            else
                return IntercomClientDashboardType.NEW_DCA_ONLY;
        case ClientServiceLineConfigurationType.ALL:
            if(clientConfigurationDashboardType === ClientDashboardType.DEFAULT)
                return IntercomClientDashboardType.FM_OLD_DCA;
            else
                return IntercomClientDashboardType.FM_NEW_DCA;
        default:
            return 'Unknown';
    }
};