import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@mui/material/Table';
import {TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Currency from 'worksmith/components/Currency/Currency';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {withTheme} from '../Theme/ThemeProvider';
import {AlignItems, JustifyContent, TablePadding, TextAlign} from "worksmith/enums/CSSEnums";
import TableColumnType from "worksmith/enums/TableColumnType";
import {GetNested, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import Text, {TextColor} from "../Text/Text.web";
import {MomentFormat} from "../../enums/MomentFormat";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Checkbox from "@mui/material/Checkbox";
import Grid from "worksmith/components/Grid/Grid.web";
import Button, {ButtonSize, ButtonVariant} from 'worksmith/components/Button/Button';
import styled from 'styled-components';
import MenuButton from 'worksmith/components/MenuButton/MenuButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from 'worksmith/components/Menu/MenuItem';
import Icon from 'worksmith/components/Icon/Icon';
import {IconType} from 'worksmith/enums/MaterialEnums';
import {Color, PrimaryColor} from 'worksmith/enums/Color';
import Skeleton from 'worksmith/components/Skeleton/Skeleton';

const BorderlessCell = styled(TableCell)`
    border-bottom: none !important;
    border-top: none !important;
`;

const BorderlessRow = styled(TableRow)`
    border-bottom: none !important;
    border-top: none !important;
    ${({disableCursorStyling}) => disableCursorStyling ? null : 'cursor: pointer;'}
    :hover {
      ${({hover}) => hover ? `background-color: ${PrimaryColor['50']} !important` : null}
    }
    &:nth-of-type(odd) {
    ${({alternateRowColor}) => alternateRowColor ? `background-color: ${Color.LIGHT_GREY} !important` : null}
    }

`;

export const TableSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
});

// CHECK MATERIAL-UI DOCS FOR ADDITIONAL FUNCTIONALITY
class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: props.page,
            rowsPerPage: props.rowsPerPage,
            numRowsSelected: 0,
            rowCount: props.data.length,
            selected: [],
            showActionMenu: false,
            singleSelected: null
        };
    }

    handleClick = (event, row) => {
        const {getRowIsSelectable, onUnselectableRowClicked} = this.props;
        if(ValueIsSet(getRowIsSelectable) && !getRowIsSelectable(row)){
            if(ValueIsSet(onUnselectableRowClicked))
                onUnselectableRowClicked(row);
            event.stopPropagation();
        }
        else
            this.handleSingleSelectionChange(event, row.id);
    };

    handleSingleSelectionChange = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected, numRowsSelected: newSelected.filter(n => n > 0).length });
    };

    handleSelectAll = () => {
        const {data, allEligibleIds, getRowIsSelectable} = this.props;
        const {selected} = this.state;

        let selectableRows;

        if(ValueIsSet(getRowIsSelectable))
            selectableRows = data.filter((row) => getRowIsSelectable(row));

        if (selected.length === data.length || (ValueIsSet(allEligibleIds) && selected.length === allEligibleIds.length) || (ValueIsSet(getRowIsSelectable) && selectableRows.length === selected.length)) {
            this.setState({ selected: [], numRowsSelected: 0 });
            return;
        }

        const updatedSelectedIds =
            ValueIsSet(allEligibleIds) ?
                allEligibleIds
                :
                ValueIsSet(getRowIsSelectable) ?
                    selectableRows.map(n => n.id)
                    :
                    data.map(n => n.id);

        this.setState({
            selected: updatedSelectedIds,
            numRowsSelected: updatedSelectedIds.length});
    };

    isSelected = (id) =>{
        return this.state.selected.indexOf(id) !== -1;
    };

    handleChangePage = (event, page) => {
        const {handleChangePage} = this.props;
        this.setState({ page });
        handleChangePage(event, page);
    };

    handleChangeRowsPerPage = (event) => {
        const {handleChangeRowsPerPage} = this.props;
        this.setState({ page: 0, rowsPerPage: event.target.value });
        handleChangeRowsPerPage(event);
    };

    toggleActionMenu = isOpen => {
        this.setState({showActionMenu: isOpen})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { data } = this.props;
        if(prevProps.data.length !== data.length) {
            this.setState({
                rowCount: data.length
            })
        }
    }

    render() {

        let {
            actionButton,
            alternateRowColor,
            buttonTitle,
            clearSelectionOnSelectionProcessed,
            columns,
            convertToComponentMap,
            customHeaderGridItems,
            customHeaderGridItemsRightAligned,
            customColumnHeaders,
            data,
            dataKey,
            disableCursorStyling,
            displayAsComponent,
            displayGutter,
            getRowIsSelectable,
            gutter,
            hasActionButton,
            hasCheckBox,
            hasHeaderActionButton,
            headerActionButtonOptions,
            headerInputField,
            hover,
            hideHeader,
            hideNumberSelected,
            isPaginated,
            loading,
            onClick,
            onMouseEnter,
            onMouseLeave,
            processSelection,
            rowsPerPageOptions,
            size,
            showLinkArrow,
            showSingleSelectedRow,
            showTopTablePagination,
            stickyHeader,
            totalDataLength,
        } = this.props;

        let {
            numRowsSelected,
            page,
            rowCount,
            rowsPerPage,
            selected,
            showActionMenu,
            singleSelected
        } = this.state;

        let {
            isSelected,
            handleClick,
            handleSelectAll,
            handleChangePage,
            handleChangeRowsPerPage,
            toggleActionMenu
        } = this;

        columns = columns.map(column => {
            if (column.type === TableColumnType.NUMERIC) {
                if (ValueIsSet(column.cellProps)) {
                    column.cellProps.align = TextAlign.RIGHT;
                } else {
                    column.cellProps = {
                        align: TextAlign.RIGHT
                    };
                }
            }
            return column;
        });

        const calculateColumns = () => { // ensures the actions/pagination row length matches the rest of the table
            if (hasCheckBox && showLinkArrow) {
                return columns.length + 2
            } else {
                return columns.length + 1
            }
        }

        return (
            <TableContainer>
                <MaterialTable size={size} stickyHeader={stickyHeader}>
                    {
                        hideHeader ?
                            null
                            :
                            <TableHead>
                                    {hasCheckBox || (isPaginated && showTopTablePagination) || showLinkArrow ?
                                        <TableRow>
                                            <TableCell colSpan={calculateColumns()}>
                                                <Grid
                                                    alignItems={AlignItems.CENTER}
                                                    container
                                                    justify={JustifyContent.SPACE_BETWEEN}
                                                >
                                                    <Grid container item xs={12} md={6} lg={8}>
                                                        {
                                                            ValueIsSet(hasCheckBox) && !hideNumberSelected ?
                                                                <Grid item xs={12} md={2}>
                                                                    <Text>{selected.length} selected</Text>
                                                                </Grid>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            ValueIsSet(headerInputField) ?
                                                                <Grid item xs={12} md={3}>
                                                                    {headerInputField}
                                                                </Grid>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            ValueIsSet(customHeaderGridItems) ?
                                                                customHeaderGridItems
                                                                :
                                                                null
                                                        }

                                                        {ValueIsSet(processSelection) ?
                                                            <Grid item xs={12} sm={4}>
                                                                <Button disabled={selected.length===0}
                                                                        variant={ButtonVariant.CONTAINED}
                                                                        primary
                                                                        onClick={() => {
                                                                            let selectedCopy = [...selected];
                                                                            if(clearSelectionOnSelectionProcessed) {
                                                                                this.setState({
                                                                                    selected: [],
                                                                                    numRowsSelected: 0
                                                                                })
                                                                            }
                                                                            processSelection(selectedCopy);
                                                                        }}>
                                                                    {buttonTitle}
                                                                </Button>
                                                            </Grid>
                                                            :
                                                            null
                                                        }
                                                        {
                                                            ValueIsSet(hasHeaderActionButton) ?
                                                                <Grid item xs={12} md={2}>
                                                                    <MenuButton
                                                                        Button={
                                                                            <Button
                                                                                disabled={selected.length===0}
                                                                                primary
                                                                                variant={ButtonVariant.CONTAINED}
                                                                                size={ButtonSize.MEDIUM}
                                                                                endIcon={<ArrowDropDownIcon style={{fontSize: 30}}/>}
                                                                                onClick={() => {
                                                                                    toggleActionMenu(true);
                                                                                }}
                                                                            >
                                                                                ACTIONS
                                                                            </Button>
                                                                        }
                                                                        onClose={() => {
                                                                            toggleActionMenu(false);
                                                                        }}
                                                                        open={showActionMenu}>
                                                                        {headerActionButtonOptions.map(option => (
                                                                            <MenuItem onClick={() => {
                                                                                option.onClick(selected);
                                                                                toggleActionMenu(false);
                                                                            }}>{option.title}</MenuItem>
                                                                        ))}
                                                                    </MenuButton>
                                                                </Grid>
                                                                :
                                                                null
                                                        }
                                                    </Grid>
                                                    <Grid container justify={JustifyContent.FLEX_END} item xs={12} md={6} lg={4}>
                                                        {
                                                            ValueIsSet(customHeaderGridItemsRightAligned) ?
                                                                customHeaderGridItemsRightAligned
                                                                :
                                                                null
                                                        }
                                                        {
                                                            ValueIsSet(isPaginated) && ValueIsSet(showTopTablePagination) ?
                                                                <StyledTablePagination
                                                                    style={{border: 'none'}}
                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                    count={totalDataLength}
                                                                    rowsPerPage={rowsPerPage}
                                                                    page={page}
                                                                    backIconButtonProps={{
                                                                        'aria-label': 'Previous Page',
                                                                    }}
                                                                    nextIconButtonProps={{
                                                                        'aria-label': 'Next Page',
                                                                    }}
                                                                    onPageChange={handleChangePage}
                                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                                />
                                                                :
                                                                null
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                    }
                                {customColumnHeaders ?
                                    <TableRow>
                                        {customColumnHeaders.map(column => (
                                            <TableCell {...column.cellProps}>
                                                {typeof column.title === JavascriptType.STRING ?
                                                    <Text color={TextColor.TEXT_SECONDARY} semiBold>{column.title}</Text>
                                                    :
                                                    column.title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                : null
                                }
                                <TableRow>
                                    {hasCheckBox ?
                                        <TableCell padding={TablePadding.CHECKBOX}>
                                            <Checkbox
                                                color="primary"
                                                indeterminate={numRowsSelected > 0 && numRowsSelected < rowCount}
                                                checked={numRowsSelected === rowCount}
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>
                                        : null
                                    }
                                    {hasActionButton ?
                                        <TableCell padding={TablePadding.BUTTON}/>
                                        :
                                        null
                                    }

                                    {columns.map(column => (
                                        <TableCell {...column.cellProps}>
                                            {typeof column.title === JavascriptType.STRING ?
                                                <Text color={TextColor.TEXT_SECONDARY} semiBold>{column.title}</Text>
                                                :
                                                column.title}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                    }
                    { loading ?
                       new Array(rowsPerPage).fill(null).map(item => (
                            <BorderlessRow disableCursorStyling={disableCursorStyling}>
                                {
                                    columns.map(row => (
                                        <BorderlessCell>
                                            <Skeleton />
                                        </BorderlessCell>
                                    ))
                                }
                            </BorderlessRow>
                       ))
                        :
                        <TableBody>
                            {data.map(row => (
                                <Fragment key={GetNested(row, dataKey)}>
                                    <BorderlessRow alternateRowColor={alternateRowColor}
                                                   key={GetNested(row, dataKey)}
                                                   disableCursorStyling={disableCursorStyling}
                                                   role="checkbox"
                                                   hover={hover}
                                                   onMouseEnter={ValueIsSet(onMouseEnter) ? e => onMouseEnter(e, row) : null}
                                                   onMouseLeave={ValueIsSet(onMouseLeave) ? e => onMouseLeave(e) : null}
                                                   selected={showSingleSelectedRow ? row[dataKey] === singleSelected : null}
                                                   onClick={() => this.setState({singleSelected: row[dataKey]})}
                                    >
                                        {hasCheckBox ?
                                            <TableCell onClick={
                                                        event => {
                                                            handleClick(event, row)
                                                        }
                                                    }
                                                    padding="checkbox">
                                                <Checkbox color="primary" checked={isSelected(row.id)} disabled={ValueIsSet(getRowIsSelectable) ? !(getRowIsSelectable(row)) : false} />
                                            </TableCell>
                                            : null}
                                        {hasActionButton ?
                                            displayGutter(row) ?
                                                <BorderlessCell padding="button">
                                                    {actionButton(row)}
                                                </BorderlessCell> :
                                                <TableCell padding="button">
                                                    {actionButton(row)}
                                                </TableCell>
                                            : null}
                                        {columns.map((column, index) => {
                                            let cellValue = ValueIsSet(column.cellValueFunction) ? column.cellValueFunction(row) : GetNested(row, column.fieldPath);
                                            if(ValueIsSet(cellValue)) {
                                                if (column.type === TableColumnType.DATETIME) {
                                                    cellValue = moment(cellValue).format(MomentFormat.DateTime);
                                                } else if (column.type === TableColumnType.DATE) {
                                                    cellValue = moment(cellValue).format(MomentFormat.MonthDayYearSlash);
                                                } else if (column.type === TableColumnType.CURRENCY) {
                                                    cellValue = <Currency amount={cellValue}/>;
                                                }
                                            }

                                        return displayGutter(row) ?
                                                <BorderlessCell onClick={onClick ? () => onClick(row.id) : event => handleClick(event, row)} {...column.cellProps} key={index}>
                                                    {typeof cellValue === JavascriptType.STRING
                                                    || typeof cellValue === JavascriptType.NUMBER ?
                                                        ValueIsSet(displayAsComponent) && displayAsComponent.includes(index) ? convertToComponentMap[index](row, cellValue) : <Text>{cellValue}</Text>
                                                        :
                                                        cellValue
                                                    }
                                                </BorderlessCell> :
                                            <TableCell onClick={onClick ? () => onClick(ValueIsSet(row.id) ? row.id : row) : hasCheckBox ? event => handleClick(event, row) : null} {...column.cellProps} key={index}>
                                                {typeof cellValue === JavascriptType.STRING
                                                || typeof cellValue === JavascriptType.NUMBER ?
                                                    ValueIsSet(displayAsComponent) && displayAsComponent.includes(index) ? convertToComponentMap[index](row, cellValue)  : <Text>{cellValue}</Text>
                                                    :
                                                    cellValue
                                                }
                                            </TableCell>

                                            ;
                                        })}
                                        {
                                            showLinkArrow ?
                                                <TableCell onClick={onClick ? () => onClick(row.id) : event => handleClick(event, row)} padding="button">
                                                    <Icon name={IconType.CHEVRON_RIGHT} />
                                                </TableCell>
                                                :
                                                null
                                        }
                                    </BorderlessRow>
                                    {displayGutter(row) ? <TableCell colSpan={6}>{gutter(row)}</TableCell>: null}
                                </Fragment>
                            ))}
                        </TableBody>
                    }

                    {isPaginated ?
                        <TableFooter>
                            <TableRow>
                                <StyledTablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    count={totalDataLength}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Next Page',
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                        : null}
                </MaterialTable>
            </TableContainer>
        );
    }
}

const StyledTablePagination = styled(TablePagination)`
    & .MuiTablePagination-displayedRows {
        margin: 0;
    }
`;

Table.propTypes = {
    allEligibleIds: PropTypes.arrayOf(PropTypes.number), //Include this if you want the select all checkbox to check off screen elements when using a paginated table
    actionButton: PropTypes.func, // a function that takes in a row and returns a dom element
    buttonTitle: PropTypes.string,
    columns: PropTypes.arrayOf(
            PropTypes.shape({
            title: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
            fieldPath: PropTypes.string, // The path to the column cell value from the object passed in as data
            type: CustomPropTypes.enum(TableColumnType),
            cellProps: PropTypes.shape({
                //comes from TableCell prop types
                align: CustomPropTypes.enum(TextAlign),
            }),
            cellValueFunction: PropTypes.func // a function used to render the calls in the given column. This function will receive the data per row as a param
        })
    ).isRequired,
    clearSelectionOnSelectionProcessed: PropTypes.bool,
    convertToComponentMap: PropTypes.objectOf(PropTypes.func),   // a map of array indexes from displayAsComponent to functions that take a cell value and converts it to a component
    customHeaderGridItems: PropTypes.arrayOf(PropTypes.element), // list of grid item elements that will show up on the left in line with the top pagination
    customHeaderGridItemsRightAligned: PropTypes.arrayOf(PropTypes.element), // list of grid item elements that will show up on the right in line with the top pagination
    data: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
            PropTypes.number,
            PropTypes.object,
            PropTypes.bool,
        ]))
    ).isRequired,
    dataKey: PropTypes.string, //The path used to grab the key of each row
    disableCursorStyling: PropTypes.bool,
    displayAsComponent: PropTypes.arrayOf(PropTypes.number), // enter an array of indexes that is to be displayed as components
    displayGutter: PropTypes.func, //a function that takes in a row and returns a boolean determining if the gutter should be displayed
    getRowIsSelectable: PropTypes.func, // (row) => boolean: based on the content of the row, this function returns a boolean of whether that row can be selected
    gutter: PropTypes.func, // a function that takes in a row and returns a dom element.  Appears beneath each row.
    hasActionButton: PropTypes.bool,
    hasCheckBox: PropTypes.bool,
    hasHeaderActionButton: PropTypes.bool,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    headerActionButtonOptions: PropTypes.shape({
        title: PropTypes.string,
        onClick: PropTypes.func
    }),
    headerInputField: PropTypes.element,
    hideHeader: PropTypes.bool,
    hideNumberSelected: PropTypes.bool,
    isPaginated: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.bool,
    onMouseEnter: PropTypes.func,               // onMouseEnter and onMouseLeave are called when you hover on a row
    onMouseLeave: PropTypes.func,
    onUnselectableRowClicked: PropTypes.bool,
    page: PropTypes.number,
    processSelection: PropTypes.func,
    rowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    showLinkArrow: PropTypes.bool,
    showSingleSelectedRow: PropTypes.bool,  //when not using checkboxes if you want to show the selected row use this bool
    showTopTablePagination: PropTypes.bool,
    totalDataLength: PropTypes.number, //When using pagination, you have to indicate what the total size of the dataset is (because the table is only passed one page of data at a time)
};

Table.defaultProps = {
    dataKey: 'id',
    hideHeader: false,
    rowsPerPageOptions: [5, 10, 25],
    showSingleSelectedRow: false,
    showTopTablePagination: false,
    displayGutter: () => { return false; }
};

export default withTheme(Table);
