// This file was generated from com.worksmith.proposal.enums.WalkthroughStatus
// noinspection JSUnusedGlobalSymbols
const WalkthroughStatus = Object.freeze({
    ACCEPTED: 'accepted',
    NEEDS_CLIENT_INPUT: 'needs client input',
    CANCELED: 'canceled',
    DECLINED: 'declined',
    getClosedStatuses: function() {
        return [this.ACCEPTED, this.DECLINED, this.CANCELED];
    },
    getOpenStatuses: function() {
        return [this.NEEDS_CLIENT_INPUT];
    }
});

export default WalkthroughStatus;
