export const DeepCopy = (object) => {
    return JSON.parse(JSON.stringify(object));
};

export const Debounce = (fn, interval, ignoreFunction) => {
    let timeout;

    return function() {
        const functionCall = () => fn.apply(this, arguments);

        if (ignoreFunction)
            ignoreFunction();

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, interval);
    }
};

export const ValueIsSet = (object) => {
    return object !== undefined && object !== null;
};

export const Uppercase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
//For IE 11 Compatibility
export const getValuesFromObject = (object) => {
    try {
        // noinspection SSBasedInspection
        return Object.values(object);
    } catch (err) {
        return Object.keys(object).map(e => object[e]);
    }
};

//This method is outdated. Use the GetURLSearchParams method instead
export const ParamStringToObject = (paramsString) => {
    let conversion = paramsString.replace('?', '');

    conversion = conversion.split("&");

    return conversion.reduce((obj, param) => {
        let keyValue = param.split('=');

        obj[keyValue[0]] = keyValue[1];

        return obj;
    }, {})
};

export const GetURLSearchParams = (paramsString) => {
    return new Proxy(new URLSearchParams(paramsString), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
};

export const ConvertToDurationString = (duration) => {
    let durationString = '';
    let hours = Math.floor(duration / 60);
    let minutes = duration % 60;

    if(hours === 1)
        durationString += hours + ' hour ';
    else if(hours > 1)
        durationString += hours + ' hours ';
    if(hours > 0 && minutes > 0)
        durationString += ' and ';
    if(minutes > 0)
        durationString += minutes + ' minutes';

    return durationString;
};
