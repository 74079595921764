import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InternalNotesList from "./InternalNotesList";
import {Display, FlexDirection, JustifyContent} from "worksmith/enums/CSSEnums";
import {Debounce, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CircularProgress from "../Loader/CircularProgress";
import Box from "@mui/material/Box";
import Button, {ButtonVariant} from "../Button/Button";
import TextField, {TextFieldVariant} from "../Inputs/TextField/TextField";
import Text, {TextVariant} from "../Text/Text";
import Skeleton from "worksmith/components/Skeleton/Skeleton";

const InternalNotesContainer = styled.div`
    display: ${Display.FLEX};
    flex-direction: ${FlexDirection.COLUMN};
`;

const MessagesContainer = styled.div`
    overflow-y: scroll;
    max-height: 20em;
`;

const ButtonContainer = styled.div`
    margin-top: 1em;
    margin-left: auto;
`;

const InternalNotesView = (props) => {
    let {
        initialContent,
        disableSubmit,
        loading,
        notes,
        onTextFieldChange,
        textFieldRef,
        writeNote} = props;

    if (loading) {
        return (
            <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                <CircularProgress/>
            </Box>
        )
    }

    let debouncedOnChange = Debounce(onTextFieldChange, 50);

    return ValueIsSet(notes) ?
        <InternalNotesContainer>
            <Text variant={TextVariant.BODY_1} gutterBottom>Add notes that are only visible to Admins and Dispatchers. If you would like to add notes visible to the Technicians, use the Instructions tab.</Text>
            <MessagesContainer>
                <InternalNotesList notes={notes}/>
            </MessagesContainer>

            <TextField initialValue={initialContent}
                       onChange={debouncedOnChange}
                       variant={TextFieldVariant.OUTLINED}
                       ref={textFieldRef}
                       rowsMax={5}
                       rows={3}
                       multiline
                       fullWidth/>
            <ButtonContainer>
                <Button disabled={disableSubmit}
                        debounce
                        variant={ButtonVariant.CONTAINED}
                        primary
                        onClick={writeNote}>
                    Add Note
                </Button>
            </ButtonContainer>
        </InternalNotesContainer>
        :
        <Text>
            No Notes Available.
        </Text>;
};

InternalNotesView.propTypes = {
    initialContent: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.shape({
        author: PropTypes.shape({
            id: PropTypes.number,
            displayName: PropTypes.string
        }),
        note: PropTypes.string,
        createdTimestamp: PropTypes.string,
        id: PropTypes.id
    })),
    loading: PropTypes.bool,
    textFieldRef: PropTypes.object,
    writeNote: PropTypes.func.isRequired
};

export default InternalNotesView;