// This file was generated from com.worksmith.core.constants.TimeZone
// noinspection JSUnusedGlobalSymbols
const TimeZone = Object.freeze({
    NEW_YORK: 'America/New_York',
    PUERTO_RICO: 'America/Puerto_Rico',
    CHICAGO: 'America/Chicago',
    DENVER: 'America/Denver',
    NORTH_DAKOTA: 'America/North_Dakota/Center',
    BOISE: 'America/Boise',
    PHOENIX: 'America/Phoenix',
    LOS_ANGELES: 'America/Los_Angeles',
    HONOLULU: 'Pacific/Honolulu',
    ANCHORAGE: 'America/Anchorage',
    CANADA_EASTERN: 'Canada/Eastern',
    CANADA_PACIFIC: 'Canada/Pacific',
    CANADA_MOUNTAIN: 'Canada/Mountain',
    CANADA_ATLANTIC: 'Canada/Atlantic',
    CANADA_CENTRAL: 'Canada/Central'
});

export default TimeZone;
