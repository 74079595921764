// This file was generated from com.worksmith.proposal.controller.WalkthroughController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class WalkthroughService extends Http {
    constructor() {
        super('/walkthroughs')
    }
    
    accept(walkthroughId) {
        return this.callApi( `${walkthroughId}/accept`, PATCH, null, null, null, true);
    }
    
    acceptAndSchedule(walkthroughId, scheduleId) {
        return this.callApi( `${walkthroughId}/schedules/${scheduleId}/acceptAndSchedule`, PATCH, null, null, null, true);
    }
    
    acceptSchedule(walkthroughId, scheduleId) {
        return this.callApi( `${walkthroughId}/schedules/${scheduleId}/acceptSchedule`, PATCH, null, null, null, true);
    }
    
    canBeAcceptedByRequester(walkthroughId) {
        return this.callApi( `${walkthroughId}/canBeAcceptedByRequester`, GET, null, null, null, true);
    }
    
    cancel(walkthroughId, cancellationReason) {
        let params = {
            cancellationReason: cancellationReason
        };
        return this.callApi( `${walkthroughId}/cancel`, PATCH, null, params, null, true);
    }
    
    decline(walkthroughId, muteVendorNotifications, declineReason) {
        let params = {
            muteVendorNotifications, 
            declineReason
        };
    
        return this.callApi( `${walkthroughId}/decline`, PATCH, null, params, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findClientBidCards(params) {
        return this.callApi( `findClientWalkthroughCards`, POST, params, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getClientProposalSchedulingObject(walkthroughId) {
        return this.callApi( `${walkthroughId}/getClientProposalSchedulingObject`, GET, null, null, null, true);
    }
    
    getClientVendorSummary(walkthroughId) {
        return this.callApi( `${walkthroughId}/getClientVendorSummary`, GET, null, null, null, true);
    }
    
    getClientWalkthroughDetails(walkthroughId) {
        return this.callApi( `${walkthroughId}/getClientWalkthroughDetails`, GET, null, null, null, true);
    }
    
    requestApproval(walkthroughId) {
        return this.callApi( `${walkthroughId}/requestApproval`, PATCH, null, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default WalkthroughService;
