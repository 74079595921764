import React from 'react';
import PropTypes from 'prop-types';
import MaterialCollapse from '@mui/material/Collapse';
import {withTheme} from "../Theme/ThemeProvider";

let Collapsible = (props) => {
    let {
        collapsedHeight,
        className,
        expanded,
        mountOnEnter,
        timeout,
        unmountOnExit
    } = props;

    return (
        <MaterialCollapse in={expanded}
                          collapsedHeight={collapsedHeight}
                          mountOnEnter={mountOnEnter}
                          unmountOnExit={unmountOnExit}
                          timeout={timeout}
                          className={className}>
            {props.children}
        </MaterialCollapse>
    )
};

Collapsible.propTypes = {
    className: PropTypes.string,
    collapsedHeight: PropTypes.string,
    expanded: PropTypes.bool,
    mountOnEnter: PropTypes.bool,
    timeout: PropTypes.oneOfType([                          //the duration for the transition
        PropTypes.number,
        PropTypes.oneOf(['auto']),
        PropTypes.shape({
            enter: PropTypes.number,
            exit: PropTypes.number
        })
    ]),
    unmountOnExit: PropTypes.bool,
};

Collapsible = withTheme(Collapsible);
export default Collapsible;