import axios from 'axios';

import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {BaseApiURL, Headers, LocalStorageKeys, RequestMethod} from "../utilities/HttpEnums";
import AuthTokenManager from "../utilities/AuthTokenManager";
import {HttpHandler} from "../utilities/HttpHandler";
import {convertToHEICToJPG} from "worksmith/helpers/WebHelper";
const authHelper = new AuthTokenManager();

class Http {
    constructor(baseUrl){
        this.baseUrl = baseUrl;
    }

    static getBaseApiURL() {
        switch (localStorage.getItem(LocalStorageKeys.BASE_API_URL)) {
            case 'local':
                return BaseApiURL.LOCAL;
            case 'local-dev':
                return BaseApiURL.LOCAL_DEV;
            case 'demo':
                return BaseApiURL.DEMO;
            case 'qa':
                return BaseApiURL.QA;
            case 'production':
                return BaseApiURL.PRODUCTION;
        }
    };

    delete(id) {
        return this.callApi('delete/' + id, RequestMethod.DELETE, null, null, null, true);
    }

    findAll() {
        return this.callApi('findAll', RequestMethod.GET, null, null, null, true);
    }

    findAllByIds(data) { // takes array of IDs
        return this.callApi('findAllByIds', RequestMethod.POST, data, null, null, true);
    }

    findOne(id) {
        return this.callApi('findOne/' + id, RequestMethod.GET, null, null, null, true);
    }

    insert(data) {
        return this.callApi('insert', RequestMethod.POST, data, null, null, true);
    }

    update(data) {
        return this.callApi('update', RequestMethod.PUT, data, null, null, true);
    }

    updateAll(data) { // Array as argument
        return this.callApi('updateAll', RequestMethod.PUT, data, null, null, true);
    }

    search(params) { // Array as argument
        return this.callApi('search', RequestMethod.GET, null, params, null, true);
    }

    callApi = async (path, method, data, params, customHeaders, requireAuthorization, files, responseType) => {
        if (path[0] === '/')
            throw "Service method called with forward slash at beginning of the path. Called from " + this.constructor.name;

        let hasFiles = ValueIsSet(files);
        let headers = this.buildHeaders(customHeaders, requireAuthorization, hasFiles);
        const formData = new FormData();
        const refreshToken = authHelper.getRefreshToken();
        const baseApiUrl = Http.getBaseApiURL();

        if (hasFiles) {
            const convertedFiles = await convertToHEICToJPG(files);

            convertedFiles.forEach(function (file) {
                formData.append('attachments', file);
            });

            if(ValueIsSet(data)){
                formData.append('params', JSON.stringify(data));
            }
        }

        let reducedParams = null;

        if (ValueIsSet(params))
            reducedParams = Object.keys(params).reduce((reducedParams, key) => {
                //We only want to send params that have a value set, otherwise params will be sent back as a 'null' string and break the backend serialization or cause unexpected behavior where strings are expected
                if (ValueIsSet(params[key]))
                    reducedParams[key] = params[key];

                return reducedParams;
            }, {});

        const options = {
            url: baseApiUrl + this.baseUrl + "/" + path,
            method,
            data: files ? formData : data,
            params: reducedParams,
            paramsSerializer: (params) => this.stringifyParams(params),
            headers,
            responseType
        };

        return axios(options).then((response) => {
            return response.data;
        }).catch((error) => {
            console.error('Error in service call: ', error.toString());
            let { config, response } = error;
            let { status } = response;
            let originalRequest = config;

            if (status === 401 && refreshToken != null) {
                if(!HttpHandler.isAlreadyFetchingAccessToken) {
                    HttpHandler.isAlreadyFetchingAccessToken = true;
                    this.refreshAccessToken(refreshToken).then((accessToken) => {
                        HttpHandler.isAlreadyFetchingAccessToken = false;
                        this.onAccessTokenFetched(accessToken);
                    }).catch(() => {
                        HttpHandler.subscribers = [];
                        HttpHandler.isAlreadyFetchingAccessToken = false;
                        HttpHandler.onUnauthorized();
                    });
                }

                //Retry original request
                return new Promise((resolve) => {
                    this.addSubscriber(accessToken => {
                        let updatedAuthHeader = {'X-Authorization' : "Bearer " + accessToken};
                        Object.assign(originalRequest.headers, updatedAuthHeader);

                        return resolve(axios(originalRequest).then((response) => {
                            return response.data;
                        }).catch((error) => {
                            this.handleError(error);
                        }));
                    });
                });
            } else {
                this.handleError(error);
            }

            error.toString = function() {
                return error.response.headers['error-message']
            };

            throw error;
        });
    };

    buildHeaders = (customHeaders, requireAuthorization, hasFiles) => {
        let headers = {
            "Portal-type": localStorage.getItem(LocalStorageKeys.PORTAL_TYPE)
        };

        if(ValueIsSet(customHeaders)) {
            Object.assign(headers, customHeaders);
        }

        const contentTypeHeader = hasFiles ? Headers.MULTIPART_FORM_DATA : Headers.CONTENT_TYPE_JSON;

        Object.assign(headers, contentTypeHeader);

        if(requireAuthorization) {
            //Add X-Authorization to header
            const token = authHelper.getAuthToken();
            const userId = authHelper.getUserId();
            const authHeader = {
                "X-Authorization" : "Bearer " + token,
                "User-id" : userId
            };

            Object.assign(headers, authHeader);
        }

        return headers;
    };

    addSubscriber = (callback) => {
        HttpHandler.subscribers.push(callback);
    };

    handleError = (error) => {
        let {response} = error;

        if (ValueIsSet(error) && ValueIsSet(response) && ValueIsSet(response.headers) && ValueIsSet(response.headers['error-message']))
            HttpHandler.errorHandler('Error', response.headers['error-message'], response.status);
        else
            HttpHandler.errorHandler('Uncaught Error', error.message, response.status);
    };

    refreshAccessToken = (token) => {

        const options = {
            url: Http.getBaseApiURL() + "/user/user/tokens/refresh",
            method: RequestMethod.POST,
            data: token,
            headers: Headers.CONTENT_TYPE_JSON
        };

        return axios(options).then((response) => {
            return authHelper.storeAuthData(response.data);
        }).then(() => {
            return authHelper.getAuthToken();
        });
    };

    onAccessTokenFetched = (accessToken) => {
        HttpHandler.subscribers = HttpHandler.subscribers.filter(callback => callback(accessToken));
    };

    stringifyParams = (params) => {
        if(params) {
            let queryString = Object.keys(params).reduce((string,key) => {
                if(Array.isArray(params[key]))
                    string += params[key].reduce((innerString, innerKey)=>{
                        innerString += key + '=' + innerKey + '&';
                        return innerString;
                    },'');
                else
                    string += key + '=' + params[key] + '&';

                return string;
            },'');

            return queryString.slice(0,-1); //removing last character
        }
    };
}

export default Http;