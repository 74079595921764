import React from 'react';
import Box from "@mui/material/Box";

const LegacyPageContainer = (props) => {

    return (
        <Box
            pl={{xs: 2, sm: '5%'}}
            pr={{xs: 2, sm: 0}}
            width={{xs: '100%', sm: '83%'}}>
            {props.children}
        </Box>
    )
};

export default LegacyPageContainer;