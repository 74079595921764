import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import MaterialSlider from '@mui/material/Slider'
import {ComponentColor} from "../../enums/Color";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {withTheme} from "../Theme/ThemeProvider";
import {ObjectsHaveIdenticalValue} from "../../helpers/GenericHelpers";

export const SliderOrientation = Object.freeze({
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
});

export const SliderValueDisplay = Object.freeze({
    ON: 'on',
    AUTO: 'auto',
    OFF: 'off'
});

export const SliderTrack = Object.freeze({
    NORMAL: 'normal',
    INVERTED: 'inverted',
    FALSE: 'false'
});

const Slider = (props) => {
    let {
        color,
        defaultValue,
        disabled,
        marks,
        max,
        min,
        onChange,
        onChangeCommitted,
        orientation,
        step,
        track,
        valueLabelDisplay
    } = props;

    return (
        <MaterialSlider color={color}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        marks={marks}
                        max={max}
                        min={min}
                        onChange={onChange}
                        onChangeCommitted={onChangeCommitted}
                        orientation={orientation}
                        step={step}
                        track={track}
                        valueLabelDisplay={valueLabelDisplay}/>
    )
};

Slider.defaultProps = {
    color: ComponentColor.PRIMARY,
    orientation: SliderOrientation.HORIZONTAL,
    track: SliderTrack.NORMAL,
    valueLabelDisplay: SliderValueDisplay.OFF
};

// see material-ui library for additional functionality
Slider.propTypes = {
    color: PropTypes.oneOf([
        ComponentColor.PRIMARY,
        ComponentColor.SECONDARY
    ]),
    defaultValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number) // range [firstValue, secondValue]
    ]).isRequired,
    disabled: PropTypes.bool,
    marks: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string
        })
    ]),
    max: PropTypes.number.isRequired, // max and min cannot be equal
    min: PropTypes.number.isRequired,
    onChange: PropTypes.func, // returns a single value or a two value array based off of whether ranged or not
    onChangeCommitted: PropTypes.func,
    orientation: CustomPropTypes.enum(SliderOrientation),
    step: PropTypes.number.isRequired,
    track: CustomPropTypes.enum(SliderTrack),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number) // range [firstValue, secondValue]
    ]),
    valueLabelDisplay: CustomPropTypes.enum(SliderValueDisplay)
};

export default memo(withTheme(Slider), (props, nextProps) => ObjectsHaveIdenticalValue(props, nextProps));