import React from 'react';
import PropTypes from 'prop-types';
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import {TextAlign} from "worksmith/enums/CSSEnums";
import styled from 'styled-components';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Grid from "worksmith/components/Grid/Grid";

const RecurringServiceClientSummaryString = (props) => {
    const {obligationTemplates, clientId, pendingOpportunities} = props;

    //Go through each opportunity and filter down to only opportunities for the designated client, while also grouping opportunities by service line
    const pendingOpportunitiesByServiceLine = pendingOpportunities.reduce((pendingOpportunitiesByServiceLine, pendingOpportunity) => {
        if (pendingOpportunity.client.id === clientId) {
            if (!ValueIsSet(pendingOpportunitiesByServiceLine[pendingOpportunity.serviceLine.name]))
                pendingOpportunitiesByServiceLine[pendingOpportunity.serviceLine.name] = [];

            pendingOpportunitiesByServiceLine[pendingOpportunity.serviceLine.name].push(pendingOpportunity);
        }

        return pendingOpportunitiesByServiceLine;
    }, {});

    //Go through each obligation template and filter down to only templates for the designated client, while also grouping templates by service line
    const ongoingServiceByServiceLine = ValueIsSet(obligationTemplates) ? obligationTemplates.reduce((ongoingServiceByServiceLine, obligationTemplate) => {
        if (obligationTemplate.client.id === clientId && (ObligationTemplateStatus.getOpenStatuses().includes(obligationTemplate.status))) {
            if (!ValueIsSet(ongoingServiceByServiceLine[obligationTemplate.serviceLine.name]))
                ongoingServiceByServiceLine[obligationTemplate.serviceLine.name] = [];

            ongoingServiceByServiceLine[obligationTemplate.serviceLine.name].push(obligationTemplate);
        }

        return ongoingServiceByServiceLine;
    }, {}) : [];

    const combinedData = {};

    Object.entries(ongoingServiceByServiceLine).forEach(([key, value]) => {
        combinedData[key] = key + ": " + value.length + " ongoing";
    });

    Object.entries(pendingOpportunitiesByServiceLine).forEach(([key, value]) => {
        if (ValueIsSet(combinedData[key]))
            combinedData[key] += " | " + value.length + " pending setup";
        else
            combinedData[key] = key + ": " + value.length + " pending setup";
    });

    return (
        <Grid container>
            {Object.values(combinedData)
                .sort((stringA, stringB) => stringA.localeCompare(stringB))
                .map((serviceLineString) =>
                <Grid item xs={12} key={serviceLineString}>
                    <SummaryText color={TextColor.TEXT_SECONDARY} variant={TextVariant.SUBTITLE_1} light align={TextAlign.RIGHT}>{serviceLineString}</SummaryText>
                </Grid>
            )}
        </Grid>
    )
};

const SummaryText = styled(Text)`
 padding-top: 0.2em;
`;

RecurringServiceClientSummaryString.propTypes = {
    clientId: PropTypes.number.isRequired,
    obligationTemplates: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.shape({
            id: PropTypes.number.isRequired,
            officialName: PropTypes.string.isRequired
        }),
        serviceLine: PropTypes.shape({
            id: PropTypes.number
        }),
        status: CustomPropTypes.enum(ObligationTemplateStatus)
    })).isRequired,
    pendingOpportunities: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.shape({               //client is required here if you plan to group the data by client
            id: PropTypes.number.isRequired
        }),
        serviceLine: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number
        }).required
    })),
};

export default RecurringServiceClientSummaryString;