// This file was generated from com.worksmith.vpm.controller.VendorLocationController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class VendorLocationService extends Http {
    constructor() {
        super('/vpm/vendorLocation')
    }
    
    addServiceLine(vendorLocationId, serviceLineId) {
        return this.callApi( `${vendorLocationId}/addServiceLine`, PATCH, serviceLineId, null, null, true);
    }
    
    addZipCodes(vendorLocationId, createParams) {
        return this.callApi( `${vendorLocationId}/addZipCodes`, POST, createParams, null, null, true);
    }
    
    addZipCodesToVendorLocation(vendorLocationId, zipCodesCSV) {
        return this.callApi( `${vendorLocationId}/addZipCodesToVendorLocation`, POST, zipCodesCSV, null, null, true);
    }
    
    declineAllOpenOpportunities(vendorLocationId) {
        return this.callApi( `${vendorLocationId}/declineAllOpenOpportunities`, PATCH, null, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllActiveSummaries() {
        return this.callApi( `findAllActiveSummaries`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findByServiceLineIds(serviceLineIds) {
        return this.callApi( `findByServiceLineIds`, POST, serviceLineIds, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findStandardPricings(id, serviceLineId, clientLocationId) {
        let params = {
            clientLocationId
        };
    
        return this.callApi( `${id}/serviceLines/${serviceLineId}/standardPricings`, GET, null, params, null, true);
    }
    
    getAssociatedClientLocations(id, clientId, serviceLineId) {
        let params = {
            serviceLineId
        };
    
        return this.callApi( `${id}/associatedClients/${clientId}/clientLocations`, GET, null, params, null, true);
    }
    
    getAssociatedClients(vendorLocationId, serviceLineId) {
        let params = {
            serviceLineId
        };
    
        return this.callApi( `${vendorLocationId}/associatedClients`, GET, null, params, null, true);
    }
    
    getVendorActiveTicketNumber(vendorLocationId) {
        return this.callApi( `${vendorLocationId}/vendorActiveTickets`, GET, null, null, null, true);
    }
    
    identify() {
        return this.callApi( `identify`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    removeServiceLine(vendorLocationId, serviceLineId) {
        return this.callApi( `${vendorLocationId}/removeServiceLine`, PATCH, serviceLineId, null, null, true);
    }
    
    removeZipCodes(vendorLocationId, zipCodes) {
        return this.callApi( `${vendorLocationId}/removeZipCodes`, POST, zipCodes, null, null, true);
    }
    
    search() {
        return this.callApi( `search`, GET, null, null, null, true);
    }
    
    searchSimple(name, clientId, clientLocationId, serviceLineIds, zipCodes) {
        let params = {
            name, 
            clientId, 
            clientLocationId, 
            serviceLineIds, 
            zipCodes
        };
    
        return this.callApi( `searchSimple`, GET, null, params, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    updateZipCodes(vendorLocationId, updateParams) {
        return this.callApi( `${vendorLocationId}/updateZipCodes`, POST, updateParams, null, null, true);
    }
    
    
}

export default VendorLocationService;
