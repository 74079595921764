import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {merge} from "lodash";

import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import ScheduledView from "./ScheduledView";
import ObligationTemplateService from "../../../../services/api/ObligationTemplateService";
import ObligationService from "../../../../services/api/ObligationService";
import ChangeOrderRequestService from "../../../../services/api/ChangeOrderRequestService";
import ChangeRequestType from "worksmith/enums/api/cr/ChangeRequestType";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";

const graphQLServiceClass = new GraphQLServiceClass();
const obligationTemplateService = new ObligationTemplateService();
const obligationService = new ObligationService();
const changeOrderService = new ChangeOrderRequestService();

class Scheduled extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            changeOrder: null,
            canAcceptChangeOrder: false,
            duration: null,
            frequency: null,
            obligationId: null,
            obligationTemplateId: null,
            schedule: null,
            vendorLocation: null,
            useTicketing: null,
        };

        if (ValueIsSet(props.request)) {
            this.state = merge(this.state, Scheduled.getStateFromRequest(props.request));
        }

    }

    componentDidMount = () => {
        const {requestId} = this.props;

        if (requestId) {
            this.getRequestFromId(requestId).then(request => {

                if (request.associatedObligation && request.associatedObligation.pendingChangeOrderRequest) {
                    changeOrderService.canAccept(request.associatedObligation.pendingChangeOrderRequest.id).then((canAcceptChangeOrder) => {
                        this.setState({canAcceptChangeOrder, ...Scheduled.getStateFromRequest(request), loading: false});
                    })
                } else {
                    this.setState({loading: false,  ...Scheduled.getStateFromRequest(request)});
                }
            })
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            this.getRequestFromId(this.props.requestId).then((data) => {
                this.setState({loading: false, ...Scheduled.getStateFromRequest(data)});
            });
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            this.getRequestFromId(this.props.requestId).then((data) => {
                this.setState({loading: false, ...Scheduled.getStateFromRequest(data)});
            });
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            newState = merge(newState, this.getStateFromRequest(DeepCopy(props.request)))
        }

        return newState;
    };

    getRequestFromId = (requestId) => {

        return graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            ScheduledGraphQLString)
    };

    static getStateFromRequest = (request) => {
        let newState = {};

        if (request.associatedObligation) {
            newState.duration = request.associatedObligation.duration;
            newState.schedule = request.associatedObligation.schedule;
            newState.changeOrder = request.associatedObligation.pendingChangeOrderRequest;
            newState.vendorLocation = request.associatedObligation.vendorLocation;
            newState.obligationId = request.associatedObligation.id;

        } else if (request.associatedObligationTemplate) {
            newState.duration = request.associatedObligationTemplate.recurringScheduleTemplate.duration;
            newState.frequency = request.associatedObligationTemplate.recurringScheduleTemplate.frequency;
            newState.schedule = request.associatedObligationTemplate.schedule;
            newState.vendorLocation = request.associatedObligationTemplate.vendorLocation;
            newState.obligationTemplateId = request.associatedObligationTemplate.id;
        }
        newState.useTicketing = request.serviceLine.useTicketing;

        return newState;
    };

    createChangeRequest = (schedules) => {
        const {obligationId, obligationTemplateId} = this.state;
        const {onChangeRequestCreated, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        let changeRequest = {
            scheduleHandler: {
                scheduleOptions: schedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        if (obligationId) {
            changeRequest.obligation = {
                id: obligationId
            };
            obligationService.addChangeRequest(obligationId, changeRequest, false, false, false)
                .then(() => onChangeRequestCreated())
        } else if (obligationTemplateId) {
            changeRequest.obligationTemplate = {
                id: obligationTemplateId
            };
            obligationTemplateService.addChangeRequest(obligationTemplateId, changeRequest, false, false, false)
                .then(() => onChangeRequestCreated())
        }
    };

    acceptChangeOrder = () => {
        const {changeOrder} = this.state;
        const {onAcceptChangeOrder, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        if(changeOrder && changeOrder.id) {
            changeOrderService.accept(changeOrder.id)
                .then(() => onAcceptChangeOrder())
        }
    }

    declineChangeOrder = (declineReason) => {
        const {changeOrder} = this.state;
        const {onDeclineChangeOrder, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        if(changeOrder && changeOrder.id) {
            changeOrderService.decline(changeOrder.id, declineReason)
                .then(() => onDeclineChangeOrder())
        }
    }

    render() {
        const {acceptChangeOrder, declineChangeOrder, createChangeRequest} = this;
        const {
            canAcceptChangeOrder,
            changeOrder,
            duration,
            loading,
            frequency,
            schedule,
            vendorLocation,
            useTicketing,
        } = this.state;
        const {user} = this.props;

        return (
            <ScheduledView
                acceptChangeOrder={acceptChangeOrder}
                canAcceptChangeOrder={canAcceptChangeOrder}
                changeOrder={changeOrder}
                declineChangeOrder={declineChangeOrder}
                duration={duration}
                frequency={frequency}
                loading={loading || this.props.loading}
                createChangeRequest={createChangeRequest}
                schedule={schedule}
                user={user}
                useTicketing={useTicketing}
                vendorLocation={vendorLocation}/>
        )
    }
}

const ObligationScheduledPropTypes = {
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

Scheduled.propTypes = {
    loading: PropTypes.bool,
    onAcceptChangeOrder: PropTypes.func.isRequired,
    onChangeRequestCreated: PropTypes.func.isRequired,
    onDeclineChangeOrder: PropTypes.func.isRequired,
    request: PropTypes.shape({
        serviceLine: PropTypes.shape({
            useTicketing: PropTypes.bool
        }),
        associatedObligation: PropTypes.shape({
            duration: PropTypes.number,
            pendingChangeOrderRequest: PropTypes.shape({
                id: PropTypes.number,
                changedAdditionalApprovalAmount: PropTypes.number,
                changedDuration: PropTypes.number,
                changedLineItems: PropTypes.arrayOf(PropTypes.shape({
                    lineItemType: PropTypes.shape({
                        name: PropTypes.string
                    }),
                    description: PropTypes.string,
                    id: PropTypes.number,
                    quantity: PropTypes.number,
                    unitRetailPrice: PropTypes.number
                })),
                changedScopeOfWork: PropTypes.string,
                originalAdditionalApprovalAmount: PropTypes.number
            }),
            ...ObligationScheduledPropTypes,
        }),
        associatedObligationTemplate: PropTypes.shape({
            recurringScheduleTemplate: PropTypes.shape({
                duration: PropTypes.number,
                frequency: PropTypes.shape({
                    recurrenceCount: PropTypes.number,
                    recurrenceType: CustomPropTypes.enum(RecurrenceType),
                    repeatEvery: PropTypes.number,
                })
            }),
            ...ObligationScheduledPropTypes,
        })
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func
};

const ObligationScheduledFields = `
    schedule {
        arrivalEndTime
        arrivalStartTime
        date
        dayOfMonth
        daysOfWeek
        duration
        recurrenceType
        repeatEvery
        returnDate
        scheduleDows {
            dayOfWeek
            arrivalStartTime
            arrivalEndTime
        }
    }
    vendorLocation {
        vendor {
            officialName
        }
    }
`;

const ScheduledGraphQLString = `
    serviceLine {
        useTicketing
    }
    associatedObligation {
        id
        pendingChangeOrderRequest {
            id
            changedAdditionalApprovalAmount
            changedDuration
            changedLineItems {
                lineItemType {
                    name
                }
                description
                id
                quantity
                unitRetailPrice
            }
            createdTimestamp
            changeOrderSubmittedActivity {
                createdTimestamp
                user {
                    displayName
                }
            }
            changedScopeOfWork
            obligation {
                vendor {
                    nickname
                    rating
                }
            }
            originalAdditionalApprovalAmount
        }
        duration
        ${ObligationScheduledFields}
    }
    associatedObligationTemplate {
        id
        recurringScheduleTemplate {
            duration
            frequency {
                recurrenceCount
                recurrenceType
                repeatEvery
            }
        }
        ${ObligationScheduledFields}
    }
`;

export default Scheduled;