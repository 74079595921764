import React from 'react'
import PropTypes from "prop-types";

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Select, {SelectViewSize} from "worksmith/components/Inputs/Select/Select";
import {Country} from "worksmith/enums/api/country/Country";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const CountrySelect = ({error, helperText, initialValue, label, viewSize, onChange}) => {

    const countryOptions = () => {
        return Object.values(Country).map(state => ({
            label: state,
            value: state
        }));
    };

    return (
        <Select
            noMaxWidth
            fullWidth
            label={label}
            onChange={(country) => onChange(country)}
            initialValue={ValueIsSet(initialValue) ? initialValue : ''}
            options={countryOptions()}
            viewSize={viewSize}
            error={error}
            helperText={helperText}
        />
    )
};

export default CountrySelect;

CountrySelect.propTypes = {
    error: PropTypes.bool,
    helperText: PropTypes.string,
    initialValue: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    viewSize: CustomPropTypes.enum(SelectViewSize),
};