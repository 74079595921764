import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';

import {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Text from "worksmith/components/Text/Text.web";
import Grid from "worksmith/components/Grid/Grid.web";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import {withTheme} from "../../../components/Theme/ThemeProvider";

const RequestStateLayout = (props) => {
    const {
        description,
        body,
        loading,
        status,
        statusColor
    } = props;
    const theme = useTheme();
    const onMobile =  useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container maxWidth={800} spacing={3}>
            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <Text variant={TextVariant.H6} color={statusColor} renderSkeleton={loading} SkeletonProps={{width: onMobile ? '9rem' : '12rem'}}>
                        {status}
                    </Text>
                </Grid>
                <Grid item xs={12}>
                    {
                        typeof description === JavascriptType.STRING || loading ?
                            <Text variant={TextVariant.BODY_1} renderSkeleton={loading} SkeletonProps={{width: onMobile ? '11rem' : '20rem'}}>
                                {description}
                            </Text>
                            :
                            description
                    }
                </Grid>
            </Grid>
            {
                loading ?
                    null
                    :
                    <Grid item xs={12}>
                        {body}
                    </Grid>
            }
        </Grid>
    )
};

RequestStateLayout.defaultProps = {
    statusColor: TextColor.PRIMARY
};

RequestStateLayout.propTypes = {
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    loading: PropTypes.bool,
    body: PropTypes.element,
    status: PropTypes.string.isRequired,
    statusColor: CustomPropTypes.enum(TextColor)
};

export default withTheme(RequestStateLayout);