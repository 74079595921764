import React from 'react';
import PropTypes from 'prop-types';

import {VictoryPie, VictoryLegend} from 'victory';
import {DataVisualizationTheme, FontFamily} from "../../Theme/ThemeProvider";
import CustomDataLabel from "../CustomDataLabel.web";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import {OverflowOption} from "worksmith/enums/CSSEnums";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";

const PieChart = (props) => {
    const {
        animate,
        data,
        hideLabels,
        overflow,
        title,
        withLegend,
        x,
        y,
        width
    } = props;

    let combinedDataTotal = 0;

    if (data.length > 0 && !isNaN(data[0].y))
        data.forEach((dataPoint) => {
            combinedDataTotal += dataPoint.y;
        });

    return (
        data.length > 0 ?
            <div style={{width: width + 'px', margin: 'auto'}}>
                {typeof title !== JavascriptType.STRING ?
                    title
                    :
                    null
                }
                <svg viewBox={'0 0 500 350'} style={{height: 'auto', width: '100%', overflow: overflow}}>
                    {typeof title === JavascriptType.STRING ?
                        <text fontSize={20} fontFamily={FontFamily} x={250} textAnchor={'middle'}>{title}</text>
                        :
                        null
                    }
                    <VictoryPie theme={DataVisualizationTheme}
                                animate={animate}
                                standalone={false}
                                innerRadius={80}
                                labelRadius={140}
                                labels={({datum}) => hideLabels ? '' : datum.x}
                                labelComponent={<CustomDataLabel withLegend={withLegend} hideLabels={hideLabels}
                                                                 type={'pie'} combinedDataTotal={combinedDataTotal}/>}
                                width={500}   //the best way to dictate the actually rendered size of the chart is to change the width of the container you place it in
                                height={350}
                                x={x}
                                y={y}
                                origin={withLegend && hideLabels ? {x: 200} : withLegend ? {x: 170} : {}}
                                data={data}/>
                    {withLegend ?
                        <VictoryLegend standalone={false}
                                       theme={DataVisualizationTheme}
                                       x={400}
                                       y={175 - ((data.length > 13 ? 13 : data.length) * 13)}
                                       itemsPerRow={13}
                                       data={data.map((dataPoint) => {
                                           return {name: dataPoint.x}
                                       })}/>
                        :
                        null
                    }
                </svg>
            </div>
            :
            null
    )
};

PieChart.propTypes = {
    animate: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),       //more info here https://formidable.com/open-source/victory/docs/victory-animation and here https://formidable.com/open-source/victory/docs/victory-transition
    data: PropTypes.arrayOf(PropTypes.shape({
        tooltip: PropTypes.string,
        tooltipLabelFontSize: CustomPropTypes.stringOrNumber,
        x: PropTypes.string,        //by default x is the label
        y: PropTypes.number         //by default y is the value
    })).isRequired,
    hideLabels: PropTypes.bool,
    overflow: CustomPropTypes.enum(OverflowOption),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    width: PropTypes.number.isRequired,                 //width is required because the height of the pie chart itself is auto calculated to ensure the correct shape
    withLegend: PropTypes.bool,
    x: PropTypes.oneOfType([                            //this can be used to specify a data accessor for the x value, more info here https://formidable.com/open-source/victory/docs/common-props/#x
        PropTypes.string,                               //string: specify which property in an array of data objects should be used as the x value
        PropTypes.number,                               //function: use a function to translate each element in a data array into an x value
        PropTypes.arrayOf(PropTypes.string),            //array index: specify which index of an array should be used as an x value when data is given as an array of arrays
        PropTypes.func                                  //path string or path array: specify which property in an array of nested data objects should be used as an x value
    ]),
    y: PropTypes.oneOfType([                            //this can be used to specify a data accessor for the y value, more info here https://formidable.com/open-source/victory/docs/common-props/#y
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func
    ])
};

PieChart.defaultProps = {
    animate: {duration: 1000},
    overflow: OverflowOption.VISIBLE
};

export default PieChart;

