// This file was generated from com.worksmith.mall.controller.MallController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class MallService extends Http {
    constructor() {
        super('/mall')
    }
    
    addApprovedVendor(mallId, vendorLocationId) {
        return this.callApi( `${mallId}/approvedVendor/${vendorLocationId}`, POST, null, null, null, true);
    }
    
    createMall(params) {
        return this.callApi( ``, POST, params, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteMall(id) {
        return this.callApi( `${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getAllMallsDRO() {
        return this.callApi( ``, GET, null, null, null, true);
    }
    
    getApprovedVendors(mallId) {
        return this.callApi( `${mallId}/approvedVendor`, GET, null, null, null, true);
    }
    
    getMallDRO(id) {
        return this.callApi( `${id}`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    patchMall(id, params) {
        return this.callApi( `${id}`, PATCH, params, null, null, true);
    }
    
    removeApprovedVendor(mallId, vendorLocationId) {
        return this.callApi( `${mallId}/approvedVendor/${vendorLocationId}`, DELETE, null, null, null, true);
    }
    
    searchMallBDRO(params) {
        return this.callApi( `searchSimpleMalls`, POST, params, null, null, true);
    }
    
    searchMallDROs(params) {
        return this.callApi( `searchMallDRO`, POST, params, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default MallService;
