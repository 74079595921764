import React from 'react';
import PropTypes from 'prop-types';
import MaterialModal from '@mui/material/Modal';
import {Display, AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {withTheme} from '../Theme/ThemeProvider';
import Box from "@mui/material/Box";

const Modal = (props) => {
        let {
            disableBackdropClick,
            disableEscapeKey,
            keepMounted,
            onBackdropClick,
            onClose,
            onEscapeKeyDown,
            onRendered,
            open
        } = props;

        return (
            <MaterialModal disableAutoFocus
                           disableBackdropClick={disableBackdropClick}
                           disableEscapeKeyDown={disableEscapeKey}
                           keepMounted={keepMounted}
                           onBackdropClick={onBackdropClick}
                           onClose={onClose}
                           onEscapeKeyDown={onEscapeKeyDown}
                           onRendered={onRendered}
                           open={open}
                           style={{display: Display.FLEX, alignItems: AlignItems.CENTER, justifyContent: JustifyContent.CENTER}}>
                <Box style={{outline: '0'}}>
                    {props.children}
                </Box>
            </MaterialModal>
        )
};

Modal.propTypes = {
    disableBackdropClick: PropTypes.bool,
    disableEscapeKey: PropTypes.bool,
    keepMounted: PropTypes.bool,                    //determines whether the children in the modal stay mounted after modal is closed
    onBackdropClick: PropTypes.func,                //function called when background is clicked
    onClose: PropTypes.func,
    onEscapeKeyDown: PropTypes.func,
    onRendered: PropTypes.func,                     //function called when modal is fully rendered
    open: PropTypes.bool.isRequired
};

Modal.defaultProps = {
    keepMounted: false
};

export default withTheme(Modal);