import React from 'react';
import PropTypes from 'prop-types';
import FreeFormTextModal from "worksmith/components/FreeFormTextModal/FreeFormTextModal";

const DeclineChangeOrderDialog = (props) => {
    const {open, onClose, onSubmit} = props;
    return (
        <>
            <FreeFormTextModal
                cancelButtonText={'NO, GO BACK'}
                submitButtonTex={'YES, DECLINE'}
                onClose={onClose}
                onSubmit={onSubmit}
                open={open}
                question={'Decline Change Order?'}
                required
                submitButtonText={'Yes, Continue'}
                supportingText={'Declining the change order will keep the original pricing in place. Please provide your reason for declining.'}
                textFieldRows={5}
                validationError={'Reason field must not be empty'}/>
        </>
    )
};

DeclineChangeOrderDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default DeclineChangeOrderDialog