import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import {Input} from "@mui/material";
import {Button, Menu, MenuItem} from '@mui/material';
import {Add, PhotoLibrary, Publish} from '@mui/icons-material';

import {AlignItems, Display, JustifyContent, TextAlign} from "worksmith/enums/CSSEnums";
import {Color} from "worksmith/enums/Color";
import {GetUniqueReactKey, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Grid from "worksmith/components/Grid/Grid";
import FileUploadMediaLibraryModal from "worksmith/components/FileUpload/FileUploadMediaLibraryModal";
import Text, {TextColor, TextDisplay, TextVariant} from "worksmith/components/Text/Text";

const StyledLabel = styled.label`
  color: ${Color.DARK_GREY};
  display: ${Display.FLEX};
  align-items: ${AlignItems.CENTER};
  justify-content: ${JustifyContent.SPACE_BETWEEN};
  cursor: pointer;
`

const StyledText = styled.div`
  margin-left: 16px;
`

const StyledUploadButton = styled(Button)`
    width: 5.5em;
    height: 5.5em;
`;


const FileUploadButton = (props) => {
    const {
        acceptedFileFormats,
        addFiles,
        addMediaLibraryFiles,
        allowMediaLibrary,
        centerAddAttachmentButton,
        disabled,
        error,
        locationId,
        maxFileSizeCaptionsUnderAddAttachmentButton,
        multiple,
        uploadButtonBody,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [showMediaLibraryModal, setShowMediaLibraryModal] = useState(false);
    const inputKey = GetUniqueReactKey();
    const fileUploadKey = GetUniqueReactKey();


    const openMediaLibraryModal = () => {
        setAnchorEl(null);
        setShowMediaLibraryModal(true);
    };

    const closeMediaLibraryModal = () => {
        setShowMediaLibraryModal(false);
    };

    return allowMediaLibrary ? (
            <div>
                <FileUploadMediaLibraryModal
                    addMediaLibraryFiles={addMediaLibraryFiles}
                    closeMediaLibraryModal={closeMediaLibraryModal}
                    locationId={locationId}
                    multiple={multiple}
                    showMediaLibraryModal={showMediaLibraryModal}
                />
                <StyledUploadButton
                    component={"span"}
                    disabled={disabled}
                    variant={"outlined"}
                    color={'inherit'}
                    onClick={e => setAnchorEl(e.currentTarget)}
                >
                    <Add color={'inherit'} style={{'fontSize': '3em'}}/>
                </StyledUploadButton>
                <Input
                    style={{'display': Display.NONE}}
                    onChange={addFiles}
                    id={fileUploadKey.toString()}
                    type={"file"}
                    inputProps={{
                        multiple: multiple,
                        accept: acceptedFileFormats
                    }}
                />
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                    onClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl)}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => setAnchorEl(null)}>
                        <StyledLabel htmlFor={fileUploadKey}>
                            <Publish color={'inherit'}/>
                            <StyledText>
                                <Text display={TextDisplay.INLINE}>Upload from computer</Text>
                            </StyledText>
                        </StyledLabel>
                    </MenuItem>
                    <MenuItem onClick={openMediaLibraryModal}>
                        <PhotoLibrary color={'inherit'}/>
                        <StyledText>
                            <Text display={TextDisplay.INLINE}>Choose from Media Library</Text>
                        </StyledText>
                    </MenuItem>
                </Menu>
            </div>
        ) : (
        <Grid container={ValueIsSet(centerAddAttachmentButton) ? true : false} justify={ValueIsSet(centerAddAttachmentButton) ? JustifyContent.CENTER : null}>
                <Input
                    style={{'display': Display.NONE}}
                    onChange={addFiles}
                    id={inputKey.toString()}
                    type={"file"}
                    disabled={disabled}
                    inputProps={{
                        multiple: multiple,
                        accept: acceptedFileFormats
                    }}
                />
                <label htmlFor={inputKey}>
                    {uploadButtonBody}
                </label>
            {maxFileSizeCaptionsUnderAddAttachmentButton &&
                <Grid item xs={12} textAlign={TextAlign.CENTER}>
                    <Text variant={TextVariant.CAPTION} color={TextColor.TEXT_SECONDARY}>16MB max for individual uploads</Text>
                    <Text variant={TextVariant.CAPTION} color={TextColor.TEXT_SECONDARY}>64MB max for combined total</Text>
                    {ValueIsSet(error) &&
                        <Grid>
                            <Text variant={TextVariant.CAPTION} color={TextColor.ERROR}>{error}</Text>
                        </Grid>
                    }
                </Grid>
            }
        </Grid>
    );
};

FileUploadButton.propTypes = {
    acceptedFileFormats: PropTypes.string,                              // takes a string containing one or more file type specifiers as its value, separated by commas. (ex. '.jpg, .jpeg, .png' or 'image/*,.pdf')
    addFiles: PropTypes.func.isRequired,
    addMediaLibraryFiles: PropTypes.func,
    allowMediaLibrary: PropTypes.bool,
    centerAddAttachmentButton: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    locationId: PropTypes.number,
    maxFileSizeCaptionsUnderAddAttachmentButton: PropTypes.bool,
    multiple: PropTypes.bool.isRequired,
    uploadButtonBody: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired
};

FileUploadButton.defaultProps = {
    acceptedFileFormats: 'image/*,video/*,application/pdf.xls,.csv,.xlsx,.pdf,.xml,.doc,.txt,.heic',
};

export default FileUploadButton;
