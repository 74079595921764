// This file was generated from com.worksmith.user.controller.UserController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class UserService extends Http {
    constructor() {
        super('/user/user')
    }
    
    addAssignmentAllocationGrouping(userAssignmentAllocationGroupingSetParams) {
        return this.callApi( `addAssignmentAllocationGrouping`, POST, userAssignmentAllocationGroupingSetParams, null, null, true);
    }
    
    addUsersToZendesk(userIds) {
        return this.callApi( `addUsersToZendesk`, POST, userIds, null, null, true);
    }
    
    authorizeByBurberryToken(idTokenString) {
        return this.callApi( `authorizeByBurberryToken`, POST, idTokenString, null, null, true);
    }
    
    authorizeByGoogleToken(portal, idTokenString) {
        let params = {
            portal
        };
    
        return this.callApi( `authorizeByGoogleToken`, POST, idTokenString, params, null, true);
    }
    
    authorizeBySquareToken(idTokenString) {
        return this.callApi( `authorizeBySquareToken`, POST, idTokenString, null, null, true);
    }

    bulkUploadFromCSV(listOfFiles, clientId){
        let params = {
            clientId
        };
        return this.callApi( `bulkUploadFromCSV`, POST, null, params, null, true, listOfFiles);
    }
    
    changePassword(changePassword) {
        return this.callApi( `changePassword`, POST, changePassword, null, null, true);
    }

    changeLanguage(data) {
        return this.callApi( `changeLanguage`, POST, data, null, null, true);
    }

    changeSpecificUserLanguage(userId, data) {
        return this.callApi( `${userId}/changeLanguage`, POST, data, null, null, true);
    }
    
    createClientAccount(newAccount) {
        return this.callApi( `createClientAccount`, POST, newAccount, null, null, true);
    }

    createWhiteLabelClientUser(newAccount) {
        return this.callApi(`createWhiteLabelClientUser`, POST, newAccount, null, null, true)
    }

    checkWhiteLabelClient(username) {
        return this.callApi(`checkWhiteLabelClient`, POST, username, null, null, true)
    }

    createNonCorporateClientAccount(newAccount) {
        return this.callApi( `createNonCorporateClientAccount`, POST, newAccount, null, null, true);
    }
    
    createWithFileMask(fileMask, attachment) {
        let params = {
            fileMask
        };
    
        return this.callApi( `createWithFileMask`, POST, null, params, null, true, attachment);
    }
    
    createWorksmithUser(newWorksmithUser) {
        return this.callApi( `createWorksmithUser`, POST, newWorksmithUser, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findByEmail(email) {
        let params = {
            email
        };
    
        return this.callApi( `findByEmail`, GET, null, params, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    forgotPasswordReset(emailAddress) {
        let params = {
            emailAddress
        };
    
        return this.callApi( `resetPassword`, GET, null, params, null, true);
    }

    generateAuthForUser(id) {
        return this.callApi( `generateAuthForUser/${id}`, GET, null, null, null, true);
    }

    generateAccessToken(id) {
        return this.callApi( `${id}/generateAccessToken`, GET, null, null, null, true);
    }
    
    getAccountManagersUserOverview() {
        return this.callApi( `getAccountManagersUserOverview`, GET, null, null, null, true);
    }
    
    getActionItems(today) {
        let params = {
            today
        };
    
        return this.callApi( `actionItems`, GET, null, params, null, true);
    }
    
    getAllOnCall() {
        return this.callApi( `getAllOnCall`, GET, null, null, null, true);
    }
    
    getAssignmentNotifications() {
        return this.callApi( `assignmentNotifications`, GET, null, null, null, true);
    }
    
    getClientLocationClientSummariesForUser(userId) {
        return this.callApi( `${userId}/getClientLocationClientSummariesForUser`, GET, null, null, null, true);
    }
    
    getClientLocationsForUser(userId) {
        return this.callApi( `${userId}/clientLocations`, GET, null, null, null, true);
    }
    
    getMarketplaceOpsUserRequestOverview() {
        return this.callApi( `getMarketplaceOpsUserRequestOverview`, GET, null, null, null, true);
    }
    
    getNeedsAttentionCount(needsAttentionCountParams) {
        return this.callApi( `getNeedsAttentionCount`, POST, needsAttentionCountParams, null, null, true);
    }
    
    getNeedsAttentionCount(clientLocationIds, getTicketing, includeCampaignRequests) {
        let params = {
            clientLocationIds, 
            getTicketing, 
            includeCampaignRequests
        };
    
        return this.callApi( `getNeedsAttentionCount`, GET, null, params, null, true);
    }
    
    getNextAssignment() {
        return this.callApi( `assignments/next`, GET, null, null, null, true);
    }
    
    getNextProspect(id) {
        return this.callApi( `${id}/telesales/prospects/next`, GET, null, null, null, true);
    }
    
    getOpenFeedbackRequests() {
        return this.callApi( `feedbackRequests`, GET, null, null, null, true);
    }
    
    getSetupForUser(userId) {
        return this.callApi( `${userId}/setup`, GET, null, null, null, true);
    }
    
    getTeamSummary() {
        return this.callApi( `getTeamSummary`, GET, null, null, null, true);
    }
    
    getTelesalesProspectActivities(id, limit) {
        let params = {
            limit
        };
    
        return this.callApi( `${id}/telesales/prospects/activities`, GET, null, params, null, true);
    }
    
    getUserDeviceTypes(userId) {
        return this.callApi( `${userId}/getUserDeviceTypes`, GET, null, null, null, true);
    }
    
    getUserTicketStatisticsForRole(worksmithRole) {
        let params = {
            worksmithRole
        };
    
        return this.callApi( `userTicketStatistics`, GET, null, params, null, true);
    }
    
    initiateAclTables() {
        return this.callApi( `initiateAclTables`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    massUserSave(users) {
        return this.callApi( `massUserSave`, POST, users, null, null, true);
    }
    
    putOnCall(id) {
        return this.callApi( `putOnCall/${id}`, POST, null, null, null, true);
    }
    
    refreshAccessToken(refreshToken) {
        return this.callApi( `tokens/refresh`, POST, refreshToken, null, null, true);
    }
    
    registerDevice(userDeviceDetails) {
        return this.callApi( `registerDevice`, POST, userDeviceDetails, null, null, true);
    }
    
    removeAssignmentAllocationGrouping(userAssignmentAllocationGroupingSetParams) {
        return this.callApi( `removeAssignmentAllocationGrouping`, POST, userAssignmentAllocationGroupingSetParams, null, null, true);
    }
    
    removeFromOnCall(id) {
        return this.callApi( `removeFromOnCall/${id}`, POST, null, null, null, true);
    }
    
    resetPassword(changePassword) {
        return this.callApi( `resetPassword`, POST, changePassword, null, null, true);
    }
    
    resetUserPermissions(id) {
        return this.callApi( `resetUserAclPermissions/${id}`, PATCH, null, null, null, true);
    }
    
    saveUserAllocationInformation(bucketAllocations) {
        return this.callApi( `saveUserAllocationInformation`, PATCH, bucketAllocations, null, null, true);
    }
    
    search(clientId, userId, name, clientLocationId, vendorId, userType, role) {
        let params = {
            clientId, 
            userId, 
            name, 
            clientLocationId, 
            vendorId, 
            userType, 
            role
        };
    
        return this.callApi( `search`, GET, null, params, null, true);
    }
    
    searchSimple(clientId, userId, name, clientLocationId, vendorId, userType, role) {
        let params = {
            clientId, 
            userId, 
            name, 
            clientLocationId, 
            vendorId, 
            userType, 
            role
        };
    
        return this.callApi( `searchSimple`, GET, null, params, null, true);
    }
    
    searchSummaries(clientId, userId, name, clientLocationId, vendorId, userType, role) {
        let params = {
            clientId, 
            userId, 
            name, 
            clientLocationId, 
            vendorId, 
            userType, 
            role
        };
    
        return this.callApi( `summaries/search`, GET, null, params, null, true);
    }

    sendNewWorkbenchLink(emailValue,phoneValue,redirectUrl) {
        let data = {
            emailValue,
            phoneValue,
            redirectUrl
        };
        return this.callApi(`sendNewWorkbenchLink`, POST, data, null, null, true)
    }

    sendNonWorksmithWelcomeEmail(userIds) {
        return this.callApi( `sendNonWorksmithWelcomeEmail`, POST, userIds, null, null, true);
    }
    
    sendWelcomeEmail(id) {
        return this.callApi( `${id}/sendWelcomeEmail`, POST, null, null, null, true);
    }
    
    turnOffAllOnCall() {
        return this.callApi( `turnOffAllOnCall`, POST, null, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    validateLogin(user) {
        return this.callApi( `validateLogin`, POST, user, null, null, true);
    }
    
    validateToken(token) {
        let params = {
            token
        };
    
        return this.callApi( `validateToken`, GET, null, params, null, true);
    }
    
    writeToReadUserRequestSummary() {
        return this.callApi( `writeToReadUserRequestSummary`, POST, null, null, null, true);
    }
    fetchUsersThatVendorUserCanMessage() {
        return this.callApi(`fetchUsersThatVendorUserCanMessage`, null, null, null, true);
    }
    
}

export default UserService;
