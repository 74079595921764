import {OAuthProvider, RequestMethod} from "../../Enums";
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import Http from 'worksmith/services/Http/Http';

const authTokenManager = new AuthTokenManager();

class OAuthServiceClass extends Http {
    constructor(){
        super('/oauth');
    }

    authorizeByAuthCode(code, authType) {
        let path = '';

        switch (authType) {
            case OAuthProvider.GOOGLE:
                path = 'authorizeByGoogleAuthCode';
                break;
            case OAuthProvider.BURBERRY:
                path = 'authorizeByBurberryCode';
                break;
            case OAuthProvider.SQUARE:
                path = 'authorizeBySquareCode';
                break;
            case OAuthProvider.AUTH0:
                path = 'authorizeByAuth0Code';
                break;
        }

        return this.callApi(path, RequestMethod.POST, code, null, null, false).then((data) => {
            authTokenManager.storeAuthData(data,authType);
        })
    }

}

export default OAuthServiceClass;