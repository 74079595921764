import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ConversationView from "./ConversationView";
import ConversationService from 'worksmith/services/api/ConversationService';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import moment from "moment";
import {DisplayErrorNotification} from "../../helpers/SweetAlertHelpers";

const conversationService = new ConversationService();
const graphQLService = new AsyncGraphQLServiceClass();

class MessageVendorConversation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            textFieldHidden: false,
            loading: true,
            messages: null,
            content: '',
            files: [],
            isOpen: false,
            submitting: false,
            valid: false
        };

        this.textFieldRef = React.createRef();
    }

    componentDidMount = async () => {
        const {conversationId, requestId} = this.props;
        const {onMessagesRead} = this.props;

        if (conversationId) {
            conversationService.findById(conversationId).then(conversation => {
                this.setState({loading: false, messages: conversation.messages, isOpen: conversation.isOpen, textFieldHidden: !conversation.isOpen});
                conversationService.markRead(conversationId).then(() => {
                    if (onMessagesRead) {
                        onMessagesRead();
                    }
                });
            }).catch(() => {
                this.setState({loading: false});
            })

        } else {
            let conversationMessages = await graphQLService.findAll({requestId},
                GraphQLObjectType.CONVERSATION,
                conversationFields);
            let messages = [];

            if (conversationMessages) {
                messages = conversationMessages.flatMap(conversation => conversation.messages)
                    .sort((a, b) => moment(a.createdTimestamp).isAfter(moment(b.createdTimestamp)));

            }
            this.setState({loading: false, messages: messages, isOpen: true, textFieldHidden: true});
        }
    }

    writeMessage = () => {
        let {content, submitting, files} = this.state;
        const {conversationId, onMessagesRead} = this.props;
        const {textFieldRef} = this;

        if(((content !== '' && ValueIsSet(content)) || files.length > 0) && ValueIsSet(conversationId)) {
            this.setState({submitting: true});

            conversationService.writeMessage(conversationId, content, false, files).then(updatedConversation => {
                if (onMessagesRead) {
                    onMessagesRead();
                }
                textFieldRef.current.clear();
                this.setState({messages: updatedConversation.messages , submitting: false, files: []});
            }).catch((error) =>{
                DisplayErrorNotification("Error sending message");
            }).finally(() => {
                if (submitting)
                    this.setState({submitting: false});
            });
        }
    };

    onFileUploadChange = (fileData) => {
        this.setState({files: fileData})
    };

    onTextFieldChange = (value) => {
        this.setState({content: value});
    };

    render() {
        const {onFileUploadChange, onTextFieldChange, writeMessage} = this;
        const {textFieldRef} = this;
        const {content, files, isOpen, messages, submitting, loading, textFieldHidden} = this.state;
        const {clientLocationId, initialContentOverride} = this.props;
        const valid = content.trim().length > 0 || files.length > 0;

        return (
            <ConversationView
                clientLocationId={clientLocationId}
                initialContent={initialContentOverride}
                initialFiles={files}
                textFieldHidden={textFieldHidden}
                isOpen={isOpen}
                messages={messages}
                loading={loading}
                onFileUploadChange={onFileUploadChange}
                onTextFieldChange={onTextFieldChange}
                disableSubmit={submitting || !valid}
                textFieldRef={textFieldRef}
                writeMessage={writeMessage}
            />
        )
    }
}

MessageVendorConversation.propTypes = {
    clientLocationId: PropTypes.number,
    conversationId: PropTypes.number,
    initialContentOverride: PropTypes.string,
    onMessagesRead: PropTypes.func,
    requestId: PropTypes.number,
};

export default MessageVendorConversation;

const conversationFields = `
messages {
    author {
        id
        nameWithRole
        companyName
    }
    content
    createdTimestamp
    conversationMessageAttachments {
        id
        singleUseUrl
        name
        fileName
    }
}
`