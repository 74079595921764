import PropTypes from 'prop-types';

const AddressPropType = PropTypes.shape({
    addressLineOne: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
});

export default AddressPropType;