import React, {Component} from 'react';

import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {WithContext} from "../../context/GlobalContext";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import Loader from "worksmith/antd-components/Loader/Loader";
import {LoaderType} from "worksmith/enums/LoaderType";
import ServiceLineSummaryPageView from "./ServiceLineSummaryPageView";
import {GroupBy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";

const graphQLServiceClass = new AsyncGraphQLServiceClass();

const ObligationFilterOptions = Object.freeze({
    ALL: 'All',
    ONGOING: 'Ongoing',
    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
    NO_SERVICE: 'No Service'
});

class ServiceLineSummaryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: [],
            obligationTemplates: [],
            locations: null,
            filteredLocations: null,
            serviceLine: null,
            loading: true,
            filterString: []
        };
    }

    componentDidMount = async () => {
        let {client} = this.props;
        let {serviceLineId} = this.props.match.params;
        let {onFilterChange} = this;

        let obligationTemplateSearchParams = {
            clientIds: [client.id],
            serviceLineId: serviceLineId,
            statuses: ObligationTemplateStatus.getOpenStatuses(),
            filterByRequester: true
        };

        let requestSearchParams = {
            clientId: client.id,
            hasNotYetBeenScheduled: true,
            isRecurring: true,
            serviceLineIds: [serviceLineId],
            filterByRequester: true
        };

        let locationSearchParams = {
            clientIds: [client.id],
            filterByRequester: true
        };

        let obligationTemplates = graphQLServiceClass.findAll(
            obligationTemplateSearchParams,
            GraphQLObjectType.FAST_OBLIGATION_TEMPLATE_INFO,
            obligationTemplateFields
        );

        let requests = graphQLServiceClass.findAll(
            requestSearchParams,
            GraphQLObjectType.REQUEST_COMPLETION_TRACKER,
            requestFields,
            "id",
            GraphQLSortDirection.ASCENDING
        );

        let locations = graphQLServiceClass.findAll(
            locationSearchParams,
            GraphQLObjectType.CLIENT_LOCATION,
            clientLocationFields
        );

        let serviceLine = graphQLServiceClass.findOneById(
            serviceLineId,
            GraphQLObjectType.SERVICE_LINE,
            serviceLineFields
        );

        await Promise.all([obligationTemplates, requests, locations, serviceLine]).then(
            data => {
                this.setState({
                    obligationTemplates: data[0],
                    requests: data[1],
                    locations: GroupBy(data[2], 'state'),
                    filteredLocations: GroupBy(data[2], 'state'),
                    serviceLine: data[3], loading: false}, () => {
                    let filter = "All";
                    if(data[1].length > 0)
                        filter = "Pending";
                    if(data[0].length > 0)
                        filter = "Ongoing";
                    onFilterChange(filter);
                });
            }
        );
    };

    onFilterChange = (option) => {

        let {locations, obligationTemplates, requests} = this.state;
        let {addToLocationObject} = this;
        let filteredLocations = {};

        if(ValueIsSet(locations)) {
            Object.keys(locations).forEach(key =>
                locations[key].forEach(location => {
                        if (option === ObligationFilterOptions.ALL) {
                            addToLocationObject(filteredLocations, location);
                        } else if (option === ObligationFilterOptions.ONGOING && (obligationTemplates.filter(ot => ot.status !== 'canceled').map(ot => ot.clientLocationId).includes(location.id))) {
                            addToLocationObject(filteredLocations, location);
                        } else if (option === ObligationFilterOptions.PENDING && (requests.filter(r => r.status !== 'canceled').map(request => request.clientLocationId).includes(location.id))) {
                            addToLocationObject(filteredLocations, location);
                        }  else if (option === ObligationFilterOptions.NO_SERVICE && (((!requests.map(request => request.clientLocationId).includes(location.id))) && (!obligationTemplates.filter(ot => ot.status !== 'canceled').map(ot => ot.clientLocationId).includes(location.id)))) {
                            addToLocationObject(filteredLocations, location);
                        }
                    }
                )
            );
            this.setState({filteredLocations: filteredLocations});
        }
    };

    addToLocationObject = (locationObject, location) => {
        if (Object.keys(locationObject).includes(location.state) && !(locationObject[location.state].includes(location))) {
            locationObject[location.state].push(location);
        }
        if (!(Object.keys(locationObject).includes(location.state))){
            locationObject[location.state] = [location];
        }
    };

    render(){
        let {requests, obligationTemplates, filteredLocations, serviceLine, loading, locations} = this.state;
        let {onFilterChange} = this;
        let filteredRequests = requests.filter(r => !obligationTemplates.map(ot => ValueIsSet(ot.requestForProposal) ? ot.requestForProposal.id : -1).includes(r.id));

        return loading ? <Loader loaderType={LoaderType.LOGO}/> : <ServiceLineSummaryPageView requests={filteredRequests} obligationTemplates={obligationTemplates} locationsByState={filteredLocations} unfilteredLocations={locations} serviceLine={serviceLine} onFilterChange={onFilterChange}/>;
    }
}

const obligationTemplateFields = `
    id
    clientLocationId
     status
     totalRetailPrice
     lastCompletedVisitDate
     nextUpcomingObligationDate
`;

const requestFields = `
    id
    clientLocationId
    status
`;

const clientLocationFields = `
    id
    title
    state
`;

const serviceLineFields = `
    id
    name
`;

export default WithContext(ServiceLineSummaryPage);