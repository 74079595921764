import React, {Component} from 'react';
import Modal from 'antd/lib/modal';
import TicketForm from "../../components/forms/TicketForm";
import ObligationService from 'worksmith/services/api/ObligationService';
import ClientService from 'worksmith/services/api/ClientService';
import Loader from "../../components/Loader";
const obligationService = new ObligationService();
const clientService = new ClientService();

class NewTicketPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: props.user,
            client: props.client,
            settings: {
                dryCleaning: {}
            },
            obligation: null
        };

        this.handleNewTicketSuccess = this.handleNewTicketSuccess.bind(this);
    }

    handleNewTicketSuccess(){
        Modal.confirm({
            title: "Would you like to add another ticket to this pick up date?",
            iconType: "tag",
            onOk: () => window.location.reload(),
            onCancel: () => this.props.history.push('/'),
            okText: "Yes",
            cancelText: "No"
        });
    }

    componentDidMount(){
        let {user} = this.props;
        let _this = this;
        let clientId;
        if (user.clientRoles !== null) {
            clientId = user.clientRoles[0].client.id;
        } else {
            clientId = user.clientLocationRoles[0].clientId;
        }

        let obligationId = this.props.match.params.id;

        clientService.getSingleDryCleaningFormConfiguration(clientId)
            .then(function (data) {
                _this.setState({settings: {dryCleaning: data}});
            });


        obligationService.getSummary(obligationId).then(function(data){
            let obligation = data;

            _this.setState({ obligation });
        })
    }

    render(){
        let {settings, obligation} = this.state;

        if(obligation){
            return(
                    <TicketForm onSuccess={this.handleNewTicketSuccess}
                                   client={this.state.client}
                                   globalSettings={settings}
                                   obligation={this.state.obligation}/>
            )
        } else {
            return <Loader/>
        }
    }
}

export default NewTicketPage;