import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Collapsible from "./Collapsible.web";
import {withTheme} from "../Theme/ThemeProvider";
import Grid from "../Grid/Grid.web";
import Text, {TextDisplay, TextVariant} from "../Text/Text.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {Pluralize} from "worksmith/helpers/LanguageHelper";

import styled from "styled-components";
import IconButton from "../Button/IconButton.web";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import {JustifyContent} from "../../enums/CSSEnums";

const ExpandIconButton = styled(IconButton)`
    ${props => props.expanded ? 'transform: rotate(180deg);' : null}
`;

const Collapse = (props) => {
    let {
        buttonRight,
        count,
        justifySpaceBetween,
        label,
        onExpand,
        expanded,
        pluralLabel,
        timeout
    } = props;

    const iconButtonJSX = (
        <Grid item xs={justifySpaceBetween ? 1 : null}>
            <ExpandIconButton iconName={'ExpandMore'}
                              onClick={onExpand}
                              expanded={expanded}/>
        </Grid>
    );

    const header = [
        <Grid item xs={justifySpaceBetween ? 11 : null}>
            {typeof label === JavascriptType.STRING ?
                <Text display={TextDisplay.INLINE} variant={TextVariant.H6}>{count} {ValueIsSet(count) ? Pluralize(count, label, pluralLabel) : label}</Text>
                :
                label
            }
        </Grid>
    ];


    return (
        <Fragment>
            <Grid container justify={justifySpaceBetween ? JustifyContent.SPACE_BETWEEN : null}>
                {!buttonRight && iconButtonJSX}
                {header}
                {buttonRight && iconButtonJSX}
            </Grid>

            <Collapsible expanded={expanded}
                         timeout={timeout}>
                {props.children}
            </Collapsible>
        </Fragment>
    );
};

Collapse.propTypes = {
    buttonRight: PropTypes.bool,
    count: PropTypes.number,
    expanded: PropTypes.bool,
    justifySpaceBetween: PropTypes.bool,                    //Adds the styling of justify: space-between to the headed and the icon button. Use this if you want the Collapse component use the entire width of the parent component.
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    onExpand: PropTypes.func.isRequired,                    //Triggered when someone clicks the ExpandIconButton
    pluralLabel: PropTypes.string,                          //Used as an override for the plural version of the label if adding 's' to the end isn't correct
    timeout: PropTypes.oneOfType([                          //the duration for the transition
        PropTypes.number,
        PropTypes.oneOf(['auto']),
        PropTypes.shape({
            enter: PropTypes.number,
            exit: PropTypes.number
        })
    ])
};

export default withTheme(Collapse);