import React from 'react';
import PropTypes from "prop-types";

import Grid from "../../../../components/Grid/Grid";
import Table from "../../../../components/Table/Table";
import Tooltip from "worksmith/components/Tooltip/Tooltip";
import Text, {TextColor} from "worksmith/components/Text/Text";
import Icon, {IconFontSize} from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";

const clientColumns = [
    {
        title: 'User',
        fieldPath: 'userColumn',
    },
];

const vendorColumns = [
    {
        title: 'User',
        fieldPath: 'userColumn',
    },
    {
        title:
            <Tooltip
                title={'This sends an email with instructions on downloading the app and logging into client portal.'}
            >
                <Text semiBold color={TextColor.TEXT_SECONDARY}>Send Welcome Email <Icon name={IconType.INFO_OUTLINED} secondary right verticalCentered fontSize={IconFontSize.SMALL}/></Text>
            </Tooltip>,
        fieldPath: 'sendWelcomeEmail',
    },
    {
        title: '',
        fieldPath: 'editUser',
    }
];

const AdminView = ({adminViewFields, vendorPortal}) => {

    return (
        <Grid item xs={11} alignItems={'flex-start'}>
            <Table data={adminViewFields} columns={vendorPortal ? vendorColumns : clientColumns} />
        </Grid>
    )
};

export default AdminView;

AdminView.propTypes = {
    adminViewFields: PropTypes.arrayOf(PropTypes.shape({
        location: PropTypes.string,
        userColumn: PropTypes.string,
        approvalThreshold: PropTypes.number
    })),
    vendorPortal: PropTypes.bool,
};