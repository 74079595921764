// This file was generated from com.worksmith.user.enums.PortalType
// noinspection JSUnusedGlobalSymbols
const PortalType = Object.freeze({
    ADMIN: 'Admin',
    CLIENT: 'Client',
    VENDOR: 'Vendor',
    WORKBENCH: 'workbench'
});

export default PortalType;
