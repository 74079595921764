import React, {useState, Fragment, useEffect} from 'react';

import {WithContext} from "../../context/GlobalContext";
import Grid from 'worksmith/components/Grid/Grid.web';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ClientService from "worksmith/services/api/ClientService";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

import ExploType from "worksmith/enums/api/reporting/ExploType";


const clientService = new ClientService();

const ExploHelper = (props) => {
    const [embedJwt, setEmbedJwt] = useState(null);

    const {
        clientId,
        selectedReport,
        selectedReportVariables,
        environment} = props;

    const fetchEmbedJwt = async () => {
        try {
            const jwt = await clientService.getExploJWTToken(clientId, selectedReport.dashboardKey);
            setEmbedJwt(jwt);
        }
        catch (error) {
            console.error("Error generating Embed JWT", error);
            await DisplayErrorNotification("Error Displaying Report");
        }
    };

    useEffect(() => {
        if(ValueIsSet(selectedReport) && ValueIsSet(selectedReport.dashboardKey)) {
            fetchEmbedJwt();
        }
    }, [selectedReport]);

    return(
        <Fragment>
                    { ValueIsSet(embedJwt) ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/*Steve said to always set the environment to prod for reporting because he doesn't have a QA sandbox*/}
                                {selectedReport.type === ExploType.DASHBOARD ?
                                    <explo-dashboard
                                        dash-jwt={embedJwt}
                                        variables={JSON.stringify(selectedReportVariables)}
                                        isProduction={environment == 'production'}
                                        environment={environment}/>
                                :
                                    <explo-report-builder
                                        jwt={embedJwt}
                                        isProduction={'production'}
                                        environment={'production'}
                                        variables={JSON.stringify(selectedReportVariables)}/>
                                }
                            </Grid>
                        </Grid>
                        : null
                    }
        </Fragment>
        )

};

export default WithContext(ExploHelper);