import React, {Component} from 'react';
import {merge} from 'lodash';

import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {WithContext} from "../../context/GlobalContext";
import SingleRequestPageView from "./SingleRequestPageView";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import {RequestStateFullGraphQLObject} from "worksmith/composite-components/RequestState/RequestState";
import {RequestSummaryGraphQLObject} from "worksmith/composite-components/RequestSummary/RequestSummary";
import {RequestExpandableSectionsGraphQLString} from "worksmith/composite-components/RequestExpandableSections/RequestExpandableSections";

const graphQLServiceClass = new AsyncGraphQLServiceClass();

class SingleRequestPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullRequestLoading: true,
            partialRequestLoading: false,
            request: null,
        };

        this.requestId = props.match.params.id;
    }

    componentDidMount = async () => {
        let {requestId} = this;
        window.scrollTo(0, 0);

        let data = await this.getFullRequestFromId(requestId);

        this.setState({request: data, fullRequestLoading: false});
    };

    getFullRequestFromId = async (requestId) => {

        return await graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            RequestStateFullGraphQLObject +
            RequestSummaryGraphQLObject +
            PartialRequestGraphQLString +
            RequestExpandableSectionsGraphQLString
        )
    };

    reloadFullRequest = async () => {
        let {requestId} = this;

        if (!this.state.fullRequestLoading) {
            this.setState({fullRequestLoading: true});
        }

        let data = await this.getFullRequestFromId(requestId);

        this.setState({request: data, fullRequestLoading: false});
    };

    reloadPartialRequest = async () => {
        let {requestId} = this;
        const {request} = this.state;

        if (!this.state.partialRequestLoading) {
            this.setState({partialRequestLoading: true});
        }

        let data = await graphQLServiceClass.findOneById(
                        requestId,
                        GraphQLObjectType.REQUEST,
                        PartialRequestGraphQLString + RequestExpandableSectionsGraphQLString)



        this.setState({request: merge(request, data), partialRequestLoading: false});
    };

    startLoad = (fullLoad) => {
        if (fullLoad) {
            if (!this.state.fullRequestLoading) {
                this.setState({fullRequestLoading: true});
            }
        } else {
            if (!this.state.partialRequestLoading) {
                this.setState({partialRequestLoading: true});
            }
        }
    }

    render() {
        let {reloadFullRequest, reloadPartialRequest, startLoad} = this;
        let {fullRequestLoading, partialRequestLoading, request} = this.state;
        let {user} = this.props;
        let {client} = this.props.context;

        return (
            <SingleRequestPageView
                clientId={client.id}
                clientSettings={client.settings}
                fullRequestLoading={fullRequestLoading}
                partialRequestLoading={partialRequestLoading}
                reloadFullRequest={reloadFullRequest}
                reloadPartialRequest={reloadPartialRequest}
                startLoad={startLoad}
                requestId={this.requestId}
                request={request}
                user={user}/>
        )
    }
}

const PartialRequestGraphQLString = `
    hasPendingChangeRequest
    newStatus
    status
`;

export default WithContext(SingleRequestPage);