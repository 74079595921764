import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Accordion as MuiAccordion, AccordionActions, AccordionDetails, AccordionSummary} from '@mui/material';
import {withTheme} from "../Theme/ThemeProvider";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import Icon from "../Icon/Icon";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = (props) => {
    let {
        actions,
        details,
        detailsPadding,
        disabled,
        expanded,
        expandIcon,
        large,
        onChange,
        summaryPadding,
        summary,
        width,
        transitionProps,
        elevation,
        children,
        renderDetailsOnFirstExpansion,
        square
    } = props;

    const [renderDetails, setRenderDetails] = useState(!renderDetailsOnFirstExpansion || expanded);

    const onChangeWrapper = (event, expanded) => {
        if(renderDetailsOnFirstExpansion && !renderDetails) {
            setRenderDetails(true);
        }

        if(ValueIsSet(onChange)){
            onChange(event, expanded);
        }
    };


    let icon = undefined;

    if (ValueIsSet(expandIcon)) {
        icon = typeof expandIcon === JavascriptType.STRING ? <Icon name={expandIcon}/> : expandIcon;
    }

    const customStyling = {};

    if (ValueIsSet(width))
        customStyling.width = typeof width === JavascriptType.NUMBER ? width + 'px' : width;

    const summaryStyling = {};

    if (!ValueIsSet(icon))
        summaryStyling.paddingRight = 0;

    if (ValueIsSet(large) && large) {
        summaryStyling.padding = 24;
        icon = <Icon name={'ExpandMore'} fontSize={'large'} />
    }

    if (ValueIsSet(summaryPadding)) {
        summaryStyling.padding = summaryPadding;
    }

    const detailsStyling = {};

    if (ValueIsSet(detailsPadding)) {
        detailsStyling.padding = detailsPadding;
    }

    return (
        <MuiAccordion disabled={disabled}
                                slotProps={{ transition: { unmountOnExit: true } }}
                                expanded={expanded}
                                //params - onChange(event, expanded)
                                onChange={onChangeWrapper}
                                style={customStyling}
                                TransitionProps={transitionProps}
                                square={square}
                                elevation={elevation}>
            <AccordionSummary style={summaryStyling} expandIcon={icon}>
                {summary}
            </AccordionSummary>
            <AccordionDetails style={detailsStyling}>
                {renderDetails ?
                    (ValueIsSet(details) ? details : children)
                    :
                    null
                }
            </AccordionDetails>
            {ValueIsSet(actions) ?
                <AccordionActions>
                    {actions}
                </AccordionActions>
                :
                null
            }
        </MuiAccordion>
    )
};

Accordion.propTypes = {
    actions: PropTypes.element,
    summaryActions: PropTypes.element,
    details: PropTypes.element,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    expandIcon: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.element
    ]),
    elevation: PropTypes.number,
    large: PropTypes.bool,
    onChange: PropTypes.func,
    padding: PropTypes.number,
    renderDetailsOnFirstExpansion: PropTypes.bool,  //prop decides if children render immediately or when first clicked
    summary: PropTypes.element.isRequired,
    square: PropTypes.bool,                         // Squares the border of the accordion.
    width: CustomPropTypes.stringOrNumber,
};

Accordion.defaultProps = {
    square: false,
};

Accordion.defaultProps = {
    elevation: 1,
    expandIcon: <ExpandMoreIcon/>,
    width: '100%',
};

export default withTheme(Accordion);