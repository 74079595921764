import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import Accordion from "../../components/Accordion/Accordion";
import {Display, JustifyContent} from "../../enums/CSSEnums";
import CircularProgress from "../../components/Loader/CircularProgress";
import ConversationHeader from "worksmith/composite-components/ConversationHeader/ConversationHeader";
import ClientVendorConversation from "../ClientVendorConversation/ClientVendorConversation";
import Text, {TextVariant} from "worksmith/components/Text/Text";

class ConversationListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expansionPanelStates: [],
            conversationsRead: [],
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.loading && this.props.loading) {
            this.setState({
                expansionPanelStates: [],
            })
        }

        if (prevProps.loading && !this.props.loading) {
            let newExpansionPanelStates = [];
            this.props.conversations.forEach((conversation) => {
                newExpansionPanelStates.push(
                    {open: false, hasBeenOpened: false}
                )
            });
            this.setState({expansionPanelStates: newExpansionPanelStates})
        }
    }

    onExpansionPanelClick = (index) => {
       const {expansionPanelStates} = this.state;

       let newExpansionPanelStates = expansionPanelStates.slice();

       newExpansionPanelStates[index].open = !newExpansionPanelStates[index].open;
       newExpansionPanelStates[index].hasBeenOpened = true;
       this.setState({expansionPanelStates: newExpansionPanelStates})
    }

    onMessageGroupRead = (id, number) => {
        const {conversationsRead} = this.state;
        const {onMessageGroupRead} = this.props;

        if (conversationsRead.indexOf(id) < 0)  {
            let newConversationsRead = conversationsRead.slice();
            newConversationsRead.push(id);
            this.setState({conversationsRead: newConversationsRead}, () => onMessageGroupRead(number));
        }
    }

    render() {
        const {onExpansionPanelClick, onMessageGroupRead} = this;
        const {expansionPanelStates} = this.state;
        const {
            conversations,
            loading,
            displayAsVendor
        } = this.props;

        let conversationExpansionPanels = [];

        if (loading) {
            conversationExpansionPanels.push(
                <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                    <CircularProgress/>
                </Box>
            )
        } else {
            conversationExpansionPanels =
                conversations.map((conversation, index) => (
                    <Fragment>
                        <Accordion
                            key={conversation.id}
                            summary={
                                displayAsVendor ? <Text semiBold variant={TextVariant.H6}>{conversation.opportunity.request.clientLocation.client.nickname}</Text> :
                                <ConversationHeader
                                    conversationClosed={!conversation.isOpen}
                                    unreadMessageCount={
                                        expansionPanelStates.length > 0 &&
                                        expansionPanelStates[index].hasBeenOpened ?
                                        0
                                        :
                                        conversation.unreadMessageCount
                                    }
                                    vendorLocation={conversation.opportunity.vendorLocation}
                                />
                            }
                            details={
                                expansionPanelStates.length > 0
                                && expansionPanelStates[index].hasBeenOpened ?
                                    <ClientVendorConversation
                                        closed={!conversation.isOpen}
                                        conversationId={conversation.id}
                                        onMessageRead={() => onMessageGroupRead(conversation.id, conversation.unreadMessageCount)}
                                        vendorId={displayAsVendor ? null : conversation.opportunity.vendorLocation.vendor.id}
                                        clientLocationId={conversation.opportunity.request.clientLocation.id}/>
                                        :
                                    <Fragment/>
                            }
                            onChange={() => {
                                onExpansionPanelClick(index);
                            }}
                            elevation={2}
                            expanded={expansionPanelStates.length > 0 && expansionPanelStates[index].open}
                            expandIcon={false}/>
                    </Fragment>
                ))
        }


        return (
            conversationExpansionPanels
        )
    }
}

ConversationListView.propTypes = {
    conversations: []
}

ConversationListView.propTypes = {
    loading: PropTypes.bool,
    conversations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        isOpen: PropTypes.bool,
        opportunity: PropTypes.shape({
            hasActiveBid: PropTypes.bool,
            hasActiveWalkthrough: PropTypes.bool,
            vendorLocation: PropTypes.shape({
                vendor: PropTypes.shape({
                    id: PropTypes.number,
                    officialName: PropTypes.string,
                }),
                rating: PropTypes.number,
                ratingsCount: PropTypes.number
            })
        }),
        unreadMessageCount: PropTypes.number,
        messageCount: PropTypes.number
    })),
    onMessageGroupRead: PropTypes.number,
};

export default ConversationListView;