import Papa from 'papaparse';

class CSVHelper {
    /**
     * The CSV Helper is intended to take a json object and turn it into a csv string that can be downloaded as a csv
     * JSON FORMAT : [{
     *     nameOne: headerOne,
     *     nameTwo: headerTwo,
     *     nameThree: headerThree
     * },{
     *     nameOne: valueOne,
     *     nameTwo: valueTwo,
     *     nameThree: valueThree
     * }, ...]
     * @param jsonData
     * @param filename
     */
    constructor(jsonData, filename){
        this.csvString = Papa.unparse(jsonData, {header: false, delimiter: ',', skipEmptyLines: true});
        this.filename = filename;
    }

    /**
     * Downloads csv based off the jsonData and filename
     */
    download = () => {
        const {csvString, filename} = this;

        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv; charset=utf-8,' + encodeURI(csvString);
        hiddenElement.target = '_blank';
        hiddenElement.download = filename + '.csv';
        hiddenElement.click();
    };
}

export default CSVHelper;