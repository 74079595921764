import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import {ClientDashboardType, Color} from "../Enums";
import {ExternalLinks} from "worksmith/enums/ExternalLinks";
import styled from 'styled-components';
import {Branding} from "../Config";
import MenuOption from "./MenuOption";
import Icon from "@mui/material/Icon";
import ClientRoutes from "../ClientRoutes";
import MenuOptionExternal from "./MenuOptionExternal";

const userService = new UserService();
const authTokenManager = new AuthTokenManager();

const SidebarGrid = styled.div`
    background-color: ${Color.NAVY};
    height:100%;
    grid-area: sidebar;
    position: fixed;
    z-index: 50;
    min-width: 229px;

    @media print {
        display: none;
    }

    @media only screen and (max-width: 900px) {
        display: ${props => props.sidebarIsExpanded ? 'block' : 'none'};
    }
`;

const Logo = styled.div`
    padding: 1em;
    margin-bottom: 2.5em;
`;

const WorksmithIcon = styled.img`
    height: 20px;
    padding-right: 0.5em;   
`;

const SidebarMenuOption = styled(MenuOption)`
    margin-left: 1.5em;
`;

const SidebarMenuOptionExternal = styled(MenuOptionExternal)`
    margin-left: 1.5em;
`;

const SidebarCloser = styled.div`
    display: none;

    @media only screen and (max-width: 900px) {
        display: inline-block;
        color: ${Color.WHITE};
        margin-left: 1em;
        margin-right: -1em;
    }
`;


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
        };

        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        let _this = this;
        userService.findOne(authTokenManager.getUserId()).then(function (data) {
            _this.setState({user: data});
        })
    }

    handleLogout() {
        let {onLogout} = this.props;
        onLogout();
    }

    render() {
        let {type, clientConfig, isCorporateClient, sidebarIsExpanded, expandSidebar, activeContract, client}= this.props;
        let {user} = this.state;
        let {isSelfService} = client;

        let dashboardType = clientConfig.dashboardType;

        let singleLocationId = 0;
        let isOneLocation = false;

        // Making sure the user client role is initialized to get the locations
        if(user.clientLocationRoles) {
            // Gets the first location id available for comparison
            singleLocationId = user.clientLocationRoles[0].clientLocation.id;

            if (user.clientLocationRoles.length === 1) {
                isOneLocation = true;
            } else {

                // If the first location id matches the other ids then there is only one store location
                isOneLocation = user.clientLocationRoles.every((role) => {
                    return role.clientLocation.id === singleLocationId;
                });
            }
        }

        const ClientAllLinks = () => (
            <div>
                {dashboardType !== ClientDashboardType.BURBERRY && dashboardType !== ClientDashboardType.HUGO_BOSS && dashboardType !== ClientDashboardType.INTERMIX ?
                    <Fragment>
                        <SidebarMenuOption key={'home'} to={"/"}>Home</SidebarMenuOption>
                        <SidebarMenuOption id={'sideBarCalendar'} key={'calendar'} to={"/calendar"}>Calendar</SidebarMenuOption>
                        <SidebarMenuOption key={'scheduleChanges'} to={"/" + ClientRoutes.SCHEDULE_CHANGES}>Schedule Changes</SidebarMenuOption>
                        <SidebarMenuOption key={'recurringServices'} to={"/" + ClientRoutes.RECURRING_SERVICES}>Recurring Services</SidebarMenuOption>
                        {clientConfig.dashboardType === ClientDashboardType.KENDRA_SCOTT ?
                            <SidebarMenuOption key={'request-history'} to={'/request-history'}>Request History</SidebarMenuOption> :
                            <SidebarMenuOption id={'sideBarRequests'} key={'requests'} to={'/requests'}>Requests</SidebarMenuOption>
                        }
                        {clientConfig.dashboardType === ClientDashboardType.DEFAULT ?
                            <SidebarMenuOption key={'ticket-dashboard'} to={"/ticket-dashboard"}>Ticket Dashboard</SidebarMenuOption> :
                            <Fragment>
                                <SidebarMenuOption key={'ticketing-dashboard'} to={"/ticketing/ticket-dashboard"}>Ticket Dashboard</SidebarMenuOption>
                                {!isSelfService ? <SidebarMenuOption key={'new-ticket'} to={"/ticketing/new-ticket"}>New Ticket</SidebarMenuOption> : null}
                            </Fragment>
                        }

                    </Fragment>
                    :
                    clientConfig.dashboardType === ClientDashboardType.KENDRA_SCOTT ?
                        <SidebarMenuOption key={'request-history'} to={'/request-history'}>Request History</SidebarMenuOption> :
                        <SidebarMenuOption id={'sideBarRequests'} key={'requests'} to={'/requests'}>Requests</SidebarMenuOption>
                }

                {isCorporateClient  ?
                    isOneLocation ? <SidebarMenuOption key={'locations'} to={"/locations/" + singleLocationId}>Location</SidebarMenuOption>
                        : <SidebarMenuOption id={'sideBarLocations'} key={'locations'} to={"/locations/"}>Locations</SidebarMenuOption>
                    :
                    null
                }

                {user.clientRoles ?
                    [
                        <SidebarMenuOption id={'sideBarUsers'} key={'users'} to={'/users'}>Users</SidebarMenuOption>,
                        <SidebarMenuOption id={'sideBarReporting'} key={'reporting'} to={"/reporting"}>Reporting</SidebarMenuOption>,
                        ...!isSelfService ? [<SidebarMenuOption id={'sideBarInvoices'} key={'invoices'} to={'/invoices'}>Invoices</SidebarMenuOption>] : [],
                    ] : null}

                {activeContract ?
                    <SidebarMenuOption key={'contract'} to={"/" + ClientRoutes.CONTRACT}>Rate Cards</SidebarMenuOption> : null
                }

                <SidebarMenuOptionExternal key={'support'} to={ExternalLinks.ZENDESK_SUPPORT}>Help Center</SidebarMenuOptionExternal>
                <SidebarMenuOptionExternal key={'support-ticket'} to={ExternalLinks.ZENDESK_SUPPORT_TICKET}>Contact Support</SidebarMenuOptionExternal>
            </div>
        );

        const ClientDryCleaningLinks = () => (
            <div>
                {(user.isClientAdmin && (isSelfService)) &&
                    <>
                        <SidebarMenuOption key={'standard-items'} to={"/standard-items"}>Standard Items</SidebarMenuOption>
                        <SidebarMenuOption key={'vendors'} to={"/vendors"}>Vendors</SidebarMenuOption>
                    </>
                }
                {dashboardType === ClientDashboardType.HUGO_BOSS ||
                dashboardType === ClientDashboardType.INTERMIX ||
                dashboardType === ClientDashboardType.BURBERRY ?
                    null
                    :
                    (dashboardType === ClientDashboardType.DEFAULT ?
                        <SidebarMenuOption key={'ticket-dashboard'}
                                           to={"/ticket-dashboard"}>Tickets</SidebarMenuOption> :
                        <SidebarMenuOption key={'ticketing-dashboard'}
                                           to={"/ticketing/ticket-dashboard"}>Tickets</SidebarMenuOption>)
                }

                {isCorporateClient  ?
                    isOneLocation ? <SidebarMenuOption key={'locations'} to={"/locations/" + singleLocationId}>Location</SidebarMenuOption>
                        : <SidebarMenuOption id={'sideBarLocations'} key={'locations'} to={"/locations/"}>{isSelfService ? 'Locations & Prices' : 'Locations'}</SidebarMenuOption>
                    :
                    null
                }

                {user.clientRoles ?
                    [
                        <SidebarMenuOption id={'sideBarUsers'} key={'users'} to={"/users"}>Users</SidebarMenuOption>,
                        <SidebarMenuOption id={'sideBarReporting'} key={'reporting'} to={"/reporting"}>Reporting</SidebarMenuOption>,
                        ...!isSelfService ? [<SidebarMenuOption id={'sideBarInvoices'} key={'invoices'} to={"/invoices"}>Invoices</SidebarMenuOption>] : [],
                    ]
                    : null}

                {activeContract ?
                    <SidebarMenuOption key={'contract'} to={"/" + ClientRoutes.CONTRACT}>Rate Cards</SidebarMenuOption> : null
                }

                <SidebarMenuOptionExternal key={'support'} to={ExternalLinks.ZENDESK_SUPPORT}>Help Center</SidebarMenuOptionExternal>
                <SidebarMenuOptionExternal key={'support-ticket'} to={ExternalLinks.ZENDESK_SUPPORT_TICKET}>Contact Support</SidebarMenuOptionExternal>

            </div>
        );

        const ClientNonDryCleaningLinks = () => (
            <React.Fragment>
                <SidebarMenuOption key={'home'} to={"/"}>Home</SidebarMenuOption>
                <SidebarMenuOption id={'sideBarCalendar'} key={'calendar'} to={"/calendar"}>Calendar</SidebarMenuOption>
                <SidebarMenuOption key={'scheduleChanges'} to={"/" + ClientRoutes.SCHEDULE_CHANGES}>Schedule Changes</SidebarMenuOption>
                <SidebarMenuOption key={'recurringServices'} to={"/" + ClientRoutes.RECURRING_SERVICES}>Recurring Services</SidebarMenuOption>
                {clientConfig.dashboardType === ClientDashboardType.KENDRA_SCOTT ?
                    <SidebarMenuOption key={'request-history'} to={"/"}>Request History</SidebarMenuOption> :
                    <SidebarMenuOption id={'sideBarRequests'} key={'requests'} to={"/requests"}>Requests</SidebarMenuOption>
                }

                {isCorporateClient ?
                    isOneLocation ?
                        <SidebarMenuOption key={'locations'} to={"/locations/" + singleLocationId}>Location</SidebarMenuOption>
                        : <SidebarMenuOption id={'sideBarLocations'} key={'locations'} to={"/locations"}>Locations</SidebarMenuOption>
                    :
                    null
                }

                {user.clientRoles ?
                    [
                        <SidebarMenuOption id={'sideBarUsers'} key={'users'} to={"/users"}>Users</SidebarMenuOption>,
                        <SidebarMenuOption id={'sideBarReporting'} key={'reporting'} to={"/reporting"}>Reporting</SidebarMenuOption>,
                        ...!isSelfService ? [<SidebarMenuOption id={'sideBarInvoices'} key={'invoices'} to={"/invoices"}>Invoices</SidebarMenuOption>] : [],
                    ]
                    : null}
                {activeContract ?
                    <SidebarMenuOption key={'contract'} to={"/" + ClientRoutes.CONTRACT}>Rate Cards</SidebarMenuOption> : null
                }

                <SidebarMenuOptionExternal key={'support'} to={ExternalLinks.ZENDESK_SUPPORT}>Help Center</SidebarMenuOptionExternal>
                <SidebarMenuOptionExternal key={'support-ticket'} to={ExternalLinks.ZENDESK_SUPPORT_TICKET}>Contact Support</SidebarMenuOptionExternal>

            </React.Fragment>
        );

        const BurberryLinks = () => (
            <div>
                <SidebarMenuOption key={'ticketing-dashboard'} to={"/ticketing/ticket-dashboard"}>Home</SidebarMenuOption>
                {!isSelfService ? <SidebarMenuOption key={'new-ticket'} to={"/ticketing/new-ticket"}>New Ticket</SidebarMenuOption> : null}
                <SidebarMenuOption key={'bulk-shipments'} to={"/ticketing/bulk-shipments"}>Bulk Shipments</SidebarMenuOption>
                {type !== 'clientAll' ?
                    null
                    :
                    <Fragment>
                        <SidebarMenuOption id={'sideBarCalendar'} key={'calendar'} to={"/calendar"}>Calendar</SidebarMenuOption>
                        <SidebarMenuOption key={'scheduleChanges'} to={"/" + ClientRoutes.SCHEDULE_CHANGES}>Schedule Changes</SidebarMenuOption>
                    </Fragment>
                }
            </div>
        );

        const HugoBossLinks = () => (
            <div>
                <SidebarMenuOption key={'ticketing-dashboard'} to={"/ticketing/ticket-dashboard"}>Home</SidebarMenuOption>
                {!isSelfService ? <SidebarMenuOption key={'new-ticket'} to={"/ticketing/new-ticket"}>New Ticket</SidebarMenuOption> : null}

                {type !== 'clientAll' ?
                    null
                    :
                    <Fragment>
                        <SidebarMenuOption id={'sideBarCalendar'} key={'calendar'} to={"/calendar"}>Calendar</SidebarMenuOption>
                        <SidebarMenuOption key={'scheduleChanges'} to={"/" + ClientRoutes.SCHEDULE_CHANGES}>Schedule Changes</SidebarMenuOption>
                        <SidebarMenuOption key={'recurringServices'} to={"/" + ClientRoutes.RECURRING_SERVICES}>Recurring Services</SidebarMenuOption>
                    </Fragment>
                }
            </div>
        );

        const sidebar = () => (
            <SidebarGrid sidebarIsExpanded={sidebarIsExpanded}>
                <Logo>
                    <div style={{float: 'left', marginRight: '1.5em', display: "inline-block"}}>
                        <WorksmithIcon style={{height: Branding.logoWithTextHeight}} src={Branding.logoWithText}  alt="Worksmith"/>
                        <SidebarCloser>
                            <Icon onClick={() => expandSidebar(false)}>close</Icon>
                        </SidebarCloser>
                    </div>
                </Logo>

                {dashboardType === ClientDashboardType.HUGO_BOSS ? <HugoBossLinks/> : null}
                {dashboardType === ClientDashboardType.BURBERRY ? <BurberryLinks/> : null}
                {dashboardType === ClientDashboardType.INTERMIX ? <HugoBossLinks/> : null}

                {type === 'clientAll' ? <ClientAllLinks/> : null}
                {type === 'nonDryCleaning' ? <ClientNonDryCleaningLinks/> : null}
                {type === 'dryCleaning' ? <ClientDryCleaningLinks/> : null}

                <SidebarMenuOption key={'account'} to={'/account'}>My Account</SidebarMenuOption>
                <SidebarMenuOption key={'logout'} to={'/logout'}>Logout</SidebarMenuOption>
            </SidebarGrid>
        );

        return sidebar();
    }
}

Sidebar.propTypes = {
    client: PropTypes.shape({
        isSelfService: PropTypes.bool.isRequired,
    }),
    clientConfig:   PropTypes.object,
    isCorporateClient: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
    type: PropTypes.string,
    activeContract: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })
};

export default withRouter(Sidebar);
