import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RfpType from "../../../../enums/api/proposal/RfpType";
import Text, {LinkUnderline, TextColor, TextVariant} from "../../../../components/Text/Text.web";
import {Display} from "worksmith/enums/CSSEnums";
import moment from "moment";
import RequestStateLayout from "../RequestStateLayout";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import RaiseConcernDialog from "../../../RaiseConcern/RaiseConcernDialog";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ObligationService from "worksmith/services/api/ObligationService";
import {IconType} from "worksmith/enums/MaterialEnums";
import Button, {ButtonSize} from "worksmith/components/Button/Button";
import Icon from "worksmith/components/Icon/Icon";
import Grid from "@mui/material/Grid";
import ClientSignOffModal from "worksmith/composite-components/ClientSignOff/ClientSignOffModal";
import ResolveConcernDialog from "worksmith/composite-components/ResolveConcern/ResolveConcernDialog";

const obligationService = new ObligationService();

class CompletedView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            raiseConcernModalOpen: false,
            resolveConcernModalOpen: false,
            signOffModalOpen: false
        };
    }

    resolveConcern = async () => {
        const {onConcernRaised, request} = this.props;
        await obligationService.resolveConcern(request.associatedObligation.openConcern.id);
        onConcernRaised();
    }

    unconfirmJob = async () => {
        const {onConcernRaised, request} = this.props;
        await obligationService.unconfirmJob(request.associatedObligation.id);
        onConcernRaised();
    }

   openRaiseConcernModal = () => {
       this.setState({raiseConcernModalOpen: true})
   }

   hideRaiseConcernModal = () => {
       this.setState({raiseConcernModalOpen: false})
   }

   openResolveConcernModal = () => {
       this.setState({resolveConcernModalOpen: true})
   }

   hideResolveConcernModal = () => {
       this.setState({resolveConcernModalOpen: false})
   }

   hideSignOffModal = () => {
       this.setState({signOffModalOpen: false})
   }

   openSignOffModal = () => {
       this.setState({signOffModalOpen: true})
   }

    render() {
        const {openRaiseConcernModal, openResolveConcernModal, openSignOffModal, hideRaiseConcernModal, hideResolveConcernModal, hideSignOffModal, resolveConcern, unconfirmJob} = this;
        const {raiseConcernModalOpen, resolveConcernModalOpen, signOffModalOpen} = this.state;
        const {loading, onConcernRaised, request, userId} = this.props;

        let description = '';
        let raiseConcernPrompt = null;
        // let concernRaisedInfo = '';  // removing ability to raise concern, commenting out incase we bring it back
        let confirmButton = null;
        let unconfirmInfo = '';


        if (!loading) {
            if (request.type === RfpType.ON_DEMAND) {
                description =
                    <Text display={Display.INLINE} variant={TextVariant.BODY_1}>
                        {`View the completion details section below. `}
                    </Text>
            }

            if (request.type !== RfpType.PRICING_ONLY
                && ValueIsSet(request.associatedObligation)
                && request.completionDate
                && moment(request.completionDate).isSameOrAfter(moment().subtract(60, 'days'))
                && request.associatedObligation.isContestable) {
                    raiseConcernPrompt = (
                    <Text display={Display.INLINE} variant={TextVariant.BODY_1}>
                        {`Problem with this job? `}
                        <Text
                            link
                            onClick={openRaiseConcernModal}
                            display={Display.INLINE}
                            variant={TextVariant.INHERIT}
                            underline={LinkUnderline.ALWAYS}
                            semiBold
                            color={TextColor.SECONDARY}>
                            Raise a concern
                        </Text>
                    </Text>
                )
            }

            // commenting out instead of deleting in case we want to bring it back
            // if (ValueIsSet(request.associatedObligation) && request.associatedObligation.hasOpenConcern) {
            //     concernRaisedInfo = (
            //         <Text link
            //               onClick={openResolveConcernModal}
            //               display={Display.INLINE}
            //               variant={TextVariant.INHERIT}
            //               underline={LinkUnderline.ALWAYS}
            //               color={TextColor.SECONDARY}>
            //             Resolve Concern.
            //         </Text>
            //     )
            // }

            if (ValueIsSet(request.associatedObligation) && request.associatedObligation.confirmed){
                unconfirmInfo = (
                    <Text link
                          onClick={unconfirmJob}
                          display={Display.INLINE}
                          variant={TextVariant.INHERIT}
                          underline={LinkUnderline.ALWAYS}
                          color={TextColor.SECONDARY}>
                        Unconfirm Job.
                    </Text>
                )
            }

            if(request.client.storeSignOffEnabled && ValueIsSet(request.associatedObligation) && !request.associatedObligation.confirmed && !request.associatedObligation.hasOpenConcern){
                confirmButton = <Button primary
                                        size={ButtonSize.MEDIUM}
                                        startIcon={<Icon name={IconType.ASSIGNMENT_CHECKED}/>}
                                        onClick={openSignOffModal}>
                                    Sign Off On Job
                                </Button>
            }


        }

        let onRaiseConcern = () => {
            hideRaiseConcernModal();
            onConcernRaised();
        };

        let onResolveConcern = () => {
            hideResolveConcernModal();
            onConcernRaised();
        };

        let onSignOffSubmitted = () => {
            hideSignOffModal();
            onConcernRaised();
        }

        return (
            <Fragment>
                {
                    request && ValueIsSet(request.associatedObligation) ?
                        <RaiseConcernDialog
                            obligationId={request.associatedObligation.id}
                            onCancel={hideRaiseConcernModal}
                            onRaiseConcern={onRaiseConcern}
                            open={raiseConcernModalOpen}/>
                        :
                        null
                }
                {
                    request && ValueIsSet(request.associatedObligation) && ValueIsSet(request.associatedObligation.openConcern) ?
                        <ResolveConcernDialog
                            concernId={request.associatedObligation.openConcern.id}
                            onCancel={hideResolveConcernModal}
                            onResolveConcern={onResolveConcern}
                            open={resolveConcernModalOpen}/>
                        :
                        null
                }
                {
                    request && ValueIsSet(request.associatedObligation) && ValueIsSet(request.client) && request.client.storeSignOffEnabled ?
                        <ClientSignOffModal
                            obligationId={request.associatedObligation.id}
                            onCancel={hideSignOffModal}
                            onCompletion={onSignOffSubmitted}
                            open={signOffModalOpen}/>
                        :
                        null
                }
                <RequestStateLayout
                    description={
                        <Fragment>
                            {description}
                            {raiseConcernPrompt}
                            {/*{concernRaisedInfo} commenting out incase we bring it back*/}
                            {unconfirmInfo}
                            <Grid>
                                {confirmButton}
                            </Grid>
                        </Fragment>
                    }
                    loading={loading}
                    status={TitleCase(NewRequestStatus.COMPLETED)}
                    statusColor={TextColor.PRIMARY}/>
            </Fragment>
        )
    }
}

CompletedView.propTypes = {
    loading: PropTypes.bool,
    onConcernRaised: PropTypes.func,
    raiseConcernModalValid: PropTypes.func,
    request: PropTypes.shape({
        associatedObligation: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        completionDate: PropTypes.string,
        concernRaisedActivities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            createdTimestamp: PropTypes.string,
            notes: PropTypes.string,
            user: PropTypes.shape({
                id: PropTypes.number,
                displayName: PropTypes.string
            })
        })),
        type: CustomPropTypes.enum(RfpType),
    }),
    userId: PropTypes.number.isRequired
};

export default CompletedView;