import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InvoicesPricingOverview from "./InvoicesPricingOverview/InvoicesPricingOverview";
import Card from "worksmith/components/Card/Card";
import InvoiceSearch from "./InvoicesSearch/InvoiceSearch";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import BreakpointEnum from "worksmith/enums/BreakpointEnum";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";

const PageSection = styled.div`
    margin-top: 1em;
    margin-bottom: 1em;
`;

const InvoicesOverviewPage = (props) => {
    const {
        billingProfiles,
        clientId,
        isPaused,
        linkToInvoice,
    } = props;

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(BreakpointEnum.XS));

    return (
        <Fragment>
            <PageSection>
                <Card padding={3} raised width={'inherit'}>
                    <InvoicesPricingOverview billingProfiles={billingProfiles} isPaused={isPaused}/>
                </Card>
            </PageSection>

            <PageSection>
                <div style={{minWidth: isSmall ? 'auto' : '1000px'}}>
                    <Card padding={0} raised width={'inherit'}>
                        <InvoiceSearch clientId={clientId} linkToInvoice={linkToInvoice}/>
                    </Card>
                </div>
            </PageSection>
        </Fragment>
    )
};

InvoicesOverviewPage.propTypes = {
    billingProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
    clientId:        CustomPropTypes.stringOrNumber.isRequired,
    isPaused:        PropTypes.bool.isRequired,
    linkToInvoice:   PropTypes.func.isRequired,
};

export default InvoicesOverviewPage;