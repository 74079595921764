import React from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import {JustifyContent} from "worksmith/enums/CSSEnums";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";

const FieldTitleAndBody = (props) => {
    let {
        item,
        bodyColor,
        body,
        bodyTextVariant,
        loading,
        mobile,
        spacing,
        title,
        titleColor,
        titleTextVariant,
        xs
    } = props;


    return (
        <Grid container
              item={item}
              spacing={spacing}
              xs={xs}
              justify={JustifyContent.FLEX_START}>
            <Grid item xs={12}>
                <Text
                    variant={titleTextVariant}
                    color={titleColor}
                    semiBold
                    renderSkeleton={loading}
                    SkeletonProps={{width: mobile ? '10rem' : '20rem'}}>
                   {title}
                </Text>
            </Grid>
            <Grid item xs={12}>
                {typeof body === JavascriptType.STRING ?
                    <Text
                        variant={bodyTextVariant}
                        color={bodyColor}
                        renderSkeleton={loading}
                        SkeletonProps={{width:  mobile ? '20rem' : '40rem'}}>
                        {body}
                    </Text>
                    :
                    body
                }
            </Grid>
        </Grid>
    );
};

FieldTitleAndBody.defaultProps = {
    bodyColor: TextColor.TEXT_PRIMARY,
    bodyTextVariant: TextVariant.BODY_1,
    titleTextVariant: TextVariant.SUBTITLE_1,
    item: false,
    mobile: false,
    spacing: 1,
    titleColor: TextColor.TEXT_PRIMARY
};

FieldTitleAndBody.propTypes = {
    bodyColor: CustomPropTypes.enum(TextColor),
    body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    bodyTextVariant: CustomPropTypes.enum(TextVariant),
    item: PropTypes.bool,
    loading: PropTypes.bool,
    mobile: PropTypes.bool,
    spacing: PropTypes.number,
    title: PropTypes.string.isRequired,
    titleColor: CustomPropTypes.enum(TextColor),
    titleTextVariant: CustomPropTypes.enum(TextVariant),
    xs: PropTypes.number
};

export default FieldTitleAndBody;