import React from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from "@mui/material/Tooltip";

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {withTheme} from '../Theme/ThemeProvider';
import Box from "@mui/material/Box";

export const TooltipPlacement = Object.freeze({
    BOTTOM_END: 'bottom-end',
    BOTTOM_START: 'bottom-start',
    BOTTOM: 'bottom',
    LEFT_END: 'left-end',
    LEFT_START: 'left-start',
    LEFT: 'left',
    RIGHT_END: 'right-end',
    RIGHT_START: 'right-start',
    RIGHT: 'right',
    TOP_END: 'top-end',
    TOP_START: 'top-start',
    TOP: 'top'
});

const Tooltip = (props) => {
    let {
        disableFocusListener,
        enterDelay,
        hideTooltip,
        interactive,
        leaveDelay,
        onClose,
        onOpen,
        placement,
        title
    } = props;

    return (
        <MaterialTooltip enterDelay={enterDelay}
                         disableHoverListener={hideTooltip}
                         disableFocusListener={disableFocusListener}
                         interactive={interactive}
                         className={props.className}
                         leaveDelay={leaveDelay}
                         onClose={onClose}
                         onOpen={onOpen}
                         placement={placement}
                         title={title}>
            <Box>
                {props.children}
            </Box>
        </MaterialTooltip>
    )
};

Tooltip.propTypes = {
    disableFocusListener: PropTypes.bool,       //Prevents the tooltip form displaying when the element is in focus.
    enterDelay: PropTypes.number,               //The number of milliseconds to wait before showing the tooltip
    interactive: PropTypes.bool,                //Makes a tooltip interactive, i.e. will not close when the user hovers over the tooltip before the leaveDelay is expired
    leaveDelay: PropTypes.number,               //The number of milliseconds to wait before hiding the tooltip
    hideTooltip: PropTypes.bool,                //Make the tooltip toggleable, first used for small DispatcherMenuButton, hidden when button is large enough to show name
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    placement: CustomPropTypes.enum(TooltipPlacement),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ])                                          //The message to be displayed in the tooltip
};

Tooltip.defaultProps = {
    hideTooltip: false,
    enterDelay: 0,
    leaveDelay: 0,
    placement: TooltipPlacement.TOP
};

export default withTheme(Tooltip);