import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useMediaQuery, useTheme} from "@mui/material";

import Grid from "worksmith/components/Grid/Grid";
import Text, {TextDisplay, TextVariant} from "worksmith/components/Text/Text";
import Currency from "worksmith/components/Currency/Currency";
import {AlignItems} from "worksmith/enums/CSSEnums";
import BreakpointEnum from "worksmith/enums/BreakpointEnum";
import {withTheme} from "../Theme/ThemeProvider";
import {ToCurrency} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";


const LineItemBreakdown = (props) => {
    const {description, lineItemType, quantity, unitRetailPrice, unitWholesalePrice, showWholesale, textColor, portalType} = props;

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(BreakpointEnum.XS));
    const totalRetailPrice = quantity * unitRetailPrice;

    return (

        <Fragment>
            {showWholesale ?
                <Grid alignItems={AlignItems.FLEX_END}
                      container
                      item>
                    <Grid item xs={12} sm={4}>
                        <Text variant={TextVariant.BODY_2}
                              color={textColor}>{lineItemType.description}</Text>
                        <Text variant={TextVariant.SUBTITLE_2}
                              color={textColor}>{description}</Text>
                    </Grid>
                    <Grid item xs={2}>
                        <Text variant={TextVariant.SUBTITLE_2}
                              color={textColor}>{quantity}</Text>
                    </Grid>
                    {portalType === PortalType.ADMIN &&
                        <>
                            <Grid item xs={3}>
                                <Text variant={TextVariant.SUBTITLE_2}
                                      color={textColor}>${ToCurrency(unitRetailPrice)}
                                      <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_2} color={textColor}> Retail</Text>
                                </Text>
                            </Grid>
                            <Grid item xs={3}>
                                <Text variant={TextVariant.SUBTITLE_2}
                                      color={textColor}>${ToCurrency(Number(unitRetailPrice) * Number(quantity))}
                                    <Text display={TextDisplay.INLINE}
                                          variant={TextVariant.BODY_2}
                                          color={textColor}> Retail</Text>
                                </Text>
                            </Grid>
                            <Grid item xs={6}/>
                        </>
                    }
                    <Grid item xs={3}>
                        <Text variant={TextVariant.SUBTITLE_2}
                              color={textColor}>${ToCurrency(unitWholesalePrice)}
                              <Text display={TextDisplay.INLINE}
                                    variant={TextVariant.BODY_2}
                                    color={textColor}> Wholesale</Text>
                        </Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text variant={TextVariant.SUBTITLE_2}
                              color={textColor}>${ToCurrency(Number(unitWholesalePrice) * Number(quantity))}
                            <Text display={TextDisplay.INLINE}
                                  variant={TextVariant.BODY_2}
                                  color={textColor}> Wholesale</Text>
                        </Text>
                    </Grid>
                </Grid>
                :  <Grid alignItems={AlignItems.FLEX_END}
                         container
                         item>
                    <Grid item xs={12} sm={4}>
                        <Text variant={TextVariant.SUBTITLE_2}
                              color={textColor}>{lineItemType.description}</Text>
                        <Text variant={TextVariant.BODY_2}
                              color={textColor}>{description}</Text>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        {isSmall ? <Text variant={TextVariant.BODY_2} color={textColor}>Qty</Text> : null}
                        <Text variant={TextVariant.BODY_2} color={textColor}>{quantity}</Text>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        {isSmall ? <Text variant={TextVariant.BODY_2} color={textColor}>Retail Price</Text> : null}
                        <Text variant={TextVariant.BODY_2} color={textColor}>
                            <Currency amount={unitRetailPrice}/>
                        </Text>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        {isSmall ? <Text variant={TextVariant.BODY_2} color={textColor}>Total Price</Text> : null}
                        <Text variant={TextVariant.BODY_2} color={textColor}>
                            <Currency amount={totalRetailPrice}/>
                        </Text>
                    </Grid>
                </Grid>
            }


        </Fragment>

    )
};

LineItemBreakdown.propTypes = {
    description:     PropTypes.string.isRequired,
    lineItemType:    PropTypes.shape({
                         name: PropTypes.string.isRequired
                     }).isRequired,
    quantity:        PropTypes.number.isRequired,
    unitRetailPrice: PropTypes.number.isRequired,
    unitWholeSalePrice: PropTypes.number.isRequired,
    textColor: PropTypes.string,
    portalType: CustomPropTypes.enum(PortalType)
};

export default withTheme(LineItemBreakdown);