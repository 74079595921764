import React from 'react';
import PropTypes from 'prop-types';
import {Color} from "worksmith/enums/Color";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import WorksmithLogo from "worksmith/components/Assets/WorksmithLogo";

const OpportunityLogo = (props) => {
    const {isForNonWorksmithClient, companyLogo, vendorName, widthInPixels} = props;

    const logo = isForNonWorksmithClient ?
        ValueIsSet(companyLogo) ?
            <img style={{width: widthInPixels + 'px', marginRight: '8px'}} src={companyLogo} alt={"Logo"}/>
            :
            null
        :
        <WorksmithLogo color={Color.PRIMARY} height={widthInPixels + 'px'} width={widthInPixels + 'px'} margin={'0 8px -8px 0'}/>;

    const companyDoingBusinessWithClient = isForNonWorksmithClient ? vendorName : "Worksmith";

    return (
        <>
            <span style={{width: widthInPixels + 'px'}}>{logo}</span> {companyDoingBusinessWithClient} Client
        </>
    )
};

OpportunityLogo.propTypes = {
    isForNonWorksmithClient: PropTypes.bool.isRequired,
    companyLogo: PropTypes.string,
    vendorName: PropTypes.string.isRequired,
    widthInPixels: PropTypes.number
};

OpportunityLogo.defaultProps = {

}

export default OpportunityLogo