import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import AddEditVendorForm from "./AddEditVendorForm";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import IconButton from "worksmith/components/Button/IconButton";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import TextField, {TextFieldType} from "worksmith/components/Inputs/TextField/TextField";
import VendorsPageView from "./VendorsPageView";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import {EventKey} from "worksmith/enums/EventKey";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";

const VENDORS_PER_PAGE = 20;
const graphQLService = new GraphQLServiceClass();

const VendorsPage = ({client}) => {

    const [showAddEditVendor, setShowAddEditVendor] = useState(false);
    const [editVendor, setEditVendor] = useState({id: null, isInHouseTailor: false});

    const [vendorData, setVendorData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [searchBoxText, setSearchBoxText] = useState(null);

    const [vendorSearchParams, setVendorSearchParams] = useState({selfServiceClientId: client.id, clientVendorsPageFilterString: ""});


    const getUniqueClientLocationsAndSort = (data) => {
        data.forEach((vendor) => {
            // add client locations to a map to remove duplicates
            let uniqueLocationsMap = new Map();
            if(vendor.preferredVendorRelationships.length > 0) {
                vendor.preferredVendorRelationships.forEach((pvr) => {
                    let location = pvr.clientLocation;
                    uniqueLocationsMap.set(location.id, location);
                });
                // convert to array and sort by client location's title
                uniqueLocationsMap = Array.from(uniqueLocationsMap.values());
                uniqueLocationsMap.sort((a,b) => {
                    const textA = a.title.toUpperCase();
                    const textB = b.title.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
            }
            vendor.uniqueLocations = uniqueLocationsMap;
        });
    };

    const fetchVendors = async (page) => {
        setLoadingData(true);
        try {
            const { content, currentPage, totalElements } = await graphQLService.findPage(
                page - 1,
                VENDORS_PER_PAGE,
                "nickname",
                GraphQLSortDirection.ASCENDING,
                vendorSearchParams,
                GraphQLObjectType.VENDOR,
                vendorFields
            );
            if(content) {
                getUniqueClientLocationsAndSort(content)
            }
            setVendorData(content);
            setTotalElements(totalElements);
            setCurrentPage(currentPage);
        } catch (err) {
            await DisplayErrorNotification("Error fetching clients data");
        }
        setLoadingData(false);
    };

    // these 3 functions ensure that search takes place if
    // "ENTER" is hit
    // you clear/delete the last character
    // you click out of the text box
    const onTextKeyUp = ({key}) => {
        if (key === EventKey.ENTER) {
            setVendorSearchParams({...vendorSearchParams, clientVendorsPageFilterString: searchBoxText})
        }
    };
    const onTextChange = (value) => {
        setSearchBoxText(value);
        if(value === ""){
            setVendorSearchParams({...vendorSearchParams, clientVendorsPageFilterString: ""})
        }
    };
    const onTextBlur = (value) => {
        if(value.target.value !== vendorSearchParams.clientVendorsPageFilterString){
            setVendorSearchParams({...vendorSearchParams, clientVendorsPageFilterString: value.target.value})
        }
    };


    const handleChangePage = (e, pageNumber) => {
        fetchVendors(pageNumber + 1);
    };

    useEffect(() => {
        fetchVendors(1);
    }, [vendorSearchParams]);

    return (
        <>
            <Grid container margin={'20px 0 0 0'}>
                <Grid item xs={2}>
                    <Text variant={TextVariant.H4}>Vendors</Text>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        secondary
                        onClick={() => {
                            setEditVendor({id: null, isInHouseTailor: false});
                            setShowAddEditVendor(true);
                        }}
                    >
                        <Icon name={'Add'}/>
                        Add Vendor
                    </Button>
                </Grid>
                <Grid item xs={12} margin={'20px 0 0 0'}>
                    <Grid item sm={12} md={4}>
                        <TextField
                            autoFocus={true}
                            fullWidth
                            includeClearButton
                            onChange={onTextChange}
                            onBlur={onTextBlur}
                            onKeyUp={onTextKeyUp}
                            placeholder={'Search name, email, location...'}
                            startAdornment={
                                <IconButton disablePadding iconName={'Search'} primary size="large" />
                            }
                            type={TextFieldType.SEARCH}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <VendorsPageView
                currentPage={currentPage}
                handleChangePage={handleChangePage}
                loading={loadingData}
                setEditVendor={setEditVendor}
                setShowAddEditVendor={setShowAddEditVendor}
                totalDataLength={totalElements}
                vendors={vendorData}
                vendorsPerPage={VENDORS_PER_PAGE}
            />
            {showAddEditVendor &&
                <AlertDialog
                    open={showAddEditVendor}
                >
                    <AddEditVendorForm
                        client={client}
                        editVendor={editVendor}
                        fetchVendors={fetchVendors}
                        setShowAddEditVendor={setShowAddEditVendor}
                    />
                </AlertDialog>
            }
        </>
    )
};

export default VendorsPage;

const vendorFields = `
    id
    nickname
    users {
        firstName
        lastName
        email
        phone
        countryCode
    }
    preferredVendorRelationships {
        id
        clientLocation {
            id
            title
        }
        serviceLine {
            name
        }
    }
    vendorLocations {
        id
        isBurberryVendor
    }
`;

VendorsPage.propTypes = {
    client: PropTypes.object
};