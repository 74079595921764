// This file was generated from com.worksmith.proposal.enums.BidStatus
// noinspection JSUnusedGlobalSymbols
const BidStatus = Object.freeze({
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    CANCELED: 'canceled',
    PENDING_ADMIN: 'pending admin approval',
    PENDING_REGIONAL_MANAGER: 'pending regional manager approval',
    NEEDS_CLIENT_INPUT: 'needs client input',
    getActiveStatuses: function() {
        return [this.ACCEPTED, this.NEEDS_CLIENT_INPUT, this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER];
    },
    getClosedStatuses: function() {
        return [this.ACCEPTED, this.DECLINED, this.CANCELED];
    },
    getOpenStatuses: function() {
        return [this.NEEDS_CLIENT_INPUT, this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER];
    }
});

export default BidStatus;
