import React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import {DayPickerRangeController} from "react-dates";
import {END_DATE, HORIZONTAL_ORIENTATION, START_DATE} from "react-dates/constants";
import moment from "moment";

import BreakpointEnum from "../../enums/BreakpointEnum";
import {withAphroditeTheme} from "../Theme/ReactDatesThemeProvider";
import {withTheme} from "../Theme/ThemeProvider";

const DateRangeCalendarBreakpointWrapper = (props) => {
    const theme = useTheme();
    const useSingleMonth = useMediaQuery(theme.breakpoints.down(BreakpointEnum.MD));

    return (
        <DayPickerRangeController
            orientation={HORIZONTAL_ORIENTATION}
            numberOfMonths={useSingleMonth ? 1 : 2}
            {...props}/>
    )
};

DateRangeCalendarBreakpointWrapper.defaultProps = {
    hideKeyboardShortcutsPanel: true,
    minimumNights: 0,
    noBorder: true,
};

DateRangeCalendarBreakpointWrapper.propTypes = {
    autoFocusEndDate: PropTypes.bool,
    endDate: PropTypes.instanceOf(moment),
    focusedInput: PropTypes.oneOf([START_DATE, END_DATE]),
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,
    isOutsideRange: PropTypes.func,
    minimumNights: PropTypes.number,
    noBorder: PropTypes.bool,
    onDatesChange: PropTypes.func.isRequired,
    renderMonthElement: PropTypes.func,
    renderNavNextButton: PropTypes.func,
    renderNavPrevButton: PropTypes.func,
    startDate: PropTypes.instanceOf(moment),
};

export default withTheme(withAphroditeTheme(DateRangeCalendarBreakpointWrapper));