import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import CollapseBarView from "worksmith/components/Collapse/Informational/CollapseBarView.web";

class CollapseBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.initialExpanded
        };
    }

    toggleExpand = () => {
        const {expanded} = this.state;
        this.setState({expanded: !expanded});
    };

    render() {
        const {toggleExpand} = this;
        const {expanded} = this.state;
        const {children, label} = this.props;

        return (
            <CollapseBarView expanded={expanded}
                             label={label}
                             toggleExpand={toggleExpand}>
                {children}
            </CollapseBarView>
        )
    }
}

CollapseBar.propTypes = {
    initialExpanded: PropTypes.bool,
    label: PropTypes.string
};

CollapseBar.defaultProps = {
    initialExpanded: true
};

export default CollapseBar;