import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AsyncGraphQLServiceClass from "../../../../services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import RfpType from "../../../../enums/api/proposal/RfpType";
import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import CompletedView from "./CompletedView";

const graphQLServiceClass = new AsyncGraphQLServiceClass();

class Completed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null
        };
    }

    componentDidMount = async () => {
        const {requestId} = this.props;

        if (requestId) {
            let data = await this.getRequestFromId(requestId);
            this.setState({loading: false, request: data});
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            newState.request = DeepCopy(props.request);
        }

        return newState;
    };

    getRequestFromId = async (requestId) => {

       return await graphQLServiceClass.findOneById(
           requestId,
           GraphQLObjectType.REQUEST,
           CompletedGraphQLString)
    };

    render() {
        const {onRaiseConcernChange, onConcernRaised} = this.props;
        const {loading, raiseConcernValid, request} = this.state;
        const {userId} = this.props;

        return (
            <CompletedView
                onRaiseConcernChange={onRaiseConcernChange}
                loading={loading || this.props.loading}
                onConcernRaised={onConcernRaised}
                raiseConcernModalValid={raiseConcernValid}
                request={request}
                userId={userId} />
        )
    }
}

Completed.propTypes = {
    loading: PropTypes.bool,
    onConcernRaised: PropTypes.func,
    request: PropTypes.shape({
        associatedObligation: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        completionDate: PropTypes.string,
        concernRaisedActivities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            createdTimestamp: PropTypes.string,
            notes: PropTypes.string,
            user: PropTypes.shape({
                id: PropTypes.number,
                displayName: PropTypes.string
            })
        })),
        type: CustomPropTypes.enum(RfpType),
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func,
    userId: PropTypes.number.isRequired
};

const CompletedGraphQLString = `
    associatedObligation {
        id
        isContestable
        hasOpenConcern
        confirmed
        vendorLocation {
            nickname
        }
        openConcern{
            id
            createdTimestamp
            creatorUser{
                displayName
            }
            description
        }
    }
    client{
        storeSignOffEnabled
    }
    completionDate
    concernRaisedActivities {
        id
        createdTimestamp
        notes
        user {
            id
            displayName
        }
    }
    type
`;

export default Completed;