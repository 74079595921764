import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import Divider, {DividerVariant} from "worksmith/components/Divider/Divider.web";
import moment from "moment";
import InvoiceItem from "./InvoiceItem";
import Currency from "worksmith/components/Currency/Currency.web";
import {GetPercentage, GroupBy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Skeleton, {SkeletonVariant} from "worksmith/components/Skeleton/Skeleton";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";

const Header = styled.div`
    margin: 0 1.5em 1em 1.5em;
`;

const TotalSection= styled.div`
    margin: 1em 1.5em;
`;

const TotalSubSection = styled.div`
    margin-bottom: 1em;
`;

const InvoiceItemSection = styled.div`
    margin: 1.5em;
`;

const InvoiceItemList = (props) => {
    const {effectiveTax, linkTo, loading, receivableItems, subTotal, total, totalDiscount} = props;
    const [columnWidths, setColumnWidths] = useState({});

    let invoiceItemsJSX = <Skeleton variant={SkeletonVariant.RECTANGLE} height={'200px'}/>;

    if(ValueIsSet(receivableItems)) {
        // noinspection SSBasedInspection
        invoiceItemsJSX = Object.values(GroupBy(receivableItems, item => item.obligation.id))
            .map(invoiceItem => {
                    return <div key={invoiceItem[0].id}>
                        <InvoiceItemSection>
                            <InvoiceItem linkTo={linkTo} invoiceItem={invoiceItem} totalDiscount={totalDiscount} columnWidths={columnWidths}/>
                        </InvoiceItemSection>
                        <Divider variant={DividerVariant.FULL_WIDTH}/>
                    </div>;
            });
    }

    const calculateColumnWidths = (receivableItems) => {
        let showReasonCode = false;
        // loop through all items to see if any of them have a reasonCode, if they do then add the header and show the reasonCode

        if(ValueIsSet(receivableItems)) {
            // noinspection SSBasedInspection
            invoiceItemsJSX = Object.values(GroupBy(receivableItems, item => item.obligation.id))
                .every(invoiceItem => {
                    invoiceItem.map(i => {
                        if (ValueIsSet(i.pricingItem.obligationItem) && ValueIsSet(i.pricingItem.obligationItem.reasonCode)) {
                            showReasonCode = true;
                            return false; // this ends the loop, we only need to know if 1 reasonCode is present
                        }
                    });
                    return true; // continues the loop looking for reasonCode to be present
                });
        }

        // because there are now 2 columns that don't always show, this dynamically changes the sizes so that it still adds up to about 100% width
        const mandatoryColumnMinWidths = {
            serviceDate: 8,
            serviceDetails: 25,
            qty: 5,
            unitPrice: 15,
            amount: 15
        };

        const totalMandatoryWidth = Object.values(mandatoryColumnMinWidths).reduce((total, width) => total + width, 0);
        const numOptionalColumns = (totalDiscount > 0 ? 1 : 0) + (showReasonCode ? 1 : 0);
        const optionalColumnWidth = 15;

        const adjustedMandatoryWidth = 100 - optionalColumnWidth * numOptionalColumns;
        const adjustedMandatoryColumns = {};
        adjustedMandatoryColumns["reasonCode"] = showReasonCode ? optionalColumnWidth : 0;
        adjustedMandatoryColumns["totalDiscount"] = totalDiscount > 0 ? optionalColumnWidth : 0;
        for( const [column, width] of Object.entries(mandatoryColumnMinWidths)){
            adjustedMandatoryColumns[column] = Math.round((width / totalMandatoryWidth) * adjustedMandatoryWidth);
        }
        setColumnWidths(adjustedMandatoryColumns);
    };

    useEffect(() => {
        calculateColumnWidths(receivableItems);
    }, [receivableItems]);

    return (
        <Fragment>
            <Header>
                <Grid container>
                    <Grid item width={`${columnWidths.serviceDate}%`}>
                        <Text variant={TextVariant.SUBTITLE_2}>Service Date</Text>
                    </Grid>
                    <Grid item width={`${columnWidths.serviceDetails}%`}>
                        <Text variant={TextVariant.SUBTITLE_2}>Service Details</Text>
                    </Grid>
                    <Grid item width={`${columnWidths.qty}%`}>
                        <Text variant={TextVariant.SUBTITLE_2}>Qty</Text>
                    </Grid>
                    <Grid item width={`${columnWidths.unitPrice}%`}>
                        <Text variant={TextVariant.SUBTITLE_2}>Unit Price</Text>
                    </Grid>
                    {columnWidths.totalDiscount > 0 ?
                        <Grid item width={`${columnWidths.totalDiscount}%`}>
                            <Text variant={TextVariant.SUBTITLE_2}>Unit Discounts</Text>
                        </Grid>
                    :
                        null}
                    <Grid item width={`${columnWidths.amount}%`}>
                        <Text variant={TextVariant.SUBTITLE_2}>Amount</Text>
                    </Grid>
                    {columnWidths.reasonCode > 0 ?
                        <Grid item width={`${columnWidths.reasonCode}%`}>
                            <Text variant={TextVariant.SUBTITLE_2}>Reason Code</Text>
                        </Grid>
                    :
                        null}
                </Grid>
            </Header>
            <Divider variant={DividerVariant.FULL_WIDTH}/>
            {invoiceItemsJSX}
            <TotalSection>
                <Grid container>
                    <Grid item xs={12} sm={totalDiscount > 0 ? 8 : 6}/>
                    <Grid container item xs={12} sm={totalDiscount > 0 ? 4 : 6}>
                        <Grid item xs={6}>
                            <TotalSubSection>
                                <Text variant={TextVariant.SUBTITLE_1}>Subtotal</Text>
                            </TotalSubSection>
                        </Grid>
                        <Grid item xs={6}>
                            <TotalSubSection>
                                <Text renderSkeleton={loading} variant={TextVariant.SUBTITLE_1}>
                                    <Currency amount={subTotal}/>
                                </Text>
                            </TotalSubSection>
                        </Grid>

                        <Grid item xs={6}>
                            <TotalSubSection>
                                <Text renderSkeleton={loading} variant={TextVariant.SUBTITLE_1}>Tax</Text>
                            </TotalSubSection>
                        </Grid>
                        <Grid item xs={6}>
                            <TotalSubSection>
                                <Text renderSkeleton={loading} variant={TextVariant.SUBTITLE_1}>
                                    <Currency amount={effectiveTax}/>
                                </Text>
                            </TotalSubSection>
                        </Grid>


                        <Grid item xs={6}>
                            <Text variant={TextVariant.H6}>Total</Text>
                        </Grid>
                        <Grid item xs={6}>
                            <Text renderSkeleton={loading} variant={TextVariant.H6}>
                                <Currency amount={total}/>
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </TotalSection>
        </Fragment>
    )
};

InvoiceItemList.propTypes = {
    effectiveTax:    CustomPropTypes.stringOrNumber,
    linkTo:          PropTypes.func,
    loading:         PropTypes.bool.isRequired,
    receivableItems: PropTypes.arrayOf(PropTypes.object),
    subTotal:        CustomPropTypes.stringOrNumber,
    total:           CustomPropTypes.stringOrNumber,
    totalDiscount:   CustomPropTypes.stringOrNumber
};

export default InvoiceItemList;