import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'

import InsuranceForm from "./InsuranceForm";
import InsuranceView from "./InsuranceView";

import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {Color} from 'worksmith/enums/Color';
import Accordion from 'worksmith/components/Accordion/Accordion';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import Text, {TextVariant} from "worksmith/components/Text/Text";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientLocationService from "worksmith/services/api/ClientLocationService";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientLocationService = new ClientLocationService();

const InsurancePanel = ({insuranceInfo, handleCompletePanel, readOnly, whiteLabelClient}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [insuranceFormFields, setInsuranceFormFields] = useState({})

    const insuranceInfoComplete =
        insuranceFormFields.unionLaborRequirement &&
        insuranceFormFields.occurrenceInsuranceRequirement &&
        insuranceFormFields.aggregateInsuranceRequirement


    let formFields = {
        unionLaborRequirement: insuranceFormFields.unionLaborRequirement ? insuranceFormFields.unionLaborRequirement : null,
        occurrenceInsuranceRequirement: insuranceFormFields.occurrenceInsuranceRequirement ? insuranceFormFields.occurrenceInsuranceRequirement : 1000000,
        aggregateInsuranceRequirement: insuranceFormFields.aggregateInsuranceRequirement ? insuranceFormFields.aggregateInsuranceRequirement : 2000000,
        additionalInsuranceDetails: insuranceFormFields.additionalInsuranceDetails ? insuranceFormFields.additionalInsuranceDetails : ''
    }

    const refetchInsuranceData = async () => {
        const insuranceData = await asyncGraphQLServiceClass.findOneById(insuranceInfo.locationId, GraphQLObjectType.CLIENT_LOCATION, insuranceFields);
        setInsuranceFormFields({
            unionLaborRequirement: insuranceData.clientLocationOperationDetails.unionLaborRequirement ? insuranceData.clientLocationOperationDetails.unionLaborRequirement : null,
            occurrenceInsuranceRequirement: insuranceData.clientLocationOperationDetails.occurrenceInsuranceRequirement ? insuranceData.clientLocationOperationDetails.occurrenceInsuranceRequirement : 1000000,
            aggregateInsuranceRequirement: insuranceData.clientLocationOperationDetails.aggregateInsuranceRequirement ? insuranceData.clientLocationOperationDetails.aggregateInsuranceRequirement : 2000000,
            additionalInsuranceDetails: insuranceData.clientLocationOperationDetails.additionalInsuranceDetails ? insuranceData.clientLocationOperationDetails.additionalInsuranceDetails : ''
        })
    }

    const submitInsuranceInfo = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let submitData = {};
        submitData.clientLocationOperationDetails = {
            id: insuranceInfo.id,
            locationId: insuranceInfo.locationId,
            unionLaborRequirement: formFields.unionLaborRequirement,
            occurrenceInsuranceRequirement: formFields.occurrenceInsuranceRequirement,
            aggregateInsuranceRequirement: formFields.aggregateInsuranceRequirement,
            additionalInsuranceDetails: formFields.additionalInsuranceDetails,
        }
        submitData.id = insuranceInfo.locationId;

        try {
            await clientLocationService.update(submitData);
            await refetchInsuranceData();
            setIsEditing(false);
            setIsSubmitting(false);
        } catch (err) {
            console.error(err)
            setIsEditing(false);
            setIsSubmitting(false);
        }
    }

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    }

    const handleChange = (formField, value) => {
        formFields[formField] = value
        if (formField === "unionLaborRequirement") {
            setInsuranceFormFields({
                ...insuranceFormFields,
                unionLaborRequirement: value
            })
        }
    }

    const panelSummary = (
        <Grid container>
            <Grid container item xs={11} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H6}>
                    {whiteLabelClient ? "Union Requirement" : "Union & Insurance Requirements"}
                </Text>
            </Grid>
            <Grid container item xs={1} alignItems={AlignItems.CENTER} justify={JustifyContent.CENTER}>
                {!readOnly ? <Icon name={insuranceInfoComplete ? IconType.CHECK_CIRCLE_OUTLINED : IconType.REPORT_PROBLEM_OUTLINED} iconColor={insuranceInfoComplete ? Color.GREEN : Color.RED} /> : null}
            </Grid>
        </Grid>
    );

    useEffect(() => {
        insuranceInfoComplete ? handleCompletePanel('insurance', true) : handleCompletePanel('insurance', false);
    }, [insuranceFormFields])

    useEffect(() => {
        formFields.unionLaborRequirement = insuranceFormFields.unionLaborRequirement
    }, [insuranceFormFields])

    useEffect(() => {
        if (insuranceInfo) {
            setInsuranceFormFields({
                unionLaborRequirement: insuranceInfo.unionLaborRequirement ? insuranceInfo.unionLaborRequirement : null,
                occurrenceInsuranceRequirement: insuranceInfo.occurrenceInsuranceRequirement ? insuranceInfo.occurrenceInsuranceRequirement : 1000000,
                aggregateInsuranceRequirement: insuranceInfo.aggregateInsuranceRequirement ? insuranceInfo.aggregateInsuranceRequirement : 2000000,
                additionalInsuranceDetails: insuranceInfo.additionalInsuranceDetails ? insuranceInfo.additionalInsuranceDetails : ''
            })
        }
    }, [])

    return (
            <Accordion
                summary={panelSummary}
                details={isEditing && !readOnly ?
                    <InsuranceForm
                        handleChange={handleChange}
                        insuranceFormFields={insuranceFormFields}
                        isSubmitting={isSubmitting}
                        submitInsuranceInfo={submitInsuranceInfo}
                        toggleEdit={toggleEdit}
                        whiteLabelClient={whiteLabelClient}
                    />
                    :
                    <InsuranceView
                        handleChange={handleChange}
                        insuranceFormFields={insuranceFormFields}
                        readOnly={readOnly}
                        toggleEdit={toggleEdit}
                        whiteLabelClient={whiteLabelClient}
                    />

                }
                elevation={0}
                large
                width={'95%'}
            />
    )
};

export default InsurancePanel;

InsurancePanel.defaultProps = {
    whiteLabelClient: false
};

InsurancePanel.propTypes = {
    insuranceInfo: PropTypes.shape({
        id: PropTypes.number.isRequired,
        locationId: PropTypes.number.isRequired,
        unionLaborRequirement: PropTypes.string,
        occurrenceInsuranceRequirement: PropTypes.number,
        aggregateInsuranceRequirement: PropTypes.number,
        additionalInsuranceDetails: PropTypes.string,
        locationSquareFootage: PropTypes.number,
        hasLadderOnSite: PropTypes.bool,
        ladderHeightFt: PropTypes.number,
        parkingDirections: PropTypes.string,
        serviceEntranceDescription: PropTypes.string,
        additionalAccessInfo: PropTypes.string
    }),
    handleCompletePanel: PropTypes.func,
    readOnly: PropTypes.bool,
    whiteLabelClient: PropTypes.bool
}

const insuranceFields = `
    clientLocationOperationDetails {
        unionLaborRequirement
        occurrenceInsuranceRequirement
        aggregateInsuranceRequirement
        additionalInsuranceDetails
    }
`
