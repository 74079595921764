import React from 'react';
import {
    useLightboxState,
    useController,
    IconButton,
} from "yet-another-react-lightbox/dist/core";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import {downloadAttachment} from "worksmith/components/Lightbox/LightBoxHelpers";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const DownloadIcon = () => {
    return (
        <Icon name={IconType.GET_APP} />
    )
};

const DownloadButton = () => {
    const {
        state: { currentIndex }
    } = useLightboxState();
    const { getLightboxProps } = useController();
    const { slides } = getLightboxProps();
    const slide = slides[currentIndex];

    const slideSrc = ValueIsSet(slide.src) ? slide.src : slide.sources[0].src;

    return (
        <IconButton
            label="Download"
            icon={DownloadIcon}
            onClick={() => downloadAttachment(slideSrc, slide.title)}
            size="large" />
    );
};

const Download = ({ augment }) => {
    augment(({ toolbar: { buttons, ...restToolbar }, ...restProps }) => ({
        toolbar: {
            buttons: [<DownloadButton key="download" />, ...buttons],
            ...restToolbar
        },
        ...restProps
    }));
};

export default Download;
