// This file was generated from com.worksmith.proposal.enums.NewRequestStatus
// noinspection JSUnusedGlobalSymbols
const NewRequestStatus = Object.freeze({
    CORPORATE_REVIEW: 'corporate review',
    MARKET: 'market',
    CORPORATE_PRICING_REVIEW: 'corporate pricing review',
    SCHEDULING: 'scheduling',
    SCHEDULED: 'scheduled',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
    getClosedStatuses: function() {
        return [this.COMPLETED, this.CANCELED];
    },
    getMarketplaceOperationsAvailableStatuses: function() {
        return [this.MARKET, this.CORPORATE_PRICING_REVIEW, this.SCHEDULING];
    },
    getOpenStatuses: function() {
        return [this.CORPORATE_REVIEW, this.MARKET, this.CORPORATE_PRICING_REVIEW, this.SCHEDULING, this.SCHEDULED];
    }
});

export default NewRequestStatus;
