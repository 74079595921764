import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import EditComplianceModalView from "./EditComplianceModalView";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ServiceLevelAgreementItemService from "worksmith/services/api/ServiceLevelAgreementItemService";
import CircularProgress from "@mui/material/CircularProgress";
import {LinearProgressVariant as ProgressBarVariant} from "worksmith/components/LinearProgress/LinearProgress";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";

const graphQLService = new GraphQLServiceClass();
const serviceLevelAgreementItemService = new ServiceLevelAgreementItemService();

const CenteredProgressbar = styled(CircularProgress)`
    position: absolute;
    top:  50%;
    left: 50%;
    transform: translate(-50%,-50%);
`;

class EditComplianceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isCurrentlyCompliant: null,
            isRecurring: null,
            requestId: null,
            obligationId: null,
            selectedCompliance: null,
            slaActivities: null
        };
    }

    componentDidMount() {
        let {serviceLevelAgreementItemId} = this.props;
        graphQLService.findAll(
            {id: serviceLevelAgreementItemId},
            GraphQLObjectType.SERVICE_LEVEL_AGREEMENT_ITEM,
            `
            id
            resolved
            compliant
            itemUpdateActivities{
                activity
                user{
                    displayName
                }
                notes
                createdTimestamp
            }
            request{
                id
                type
            }
            obligation{
                id
                obligationTemplateId
            }
        `)
            .then(data => {
            let slaItem = data[0];
            let isCurrentlyCompliant = ValueIsSet(slaItem.resolved) ? slaItem.resolved : slaItem.compliant;
            let isRecurring = ValueIsSet(slaItem.obligation) && ValueIsSet(slaItem.obligation.obligationTemplateId);
            this.setState({
                loading: false,
                isCurrentlyCompliant,
                isRecurring,
                requestId: slaItem.request.id,
                obligationId: ValueIsSet(slaItem.obligation) ? slaItem.obligation.id : null,
                slaActivities: slaItem.itemUpdateActivities
            })
        });
    }

    onAccept = (textFieldValue) => {
        this.onSubmit(textFieldValue);

    };

    onSubmit = (textFieldValue) => {
        let {selectedCompliance, isCurrentlyCompliant} = this.state;
        const { serviceLevelAgreementItemId } = this.props;

        let statusWasChanged = false;

        let updateParams = {notes: textFieldValue};
        if(selectedCompliance !== isCurrentlyCompliant){
            updateParams.resolved = selectedCompliance;
            statusWasChanged = true;
        }

        serviceLevelAgreementItemService.updateServiceLevelAgreementItem(serviceLevelAgreementItemId,
            updateParams).then( () => {
                this.props.onClose(statusWasChanged);
                DisplaySuccessNotification("Successfully Updated");
            }
        ).catch( () => {
                DisplayErrorNotification("Update Failed");
                this.props.onClose(false);
            }
        )
    };

    setNewComplianceStatus = (event, value) => {
        this.setState({selectedCompliance: value === "true"});
    };

    render() {
        let {onAccept, setNewComplianceStatus} = this;
        const {requestId, obligationId, isRecurring, isCurrentlyCompliant, selectedCompliance, slaActivities, loading} = this.state;
        const {open, onClose} = this.props;

        let title = isRecurring ? ("Edit Status - Recurring Task #" + obligationId) : ("Edit Status - Request #" + requestId);

        if(loading && open){
            return <Modal open={true} children={<CenteredProgressbar variant={ProgressBarVariant.INDETERMINATE}/>}/>;
        }

        return (
            <EditComplianceModalView
                onAccept={onAccept}
                onCloseModal={() => onClose(false)}
                title={title}
                setNewComplianceStatus={setNewComplianceStatus}
                open={open}
                isCurrentlyCompliant={isCurrentlyCompliant}
                selectedCompliance={selectedCompliance}
                slaActivities={slaActivities}
            />
        )
    }
}

EditComplianceModal.propTypes = {
    serviceLevelAgreementItemId: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired // called when modal is closed. Takes a parameter called "statusWasChanged" for conditional re-loading.
};

export default EditComplianceModal;