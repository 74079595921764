import React, {useState} from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import Accordion from "worksmith/components/Accordion/Accordion";
import Grid from "worksmith/components/Grid/Grid";
import StandardPricingItem from "worksmith/composite-components/ClientLocationProfile/standardPricing/StandardPricingItem";
import Text from "worksmith/components/Text/Text";
import {Color} from "worksmith/enums/Color";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";


const StandardPricingItemAccordion = ({
                                          currency,
                                          getClientStandardItemsPricing,
                                          locationId,
                                          selfServiceClientVendorRelationships,
                                          serviceLineId,
                                          standardItems,
                                          standardItemsPricing,
                                          userIsAdmin,
}) => {

    const [expanded, setExpanded] = useState(null);


    return (
        <Grid container>
            {(standardItems.length > 0) ?
                standardItems.sort((a, b) => a.name.localeCompare(b.name)).map(item => {
                    return (
                        <Grid item xs={8} key={item.id} padding={'0 0 0 8px'}>
                            <Accordion
                                transitionProps={{unmountOnExit: true}}
                                expanded={expanded === item.id}
                                onChange={() => {
                                    setExpanded((prevState) => prevState === item.id ? null : item.id)
                                }}
                                summary={item.name}
                                details={
                                    <StandardPricingItem
                                        currency={currency}
                                        getClientStandardItemsPricing={getClientStandardItemsPricing}
                                        initialItemPricings={standardItemsPricing.filter(itemPricing => itemPricing.standardItem.id === item.id)}
                                        locationId={locationId}
                                        selfServiceClientVendorRelationships={selfServiceClientVendorRelationships}
                                        serviceLineId={serviceLineId}
                                        setExpanded={setExpanded}
                                        standardItem={item}
                                        userIsAdmin={userIsAdmin}
                                    />
                                }
                                square
                                renderDetailsOnFirstExpansion
                            />
                        </Grid>
                    )
                })
                :
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        {userIsAdmin ?
                            <Text>No items. Go to the <Link to={"/standard-items/"} style={{textDecoration: 'underline', color: Color.SECONDARY}}>Standard Items</Link> page to add items.</Text>
                            :
                            <Text>No items. Contact admin user to add Standard Items.</Text>
                        }
                    </Grid>
                </Grid>
            }
        </Grid>
    )
};

export default StandardPricingItemAccordion;

StandardPricingItemAccordion.propTypes = {
    currency: CustomPropTypes.enum(CurrencySymbolEnum),
    getClientStandardItemsPricing: PropTypes.func,
    locationId: PropTypes.number,
    selfServiceClientVendorRelationships: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    serviceLineId: PropTypes.number,
    standardItems: PropTypes.arrayOf(PropTypes.shape({
        standardItemsForClient: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            client: PropTypes.shape({
                id: PropTypes.number
            })
        }))
    })),
    standardItemsPricing: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        updatedTimestamp: PropTypes.string,
        unitRetailPrice: PropTypes.number,
        unitWholesalePrice: PropTypes.number,
        unitCustomerCharge: PropTypes.number,
        clientLocationId: PropTypes.number,
        vendorLocationId: PropTypes.number,
        vendorName: PropTypes.string,
        standardItem: PropTypes.shape({
            id: PropTypes.number,
            createdTimestamp: PropTypes.string,
            updatedTimestamp: PropTypes.string,
            name: PropTypes.string,
            client: PropTypes.object,
            serviceLine: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            lineItemType: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
        }),
    })),
    userIsAdmin: PropTypes.bool,
};