import React from "react";
import PropTypes from "prop-types";
import Grid from "../Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import {TextColor} from "../Text/Text.web";

const Address = (props) => {
    const{addressLineOne,addressLineTwo,city,singleLine,state,zip,textColor,textVariant} = props;

    return (
        !singleLine ?
            <Grid container item xs={12} spacing={0}>
                <Grid item xs={12}>
                    <Text variant={textVariant} color={textColor}>{addressLineOne}</Text>
                </Grid>
                {ValueIsSet(addressLineTwo) && addressLineTwo !== '' ?
                    <Grid item xs={12}>
                        <Text variant={textVariant} color={textColor}>{addressLineTwo + ", " + city + ", " + state + ", " + zip}</Text>
                    </Grid> :
                    <Grid item xs={12}>
                        <Text variant={textVariant} color={textColor}>{city + ", " + state + ", " + zip}</Text>
                    </Grid>
                }
            </Grid> :
            <Grid container item xs={12} spacing={0}>
                {ValueIsSet(addressLineTwo) ?
                    <Grid item xs={12}>
                        <Text variant={textVariant} color={textColor}>{addressLineTwo + ", " + addressLineOne + " " + city + ", " + state + " " + zip}</Text>
                    </Grid> :
                    <Grid item xs={12}>
                        <Text variant={textVariant} color={textColor}>{addressLineOne + " " + city + ", " + state + " " + zip}</Text>
                    </Grid>
                }
            </Grid>
    );

};

Address.defaultPropTypes = {
    singleLine: false,
    textColor: TextColor.PRIMARY,
    textVariant: TextVariant.BODY_2
};

Address.propTypes = {
    addressLineOne: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string,
    city: PropTypes.string.isRequired,
    singleLine: PropTypes.bool,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    textColor: CustomPropTypes.enum(TextColor),
    textVariant: CustomPropTypes.enum(TextVariant)
};

export default Address;