import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Select, {SelectViewSize} from "worksmith/components/Inputs/Select/Select";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import {CountryStateProvince, CountriesCombined, UnitedStatesCanadaCombinedList} from "worksmith/enums/api/country/CountryStateProvince";
import {StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";

const StateProvinceSelect = (props) => {
    const {
        country,
        error,
        helperText,
        initialValue,
        label,
        onChange,
        viewSize,
    } = props;

    const onChangeRef = useRef();

    const countryIsSet = ValueIsSet(country) && StringHasText(country);

    const countryOptions = (country) => {
      if(country === CountriesCombined.USA_CANADA) {
          return UnitedStatesCanadaCombinedList;
      }

      return Object.entries(CountryStateProvince[country]).map(([key, value]) => {
          return {
            label: value,
            value: key,
        }});
    };

    const initialValueOption = (initialValue) => {
        return [{
            label: initialValue,
            value: initialValue
        }]
    };


    useEffect(() => {
        if (countryIsSet && countryOptions(country).length === 1 && onChangeRef.current) {
            onChangeRef.current.reset(countryOptions(country)[0].value);
            onChange(countryOptions(country)[0].value);
        }
    }, [country]);


    return (
        <>
            <Tooltip
                placement={TooltipPlacement.TOP_START}
                title={"A country must be selected in order to choose a State/Province."}
                hideTooltip={countryIsSet}
            >
                <Select
                    ref={onChangeRef}
                    disabled={!countryIsSet}
                    includeNullOption
                    initialValue={initialValue}
                    label={label}
                    options={countryIsSet ? countryOptions(country) : ValueIsSet(initialValue) ? initialValueOption(initialValue) : []}
                    onChange={onChange}
                    fullWidth
                    noMaxWidth
                    viewSize={viewSize}
                    error={error}
                    helperText={helperText}
                />
            </Tooltip>
        </>
    )
};

export default StateProvinceSelect;

StateProvinceSelect.propTypes = {
    country: PropTypes.string.isRequired,          // two letter abbreviation for the country ex ('us')
    error: PropTypes.bool,
    helperText: PropTypes.string,
    initialValue: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    viewSize: CustomPropTypes.enum(SelectViewSize),
};