import React, {memo, PureComponent} from 'react';
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";

import {ComponentColor} from "../../enums/Color";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {withTheme} from "../Theme/ThemeProvider";
import {AlignItems, Display, FlexDirection, JustifyContent} from "../../enums/CSSEnums";
import Text from "../Text/Text.web";
import {ObjectsHaveIdenticalValue, ValueIsSet} from "../../helpers/GenericHelpers";

export const SliderOrientation = Object.freeze({
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
});

export const SliderValueDisplay = Object.freeze({
    ON: 'on',
    AUTO: 'auto',
    OFF: 'off'
});

export const SliderTrack = Object.freeze({
    NORMAL: 'normal',
    INVERTED: 'inverted',
    FALSE: 'false'
});

const MemoSlider = memo(Slider, (props, nextProps) => ObjectsHaveIdenticalValue(props, nextProps));

class SliderField extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
           value: props.initialValue ? props.initialValue : null
        };

        if(this.state.value === null) {
            this.state.value = props.ranged ? [props.min, props.max] : props.min;
        }
    }

    onChange = (event, value) => {
        this.setState({value}, () => {
            if (this.props.onChange)  {
                this.props.onChange(value)
            }
        });
    }

    onChangeCommitted = (event, value) => {
        this.setState({value}, () => {
           if (this.props.onChangeCommitted)  {
               this.props.onChangeCommitted(value)
           }
        });
    }

    render() {
        let {onChange, onChangeCommitted} = this;
        const {value} = this.state;
        const {
            color,
            disabled,
            displayMinMaxText,
            error,
            helperText,
            marks,
            loadingSkeleton,
            max,
            min,
            orientation,
            step,
            label,
            track,
            valueLabelDisplay
        } = this.props;

        const formControlStyle = orientation === SliderOrientation.HORIZONTAL ?
            {
                width: '93%',
                justifyContent: JustifyContent.CENTER
            }
            :
            {
                flexDirection: FlexDirection.COLUMN,
                alignItems: AlignItems.CENTER,
                display: Display.INLINE_FLEX,
                height: '93%'
            };

        return (
            <FormControl
                variant="standard"
                disabled={disabled || loadingSkeleton}
                hiddenLabel={!ValueIsSet(label)}
                error={error}
                fullWidth
                style={formControlStyle}>
                <FormLabel component={Text}>
                    {label}
                </FormLabel>
                <MemoSlider
                    color={color}
                    disabled={disabled || loadingSkeleton}
                    marks={marks}
                    max={max}
                    min={min}
                    onChange={onChange}
                    onChangeCommitted={onChangeCommitted}
                    orientation={orientation}
                    step={step}
                    track={track}
                    value={value}
                    valueLabelDisplay={valueLabelDisplay}/>
                {ValueIsSet(helperText) ?
                    <FormHelperText style={{marginTop: '-9px', marginBottom: '12px'}}>{helperText}</FormHelperText>
                    :
                    null
                }
                {ValueIsSet(displayMinMaxText) && displayMinMaxText ?
                    <Grid container justifyContent={JustifyContent.SPACE_BETWEEN}>
                        <Grid item>
                            <FormHelperText>{min}</FormHelperText>
                        </Grid>
                        <Grid item>
                            <FormHelperText>{max}</FormHelperText>
                        </Grid>
                    </Grid>
                    :
                    null
                }
            </FormControl>
        );
    }
}

SliderField.defaultProps = {
    color: ComponentColor.PRIMARY,
    displayMinMaxText: false,
    error: false,
    orientation: SliderOrientation.HORIZONTAL,
    track: SliderTrack.NORMAL,
    valueLabelDisplay: SliderValueDisplay.OFF,
    ranged: false
};

SliderField.propTypes = {
    color: PropTypes.oneOf([
        ComponentColor.PRIMARY,
        ComponentColor.SECONDARY
    ]),
    displayMinMaxText: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    initialValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number) // range [firstValue, secondValue]
    ]),                       // passing in an initial value of [number] will set ranged to true
    label: PropTypes.string,
    loadingSkeleton: PropTypes.bool,
    marks: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string
        })
    ]),
    max: PropTypes.number.isRequired, // max and min cannot be equal
    min: PropTypes.number.isRequired,
    onChange: PropTypes.func, // returns a single value or a two value array based off of whether ranged or not
    onChangeCommitted: PropTypes.func,
    orientation: CustomPropTypes.enum(SliderOrientation),
    ranged: PropTypes.bool,
    step: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number) // range [firstValue, secondValue]
    ]),
    valueLabelDisplay: CustomPropTypes.enum(SliderValueDisplay)
};

export default memo(withTheme(SliderField), (props, nextProps) => ObjectsHaveIdenticalValue(props, nextProps));