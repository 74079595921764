import React, {Component, Fragment} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Papa from 'papaparse';
import Loader from "../../components/Loader";
import ClientReportingService from 'worksmith/services/api/ClientReportingService'
import {WithContext} from "../../context/GlobalContext";
import {ValueIsSet} from "../../shared/helpers/Helpers";
import Button from 'worksmith/antd-components/Button/Button.web';
import CsvLinkWeb from 'worksmith/antd-components/CsvLink/CsvLink.web';
import MonthPicker from 'worksmith/antd-components/MonthPicker/MonthPicker.web';
import Section from 'worksmith/antd-components/Section/Section.web';
import Select from 'worksmith/antd-components/Select/Select';
import Title from 'worksmith/antd-components/Title/Title.web';
import {ButtonType} from 'worksmith/enums/ButtonType';
import {Color} from 'worksmith/enums/Color';
import Grid from 'worksmith/components/Grid/Grid.web';
import Text, {TextVariant} from 'worksmith/components/Text/Text.web';
import ClientStandardReportType from "worksmith/enums/api/reporting/customReport/ClientStandardReportType";
import {JustifyContent, TextAlign} from "worksmith/enums/CSSEnums";
import BarGraph from "worksmith/components/DataVisualization/BarGraph/BarGraph.web";
import Divider from "worksmith/components/Divider/Divider.web";
import PieChart from "worksmith/components/DataVisualization/PieChart/PieChart.web";
import {GetDollarString} from "worksmith/helpers/GenericHelpers";
import BreakpointListener from "worksmith/components/BreakpointListener/BreakpointListener.web";

const clientReportingService = new ClientReportingService();

const RedText = styled(Text)`
    color: ${Color.RED};
`;

const ReportSelectSection =  styled.div`
    flex: 2;
`;

const DateSelectSection =  styled.div`
    flex: 1;
`;

const FullWidthMonthPicker =  styled(MonthPicker)`
    width: 100%; 
`;

const SearchSection =  styled.div`
    flex: 1;
    padding: 1.3rem 0 0 0;
`;

class ClientStandardReportingPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            csvData: null,
            disabled: true,
            invalidDates: false,
            loading: false,
            loadingReportForGraphs: false,
            loadingReportForCsv: false,
            reportOptions: [],
            reports: [],
            selectedReportIndex: null,
            startDate: null,
            endDate: null,
            hasSearched: false,
            pieChartData: null,
            barGraphData: null,
            totalCombinedSpend: 0
        };

        this.csvRef = React.createRef()
    }

    componentDidMount() {
        let {context} = this.props;

        this.setState({loading: true});
        clientReportingService.getAvailableReports(context.client.id).then(data => {
            let reportOptions = data.map((option, index) => {
                return {value: index, name: option.name}
            });
            this.setState({reports: data, reportOptions, loading: false});
        });
    }

    onChangeStartDate = (date) => {
        this.setState({startDate: date}, () => {
            this.checkDisabled();
        })
    };

    onChangeEndDate =(date) => {
        this.setState({endDate: date}, () => {
            this.checkDisabled();
        })
    };

    isDateDisabled = (date) => {
        return (moment().month() < date.month() && moment().year() === date.year()) || date.year() > moment().year();
    };

    onReportSelect = (value) => {
       this.setState({selectedReportIndex: value}, () => {
            this.checkDisabled();
        });
    };

    checkDisabled = () => {
        let {invalidDate, disabled, selectedReportIndex, startDate, endDate} = this.state;

        // validate the date
        let newState = {invalidDate, disabled};
        if (ValueIsSet(endDate) && ValueIsSet(startDate) && endDate.isBefore(startDate)) {
            newState.invalidDate = true;
        } else if (invalidDate) {
            newState.invalidDate = false;
        }

        // validate the fields are filled out
        if (disabled && ValueIsSet(selectedReportIndex) && ValueIsSet(startDate) && ValueIsSet(endDate)) {
            newState.disabled = false;
        } else if (!disabled && !(ValueIsSet(selectedReportIndex) && ValueIsSet(startDate) && ValueIsSet(endDate))) {
            newState.disabled = true;
        }

        if (newState.invalidDate && !newState.disabled)
            newState.disabled = true;

        if (newState.invalidDate !== invalidDate || newState.disabled !== disabled)
            this.setState(newState);
    };

    downloadReport = (exportToCsv) => {
        let {csvRef, buildGraphDataForReport} = this;
        let {startDate, endDate, selectedReportIndex, reports} = this.state;
        let {context} = this.props;

        let params = {
            clientId: context.client.id,
            startDate: moment(startDate).startOf('month').format('YYYY-MM-DD'),
            endDate: moment(endDate).endOf('month').format('YYYY-MM-DD'),
        };

        let selectedReport = reports[selectedReportIndex];

        if (ValueIsSet(selectedReport.id)) {
            params.reportId = selectedReport.id;
        } else {
            params.standardReportType = selectedReport.name;
        }

        this.setState({ loadingReportForCsv: exportToCsv, loadingReportForGraphs: !exportToCsv });
        clientReportingService.getReport(params).then((data) => {
            buildGraphDataForReport(data, selectedReport.name);

            let csvFileName = moment(startDate).format('YYYY-MM-DD') + ' to ' + moment(endDate).format('YYYY-MM-DD') + ' ' + selectedReport.name + '.csv';
            this.setState({loadingReportForGraphs: false, loadingReportForCsv: false, csvData: data, csvFileName}, () => {
                if (exportToCsv)
                    csvRef.current.clickLink();
            });
        });
    };

    //If we want more customized graphs than a pie chart and/or bar graph, we'll need to build out a more robust system
    buildGraphDataForReport = (csvData, reportType) => {
        const papaParseObj = Papa.parse(csvData, {header: true, dynamicTyping: true, skipEmptyLines: true});
        const parsedData = papaParseObj.data;

        let totalCombinedSpend = 0;

        switch(reportType) {
            case ClientStandardReportType.FACILITIES:
                const totalsByServiceLine = parsedData.reduce((combinedData, dataPoint) => {
                    if (!ValueIsSet(combinedData[dataPoint['Service Line']]))
                        combinedData[dataPoint['Service Line']] = 0;

                    combinedData[dataPoint['Service Line']] += dataPoint['Total Price with Tax'];
                    totalCombinedSpend += dataPoint['Total Price with Tax'];

                    return combinedData;
                }, {});

                const pieChartData = Object.keys(totalsByServiceLine).reduce((pieChartData, serviceLine) => {
                    const totalSpend = totalsByServiceLine[serviceLine];

                    if (totalSpend > 0) {
                        const dollarString = serviceLine + '\nTotal Spend: \n' + GetDollarString(Number(totalSpend.toString())) + '\n(${percent})';

                        pieChartData.push({
                            x: serviceLine,
                            y: totalSpend,
                            tooltip: dollarString,
                            tooltipLabelFontSize: 12
                        });
                    }

                    return pieChartData;
                }, []);

                const totalsByLocation = parsedData.reduce((combinedData, dataPoint) => {
                    if (!ValueIsSet(combinedData[dataPoint['Location Name']]))
                        combinedData[dataPoint['Location Name']] = 0;

                    combinedData[dataPoint['Location Name']] += dataPoint['Total Price with Tax'];

                    return combinedData;
                }, {});

                const barGraphData = Object.keys(totalsByLocation).reduce((barGraphData, location) => {
                    const totalSpend = totalsByLocation[location];

                    if (totalSpend > 0) {
                        const dollarString = 'Total Spend: \n' + GetDollarString(Number(totalSpend.toString())) + '\n(${percent})';

                        barGraphData.push({
                            x: location,
                            y: totalSpend,
                            tooltip: dollarString
                        });
                    }

                    return barGraphData;
                }, []).sort((a, b) => a.y - b.y);

                this.setState({pieChartData: pieChartData, barGraphData: barGraphData, totalCombinedSpend});
                break;


            case ClientStandardReportType.TICKETING:
                const totalsByLocationTicketing = parsedData.reduce((combinedData, dataPoint) => {
                    if (!ValueIsSet(combinedData[dataPoint['Location Name']]))
                        combinedData[dataPoint['Location Name']] = 0;

                    combinedData[dataPoint['Location Name']] += dataPoint['Total Price'];
                    totalCombinedSpend += dataPoint['Total Price'];

                    return combinedData;
                }, {});

                const barGraphDataTicketing = Object.keys(totalsByLocationTicketing).reduce((barGraphData, location) => {
                    const totalSpend = totalsByLocationTicketing[location];

                    if (totalSpend > 0) {
                        const dollarString = 'Total Spend: \n' + GetDollarString(Number(totalSpend.toString()));

                        barGraphData.push({
                            x: location,
                            y: totalSpend,
                            tooltip: dollarString
                        });
                    }

                    return barGraphData;
                }, []).sort((a, b) => a.y - b.y);

                this.setState({pieChartData: null, barGraphData: barGraphDataTicketing, totalCombinedSpend});
                break;
            default:
                this.setState({pieChartData: null, barGraphData: null});
        }
    };

    render() {
        let {disabled, invalidDate, loading, loadingReportForCsv, loadingReportForGraphs, reportOptions, csvData, csvFileName, pieChartData, barGraphData, totalCombinedSpend} = this.state;
        let {downloadReport, isDateDisabled, onReportSelect} = this;
        let {csvRef} = this;

        return (
            <Fragment>
                <Section>
                    <Title level={3}>Client Reporting</Title>
                </Section>

                <Section>
                    {
                        invalidDate ?
                            <RedText>The invoice end date cannot be before the invoice start date.</RedText>
                            :
                            null

                    }
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={12}>
                            <ReportSelectSection>
                                <div>
                                    <Text>Report Type</Text>
                                </div>
                                <Select
                                    style={{width: '100%'}}
                                    options={reportOptions}
                                    optionFieldName={'name'}
                                    valueFieldName={'value'}
                                    onSelect={onReportSelect}/>
                            </ReportSelectSection>
                        </Grid>

                        <Grid item lg={2} xs={6}>
                            <DateSelectSection>
                                <div>
                                    <Text>Invoice Start Month</Text>
                                </div>
                                <FullWidthMonthPicker
                                    isDateDisabled={isDateDisabled}
                                    onChange={this.onChangeStartDate}/>
                            </DateSelectSection>
                        </Grid>

                        <Grid item lg={2} xs={6}>
                            <DateSelectSection>
                                <div>
                                    <Text>Invoice End Month</Text>
                                </div>
                                <FullWidthMonthPicker
                                    isDateDisabled={isDateDisabled}
                                    onChange={this.onChangeEndDate}/>
                            </DateSelectSection>
                        </Grid>

                        <Grid item lg={4} xs={6}>
                            <SearchSection>
                                <Grid container>
                                    <Grid item>
                                        <Button
                                            debounce
                                            type={ButtonType.PRIMARY}
                                            message={loadingReportForGraphs ? 'loading ...' : 'Show Report'}
                                            disabled={disabled || loadingReportForGraphs || loadingReportForCsv}
                                            loading={loadingReportForGraphs}
                                            onClick={() => downloadReport(false)}/>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            debounce
                                            type={ButtonType.PRIMARY}
                                            message={loadingReportForCsv ? 'loading ...' : 'Export to CSV'}
                                            disabled={disabled || loadingReportForCsv || loadingReportForGraphs}
                                            loading={loadingReportForCsv}
                                            onClick={() => downloadReport(true)}/>
                                        <CsvLinkWeb ref={csvRef} filename={csvFileName} data={csvData} style={{display:'none'}}/>
                                    </Grid>
                                </Grid>
                            </SearchSection>
                        </Grid>
                    </Grid>
                </Section>

                <Grid container justify={JustifyContent.CENTER}>
                    {(!ValueIsSet(pieChartData) || pieChartData.length === 0) && (!ValueIsSet(barGraphData) || barGraphData.length === 0) ?
                        <Text>No Data To Display</Text>
                        :
                        null
                    }
                    {ValueIsSet(pieChartData) ?
                        <Grid item xs={12}>
                            <BreakpointListener width={{
                                xs: 600,
                                lg: 800
                            }}>
                                <PieChart data={pieChartData}
                                          title={<Fragment>
                                              <Text align={TextAlign.CENTER} variant={TextVariant.H5}>Total Spend by Service Line</Text>
                                              <Text align={TextAlign.CENTER} variant={TextVariant.H5}>Total Combined Spend: {GetDollarString(totalCombinedSpend)}</Text>
                                              </Fragment>}
                                          hideLabels
                                          withLegend
                                          animate={false}
                                          width={800}/>
                            </BreakpointListener>
                        </Grid>
                        :
                        null
                    }

                    {ValueIsSet(pieChartData) && ValueIsSet(barGraphData) ?
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        :
                        null
                    }

                    {ValueIsSet(barGraphData) ?
                        <BreakpointListener margin={{
                            xs: '0 0 0 200px',
                            lg: undefined
                        }}>
                            <Grid item xs={12}>
                                <BreakpointListener width={{
                                    xs: 600,
                                    xl: 800
                                }}>
                                    <BarGraph data={barGraphData}
                                              maxLabelLength={30}
                                              title={<Fragment>
                                                        <Text align={TextAlign.CENTER} variant={TextVariant.H5}>Total Spend by Location</Text>
                                                        <Text align={TextAlign.CENTER} variant={TextVariant.H5}>Total Combined Spend: {GetDollarString(totalCombinedSpend)}</Text>
                                                    </Fragment>}
                                              animate={false}
                                              horizontal
                                              height={barGraphData.length < 10 ? 400 : barGraphData.length * 40}
                                              width={800}/>
                                </BreakpointListener>
                            </Grid>
                        </BreakpointListener>
                        :
                        null
                    }
                </Grid>

                {loading ? <Loader/> : null }
            </Fragment>
        );
    }
}

export default WithContext(ClientStandardReportingPage);