import React, {Component} from 'react';
import StoreHours from '../../components/StoreHours';
import {getStatesEnum} from '../../components/Utilities';
import Button from "../../components/Button";
import ClientService from 'worksmith/services/api/ClientService';
import notification from 'antd/lib/notification';
import Loader from "../../components/Loader";
import {Color} from "../../Enums";
import {validateEmail, validatePhoneNumber} from "worksmith/helpers/LanguageHelper";
import {ValueIsSet} from "../../Helpers";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import {CountryCodeWithAbbreviation} from "worksmith/enums/api/country/CountryCodeWithAbbreviation";
import {Country} from "worksmith/enums/api/country/Country";
import {Currency} from "worksmith/enums/api/country/Currency";
import {CountryStateProvince} from "worksmith/enums/api/country/CountryStateProvince";
import {UnitedStatesAndCanada, USAState} from "worksmith/enums/api/country/USAState";

const clientService = new ClientService();
const t = require('tcomb-form');
const Form = t.form.Form;

const NewLocationFormOptions = {
    fields: {
        title: {
            label: 'Location Name', //let's not change these terms throughout our system until we firmly decide on them
            help: 'Example: Chicago, Tysons Corner, 57th Street...'
        },
        locationCode: {
            label: 'Your Unique Internal Location Code',
            help: 'Example: 001, NYC-08'
        }
    }
};

const userCreateParamsFormOptions = {
    fields: {}
};

class NewLocationPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            user: props.user,
            requireUsersForNewLocations: props.requireUsersForNewLocations,
            value: {},
            userCreateParams: {},

            locationHours: {
                sunday: {
                    openTime: '09:00:00',
                    closeTime: '17:00:00',
                    dayOfWeek: 'Sunday',
                    closed: false
                },
                monday: {
                    openTime: '09:00:00',
                    closeTime: '17:00:00',
                    dayOfWeek: 'Monday',
                    closed: false
                },
                tuesday: {
                    openTime: '09:00:00',
                    closeTime: '17:00:00',
                    dayOfWeek: 'Tuesday',
                    closed: false
                },
                wednesday: {
                    openTime: '09:00:00',
                    closeTime: '17:00:00',
                    dayOfWeek: 'Wednesday',
                    closed: false
                },
                thursday: {
                    openTime: '09:00:00',
                    closeTime: '17:00:00',
                    dayOfWeek: 'Thursday',
                    closed: false
                },
                friday: {
                    openTime: '09:00:00',
                    closeTime: '17:00:00',
                    dayOfWeek: 'Friday',
                    closed: false
                },
                saturday: {
                    openTime: '09:00:00',
                    closeTime: '17:00:00',
                    dayOfWeek: 'Saturday',
                    closed: false
                }
            },
            step: 0,
            invalidFields: [''], // defaulting to empty string for .length
            invalidNewUserFields: ['']
        };
    }

    onChange = (value) => {
        let invalidFields = this.refs.form.validate().errors;
        this.setState({ invalidFields, value })
    };

    onChangeStoreHours = (locationHours) => {
        this.setState({ locationHours })
    };

    onChangeLocationNewUser = (userCreateParams) => {
        let invalidNewUserFields = this.refs.formStep3.validate().errors;
        this.setState({ invalidNewUserFields, userCreateParams })
    };

    handleStepChange = (step) => {

        let submitData = Object.assign(this.state.value);
        let _this = this;

        if(ValueIsSet(submitData.email) && submitData.email !== "" && !validateEmail(submitData.email)){
            return DisplayErrorNotification("Email Not Valid: " + submitData.email);
        } else if(ValueIsSet(submitData.phone) && submitData.phone !== "" && ((!ValueIsSet(submitData.countryCode) || submitData.countryCode === "US" || submitData.countryCode === "CA") && !validatePhoneNumber(submitData.phone))){
            return DisplayErrorNotification("Phone Number Not Valid: " + submitData.phone);
        } else {
            this.setState({ step })
        }
    };

    submit = (includingUserForNewLocation) => {
        let {user, value, locationHours, userCreateParams} = this.state;
        let _this = this;
        let {isSelfService} = this.props.client;
        _this.setState({loading: true});
        let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;

        let submitData = {
            title: value.title,
            locationCode: value.locationCode,
            ...(isSelfService) && {countryCode: value.countryCode},
            phone: value.phone,
            email: value.email,
            ...(isSelfService) && {country: Country[value.country]},
            addressLineOne: value.addressLineOne,
            addressLineTwo: value.addressLineTwo,
            city: value.city,
            state: value.stateOrProvince,
            zip: value.zip,
            ...(isSelfService) && {currency: value.currency},
        };

        let locationsArray = [];
        for (let prop in locationHours) {
           locationsArray.push(locationHours[prop]);
        }

        submitData.clientId = clientId;

        submitData.locationHours = locationsArray;

        if (includingUserForNewLocation === true) {
            submitData.userCreateParams = userCreateParams;
        } else {
            submitData.userCreateParams = null;
        }

        clientService.insertLocation(submitData).then(function(data){
            let location = data;
            _this.props.history.push(`locations/${location.id}`);
            notification['success']({
                message: 'Location added!',
            });
        }).catch(error => {
            DisplayErrorNotification("Error Submitting Client Location");
            console.error(error);
        }).finally(() => {
            _this.setState({loading: false});
        });
    };

    render(){
        let invalidStep1 = !!this.state.invalidFields.length;
        let invalidStep3 = !!this.state.invalidNewUserFields.length;
        const {value} = this.state;
        let {isSelfService} = this.props.client;

        const userCreateParamsFormStruct = t.struct({
            firstName: t.String,
            lastName: t.String,
            email: t.String,
            ...(isSelfService) && {countryCode: t.maybe(t.enums(CountryCodeWithAbbreviation))},
            phone: t.maybe(t.String),
            username: t.String
        });

        const NewLocationFormStruct = t.struct({
            title: t.String,
            locationCode: t.String,
            ...(isSelfService) && {countryCode: t.maybe(t.enums(CountryCodeWithAbbreviation))},
            phone: t.maybe(t.String),
            email: t.maybe(t.String),
            ...(isSelfService) && {country: t.maybe(t.enums(Country))},
            addressLineOne:t.String,
            addressLineTwo:t.maybe(t.String),
            city: t.String,
            stateOrProvince: t.enums(ValueIsSet(value.country) ? CountryStateProvince[Country[this.state.value.country]] : UnitedStatesAndCanada),
            zip: t.String,
            ...(isSelfService) && {currency: t.maybe(t.enums(Currency))},
        });

        if ( !this.state.loading ) {
            return (
                <div>
                    <section style={{'border': '0px', 'textAlign': 'center'}} className="ws-section">
                        <h1 className="page-header">Add A New Location</h1>
                    </section>

                    <section style={{'border': '0px'}} className="ws-section">
                        {this.state.step === 0 ?
                            <div>
                                <h3 className='body-copy-header'>Step 1: Add Location Information</h3>
                                <hr/>
                                <Form
                                    ref={'form'}
                                    options={NewLocationFormOptions}
                                    type={NewLocationFormStruct}
                                    value={this.state.value}
                                    onChange={this.onChange}
                                />
                                <div className="text-right">
                                    <Button type={'primary'} style={{'width':'155px', 'height':'40px','textAlign':'center','background': Color.GREEN}}
                                            message={'Next Step'} disabled={invalidStep1}
                                            onPress={() => this.handleStepChange(1)}/>
                                </div>
                            </div> : null}
                        {this.state.step === 1 ?
                            <div>
                                <h3 style={{'border': '0px'}} className='body-copy-header'>Step 2: Add Location Hours</h3>
                                <hr/>

                                <StoreHours locationHours={this.state.locationHours}
                                            propagateHourChange={(newHours) => this.onChangeStoreHours(newHours)}/>

                                <hr/>
                                <div className="text-right">
                                    <Button style={{'width':'155px', 'height':'40px','textAlign':'center','float': 'left'}} type='warning'
                                            onPress={() => this.handleStepChange(0)} icon='arrow-left'
                                            message='back'/>
                                    <Button type={'primary'} style={{'width':'155px', 'height':'40px','textAlign':'center','background': Color.GREEN}}
                                            message={'Next Step'}
                                            onPress={() => this.handleStepChange(2)}/>
                                </div>
                            </div>
                            : null}
                        {this.state.step === 2 ?
                            <div>
                                <h3 style={{'border': '0px'}} className='body-copy-header'>Step 3: Add Location Manager Information</h3>
                                <hr/>
                                {this.state.requireUsersForNewLocations === false ?
                                    <React.Fragment>
                                        <h5>Worksmith recommends that you add a location manager for your new location.</h5>
                                        <Button type={'primary'} style={{'width':'300px', 'height':'40px','textAlign':'center','background': 'navy', 'marginTop': '15px', 'marginBottom': '20px'}}
                                                message={'Skip adding a Location Manager'}
                                                onPress={() => this.submit(false)}/>
                                    </React.Fragment>
                                    :
                                    null
                                }

                                <h5>Choose the best point of contact for this location.</h5>

                                <hr/>
                                <Form
                                    ref={'formStep3'}
                                    options={userCreateParamsFormOptions}
                                    type={userCreateParamsFormStruct}
                                    value={this.state.userCreateParams}
                                    onSubmit={this.submit}
                                    onChange={this.onChangeLocationNewUser}
                                />
                                <hr/>
                                <div className="text-right">
                                    <Button style={{'width':'155px', 'height':'40px','textAlign':'center','float': 'left'}} type='warning'
                                            onPress={() => this.handleStepChange(1)} icon='arrow-left'
                                            message='back'/>
                                    <Button type={'primary'} style={{'width':'155px', 'height':'40px','textAlign':'center','background': Color.GREEN}}
                                            message={'Save Location'} disabled={invalidStep3}
                                            onPress={() => this.submit(true)}/>
                                </div>
                            </div>
                            : null}
                    </section>
                </div>
            )
        } else {
            return <Loader/>;
        }
    }
}

export default NewLocationPage;