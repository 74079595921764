// This file was generated from com.worksmith.task.controller.ObligationController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ObligationService extends Http {
    constructor() {
        super('/task/obligation')
    }
    
    addAttachments(id, attachmentType, attachments) {
        let params = {
            attachmentType
        };
    
        return this.callApi( `${id}/attachments/multiple`, POST, null, params, null, true, attachments);
    }

    deleteAttachments(attachmentIds) {
        let params = {
            attachmentIds
        };

        return this.callApi( `deleteAttachments`, DELETE, null, params, null, true);
    }

    addChangeRequest(id, changeRequest, muteClientNotifications, muteVendorNotifications, muteResourceNotifications) {
        let params = {
            muteClientNotifications, 
            muteVendorNotifications,
            muteResourceNotifications,
        };

        return this.callApi( `${id}/changeRequests`, POST, changeRequest, params, null, true);
    }
    
    addContestedDescription(createParams) {
        return this.callApi( `addContestedDescription`, POST, createParams, null, null, true);
    }

    countJobs(obligationSearchParams) {
        return this.callApi( `countJobs`, POST, obligationSearchParams, null, null, true);
    }

    bulkEditJobsExport(obligationSearchParams, emailAddress) {
        let params = {
            emailAddress
        };
        return this.callApi( `bulkEditJobsExport`, POST, obligationSearchParams, params, null, true);
    }

    bulkEditJobsImport(attachments) {
        return this.callApi( `bulkEditJobsImport`, POST, null, null, null, true, attachments);
    }

    getJobCompletionDownload(obligationId) {
        return this.callApi( `${obligationId}/getJobCompletionDownload`, GET, null, null, null, true, null, 'arraybuffer');
    }

    addItemGroup(id, obligationItemGroup) {
        return this.callApi( `${id}/itemGroups`, PATCH, obligationItemGroup, null, null, true);
    }
    
    adjustItemizedPricing(params) {
        return this.callApi( `adjustItemizedPricing`, POST, params, null, null, true);
    }
    
    canAdjustPricing(obligationId) {
        return this.callApi( `${obligationId}/canAdjustPricing`, GET, null, null, null, true);
    }
    
    cancelObligation(id, cancellationDetails, muteClientNotifications, muteVendorNotifications, muteResourceNotifications) {
        let params = {
            muteClientNotifications, 
            muteVendorNotifications,
            muteResourceNotifications
        };
    
        return this.callApi( `${id}/cancel`, DELETE, cancellationDetails, params, null, true);
    }

    complete(params, completionPhotos) {
        return this.callApi(`complete`, POST, params, null, null, true, completionPhotos);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteAttachment(id, attachmentId) {
        return this.callApi( `${id}/attachments/${attachmentId}`, DELETE, null, null, null, true);
    }
    
    find(params) {
        return this.callApi( `find`, POST, params, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findNextServiceDate(clientLocationId, serviceLineId, vendorLocationId) {
        let params = {
            clientLocationId, 
            serviceLineId, 
            vendorLocationId
        };
    
        return this.callApi( `findNextServiceDate`, GET, null, params, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getAttachments(id) {
        return this.callApi( `${id}/attachments`, GET, null, null, null, true);
    }
    
    getCancellationReasons() {
        return this.callApi( `getCancellationReasons`, GET, null, null, null, true);
    }
    
    getClientJobDetails(obligationId) {
        return this.callApi( `getClientJobDetails/${obligationId}`, GET, null, null, null, true);
    }
    
    getCompleted(vendorId, startDate, endDate) {
        let params = {
            startDate, 
            endDate
        };
    
        return this.callApi( `${vendorId}/completed`, GET, null, params, null, true);
    }
    
    getDatesWithJobs(startDate, endDate, vendorId, clientId, clientLocationIds, excludeTicketing) {
        let params = {
            startDate, 
            endDate, 
            vendorId, 
            clientId, 
            clientLocationIds, 
            excludeTicketing
        };
    
        return this.callApi( `getDatesWithJobs`, GET, null, params, null, true);
    }
    
    getFullVendor(id) {
        return this.callApi( `${id}/fullVendor`, GET, null, null, null, true);
    }
    
    getJobCardSummaries(params) {
        return this.callApi( `getJobCardSummaries`, POST, params, null, null, true);
    }
    
    getJobCounts(date, excludeTicketing) {
        let params = {
            date, 
            excludeTicketing
        };
    
        return this.callApi( `getJobCounts`, GET, null, params, null, true);
    }
    
    getJobDates(startDate, endDate) {
        let params = {
            startDate, 
            endDate
        };
    
        return this.callApi( `getJobDates`, GET, null, params, null, true);
    }
    
    getJobDetails(vendorId, obligationId) {
        let params = {
            obligationId
        };
    
        return this.callApi( `${vendorId}/jobDetails`, GET, null, params, null, true);
    }
    
    getObligationVendorSummaries(params) {
        return this.callApi( `getObligationVendorSummaries`, POST, params, null, null, true);
    }
    
    getPastDueJobs(vendorId) {
        return this.callApi( `${vendorId}/pastDueJobs`, GET, null, null, null, true);
    }
    
    getPricingAdjustmentType(obligationId) {
        return this.callApi( `${obligationId}/getPricingAdjustmentType`, GET, null, null, null, true);
    }
    
    getPricingSummary(id) {
        return this.callApi( `${id}/pricingSummary`, GET, null, null, null, true);
    }
    
    getStatuses() {
        return this.callApi( `statuses`, GET, null, null, null, true);
    }
    
    getSummary(id) {
        return this.callApi( `${id}/summary`, GET, null, null, null, true);
    }
    
    getVendorObligationsToScheduleCount(vendorId) {
        return this.callApi( `${vendorId}/getVendorObligationsToScheduleCount`, GET, null, null, null, true);
    }
    
    getVendorPastDueObligationCount(vendorId) {
        return this.callApi( `${vendorId}/getVendorPastDueObligationCount`, GET, null, null, null, true);
    }
    
    getVendorScheduledJobDetailsCards(params) {
        return this.callApi( `getVendorScheduledJobDetailsCards`, POST, params, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }

    resolveConcern(concernId, resolveConcernParams) {
        return this.callApi(`${concernId}/resolveConcern`, POST, resolveConcernParams, null, null, true);
    }

    unconfirmJob(jobId){
        return this.callApi(`${jobId}/aggregatorUnconfirmCompletion`, POST, null, null, null, true);
    }

    confirmJob(jobId){
        return this.callApi(`${jobId}/aggregatorConfirmCompletion`, POST, null, null, null, true);
    }
    
    summaries(params) {
        return this.callApi( `summaries`, POST, params, null, null, true);
    }
    
    swapObligation(obligationId, swapReasonId, newVendorLocationId, items) {
        return this.callApi( `${obligationId}/swap/${newVendorLocationId}/${swapReasonId}`, POST, items, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    upsertMetadata(id, metadata) {
        return this.callApi( `${id}/metadata`, PATCH, metadata, null, null, true);
    }

    updateWorkOrderNumber(obligationId, workOrder) {
        let params = {workOrderNumber: workOrder};
        return this.callApi( `${obligationId}/updateWorkOrderNumber`, PATCH, null, params, null, true);
    }

    fphManifestCSV(fphManifestCsvCreateParams) {
        return this.callApi( `fphManifestCSV`, POST, fphManifestCsvCreateParams, null , null, true);
    }
    todaysScheduleCSV(obligationSearchParams, emailAddress, localTimeZone, sendHoursWorkedSummary, sendAllJobsView) {
        let params = {
            emailAddress,
            localTimeZone,
            sendHoursWorkedSummary,
            sendAllJobsView
        };

        return this.callApi( `todaysScheduleCSV`, POST, obligationSearchParams, params , null, true);
    }

    updateRequiredParts(businessObject) {
        return this.callApi( `updateRequiredParts`, POST, businessObject, null, null, true);
    }

}

export default ObligationService;
