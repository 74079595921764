import React from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import Text, {LinkUnderline, TextColor, TextVariant} from "../../../../components/Text/Text.web";
import Schedule from "../../../../components/Schedule/Schedule.web";
import Grid from "../../../../components/Grid/Grid.web";
import UserType from "../../../../enums/api/user/UserType";
import {getReschedulingSubStatusFromCreatorType} from "./ReschedulingView";
import {Display} from "worksmith/enums/CSSEnums";

const ReschedulingClientSubmittedAvailability = (props) => {
    const {creatorType, loading, schedule, scheduleHandler, showCancelChangeRequestModal} = props;

    return (
        <RequestStateLayout
            description={creatorType === UserType.CLIENT ?
                 <Text variant={TextVariant.BODY_1}>
                     {`You or another user requested to reschedule this job. You will be notified when the vendor responds. No longer need to reschedule? Click here to `}
                    <Text
                        link
                        onClick={showCancelChangeRequestModal}
                        display={Display.INLINE}
                        variant={TextVariant.INHERIT}
                        underline={LinkUnderline.ALWAYS}
                        semiBold
                        color={TextColor.SECONDARY}>
                        {`keep your current time.`}
                    </Text>
                </Text>
                :
                "You or another user proposed a different time. You will be notified when the vendor responds."
            }
            loading={loading}
            body={
                <Grid container spacing={3}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Text variant={TextVariant.BODY_1} color={TextColor.PRIMARY} semiBold>
                                Currently Scheduled For:
                            </Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Schedule
                                textVariant={TextVariant.BODY_2}
                                schedule={schedule}
                            />
                        </Grid>
                    </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Text variant={TextVariant.BODY_1} color={TextColor.SECONDARY} semiBold>
                            Requested to Reschedule For:
                        </Text>
                    </Grid>
                    {
                        scheduleHandler.openScheduleOptions.map(scheduleOption => {
                            return (
                                <Grid item key={scheduleOption.id} xs={12}>
                                    <Schedule
                                        textColor={TextColor.SECONDARY}
                                        textVariant={TextVariant.BODY_2}
                                        schedule={scheduleOption.schedule}/>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                </Grid>
            }
            status={'Rescheduling - ' + getReschedulingSubStatusFromCreatorType(creatorType)}
            statusColor={TextColor.PRIMARY}/>
    )
};

ReschedulingClientSubmittedAvailability.propTypes = {
    creatorType: PropTypes.oneOf([
        UserType.CLIENT,
        UserType.VENDOR,
    ]),
    loading: PropTypes.bool,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    scheduleHandler: PropTypes.shape({
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        })),
    }),
    showCancelChangeRequestModal: PropTypes.func.isRequired,
};

export default ReschedulingClientSubmittedAvailability;