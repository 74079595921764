import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LineItemFieldsGroupView from "worksmith/components/LineItem/LineItemForm/LineItemFieldsGroupView";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const newLineItem = {
    description:         '',
    excludeRevenueShare: false,
    lineItemType:        null,
    quantity:            1,
    unitRetailPrice:     null,
    unitWholesalePrice:  0.00
};

class LineItemFieldsGroup extends Component {
    constructor(props) {
        super(props);

        const additionalApproval = props.allowAdditionalApproval ?
            ValueIsSet(props.initialAdditionalApproval) ?
                Number(props.initialAdditionalApproval)
                : 100.00
            :
            0.00;

        this.state = {
            additionalApproval:  additionalApproval,
            lineItems:           props.initialLineItems,
            totalRetailPrice:    props.initialLineItems.reduce((total, lineItem) => {
                                    return total + (lineItem.unitRetailPrice * lineItem.quantity);
                                 }, 0),
            totalWholesalePrice: props.initialLineItems.reduce((total, lineItem) => {
                                    return total + (Number(lineItem.unitWholesalePrice).toFixed(2) * lineItem.quantity);
                                 }, 0)
        };

        this.lineItems = DeepCopy(props.initialLineItems);
    }

    calculateTotals = () => {
        const {lineItems} = this;
        this.setState({
            totalRetailPrice:    lineItems.reduce((total, lineItem) => {
                return total + (lineItem.unitRetailPrice * lineItem.quantity);
            }, 0),
            totalWholesalePrice: lineItems.reduce((total, lineItem) => {
                return total + (Number(lineItem.unitWholesalePrice).toFixed(2) * lineItem.quantity);
            }, 0)
        });
    };

    onAdd = () => {
        let {lineItems} = this;
        const {lineItemTypes, onLineItemsChange} = this.props;

        newLineItem.lineItemType = lineItemTypes[0];

        lineItems.push(DeepCopy(newLineItem));
        this.setState({lineItems});
        onLineItemsChange(lineItems);
    };

    onAdditionalApprovalChange = (additionalApproval) => {
        const {onAdditionalApprovalChange} = this.props;
        this.setState({additionalApproval});
        onAdditionalApprovalChange(additionalApproval);
    };

    onLineItemChange = (index, lineItem) => {
        let {calculateTotals, lineItems} = this;
        const {onLineItemsChange} = this.props;

        if(lineItem.unitRetailPrice !== lineItems[index].unitRetailPrice
            || lineItem.unitWholesalePrice !== lineItems[index].unitWholesalePrice
            || lineItem.quantity !== lineItems[index].quantity){
            lineItems[index] = DeepCopy(lineItem);
            calculateTotals();
        } else {
            lineItems[index] = DeepCopy(lineItem);
        }

        onLineItemsChange(lineItems);
    };

    onRemove = (index) => {
        let {calculateTotals, lineItems} = this;
        const {onLineItemsChange} = this.props;

        if(lineItems.length <= 1)
            return;

        lineItems.splice(index, 1);
        calculateTotals();
        this.setState({lineItems});
        onLineItemsChange(lineItems);
    };

    render() {
        const {
            onAdd,
            onAdditionalApprovalChange,
            onLineItemChange,
            onRemove
        } = this;
        const {
            additionalApproval,
            lineItems,
            totalRetailPrice,
            totalWholesalePrice} = this.state;
        const {
            allowAdditionalApproval,
            effectiveWholesaleRate,
            errors,
            lineItemTypes,
            portalType,
        } = this.props;

        return (
            <LineItemFieldsGroupView additionalApproval={additionalApproval}
                                     allowAdditionalApproval={allowAdditionalApproval}
                                     effectiveWholesaleRate={effectiveWholesaleRate}
                                     errors={errors}
                                     lineItemTypes={lineItemTypes}
                                     initialLineItems={lineItems}
                                     onAdd={onAdd}
                                     onAdditionalApprovalChange={onAdditionalApprovalChange}
                                     onLineItemChange={onLineItemChange}
                                     onRemove={onRemove}
                                     portalType={portalType}
                                     totalRetailPrice={totalRetailPrice}
                                     totalWholesalePrice={totalWholesalePrice}/>
        )
    }
}

LineItemFieldsGroup.propTypes = {
    allowAdditionalApproval:    PropTypes.bool,
    effectiveWholesaleRate:     PropTypes.number.isRequired,
    errors:                     PropTypes.arrayOf(
                                    PropTypes.shape({
                                        description:     PropTypes.bool,
                                        quantity:        PropTypes.bool,
                                        unitRetailPrice: PropTypes.bool
                                    })
                                ),
    initialAdditionalApproval:  CustomPropTypes.stringOrNumber,
    initialLineItems:           PropTypes.arrayOf(PropTypes.object),
    lineItemTypes:              PropTypes.arrayOf(PropTypes.shape({
                                    id:   PropTypes.number.isRequired,
                                    name: PropTypes.string.isRequired
                                })).isRequired,
    onAdditionalApprovalChange: PropTypes.func,
    onLineItemsChange:          PropTypes.func.isRequired,
    portalType: CustomPropTypes.enum(PortalType),
};

LineItemFieldsGroup.defaultProps = {
    allowAdditionalApproval: false
};

export default LineItemFieldsGroup;