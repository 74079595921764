import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ContractItemScopeOfWork from "worksmith/components/Contract/ContractItemScopeOfWork";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import Loader from "../Loader/Loader";


const ContractScopeOfWorkContainer = styled.div`
    min-height:200px;
    width: 80vmin;
`;


const ContractScopeOfWorkView = (props) => {
    let { locationTitle, serviceLineName, rateCardEntries, canEdit, loading } = props;

    if (loading) {
        return (<Loader/>)
    } else {
        return (
            <Fragment>
                <ContractScopeOfWorkContainer>
                    <Text color={TextColor.TEXT_PRIMARY} variant={TextVariant.H6}>{locationTitle}</Text>
                    <Text color={TextColor.TEXT_PRIMARY} variant={TextVariant.SUBTITLE_2}>{serviceLineName} - scope of
                        work</Text>
                    <br/>
                    {
                        rateCardEntries.map((item) => {
                            return (
                                <ContractItemScopeOfWork key={item.id} rateCardEntry={item}
                                                         canEdit={canEdit}/>
                            )
                        })
                    }
                </ContractScopeOfWorkContainer>
            </Fragment>
        )
    }

};

ContractScopeOfWorkView.propTypes = {
    locationTitle:           PropTypes.string,
    serviceLineName:         PropTypes.string,
    rateCardEntries:         PropTypes.arrayOf(PropTypes.shape({
        id:                  PropTypes.number.isRequired,
        itemName:            PropTypes.string,
        scopeOfWork:         PropTypes.string
    })).isRequired,
    canEdit:                 PropTypes.bool
};

export default ContractScopeOfWorkView;
