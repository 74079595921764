import React, {Component} from 'react';
import ClientService from 'worksmith/services/api/ClientService';
import UserService from 'worksmith/services/api/UserService';
import notification from 'antd/lib/notification';
import Button from "../Button";
import Popconfirm from 'antd/lib/popconfirm';
import AssociateUserToLocationForm from "./AssociateUserToLocationForm";
import {Email} from "worksmith/helpers/CustomTcombTypes";
import {ErrorText} from "../styledComponents/Basic";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {CountryCodeWithAbbreviation} from "worksmith/enums/api/country/CountryCodeWithAbbreviation";

const userService = new UserService();
const clientService = new ClientService();

const t = require('tcomb-form');
const Form = t.form.Form;



class EditUserForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            baseUser: props.user, // setting original user to compare against
            user: props.user,
            locations: {},
            value: props.user,
            disabled: true,
            isSelfService: ValueIsSet(props.isSelfService) ? props.isSelfService : false
        };

        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount(){}

    onChange(value){
        let {baseUser} = this.state;

        if(value.username  !== baseUser.username  ||
           value.firstName !== baseUser.firstName ||
           value.lastName  !== baseUser.lastName  ||
           value.phone     !== baseUser.phone     ||
           value.email     !== baseUser.email     ||
           value.countryCode !== baseUser.countryCode){

            this.setState({ disabled: false });

        } else if(value.username === baseUser.username  &&
                 value.firstName === baseUser.firstName &&
                 value.lastName  === baseUser.lastName  &&
                 value.phone  === baseUser.phone        &&
                 value.email     === baseUser.email     &&
                 value.countryCode === baseUser.countryCode){

            this.setState({ disabled: true });
        }

        this.setState({value})
    }

    onDelete(){
        let {user} = this.state;
        let {history} = this.props;

        userService.delete(user.id).then(function(){
            history.push(`/users`);
        })
    }

    submit(){
        let {value, user} = this.state;
        let _this = this;
        let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;


        if (this.refs.form.validate().isValid()) {

            clientService.updateUserForClient(clientId, user.id, value).then(function (data) {
                _this.props.onSuccess();
                notification['success']({
                    message: 'User updated!'
                })
            })
        }
    }

    render(){
        let {user, isSelfService} = this.state;

        let EditUserStruct = t.struct({
            username: t.String,
            firstName: t.String,
            lastName: t.String,
            ...(isSelfService) && {countryCode: t.maybe(t.enums(CountryCodeWithAbbreviation))},
            phone: t.maybe(t.String),
            email: Email,
        });
        let formOptions = {
            fields: {
                username: {
                    error: <ErrorText>Required Field</ErrorText>
                },
                firstName: {
                    error: <ErrorText>Required Field</ErrorText>
                },
                lastName: {
                    error: <ErrorText>Required Field</ErrorText>
                },
            }
        };


        return(
            <div>
                {ValueIsSet(user.clientLocationRoles) ?
                    <div><h4> Edit Locations </h4>
                        <AssociateUserToLocationForm user={user}/>
                        <hr/>
                    </div>
                    :
                    null
                }
                <h4> Edit User Information </h4>
                <Form
                    ref="form"
                    type={EditUserStruct}
                    value={this.state.value}
                    onChange={(value) => this.onChange(value)}
                    options={formOptions}
                />
                <div className="text-right">
                    <Button type={'success'} message={'Submit Edits'} disabled={this.state.disabled} onPress={() => this.submit()} />
                </div>
                <hr/>
                <div className="text-left">
                    <Popconfirm placement="top" title={'Are you sure you want to delete this user?'}
                                onConfirm={() => this.onDelete()} okText="Yes" cancelText="No">
                        <span className="ws-btn ws-btn-danger full-width-sm text-center" style={{display: 'inline-block'}}>
                            Delete User
                        </span>
                    </Popconfirm>
                </div>
            </div>
        )
    }

}

export default EditUserForm;