// This file was generated from com.worksmith.cr.controller.ChangeRequestController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ChangeRequestService extends Http {
    constructor() {
        super('/changeRequests')
    }
    
    accept(id) {
        return this.callApi( `${id}/accept`, PATCH, null, null, null, true);
    }
    
    addComment(id, changeRequestComment) {
        return this.callApi( `${id}/comments`, POST, changeRequestComment, null, null, true);
    }
    
    cancel(id) {
        return this.callApi( `${id}/cancel`, DELETE, null, null, null, true);
    }
    
    counterChangeRequest(id, changeRequest) {
        return this.callApi( `${id}/counter`, PATCH, changeRequest, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findSummaries(type, status) {
        let params = {
            type, 
            status
        };
    
        return this.callApi( `summaries`, GET, null, params, null, true);
    }
    
    getAssignmentIdByCommonId(commonId) {
        return this.callApi( `getAssignmentIdByCommonId/${commonId}`, GET, null, null, null, true);
    }
    
    getClientChangeRequestFullSummary(id) {
        return this.callApi( `${id}/clientChangeRequestFullSummary`, GET, null, null, null, true);
    }
    
    getComments(id) {
        return this.callApi( `${id}/comments`, GET, null, null, null, true);
    }
    
    getFullDetails(id) {
        return this.callApi( `${id}/fullDetails`, GET, null, null, null, true);
    }
    
    getOpenChangeRequests(associatedWithCampaign) {
        let params = {
            associatedWithCampaign
        };
    
        return this.callApi( `getOpenChangeRequests`, GET, null, params, null, true);
    }
    
    getOpenVendorChangeRequests() {
        return this.callApi( `getOpenVendorChangeRequests`, GET, null, null, null, true);
    }
    
    getUserChangeRequestStats(userId) {
        let params = {
            userId
        };
    
        return this.callApi( `marketplaceOperationsStats`, GET, null, params, null, true);
    }
    
    getVendorChangeRequestSummary(id) {
        return this.callApi( `${id}/vendorChangeRequestSummary`, GET, null, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default ChangeRequestService;
