import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import AuthTokenManager from "worksmith/services/utilities/AuthTokenManager";
import Autocomplete from "worksmith/components/Inputs/Autocomplete/Autocomplete";
import Box from "@mui/material/Box";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Checkbox from "worksmith/components/Checkbox/Checkbox";
import DateRangePicker from "worksmith/components/DateRangePicker/DateRangePicker";
import FilterChip from "worksmith/components/FilterChip/FilterChip";
import Grid from 'worksmith/components/Grid/Grid';
import Icon from 'worksmith/components/Icon/Icon';
import JobCompletionDetailsDialog from "worksmith/composite-components/JobCompletionDetails/JobCompletionDetailsDialog";
import JobDetailsSidePanel from "worksmith/composite-components/JobDetails/JobDetailsSidePanel";
import Multiselect, {MultiselectType} from "worksmith/components/Inputs/Multiselect/Multiselect";
import ObligationStatus from "../../shared/enums/api/task/ObligationStatus";
import Select, {SelectType} from 'worksmith/components/Inputs/Select/Select';
import Table from "worksmith/components/Table/Table";
import Text, {LinkUnderline, TextColor, TextVariant} from 'worksmith/components/Text/Text';
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import UserService from "worksmith/services/api/UserService";
import UserType from "worksmith/enums/api/user/UserType";
import {AlignItems} from 'worksmith/enums/CSSEnums';
import {Color} from 'worksmith/enums/Color';
import {GraphQLObjectType} from 'worksmith/enums/GraphQLObjectType';
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";
import {IconType} from "worksmith/enums/MaterialEnums";
import {MomentFormat} from 'worksmith/enums/MomentFormat';
import {RequestType} from "../../Enums";
import {TextFieldVariant} from "worksmith/components/Inputs/TextField/TextField";
import {Uppercase} from "worksmith/helpers/LanguageHelper";
import {ValueIsSet} from "../../Helpers";
import {useDeviceSize} from "worksmith/helpers/StylingHelpers";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import {WithContext} from "../../context/GlobalContext";

const graphQLService = new AsyncGraphQLServiceClass();
const userService = new UserService();
const authTokenManager = new AuthTokenManager();


const StyledFilter = styled(Grid)`
  opacity: ${({loading}) => loading ? 0.5 : 1};
  legend {
    border: none;
  }
`;

const CheckMark = styled(Icon)`
    margin-left: 10px !important;
    margin-bottom: -0.25em;
`;

const HorizontalLine = styled.hr`
   border-top-color: ${Color.BLACK} !important;
   border-top-width: thin !important;
   margin-top: 40px;
   margin-bottom: 40px;
   margin-right: -9px;
   margin-left: -9px;
`;

const StyledTitleText = styled(Text)`
    font-weight: bold !important;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const StyledGrid = styled(Grid)`
    margin: 0 0 0 28px;
`;

let columns = [
    {
        title: 'Service',
        cellValueFunction: (data) => {
            return (
                <Grid container>
                    {ValueIsSet(data.serviceIcon) ?
                        data.serviceIcon
                        :
                        null}
                    <Grid>
                        <Text gutterBottom>{data.clientLocation.title}</Text>
                    </Grid>
                    {ValueIsSet(data.request) ?
                        <Grid xs={12}>
                            <Text>{"#" + data.request.id + " - " + data.serviceLine.name}</Text>
                        </Grid>
                        :
                        null}
                    <Grid xs={12}>
                        <Text color={TextColor.TEXT_SECONDARY}>{data.serviceSubtext}</Text>
                    </Grid>
                </Grid>);
        },
        cellProps: {
            style: {
                width: '320px',
                minWidth: '260px'
            }
        }
    },
    {
        title: 'Scheduled Date',
        cellValueFunction: (data) => {
            return (
                <Grid container>
                    <Grid xs={12}>
                        <Text gutterBottom>{moment(data.schedule.date).format(MomentFormat.FullDow)}</Text>
                    </Grid>
                    <Grid xs={12}>
                        <Text>{moment(data.schedule.date).format(MomentFormat.MonthDayYearSlash)}</Text>
                    </Grid>
                    <Grid xs={12}>
                        <Text color={TextColor.TEXT_SECONDARY}>{moment(data.schedule.arrivalStartTime, MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)
                        + " - " +
                        moment(data.schedule.arrivalEndTime, MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)}
                        </Text>
                    </Grid>
                </Grid>);
        },
        cellProps: {
            style: {
                maxWidth: '200px',
                minWidth: '170px'
            }
        }
    },
    {
        title: 'Status',
        cellValueFunction: (data) => {
            return (
                <Grid container xs={12} spacing={1}>
                    <Grid item xs={12}>
                        {data.statusColumn}
                    </Grid>
                </Grid>);
        },
        cellProps: {
            style: {
                maxWidth: '160px',
                minWidth: '155px'
            }
        }
    }
];

const JOBS_PER_PAGE = 5;

const JobTypesFilter = { // this is the same as regular RfpStatus but instead of pending regional/admin approval, we have pending approval
    // and instead of needs client input and pending regional/admin bid approval, we have awaiting price approval
    RECURRING: "Recurring",
    ONE_TIME: 'One time',
};

const STATUS_OPTIONS = {
    SCHEDULED: "SCHEDULED",
    OPEN_CHANGE_ORDER: "HAS_OPEN_CHANGE_ORDER",
    RESCHEDULING: "RESCHEDULING",
    COMPLETED: "COMPLETED",
    HAS_OPEN_CONCERN: "HAS_OPEN_CONCERN",
    AWAITING_SIGN_OFF: "AWAITING_CLIENT_SIGNOFF"
};

const SCHEDULED_RADIO_BUTTONS = [
    {
        label: 'All scheduled',
        value: STATUS_OPTIONS.SCHEDULED
    },
    {
        label: 'Open change orders only',
        value: STATUS_OPTIONS.OPEN_CHANGE_ORDER
    }
];

const COMPLETED_RADIO_BUTTONS = [
    {
        label: 'All completed',
        value: STATUS_OPTIONS.COMPLETED
    },
    {
        label: 'Open concerns only',
        value: STATUS_OPTIONS.HAS_OPEN_CONCERN
    },
    {
        label: 'Awaiting sign off only',
        value: STATUS_OPTIONS.AWAITING_SIGN_OFF
    }
];

const INITIAL_STATUSES_OPTIONS = [
    STATUS_OPTIONS.SCHEDULED,
    STATUS_OPTIONS.COMPLETED,
    STATUS_OPTIONS.RESCHEDULING
];

const CalendarListView = ({
                              context,
                              openChangeOrderFilter,
                              disableScheduleFilter,
                              disableCompletedFilter,
                              disableRescheduleFilter,
                              initialEndDate,
                              initialStartDate,
                              initialCompletedSelected,
                              nullDateFilter,
                              clientId,
                              hideFilters,
                              obligationTemplateId,
                              status,
                              sortDesc,
                              title,
                              user,
                              showFrequentJobsFilter,
                              initialFrequentJobsFiltered
                          }) => {

    const [isMobile] = useDeviceSize();
    const [loading, setLoading] = useState(true);
    const [resetting, setResetting] = useState(false);

    // these are the service lines and locations to populate autocompletes
    const [serviceLines, setServiceLines] = useState(null);
    const [clientLocations, setClientLocations] = useState(null);

    // variables for the table of data
    const [listEvents, setListEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalElements ,setTotalElements] = useState(0);

    // variables for side panel
    const [selectedService, setSelectedService] = useState(null);
    const [showJobDetails, setShowJobDetails] = useState(false);

    const [showCompleteJobModal, setShowCompleteJobModal] = useState(false);

    // these are used for checkboxes on the advanced filters button on tab 1 of calendar list view.
    const [isScheduleChecked, setIsScheduleChecked] = useState(!disableScheduleFilter);
    const [isCompletedChecked, setIsCompletedChecked] = useState(!disableCompletedFilter);
    const [isRescheduledChecked, setIsRescheduledChecked] = useState(!disableRescheduleFilter);
    const filterChipRef = useRef(null);
    const typeRef = useRef(null);
    const clientLocationsSelectRef = useRef(null);
    const serviceLinesSelectRef = useRef(null);
    const dateRangeRef = useRef(null);

    // filter params
    const [completedFilterSelected, setCompletedFilterSelected] = useState(ValueIsSet(initialCompletedSelected) ? initialCompletedSelected : STATUS_OPTIONS.COMPLETED);
    const [scheduleFilterSelected, setScheduleFilterSelected] = useState(STATUS_OPTIONS.SCHEDULED);
    const [listViewStartDay, setListViewStartDay] = useState(ValueIsSet(nullDateFilter) ? null : initialStartDate);
    const [listViewEndDay, setListViewEndDay] = useState(ValueIsSet(nullDateFilter) ? null : initialEndDate);
    const [types, setTypes] = useState([]);
    const [selectedClientLocations, setSelectedClientLocations] = useState(null);
    const [selectedServiceLines, setSelectedServiceLines] = useState(null);
    const [numberOfActiveFilters, setNumberOfActiveFilters] = useState(INITIAL_STATUSES_OPTIONS.length);
    const [hideFrequentRecurringJobs, setHideFrequentRecurringJobs] = useState(initialFrequentJobsFiltered);

    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;

    const fetchClientServiceLines = async () => {
        try {
            let serviceLineList = [];
            const serviceLineData = await graphQLService.findOneById(
                clientId,
                GraphQLObjectType.CLIENT,
                ServiceLineFields
            );

            serviceLineData.obligationServiceLines.forEach(serviceLine => {
                return serviceLineList.push({
                    name: serviceLine.name,
                    id: serviceLine.id
                })
            });
            setServiceLines(serviceLineList)
        } catch(error) {
            console.error(error);
            await DisplayErrorNotification("Error fetching service lines");
        }
    };

    const fetchLocations = async () => {
        try {
            let locationList = [];
            const locationData = await userService.getClientLocationsForUser(authTokenManager.getUserId());
            locationData.forEach((location) => {
                return locationList.push({
                    id: location.id,
                    title: location.title
                })
            });
            setClientLocations(locationList);
        } catch(error) {
            console.error(error);
            await DisplayErrorNotification("Error fetching locations");
        }

    };

    const handleChangePage = (e, pageNumber) => {
        if(showJobDetails) {
            setShowJobDetails(false)
        }
        fetchJobs(pageNumber);
    };

    const handleDateRangeChange = (startDate, endDate) => {
        setListViewStartDay(startDate);
        setListViewEndDay(endDate);
    };

    const fetchJobs = async (page) => {
        setLoading(true);
        if(showJobDetails){
            setShowJobDetails(false)
        }

        let params = getSearchParamFilters();

        try {
            const { content, currentPage, totalElements } = await graphQLService.findPage(
                page,
                JOBS_PER_PAGE,
                'obligationDate',
                sortDesc ? GraphQLSortDirection.DESCENDING : GraphQLSortDirection.ASCENDING,
                params,
                GraphQLObjectType.OBLIGATION,
                ObligationListFields
            );

            if (content) {
                const updatedData = content.map((item)=> {
                    item.serviceSubtext = "";
                    if(ValueIsSet(item.request) && item.request.emergency){
                        if(item.isWalkthrough){
                            item.serviceSubtext = "Emergency Walkthrough"
                        }
                        else{
                            item.serviceSubtext = "Emergency"
                        }
                    }
                    else if(ValueIsSet(item.request) && item.request.type === RequestType.RECURRING &&
                        ValueIsSet(item.obligationTemplate) && ValueIsSet(item.obligationTemplate.recurringScheduleTemplate.frequency.readableString)){
                        item.serviceSubtext = item.obligationTemplate.recurringScheduleTemplate.frequency.readableString
                    }
                    else if(ValueIsSet(item.request) && item.request.type === RequestType.ON_DEMAND){
                        if(item.isWalkthrough){
                            item.serviceSubtext = "Walkthrough"
                        }
                        else{
                            item.serviceSubtext = "One Time";
                        }
                    }

                    if(item.isRescheduling){
                        item.statusColumn =
                            <Grid container>
                                <Tooltip title={"Rescheduling"}><Icon iconColor={Color.VENDOR_GREY} name={IconType.UPDATE}/></Tooltip>
                                <Grid item>
                                    {item.obligationAttachments.length > 0 ? <Icon iconColor={Color.VENDOR_GREY} name={"AttachFileRounded"}/> : null}
                                </Grid>
                            </Grid>
                    }
                    else if(item.status === ObligationStatus.COMPLETED){
                        item.statusColumn = <Grid container>
                            <Grid item>
                                <Text>Completed</Text>
                            </Grid>
                            <Grid item>
                                <CheckMark secondary name={IconType.CHECK}/>
                            </Grid>
                            <Grid item>
                                <Text onClick={() => setShowCompleteJobModal(true)} underline={LinkUnderline.ALWAYS} link>
                                    View Report
                                </Text>
                            </Grid>
                            <Grid item>
                                {item.obligationAttachments.length > 0 ? <Icon iconColor={Color.VENDOR_GREY} name={"AttachFileRounded"}/> : null}
                            </Grid>
                        </Grid>
                    }
                    else{
                        item.statusColumn =
                            <Grid container>
                                <Tooltip title={"Scheduled"}><Icon iconColor={Color.VENDOR_GREY} name={"Event"}/></Tooltip>
                                <Grid item>
                                    {item.obligationAttachments.length > 0 ? <Icon iconColor={Color.VENDOR_GREY} name={"AttachFileRounded"}/> : null}
                                </Grid>
                            </Grid>
                    }


                    if(item.hasPendingChangeOrderRequest){
                        item.serviceIcon = <Tooltip title={"Open Change Order"}><Icon iconColor={Color.SECONDARY} name={IconType.HOURGLASS_EMPTY}/></Tooltip>
                    }
                    else if(item.client.storeSignOffEnabled && item.awaitingClientSignOff){
                        item.serviceIcon = <Tooltip title={"Awaiting Sign Off"}><Icon iconColor={Color.VENDOR_GREY} name={"AssignmentOutlined"}/></Tooltip>
                    }
                    else if(item.hasOpenConcern){
                        item.serviceIcon = <Tooltip title={"Concern Raised"}><Icon iconColor={Color.RED} name={"ErrorOutline"}/></Tooltip>
                    }
                    else if(item.confirmed){
                        item.serviceIcon = <Tooltip title={"Sign Off Complete"}><Icon iconColor={Color.BLACK} name={"AssignmentTurnedInRounded"}/></Tooltip>
                    }

                    return item;
                });
                setListEvents(updatedData);
                setCurrentPage(currentPage + 1);
                setTotalElements(totalElements);
            }
        } catch(error) {
            console.error(error);
            await DisplayErrorNotification("Error fetching data");
        }
        setLoading(false);
        if(resetting)
            setResetting(false);
    };

    const getSearchParamFilters = () => {

        let statuses = [];
        if(isScheduleChecked){
            statuses.push(scheduleFilterSelected);
        }
        if(isRescheduledChecked){
            statuses.push(STATUS_OPTIONS.RESCHEDULING);
        }
        if(isCompletedChecked){
            statuses.push(completedFilterSelected);
        }

        // if specific client locations are not selected then filter by locations that this user has access to
        let clientLocationIdList = null;
        if(ValueIsSet(selectedClientLocations) && selectedClientLocations.length > 0){
            clientLocationIdList = selectedClientLocations.map(location => location.id)
        }
        else if(user != null && user.clientLocationRoles && user.clientLocationRoles.length > 0){
            clientLocationIdList = user.clientLocationRoles.map((roles) => {
                return roles.clientLocation.id;
            });
        }

        let serviceLineIds = ValueIsSet(selectedServiceLines) && selectedServiceLines.length > 0 ? selectedServiceLines.map(sl => sl.id) : null;

        // only include/exclude recurring if only 1 option is selected.
        let isRecurring = null;
        if(ValueIsSet(types) && types.length > 0  && !(types.includes(JobTypesFilter.ONE_TIME) === types.includes(JobTypesFilter.RECURRING))) {
            isRecurring = !types.includes(JobTypesFilter.ONE_TIME);
        }

        return {
            clientId,
            startDate: listViewStartDay,
            endDate: listViewEndDay,
            clientLocationIds: clientLocationIdList,
            obligationStatuses: statuses.length > 0 ? statuses : null,
            serviceLineIds: serviceLineIds,
            isRecurring: isRecurring,
            isFrequent: hideFrequentRecurringJobs ? !hideFrequentRecurringJobs : null,
            // these three params are only used on recurring service page
            hasOpenChangeOrder: ValueIsSet(openChangeOrderFilter) ? openChangeOrderFilter : null,
            obligationTemplateId: ValueIsSet(obligationTemplateId) ? obligationTemplateId : null,
            status: ValueIsSet(status) ? status : null
        };
    };

    const getNumberOfActiveFilters = () => {
        let filtersCount = 0;
        if(ValueIsSet(selectedClientLocations))
            filtersCount = filtersCount + selectedClientLocations.length;
        if(ValueIsSet(selectedServiceLines))
            filtersCount = filtersCount + selectedServiceLines.length;
        if(ValueIsSet(types) && types.length > 0)
            filtersCount = filtersCount + 1;
        if(isScheduleChecked)
            filtersCount = filtersCount + 1;
        if(isRescheduledChecked)
            filtersCount = filtersCount + 1;
        if(isCompletedChecked)
            filtersCount = filtersCount + 1;
        return filtersCount;
    };

    const onShowResultsClicked = () => {
        setLoading(true);
        fetchJobs(0);
        setNumberOfActiveFilters(getNumberOfActiveFilters());
    };

    const onClear = () => {
        setResetting(true);

        if(ValueIsSet(typeRef.current))
            typeRef.current.onCheckboxChange([], []);
        if(ValueIsSet(clientLocationsSelectRef.current))
            clientLocationsSelectRef.current.clear();
        if(ValueIsSet(serviceLinesSelectRef.current))
            serviceLinesSelectRef.current.clear();
        setSelectedClientLocations(null);
        setSelectedServiceLines(null);

        setListViewEndDay(moment().startOf('day').add(3, 'days'));
        setListViewStartDay(moment().startOf('day').subtract(3, 'days'));

        setTypes([]);
        setIsRescheduledChecked(true);
        setIsCompletedChecked(true);
        setIsScheduleChecked(true);
        setScheduleFilterSelected(STATUS_OPTIONS.SCHEDULED);
        setCompletedFilterSelected(STATUS_OPTIONS.COMPLETED);
    };

    const onScheduleCheckClicked = () => {
        if(isScheduleChecked)
            setScheduleFilterSelected(null);
        else
            setScheduleFilterSelected(STATUS_OPTIONS.SCHEDULED);

        setIsScheduleChecked(!isScheduleChecked);
    };

    const onCompletedCheckClicked = () => {
        if(isCompletedChecked)
            setCompletedFilterSelected(null);
        else
            setCompletedFilterSelected(STATUS_OPTIONS.SCHEDULED);

        setIsCompletedChecked(!isCompletedChecked);
    };

    const onRescheduleCheckClicked = () => {
        setIsRescheduledChecked(!isRescheduledChecked);
    };

    useEffect(()=> {
        if(resetting){
            fetchJobs(0);
            setNumberOfActiveFilters(getNumberOfActiveFilters());
        }
    }, [resetting]);

    useEffect(()=> {
        if(!resetting){
            fetchJobs(0);
        }
    }, [listViewEndDay, listViewStartDay, status, openChangeOrderFilter, hideFrequentRecurringJobs]);

    useEffect(() => {
      if(!resetting && dateRangeRef !== null && dateRangeRef.current !== null){
          dateRangeRef.current.resetToInitialDates();
      }
    },[resetting]);


    useEffect(() => {
        if(!hideFilters) {
            fetchClientServiceLines();
            fetchLocations();
        }
        fetchJobs(0);
    }, []);

    return (
        <Grid>
            <Grid container spacing={2} margin={ValueIsSet(title) ? '40px 0 16px 0' : '0 0 16px 0'} alignItems={AlignItems.FLEX_START}>
                {!hideFilters ?
                    <StyledFilter loading={loading} container item spacing={2} >
                        <Grid item xs={isMobile ? 12 : 4}>
                            <DateRangePicker
                                ref={dateRangeRef}
                                onDatesChange={handleDateRangeChange}
                                initialStartDate={listViewStartDay}
                                initialEndDate={listViewEndDay}
                                disabled={loading}
                                fullWidth/>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <FilterChip disabled={loading}
                                        buttonFilterChip
                                        numberOfFilters={numberOfActiveFilters}
                                        active={true}
                                        filterTitle={'FILTERS'}
                                        onApply={onShowResultsClicked}
                                        onClear={() => onClear()}
                                        ref={filterChipRef}>
                                <Grid container>
                                    <Grid item>
                                        <StyledTitleText variant={TextVariant.H6}>Type</StyledTitleText>
                                        <Multiselect options={Object.values(JobTypesFilter).map(status => {return {label: Uppercase(status), value: status}})}
                                                     type={MultiselectType.CHECKBOX}
                                                     initialValue={types}
                                                     onChange={setTypes}
                                                     ref={typeRef}/>
                                    </Grid>

                                    <Grid item>
                                        <StyledTitleText variant={TextVariant.H6}>Status</StyledTitleText>
                                        <StyledGrid>
                                            <Checkbox primary
                                                      label={"Scheduled"}
                                                      checked={isScheduleChecked}
                                                      onChange={onScheduleCheckClicked}/>
                                            <StyledGrid>
                                                <Select primary
                                                        onChange={(value) => setScheduleFilterSelected(value)}
                                                        disabled={!isScheduleChecked}
                                                        options={SCHEDULED_RADIO_BUTTONS}
                                                        type={SelectType.RADIO_BUTTONS}
                                                        initialValue={!isScheduleChecked ? [] : scheduleFilterSelected}/>
                                            </StyledGrid>
                                        </StyledGrid>
                                    </Grid>

                                    <Grid item>
                                        <StyledGrid>
                                            <Checkbox primary
                                                      checked={isRescheduledChecked}
                                                      label={"Rescheduling"}
                                                      initialValue={isRescheduledChecked}
                                                      onChange={onRescheduleCheckClicked}/>
                                        </StyledGrid>
                                    </Grid>

                                    <Grid item>
                                        <StyledGrid>
                                            <Checkbox primary
                                                      checked={isCompletedChecked}
                                                      label={"Completed"}
                                                      onChange={onCompletedCheckClicked}/>
                                            <StyledGrid>
                                                <Select primary
                                                        onChange={(value) => setCompletedFilterSelected(value)}
                                                        disabled={!isCompletedChecked}
                                                        options={COMPLETED_RADIO_BUTTONS}
                                                        type={SelectType.RADIO_BUTTONS}
                                                        initialValue={!isCompletedChecked ? [] : completedFilterSelected}/>
                                            </StyledGrid>
                                        </StyledGrid>
                                    </Grid>

                                    <Grid item xs={12} margin={'40px 0 0 0'}>
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            placeholder={'Search location'}
                                            getOptionLabel={option => option.title}
                                            onSelect={setSelectedClientLocations}
                                            variant={TextFieldVariant.SIMPLE_LINE}
                                            options={clientLocations}
                                            defaultMultipleValue={ValueIsSet(selectedClientLocations) ? selectedClientLocations : []}
                                            ref={clientLocationsSelectRef}/>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <HorizontalLine/>
                                        <StyledTitleText variant={TextVariant.H6}>Service Lines</StyledTitleText>
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            placeholder={'Select...'}
                                            getOptionLabel={option => option.name}
                                            onSelect={setSelectedServiceLines}
                                            variant={TextFieldVariant.SIMPLE_LINE}
                                            options={serviceLines}
                                            defaultMultipleValue={ValueIsSet(selectedServiceLines) ? selectedServiceLines : []}
                                            ref={serviceLinesSelectRef}/>
                                    </Grid>

                                </Grid>
                            </FilterChip>
                        </Grid>
                        <Grid item>
                            <Button variant={ButtonVariant.OUTLINED} onClick={() => onClear()}>Reset</Button>
                        </Grid>
                    </StyledFilter>
                    :
                    null}
                {showFrequentJobsFilter ?
                    <Grid item xs={12} margin={!hideFilters ? "0 0 0 0" : '-2em 0 0 0'}>
                        <Tooltip placement={TooltipPlacement.BOTTOM_START} title={'Jobs happening once a week or more will be hidden'}>
                            <Checkbox primary
                                      checked={hideFrequentRecurringJobs}
                                      label={"Hide frequent recurring jobs"}
                                      disabled={loading}
                                      onChange={(value) => setHideFrequentRecurringJobs(value)}/>
                        </Tooltip>
                    </Grid>
                    : null
                }

                <Box width={isMobile ? "100%" : "70%"}>
                    <Table
                        columns={columns}
                        data={listEvents}
                        handleChangePage={handleChangePage}
                        hover
                        isPaginated
                        loading={loading}
                        onClick={(job) => {
                            setSelectedService(job);
                            setShowJobDetails(true);}}
                        page={currentPage}
                        rowsPerPage={JOBS_PER_PAGE}
                        rowsPerPageOptions={[JOBS_PER_PAGE]}
                        showLinkArrow
                        showSingleSelectedRow
                        totalDataLength={totalElements}
                    />
                </Box>
                <JobDetailsSidePanel
                    reloadPage={fetchJobs}
                    jobId={selectedService}
                    showJobDetails={showJobDetails}
                    setShowJobDetails={setShowJobDetails}
                    currentPage={currentPage}
                    pageLoading={loading}/>

                {showCompleteJobModal ?
                    <JobCompletionDetailsDialog
                        hidePricingForNonAdminClientUsers={hidePricingForNonAdminUsers}
                        open={showCompleteJobModal}
                        onAccept={() => setShowCompleteJobModal(false)}
                        obligationId={selectedService}
                        userType={UserType.CLIENT}
                    />
                    :
                    null
                }
            </Grid>
        </Grid>
    )
};

export default WithContext(CalendarListView)

CalendarListView.defaultProps = {
    disableScheduleFilter: false,
    disableCompletedFilter: false,
    disableRescheduledFilter: false,
    hideFilters: false,
    sortDesc: false,
    showFrequentJobsFilter: false,
};

CalendarListView.propTypes = {
    clientId: PropTypes.number,
    disableCompletedFilter: PropTypes.bool,
    disableRescheduleFilter: PropTypes.bool,
    disableScheduleFilter: PropTypes.bool,
    hideFilters: PropTypes.bool,
    initialCompletedSelected: PropTypes.bool,
    initialEndDate: PropTypes.string,
    initialStartDate: PropTypes.string,
    nullDateFilter: PropTypes.bool,
    obligationTemplateId: PropTypes.number,
    openChangeOrderFilter: PropTypes.bool,      // used only on recurring service page.
    sortDesc: PropTypes.bool,
    status: PropTypes.string,                   // used only on recurring service page.
    title: PropTypes.string,
    user: PropTypes.element,
    showFrequentJobsFilter: PropTypes.bool,
};

const ServiceLineFields = `
    id
    obligationServiceLines{
        id
        name
    }
`;

const ObligationListFields = `
    id
    client{
        storeSignOffEnabled
    }
    clientLocation{
        id
        title
    }
    hasPendingChangeOrderRequest
    request{
        id
        type
        emergency
        rfpAttachments{
            fileName
            singleUseUrl
        }
    }
    serviceLine{
        name
    }
    status
    schedule{
        arrivalEndTime
        arrivalStartTime
        date
        daysOfWeek
    }
    obligationTemplate{
        recurringScheduleTemplate{
            frequency{
                readableString
            }
        }
    }
    obligationAttachments{
        id
        type
    }
    isWalkthrough
    isRescheduling
    hasOpenConcern
    awaitingClientSignOff
    confirmed
`;
