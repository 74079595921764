import * as PropTypes from "prop-types";
import React, {Fragment} from "react";
import styled from "styled-components";
import {useTheme} from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";

import TextField, {TextFieldType} from 'worksmith/components/Inputs/TextField/TextField';
import Select from 'worksmith/components/Inputs/Select/Select';
import {LineItemProperties} from 'worksmith/enums/LineItemProperties';
import Currency from "worksmith/components/Currency/Currency";
import Text from "worksmith/components/Text/Text";
import {FlexDirection, TextAlign} from "worksmith/enums/CSSEnums";
import Grid from "worksmith/components/Grid/Grid";
import BreakpointEnum from "worksmith/enums/BreakpointEnum";
import LineItemType from "worksmith/enums/api/psldb/LineItemType";
import {TextVariant} from "worksmith/components/Text/Text";
import Switch from "worksmith/components/Switch/Switch";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {withTheme} from "../../Theme/ThemeProvider";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const PricingSpacer = styled.div`
    height: 1.2em;
`;

const FieldSpacer = styled.div`
    height: .4em;
`;

const LineItemFieldsView = (props) => {
    const {
        allowExcludeRevShare,
        errors,
        excludeRevShare,
        initialDescription,
        initialQuantity,
        initialUnitWholesalePrice,
        lineItemType,
        lineItemTypes,
        onChange,
        onBlur,
        totalRetailPrice,
        totalWholesalePrice,
        unitRetailPrice,
        rateCardEntry,
        portalType,
    } = props;

    const theme = useTheme();
    const stackFields = useMediaQuery(theme.breakpoints.down(BreakpointEnum.SM));

    return (
        <Grid container direction={FlexDirection.COLUMN}>
            <Grid container item>
                <Grid item xs={12}>
                    <Select label={'Type'}
                            initialValue={ValueIsSet(rateCardEntry) ? rateCardEntry.displayValue : lineItemType.name}
                            options={lineItemTypes}
                            valueFieldName={'name'}
                            labelFieldName={'name'}
                            fullWidth
                            onChange={value => onChange(LineItemProperties.LINE_ITEM_TYPE, value)}/>
                </Grid>
                <Grid item xs={12}>
                    <FieldSpacer/>
                </Grid>

                <Grid item xs={12}>
                    {ValueIsSet(rateCardEntry) ?
                        <Text variant={TextVariant.BODY_2}>
                            {rateCardEntry.scopeOfWork}
                        </Text>
                        :
                        <TextField fullWidth
                                   error={ValueIsSet(errors) && errors.description}
                                   initialValue={initialDescription}
                                   label={'Item Description'}
                                   onChange={(value) => onChange(LineItemProperties.DESCRIPTION, value)}
                                   placeholder={"E. g. 60\" 2X4, Two technicians for two hours, Truck fee"}/>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FieldSpacer/>
                </Grid>
            </Grid>

            <Grid container item>
                <Grid item sm={4} xs={6}>
                    {ValueIsSet(rateCardEntry) ?
                        <Fragment>
                            <Text variant={TextVariant.CAPTION}> Wholesale Price (Per Unit)</Text>
                            <Text variant={TextVariant.BODY_2}>
                                <Currency amount={rateCardEntry.unitWholesalePrice}/>
                            </Text>
                        </Fragment>
                        :
                        <TextField initialValue={initialUnitWholesalePrice}
                                   error={ValueIsSet(errors) && errors.unitRetailPrice}
                                   label={'Wholesale Price (Per Unit)'}
                                   startAdornment={'$'}
                                   type={TextFieldType.NUMBER}
                                   fullWidth
                                   onBlur={(value) => onBlur(LineItemProperties.UNIT_WHOLESALE_PRICE, value)}
                        />}

                </Grid>
                {portalType === PortalType.ADMIN &&
                    <Grid item sm={4} xs={6}>
                        <div style={{marginLeft: stackFields ? 0 : '2em'}}>
                            <Text variant={TextVariant.CAPTION}> Retail Price (Per Unit)</Text>
                            <Text variant={TextVariant.BODY_2}>
                                <Currency amount={ValueIsSet(rateCardEntry) ? rateCardEntry.unitRetailPrice : unitRetailPrice}/>
                            </Text>
                        </div>
                    </Grid>
                }
                {stackFields && <Grid item xs={12}>
                    <FieldSpacer/>
                </Grid>}

                <Grid item sm={4} xs={12} textAlign={TextAlign.RIGHT}>
                    <TextField initialValue={initialQuantity}
                               error={ValueIsSet(errors) && errors.quantity}
                               label={'Quantity'}
                               type={TextFieldType.NUMBER}
                               fullWidth
                               onChange={(value) => onChange(LineItemProperties.QUANTITY, value)}/>
                </Grid>
                <Grid item xs={12}>
                    <FieldSpacer/>
                </Grid>

                {(allowExcludeRevShare
                    && lineItemType.name === LineItemType.FEE) &&
                <Grid container item>
                    <Grid item xs={3}>
                        <Text variant={TextVariant.BODY_2}>Exclude Revenue Share</Text>
                    </Grid>
                    <Grid item xs={9}>
                        <Switch initialValue={excludeRevShare}
                                onChange={(value) => onChange(LineItemProperties.EXCLUDE_REVENUE_SHARE, value)}
                                primary/>
                    </Grid>
                </Grid>}
                <Grid item xs={12}>
                    <PricingSpacer/>
                </Grid>
                {portalType === PortalType.ADMIN &&
                    <>
                        <Grid item xs={3}>
                            <Text variant={TextVariant.BODY_2}>
                                Retail Price
                            </Text>
                        </Grid>
                        <Grid item xs={9} textAlign={TextAlign.RIGHT}>
                            <Text variant={TextVariant.BODY_2}>
                                <Currency amount={totalRetailPrice}/>
                            </Text>
                        </Grid>
                    </>
                }

                <Grid item xs={3}>
                    <Text variant={TextVariant.BODY_2}>
                        Wholesale Price
                    </Text>
                </Grid>
                <Grid item xs={9} textAlign={TextAlign.RIGHT}>
                    <Text variant={TextVariant.BODY_2}>
                        <Currency amount={totalWholesalePrice}/>
                    </Text>
                </Grid>
            </Grid>
    </Grid>);
};

LineItemFieldsView.propTypes = {
    allowExcludeRevShare:       PropTypes.bool.isRequired,
    errors:                     PropTypes.object,
    excludeRevShare:            PropTypes.bool.isRequired,
    initialDescription:         PropTypes.string,
    initialQuantity:            PropTypes.number,
    initialUnitWholesalePrice:  PropTypes.number,
    lineItemType:               PropTypes.shape({
                                    id: PropTypes.number.isRequired,
                                    name: PropTypes.string.isRequired
                                }),
    lineItemTypes:              PropTypes.arrayOf(PropTypes.shape({
                                    id: PropTypes.number.isRequired,
                                    name: PropTypes.string.isRequired
                                })).isRequired,
    onChange:                   PropTypes.func.isRequired,
    onBlur:                     PropTypes.func.isRequired,
    totalRetailPrice:           PropTypes.number,
    totalWholesalePrice:        PropTypes.number,
    unitRetailPrice:            PropTypes.number,
    rateCardEntry:              PropTypes.object,
    portalType:             CustomPropTypes.enum(PortalType)
};

LineItemFieldsView.defaultProps = {
    lineItemType: LineItemType[0]
};

export default withTheme(LineItemFieldsView);