import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import {ValueIsSet} from 'worksmith/helpers/GenericHelpers';

function TabContainer(props) {
    let {children, padding} = props;
    let extraStyling = {
        width: '100%'
    };
    if (ValueIsSet(padding)) {
        extraStyling.padding = padding
    } else {
        extraStyling.padding = "24px 0"
    }

    return (
        <Typography component="div" style={extraStyling}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default TabContainer;
