import {JavascriptType} from 'worksmith/enums/GenericEnums';
import {trimEnd} from 'lodash';
import {StateFullName} from "worksmith/enums/StateFullName";
import {CanadianProvinceNames} from "worksmith/enums/CanadianProvinceNames";
import {OTHER_ISSUE_OPTION_TEXT} from "worksmith/helpers/Constants";
import {useIntl} from "react-intl";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";
import numeral from 'numeral';
import Thai from "worksmith/languages/th.json";
import English from "worksmith/languages/en.json";
import Mandarin from "worksmith/languages/zh.json";
import {convertToLocalTime} from "worksmith/helpers/TimeHelpers";
import {MomentFormat} from "worksmith/enums/MomentFormat";

export const SystemUserId = 261;
export const MaxFileSize = 16;
export const MaxCombinedFileSize = 64;

export const GetYearRange = (upperBound, lowerBound) => {
    return Array.from(Array((upperBound + 1) - lowerBound), (yearOption, index) => {
        return {
            value: String(index + lowerBound),
            label: String(index + lowerBound)
        };
    }).sort((a, b) => b.value - a.value);
};

export const ToCurrency = (currency) => {
    return Number(currency).toFixed(2);
};

export const NoZeroCentCurrency = (currency) => {
    let toReturn = Number(currency).toFixed(2);
    return toReturn.replace('.00', '');
};

export const GetKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};

export const GetIntersectionOfLists = (...lists) => { // returns list of items that are included in all lists passed in
    let intersection = lists[0];
    lists.forEach(list => {
        intersection = intersection.filter(item => list.includes(item))
    });
    return intersection;
};

// it seems that this is being deprecated starting April 25th, 2022 so in the future use TriggerHotjarEvent
export const StartHotjarTrigger = (triggerName) => {
    if (ValueIsSet(window.hj)) {
        window.hj('trigger', triggerName);
    }
};

export const TriggerHotjarEvent = (eventName) => {
    if (ValueIsSet(window.hj)) {
        window.hj('event', eventName);
    }
};

export const GetPercentage = (decimal) => {
    return decimal.toFixed(2) * 100;
};

export const ValueIsSet = (object) => {
    return object !== undefined && object !== null;
};

export const ValuesAreSet = (list) => {
    let valuesAreSet = true;
    list.forEach(element => {
        valuesAreSet = valuesAreSet && ValueIsSet(element);
    });
    return valuesAreSet;
};

export const StringHasText = (string) => {
    return ValueIsSet(string) && typeof string === 'string' && string.trim().length > 0;
};

export const ValueOrDefault = (value, defaultValue) => {
    return ValueIsSet(value) ? value : defaultValue;
};

export const GetUniqueReactKey = () => {
    return Math.random();
};

//Returns the first index if any value in the given array is identical in value to the given value param, otherwise it returns -1
export const ArrayIndexOfEquivalentValue = (array, value) => {
    let index = -1;

    array.some((item, i) => {
        if (ObjectsHaveIdenticalValue(item, value)) {
            index = i;
            return true;
        }

        return false;
    });

    return index;
};

export const ObjectsHaveIdenticalValue = (a, b, useLooseEquality) => {
    // noinspection EqualityComparisonWithCoercionJS
    if (useLooseEquality ? a == b : a === b)
        return true;

    let typeOfA = typeof a;
    let typeOfB = typeof b;

    //If a and b aren't the same type then we will say they can't possibly be equivalent in value
    if (typeOfA !== typeOfB)
        return false;

    switch (typeOfA) {
        //If the very first a === b statement didn't return then the boolean values are definitely not the same
        case JavascriptType.BOOLEAN:
            return false;

        //For now if a === b didn't pass then we will say that the functions are different. We can build this out later if its needed
        case JavascriptType.FUNCTION:
            return a.toString() === b.toString();

        //If a === b didn't pass then the numbers are different
        case JavascriptType.NUMBER:
            return false;

        //For objects we need to go through all the keys of a and b and make sure they are identical
        case JavascriptType.OBJECT:
            let aKeys = Object.keys(a);
            let bKeys = Object.keys(b);

            if (aKeys.length !== bKeys.length)
                return false;
            else if (aKeys.length === 0)
                return true;
            else {
                aKeys.sort();
                bKeys.sort();

                return aKeys.every((key, index) => {
                    if (key !== bKeys[index])
                        return false;

                    return ObjectsHaveIdenticalValue(a[key], b[key], useLooseEquality);
                });
            }

        //If a === b didn't pass then the strings are different
        case JavascriptType.STRING:
            return false;

        //This should cover symbol comparison
        case JavascriptType.SYMBOL:
            return a.toString() === b.toString();

        //If we got here that means both a and b are `undefined` so they are equal
        case JavascriptType.UNDEFINED:
            return true;
    }

    //This would only happen if the type of both a and b wasn't a Javascript primitive, so we return false since we can't guess at how to evaluate equivalency
    return false;
};

export const Range = (min, max) => [...Array(max + 1).keys()].slice(min, max + 1);

export const StringHasCurrencyFormat = (string) => {
    return /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{0,2})?$/.test(string);
};

export const StringHasNumberFormat = (string) => {
    return /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]*)?$/.test(string);
};

export const StringHasIntegerFormat = (string) => {
    return /^[0-9]+$/.test(string);
};

export const StringHasEmailFormat = (string) => {
    return /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(string);
};


//This method allows for the copying of objects wih no connection by reference to the original object
//The downside to this method is that it only works on JSON safe objects (ex. if you copy an object that contains a moment, the copy will replace the moment with a date string)
//NOTE: If you are copying an array it is better to just use arrayName.slice()
export const DeepCopy = (object) => {
    return JSON.parse(JSON.stringify(object));
};

//This method copies an array of javascript file objects to a new array of javascript file objects
export const DeepCopyFiles = (files) => {
    return files.map(file => DeepCopyFile(file));
};

//This method copies a javascript file object to a new javascript file object
export const DeepCopyFile = (file) => {
    let fileCopy = new File([file], file.name, {type: file.type});
    fileCopy.id = file.id;
    fileCopy.url = file.url;
    return fileCopy;
};

//This method allows for copying of objects, but any nested values will be copied by reference
//This copy is preferable to the DeepCopy as long as the nested values aren't going to cause an issue
//NOTE: If you are copying an array it is better to just use arrayName.slice()
export const ShallowCopy = (object) => {
    return Object.assign({}, object);
};


//This can be used to debounce functions
//This causes the passed function to not fire off until the interval given has successfully passed without the given function being called
export const Debounce = (fn, interval, ignoreFunction) => {
    let timeout;

    return function () {
        const functionCall = () => fn.apply(this, arguments);

        if (ignoreFunction)
            ignoreFunction();

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, interval);
    };
};

export const Margin = (first, second, third, fourth) => {
    let top = first;
    let right = ValueIsSet(second) ? second : first;
    let bottom = ValueIsSet(third) ? third : first;
    let left = ValueIsSet(fourth) ? fourth : ValueIsSet(second) ? second : first;

    return 'margin-top: ' + top + ';'
        + 'margin-bottom: ' + bottom + ';'
        + 'margin-left: ' + left + ';'
        + 'margin-right: ' + right + ';';
};

export const BytesToMegabytes = (byteSize) => {
    return byteSize / 1000000;
};

export const StringToFloat = (number, decimalPlaces = 2) => {
  const floatString = parseFloat(number).toFixed(decimalPlaces);
  return Number(floatString);
};

export const TruncateFloat = (number, decimalPlaces) => {
    return Number.parseFloat(number).toFixed(decimalPlaces);
};

export const IsArrowFunction = (func) => {
    //If the type of func isn't "function" then it definitely isn't an arrow function
    if (typeof func !== JavascriptType.FUNCTION)
        return false;

    //Arrow functions don't have a prototype, whereas regular functions do
    //If the code is being transpiled into es5 this doesn't hold true
    if (!func.hasOwnProperty('prototype'))
        return true;

    //Because of automatic transpiling to es5 arrow functions are being converted to normal functions where all instances of 'this' are replaced with '_this'
    //Will want to revisit this eventually since this is messy
    //Note that if an arrow function doesn't contain any calls to 'this' in them we will return false, but that should be fine because an arrow function with no call to 'this' should be equivalent to a regular function anyway
    return func.toString().indexOf('_this') !== -1;
};

export const GetNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const NumberToFixedDecimals = (number, decimals) => {
    return Number.parseFloat(number).toFixed(decimals);
};

export const GetTranslation = (id, defaultMessage) => {
    try {
        const intl = useIntl();
        return intl.formatMessage({id: id, defaultMessage: defaultMessage})
    }catch (e){
        return defaultMessage
    }
};

export const GetDollarString = (number, roundDown) => {
    if (number >= 0) {
        if (roundDown) {
            return '$' + GetNumberWithCommas(Math.floor(number));
        } else {
            return '$' + GetNumberWithCommas(NumberToFixedDecimals(number, 2));
        }
    } else {
        if (roundDown) {
            return '-$' + GetNumberWithCommas(Math.floor(number));
        }
        {
            return '-$' + GetNumberWithCommas(NumberToFixedDecimals(Math.abs(number), 2));
        }
    }
};

// This function takes a parent object and an object path and will grab nested values in a javascript
// object. Example: exampleObject['user.name']
export const GetNested = (parentObject, path, separator) => {
    try {
        separator = separator || '.';
        return path.replace('[', separator)
            .replace(']', '')
            .split(separator)
            .reduce(
                function (obj, property) {
                    return obj[property];
                }, parentObject
            );
    } catch (err) {
        return undefined;
    }
};

export const CSVStringToObjectArray = (dataString) => {
    if (typeof dataString !== JavascriptType.STRING) {
        console.warn('Tried to parse object of type "' + typeof dataString + '" using CSVStringToObjectArray helper method');
        return [];
    }

    const splitData = dataString.split('\n');

    //If the CSV doesn't include headers (splitData[0]) or at least one set of data (splitData[1]), we can return an empty array
    if (splitData[0] && splitData[1]) {
        //By default we can't split the data by comma because any data values that have a comma in them (eg. "Austin, TX") would be split incorrectly
        //If we use this regEx though, it will split by all commas unless they are surrounded by quotation marks, which is what a standard CSV uses to identify commas within data points
        const commaSeparatedRegEx = /(".*?"|[^", ]+[^",]*\b|,\s*(?=,))/g;

        if (splitData[splitData.length - 1] === '')
            splitData.pop();

        const keys = splitData[0].match(commaSeparatedRegEx).map(str => {
            if (str[0] === ',')
                return str.replace(',', '');

            return str;
        });
        splitData.shift();

        return splitData.map((data) => {
            return data.match(commaSeparatedRegEx).map(str => {
                if (str[0] === ',')
                    return str.replace(',', '');

                return str;
            }).reduce((returnObject, dataPoint, index) => {
                returnObject[keys[index]] = !dataPoint || isNaN(dataPoint) ? dataPoint : Number(dataPoint);

                return returnObject;
            }, {});
        });
    } else {
        return [];
    }
};

export const TruncateStringWithEllipsis = (string, characterCount) => {
    if (string.length <= characterCount)
        return string;

    let truncatedString = string.slice(0, characterCount);

    truncatedString = trimEnd(truncatedString);

    return truncatedString + '...';
};

//This method handles new lines and treats them as if they are filler characters
export const TruncateStringWithEllipsisOneLine = (string, characterCount) => {
    const splitString = string.split('\n');
    const initialString = splitString[0];

    if (initialString.length <= characterCount && splitString.length > 1)
        return initialString + '...';
    else if (initialString.length <= characterCount)
        return initialString;
    else
        return TruncateStringWithEllipsis(initialString, characterCount);
};

export const GroupBy = (arr, criteria) => {
    return arr.reduce(function (obj, item) {
        let key = typeof criteria === 'function' ? criteria(item) : item[criteria];
        if (!obj.hasOwnProperty(key)) {
            obj[key] = [];
        }
        obj[key].push(item);
        return obj;
    }, {});
};

export const externalNewTab = (url) => {
    window.open(url);
};

// for sorting a exclusively contract items
export const rateCardEntrySort = (left, right) => {
    if (left.sequencePosition < right.sequencePosition) {
        return -1;
    } else if (left.sequencePosition > right.sequencePosition) {
        return 1;
    } else {
        return 0;
    }
};

// GenericSort: Sorts an array of objects based on a nested value through the provided mapping function with an option to reverse the sort order.
// Example: const people = [{ name: "Alice", age: 25 }, { name: "Bob", age: 20 }, { name: "Clara", age: 30 }] GenericSort(people, person => person.age, false);
export const GenericSort = (array, mapFunction, reverse) => {
    let sortedArray = array.slice();
    sortedArray.sort((a, b) => {
        if (mapFunction(a) < mapFunction(b)) {
            return -1;
        }
        if (mapFunction(b) < mapFunction(a)) {
            return 1;
        }
        return 0;
    });

    if (reverse)
        return sortedArray.reverse();
    return sortedArray;
};

// Sort alphabetically placing null values at the end of the returned array. use in side a sort method (example .sort(alphabetically(true))).
export const alphabetically = (ascending) => {
    return function (a, b) {
        // equal items sort equally
        if (a === b) {
            return 0;
        }

        // nulls sort after anything else
        if (a === null) {
            return 1;
        }
        if (b === null) {
            return -1;
        }

        // otherwise, if we're ascending, lowest sorts first
        if (ascending) {
            return a < b ? -1 : 1;
        }

        // if descending, highest sorts first
        return a < b ? 1 : -1;
    };
};

// for sorting a mixture of contract items and non-contract items
export const lineItemSort = (left, right) => {
    const leftVal = ValueIsSet(left.rateCardEntry) ? left.rateCardEntry.sequencePosition : Number.MAX_SAFE_INTEGER;
    const rightVal = ValueIsSet(right.rateCardEntry) ? right.rateCardEntry.sequencePosition : Number.MAX_SAFE_INTEGER;
    return leftVal - rightVal;
};

export const getLineItemErrors = (lineItem) => {
    let errors = {
        description:     false,
        quantity:        false,
        unitRetailPrice: false
    };

    if(!ValueIsSet(lineItem.quantity) || Number(lineItem.quantity) < 1) {
        errors.quantity = true;
    }

    if(!ValueIsSet(lineItem.rateCardEntry)) {
        if(!ValueIsSet(lineItem.quantity) || Number(lineItem.quantity) < 1) {
            errors.quantity = true;
        }
        if(!ValueIsSet(lineItem.description) || lineItem.description === '') {
            errors.description = true;
        }
        if(!ValueIsSet(lineItem.unitRetailPrice) || Number(lineItem.unitRetailPrice) < 0) {
            errors.unitRetailPrice = true;
        }
    }
    return errors;
};

// helps to re-order an array when design calls for alphabetized columns
export const transposeArray = (array, numColumns) => {
    const matrix = new Array(numColumns).fill(null).map(() => []);
    const itemsPerLine = Math.ceil(array.length / numColumns);

    for (let line = 0; line < numColumns; line++) {
        for (let i = 0; i < itemsPerLine; i++) {
            const value = array[i + line * itemsPerLine];
            if (!value) continue;
            matrix[line].push(value);
        }
    }
    return Object.keys(matrix[0]).map(colNumber => matrix.map(rowNumber => rowNumber[colNumber])).flat().filter(item => item);
};

//for linking directly in javascript, useful ONLY when running react inside angular.
// THIS FUNCTION WILL BREAK ON QA/PRODUCTION if used in a react portal, BUT WILL WORK LOCALLY. BEWARE!
export const linkTo = (uri, newTab) => {
    if (newTab) {
        window.open('app#/' + uri);
    } else {
        window.location = window.location.hostname + 'app#/' + uri;
    }
};


export const ParamStringToObject = (paramsString) => {
    let conversion = paramsString.replace('?', '');

    conversion = conversion.split("&");

    return conversion.reduce((obj, param) => {
        let keyValue = param.split('=');

        obj[keyValue[0]] = keyValue[1];

        return obj;
    }, {})
};

export const CurrencySymbolFormat = (string, format, currencyEnum) => {
    //this method uses the numeral library so format string has to be found here http://numeraljs.com/

    let currencySymbol = CurrencySymbolEnum[currencyEnum];
    let value = numeral(string);

    return currencySymbol + value.format(format);
};

// Get the URL Query Parameter value by name
export const GetParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// Get Initials form full name. If full name has more than 2 names the initials will be the first letter of the first and the first letter of the last name.
export const GetInitialsFromFullName = (name) => {
    const nameSplit = name.split(' ');
  const firstName = nameSplit[0];
  const lastName = nameSplit[nameSplit.length - 1];
  const initials = firstName[0] + lastName[0];
  return initials;
};

// Get the issue options for the service line and add the option "Other" to the end of the issue options list.
export const getAssessmentOfIssueSelectOptionObjects = (fetchedAssessmentOfIssueOptions) => {
    let assessmentOfIssueSelectOptionObjects = fetchedAssessmentOfIssueOptions.map(option => {return {label: option.issueOptionText, value: option.issueOptionText}});
    assessmentOfIssueSelectOptionObjects.push({label: OTHER_ISSUE_OPTION_TEXT, value: OTHER_ISSUE_OPTION_TEXT});
    return assessmentOfIssueSelectOptionObjects;
};


const stateOptions = Object.keys(StateFullName).map(state => ({
    label: `${state}`,
    value: state
}));

const provinceOptions = Object.keys(CanadianProvinceNames).map(province => ({
    label: province,
    value: province
}));

//Use UnitedStatesCanadaCombinedList to have the full state/provence name. Example: Alabama (AL) vs AL
export const StateDropdownMenuOptionsWeb = [
    {
        label :'United States:',
        value: ''
    },
    ...stateOptions,
    {
        label :'Canadian Provinces:',
        value: ''
    },
    ...provinceOptions
];

export const StateDropdownMenuOptionsMobile = [
    ...stateOptions,
    ...provinceOptions
];

export const isHexColor = (value) => {
    if (ValueIsSet(value) && value.includes(' ')) {
        return false; // Return false if the string contains spaces
    }

    // Check if the value has a length of 6 (without a leading hash) or 7 (with a leading hash)
    if (/^#?[0-9A-Fa-f]{6}$/.test(value)) {
        return true;
    }

    return false;
};

// Open the global snackbar from anywhere in the Admin, vendor, and client portal.
// Dev Note: If you use this method is an angular component you will need to add $scope.$apply(); afterwards to rerender the page.
export const globalSnackbarTrigger = (message) => {
    return window.dispatchEvent(new CustomEvent('global-snackbar-event', {detail: {message: message}}));
};

export const pickLanguageJson = (language) => {
    switch (language) {
        case 'THAI' :
        case 'th':
            return Thai;
        case 'MANDARIN' :
        case 'zh':
            return Mandarin;
        case 'ENGLISH':
        case 'en':
            return English;
        default:
            return English;
    }
};


export const formatTimestamp = (timestamp) => {
    return convertToLocalTime(timestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt);
};

export const capitalizeFirstLetterOfSentence = (sentence) => {
    if (sentence.length === 0) return sentence;
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

