import PropTypes from 'prop-types';
import moment from 'moment';

import {PropTypeError} from "./PropTypeError";
import {IsArrowFunction} from "worksmith/helpers/GenericHelpers";
import {JavascriptType} from "worksmith/enums/GenericEnums";

const CustomPropTypes = Object.freeze({
    enum: function (enumType) {
        return PropTypes.oneOf(Object.values(enumType))
    },
    arrowFunction: function (props, propName, componentName) {
        if (!IsArrowFunction(props[propName]))
            return new PropTypeError(propName, componentName, 'Must be an arrow function')
    },
    objectOfArrowFunction: function (props, propName, componentName) {
        if (props[propName]) {
            if (typeof props[propName] !== JavascriptType.OBJECT)
                return new PropTypeError(propName, componentName, 'Must be an object');

            let values = Object.values(props[propName]);

            if (values.length > 0) {
                let onlyArrowFunctions = values.every((value) => {
                    return IsArrowFunction(value);
                });

                if (!onlyArrowFunctions)
                    return new PropTypeError(propName, componentName, 'All values in the object must be arrow functions');
            }
        }
    },
    nonArrowFunction: function (props, propName, componentName) {
        if (typeof props[propName] !== JavascriptType.FUNCTION)
            return new PropTypeError(propName, componentName, 'Must be a function');
        if (IsArrowFunction(props[propName]))
            return new PropTypeError(propName, componentName, "Can't be an arrow function")
    },
    objectOfNonArrowFunction: function (props, propName, componentName) {
        if (props[propName]) {
            if (typeof props[propName] !== JavascriptType.OBJECT)
                return new PropTypeError(propName, componentName, 'Must be an object');

            let values = Object.values(props[propName]);

            if (values.length > 0) {
                let onlyNonArrowFunctions = values.every((value) => {
                    return typeof value === JavascriptType.FUNCTION && !IsArrowFunction(value);
                });

                if (!onlyNonArrowFunctions)
                    return new PropTypeError(propName, componentName, 'All values in the object must be non arrow functions');
            }
        }
    },
    stringOrNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    moment: function(props, propName, componentName) {
        if (!props[propName] instanceof moment)
            return new PropTypeError(propName, componentName, 'Must be of type "moment"')
    },
    arrayOfMoment: function(props, propName, componentName) {
        if (!Array.isArray(props[propName])){
            return new PropTypeError(propName, componentName, 'Must be an "array"')
        } else {
            let isNotMoment = props[propName].some(momentObject=>{
                return !(momentObject instanceof moment)
            });

            if(isNotMoment)
                return new PropTypeError(propName, componentName, 'Must be of type "moment"')
        }
    }
});

export default CustomPropTypes;