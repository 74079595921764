import React, {memo, useState} from 'react';
import PropTypes from "prop-types";

import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Grid from "worksmith/components/Grid/Grid";
import HugoBossAlterationsStandardItems from "./HugoBossAlterationsStandardItems";
import HugoBossAlterationsStandardPricing from "worksmith/composite-components/ClientLocationProfile/standardPricing/HugoBossAlterationsStandardPricing";
import Icon from "worksmith/components/Icon/Icon";
import Text from "worksmith/components/Text/Text";
import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {IconType} from "worksmith/enums/MaterialEnums";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";

const HugoBossAlterations = ({
                                 client,
                                 currency,
                                 getClientStandardItems,
                                 getClientStandardItemsPricing,
                                 isForStandardPricingPage,
                                 lineItem,
                                 locationId,
                                 selfServiceClientVendorRelationships,
                                 serviceLine,
                                 standardItemsPricing,
                                 userIsAdmin,
}) => {

    // Creates an object with categories for each of Hugo Boss's alteration types. Each category contains an array of standardItems.
    const configureStandardItems = () => {
        let categories = {};

        lineItem.standardItemsForClient.map(item => {
            // Split the item name into a potential number and category part before the dash, and the name after.
            let numberAndCategory = item.name.split(" - ")[0];
            let name = item.name.split(" - ")[1];

            // Define a regex to check if the item name starts with a number followed by a period, indicating a numbered category.
            let regexContainsNumberFollowedByPeriod = /\d\./;

            // If the item name matches the pattern of having a number followed by a period at the start.
            if (regexContainsNumberFollowedByPeriod.test(item.name)) {
                // Extract the number and category from the numberAndCategory part.
                let number = numberAndCategory.split(/(?<=\d\.)\s+/)[0];
                let category = numberAndCategory.split(/(?<=\d\.)\s+/)[1];

                // If the category does not already exist in the categories object, initialize it with the current item.
                if (!categories.hasOwnProperty(category)) {
                    categories[category] = [{id: item.id, number: number, category: category, name: name}];
                } else {
                    // If the category already exists, append the current item to it.
                    categories[category] = [...categories[category], {id: item.id, number: number, category: category, name: name}];
                }
            } else {
                // If the item name does not start with a number followed by a period, treat the entire numberAndCategory part as the category.
                if (!categories.hasOwnProperty(numberAndCategory)) {
                    categories[numberAndCategory] = [{id: item.id, category: numberAndCategory, name: name}];
                } else {
                    // If the category already exists, append the current item to it.
                    categories[numberAndCategory] = [...categories[numberAndCategory], {id: item.id, category: numberAndCategory, name: name}];
                }
            }
        });
        return categories;
    };

    // finds the next available Hugo Boss category number/standard item number based on existing items. Example Hugo Boss Item Name (1. Jackets - Center Seam) this function gets the next available number that begins the item name.
    const getNextStandardItemNumber = () => {
        let largestNumber = 0;
        lineItem.standardItemsForClient.forEach(item => {
            // Define a regex to identify if the item's name contains a number followed by a period at the beginning.
            let regexContainsNumberFollowedByPeriod = /\d\./;

            // If the item's name matches the regex, indicating it starts with a number followed by a period.
            if (regexContainsNumberFollowedByPeriod.test(item.name)) {
                // Extract the number part from the item's name and convert it to an integer.
                let number = parseInt(item.name.split(/(?<=\d\.)\s+/)[0]);

                // Update the largestNumber if the current item's number is greater than the largestNumber found so far.
                largestNumber = number > largestNumber ? number : largestNumber;
            }
        });

        // Calculate the next item number by adding 1 to the largest number found, convert it to a string, and append a period.
        return (largestNumber + 1).toString() + '.';
    };

    const [standardItems, setStandardItems] = useState(configureStandardItems());


    const renderStandardItems = () => {
        return Object.entries(standardItems).sort((a,b) => a[1][0].category.localeCompare(b[1][0].category)).map(([key, value]) => {
            return (
                <Grid item xs={12} key={key} padding={0}>
                    {isForStandardPricingPage ?
                        <HugoBossAlterationsStandardPricing
                            currency={currency}
                            getClientStandardItemsPricing={getClientStandardItemsPricing}
                            initialAlterations={value}
                            initialCategory={key}
                            key={key}
                            locationId={locationId}
                            selfServiceClientVendorRelationships={selfServiceClientVendorRelationships}
                            standardItemsPricing={standardItemsPricing}
                            userIsAdmin={userIsAdmin}
                        />
                        :
                        <HugoBossAlterationsStandardItems
                            client={client}
                            getClientStandardItems={getClientStandardItems}
                            initialAlterations={value}
                            initialCategory={key}
                            lineItem={lineItem}
                            nextStandardItemNumber={getNextStandardItemNumber()}
                            serviceLine={serviceLine}
                            setStandardItems={setStandardItems}
                        />
                    }
                </Grid>
            )
        });
    };

    return (
        <>
            {standardItems.length === 0 ?
                <Grid container>
                    <Grid item xs={12}>
                        <Text>No items. Click Edit to add items.</Text>
                    </Grid>
                </Grid>
                :
                <Grid container justify={JustifyContent.FLEX_START} alignItems={AlignItems.FLEX_START}>
                    {renderStandardItems()}
                    {!isForStandardPricingPage &&
                        <Grid item xs={12} margin={'0 0 10px 0'}>
                            <Button
                                onClick={() => setStandardItems(prevState => {
                                    return {...prevState, NewCategory: [{id: 0, number: getNextStandardItemNumber(), category: '', name: 'Multiple Alterations', newCategory: true, changed: true}]}
                                })}
                                variant={ButtonVariant.OUTLINED}
                                secondary
                                startIcon={<Icon name={IconType.ADD}/>}
                            >
                                Add Garment
                            </Button>
                        </Grid>
                    }
                </Grid>
            }
        </>
    )
};

export default memo(HugoBossAlterations);

HugoBossAlterations.propTypes = {
    client: PropTypes.object,
    currency: CustomPropTypes.enum(CurrencySymbolEnum),
    getClientStandardItems: PropTypes.func,
    getClientStandardItemsPricing: PropTypes.func,
    isForStandardPricingPage: PropTypes.bool,
    lineItem: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        isAvailableForStandardItems: PropTypes.bool,
        standardItemsForClient: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                client: PropTypes.shape({
                    id: PropTypes.number
                })
            })
        )
    }),
    locationId: PropTypes.number,
    selfServiceClientVendorRelationships: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    serviceLine: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        lineItemTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isAvailableForStandardItems: PropTypes.bool,
                standardItemsForClient: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        client: PropTypes.shape({
                            id: PropTypes.number
                        })
                    })
                )
            })
        )
    }),
    standardItemsPricing: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        updatedTimestamp: PropTypes.string,
        unitRetailPrice: PropTypes.number,
        unitWholesalePrice: PropTypes.number,
        unitCustomerCharge: PropTypes.number,
        clientLocationId: PropTypes.number,
        vendorLocationId: PropTypes.number,
        vendorName: PropTypes.string,
        standardItem: PropTypes.shape({
            id: PropTypes.number,
            createdTimestamp: PropTypes.string,
            updatedTimestamp: PropTypes.string,
            name: PropTypes.string,
            client: PropTypes.object,
            serviceLine: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            lineItemType: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
        }),
    })),
    userIsAdmin: PropTypes.bool,
};