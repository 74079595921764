import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import LocationSpecificationsForm from "./LocationSpecificationsForm";
import LocationSpecificationsView from "./LocationSpecificationsView";

import {AlignItems, JustifyContent} from "../../../enums/CSSEnums";
import Accordion from '../../../components/Accordion/Accordion';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Grid from "../../../components/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import {IconType} from "../../../enums/MaterialEnums";
import Text, {TextVariant} from "../../../components/Text/Text";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientLocationService from "../../../services/api/ClientLocationService";
import {Color} from "worksmith/enums/Color";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientLocationService = new ClientLocationService();

const LocationSpecificationsPanel = ({location, readOnly}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [locationSpecificationsFormFields, setLocationSpecificationsFormFields] = useState({
        clientLocationFloors: [{
            ceilingHeightFt: 0,
            bathroomsCount: 0,
            kitchensCount: 0,
            fittingRoomsCount: 0,
            level: 1,
            locationId: location.clientLocationOperationDetails.locationId,
        }]
    });
    const floorMissingCeilingHeight = locationSpecificationsFormFields.clientLocationFloors.filter(floor => !floor.ceilingHeightFt);
    const locationSpecificationsInfoComplete =
        locationSpecificationsFormFields.locationSquareFootage &&
        locationSpecificationsFormFields.clientLocationFloors.length > 0 &&
        floorMissingCeilingHeight.length === 0;

    let formFields = {
        locationSquareFootage: locationSpecificationsFormFields.locationSquareFootage,
        hasLadderOnSite: locationSpecificationsFormFields.hasLadderOnSite,
        ladderHeightFt: locationSpecificationsFormFields.ladderHeightFt,
        vendorNotes: locationSpecificationsFormFields.vendorNotes,
        clientLocationFloors: locationSpecificationsFormFields.clientLocationFloors,
    };

    const refetchSpecificationsData = async () => {
        const specificationsData = await  asyncGraphQLServiceClass.findOneById(location.id, GraphQLObjectType.CLIENT_LOCATION, specificationsFields);
        setLocationSpecificationsFormFields({
            locationSquareFootage: specificationsData.clientLocationOperationDetails.locationSquareFootage ? specificationsData.clientLocationOperationDetails.locationSquareFootage : 0,
            hasLadderOnSite: specificationsData.clientLocationOperationDetails.hasLadderOnSite,
            ladderHeightFt: specificationsData.clientLocationOperationDetails.ladderHeightFt ? specificationsData.clientLocationOperationDetails.ladderHeightFt : 0,
            vendorNotes: specificationsData.clientLocationOperationDetails.vendorNotes ? specificationsData.clientLocationOperationDetails.vendorNotes : "",
            clientLocationFloors: specificationsData.clientLocationFloors.length > 0 ? specificationsData.clientLocationFloors : [{
                ceilingHeightFt: 0,
                bathroomsCount: 0,
                kitchensCount: 0,
                fittingRoomsCount: 0,
            }]
        })
    };

    const submitLocationSpecifications = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        let submitData = {};
        submitData.id = location.id;
        submitData.clientLocationOperationDetails = {
            id: location.clientLocationOperationDetails.id,
            locationId: location.clientLocationOperationDetails.locationId,
            locationSquareFootage: formFields.locationSquareFootage,
            hasLadderOnSite: formFields.hasLadderOnSite,
            ladderHeightFt: formFields.ladderHeightFt,
            vendorNotes: formFields.vendorNotes,
        };
        submitData.clientLocationFloors = formFields.clientLocationFloors;

        try {
            await clientLocationService.update(submitData);
            await refetchSpecificationsData();
            setIsEditing(false);
            setIsSubmitting(false);
        } catch (err) {
            console.error(err)
            setIsEditing(false);
            setIsSubmitting(false);
        }
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleAddFloor = () => {
        setLocationSpecificationsFormFields({
            hasLadderOnSite: formFields.hasLadderOnSite,
            locationSquareFootage: formFields.locationSquareFootage,
            ladderHeightFt: formFields.ladderHeightFt,
            clientLocationFloors: [
                ...locationSpecificationsFormFields.clientLocationFloors,
                {
                    ceilingHeightFt: 0,
                    bathroomsCount: 0,
                    kitchensCount: 0,
                    fittingRoomsCount: 0,
                    level: locationSpecificationsFormFields.clientLocationFloors.length + 1
                }
            ]
        })
    };

    const handleRemoveFloor = (idx) => {
        const updatedFloors = formFields.clientLocationFloors.filter((block, i) => i !== idx)
        setLocationSpecificationsFormFields({
            hasLadderOnSite: formFields.hasLadderOnSite,
            locationSquareFootage: formFields.locationSquareFootage,
            ladderHeightFt: formFields.ladderHeightFt,
            clientLocationFloors: updatedFloors
        })
    };

    const handleUpdateFloor = (formField, value, idx) => {
        const floors = formFields.clientLocationFloors
        let updatedFloors = floors.slice()
        updatedFloors[idx][formField] = formField === 'ceilingHeightFt' ?
            value : updatedFloors[idx][formField] + value
        setLocationSpecificationsFormFields({
            hasLadderOnSite: formFields.hasLadderOnSite,
            locationSquareFootage: formFields.locationSquareFootage,
            ladderHeightFt: formFields.ladderHeightFt,
            clientLocationFloors: updatedFloors
        })
    };

    const handleChange = (formField, value, idx) => {
        if (formField === 'locationSquareFootage' || formField === 'ladderHeightFt' || formField === 'vendorNotes') {
            formFields[formField] = value
        }
        if (formField === 'ceilingHeightFt') {
            formFields['clientLocationFloors'][idx][formField] = value
        }
        if (formField === 'hasLadderOnSite') {
            setLocationSpecificationsFormFields({
                ...locationSpecificationsFormFields,
                hasLadderOnSite: value,
                locationSquareFootage: formFields.locationSquareFootage,
                ladderHeightFt: formFields.ladderHeightFt,
                vendorNotes: formFields.vendorNotes,
            })
        }
    };


    useEffect(() => {
        if (location) {
            setLocationSpecificationsFormFields({
                locationSquareFootage: location.clientLocationOperationDetails.locationSquareFootage ? location.clientLocationOperationDetails.locationSquareFootage : 0,
                hasLadderOnSite: location.clientLocationOperationDetails.hasLadderOnSite,
                ladderHeightFt: location.clientLocationOperationDetails.ladderHeightFt ? location.clientLocationOperationDetails.ladderHeightFt : 0,
                vendorNotes: location.clientLocationOperationDetails.vendorNotes ? location.clientLocationOperationDetails.vendorNotes : "",
                clientLocationFloors: location.clientLocationFloors.length > 0 ? location.clientLocationFloors : [{
                    ceilingHeightFt: 0,
                    bathroomsCount: 0,
                    kitchensCount: 0,
                    fittingRoomsCount: 0,
                    level: 1
                }]
            })
        }
    }, [location]);

    const panelSummary = (
        <Grid container>
            <Grid container item xs={2} md={1} alignItems={AlignItems.CENTER}>
                <Icon name={IconType.STORE} />
            </Grid>
            <Grid container item xs={9} md={10} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H5}>
                    {"Location Specifications"}
                </Text>
            </Grid>
            <Grid container item xs={1} alignItems={AlignItems.CENTER} justify={JustifyContent.CENTER}>
                {!readOnly ? <Icon name={locationSpecificationsInfoComplete ? IconType.CHECK_CIRCLE_OUTLINED : IconType.REPORT_PROBLEM_OUTLINED} iconColor={locationSpecificationsInfoComplete ? Color.GREEN : Color.RED} /> : null}
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            summary={panelSummary}
            details={isEditing && !readOnly ?
                <LocationSpecificationsForm
                    locationSpecificationsFormFields={locationSpecificationsFormFields}
                    handleAddFloor={handleAddFloor}
                    handleRemoveFloor={handleRemoveFloor}
                    handleUpdateFloor={handleUpdateFloor}
                    handleChange={handleChange}
                    isSubmitting={isSubmitting}
                    submitLocationSpecifications={submitLocationSpecifications}
                    toggleEdit={toggleEdit}
                />
                :
                <LocationSpecificationsView
                    locationSpecificationsFormFields={locationSpecificationsFormFields}
                    readOnly={readOnly}
                    toggleEdit={toggleEdit}
                />
            }
            large
        />
    )
};

export default LocationSpecificationsPanel;

LocationSpecificationsPanel.propTypes = {
    location: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        client: PropTypes.shape({
            adminUsers: PropTypes.arrayOf(PropTypes.shape({
                clientRole: PropTypes.string,
                companyName: PropTypes.string,
                displayName: PropTypes.string,
                email: PropTypes.string.isRequired,
                phone: PropTypes.string,
                clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
                    clientLocation: PropTypes.shape({
                        locationCode: PropTypes.string,
                        client: PropTypes.shape({
                            officialName: PropTypes.string.isRequired
                        }).isRequired
                    })
                })).isRequired
            })),
            id: PropTypes.number.isRequired
        }).isRequired,
        clientLocationAttachments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            singleUseUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })),
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
        email: PropTypes.string,
        industry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: PropTypes.string
        }),
        openingDate: PropTypes.string,
        regionalManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        locationManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        clientLocationOperationDetails: PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            unionLaborRequirement: PropTypes.string,
            occurrenceInsuranceRequirement: PropTypes.number,
            aggregateInsuranceRequirement: PropTypes.number,
            additionalInsuranceDetails: PropTypes.string,
            locationSquareFootage: PropTypes.number,
            hasLadderOnSite: PropTypes.bool,
            ladderHeightFt: PropTypes.number,
            parkingDirections: PropTypes.string,
            serviceEntranceDescription: PropTypes.string,
            additionalAccessInfo: PropTypes.string,
            vendorNotes: PropTypes.string
        }),
        locationHours: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            dayOfWeekInteger: PropTypes.number,
            openTime: PropTypes.string,
            closeTime: PropTypes.string,
            closed: PropTypes.bool,
        })),
        id: PropTypes.number,
        locationCode: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired
    }),
    readOnly: PropTypes.bool
}

const specificationsFields = `
    clientLocationFloors {
        id
        locationId
        level
        title
        description
        ceilingHeightFt
        bathroomsCount
        kitchensCount
        fittingRoomsCount
        deleted
    }
    clientLocationOperationDetails {
        id
        locationId
        locationSquareFootage
        hasLadderOnSite
        ladderHeightFt
        vendorNotes
    }
    id
    locationCode
`
