import {StateFullName} from "worksmith/enums/StateFullName";
import {CanadianProvinceNames} from "worksmith/enums/CanadianProvinceNames";
import {ThailandProvince} from "worksmith/enums/api/country/ThailandProvince";
import {MalaysiaProvince} from "worksmith/enums/api/country/MalaysiaProvince";
import {SingaporeProvince} from "worksmith/enums/api/country/SingaporeProvince";
import {UnitedStatesAndCanada, USAState} from "worksmith/enums/api/country/USAState";
import {CanadianProvinceNameAndAbbr} from "worksmith/enums/api/country/CanadianProvinceNameAndAbbr";

export const CountryStateProvince = Object.freeze({
    'United States': USAState,
    'Canada': CanadianProvinceNameAndAbbr,
    'Malaysia': MalaysiaProvince,
    'Thailand': ThailandProvince,
    'Singapore': SingaporeProvince,
});


export const CountriesCombined = Object.freeze({
    USA_CANADA: 'USA_CANADA',
});

export const UnitedStatesCanadaCombinedList = Object.entries(UnitedStatesAndCanada).map(([key, value]) => {
    return {
        label: value,
        value: key,
    }});