import React, {Component} from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import InfiniteLoader from "react-window-infinite-loader";
import {FixedSizeList} from "react-window";
import CircularProgress from "../Loader/CircularProgress";
import {ComponentColor} from "../../enums/Color";

const ListCustomScrollbar = styled(FixedSizeList)`
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
`;

class InfiniteScrollingList extends Component {
    constructor(props) {
        super(props);
    }

    isItemLoaded = (index) => {
        let {currentPage, totalPages, items} = this.props;

        return currentPage === (totalPages - 1) || index < items.length;
    };

    renderItem = ({data, index, style}) => {
        let {renderItems, allItemsFetched} = this.props;

        if (this.isItemLoaded(index)) {
            return renderItems(data[index],style);
        } else {
            if(!allItemsFetched)
                return (
                    <div style={style}>
                       <CircularProgress size={30} style={{marginLeft: '50%'}} color={ComponentColor.SECONDARY}/>
                    </div>
                );
            else
                return null;
        }
    };

    render() {
        let {renderItem, isItemLoaded} = this;
        let {
            className,
            currentPage,
            height,
            itemSize,
            items,
            loading,
            loadNextPageCallback,
            minimumBatchSize,
            threshold,
            totalPages,
            width,
            allItemsFetched
        } = this.props;

        let itemCount = currentPage < (totalPages - 1) ? items.length + 1 : items.length;
        let loadMoreItems = () =>  loading || allItemsFetched ? null : loadNextPageCallback();

        return (
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    threshold={threshold}
                    minimumBatchSize={minimumBatchSize}
                    loadMoreItems={loadMoreItems}>
                    {({onItemsRendered, ref}) => (
                        <ListCustomScrollbar
                            className={className}
                            itemCount={itemCount}
                            onItemsRendered={onItemsRendered}
                            height={height}
                            width={width}
                            itemData={items}
                            itemSize={itemSize}
                            ref={ref}>
                            {renderItem}
                        </ListCustomScrollbar>
                    )}
                </InfiniteLoader>
        )
    }
}

InfiniteScrollingList.propTypes = {
    allItemsFetched: PropTypes.bool, // this props is used when the total number of items is not available
    currentPage: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired, // Number of rows displayed at any one time
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    itemSize: PropTypes.number.isRequired, // Row height
    loading: PropTypes.bool.isRequired,
    loadNextPageCallback: PropTypes.func.isRequired,
    minimumBatchSize: PropTypes.number, // Min number of rows loaded at a time
    renderItems: PropTypes.func.isRequired, // This function needs to take an item and a style parameter and pass it through to each component
    //^^^^^^^^^ items will flicker if the style param is not passed to root element returned from renderItems function
    threshold: PropTypes.number, // Pre-fetches data this number of rows from bottom
    totalPages: PropTypes.number.isRequired,
    // from documentation: "Number of rows in list; can be arbitrary high number if actual number is unknown."
    width: PropTypes.oneOfType([
        PropTypes.string, //percentage
        PropTypes.number //number of columns
    ]).isRequired
};

export default InfiniteScrollingList;