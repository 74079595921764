import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'worksmith/components/Button/Button';
import Grid from 'worksmith/components/Grid/Grid';
import IconButton from 'worksmith/components/Button/IconButton';
import {IconType} from 'worksmith/enums/MaterialEnums';
import {JustifyContent} from 'worksmith/enums/CSSEnums';
import Text, {TextColor, TextVariant} from 'worksmith/components/Text/Text';
import Icon, {IconFontSize} from "worksmith/components/Icon/Icon";
import {FunctionalColor} from "worksmith/enums/Color";
import CalendarTabEnum from "../../enums/CalendarTabEnum";

const localizer = momentLocalizer(moment)

export const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

const StyledContainer = styled.div`
  height: 60vh;
`

const Toolbar = ({
    date,
    label,
    onNavigate,
    onView,
    view,
    views
}) => {
    const viewButtons = views.map(v => (
        <Grid item xs={3}>
            <Button fullWidth primary={v === view} onClick={() => onView(v)}>{v.toUpperCase()}</Button>
        </Grid>
    ))

    const handleNavigate = (direction) => {
        if (view === CalendarTabEnum.AGENDA) {
            if (direction === navigate.PREVIOUS) {
                onNavigate(navigate.PREVIOUS, moment(date).subtract(1, 'days').toDate());
            } else {
                onNavigate(navigate.NEXT, moment(date).add(1, 'days').toDate());
            }
        } else {
            onNavigate(direction);
        }
    }

    return (
        <Grid container margin={'0 0 8px 0'}>
            <Grid item xs={4}></Grid>
            <Grid container justify={JustifyContent.CENTER} item xs={4}>
                <IconButton
                    iconName={IconType.CHEVRON_LEFT}
                    onClick={() => handleNavigate(navigate.PREVIOUS)}
                    size="large" />
                <Text variant={TextVariant.BODY_1}>{label}</Text>
                <IconButton
                    iconName={IconType.CHEVRON_RIGHT}
                    onClick={() => handleNavigate(navigate.NEXT)}
                    size="large" />
            </Grid>
            <Grid item container justify={JustifyContent.FLEX_END} xs={4}>
                {viewButtons}
            </Grid>
        </Grid>
    );
}

// docs: https://github.com/jquense/react-big-calendar/blob/master/src/Calendar.js
const BigCalendar = ({
    allDayAccessor = 'allDay',
    components,
    date,
    endAccessor = 'end',
    events = [],
    eventPropGetter,
    length,
    onNavigate,
    onSelectSlot,
    onShowMore,
    onView,
    startAccessor = 'start',
    view,
}) => {
    const customComponents = {
        ...components,
        toolbar: Toolbar
    }

    return (
        <StyledContainer>
            <Calendar
                allDayAccessor={allDayAccessor}
                components={customComponents}
                date={date}
                endAccessor={endAccessor}
                eventPropGetter={eventPropGetter}
                events={events}
                length={length}
                localizer={localizer}
                messages={{ showMore: (target) => <a onClick={(e) => onShowMore(e, null)} role="presentation"> Show {target} more</a> }}
                onNavigate={onNavigate}
                onSelectSlot={(slot) => onSelectSlot(slot)}
                onView={onView}
                selectable
                startAccessor={startAccessor}
                view={view}
            />
        </StyledContainer>
    )
}

export default BigCalendar;

BigCalendar.propTypes = {
    allDayAccessor: PropTypes.string,
    components: PropTypes.shape({
        event: PropTypes.element
    }),
    date: PropTypes.string,
    endAccessor: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.shape({
        allDay: PropTypes.bool,
        client: PropTypes.shape({
            id: PropTypes.number,
            nickname: PropTypes.string
        }),
        clientLocation: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string
        }),
        color: PropTypes.string,
        end: PropTypes.string,
        isFirstObligationInRecurringService: PropTypes.bool,
        isPastDue: PropTypes.bool,
        isRescheduling: PropTypes.bool,
        obligationDate: PropTypes.string,
        obligationId: PropTypes.number,
        obligationTemplate: PropTypes.shape({
            id: PropTypes.number
        }),
        opportunity: PropTypes.shape({
            commonId: PropTypes.string,
            id: PropTypes.number,
            walkthroughs: PropTypes.array
        }),
        serviceLine: PropTypes.string,
        start: PropTypes.string,
        status: PropTypes.string,
    })),
    eventPropGetter: PropTypes.func,
    length: PropTypes.number,
    onNavigate: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onShowMore: PropTypes.func,
    onView: PropTypes.func,
    startAccessor: PropTypes.string,
    view: PropTypes.string,
}