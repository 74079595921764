import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import ScheduledDefaultView from "./ScheduledDefaultView";
import ScheduledChangeOrderView from "./ScheduledChangeOrderView";
import RequestStateLayout from "worksmith/composite-components/RequestState/RequestStateStatus/RequestStateLayout";

const ScheduledView = (props) =>  {
    const {
        acceptChangeOrder,
        canAcceptChangeOrder,
        changeOrder,
        declineChangeOrder,
        duration,
        frequency,
        loading,
        createChangeRequest,
        schedule,
        vendorLocation,
        useTicketing,
    } = props;


    if (loading) {
        return (
            <RequestStateLayout loading={loading} status={''}/>
        )

    } else if (changeOrder) {
        return (
            <ScheduledChangeOrderView
                acceptChangeOrder={acceptChangeOrder}
                canAcceptChangeOrder={canAcceptChangeOrder}
                changeOrder={changeOrder}
                declineChangeOrder={declineChangeOrder}
                loading={loading}
                vendorLocation={vendorLocation}/>
        );

    } else {
        return (
            <ScheduledDefaultView
                createChangeRequest={createChangeRequest}
                duration={duration}
                frequency={frequency}
                loading={loading}
                schedule={schedule}
                useTicketing={useTicketing}
                vendorLocation={vendorLocation}/>
        )
    }
}

ScheduledView.propTypes = {
    acceptChangeOrder: PropTypes.func.isRequired,
    declineChangeOrder: PropTypes.func.isRequired,
    canAcceptChangeOrder: PropTypes.bool,
    changeOrder: PropTypes.shape({
        id: PropTypes.number,
        changedAdditionalApprovalAmount: PropTypes.number,
        changedDuration: PropTypes.number,
        changedLineItems: PropTypes.arrayOf(PropTypes.shape({
            lineItemType: PropTypes.shape({
                name: PropTypes.string
            }),
            description: PropTypes.string,
            id: PropTypes.number,
            quantity: PropTypes.number,
            unitRetailPrice: PropTypes.number
        })),
        changedScopeOfWork: PropTypes.string,
        originalAdditionalApprovalAmount: PropTypes.number
    }),
    duration: PropTypes.number,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    loading: PropTypes.bool,
    createChangeRequest: PropTypes.func.isRequired,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

export default ScheduledView;