// This file was generated from com.worksmith.task.enums.ObligationTemplateStatus
// noinspection JSUnusedGlobalSymbols
const ObligationTemplateStatus = Object.freeze({
    ACTIVE: 'active',
    CANCELED: 'canceled',
    SCHEDULED: 'scheduled',
    PENDING_CANCELLATION: 'pending cancellation',
    getClosedStatuses: function() {
        return [this.CANCELED];
    },
    getOpenStatuses: function() {
        return [this.ACTIVE, this.SCHEDULED, this.PENDING_CANCELLATION];
    }
});

export default ObligationTemplateStatus;
