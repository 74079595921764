import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {ValueIsSet} from "../../helpers/GenericHelpers";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {InputBaseVariant} from "../Inputs/InputBase/InputBase.web";
import OutlinedRangePickerInputView from "./OutlinedRangePickerInputView";
import SimpleLineRangePickerInputView from "./SimpleLineRangePickerInputView";

class RangePickerInput extends Component {
    constructor(props) {
        super(props);
        let {initialStartDate, initialEndDate} = this.props;

        this.state = {
            startDateTextValue: initialStartDate || '',
            endDateTextValue: initialEndDate || ''
        }
    }

    onStartDateChange = (event) => {
        this.setState(
            {startDateTextValue: event.target.value}, () => {
                if (ValueIsSet(this.props.onStartDateChange))
                    this.props.onStartDateChange(this.state.startDateTextValue);
            }
        );
    };

    onEndDateChange = (event) => {
        this.setState(
            {endDateTextValue: event.target.value}, () => {
                if (ValueIsSet(this.props.onEndDateChange))
                    this.props.onEndDateChange(this.state.endDateTextValue);
            }
        );
    };

    setDates = (startDateString, endDateString) => {
        this.setState({startDateTextValue: startDateString || '', endDateTextValue: endDateString || ''});
    };

    render() {
        const { onEndDateChange, onStartDateChange } = this;
        const { endDateTextValue, startDateTextValue } = this.state;
        let {variant, ...otherProps} = this.props;

        let InputComponent = variant === InputBaseVariant.OUTLINED ?
                             OutlinedRangePickerInputView : SimpleLineRangePickerInputView;

        return (
           <InputComponent
               {...otherProps}
               startDate={startDateTextValue}
               endDate={endDateTextValue}
               onEndDateChange={onEndDateChange}
               onStartDateChange={onStartDateChange}/>
        )
    }
}

RangePickerInput.defaultProps = {
    autoFocusStartDate: false,
    autoFocusEndDate: false,
    error: false,
    fullWidth: false,
    readOnly: false,
    variant: InputBaseVariant.OUTLINED
};

RangePickerInput.propTypes = {
    autoFocusEndDate: PropTypes.bool,
    autoFocusStartDate: PropTypes.bool,
    childRef: PropTypes.object,
    endDateInputRef: PropTypes.object,
    endDateOnBlur: PropTypes.func,
    endDatePlaceholderText: PropTypes.string,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    initialEndDate: PropTypes.string,
    initialStartDate: PropTypes.string,
    onEndDateChange: PropTypes.func,
    onEndDateClick: PropTypes.func,
    onEndDateFocus: PropTypes.func,
    onStartDateChange: PropTypes.func,
    onStartDateClick: PropTypes.func,
    onStartDateFocus: PropTypes.func,
    readOnly: PropTypes.bool,
    startDateInputRef: PropTypes.object,
    startDatePlaceholderText: PropTypes.string,
    variant: CustomPropTypes.enum(InputBaseVariant)
};

export default RangePickerInput;