import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

import Text, {TextVariant} from "../Text/Text.web";

const CalendarMonth = (props) => {
    let {month} = props;

    return (
        <Text variant={TextVariant.BODY_1}>{month.format('MMMM YYYY')}</Text>
    )
};

CalendarMonth.propTypes = {
    month: PropTypes.instanceOf(moment),
};

export default CalendarMonth;