import React from "react";
import {Typography} from "antd";
const {Paragraph: AntdParagraph} = Typography;

const Paragraph = (props) => {
    return  <AntdParagraph ellipsis={props.ellipsis} style={{whiteSpace: 'pre-wrap'}} className={props.className}>
                {props.children}
            </AntdParagraph>
};

export default Paragraph;