import React, {Component} from 'react';
import AttachmentsList from "../../AttachmentsList";
import {WithContext} from "../../context/GlobalContext";
import {ClientDashboardType} from "../../Enums";


class PrintSingleTicket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticket: props.ticket
        };
    }

    render() {
        let {ticket} = this.state;
        let {clientDashboardType} = this.props.context;

        let itemMetadataFieldNames = {};
        if (ticket.obligationItems && ticket.obligationItems.length > 0) {
            ticket.obligationItems.forEach(function(item) {
                if (item.metadata) {
                    for (let key in item.metadata) {
                        if (item.metadata.hasOwnProperty(key)) {
                            itemMetadataFieldNames[key] = true;
                        }
                    }
                }
            })
        }

        return (
            <div className={'ws-section no-pad'} style={{'page-break-before': 'always'}}>
                <div className="ws-section ws-section--inverse margin-top-none">
                    <div className="d-flex flex-row">
                        <h3 className={'flex-1'}>{ticket.groupId ? 'Ticket #' + ticket.groupId : ticket.ticketType}</h3>
                        <h3 className={'flex-1'}>{ticket.vendorLocation.name}</h3>
                    </div>
                </div>
                <div className="ws-section ws-section-empty">
                    <div className={'row'}>

                            <div className="col-md-6">
                                <p style={{'font-size': '20px', 'font-weight':'bold'}}>WS Ticket ID</p>
                                <p style={{'font-size': '20px'}}>WS{ticket.id}</p>
                            </div>

                            {ticket.name ?
                            <div className="col-md-6">
                                <p style={{'font-size': '20px', 'font-weight':'bold'}}>Name</p>
                                <p style={{'font-size': '20px'}}>{ticket.name}</p>
                            </div> : null}

                            {ticket.metadata.hasOwnProperty('customerNumber') ?
                                <div className="col-md-6">
                                    <p style={{'font-size': '20px', 'font-weight':'bold'}}>Client Number</p>
                                    <p style={{'font-size': '20px'}}>{ticket.metadata['customerNumber']}</p>
                                </div> : null}

                            {ticket.urgency ?
                                <div className="col-md-6">
                                    <p style={{'font-size': '20px', 'font-weight':'bold'}}>Urgency</p>
                                    <p style={{'font-size': '20px'}}>{ticket.urgency}</p>
                                </div> : null}

                            {ticket.vendorLocationTailor ?
                                <div className="col-md-6">
                                    <p style={{'font-size': '20px', 'font-weight':'bold'}}>Assigned Tailor</p>
                                    <p style={{'font-size': '20px'}}>{ticket.vendorLocationTailor.name}</p>
                                </div> : null}

                            {ticket.metadata.hasOwnProperty('serviceType') ?
                                <div className="col-md-6">
                                    <p style={{'font-size': '20px', 'font-weight':'bold'}}>Service Type</p>
                                    <p style={{'font-size': '20px'}}>{ticket.metadata['serviceType']}</p>
                                </div> : null}

                            {ticket.metadata.hasOwnProperty('expeditedShippingRequested') && ticket.metadata['expeditedShippingRequested'] === 'Yes' ?
                                <div className="col-md-6">
                                    <p style={{'font-size': '20px', 'font-weight':'bold'}}>Expedited Shipping Requested</p>
                                    <p style={{'font-size': '20px'}}>{ticket.metadata['expeditedShippingRequested']}</p>
                                </div> : null}

                            {ticket.creatorName && clientDashboardType !== ClientDashboardType.INTERMIX  ?
                                <div className="col-md-6">
                                    <p style={{'font-size': '20px', 'font-weight':'bold'}}>Associate</p>
                                    <p style={{'font-size': '20px'}}>{ticket.creatorName} ({ticket.creatorEmail})</p>
                                </div> : null}

                            {ticket.metadata['clientName'] && clientDashboardType === ClientDashboardType.INTERMIX  ?
                                <div className="col-md-6">
                                    <p style={{'font-size': '20px', 'font-weight':'bold'}}>Client Name</p>
                                    <p style={{'font-size': '20px'}}>{ticket.metadata['clientName']}</p>
                                </div> : null}

                    </div>
                    <div className={'row'}>
                        <div className="col-md-6 margin-top-none">
                            {ticket.obligationItems.length ?
                            <table className="ws-table table-small table-bordered">
                                <thead>
                                <tr className={'d-flex justify-content-around'}>
                                    <th className={'flex-4'} style={{'font-size': '20px'}}>Item</th>
                                    { itemMetadataFieldNames.hasOwnProperty('garmentNumber') ?
                                        <th className={'flex-3'} style={{'font-size': '20px'}}>Garment Number</th>
                                    : null}
                                    { itemMetadataFieldNames.hasOwnProperty('valueOfGarment') ?
                                        <th className={'flex-3'} style={{'font-size': '20px'}}>Value of Garment</th>
                                        : null}
                                    <th className={'flex-4'} style={{'font-size': '20px'}}>Notes</th>
                                    <th className={'flex-2'} style={{'font-size': '20px'}}>Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                {ticket.obligationItems.map(function (item, idx) {
                                    return (
                                        <tr className={'d-flex justify-content-around'} key={idx}>
                                            <td className={'flex-4'} style={{'font-size': '20px'}}>
                                                <div>{item.title}</div>
                                                <div>{item.lineItemType.name}</div>
                                                {itemMetadataFieldNames.hasOwnProperty('requestingEstimate') && item.metadata['requestingEstimate'] === 'Yes' ?
                                                    <div style={{'font-weight':'bold'}}>REQUESTING ESTIMATE</div>
                                                    : null}
                                            </td>
                                            { itemMetadataFieldNames.hasOwnProperty('garmentNumber') ?
                                                <td className={'flex-3'} style={{'font-size': '20px'}}>{ item.metadata['garmentNumber'] ? item.metadata['garmentNumber'] : '-'}</td>
                                                : null}
                                            { itemMetadataFieldNames.hasOwnProperty('valueOfGarment') ?
                                                <td className={'flex-3'} style={{'font-size': '20px'}}>{ item.metadata['valueOfGarment'] ? item.metadata['valueOfGarment'] : '-'}</td>
                                                : null}
                                            <td className={'flex-4'} style={{'font-size': '20px'}}>{item.description}</td>
                                            <td className={'flex-2 tex-right'} style={{'font-size': '20px'}}>{item.quantity}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table> : 'No items'}
                        </div>
                    </div>
                    <div className='row margin-top-lg'>
                        <div className="col-md-12">
                            <AttachmentsList obligationItemGroup={ticket} includeFileUploader={false}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WithContext(PrintSingleTicket);
