import React from "react";

import Button, {ButtonSize} from "worksmith/components/Button/Button";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

export const LightBoxFileTypes = Object.freeze({
    YOUTUBE: 'youtube',
    IMAGE: 'image',
    NON_IMAGE_OR_VIDEO_FILE: 'nonImageOrVideoFile',
});

export const downloadAttachment = (dataurl, filename) => {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
};

const imageFiles = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff'];
const videoExtensions = ['.mp4', '.mov', '.ogg', '.wmv', '.webm', '.avi', '.qt'];

export const createLightBoxSlidesFromAttachments = (attachments) => {
    const attachmentSlides = attachments.map((attachment, index) => {
        const fileNameString = ValueIsSet(attachment.name) ? attachment.name : ValueIsSet(attachment.fileName) ? attachment.fileName : attachment.title;
        const urlString = ValueIsSet(attachment.url) ? attachment.url : attachment.singleUseUrl;
        if (videoExtensions.some(extension => fileNameString.toLowerCase().endsWith(extension))) {
            return {
                type: "video",
                width: "auto",
                height: "auto",
                title: fileNameString,
                sources: [
                    {
                        src: urlString,
                        type: "video/mp4"
                    }
                ]
            }
        } else if (imageFiles.some(extension => fileNameString.toLowerCase().endsWith(extension))) {
            return {
                slideNumber: index,
                type: LightBoxFileTypes.IMAGE,
                src: urlString,
                title: fileNameString,
            }
        }
        else {
            return {
                slideNumber: index,
                type: LightBoxFileTypes.NON_IMAGE_OR_VIDEO_FILE,
                title: fileNameString,
                src: urlString,
            }
        }
    });
    return attachmentSlides
};

export const renderLightBoxSlideThatAreNotImagesOrVideos = () => {
return (
    {
        slide: ({ type, src, width, height, title }, offset, rect) =>
            type === LightBoxFileTypes.YOUTUBE ? (
                <iframe
                    width={Math.min(
                        width,
                        rect.width,
                        (width * rect.height) / height
                    )}
                    height={Math.min(
                        height,
                        rect.height,
                        (height * rect.width) / width
                    )}
                    src={src}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )  : type === LightBoxFileTypes.NON_IMAGE_OR_VIDEO_FILE ? (
                <Button primary
                        size={ButtonSize.MEDIUM}
                        endIcon={<Icon name={IconType.GET_APP}/>}
                        onClick={() => downloadAttachment(src, title)}>
                    {title}
                </Button>
            ) : null
    }
)};

// For future use an example of an attachment slide object for a Youtube embeded video.
// const youTubeSlides =
//     {
//         type: "youtube",
//         src: "https://www.youtube.com/embed/j1a_xUixnLU",
//         title: "Big Buck Bunny",
//         width: 560,
//         height: 315
//     };
