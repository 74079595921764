import React, {Component} from 'react';
import PickupDetails from "../../components/obligations/PickupDetails";

import ObligationService from 'worksmith/services/api/ObligationService';
import ClientService from 'worksmith/services/api/ClientService';
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';

import Loader from "../../components/Loader";

const obligationService = new ObligationService();
const userService = new UserService();
const clientService = new ClientService();
const authTokenManager = new AuthTokenManager();

class SinglePickupPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: props.user,
            client: props.client,
            obligation: {},
            loading: true,
            settings: {},
        };

        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){
        let _this = this;

        let obligationId = this.props.match.params.id;

        userService.findOne(authTokenManager.getUserId()).then(function(data){
            let user = data;
            let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;

            clientService.getSingleDryCleaningFormConfiguration(clientId).then(function (data) {
                _this.setState({ settings: { dryCleaning: data }});

                obligationService.getSummary(obligationId).then(function(data){
                    let obligation = data;
                    _this.setState({ obligation, loading: false })
                });
            });
        });




    }
    
    render(){
        let {loading} = this.state;

        if(!loading){
            return(
                <div>
                    <div>
                        <button className="ws-btn ws-btn-link" onClick={() => this.goBack()}>back</button>
                        <PickupDetails settings={this.state.settings} obligation={this.state.obligation} user={this.state.user} client={this.state.client}/>
                    </div>
                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

export default SinglePickupPage;