import React, {Fragment} from "react";
import PropTypes from 'prop-types';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {toCurrencyString} from "worksmith/helpers/LanguageHelper"


const Currency = (props) => {
    const {amount, removeDecimals} = props;

    return <Fragment>{toCurrencyString(Number(amount), removeDecimals)}</Fragment>;
};

Currency.defaultProps = {
   removeDecimals: false
};

Currency.propTypes = {
    amount: CustomPropTypes.stringOrNumber.isRequired,
    removeDecimals: PropTypes.bool,
};

export default Currency;