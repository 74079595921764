import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import Currency from "worksmith/components/Currency/Currency.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import InvoiceLineItem from "./InvoiceLineItem";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import moment from "moment";

const InvoiceGroups = styled.div`
    margin-top: .5em;
`;

const InvoiceLineItemSection = styled.div`
    margin-bottom: .4em;
`;

const InvoiceItem = (props) => {
    const {invoiceItem, linkTo, totalDiscount, columnWidths} = props;

    const lineItemsJSX = invoiceItem.map(lineItem => <InvoiceLineItemSection key={lineItem.id}>
        <InvoiceLineItem description={(lineItem.lineItemType ? lineItem.lineItemType.name + ' - ' : '') + lineItem.description}
                         quantity={lineItem.quantity}
                         total={(lineItem.unitPrice - lineItem.unitDiscount) * lineItem.quantity}
                         totalDiscount={totalDiscount}
                         unitDiscount={lineItem.unitDiscount}
                         unitPrice={lineItem.unitPrice}
                         reasonCode={ValueIsSet(lineItem.pricingItem) && ValueIsSet(lineItem.pricingItem.obligationItem) ? lineItem.pricingItem.obligationItem.reasonCode : null}
                         columnWidths={columnWidths}/>
    </InvoiceLineItemSection>);

    const total = invoiceItem.reduce((sum, lineItem) => {
        sum += (lineItem.unitPrice - lineItem.unitDiscount) * lineItem.quantity;
        return sum;
    } , 0);

    return (
        <Fragment>
            <Grid container>
                <Grid item width={`${columnWidths.serviceDate}%`}>
                    <Text variant={TextVariant.BODY_2}>{moment(invoiceItem[0].serviceDate).format('MM/DD/YYYY')}</Text>
                </Grid>
                <Grid item width={`${columnWidths.serviceDetails}%`}>
                    <Text variant={TextVariant.BODY_2} color={TextColor.TEXT_SECONDARY}>{invoiceItem[0].obligation.vendor.nickname}</Text>
                </Grid>
                <Grid item width={`${columnWidths.qty + columnWidths.unitPrice + columnWidths.totalDiscount}%`}/>
                <Grid item width={`${columnWidths.amount}%`}>
                    <Text variant={TextVariant.SUBTITLE_2}>
                        <Currency amount={total}/>
                    </Text>
                </Grid>
                {ValueIsSet(invoiceItem[0].referenceNumber) ?
                    <Grid container item>
                        <Grid item width={`${columnWidths.serviceDate}%`}/>
                        <Grid item width={`${columnWidths.serviceDetails}%`}>
                            <Text variant={TextVariant.BODY_2}>
                                P.O# {invoiceItem[0].referenceNumber}
                            </Text>
                        </Grid>
                    </Grid>
                : null}
                {ValueIsSet(invoiceItem[0].obligation.requestId) ?
                    <Grid container item>
                        <Grid item width={`${columnWidths.serviceDate}%`}/>
                        <Grid item width={`${columnWidths.serviceDetails}%`}>
                            <Text variant={TextVariant.BODY_2}>
                                <a style={{textDecoration: 'underline'}} onClick={() => linkTo(invoiceItem[0].obligation.requestId)}>
                                    Request #{invoiceItem[0].obligation.requestId}
                                </a>
                            </Text>
                        </Grid>
                    </Grid>: null}
            </Grid>
            <InvoiceGroups>
                {lineItemsJSX}
            </InvoiceGroups>
        </Fragment>
    )
};

InvoiceItem.propTypes = {
    invoiceItem:  PropTypes.arrayOf(PropTypes.shape({
                        id: PropTypes.number,
                        lineItemType: PropTypes.object,
                        obligation: PropTypes.object,
                        quantity: PropTypes.number,
                        referenceNumber: PropTypes.string,
                        serviceDate: CustomPropTypes.moment,
                        unitDiscount: PropTypes.number,
                        unitPrice: PropTypes.number,
                   })),
    linkTo:        PropTypes.func.isRequired,
    totalDiscount: PropTypes.number.isRequired,
};

export default InvoiceItem;