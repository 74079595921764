import React from 'react';
import PropTypes from 'prop-types';

import MaterialAvatar from '@mui/material/Avatar';
import {withTheme, ThemeConstants} from "../Theme/ThemeProvider";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {ShouldTextBeBlack, HexToRGB} from "worksmith/helpers/StylingHelpers";
import {Color} from "worksmith/enums/Color";

const Avatar = (props) => {
    let {
        alt,
        className,
        color,
        imgProps,
        marginLeft,
        marginRight,
        onMouseLeave,
        onMouseOver,
        sizes,
        src,
        srcSet
    } = props;

    const customStyling = {};

    if (ValueIsSet(color)) {
        customStyling.backgroundColor = color;

        if (ShouldTextBeBlack(...HexToRGB(color)))
            customStyling.color = Color.BLACK;
    }

    if (marginLeft) {
        customStyling.marginLeft = ThemeConstants.spacingUnit * marginLeft;
    }

    if (marginRight) {
        customStyling.marginRight = ThemeConstants.spacingUnit * marginRight;
    }

    return (
        <MaterialAvatar alt={alt}
                        className={className}
                        imgProps={imgProps}
                        onMouseLeave={onMouseLeave}
                        onMouseOver={onMouseOver}
                        sizes={sizes}
                        src={src}
                        srcSet={srcSet}
                        style={customStyling}>
            {props.children}
        </MaterialAvatar>
    )
};

Avatar.propTypes = {
    alt: PropTypes.string,              //Used in combination with src or srcSet to provide an alt attribute for the rendered img element
    color: PropTypes.string,
    imgProps: PropTypes.object,         //Attributes applied to the img element if the component is used to display an image
    marginLeft: PropTypes.number,       //applies a margin to the left of the avatar equal to value * ThemeConstant.spacingUnit
    marginRight: PropTypes.number,      //applies a margin to the right of the avatar equal to value * ThemeConstant.spacingUnit
    onMouseLeave: PropTypes.func,       //this allows support for wrapping buttons with Tooltips
    onMouseOver: PropTypes.func,        //this allows support for wrapping buttons with Tooltips
    sizes: PropTypes.string,            //The sizes attribute for the img element
    src: PropTypes.string,              //The src attribute for the img element
    srcSet: PropTypes.string            //The srcSet attribute for the img element
};

export default withTheme(Avatar);