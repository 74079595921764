import React, {Component} from 'react';
import styled from 'styled-components';
import Modal from 'antd/lib/modal';
import UserService from 'worksmith/services/api/UserService';
import ClientService from 'worksmith/services/api/ClientService';
import ServiceLineService from 'worksmith/services/api/ServiceLineService';
import ClientLocationService from 'worksmith/services/api/ClientLocationService';
import Loader from "../../components/Loader";
import TicketForm from "../../components/forms/TicketForm";
import {ValueIsSet} from "../../Helpers";
import {Color} from "../../Enums";

const clientService = new ClientService();
const userService = new UserService();
const serviceLineService = new ServiceLineService();
const clientLocationService = new ClientLocationService();

const t = require('tcomb-form');
const Form = t.form.Form;

const FormContainer = styled.div`
    margin: 50px 50px -40px 50px;
    border-bottom: ${props => props.showBottomBorder ? '1px' : '0px'};
`;

const TicketContainer = styled.div`
    margin: 0 50px;
`;

class BurberryNewTicketPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            locationFormStruct: t.struct({}),
            locationFormOptions: {},
            locationValue: {
                value: null
            },
            singleLocation: false,
            ticketTypeFormStruct: t.struct({}),
            ticketTypeFormOptions: {},
            ticketTypeValue: {
                value: null
            },
            serviceLineOptions: {},
            serviceLineFormStruct: t.struct({}),
            serviceLineFormOptions: {},
            serviceLineValue: {
                value: null
            },
            preferredVendorFormStruct: t.struct({}),
            preferredVendorFormOptions: {},
            preferredVendorValue: {
                value: null
            },
            preferredVendorIsBurberryVendor: {},
            preferredVendorsLoading: false,
            noPreferredVendors: false,
            locationsAsSelectOptions: {},
            locationInfo: {},
            ticketFieldOptions: [],
            filteredTicketOptions: [],
            obligation: null,
            clientId: null,
            settings: null,
            loadingOptions: false
        };

        this.setUpSingleSelectFieldFormStructAndOptions = this.setUpSingleSelectFieldFormStructAndOptions.bind(this);
        this.selectLocation = this.selectLocation.bind(this);
        this.selectTicketType = this.selectTicketType.bind(this);
        this.selectServiceLine = this.selectServiceLine.bind(this);
        this.selectVendor = this.selectVendor.bind(this);
        this.handleNewTicketSuccess = this.handleNewTicketSuccess.bind(this);
    }

    componentDidMount() {
        let {getOptions} = this;
        let {user} = this.state;
        let _this = this;

        let isAdmin = user.clientRoles && user.clientRoles.length > 0;
        let notAdmin = user.clientLocationRoles && user.clientLocationRoles.length > 0;
        let clientId = isAdmin ? user.clientRoles[0].client.id : notAdmin ? user.clientLocationRoles[0].clientId : 0;

        userService.getClientLocationClientSummariesForUser(user.id).then(function(data) {
            let locations = data;
            let locationInfo = {};
            let locationValue = {
                value: null
            };

            if (locations.length > 1) {
                getOptions(clientId).then(() => {
                    let locationsAsSelectOptions = {};
                    locationValue.value = locations[0].id;

                    locations.forEach(function (location) {
                        locationsAsSelectOptions[location.id] = location.title;
                        locationInfo[location.id] = {
                            title: location.title,
                            addressLineOne: location.addressLineOne,
                            addressLineTwo: location.addressLineTwo,
                            city: location.city,
                            state: location.state,
                            zip: location.zip
                        }
                    });

                    let locationFormStruct = {};
                    let locationFormOptions = {};
                    locationFormStruct = _this.setUpSingleSelectFieldFormStructAndOptions(locationFormStruct, locationFormOptions, locationsAsSelectOptions, 'Location', false);

                    _this.setState({locationFormStruct, locationFormOptions, locationValue, locationInfo}, () => {
                        _this.selectLocation({value: locations[0].id});
                    });
                });
            } else {
                let location = locations[0];
                getOptions(clientId, location.id).then(() => {
                    locationValue.value = location.id;

                    locationInfo[location.id] = {
                        title: location.title,
                        addressLineOne: location.addressLineOne,
                        addressLineTwo: location.addressLineTwo,
                        city: location.city,
                        state: location.state,
                        zip: location.zip
                    };

                    _this.setState({locationValue, locationInfo, singleLocation: true}, () => {
                        _this.selectLocation({value: locations[0].id});
                    });
                });
            }
        });

        clientService.getSingleDryCleaningFormConfiguration(clientId)
            .then(function (data) {
                _this.setState({settings: {dryCleaning: data}});
            });

        this.setState({clientId});
    }

    getOptions = (clientId, clientLocationId) => {
        let _this = this;

        this.setState({loadingOptions: true});
        return clientService.getTicketFormOptions(clientId, clientLocationId).then(function(data) {
            let fieldOptions = data;

            return serviceLineService.findSimpleBusinessObjects(null, true).then(function(data) {
                let serviceLines = data;

                let serviceLinesAsObject = {};
                serviceLines.forEach(function(serviceLine) {
                    serviceLinesAsObject[serviceLine.id] = serviceLine.name;
                });

                //Set up ticket type options
                let ticketTypeOptions = {};
                fieldOptions.forEach(function(option) {
                    ticketTypeOptions[option.group] = option.group;
                });

                let ticketTypeFormStruct = {};
                let ticketTypeFormOptions = {};
                ticketTypeFormStruct = _this.setUpSingleSelectFieldFormStructAndOptions(ticketTypeFormStruct, ticketTypeFormOptions, ticketTypeOptions, 'Ticket Type', true);

                // //Set up service line options
                let serviceLineOptions = {};
                fieldOptions.forEach(function(option) {
                    if (!serviceLineOptions[option.group])
                        serviceLineOptions[option.group] = {};

                    serviceLineOptions[option.group][option.serviceLineId] = serviceLinesAsObject[option.serviceLineId];
                });

                _this.setState({ticketTypeFormStruct, ticketTypeFormOptions, serviceLineOptions, ticketFieldOptions: fieldOptions, loadingOptions: false});
            });
        });
    };

    setUpSingleSelectFieldFormStructAndOptions(formStruct, formOptions, valueOptions, label, nullOption) {
        let {preferredVendorsLoading} = this.state;
        formOptions.fields = {};

        let mappedValueOptions = Object.keys(valueOptions).map(function(key){
            return {
                value: key,
                text: valueOptions[key]
            }
        });

        mappedValueOptions.sort(function(a, b) {
            if (a.text.toLowerCase() > b.text.toLowerCase())
                return 1;
            else if (a.text.toLowerCase() < b.text.toLowerCase())
                return -1;

            return 0;
        });

        formStruct.value = t.enums.of(Object.keys(formOptions).toString().replace(/,/g, ' '));
        formStruct = t.struct(formStruct);
        formOptions.fields.value = {
            label: label,
            nullOption: nullOption,
            options: mappedValueOptions,
            disabled: false
        };

        return formStruct;
    }

    selectLocation(value) {
        let {serviceLineValue, ticketFieldOptions} = this.state;

        if (serviceLineValue.value) {
            this.getPreferredVendors(value.value, serviceLineValue.value);
        }

        let ticketTypeOptions = {};

        //Find options specific to the location selected
        ticketFieldOptions.forEach(function(option) {
            if (option.clientLocationId == value.value)
                ticketTypeOptions[option.group] = option.group;
        });

        //Find options that aren't specific to a location and use them if they weren't already added by the location
        ticketFieldOptions.forEach(function(option) {
            if (!ValueIsSet(option.clientLocationId) && !ValueIsSet(ticketFieldOptions[option.group]))
                ticketTypeOptions[option.group] = option.group;
        });

        if (Object.keys(ticketTypeOptions).length === 0)
            ticketFieldOptions.forEach(function(option) {
                if (!ValueIsSet(option.clientLocationId))
                    ticketTypeOptions[option.group] = option.group;
            });
        else
            this.selectTicketType({value: ''});

        let ticketTypeFormStruct = {};
        let ticketTypeFormOptions = {};
        ticketTypeFormStruct = this.setUpSingleSelectFieldFormStructAndOptions(ticketTypeFormStruct, ticketTypeFormOptions, ticketTypeOptions, 'Ticket Type', true);

        this.setState({locationValue: value, ticketTypeFormStruct, ticketTypeFormOptions});
    }

    selectTicketType(value) {
        let {serviceLineOptions} = this.state;

        if (value.value) {
            let serviceLineFormStruct = {};
            let serviceLineFormOptions = {};
            serviceLineFormStruct = this.setUpSingleSelectFieldFormStructAndOptions(serviceLineFormStruct, serviceLineFormOptions, serviceLineOptions[value.value], 'Service Line', true);

            this.setState({serviceLineFormStruct, serviceLineFormOptions});
        }

        if (ValueIsSet(serviceLineOptions[value.value]) && Object.keys(serviceLineOptions[value.value]).length === 1)
            this.selectServiceLine({value: Object.keys(serviceLineOptions[value.value])[0]});
        else
            this.selectServiceLine({value: ""});
        this.setState({ticketTypeValue: value});
    }

    selectServiceLine(value) {
        let {locationValue} = this.state;

        if (value.value) {
            this.getPreferredVendors(locationValue.value, value.value);
        }

        this.selectVendor({value: ""});
        this.setState({serviceLineValue: value});
    }

    selectVendor(value) {
        let {locationValue, serviceLineValue, serviceLineOptions, ticketTypeValue, preferredVendorIsBurberryVendor, ticketFieldOptions, clientId} = this.state;

        this.setState({obligation: null, filteredTicketOptions: []}, () => {
            if (value.value) {
                let filteredTicketOptions = ticketFieldOptions.filter(option => option.group === ticketTypeValue.value).filter(option => option.serviceLineId == serviceLineValue.value);

                let obligation = {
                    client: {
                        id: clientId
                    },
                    metadata: {
                        'Return Date': null
                    },
                    clientLocation: {
                        id: locationValue.value
                    },
                    vendorLocation: {
                        id: value.value,
                        isBurberryVendor: preferredVendorIsBurberryVendor[value.value]
                    },
                    obligationDate: null,
                    serviceLine: {
                        id: serviceLineValue.value,
                        name: serviceLineOptions[serviceLineValue.value]
                    }
                };

                this.setState({filteredTicketOptions, obligation: obligation});
            }
        });

        this.setState({preferredVendorValue: value});
    }

    handleNewTicketSuccess() {
        let {preferredVendorValue} = this.state;
        Modal.confirm({
            title: "Would you like to add another ticket to this pick up date?",
            iconType: "tag",
            onOk: () => this.selectVendor(preferredVendorValue),
            onCancel: () => {window.location = '/#/ticketing/ticket-dashboard'},
            okText: "Yes",
            cancelText: "No"
        });
    }

    getPreferredVendors(locationId, serviceLineId) {
        let _this = this;

        this.setState({preferredVendorsLoading: true});
        clientLocationService.getPreferredVendorSummaries(locationId, serviceLineId, true).then(function(data) {
            let preferredVendorLocations = data;

            let preferredVendorOptions = {};
            let preferredVendorIsBurberryVendor = {};
            preferredVendorLocations.forEach(function(location) {
                preferredVendorOptions[location.vendorLocation.id] = location.vendorLocation.name;
                preferredVendorIsBurberryVendor[location.vendorLocation.id] = location.vendorLocation.isBurberryVendor;
            });

            let preferredVendorFormStruct = {};
            let preferredVendorFormOptions = {};
            preferredVendorFormStruct = _this.setUpSingleSelectFieldFormStructAndOptions(preferredVendorFormStruct, preferredVendorFormOptions, preferredVendorOptions, 'Vendor', true);

            _this.setState({preferredVendorFormStruct, preferredVendorFormOptions, preferredVendorIsBurberryVendor, preferredVendorsLoading: false, noPreferredVendors: preferredVendorLocations.length === 0}, () => {
                if (preferredVendorLocations.length === 1)
                    _this.selectVendor({value: preferredVendorLocations[0].vendorLocation.id});
                else
                    _this.selectVendor({value: ''});
            })
        });
    }

    render() {
        let {loadingOptions, filteredTicketOptions, obligation, clientId, settings, locationInfo, locationFormStruct, locationFormOptions, locationValue, ticketTypeFormStruct, ticketTypeFormOptions, ticketTypeValue, serviceLineFormStruct, serviceLineFormOptions, serviceLineValue, preferredVendorFormStruct, preferredVendorFormOptions, preferredVendorValue, singleLocation, preferredVendorsLoading, noPreferredVendors} = this.state;
        let {selectLocation, selectTicketType, selectServiceLine, selectVendor} = this;

        let selectedLocationInfo = locationInfo[locationValue.value];
        let showTicketForm = filteredTicketOptions && filteredTicketOptions.length > 0 && obligation && clientId && settings && preferredVendorValue;

        // if (preferredVendorsLoading) {
        //     locationFormOptions.fields.value.disabled = true;
        //     ticketTypeFormOptions.fields.value.disabled = true;
        //     serviceLineFormOptions.fields.value.disabled = true;
        // }

        return (
            selectedLocationInfo && !loadingOptions ?
                <div>
                    <FormContainer className={'ws-section'} showBottomBorder={!showTicketForm}>
                        <h2>{selectedLocationInfo.title}</h2>
                        <div>New tickets must be submitted the day prior to your next scheduled pick up</div>
                        {singleLocation ? null :
                            <Form ref="locationForm"
                                  type={locationFormStruct}
                                  onChange={(value) => selectLocation(value)}
                                  value={locationValue}
                                  options={locationFormOptions}/>
                        }
                        <Form ref="ticketTypeForm"
                              type={ticketTypeFormStruct}
                              onChange={(value) => selectTicketType(value)}
                              value={ticketTypeValue}
                              options={ticketTypeFormOptions}/>
                        {ticketTypeValue.value ?
                            <Form ref="serviceLineForm"
                                  type={serviceLineFormStruct}
                                  onChange={(value) => selectServiceLine(value)}
                                  value={serviceLineValue}
                                  options={serviceLineFormOptions}/>
                            : null
                        }
                        {serviceLineValue.value && locationValue.value ?
                            (!preferredVendorsLoading ?
                                ( noPreferredVendors ?
                                    <div>
                                        <p style={{color: Color.RED}}>This location ({selectedLocationInfo.title}) doesn't currently have any vendors set up for this service line.</p>
                                        <p style={{color: Color.RED}}>Please contact worksmith support to get this issue resolved.</p>
                                    </div>
                                    :
                                    <Form ref="vendorForm"
                                          type={preferredVendorFormStruct}
                                          onChange={(value) => selectVendor(value)}
                                          value={preferredVendorValue}
                                          options={preferredVendorFormOptions}/>
                                )
                                : <Loader/>
                            )
                            : null
                        }
                    </FormContainer>
                    <TicketContainer>
                        { showTicketForm ?
                            <TicketForm onSuccess={this.handleNewTicketSuccess}
                                        disableHeader={true}
                                        fieldSettings={filteredTicketOptions}
                                        client={{id: clientId}}
                                        globalSettings={settings}
                                        obligation={obligation}
                                        style={{borderTop: '0px'}}/>
                            : null
                        }
                    </TicketContainer>
                </div>
                :
                <Loader/>
        )
    }
}

export default BurberryNewTicketPage;
