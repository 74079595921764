import React, {Component} from 'react';
import moment from 'moment';

import EmergencyConfirmationModal from './EmergencyConfirmationModal';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import ClientService from 'worksmith/services/api/ClientService';
import MarketingService from 'worksmith/services/api/MarketingService';
import RequestForProposalService from 'worksmith/services/api/RequestForProposalService';
import UserService from 'worksmith/services/api/UserService';

import ConfirmRequestModal from "../../../pages/requests/ConfirmRequestModal";
import notification from 'antd/lib/notification';
import Select from 'antd/lib/select';
import * as custom from 'worksmith/helpers/CustomTcombTypes';
import {ErrorText} from "../../styledComponents/Basic";
import {
    AutoAcceptWalkthroughsDuringBusinessHours,
    RequestType,
    ServiceLine,
    ServiceLineType,
    WalkthroughPreference
} from "../../../Enums";
import {DeepCopy, GetKeyByValue, StartHotjarTrigger, ValueIsSet} from 'worksmith/helpers/GenericHelpers';
import {isAutomationTargeted} from 'worksmith/helpers/AutomationHelper';
import RecurrenceType from 'worksmith/enums/api/task/RecurrenceType';
import FileUpload from 'worksmith/components/FileUpload/FileUpload';
import styled from "styled-components";
import {WithContext} from "../../../context/GlobalContext";
import {Color} from "worksmith/enums/Color";
import {StringHasText} from "../../../lib-worksmith/helpers/GenericHelpers";
import Button from "worksmith/components/Button/Button.web";
import ClientLocationService from "worksmith/services/api/ClientLocationService";
import ClientRoutes from "../../../ClientRoutes";
import FrequencyPicker from "worksmith/components/FrequencyPicker/FrequencyPicker.web";
import LocationProfileQuestionsModal from "../../../pages/requests/LocationProfileQuestionsModal";
import {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import Text from "worksmith/components/Text/Text";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const clientService = new ClientService();
const clientLocationService = new ClientLocationService();
const userService = new UserService();
const requestForProposalService = new RequestForProposalService();
const authTokenManager = new AuthTokenManager();
const marketingService = new MarketingService();

const t = require('tcomb-form');
const Form = t.form.Form;
const Option = Select.Option;

const AttachmentHelpText = styled.div`
    color: #1A9BF1;
    font-size: 0.875rem !important;
    font-style: italic
    display: ${props => props.display};
    text-align: left;
`;

const EmergencyHelpText = styled.p`
    color: ${Color.RED} !important;
    font-size: 0.875rem !important;
    font-style: italic
`;

const FrequencyContainer = styled.div`
    padding-bottom: 25px;
`;

class NewRequestForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            attachmentsUploaded: false,
            attachmentsUploadError: false,
            loading: false,
            emergencyConfirmationModalVisible: false,
            emergencyAcknowledged: false,
            confirmationModalVisible: false,
            standardItems: [],
            preferredVendorName: null,
            previousVendors: {},
            files: [],
            profileModalOpen: false,
            profileQuestionTopics: [],
            recurrenceTypeModifier: null,
            recurrenceCountModifier: null,
            isEmergencyRequestDate: false,
            requestSubmitError: false,
            requestSubmitted: false,
            value: {
                requestType: props.initialType,
                requestItems: [{
                    id: 0,
                    name: "",
                }],
                emergency: null,
                requestSchedulingOption: (props.defaultRequestSchedulingOption ? props.defaultRequestSchedulingOption : "within 2 weeks"),
                walkthroughPreference: (props.defaultWalkthroughPreference ? props.defaultWalkthroughPreference : "Walkthrough Optional"),
                autoAcceptWalkthroughsDuringBusinessHours: (props.autoAcceptWalkthroughsDuringBusinessHours ? props.autoAcceptWalkthroughsDuringBusinessHours : "true"),
                onDate: [moment().format('YYYY'), null, null],
                itemizedBidRequired: true,
                excludedVendors: [],
                standardItems: [],
            }
        };

        this.getFileData = this.getFileData.bind(this);
        this.openConfirmationModal = this.openConfirmationModal.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        this.fetchPreviousVendors = this.fetchPreviousVendors.bind(this);
        this.fetchStandardItems = this.fetchStandardItems.bind(this);
        this.frequency = {
            recurrenceCount: 1,
            recurrenceType: RecurrenceType.DAILY,
            repeatEvery: 1
        };

    }

    componentDidMount() {
        this.onServiceLineChanged(this.props.selectedServiceLine.value);
        this.onLocationChanged();
    }

    shouldComponentUpdate(nextProps, nextState){
        let {selectedLocationId, selectedServiceLine} = this.props;
        let locationChangedAndNonNull = nextProps.selectedLocationId !== selectedLocationId && StringHasText(nextProps.selectedLocationId);
        let serviceLineChangedAndNonNull = nextProps.selectedServiceLine.value !== selectedServiceLine.value && StringHasText(nextProps.selectedServiceLine.value.toString());

        if(locationChangedAndNonNull) {
            this.onLocationChanged(nextProps.selectedLocationId);
        }

        if(serviceLineChangedAndNonNull){
            this.onServiceLineChanged(nextProps.selectedServiceLine.value);
        }
        return true;
    }

    // Helper Methods ------------------------------------------------------------------------------
    getFileData(fileData) {
        this.setState({files: fileData});
    }

    validateOpenModal = modalStateKey => {
        let {selectedServiceLine} = this.props;
        let {numberOfRequestPhotosRequired, numberOfEmergencyRequestPhotosRequired} = this.props.client.configurationSettings;

        if (this.refs.form.validate().isValid()) {
            if(!this.state.emergency && ValueIsSet(numberOfRequestPhotosRequired) && this.state.files.length < numberOfRequestPhotosRequired){
                this.setState({loading: false, [modalStateKey]: false});
                DisplayErrorNotification('At least ' +  numberOfRequestPhotosRequired + ' ' + Pluralize(numberOfRequestPhotosRequired, 'photo is', 'photos are')  + ' required to submit your request!').then();
                return;
            }else if( this.state.emergency && ValueIsSet(numberOfEmergencyRequestPhotosRequired) && this.state.files.length < numberOfEmergencyRequestPhotosRequired) {
                this.setState({loading: false, [modalStateKey]: false});
                DisplayErrorNotification('At least ' +  numberOfEmergencyRequestPhotosRequired + ' ' + Pluralize(numberOfEmergencyRequestPhotosRequired, 'photo is', 'photos are')  + ' required to submit your emergency request!').then();
                return;
            }else{
                if (isAutomationTargeted(GetKeyByValue(ServiceLineType, parseInt(selectedServiceLine.value)), true)) {
                    if (this.state.files.length === 0) {
                        this.setState({loading: false, [modalStateKey]: false});
                        DisplayErrorNotification('At least one photo is required for ' + ServiceLine[GetKeyByValue(ServiceLineType, parseInt(selectedServiceLine.value))].toLowerCase() + ' requests!').then();
                        return;
                    }
                }
            }
            if (this.state.value.emergency === "yes" && !this.state.emergencyAcknowledged) {
                this.setState({emergencyConfirmationModalVisible: true});
            } else {
                this.setState({[modalStateKey]: true});
            }
        } else
            notification['error']({
                message: 'Please complete all required fields!',
                style: {'zoom': '150%'}
            });
    }

    openProfileModal = () => {
        const {locationProfileData, selectedServiceLine} = this.props
        const serviceLinesForProfileModal =
            selectedServiceLine.value === ServiceLineType.ELECTRICAL_WORK ||
            selectedServiceLine.value === ServiceLineType.HANDYMAN ||
            selectedServiceLine.value === ServiceLineType.LIGHTING ||
            selectedServiceLine.value === ServiceLineType.PAINTING ||
            selectedServiceLine.value === ServiceLineType.PEST_CONTROL ||
            selectedServiceLine.value === ServiceLineType.WINDOW_WASHING

        this.setState({
            emergencyConfirmationModalVisible: false
        })
        if (!serviceLinesForProfileModal) { // if no relevant service lines, skip profile modal and move to
            this.openConfirmationModal()
        }

        const noLadderInfo = !locationProfileData.clientLocationOperationDetails.hasLadderOnSite && !locationProfileData.clientLocationOperationDetails.ladderHeightFt
        const noIndustryInfo = !locationProfileData.industry
        const noCeilingHeightInfo = locationProfileData.clientLocationFloors.length === 0 || !locationProfileData.clientLocationFloors[0].ceilingHeightFt

        switch (selectedServiceLine.value) {
            case ServiceLineType.PEST_CONTROL:
                if (noIndustryInfo) {
                    this.setState({profileQuestionTopics: ['industry']})
                    this.validateOpenModal('profileModalOpen')
                } else {
                    this.openConfirmationModal()
                }
                break;
            case ServiceLineType.HANDYMAN:
                if (noLadderInfo) {
                    this.setState({profileQuestionTopics: ['ladder']})
                    this.validateOpenModal('profileModalOpen')
                } else {
                    this.openConfirmationModal()
                }
                break;
            case ServiceLineType.WINDOW_WASHING:
                if (noLadderInfo) {
                    this.setState({profileQuestionTopics: ['ladder']})
                    this.validateOpenModal('profileModalOpen')
                } else {
                    this.openConfirmationModal()
                }
                break;
            case ServiceLineType.ELECTRICAL_WORK:
                if (noLadderInfo || noCeilingHeightInfo) {
                    let updatedTopics = []
                    if (noLadderInfo) {
                        updatedTopics.push('ladder')
                    }
                    if (noCeilingHeightInfo) {
                        updatedTopics.push('ceilingHeight')
                    }
                    this.setState({profileQuestionTopics: updatedTopics})
                    this.validateOpenModal('profileModalOpen')
                } else {
                    this.openConfirmationModal()
                }
                break;
            case ServiceLineType.LIGHTING:
                if (noLadderInfo || noCeilingHeightInfo) {
                    let updatedTopics = []
                    if (noLadderInfo) {
                        updatedTopics.push('ladder')
                    }
                    if (noCeilingHeightInfo) {
                        updatedTopics.push('ceilingHeight')
                    }
                    this.setState({profileQuestionTopics: updatedTopics})
                    this.validateOpenModal('profileModalOpen')
                } else {
                    this.openConfirmationModal()
                }
                break;
            case ServiceLineType.PAINTING:
                if (noLadderInfo || noCeilingHeightInfo) {
                    let updatedTopics = []
                    if (noLadderInfo) {
                        updatedTopics.push('ladder')
                    }
                    if (noCeilingHeightInfo) {
                        updatedTopics.push('ceilingHeight')
                    }
                    this.setState({profileQuestionTopics: updatedTopics})
                    this.validateOpenModal('profileModalOpen')
                } else {
                    this.openConfirmationModal()
                }
                break;
        }
    }

    openConfirmationModal = () => {
        this.setState({
            profileModalOpen: false,
        })
        this.validateOpenModal('confirmationModalVisible')
    };


    renderAddButton(locals) {
        return <button className="ws-btn ws-btn-primary btn-block"
                       onClick={locals.add.click}>{locals.add.label}</button>;
    }

    renderRemoveButton(button) {
        return <button className="ws-btn ws-btn-warning btn-block" onClick={button.click}>{button.label}</button>;
    }


    // Click Methods -------------------------------------------------------------------------------
    addItem() {
        let newValue = this.state.value;

        if (this.state.standardItems.length > 0)
            newValue.requestItems.push(DeepCopy(this.state.standardItems[0]));

        this.setState({value: newValue});
    }

    addAllItems(selectStandardItems) {
        let newValue = this.state.value;
        let index = -1;

        newValue.requestItems = selectStandardItems.map((item) => {
            index++;
            return ({
                id: item.id,
                name: item.name
            });
        });

        this.setState({value: newValue});

    }

    removeItem(index) {
        let newValue = this.state.value;
        newValue.requestItems.splice(index, 1);
        this.setState({value: newValue});
    }

    removeAllItems() {
        let newValue = this.state.value;
        newValue.requestItems.splice(0, newValue.requestItems.length);
        this.setState({value: newValue});
    }

    onItemSelect(itemId, index) {
        // Other was selected
        let value = this.state.value;
        let requestItems = value.requestItems;
        if (itemId == 0) {
            requestItems[index].standardItemId = 0;
        } else {

            let standardItem = this.state.standardItems.find((item) => {
                return item.id == itemId;
            });

            requestItems[index] = standardItem;

        }
        value.requestItems = requestItems;
        this.setState({value});
    }

    onServiceLineChanged(newServiceLineId){
        let {value} = this.state;
        this.fetchStandardItems(newServiceLineId);
        if (this.props.selectedLocationId) {
            this.setPreviousVendorInfo(value);
        }
        if (isAutomationTargeted(GetKeyByValue(ServiceLineType, parseInt(newServiceLineId)))) {
            value.requestType = 'on-demand';
            StartHotjarTrigger('automation_target');
        }

        this.setState({value});
    }

    onLocationChanged(){
        let {value} = this.state;
        if(ValueIsSet(this.props.selectedServiceLine)){
            this.setPreviousVendorInfo(value);
        }
    }

    onFrequencyChanged = (newFrequency) => {
        this.frequency = newFrequency;
    };

    setPreviousVendorInfo(value){
        let _this = this;
        let {selectedLocationId, selectedServiceLine} = this.props;
        this.fetchPreviousVendors(selectedLocationId, selectedServiceLine.value).then(function (data) {
            let previousVendors = data;
            let previousVendorsToObject = {};
            if (previousVendors.length) {
                previousVendorsToObject = previousVendors.reduce(function (map, obj) {
                    map[obj.id] = obj.name;
                    return map;
                }, {});
            }

            delete value.preferredVendor;
            value.usePreviousVendor = false;
            value.excludePreviousVendors = false;
            _this.setState({
                previousVendors: previousVendorsToObject,
                value: value
            });

        });
    }

    onChange(value) {
        if (value) {
            let dateChangedAndNotNull = value.onDate && value.onDate !== this.state.value.onDate;
            let requestStatusChanged = value.requestType && value.requestType !== this.state.value.requestType;

            if (dateChangedAndNotNull) {
                let enteredDate = value.onDate;
                this.state.isEmergencyRequestDate = !!custom.isEmergencyRequest(enteredDate);
            }
            if (requestStatusChanged) {
                value.emergency = 'no';
            }

            this.setState({value});
        }
    }

    closeProfileModal = () => {
        this.setState({ profileModalOpen: false, confirmationModalVisible: true})
    }

    async submit() {
        let value = this.state.value;
        let {history, selectedLocationId, selectedServiceLine, serviceLineSpecificQuestionAnswers} = this.props;
        let {marketingParams} = this.props.context;

        this.setState({loading: true});
        let locationId = parseInt(selectedLocationId, 10);

        let getExcludedVendors = () => {
            if (value.excludePreviousVendors) {
                let excludedVendors = [];
                value.excludedVendors.forEach(function (vendorId) {
                    if (vendorId)
                        excludedVendors.push({id: vendorId});
                });

                excludedVendors = excludedVendors.filter((vendor, index, self) =>
                    index === self.findIndex((v) => (
                        v.id === vendor.id
                    ))
                );

                return excludedVendors;
            } else {
                return [];
            }
        };

        // rfpItems only accept the name field so in the even we are saving a new non standard item or the name fields
        // together with preference to standard item. Also we will want to remove any items without at least one of the
        // name fields.

        let validItems = [];
        this.state.value.requestItems.forEach((item) => {
            let duplicate = undefined;
            // Creating a copy of standardItem as to prevent circular structuring
            if (this.state.standardItems && this.state.standardItems.length != 0) {
                //duplicate check
                duplicate = validItems.find((validItem) => {
                    return validItem.id === item.id;
                });
                //deep copy
                if (duplicate === undefined) {
                    item.standardItem = DeepCopy(this.state.standardItems.find((stdItem) => {
                        return stdItem.id == item.id;
                    }));
                }
            }
            if (duplicate === undefined)
                validItems.push(item);
        });

        let submitData = {
            customAnswers: serviceLineSpecificQuestionAnswers,
            description: value.description,
            location: {
                id: locationId
            },
            serviceLine: {
                id: parseInt(selectedServiceLine.value, 10)
            },
            emergency: value.emergency === "yes",
            timePreference: value.timePreference,
            type: value.requestType,
            walkthroughPreference: value.walkthroughPreference,
            requestSchedulingOption: value.requestSchedulingOption,
            autoAcceptWalkthroughsDuringBusinessHours: value.autoAcceptWalkthroughsDuringBusinessHours,
            poNumber: value.poNumber,
            itemizedBidRequired: true,
            excludedVendors: getExcludedVendors(),
            rfpItems: validItems,
            worksmithNTE: value.worksmithNTE,
            attachmentCount: ValueIsSet(this.state.files) ? this.state.files.length : 0
        };

        try {
            const mediaData = this.state.files.filter(file => !file.isMediaFile);
            await clientLocationService.addAttachments(locationId, mediaData, "");
        } catch (err) {
            console.error(err);
            await DisplayErrorNotification("Error uploading attachments.");
        }

        submitData.onDate = moment(value.onDate).toISOString();

        if (this.state.value.requestType === 'recurring') {
            submitData.frequency = this.frequency;
        }

        if (this.state.value.preferredVendor) {
            submitData.preferredVendorLocation = {id: parseInt(this.state.value.preferredVendor, 10)};
        }
        if ((value.itemizedBidRequired &&
            selectedServiceLine &&
            (!selectedServiceLine.useTicketing || (selectedServiceLine.useTicketing && validItems.length !== 0)))
            || (!value.itemizedBidRequired && selectedServiceLine)) {
            requestForProposalService.insertWithAttachments(submitData, this.state.files).then((rfp) => {
                let rfpId = rfp.id;
                this.setState({requestSubmitted: true, loading: false, attachmentsUploaded: true});
                return rfpId;
            }).then((id) => {
                if (ValueIsSet(marketingParams))
                    marketingService.trackRequestSubmission(id,
                        marketingParams.utm_campaign,
                        marketingParams.utm_content,
                        marketingParams.utm_medium,
                        marketingParams.utm_term,
                        marketingParams.utm_source)
                        .catch(() => {
                            console.log("Error occurred while saving tracking data");
                        });
                setTimeout(() => history.push(ClientRoutes.SINGLE_REQUESTS(id)), 1000);
            }).catch(() => {
                this.setState({loading: false, requestSubmitError: true});
            });
        } else {
            this.setState({loading: false, confirmationModalVisible: false});
            notification['error']({
                message: 'Standard Items need to be added!',
                style: {'zoom': '150%',}
            });
        }

        return 1;
    }

    confirmEmergency = () => {
        this.setState({emergencyAcknowledged: true}, () => this.openProfileModal());
    };

    cancelEmergency = () => {
        this.setState({emergencyConfirmationModalVisible: false});
    };

    // Fetch Request Methods -----------------------------------------------------------------------
    fetchPreviousVendors(locationId, serviceLineId) {
        return userService.findOne(authTokenManager.getUserId()).then((data) => {
            let userData = data;
            let clientId;
            let preferredVendorName;

            if (userData.clientRoles !== null) {
                clientId = userData.clientRoles[0].client.id;
            } else {
                clientId = userData.clientLocationRoles[0].clientId;
            }

            clientLocationService.getPreferredVendorLocation(locationId, serviceLineId).then((data) => {
                if(data.length > 0){
                    preferredVendorName = data[0].name;
                }
                this.setState({preferredVendorName})
            });

            return clientService.getVendorsByClientLocation(clientId, locationId, serviceLineId);
        })
    }

    fetchStandardItems(serviceLineId) {
        let _this = this;

        return userService.findOne(authTokenManager.getUserId()).then(function (data) {
            let userData = data;
            let clientId;

            if (userData.clientRoles !== null) {
                clientId = userData.clientRoles[0].client.id;
            } else {
                clientId = userData.clientLocationRoles[0].clientId;
            }

            clientService.getStandardItems(clientId, serviceLineId).then((data) => {
                let sortedData = data.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                _this.setState({standardItems: (sortedData ? sortedData : [])});
                _this.removeAllItems();
            });
        })
    }

    render() {
        let {value, standardItems, emergencyConfirmationModalVisible, preferredVendorName} = this.state;
        let {confirmEmergency, cancelEmergency, onFrequencyChanged, openProfileModal} = this;
        let {hideRequestType, hideWalkthroughPreference, locationProfileData, selectedServiceLine, selectedLocationId, client} = this.props;
        let {defaultMarket, autoAcceptWalkthroughsDuringBusinessHours, numberOfRequestPhotosRequired, numberOfEmergencyRequestPhotosRequired} = this.props.client.configurationSettings;
        let NewRequestFormFields = {};
        let optionFields = {};
        let order = [];
        let addItem = this.addItem.bind(this);
        let addAllItems = this.addAllItems.bind(this);
        let removeAllItems = this.removeAllItems.bind(this);

        //When using preferred vendors you shouldn't have to select vendors
        if (!ValueIsSet(preferredVendorName)) {
            if (Object.keys(this.state.previousVendors).length >= 1) {
                NewRequestFormFields.usePreviousVendor = t.Boolean;
                optionFields.usePreviousVendor = {
                    label: <span>
                        Use previous vendor
                        <div>
                            <i>This will allow you to chat directly with the selected vendor once the request is submitted.</i>
                        </div>
                    </span>
                };
                order.push("usePreviousVendor");

                if (this.state.value.usePreviousVendor) {
                    NewRequestFormFields.preferredVendor = t.enums(this.state.previousVendors);
                    order.push("preferredVendor");
                }

                NewRequestFormFields.excludePreviousVendors = t.Boolean;
                order.push("excludePreviousVendors");

                if (this.state.value.excludePreviousVendors) {
                    let customList = Form.templates.list.clone();
                    customList.renderAddButton = this.renderAddButton;
                    customList.renderRowButton = this.renderRemoveButton;

                    NewRequestFormFields.excludedVendors = t.list(t.maybe(t.enums(this.state.previousVendors)));
                    optionFields.excludedVendors = {
                        disableOrder: true,
                        template: customList
                    };
                    order.push("excludedVendors");
                }
            }
        }
        else {
            let preferredVendorText = (locals) => {
                return (
                    <div style={{'paddingBottom': '20px'}}>
                        <label style={{'paddingTop': '15px'}}>{locals.label}</label>
                    </div>
                )
            };

            optionFields.preferredVendorText = {
                label: "You have a preferred vendor available for " + selectedServiceLine.text + ".  Your request will be routed directly to " + preferredVendorName + ".",
                template: preferredVendorText,
            };

            NewRequestFormFields.preferredVendorText = t.list(
                t.struct({
                    preferredVendorText: t.maybe(t.String),
                }));

            order.push("preferredVendorText");
        }

        // Only applicable for dry cleaning and alterations (for now)
        if (selectedServiceLine && selectedServiceLine.useTicketing) {
            NewRequestFormFields.requestItems = t.list(
                t.struct({
                    id: t.maybe(t.Number),
                    standardItemId: t.maybe(t.String),
                    standardItemName: t.maybe(t.String),
                })
            );

            let buildSelectOptions = (standardItems) => {

                let mappedItems = standardItems.map((item) => {
                    return <Option key={item.id}
                                   value={item.id}>{item.name}</Option>
                });

                return mappedItems;
            };

            let requestItemTemplate = (locals) => {
                return (
                    <div>
                        <div className="row">
                            <div className="col-md-3">
                                {standardItems && standardItems.length > 0 ?
                                    <div>
                                        <label>Item</label>
                                        <Select style={{width: '250px', paddingLeft: '10px'}}
                                                placeholder="Standard Items"
                                                defaultValue={this.state.value.requestItems[locals.path[1]].name
                                                    ? this.state.value.requestItems[locals.path[1]].name
                                                    : this.state.standardItems[0].name}
                                                onChange={(item) => {
                                                    this.onItemSelect(item, locals.path[1]);
                                                }}
                                        >
                                            {buildSelectOptions(standardItems)}
                                        </Select>
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                )
            };

            let requestItemListTemplate = (locals) => {
                return (
                    <div className="margin-bottom-md" hidden={!this.state.value.itemizedBidRequired}>
                        <h4>{locals.label}</h4>
                        <div style={{fontSize: 12}}>
                            If you have items you typically dry clean or tailor
                            for employees and/or clients,
                            you can create a standard list by entering the items
                            and requiring the service providers to bid on each item.
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <button className="ws-btn ws-btn-info btn-block margin-top-md"
                                        style={{'width': '175px', 'height': '40px', 'textAlign': 'center'}}
                                        onClick={addItem}> Add Item
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="ws-btn ws-btn-primary btn-block margin-top-md"
                                        style={{'width': '175px', 'height': '40px', 'textAlign': 'center'}}
                                        onClick={() => addAllItems(standardItems)}> Add All Items
                                </button>
                            </div>
                            <div className="col-md-3">
                                <button className="ws-btn ws-btn-warning btn-block margin-top-md"
                                        style={{'width': '175px', 'height': '40px', 'textAlign': 'center'}}
                                        onClick={removeAllItems}> Remove All Items
                                </button>
                            </div>
                        </div>
                        {
                            locals.items.map(function (item) {
                                return (
                                    <div key={item.key}>
                                        <div className="row" key={item.key}>
                                            <div className="col-sm-10">
                                                {item.input}
                                            </div>
                                        </div>
                                        <div className="row" style={{float: 'right', marginTop: '-60px'}}>
                                            <div className="col-sm-3">
                                                {
                                                    item.buttons.map(function (button, i) {
                                                        return (
                                                            <button className="ws-btn ws-btn-warning btn-block"
                                                                    style={{width: '140px',}} key={i}
                                                                    onClick={button.click}>{button.label} Item</button>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            };

            optionFields.requestItems = {
                label: "Requested Items",
                template: requestItemListTemplate,
                disableOrder: true,
                item: {
                    template: requestItemTemplate,
                    auto: 'none',
                    fields: {
                        id: {},
                        name: {type: 'select'},
                    }
                }
            };

            order.push("requestItems");
        }

        if (!hideWalkthroughPreference) {
            let walkthroughPreferenceOptions = {
                "Walkthrough Optional": "Walkthrough Optional",
                "Walkthrough Prohibited": "Walkthrough Prohibited"
            };
            if (!isAutomationTargeted(GetKeyByValue(ServiceLineType, parseInt(selectedServiceLine.value)))) {
                walkthroughPreferenceOptions["Walkthrough Required"] = "Walkthrough Required";
            }
            NewRequestFormFields.walkthroughPreference = t.enums(walkthroughPreferenceOptions);
            optionFields.walkthroughPreference = {
                label: "Walkthrough Preference",
                nullOption: false
            };
            order.push("walkthroughPreference");
        }

        if (!hideRequestType) {

            NewRequestFormFields.requestType = t.enums({
                "on-demand": "One Time",
                "recurring": "Recurring"
            });
            optionFields.requestType = {
                label: 'Request Type',
                error: <ErrorText>Required Field</ErrorText>,
                factory: t.form.Radio
            };
            order.push("requestType");
        }

        if (value.requestType === 'on-demand') {
            NewRequestFormFields.emergency = t.enums({
                "no": "No",
                "yes": "Yes",
            });
            optionFields.emergency = {
                factory: t.form.Radio,
                label: <div>
                    <div style={{marginBottom: '-10px'}}>Is this an Emergency?<br/><EmergencyHelpText>Is this a serious
                        or dangerous issue requiring immediate attention?<br/>Emergency rates may
                        apply.</EmergencyHelpText></div>
                </div>
            };
            order.push("emergency");
        }

        if (value.emergency === 'no') {

            NewRequestFormFields.requestSchedulingOption = t.enums({
                "within a week": "Within a Week",
                "within 2 weeks": "Within 2 Weeks",
                "within a month": "Within a Month",
                "on a specific date": "On a Specific Date"
            });
            optionFields.requestSchedulingOption = {
                label: "When would you like the request to be completed by?",
                nullOption: false
            };

            order.push("requestSchedulingOption");

            if (value.requestSchedulingOption === 'on a specific date') {

                NewRequestFormFields.onDate = custom.RequestDate;
                optionFields.onDate = {
                    label: 'Preferred ' + (value.requestType === 'recurring' ? 'Start Date' : 'Date of Completion'),
                    help: this.state.isEmergencyRequestDate ?
                        <i>Target completion dates of same-day, next-day, holidays, or weekends may incur expedited
                            fees
                            for after-hours labor.</i> : '',
                    minimumDate: moment().startOf('day').toDate()
                };
                order.push("onDate");

            }
        }

        if (value.requestType === RequestType.RECURRING) {

            let frequencyPicker = (locals) => {
                return (
                    <FrequencyContainer>
                        <label style={{'paddingTop': '15px'}}>{locals.label}</label>
                        <FrequencyPicker initialFrequency={this.frequency} onChange={onFrequencyChanged}/>
                    </FrequencyContainer>
                    );
            };

            NewRequestFormFields.frequency = t.maybe(t.String);
            optionFields.frequency = {
                label: 'Frequency',
                template: frequencyPicker
            };
            order.push("frequency");
        }

        if (value.emergency === 'no') {

            NewRequestFormFields.timePreference = t.enums({
                "Before Business Hours": "Before Business Hours",
                "During Business Hours": "During Business Hours",
                "After Business Hours": "After Business Hours",
            });
            optionFields.timePreference = {
                label: 'Time Preference',
                nullOption: {value: '', text: 'Select a Time Preference'},
                error: <ErrorText>Required Field</ErrorText>,
            };
            order.push("timePreference");
        }

        if (autoAcceptWalkthroughsDuringBusinessHours === AutoAcceptWalkthroughsDuringBusinessHours.SOMETIMES && value.walkthroughPreference !== WalkthroughPreference.PROHIBITED) {
            NewRequestFormFields.autoAcceptWalkthroughsDuringBusinessHours = t.enums({
                "true": "Yes",
                "false": "No"
            });
            optionFields.autoAcceptWalkthroughsDuringBusinessHours = {
                label: 'Automatically Accept Complimentary Walkthroughs During Business Hours',
                error: <ErrorText>Required Field</ErrorText>,
                factory: t.form.Radio
            };
            order.push("autoAcceptWalkthroughsDuringBusinessHours");
        }

        NewRequestFormFields.poNumber = t.maybe(t.String);
        optionFields.poNumber = {
            label: "PO Number (optional)",
            type: 'text'
        };
        order.push("poNumber");

        NewRequestFormFields.description = t.refinement(t.String, (s) => s.length > 9);
        optionFields.description = {
            type: 'textarea',
            label: selectedServiceLine.requestDescription || 'Description',
            error: <ErrorText>A minimum of 10 characters is required</ErrorText>
        };
        order.push("description");

        NewRequestFormFields.worksmithNTE = t.maybe(t.Number);
        optionFields.worksmithNTE = {
            type: 'number',
            label: <div>
                <div>Not To Exceed (NTE) (optional)</div>
                <i>Authorizes Worksmith to approve pricing up to this amount to streamline request completion</i></div>
        };
        order.push("worksmithNTE");

        let OnDemand = t.struct(NewRequestFormFields);

        let formOptions = {
            order: order,
            fields: optionFields
        };

        let isLocksmith = Object.is(parseInt(selectedServiceLine.value, 10), ServiceLineType.LOCKSMITH);
        let isDoorRepair = Object.is(parseInt(selectedServiceLine.value, 10), ServiceLineType.DOOR_REPAIR);
        let numPhotosRequired = 0;
        if(ValueIsSet(this.state.emergency) && this.state.emergency && ValueIsSet(numberOfEmergencyRequestPhotosRequired)){
            numPhotosRequired = numberOfEmergencyRequestPhotosRequired;
        }
        else if(ValueIsSet(this.state.emergency) && !this.state.emergency && ValueIsSet(numberOfRequestPhotosRequired)){
            numPhotosRequired = numberOfRequestPhotosRequired
        }
        else {
            numPhotosRequired = isAutomationTargeted(GetKeyByValue(ServiceLineType, parseInt(selectedServiceLine.value)), true) ? 1 : 0;
        }

        return (
            <div>
                <ConfirmRequestModal
                    attachmentsUploaded={this.state.attachmentsUploaded}
                    attachmentsUploadError={this.state.attachmentsUploadError}
                    handleOk={this.submit}
                    handleCancel={() => {
                        this.setState({confirmationModalVisible: false, requestSubmitError: false})
                    }}
                    immediatelySubmit={true}
                    visible={this.state.confirmationModalVisible}
                    requestSubmitted={this.state.requestSubmitted}
                    requestSubmitError={this.state.requestSubmitError}
                    submitting={this.state.loading}/>

                <LocationProfileQuestionsModal
                    closeProfileModal={this.closeProfileModal}
                    locationId={locationProfileData && locationProfileData.id}
                    locationProfileData={locationProfileData}
                    openConfirmationModal={this.openConfirmationModal}
                    profileModalOpen={this.state.profileModalOpen}
                    profileQuestionTopics={this.state.profileQuestionTopics}
                    selectedServiceLine={selectedServiceLine.value}
                />

                <EmergencyConfirmationModal visible={emergencyConfirmationModalVisible} onConfirm={confirmEmergency}
                                            onCancel={cancelEmergency}/>
                <Form
                    ref="form"
                    type={OnDemand}
                    value={this.state.value}
                    onChange={(value) => this.onChange(value)}
                    options={formOptions}
                />
                <AttachmentHelpText display={(isLocksmith || isDoorRepair) ? 'block' : 'none'}>
                    {ValueIsSet(numPhotosRequired) && numPhotosRequired > 0 ?
                        <Text>
                            {'At least ' + numPhotosRequired + ' ' + Pluralize(numPhotosRequired, 'photo is', 'photos are') + ' required! Helpful photos include:'}
                        </Text>
                        : <div>Helpful photos include:</div>
                    }
                    <div>For doors - front and back of door, the interior/exterior edges, the door jamb, the hinges, and
                        the latch hole
                    </div>
                    <div>For locks - closeups of the hardware, the brand, the model number, and the keys</div>
                </AttachmentHelpText>
                <FileUpload
                    allowMediaLibrary
                    locationId={selectedLocationId}
                    onChange={this.getFileData}
                    multiple
                    title={'Photos & Attachments'}
                    tooltipPlacement={TooltipPlacement.BOTTOM_START}
                />
                <div className="text-right">
                    <Button
                        primary
                        onClick={openProfileModal}>
                        Submit Request
                    </Button>
                </div>
            </div>
        )
    }
}

export default WithContext(NewRequestForm);
