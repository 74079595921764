import heic2any from "heic2any";

export const convertToHEICToJPG = async (files) => {
    let convertedFiles = [];
    for (const file of files) {
        if (file.type === 'image/heic' || file.type === 'image/heif') {
            try {
                const blob = await heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                });

                const jpgFile = new File([blob], file.name.split('.')[0] + '.jpeg', { type: 'image/jpeg' });

                convertedFiles.push(jpgFile)
            } catch (error) {
                console.error("Unable to convert HEIC/HEIF file - ", error);
            }
        } else {
            convertedFiles.push(file)
        }
    };
    return convertedFiles
};