// This file was generated from com.worksmith.marketing.controller.MarketingController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class MarketingService extends Http {
    constructor() {
        super('/marketing')
    }
    
    trackClientSignup(clientId, campaign, content, medium, term, source) {
        let params = {
            clientId, 
            campaign, 
            content, 
            medium, 
            term, 
            source
        };
    
        return this.callApi( `trackClientSignup`, POST, null, params, null, true);
    }
    
    trackLogin(userId, campaign, content, medium, term, source) {
        let params = {
            userId, 
            campaign, 
            content, 
            medium, 
            term, 
            source
        };
    
        return this.callApi( `trackLogin`, POST, null, params, null, true);
    }
    
    trackRequestSubmission(requestId, campaign, content, medium, term, source) {
        let params = {
            requestId, 
            campaign, 
            content, 
            medium, 
            term, 
            source
        };
    
        return this.callApi( `trackRequestSubmission`, POST, null, params, null, true);
    }
    
    
}

export default MarketingService;
