import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import RaiseConcernForm from "./RaiseConcernForm";
import ObligationService from "../../services/api/ObligationService";
import {DialogSize} from "worksmith/enums/DialogSize";
import AlertDialog from "../../components/Dialog/AlertDialog";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";

const obligationService = new ObligationService();

class RaiseConcernDialog extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            loading: false
        };

        this.concernRaised = null;
    }

    raiseConcern = () => {
        const {concernRaised} = this;
        const {onRaiseConcern, obligationId} = this.props;
        const {valid} = this.state;

        if (valid) {
            let contestedDescription = {
                description: concernRaised,
                obligationId: obligationId
            };

            obligationService.addContestedDescription(contestedDescription)
                .then(() => {
                    if (onRaiseConcern) {
                        onRaiseConcern();
                    }
                    this.setState({loading: false});
                    DisplaySuccessNotification("Concern Raised!");
                }, () => {
                    if (onRaiseConcern) {
                        onRaiseConcern();
                    }
                    DisplayErrorNotification("Failed to Raise Concern")});
        }
    };

    onFormChange = ({concernRaised, valid}) => {
        this.concernRaised = concernRaised;

        if (this.state.valid !== valid) {
            this.setState({valid});
        }
    };

    setLoading = (loading) => {
        this.setState({loading: loading});
    };

    render() {
        let {raiseConcern, onFormChange, setLoading} = this;
        const {loading, valid} = this.state;
        const {onCancel, open} = this.props;

        return (
            <AlertDialog
                acceptDisabled={!valid || loading}
                acceptText={'Send'}
                cancelText={'Cancel'}
                onCancel={onCancel}
                onAccept={raiseConcern}
                onAcceptIgnoreDebounce={() => setLoading(true)}
                size={DialogSize.SMALL}
                title={'Raise Concern?'}
                open={open}>
                <RaiseConcernForm
                    onChange={onFormChange}
                    loading={loading}/>
            </AlertDialog>
        )
    }
}

RaiseConcernDialog.propTypes = {
    obligationId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRaiseConcern: PropTypes.func.isRequired,
    open: PropTypes.bool
};

export default RaiseConcernDialog;