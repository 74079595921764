// This file was generated from com.worksmith.psldb.controller.ServiceLineController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ServiceLineService extends Http {
    constructor() {
        super('/psldb/serviceLine')
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findAllUndeletedSimple(name, useTicketing) {
        let params = {
            name, 
            useTicketing
        };
    
        return this.callApi( `findAllUndeletedSimple`, GET, null, params, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findSimpleBusinessObjects(name, useTicketing) {
        let params = {
            name, 
            useTicketing
        };
    
        return this.callApi( `findSimpleBusinessObjects`, GET, null, params, null, true);
    }
    
    getLineItemTypes(id) {
        return this.callApi( `${id}/lineItemTypes`, GET, null, null, null, true);
    }
    
    getRfpQuestions(id) {
        return this.callApi( `${id}/rfpQuestions`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default ServiceLineService;
