import React, {Component} from 'react';
import NewUserForm from "../../components/forms/NewUserForm";

class NewUserPage extends Component {
    render(){
        return(
            <div>
                <section style={{'border': '0px', 'textAlign':'center'}} className="ws-section">
                    <h1 className="page-header">Add Users</h1>
                </section>

                <section style={{'border': '0px'}} className="ws-section">
                    <NewUserForm props={this.props} client={this.props.client}/>
                </section>
            </div>
        )
    }
}

export default NewUserPage;