import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import {TitleCase} from "../../../../helpers/LanguageHelper";
import {TextColor, TextVariant} from "../../../../components/Text/Text.web";
import Schedule from "../../../../components/Schedule/Schedule.web";
import Grid from "../../../../components/Grid/Grid.web";

const SchedulingClientSubmittedAvailability = (props) => {
    const {loading, scheduleHandler} = props;

    return (
        <RequestStateLayout description={"You will be notified once the vendor responds."}
                            loading={loading}
                            body={
                                <Grid container spacing={2}>
                                    {
                                        scheduleHandler.openScheduleOptions.map(scheduleOption => {
                                            return (
                                                <Grid item key={scheduleOption.id} xs={12}>
                                                    <Schedule
                                                        textVariant={TextVariant.BODY_1}
                                                        schedule={scheduleOption.schedule}/>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            }
                            status={TitleCase(NewRequestStatus.SCHEDULING) + ' - Availability Submitted'}
                            statusColor={TextColor.PRIMARY}/>
    )
};

SchedulingClientSubmittedAvailability.propTypes = {
    loading: PropTypes.bool,
    scheduleHandler: PropTypes.shape({
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        })),
    })
};

export default SchedulingClientSubmittedAvailability;