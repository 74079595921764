import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AlertDialog from "../../components/Dialog/AlertDialog";
import ChangeRequestApprovalType from "worksmith/enums/api/cr/ChangeRequestApprovalType";
import ChangeRequestService from "worksmith/services/api/ChangeRequestService";
import ChangeRequestType from "worksmith/enums/api/cr/ChangeRequestType";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import LinearProgress, {LinearProgressVariant} from "worksmith/components/LinearProgress/LinearProgress";
import LoadingWithTimeoutDialog from "worksmith/composite-components/LoadingWithTimeoutDialog/LoadingWithTimeoutDialog";
import MuteNotificationsModal from "worksmith/composite-components/ScheduleOptionsForm/MuteNotificationsModal";
import ObligationService from "worksmith/services/api/ObligationService";
import ObligationTemplateService from "worksmith/services/api/ObligationTemplateService";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import ScheduleOptionsForm from "./ScheduleOptionsForm";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import UserType from "worksmith/enums/api/user/UserType";
import {globalSnackbarTrigger, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const obligationService = new ObligationService();
const obligationTemplateService = new ObligationTemplateService();
const graphQlService = new GraphQLServiceClass();
const changeRequestService = new ChangeRequestService();


class CreateScheduleOptionsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            valid: false,
            showChangeOrderConfirmationModal: false,
            showUnassignCustomRecurringResourceAssignmentsWarning: false,
            muteNotificationsModalOpen: false,
            submittedSchedules: null,
            submittedApprovalType: null,
            submittingEntireRecurringServiceChangeRequest: false,
            entireRecurringServiceChangeRequestError: null,
        };

        this.scheduleOptionsFormRef = React.createRef();
        this.scheduleOptionsToSubmit = null;
    }

    onFormValidChange = (valid) => {
        this.setState({valid: valid});
    };

    onChangeOrderConfirmationModalCancel = () => {
        this.setState({showChangeOrderConfirmationModal: false})
    };

    onChangeOrderConfirmationModalAccept = () => {
        this.setState({showChangeOrderConfirmationModal: false}, () => {
            this.submit();
        });
    };

    onUnassignCustomRecurringResourceAssignmentsCancelClicked = () => {
        this.setState({showUnassignCustomRecurringResourceAssignmentsWarning: false, loading: false});
    };

    onUnassignCustomRecurringResourceAssignmentsConfirmed = () => {
        this.setState({showUnassignCustomRecurringResourceAssignmentsWarning: false}, () => {
            this.checkForOpenChangeOrdersAndSubmit();
        });
    };

    checkForWarningsAndSubmit = () => {
        const {obligationTemplate, userType} = this.props;

       if(ValueIsSet(obligationTemplate) && obligationTemplate.hasCustomRecurringResourceAssignment && userType !== UserType.CLIENT)
           this.setState({showUnassignCustomRecurringResourceAssignmentsWarning: true});
       else if (userType === UserType.WORKSMITH)
           this.checkForOpenChangeOrdersAndSubmit();
       else
           this.submit();
    };

    checkForOpenChangeOrdersAndSubmit = () => {
        let {scheduleOptionsFormRef} = this;
        let {obligationTemplate} = this.props;
        let approvalType = scheduleOptionsFormRef.current.getApprovalType();
        this.scheduleOptionsToSubmit = scheduleOptionsFormRef.current.getValue();

        if(ValueIsSet(obligationTemplate) && approvalType === 'NONE'){
            let params = {
                "obligationTemplateId": obligationTemplate.id,
                "hasOpenOrAcceptedChangeOrder": true,
                "status": "pending",
                "startDate": this.scheduleOptionsToSubmit[0].date
            };
            graphQlService.findPage(1,1,null,null,params,GraphQLObjectType.OBLIGATION,`id`).then(res => {
                if(res.totalElements > 0){
                    this.setState({showChangeOrderConfirmationModal: true});
                } else {
                    this.submit();
                }
            })
        } else {
            this.submit();
        }
    };

    submit = () => {
        const {scheduleOptionsFormRef, scheduleOptionsToSubmit} = this;
        let {isForNonWorksmithClient, showMuteNotificationDialog, rescheduleEntireService} = this.props;
        let schedules = ValueIsSet(scheduleOptionsToSubmit) ? scheduleOptionsToSubmit : scheduleOptionsFormRef.current.getValue();
        let approvalType = scheduleOptionsFormRef.current.getApprovalType();
        let mutedNotification = scheduleOptionsFormRef.current.getMutedNotifications();

        if(this.props.userType === UserType.WORKSMITH){
            showMuteNotificationDialog ? this.openMuteNotificationDialogAndStoreSchedulesAndApprovalType(schedules, approvalType) : this.props.onSubmit(schedules, approvalType, mutedNotification);
        } else if (this.props.userType === UserType.VENDOR) {
            if(isForNonWorksmithClient) {
                showMuteNotificationDialog ? this.openMuteNotificationDialogAndStoreSchedulesAndApprovalType(schedules, ChangeRequestApprovalType.NONE) : this.props.onSubmit(schedules, ChangeRequestApprovalType.NONE, true);
            } else {
                showMuteNotificationDialog ? this.openMuteNotificationDialogAndStoreSchedulesAndApprovalType(schedules, approvalType) : this.props.onSubmit(schedules, approvalType, mutedNotification);
            }
        } else {
            this.props.onSubmit(schedules);
        }
    };

    createChangeRequest = async (muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications) => {
        const {submittedSchedules, submittedApprovalType} = this.state;
        let {obligation, onCompletion, onCancel} = this.props;

        let changeRequest = {
            obligation: {
                id: obligation.id
            },
            scheduleHandler: {
                scheduleOptions: submittedSchedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        if(ValueIsSet(submittedApprovalType)) {
            changeRequest.approvalType = submittedApprovalType;
            if(submittedApprovalType === ChangeRequestApprovalType.NONE)
                changeRequest.approvalOverride = true;
        }


        const addChangeRequest = () => {
            obligationService.addChangeRequest(obligation.id, changeRequest, muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications).then(() => {
                if(ValueIsSet(onCompletion)) {
                    onCompletion(submittedApprovalType);
                }

                !this.props.dontTriggerGlobalSnackbar && globalSnackbarTrigger("Schedule submitted.");
            }).catch((error) => {
                console.error("Error adding change request - " + error);
                // noinspection JSIgnoredPromiseFromCall
                DisplayErrorNotification("Error adding change request");
            }).finally(() => {
                onCancel();
            });
        };

        if (obligation.openChangeRequests.length > 0) {
            await changeRequestService.cancel(obligation.openChangeRequests[0].id).then(() => {
                addChangeRequest();
            }).catch((error) => {
                console.error("Error deleting old change request - " + error);
                DisplayErrorNotification('Error deleting old change request')
            })
        } else {
            addChangeRequest();
        }
    };

    createChangeRequestEntireService = async (muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications) => {
        const {submittedSchedules, submittedApprovalType} = this.state;
        let {obligationTemplate, onCompletion, onCancel} = this.props;

        this.setState({submittingEntireRecurringServiceChangeRequest: true});

        let changeRequest = {
            obligationTemplate: {
                id: obligationTemplate.id
            },
            scheduleHandler: {
                scheduleOptions: submittedSchedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        if(ValueIsSet(submittedApprovalType)) {
            changeRequest.approvalType = submittedApprovalType;
            if(submittedApprovalType === ChangeRequestApprovalType.NONE)
                changeRequest.approvalOverride = true;
        }

        const addEntireRecurringServiceChangeRequest = () => {
            obligationTemplateService.addChangeRequest(obligationTemplate.id, changeRequest, muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications).then(() => {
                if(ValueIsSet(onCompletion)) {
                    onCompletion();
                }

                !this.props.dontTriggerGlobalSnackbar && globalSnackbarTrigger("Schedule submitted.");
            }).catch((error) => {
                this.setState({entireRecurringServiceChangeRequestError: error.toString()});
                console.error("Error adding change request - " + error);
                // noinspection JSIgnoredPromiseFromCall
                DisplayErrorNotification("Error adding change request");
            }).finally(() => {
                this.setState({submittingEntireRecurringServiceChangeRequest: false});
                onCancel();
            });
        };

        if (ValueIsSet(obligationTemplate.pendingChangeRequest)) {
            await changeRequestService.cancel(obligationTemplate.pendingChangeRequest.id).then(() => {
                addEntireRecurringServiceChangeRequest();
            }).catch((error) => {
                console.error("Error deleting old change request - " + error);
                DisplayErrorNotification('Error deleting old change request')
            })
        } else {
            addEntireRecurringServiceChangeRequest();
        }
    };

    openMuteNotificationDialogAndStoreSchedulesAndApprovalType = (schedules, approvalType) => {
      this.setState({
          submittedSchedules: schedules,
          submittedApprovalType: approvalType,
          muteNotificationsModalOpen: true,
      })
    };

    handleCloseMuteNotificationsModal = () => {
        this.setState({muteNotificationsModalOpen: false})
    };

    setLoading = (loading) => {
      this.setState({loading: loading})
    };

    render() {
        const {scheduleOptionsFormRef} = this;
        const {
            onFormValidChange, 
            setLoading, 
            onChangeOrderConfirmationModalAccept,
            onChangeOrderConfirmationModalCancel, 
            onUnassignCustomRecurringResourceAssignmentsConfirmed, 
            onUnassignCustomRecurringResourceAssignmentsCancelClicked,
            checkForWarningsAndSubmit,
            createChangeRequest,
            createChangeRequestEntireService,
            handleCloseMuteNotificationsModal,
        } = this;
        const {
            loading,
            valid,
            showChangeOrderConfirmationModal,
            showUnassignCustomRecurringResourceAssignmentsWarning,
            muteNotificationsModalOpen,
            submittedApprovalType,
            entireRecurringServiceChangeRequestError,
            submittingEntireRecurringServiceChangeRequest,
        } = this.state;
        const {
            adjustableFrequency,
            allowCustomScheduleCheckBox,
            cancelButtonText,
            clientLocation,
            duration,
            frequency,
            hideScheduleOptionFormNotifications,
            isForNonWorksmithClient,
            isPendingEntireRecurringServiceSchedule,
            numberOfRequiredSchedules,
            numberOfSchedules,
            obligation,
            obligationChangeRequest,
            obligationTemplate,
            onCancel,
            open,
            rescheduleEntireService,
            scheduleOptionType,
            serviceLine,
            showCurrentSchedule,
            showMuteNotificationDialog,
            submitButtonText,
            templateChangeRequest,
            title,
            userType,
            useTicketing,
            useIndeterminateLoader,
            vendor,
        } = this.props;

        let obligationHasOpenChangeOrder = ValueIsSet(obligation) && ValueIsSet(obligation.pendingChangeOrderRequest);

        return (
            (!obligationHasOpenChangeOrder || userType === UserType.WORKSMITH) ?
                <>
                    <AlertDialog
                        acceptDisabled={!valid || loading}
                        acceptText={submitButtonText}
                        cancelText={cancelButtonText}
                        debounceAccept
                        fullScreenOnMobile
                        fullWidth
                        onAccept={checkForWarningsAndSubmit}
                        onAcceptIgnoreDebounce={() => setLoading(true)}
                        onCancel={onCancel}
                        open={open}
                        title={title}>
                        <ScheduleOptionsForm
                            adjustableFrequency={adjustableFrequency}
                            allowCustomScheduleCheckBox={allowCustomScheduleCheckBox}
                            clientLocation={clientLocation}
                            duration={duration}
                            frequency={frequency}
                            isForNonWorksmithClient={isForNonWorksmithClient}
                            isPendingEntireRecurringServiceSchedule={isPendingEntireRecurringServiceSchedule}
                            numberOfRequiredSchedules={numberOfRequiredSchedules}
                            numberOfSchedules={numberOfSchedules}
                            obligation={obligation}
                            obligationChangeRequest={obligationChangeRequest}
                            obligationTemplate={obligationTemplate}
                            onValidChange={onFormValidChange}
                            ref={scheduleOptionsFormRef}
                            scheduleOptionType={scheduleOptionType}
                            serviceLine={serviceLine}
                            showCurrentSchedule={showCurrentSchedule}
                            templateChangeRequest={templateChangeRequest}
                            userType={userType}
                            useMuteNotificationDialogInstead={showMuteNotificationDialog || hideScheduleOptionFormNotifications}
                            useTicketing={useTicketing}
                            vendor={vendor}
                        />
                        {showChangeOrderConfirmationModal ?
                            <AlertDialog
                                title={"Remove Change Orders?"}
                                acceptText={"Accept"}
                                cancelText={"Cancel"}
                                open={showChangeOrderConfirmationModal}
                                onAccept={onChangeOrderConfirmationModalAccept}
                                onCancel={onChangeOrderConfirmationModalCancel}
                                onClose={onChangeOrderConfirmationModalCancel}
                            >
                                <Text variant={TextVariant.SUBTITLE_1}>
                                    There are open jobs with change orders inside your
                                    selected date range. If you’d like those jobs to reflect that pricing
                                    please ensure the change order is accepted and the job is marked complete.
                                    Otherwise, all jobs will reflect the new price you are entering here.
                                </Text>
                            </AlertDialog> : null}
                        {showUnassignCustomRecurringResourceAssignmentsWarning ?
                            <AlertDialog
                                title={"Unassign All Technicians?"}
                                acceptText={"Continue"}
                                cancelText={"Cancel"}
                                open={showUnassignCustomRecurringResourceAssignmentsWarning}
                                onAccept={onUnassignCustomRecurringResourceAssignmentsConfirmed}
                                onCancel={onUnassignCustomRecurringResourceAssignmentsCancelClicked}
                                onClose={onUnassignCustomRecurringResourceAssignmentsCancelClicked}
                            >
                                <Text variant={TextVariant.SUBTITLE_1}>
                                    This service has a custom assignment. All technicians will be unassigned if
                                    you go forward with rescheduling. Once the service is successfully rescheduled,
                                    you will need to reassign your technicians.
                                </Text>
                            </AlertDialog> : null}
                    </AlertDialog>
                    {useIndeterminateLoader ?
                        <AlertDialog
                            title={"Updating visit..."}
                            customButtons={[]}
                            open={loading}
                        >
                            <LinearProgress variant={LinearProgressVariant.INDETERMINATE}/>
                        </AlertDialog>
                        :
                        null}
                    {muteNotificationsModalOpen &&
                        <MuteNotificationsModal
                            showModal={muteNotificationsModalOpen}
                            handleSubmit={(muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications) => {
                                handleCloseMuteNotificationsModal(false);
                                rescheduleEntireService ? createChangeRequestEntireService(muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications) : createChangeRequest(muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications)
                            }}
                            hideMuteVendorNotificationCheckbox={userType === UserType.VENDOR}
                            hideMuteClientNotificationCheckbox={userType === UserType.WORKSMITH ? false : !isForNonWorksmithClient}
                            showMuteTechnicianNotificationCheckbox={ValueIsSet(obligationTemplate) ? obligationTemplate.vendor.isAggregator : obligation.vendor.isAggregator}
                            subtitle={'The client and technicians will receive a notification (unless their notification settings are turned off). Check the box below if you do not wish to send a notification.'}
                        />
                    }
                    {submittingEntireRecurringServiceChangeRequest &&
                        <LoadingWithTimeoutDialog
                            loading={submittingEntireRecurringServiceChangeRequest}
                            loadingDialogTitle={(submittedApprovalType === ChangeRequestApprovalType.NONE ? "Updating" : "Requesting") + ' recurring schedule...'}
                            error={entireRecurringServiceChangeRequestError}
                            timeoutErrorTitle={"Rescheduling is taking longer than expected"}
                            timeoutErrorBody={"This typically happens when rescheduling a daily or weekly service. " +
                                "It's likely that the service is still in the process of rescheduling so please wait " +
                                "a couple of minutes and refresh the page."}
                            otherErrorMessagePreamble={"Failed to reschedule service: "}
                        />
                    }
                </>
                :
                <AlertDialog
                    title={"You cannot request a new time for this visit"}
                    body={"In order to request a new visit time, you must either cancel the change order or wait for the client to accept or decline."}
                    acceptText={"OK"}
                    onAccept={onCancel}
                    open={open}
                />
        )
    }
};

CreateScheduleOptionsDialog.defaultProps = {
    adjustableFrequency: false,
    cancelButtonText: 'Cancel',
    submitButtonText: 'Submit',
    useTicketing: false,
    useIndeterminateLoader: false,
    isForNonWorksmithClient: false
};

CreateScheduleOptionsDialog.propTypes = {
    adjustableFrequency: PropTypes.bool,
    allowCustomScheduleCheckBox: PropTypes.bool,    // allowCustomScheduleCheckBox prop need to be passed down the component tree in every instance the CustomScheduleCheckBox option should be displayed otherwise the CustomScheduleCheckBox will not be displayed.
    cancelButtonText: PropTypes.string,
    clientLocation: PropTypes.shape({
        addressLineOne: PropTypes.string,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string,
        client: PropTypes.shape({
            nickname: PropTypes.string
        }),
        state: PropTypes.string,
        zip: PropTypes.string,
    }),
    dontTriggerGlobalSnackbar: PropTypes.bool, // Use this if you want to prevent the global snackbar from being triggered because a snackbar is triggered when onComplete runs.
    duration: PropTypes.number,
    frequency: PropTypes.shape({     // If frequency is passed in, the schedules created will be recurring
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    isForNonWorksmithClient: PropTypes.bool,
    isPendingEntireRecurringServiceSchedule: PropTypes.bool,
    numberOfRequiredSchedules: PropTypes.number,
    numberOfSchedules: PropTypes.number,
    obligation: PropTypes.shape({
        obligationDate: PropTypes.string,
        duration: PropTypes.number,
        arrivalStartTime: PropTypes.string,
        arrivalEndTime: PropTypes.string,
        pendingChangeOrderRequest: PropTypes.object
    }),
    obligationChangeRequest: PropTypes.shape({
        requestedSchedules: PropTypes.arrayOf(PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            duration: PropTypes.number,
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }))
    }),
    obligationTemplate: PropTypes.shape({
        hasJobWithPendingChangeOrder: PropTypes.bool,
        hasCustomRecurringResourceAssignment: PropTypes.bool,
        recurringScheduleTemplate: PropTypes.shape({
            duration: PropTypes.number,
            frequency: PropTypes.shape({
                recurrenceCount: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number
            }),
        })
    }),
    onCancel: PropTypes.func.isRequired,
    onCompletion: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool.isRequired,
    rescheduleEntireService: PropTypes.bool,
    scheduleOptionType: CustomPropTypes.enum(ScheduleOptionType),
    serviceLine: PropTypes.shape({
        name: PropTypes.string
    }),
    showCurrentSchedule: PropTypes.bool,
    showMuteNotificationDialog: PropTypes.bool,
    submitButtonText: PropTypes.string,
    templateChangeRequest: PropTypes.shape({
        requestedSchedules: PropTypes.arrayOf(PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            duration: PropTypes.number,
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }))
    }),
    title: PropTypes.string.isRequired,
    userType: CustomPropTypes.enum(UserType),
    useTicketing: PropTypes.bool,
    useIndeterminateLoader: PropTypes.bool,
    vendor: PropTypes.string
};

export default CreateScheduleOptionsDialog;