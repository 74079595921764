let env = {};

switch(process.env.REACT_APP_ENV) {
    case 'local-dev':
        env = {
            "PORT": 3001,
            "BASE_URL": "https://dev-core-api.worksmith.com/ws-core-service",
            "MONGO_URI": "mongodb://localhost:27017/sessions",
            "MONGO_HOST": "127.0.0.1",
            "MONGO_PORT": "27017",
            "MONGO_OPTIONS": { "db": { "safe": true } },
            "GOOGLE_REDIRECT_URL": "http://localhost:3001/authenticate/google",
            "BURBERRY_REDIRECT_URL": "http://localhost:3001/authenticate/burberry",
            "STRIPE_API_KEY": "pk_test_wxgnUi03hqaoggO2pyro6gBW",
            "HOTJAR_SITE_ID": undefined,
            "HOTJAR_SITE_VERSION": undefined,
            "INTERCOM_ID": "r1ery6lw",
            "USE_INTERCOM": false,
            "AUTH0_DOMAIN": "demo-clients.auth0.com",
            "AUTH0_CLIENT_ID": "sK9KMo5FtWkeu12mlHOhbG7uv5GTbBXk",
            "SAML_REDIRECT_URL": "http://localhost:3001/authenticate/auth0"
        };
        break;

    case 'local':
        env = {
            "PORT": 3001,
            "BASE_URL": "http://localhost:8085/ws-core-service",
            "MONGO_URI": "mongodb://localhost:27017/sessions",
            "MONGO_HOST": "127.0.0.1",
            "MONGO_PORT": "27017",
            "MONGO_OPTIONS": { "db": { "safe": true } },
            "GOOGLE_REDIRECT_URL": "http://localhost:3001/authenticate/google",
            "BURBERRY_REDIRECT_URL": "http://localhost:3001/authenticate/burberry",
            "STRIPE_API_KEY": "pk_test_wxgnUi03hqaoggO2pyro6gBW",
            "HOTJAR_SITE_ID": undefined,
            "HOTJAR_SITE_VERSION": undefined,
            "INTERCOM_ID": "r1ery6lw",
            "USE_INTERCOM": false,
            "AUTH0_DOMAIN": "demo-clients.auth0.com",
            "AUTH0_CLIENT_ID": "sK9KMo5FtWkeu12mlHOhbG7uv5GTbBXk",
            "SAML_REDIRECT_URL": "http://localhost:3001/authenticate/auth0"
        };
        break;

    case 'demo':
        env = {
            "BASE_URL": "https://demo-core-api.worksmith.com/ws-core-service",
            "MONGO_URI": "mongodb://devMongoUser:reallybadpassword@172.31.28.98:27017/sessions?authSource=admin",
            "MONGO_HOST": "127.0.0.1",
            "MONGO_PORT": "27017",
            "MONGO_OPTIONS": { "db": { "safe": true } },
            "GOOGLE_REDIRECT_URL": "https://demo-clients.worksmith.com/authenticate/google",
            "BURBERRY_REDIRECT_URL": "https://dev-clients.worksmith.com/authenticate/burberry",
            "STRIPE_API_KEY": "pk_test_wxgnUi03hqaoggO2pyro6gBW",
            "HOTJAR_SITE_ID": undefined,
            "HOTJAR_SITE_VERSION": undefined,
            "INTERCOM_ID": "r1ery6lw",
            "USE_INTERCOM": false,
            "AUTH0_DOMAIN": "demo-clients.auth0.com",
            "AUTH0_CLIENT_ID": "g3hHyxFWR8XNkmXsjOmGPheTxWnawr8u",
            "SAML_REDIRECT_URL": "https://demo-clients.worksmith.com/authenticate/auth0"
        };
        break;

    case 'qa':
        env = {
            "BASE_URL": "https://qa-core-api.worksmith.com/ws-core-service",
            "MONGO_URI": "mongodb://devMongoUser:reallybadpassword@172.31.28.98:27017/sessions?authSource=admin",
            "MONGO_HOST": "127.0.0.1",
            "MONGO_PORT": "27017",
            "MONGO_OPTIONS": { "db": { "safe": true } },
            "GOOGLE_REDIRECT_URL": "https://qa-clients.worksmith.com/authenticate/google",
            "BURBERRY_REDIRECT_URL": "https://qa-clients.worksmith.com/authenticate/burberry",
            "STRIPE_API_KEY": "pk_test_wxgnUi03hqaoggO2pyro6gBW",
            "HOTJAR_SITE_ID": undefined,
            "HOTJAR_SITE_VERSION": undefined,
            "INTERCOM_ID": "r1ery6lw",
            "USE_INTERCOM": true,
            "AUTH0_DOMAIN": "demo-clients.auth0.com",
            "AUTH0_CLIENT_ID": "g3hHyxFWR8XNkmXsjOmGPheTxWnawr8u",
            "SAML_REDIRECT_URL": "https://qa-clients.worksmith.com/authenticate/auth0"
        };
        break;

    case 'production':
        env = {
            "PORT": 3000,
            "BASE_URL": "https://core-api.worksmith.com/ws-core-service",
            "MONGO_URI": "mongodb://devMongoUser:reallybadpassword@172.31.0.43:27017/sessions?authSource=admin",
            "MONGO_HOST": "127.0.0.1",
            "MONGO_PORT": "27017",
            "MONGO_OPTIONS": { "db": { "safe": true } },
            "LOGGING_CONFIG": "logging-prod.json",
            "GOOGLE_REDIRECT_URL": "https://clients.worksmith.com/authenticate/google",
            "BURBERRY_REDIRECT_URL": "https://clients.worksmith.com/authenticate/burberry",
            "STRIPE_API_KEY": "pk_live_8EStnRQIVw7MRa30qppLjxjI",
            "HOTJAR_SITE_ID": "1383145",
            "HOTJAR_SITE_VERSION": "6",
            "INTERCOM_ID": "i50src3z",
            "USE_INTERCOM": true,
            "AUTH0_DOMAIN": "worksmith-clients.us.auth0.com",
            "AUTH0_CLIENT_ID": "enHIp7em4xxdHmY0hmFbMPUXR8R3wb4e",
            "SAML_REDIRECT_URL": "https://clients.worksmith.com/authenticate/auth0"
        }
}

export const config = env;
