import React, {Component} from 'react';
import StatusBadge from "./StatusBadge";
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification'
import Loader from "./Loader";
import Modal from 'react-modal';
import ChangeRequestCounterForm from "./forms/change-requests/ChangeRequestCounterForm";
import WSButton from '../components/Button';
import Duration from "../shared/components/Duration/Duration";
import ChangeRequestService from 'worksmith/services/api/ChangeRequestService';
import {WithContext} from "../context/GlobalContext";

const changeRequestService = new ChangeRequestService();

class ChangeRequestList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: props.filter || '',
            changeRequests: [],
            counteringChangeRequest: null,
            changeRequestCounterModalVisible: false,
            loading: true
        };

        this.acceptChangeRequest = this.acceptChangeRequest.bind(this);
        this.counterChangeRequestModal = this.counterChangeRequestModal.bind(this);
        this.cancelChangeRequest = this.cancelChangeRequest.bind(this);
        this.getOpenChangeRequests = this.getOpenChangeRequests.bind(this);
    }

    componentDidMount() {
       let {showCampaigns} = this.props.context;
       let _this = this;

       this.setState({showCampaigns}, () => _this.getOpenChangeRequests());
    }

    getOpenChangeRequests(){
        let {showCampaigns} = this.state;
        let _this = this;
        this.setState({ loading: true });

        let associatedWithCampaign = showCampaigns ? null : false;

        changeRequestService.getOpenChangeRequests(associatedWithCampaign).then(function (data) {
            _this.setState({changeRequests: data, loading: false});
        });
    }

    acceptChangeRequest(changeRequestId) {
        let _this = this;

        changeRequestService.accept(changeRequestId).then(function(data){
            _this.getOpenChangeRequests();
            notification['success']({
                message: 'New schedule confirmed!'
            })
        })
    }

    counterChangeRequestModal(changeRequest) {
        this.setState({ changeRequestCounterModalVisible: true, counteringChangeRequest: changeRequest });
    }

    cancelChangeRequest(changeRequestId) {
        let _this = this;

        changeRequestService.cancel(changeRequestId).then(function(data){
            _this.getOpenChangeRequests();
            notification['success']({
                message: 'Request Canceled!'
            })
        })
    }

    render() {
        let {changeRequests, loading} = this.state;

        const dropdownMenu = (changeRequest) => {
            let needsClientInput = changeRequest.status === 'needs client input';
            let createdByClient = changeRequest.creatorType === 'Client';
            return (
                <Menu>
                    {needsClientInput && !changeRequest.expired ?
                        <Menu.Item key="1">
                            <Popconfirm placement="top" title={'Are you sure you want to accept this change?'}
                                        onConfirm={() => this.
                                        acceptChangeRequest(changeRequest.id)} okText="Yes" cancelText="No">
                                Accept Change
                            </Popconfirm>
                        </Menu.Item> : null}
                    {needsClientInput ?
                        <Menu.Item key="2">
                                <span onClick={() => this.counterChangeRequestModal(changeRequest)}>Provide Alternate Schedule</span>
                        </Menu.Item> : null}
                    {createdByClient ?
                        <Menu.Item key="3">
                            <Popconfirm placement="top" title={'Are you sure you want to cancel?'}
                                        onConfirm={() => this.cancelChangeRequest(changeRequest.id)} okText="Yes" cancelText="No">
                                Cancel Request
                            </Popconfirm>
                        </Menu.Item> : null}
                </Menu>
            )
        };

        if (!loading) {
            return (
                <section className={'ws-section'}>
                    <p className="subheader">Requests For Schedule Change <small className={'hidden-md-up'}><em>(scroll right to see more)</em></small></p>
                    <Modal
                        isOpen={this.state.changeRequestCounterModalVisible}
                        contentLabel="Modal">
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <WSButton
                                    message={'Close'}
                                    type={'link'}
                                    onPress={() => this.setState({changeRequestCounterModalVisible: false})}/>
                            </div>
                        </div>
                        <ChangeRequestCounterForm onSuccess={() => this.setState({ changeRequestCounterModalVisible: false })} changeRequestToCounter={this.state.counteringChangeRequest}/>
                    </Modal>

                    <div className="table-responsive">
                        {changeRequests.length ?
                            <table className="table ws-table">
                                <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Vendor</th>
                                    <th>Location</th>
                                    <th>Current Schedule</th>
                                    <th>Requested Schedule</th>
                                    <th>Estimated Duration</th>
                                    <th>Action(s)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {changeRequests.map(function (changeRequest, idx) {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <StatusBadge status={changeRequest.status}/>
                                            </td>
                                            <td>{changeRequest.obligationTemplate ? changeRequest.obligationTemplate.vendorLocation.name : changeRequest.obligation.vendorLocation.name}</td>
                                            <td>{changeRequest.obligationTemplate ? changeRequest.obligationTemplate.clientLocation.name : changeRequest.obligation.clientLocation.name}</td>
                                            <td>{changeRequest.currentState}</td>
                                            <td>{ changeRequest.requestedStates.map((state, index) => {
                                                return <div key={index}>{state}</div>
                                            })}</td>
                                            <td><Duration duration={changeRequest.duration}/></td>
                                            <td>
                                                <Dropdown overlay={dropdownMenu(changeRequest)}>
                                                    <Button style={{marginLeft: 8}}>
                                                        Action <Icon type="down"/>
                                                    </Button>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table> : <p>No schedule changes to show.</p>}
                    </div>
                </section>
            )
        } else {
            return (
                <section className="ws-section">
                    <Loader/>
                </section>
            )
        }
    }
}

export default WithContext(ChangeRequestList);