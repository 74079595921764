import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';
import ReactDOM from "react-dom";
import t from 'tcomb-form';
import {Color, LineItemTypeId} from "../../../Enums";
import {ValueIsSet} from "../../../Helpers";

const Form = t.form.Form;

const dryCleanGroupName = "Dry Cleaning";

class TicketItemsForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            formData: ValueIsSet(props.currentItemsValue) ? this.readFromInitial(props.currentItemsValue) : {},
            formStruct: {},
            formOptions: {}
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        let {standardPricingsGroupedByItemType, itemsWithMultipleAlterationsOption} = this.props;
        let {formData} = this.state;

        let formStruct = {};
        let formOptions = {
            fields: {}
        };

        itemsWithMultipleAlterationsOption.forEach(itemType => {
            formOptions.fields[itemType] = {
                template: itemsListTemplate(false),
                item: {
                    template: itemTemplate,
                    fields: {}
                }
            };
            if (!ValueIsSet(formData[itemType]) || formData[itemType].length === 0)
                formData[itemType] = [undefined];

            let alterationOptions = standardPricingsGroupedByItemType[itemType];

            let alterationsOptionsStruct = {};
            Object.keys(alterationOptions).forEach((option) => {
                if (option !== 'standardPricingId') {
                    let fieldName = option;
                    alterationsOptionsStruct[fieldName] = t.Boolean;
                    formOptions.fields[itemType].item.fields[fieldName] = {
                        template: checkBoxTemplate
                    };
                }
            });

            let itemStruct = t.struct(alterationsOptionsStruct);

            formStruct[itemType] = t.list(itemStruct);
        });

        //Dry Clean Options
        let dryCleanOptionsExist = false;
        let dryCleanOptionsStruct = {};
        Object.keys(standardPricingsGroupedByItemType).forEach((type) => {
            if(!itemsWithMultipleAlterationsOption.includes(type) && ValueIsSet(standardPricingsGroupedByItemType[type][type]) && standardPricingsGroupedByItemType[type][type].standardItem.lineItemType.id === LineItemTypeId.DRY_CLEAN) {
                if (!dryCleanOptionsExist) {
                    formOptions.fields[dryCleanGroupName] = {
                        template: itemsListTemplate(true),
                        item: {
                            template: itemTemplate,
                            fields: {}
                        }
                    };

                    dryCleanOptionsExist = true;
                }

                let fieldName = type;
                dryCleanOptionsStruct[fieldName] = t.Boolean;
                formOptions.fields[dryCleanGroupName].item.fields[fieldName] = {
                    template: checkBoxTemplate
                };
            }
        });

        if (dryCleanOptionsExist) {
            if (!ValueIsSet(formData[dryCleanGroupName]) || formData[dryCleanGroupName].length === 0)
                formData[dryCleanGroupName] = [undefined];
            formStruct[dryCleanGroupName] = t.list(t.struct(dryCleanOptionsStruct));
        }

        this.setState({formStruct : t.struct(formStruct), formOptions, formData: formData});
    }

    readFromInitial = (obligationItems) => {
        let formData = {};

        obligationItems.forEach((item) => {
            if (ValueIsSet(item.alterations)) {
                let itemType = item.title.split('-')[0];

                if (!ValueIsSet(formData[itemType]))
                    formData[itemType] = [];

                let alterations = item.alterations.replace('- ', '').replace(/\n/g, '').split('- ');
                let formattedItem = alterations.reduce((formattedItem, alteration) => {
                    formattedItem[alteration] = true;

                    return formattedItem;
                }, {});

                formData[itemType].push(formattedItem);
            } else if (item.lineItemTypeId === 1) {
                if (!ValueIsSet(formData[dryCleanGroupName]))
                    formData[dryCleanGroupName] = [{}];

                formData[dryCleanGroupName][0][item.title] = true;
            }
        });

        return formData;
    };

    onFormChange = (value) => {
        this.setState({formData: value});
    };

    // Creates an obligation item object for non-dry cleaning items.
    // If an item with the same title already exists in currentItemsValue, its properties will be spread onto the new obligation item to ensure persistence of filled-out fields.
    processNonDryCleanItem = (item, name) => {
        let {standardPricingsGroupedByItemType, currentItemsValue} = this.props;

        if (!ValueIsSet(item) || !Object.keys(item).length) {
            return null;
        }

        let atLeastOneTypeSelected = false;
        let alterationsDescription = '';
        let lineItemTypeId = null;
        let standardPricingIds = [];

        Object.keys(item).forEach((type) => {
            if (item[type]) {
                atLeastOneTypeSelected = true;
                alterationsDescription += `- ${type}\n`;
                lineItemTypeId = standardPricingsGroupedByItemType[name][type].standardItem.lineItemType.id;
                standardPricingIds.push(standardPricingsGroupedByItemType[name][type].id);
            }
        });

        if (!atLeastOneTypeSelected) {
            return null;
        }
        const currentItem = ValueIsSet(currentItemsValue) ? currentItemsValue.find(ci => ci.title.includes(name)) : null;

        if (currentItem) {
            return {
                ...currentItem,
                alterations: alterationsDescription,
                standardPricingId: standardPricingsGroupedByItemType[name].standardPricingId,
                title: name + '- Multiple Alterations',
                standardPricingIds
            };
        } else {
            return {
                alterations: alterationsDescription,
                lineItemTypeId: lineItemTypeId,
                quantity: 1,
                standardPricingId: standardPricingsGroupedByItemType[name].standardPricingId,
                title: `${name}- Multiple Alterations`,
                standardPricingIds
            };
        }
    };

    // Creates obligation item objects for dry cleaning items.
    // If an item with the same title already exists in currentItemsValue, it will be returned with its properties. Otherwise, a new obligation item with default values will be created.
    processDryCleanItems = (combinedItems) => {
        let {standardPricingsGroupedByItemType, currentItemsValue} = this.props;

        if (!ValueIsSet(combinedItems) || !Object.keys(combinedItems).length) {
            return [];
        }

        return Object.keys(combinedItems)
            .filter(item => combinedItems[item])
            .map(item => {
                const currentItem = ValueIsSet(currentItemsValue) ? currentItemsValue.find(psi => psi.title === item) : false;

                return currentItem
                    ? { ...currentItem,
                        lineItemTypeId: standardPricingsGroupedByItemType[item][item].standardItem.lineItemType.id,
                    }
                    : {
                        lineItemTypeId: standardPricingsGroupedByItemType[item][item].standardItem.lineItemType.id,
                        quantity: 1,
                        standardPricingId: standardPricingsGroupedByItemType[item][item].id,
                        title: item
                    };
            });
    };

    onOk = () => {
        let {closeModal, processNonDryCleanItem, processDryCleanItems} = this;
        let {formData} = this.state;
        let {onSubmit} = this.props;

        let obligationItems = [];

        Object.keys(formData).forEach((name) => {
            if (formData[name].length > 0) {
                if (name !== dryCleanGroupName) {
                    obligationItems.push(
                        ...formData[name]
                            .map(item => processNonDryCleanItem(item, name))
                            .filter(item => item !== null)
                    );
                } else {
                    obligationItems.push(
                        ...formData[name].flatMap(combinedItems => processDryCleanItems(combinedItems, name))
                    );
                }
            }
        });

        onSubmit(obligationItems);
        closeModal();
    };

    closeModal = () => {
        ReactDOM.render(null, document.getElementById('form-modal'));
    };

    render() {
        let {formRef, onFormChange, onOk, closeModal} = this;
        let {formStruct, formOptions} = this.state;
        let {} = this.props;

        return (
            <Modal title={<h3>Items</h3>}
                   width={'90%'}
                   visible
                   onOk={onOk}
                   onCancel={closeModal}>
                <Form ref={formRef}
                      type={formStruct}
                      value={this.state.formData}
                      onChange={(value) => onFormChange(value)}
                      options={formOptions}
                />
            </Modal>
        );
    }
}

TicketItemsForm.propTypes = {
    currentItemsValue: PropTypes.object,
    itemsWithMultipleAlterationsOption: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func.isRequired,
    standardPricingsGroupedByItemType: PropTypes.object.isRequired
};

export default TicketItemsForm;

const checkBoxTemplate = (locals) => {
    return <div className={'col-md-3'}>
        <label>
            <input checked={locals.value} onClick={(event) => locals.onChange(event.target.checked)} type={'checkbox'} style={{marginRight: '5px'}} {...locals.attrs}/>
            {locals.label}
        </label>
    </div>
};

const itemsListTemplate = (disableAdd) => {
    return (locals) => {
        return <div style={{marginBottom: '35px'}}>
            {
                locals.items.map((item) => {
                    return (<Fragment>
                            <h4>{locals.label.toUpperCase()}</h4>
                            <div className="row">
                                {item.input}
                            </div>
                        </Fragment>
                    )
                })
            }
            {disableAdd ?
                null
                :
                <button style={{fontSize: '18px', borderWidth: 0, color: Color.BLUE}}
                        onClick={locals.add.click}>Add +</button>
            }
        </div>
    }
};

const itemTemplate = (locals) => {
    return (
        <div className="row" style={{margin: '5px 10px 20px 30px', width: '100%'}}>
            {
                Object.keys(locals.inputs).map((key) => {
                    return locals.inputs[key];
                })
            }
        </div>
    )
};