import React from 'react';
import PropTypes from 'prop-types';

import AlertExpandableSection from "worksmith/components/ExpandableSection/AlertExpandableSection";
import Box from "@mui/material/Box";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import {Color} from "worksmith/enums/Color";


const SignOffExpansionPanel = ({sidePanel}) => {

    return (
        <AlertExpandableSection
            initialExpanded
            collapsibleProps={{
                unmountOnExit: false
            }}
            title={ <Grid container margin={sidePanel ? "0 0 0 1rem" : "0 0 0 0" }>
                         <Icon iconColor={Color.VENDOR_GREY} name={"AssignmentOutlined"}/>
                         <Text variant={TextVariant.BODY_1}> Awaiting Sign Off</Text>
                     </Grid>}>
            <Box mt={3} ml={1} mr={2} >
                <Grid container margin={"0 0 0 1rem"}>
                    <Grid xs={12} margin={"0 0 1rem 0"}>
                        <Text color={TextColor.SECONDARY}>Use the actions button to sign off. After
                            48 hours from the time of completion,
                            the job will automatically sign off for you.</Text>
                    </Grid>
                </Grid>
            </Box>
        </AlertExpandableSection>
    );
};

SignOffExpansionPanel.propTypes = {
    sidePanel: PropTypes.bool
};

export default SignOffExpansionPanel;