import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {isEqual} from "lodash";

import FilterChip from "../FilterChip";
import Multiselect, {MultiselectType} from "../../Inputs/Multiselect/Multiselect";
import {OverflowOption} from "../../../enums/CSSEnums";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Skeleton, {SkeletonAnimation, SkeletonVariant} from 'worksmith/components/Skeleton/Skeleton';

const ListContainer = styled.div`
    max-height: 350px;
    overflow: ${OverflowOption.AUTO};
`;

const MultiselectFilterChip = ({initialSelected, options, disabled, loading, includeSelectAll, selectAllLabel, title, onChange, updatePopoverFieldButtons}) => {
    const multiselectRef = React.createRef();
    const [selected, setSelected] = useState([]);
    const [selectedGroupsState, setSelectedGroupsState] = useState([]);
    const [optionsChanged, setOptionsChanged] = useState(false);

    useEffect(() => {
        if (initialSelected) {
            let selectedGroups = getInitialGroupsFromInitialValues(initialSelected, options);
            setSelected(initialSelected);
            setSelectedGroupsState(selectedGroups);
        }
        if (!initialSelected || (initialSelected && initialSelected.length === 0)) {
            setSelected([]);
        }
    }, [initialSelected]);

    useEffect(() => {
        if (optionsChanged) {
            onChange(selected);
            setOptionsChanged(false);
        }
    }, [selected]);

    const onClear = (apply) => {
        if (apply) {
            setSelectedGroupsState([]);
            setSelected([]);
        } else {
            multiselectRef.current.clear();
        }
    };

    const onApply = async () => {
        const {selectedOptions, selectedGroups} = multiselectRef.current.getValue();
        if (!(isEqual(selected, selectedOptions) && isEqual(selectedGroupsState, selectedGroups))) {
            setSelected(selectedOptions.slice());
            setSelectedGroupsState(selectedGroups.slice());
            setOptionsChanged(true);
        }
    };

    const getInitialGroupsFromInitialValues = (initialSelected, options) => {
        let initialGroups = [];

        let initialSelectedOptions = options.filter(option => initialSelected.indexOf(option.value) >= 0);

        initialSelectedOptions.forEach(selectedOption => {
            if (ValueIsSet(selectedOption.group) && initialGroups.indexOf(selectedOption.group) < 0) {
                let groupIsChecked = options.reduce((isChecked, currentOption) => {
                    if (isChecked) {
                        return (currentOption.group !== selectedOption.group || initialSelected.indexOf(currentOption.value) >= 0)
                    } else {
                        return isChecked;
                    }
                }, true);
                if (groupIsChecked) {
                    initialGroups.push(selectedOption.group);
                }
            }
        });
        return initialGroups;
    };

    if (loading) {
        return (
            <Skeleton
                animation={SkeletonAnimation.WAVE}
                chip
                height={32}
                variant={SkeletonVariant.RECTANGLE}
                width={120}
            />
        )
    }

    return (
        <FilterChip
            active={selected.length > 0}
            disabled={disabled}
            filterTitle={title}
            onApply={onApply}
            onClear={onClear}
            selected={selected}
        >
            <ListContainer>
                <Multiselect
                    includeSelectAll={includeSelectAll}
                    selectAllLabel={selectAllLabel}
                    ref={multiselectRef}
                    initialValue={selected}
                    type={MultiselectType.CHECKBOX}
                    options={options}
                    onChange={updatePopoverFieldButtons}
                />
            </ListContainer>
        </FilterChip>
    )
}

MultiselectFilterChip.defaultProps = {
    disabled: false,
    includeSelectAll: false,
    selectAllLabel: "Select all",
    selectAllTitle: "All"
};

MultiselectFilterChip.propTypes = {
    disabled: PropTypes.bool,
    includeSelectAll: PropTypes.bool,
    initialSelected: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
            group: PropTypes.string
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    selectAllLabel: PropTypes.string,
    selectAllTitle: PropTypes.string
};

export default MultiselectFilterChip;
