import React from 'react';
import FrequencyPropType from "worksmith/custom-prop-types/FrequencyPropType";
import {FrequencyString} from "worksmith/helpers/LanguageHelper";
import Text from "../Text/Text.web";

function Frequency(props) {
    return <Text>{FrequencyString(props.frequency)}</Text>;
}

Frequency.propTypes = {
    frequency: FrequencyPropType.isRequired
};

export default Frequency;