// This file was generated from com.worksmith.task.controller.ObligationTemplateController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ObligationTemplateService extends Http {
    constructor() {
        super('/task/obligationTemplate')
    }
    
    addChangeRequest(id, changeRequest, muteClientNotifications, muteVendorNotifications, muteResourceNotifications) {
        let params = {
            muteClientNotifications, 
            muteVendorNotifications,
            muteResourceNotifications,
        };
    
        return this.callApi( `${id}/changeRequests`, POST, changeRequest, params, null, true);
    }
    
    addInternalNote(params) {
        return this.callApi( `addInternalNote`, POST, params, null, null, true);
    }
    
    addObligationItemTemplate(params) {
        return this.callApi( `addObligationItemTemplate`, POST, params, null, null, true);
    }
    
    cancelObligationTemplate(id, cancellationDetails, muteClientNotifications, muteVendorNotifications, muteResourceNotifications) {
        let params = {
            muteClientNotifications, 
            muteVendorNotifications,
            muteResourceNotifications
        };
    
        return this.callApi( `${id}/cancel`, DELETE, cancellationDetails, params, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    extend(id) {
        return this.callApi( `${id}/extend`, PATCH, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findStandardPricing(clientLocationId, vendorLocationId) {
        return this.callApi( `findStandardPricing/${clientLocationId}/${vendorLocationId}`, GET, null, null, null, true);
    }
    
    getClientObligationTemplateCards(params) {
        return this.callApi( `getClientObligationTemplateCards`, POST, params, null, null, true);
    }
    
    getStandardItems(clientId) {
        return this.callApi( `getStandardItems/${clientId}`, GET, null, null, null, true);
    }
    
    getSummary(id) {
        return this.callApi( `${id}/summary`, GET, null, null, null, true);
    }
    
    getVendorObligationTemplateCards(params) {
        return this.callApi( `getVendorObligationTemplateCards`, POST, params, null, null, true);
    }
    
    getVendorReschedulingRecurringCount(vendorId) {
        return this.callApi( `${vendorId}/getVendorReschedulingRecurringCount`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    removeObligationItemTemplate(params) {
        return this.callApi( `removeObligationItemTemplate`, POST, params, null, null, true);
    }
    
    summaries(activeOnly, vendorLocationId, vendorIds, clientIds, clientLocationIds, serviceLineId, rfpId, statuses) {
        let params = {
            activeOnly, 
            vendorLocationId,
            serviceLineId,
            vendorIds,
            clientIds,
            clientLocationIds,
            rfpId, 
            statuses
        };

        return this.callApi( `summaries`, POST, params, null, null, true);
    }
    
    swapObligation(obligationId, obligationTemplateId, swapReasonId, newVendorLocationId, items) {
        return this.callApi( `${obligationId}/${obligationTemplateId}/swap/${newVendorLocationId}/${swapReasonId}`, POST, items, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    updateItems(params) {
        return this.callApi( `updateItems`, POST, params, null, null, true);
    }
    
    
}

export default ObligationTemplateService;
