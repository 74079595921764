import React, {Component} from 'react';
import moment from "moment";
import PropTypes from 'prop-types';

import CircularProgress from "worksmith/components/Loader/CircularProgress";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import FreeFormTextModal from "worksmith/components/FreeFormTextModal/FreeFormTextModal";
import MuteNotificationsModal from "worksmith/composite-components/ScheduleOptionsForm/MuteNotificationsModal";
import ObligationService from "worksmith/services/api/ObligationService";
import UserType from "worksmith/enums/api/user/UserType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {DisplayErrorNotification, DisplaySuccessNotification} from "worksmith/helpers/SweetAlertHelpers";

const obligationService = new ObligationService();

class CancelObligationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            muteNotificationsModalOpen: false,
            cancellationReason: null,
            canceling: false,
        };
    }

    cancelObligation = (muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications) => {
        this.setState({canceling: true});
        const {isWalkthrough, onClose, onCompletion, obligationId} = this.props;
        const {cancellationReason} = this.state;

        const cancellationDetails = {
            cancellationReason,
            effectiveDate: moment().format(MomentFormat.ISODate)
        };

        return obligationService.cancelObligation(obligationId, cancellationDetails, muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications).then(() => {
            if(ValueIsSet(onCompletion))
                onCompletion();

            DisplaySuccessNotification(isWalkthrough ? "Walkthrough Canceled!" : "Job Canceled!");
        }).catch((error) => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification(error.toString());
        }).finally(() => {
            this.setState({canceling: false});
            onClose();
        });
    };

    handleMuteNotificationsModalOpen = (show) => {
        this.setState({muteNotificationsModalOpen: show})
    };

    submitCancellationReason = (cancellationReason) => {
        this.setState({
            muteNotificationsModalOpen: true,
            cancellationReason: cancellationReason,
        });
    };

    handleMuteNotificationsModalOpen = (show) => {
        this.setState({muteNotificationsModalOpen: show})
    };

    submitCancellationReason = (cancellationReason) => {
        this.setState({
            muteNotificationsModalOpen: true,
            cancellationReason: cancellationReason,
        });
    };

    render() {
        const {cancelObligation, handleMuteNotificationsModalOpen, submitCancellationReason} = this;
        const {muteNotificationsModalOpen, canceling} = this.state;
        const {onClose, open, locationName, serviceDate, serviceLineName, isAggregator, isForNonWorksmithClient, supportingText, userType} = this.props;

        return (
            <>
                <FreeFormTextModal
                    onClose={onClose}
                    onSubmit={(cancellationReason) => submitCancellationReason(cancellationReason)}
                    open={open}
                    validationError={'The cancellation reason cannot be empty'}
                    submitButtonText={canceling ? <CircularProgress size={24}/> : 'Yes, Cancel'}
                    cancelButtonText={'No, Go Back'}
                    supportingText={supportingText ? supportingText : 'The ' + serviceLineName + ' visit at ' + locationName + ' on ' + serviceDate.format(MomentFormat.MonthDayYearSlash) + ' will be canceled. Please provide a reason for canceling the visit.'}
                    question={'Cancel Visit?'}
                    textFieldPlaceholder={'Why are you canceling this job?'}
                    inputLabel={'Cancellation reason'}
                />
                {muteNotificationsModalOpen &&
                    <MuteNotificationsModal
                        showModal={muteNotificationsModalOpen}
                        handleSubmit={(muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications) => {
                            handleMuteNotificationsModalOpen(false);
                            cancelObligation(muteClientNotifications, muteVendorNotifications, muteTechnicianNotifications)
                        }}
                        hideMuteVendorNotificationCheckbox={(userType === UserType.VENDOR)}
                        hideMuteClientNotificationCheckbox={!isForNonWorksmithClient}
                        showMuteTechnicianNotificationCheckbox={isAggregator}
                        subtitle={'The client and technicians will receive a notification (unless their notification settings are turned off). Check the box below if you do not wish to send a notification.'}
                    />
                }
            </>
        )
    }
}

CancelObligationModal.propTypes = {
    isWalkthrough:      PropTypes.bool,
    locationName:       PropTypes.string.isRequired,
    obligationId:       CustomPropTypes.stringOrNumber.isRequired,
    onClose:            PropTypes.func.isRequired,
    onCompletion:       PropTypes.func,
    open:               PropTypes.bool.isRequired,
    serviceDate:        CustomPropTypes.moment,
    serviceLineName:    PropTypes.string.isRequired,
    isAggregator:       PropTypes.bool,
    isForNonWorksmithClient: PropTypes.bool.isRequired,
    supportingText:     PropTypes.string,
    userType:           CustomPropTypes.enum(UserType),
};

export default CancelObligationModal;