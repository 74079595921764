import React from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import DeclineBidForm from "./DeclineBidForm";
import Text from "../../components/Text/Text.web";
import AlertDialog from "../../components/Dialog/AlertDialog";
import {DialogSize} from "worksmith/enums/DialogSize";

const DeclineBidDialogView = (props) => {
    let {
        bidId,
        cancelRequest,
        declineBid,
        declineReason,
        isEstimate,
        loading,
        onCancel,
        onFormChange,
        open,
        setLoading,
        valid} = props;

    return (
        <AlertDialog
            acceptDisabled={!valid || loading}
            acceptText={'Submit'}
            debounceAccept
            fullWidth
            fullScreenOnMobile
            onAcceptIgnoreDebounce={() => setLoading(true)}
            onAccept={declineReason && declineReason.id === 4 ? cancelRequest : declineBid}
            onCancel={onCancel}
            size={DialogSize.SMALL}
            title={`Decline ${isEstimate ? 'Estimate' : 'Bid'}?`}
            open={open}>
                <DeclineBidForm
                    bidId={bidId}
                    onChange={onFormChange}
                    loadingForm={loading}/>
                {
                    declineReason && declineReason.id === 4 ?
                        <Box>
                            <Text>
                                This will decline all existing bids/estimates and cancel the request.
                            </Text>
                        </Box>
                        :
                        null
                }
        </AlertDialog>
    )
};

DeclineBidDialogView.propTypes = {
    bidId: PropTypes.number,
    cancelRequest: PropTypes.func.isRequired,
    declineBid: PropTypes.func,
    declineReason: PropTypes.object,
    isEstimate: PropTypes.bool,
    loading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    open: PropTypes.bool,
    setLoading: PropTypes.func.isRequired,
    valid: PropTypes.bool
};

export default DeclineBidDialogView;