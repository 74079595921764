import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {TextAlign} from "worksmith/enums/CSSEnums";
import Loader from "worksmith/components/Loader/Loader";
import SingleMetricPieChart from "worksmith/components/DataVisualization/PieChart/SingleMetricPieChart";
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import {AdminRoutes} from "../../../shared/enums/Enums";
import Link from "worksmith/components/Link/Link";

const SLASummaryLine = (props) => {
    const {
        slaText,
        nonCompliantCount,
        totalCount,
        loading,
        relatedObjectDescription,
        showNoncompliant,
        complianceRate,
        slaType,
        clientId,
        slaPeriodStartDate,
        slaPeriodEndDate,
        showNoncomplianceLinks} = props;

    const slaComplianceValue = totalCount === 0 ? 0 : Math.floor(((totalCount - nonCompliantCount)/totalCount) * 100);

    return (
        <Grid item container xs={12}>
            <Grid item container spacing={1} xs={8}>
                <Grid item xs={12}>
                    <Text variant={TextVariant.BODY_2}>
                        {slaText}
                    </Text>
                </Grid>
                <Grid item xs={12}>
                    <Text renderSkeleton={loading} variant={TextVariant.SUBTITLE_2} bold>
                        {(totalCount ? totalCount : 0) + " total " + Pluralize(totalCount,relatedObjectDescription)}
                    </Text>
                </Grid>
                {showNoncompliant ?
                    <Grid item xs={12}>
                        <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>
                            {
                                nonCompliantCount ?
                                    (showNoncomplianceLinks ?
                                        <Link href={AdminRoutes.COMPLIANCE_PAGE(clientId, slaType, slaPeriodStartDate, slaPeriodEndDate)} target={'_blank'}>
                                            {(nonCompliantCount ? nonCompliantCount : 0) + " noncompliant " + Pluralize(nonCompliantCount,relatedObjectDescription)}
                                        </Link>
                                            :
                                        <Text>{(nonCompliantCount ? nonCompliantCount : 0) + " noncompliant " + Pluralize(nonCompliantCount,relatedObjectDescription)}</Text>
                                    )
                                    :
                                    <Text>0 noncompliant {relatedObjectDescription}</Text>
                            }

                        </Text>
                    </Grid> : null
                }
            </Grid>
            <Grid item xs={4} textAlign={TextAlign.CENTER}>
                {loading ? <Loader/> :
                    <SingleMetricPieChart warningThreshold={complianceRate} innerRadius={105} width={150} metricValue={slaComplianceValue} emptyDataset={totalCount === 0}/>
                }
            </Grid>
        </Grid>
    )
};

SLASummaryLine.propTypes = {
    complianceRate: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    nonCompliantCount: PropTypes.number,
    relatedObjectDescription: PropTypes.string,
    showNoncompliant: PropTypes.bool,
    slaText: PropTypes.string,
    totalCount: PropTypes.number,
    slaPeriodStartDate: PropTypes.string,
    slaPeriodEndDate: PropTypes.string,
    slaType: PropTypes.string.isRequired,
    clientId: PropTypes.number.isRequired,
    showNoncomplianceLinks: PropTypes.bool
};

export default SLASummaryLine;