import * as PropTypes from "prop-types";
import styled from "styled-components";
import React from "react";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import Text, {TextColor} from "worksmith/components/Text/Text.web";
import {TextAlign} from "worksmith/enums/CSSEnums";
import RecurringServiceStatusIcon from "./RecurringServiceStatusIcon";
import moment from "moment";
import {MomentFormat} from "../../shared/enums/Enums";
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import Grid from "worksmith/components/Grid/Grid.web";
import {WithContext} from "../../context/GlobalContext";

const OptionText = styled(Text)`
    font-size: 1em;
`;

const InlineBlock = styled.div`
    margin-top: -1em;
`;

const IconBlock = styled.div`
    margin-left: 0.35em;
    padding-top: 0.40em;
`;

const RequestsOrObligationSummary = (props) =>{

    let {
        context,
        obligationTemplates,
        requests
    } = props;

    let requestsOrObligationSummaryString = "";
    let filteredObligationTemplates = obligationTemplates.filter(ot => ot.status !== ObligationTemplateStatus.CANCELED);
    let numberOfServices = requests.length + filteredObligationTemplates.length;
    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;

    if(numberOfServices === 0){
        requestsOrObligationSummaryString = "No Service";
    }
    else if(numberOfServices >= 2)
        requestsOrObligationSummaryString = (numberOfServices) + (obligationTemplates.length > 0 ? " Ongoing Services" : " Pending Services");
    else{
        let obligationTemplate = filteredObligationTemplates[0];
        let request = requests[0];
        if(ValueIsSet(obligationTemplate)){
            requestsOrObligationSummaryString = requestsOrObligationSummaryString + (ValueIsSet(obligationTemplate.lastCompletedVisitDate) ? "Last Visit: " + moment(obligationTemplate.lastCompletedVisitDate).format(MomentFormat.MonthDayYearSlash) : "");
            requestsOrObligationSummaryString = requestsOrObligationSummaryString + (ValueIsSet(obligationTemplate.nextUpcomingObligationDate) ? "Next Visit: " + moment(obligationTemplate.nextUpcomingObligationDate).format(MomentFormat.MonthDayYearSlash) : "");
            if (!hidePricingForNonAdminUsers) requestsOrObligationSummaryString = requestsOrObligationSummaryString + " | Price: $" + obligationTemplate.totalRetailPrice;
        }
        else if(ValueIsSet(request)){
            requestsOrObligationSummaryString = requests.length + Pluralize(requests.length, " Pending Service");
        }
    }

    return <InlineBlock>
            <Grid container>
                <Grid item xs={11}>
                    <OptionText color={TextColor.TEXT_SECONDARY} align={TextAlign.RIGHT}>{requestsOrObligationSummaryString}</OptionText>
                </Grid>
                <Grid item xs={1}>
                    <IconBlock>
                        <RecurringServiceStatusIcon statuses={obligationTemplates.map(ot => ot.status).concat(requests.map(r => r.status))}/>
                    </IconBlock>
                </Grid>
            </Grid>
        </InlineBlock>;
};

RequestsOrObligationSummary.propTypes = {
    requests: PropTypes.arrayOf(PropTypes.object),
    obligationTemplates: PropTypes.arrayOf(PropTypes.object)
};

export default WithContext(RequestsOrObligationSummary);