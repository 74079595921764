import React from 'react';
import PropTypes from "prop-types";

import AddInHouseTailorRelationship from "./AddInHouseTailorRelationship";
import AddPreferredVendorRelationship from "./AddPreferredVendorRelationship";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import CountryCodePhoneNumberInput, {convertPhoneNumberIntoNumberAndCountryCode} from "worksmith/components/Inputs/PhoneInput/CountryCodePhoneNumberInput";
import CountrySelect from "worksmith/components/Inputs/Select/CountrySelect";
import Grid from "worksmith/components/Grid/Grid";
import StateProvinceSelect from "worksmith/components/Inputs/Select/StateProvinceSelect";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import TextField from "worksmith/components/Inputs/TextField/TextField";
import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {Debounce, ValueIsSet} from "worksmith/helpers/GenericHelpers";

const AddEditVendorFormView = ({
                                   client,
                                   editVendorId,
                                   handleChange,
                                   handleSubmit,
                                   isInHouseTailor,
                                   setShowAddEditVendor,
                                   setVendorInfo,
                                   submittingVendor,
                                   vendorInfo,
                               }) => {


    return (
        <Grid container xs={12} margin={'0.5rem 0 0 0'}>
            <Grid container spacing={1} rowSpacing={2} xs={12} margin={0} alignItems={AlignItems.FLEX_START}>
                <Grid component={'header'} container item justify={JustifyContent.FLEX_START} margin={'0 0 -0.5rem 0'}>
                    <Text variant={TextVariant.SUBTITLE_1}>
                        Company Information
                    </Text>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        initialValue={vendorInfo.companyName}
                        fullWidth
                        label={isInHouseTailor ? 'Account Name (i.e. Northeast In-House Tailor)' : 'Company Name'}
                        onChange={Debounce((value) => handleChange('companyName', value))}
                        error={ValueIsSet(vendorInfo.errors.companyName)}
                        helperText={vendorInfo.errors.companyName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CountrySelect
                        label={"Country"}
                        initialValue={vendorInfo.country}
                        onChange={(country) => {
                            handleChange('country', country)
                        }}
                        error={ValueIsSet(vendorInfo.errors.country)}
                        helperText={vendorInfo.errors.country}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        initialValue={vendorInfo.addressLineOne}
                        fullWidth
                        label={'Address Line One'}
                        onChange={Debounce((value) => handleChange('addressLineOne', value))}
                        error={ValueIsSet(vendorInfo.errors.addressLineOne)}
                        helperText={vendorInfo.errors.addressLineOne}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        initialValue={vendorInfo.addressLineTwo}
                        fullWidth
                        label={'Address Line Two'}
                        onChange={Debounce((value) => handleChange('addressLineTwo', value))}
                        error={ValueIsSet(vendorInfo.errors.addressLineTwo)}
                        helperText={vendorInfo.errors.addressLineTwo}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        initialValue={vendorInfo.city}
                        fullWidth
                        label={'City'}
                        onChange={(value) => handleChange("city", value)}
                        error={ValueIsSet(vendorInfo.errors.city)}
                        helperText={vendorInfo.errors.city}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StateProvinceSelect
                        country={vendorInfo.country}
                        initialValue={vendorInfo.stateProvince}
                        label={'State/Province'}
                        onChange={state => handleChange('stateProvince', state)}
                        error={ValueIsSet(vendorInfo.errors.stateProvince)}
                        helperText={vendorInfo.errors.stateProvince}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        initialValue={vendorInfo.zip}
                        fullWidth
                        label={'Zip'}
                        onChange={(value) => handleChange("zip", value)}
                        error={ValueIsSet(vendorInfo.errors.zip)}
                        helperText={vendorInfo.errors.zip}
                    />
                </Grid>
                {isInHouseTailor &&
                    <Grid item xs={12}>
                        <TextField
                            initialValue={vendorInfo.email}
                            fullWidth
                            label={'Email'}
                            onChange={(value) => handleChange("email", value)}
                            error={ValueIsSet(vendorInfo.errors.email)}
                            helperText={vendorInfo.errors.email}
                        />
                    </Grid>
                }
            </Grid>
            {!isInHouseTailor &&
                <Grid container spacing={1} rowSpacing={2} xs={12} margin={'0.5rem 0 0 0'} alignItems={AlignItems.FLEX_START}>
                    <Grid component={'header'} container item justify={JustifyContent.FLEX_START} margin={'0 0 -0.5rem 0'}>
                        <Text variant={TextVariant.SUBTITLE_1}>
                            User Information
                        </Text>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            initialValue={vendorInfo.firstName}
                            fullWidth
                            label={'First Name'}
                            onChange={(value) => handleChange("firstName", value)}
                            error={ValueIsSet(vendorInfo.errors.firstName)}
                            helperText={vendorInfo.errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            initialValue={vendorInfo.lastName}
                            fullWidth
                            label={'Last Name'}
                            onChange={(value) => handleChange("lastName", value)}
                            error={ValueIsSet(vendorInfo.errors.lastName)}
                            helperText={vendorInfo.errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            initialValue={vendorInfo.email}
                            fullWidth
                            label={'Email'}
                            onChange={(value) => handleChange("email", value)}
                            error={ValueIsSet(vendorInfo.errors.email)}
                            helperText={vendorInfo.errors.email}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CountryCodePhoneNumberInput
                            countryCode={vendorInfo.countryCode}
                            initialValue={vendorInfo.phone}
                            specialLabel={"Mobile Number (optional)"}
                            onChange={(value, country, e, formattedValue) => {
                                const phoneNumber = convertPhoneNumberIntoNumberAndCountryCode(formattedValue, country);
                                setVendorInfo((prevState) => ({...prevState, ['phone']: phoneNumber.phone, ['countryCode']: phoneNumber.countryCode}));
                            }}
                            error={ValueIsSet(vendorInfo.errors.phone) ? vendorInfo.errors.phone : null}
                        />
                    </Grid>
                </Grid>
            }
            <Grid container spacing={1} rowSpacing={2} xs={12} margin={'0.5rem 0 0 0'} alignItems={AlignItems.FLEX_START}>
                <Grid component={'header'} container item justify={JustifyContent.FLEX_START} margin={'0 0 -0.5rem 0'}>
                    <Text variant={TextVariant.SUBTITLE_1}>
                        Locations
                    </Text>
                </Grid>
                <AddPreferredVendorRelationship
                    client={client}
                    combinations={vendorInfo.preferredVendorRelationships}
                    handleChange={handleChange}
                />
            </Grid>
            {isInHouseTailor &&
                <Grid container spacing={1} rowSpacing={2} xs={12} margin={'0.5rem 0 0 0'} alignItems={AlignItems.FLEX_START}>
                    <Grid component={'header'} container item justify={JustifyContent.FLEX_START} margin={'0 0 -0.5rem 0'}>
                        <Text variant={TextVariant.SUBTITLE_1}>
                            Tailors
                        </Text>
                    </Grid>
                    <AddInHouseTailorRelationship
                        handleChange={handleChange}
                        listOfTailors={vendorInfo.inHouseTailorRelationships}
                        error={ValueIsSet(vendorInfo.errors.inHouseTailorRelationships)}
                        helperText={vendorInfo.errors.inHouseTailorRelationships}
                    />
                </Grid>
            }
            <Grid container xs={12} justify={JustifyContent.FLEX_END} margin={'1rem 0 0 0'}>
                <Grid item>
                    <Button
                        disabled={submittingVendor}
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => setShowAddEditVendor(false)}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        debounce
                        onClick={handleSubmit}
                        primary
                        isSubmitting={submittingVendor}
                    >
                        {ValueIsSet(editVendorId) ? 'Save' : 'Add Vendor'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default AddEditVendorFormView;

AddEditVendorFormView.propTypes = {
    client:PropTypes.object,
    editVendorId:PropTypes.number,
    handleChange:PropTypes.func,
    handleSubmit:PropTypes.func,
    isInHouseTailor: PropTypes.bool,
    setShowAddEditVendor:PropTypes.func,
    setVendorInfo:PropTypes.func,
    submittingVendor:PropTypes.bool,
    vendorInfo:PropTypes.objectOf({
        companyName: PropTypes.string,
        country: PropTypes.string,
        addressLineOne: PropTypes.string,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string,
        stateProvince: PropTypes.string,
        zip: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        countryCode: PropTypes.string,
        preferredVendorRelationships: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            clientLocation: PropTypes.shape({
                id: PropTypes.number,
                title: PropTypes.string,
            }),
            serviceLine: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                usesTicketing: PropTypes.bool,
            })
        })),
        errors: PropTypes.object,
    }),
};