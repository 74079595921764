import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {TextVariant} from "worksmith/components/Text/Text";
import FileUpload from "worksmith/components/FileUpload/FileUpload";
import Grid from "worksmith/components/Grid/Grid";
import ObligationService from "worksmith/services/api/ObligationService";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Loader from "worksmith/components/Loader/Loader";
import Text from "worksmith/components/Text/Text.web";

const obligationService = new ObligationService();
const graphqlService = new GraphQLServiceClass();

class ObligationAttachmentSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            obligation: {}
        };
    }

    componentDidMount() {
        this.loadObligationAttachments();
    }

    loadObligationAttachments = () => {
        let {obligationId} = this.props;
        graphqlService.findOneById(
            obligationId,
            GraphQLObjectType.OBLIGATION,
            obligationObject
        ).then((data) => {
            console.log(data);
            this.setState({obligation: data, loading: false});
        }).catch(() => {
            console.log('error!');
        });
    };

    addOrRemoveAttachments = (newFiles) => {
        let {obligation} = this.state;
        let {loadObligationAttachments} = this;

        this.setState({loading: true});

        const existingFileIds = obligation.obligationAttachments.map(file => {
            return file.id;
        });

        const newFileIds = newFiles.slice().map(file => {
            return file.id
        });

        if(newFileIds.length > existingFileIds.length){

            let attachmentsToAdd = newFiles.filter(file => {
                return !existingFileIds.includes(file.id)
            });


            obligationService.addAttachments(
                obligation.id,
                "COMPLETION_PHOTO",
                attachmentsToAdd).then(() => {
                loadObligationAttachments();
            }).catch(() => {
                console.log('error');
            });
        } else if(newFiles.length < obligation.obligationAttachments.length){
            let attachmentIdToDelete = existingFileIds.filter(fileId => {
                return !newFileIds.includes(fileId);
            })[0];

            obligationService.deleteAttachment(
                obligation.id,
                attachmentIdToDelete
                ).then(() => {
                loadObligationAttachments();
            }).catch(() => {
                console.log('error');
            });
        }
    };

    createAttachmentsList = () => {
        let {obligation} = this.state;
        return obligation.obligationAttachments.map((attachment) => (
            {
                id: attachment.id,
                url: attachment.singleUseUrl,
                name: attachment.name
            }
        ))
    };

    render() {
        let {createAttachmentsList, addOrRemoveAttachments} = this;
        let {loading} = this.state;
        let {readOnly, addOnly} = this.props;

        return (
            <Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Text bold variant={TextVariant.BODY_1}>Completion Photos/Files</Text>
                    </Grid>
                    {loading ? <Loader/> :
                            <Grid item xs={12}>
                            <FileUpload
                            initialFiles={createAttachmentsList().length > 0 ? createAttachmentsList() : []}
                            onChange={addOrRemoveAttachments}
                            readOnly={readOnly}
                            addOnly={addOnly}
                            />
                            </Grid>
                    }
                    <br/>
                </Grid>
            </Fragment>
        )
    }
}

ObligationAttachmentSection.propTypes = {
    obligationId: PropTypes.number,
    readOnly: PropTypes.bool
};

export default ObligationAttachmentSection;

const obligationObject = `
    id
    obligationAttachments {
        id
        name
        singleUseUrl
    }
`;