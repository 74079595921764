import React, {useState} from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";

import Accordion from "worksmith/components/Accordion/Accordion";
import ClientDashboardType from "worksmith/enums/api/cpm/ClientDashboardType";
import Grid from "worksmith/components/Grid/Grid";
import HugoBossAlterations from "../../../../pages/standardItems/HugoBossAlterations";
import Loader from "worksmith/antd-components/Loader/Loader";
import StandardPricingItemAccordion from "worksmith/composite-components/ClientLocationProfile/standardPricing/StandardPricingItemAccordion";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {LoaderType} from "worksmith/enums/LoaderType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";


const StandardPricingPageView = ({
                                     clientDashboardType,
                                     currency,
                                     getClientStandardItemsPricing,
                                     loading,
                                     locationId,
                                     selfServiceClientVendorRelationships,
                                     selectedServiceLine,
                                     standardItemsPricing,
                                     userIsAdmin,
}) => {

    const [expanded, setExpanded] = useState(null);


    return (
        <Grid constainer item xs={12} margin={"20px 0 0 0"}>
            {(!loading && ValueIsSet(selectedServiceLine)) ? selectedServiceLine.map(serviceLine => {
                return (
                    <Grid item key={serviceLine.id} margin={'20px 0 0 0'}>
                        <StyledGrid item>
                            <Text variant={TextVariant.H5}>{serviceLine.name}</Text>
                        </StyledGrid>
                        {serviceLine.lineItemTypes.filter(lineItem => lineItem.isAvailableForStandardItems).map(lineItem => {
                            return (
                                <Grid item key={lineItem.id}>
                                    <Accordion
                                        transitionProps={{unmountOnExit: true}}
                                        expanded={expanded === lineItem.id}
                                        onChange={() => {
                                            setExpanded((prevState) => prevState === lineItem.id ? null : lineItem.id)
                                        }}
                                        summary={lineItem.name}
                                        details={
                                            (clientDashboardType === ClientDashboardType.HUGO_BOSS && lineItem.id === 2) ?
                                                <HugoBossAlterations
                                                    currency={currency}
                                                    getClientStandardItemsPricing={getClientStandardItemsPricing}
                                                    isForStandardPricingPage
                                                    lineItem={lineItem}
                                                    locationId={locationId}
                                                    selfServiceClientVendorRelationships={selfServiceClientVendorRelationships}
                                                    serviceLine={serviceLine}
                                                    standardItemsPricing={standardItemsPricing}
                                                    userIsAdmin={userIsAdmin}
                                                />
                                                :
                                                <StandardPricingItemAccordion
                                                    currency={currency}
                                                    getClientStandardItemsPricing={getClientStandardItemsPricing}
                                                    locationId={locationId}
                                                    selfServiceClientVendorRelationships={selfServiceClientVendorRelationships}
                                                    serviceLineId={serviceLine.id}
                                                    standardItems={lineItem.standardItemsForClient}
                                                    standardItemsPricing={standardItemsPricing}
                                                    userIsAdmin={userIsAdmin}
                                                />
                                        }
                                        square
                                        renderDetailsOnFirstExpansion
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            })
                :
                <Loader loaderType={LoaderType.LOGO}/>
            }
        </Grid>
    )
};


const StyledGrid = styled(Grid)`
  padding: 16px 14px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export default StandardPricingPageView;

StandardPricingPageView.propTypes = {
    clientDashboardType: CustomPropTypes.enum(ClientDashboardType),
    currency: CustomPropTypes.enum(CurrencySymbolEnum),
    getClientStandardItemsPricing: PropTypes.func,
    loading: PropTypes.bool,
    locationId: PropTypes.number,
    selfServiceClientVendorRelationships: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    selectedServiceLine: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        lineItemTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isAvailableForStandardItems: PropTypes.bool,
                standardItemsForClient: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        client: PropTypes.shape({
                            id: PropTypes.number
                        })
                    })
                )
            })
        )
    })),
    standardItemsPricing: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        updatedTimestamp: PropTypes.string,
        unitRetailPrice: PropTypes.number,
        unitWholesalePrice: PropTypes.number,
        unitCustomerCharge: PropTypes.number,
        clientLocationId: PropTypes.number,
        vendorLocationId: PropTypes.number,
        vendorName: PropTypes.string,
        standardItem: PropTypes.shape({
            id: PropTypes.number,
            createdTimestamp: PropTypes.string,
            updatedTimestamp: PropTypes.string,
            name: PropTypes.string,
            client: PropTypes.object,
            serviceLine: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            lineItemType: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
        }),
    })),
    userIsAdmin: PropTypes.bool,
};