import React, {useState} from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";

import Accordion from "worksmith/components/Accordion/Accordion";
import ClientDashboardType from "worksmith/enums/api/cpm/ClientDashboardType";
import Grid from "worksmith/components/Grid/Grid";
import HugoBossAlterations from "./HugoBossAlterations";
import Loader from "worksmith/antd-components/Loader/Loader";
import StandardItem from "./StandardItem";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {LoaderType} from "worksmith/enums/LoaderType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const StandardItemsSelectedServiceAccordion = ({
                                                   client,
                                                   getClientStandardItems,
                                                   loading,
                                                   standardItems,
}) => {

    const [expanded, setExpanded] = useState(null);

    return (
        <Grid constainer item xs={12} margin={"20px 0 0 0"}>
            {(!loading && ValueIsSet(standardItems)) ? standardItems.map(serviceLine => {
                    return (
                        <Grid item key={serviceLine.id} margin={'20px 0 0 0'}>
                            <StyledGrid item>
                                <Text variant={TextVariant.H5}>{serviceLine.name}</Text>
                            </StyledGrid>
                            {serviceLine.lineItemTypes.filter(lineItem => lineItem.isAvailableForStandardItems).map(lineItem => {
                                return (
                                    <Grid item key={lineItem.id}>
                                        <Accordion
                                            transitionProps={{unmountOnExit: true}}
                                            expanded={expanded === lineItem.id}
                                            onChange={() => setExpanded((prevState) => prevState === lineItem.id ? null : lineItem.id)}
                                            summary={lineItem.name}
                                            details={
                                                <>
                                                    {(client.configurationSettings.dashboardType === ClientDashboardType.HUGO_BOSS && lineItem.id === 2) ?
                                                        <HugoBossAlterations
                                                            client={client}
                                                            getClientStandardItems={getClientStandardItems}
                                                            lineItem={lineItem}
                                                            serviceLine={serviceLine}
                                                        />
                                                        :
                                                        <StandardItem
                                                            client={client}
                                                            getClientStandardItems={getClientStandardItems}
                                                            lineItem={lineItem}
                                                            serviceLine={serviceLine}
                                                        />
                                                    }
                                                </>
                                            }
                                            square
                                            renderDetailsOnFirstExpansion
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                })
                :
                <Loader loaderType={LoaderType.LOGO}/>
            }
        </Grid>
    )
};

const StyledGrid = styled(Grid)`
  padding: 16px 14px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`;

export default StandardItemsSelectedServiceAccordion;

StandardItemsSelectedServiceAccordion.propTypes = {
    client: PropTypes.object,
    getClientStandardItems: PropTypes.func,
    loading: PropTypes.bool,
    standardItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        lineItemTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isAvailableForStandardItems: PropTypes.bool,
                standardItemsForClient: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        client: PropTypes.shape({
                            id: PropTypes.number
                        })
                    })
                )
            })
        )
    })),
};