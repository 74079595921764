import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {InputLabel} from "@mui/material";

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import CustomScheduleCheckBox from "worksmith/composite-components/ScheduleOptionsForm/CustomScheduleCheckBox";
import DeleteCustomScheduleWarningDialog
    from "worksmith/composite-components/ScheduleOptionsForm/DeleteCustomScheduleWarningDialog";
import FrequencyPicker from "worksmith/components/FrequencyPicker/FrequencyPicker.web";
import {FrequencyString} from "worksmith/helpers/LanguageHelper";
import Grid from "worksmith/components/Grid/Grid.web";
import Multiselect, {MultiselectType} from "../../components/Inputs/Multiselect/Multiselect.web";
import ObligationCurrentScheduleSummary from "./ObligationCurrentScheduleSummary";
import ObligationTemplateCurrentScheduleSummary
    from "worksmith/composite-components/ScheduleOptionsForm/ObligationTemplateCurrentScheduleSummary";
import OneTimeScheduleInput from "../OneTimeScheduleInput/OneTimeScheduleInput";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";
import RecurringScheduleInput from "../RecurringScheduleInput/RecurringScheduleInput";
import Schedule from "worksmith/components/Schedule/Schedule.web";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import ScheduleOptionsFormServiceSummary
    from "worksmith/composite-components/ScheduleOptionsForm/ScheduleOptionsFormServiceSummary";
import Select, {SelectType} from "../../components/Inputs/Select/Select.web";
import SelectedCustomScheduleList
    from "worksmith/composite-components/ScheduleOptionsForm/SelectedCustomScheduleList";
import Text, { TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import UserType from "worksmith/enums/api/user/UserType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ChangeRequestApprovalType from "worksmith/enums/api/cr/ChangeRequestApprovalType";
import ChangeRequestStatus from "worksmith/enums/api/cr/ChangeRequestStatus";

const notificationOptions = [
    {
        label: 'Mute client notifications',
        value: 'CLIENT'
    },
    {
        label: 'Mute vendor notifications',
        value: 'VENDOR'
    }
];

const ScheduleOptionsFormView = (props) => {
    const [customScheduleOpen, setCustomScheduleOpen] = useState(false);
    const [customScheduleChecked, setCustomScheduleChecked] = useState(false);
    const [customScheduleSaved, setCustomScheduleSaved] = useState(false);

    const {
        adjustableFrequency,
        allowCustomScheduleCheckBox,
        clientLocation,
        customScheduleWarningDialogOpen,
        frequency,
        handleCustomScheduleWarningDialogOpen,
        isForNonWorksmithClient,
        isPendingEntireRecurringServiceSchedule,
        obligation,
        obligationChangeRequest,
        obligationTemplate,
        onFrequencyChange,
        onScheduleChange,
        scheduleOptionType,
        schedules,
        serviceLine,
        setApprovalType,
        setMutedNotifications,
        showCurrentSchedule,
        spacing,
        templateChangeRequest,
        updatedFrequency,
        userType,
        useMuteNotificationDialogInstead,
        useTicketing,
        vendor,
    } = props;

    let firstSchedule = schedules[0];
    let hasPendingChangeOrder = (ValueIsSet(obligation) && obligation.hasPendingChangeOrderRequest) || (ValueIsSet(obligationTemplate) && obligationTemplate.hasJobWithPendingChangeOrder);
    const showCustomScheduleOption = (ValueIsSet(firstSchedule.daysOfWeek) && firstSchedule.daysOfWeek.length > 1 && firstSchedule.daysOfWeek.length === updatedFrequency.recurrenceCount && firstSchedule.date);

    const adminApprovalOptions = [
        {
            toolTip: showCustomScheduleOption ? "In order to use a custom schedule you must update the schedule on the client and vendor's behalf." : null,
            label: 'No Approval Required',
            value: 'NONE',
            disabled: false
        },
        {
            label: 'Client Approval Required',
            value: 'CLIENT',
            disabled: userType === UserType.WORKSMITH && hasPendingChangeOrder || customScheduleChecked
        },
        {
            label: 'Vendor Approval Required',
            value: 'VENDOR',
            disabled: userType === UserType.WORKSMITH && hasPendingChangeOrder || customScheduleChecked
        }
    ];

    // If the request is a pending change request, the approval type option that matches the pending change request's approval type will be deleted.
    const getAdminApprovalOptions = () => {
        if (ValueIsSet(templateChangeRequest) || ValueIsSet(obligationChangeRequest)) {
            const status = isPendingEntireRecurringServiceSchedule ? templateChangeRequest.status : obligationChangeRequest.status;
            const mapStatus = {
                [ChangeRequestStatus.NEEDS_CLIENT_INPUT]: "CLIENT",
                [ChangeRequestStatus.NEEDS_VENDOR_INPUT]: "VENDOR",
            };
            return adminApprovalOptions.filter((options) => options.value !== mapStatus[status])
        } else {
            return adminApprovalOptions
        }
    };

    const vendorApprovalOptions = [ // approval options for vendors (for worksmith jobs)
        {
            label: 'Client Approval Needed - Send to the client for approval',
            value: ChangeRequestApprovalType.CLIENT,
            disabled: false
        },
        {
            label: 'No Approval Needed - The client has already agreed to a new schedule',
            value: ChangeRequestApprovalType.NONE,
            disabled: false
        }
    ];

    const handleCustomScheduleOpen = (customScheduleDialogOn) => {
        setCustomScheduleOpen(customScheduleDialogOn);
    };

    const handleCustomScheduleChecked = (customScheduleCheckedTrue) => {
        setCustomScheduleChecked(customScheduleCheckedTrue);

        if (customScheduleCheckedTrue) {
            if (!customScheduleSaved) {
                onScheduleChange({
                    ...firstSchedule,
                    customScheduleArrivalWindows: firstSchedule.daysOfWeek.map((day) => {
                        return {dayOfWeek: day, arrivalStartTime: "10:00:00", arrivalEndTime: "18:00:00"}
                    })
                }, 0)
            }
        } else {
            firstSchedule.customScheduleArrivalWindows = null;
        }
    };

    const handleCustomScheduleSave = () => {
        onScheduleChange({
            ...firstSchedule,
            daysOfWeek: firstSchedule.customScheduleArrivalWindows,
        }, 0);

        handleCustomScheduleOpen(false);
        setCustomScheduleSaved(true);
    };

    useEffect(() => {
       if (firstSchedule.customScheduleArrivalWindows === null) {
           setCustomScheduleSaved(false);
       }
    });

    return (
        <Grid container
              spacing={spacing}>
            {
                frequency && !showCurrentSchedule ?
                    <Grid item xs={12} sm={12}>
                            <Text variant={TextVariant.BODY_1}>Frequency: {FrequencyString(frequency)}</Text>
                    </Grid>
                    :
                    null
            }
            {
                ValueIsSet(obligationTemplate) && !ValueIsSet(templateChangeRequest) && userType === UserType.CLIENT ?
                    <Grid item xs={12} md={8}>
                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.SECONDARY}>
                            Requesting to increase or decrease the frequency of the service might result in a different price per visit.
                        </Text>
                    </Grid> : null
            }
            {
                ValueIsSet(scheduleOptionType) && ValueIsSet(serviceLine) && ValueIsSet(clientLocation) && ValueIsSet(userType) ?
                    <Grid container item>
                        <ScheduleOptionsFormServiceSummary
                            clientLocation={clientLocation}
                            scheduleOptionType={scheduleOptionType}
                            serviceLine={serviceLine}
                            userType={userType}
                            vendor={vendor}
                        />
                    </Grid>: null
            }
            {
                ValueIsSet(obligation) && showCurrentSchedule && !ValueIsSet(obligationChangeRequest) ?
                    <Grid container item>
                        <ObligationCurrentScheduleSummary obligation={obligation}/>
                    </Grid> : null
            }
            {
                ValueIsSet(obligationTemplate) && showCurrentSchedule && !ValueIsSet(templateChangeRequest) ?
                    <Grid container item>
                        <ObligationTemplateCurrentScheduleSummary obligationTemplate={obligationTemplate}/>
                    </Grid> : null
            }{
                ValueIsSet(obligation) && ValueIsSet(obligationChangeRequest) && showCurrentSchedule ?
                    <Grid container item>
                        <Grid container item spacing={3}>
                        <Grid container item xs={12} sm={6}>
                            <Grid item xs={12}><Text semiBold variant={TextVariant.SUBTITLE_1}>Current Schedule:</Text></Grid>
                            <Grid item xs={12}><Schedule schedule={
                                    {
                                        "date":obligation.obligationDate,
                                        "arrivalStartTime":obligation.arrivalStartTime,
                                        "arrivalEndTime":obligation.arrivalEndTime
                                    }
                                }/>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Text semiBold variant={TextVariant.SUBTITLE_1}color={TextColor.SECONDARY}>
                                    Proposed Schedule:
                                </Text>
                            </Grid>
                            <Grid item xs={12}><Schedule schedule={obligationChangeRequest.requestedSchedules}/></Grid>
                        </Grid>
                        </Grid>
                    </Grid> : null
            }
            {
                ValueIsSet(obligationTemplate) && ValueIsSet(templateChangeRequest) && showCurrentSchedule ?
                    <Grid container item>
                        <Grid container item spacing={3}>
                        <Grid container item xs={12} sm={6}>
                            <Grid item xs={12}><Text semiBold variant={TextVariant.SUBTITLE_1}>Current Recurring Schedule:</Text></Grid>
                            <Grid item xs={12}><Schedule schedule={
                                {
                                    "date":obligationTemplate.schedule.date,
                                    "arrivalStartTime":obligationTemplate.schedule.arrivalStartTime,
                                    "arrivalEndTime":obligationTemplate.schedule.arrivalEndTime,
                                    "dayOfMonth":obligationTemplate.schedule.dayOfMonth,
                                    "daysOfWeek":obligationTemplate.schedule.daysOfWeek,
                                    "recurrenceType":obligationTemplate.schedule.recurrenceType,
                                    "repeatEvery":obligationTemplate.schedule.repeatEvery,
                                    "returnDate":obligationTemplate.schedule.returnDate
                                }
                            }/></Grid>
                        </Grid>
                        <Grid container item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Text semiBold variant={TextVariant.SUBTITLE_1} color={TextColor.SECONDARY}>
                                    Proposed Recurring Schedule:
                                </Text>
                            </Grid>
                            <Grid item xs={12}><Schedule schedule={templateChangeRequest.requestedSchedules[0]}/></Grid>
                        </Grid>
                        </Grid>
                    </Grid> : null
            }
            {
                adjustableFrequency ?
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <Text semiBold variant={TextVariant.SUBTITLE_1}>Frequency:</Text>
                        </Grid>
                        <Grid item xs={12}>
                            <FrequencyPicker
                                disabled={ValueIsSet(firstSchedule.customScheduleArrivalWindows)}
                                handleCustomScheduleWarningDialogOpen={(warningDialogOpen) => handleCustomScheduleWarningDialogOpen(warningDialogOpen)}
                                initialFrequency={updatedFrequency}
                                onChange={(newFrequency) => {onFrequencyChange(newFrequency)}}
                            />
                        </Grid>
                    </Grid> : null
            }
            {schedules.map((schedule, index) => {
               if (ValueIsSet(updatedFrequency)) {
                   return (
                       <Grid container item xs={allowCustomScheduleCheckBox ? 6 : 12} key={index}>
                           <RecurringScheduleInput allowCustomScheduleCheckBox={allowCustomScheduleCheckBox}
                                                   customScheduleChecked={customScheduleChecked}
                                                   disabled={ValueIsSet(firstSchedule.customScheduleArrivalWindows)}
                                                   frequency={updatedFrequency}
                                                   handleCustomScheduleWarningDialogOpen={handleCustomScheduleWarningDialogOpen}
                                                   onChange={(schedule) => onScheduleChange(schedule, index)}
                                                   schedule={schedule}
                                                   title={schedules.length > 1 ? 'Preference ' + (index + 1) + ':' : ''}
                           />
                           {ValueIsSet(updatedFrequency) && allowCustomScheduleCheckBox &&
                               <>
                                   <Grid container item xs={6}>
                                       <CustomScheduleCheckBox
                                           customScheduleOpen={customScheduleOpen}
                                           customScheduleChecked={customScheduleChecked}
                                           customScheduleSaved={customScheduleSaved}
                                           date={firstSchedule.date}
                                           dayOfMonth={firstSchedule.dayOfMonth}
                                           daysOfWeek={firstSchedule.daysOfWeek}
                                           daysOfWeekNumber={firstSchedule.daysOfWeek}
                                           handleCustomScheduleOpen={handleCustomScheduleOpen}
                                           handleCustomScheduleChecked={handleCustomScheduleChecked}
                                           handleCustomScheduleSave={handleCustomScheduleSave}
                                           handleCustomScheduleWarningDialogOpen={(warningDialogOpen, deleteCustomSchedule) => handleCustomScheduleWarningDialogOpen(warningDialogOpen, deleteCustomSchedule)}
                                           onChange={(schedules) => onScheduleChange(schedules)}
                                           onScheduleChange={onScheduleChange}
                                           recurrenceType={firstSchedule.recurrenceType}
                                           repeatEvery={firstSchedule.repeatEvery}
                                           schedule={firstSchedule}
                                           showCustomScheduleOption={showCustomScheduleOption}
                                           updatedFrequency={updatedFrequency}
                                       />
                                   </Grid>
                                   <DeleteCustomScheduleWarningDialog customScheduleWarningDialogOpen={customScheduleWarningDialogOpen}
                                                                      handleCustomScheduleChecked={handleCustomScheduleChecked}
                                                                      handleCustomScheduleWarningDialogOpen={(warningDialogOpen, deleteCustomSchedule) => handleCustomScheduleWarningDialogOpen(warningDialogOpen, deleteCustomSchedule)}
                                   />
                               </>
                           }
                           {customScheduleChecked &&
                               <SelectedCustomScheduleList handleCustomScheduleOpen={handleCustomScheduleOpen}
                                                           schedule={firstSchedule}
                                                           showCustomScheduleOption={customScheduleChecked}
                               />
                           }
                       </Grid>
                   )
               } else {
                   return (
                       <Grid container item xs={12} key={index}>
                           <OneTimeScheduleInput onChange={(schedule) => onScheduleChange(schedule, index)}
                                                 title={schedules.length > 1 ? 'Preference ' + (index + 1) + ':' : ''}
                                                 includeReturnDate={useTicketing}/>
                       </Grid>
                       )
                    }
                }
            )}

            {
                userType === UserType.WORKSMITH ?
                    <Grid container item xs={12} spacing={4}>
                        <Grid item xs={12}>
                            <Select label={'Approval Type'} includeNullOption primary onChange={(val) => {setApprovalType(val)}} options={getAdminApprovalOptions()} type={SelectType.RADIO_BUTTONS}/>
                        </Grid>
                        { !useMuteNotificationDialogInstead &&
                            <Grid item xs={12}>
                                <Grid item>
                                    <InputLabel>Notifications</InputLabel>
                                </Grid>
                                <Grid item>
                                    <Multiselect onChange={(val) => {
                                        setMutedNotifications(val)
                                    }} options={notificationOptions} type={MultiselectType.CHECKBOX}/>
                                </Grid>
                            </Grid>
                        }
                    </Grid> : null
            }
            {
                userType === UserType.VENDOR && !isForNonWorksmithClient && (ValueIsSet(obligationTemplate) || ValueIsSet(obligation)) ?
                    <Grid container item xs={12} spacing={4}>
                        <Grid item xs={12}>
                            <Select includeNullOption
                                    primary
                                    onChange={(val) => {setApprovalType(val)}}
                                    options={vendorApprovalOptions}
                                    type={SelectType.RADIO_BUTTONS}
                                    initialValue={ChangeRequestApprovalType.CLIENT}/>
                        </Grid>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
};

ScheduleOptionsFormView.defaultProps = {
    submitText: 'Submit'
};

ScheduleOptionsFormView.propTypes = {
    allowCustomScheduleCheckBox: PropTypes.bool,    // allowCustomScheduleCheckBox prop need to be passed down the component tree in every instance the CustomScheduleCheckBox option should be displayed otherwise the CustomScheduleCheckBox will not be displayed.
    clientLocation: PropTypes.shape({
        addressLineOne: PropTypes.string,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string,
        client: PropTypes.shape({
            nickname: PropTypes.string
        }),
        state: PropTypes.string,
        zip: PropTypes.string,
    }),
    customScheduleWarningDialogOpen: PropTypes.bool,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    handleCustomScheduleWarningDialogOpen: PropTypes.func,
    isForNonWorksmithClient: PropTypes.bool,
    isPendingEntireRecurringServiceSchedule: PropTypes.bool,
    obligation: PropTypes.shape({
        obligationDate: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        arrivalEndTime: PropTypes.string
    }),
    obligationChangeRequest: PropTypes.shape({
        requestedSchedules: PropTypes.arrayOf(PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }))
    }),
    obligationTemplate: PropTypes.shape({
        recurringScheduleTemplate: PropTypes.shape({
            frequency: PropTypes.shape({
                recurrenceCount: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number
            }),
        }),
        schedule: PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        })
    }),
    onScheduleChange: PropTypes.func.isRequired,
    scheduleOptionType: CustomPropTypes.enum(ScheduleOptionType),
    schedules: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
            date: PropTypes.instanceOf(moment).isRequired,
            arrivalEndTime: PropTypes.instanceOf(moment).isRequired,
            arrivalStartTime: PropTypes.instanceOf(moment).isRequired,
            returnDate: PropTypes.instanceOf(moment)
        }),
        PropTypes.shape({
            date: PropTypes.instanceOf(moment).isRequired,
            arrivalEndTime: PropTypes.instanceOf(moment).isRequired,
            arrivalStartTime: PropTypes.instanceOf(moment).isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number)
        }),
        PropTypes.shape({}),
    ])),
    serviceLine: PropTypes.shape({
        name: PropTypes.string
    }),
    showCurrentSchedule: PropTypes.bool,
    spacing: PropTypes.number,
    templateChangeRequest: PropTypes.shape({
        requestedSchedules: PropTypes.arrayOf(PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }))
    }),
    userType: CustomPropTypes.enum(UserType),
    useMuteNotificationDialogInstead: PropTypes.bool,
    useTicketing: PropTypes.bool,
    vendor: PropTypes.string,
};

export default ScheduleOptionsFormView;