import React from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import {TitleCase} from "../../../../helpers/LanguageHelper";
import {TextColor} from "../../../../components/Text/Text.web";
import CreateScheduleOptions from "../../../ScheduleOptionsForm/CreateScheduleOptions";

const SchedulingElapsedView = (props) => {
    const {
        duration,
        frequency,
        loading,
        proposeSchedules,
        useTicketing,
        vendorLocation
    } = props;

    return (
        <RequestStateLayout
            description={`The availability submitted by you or ${vendorLocation.vendor.officialName} has expired. Please submit new availability.`}
            body={
                <CreateScheduleOptions
                    duration={duration}
                    frequency={frequency}
                    onSubmit={(schedules) => proposeSchedules(schedules)}
                    useTicketing={useTicketing}/>
            }
            status={TitleCase(NewRequestStatus.SCHEDULING) + ' - Submit Availability'}
            statusColor={TextColor.ERROR}/>
    )
};

SchedulingElapsedView.propTypes = {
    duration: PropTypes.number,
    frequency: PropTypes.shape({     // If frequency is passed in, the schedules created will be recurring
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    loading: PropTypes.bool,
    proposeSchedules: PropTypes.func,
    useTicketing: PropTypes.bool,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

export default SchedulingElapsedView;