import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';


import {TitleCase} from "worksmith/helpers/LanguageHelper";
import RequestDisplayType from "worksmith/enums/RequestDisplayType";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import NewRequestStatus from "worksmith/enums/api/proposal/NewRequestStatus";
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextColor, TextDisplay, TextVariant} from "worksmith/components/Text/Text.web";
import {DisplayTypeFromTypeAndEmergency} from "worksmith/helpers/RequestLanguageHelper";
import CorporateReview from "./RequestStateStatus/CorporateReview/CorporateReview";
import Canceled from "./RequestStateStatus/Canceled/Canceled";
import ContactingVendors from "./RequestStateStatus/ContactingVendors/ContactingVendors";
import Completed from "./RequestStateStatus/Completed/Completed";
import CorporateBidReview from "./RequestStateStatus/CorporateBidReview/CorporateBidReview";
import Scheduling from "./RequestStateStatus/Scheduling/Scheduling";
import Scheduled from "worksmith/composite-components/RequestState/RequestStateStatus/Scheduled/Scheduled";
import {SkeletonVariant} from "worksmith/components/Skeleton/Skeleton";
import Skeleton from "@mui/lab/Skeleton";
import RequestStateLayout from "worksmith/composite-components/RequestState/RequestStateStatus/RequestStateLayout";
import ClientLocationRole from "worksmith/enums/api/user/ClientLocationRole";
import Rescheduling from "worksmith/composite-components/RequestState/RequestStateStatus/Rescheduling/Rescheduling";
import {withTheme} from "../../components/Theme/ThemeProvider";

const RequestTypeAndServiceLine = (type, serviceLine, textVariant) => {
    return(
        <Fragment>
            <Text display={TextDisplay.INLINE}
              variant={textVariant}
              color={type === RequestDisplayType.EMERGENCY ? TextColor.ERROR : TextColor.PRIMARY}
              upperCase={type === RequestDisplayType.EMERGENCY}>
            {TitleCase(type)}
            </Text>
            <Text display={TextDisplay.INLINE} variant={textVariant} color={TextColor.PRIMARY}>
                {" " + TitleCase(serviceLine.name)}
            </Text>
        </Fragment>
    )
};

const RequestStateView = (props) => {
    let {chatRef,
        clientSettings,
        fullRequestLoading,
        partialRequestLoading,
        reloadFullRequest,
        reloadPartialRequest,
        request,
        startLoad,
        user} = props;
    const theme = useTheme();
    const onMobile =  useMediaQuery(theme.breakpoints.down('md'));
    let RequestStatusSection;
    let type;

    if (!fullRequestLoading) {
        type = DisplayTypeFromTypeAndEmergency(request.type, request.emergency);

        switch (request.newStatus) {
            case NewRequestStatus.CORPORATE_REVIEW:
                let clientRoleAtLocation = user.clientRoles && user.clientRoles.length > 0 ? false : user.clientLocationRoles.find(role => role.clientLocation.id === request.clientLocation.id)
                let canApproveCorporateReview = (user.clientRoles && user.clientRoles.length > 0)
                                                || (clientSettings.requiresApprovalToSubmit === 1 && clientRoleAtLocation && clientRoleAtLocation.role === ClientLocationRole.REGIONAL_MANAGER && request.status === "pending regional manager approval")
                                                || (clientSettings.requiresApprovalToSubmit === 0);
                RequestStatusSection = (
                    <CorporateReview
                        loading={partialRequestLoading}
                        canApprove={canApproveCorporateReview}
                        onCancel={reloadPartialRequest}
                        onApprove={reloadPartialRequest}
                        requestId={request.id}
                        startLoad={startLoad}/>
                )
                break;
            case NewRequestStatus.MARKET:
                RequestStatusSection = (
                    <ContactingVendors
                        chatRef={chatRef}
                        loading={partialRequestLoading}
                        onReleaseToNetwork={reloadPartialRequest}
                        requestId={request.id}
                        startLoad={startLoad}
                        userId={user.id}/>
                )
                break;
            case NewRequestStatus.CORPORATE_PRICING_REVIEW:
                RequestStatusSection = (
                    <CorporateBidReview
                        loading={partialRequestLoading}
                        onAcceptBid={reloadPartialRequest}
                        onDeclineBid={reloadPartialRequest}
                        onRequestCancel={reloadPartialRequest}
                        onRequestRevision={reloadPartialRequest}
                        requestId={request.id}
                        startLoad={startLoad}
                        user={user}/>
                )
                break;
            case NewRequestStatus.SCHEDULING:
                if (request.hasPendingChangeRequest) {
                    RequestStatusSection = (
                        <Rescheduling
                            loading={partialRequestLoading}
                            onAcceptChangeRequest={reloadPartialRequest}
                            onCancelChangeRequest={reloadPartialRequest}
                            onCreateChangeRequest={reloadPartialRequest}
                            onProposeSchedule={reloadPartialRequest}
                            requestId={request.id}
                            startLoad={startLoad}/>
                    )
                } else {
                    RequestStatusSection = (
                        <Scheduling
                            loading={partialRequestLoading}
                            onProposeSchedule={reloadPartialRequest}
                            onSchedule={reloadPartialRequest}
                            requestId={request.id}
                            startLoad={startLoad}
                            user={user}/>
                    )
                }
                break;
            case NewRequestStatus.SCHEDULED:
                RequestStatusSection = (
                    <Scheduled
                        loading={partialRequestLoading}
                        onAcceptChangeOrder={reloadPartialRequest}
                        onChangeRequestCreated={reloadPartialRequest}
                        onDeclineChangeOrder={reloadPartialRequest}
                        requestId={request.id}
                        startLoad={startLoad}/>
                )
                break;
            case NewRequestStatus.CANCELED:
                RequestStatusSection = (
                    <Canceled
                        loading={partialRequestLoading}
                        requestId={request.id}
                        userId={user.id}/>
                )
                break;
            case NewRequestStatus.COMPLETED:
                RequestStatusSection = (
                    <Completed
                        loading={partialRequestLoading}
                        onConcernRaised={reloadPartialRequest}
                        requestId={request.id}
                        userId={user.id}/>
                )
                break;
            default:
                RequestStatusSection = (
                    <RequestStateLayout loading={true} status={''} />
                )
        }
    } else  {
        RequestStatusSection = (
            <RequestStateLayout loading={true} status={''} />
        )
    }

    let singleLocationUser = user.clientLocationRoles && user.clientLocationRoles.length === 1;
    return (
        <Fragment>
            <Grid container
                  spacing={2}>
                {
                    fullRequestLoading ?
                        singleLocationUser ?
                            <Grid item xs={12}>
                                <Skeleton variant={SkeletonVariant.TEXT} width={ onMobile ? '20rem' : '40rem' }/>
                            </Grid>
                            :
                            <Fragment>
                                <Grid item xs={12}>
                                    <Skeleton variant={SkeletonVariant.TEXT} width={ onMobile ? '20rem' : '40rem' }/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton variant={SkeletonVariant.TEXT} width={ onMobile ? '15rem' : '30rem'}/>
                                </Grid>
                            </Fragment>
                        :
                        singleLocationUser ?
                            <Grid item xs={12}>
                                {RequestTypeAndServiceLine(type, request.serviceLine, TextVariant.H4)}
                            </Grid>
                            :
                            <Fragment>
                                <Grid item xs={12}>
                                    <Text variant={TextVariant.H4} color={TextColor.PRIMARY}>
                                        {request.clientLocation.title}
                                    </Text>
                                </Grid>
                                <Grid item xs={12}>
                                    {RequestTypeAndServiceLine(type, request.serviceLine, TextVariant.H5)}
                                </Grid>
                            </Fragment>
                }
            </Grid>
            <Box mt={5} mb={5}>
                {RequestStatusSection}
            </Box>
        </Fragment>

    )
};

RequestStateView.propTypes = {
    chatRef: PropTypes.object,
    loading: PropTypes.bool,
    clientSettings: PropTypes.object,
    fullRequestLoading: PropTypes.bool,
    partialRequestLoading: PropTypes.bool,
    reloadFullRequest: PropTypes.func.isRequired,
    reloadPartialRequest: PropTypes.func.isRequired,
    request: PropTypes.shape({
        clientLocation: PropTypes.shape({
            title: PropTypes.string
        }),
        emergency: PropTypes.bool,
        hasPendingChangeRequest: PropTypes.bool,
        newStatus: CustomPropTypes.enum(NewRequestStatus),
        serviceLine: PropTypes.shape({
            name: PropTypes.string
        }),
        type: CustomPropTypes.enum(RfpType)
    }),
    startLoad: PropTypes.func,
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
        clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
            clientLocation: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: CustomPropTypes.enum(ClientLocationRole),
        })),
    }).isRequired,
};

export default withTheme(RequestStateView);