import moment from 'moment';

export const AutoAcceptWalkthroughsDuringBusinessHours = Object.freeze({
    ALWAYS: "ALWAYS",
    SOMETIMES: "SOMETIMES",
    NEVER: "NEVER"
});

export const ClientDashboardType = Object.freeze({
    DEFAULT: 'default',
    DEFAULT_NEW: 'default new',
    KENDRA_SCOTT: 'kendra scott',
    BURBERRY: 'burberry',
    HUGO_BOSS: 'hugo boss',
    INTERMIX: 'intermix',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

//Used for the defaultMarket option that's part of clientConfigurationSettings
export const ClientDefaultMarketOption = Object.freeze({
    ALL_APPLICABLE: 'all applicable',
    PREFERRED_VENDORS: 'preferred vendors',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

//Used for the whoCanOpenMarket option that's part of clientConfigurationSettings
export const ClientType = Object.freeze({
    DRY_CLEANING_ONLY: "Dry Cleaning Only",
    NON_DRY_CLEANING_ONLY: "Non-dry cleaning only",
    ALL: "All"
});

//Used for the dashboardType option that's part of clientConfigurationSettings
export const ClientUserGroups = Object.freeze({
    NONE: "none",
    ANY: "any",
    ADMIN: "admin",
    ADMIN_AND_REGIONAL_MANAGER: "admin and regional manager",
    userInGroup: function (userGroup, user) {
        let isAdmin = !!user.clientRoles;
        let isRegionalManager = user.clientLocationRoles ? user.clientLocationRoles.filter(r => r.role === 'Regional Manager').length > 0 : false;

        switch (userGroup) {
            case this.ANY:
                return true;
            case this.ADMIN:
                return isAdmin;
            case this.ADMIN_AND_REGIONAL_MANAGER:
                return isAdmin || isRegionalManager;
            case this.NONE:
                return false;
            default:
                return false;
        }
    }
});

export const ClientWhoCanOpenMarketOption = Object.freeze({
    ADMIN_ONLY: 'admin only',
    ADMIN_AND_REGIONAL: 'admin and regional',
    ANY: 'any',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const Color = Object.freeze({
    RED: '#DD2E2B',
    ORANGE: '#F78B21',
    GREEN: '#6DAD3D',
    TURQUOISE: '#156F83',
    BLUE: '#3F7BBF',
    NAVY: '#293D4B',
    BLACK: '#171616',
    DARK_GREY: '#3D3D3D',
    BORDER_GREY: '#979797',
    MEDIUM_GREY: '#B2B2B2',
    GREY: '#D9D9D9',
    FORM_CONTROL_GREY: '#464a4c',
    OFF_WHITE: '#F8F8F8',
    WHITE: '#FFFFFF',
    TRANSPARENT: 'transparent'
});

export const FormFieldNames = Object.freeze({
    TRANSACTION_ID: 'transactionId',
    NAME: 'name',
    PHONE: 'phone',
    EMAIL: 'email',
    DATE_PROMISED: 'datePromised',
    IS_PREFERRED_CUSTOMER: 'isPreferredCustomer',
    LAST_FOUR_OF_CARD: 'lastFourOfCard',
    DELIVERY_TYPE: 'deliveryType',
    SEND_EMAIL_RECEIPT: 'sendEmailReceipt',
    SEND_UPDATES_TO_CUSTOMER: 'sendUpdatesToCustomer',
    OBLIGATION_ITEMS: 'obligationItems',
    TITLE: 'title',
    DESCRIPTION: 'description',
    QUANTITY: 'quantity',
    CUSTOMER_CHARGE: 'customerCharge',
    COMPLIMENTARY_REASON: 'complimentaryReason',
    REQUESTER_NAME: 'requesterName',
    STANDARD_PRICING_ID: 'standardPricingId',
    SERVICE_TYPE: 'serviceType',
    LINE_ITEM_TYPE_ID: 'lineItemTypeId',
    GROUP_ID: 'groupId',
    EMPLOYEE_NUMBER: 'employeeNumber',
    ADDRESS_LINE_ONE: 'addressLineOne',
    ADDRESS_LINE_TWO: 'addressLineTwo',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zip',
    EMPLOYEE_NAME: 'employeeName',
    STYLE_NUMBER: 'styleNumber',
    ITEM_TYPE: 'itemType',
    URGENCY: 'urgency',
    CUSTOMER_NUMBER: 'customerNumber',
    STORE_NUMBER: 'storeNumber',
    TRANSACTION_DATE: 'transactionDate',
    PRICE_CLIENT_CHARGED: 'priceClientCharged',
    GARMENT_NUMBER: 'garmentNumber',
    VALUE_OF_GARMENT: 'valueOfGarment',
    REQUESTER_EMAIL: 'requesterEmail',
    CONFIRM_SAMPLE_SALE: 'confirmSampleSale',
    EMPLOYEE_TICKET_TYPE: 'employeeTicketType',
    BU_NUMBER: 'BUNumber',
    BURBERRY_REQUEST_FORM_NUMBER: 'burberryRequestFormNumber',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    COMPANY_NAME: 'companyName',
    JOB_TITLE: 'jobTitle',
    BILLING_PROFILE_NAME: 'billingProfileName',
    BILLING_PROFILE_PHONE: 'billingProfilePhone',
    BILLING_PROFILE_EMAIL: 'billingProfileEmail',
    BACKGROUND_QUESTION_ONE: 'backgroundQuestionOne',
    BACKGROUND_QUESTION_TWO: 'backgroundQuestionTwo',
    BACKGROUND_QUESTION_THREE: 'backgroundQuestionThree',
    USES_FACILITIES_MAINTENANCE: 'usesFacilitiesManagement',
    USES_DRY_CLEANING_AND_TAILORING: 'usesDryCleaningAndTailoring',
    USES_TAILORING: 'usesTailoring',
    USES_EVENTS_AND_EXPERIENCES: 'usesEventsAndExperiences',
    USES_OFFICE_SETUP: 'usesOfficeSetup',
    FACILITIES_MANAGEMENT_QUESTION_ONE: 'facilitiesManagementQuestionOne',
    FACILITIES_MANAGEMENT_QUESTION_TWO: 'facilitiesManagementQuestionTwo',
    DRY_CLEANING_AND_TAILORING_QUESTION_ONE: 'dryCleaningAndTailoringQuestionOne',
    DRY_CLEANING_AND_TAILORING_QUESTION_TWO: 'dryCleaningAndTailoringQuestionTwo',
    TAILORING_QUESTION_ONE: 'tailoringQuestionOne',
    TAILORING_QUESTION_TWO: 'tailoringQuestionTwo',
    EVENTS_AND_EXPERIENCES_QUESTION_ONE: 'eventsAndExperiencesQuestionOne',
    EVENTS_AND_EXPERIENCES_QUESTION_TWO: 'eventsAndExperiencesQuestionTwo',
    OFFICE_SETUP_QUESTION_ONE: 'officeSetupQuestionOne',
    OFFICE_SETUP_QUESTION_TWO: 'officeSetupQuestionTwo',
    GENERIC_HEADER: 'genericHeader',
    SERVICES_QUESTION_ONE: 'servicesQuestionOne',
    USES_OTHER_SERVICES: 'usesOtherServices',
    OTHER_QUESTION_TWO: 'otherQuestionTwo',
    OTHER_QUESTION_THREE: 'otherQuestionThree',
    OTHER_QUESTION_ONE: 'otherQuestionOne',
    BILLING_PROFILE_FIRST_NAME: 'billingProfileFirstName',
    BILLING_PROFILE_LAST_NAME: 'billingProfileLastName',
    SCHEDULE_OPTION_DAY_ONE: 'scheduleOptionDayOne',
    SCHEDULE_OPTION_TIME_ONE: 'scheduleOptionTimeOne',
    SCHEDULE_OPTION_DAY_TWO: 'scheduleOptionDayTwo',
    SCHEDULE_OPTION_TIME_TWO: 'scheduleOptionTimeTwo',
    SCHEDULE_OPTION_DAY_THREE: 'scheduleOptionDayThree',
    SCHEDULE_OPTION_TIME_THREE: 'scheduleOptionTimeThree',
    GENERIC_HEADER_2: 'genericHeader2',
    GENERIC_HEADER_3: 'genericHeader3',
    SCHEDULE_OPTION_TIMEZONE: 'scheduleOptionTimezone',
    CONDITION: 'condition',
    REQUESTING_ESTIMATE: 'requestingEstimate',
    EXPEDITED_SHIPPING_REQUESTED: 'expeditedShippingRequested',
    ALTERATIONS: 'alterations',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const GlobalRoutes = Object.freeze({
    APP: '/',
    SERVICE_AGREEMENT: '/serviceAgreement',
    PRESIGNED_CHECK: '/validate'
});

export const Headers = Object.freeze({
    CONTENT_TYPE_JSON: {"Content-Type": "application/json"},
    MULTIPART_FORM_DATA: {
        "Content-Type": "multipart/form-data",
        "transfer-encoding": "chunked"
    }
});

export const JavascriptType = Object.freeze({
    BOOLEAN: 'boolean',
    FUNCTION: 'function',
    NUMBER: 'number',
    OBJECT: 'object',
    STRING: 'string',
    SYMBOL: 'symbol',
    UNDEFINED: 'undefined'
});

export const LineItemTypeId = Object.freeze({
    DRY_CLEAN: 1,
    ALTERATIONS: 2
});

export const LoggedOutRoutes = Object.freeze({
    LOGIN: '/',
    OAUTH: '/authenticate', //Template used to match all oauth types
    GOOGLE_AUTH: '/authenticate?authType=google',
    SQUARE_LOGIN: '/square',
    SQUARE_AUTH: '/authenticate?authType=square',
    BURBERRY_LOGIN: '/burberry',
    BURBERRY_AUTH: '/authenticate?authType=burberry',
    FORGOT_PASSWORD: '/forgot-password',
    ACCOUNT_CREATION: '/accountCreation',
    NONCORPORATE_CLIENT_ACCOUNT_CREATION: '/locationAccountCreation',
    WHITE_LABEL_SPLASH: '/download',
});

export const ModalStyle = Object.freeze({
    DEFAULT: {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        content: {
            position: 'absolute',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            border: '1px solid ' + Color.GREY,
            background: Color.WHITE,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'

        }
    },
    CONFIRMATION: {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        content: {
            position: 'absolute',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            height: '120px',
            border: '1px solid ' + Color.GREY,
            background: Color.WHITE,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
        }
    },
    AUTOSIZED: {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        content: {
            position: 'relative',
            top: undefined,
            left: undefined,
            right: undefined,
            bottom: undefined,
            border: '1px solid ' + Color.GREY,
            background: Color.WHITE,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            margin: '40px auto',
            width: 'fit-content',
        }
    },
});

export const NewRequestStatus = Object.freeze({
    MARKET: 'market',
    SCHEDULING: 'scheduling',
    SCHEDULED: 'scheduled',
    COMPLETED: 'completed',
    CORPORATE_REVIEW: 'corporate review',
    CORPORATE_PRICING_REVIEW: 'corporate pricing review',
    CANCELED: 'canceled',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const NTEType = Object.freeze({
    REGIONAL: 'regional',
    ADMIN: 'admin',
    WORKSMITH: 'worksmith'
});

export const OAuthProvider = Object.freeze({
    GOOGLE: 'google',
    BURBERRY: 'burberry',
    SQUARE: 'square',
    AUTH0: 'auth0'
});

export const ObligationStatus = Object.freeze({
    PENDING: 'pending',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const ObligationType = Object.freeze({
    NORMAL: 'normal',
    ON_SITE: 'on site',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const PortalType = Object.freeze({
    CLIENT: 'Client'
});

export const RequestMethod = Object.freeze({
    DELETE: 'DELETE',
    GET: 'GET',
    HEAD: 'HEAD',
    OPTIONS: 'OPTIONS',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT'
});

export const RequestType = Object.freeze({
    RECURRING: 'recurring',
    ON_DEMAND: 'on-demand',
    PRICING_ONLY: 'pricing-only'
});

export const RfpMarket = Object.freeze({
    OPEN_NETWORK: 'open market',
    WARRANTY: 'warranty',
    NONE: 'none',
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    },
    getContactingVendorsStatuses: function () {
        return [this.NEW, this.SOURCING, this.WALKTHROUGH_SCHEDULED, this.NEEDS_CLIENT_INPUT];
    }
});

export const RfpStatus = Object.freeze({
    NEW: 'new',
    PENDING_ADMIN: 'pending admin approval',
    PENDING_REGIONAL_MANAGER: 'pending regional manager approval',
    PENDING_ADMIN_BID: 'pending admin bid approval',
    PENDING_REGIONAL_MANAGER_BID: 'pending regional manager bid approval',
    SOURCING: 'sourcing',
    WALKTHROUGH_SCHEDULED: 'walkthrough scheduled',
    NEEDS_CLIENT_INPUT: 'needs client input',
    OPEN: 'open',
    CLOSED: 'closed',
    SCHEDULED: 'scheduled',
    SCHEDULING: 'scheduling',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
    getBidPhaseStatuses: function () {
        return [this.NEW, this.SOURCING, this.WALKTHROUGH_SCHEDULED, this.NEEDS_CLIENT_INPUT]
    },
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    },
    getPendingReviewStatuses: function () {
        return [this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER];
    },
    getActiveStatuses: function () {
        return [this.NEW, this.SOURCING, this.WALKTHROUGH_SCHEDULED, this.NEEDS_CLIENT_INPUT,
                this.PENDING_ADMIN, this.PENDING_ADMIN_BID, this.PENDING_REGIONAL_MANAGER, this.PENDING_REGIONAL_MANAGER_BID,
                this.OPEN, this.SCHEDULING];
    }
});

export const ScheduleStatus = Object.freeze({
    ACCEPTED: ("ACCEPTED"),
    CANCELED: ("CANCELED"),
    DECLINED: ("DECLINED"),
    NEEDS_CLIENT: ("NEEDS_CLIENT"),
    NEEDS_VENDOR: ("NEEDS_VENDOR"),
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    },
    getOpenStatuses: function () {
        return [this.ACCEPTED, this.NEEDS_CLIENT, this.NEEDS_VENDOR];
    }
});

export const ScheduleType = Object.freeze({
    ONE_TIME: ("one-time"),
    RECURRING: ("recurring"),
    getKeyFromValue: function (value) {
        return Object.keys(this).find(key => this[key] === value);
    }
});

export const ServiceLine = Object.freeze({
    APPLIANCE_REPAIR: 'Appliance Repair',
    BEVERAGE_SERVICE: 'Beverage Services',
    CARPET_CLEANING: 'Carpet Cleaning',
    COMMERCIAL_CLEANING: 'Commercial Cleaning',
    COURIER_SERVICES: 'Courier Services',
    DOOR_REPAIR: 'Door Repair',
    DRY_CLEANING_AND_ALTERATIONS: 'Dry Cleaning and Alterations',
    ELECTRICAL_WORK: 'Electrical Work',
    EVENT_RENTALS: 'Event Rentals',
    FIRE_AND_SAFETY_INSPECTION: 'Fire and Safety Inspection',
    FLOORING_REPAIR: 'Flooring Repair',
    HANDYMAN: 'Handyman',
    HURRICANE_PREPARATION: 'Hurricane Preparation',
    HVAC: 'HVAC',
    LANDSCAPING: 'Landscaping',
    LEATHER_REPAIR: 'Leather Repair',
    LIGHTING: 'Lighting',
    LOCKSMITH: 'Locksmith',
    MOLD_INSPECTION: 'Mold Inspection',
    OTHER: 'Other',
    PAINTING: 'Painting',
    PEST_CONTROL: 'Pest Control',
    PLUMBING: 'Plumbing',
    REFRIGERATION_SERVICES: 'Refrigeration Services',
    SEWING_MACHINE_REPAIR: 'Sewing Machine Repair',
    SNOW_REMOVAL: 'Snow Removal',
    TRASH_REMOVAL: 'Trash Removal',
    TRENCHCOAT_REPROOFING_AND_LINING: 'Trenchcoat Reproofing & Lining',
    UPHOLSTERY: 'Upholstery',
    WATCH_REPAIR: 'Watch Repair',
    WINDOW_WASHING: 'Window Washing',
    ZIPPER_REPAIR: 'Zipper Repair'
});

export const StripePaymentMethodType = Object.freeze({
    CARD: 'card',
    CARD_PRESENT: 'card_present'
});

export const TicketMetadata = Object.freeze({
    DELIVERY_COMMENTS: 'Delivery comments'
});

export const WalkthroughPreference = Object.freeze({
    REQUIRED: "Walkthrough Required",
    PROHIBITED: "Walkthrough Prohibited",
    OPTIONAL: "Walkthrough Optional"
});

export const WorksmithConstants = Object.freeze({
    UNSET: -100
});

export const RequestSchedulingOption = Object.freeze({
    WITHIN_A_WEEK: 'within a week',
    WITHIN_2_WEEKS: 'within 2 weeks',
    WITHIN_A_MONTH: 'within a month',
    ON_A_SPECIFIC_DATE: 'on a specific date'
});

export const bidOrEstimateType = Object.freeze({
    BID: 'bid',
    ESTIMATE: 'estimate'
});

export const ServiceLineType = Object.freeze({
    DRY_CLEANING_AND_ALTERATIONS: 1,
    COMMERCIAL_CLEANING: 2,
    HANDYMAN: 3,
    HVAC: 4,
    PLUMBING: 5,
    PAINTING: 6,
    ELECTRICAL_WORK: 7,
    LOCKSMITH: 8,
    PEST_CONTROL: 9,
    LANDSCAPING: 10,
    OTHER: 11,
    UPHOLSTERY: 12,
    TRASH_REMOVAL: 13,
    CARPET_CLEANING: 14,
    SNOW_REMOVAL: 15,
    FIRE_AND_SAFETY_INSPECTION: 16,
    MOLD_INSPECTION: 17,
    WINDOW_WASHING: 18,
    APPLIANCE_REPAIR: 19,
    SEWING_MACHINE_REPAIR: 20,
    FLOORING_REPAIR: 21,
    EVENT_RENTALS: 22,
    BEVERAGE_SERVICES: 23,
    DOOR_REPAIR: 24,
    HURRICANE_PREPARATION: 25,
    ZIPPER_REPAIR: 26,
    WATCH_REPAIR: 27,
    TRENCHCOAT_REPROOFING_AND_LINING: 28,
    REFRIGERATION_SERVICES: 29,
    LIGHTING: 30,
    LEATHER_REPAIR: 31,
    WATCH_PARTS: 32,
    FLOWER_AND_PLANT_DELIVERY: 33,
    CATERING_SERVICES: 34,
    MOVING: 35,
    SECURITY: 36,
    COURIER_SERVICES: 37,
    EXTRA_LABOR: 38,
    SIGNAGE: 39,
    POWER_WASHING: 40,
    GLASS_AND_MIRROR_REPAIR: 41,
    IT_SETUP: 42,
    GREASE_TRAP_SERVICE: 43,
    SITE_SURVEY: 44
});

export const ExploReportingVariableValues = Object.freeze({
    START_OF_TRAILING_YEAR: 'START_OF_TRAILING_YEAR',
    END_OF_TRAILING_YEAR: 'END_OF_TRAILING_YEAR',
    START_OF_CURRENT_MONTH: 'START_OF_CURRENT_MONTH',
    END_OF_CURRENT_MONTH: 'END_OF_CURRENT_MONTH',
    LAST_MONTH_MONTH: 'LAST_MONTH_MONTH',
    LAST_MONTH_YEAR: 'LAST_MONTH_YEAR',
    calculateValue: function (value) {
        if(value == this.START_OF_TRAILING_YEAR) {
            return moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
        } else if (value == this.END_OF_TRAILING_YEAR) {
            return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        } else if (value == this.START_OF_CURRENT_MONTH) {
            return moment().startOf('month').format('YYYY-MM-DD');
        } else if (value == this.END_OF_CURRENT_MONTH) {
            return moment().endOf('month').format('YYYY-MM-DD');
        } else if (value == this.LAST_MONTH_MONTH) {
            return Number(moment().subtract(1, 'months').format('M'));
        } else if (value == this.LAST_MONTH_YEAR) {
            return Number(moment().subtract(1, 'months').format('YYYY'));
        } else {
            return moment().format('YYYY-MM-DD');
        }
    }
});

export const InternalNotesType = Object.freeze({
    NONE: 'NONE',
    ALL: 'ALL',
    WARRANTY_ONLY: 'WARRANTY_ONLY'
});
