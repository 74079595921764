import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import Grid from "worksmith/components/Grid/Grid.web";


const BlockedText = styled(Text)`
    padding-bottom: 0.75em;
    padding-top: 0.75em;
`;

const UnderlineLink = styled(Link)`
    text-decoration: underline;
    color: #156F83;
`;


const NoRecurringService = (props) => {
    return <div>
        <BlockedText bold={false} variant={TextVariant.SUBTITLE_1}>Current Service</BlockedText>
        <BlockedText>There is no recurring service at this location. If you'd like to set up a recurring {props.serviceLine.name} service please <UnderlineLink to={"/" + "new-request"}>submit a new request.</UnderlineLink></BlockedText>
        <Grid container>
            <Grid item xs={6} sm={3}>
                <BlockedText semiBold> Service Status</BlockedText>
            </Grid>
            <Grid item xs={6} sm={3}>
                <BlockedText>No Service</BlockedText>
            </Grid>
        </Grid>
    </div>;
};

NoRecurringService.propTypes = {
    serviceLine: PropTypes.object
};

export default NoRecurringService;