// This file was generated from com.worksmith.task.enums.RecurrenceType
// noinspection JSUnusedGlobalSymbols
const RecurrenceType = Object.freeze({
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly'
});

export default RecurrenceType;
