import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import RequestStateLayout from "../RequestStateLayout";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import Text, {TextColor, TextVariant} from "../../../../components/Text/Text.web";
import {AlignItems, Display, JustifyContent} from "worksmith/enums/CSSEnums";
import Button, {ButtonSize, ButtonVariant} from "../../../../components/Button/Button.web";
import ChangeOrderRequestReviewView
    from "../../../../components/ChangeOrderRequestReview/ChangeOrderRequestReviewView.web";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import styled from "styled-components";
import AcceptChangeOrderDialog from "worksmith/components/ChangeOrderRequestReview/AcceptChangeOrderDialog";
import DeclineChangeOrderDialog from "worksmith/components/ChangeOrderRequestReview/DeclineChangeOrderDialog";
import {formatTimestamp, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {WithContext} from "../../../../../context/GlobalContext";
import Grid from "worksmith/components/Grid/Grid";


const StyledText = styled(Text)`
    padding-top: 0.8em;
`;

class ScheduledChangeOrderView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acceptChangeOrderModalOpen: false,
            declineChangeOrderModalOpen: false
        };
    }

    showAcceptChangeOrderModal = () => {
        this.setState({acceptChangeOrderModalOpen: true});
    };

    hideAcceptChangeOrderModal = () => {
        this.setState({acceptChangeOrderModalOpen: false});
    };

    showDeclineChangeOrderModal = () => {
        this.setState({declineChangeOrderModalOpen: true});
    };

    hideDeclineChangeOrderModal = () => {
        this.setState({declineChangeOrderModalOpen: false});
    };

    render() {
        let {
            hideAcceptChangeOrderModal,
            hideDeclineChangeOrderModal,
            showAcceptChangeOrderModal,
            showDeclineChangeOrderModal
        } = this;
        const {acceptChangeOrderModalOpen, declineChangeOrderModalOpen} = this.state;
        const {
            context,
            acceptChangeOrder,
            canAcceptChangeOrder,
            changeOrder,
            declineChangeOrder,
            vendorLocation
        } = this.props;

        const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;

        let description = vendorLocation.vendor.officialName + ' opened a change order that reflects any additions, omissions, or substitutions to the original '
            + 'scope of work.';

        description += (canAcceptChangeOrder ?
            ' Accepting the change order will replace your current pricing. '
            + 'Declining will keep your current pricing in place. '
            + 'See the Job Details section below for your currently accepted pricing.'
            :
            ' The pricing is above your approved threshold and requires authorization from an Admin.'
            + ' You will be notified if it has been accepted.'
            + (hidePricingForNonAdminUsers ? '' : ' Declining will keep your current pricing in place.'
            + ' See the Job Details section below for your currently accepted pricing.'));

        return (
            <Fragment>
                <AcceptChangeOrderDialog
                    open={acceptChangeOrderModalOpen}
                    onAcceptChangeOrder={() => {
                        hideAcceptChangeOrderModal();
                        acceptChangeOrder();
                    }}
                    onCancel={hideAcceptChangeOrderModal}/>
                <DeclineChangeOrderDialog
                    open={declineChangeOrderModalOpen}
                    onClose={hideDeclineChangeOrderModal}
                    onSubmit={(declineReason) => {
                        hideDeclineChangeOrderModal();
                        declineChangeOrder(declineReason)
                    }}/>
                <RequestStateLayout
                    description={(
                        <Text variant={TextVariant.BODY_1}>
                            {description}
                        </Text>
                    )}
                    body={(
                        <Fragment>
                        { !hidePricingForNonAdminUsers &&
                            <>
                                <ChangeOrderRequestReviewView
                                    hideTitle
                                    hideVendorInformation
                                    additionalApproval={changeOrder.changedAdditionalApprovalAmount ? changeOrder.changedAdditionalApprovalAmount : changeOrder.originalAdditionalApprovalAmount}
                                    lineItems={changeOrder.changedLineItems}
                                    scopeOfWork={changeOrder.changedScopeOfWork}
                                />
                                <Box display={Display.INLINE_FLEX} mt={3}>
                                    <Box mr={2}>
                                        <Button onClick={showDeclineChangeOrderModal}
                                                size={ButtonSize.LARGE}
                                                variant={ButtonVariant.OUTLINED}
                                                primary>
                                            Decline
                                        </Button>
                                    </Box>
                                    {
                                        canAcceptChangeOrder ?
                                            <Button onClick={showAcceptChangeOrderModal}
                                                    size={ButtonSize.LARGE}
                                                    primary>
                                                Accept
                                            </Button>
                                            :
                                            <StyledText italic>The change order is above your approval threshold. Please message your Admin.</StyledText>

                                    }
                                </Box>
                                <Grid container justify={JustifyContent.FLEX_END} alignItems={AlignItems.FLEX_END} xs={12}>
                                    {ValueIsSet(changeOrder.changeOrderSubmittedActivity) ?
                                        <Text>{'Submitted by ' + changeOrder.changeOrderSubmittedActivity.user.displayName + ' on ' + formatTimestamp(changeOrder.changeOrderSubmittedActivity.createdTimestamp)}</Text>
                                        :
                                        ValueIsSet(changeOrder.createdTimestamp) ?
                                            <Text>{'Submitted on ' + formatTimestamp(changeOrder.createdTimestamp)}</Text>
                                            : null
                                    }
                                </Grid>
                            </>
                        }
                        </Fragment>
                    )}
                    statusColor={TextColor.ERROR}
                    status={`${TitleCase(NewRequestStatus.SCHEDULED)} - Open Change Order`}/>
            </Fragment>
        )
    }
}

ScheduledChangeOrderView.propTypes = {
    acceptChangeOrder: PropTypes.func.isRequired,
    canAcceptChangeOrder: PropTypes.bool.isRequired,
    changeOrder: PropTypes.shape({
        id: PropTypes.number,
        changedAdditionalApprovalAmount: PropTypes.number,
        changedLineItems: PropTypes.arrayOf(PropTypes.shape({
            lineItemType: PropTypes.shape({
                name: PropTypes.string
            }),
            description: PropTypes.string,
            id: PropTypes.number,
            quantity: PropTypes.number,
            unitRetailPrice: PropTypes.number
        })),
        changedScopeOfWork: PropTypes.string,
        originalAdditionalApprovalAmount: PropTypes.number
    }).isRequired,
    declineChangeOrder: PropTypes.func.isRequired,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

export default WithContext(ScheduledChangeOrderView);