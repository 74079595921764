import React, {Component} from 'react';
import UserService from "worksmith/services/api/UserService";
import {Link} from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import {Color} from "worksmith/enums/Color";
import Loader from "../../components/Loader";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const userService = new UserService();

const BlankPageContainer = styled.div`
    color: ${Color.PRIMARY}
    background-color:white;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
    font-family:Avenir,Helvetica,Arial;
`;

const CenteredContainer = styled.div`
    margin-top:15%;
    z-index:1001;
`;

class UnsubscribePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            loading: true,
            notificationSetting: props.match.params.notificationSetting
        }
    }

    componentDidMount() {
        let {user,notificationSetting} = this.state;
        if(ValueIsSet(user.clientNotifications[notificationSetting])){
        user.clientNotifications[notificationSetting] = false;
            userService.update(user).then(() =>
                this.setState({loading: false})
            )
        } else {
            DisplayErrorNotification('Unable to unsubscribe from email, please contact Worksmith support');
            this.props.history.push('/');
        }
    }

    render() {
        let {loading, notificationSetting} = this.state;
        let notificationSettingPhrase = '';
        switch(notificationSetting) {
            case 'dailyDigest' :
                notificationSettingPhrase = 'Worksmith Daily Digest';
                break;
            default :
                notificationSettingPhrase = 'Worksmith';
                break;
        }

        return (
            loading ? <Loader/> :
            <BlankPageContainer>
                <CenteredContainer>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <h1>Unsubscribe successful</h1>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <span>You've successfully unsubscribed from the {notificationSettingPhrase} email.</span>
                        </div>
                        <div className="col-md-12">
                            <span>Made a mistake? Re-subscribe <Link to='/account'>here.</Link></span>
                        </div>
                    </div>
                </CenteredContainer>
            </BlankPageContainer>
        )
    }
}

UnsubscribePage.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UnsubscribePage;