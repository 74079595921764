import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import {ValueIsSet} from "../../helpers/GenericHelpers";
import {JavascriptType} from "../../enums/GenericEnums";
import Text, {TextColor, TextVariant} from "../Text/Text.web";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {DialogSize} from "../../enums/DialogSize";

const SimpleDialog = (props) => {

    const {
        body,
        children,
        fullScreenOnMobile,
        fullScreen,
        fullWidth,
        open,
        keepMounted,
        onClose,
        PaperProps,
        size,
        title
    } = props;
    const theme = useTheme();
    let onMobile =  useMediaQuery(theme.breakpoints.down('md'));

    let fullScreenDialog = fullScreen || (fullScreenOnMobile && onMobile);

    return (
        <Dialog
            fullScreen={fullScreenDialog}
            fullWidth={fullWidth}
            keepMounted={keepMounted}
            maxWidth={size}
            open={open}
            onClose={onClose}
            PaperProps={PaperProps}
            scroll={'body'}>
            {ValueIsSet(title) ? (
                    <DialogTitle>
                        {typeof title === JavascriptType.STRING ? (
                                <Text variant={TextVariant.H6}>{title}</Text>
                            )
                            : title}
                    </DialogTitle>
                )
                : null}
            <DialogContent>
                {
                    body ?
                        (<Fragment>
                            <DialogContentText color={TextColor.TEXT_PRIMARY}>
                                {body}
                            </DialogContentText>
                            {children}
                        </Fragment>)
                        :
                        children
                }
            </DialogContent>
        </Dialog>
    );
};

SimpleDialog.propTypes = {
    body: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    fullWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullScreenOnMobile: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    keepMounted: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    PaperProps: PropTypes.object,
    size: CustomPropTypes.enum(DialogSize).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

SimpleDialog.defaultProps = {
    fullScreenOnMobile: false,
    keepMounted: false,
    size: DialogSize.SMALL,
};

export default SimpleDialog;