import React from 'react';
import PropTypes from 'prop-types';

import AddressPropType from "../../../custom-prop-types/AddressPropType";
import {AlignItems} from "../../../enums/CSSEnums"
import Button, {ButtonVariant} from "../../../components/Button/Button";
import {convertYMDToMDY} from "../../../helpers/TimeHelpers";
import Grid from "../../../components/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import {IconType} from "../../../enums/MaterialEnums";
import Text, {TextColor, TextVariant} from "../../../components/Text/Text";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {CountryCode} from "worksmith/enums/api/country/CountryCode";
import {Country} from "worksmith/enums/api/country/Country";
import {Currency} from "worksmith/enums/api/country/Currency";
import GeneralInfoForm from "worksmith/composite-components/ClientLocationProfile/generalInfo/GeneralInfoForm";

const GeneralInfoView = ({generalInfoFormFields, readOnly, toggleEdit, location, isSelfService}) => {

    const LabelText = ({text}) => (
        <Text variant={TextVariant.BODY_2} color={TextColor.TEXT_SECONDARY} gutterBottom semiBold>{text}</Text>
    );

    const ContentText = ({text}) => (
        <Text variant={TextVariant.BODY_1} gutterBottom>{text}</Text>
    );

    const city = generalInfoFormFields.city ? `${generalInfoFormFields.city}, ` : '';
    const state = generalInfoFormFields.state ? `${generalInfoFormFields.state}` : '';
    const zip = generalInfoFormFields.zip ? ` ${generalInfoFormFields.zip}` : '';
    const address = `${city}${state}${zip}`;

    return (
        <Grid container alignItems={AlignItems.FLEX_START}>
            <Grid container item xs={12} md={6} alignItems={AlignItems.FLEX_START}>
                <Grid item xs={12}>
                    <LabelText text={'Location title'}/>
                    <ContentText text={generalInfoFormFields.title} />
                </Grid>
                <Grid item xs={12}>
                    <LabelText text={'Location ID code'}/>
                    <ContentText text={generalInfoFormFields.locationCode} />
                </Grid>
                <Grid item xs={12}>
                    <LabelText text={'Opening date'}/>
                    <ContentText text={generalInfoFormFields.openingDate ? convertYMDToMDY(generalInfoFormFields.openingDate) : '—'} />
                </Grid>
                <Grid item xs={12}>
                    <LabelText text={'Industry'}/>
                    <ContentText text={generalInfoFormFields.otherIndustry ? generalInfoFormFields.otherIndustry : (generalInfoFormFields.industry ? generalInfoFormFields.industry : '—')} />
                </Grid>
                <Grid item xs={12}>
                    <LabelText text={'Location phone number'}/>
                    <ContentText text={generalInfoFormFields.phone ? ValueIsSet(location.countryCode) ? CountryCode[location.countryCode] + " " +  generalInfoFormFields.phone : generalInfoFormFields.phone : '—'} />
                </Grid>
                <Grid item xs={12}>
                    <LabelText text={'Location email'}/>
                    <ContentText text={generalInfoFormFields.email ? generalInfoFormFields.email : '—'} />
                </Grid>
            </Grid>
            <Grid container item xs={12} md={6} alignItems={AlignItems.FLEX_START}>
                <Grid item xs={12}>
                    <LabelText text={'Location Address'}/>
                    <ContentText text={generalInfoFormFields.addressLineOne ? generalInfoFormFields.addressLineOne : '—'} />
                    {generalInfoFormFields.addressLineTwo ? <ContentText text={generalInfoFormFields.addressLineTwo}/> : null}
                    <ContentText text={address} />
                    {isSelfService && generalInfoFormFields.country ?
                        <ContentText text={Country[generalInfoFormFields.country]} /> : null
                    }
                </Grid>
                {isSelfService ?
                    <Grid item xs={12}>
                        <LabelText text={'Currency'}/>
                        <ContentText text={generalInfoFormFields.currency ? `Currency: ${Currency[generalInfoFormFields.currency]}` : '—'} />
                    </Grid>
                : null}
                <Grid item xs={12}>
                    <LabelText text={'Is this location in a mall?'}/>
                    <ContentText text={generalInfoFormFields.isInMall === false ? 'No' : generalInfoFormFields.isInMall ? `Yes - ${generalInfoFormFields.mall && generalInfoFormFields.mall.name ? generalInfoFormFields.mall.name : 'mall not specified'}` : '—'} />
                </Grid>
                <Grid item xs={12}>
                    <LabelText text={'Is this location a shop-in-shop? (i.e. a Starbucks inside a bookstore)'}/>
                    <ContentText text={generalInfoFormFields.isShopInShop === true ? `Yes` : generalInfoFormFields.isShopInShop === false ? 'No' : '—'} />
                </Grid>
            </Grid>
            {
                !readOnly ?
                    <Grid container item xs={8} margin={'32px 0 0 0'}>
                        <Grid item xs={2}>
                            <Button fullWidth variant={ButtonVariant.OUTLINED} startIcon={<Icon name={IconType.EDIT} />} onClick={toggleEdit}>EDIT</Button>
                        </Grid>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
};


GeneralInfoView.defaultProps = {
    isSelfService: false
};

GeneralInfoView.propTypes = {
    generalInfoFormFields: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        email: PropTypes.string,
        isInMall: PropTypes.bool,
        id: PropTypes.number,
        industry: PropTypes.string,
        otherIndustry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        locationCode: PropTypes.string,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: AddressPropType
        })
    }),
    readOnly: PropTypes.bool,
    toggleEdit: PropTypes.func
};

export default GeneralInfoView;

