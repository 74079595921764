import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import LinearProgress, {LinearProgressVariant} from "worksmith/components/LinearProgress/LinearProgress";
import Swal from "sweetalert2";
import {StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {DisplayErrorNotification} from "../../helpers/SweetAlertHelpers";

const LoadingWithTimeoutDialog = (props) => {
    const {loading, loadingDialogTitle, error, timeoutErrorTitle, timeoutErrorBody, otherErrorMessagePreamble} = props;

    useEffect(() => {
        if(ValueIsSet(error)){
            let timeoutErrorOccurred = ValueIsSet(error.response) && error.response.status === 504;

            if(timeoutErrorOccurred && StringHasText(timeoutErrorTitle))
                Swal.fire({
                    title: timeoutErrorTitle,
                    type: 'warning',
                    text: timeoutErrorBody
                });
            else
                DisplayErrorNotification(otherErrorMessagePreamble + error.message);
        }
    }, [error]);

    return (
        <>
            {
                loading ?
                    <AlertDialog
                        title={loadingDialogTitle}
                        customButtons={[]}
                        open={loading}
                    >
                        <LinearProgress variant={LinearProgressVariant.INDETERMINATE}/>
                    </AlertDialog>
                    :
                    null
            }
        </>
    )
};

LoadingWithTimeoutDialog.propTypes = {
    loading: PropTypes.bool,
    loadingDialogTitle: PropTypes.string,
    error: PropTypes.object, // error object returned from catch statement of a promise
    timeoutErrorTitle: PropTypes.string,
    timeoutErrorBody: PropTypes.string,
    otherErrorMessagePreamble: PropTypes.string
};

export default LoadingWithTimeoutDialog;