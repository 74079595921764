import React from "react";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {toCurrencyString} from "worksmith/helpers/LanguageHelper"


const Currency = (props) => {
    const {amount} = props;

    return <>{toCurrencyString(Number(amount))}</>;
};

Currency.propTypes = {
    amount: CustomPropTypes.stringOrNumber.isRequired,
};

export default Currency;