import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import Currency from "worksmith/components/Currency/Currency.web";
import styled from "styled-components";

const TotalText = styled.div`
    margin-left: -2em;
`;

const InvoiceSectionTotal = (props) => {
    const {amount, openBalance, title} = props;

    return (
        <Fragment>
            <Grid container>
                <Grid item sm={8}>
                    <TotalText>
                        <Text variant={TextVariant.SUBTITLE_2}>{title}</Text>
                    </TotalText>
                </Grid>
                <Grid item sm={2}>
                    <Text variant={TextVariant.SUBTITLE_2}>
                        <Currency amount={amount}/>
                    </Text>
                </Grid>
                <Grid item sm={2}>
                    <Text variant={TextVariant.SUBTITLE_2}>
                        <Currency amount={openBalance}/>
                    </Text>
                </Grid>
            </Grid>
        </Fragment>
    )
};

InvoiceSectionTotal.propTypes = {
    amount:      PropTypes.number.isRequired,
    openBalance: PropTypes.number.isRequired,
    title:       PropTypes.string.isRequired
};

export default InvoiceSectionTotal;