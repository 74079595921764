import React, {Component} from 'react';
import Logo from '../assets/logo-svg.svg';
import Isvg from 'react-inlinesvg';
import styled from 'styled-components'

const StyledContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
`

class Loader extends Component {
    render(){
        let {id} = this.props;
        return(
            <StyledContainer id={id} className="text-center loader">
                <Isvg src={Logo} />
            </StyledContainer>
        )
    }
}

export default Loader;