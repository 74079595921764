// This file was generated from com.worksmith.vpm.controller.VendorLocationController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class VendorLocationTailorService extends Http {
    constructor() {
        super('/vpm/vendorLocationTailor')
    }

    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }

    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }

    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }

    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
}

export default VendorLocationTailorService;
