import React, {Component, Fragment} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import firebase from 'firebase/app';

import LoggedInNavigator from "./LoggedInNavigator";
import LoggedOutNavigator from "./LoggedOutNavigator";
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import {GlobalRoutes, LoggedOutRoutes} from "./Enums";
import ServiceAgreement from "./pages/serviceAgreement/ServiceAgreement";
import {GetURLSearchParams} from "./Helpers";
import PresignedCheck from "./components/auth/PresignedCheck";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import * as WebSessionHelper from "worksmith/helpers/WebSessionHelper";
import GlobalSnackbar from "worksmith/components/Snackbar/GlobalSnackbar";

let authTokenManager = new AuthTokenManager();

class App extends Component {
    constructor() {
        super();

        this.state = {
            isAuthorized: authTokenManager.isAuthorized()
        };

        if(!ValueIsSet(WebSessionHelper.showRedirectToAppModal()))
            WebSessionHelper.setShowRedirectToAppModal(true);

        firebase.initializeApp({
            apiKey: "AIzaSyBQxnq8tDDRPSHuMEzsqR93ca0NGiokdsQ",
            authDomain: "worksmith-portal-client.firebaseapp.com",
            databaseURL: "https://worksmith-portal-client.firebaseio.com",
            projectId: "worksmith-portal-client",
            storageBucket: "worksmith-portal-client.appspot.com",
            messagingSenderId: "1059921984455"
        });

        this.marketingParams = null;
        this.returningFromSSO = false;
    }

    getMarketingParamsFromHistory = (history) => {
        let params = GetURLSearchParams(history.location.search);
        let marketingParams = null;

        if (ValueIsSet(params.utm_campaign)
            || ValueIsSet(params.utm_content)
            || ValueIsSet(params.utm_medium)
            || ValueIsSet(params.utm_term)
            || ValueIsSet(params.utm_source)) {
            marketingParams = {};

            marketingParams['utm_campaign'] = params.utm_campaign;
            marketingParams['utm_content'] = params.utm_content;
            marketingParams['utm_medium'] = params.utm_medium;
            marketingParams['utm_term'] = params.utm_term;
            marketingParams['utm_source'] = params.utm_source;
        }
        return marketingParams;
    };

    componentDidMount() {
        const globalSiteTagSrc = document.createElement("script");
        globalSiteTagSrc.async = true;
        globalSiteTagSrc.src = "https://www.googletagmanager.com/gtag/js?id=AW-774105168";

        const globalSiteTag = document.createElement("script");
        globalSiteTag.appendChild(document.createTextNode(
            "window.dataLayer = window.dataLayer || []; " +
            "function gtag(){dataLayer.push(arguments);} " +
            "gtag('js', new Date()); " +
            "gtag('config', 'AW-774105168');")
        );

        document.head.appendChild(globalSiteTagSrc);
        document.head.appendChild(globalSiteTag);
    }

    updateAuthStatus = (returningFromSSOFlag) => {
        if(ValueIsSet(returningFromSSOFlag)) {
            this.returningFromSSO = returningFromSSOFlag;
        }
        this.setState({isAuthorized: authTokenManager.isAuthorized()});
    };

    render() {
        let {updateAuthStatus, returningFromSSO} = this;
        let {isAuthorized} = this.state;

        return (
            <Router>
                <Fragment>
                    <GlobalSnackbar />
                    <Switch>
                        <Route path={GlobalRoutes.SERVICE_AGREEMENT} component={ServiceAgreement}/>

                        <Route path={GlobalRoutes.PRESIGNED_CHECK} render={({history}) => {
                            if (!ValueIsSet(this.marketingParams)) {
                                this.marketingParams = this.getMarketingParamsFromHistory(history);
                            }
                            const params = GetURLSearchParams(history.location.search);

                            return <PresignedCheck marketingParams={this.marketingParams} params={params} onLogin={updateAuthStatus}/>;
                        }}/>

                        <Route path={GlobalRoutes.APP} render={({history}) => {
                            if (!ValueIsSet(this.marketingParams)) {
                                this.marketingParams = this.getMarketingParamsFromHistory(history);
                            }

                            if(history.location.pathname === LoggedOutRoutes.OAUTH && !returningFromSSO) {
                                authTokenManager.clearAuthData();
                                return <LoggedOutNavigator marketingParams={this.marketingParams}
                                                           onLogin={updateAuthStatus}/>
                            }
                            else {
                                return isAuthorized ?
                                    <LoggedInNavigator marketingParams={this.marketingParams}
                                                       onLogout={updateAuthStatus}/>
                                    :
                                    <LoggedOutNavigator marketingParams={this.marketingParams}
                                                        onLogin={updateAuthStatus}/>
                            }
                        }}/>
                    </Switch>
                </Fragment>
            </Router>
        )
    }
}

export default App;