import Loader from "worksmith/components/Loader/Loader";
import React from "react";
import PropTypes from "prop-types";
import ContractScopeOfWork from "worksmith/components/Contract/ContractScopeOfWork";
import Grid from 'worksmith/components/Grid/Grid';
import Text, {TextVariant} from "worksmith/components/Text/Text";
import moment from "moment";
import List from "worksmith/components/List/List.web"
import ListItem from "worksmith/components/ListItem/ListItem.web"
import {TextColor} from "worksmith/components/Text/Text.web";
import {JustifyContent} from "../../../CSSEnums";



const LocationContractItemDetailsView = (props) => {
    let {
        loading,
        rateCardEntriesToDisplay,
        locationId,
        serviceLineId
    } = props;

    if (loading) {
        return (<Loader/>)
    } else {
        return (
            <Grid container spacing={3}>
                <Grid container item md={7} xs={12} spacing={0} margin={0}>
                    <List style={{'width':'100%'}}>
                    {rateCardEntriesToDisplay.map(contractItem => {return (
                        <ListItem
                            style={{'paddingLeft':'0px', 'paddingRight':'0px'}}
                            text={
                                <Grid container item xs={12} justify={JustifyContent.SPACE_BETWEEN}>
                                    <Grid item><Text variant={TextVariant.BODY_2}>{contractItem.name}</Text></Grid>
                                    <Grid item><Text variant={TextVariant.BODY_2}>{contractItem.value}</Text></Grid>
                                </Grid>}
                            divider
                        />
                    );}
                    )}
                    <ListItem
                        style={{'paddingLeft':'0px', 'paddingRight':'0px'}}
                        text={
                            <Grid container item xs={12}>
                                <Grid item md={3} xs={8}>
                                    <Text color={TextColor.PRIMARY} variant={TextVariant.SUBTITLE_2}>Scope of Work </Text>
                                </Grid>
                                <Grid item md={2} xs={4}>
                                    <ContractScopeOfWork
                                        serviceLineId={serviceLineId}
                                        locationId={locationId}
                                        buttonText={'VIEW'}
                                    />
                                </Grid>
                            </Grid>
                        }
                        divider
                    />
                    </List>
                </Grid>
            </Grid>
        );
    }
};

LocationContractItemDetailsView.propTypes = {
    loading: PropTypes.bool.isRequired,
    locationId: PropTypes.number.isRequired,
    rateCardEntriesToDisplay: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    })),
    serviceLineId: PropTypes.number.isRequired
};

export default LocationContractItemDetailsView;