import PropTypes from 'prop-types';
import SimpleBusinessObjectPropType from "../SimpleBusinessObjectPropType";
import {NTEType} from "../../../../Enums";

const ClientNTEListObjectPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    client: SimpleBusinessObjectPropType,
    clientLocation: SimpleBusinessObjectPropType,
    serviceLine: SimpleBusinessObjectPropType,
    type: PropTypes.oneOf([
        NTEType.WORKSMITH,
        NTEType.ADMIN,
        NTEType.REGIONAL
    ]),
    NTEValue: PropTypes.number

});

export default ClientNTEListObjectPropType;