import React, {useEffect, useMemo, useState} from 'react';
import {debounce} from "lodash";

import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import Button, {ButtonVariant} from "worksmith/components/Button/Button.web.js";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import IconButton from "worksmith/components/Button/IconButton";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import TextField, {TextFieldType} from "worksmith/components/Inputs/TextField/TextField";
import UsersPageList from "./UsersPageList";
import UserService from 'worksmith/services/api/UserService';
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const userService = new UserService();
const graphQLService = new GraphQLServiceClass();
const authTokenManager = new AuthTokenManager();


const UsersPage = () => {

    const USERS_PER_PAGE = 20;

    const [clientId, setClientId] = useState(null);
    const [users, setUsers] = useState({users: [], loading: true, currentPage: 0, totalDataLength: 0});
    const [filterString, setFilterString] = useState('');


    const fetchUsersSearch = async (page) => {
        setUsers({...users, loading: true});
        let idOfClient = clientId;
        if (clientId === null) {
            await userService.findOne(authTokenManager.getUserId()).then(function (data) {
                let user = data;
                let clientId;

                if (user.clientRoles !== null) {
                    clientId = user.clientRoles[0].client.id;
                } else {
                    clientId = user.clientLocationRoles[0].clientId;
                }
                setClientId(clientId);
                return idOfClient = clientId;
            })
        }


        graphQLService.findPage(
            page,
            USERS_PER_PAGE,
            "firstName",
            GraphQLSortDirection.ASCENDING,
            {
                clientIds: [idOfClient],
                nameEmailLocationSearchString: filterString,
            },
            GraphQLObjectType.USER,
            UsersQueryFields
        ).then((data) => {
            //We don't want to include worksmith users for this
            // call but is still considered a client user so not filtered on the backend
            setUsers({
                users: data.content.filter(user => !user.email.includes('worksmith.com')),
                loading: false,
                currentPage: data.currentPage,
                totalDataLength: data.totalElements,
            })
        }).catch(error => {
            console.log("FETCH USER SEARCH ERROR", error);
            DisplayErrorNotification(error.toString());
        });
    };

    const onFilterChange = (filteringString) => {
        setFilterString(filteringString);
    };

    const debouncedEventHandler = useMemo(
        () => debounce(onFilterChange, 300)
        , []);

    const handleChangePage = (event, pageNumber) => {
        fetchUsersSearch(pageNumber);
    };

    useEffect(() => {
        fetchUsersSearch(0);
        return () => {
            debouncedEventHandler.cancel();
        }
    },[filterString]);


    return <>
        <Grid container margin={'20px 0 0 0'}>
            <Grid item xs={2}>
                <Text variant={TextVariant.H4}>Users</Text>
            </Grid>
            <Grid item xs={3}>
                <Button variant={ButtonVariant.OUTLINED} secondary routerLink={'/#/new-user'}>
                    <Icon name={'Add'}/>
                    Add New User
                </Button>
            </Grid>
            <Grid item xs={12} margin={'20px 0 0 0'}>
                <Grid item sm={12} md={4}>
                    <TextField
                        autoFocus={true}
                        fullWidth
                        includeClearButton
                        onChange={debouncedEventHandler}
                        placeholder={'Search name, email, location...'}
                        startAdornment={
                            <IconButton disablePadding iconName={'Search'} primary size="large" />
                        }
                        type={TextFieldType.SEARCH}
                    />
                </Grid>
            </Grid>
        </Grid>
        <UsersPageList
            currentPage={users.currentPage}
            handleChangePage={handleChangePage}
            loading={users.loading}
            totalDataLength={users.totalDataLength}
            users={users.users}
            usersPerPage={USERS_PER_PAGE}
        />
    </>;

};

export default UsersPage;

const UsersQueryFields = `
        clientLocationRoles {
            clientLocation {
                id
                title
                state
                city
            }
            id
            role
        }
        clientRoles {
            client {
                id
                nickname
            }
            id
            role
            isAccountant
        }
        email
        firstName
        id
        lastName
        username
`;