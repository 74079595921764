import React, {useEffect, useState} from 'react';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Grid from "worksmith/components/Grid/Grid";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import Select from "worksmith/components/Inputs/Select/Select";
import {AutoAcceptPricedWalkthroughsConfig} from "worksmith/enums/AutoAcceptPricedWalkthroughConfig";
import ClientService from "worksmith/services/api/ClientService";
import Table from "worksmith/components/Table/Table";
import EditToggleIcons from "worksmith/components/Contract/EditToggleIcons";
import TextField, {TextFieldSize} from "worksmith/components/Inputs/TextField/TextField";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientService = new ClientService();


const ClientNtePage = (props) => {
    const {
        clientId,
        readOnly
    } = props;

    const [autoAcceptConfig, setAutoAcceptConfig] = useState(null);
    const [loading, setLoading] = useState(false);
    const [nteData, setNteData] = useState([]);
    const [editing, setEditing] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [isEmergencyNTE, setIsEmergencyNTE] = useState(null);
    const [nteValue, setNteValue] = useState(null);

    let pricedWalkthroughOptions = Object.entries(AutoAcceptPricedWalkthroughsConfig).map(([key, label]) => {
        let options = {};
        options['label'] = label;
        options['value'] = key;
        return options;
    });

    const updatePricedWalkthroughAutoAccept = (event) => {
        let config = {client: {}, autoAcceptPricedWalkthroughsConfig : null};
        config.client.id = clientId;
        config.autoAcceptPricedWalkthroughsConfig = event;

        clientService.updateAutoAcceptConfiguration(config).then(data => {
            DisplaySuccessNotification("Updated!");
        }).catch((error) => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification("Failed to Update!");
            console.error(error)
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            let data = await asyncGraphQLServiceClass.findOneById(clientId,
                GraphQLObjectType.CLIENT,
                `
                id
                autoAcceptConfig{
                    autoAcceptPricedWalkthroughsConfig
                }
                selectedServiceLines{
                    id
                    name
                    clientNonEmergencyNTE(clientId: $clientId) {
                        id
                        nteValue
                    }
                    clientEmergencyNTE(clientId: $clientId) {
                        id
                        nteValue
                    }
                }
                `,
                {clientId: clientId},
                '$clientId: Long'
            );

            if(ValueIsSet(data.autoAcceptConfig)){
                setAutoAcceptConfig(data.autoAcceptConfig.autoAcceptPricedWalkthroughsConfig);
            }

            setNteData(data.selectedServiceLines);

        } catch (error) {
            DisplayErrorNotification("Error Fetching Data");
        }
        setLoading(false);
    };

    const saveChanges = (serviceLineId) => {
        setLoading(true);

        if(ValueIsSet(nteValue) && nteValue !== ""){
            let params = {
                identity: {
                    clientId: clientId,
                    isEmergencyNTE: isEmergencyNTE,
                    serviceLineId: serviceLineId,
                    type: "WORKSMITH"
                },
                value: nteValue,
            };

            clientService.setNTEConfig(params).then(data => {
                DisplaySuccessNotification("NTE Updated!");
                fetchData();
            }).catch((error) => {
                // noinspection JSIgnoredPromiseFromCall
                DisplayErrorNotification("Failed to Update NTE!");
                console.error(error)
            });
        }else{
            let params = {
                clientId: clientId,
                isEmergencyNTE: isEmergencyNTE,
                serviceLineId: serviceLineId,
                type: "WORKSMITH"
            };

            clientService.removeNTEConfig(params).then(data => {
                DisplaySuccessNotification("NTE Removed!");
                fetchData();
            }).catch((error) => {
                // noinspection JSIgnoredPromiseFromCall
                DisplayErrorNotification("Failed to Remove NTE!");
                console.error(error)
            });
        }

        setLoading(false);
        setEditing(false);
    };

    const handleChange = (value) => {
        setNteValue(value);
    };

    const toggleEdit = (serviceLineId, isEmergencyNTE) => {
        setEditing(!editing);
        setSelectedId(serviceLineId);
        setIsEmergencyNTE(isEmergencyNTE)
    };

    let columns = [
        {
            title: 'Service Line',
            cellValueFunction: (data) => {
                return <Text>{data.name}</Text>
            },
            cellProps: {
                style: {
                    minWidth: '250px',
                }
            }
        },
        {
            title: 'NTE',
            cellValueFunction: (data) => {
                return <Grid container xs={12}>
                    {editing && selectedId === data.id && !isEmergencyNTE ?
                        <Grid item width={'120px'}>
                            <TextField
                                startAdornment={<Text>$</Text>}
                                size={TextFieldSize.SMALL}
                                initialValue={ValueIsSet(data.clientNonEmergencyNTE) ? data.clientNonEmergencyNTE.nteValue : null}
                                onBlur={(e) => handleChange(e.target.value)}
                            />
                        </Grid>
                        :
                        <Text>{ValueIsSet(data.clientNonEmergencyNTE) ? "$" + data.clientNonEmergencyNTE.nteValue : "--"}</Text>
                    }
                    {!readOnly ?
                        <Grid item>
                            <EditToggleIcons editing={editing && selectedId === data.id && !isEmergencyNTE}
                                             onCheck={() => saveChanges(data.id)}
                                             onToggle={() => toggleEdit(data.id, false)}/>
                        </Grid>
                        : null
                    }
                </Grid>
            },
            cellProps: {
                style: {
                    minWidth: '250px',
                }
            }
        },
        {
            title: 'Emergency NTE',
            cellValueFunction: (data) => {
                return <Grid container xs={12}>
                    {editing && selectedId === data.id && isEmergencyNTE ?
                        <Grid item width={'120px'}>
                            <TextField
                                startAdornment={<Text>$</Text>}
                                size={TextFieldSize.SMALL}
                                initialValue={ValueIsSet(data.clientEmergencyNTE) ? data.clientEmergencyNTE.nteValue : null}
                                onBlur={(e) => handleChange(e.target.value)}
                            />
                        </Grid>
                        :
                        <Text>{ValueIsSet(data.clientEmergencyNTE) ? "$" + data.clientEmergencyNTE.nteValue : "--"}</Text>
                    }
                    {!readOnly ?
                        <Grid item>
                            <EditToggleIcons editing={editing && selectedId === data.id && isEmergencyNTE}
                                             onCheck={() => saveChanges(data.id)}
                                             onToggle={() => toggleEdit(data.id, true)}/>
                        </Grid>
                        : null
                    }
                </Grid>
            },
            cellProps: {
                style: {
                    minWidth: '250px',
                }
            }
        }
    ];

    return (
        <Grid container margin={readOnly ? '-2rem 0 0 0' : '0 0 0 4em'} xs={12}>
            {!readOnly ?
                <Grid container>
                    <Grid item xs={2}>
                        <Text variant={TextVariant.BODY_2}>Priced Walkthrough Auto Accept</Text>
                    </Grid>
                    <Grid item xs={10}>
                        <Select
                            disabled={loading}
                            onChange={(e) => updatePricedWalkthroughAutoAccept(e)}
                            initialValue={autoAcceptConfig}
                            options={pricedWalkthroughOptions}
                        />
                    </Grid>
                </Grid>
                : null
            }
                <Grid item xs={8} margin={'3rem 0 0 0'}>
                    <Table data={nteData}
                           loading={loading}
                           columns={columns}
                           rowsPerPageOptions={[]}
                           disableCursorStyling
                           />
                </Grid>
        </Grid>
    )
};

ClientNtePage.defaultProps = {
    readOnly: false,
};

ClientNtePage.propTypes = {
    clientId: CustomPropTypes.stringOrNumber.isRequired,
};


export default ClientNtePage;