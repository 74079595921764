import React, {Fragment, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from "@mui/material/Box";
import moment from "moment";

import Text, {TextColor, TextVariant} from "../../components/Text/Text.web";
import Grid from "../../components/Grid/Grid.web";
import {AlignItems, FlexDirection, Height, JustifyContent} from "worksmith/enums/CSSEnums";
import RequestTypeBar from "../RequestTypeBar/RequestTypeBar";
import Button, {ButtonSize} from "../../components/Button/Button.web";
import MenuItem from "../../components/Menu/MenuItem.web";
import Divider from "../../components/Divider/Divider.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {RequestTargetString, toCurrencyString} from "worksmith/helpers/LanguageHelper";
import {ThemeConstants} from "../../components/Theme/ThemeProvider";
import NewRequestStatus from "../../enums/api/proposal/NewRequestStatus";
import FileUpload from "worksmith/components/FileUpload/FileUpload";
import {MomentFormat} from "../../enums/MomentFormat";
import RequestSummaryLoadingView from "worksmith/composite-components/RequestSummary/RequestSummaryLoadingView";
import FieldTitleAndBody from "../FieldTitleAndBody/FieldTitleAndBody";
import MenuButton from "../../components/MenuButton/MenuButton.web";
import QuestionForWorksmith from "../ClientVendorConversation/QuestionForWorksmith";
import {ExternalLinks} from "worksmith/enums/ExternalLinks";
import TextField from "worksmith/components/Inputs/TextField/TextField";
import IconButton from "worksmith/components/Button/IconButton";
import {IconType} from "worksmith/enums/MaterialEnums";
import styled from "styled-components";
import RequestForProposalService from "worksmith/services/api/RequestForProposalService";
import CancelRequest from "worksmith/composite-components/CancelRequest/CancelRequest";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";

const StyledPOEditContainer = styled.div`
  display: flex;
`

const requestForProposalService = new RequestForProposalService();

const RequestSummaryView = (props) => {
    const {onRequestCancel, editRequest, loading, partialRequestLoading, request, sendWorksmithComment, user} = props;
    const [showActionModal, setShowActionModal] = useState(false);
    const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
    const [showQuestionForWorksmithModal, setShowQuestionForWorksmithModal] = useState(false);
    const [isEditingPONumber, setIsEditingPONumber] = useState(false);
    const poNumberRef = useRef(null);


    const submitPONumber = async (PONumber) => {
        try {
            await requestForProposalService.updateAll([{id: request.id, poNumber: PONumber}]);
            request.poNumber = PONumber;
            return DisplaySuccessNotification("PO number updated");
        } catch {
            return DisplayErrorNotification("Error updating PO number");
        }
    };

    const updatePONumber = async () => {
        if (poNumberRef.current) {
            await submitPONumber(poNumberRef.current.state.value);
            poNumberRef.current.update(poNumberRef.current.state.value);
        }
        setIsEditingPONumber(false);
    };

    const cancelPONumberEdit = () => {
        if (poNumberRef.current) {
            poNumberRef.current.update(ValueIsSet(request.poNumber) && request.poNumber.trim() !== '' ? request.poNumber : "None");
        }
        setIsEditingPONumber(false);
    }

    let leftMargin = 5;

    let attachmentsList = [];
    let createdByDescription = ''

    if (!loading) {
        createdByDescription = request.creator.id === user.id ?
            `You created this request `
            :
            `Request created by ${request.creator.displayName} `

        createdByDescription += `on ${moment(request.createdTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)} (ET)`

        if (ValueIsSet(request.rfpAttachments) && request.rfpAttachments.length > 0) {
            attachmentsList = request.rfpAttachments.map((attachment) => (
                {
                    url: attachment.singleUseUrl,
                    name: attachment.fileName
                }
            ));
        }
    }


    if (loading) {
        return (<RequestSummaryLoadingView leftMargin={leftMargin}/>);
    } else {
        return (
            <Fragment>
                <Grid container spacing={0} height={90} alignItems={AlignItems.CENTER}>
                    <Grid item height={Height.INHERIT}>
                        <Box height={Height.INHERIT} width={ThemeConstants.spacingUnit * leftMargin}>
                            <RequestTypeBar type={request.type} emergency={request.emergency}/>
                        </Box>
                    </Grid>
                    <Grid item container spacing={2} xs={11} justify={JustifyContent.SPACE_BETWEEN}
                          alignItems={AlignItems.CENTER}>
                        <Grid item>
                            <Text variant={TextVariant.H5}>
                                Request {request.id}
                            </Text>
                        </Grid>
                        {
                            request.newStatus !== NewRequestStatus.COMPLETED
                            && request.newStatus !== NewRequestStatus.CANCELED
                            && !loading ?
                                <Grid item>
                                    <MenuButton
                                        Button={
                                            <Button primary
                                                    size={ButtonSize.MEDIUM}
                                                    disabled={loading || partialRequestLoading}
                                                    endIcon={<ArrowDropDownIcon style={{fontSize: 30}}/>}
                                                    onClick={() => setShowActionModal(true)}>
                                                ACTIONS
                                            </Button>
                                        }
                                        onClose={() => setShowActionModal(false)}
                                        open={showActionModal}>
                                        {
                                            request.newStatus !== NewRequestStatus.SCHEDULED ?
                                                <MenuItem onClick={() => {
                                                    setShowActionModal(false);
                                                    editRequest();
                                                }}>
                                                    Edit Request
                                                </MenuItem>
                                                :
                                                null
                                        }
                                        <MenuItem onClick={() => {
                                            setShowCancelRequestModal(true);
                                            setShowActionModal(false);
                                        }}>
                                            Cancel Request
                                        </MenuItem>
                                        <MenuItem onClick={() => window.open(ExternalLinks.ZENDESK_SUPPORT_TICKET)}>I need Worksmith's help</MenuItem>
                                    </MenuButton>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </Grid>
                <Divider/>
                <Box mt={5} mr={5} ml={leftMargin}>
                    <Grid container
                          spacing={5}
                          ItemProps={{
                              spacing: 2,
                              bodyColor: TextColor.TEXT_SECONDARY,
                              xs: 12
                          }}>
                        <FieldTitleAndBody
                            key={'photos'}
                            item
                            title={"Photos & Attachments"}
                            body={
                                attachmentsList.length > 0 ?
                                    <FileUpload initialFiles={attachmentsList} readOnly/>
                                    :
                                    "None"
                            }/>
                        {
                            request.customAnswers && request.customAnswers.length > 0 && !loading ?
                                <FieldTitleAndBody
                                    key={'requestQuestions'}
                                    item
                                    title={"Details"}
                                    body={
                                        <Grid container direction={FlexDirection.COLUMN} spacing={3}
                                              alignItems={AlignItems.FLEX_START}>
                                            {
                                                request.customAnswers.map(answer => (
                                                    <Grid container
                                                          item
                                                          key={answer.id}
                                                          direction={FlexDirection.COLUMN} spacing={2}
                                                          alignItems={AlignItems.FLEX_START}>
                                                        <Grid item>
                                                            <Text variant={TextVariant.BODY_1}
                                                                  color={TextColor.TEXT_SECONDARY}>
                                                                {answer.question.question}
                                                            </Text>
                                                        </Grid>
                                                        <Grid item>
                                                            <Text variant={TextVariant.BODY_1}
                                                                  color={TextColor.TEXT_SECONDARY}>
                                                                {answer.answer}
                                                            </Text>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    }/>
                                :
                                null
                        }
                        <FieldTitleAndBody
                            key={'description'}
                            item
                            title={request.customAnswers && request.customAnswers.length > 0 ? "Additional Description" : "Description"}
                            body={request.description}/>
                        <FieldTitleAndBody
                            key={'nte'}
                            item
                            title={"Not to Exceed (NTE)"}
                            body={
                                ValueIsSet(request.worksmithNTE) ?
                                    toCurrencyString(request.worksmithNTE, true)
                                    :
                                    "None"
                            }/>
                        <FieldTitleAndBody
                            key={'target'}
                            item
                            title={"Target Date"}
                            body={RequestTargetString(request.target)}/>
                        <FieldTitleAndBody
                            key={'timePreference'}
                            item
                            title={"Time Preference"}
                            body={request.timePreference}/>
                        <FieldTitleAndBody
                            key={'walkthroughPreference'}
                            item
                            title={"Walkthrough Preference"}
                            body={request.walkthroughPreference}/>
                        <FieldTitleAndBody
                            key={'poNumber'}
                            item
                            title={"PO Number"}
                            body={
                                <StyledPOEditContainer>
                                    <TextField
                                        initialValue={ValueIsSet(request.poNumber) && request.poNumber.trim() !== '' ? request.poNumber : "None"}
                                        ref={poNumberRef}
                                        disabled={!isEditingPONumber}
                                    />
                                    <IconButton
                                        iconName={isEditingPONumber ? IconType.CHECK : IconType.EDIT}
                                        secondary
                                        onClick={() => isEditingPONumber ? updatePONumber() : setIsEditingPONumber(true)}
                                        size="medium" />
                                    {isEditingPONumber ? <IconButton
                                        iconName={IconType.HIGHLIGHT_OFF}
                                        secondary
                                        onClick={() => cancelPONumberEdit()}
                                        size="medium" /> : null}
                                </StyledPOEditContainer>
                            }/>
                    </Grid>
                    <Box mt={6}>
                        <Text variant={TextVariant.BODY_2}
                              color={TextColor.TEXT_SECONDARY}
                              italic>
                            {createdByDescription}
                        </Text>
                    </Box>
                </Box>
                <CancelRequest
                    closeCancelRequestModal={() => setShowCancelRequestModal(false)}
                    cancelRequestModalOpen={showCancelRequestModal}
                    openBidCount={request.openBidCount}
                    requestId={request.id}/>
                <QuestionForWorksmith
                    open={showQuestionForWorksmithModal}
                    isClient={true}
                    sendComment={sendWorksmithComment}
                    onCloseModal={() => setShowQuestionForWorksmithModal(false)}/>
            </Fragment>
        );
    }
}

RequestSummaryView.defaultProps = {
    loading: false
};

RequestSummaryView.propTypes = {
    editRequest: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    partialRequestLoading: PropTypes.bool,
    onRequestCancel: PropTypes.func.isRequired,
    request: PropTypes.shape({
        commentCount: PropTypes.number,
        createdTimestamp: PropTypes.string,
        creator: PropTypes.shape({
            displayName: PropTypes.string
        }),
        customAnswers: PropTypes.arrayOf(
            PropTypes.shape({
                answer: PropTypes.string,
                question: PropTypes.shape({
                    id: PropTypes.number,
                    question: PropTypes.string
                })
            })
        ),
        description: PropTypes.string,
        emergency: PropTypes.bool,
        id: PropTypes.number,
        newStatus: PropTypes.string,
        poNumber: PropTypes.string,
        openBidCount: PropTypes.string,
        rfpAttachments: PropTypes.arrayOf(PropTypes.object),
        target:  PropTypes.shape({
            type: PropTypes.string,
            date: PropTypes.string
        }),
        timePreference: PropTypes.string,
        type: PropTypes.string,
        walkthroughPreference: PropTypes.string,
        worksmithNTE: PropTypes.number
    }),
    sendWorksmithComment: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.number
    })
};


export default RequestSummaryView;
