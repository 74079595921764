import React from 'react';
import PropTypes from 'prop-types';
import MaterialMenu from '@mui/material/Menu';
import {withTheme} from '../Theme/ThemeProvider';
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {HorizontalOrigin, VerticalOrigin} from "../Popover/Popover.web";

const Menu = (props) => {

    let {
        id,
        anchorElement,
        anchorOrigin,
        transformOrigin,
        getContentAnchorEl,
        keepMounted,
        onClose,
        open
    } = props;

    return (
        <MaterialMenu id={id}
                      anchorEl={anchorElement}
                      anchorOrigin={anchorOrigin}
                      transformOrigin={transformOrigin}
                      getContentAnchorEl={getContentAnchorEl}
                      keepMounted={keepMounted}
                      open={open}
                      onClose={onClose}
                      disableAutoFocusItem>
            {props.children}
        </MaterialMenu>
    )
};

Menu.propTypes = {
    anchorElement: PropTypes.object,
    anchorOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(HorizontalOrigin)
            ]),
        vertical: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(VerticalOrigin)
            ])
    }),
    getContentAnchorEl: PropTypes.func,
    keepMounted: PropTypes.bool,
    transformOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(HorizontalOrigin)
            ]),
        vertical: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(VerticalOrigin)
            ])
    }),
    onClose: PropTypes.func,
    open: PropTypes.bool
};

export default withTheme(Menu);