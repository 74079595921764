import React, {useState} from 'react';
import PropTypes from "prop-types";

import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import ClientService from "worksmith/services/api/ClientService";
import Divider from "worksmith/components/Divider/Divider";
import Grid from "worksmith/components/Grid/Grid";
import HugoBossAlterationsStandardItemsEdit from "./HugoBossAlterationsStandardItemsEdit";
import Icon from "worksmith/components/Icon/Icon";
import Text from "worksmith/components/Text/Text";
import {IconType} from "worksmith/enums/MaterialEnums";
import {globalSnackbarTrigger, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const clientService = new ClientService();


const HugoBossAlterationsStandardItems = ({
                                              client,
                                              getClientStandardItems,
                                              initialAlterations,
                                              initialCategory,
                                              lineItem,
                                              nextStandardItemNumber,
                                              serviceLine,
                                              setStandardItems,
}) => {

    const [edit, setEdit] = useState(initialCategory === "NewCategory");
    const [alterations, setAlterations] = useState(JSON.parse(JSON.stringify(initialAlterations)));  // this creates deep copy of the initialAlterations array of objects. (JSON.parse(JSON.stringify())
    const [category, setCategory] = useState({name: initialCategory, error: null});
    const [saving, setSaving] = useState(false);


    const handleCancel = (alteration) => {
        if (nextStandardItemNumber === alteration.number) {
            setStandardItems(prevState => {
                let state = {...prevState};
                delete state['NewCategory'];
                return state;
            })
        } else {
            setAlterations(JSON.parse(JSON.stringify(initialAlterations)));
            setCategory({name: initialCategory, error: null});
            setEdit(false);
        }
    };

    const handleSave = (alterations) => {
        setSaving(true);

        let updatedItems = [];

        alterations.filter(item => item.changed).forEach(item => {

            updatedItems.push({
                name: `${ValueIsSet(item.number) ? item.number + " " : ""}${item.category.trim()} - ${item.name.trim()}`,
                id: item.id.toString().includes('newAlteration') ? 0 : item.id,
                client:{
                    id: client.id,
                    name: client.officialName
                },
                serviceLine:{
                    id: serviceLine.id,
                    name: serviceLine.name
                },
                lineItemType:{
                    id: lineItem.id,
                    name: lineItem.name
                }
            });
        });

        if (updatedItems.length > 0 ) {
            clientService.saveStandardItems(updatedItems).then(() => {
                globalSnackbarTrigger("Alterations updated.");
            }).catch(error => {
                console.error("Error saving alteration standard items: " + error);
                DisplayErrorNotification("Error saving alteration standard items");
            }).finally(() => {
                setSaving(false);
                setEdit(false);
                getClientStandardItems();
            });
        } else {
            setSaving(false);
            setEdit(false);
        }
    };

    return (
        (!edit) ?
            <Grid container item xs={12} key={category.name} margin={0} padding={0}>
                <Grid item xs={12}>
                    <Text bold>{category.name}</Text>
                </Grid>
                {alterations && alterations.filter(item => item.name !== "Multiple Alterations").sort((a, b) => a.name.localeCompare(b.name)).map(item => {
                    return (
                        <Grid item xs={3} key={item.id} margin={'0 0 20px 0'}>
                            <Text>{item.name}</Text>
                        </Grid>
                    )
                })}
                <Grid item xs={12} margin={'0 0 10px 0'}>
                    <Button
                        onClick={() => setEdit(true)}
                        variant={ButtonVariant.OUTLINED}
                        startIcon={<Icon name={IconType.EDIT}/>}
                    >
                        Edit
                    </Button>
                </Grid>
                <Grid item xs={12} margin={'0 0 20px 0'}>
                    <Divider/>
                </Grid>
            </Grid>
            :
            <HugoBossAlterationsStandardItemsEdit
                alterations={alterations}
                category={category}
                handleCancel={handleCancel}
                handleSave={handleSave}
                saving={saving}
                setAlterations={setAlterations}
                setCategory={setCategory}
            />
    )
};

export default HugoBossAlterationsStandardItems;

HugoBossAlterationsStandardItems.propTypes = {
    client: PropTypes.object,
    getClientStandardItems: PropTypes.func,
    initialAlterations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
        category: PropTypes.string,
        name: PropTypes.string,
    })),
    initialCategory: PropTypes.string,
    lineItem: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        isAvailableForStandardItems: PropTypes.bool,
        standardItemsForClient: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                client: PropTypes.shape({
                    id: PropTypes.number
                })
            })
        )
    }),
    nextStandardItemNumber: PropTypes.number,
    serviceLine: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        lineItemTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isAvailableForStandardItems: PropTypes.bool,
                standardItemsForClient: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        client: PropTypes.shape({
                            id: PropTypes.number
                        })
                    })
                )
            })
        )
    }),
    setStandardItems: PropTypes.func,
};