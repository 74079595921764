import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import RequestForProposalService from "worksmith/services/api/RequestForProposalService";
import DeclineBidDialogView from "./DeclineBidDialogView";
import BidService from "../../services/api/BidService";

const requestService = new RequestForProposalService();
const bidService = new BidService();

class DeclineBidDialog extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            declineReason: null,
            loading: false,
            valid: false
        };

        this.customReason = '';
    }

    declineBid = () => {
        const { bidId, declineBid, onBidDecline } = this.props;
        const { declineReason, valid } = this.state;
        let { customReason } = this;


        if (valid) {
            if (declineBid) {
                declineBid(declineReason.id, customReason)
            } else {
                bidService.decline(bidId, declineReason.id, customReason).then(() => {
                    onBidDecline();
                    this.setState({loading: false});
                });
            }
        }
    }

    cancelRequest = () => {
        const {cancelRequest, onRequestCancel, requestId} = this.props;
        const { declineReason, valid } = this.state;

        if (valid) {
            let canceledRequest = {
                cancellationReasons: [{
                    id: declineReason.id,
                    name: declineReason.description
                }]
            };

            if (cancelRequest) {
                cancelRequest(canceledRequest);
            } else {
                requestService.cancel(
                    requestId,
                    cancelRequest,
                    false,
                    false,
                    false,
                ).then(() => {
                    onRequestCancel();
                    this.setState({loading: false});
                })
            }
        }
    }

    onFormChange = ({declineReason, customReason, valid}) => {
        this.customReason = customReason;

        this.setState({valid, declineReason});
    }

    setLoading = (loading) => {
        this.setState({loading: loading});
    };

    render() {
        let {cancelRequest, declineBid, onFormChange, setLoading} = this;
        const {declineReason, loading, valid} = this.state;
        const {bidId, isEstimate, open, onCancel} = this.props;

        return (
            <DeclineBidDialogView
                bidId={bidId}
                cancelRequest={cancelRequest}
                declineBid={declineBid}
                declineReason={declineReason}
                isEstimate={isEstimate}
                loading={loading}
                onCancel={onCancel}
                onFormChange={onFormChange}
                open={open}
                setLoading={setLoading}
                valid={valid}/>
        )
    }
}

DeclineBidDialog.propTypes = {
    bidId: PropTypes.number.isRequired,
    cancelRequest: PropTypes.func,
    declineBid: PropTypes.func,
    isEstimate: PropTypes.bool,
    onBidDecline: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onRequestCancel: PropTypes.func,
    requestId: PropTypes.number
};

export default DeclineBidDialog;