import * as PropTypes from "prop-types";
import React from "react";
import {FunctionalColor} from "worksmith/enums/Color";

import Icon from "worksmith/components/Icon/Icon.web";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip.web";
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import {RfpStatus} from "../../Enums";


const RecurringServiceStatusIcon = (props) =>{

    let {statuses} = props;
    let color = FunctionalColor.BLUE;
    let toolTip = "Pending Setup";

    if(statuses.includes(ObligationTemplateStatus.SCHEDULED) ||
        statuses.includes(ObligationTemplateStatus.ACTIVE) ||
        statuses.includes(ObligationTemplateStatus.PENDING_CANCELLATION)){
        color = FunctionalColor.GREEN;
        toolTip = "Ongoing";
    }
    else if(statuses.some(status => RfpStatus.getActiveStatuses().includes(status))){
        color = FunctionalColor.BLUE;
        toolTip = "Pending Setup";
    } else {
        color = FunctionalColor.GRAY;
        toolTip = "No Service";
    }

        return <Tooltip placement={TooltipPlacement.BOTTOM} title={toolTip}>
            <Icon name={'FiberManualRecord'} iconColor={color}/>
        </Tooltip>
};

RecurringServiceStatusIcon.propTypes = {
    statuses: PropTypes.arrayOf(PropTypes.string)
};

export default RecurringServiceStatusIcon;