import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import CalendarListViewTabs from "./CalendarListViewTabs";
import ClientCalendarPage from "./ClientCalendarPage";
import Grid from 'worksmith/components/Grid/Grid';
import Tabs from "worksmith/components/Tab/Tabs";
import Text, {TextVariant} from 'worksmith/components/Text/Text';


const TAB_TITLES = [
    <Text key={0} variant={TextVariant.SUBTITLE_2}>Calendar</Text> ,
    <Text key={1} variant={TextVariant.SUBTITLE_2}>List View</Text>
];

const CalendarPage = ({clientConfigurations, initialClientListViewTab, initialTab, user}) => {


    const TAB_CONTENTS = [
        <Fragment key={0}>
            <ClientCalendarPage
                clientId={clientConfigurations.client.id}
                user={user}
                />
        </Fragment>,
        <Fragment key={1}>
            <CalendarListViewTabs
                initialTab={initialClientListViewTab}
                clientId={clientConfigurations.client.id}
                title={"List View"}
                user={user}
            />
        </Fragment>
    ];

    return (
        <Grid>
            <Tabs initialValue={initialTab} tabFullWidth titleList={TAB_TITLES}>
                {TAB_CONTENTS}
            </Tabs>
        </Grid>
    )
};

export default CalendarPage

CalendarPage.defaultProps = {
    initialTab: 0
};

CalendarPage.propTypes = {
    clientConfigurations: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.shape({
            id: PropTypes.number,
        }),
    })),
    initialClientListViewTab: PropTypes.number,
    initialTab: PropTypes.number,
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
        })),
        clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
            clientLocation: PropTypes.shape({
                title: PropTypes.string,
            }),
        })),
    }),
};
