// This file was generated from com.worksmith.proposal.enums.RfpStatus
// noinspection JSUnusedGlobalSymbols
const RfpStatus = Object.freeze({
    NEW: 'new',
    PENDING_ADMIN: 'pending admin approval',
    PENDING_REGIONAL_MANAGER: 'pending regional manager approval',
    PENDING_ADMIN_BID: 'pending admin bid approval',
    PENDING_REGIONAL_MANAGER_BID: 'pending regional manager bid approval',
    SOURCING: 'sourcing',
    WALKTHROUGH_SCHEDULED: 'walkthrough scheduled',
    NEEDS_CLIENT_INPUT: 'needs client input',
    SCHEDULING: 'scheduling',
    SCHEDULED: 'scheduled',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
    getAwaitingApprovalStatuses: function() {
        return [this.PENDING_ADMIN_BID, this.PENDING_REGIONAL_MANAGER_BID, this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER];
    },
    getClosedStatuses: function() {
        return [this.COMPLETED, this.CANCELED];
    },
    getContactingVendorStatuses: function() {
        return [this.NEW, this.SOURCING, this.NEEDS_CLIENT_INPUT, this.WALKTHROUGH_SCHEDULED];
    },
    getCorporateReviewStatus: function() {
        return [this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER];
    },
    getMarketStatuses: function() {
        return [this.NEW, this.SOURCING, this.NEEDS_CLIENT_INPUT, this.WALKTHROUGH_SCHEDULED, this.PENDING_ADMIN_BID, this.PENDING_REGIONAL_MANAGER_BID];
    },
    getOpenStatuses: function() {
        return [this.NEW, this.SOURCING, this.NEEDS_CLIENT_INPUT, this.WALKTHROUGH_SCHEDULED, this.PENDING_ADMIN_BID, this.PENDING_REGIONAL_MANAGER_BID, this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER, this.SCHEDULING, this.SCHEDULED];
    },
    getPulseStatuses: function() {
        return [this.NEW, this.SOURCING, this.NEEDS_CLIENT_INPUT, this.WALKTHROUGH_SCHEDULED, this.PENDING_ADMIN_BID, this.PENDING_REGIONAL_MANAGER_BID, this.SCHEDULING];
    },
    getRequiresAdminActionStatuses: function() {
        return [this.PENDING_ADMIN_BID, this.PENDING_REGIONAL_MANAGER_BID, this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER, this.NEEDS_CLIENT_INPUT];
    },
    getAllStatuses: function() {
        return [this.NEW, this.SOURCING, this.NEEDS_CLIENT_INPUT, this.WALKTHROUGH_SCHEDULED, this.PENDING_ADMIN_BID, this.PENDING_REGIONAL_MANAGER_BID, this.PENDING_ADMIN, this.PENDING_REGIONAL_MANAGER, this.SCHEDULING, this.SCHEDULED, this.COMPLETED, this.CANCELED];
    },
});

export default RfpStatus;
