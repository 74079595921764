import {LocalStorageKeys} from "./HttpEnums";

export class AuthTokenManager {

    storeAuthData = (authTokenData,authType) => {
        localStorage.setItem(LocalStorageKeys.USER_ID, authTokenData.userId);
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, authTokenData.access);
        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, authTokenData.refresh);
        localStorage.setItem("authType",authType);
        localStorage.setItem(LocalStorageKeys.PORTAL_TYPE, 'workbench');
        localStorage.setItem(LocalStorageKeys.STREAM_CHAT_TOKEN, authTokenData.streamChatToken);
    };

    getStorageValue = (key) => {
        return localStorage.getItem(key);
    };

    getAuthToken = () => {
        return localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
    };

    getRefreshToken = () => {
        return localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
    };

    getAuthType = () => {
        return localStorage.getItem(LocalStorageKeys.AUTH_TYPE);
    };

    getStreamChatToken = () => {
        return localStorage.getItem(LocalStorageKeys.STREAM_CHAT_TOKEN);
    };

    getUserId = () => {
        return localStorage.getItem(LocalStorageKeys.USER_ID);
    };

    isAuthorized = () => {
        return !!this.getAuthToken();
    };

    clearAuthData = () => {
        localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
        localStorage.removeItem(LocalStorageKeys.USER_ID);
        localStorage.removeItem(LocalStorageKeys.AUTH_TYPE);
        localStorage.removeItem(LocalStorageKeys.STREAM_CHAT_TOKEN);
    };
}

export default AuthTokenManager;
