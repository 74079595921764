import React, {Component} from 'react';
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import NavigationButton from "../../components/NavigationButton";
import Loader from "../../components/Loader";
import SearchInput from 'react-search-input'
import Button from "../../components/Button";
import styled from 'styled-components';
import t from 'tcomb-form';

const Form = t.form.Form;

const userService = new UserService();
const authTokenManager = new AuthTokenManager();


const LocationToolbar = styled.div`
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    grid-template-rows: 100%;
    grid-template-areas: 
        "locationSearchFilter locationSort";
`;

const LocationSort = styled.div`
    grid-area: locationSort;
    padding: 20px 5px 0;
`;

const LocationSearchFilter = styled.div`
    grid-area: locationSearchFilter;
    padding: 20px 0 0;
    
    & div {
        padding: 0 13px 0 0;
    }
    
    & input {
        height: 38px;
        padding: 0 0 0 35px
    }
    
    & input::placeholder {
        opacity: 0
    }
    
    & :before {
        top: 4px !important;
        left: 10px !important;
    }
`;

class LocationsPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            locations: [],
            filterString: '',
            sort: {
                status: "title"
            },
            loading: true
        };
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidMount(){
        let _this = this;

        userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId()).then(function(locations){
            _this.setState({ locations, loading: false });
        });

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.loading!==this.state.loading){
            this.onSortChange(this.state.sort);
        }
    }

    onFilterChange(filteringString) {
        this.setState({filterString: filteringString});
    }

    onSortChange(sortValue) {
        let {locations} = this.state;
        let sortedList = locations;

        switch(sortValue.status) {
            case("title"):
                sortedList.sort((a, b) => {
                    return a.title.localeCompare(b.title, undefined, {sensitivity: 'base'});
                });
                break;
            case("locationCode"):
                sortedList.sort((a, b) => {
                    return a.locationCode.localeCompare(b.locationCode, undefined, {sensitivity: 'base'});
                });
                break;
            case("city"):
                sortedList.sort((a, b) => {
                    return a.city.localeCompare(b.city, undefined, {sensitivity: 'base'});
                });
                break;
            default:
                console.log("Unexpected status!!!")
        }

        this.setState({locations: sortedList, sort: sortValue});
    }
    
    render(){
        let { locations, filterString, loading, sort} = this.state;
        let { user } = this.props;
        let {isSelfService} = this.props.client;
        let isAdmin = false;

        if(user) {
            isAdmin = user.clientRoles !== null;   // If there is a client role they're an admin
        }


        let LocationSortStruct = t.struct({
            status: t.enums({
                "title": "Location Title",
                "locationCode":"Location Code",
                "city":"City"
            })
        });


        let options = {
            auto: 'none',
            fields: {
                status: {
                    nullOption: false,
                    disabled: loading
                }
            }
        };

        if(!loading){
            return(
                <div>
                    <div className="ws-section">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="page-header">{isSelfService ? 'Locations & Prices' : 'Locations'}</h1>
                            </div>
                            {isAdmin ? <div className="col-md-6 text-right">
                                <NavigationButton route={'/new-location'} icon={'map'} message={'  Add new location'}/>
                            </div> : null}
                        </div>
                    </div>
                    <LocationToolbar>
                        <LocationSearchFilter>
                            Search:
                            <SearchInput className="search-input" onChange={this.onFilterChange}/>
                        </LocationSearchFilter>
                        <LocationSort>
                            Sort by:
                            <Form type={LocationSortStruct} options={options} value={sort} onChange={(sortValue) => this.onSortChange(sortValue)} />
                        </LocationSort>
                    </LocationToolbar>
                    <div className="ws-section">
                        <table className="table ws-table">
                            <thead>
                            <tr>
                                <th>Location Title</th>
                                <th>Location Code</th>
                                <th>Phone Number</th>
                                <th>City</th>
                                {isSelfService ? <th>Country</th> : null}
                                <th>Regional Users</th>
                                <th>Local Users</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                            {locations.filter(location => {
                                if ((location.title && location.title != null && location.title.toLowerCase().includes(filterString.toLowerCase())) ||
                                    (location.title && location.locationCode != null && location.locationCode.toLowerCase().includes(filterString.toLowerCase()))||
                                    (location.title && location.city != null && location.city.toLowerCase().includes(filterString.toLowerCase()))
                                ) {
                                    return true;
                                }
                                return false
                            }).map(function(location, idx){
                                return (

                                    <tr key={idx}>
                                        <td>{ location.title }</td>
                                        <td>{ location.locationCode }</td>
                                        <td>{ location.phone }</td>
                                        <td>{ location.city }</td>
                                        {isSelfService ? <td>{ location.country != null ? location.country : "--" }</td> : null}
                                        <td>{ location.regionalUserCount}</td>
                                        <td>{ location.localUserCount}</td>
                                        <td><Button type={'link'} message={'View'} linkTo={`/locations/${location.id}`}/></td>
                                    </tr>
                                )
                            })}
                            </tbody>

                        </table>
                    </div>
                </div> );
        } else {
            return <Loader/>
        }
    }
}

export default LocationsPage;