const ServiceLineType = Object.freeze({
    DRY_CLEANING_AND_ALTERATIONS: 1,
    COMMERCIAL_CLEANING: 2,
    HANDYMAN: 3,
    HVAC: 4,
    PLUMBING: 5,
    PAINTING: 6,
    ELECTRICAL_WORK: 7,
    LOCKSMITH: 8,
    PEST_CONTROL: 9,
    LANDSCAPING: 10,
    OTHER: 11,
    UPHOLSTERY: 12,
    TRASH_REMOVAL: 13,
    CARPET_CLEANING: 14,
    SNOW_REMOVAL: 15,
    FIRE_AND_SAFETY_INSPECTION: 16,
    MOLD_INSPECTION: 17,
    WINDOW_WASHING: 18,
    APPLIANCE_REPAIR: 19,
    SEWING_MACHINE_REPAIR: 20,
    FLOORING_REPAIR: 21,
    EVENT_RENTALS: 22,
    BEVERAGE_SERVICES: 23,
    DOOR_REPAIR: 24,
    HURRICANE_PREPARATION: 25,
    ZIPPER_REPAIR: 26,
    WATCH_REPAIR: 27,
    TRENCHCOAT_REPROOFING_AND_LINING: 28,
    REFRIGERATION_SERVICES: 29,
    LIGHTING: 30,
    LEATHER_REPAIR: 31,
    WATCH_PARTS: 32,
    FLOWER_AND_PLANT_DELIVERY: 33,
    CATERING_SERVICES: 34,
    MOVING: 35,
    SECURITY: 36,
    COURIER_SERVICES: 37,
    EXTRA_LABOR: 38,
    SIGNAGE: 39,
    POWER_WASHING: 40,
    GLASS_AND_MIRROR_REPAIR: 41,
    IT_SETUP: 42,
    GREASE_TRAP_SERVICE: 43,
    SITE_SURVEY: 44
});

export default ServiceLineType;