import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

import AddEditVendorFormView from "./AddEditVendorFormView";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import ClientLocationService from "worksmith/services/api/ClientLocationService";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import Grid from "worksmith/components/Grid/Grid";
import SelectAddVendorOrInHouseTailor from "./SelectAddVendorOrInHouseTailor";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import VendorLocationTailorService from "worksmith/services/api/VendorLocationTailorService";
import VendorService from "worksmith/services/api/VendorService";
import {Country} from "worksmith/enums/api/country/Country";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {globalSnackbarTrigger, StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {vendorOrInHouseTailorType} from "./AddInHouseTailorRelationship";

const vendorService = new VendorService();
const vendorLocationTailorService = new VendorLocationTailorService();
const clientLocationService = new ClientLocationService();
const graphQLService = new GraphQLServiceClass();


const AddEditVendorForm = ({
                               client,
                               editVendor,
                               fetchVendors,
                               setShowAddEditVendor,
}) => {

    const [vendorInfo, setVendorInfo] = useState({
        companyName: '',
        country: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        stateProvince: '',
        zip: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        countryCode: '',
        preferredVendorRelationships: [],
        inHouseTailorRelationships: [],
        serviceLines: [],
        errors: {},
    });
    const [initialPreferredVendorRelationships, setInitialPreferredVendorRelationships] = useState([]);
    const [initialInHouseTailorRelationships, setInitialInHouseTailorRelationships] = useState([]);
    const [submittingVendor, setSubmittingVendor] = useState(false);
    const [showDeletePreferredVendorOrTailorModal, setShowDeletePreferredVendorOrTailorModal] = useState(false);

    const hasEditVendorId = ValueIsSet(editVendor.id);
    const [addVendorOrInHouseTailor, setAddVendorOrInHouseTailor] = useState((hasEditVendorId && editVendor.isInHouseTailor) ? vendorOrInHouseTailorType.IN_HOUSE_TAILOR  : "");


    const fetchVendorById = async (id) => {
        try {
            const vendorResponse = await graphQLService.findOneById(
                id,
                GraphQLObjectType.VENDOR,
                vendorFields
            );
            setVendorInfo({
                companyName: vendorResponse.nickname,
                country: Country[vendorResponse.vendorLocations[0].country],
                addressLineOne: vendorResponse.vendorLocations[0].addressLineOne,
                addressLineTwo: vendorResponse.vendorLocations[0].addressLineTwo,
                city: vendorResponse.vendorLocations[0].city,
                stateProvince: vendorResponse.vendorLocations[0].state,
                zip: vendorResponse.vendorLocations[0].zip,
                firstName: vendorResponse.users[0].firstName,
                lastName: vendorResponse.users[0].lastName,
                email: vendorResponse.users[0].email,
                phone: vendorResponse.users[0].phone,
                countryCode: vendorResponse.users[0].countryCode,
                userId: vendorResponse.users[0].id,
                vendorLocationId: vendorResponse.vendorLocations[0].id,
                preferredVendorRelationships: vendorResponse.preferredVendorRelationships,
                inHouseTailorRelationships: vendorResponse.vendorLocations[0].locationTailors,
                serviceLines: vendorResponse.selectedServiceLines,
                errors: {},
            });
            setInitialPreferredVendorRelationships(ValueIsSet(vendorResponse.preferredVendorRelationships) ? vendorResponse.preferredVendorRelationships : []);
            setInitialInHouseTailorRelationships(ValueIsSet(vendorResponse.vendorLocations[0]) ? vendorResponse.vendorLocations[0].locationTailors : []);
        } catch (error) {
            console.error("Error fetching vendor data: ", error);
            await DisplayErrorNotification("Error fetching vendor data")
        }
    };


    const handleChange = (name, value) => {
        setVendorInfo((prevState) => ({...prevState, [name]: value}));
    };

    const validateInputsAndSubmit = async () => {
        let errors = {};
        let valid = true;
        const nonRequiredFieldsForAddVendor = ['addressLineTwo', 'phone', 'countryCode', 'preferredVendorRelationships', 'serviceLines', 'errors', 'userId', 'vendorLocationId', 'inHouseTailorRelationships'];
        const nonRequiredFieldsForInHouseTailors = ['addressLineTwo', 'firstName', 'lastName', 'phone', 'countryCode', 'preferredVendorRelationships', 'serviceLines', 'errors', 'userId', 'vendorLocationId', 'inHouseTailorRelationships'];
        for (const [key, value] of Object.entries(vendorInfo)) {
            if ((addVendorOrInHouseTailor === vendorOrInHouseTailorType.ADD_VENDOR ? !nonRequiredFieldsForAddVendor.includes(key) : !nonRequiredFieldsForInHouseTailors.includes(key)) && !StringHasText(value)) {
                errors[key] = "*Input required";
                valid = false;
            }
        }

        // check if email or phone number is already in use in the system if creating a new vendor.
        if (!hasEditVendorId) {
            let techCheck =  {userUniqueParams: {
                    email: vendorInfo.email !== "" ? vendorInfo.email : null,
                    phone: vendorInfo.phone !== "" ? vendorInfo.phone : null,
                }};

            try {
                const { content } = await graphQLService.findPage(
                    0,
                    5,
                    null,
                    null,
                    techCheck,
                    GraphQLObjectType.USER,
                    'id, email, phone',
                );
                if(content.length > 0){
                    errors = {...errors, email: "*Email or phone number already in use.", phone: "*Email or phone number already in use."};
                    valid = false;
                }
            } catch (err) {
                console.error("Error validating if email or phone number already in use: ", err);
                await DisplayErrorNotification("Error validating if email or phone number already in use");
            }
        }

        if (valid) {
            const deletePreferredVendorList = initialPreferredVendorRelationships.length > 0 ? initialPreferredVendorRelationships.filter(initItem => !vendorInfo.preferredVendorRelationships.some(vendorItem => vendorItem.id === initItem.id)) : [];
            const deleteInHouseTailorList = initialInHouseTailorRelationships.length > 0 ? initialInHouseTailorRelationships.filter(initItem => !vendorInfo.inHouseTailorRelationships.some(tailor => tailor.id === initItem.id)) : [];
            if (deletePreferredVendorList.length > 0 || deleteInHouseTailorList.length > 0) {
                setShowDeletePreferredVendorOrTailorModal(true);
            } else {
                handleSubmit();
            }
        } else {
            setVendorInfo((prevState) => ({...prevState, errors: errors}));
        }
    };

    const handleSubmit = () => {
        setSubmittingVendor(true);

        const hasPhoneNumber = StringHasText(vendorInfo.phone);
        const isInHouseTailor = addVendorOrInHouseTailor === vendorOrInHouseTailorType.IN_HOUSE_TAILOR;

        const configureVendorInfo = {
            clientId: client.id,
            nickname: vendorInfo.companyName,
            officialName: vendorInfo.companyName,
            users: [{
                id: vendorInfo.userId,
                firstName: isInHouseTailor ? "In-House": vendorInfo.firstName,
                lastName: isInHouseTailor ? "Tailor" : vendorInfo.lastName,
                email: vendorInfo.email,
                username: vendorInfo.email,
                phone: hasPhoneNumber ? vendorInfo.phone : null,
                countryCode: hasPhoneNumber ? vendorInfo.countryCode : null,
            }],
            vendorLocations: [{
                    id: vendorInfo.vendorLocationId,
                    serviceLines: vendorInfo.serviceLines,
                    rating: 0,
                    isBurberryVendor: isInHouseTailor,
                    addressLineOne: vendorInfo.addressLineOne,
                    addressLineTwo: vendorInfo.addressLineTwo,
                    city: vendorInfo.city,
                    zip: vendorInfo.zip,
                    country: vendorInfo.country,
                    state: vendorInfo.stateProvince,
                    phone: hasPhoneNumber ? vendorInfo.phone : null,
                    countryCode: hasPhoneNumber ? vendorInfo.countryCode : null,
                    title: vendorInfo.city + ", " + vendorInfo.stateProvince
            }],
        };

        if (hasEditVendorId) {
            async function updateVendorAndAddPreferredVendors() {
                try {
                    const vendorUpdateResponse = await vendorService.update({'id': editVendor.id, ...configureVendorInfo});

                    const deletePreferredVendorList = initialPreferredVendorRelationships.filter(initItem => !vendorInfo.preferredVendorRelationships.some(vendorItem => vendorItem.id === initItem.id));
                    const addPreferredVendorList = vendorInfo.preferredVendorRelationships.filter(initItem => !initialPreferredVendorRelationships.some(vendorItem => vendorItem.id === initItem.id));

                    if (deletePreferredVendorList.length > 0) {
                        for (const relationship of deletePreferredVendorList) {
                            await removePreferredVendor(relationship.clientLocation.id, relationship.id);
                        }
                    }

                    if (addPreferredVendorList.length > 0) {
                        for (const relationship of addPreferredVendorList) {
                            await addPreferredVendor(relationship.clientLocation.id, vendorInfo.vendorLocationId, relationship.serviceLine.id);
                        }
                    }

                    const deleteInHouseTailorRelationshipList = initialInHouseTailorRelationships.filter(initItem => !vendorInfo.inHouseTailorRelationships.some(tailor => tailor.id === initItem.id));
                    const addInHouseTailorRelationshipList = vendorInfo.inHouseTailorRelationships.filter(initItem => !initialInHouseTailorRelationships.some(vendorItem => vendorItem.id === initItem.id));

                    if (deleteInHouseTailorRelationshipList.length > 0) {
                        for (const tailor of deleteInHouseTailorRelationshipList) {
                            await vendorLocationTailorService.delete(tailor.id);
                        }
                    }

                    if (addInHouseTailorRelationshipList.length > 0) {
                        for (const tailor of addInHouseTailorRelationshipList) {
                            await vendorLocationTailorService.insert({vendorLocationId: vendorUpdateResponse.vendorLocations[0].id, name: tailor.name.trim()});
                        }
                    }

                    setShowAddEditVendor(false);
                    fetchVendors(1);

                } catch (error) {
                    console.error("Error updating vendor: ", error);
                    await DisplayErrorNotification("Error updating vendor");
                } finally {
                    setSubmittingVendor(false);
                    globalSnackbarTrigger('Vendor updated.')
                }
            }
            updateVendorAndAddPreferredVendors();
        } else {
            async function insertVendorAndAddPreferredVendors() {
                try {
                    const vendorInsertResponse = await vendorService.insert(configureVendorInfo);

                    if (vendorInfo.preferredVendorRelationships.length > 0) {
                        for (const relationship of vendorInfo.preferredVendorRelationships) {
                            await addPreferredVendor(relationship.clientLocation.id, vendorInsertResponse.vendorLocations[0].id, relationship.serviceLine.id);
                        }
                    }

                    if (vendorInfo.inHouseTailorRelationships.length > 0) {
                        for (const tailor of vendorInfo.inHouseTailorRelationships) {
                            await vendorLocationTailorService.insert({vendorLocationId: vendorInsertResponse.vendorLocations[0].id, name: tailor.name.trim()});
                        }
                    }

                    setShowAddEditVendor(false);
                    fetchVendors(1);
                    globalSnackbarTrigger('Vendor added.')

                } catch (error) {
                    console.error("Error inserting vendor: ", error);
                    await DisplayErrorNotification("Error inserting vendor");
                } finally {
                    setSubmittingVendor(false);
                }
            }
            insertVendorAndAddPreferredVendors();
        }
    };

    const addPreferredVendor = async (clientLocationId, vendorId, serviceLineId) => {
          clientLocationService.addPreferredVendor(clientLocationId, vendorId, serviceLineId).then(res => {
              return res;
          }).catch(error => {
              console.error('Error adding preferred vendor: ', error);
              DisplayErrorNotification("Error adding preferred vendor");
              return error;
          })
    };

    const removePreferredVendor = async (clientLocationId, preferredVendorId) => {
          clientLocationService.removePreferredVendor(clientLocationId, preferredVendorId).then(res => {
              return res;
          }).catch(error => {
              console.error('Error removing preferred vendor: ', error);
              DisplayErrorNotification("Error removing preferred vendor");
              return error;
          })
    };

    const createTitle = () => {
        if (addVendorOrInHouseTailor === vendorOrInHouseTailorType.IN_HOUSE_TAILOR) {
            return hasEditVendorId ? "Edit In-House Tailor Account" : "Create an In-House Tailor Account"
        } else {
            return hasEditVendorId ? "Edit Vendor" : "Add Vendor"
        }
    };

    useEffect(() => {
        hasEditVendorId && fetchVendorById(editVendor.id);
    }, [editVendor.id]);

    return (
        <>
            {(!hasEditVendorId && addVendorOrInHouseTailor === "") ?
                <SelectAddVendorOrInHouseTailor
                    setShowAddEditVendor={setShowAddEditVendor}
                    setAddVendorOrInHouseTailor={setAddVendorOrInHouseTailor}
                />
                :
                <Grid container width={700}>
                    <Grid item xs={12} padding={'24px 0 0 8px'}>
                        <Text variant={TextVariant.H5}>{createTitle()}</Text>
                    </Grid>
                    <AddEditVendorFormView
                        isInHouseTailor={editVendor.isInHouseTailor || addVendorOrInHouseTailor === vendorOrInHouseTailorType.IN_HOUSE_TAILOR}
                        client={client}
                        editVendorId={editVendor.id}
                        handleChange={handleChange}
                        handleSubmit={validateInputsAndSubmit}
                        setShowAddEditVendor={setShowAddEditVendor}
                        setVendorInfo={setVendorInfo}
                        submittingVendor={submittingVendor}
                        vendorInfo={vendorInfo}
                    />
                </Grid>
            }
            {showDeletePreferredVendorOrTailorModal &&
                <AlertDialog
                    title={"Open tickets will not be affected"}
                    acceptText={"Ok"}
                    cancelText={"Cancel"}
                    open={showDeletePreferredVendorOrTailorModal}
                    onAccept={handleSubmit}
                    onCancel={() => {
                        setShowDeletePreferredVendorOrTailorModal(false);
                        setShowAddEditVendor(false);
                    }}
                    disableBackdropClick
                >
                    <Grid width={500}>
                        <Text>
                            Any tailors or locations you removed cannot be assigned to new tickets going forward, but existing tickets will not be affected.                        </Text>
                    </Grid>
                </AlertDialog>
            }
        </>
    )
};

export default AddEditVendorForm;

const vendorFields = `
    id
    nickname
    users {
        id
        firstName
        lastName
        email
        phone
        countryCode
    }
    preferredVendorRelationships {
        id
        clientLocation {
            id
            title
        }
        serviceLine {
            id
            name
        }
    }
    selectedServiceLines {
        id
        name
    }
    vendorLocations {
        id 
        addressLineOne
        addressLineTwo
        city
        zip
        country
        state
        locationTailors {
            id
            name
        }
    }
`;

AddEditVendorForm.propTypes = {
    client: PropTypes.object,
    editVendor: PropTypes.shape({
        id: PropTypes.number,
        isInHouseTailor: PropTypes.bool
    }),
    fetchVendors: PropTypes.func,
    setShowAddEditVendor: PropTypes.func,
};

