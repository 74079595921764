import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "../../Grid/Grid.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Text, {TextVariant} from "../../Text/Text.web";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import Card from "../../Card/Card.web";
import styled from "styled-components";
import Button, {ButtonVariant} from "../../Button/Button.web";
import {JustifyContent, Display} from "worksmith/enums/CSSEnums";
import Box from "@mui/material/Box";
import {ThemeConstants} from "../../Theme/ThemeProvider";

const StyledCard = styled(Card)`
    min-width: 280px;
    max-width: 1000px;
    
    min-height: 97px;
    max-height: calc(60% + 120px);
`;

const FlexDiv = styled.div`
    display: ${Display.FLEX}
    flex-direction: column;
    padding: 16px 16px 0 16px;
`;

// If you're putting a grid inside of the body div, and having scrolling issues
// Try adding padding to the top and bottom of the grid
const BodyDiv = styled.div`
    margin-bottom: ${props => props.spacing * ThemeConstants.spacingUnit}px;
    max-height: 80vh;
    
    ${props => props.scrollable ?
    `
    overflow-y: scroll;
    overflow-x: hidden;
    `
    :
    `` 
    }
    
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
`;

const ButtonsDiv = styled.div`
    margin-top: 8px;
`;

const ActionableModalTemplate = (props) => {
    let {
        approveDisabled,
        approvalText,
        body,
        buttons,
        cancelText,
        debounceApprove,
        onCancel,
        onApprove,
        onApproveIgnoreDebounce,
        scrollable,
        spacing,
        title,
        subtitle,
        width
    } = props;

    return (
        <StyledCard width={width}>
            <FlexDiv>
                {ValueIsSet(title) ?
                    <Box mb={spacing * 2}>
                        {typeof title === JavascriptType.STRING ?
                            <Text variant={TextVariant.H6}>{title}</Text>
                            :
                            title
                        }
                        {ValueIsSet(subtitle) ?
                            typeof subtitle === JavascriptType.STRING ?
                                    <Text variant={TextVariant.BODY_1}>{subtitle}</Text>
                                    :
                                    subtitle

                            :
                            null
                        }
                    </Box>
                    :
                    null
                }
                <BodyDiv scrollable={scrollable} spacing={spacing * 2}>
                    {typeof body === JavascriptType.STRING ?
                        <Text>{body}</Text>
                        :
                        body
                    }
                </BodyDiv>
                <ButtonsDiv>
                    <Grid container justify={JustifyContent.FLEX_END}>
                        {buttons ?
                            buttons.map((button, index) => {
                                return <Grid key={index} item>
                                    {button}
                                </Grid>
                            })
                            :
                            <Fragment>
                                <Grid item>
                                    <Button onClick={onCancel}
                                            variant={ButtonVariant.OUTLINED}>{cancelText}</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        debounce={debounceApprove}
                                        onClickIgnoreDebounce={onApproveIgnoreDebounce}
                                        disabled={approveDisabled}
                                        onClick={onApprove}
                                        primary>{approvalText}</Button>
                                </Grid>
                            </Fragment>
                        }
                    </Grid>
                </ButtonsDiv>
            </FlexDiv>
        </StyledCard>
    );
};

ActionableModalTemplate.defaultProps = {
    approvalText: 'Ok',
    cancelText: 'Cancel',
    debounceApprove: true,
    scrollable: false,
    spacing: 1,
    width: '100%'
};

ActionableModalTemplate.propTypes = {
    approveDisabled: PropTypes.bool,
    approvalText: PropTypes.string,
    body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    buttons: PropTypes.arrayOf(PropTypes.element),          //use this if you want to add completely custom buttons to the bottom of the modal
    cancelText: PropTypes.string,
    debounceApprove: PropTypes.bool,
    onCancel: PropTypes.func,
    onApprove: PropTypes.func,
    onApproveIgnoreDebounce: PropTypes.func,
    scrollable: PropTypes.bool,
    spacing: PropTypes.number,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    subtitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    width: PropTypes.string
};

export default ActionableModalTemplate;
