import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

import {config} from "../../env";
import {ValueIsSet} from "../../Helpers";
import CustomPropTypes from "../../utilities/propTypes/CustomPropTypes";
import {LoggedOutRoutes, OAuthProvider} from "../../Enums";
import Loader from "../Loader";

import OAuthServiceClass from "../http/OAuthServiceClass";
const oAuthService = new OAuthServiceClass();

const googleClientId = '190011252362-9p3c35g26rlbgqtld2ese6dulv2f5bdj.apps.googleusercontent.com';
const googleRedirectUri = config.GOOGLE_REDIRECT_URL;
const googleScope = 'profile email';


const auth0ClientId = config.AUTH0_CLIENT_ID;
const auth0Domain = config.AUTH0_DOMAIN;
const SAMLRedirectURL = config.SAML_REDIRECT_URL;
const auth0Scope = 'openid profile email';


const burberryClientId = 'dev.app.worksmith';
const burberryRedirectUri = config.BURBERRY_REDIRECT_URL;
const burberryScope = 'openid';

const squareClientId = 'sq0idp-pA5B2Fy_1YmEnxDBVbpr5A';
// Square automatically redirects to the URL specified in our Square account settings
const squareScope = 'MERCHANT_PROFILE_READ EMPLOYEES_READ';

const OAuth = (props) => {

    let {onLogin, history} = props;

    let authType = props.authType;
    let auth0Connection = props.connection;
    let code = props.params.code;


    const options = {
        google: {
            loginURL: `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${googleRedirectUri}&scope=${googleScope}`
        },
        burberry: {
            loginURL: `https://sso.dev.burberry.com/as/authorization.oauth2?response_type=code&client_id=${burberryClientId}&redirect_uri=${burberryRedirectUri}&scope=${burberryScope}`
        },
        square: {
            loginURL: `https://connect.squareup.com/oauth2/authorize?client_id=${squareClientId}&scope=${squareScope}&session=false`
        },
        auth0: {
            loginURL: auth0Connection === "undefined" || !ValueIsSet(auth0Connection) ?
                `https://${auth0Domain}/authorize?response_type=code&client_id=${auth0ClientId}&redirect_uri=${SAMLRedirectURL}&scope=${auth0Scope}` :
                `https://${auth0Domain}/authorize?response_type=code&client_id=${auth0ClientId}&connection=${auth0Connection}&redirect_uri=${SAMLRedirectURL}&scope=${auth0Scope}`
        }
    };

    let settings = options[authType];

    if (code === "undefined" || !ValueIsSet(code)) {
        window.location = settings.loginURL;
    } else {
        oAuthService.authorizeByAuthCode(code, authType).then(() => {
            onLogin(true);
            history.push(LoggedOutRoutes.LOGIN);
        });
    }
    return <Loader/>
}

OAuth.propTypes = {
    authType: CustomPropTypes.enum(OAuthProvider).isRequired,
    onLogin: PropTypes.func.isRequired
};

export default withRouter(OAuth);