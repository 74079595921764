import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {END_DATE, START_DATE} from "react-dates/constants";
import Box from "@mui/material/Box";

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {FontFamily, withTheme} from "../Theme/ThemeProvider";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import Grid from "../Grid/Grid.web";
import {Display, JustifyContent} from "../../enums/CSSEnums";
import PopoverFieldButtons from "../Popover/PopoverFieldButtons";
import DateRangeCalendarBreakpointWrapper from "./DateRangeCalendarBreakpointWrapper";
import CalendarMonth from "../Dates/CalendarMonth";
import MonthNavigationButton from "../Dates/MonthNavigationButton";
import {InputBaseVariant} from "../Inputs/InputBase/InputBase.web";
import RangePickerInput from "./RangePickerInput";
import Button, {ButtonSize, ButtonVariant} from "worksmith/components/Button/Button";
import moment from "moment";
import styled from "styled-components";
import {Color} from "worksmith/enums/Color";
import SimpleDialog from "../Dialog/SimpleDialog";

const DateSelectTypes = Object.freeze({
    START_DATE: 'startDate',
    END_DATE: 'endDate'
});

const DateRangeInputFormat = Object.freeze({
    MonthDayYearSlash: {
        format: 'MM/DD/YYYY',
        placeholder: 'mm/dd/yyyy',
    },
});

class DateRangePickerView extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            anchorElement: null
        };

        this.popoverElementRef = React.createRef();
        this.endDateInputRef = React.createRef();
        this.startDateInputRef = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
        let {endDateInputRef, startDateInputRef} = this;

        if (prevProps.currentSelection !== this.props.currentSelection && this.state.anchorElement) {
           if (this.props.currentSelection === DateSelectTypes.START_DATE) {
               startDateInputRef.current.focus();
           }
           else
               endDateInputRef.current.focus();
        }

    };

    readOnlyFieldsClicked = (event, currentSelection) => {
       let {popoverElementRef}  = this;
       let {disabled, readOnly, onInputSelected} = this.props;
       if (!disabled && !readOnly) {
           this.setState({anchorElement: popoverElementRef.current},
               () => onInputSelected(currentSelection))
       }
    };

    onClose = () => {
        const {onApply} = this.props;

        onApply();
        this.setState({anchorElement: null}, this.props.onClose);
    };

    onApply = () => {
        let {onApply} = this.props;

        this.setState({anchorElement: null}, () => onApply());
    };

    render()
    {
        const {readOnlyFieldsClicked, onClose} = this;
        const {startDateInputRef, endDateInputRef, popoverElementRef} = this;
        const {anchorElement, maxPopoverWidth, minPopoverWidth} = this.state;
        const {
            currentSelection,
            disabled,
            endDate,
            error,
            fullScreenOnMobile,
            fullWidth,
            initialVisibleMonth,
            inputBackground,
            inputBorderError,
            inputVariant,
            isDayBlocked,
            isDayHighlighted,
            isOutsideRange,
            minimumNights,
            onClear,
            onDateSelect,
            onEndDateChange,
            onInputBlur,
            onInputSelected,
            onStartDateChange,
            readOnlyInputRef,
            startDate,
            tempDatesInputRef,
            hideClearButton,
            showResetDatesButton,
            initialStartDate,
            initialEndDate
        } = this.props;
        const {format, placeholder} = this.props.dateInputFormat;

        return (
            <Fragment>
                <StyledRangePickerInput inputBackground={inputBackground} inputBorderError={inputBorderError}>
                    <RangePickerInput
                        childRef={popoverElementRef}
                        ref={readOnlyInputRef}
                        disabled={disabled}
                        fullWidth={fullWidth}
                        readOnly
                        initialEndDate={ValueIsSet(endDate) ? endDate.format(format) : null}
                        onEndDateClick={(event) => readOnlyFieldsClicked(event, DateSelectTypes.END_DATE)}
                        endDatePlaceholderText={placeholder}
                        initialStartDate={ValueIsSet(startDate) ? startDate.format(format) : null}
                        onStartDateClick={(event) => readOnlyFieldsClicked(event, DateSelectTypes.START_DATE)}
                        startDatePlaceholderText={placeholder}
                        variant={inputVariant}/>
                </StyledRangePickerInput>
                <SimpleDialog
                    open={ValueIsSet(anchorElement)}
                    onClose={onClose}
                    fullScreenOnMobile={fullScreenOnMobile}
                >
                    <Grid container justify={JustifyContent.CENTER} padding={'10px'}>
                        <Grid item xs={12}>
                            <RangePickerInput
                                ref={tempDatesInputRef}
                                fullWidth
                                error={error}
                                autoFocusEndDate={currentSelection===DateSelectTypes.END_DATE}
                                autoFocusStartDate={currentSelection===DateSelectTypes.START_DATE}
                                initialEndDate={ValueIsSet(endDate) ? endDate.format(format) : null}
                                endDateInputRef={endDateInputRef}
                                onEndDateChange={onEndDateChange}
                                onEndDateFocus={() => onInputSelected(DateSelectTypes.END_DATE)}
                                endDatePlaceholderText={placeholder}
                                initialStartDate={ValueIsSet(startDate) ? startDate.format(format) : null}
                                onInputBlur={onInputBlur}
                                startDateInputRef={startDateInputRef}
                                onStartDateChange={onStartDateChange}
                                onStartDateFocus={() => onInputSelected(DateSelectTypes.START_DATE)}
                                startDatePlaceholderText={placeholder}
                                variant={inputVariant}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Box fontFamily={FontFamily}
                                 display={Display.FLEX}
                                 justifyContent={JustifyContent.CENTER}>
                            <DateRangeCalendarBreakpointWrapper
                                startDate={startDate}
                                endDate={endDate}
                                focusedInput={
                                    currentSelection === DateSelectTypes.START_DATE ?
                                        START_DATE
                                        :
                                        END_DATE
                                }
                                initialVisibleMonth={initialVisibleMonth}
                                isDayBlocked={isDayBlocked}
                                isDayHighlighted={isDayHighlighted}
                                isOutsideRange={isOutsideRange}
                                minimumNights={minimumNights}
                                onDatesChange={onDateSelect}
                                onFocusChange={() => null}
                                renderMonthElement={(props) => {
                                    return <CalendarMonth {...props}/>
                                }}
                                renderNavNextButton={(props) => {
                                    return <MonthNavigationButton next iconName={'ChevronRight'} {...props}/>
                                }}
                                renderNavPrevButton={(props) => {
                                    return <MonthNavigationButton prev iconName={'ChevronLeft'} {...props}/>
                                }}
                            />
                            </Box>
                        </Grid>
                        {!hideClearButton ? <Grid item xs={12}>
                            <PopoverFieldButtons
                                buttonJustify={JustifyContent.FLEX_END}
                                onClose={onClose}
                                onClear={onClear}/>
                        </Grid> : null}
                        {showResetDatesButton &&
                        (!moment(startDate).isSame(moment(initialStartDate), "day") ||
                         !moment(endDate).isSame(moment(initialEndDate), "day")) ? <Grid item xs={12}>
                            <Grid container justify={JustifyContent.FLEX_END}>
                                <Button
                                    onClick={() => onDateSelect({startDate: initialStartDate, endDate: initialEndDate}, false)}
                                    size={ButtonSize.SMALL}
                                    primary
                                    variant={ButtonVariant.TEXT}>
                                    Reset Dates
                                </Button>
                            </Grid>
                        </Grid> : null}
                    </Grid>
                </SimpleDialog>
            </Fragment>
        );
    }
}

DateRangePickerView.defaultProps = {
    dateInputFormat: DateRangeInputFormat.MonthDayYearSlash,
    fullScreenOnMobile: true,
};

DateRangePickerView.propTypes = {
    currentSelection: CustomPropTypes.enum(DateSelectTypes),
    dateInputFormat: PropTypes.shape({
        format: PropTypes.string.isRequired, // moment format for displaying the value
        placeholder: PropTypes.string, // input placeholder
    }).isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    endDate: CustomPropTypes.moment,
    error: PropTypes.bool,
    fullScreenOnMobile: PropTypes.bool,
    fullWidth: PropTypes.bool,
    initialVisibleMonth: PropTypes.func,
    inputVariant: CustomPropTypes.enum(InputBaseVariant),
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,
    isOutsideRange: PropTypes.func,
    minimumNights: PropTypes.number,
    onApply: PropTypes.func,
    onClear: PropTypes.func,
    onClose: PropTypes.func,
    onDateSelect: PropTypes.func.isRequired,
    onEndDateBlur: PropTypes.func,
    onEndDateChange: PropTypes.func.isRequired,
    onInputSelected: PropTypes.func.isRequired,
    onStartDateBlur: PropTypes.func,
    onStartDateChange: PropTypes.func.isRequired,
    readOnlyInputRef: PropTypes.object,
    startDate: CustomPropTypes.moment,
    tempDatesInputRef: PropTypes.object,
    hideClearButton: PropTypes.bool,
    showResetDatesButton: PropTypes.bool,
    initialStartDate: PropTypes.string,
    initialEndDate: PropTypes.string
};

export default withTheme(DateRangePickerView);

const StyledRangePickerInput = styled('div')`
    && {
        background-color: ${props => props.inputBackground ? props.inputBackground : 'inherit'};
        div & {
            border: ${props => props.inputBorderError ? `solid ${Color.ERROR} 1px` : 'inherit'};
            border-radius: 4px;
        }
    }
`;