import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@mui/icons-material';

import {ThemeConstants, withTheme} from '../Theme/ThemeProvider';
import {PickColor} from 'worksmith/helpers/ThemeHelpers';
import CustomPropTypes from 'worksmith/custom-prop-types/CustomPropTypes';
import {Display} from 'worksmith/enums/CSSEnums';
import {ComponentColor, FunctionalColor} from 'worksmith/enums/Color';

export const IconFontSize = Object.freeze({
    LARGE: 'large',
    DEFAULT: 'default',
    SMALL: 'small',
    INHERIT: 'inherit'
});

export const IconColor = Object.freeze({
    INHERIT: 'inherit',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    ACTION: 'action',
    ERROR: 'error',
    DISABLED: 'disabled',
});

const Icon = (props) => {
    let {
        centered,
        flipHorizontal,
        flipVertical,
        fontSize,
        left,
        name,
        onMouseDown,
        onMouseLeave,
        onMouseOver,
        primary,
        right,
        secondary,
        sx,
        translateTop,
        error,
        action,
        iconColor,
        verticalCentered,
        verticalSub,
    } = props;

    const IconComponent = Icons[name];

    const placementStyling = {};

    if (right) {
        placementStyling.marginLeft = ThemeConstants.spacingUnit + 'px';
    }

    if (left) {
        placementStyling.marginRight = ThemeConstants.spacingUnit + 'px';
    }

    if (centered) {
        placementStyling.display = Display.BLOCK;
        placementStyling.margin = 'auto';
    }

    if (translateTop) {
        placementStyling.transform = 'translate(0,' + translateTop + 'px)';
    }

    if (iconColor) {
        placementStyling.color = iconColor;
    }

    if (verticalCentered) {
        placementStyling.verticalAlign = 'middle';
    }

    if (verticalSub) {
        placementStyling.verticalAlign = 'sub';
    }

    if (flipHorizontal) {
        placementStyling.transform = 'scaleX(-1)';
    }

    if (flipVertical) {
        placementStyling.transform = 'scaleY(-1)';
    }

    const color = PickColor(primary, secondary, error, action);

    return (
        <IconComponent color={color === ComponentColor.DEFAULT ? 'inherit' : color}
                       fontSize={fontSize}
                       className={props.className}
                       onMouseLeave={onMouseLeave}
                       onMouseOver={onMouseOver}
                       onMouseDown={onMouseDown}
                       style={{...placementStyling}}
                       sx={sx}
        />
    );
};

Icon.propTypes = {
    centered: PropTypes.bool,
    flipHorizontal: PropTypes.bool,
    flipVertical: PropTypes.bool,
    fontSize: CustomPropTypes.enum(IconFontSize),
    left: PropTypes.bool,                           //adds standard amount of padding to right of this icon, useful when icon is placed to the left of Text
    name: PropTypes.string.isRequired,
    onMouseDown: PropTypes.func,                   //this allows support for the delete icon on the chip to work with select dropdown
    onMouseLeave: PropTypes.func,                   //this allows support for wrapping icons with Tooltips
    onMouseOver: PropTypes.func,                    //this allows support for wrapping icons with Tooltips
    primary: PropTypes.bool,
    right: PropTypes.bool,                          //adds standard amount of padding to left of this icon, useful when icon is placed to the right of Text
    secondary: PropTypes.bool,
    translateTop: PropTypes.number,                  //This props shifts the icons downward the given number of pixels, useful for lining up icons with inline components
    error: PropTypes.bool,
    action: PropTypes.bool,
    verticalCentered: PropTypes.bool,
    iconColor: CustomPropTypes.enum(FunctionalColor),
    sx: PropTypes.object,
};

export default withTheme(Icon);
