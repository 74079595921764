import React from 'react';
import PropTypes from 'prop-types';
import MaterialDivider from '@mui/material/Divider';
import {withTheme} from "../Theme/ThemeProvider";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";

export const DividerVariant = Object.freeze({
    FULL_WIDTH: 'fullWidth',
    INSET: 'inset',
    MIDDLE: 'middle'
});

export const DividerOrientation = Object.freeze({
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
});

const Divider = (props) => {
    let {
        absolute,
        light,
        orientation,
        variant
    } = props;

    return (
        <MaterialDivider
            absolute={absolute}
            light={light}
            orientation={orientation}
            variant={variant}/>
    )
};

Divider.defaultProps= {
    absolute: false, //absolute positioned
    flexItem: false,
    light: false,
    orientation: DividerOrientation.HORIZONTAL,
    variant: DividerVariant.FULL_WIDTH
}

Divider.propTypes = {
    absolute: PropTypes.bool,
    flexItem: PropTypes.bool,
    light: PropTypes.bool,
    orientation: CustomPropTypes.enum(DividerOrientation),
    variant: CustomPropTypes.enum(DividerVariant)
};

export default withTheme(Divider);