import React, {Component} from 'react';
import PrintSingleTicket from "../../components/obligations/PrintSingleTicket";
import ClientService from 'worksmith/services/api/ClientService';
const clientService = new ClientService();

class PrintTicketsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            tickets: JSON.parse(window.opener.localStorage.getItem('selectedTickets')),
            settings: {
                dryCleaning: {
                    //Set to true until settings are loaded in to avoid any chance of showing prices for a split second
                    hidePricingFromLocalUsers: true
                }
            }
        }
    }

    componentDidMount() {
        let _this = this;
        let {user} = this.state;

        let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;
        clientService.getSingleDryCleaningFormConfiguration(clientId).then(function (data) {
            _this.setState({settings: {dryCleaning: data}}, function() {
                setTimeout(function() {
                    window.print();
                    window.close();
                }, 2000);
            });
        });
    }

    render() {
        let {user, tickets, settings} = this.state;

        return (<div>
            {tickets.map(function (ticket, idx) {
                return (<PrintSingleTicket key={idx} ticket={ticket} settings={settings} printable={true} user={user}/>)
            })}
        </div>)
    }
}

export default PrintTicketsPage;
