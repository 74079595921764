import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from "moment";

import Grid from "worksmith/components/Grid/Grid.web";
import TextField from "worksmith/components/Inputs/TextField/TextField.web";
import DateRangePicker from "worksmith/components/DateRangePicker/DateRangePicker";
import Autocomplete from "worksmith/components/Inputs/Autocomplete/Autocomplete.web";
import {TextFieldVariant} from "worksmith/components/Inputs/TextField/TextField.web";
import MultiselectFilterChip from "worksmith/components/FilterChip/Multiselect/MultiselectFilterChip";
import {WithContext} from "../../context/GlobalContext";
import {Debounce} from "../../Helpers";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {GenericSort, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import {UnitedStatesAndCanada} from "worksmith/enums/api/country/USAState";

const graphQLService = new GraphQLServiceClass();

const SearchableRequestTypeOptions = [
    {
        label: "Emergency",
        value: "EMERGENCY"
    },
    {
        label: "One Time",
        value: "ON_DEMAND"
    },
    {
        label: "Recurring",
        value: "RECURRING"
    }
];

export const RequestStatusOptions = Object.freeze([
    {
        label: "Corporate Review",
        value: "CORPORATE_REVIEW",
        group: "Open"
    },
    {
        label: "Contacting Vendors",
        value: "MARKET",
        group: "Open"
    },
    {
        label: "Corporate Pricing Review",
        value: "CORPORATE_PRICING_REVIEW",
        group: "Open"
    },
    {
        label: "Scheduling",
        value: "SCHEDULING",
        group: "Open"
    },
    {
        label: "Scheduled",
        value: "SCHEDULED",
        group: "Open"
    },
    {
        label: "Completed",
        value: "COMPLETED",
    },
    {
        label: "Canceled",
        value: "CANCELED",
    }
]);

class RequestFilter extends Component {
    constructor(props) {
        super(props);

        this.allStatesProvincesOption = {label: "All States/Provinces", value: 0};
        let allStatesProvincesOption = [this.allStatesProvincesOption];
        let stateProvinceSortedWithNoDuplicates = GenericSort(props.context.clientLocations.filter((value,index,array)=> array.findIndex(value2=>(value2.state.trim()) === value.state.trim()) === index), item => item.state, false);
        allStatesProvincesOption = allStatesProvincesOption.concat(
            stateProvinceSortedWithNoDuplicates.map(location => {
                return {label: UnitedStatesAndCanada[location.state.trim()], value: location.state.trim()};
            })
        );

        this.allLocationsOption = {label: "All Locations", value: 0};
        let clientLocationOptions = [this.allLocationsOption];
        clientLocationOptions = clientLocationOptions.concat(
            props.context.clientLocations.map(location => {
                return {label: location.name, value: location.id, state: location.state.trim()};
            })
        );

        this.state = {
            allStatesProvincesOption: allStatesProvincesOption,
            clientLocationOptions: clientLocationOptions,
            loading: true,
            serviceLineOptions: [],
            showSingleLocationView: props.context.clientLocations.length === 1,
            ...this.getFilterStateFromSearchParameters(props.initialSearchParams, allStatesProvincesOption, clientLocationOptions)
        };

        this.searchTermFieldRef = React.createRef();
        this.clientLocationRef = React.createRef();
    }

    componentDidMount() {

        this.getClientServiceLines().then((clientObject) => {
            let serviceLineOptions = clientObject.selectedServiceLines.map(serviceLine => {
                return {label: serviceLine.name, value: serviceLine.id};
            });

            this.setState({
                loading: false,
                serviceLineOptions: serviceLineOptions,
            });
        });
    }

    getFilterStateFromSearchParameters = (searchParams, allStatesProvincesOption, clientLocationOptions) => {

       if (!ValueIsSet(searchParams)) {
           return ({
               initialSelectedStateProvinceOption: this.allStatesProvincesOption,
               initialSelectedLocationOption: this.allLocationsOption,
               selectedClientLocationIds: null,
               createdStartDate: null,
               createdEndDate: null,
               isSearchSelected: false,
               requestStatuses: [],
               searchableRequestTypes: [],
               searchTerm: '',
               serviceLineIds: [],
           });
       } else {
           const {rfpId, vendorName, requestStatuses, searchableRequestTypes, serviceLineIds, createdEndDate, createdStartDate, stateProvince} = searchParams;

           let filterState = {
               createdStartDate: createdStartDate,
               createdEndDate: createdEndDate,
               searchableRequestTypes: searchableRequestTypes ? searchableRequestTypes : [],
               requestStatuses: requestStatuses ? requestStatuses : [],
               serviceLineIds: serviceLineIds ? serviceLineIds : [],
               stateProvince: stateProvince ? stateProvince : 0,
           };

           if (ValueIsSet(rfpId) || ValueIsSet(vendorName)) {
               filterState.isSearchSelected = true;
               filterState.searchTerm = ValueIsSet(rfpId) ? rfpId : vendorName;
           }

           // Set the initial state/province value to the value stored in localStorage.
           if (ValueIsSet(searchParams.stateProvince) && searchParams.stateProvince !== 0) {
               filterState.initialSelectedStateProvinceOption = allStatesProvincesOption.find(stateProvinceOption => stateProvinceOption.value === searchParams.stateProvince);
           } else {
               filterState.initialSelectedStateProvinceOption = this.allStatesProvincesOption;
           }

           if (searchParams.clientLocationIds.length === 1
               && clientLocationOptions.filter(locationOption => locationOption.value === searchParams.clientLocationIds[0]).length > 0) {
               filterState.selectedClientLocationIds = searchParams.clientLocationIds[0];
               filterState.initialSelectedLocationOption = clientLocationOptions.filter(locationOption => locationOption.value === searchParams.clientLocationIds[0])[0];
           } else {
               filterState.selectedClientLocationIds = null;
               filterState.initialSelectedLocationOption = this.allLocationsOption;
           }
           return filterState;
       }
    };

    getClientServiceLines = () => {
        const {client} = this.props.context;
        return graphQLService.findOneById(
            client.id,
            GraphQLObjectType.CLIENT,
            `
            id
            selectedServiceLines{
                id
                name
            }
            `
        )
    };

    onApplyFilters = (initialLoad) => {
        const {onApply} = this.props;
        const {clientLocations, showCampaigns} = this.props.context;
        const {selectedClientLocationIds, createdEndDate, createdStartDate, isSearchSelected, requestStatuses, searchableRequestTypes, searchTerm, serviceLineIds, stateProvince}  = this.state;

        let searchParams;
        if (isSearchSelected) {
            searchParams = {
                clientLocationIds: clientLocations.map(location => location.id),
            };

            if (isNaN(searchTerm)) {
                searchParams.vendorName = searchTerm;
            } else {
                searchParams.rfpId = searchTerm;
            }
        } else {
            searchParams = {
                createdStartDate: createdStartDate ?  createdStartDate : null,
                createdEndDate: createdEndDate ? createdEndDate : null,
                searchableRequestTypes: searchableRequestTypes.length > 0 ? searchableRequestTypes : null,
                requestStatuses: requestStatuses.length > 0 ? requestStatuses : null,
                serviceLineIds: serviceLineIds.length > 0 ? serviceLineIds : null,
                stateProvince: stateProvince ? stateProvince : null,
            };

            if (ValueIsSet(selectedClientLocationIds)) {
                searchParams.clientLocationIds = selectedClientLocationIds;
            } else {
                searchParams.clientLocationIds = clientLocations.map(location => location.id);
            }
        }

        searchParams.hasCampaign = showCampaigns ? null : false;

        onApply(searchParams, initialLoad);
    };

    onSearchTermChange = (newSearchTerm) => {
       if (newSearchTerm !== '') {
          this.setState({searchTerm: newSearchTerm.trim(), isSearchSelected: true}, this.onApplyFilters);
       } else {
           this.setState({searchTerm: newSearchTerm, isSearchSelected: false}, this.onApplyFilters);
       }
    };

    onStateProvinceSelect = (stateProvince) => {
        // Filters the list of clientLocations to create a list of clientLocationIds that are located in the state/province selected.
        const clientLocationIds = stateProvince === 0 ? null : this.state.clientLocationOptions.filter(location => location.state === stateProvince).map(location => location.value);
        // Sets the locations autocomplete to "All Location" if the state/province autocomplete is changed.
        this.clientLocationRef.current.setOptionThroughRef(this.allLocationsOption);

        this.setState({selectedClientLocationIds: clientLocationIds, stateProvince: stateProvince}, this.onApplyFilters);
    };

    onLocationSelect = (locationId) => {
        // If "All Location" is selected create a list of clientLocationIds that are located in the state/province selected else set clientLocationIds to the id of the store location.
        const clientLocationIds = locationId === 0 ? this.state.clientLocationOptions.filter(location => (location.state === this.state.stateProvince) || (this.state.stateProvince === 0)).map(location => location.value) : [locationId];
        this.setState({selectedClientLocationIds: clientLocationIds}, this.onApplyFilters);
    };

    onDateRangeChange = (startDate, endDate) => {
        this.setState({createdStartDate: startDate, createdEndDate: endDate}, this.onApplyFilters);
    };

    onRequestStatusesChange = (requestStatuses) => {
        this.setState({requestStatuses}, this.onApplyFilters);
    };

    onServiceLinesChange = (serviceLineIds) => {
        this.setState({serviceLineIds}, this.onApplyFilters);
    };

    onRfpTypeChange = (searchableRequestTypes) => {
        this.setState({searchableRequestTypes}, this.onApplyFilters);
    };

    render() {
        let {
            onDateRangeChange,
            onStateProvinceSelect,
            onLocationSelect,
            onRequestStatusesChange,
            onRfpTypeChange,
            onSearchTermChange,
            onServiceLinesChange
        } = this;
        let {searchTermFieldRef, clientLocationRef} = this;
        let {
            allStatesProvincesOption,
            clientLocationOptions,
            createdEndDate,
            createdStartDate,
            initialSelectedStateProvinceOption,
            initialSelectedLocationOption,
            loading,
            isSearchSelected,
            requestStatuses,
            searchableRequestTypes,
            showSingleLocationView,
            searchTerm,
            serviceLineIds,
            serviceLineOptions,
            stateProvince
        } = this.state;

        let debounceSearchFieldChange = Debounce(onSearchTermChange, 500);

        return (
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        disabled={loading}
                        ref={searchTermFieldRef}
                        initialValue={searchTerm}
                        fullWidth
                        includeClearButton
                        onChange={debounceSearchFieldChange}
                        placeholder={"Search for a Request # or Vendor Name"}
                        startAdornment={<SearchIcon/>}/>
                </Grid>
                <Grid item xs={6}>
                    <DateRangePicker
                        onDatesChange={onDateRangeChange}
                        initialStartDate={createdStartDate}
                        initialEndDate={createdEndDate}
                        disabled={isSearchSelected || loading}
                        fullWidth
                    />
                </Grid>
                {!showSingleLocationView &&
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            allowEmptyValue={false}
                            characterBuffer={2}
                            disabled={isSearchSelected || loading}
                            fullWidth
                            initialSelectedItem={initialSelectedStateProvinceOption}
                            label={"State/Province"}
                            onSelect={onStateProvinceSelect}
                            options={allStatesProvincesOption}
                            placeholder={'Start Typing...'}
                            startAdornment={<LocationOnIcon/>}
                            startAdornmentProps={{disablePointerEvents: true}}
                            variant={TextFieldVariant.OUTLINED}
                        />
                    </Grid>
                }
                {!showSingleLocationView &&
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            ref={clientLocationRef}
                            allowEmptyValue={false}
                            characterBuffer={2}
                            disabled={isSearchSelected || loading}
                            fullWidth
                            initialSelectedItem={initialSelectedLocationOption}
                            label={"Location"}
                            onSelect={onLocationSelect}
                            options={stateProvince === 0 ? clientLocationOptions : clientLocationOptions.filter(option => option.state === stateProvince || option.value === 0)} // If state/province is not set to "All State/Province" remove options that are not located in the state/province selected.
                            placeholder={'Start Typing...'}
                            startAdornment={<Icon name={IconType.STORE}/>}
                            startAdornmentProps={{disablePointerEvents: true}}
                            variant={TextFieldVariant.OUTLINED}
                        />
                    </Grid>
                }

                <Grid item container xs={12} spacing={2}>
                    <Grid item>
                        <MultiselectFilterChip
                            key={loading ? 'loadingStatus' : 'status'}
                            includeSelectAll
                            initialSelected={loading ? null : requestStatuses}
                            disabled={isSearchSelected || loading}
                            onChange={onRequestStatusesChange}
                            options={RequestStatusOptions}
                            selectAllLabel={"Select all statuses"}
                            selectAllTitle={"All Statuses"}
                            title={"Status"}/>
                    </Grid>
                    <Grid item>
                        <MultiselectFilterChip
                            key={loading ? 'loadingServiceLines' : 'serviceLines'}
                            includeSelectAll
                            initialSelected={loading ? null : serviceLineIds}
                            disabled={isSearchSelected || loading}
                            onChange={onServiceLinesChange}
                            options={serviceLineOptions}
                            selectAllLabel={"Select all service lines"}
                            selectAllTitle={"All Service Lines"}
                            title={"Service line"}/>
                    </Grid>
                    <Grid item>
                        <MultiselectFilterChip
                            key={loading ? 'loadingTypes' : 'types'}
                            initialSelected={loading ? null : searchableRequestTypes}
                            onChange={onRfpTypeChange}
                            disabled={isSearchSelected || loading}
                            options={SearchableRequestTypeOptions}
                            title={"Type"}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

RequestFilter.propTypes = {
    initialSearchParams: PropTypes.shape({
        createdEndDate: PropTypes.instanceOf(moment),
        createdStartDate: PropTypes.instanceOf(moment),
        requestStatuses: PropTypes.arrayOf(PropTypes.string),
        searchableRequestTypes: PropTypes.arrayOf(PropTypes.string),
        rfpId: PropTypes.number,
        vendorName: PropTypes.string,
        clientLocationIds: PropTypes.arrayOf(PropTypes.number),
        serviceLineIds: PropTypes.arrayOf(PropTypes.number)
    }),
    onApply: PropTypes.func.isRequired
};

export default WithContext(RequestFilter);