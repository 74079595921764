import React from 'react';
import moment from 'moment';
import styled from "styled-components";
import {Color} from "worksmith/enums/Color";

const t = require('tcomb-form');

//Request Completion Date Type

const ErrorText = styled.div`color: ${Color.RED}`;

function isEmergencyRequest(enteredDate) {
    return moment(moment().startOf('day')).isSame(enteredDate, 'day') || moment(moment().startOf('day').add(1, 'days')).isSame(enteredDate, 'day');
}

const RequestDate = t.subtype(t.Date, function (enteredDate) {
    return moment(moment().startOf('day')).isSameOrBefore(enteredDate);
});

RequestDate.getValidationErrorMessage = function (value, path, context) {
    return (value == null) ? <ErrorText>Required Field</ErrorText> :
                             <ErrorText>Request must be for a date in the future</ErrorText>;
};

//Email Type

const emailRe = new RegExp('.+@.+\\..+');

const Email = t.subtype(t.String, function (n) {
        return emailRe.test(n);
    }
);

Email.getValidationErrorMessage = function (value, path, context) {
    var reply = (value == null) ? <ErrorText>Required Field</ErrorText>  : <ErrorText>Invalid Email</ErrorText>;
    return reply;
};

export {Email, RequestDate, isEmergencyRequest};
