import React, {Component} from 'react';
import {Portal} from "@mui/material";

import Snackbar from "worksmith/components/Snackbar/Snackbar";
import {withTheme} from '../Theme/ThemeProvider';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

class GlobalSnackbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSnackbarOpen: false,
            snackbarMessage: ""
        };
    }

    componentDidMount() {
        window.addEventListener('global-snackbar-event', this.handleSnackbarOpen);
    }

    componentWillUnmount() {
        window.removeEventListener('global-snackbar-event', this.handleSnackbarOpen);
    }

    handleSnackbarOpen = (event) => {
        if(ValueIsSet(event.detail)){
            this.setState({snackbarMessage: event.detail.message, isSnackbarOpen: true})
        }
    };

    onClose = () => {
        this.setState({isSnackbarOpen: false})
    };

    render() {
        const {onClose} = this;
        const {isSnackbarOpen, snackbarMessage} = this.state;


        return (
            <Portal>
                <Snackbar
                    onClose={onClose}
                    isOpen={isSnackbarOpen}
                    message={snackbarMessage}
                />
            </Portal>
        )
    }
}

GlobalSnackbar.propTypes = {
};

export default withTheme(GlobalSnackbar);