import React from 'react';
import PropTypes from 'prop-types';

import {VictoryBar, VictoryChart, VictoryAxis, VictoryLabel} from 'victory';
import {DataVisualizationTheme, FontFamily} from "../../Theme/ThemeProvider";
import CustomDataLabel from "../CustomDataLabel.web";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {OverflowOption} from "worksmith/enums/CSSEnums";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import {TruncateStringWithEllipsis} from "worksmith/helpers/GenericHelpers";

const BarGraph = (props) => {
    const {
        animate,
        data,
        dependentAxisLabel,
        height,
        hideLabels,
        horizontal,
        independentAxisLabel,
        maxLabelLength,
        overflow,
        title,
        width,
        x,
        y
    } = props;

    let combinedDataTotal = 0;

    //If the data being passed is represented as a number we can automatically total it up to be used in places like the tooltip
    if (data.length > 0 && !isNaN(data[0].y))
        data.forEach((dataPoint) => {
            combinedDataTotal += dataPoint.y;
        });

    return (
        data.length > 0 ?
            <div style={{width: width + 'px', height: height + 'px', margin: 'auto'}}>
                {typeof title !== JavascriptType.STRING ?
                    title
                    :
                    null
                }
                <VictoryChart containerComponent={<svg viewBox={`0 0 ${width} ${height}`}
                                                       style={{height: '100%', width: '100%', overflow: overflow}}/>}
                              height={height}
                              width={width}
                              animate={animate}
                              theme={DataVisualizationTheme}
                              domainPadding={20}>
                    {typeof title === JavascriptType.STRING ?
                        <text fontSize={20} fontFamily={FontFamily} x={width / 2} textAnchor={'middle'}>{title}</text>
                        :
                        null
                    }
                    <VictoryAxis axisLabelComponent={<VictoryLabel dy={25}/>}
                                 tickLabelComponent={<VictoryLabel
                                     text={(info) => hideLabels ? '' : TruncateStringWithEllipsis(data[info.index].x, maxLabelLength)}/>}
                                 label={independentAxisLabel}/>

                    <VictoryAxis dependentAxis
                                 axisLabelComponent={<VictoryLabel dy={-30}/>}
                                 label={dependentAxisLabel}/>

                    <VictoryBar theme={DataVisualizationTheme}
                                horizontal={horizontal}
                                labels={({datum}) => hideLabels ? '' : datum.x}
                                labelComponent={<CustomDataLabel hideLabels type={'bar'}
                                                                 combinedDataTotal={combinedDataTotal}/>}
                                x={x}
                                y={y}
                                data={data}/>
                </VictoryChart>
            </div>
            :
            null
    )
};

BarGraph.propTypes = {
    animate: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),       //more info here https://formidable.com/open-source/victory/docs/victory-animation and here https://formidable.com/open-source/victory/docs/victory-transition
    data: PropTypes.arrayOf(PropTypes.shape({
        tooltip: PropTypes.string,
        tooltipLabelFontSize: CustomPropTypes.stringOrNumber,
        x: PropTypes.string,            //by default x is the label
        y: PropTypes.number             //by default y is the value
    })).isRequired,
    dependentAxisLabel: PropTypes.string,
    height: PropTypes.number.isRequired,
    hideLabels: PropTypes.bool,
    horizontal: PropTypes.bool,
    independentAxisLabel: PropTypes.string,
    maxLabelLength: PropTypes.number,                   //determines the max character length of the independent axis labels
    overflow: CustomPropTypes.enum(OverflowOption),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    width: PropTypes.number.isRequired,
    x: PropTypes.oneOfType([                            //this can be used to specify a data accessor for the x value, more info here https://formidable.com/open-source/victory/docs/common-props/#x
        PropTypes.string,                               //string: specify which property in an array of data objects should be used as the x value
        PropTypes.number,                               //function: use a function to translate each element in a data array into an x value
        PropTypes.arrayOf(PropTypes.string),            //array index: specify which index of an array should be used as an x value when data is given as an array of arrays
        PropTypes.func                                  //path string or path array: specify which property in an array of nested data objects should be used as an x value
    ]),
    y: PropTypes.oneOfType([                            //this can be used to specify a data accessor for the y value, more info here https://formidable.com/open-source/victory/docs/common-props/#y
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func
    ])
};

BarGraph.defaultProps = {
    animate: {duration: 1000},
    hideAxis: false,
    maxLabelLength: 36,
    overflow: OverflowOption.VISIBLE
};

export default BarGraph;