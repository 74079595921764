import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {lineItemSort, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Currency from "worksmith/antd-components/Currency/Currency.web";
import Grid from "worksmith/components/Grid/Grid.web";
import {TextAlign} from 'worksmith/enums/CSSEnums';
import {Color} from 'worksmith/enums/Color';
import Text, {TextVariant} from "../../components/Text/Text.web";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import UserType from "worksmith/enums/api/user/UserType";

const Line = styled.div`
    box-sizing: border-box;	
    height: 1px;	
    width: 100%;	
    border-bottom: 0.5px solid ${Color.GREY};
`;
const CenterAlignTitle = styled.div`
    text-align: ${TextAlign.CENTER};  
`;

const CenterAlignContent = styled.div`
    text-align: ${TextAlign.CENTER};  
`;

const RightAlignTitle = styled.div`
    text-align: ${TextAlign.RIGHT};  
`;

const RightAlignContent = styled.div`
    text-align: ${TextAlign.RIGHT};  
`;

const PriceBreakdownTable = (props) => {
    let {items, textVariant, userType} = props;

    // Someday: Componentize this table and clean up styled components
    return(
        <Grid container spacing={2}>
            <Grid item container>
                <Grid item xs={5}>
                    <Text variant={textVariant}>
                       Item
                    </Text>
                </Grid>
                <Grid item xs={1}>
                    <CenterAlignTitle>
                        <Text variant={textVariant}>
                            Qty
                        </Text>
                    </CenterAlignTitle>
                </Grid>
                <Grid item xs={3}>
                    <RightAlignTitle>
                        <Text variant={textVariant}>
                            Unit Price
                        </Text>
                    </RightAlignTitle>
                </Grid>
                <Grid item xs={3}>
                    <RightAlignTitle>
                        <Text variant={textVariant}>
                            Total Price
                        </Text>
                    </RightAlignTitle>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Line/>
            </Grid>
            {/* Filters `proposal.items`: for VENDOR portal type, keeps items with non-zero `unitWholesalePrice`; */}
            {/* for CLIENT portal type, keeps items with non-zero `unitRetailPrice`. */}
            {/* This filters out items that do not have a relevant price set (price set to $0), based on the context of the portal (either a vendor or a client). */}
            { items.sort(lineItemSort).filter(item => userType === UserType.VENDOR ? item.unitWholesalePrice !== 0 : userType === UserType.CLIENT && item.unitRetailPrice !== 0).map((item, index) => {
                let itemName = ValueIsSet(item.displayValue) ?
                    item.displayValue : ValueIsSet(item.title) ?
                        item.title : item.name;

                return (
                    <Grid item container key={index}>
                        <Grid item container>
                            <Grid item xs={5}>
                                <Text variant={textVariant}>
                                    {
                                    ValueIsSet(item.lineItemType) && ValueIsSet(item.lineItemType.name)
                                        ?
                                            <Text variant={TextVariant.CAPTION} bold>{item.lineItemType.name}</Text>
                                        :
                                        null
                                    }
                                    {itemName}
                                </Text>
                            </Grid>
                            <Grid item xs={1}>
                                <CenterAlignContent>
                                    <Text variant={textVariant}>
                                        {item.quantity}
                                    </Text>
                                </CenterAlignContent>
                            </Grid>
                            <Grid item xs={3}>
                                <RightAlignContent>
                                    <Text variant={textVariant}>
                                    {item.isDoable || !ValueIsSet(item.isDoable) ? <Currency amount={userType === UserType.VENDOR ? item.unitWholesalePrice : item.unitRetailPrice}/>  : 'excluded'}
                                    </Text>
                                </RightAlignContent>
                            </Grid>
                            <Grid item xs={3}>
                                <RightAlignContent>
                                    <Text variant={textVariant}>
                                    {item.isDoable || !ValueIsSet(item.isDoable) ? <Currency amount={(userType === UserType.VENDOR ? item.unitWholesalePrice : item.unitRetailPrice) * item.quantity}/> : 'excluded'}
                                    </Text>
                                </RightAlignContent>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

PriceBreakdownTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        isDoable: PropTypes.bool,
        lineItemType: PropTypes.shape({
            name: PropTypes.string
        }),
        quantity: PropTypes.number,
        title: PropTypes.string,
        name: PropTypes.string,
        unitRetailPrice: PropTypes.number,
        unitWholesalePrice: PropTypes.number
    })),
    textVariant: CustomPropTypes.enum(TextVariant),
    userType: CustomPropTypes.enum(UserType)
};

PriceBreakdownTable.defaultProps = {
    userType: UserType.CLIENT,          // userType is defaulted to ClIENT because this component was initially designed to only show the Retail price and not wholesale price. Retail price no longer shows up anywhere in Vendor Portal so userType in passes in as a props on vendor portal to show wholesale prices instead of retail prices.
};

export default PriceBreakdownTable;
