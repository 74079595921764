import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import Currency from "worksmith/components/Currency/Currency.web";

const InvoiceLineItem = (props) => {
    const {description, quantity, total, totalDiscount, unitDiscount, unitPrice, reasonCode, columnWidths} = props;

    return (
        <Fragment>
            <Grid container>
                <Grid item width={`${columnWidths.serviceDate}%`}/>
                <Grid item width={`${columnWidths.serviceDetails}%`}>
                    <Text variant={TextVariant.BODY_2}>
                        {description}
                    </Text>
                </Grid>
                <Grid item width={`${columnWidths.qty}%`}>
                    <Text variant={TextVariant.BODY_2}>
                        {quantity}
                    </Text>
                </Grid>
                <Grid item width={`${columnWidths.unitPrice}%`}>
                    <Text variant={TextVariant.BODY_2}>
                        <Currency amount={unitPrice}/>
                    </Text>
                </Grid>
                {columnWidths.totalDiscount > 0 ?
                    <Grid item width={`${columnWidths.totalDiscount}%`}>
                        <Text variant={TextVariant.BODY_2}>
                            <Currency amount={unitDiscount}/>
                        </Text>
                    </Grid>
                    :
                    null}
                <Grid item width={`${columnWidths.amount}%`}>
                    <Text variant={TextVariant.BODY_2}>
                        <Currency amount={total}/>
                    </Text>
                </Grid>
                {columnWidths.reasonCode > 0 ?
                    <Grid item width={`${columnWidths.reasonCode}%`}>
                        <Text variant={TextVariant.BODY_2}>{reasonCode}</Text>
                    </Grid>
                    :
                    null}
            </Grid>
        </Fragment>
    )
};

InvoiceLineItem.propTypes = {
    description:   PropTypes.string.isRequired,
    quantity:      PropTypes.number.isRequired,
    total:         PropTypes.number.isRequired,
    totalDiscount: PropTypes.number.isRequired,
    unitDiscount:  PropTypes.number.isRequired,
    unitPrice:     PropTypes.number.isRequired
};

export default InvoiceLineItem;