import React from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid";

import IconButton from "worksmith/components/Button/IconButton";
import {JustifyContent, TextAlign} from "../../../CSSEnums";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import {TextDecoration} from "worksmith/enums/CSSEnums";
import Divider from "worksmith/components/Divider/Divider";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ClientRoutes from "../../../ClientRoutes";
import ActionableModalTemplate from "worksmith/components/Modal/Templates/ActionableModalTemplate";
import Modal from "worksmith/components/Modal/Modal";
import PriceBreakdown from "worksmith/composite-components/PriceBreakdown/PriceBreakdown";
import CalendarListViewActionButton from "worksmith/composite-components/CalendarListViewActionButton";
import ResolvedConcernExpansionPanel from "worksmith/composite-components/JobDetails/ResolvedConcernExpansionPanel";
import OpenConcernExpansionPanel from "worksmith/composite-components/JobDetails/OpenConcernExpansionPanel";
import SignOffExpansionPanel from "worksmith/composite-components/JobDetails/SignOffExpansionPanel";
import ClientChangeOrderDetailsDialog from "worksmith/components/ChangeOrderRequest/ClientChangeOrderDetailsDialog";
import CarouselInlineWithLightBox from "worksmith/components/Lightbox/CarouselInlineWithLightBox";
import AwaitingSignOffExpansionPanel from "worksmith/composite-components/JobDetails/AwaitingSignOffExpansionPanel";
import {WithContext} from "../../../context/GlobalContext";


const JobDetailsSidePanelView = ({
                                     context,
                                     reload,
                                     cancelChangeRequest,
                                     changeOrderModal,
                                     setChangeOrderModal,
                                     job,
                                     loading,
                                     priceModal,
                                     setPriceModal,
                                     setShowJobDetails
                                 }) => {

    let changeOrderId = job.latestNonCancelledChangeOrderRequest != null ? job.latestNonCancelledChangeOrderRequest.id : null;
    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;


    return <>
        <Grid container>
            <Grid xs={6} item>
                <IconButton
                    iconName={'Close'}
                    onClick={() => {setShowJobDetails(false)}}
                    size="medium" />
            </Grid>
            <Grid container item xs={6} justify={JustifyContent.FLEX_END}>
                <Grid item>
                    <CalendarListViewActionButton
                        obligation={job}
                        reload={reload}
                        cancelChangeRequest={cancelChangeRequest}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justify={JustifyContent.CENTER} margin={'16px 0 0 0'}>
                <Text variant={TextVariant.H5}> Job Details</Text>
            </Grid>
            <Grid container item xs={12} justify={JustifyContent.CENTER} margin={"1rem 0 0 0"}>
                {ValueIsSet(job.request) ?
                    <Text link newTab href={"#/" + ClientRoutes.SINGLE_REQUESTS(job.request.id)}
                           textAlign={TextAlign.CENTER} textDecoration={TextDecoration.UNDERLINE}>
                        {"Request" + " # " + job.request.id}
                    </Text>
                    :
                    null}
            </Grid>
            <Grid item xs={12} margin={"2rem 1rem 1rem 1rem"}>
                <Divider/>
            </Grid>
        </Grid>

        {job.confirmed ?
            <Grid margin={"0 1rem 0 0"}>
                <SignOffExpansionPanel confirmedUser={job.confirmedUser} confirmedTimestamp={job.confirmedTimestamp} sidePanel/>
                <Grid item xs={12} margin={"1rem 0 1rem 1rem"}>
                    <Divider/>
                </Grid>
            </Grid>
            :
            null}
        {job.client.storeSignOffEnabled && job.awaitingClientSignOff ?
            <Grid margin={"0 1rem 0 0"}>
                <AwaitingSignOffExpansionPanel sidePanel/>
                <Grid item xs={12} margin={"1rem 0 1rem 1rem"}>
                    <Divider/>
                </Grid>
            </Grid>
            :
            null}
        {job.hasPendingChangeOrderRequest ?
            <Grid margin={"0 2rem 0 0"}>
                <Grid margin={"0 0 0 1rem"} container item>
                    <Text link onClick={() => {setChangeOrderModal(true)}} color={TextColor.SECONDARY} textDecoration={TextDecoration.UNDERLINE}>
                        View Open Change Order
                    </Text>
                </Grid>
                <Grid item xs={12} margin={"1rem 0 2rem 1rem"}>
                    <Divider/>
                </Grid>
            </Grid>
            :
            null}
        {job.hasOpenConcern ?
            <Grid margin={"0 1rem 0 0"}>
                <OpenConcernExpansionPanel concern={job.openConcern} sidePanel/>
                <Grid item xs={12} margin={"1rem 0 1rem 1rem"}>
                    <Divider/>
                </Grid>
            </Grid>
            :
            null}
        {job.resolvedConcerns.length > 0 ?
            <Grid margin={"0 1rem 0 0"}>
                <ResolvedConcernExpansionPanel concerns={job.resolvedConcerns} sidePanel/>
                <Grid item xs={12} margin={"1rem 0 1rem 1rem"}>
                    <Divider/>
                </Grid>
            </Grid>
            :
            null}
        <Grid container xs={12} margin={"0 0 2rem 0"}>
            <Grid margin={"0 0 0 1rem"} container item xs={2}>
                <Text color={TextColor.TEXT_SECONDARY}>
                    Type
                </Text>
            </Grid>
            <Grid margin={"0 0 0 1rem"} container item xs={8}>
                <Text>
                    {job.type}
                </Text>
            </Grid>
        </Grid>
        <Grid container xs={12} margin={"0 0 2rem 0"}>
            <Grid margin={"0 0 0 1rem"} container item xs={2}>
                <Text color={TextColor.TEXT_SECONDARY}>
                    Vendor
                </Text>
            </Grid>
            <Grid margin={"0 0 0 1rem"} container item xs={8}>
                <Text>
                    {job.vendor.officialName}
                </Text>
            </Grid>
        </Grid>
        <Grid container xs={12} margin={"0 0 2rem 0"}>
            <Grid margin={"0 0 0 1rem"} container item xs={2}>
                <Text color={TextColor.TEXT_SECONDARY}>
                    Address
                </Text>
            </Grid>
            <Grid margin={"0 0 0 1rem"} container item xs={8}>
                <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{job.clientLocation.addressLineOne}</Text>
                {ValueIsSet(job.clientLocation.addressLineTwo) ? <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{job.clientLocation.addressLineTwo}</Text> : null}
                <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>{job.clientLocation.city}, {job.clientLocation.state} {job.clientLocation.zip}</Text>
            </Grid>
        </Grid>
        {!hidePricingForNonAdminUsers &&
            <Grid container xs={12} margin={"0 0 2rem 0"}>
                <Grid margin={"0 0 0 1rem"} container item xs={2}>
                    <Text color={TextColor.TEXT_SECONDARY}>
                        Price
                    </Text>
                </Grid>
                <Grid margin={"0 0 0 1rem"} container item xs={8}>
                    <Text link onClick={() => {setPriceModal(true)}} color={TextColor.SECONDARY} textDecoration={TextDecoration.UNDERLINE}>
                        {"$" + job.totalRetailPrice}
                    </Text>
                </Grid>
            </Grid>
        }
        <Grid item xs={12} margin={"2rem 1rem 1rem 1rem"}>
            <Divider/>
        </Grid>
        <Grid container xs={12} margin={"0 0 2rem 0"}>
            <Grid margin={"0 0 0 1rem"} container item xs={12}>
                <Text color={TextColor.TEXT_SECONDARY}>
                    Description
                </Text>
            </Grid>
            <Grid margin={"0 0 0 1rem"} container item xs={12}>
                {ValueIsSet(job.request) ?
                    <Text> {job.request.description} </Text>
                    :
                    null}
            </Grid>
        </Grid>
        {job.attachments.length > 0 ?
        <Grid>
            <Grid item xs={12} margin={"2rem 1rem 1rem 1rem"}>
                <Divider/>
            </Grid>
            <Grid container xs={12} margin={"0 0 2rem 0"}>
                <Grid margin={"0 0 0 1rem"} container item xs={12}>
                    <Text color={TextColor.TEXT_SECONDARY}>
                        Attachments
                    </Text>
                </Grid>
                <Grid item xs={10} margin={'0 1rem 0 1rem'}>
                        <CarouselInlineWithLightBox
                            attachments={job.attachments}
                        />
                </Grid>
            </Grid>
        </Grid>
        :
        null}
        {changeOrderModal ?
            <ClientChangeOrderDetailsDialog
                changeOrderRequestId={changeOrderId}
                onClose={() => setChangeOrderModal(false)}
                open={changeOrderModal}
                reloadPage={reload}
                showWholesalePrice={false}
            /> : null
        }
        <Modal
            open={priceModal}
            onClose={()=>{setPriceModal(false)}}
        >
        <ActionableModalTemplate
            title={ <Grid>
                        <Grid container item justify={JustifyContent.FLEX_END}>
                            <IconButton iconName={'Close'} onClick={()=> {setPriceModal(false)}} size="medium" />
                        </Grid>
                        <Text variant={TextVariant.H6}> Terms and Conditions</Text>
                        <Text variant={TextVariant.SUBTITLE_1}> All pricing includes labor and material.</Text>
                        <Grid margin={'2rem 0 0.5rem 0'}>
                            <Text variant={TextVariant.H6}> Pricing Breakdown</Text>
                        </Grid>
                    </Grid>}
            buttons={[]}
            body={<PriceBreakdown
                    loading={loading}
                    items={job.obligationItems}
                    activityTotal={job.totalRetailPrice}/>}
            onCancel={()=> {setPriceModal(false)}}
            />
        </Modal>
    </>;
};

JobDetailsSidePanelView.propTypes = {
    jobId: PropTypes.number,
    showJobDetails: PropTypes.bool,
    setShowJobDetails: PropTypes.func
};

export default WithContext(JobDetailsSidePanelView);