import React, {Component} from "react";
import PropTypes from "prop-types";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {GroupBy} from "worksmith/helpers/GenericHelpers";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import LocationContractDetailsView from "./view/LocationContractDetailsView";

const graphQLService = new GraphQLServiceClass();

class LocationContractDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            locationRateCards: []
        }
    }

    componentDidMount() {
        this.loadLocationContract();
    }

    loadLocationContract = () => {
        let {locationId} = this.props;

        graphQLService.findAll(
            {"clientLocationIds": [locationId], "priorities": [1]},
            GraphQLObjectType.RATE_CARD,
            rateCardObject
        ).then((data) => {
            this.setState({locationRateCards: data, loading: false});
        }).catch((error) => {
            console.log(error);
        });
    };

    sortRateCardsByServiceLine = (locationRateCards) => {
            let locationRateCardsByServiceLine =
                GroupBy(locationRateCards,function(rateCard) {return rateCard.serviceLine.name;});
            let toReturn = {};
            Object.keys(locationRateCardsByServiceLine).sort().forEach(key => {
                toReturn[key] = locationRateCardsByServiceLine[key]
            });
            return toReturn;
    };



    render() {
        let {locationRateCards, loading} = this.state;
        let {locationId} = this.props;
        let locationRateCardsByServiceLine = locationRateCards !== [] ?
            this.sortRateCardsByServiceLine(locationRateCards) : {};

        return <LocationContractDetailsView
                locationId={locationId}
                loading={loading}
                locationRateCardsByServiceLine={locationRateCardsByServiceLine}
            />
    }
}

const rateCardObject = `
    id
    startDate
    endDate
    serviceLine {
        id
        name
    }
    rateCardEntries {
        id
        unitRetailPrice
        unitWholesalePrice
        itemName
        contractItem {
            id
            name
        }
        displayValue
        sequencePosition
    }
`;

LocationContractDetails.propTypes = {
    locationId: PropTypes.number.isRequired
};

export default LocationContractDetails;