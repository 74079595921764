import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import Select, {SelectType} from "../../components/Inputs/Select/Select.web";
import Schedule from "../../components/Schedule/Schedule.web";
import Text, {TextVariant} from "../../components/Text/Text.web";

class SelectScheduleModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.openSchedules = [];
        this.schedule = null;

        if(props.initialScheduleHandler
            && props.initialScheduleHandler.openScheduleOptions) {
            this.openSchedules = props.initialScheduleHandler.openScheduleOptions.filter(schedule => !schedule.isExpired);
            if (this.openSchedules.length > 0) {
                this.schedule = this.openSchedules[0];
            }
        } else {
            this.schedule = null;
        }
    }

    componentDidMount() {
        this.props.onChange(this.schedule);
    }

    onScheduleSelect = (schedule) => {
        this.schedule = schedule;
        this.props.onChange(schedule);
    }

    setLoading = (loading) => {
        this.setState({loading});
    }

    render() {
        const {openSchedules, onScheduleSelect} = this;

        let scheduleSelect;
        if (openSchedules) {
            let openScheduleOptions = openSchedules.map(schedule => (
                {
                    value: schedule,
                    label: (
                        <Box mb={3}>
                            <Schedule
                                textVariant={TextVariant.BODY_1}
                                schedule={schedule.schedule}/>
                        </Box>
                    )
                }
            ))

            scheduleSelect = (
                <Select fullWidth
                        initialValue={this.schedule}
                        onChange={onScheduleSelect}
                        options={openScheduleOptions}
                        primary
                        type={SelectType.RADIO_BUTTONS}/>
            )
        }

        if (openSchedules.length > 0) {
           return scheduleSelect
        } else {
            return (
                <Text>
                    All availability has expired.
                </Text>
            )
        }

    }
}

SelectScheduleModal.propTypes = {
    initialScheduleHandler: PropTypes.shape({
        id: PropTypes.number,
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        }))
    }).isRequired,
    onChange: PropTypes.func.isRequired
};

export default SelectScheduleModal;