import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RequestState from "worksmith/composite-components/RequestState/RequestState";
import RequestSummary from "worksmith/composite-components/RequestSummary/RequestSummary";
import Divider, {DividerOrientation} from "worksmith/components/Divider/Divider.web";
import Box from "@mui/material/Box";
import Grid from "worksmith/components/Grid/Grid.web";
import {Display, FlexDirection, JustifyContent} from "worksmith/enums/CSSEnums";
import EditRequestForm from "worksmith/composite-components/EditRequest/EditRequestForm";
import RequestExpandableSections
    from "worksmith/composite-components/RequestExpandableSections/RequestExpandableSections";
import FullWidthPageContainer from "../../FullWidthPageContainer";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import {useDeviceSize} from 'worksmith/helpers/StylingHelpers';

const NoScrollBarBox = styled(FullWidthPageContainer)`
    width: 100%;
    display: ${Display.FLEX};
    
    ::-webkit-scrollbar {
        display: none;
    }
`;

const ScrollableBox = styled(Box)`
    height: 1100px;
    overflow-y: scroll;
    
    ::-webkit-scrollbar{
        display: inherit;
    }
`;

const EditRequestModalBox = styled(Box)`
    padding: 16px 16px 0 16px;
    overflow-y: scroll;
    
    ::-webkit-scrollbar{
        width: inherit;
    }
`;

const SingleRequestPageView = (props) => {
    const {
        clientId,
        clientSettings,
        fullRequestLoading,
        partialRequestLoading,
        reloadFullRequest,
        reloadPartialRequest,
        request,
        startLoad,
        user} = props;
    const [showEditModal, setShowEditModal] = useState(false);
    const [isMobile] = useDeviceSize();
    const chatRef = useRef();

    let onRequestEdited = () => {
        setShowEditModal(false);
        reloadFullRequest();
    };

    let EditRequestModal = (
        <AlertDialog
            body={
                <EditRequestModalBox>
                    <EditRequestForm
                        clientId={clientId}
                        handleSuccess={onRequestEdited}
                        request={request}
                        onCancel={() => setShowEditModal(false)}/>
                </EditRequestModalBox>
            }
            open={showEditModal}/>
    );

    let RequestStateComponent = (
        <RequestState
            chatRef={chatRef}
            clientSettings={clientSettings}
            fullRequestLoading={fullRequestLoading}
            partialRequestLoading={partialRequestLoading}
            reloadPartialRequest={reloadPartialRequest}
            reloadFullRequest={reloadFullRequest}
            request={request}
            loading={fullRequestLoading}
            startLoad={startLoad}
            user={user}/>
    )

    let RequestExpandableSectionsComponent = (
        <RequestExpandableSections
            chatRef={chatRef}
            loading={partialRequestLoading || fullRequestLoading}
            reloadRequest={reloadPartialRequest}
            request={request}
            startLoad={startLoad}
            user={user}/>
    )

    let RequestSummaryComponent = (
        <RequestSummary
            editRequest={() => setShowEditModal(true)}
            fullRequestLoading={fullRequestLoading}
            partialRequestLoading={partialRequestLoading}
            onRequestCancel={reloadFullRequest}
            request={request}
            user={user}/>
    )

    if (isMobile) {
        return (
            <NoScrollBarBox>
                {EditRequestModal}
                <Grid container direction={FlexDirection.COLUMN} margin={'20px 0 0 0'}>
                    <Grid item>
                        {RequestStateComponent}
                        <Divider/>
                        <Box width={'100%'} mt={5}>
                            {RequestExpandableSectionsComponent}
                        </Box>
                    </Grid>
                    <Grid item>
                        {RequestSummaryComponent}
                    </Grid>
                </Grid>
            </NoScrollBarBox>
        );
    } else {
        return (
            <NoScrollBarBox>
                {EditRequestModal}
                <Box display={Display.FLEX} justifyContent={JustifyContent.FLEX_END}>
                    <ScrollableBox width={'60%'}>
                        <Box pt={7} pr={6}>
                            {RequestStateComponent}
                            <Divider/>
                            <Box width={'100%'} mt={5}>
                                {RequestExpandableSectionsComponent}
                            </Box>
                        </Box>
                    </ScrollableBox>
                    <Divider orientation={DividerOrientation.VERTICAL} flexItem/>
                    <Box width={'40%'}>
                        {RequestSummaryComponent}
                    </Box>
                </Box>
            </NoScrollBarBox>
        );
    }
};

SingleRequestPageView.propTypes = {
    clientId: PropTypes.number,
    fullRequestLoading: PropTypes.bool,
    partialRequestLoading: PropTypes.bool,
    reloadFullRequest: PropTypes.func.isRequired,
    reloadPartialRequest: PropTypes.func.isRequired,
    request: PropTypes.object,
    startLoad: PropTypes.func,
    user: PropTypes.object
};

export default SingleRequestPageView;