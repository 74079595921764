//All web session variables should be handled here
//Keys should be stored in the sessionStorageKeys enum
//getters and setters for accessing session variables

//region KEYS
const sessionStorageKeys = Object.freeze({
   SHOW_REDIRECT_TO_APP_MODAL:  'showRedirectToAppModal',
});
//endregion KEYS

//region SESSION METHODS
const storeSessionVariable = (variableName, variableValue) => {
    sessionStorage.setItem(variableName, variableValue);
};

const getSessionVariable = (variableName) => {
    return sessionStorage.getItem(variableName);
};
//endregion SESSION METHODS

//region ACCESSORS
export const setShowRedirectToAppModal = (showRedirectToAppModal) => {
    storeSessionVariable(sessionStorageKeys.SHOW_REDIRECT_TO_APP_MODAL, showRedirectToAppModal);
};

export const showRedirectToAppModal = () => {
    return getSessionVariable(sessionStorageKeys.SHOW_REDIRECT_TO_APP_MODAL);
};
//endregion ACCESSORS