import React, {Component} from 'react';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import Loader from "../../components/Loader";
import ChangePasswordForm from '../../components/forms/ChangePasswordForm';
import moment from 'moment';
import Button from "../../components/Button";
import notification from 'antd/lib/notification';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const t = require('tcomb-form');

const userService = new UserService();
const authTokenManager = new AuthTokenManager();

class ClientAccountPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            user: {},
            loading: true,
            changePasswordValue: {},

            requestCreated: null,
            emergencyRequest: null,
            requestStatusChanged: null,
            requestCommentAdded: null,
            requestNeedsInput: null,
            obligationCompletedOneTime: null,
            obligationCompletedRecurring: null,
            itemSubmitted: null,
            ticketStatusChanged: null,
            ticketCommentAdded: null,
            invoiceReminders: null,
            dailyDigest: null
        };

        this.updateNotificationsPreferences = this.updateNotificationsPreferences.bind(this);
        this.onChangechangePassword = this.onChangechangePassword.bind(this);
        this.submitchangePassword   = this.submitchangePassword.bind(this);
    }

    componentDidMount(){
        let _this = this;
        userService.findOne(authTokenManager.getUserId()).then(function(data){
            let user = data;
            let { clientNotifications } = user;
            let {
                requestCreated,
                emergencyRequest,
                requestStatusChanged,
                requestCommentAdded,
                requestNeedsInput,
                obligationCompletedOneTime,
                obligationCompletedRecurring,
                itemSubmitted,
                ticketStatusChanged,
                ticketCommentAdded,
                invoiceReminders,
                dailyDigest
            } = clientNotifications;

            _this.setState({
                user,
                requestCreated,
                emergencyRequest,
                requestStatusChanged,
                requestCommentAdded,
                requestNeedsInput,
                obligationCompletedOneTime,
                obligationCompletedRecurring,
                itemSubmitted,
                ticketStatusChanged,
                ticketCommentAdded,
                invoiceReminders,
                dailyDigest,
                loading: false
            });
        })
    }

    onChangechangePassword(changePasswordValue){
        let _this = this;

        if(changePasswordValue){
            _this.setState({ changePasswordValue })
        }
    }

    submitchangePassword(){
        let {changePasswordValue, user} = this.state;
        let _this = this;
        let submitData = {
            user: {
                id: user.id,
                password: changePasswordValue.password,
            },
            confirmationPassword: changePasswordValue.confirmationPassword
        };
        
        userService.changePassword(submitData).then(function(data){
            notification['success']({
                message: 'Password reset!'
            });
            _this.setState({ changePasswordValue: {} })
        })
    }

    updateNotificationsPreferences(){
        let updatedUser = {
            clientNotifications: {
                requestCreated: this.state.requestCreated,
                emergencyRequest: this.state.emergencyRequest,
                requestStatusChanged: this.state.requestStatusChanged,
                requestCommentAdded: this.state.requestCommentAdded,
                requestNeedsInput: this.state.requestNeedsInput,
                obligationCompletedOneTime: this.state.obligationCompletedOneTime,
                obligationCompletedRecurring: this.state.obligationCompletedRecurring,
                itemSubmitted: this.state.itemSubmitted,
                ticketStatusChanged: this.state.ticketStatusChanged,
                ticketCommentAdded: this.state.ticketCommentAdded,
                invoiceReminders: this.state.invoiceReminders,
                dailyDigest: this.state.dailyDigest
            }
        };

        let submitData = Object.assign(this.state.user, updatedUser);

        userService.update(submitData).then(function(data){
            notification['success']({
                message: 'Settings Updated!',
            });
        })
    }

    render(){
        let {
            user,
            loading,
            requestCreated,
            emergencyRequest,
            requestStatusChanged,
            requestCommentAdded,
            requestNeedsInput,
            obligationCompletedOneTime,
            obligationCompletedRecurring,
            itemSubmitted,
            ticketStatusChanged,
            ticketCommentAdded,
            invoiceReminders,
            dailyDigest
        } = this.state;
        let {isSelfService} = this.props.client;

        let isAccountant;
        if(ValueIsSet(user.clientRoles)){
            isAccountant = user.clientRoles[0].isAccountant;
        }
        else
            isAccountant = false;

        if(!loading){
            return(
                <div>
                    <div className="ws-section">
                        <h1 className='page-header'>Your Account</h1>
                    </div>

                    <section className='ws-section'>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="subhead">{user.firstName + ' ' + user.lastName}</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <p className="section-accent">Username</p>
                                <p>{user.username}</p>

                                <p className="section-accent">Name</p>
                                <p>{`${user.firstName} ${user.lastName}`}</p>

                                <p className="section-accent">Email</p>
                                <p>{user.email}</p>
                            </div>

                            <div className="col-md-6">


                                <p className="section-accent">Role(s)</p>
                                {user.clientRoles ? user.clientRoles.map(function(role, idx){
                                    return(
                                        <p key={idx}>{role.isAccountant ? 'Accountant' : role.role} for { role.client.name }</p>
                                    )
                                }): user.clientLocationRoles.map(function(role, idx){
                                    return (
                                        <p key={idx}>{role.role}</p>
                                    )
                                })}
                                {!isSelfService ?
                                    <>
                                        <p className="section-accent">Account Created</p>
                                        <p>{moment(user.createdTimestamp).format('MM/DD/YYYY')}</p>
                                    </>
                                    : null}
                            </div>
                        </div>
                    </section>

                    <div className={isAccountant ?
                            ""
                            :
                            "d-flex flex-row flex-margin-row justify-content-between"}>

                        {!isAccountant ?
                        <section className="ws-section flex-1">
                            <form>
                                <table className="table table-bordered ws-table">
                                    <thead>
                                    <tr>
                                        <th>Notifications Preferences</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {!isSelfService ?
                                        <>
                                        <tr>
                                            <td>
                                                <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                    <Toggle
                                                        defaultChecked={requestCreated}
                                                        name={'requestCreated'}
                                                        onChange={() => this.setState({ requestCreated: !requestCreated} )} />
                                                    <span className={'pad-left-sm'}>Email me when there is a new request created</span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                    <Toggle
                                                        defaultChecked={emergencyRequest}
                                                        name={'emergencyRequest'}
                                                        onChange={() => this.setState({ emergencyRequest: !emergencyRequest} )} />
                                                    <span className={'pad-left-sm'}>Email me regarding any action related to emergency requests</span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                <Toggle
                                                    defaultChecked={requestStatusChanged}
                                                    onChange={() => this.setState({ requestStatusChanged: !requestStatusChanged} )} />
                                                <span className={'pad-left-sm'}>Email me when a request's status has changed</span>
                                            </label>
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                    <Toggle
                                                        defaultChecked={requestCommentAdded}
                                                        onChange={() => this.setState({ requestCommentAdded: !requestCommentAdded} )} />
                                                    <span className={'pad-left-sm'}>Email me when a comment has been added to a request</span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                    <Toggle
                                                        defaultChecked={requestNeedsInput}
                                                        onChange={() => this.setState({ requestNeedsInput: !requestNeedsInput} )} />
                                                    <span className={'pad-left-sm'}>Email me when a request needs my input</span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                    <Toggle
                                                        defaultChecked={obligationCompletedOneTime}
                                                        onChange={() => this.setState({ obligationCompletedOneTime: !obligationCompletedOneTime} )} />
                                                    <span className={'pad-left-sm'}>Email me when a one time service has been completed</span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                    <Toggle
                                                        defaultChecked={obligationCompletedRecurring}
                                                        onChange={() => this.setState({ obligationCompletedRecurring: !obligationCompletedRecurring} )} />
                                                    <span className={'pad-left-sm'}>Email me when a recurring service has been completed</span>
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                    <Toggle
                                                        defaultChecked={itemSubmitted}
                                                        onChange={() => this.setState({ itemSubmitted: !itemSubmitted} )} />
                                                    <span className={'pad-left-sm'}>Email me when a bid or walkthrough request has been submitted</span>
                                                </label>
                                            </td>
                                        </tr>
                                    </>
                                    : null}
                                    <tr>
                                        <td>
                                            <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                <Toggle
                                                    defaultChecked={ticketStatusChanged}
                                                    onChange={() => this.setState({ ticketStatusChanged: !ticketStatusChanged} )} />
                                                <span className={'pad-left-sm'}>Email me every time the status of any of my tickets changes</span>
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                <Toggle
                                                    defaultChecked={ticketCommentAdded}
                                                    onChange={() => this.setState({ ticketCommentAdded: !ticketCommentAdded} )} />
                                                <span className={'pad-left-sm'}>Email me when a comment has been added to a service ticket</span>
                                            </label>
                                        </td>
                                    </tr>
                                    {!isSelfService ?
                                        <>
                                            {
                                                user.clientRoles && user.clientRoles.length > 0 ? <tr>
                                                        <td>
                                                            <label
                                                                className={'d-flex flex-row justify-content-start align-items-center'}>
                                                                <Toggle
                                                                    defaultChecked={invoiceReminders}
                                                                    onChange={() => this.setState({invoiceReminders: !invoiceReminders})}/>
                                                                <span className={'pad-left-sm'}>Email me accounting reminders</span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    :
                                                    null
                                            }
                                            <tr>
                                                <td>
                                                    <label className={'d-flex flex-row justify-content-start align-items-center'}>
                                                        <Toggle
                                                            defaultChecked={dailyDigest}
                                                            onChange={() => this.setState({ dailyDigest: !dailyDigest} )} />
                                                        <span className={'pad-left-sm'}>Email me a daily digest</span>
                                                    </label>
                                                </td>
                                            </tr>
                                        </>
                                        : null}
                                    </tbody>
                                </table>

                                <div className="text-right">
                                    <Button type="success" message='Update Preferences' onPress={() => this.updateNotificationsPreferences()} />
                                </div>
                            </form>
                        </section>
                        :
                        null}
                        {user.isUsingSSO ? null :
                            <ChangePasswordForm user={user} maxWidth={isAccountant ? '18em' : null}/>
                        }
                    </div>
                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

export default ClientAccountPage;