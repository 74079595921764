export const Branding = {
    logo: require('./assets/logo-mark-white.png'),
    logoWithText: require('./assets/logo-with-text.svg'),
    logoWithTextHeight: '1.5em'
};

export const AppStore = {
    image: require('./assets/mobile/App_Store_Badge_US_Black.png')
};

export const GooglePlayStore = {
    image: require('./assets/mobile/Google_Play_Badge_US.png')
};