import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WalkthroughDetailsView from "./WalkthroughDetailsView";
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import WalkthroughService from "../../services/api/WalkthroughService";
import ScheduleHandlerService from "../../services/api/ScheduleHandlerService";
import ChangeRequestType from "../../enums/api/cr/ChangeRequestType";
import ObligationService from "../../services/api/ObligationService";
import ChangeRequestService from "../../services/api/ChangeRequestService";
import BidService from "worksmith/services/api/BidService";
import {ValueIsSet} from "../../../Helpers";

const graphQLService = new GraphQLServiceClass();
const changeRequestService = new ChangeRequestService();
const obligationService = new ObligationService();
const scheduleHandlerService = new ScheduleHandlerService();
const walkthroughService = new WalkthroughService();
const bidService = new BidService();

class WalkthroughDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            walkthrough: {},
            canAcceptWalkthrough: true,
            loading: true,
        };
    }

    componentDidMount = () => {
        const {walkthroughId, isAdmin} = this.props;

        graphQLService.findOneById(
            walkthroughId,
            GraphQLObjectType.WALKTHROUGH,
            WalkthroughDetailsGraphQLString
        ).then((data) => {
            this.setState({loading: false, walkthrough: data});
        });

        if (!isAdmin) {
            walkthroughService.canBeAcceptedByRequester(walkthroughId).then((data) => {
                this.setState({canAcceptWalkthrough: data});
                }
            )
        }
    }

    acceptWalkthrough = (schedules) => {
        const {walkthroughId, onWalkthroughAction, startLoad} = this.props;
        const {walkthrough} = this.state;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.accept({id: walkthroughId, concurrentWithCounteringSchedule: ValueIsSet(schedules)})
            .then((data) => {
               if (schedules) {
                   scheduleHandlerService.makeCounterOffer({scheduleHandlerId: walkthrough.scheduleHandler.id, schedules: schedules, concurrentWithProposalAcceptance: true})
                       .then(() => {
                           onWalkthroughAction();
                           this.setState({loading: false});

                       })
               } else {
                   onWalkthroughAction();
                   this.setState({loading: false});
               }
            })
    }

    scheduleWalkthrough = (scheduleId) => {
        const {walkthroughId, onWalkthroughAction, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        walkthroughService.acceptSchedule(walkthroughId, scheduleId)
            .then((data) => {
                onWalkthroughAction();
                this.setState({loading: false});
            })
    }

    makeScheduleCounterOffer = (schedules) => {
        const {onWalkthroughAction, startLoad} = this.props;
        const {walkthrough} = this.state;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        let scheduleHandlerId = walkthrough.activeObligation && walkthrough.activeObligation.pendingChangeRequest ?
            walkthrough.activeObligation.pendingChangeRequest.scheduleHandler.id
            :
            walkthrough.scheduleHandler.id;

        scheduleHandlerService.makeCounterOffer({scheduleHandlerId: scheduleHandlerId, schedules: schedules})
            .then(() => {
                onWalkthroughAction();
                this.setState({loading: false});

            })
    }

    acceptAndSchedule = (schedule) => {
        const {walkthroughId, onWalkthroughAction, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        walkthroughService.acceptAndSchedule(walkthroughId, schedule.id)
            .then(() => {
                onWalkthroughAction();
                this.setState({loading: false});
            })
    }

    createChangeRequest = (schedules) => {
        const {onWalkthroughAction, startLoad} = this.props;
        const {walkthrough} = this.state;

        let changeRequest = {
            scheduleHandler: {
                scheduleOptions: schedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        if (walkthrough.activeObligation) {
            if (startLoad) {
                startLoad();
            } else {
                this.setState({loading: true});
            }

            changeRequest.obligation = {
                id: walkthrough.activeObligation.id
            };
            obligationService.addChangeRequest(walkthrough.activeObligation.id, changeRequest, false, false, false)
                .then(() => onWalkthroughAction())

        }

    }

    cancelChangeRequest = () => {
        const {walkthrough} = this.state;
        const {onWalkthroughAction, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        if(walkthrough.activeObligation && walkthrough.activeObligation.pendingChangeRequest) {
            changeRequestService.accept(walkthrough.activeObligation.pendingChangeRequest.id)
                .then(() => onWalkthroughAction())
        }
    };

    acceptChangeRequest = () => {
        const {walkthrough} = this.state;
        const {onWalkthroughAction, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        if(walkthrough.activeObligation && walkthrough.activeObligation.pendingChangeRequest) {
            changeRequestService.accept(walkthrough.activeObligation.pendingChangeRequest.id)
                .then(() => onWalkthroughAction())
        }
    };

    cancelWalkthroughObligation = (cancellationReason) => {
        const {onWalkthroughAction, startLoad} = this.props;
        const {walkthrough} = this.state;

        let cancellationDetails = {
            cancellationReason
        };

        if (walkthrough.activeObligation) {
            if (startLoad) {
                startLoad();
            } else {
                this.setState({loading: true});
            }

            obligationService.cancelObligation(walkthrough.activeObligation.id, cancellationDetails, false, false, false)
                .then(() => onWalkthroughAction())

        }
    };

    cancelWalkthroughBid = (cancellationReason) => {
        const {walkthroughId, onWalkthroughAction, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        walkthroughService.cancel(walkthroughId, cancellationReason)
            .then(() => {
                onWalkthroughAction();
                this.setState({loading: false});
            });
    };

    requestApproval = () => {
        const {walkthroughId, onWalkthroughAction, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        walkthroughService.requestApproval(walkthroughId)
            .then((data) => {
                onWalkthroughAction();
                this.setState({loading: false});
            })
    }

    decline = (declineReason) => {
        const {walkthroughId, onWalkthroughAction, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        walkthroughService.decline(walkthroughId , false, declineReason)
            .then((data) => {
                onWalkthroughAction();
                this.setState({loading: false});
            })
    }

    render() {
        const {
            acceptWalkthrough,
            acceptAndSchedule,
            acceptChangeRequest,
            cancelChangeRequest,
            cancelWalkthroughObligation,
            cancelWalkthroughBid,
            createChangeRequest,
            decline,
            makeScheduleCounterOffer,
            requestApproval,
            scheduleWalkthrough
            } = this;
        const {walkthrough, canAcceptWalkthrough, loading} = this.state;
        const {isAdmin} = this.props;

        return (
            <WalkthroughDetailsView
                acceptWalkthrough={acceptWalkthrough}
                acceptAndSchedule={acceptAndSchedule}
                acceptChangeRequest={acceptChangeRequest}
                activeObligation={walkthrough.activeObligation}
                additionalTerms={walkthrough.additionalTerms}
                canAcceptWalkthrough={canAcceptWalkthrough}
                cancelChangeRequest={cancelChangeRequest}
                cancelWalkthroughObligation={cancelWalkthroughObligation}
                cancelWalkthroughBid={cancelWalkthroughBid}
                createChangeRequest={createChangeRequest}
                createdTimestamp={walkthrough.createdTimestamp}
                decline={decline}
                duration={walkthrough.duration}
                isCompleted={walkthrough.isCompleted}
                isAdmin={isAdmin}
                loading={loading}
                makeScheduleCounterOffer={makeScheduleCounterOffer}
                requestApproval={requestApproval}
                scheduleHandler={walkthrough.scheduleHandler}
                scheduleWalkthrough={scheduleWalkthrough}
                status={walkthrough.status}
                totalRetailPrice={walkthrough.totalRetailPrice}
                vendorLocation={walkthrough.opportunity ? walkthrough.opportunity.vendorLocation : null}
                walkthroughItems={walkthrough.walkthroughItems}
                walkthroughSubmittedActivity={walkthrough.walkthroughSubmittedActivity}
            />
        )
    }
}

WalkthroughDetails.propTypes = {
    walkthroughId: PropTypes.number,
    isAdmin: PropTypes.bool,
    onWalkthroughAction: PropTypes.func,
    startLoad: PropTypes.func,
};

const WalkthroughDetailsGraphQLString = `
    additionalTerms
    activeObligation {
        completedObligationActivity {
                aggregatorResource {
                    trustedTechnician
            }
                user {
                    displayName
                }
            }
        totalRetailPrice
        obligationItems {
            lineItemType {
                name
            }
            quantity
            title
            unitRetailPrice
            totalRetailPrice
        }
        id
        requestId
        status
        schedule {
            arrivalEndTime
            arrivalStartTime
            date
            dayOfMonth
            daysOfWeek
            duration
            recurrenceType
            repeatEvery
            returnDate
        }
        pendingChangeRequest {
            id
            creatorType
            scheduleHandler {
                id
                openScheduleOptions {
                    id
                    isExpired
                    status
                    schedule {
                        arrivalEndTime
                        arrivalStartTime
                        date
                        dayOfMonth
                        daysOfWeek
                        duration
                        recurrenceType
                        repeatEvery
                        returnDate
                    }
                }
            }
        }
        obligationAttachments {
            name
            singleUseUrl
        }
        leadTechnician
        updatedArrivalTime
        completionDate
        summaryOfWorkCompleted
    }
    createdTimestamp
    walkthroughSubmittedActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    duration
    isCompleted
    opportunity {
        vendorLocation {
            vendor {
                officialName
            }
        }
    }
    scheduleHandler {
        id
        openScheduleOptions {
            id
            isExpired
            status
            schedule {
                arrivalEndTime
                arrivalStartTime
                date
                dayOfMonth
                daysOfWeek
                duration
                recurrenceType
                repeatEvery
                returnDate
            }
        }
    }
    status
    totalRetailPrice
    walkthroughItems {
        lineItemType {
            name
        }
        quantity 
        name
        unitRetailPrice
    },
`;

export default WalkthroughDetails;