import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import Currency from "worksmith/components/Currency/Currency";

export const SinglePriceOverviewVariant = Object.freeze({
   AVAILABLE:   'Available Credits',
   OUTSTANDING: 'Outstanding Invoices',
   PAST_DUE:    'Past Due Invoices'
});

const Title = styled.div`
    margin-bottom: 1em;
`;

const SinglePriceOverview = (props) => {
    const {amount, loading, variant} = props;

    let textColor = TextColor.INHERIT;

    switch(variant) {
        case SinglePriceOverviewVariant.AVAILABLE:
            textColor = TextColor.SECONDARY;
            break;
        case SinglePriceOverviewVariant.OUTSTANDING:
            textColor = TextColor.PRIMARY;
            break;
        case SinglePriceOverviewVariant.PAST_DUE:
            textColor = TextColor.ERROR;
            break;
    }

    return (
        <Fragment>
            <Title>
                <Text renderSkeleton={loading} color={textColor} variant={TextVariant.H5}>
                    {variant}
                </Text>
            </Title>
            <Text renderSkeleton={loading} color={textColor} variant={TextVariant.H4}>
                <Currency amount={amount}/>
            </Text>
        </Fragment>
    )
};

SinglePriceOverview.propTypes = {
    amount:  PropTypes.number,
    loading: PropTypes.bool.isRequired,
    variant: CustomPropTypes.enum(SinglePriceOverviewVariant)
};

SinglePriceOverview.defaultProps = {
    amount: 0
};

export default SinglePriceOverview;