import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import {
    AlignItems,
    BorderStyle,
    BoxSizing,
    Display,
    FlexDirection,
    JustifyContent,
    ListStyleType,
    Position,
    TextAlign
} from "../../CSSEnums";
import {Color, LoggedOutRoutes} from "../../Enums";
import {Link} from "react-router-dom";

const LogInBlock = styled.section`
    display: ${Display.BLOCK};
    box-sizing: ${BoxSizing.INHERIT};
    position: ${Position.RELATIVE};\
    
    background-color: ${Color.WHITE};
    border: 1px ${BorderStyle.SOLID} ${Color.OFF_WHITE};
  
    margin: 0 auto;
    top: 15rem;
  
    width: 40%;
    min-width: 800px;
`;

const FlexDisplay = styled.div`
    display: ${Display.FLEX};
`;

const Row = styled(FlexDisplay)`
    flex-direction: ${FlexDirection.ROW};
`;

const FlexDiv = styled.div`
    flex: 1;
    text-align: ${TextAlign.CENTER};
`;

const SquareLoginPageView = (props) => {
    let {} = props;

    return (
        <LogInBlock>
            <Row>
                <FlexDiv>
                    <img src={"https://cdn.worksmith.com/logo.png"} style={{maxHeight: '250px'}} alt={"Worksmith Logo"}/>
                </FlexDiv>

                <FlexDiv style={{paddingTop: '50px'}}>
                    <img src={"https://cdn.worksmith.com/square/square-logo.png"} style={{maxHeight: '150px'}} alt={"Square Logo"}/>
                </FlexDiv>
            </Row>
            <FlexDisplay style={{alignItems: AlignItems.CENTER, flexDirection: FlexDirection.COLUMN, marginTop: '40px'}}>
                <p style={{fontWeight: 500, fontSize: '18px'}}>
                    Use Worksmith to handle all facilities maintenance issues:
                </p>
                <ul style={{fontSize: '18px', listStyleType: ListStyleType.CIRCLE}}>
                    <li>Automated work order management</li>
                    <li>Sourcing and competitive bids</li>
                    <li>Consolidated invoicing and vendor payment</li>
                </ul>
            </FlexDisplay>
            <Row style={{justifyContent: JustifyContent.CENTER, marginTop: '40px'}}>
                <div>
                    <Link to={LoggedOutRoutes.SQUARE_AUTH} className={'btn ws-btn-primary btn-block'} style={{width: '768px'}}>
                        Sign in with Square
                    </Link>
                </div>
            </Row>
            <Row style={{justifyContent: JustifyContent.CENTER, margin: '20px 0'}}>
                <div>
                    If you are logging in as a vendor, please click&nbsp;
                    <a href={'https://vendors.worksmith.com'}>here</a>
                </div>
            </Row>
        </LogInBlock>
    )
};

SquareLoginPageView.propTypes = {
    
};

export default SquareLoginPageView;