import React from 'react';
import {Link} from 'react-router-dom';
import MaterialButton from '@mui/material/Button';
import PropTypes from 'prop-types';

import CircularProgress from 'worksmith/components/Loader/CircularProgress';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {Debounce, ValueIsSet} from 'worksmith/helpers/GenericHelpers';
import Grid from "worksmith/components/Grid/Grid";
import LinearProgress, {LinearProgressVariant} from "worksmith/components/LinearProgress/LinearProgress";
import {PickColor} from "worksmith/helpers/ThemeHelpers";
import {withTheme} from '../Theme/ThemeProvider';
import {FlexWrap} from "worksmith/enums/CSSEnums";

export const ButtonSize = Object.freeze({
    TINY: 'tiny',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
});

export const ButtonVariant = Object.freeze({
    TEXT: 'text',
    OUTLINED: 'outlined',
    CONTAINED: 'contained'
});

export const ButtonType = Object.freeze({
    BUTTON: 'button',
    SUBMIT: 'submit'
});

const Button = React.forwardRef((props, ref) => {
    let {
        component,
        debounce,
        disabled,
        disableBoxShadow,
        disableRipple,
        endIcon,
        error,
        fullWidth,
        href,
        isSubmitting,
        linearProgressBar,
        newTab,
        onClickIgnoreDebounce,
        onMouseLeave,
        onMouseOver,
        primary,
        routerLink,
        size,
        secondary,
        startIcon,
        testId,
        type,
        variant
    } = props;

    let onClick = (event) => {
        event.stopPropagation();
        if (ValueIsSet(props.onClick)) {
            props.onClick(event);
        }
        if (ValueIsSet(routerLink)) {
            window.location = routerLink;
        }
    };

    if (debounce)
        onClick = Debounce(onClick, 400, onClickIgnoreDebounce);

    let customStyle = {};
    if(size === ButtonSize.TINY){
        customStyle.minWidth = '10px';
        customStyle.width = '10px';
        customStyle.height = '20px';
    }
    if(disableBoxShadow){
        customStyle.boxShadow = null;
    }

    return (
        <MaterialButton
            color={PickColor(primary, secondary, error)}
            className={props.className}
            component={component}
            disabled={disabled || isSubmitting}
            disableRipple={disableRipple}
            endIcon={endIcon}
            fullWidth={fullWidth}
            href={href}
            data-testid={testId}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
            size={size}
            startIcon={startIcon}
            style={customStyle}
            ref={ref}
            rel={newTab ? "noreferrer noopener" : null}
            target={newTab ? "_blank" : null}
            type={type}
            variant={variant}>
            {isSubmitting ?
                linearProgressBar ?
                    <Grid width={'100%'} padding={'10.25px 0'}>
                        <LinearProgress primary variant={LinearProgressVariant.INDETERMINATE} />
                    </Grid>
                    :
                    <CircularProgress size={24}/>
                :
                props.children
            }
        </MaterialButton>
    )
});



Button.propTypes = {
    component: PropTypes.string,                    //this is used to tell what component type it is (default: button). Another option includes span
    debounce: PropTypes.bool,
    disabled: PropTypes.bool,
    disableBoxShadow: PropTypes.bool,
    disableRipple: PropTypes.bool,
    endIcon: PropTypes.node,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    isSubmitting: PropTypes.bool,                   //show a circular loader
    linearProgressBar: PropTypes.bool,              //show a linear progress bar inside the button when isSubmitted is true.
    onClick: PropTypes.func,
    onClickIgnoreDebounce: PropTypes.func,
    onMouseLeave: PropTypes.func,                   //this allows support for wrapping buttons with Tooltips
    onMouseOver: PropTypes.func,                    //this allows support for wrapping buttons with Tooltips
    primary: PropTypes.bool,
    routerLink: PropTypes.string,                   // if path passed in, will allow button to serve as react router link
    size: CustomPropTypes.enum(ButtonSize),
    secondary: PropTypes.bool,
    startIcon: PropTypes.node,
    testId: PropTypes.string,
    type: CustomPropTypes.enum(ButtonType),
    variant: CustomPropTypes.enum(ButtonVariant)
};

Button.defaultProps = {
    disabled: false,
    size: ButtonSize.MEDIUM,
    variant: ButtonVariant.CONTAINED
};

export default withTheme(Button);
