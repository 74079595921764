import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";

import Collapse from "worksmith/components/Collapse/Collapse";
import Grid from "worksmith/components/Grid/Grid";
import {JustifyContent} from "worksmith/enums/CSSEnums";
import Text, {TextColor} from "worksmith/components/Text/Text";
import {ValueIsSet} from "../../Helpers";

const CollapseUserLocations = ({data}) => {

    const [admin, setAdmin] = useState(false);
    const [localUser, setLocalUser] = useState([]);
    const [open, setOpen] = useState(false);
    const [regionalUser, setRegionalUser] = useState([]);


    const sortByRoles = () => {
        let administrator = false;
        let regional = [];
        let local = [];
        if (ValueIsSet(data.clientRoles[0] && data.clientRoles[0].role === "Administrator")) {
            administrator = true;
        } else {
            data.clientLocationRoles.forEach((location) => {
                switch (location.role) {
                    case 'Location Manager':
                        local.push(location);
                        break;
                    case 'Regional Manager':
                        regional.push(location);
                        break;
                }
            })
        }
        setAdmin(administrator);
        setRegionalUser(regional);
        setLocalUser(local);
    };

    const createLocationString = (location) => {
        return `${location.clientLocation.title} (${location.clientLocation.city}, ${location.clientLocation.state})`
    };

    useEffect(() => {
        sortByRoles();
    }, []);


    const CollapseTitle = () => {
        return (
            <Grid container justify={JustifyContent.SPACE_BETWEEN} width={'100%'}>
                <Grid item xs={4}>
                    <Text color={TextColor.TEXT_SECONDARY}>{(ValueIsSet(regionalUser) && regionalUser.length > 0) ? 'Regional User' : 'Local User'}</Text>
                </Grid>
                <Grid item xs={8} routerLink={`locations/${(ValueIsSet(regionalUser) && regionalUser.length > 0) ? regionalUser[0].clientLocation.id : localUser[0].clientLocation.id}/`}>
                    <Text link color={TextColor.SECONDARY}>{ValueIsSet(regionalUser[0]) ? createLocationString(regionalUser[0]) : createLocationString(localUser[0])}</Text>
                </Grid>
            </Grid>
        );
    };

    const UserLocationsLayout = (user, role) => {
        return user.map((user) => {
            return (
                <Grid container justify={JustifyContent.SPACE_BETWEEN} width={'100%'} key={user.id}>
                    <Grid item xs={4}>
                        {role &&
                            <Text color={TextColor.TEXT_SECONDARY}>{role}</Text>
                        }
                    </Grid>
                    <Grid item xs={8} routerLink={`locations/${user.clientLocation.id}/`}>
                        <Text link color={TextColor.SECONDARY}>{createLocationString(user)}</Text>
                    </Grid>
                </Grid>
            );
        });
    };

    return (
        <Grid width={'100%'}>
            {admin &&
                <Grid item xs={12} padding={'14px 0px'}>
                    <Text color={TextColor.TEXT_SECONDARY}>Admin User</Text>
                </Grid>
            }
            { (!admin && (regionalUser.length + localUser.length === 1)) ?
                <Grid container>
                    <Grid item xs={11}>
                        <Grid container justify={JustifyContent.SPACE_BETWEEN} width={'100%'}>
                            <Grid item xs={4} padding={'14px 0px'}>
                                <Text color={TextColor.TEXT_SECONDARY}>{ValueIsSet(regionalUser[0]) ? "Regional User" : "Local User"}</Text>
                            </Grid>
                            <Grid item xs={8} routerLink={`locations/${(ValueIsSet(regionalUser) && regionalUser.length > 0) ? regionalUser[0].clientLocation.id : localUser[0].clientLocation.id}/`}>
                                <Text link color={TextColor.SECONDARY}>{ValueIsSet(regionalUser[0]) ? createLocationString(regionalUser[0]) : createLocationString(localUser[0])}</Text>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
                :
                !admin && (regionalUser.length > 0 || localUser.length > 0) &&
                <Collapse
                    buttonRight expanded={open}
                    justifySpaceBetween
                    label={CollapseTitle(data)}
                    onExpand={() => setOpen(!open)}
                >
                    <>
                        <Grid container>
                            <Grid item xs={11}>
                                {UserLocationsLayout(regionalUser.slice(1))}
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={11}>
                                {regionalUser.length !== 0 ?
                                    UserLocationsLayout(localUser, "Local User")
                                    :
                                    UserLocationsLayout(localUser.slice(1))
                                }
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </>
                </Collapse>
            }
        </Grid>
    );
};

export default CollapseUserLocations;

CollapseUserLocations.prototype = {
    data: PropTypes.shape({
        clientLocation: PropTypes.arrayOf(
            PropTypes.shape({
                clientLocation: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        title: PropTypes.string,
                        state: PropTypes.string,
                        city: PropTypes.string
                    })),
                id: PropTypes.number,
                role: PropTypes.string
            })
        ),
        clientRoles: PropTypes.array,
        email: PropTypes.string,
        firstName: PropTypes.string,
        id: PropTypes.number,
        lastName: PropTypes.string,
        username: PropTypes.string
    })
};