import PropTypes from 'prop-types';
import moment from 'moment';

import PropTypeError from "../../components/PropTypeError";
import {JavascriptType} from "../../Enums";
import {getValuesFromObject} from "../../Helpers";

const CustomPropTypes = Object.freeze({
    enum: function (enumType) {
        return PropTypes.oneOf(getValuesFromObject(enumType).filter(e => typeof e !== JavascriptType.FUNCTION))
    },
    stringOrNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    moment: function(props, propName, componentName) {
        if (!props[propName] instanceof moment)
            return new PropTypeError(propName, componentName, 'Must be of type "moment"')
    }
});

export default CustomPropTypes;