import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CompliancePageView from "./CompliancePageView";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import ServiceLevelAgreementType from "worksmith/enums/api/contract/ServiceLevelAgreementType";
import {SlaComplianceText} from "worksmith/enums/ContractEnums";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {AdminRoutes} from "../../../shared/enums/Enums";
import EditComplianceStatusButton from "worksmith/components/CompliancePage/EditComplianceStatusButton";
import Link from "worksmith/components/Link/Link";

const itemsPerPage = 25;
const graphQLService = new GraphQLServiceClass();

const compliancePageTitles = {
    ON_DEMAND_AND_RECURRING_COMPLETION: "Noncompliant On Demand & Recurring Jobs",
    EMERGENCY_REQUEST_SCHEDULING: "Noncompliant Emergency Jobs",
    REQUEST_ACKNOWLEDGEMENT: 'Noncompliant Worksmith Acknowledgement',
    EMERGENCY_REQUEST_RESPONSE: 'Noncompliant Emergency Jobs'
};

export const ON_DEMAND_AND_RECURRING_COMPLETION_SLA_COMBO = "ON_DEMAND_AND_RECURRING_COMPLETION";

class CompliancePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingTable: true,
            slaIds: [],
            slaPaginatedItems: {},
            totalSLAItemCount: 0,
            nonCompliantSLAItemCount: 0,
            targetComplianceRate: 0,
            currentPageNumber: 0,
            clientName: "",
            timeFrame: 0,
            allSlaItems: []
        };
    }

     componentDidMount() {
        this.loadPage();
    }

    loadPage = () => {
        let {clientId, slaType, startDate, endDate} = this.props;

        let slaTypes = slaType === ON_DEMAND_AND_RECURRING_COMPLETION_SLA_COMBO ?
            [ServiceLevelAgreementType.ON_DEMAND_REQUEST_COMPLETION, ServiceLevelAgreementType.RECURRING_JOB_COMPLETION]
            :
            [slaType];

        let slaPromise = graphQLService.findAll({types: slaTypes, clientId: clientId}, GraphQLObjectType.SERVICE_LEVEL_AGREEMENT, CompliancePage.ServiceLevelAgreement,
            null, null, {slaItemStartDate: startDate, slaItemEndDate: endDate}, '$slaItemStartDate: String, $slaItemEndDate: String');

        let slaConfigPromise = graphQLService.findAll({clientId: clientId},
            GraphQLObjectType.SERVICE_LEVEL_AGREEMENT_CONFIG,
            `complianceRate`);

        Promise.all([slaPromise, slaConfigPromise]).then(([slaData, slaConfigData]) => {
            console.log(slaData);
            this.setState({
                targetComplianceRate: slaConfigData[0].complianceRate,
                slaIds: slaData.map(sla => sla.id),
                clientName: slaData[0].client.nickname,
                timeFrame: slaData[0].timeFrame,
                totalSLAItemCount: slaData.map(sla => sla.SLAItemCount).reduce((a, b) => a + b, 0),
                nonCompliantSLAItemCount: slaData.map(sla => sla.nonCompliantSLAItemCount).reduce((a, b) => a + b, 0)
            },
            this.loadSlaItems);

        });
    };

    loadSlaItems = () => {
        let {slaIds, currentPageNumber} = this.state;
        let {startDate, endDate} = this.props;
        graphQLService.findAll(
            {nonCompliantOrResolvedFalse: true,
                serviceLevelAgreementIds: slaIds,
                actualEvaluationPeriodStart: startDate,
                actualEvaluationPeriodEnd: endDate},
            GraphQLObjectType.SERVICE_LEVEL_AGREEMENT_ITEM,
            CompliancePage.ServiceLevelAgreementItem)
            .then(result => {
                console.log(result);
                let sortedSlaItems = result.map(slai => {

                    slai.vendor = ValueIsSet(slai.obligation) ?
                        slai.obligation.vendor
                        :
                        slai.request.contractOpportunities[0].vendorLocation.vendor;

                    return {...slai, locationDescription: (slai.request.location.state + " - " + slai.request.location.city + "\n" + slai.request.location.title)}
                }).sort((a, b) => { return a.locationDescription.localeCompare(b.locationDescription)});
                this.setState({allSlaItems: sortedSlaItems}, () => this.loadTable(currentPageNumber));
            }
        );
    };

    loadTable = (pageNumber) => { // paginated SLA items can't currently be sorted due to the @DiscriminatorFormula on the ServiceLevelAgreementDataObject
        let {allSlaItems} = this.state;

        let slaPaginatedItems = {};
        slaPaginatedItems.content = allSlaItems.slice(pageNumber * itemsPerPage, (pageNumber+1) * itemsPerPage);
        slaPaginatedItems.totalElements = allSlaItems.length;
        this.setState({slaPaginatedItems, loading: false});
    };

    getJobLinkCell = (row) => {
        if(ValueIsSet(row.obligation) && ValueIsSet(row.obligation.obligationTemplateId))
            return <Link href={AdminRoutes.TASK_MANIFEST({id: row.obligation.id})} target={'_blank'}>Recurring Task #{row.obligation.id}</Link>;
        else if(ValueIsSet(row.request) &&  ValueIsSet(row.request.assignment.id)) {
            return <Link href={AdminRoutes.ASSIGNMENT_PAGE(row.request.assignment.id)} target={'_blank'}>On-Demand Request
                #{row.request.id}</Link>;
        }
        else {
            return null;
        }
    };

    getEditComplianceStatusButtonCell = (row) => {
        return <EditComplianceStatusButton serviceLevelAgreementItemId={row.id} compliant={row.compliant} resolved={row.resolved} onCloseEditComplianceModal={this.onCloseEditComplianceModal}/>;
    };

    handleChangePage = (event, pageNumber) => {
        this.loadTable(pageNumber);
    };

    onCloseEditComplianceModal = (statusUpdated) => {
        if (statusUpdated)
            this.loadPage();
    };

    render() {
        let {handleChangePage, onCloseEditComplianceModal, getJobLinkCell, getEditComplianceStatusButtonCell} = this;
        const { slaPaginatedItems,
                targetComplianceRate,
                clientName,
                timeFrame,
                totalSLAItemCount,
                currentPageNumber,
                loading,
                nonCompliantSLAItemCount,
            } = this.state;
        const {slaType, startDate, endDate} = this.props;

        return (
            <CompliancePageView
                loading={loading}
                slaPaginatedItems={slaPaginatedItems}
                slaType={slaType}
                targetComplianceRate={targetComplianceRate}
                clientName={clientName}
                title={compliancePageTitles[slaType]}
                slaText={SlaComplianceText[slaType](timeFrame)}
                totalSLAItemCount={totalSLAItemCount}
                nonCompliantSLAItemCount={nonCompliantSLAItemCount}
                itemsPerPage={itemsPerPage}
                currentPageNumber={currentPageNumber}
                startDate={startDate}
                endDate={endDate}
                handleChangePage={handleChangePage}
                onCloseEditComplianceModal={onCloseEditComplianceModal}
                getJobLinkCell={getJobLinkCell}
                getEditComplianceStatusButtonCell={getEditComplianceStatusButtonCell}
            />
        )
    }
}

CompliancePage.ServiceLevelAgreement = `
    id
    type
    timeFrame
    client{
        nickname
    }
    SLAItemCount(slaItemStartDate: $slaItemStartDate, slaItemEndDate: $slaItemEndDate)
    nonCompliantSLAItemCount(slaItemStartDate: $slaItemStartDate, slaItemEndDate: $slaItemEndDate)
`;

CompliancePage.ServiceLevelAgreementItem = `
    id
    serviceLevelAgreement{
        id
    }
    compliant
    resolved
    request{
        id
        assignment{
            id
        }
        location{
            id
            title
            city
            state
        }
        serviceLine{
            name
        }
        type
        contractOpportunities{
            vendorLocation{
                vendor{
                    nickname
                }
            }
        }
    }
    obligation{
        id
        clientLocation{
            id
            title
            city
            state
        }
        serviceLine{
            name
        }
        vendor{
            nickname
        }
        obligationTemplateId
    }
`;

CompliancePage.propTypes = {
    clientId: PropTypes.number.isRequired,
    slaType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export default CompliancePage;