import './SweetAlertHelpersStyles.css';
import Swal from "sweetalert2";
import {ValueIsSet} from "./GenericHelpers";

export const DisplaySuccessNotification = (message) => {
    let reloadNeeded = false;
    if (message === 'Revision Requested!') {
        reloadNeeded = true;
    }

    return Swal.fire({
        title: message,
        type: 'success'
    }).then((result) => {
        if (reloadNeeded) {
            window.location.reload();
        }
    });
};
export const DisplayErrorNotification = (message) => {
    return Swal.fire({
        title: message,
        type: 'error'
    });
};
export const DisplayWarningNotification = (message, title) => {
    if (ValueIsSet(title))
        return Swal.fire({
            title: title,
            html: message,
            type: 'warning'
        });
    else
        return Swal.fire({
            title: message,
            type: 'warning'
        });
};
export const DisplayConfirmNotification = (options) => {
    return Swal.fire({
        type: 'warning',
        showCancelButton: true,
        title: 'Are you sure?',
        text: null,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        ...options
    });
};