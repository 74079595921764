import React from 'react';
import PropTypes from 'prop-types';
import AlertDialog from "worksmith/components/Dialog/AlertDialog";

const AcceptChangeOrderDialog = (props) => {
    const {open, onCancel, onAcceptChangeOrder, submitting} = props;
    return (
        <>
            <AlertDialog
                acceptDisabled={submitting}
                acceptText={'Yes, Continue'}
                body={'This will replace your current pricing with the change order pricing.'}
                cancelText={'Exit'}
                debounceAccept
                onAccept={() => onAcceptChangeOrder()}
                onCancel={onCancel}
                open={open}
                title={'Accept Change Order?'}/>
        </>
    )
};

AcceptChangeOrderDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAcceptChangeOrder: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};

export default AcceptChangeOrderDialog;