import React, {Component} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {abbreviateUsername} from '../components/Utilities';
import Loader from "./Loader";
import GraphQLServiceClass from 'worksmith/services/graphql/GraphQLServiceClass';
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';
import RequestForProposalService from 'worksmith/services/api/RequestForProposalService';
import {Color} from 'worksmith/enums/Color';
import {GraphQLObjectType} from 'worksmith/enums/GraphQLObjectType';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {DisplayConfirmNotification} from "worksmith/helpers/SweetAlertHelpers";

const requestForProposalService = new RequestForProposalService();
const obligationItemGroupService = new ObligationItemGroupService();
const graphQLService = new GraphQLServiceClass();

const t = require('tcomb-form');
const Form = t.form.Form;

const ScrollableDiv = styled.div`
    overflow-y: scroll;
    max-height: 350px;
    margin-bottom: 20px;
    
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
`;

class Comments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rfp: this.props.rfp || null,
            opportunity: this.props.opportunity || null,
            assignment: this.props.assignment || null,
            changeRequest: this.props.changeRequest || null,
            ticket: this.props.ticket || null,
            comments: [],
            comment: '',
            type: '',
            value: {},
            sendingComment: false,
            style: this.props.style,
            loading: false,
            loadedOnce: false,
            shouldNudgeClientToVendorConversation: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.submit = this.submit.bind(this);
        this.getComments = this.getComments.bind(this);
    }

    getComments(override){
        let _this = this;
        let {
            rfp,
            opportunity,
            assignment,
            changeRequest,
            ticket,
            loadedOnce
        } = this.state;

        if (!loadedOnce || override) {
            if (rfp) {
                this.setState({type: 'Request'});
                getRfpComments(rfp);
            } else if (opportunity) {
                this.setState({type: 'Opportunity'});
                getOpportunityComments(opportunity);
            } else if (assignment) {
                this.setState({type: 'Assignment'});
                getAssignmentComments(assignment);
            } else if (changeRequest) {
                this.setState({type: 'Change Request'});
                getChangeRequestComments(changeRequest);
            } else if (ticket) {
                this.setState({type: 'Ticket'});
                getTicketComments(ticket);
            }

            _this.setState({loading: true});

            function getRfpComments(request) {
                graphQLService.findOneById(request.id,
                    GraphQLObjectType.REQUEST,
                      `
                      shouldNudgeClientToVendorConversation
                      comments {
                        comment
                        createdTimestamp
                        user {
                          displayName
                        }
                      }
                      `
                ).then(function (request) {
                    const {shouldNudgeClientToVendorConversation} = request;
                    _this.setState({comments: request.comments, loading: false, loadedOnce: true, shouldNudgeClientToVendorConversation});
                })
            }

            function getOpportunityComments(opportunity) {
            }

            function getAssignmentComments(assignment) {
            }

            function getChangeRequestComments(changeRequest) {
            }

            function getTicketComments(ticket) {
                obligationItemGroupService.getComments(ticket.id).then(function (data) {
                    let comments = data.reverse();
                    _this.setState({comments, loading: false, loadedOnce: true});
                })
            }
        }
    }

    onSubmit(evt) {
        evt.preventDefault();
        const {confirmBeforeSubmitOptions, onConfirmationDenied} = this.props;
        const {shouldNudgeClientToVendorConversation} = this.state;

        if (ValueIsSet(confirmBeforeSubmitOptions) && shouldNudgeClientToVendorConversation) {
            DisplayConfirmNotification(confirmBeforeSubmitOptions).then(result => {
                if (result.value)
                    this.submit();
                else if (ValueIsSet(onConfirmationDenied))
                    onConfirmationDenied(this.refs.form.getValue().comment);
            });
        } else {
            this.submit();
        }
    }

    submit() {
        let _this = this;
        let {
            type,
            rfp,
            ticket,
            //opportunity,
            //assignment,
            //changeRequest
        } = this.state;

        const value = this.refs.form.getValue();
        if (value) {
            switch(type){
                case 'Request':
                    submitRequestComment(value);
                    break;
                case 'Opportunity':
                    submitOpportunityComment(value);
                    break;
                case 'Assignment':
                    submitAssignmentComment(value);
                    break;
                case 'Change Request':
                    submitChangeRequestComment(value);
                    break;
                case 'Ticket':
                    submitTicketComment(value);
                    break;
                default:
                    return;
            }
        }

        function submitRequestComment(value){
            let rfpComment = {
                comment: value.comment,
                rfpId: rfp.id
            };

            _this.setState({sendingComment: true}, () => requestForProposalService.addComment(rfp.id, rfpComment).then(function(data){
                    _this.getComments(true);
                    _this.setState({sendingComment: false});
                    requestForProposalService.createOrUpdateRead(rfp.id);
                    _this.setState({comment: ''});
                    refreshNudge();
                })
            );
        };

        function refreshNudge() {
            graphQLService.findOneById(rfp.id,
                GraphQLObjectType.REQUEST,
                `
                      shouldNudgeClientToVendorConversation
                      `
            ).then(function (request) {
                const {shouldNudgeClientToVendorConversation} = request;
                _this.setState({shouldNudgeClientToVendorConversation});
            })
        }

        function submitTicketComment(value){
            let ticketComment = {
                comment: value.comment,
                obligationItemGroupId: ticket.id
            };

            _this.setState({sendingComment: true}, () => obligationItemGroupService.addComment(ticket.id, ticketComment).then(function(data){
                    _this.getComments(true);
                    _this.setState({sendingComment: false});
                    //obligationItemGroupService.createOrUpdateRead(ticket.id);
                    _this.setState({comment: ''});
                })
            );
        };

        function submitOpportunityComment(value){

        }
        function submitAssignmentComment(value){

        }
        function submitChangeRequestComment(value){

        }

    }

    onCommentChange = (value) => {
        this.setState({comment: value.comment});
    };

    render() {
        const { onCommentChange } = this;
        let {comment, comments, sendingComment, style, loading} = this.state;

        const CommentFormSchema = t.struct({
            comment: t.String
        });

        const commentFormOptions = {
            auto: 'none',
            fields: {
                comment: {
                    type: 'textarea'
                }
            }
        };

        return (
            <div style={style}>
                <ScrollableDiv>
                {(comments && comments.length > 0) ?
                    comments.map(function (comment, idx) {
                        return (
                            <div key={idx}>
                                <div className="d-flex flex-row comment">
                                    <div className="margin-right-sm">
                                        <div className="comment-abbreviation-container">
                                            <span
                                                className="comment-abbreviation">{abbreviateUsername(comment.user.displayName)}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <p className="section-accent-sm"><strong>{comment.user.displayName}</strong></p>
                                            <p className="section-accent-sm" style={{'color': Color.BLUE}}>{moment(comment.createdTimestamp).format('MMMM D YYYY, hh:mm a')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{'margin': '20px 0 20px'}}>
                                    <p className={"comment-text"} style={{'white-space': 'pre-line'}}>{comment.comment}</p>
                                </div>

                            </div>
                        )
                    }) : (loading ? <Loader/> : <h3 style={{textAlign: 'center', paddingTop: '20px'}}>There are no comments yet</h3>)
                }
                </ScrollableDiv>
                <div>
                    <form onSubmit={this.onSubmit}>
                        <Form options={commentFormOptions}
                              ref="form"
                              type={CommentFormSchema}
                              value={{comment: comment}}
                              onChange={onCommentChange}/>
                        <div className="form-group text-right">
                            <button className="ws-btn ws-btn-primary" disabled={sendingComment} type="submit">{sendingComment ? 'sending...' : 'Add Comment'}</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Comments;
