import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {
    BorderStyle,
    Cursor,
    Display,
    FlexDirection,
    JustifyContent,
    Outline,
    Overflow,
    Position,
    TextAlign,
    TextOverflow,
    TextTransform
} from "../../CSSEnums";
import {Color, LoggedOutRoutes} from "../../Enums";
import {ErrorText} from "../../components/styledComponents/Basic";
import {ValueIsSet} from "../../Helpers";

const mobileScreenWidth = 776;

const Container = styled.div`
    display: ${Display.FLEX};
    flex: 1;
    justify-content: ${JustifyContent.CENTER};
`;

const LogInBlock = styled.div`
    display: ${Display.FLEX};
    flex-direction: ${FlexDirection.ROW};
    background-color: ${Color.WHITE};
    border-style: ${BorderStyle.SOLID};
    border-width: 2px;
    border-color: ${Color.OFF_WHITE};
    margin-top: 100px;

    height: 550px;
    width: 870px;
`;

const FlexDiv = styled.div`
    display: ${Display.FLEX};
    flex-direction: ${FlexDirection.COLUMN};
    flex: 1;
`;

const ImageDiv = styled(FlexDiv)`
    @media (max-width: ${mobileScreenWidth}px) {
        display: ${Display.NONE};
    }
`;

const LoginFormContainer = styled.div`
    display: ${Display.FLEX}; 
    flex: 1; 
    flex-direction: ${FlexDirection.COLUMN};
    padding: 0 10px;
`;

const GoogleLoginButton = styled(Link)`
    width: 100%;
    height: 34px;
    display: ${Display.INLINE_BLOCK};
    
    font-size: 12px !important;
    
    color: ${Color.WHITE} !important;
    background-color: ${'#9E4838'};
    border-color: #9E4838;
    
    position: ${Position.RELATIVE};
    overflow: ${Overflow.HIDDEN};
    padding: 0.5rem 1.25rem 0.5rem 3.375rem;
    text-overflow: ${TextOverflow.ELLIPSIS};
    
    outline: ${Outline.NONE};
    cursor: ${Cursor.POINTER};
    font-size: 0.75rem;
    border-radius: 5px;
    text-transform: ${TextTransform.UPPERCASE};
`;

const GooglePlusIcon = styled.i`
    position: ${Position.ABSOLUTE};
    top: 0;
    bottom: 0;
    left: 0;
    color: ${Color.WHITE};
    
    display: ${Display.INLINE_BLOCK};
    font-size: 0.75rem;
    
    width: 2.125rem;
    text-align: ${TextAlign.CENTER};
    line-height: 2.125rem !important;
    background: rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.03);
`;

class LoginPageView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: ''
        }
    }

    onUsernameChange = (event) => {
        this.setState({username: event.target.value});
    };

    onPasswordChange = (event) => {
        this.setState({password: event.target.value});
    };

    render() {
        let {onUsernameChange, onPasswordChange} = this;
        let {errorMessage, onSubmit, submitting} = this.props;

        return (
            <Container>
                <LogInBlock>
                    <FlexDiv>
                        <div style={{display: Display.FLEX, justifyContent: JustifyContent.CENTER}}>
                            <img style={{height: '240px', width: 'auto'}}
                                 src={'https://cdn.worksmith.com/logo-navy.png'} alt={'logo'}/>
                        </div>
                        <LoginFormContainer>
                            <form data-testid={"loginForm"} onSubmit={(event) => {
                                event.preventDefault();
                                onSubmit({
                                    username: this.state.username,
                                    password: this.state.password
                                });
                            }}>
                                <input
                                    data-testid={'login-username'}
                                    type={'text'}
                                    required
                                    className={'form-control'}
                                    value={this.state.username}
                                    name={'username'}
                                    onChange={onUsernameChange}
                                    placeholder={'Username'}
                                    style={{marginBottom: '15px'}}/>
                                <input
                                    data-testid={'login-password'}
                                    type={'password'}
                                    required
                                    className={'form-control'}
                                    value={this.state.password}
                                    onChange={onPasswordChange}
                                    placeholder={'Password'}
                                    style={{marginBottom: '15px'}}/>
                                <button
                                    data-testid={'login-submit'}
                                    type={'submit'}
                                    className={'ws-btn ws-btn-warning'}
                                    disabled={submitting}
                                    style={{width: '100%', marginBottom: '15px', height: '34px', fontSize: '12px', backgroundColor: '#156F83', borderColor: '#156F83'}}>
                                    {submitting ? 'Logging In...' : 'Login'}
                                </button>
                            </form>

                            {ValueIsSet(errorMessage) ?
                                <ErrorText>
                                    {errorMessage}
                                </ErrorText>
                                :
                                null
                            }

                            <Link
                                to={LoggedOutRoutes.FORGOT_PASSWORD}
                                style={{textAlign: TextAlign.RIGHT, marginBottom: '30px', color: Color.BLUE}}>
                                Forgot your password?
                            </Link>
                            <div
                                style={{textAlign: TextAlign.CENTER}}>
                                {/* TODO: move authentication handling off the express server */}
                                <GoogleLoginButton to={LoggedOutRoutes.GOOGLE_AUTH}>
                                    <GooglePlusIcon className="fa fa-google-plus"/>
                                    Sign in with Google
                                </GoogleLoginButton>
                            </div>
                        </LoginFormContainer>
                    </FlexDiv>
                    <ImageDiv>
                        <img src={'https://cdn.worksmith.com/images/quotes/Login_Hero.png'} alt={''} width={'100%'}
                             height={'100%'}/>
                    </ImageDiv>
                </LogInBlock>
            </Container>
        )
    }
}

LoginPageView.propTypes = {
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
};

export default LoginPageView;