export const ThailandProvince = Object.freeze({
    'ACR': 'Amnat Charoen (ACR)',
    'ATG': 'Ang Thong (ATG)',
    'BKK': 'Bangkok (special administrative area) (BKK)',
    'BKN': 'Bueng Kan (BKN)',
    'BRM': 'Buriram (BRM)',
    'CCO': 'Chachoengsao (CCO)',
    'CNT': 'Chai Nat (CNT)',
    'CPM': 'Chaiyaphum (CPM)',
    'CTI': 'Chanthaburi (CTI)',
    'CMI': 'Chiang Mai (CMI)',
    'CRI': 'Chiang Rai (CRI)',
    'CBI': 'Chonburi (CBI)',
    'CPN': 'Chumphon (CPN)',
    'KSN': 'Kalasin (KSN)',
    'KPT': 'Kamphaeng Phet (KPT)',
    'KRI': 'Kanchanaburi (KRI)',
    'KKN': 'Khon Kaen (KKN)',
    'KBI': 'Krabi (KBI)',
    'LPG': 'Lampang (LPG)',
    'LPN': 'Lamphun (LPN)',
    'LEI': 'Loei (LEI)',
    'LRI': 'Lopburi (LRI)',
    'MSN': 'Mae Hong Son (MSN)',
    'MKM': 'Maha Sarakham (MKM)',
    'MDH': 'Mukdahan (MDH)',
    'NYK': 'Nakhon Nayok (NYK)',
    'NPT': 'Nakhon Pathom (NPT)',
    'NPM': 'Nakhon Phanom (NPM)',
    'NMA': 'Nakhon Ratchasima (NMA)',
    'NSN': 'Nakhon Sawan (NSN)',
    'NRT': 'Nakhon Si Thammarat (NRT)',
    'NAN': 'Nan (NAN)',
    'NWT': 'Narathiwat (NWT)',
    'NBP': 'Nong Bua Lamphu (NBP)',
    'NKI': 'Nong Khai (NKI)',
    'NBI': 'Nonthaburi (NBI)',
    'PTE': 'Pathum Thani (PTE)',
    'PTN': 'Pattani (PTN)',
    'PNA': 'Phang Nga (PNA)',
    'PLG': 'Phatthalung (PLG)',
    'PYO': 'Phayao (PYO)',
    'PNB': 'Phetchabun (PNB)',
    'PBI': 'Phetchaburi (PBI)',
    'PCT': 'Phichit (PCT)',
    'PLK': 'Phitsanulok (PLK)',
    'AYA': 'Phra Nakhon Si Ayutthaya (AYA)',
    'PRE': 'Phrae (PRE)',
    'PKT': 'Phuket (PKT)',
    'PRI': 'Prachinburi (PRI)',
    'PKN': 'Prachuap Khiri Khan (PKN)',
    'RNG': 'Ranong (RNG)',
    'RBR': 'Ratchaburi (RBR)',
    'RYG': 'Rayong (RYG)',
    'RET': 'Roi Et (RET)',
    'SKW': 'Sa Kaeo (SKW)',
    'SNK': 'Sakon Nakhon (SNK)',
    'SPK': 'Samut Prakan (SPK)',
    'SKN': 'Samut Sakhon (SKN)',
    'SKM': 'Samut Songkhram (SKM)',
    'SRI': 'Saraburi (SRI)',
    'STN': 'Satun (STN)',
    'SBR': 'Sing Buri (SBR)',
    'SSK': 'Sisaket (SSK)',
    'SKA': 'Songkhla (SKA)',
    'STI': 'Sukhothai (STI)',
    'SPB': 'Suphan Buri (SPB)',
    'SNI': 'Surat Thani (SNI)',
    'SRN': 'Surin (SRN)',
    'TAK': 'Tak (TAK)',
    'TRG': 'Trang (TRG)',
    'TRT': 'Trat (TRT)',
    'UBN': 'Ubon Ratchathani (UBN)',
    'UDN': 'Udon Thani (UDN)',
    'UTI': 'Uthai Thani (UTI)',
    'UTD': 'Uttaradit (UTD)',
    'YLA': 'Yala (YLA)',
    'YST': 'Yasothon (YST)'
});