import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import Slider from "../SliderField/Slider";
import {convertMomentTimeTo24HourNumber} from "../../helpers/TimeHelpers";
import Text, {TextColor, TextVariant} from "../Text/Text.web";
import {MomentFormat} from "../../enums/MomentFormat";
import Box from "@mui/material/Box";
import SliderField from "../SliderField/SliderField";
import TimeRangeField from "./TimeRangeField";

const TimeRangeFieldView = (props) => {
    const {
        endTime,
        initialEndTime,
        initialStartTime,
        minRange,
        maxRange,
        onTimeRangeChange,
        onTimeRangeCommitted,
        startTime,
        step,
        label
    } = props;

    let minRangeHours = convertMomentTimeTo24HourNumber(minRange);
    let maxRangeHours = convertMomentTimeTo24HourNumber(maxRange);

    return (
        <Box maxWidth={500} minWidth={250}>
            <SliderField label={label + startTime.format(MomentFormat.StandardTime) + ' - ' + endTime.format(MomentFormat.StandardTime)}
                         min={minRangeHours}
                         max={maxRangeHours}
                         step={step}
                         onChange={onTimeRangeChange}
                         onChangeCommitted={onTimeRangeCommitted}
                         initialValue={[convertMomentTimeTo24HourNumber(initialStartTime), convertMomentTimeTo24HourNumber(initialEndTime)]}/>
        </Box>
    )
};

TimeRangeFieldView.propTypes = {
    endTime: PropTypes.instanceOf(moment),
    initialEndTime: PropTypes.instanceOf(moment),
    initialStartTime: PropTypes.instanceOf(moment),
    minRange: PropTypes.instanceOf(moment),
    maxRange: PropTypes.instanceOf(moment),
    onTimeRangeChange: PropTypes.func.isRequired,
    onTimeRangeCommitted: PropTypes.func.isRequired,
    startTime: PropTypes.instanceOf(moment),
    step: PropTypes.number,
    label: PropTypes.string.isRequired
};

export default TimeRangeFieldView;