import React from 'react';
import FrequencyPropType from "../../custom-prop-types/FrequencyPropType";
import FrequencyView from "./FrequencyView";

function Frequency(props) {
    return (
        <FrequencyView frequency={props.frequency}/>
    )
}

Frequency.propTypes = {
    frequency: FrequencyPropType.isRequired
};

export default Frequency;