import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import ExpandableSection from "worksmith/components/ExpandableSection/ExpandableSection";
import Divider, {DividerOrientation} from "worksmith/components/Divider/Divider.web";
import {AlignItems, Display} from "worksmith/enums/CSSEnums";
import {IconFontSize} from "worksmith/components/Icon/Icon.web";
import Collapsible from "worksmith/components/Collapse/Collapsible.web";
import Alert from "../Alert/Alert";

const AlertExpandableSection = (props) => {
    const {alerts, hideAlerts, initialExpanded, title} = props;

    return (
        <ExpandableSection
            iconSize={IconFontSize.LARGE}
            initialExpanded={initialExpanded}
            title={title}
            subtitle={
                <Collapsible
                    expanded={!hideAlerts}
                    timeout={400}>
                    <Box
                        display={Display.FLEX}
                        alignItems={AlignItems.CENTER}>
                        {alerts ?
                            alerts.map((alert, index) => (
                                <Fragment>
                                    {index > 0 ?
                                        <Box height={20} mr={2} ml={2}>
                                            <Divider orientation={DividerOrientation.VERTICAL}/>
                                        </Box>
                                        :
                                        null
                                    }
                                    <Alert
                                        count={alert.count}
                                        hideAlertCircle={alert.hideAlertCircle}
                                        label={alert.label}/>

                                </Fragment>
                            ))
                            :
                            null
                        }
                    </Box>
                </Collapsible>
            }>
            {props.children}
        </ExpandableSection>
    )
};

AlertExpandableSection.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape({
        count: PropTypes.number,
        label: PropTypes.string.isRequired,
        hideAlertCircle: PropTypes.bool
    })),
    collapsibleProps: PropTypes.object,
    hideAlerts: PropTypes.bool,
    initialExpanded: PropTypes.bool,
    title: PropTypes.string,
};

export default AlertExpandableSection;