import React, {useState} from 'react';
import FormControlLabel from "@mui/material/FormControlLabel";
import {Paper} from "@mui/material";
import PropTypes from 'prop-types';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import styled from "styled-components";

import {Color} from "worksmith/enums/Color";

const RadioGroupPaper = ({initialValue, onChange, options}) => {

    const [radioValue, setRadioValue] = useState(initialValue);
    const [hover, setHover] = useState(null);

    const handleChange = (value) => {
        setRadioValue(value);
        onChange(value);
    };


    return (
        <RadioGroup
            name="Add Vendor"
            value={radioValue}
            onChange={(event) => {
                handleChange(event.target.value)
            }}
        >
            {options.map((option) => (
                    <StyledPaper
                        elevation={hover === option.value ? 4 : 0}
                        onClick={(event) => {
                            event.preventDefault();
                            handleChange(option.value)
                        }}
                        onMouseOver={() => setHover(option.value)}
                        onMouseOut={() => setHover('')}
                    >
                        <FormControlLabel
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                        />
                    </StyledPaper>
                )

            )}
        </RadioGroup>
    );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid ${Color.LIGHT_GREY};
`;

RadioGroupPaper.propTypes = {
    initialValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.oneOf([null]),
    ]),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
    }))
};
RadioGroupPaper.defaultProps = {
    initialValue: null,
};

export default RadioGroupPaper;