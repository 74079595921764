import React, {Fragment} from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import {Color, PrimaryColor, SecondaryColor} from "worksmith/enums/Color";

const Bar = styled.div`
    width: .225em;
    background-color: ${props => props.color};
    height: 100%;
`;

const RequestTypeBar = (props) => {
    let {emergency, loading, type} = props;
    let color;

    switch(type) {
        case RfpType.ON_DEMAND:
            color = SecondaryColor.main;
            break;
        case RfpType.PRICING_ONLY:
            color = Color.BORDER_GREY;
            break;
        case RfpType.RECURRING:
            color = PrimaryColor.main;
            break;
        default:
            color = Color.BORDER_GREY;
            break;
    }

    if (emergency) {
        color = Color.RED;
    } else if (loading) {
        color = Color.BORDER_GREY;
    }

    return (
        <Bar color={color}/>
    )
};

RequestTypeBar.propTypes = {
    loading: false,
};

RequestTypeBar.propTypes = {
    emergency: PropTypes.bool,
    loading: PropTypes.bool,
    type: CustomPropTypes.enum(RfpType)
};

export default RequestTypeBar;