import styled from "styled-components";
import {Color} from "../../Enums";

export const ErrorText = styled.div`color: ${Color.RED}`;

export const HideOnMobile = styled.div`
    @media (max-width: 768px) {
        display: none;
    }  
`;
