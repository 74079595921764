import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Message from "./Message/Message";
import moment from "moment";
import AuthTokenManager from "worksmith/services/utilities/AuthTokenManager";
import Text, {TextColor} from "../Text/Text.web";

const authTokenManager = new AuthTokenManager();

const MessageContainer = styled.div`
    margin-bottom: 1.5em;
`;

class MessageList extends Component {
    constructor(props) {
        super(props);

        this.lastMessageRef = React.createRef();
        this.state = {};
    }


    scrollToBottom = () => {
        if(this.lastMessageRef.current){
            this.lastMessageRef.current.scrollIntoView({block: 'nearest'});
        }
    };

    componentDidMount () {
        this.scrollToBottom()
    };

    componentDidUpdate(prevProps){
        if(prevProps.messages !== this.props.messages){
            if(this.lastMessageRef.current) {
                this.scrollToBottom()
            }
        }
    };

    render() {
        let {isOpen, messages} = this.props;
        let {lastMessageRef} = this;

        let currentUserId = authTokenManager.getUserId();

        return (
            <Fragment>
                {
                    messages
                        .sort((a, b) => moment(a.createdTimestamp).isBefore(moment(b.createdTimestamp)))
                        .map((message, idx) => (
                            <MessageContainer key={message.id} ref={idx === messages.length - 1 ? lastMessageRef : null}>
                                <Message
                                    attachments={message.attachments}
                                    author={message.author.companyName === 'Worksmith' ? 'Worksmith' : message.author.nameWithRole}
                                    content={message.content}
                                    createdTimestamp={moment(message.createdTimestamp).format('MMMM Do YYYY h:mm a')}
                                    shiftRight={message.author.id == currentUserId}
                                />
                            </MessageContainer>
                        ))
                }
                {
                    !isOpen ?
                        <MessageContainer key={'closed'}>
                            <Text color={TextColor.TEXT_SECONDARY}>
                                &#8212; Conversation closed &#8212;
                            </Text>
                        </MessageContainer>
                        :
                        null
                }
            </Fragment>
        )

    }
}

MessageList.defaultProps = {
    isOpen: true,
};

MessageList.propTypes = {
    isOpen: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.shape({
        author: PropTypes.shape({
            id: PropTypes.number,
            nameWithRole: PropTypes.string
        }),
        content: PropTypes.string,
        createdTimestamp: PropTypes.string,
        id: PropTypes.number,
    })).isRequired
};

export default MessageList;