// This file was generated from com.worksmith.proposal.controller.ScheduleHandlerController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ScheduleHandlerService extends Http {
    constructor() {
        super('/scheduleHandler')
    }
    
    addComment(scheduleHandlerComment) {
        return this.callApi( `addComment`, PATCH, scheduleHandlerComment, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    makeCounterOffer(params) {
        return this.callApi( `makeCounterOffer`, PATCH, params, null, null, true);
    }
    
    makeNewScheduleOffer(params) {
        return this.callApi( `makeNewScheduleOffer`, PATCH, params, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default ScheduleHandlerService;
