import React from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import Icon from "worksmith/components/Icon/Icon";
import {Color} from "worksmith/enums/Color";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Box from "@mui/material/Box";
import AlertExpandableSection from "worksmith/components/ExpandableSection/AlertExpandableSection";


const SignOffExpansionPanel = ({confirmedUser, confirmedTimestamp, sidePanel}) => {

    return (
        <AlertExpandableSection
            collapsibleProps={{
                unmountOnExit: false
            }}
            title={ <Grid container margin={sidePanel ? "0 0 0 1rem" : "0 0 0 0" }>
                         <Icon iconColor={Color.BLACK} name={"AssignmentTurnedInRounded"}/>
                         <Text variant={sidePanel ? TextVariant.BODY_1 : TextVariant.H6}>
                             {confirmedUser ? "Sign Off Complete" : "Auto Sign Off Complete"}
                         </Text>
                     </Grid>}>
            <Box mt={3} ml={1} mr={2} >
                <Grid container margin={"0 0 0 1rem"}>
                    <Grid xs={12} margin={"0 0 1rem 0"}>
                        { confirmedUser ?<Text>{confirmedUser.displayName}</Text> : null}
                        <Text>{moment(confirmedTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)}</Text>
                    </Grid>
                </Grid>
            </Box>
        </AlertExpandableSection>
    );
};

SignOffExpansionPanel.propTypes = {
    confirmedUser: PropTypes.object,
    confirmedTimestamp: PropTypes.string,
    sidePanel: PropTypes.bool
};

export default SignOffExpansionPanel;