import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import Tabs from "worksmith/components/Tab/Tabs.web";
import RecurringService from "./RecurringService";
import RecurringServiceRequest from "./RecurringServiceRequest";
import NoRecurringService from "./NoRecurringService";
import styled from 'styled-components';
import BackToAllLocationsLink from "./BackToAllLocationsLink";
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import ObligationStatus from "worksmith/enums/api/task/ObligationStatus";
import RecurringServicesDetailsExpansion from "./RecurringServiesDetailsExpansion";
import {ValueIsSet} from "../../Helpers";

const PaddedBlock = styled.div`
    margin-Bottom: 1.5em;
`;

const BottomPaddedBlock = styled.div`
    margin-bottom: 1em;
`;

const SingleLocationServiceLinePageView = (props) => {
    let {requests, obligationTemplates, obligationTemplateId, reload, serviceLine, location} = props;
    let anyActiveServices = requests.length > 0  || obligationTemplates.some(ot => ot.status !== ObligationTemplateStatus.CANCELED);
    let cancelledObligationTemplates = obligationTemplates.filter(ot => ot.status === ObligationTemplateStatus.CANCELED);
    let filterOutCancelledServices = true;

    let recurringServiceComponents = obligationTemplates.filter(ot => filterOutCancelledServices ? ot.status !== ObligationTemplateStatus.CANCELED : true)
                                                        .map((ot, index) =>
                                                                        <RecurringService reload={reload} key={ot.id}
                                                                             obligationTemplate={ot}
                                                                             anyActiveServices={anyActiveServices}
                                                                             displayCancelledServices={filterOutCancelledServices && index === 0}/>);


    let requestComponents = requests.map(r => <RecurringServiceRequest key={r.id} request={r}/>);
    let allComponents = recurringServiceComponents.concat(requestComponents);
    let titleList = allComponents.map((component, index) => "Service " + (index+1));
    let initialTabIndex = ValueIsSet(obligationTemplates) && ValueIsSet(obligationTemplateId) ?
        obligationTemplates.findIndex(ot => ot.id === obligationTemplateId) :
        0;

    return <div>
        <Text semiBold variant={TextVariant.H5}>{serviceLine.name} - Recurring Service</Text>
        <PaddedBlock>
            <BackToAllLocationsLink serviceLine={serviceLine}/>
        </PaddedBlock>
        <BottomPaddedBlock>
            <Text semiBold color={TextColor.PRIMARY} variant={TextVariant.H6}>{location.city} - {location.title}</Text>
        </BottomPaddedBlock>
        {filterOutCancelledServices && cancelledObligationTemplates.length > 0 ? <RecurringServicesDetailsExpansion cancelledObligationTemplates={cancelledObligationTemplates}/> : null}
        {titleList.length > 0 ?
            titleList.length === 1 ?
                    allComponents[0]
                :
                <Tabs titleList={titleList} initialValue={initialTabIndex}>
                    {allComponents}
                </Tabs>
            : <NoRecurringService serviceLine={serviceLine}/>
        }
    </div>;
};

SingleLocationServiceLinePageView.propTypes = {
    requests: PropTypes.arrayOf(PropTypes.object),
    obligationTemplates: PropTypes.arrayOf(PropTypes.object),
    obligationTemplateId: PropTypes.number,
    reload: PropTypes.func,
    serviceLineName: PropTypes.object,
    location: PropTypes.object
};



export default SingleLocationServiceLinePageView;