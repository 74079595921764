import React from 'react';
import PropTypes from 'prop-types';
import Text from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import {MomentFormat} from "../../enums/MomentFormat";
import moment from "moment";

const ObligationCurrentScheduleSummary = (props) => {
    const {obligation} = props;

    return <Grid container item spacing={4} xs={12}>
        <Grid item xs={6}>
            <Text>Current Date: {moment(obligation.obligationDate, MomentFormat.ISODate, true).isValid() ? moment(obligation.obligationDate,MomentFormat.ISODate).format(MomentFormat.MonthDayYearSlash) : obligation.obligationDate}</Text>
        </Grid>
        <Grid item xs={6}>
            <Text>Current Arrival Window: {moment(obligation.arrivalStartTime,MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)} - {moment(obligation.arrivalEndTime,MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)}</Text>
        </Grid>
    </Grid>

};

ObligationCurrentScheduleSummary.propTypes = {
    obligation: PropTypes.shape({
        obligationDate: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        arrivalEndTime: PropTypes.string
    })
};

export default ObligationCurrentScheduleSummary;