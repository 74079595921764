import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MaterialSwitch from '@mui/material/Switch';
import FormControlLabel from "@mui/material/FormControlLabel";

import {PickColor} from "worksmith/helpers/ThemeHelpers";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import {withTheme} from '../Theme/ThemeProvider';

class Switch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: !!props.initialValue
        };
    };

    onChange = (event, checked) => {

        this.setState(
            {value: checked},
            this.props.onChange(checked)
        );
    };

    render() {
        let {
            disabled,
            group,
            initialValue,
            label,
            primary,
            secondary,
            useInitialValue
        } = this.props;

        let {onChange} = this;
        let {value} = this.state;

        return (
            ValueIsSet(label) ?
            <FormControlLabel
                control={
                    <MaterialSwitch onChange={onChange}
                                    color={PickColor(primary, secondary)}
                                    className={this.props.className}
                                    checked={group ? initialValue : value}
                                    disabled={disabled}/>
                }
                label={label}
            />
            :
            <MaterialSwitch onChange={onChange}
                            color={PickColor(primary, secondary)}
                            className={this.props.className}
                            checked={useInitialValue ? initialValue : value}
                            disabled={disabled}/>
        )
    }
}

Switch.propTypes = {
    disabled: PropTypes.bool,
    group: PropTypes.bool,
    initialValue: PropTypes.bool,
    label: PropTypes.element,
    onChange: PropTypes.func.isRequired,        //will receive the checked boolean
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    useInitialValue: PropTypes.bool,
};

export default withTheme(Switch);
