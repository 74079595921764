// This file was generated from com.worksmith.psldb.enums.LineItemType
// noinspection JSUnusedGlobalSymbols
const LineItemType = Object.freeze({
    LABOR: 'Labor',
    MATERIALS: 'Materials',
    FEE: 'Fee',
    TRIP_CHARGE: 'Trip Charge',
    ENVIRONMENTAL_FEE: 'Environmental Fee',
    OTHER_FEE: 'Other Fee'
});

export default LineItemType;
