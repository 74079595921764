import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";

import {PickColorDefaultToDefault} from "worksmith/helpers/ThemeHelpers";
import {withTheme} from '../Theme/ThemeProvider';
import {ValueIsSet} from "../../helpers/GenericHelpers";
import {Color} from 'worksmith/enums/Color';

const StyledCheckboxContainer = styled.div`
  position: relative;
  display: inline-block;

  // this aligns the checkbox with the top of the container for checkboxes with long labels
  ${({useMultilineSpacing}) => useMultilineSpacing ? 
    `  
      label {
        align-items: flex-start;
    
        > span:first-of-type {
          padding: 2px 9px 9px;
        }
      }`
              : null
        }
    `;
const StyledAsterisk = styled.span`
    color: ${Color.RED};
    position: absolute;
    font-family: 'Open Sans';
    top: 5px;
    left: -12.5px;
`;

export const CheckboxSize = Object.freeze({
    LARGE: 'large',
    DEFAULT: 'default',
    SMALL: 'small',
    INHERIT: 'inherit'
});

class Checkbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ValueIsSet(props.checked) ? props.checked : !!props.initialValue
        };
    };

    onChange = (event, checked) => {
        let {value} = this.state;
        let {onlyChangeFromProp} = this.props;
        this.setState(
            {value: onlyChangeFromProp ? value : checked},
            this.props.onChange(checked)
        );
    };

    shouldComponentUpdate = (nextProps) => {
        if (nextProps.checked !== this.props.checked) {
           this.state.value = nextProps.checked;
        }

        return true;
    };

    render() {
        let {
            disabled,
            disablePadding,
            edge,
            indeterminate,
            label,
            primary,
            secondary,
            showRequiredAsterisk,
            size,
            testId,
            useMultilineSpacing
        } = this.props;

        let {onChange} = this;
        let {value} = this.state;
        let style = {};
        if (disablePadding) {
           style.padding = '0px';
        }

        return (
            ValueIsSet(label) ?
                <StyledCheckboxContainer useMultilineSpacing={useMultilineSpacing}>
                    {ValueIsSet(showRequiredAsterisk) ? <StyledAsterisk>*</StyledAsterisk> : null}
                    <FormControlLabel
                        control={
                            <MaterialCheckbox
                                data-testid={testId}
                                indeterminate={indeterminate}
                                onChange={onChange}
                                edge={edge}
                                onClick={(event) => event.stopPropagation()}
                                color={PickColorDefaultToDefault(primary, secondary)}
                                className={this.props.className}
                                checked={value}
                                size={size}
                                style={style}
                                disabled={disabled}/>

                        }
                        label={label}
                    />
                </StyledCheckboxContainer>
                :
                <StyledCheckboxContainer>
                    {ValueIsSet(showRequiredAsterisk) ? <StyledAsterisk>*</StyledAsterisk> : null}
                    <MaterialCheckbox
                        data-testid={testId}
                        indeterminate={indeterminate}
                        onChange={onChange}
                        edge={edge}
                        onClick={(event) => event.stopPropagation()}
                        color={PickColorDefaultToDefault(primary, secondary)}
                        className={this.props.className}
                        checked={value}
                        size={size}
                        style={style}
                        disabled={disabled}
                    />
                </StyledCheckboxContainer>
            )
    }
}

Checkbox.defaultProps = {
    onlyChangeFromProp: false
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    disablePadding: PropTypes.bool,
    edge: PropTypes.string,
    indeterminate: PropTypes.bool,
    initialValue: PropTypes.bool,
    label: PropTypes.element,
    onChange: PropTypes.func.isRequired,        //will receive params (event, checked)
    onlyChangeFromProp: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    showRequiredAsterisk: PropTypes.bool,
    size: PropTypes.string,
    testId: PropTypes.string, // used in Cypress tests
    useMultilineSpacing: PropTypes.bool,
};

export default withTheme(Checkbox);
