export const CanadianProvinceNameAndAbbr = Object.freeze({
    "AB": "Alberta (AB)",
    "BC": "British Columbia (BC)",
    "MB": "Manitoba (MB)",
    "NB": "New Brunswick (NB)",
    "NL": "Newfoundland and Labrador (NL)",
    "NT": "Northwest Territories (NT)",
    "NS": "Nova Scotia (NS)",
    "NU": "Nunavut (NU)",
    "ON": "Ontario (ON)",
    "PE": "Prince Edward Island (PE)",
    "QC": "Quebec (QC)",
    "SK": "Saskatchewan (SK)",
    "YT": "Yukon Territory (YT)"
});