import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import ActionableModalTemplate from "worksmith/components/Modal/Templates/ActionableModalTemplate";
import FilePreview from "worksmith/components/FileUpload/FilePreview";
import Grid from "worksmith/components/Grid/Grid";
import Modal from "worksmith/components/Modal/Modal";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {Display, JustifyContent, AlignItems} from "worksmith/enums/CSSEnums";
import {Color} from "worksmith/enums/Color";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {Pluralize} from "worksmith/helpers/LanguageHelper";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();

const StyledGrid = styled(Grid)`
  && {
    border: ${props => props.selected ? `2px solid ${Color.PRIMARY}` : `2px solid transparent`};
    margin: 8px;
  }
`;

const FileUploadMediaLibraryModal = ({addMediaLibraryFiles, closeMediaLibraryModal, locationId, showMediaLibraryModal}) => {

    const [mediaLibraryFiles, setMediaLibraryFiles] = useState([]);
    const [mediaFilesToAdd, setMediaFilesToAdd] = useState([]);
    const [mediaFilesToUpload, setMediaFilesToUpload] = useState([]);

    const fetchMediaData = async () => {
        const mediaData = await asyncGraphQLServiceClass.findOneById(locationId, GraphQLObjectType.CLIENT_LOCATION, mediaFields);
        const files = mediaData.clientLocationAttachments.map(file => ({
            ...file,
            url: file.singleUseUrl,
            description: file.description,
            name: file.title
        }));

        const fileUrls = await files.map(file => fetch(file.url).then(res => res.blob()).then(blob => new File([blob], file.title, { type: "image/png" })));
        const fileData = await Promise.all(fileUrls);

        const libraryData = files.map((file, idx) => ({
            ...file,
            file: fileData[idx]
        }));
        setMediaLibraryFiles(libraryData);
    };

    const addMediaFiles = (fileToAdd) => {
        const fileIds = mediaFilesToAdd.map(file => file.id);
        if (fileIds.includes(fileToAdd.id)) {
            const updatedFiles = mediaFilesToAdd.filter(file => file.id !== fileToAdd.id)
            setMediaFilesToAdd(updatedFiles)
        } else {
            setMediaFilesToAdd([
                ...mediaFilesToAdd,
                fileToAdd
            ])
        }
    };

    const handleCancel = () => {
        closeMediaLibraryModal();
        setMediaFilesToAdd([]);
    };

    const handleUpload = () => {
        closeMediaLibraryModal();
        setMediaFilesToAdd([]);
        addMediaLibraryFiles(mediaFilesToUpload);
    }

    const previews = mediaLibraryFiles.map((preview, index) => {
        const fileIds = mediaFilesToAdd.map(file => file.id);
        const selected = fileIds.includes(preview.id);
        return (
            <StyledGrid justify={JustifyContent.CENTER} alignItems={AlignItems.CENTER} item key={preview.name + index} selected={selected}>
                <FilePreview addedFile={preview}
                             index={index}
                             onFilePreviewClick={() => addMediaFiles(preview)}/>
            </StyledGrid>
        )
    });

    const approvalText = () => {
        if (mediaFilesToAdd.length > 0) {
            return `Add ${mediaFilesToAdd.length} ${Pluralize(mediaFilesToAdd.length, "Attachment")}`
        } else {
            return 'Add Attachments'
        }
    };

    useEffect(() => {
        if (locationId) {
            fetchMediaData();
        }
    }, [locationId]);

    useEffect(() => {
        const files = mediaFilesToAdd.map(file => file.file)
        setMediaFilesToUpload(files)
    }, [mediaFilesToAdd])

    return (
        <Modal open={showMediaLibraryModal}>
            <ActionableModalTemplate
                title={"Choose attachments"}
                body={
                    mediaLibraryFiles.length > 0 ?
                        <Grid container>
                            {previews}
                        </Grid>
                        :
                        <Text variant={TextVariant.BODY_1}>You do not have any files in your Media Library</Text>
                }
                onApprove={handleUpload}
                onCancel={handleCancel}
                cancelText={'CANCEL'}
                approvalText={approvalText()}
                approveDisabled={mediaFilesToAdd.length === 0}
            />
        </Modal>
    )
};

export default FileUploadMediaLibraryModal;

FileUploadMediaLibraryModal.propTypes = {
    addMediaLibraryFiles: PropTypes.func,
    closeMediaLibraryModal: PropTypes.func,
    locationId: PropTypes.number,
    showMediaLibraryModal: PropTypes.func
}

const mediaFields = `
        clientLocationAttachments {
        id
        singleUseUrl
        title
        description
    }
`;