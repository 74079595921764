import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import DateRangePickerView from "./DateRangePickerView.web";
import {InputBaseVariant} from "../Inputs/InputBase/InputBase.web";

export const DateSelectTypes = Object.freeze({
    START_DATE: 'startDate',
    END_DATE: 'endDate'
});

export const DateRangeInputFormat = Object.freeze({
    MonthDayYearSlash: {
        format: 'MM/DD/YYYY',
        placeholder: 'mm/dd/yyyy',
    },
});

class DateRangePicker extends Component {
    constructor(props) {
        super(props);
        let {initialStartDate, initialEndDate} = props;

        this.state = {
            error: false,
            startDate: null,
            endDate: null,
            tempStartDate: null,
            tempEndDate: null,
            currentSelection: DateSelectTypes.START_DATE
        };

        // If the initialEndDate is before the initialStartDate then the initial values will be ignored
        if (ValueIsSet(initialStartDate) && ValueIsSet(initialEndDate)) {
            if (initialEndDate.isSameOrAfter(initialStartDate)) {
                this.state.startDate = initialStartDate;
                this.state.endDate = initialEndDate;
            }
        } else {
            this.state.startDate = ValueIsSet(initialStartDate) ? initialStartDate : null;
            this.state.endDate = ValueIsSet(initialEndDate) ? initialEndDate : null;
        }
        this.state.tempStartDate = this.state.startDate;
        this.state.tempEndDate = this.state.endDate;

        this.readOnlyInputRef = React.createRef();
        this.tempDatesInputRef = React.createRef();
    }

    onApply = (skipOnChange) => {
        const {dateInputFormat, onDatesChange} = this.props;
        const {startDate, endDate, tempStartDate, tempEndDate} = this.state;

        // If the new dates are not the same as the current dates in state, and the new dates are either both set or not set
        if (!((moment(startDate).isSame(tempStartDate) && moment(endDate).isSame(tempEndDate))
             || (startDate == null && endDate == null && tempStartDate == null && tempEndDate == null))
            && ((ValueIsSet(tempStartDate) && ValueIsSet(tempEndDate))
                || (!ValueIsSet(tempStartDate) && !ValueIsSet(tempEndDate)))) {

            this.setState({startDate: tempStartDate, endDate: tempEndDate},
                () => {
                    const startDateText = ValueIsSet(tempStartDate) ? tempStartDate.format(dateInputFormat.format) : null;
                    const endDateText = ValueIsSet(tempEndDate) ? tempEndDate.format(dateInputFormat.format) : null;

                    this.readOnlyInputRef.current.setDates(startDateText, endDateText);

                    if (!skipOnChange) {
                        if (ValueIsSet(onDatesChange)) {
                            onDatesChange(tempStartDate, tempEndDate);
                        }
                    }

                });
        }
    };

    onClose = ()=> {
        const {startDate, endDate} = this.state;

        this.setState({ tempStartDate: startDate, tempEndDate: endDate, error: false });
    };

    onClear = () => {
        this.setState({tempStartDate: null, tempEndDate: null, currentSelection: DateSelectTypes.START_DATE}, this.setTempStateOnInput);
    };

    clear = (skipOnChange) => {
        if (skipOnChange) {
            this.setState({tempStartDate: null, tempEndDate: null, currentSelection: DateSelectTypes.START_DATE}, () => this.onApply(true));
        } else {
            this.setState({tempStartDate: null, tempEndDate: null, currentSelection: DateSelectTypes.START_DATE}, () => this.onApply(false));
        }
    };

    onInputSelected = (newCurrentSelection) => {
        let {currentSelection} = this.state;

        if (currentSelection !== newCurrentSelection)
            this.setState({currentSelection: newCurrentSelection});
    };

    onDateSelect = ({startDate, endDate}, selectFromTextInput) => {

        if (selectFromTextInput) {
            this.setState({tempStartDate: startDate, tempEndDate: endDate, error: false},
                this.setTempStateOnInput);
        } else if (!ValueIsSet(startDate) && ValueIsSet(endDate)) {
            this.setState({tempStartDate: startDate, tempEndDate: endDate, currentSelection: DateSelectTypes.START_DATE, error: false},
                this.setTempStateOnInput);
        } else {
            this.setState({tempStartDate: startDate, tempEndDate: endDate, currentSelection: DateSelectTypes.END_DATE, error: false},
                this.setTempStateOnInput);
        }
    };

    onStartDateChange = (dateString) => {
        const {error, tempEndDate} = this.state;
        const {dateInputFormat} = this.props;

        const newStartDate = dateString === '' ? null : moment(dateString, dateInputFormat.format, true);

        if (this.startDateIsValid(newStartDate)) {

            this.onDateSelect(
                {
                    startDate: newStartDate,
                    endDate: tempEndDate
                }, true);
        } else if (!error) {
            this.setState({error: true});
        }
    };

    onEndDateChange = (dateString) => {
        const {error, tempStartDate} = this.state;
        const {dateInputFormat} = this.props;

        const newEndDate = dateString === '' ? null : moment(dateString, dateInputFormat.format, true);

        if (this.endDateIsValid(newEndDate)) {

            this.onDateSelect(
                {
                    startDate: tempStartDate,
                    endDate: newEndDate,
                }, true);
        } else if (!error) {
                this.setState({error: true});
            }
    };

    startDateIsValid = (startDate) => {
        const {tempEndDate} = this.state;
        const { isDayBlocked, isOutsideRange, minimumNights} = this.props;

        return (
            startDate === '' || startDate === null ||
            (startDate.isValid() && !isDayBlocked(startDate) && !isOutsideRange(startDate)
               && (tempEndDate === null || (tempEndDate.diff(startDate, 'days') >= minimumNights))
            )
        );
    };

    endDateIsValid = (endDate) => {
        const {tempStartDate} = this.state;
        const { isDayBlocked, isOutsideRange, minimumNights} = this.props;

        return (
            endDate === null ||
            (endDate.isValid() && !isDayBlocked(endDate) && !isOutsideRange(endDate)
                && (tempStartDate === null || ((tempStartDate.diff(endDate, 'days') * -1) >= minimumNights))
            )
        );
    };

    onInputBlur = () => {
        this.setState({error: false}, this.setTempStateOnInput);
    };

    setTempStateOnInput = () => {
        const {dateInputFormat} = this.props;
        const {tempStartDate, tempEndDate} = this.state;

        this.tempDatesInputRef.current.setDates(
            ValueIsSet(tempStartDate) ? tempStartDate.format(dateInputFormat.format) : null,
            ValueIsSet(tempEndDate) ? tempEndDate.format(dateInputFormat.format) : null,
        );
    };

    resetToInitialDates = () => {
        let {initialStartDate, initialEndDate} = this.props;

        this.setState({tempStartDate: initialStartDate, tempEndDate: initialEndDate, currentSelection: DateSelectTypes.START_DATE}, () => this.onApply(true));
    };

    setStateAndReadOnlyInputRef = (startDate, endDate) => {
        const {dateInputFormat} = this.props;
        this.setState({
            error: false,
            startDate: startDate,
            endDate: endDate,
            tempStartDate: startDate,
            tempEndDate: endDate,
            currentSelection: DateSelectTypes.START_DATE
        });
        const startDateText = ValueIsSet(startDate) ? startDate.format(dateInputFormat.format) : null;
        const endDateText = ValueIsSet(endDate) ? endDate.format(dateInputFormat.format) : null;

        this.readOnlyInputRef.current.setDates(startDateText, endDateText);
    };

    render() {
        let { onApply, onClear, onClose, onDateSelect, onEndDateChange, onInputBlur, onInputSelected,onStartDateChange} = this;
        let { tempDatesInputRef, readOnlyInputRef } = this;
        let {currentSelection, error, tempStartDate, tempEndDate} = this.state;
        let {
            disabled,
            readOnly,
            fullScreenOnMobile,
            fullWidth,
            initialVisibleMonth,
            inputBackground,
            inputBorderError,
            inputVariant,
            isDayBlocked,
            isDayHighlighted,
            isOutsideRange,
            minimumNights,
            hideClearButton,
            showDateResetButton,
            initialStartDate,
            initialEndDate
        } = this.props;

        return (
            <>
                <DateRangePickerView
                    currentSelection={currentSelection}
                    disabled={disabled}
                    readOnly={readOnly}
                    endDate={tempEndDate}
                    error={error}
                    fullScreenOnMobile={fullScreenOnMobile}
                    fullWidth={fullWidth}
                    initialVisibleMonth={initialVisibleMonth}
                    inputBackground={inputBackground}
                    inputBorderError={inputBorderError}
                    inputVariant={inputVariant}
                    isDayBlocked={isDayBlocked}
                    isDayHighlighted={isDayHighlighted}
                    isOutsideRange={isOutsideRange}
                    minimumNights={minimumNights}
                    onApply={onApply}
                    onClose={onClose}
                    onClear={onClear}
                    onDateSelect={onDateSelect}
                    onEndDateChange={onEndDateChange}
                    onInputBlur={onInputBlur}
                    onInputSelected={onInputSelected}
                    onStartDateChange={onStartDateChange}
                    readOnlyInputRef={readOnlyInputRef}
                    startDate={tempStartDate}
                    tempDatesInputRef={tempDatesInputRef}
                    hideClearButton={hideClearButton}
                    showResetDatesButton={showDateResetButton}
                    initialStartDate={initialStartDate}
                    initialEndDate={initialEndDate}/>
            </>

        )
    }
}

DateRangePicker.defaultProps = {
    dateInputFormat: DateRangeInputFormat.MonthDayYearSlash,
    disabled: false,
    readOnly: false,
    fullWidth: false,
    inputVariant: InputBaseVariant.OUTLINED,
    isDayBlocked: () => false,
    isDayHighlighted: () => false,
    isOutsideRange: () => false,
    minimumNights: 0
};

DateRangePicker.propTypes = {
    dateInputFormat: PropTypes.shape({
        format: PropTypes.string.isRequired, // moment format for displaying the value
        placeholder: PropTypes.string, // input placeholder
    }),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    fullWidth: PropTypes.bool,
    fullScreenOnMobile: PropTypes.bool,
    initialEndDate: CustomPropTypes.moment,
    initialStartDate: CustomPropTypes.moment,
    initialVisibleMonth: PropTypes.func,
    inputVariant: CustomPropTypes.enum(InputBaseVariant),
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,
    isOutsideRange: PropTypes.func,
    minimumNights: PropTypes.number, // minimum number of nights required between the start and end date
    onDatesChange: PropTypes.func,
    hideClearButton: PropTypes.bool,
    showDateResetButton: PropTypes.bool
};

export default DateRangePicker;