// This file was generated from com.worksmith.proposal.controller.RequestForProposalController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class RequestForProposalService extends Http {
    constructor() {
        super('/requestForProposals')
    }

    addAttachment(id, attachmentName, muteVendorNotifications, attachment) {
        let params = {
            attachmentName,
            muteVendorNotifications
        };

        return this.callApi( `${id}/attachments`, POST, null, params, null, true, attachment);
    }

    addAttachmentToMultipleRequests(requestIds, attachments) {
        let params = {
            requestIds
        };

        return this.callApi( `addAttachmentToMultipleRequests`, POST, null, params, null, true, attachments);
    }

    addAttachments(id, muteVendorNotifications, attachments) {
        let params = {
            muteVendorNotifications
        };

        return this.callApi( `${id}/attachments/multiple`, POST, null, params, null, true, attachments);
    }

    addComment(rfpId, rfpComment) {
        return this.callApi( `${rfpId}/comments`, POST, rfpComment, null, null, true);
    }

    addCoverage(requestId, params) {
        return this.callApi( `${requestId}/addCoverage`, PATCH, params, null, null, true);
    }

    addOpportunity(rfpId, opportunity) {
        return this.callApi( `${rfpId}/opportunities`, POST, opportunity, null, null, true);
    }

    approve(rfpId) {
        return this.callApi( `${rfpId}/approve`, PATCH, null, null, null, true);
    }

    bulkCancelRequest(rfpId, cancellationReasons, effectiveDate, muteVendorNotifications, muteClientNotifications, muteResourceNotifications) {

        let params = {
            effectiveDate,
            muteVendorNotifications,
            muteClientNotifications,
            muteResourceNotifications
        };

        return this.callApi( `${rfpId}/bulkCancelRequest`, PATCH, cancellationReasons, params, null, true);
    }

    cancel(rfpId, cancellationReasons, muteVendorNotifications, muteClientNotifications, muteResourceNotifications) {
        let params = {
            muteVendorNotifications,
            muteClientNotifications,
            muteResourceNotifications,
        };

        return this.callApi( `${rfpId}/cancel`, PATCH, cancellationReasons, params, null, true);
    }

    clientListSummaries(createdStartDate, createdEndDate, status, hasCampaign, complete, clientLocationIds, clientId, id, requiresClientAction) {
        let params = {
            createdStartDate,
            createdEndDate,
            status,
            hasCampaign,
            complete,
            clientLocationIds,
            clientId,
            id,
            requiresClientAction
        };

        return this.callApi( `clientListSummaries/search`, GET, null, params, null, true);
    }

    createActionItemForStaleRequests(days, emergencyDays) {
        let params = {
            days,
            emergencyDays
        };

        return this.callApi( `createActionItemForStaleRequests`, POST, null, params, null, true);
    }

    createAdHocJob(data, muteClientNotifications, muteVendorNotifications) {
        let params = {
            muteClientNotifications,
            muteVendorNotifications
        };

        return this.callApi(`createAdHocJob`, POST, data, params, null, true);
    }

    createOrUpdateRead(rfpId) {
        return this.callApi( `${rfpId}/comments/me/read`, POST, null, null, null, true);
    }

    createRequestAndAcceptPrimaryRateCardPricing(rateCardEntryId, requestForProposal) {
        return this.callApi( `createRequestAndAcceptPrimaryRateCardPricing/${rateCardEntryId}`, POST, requestForProposal, null, null, true);
    }

    createTelesalesTicket(rfpId) {
        return this.callApi( `${rfpId}/telesalesTickets`, POST, null, null, null, true);
    }

    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }

    deleteAttachment(id, attachmentId) {
        return this.callApi( `${id}/attachments/${attachmentId}`, DELETE, null, null, null, true);
    }

    deleteAttachments(attachmentIds) {
        let params = {
            attachmentIds
        };

        return this.callApi( `attachments`, DELETE, null, params, null, true);
    }

    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }

    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }

    findClientRequestCards(params) {
        return this.callApi( `findClientRequestCards`, POST, params, null, null, true);
    }

    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }

    findRequestCountPerLocations(params) {
        return this.callApi( `findRequestCountPerLocations`, POST, params, null, null, true);
    }

    getAdminFullObject(rfpId) {
        return this.callApi( `${rfpId}/adminFullObject`, GET, null, null, null, true);
    }

    getAllAssociatedOpportunities(rfpId) {
        return this.callApi( `${rfpId}/getAllAssociatedOpportunities`, GET, null, null, null, true);
    }

    getAllAutomationSummaries(requestId) {
        return this.callApi( `${requestId}/getAllAutomationSummaries`, GET, null, null, null, true);
    }

    getAttachments(id) {
        return this.callApi( `${id}/attachments`, GET, null, null, null, true);
    }

    getBids(rfpId) {
        return this.callApi( `${rfpId}/bids`, GET, null, null, null, true);
    }

    getCalendarData(assigneeId, startDate, endDate) {
        let params = {
            assigneeId,
            startDate,
            endDate
        };

        return this.callApi( `calendarData`, GET, null, params, null, true);
    }

    getClientBidSummaries(rfpId) {
        return this.callApi( `${rfpId}/bids/clientSummaries`, GET, null, null, null, true);
    }

    getClientRequestDetails(requestId) {
        return this.callApi( `${requestId}/getClientRequestDetails`, GET, null, null, null, true);
    }

    getComments(rfpId) {
        return this.callApi( `${rfpId}/comments`, GET, null, null, null, true);
    }

    getCountOfEachStatus(clientLocationIds, isEmergency) {
        let params = {
            clientLocationIds,
            isEmergency
        };

        return this.callApi( `getCountOfEachStatus`, GET, null, params, null, true);
    }

    getFullAssignmentListDetails(requestId) {
        return this.callApi( `${requestId}/getFullAssignmentListDetails`, GET, null, null, null, true);
    }

    getFullDetails(rfpId) {
        return this.callApi( `${rfpId}/fullDetails`, GET, null, null, null, true);
    }

    getLatestAutomationSummary(requestId) {
        return this.callApi( `${requestId}/getLatestAutomationSummary`, GET, null, null, null, true);
    }

    getMyUnreadComments(requestSearchParams) {
        return this.callApi( `comments/me/unread`, POST, requestSearchParams, null, null, true);
    }

    getMyUnreadMessageCounts(requestSearchParams) {
        return this.callApi( `messages/me/unreadCounts`, POST, requestSearchParams, null, null, true);
    }

    getOpportunities(rfpId) {
        return this.callApi( `${rfpId}/opportunities`, GET, null, null, null, true);
    }

    getOverallRequestStatistics() {
        return this.callApi( `overallRequestStatistics`, GET, null, null, null, true);
    }

    getRequestFullReports(userId) {
        let params = {
            userId
        };

        return this.callApi( `requestFullReport`, GET, null, params, null, true);
    }

    getRfpStatistics(requesterId) {
        let headers = {
            requesterId
        };

        return this.callApi( `statistics`, GET, null, null, headers, true);
    }

    getRfpUserStatistics(startDate, endDate) {
        let params = {
            startDate,
            endDate
        };

        return this.callApi( `statistics/users`, GET, null, params, null, true);
    }

    getUnreadCommentListSummaries(requestSearchParams) {
        return this.callApi( `clientListSummaries/search/unread`, POST, requestSearchParams, null, null, true);
    }

    getUserRFPTicketStats(userId) {
        let params = {
            userId
        };

        return this.callApi( `marketplaceOperationsStats`, GET, null, params, null, true);
    }

    getWalkthroughs(rfpId) {
        return this.callApi( `${rfpId}/walkthroughs`, GET, null, null, null, true);
    }

    //deprecated use insertWithAttachments instead
    insert(requestForProposal) {
        return this.callApi( `insert`, POST, requestForProposal, null, null, true);
    }

    insertWithAttachments(requestForProposal, attachments) {
        if(!ValueIsSet(attachments) || attachments.length === 0){
            attachments = []
        }
        return this.callApi( `insertWithAttachments`, POST, requestForProposal, null, null, true, attachments);
    }

    markAllCommentsRead(requestId) {
        return this.callApi( `${requestId}/markAllCommentsRead`, POST, null, null, null, true);
    }

    openContractRequestToBackupVendors(rfpId) {
        return this.callApi( `${rfpId}/openContractRequestToBackupVendors`, PATCH, null, null, null, true);
    }

    openRequestToDefaultMarket(rfpId) {
        return this.callApi( `${rfpId}/openToMarket`, PATCH, null, null, null, true);
    }

    reopenToMarket(rfpId, reopenClosedOpportunities) {
        let params = {
            reopenClosedOpportunities
        };

        return this.callApi( `${rfpId}/reopenToMarket`, PATCH, null, params, null, true);
    }

    requestCanceledByChecklist() {
        return this.callApi( `requestCanceledByChecklist`, POST, null, null, null, true);
    }

    search(createdStartDate, createdEndDate, status, complete) {
        let params = {
            createdStartDate,
            createdEndDate,
            status,
            complete
        };

        return this.callApi( `search`, GET, null, params, null, true);
    }

    searchMallRfpDRO(params) {
        return this.callApi( `searchMallRfpDRO`, POST, params, null, null, true);
    }

    searchRfpAdminLightObject(params) {
        return this.callApi( `searchRfpAdminLightSummary`, POST, params, null, null, true);
    }

    setCanReceiveAutomatedComments(requestId, canReceiveAutomatedComments) {
        return this.callApi( `${requestId}/setCanReceiveAutomatedComments`, POST, canReceiveAutomatedComments, null, null, true);
    }

    setOnHold(requestId, onHold) {
        return this.callApi( `${requestId}/setOnHold/${onHold}`, PUT, null, null, null, true);
    }

    setWorksmithNTE(requestId, value) {
        return this.callApi( `${requestId}/setWorksmithNTE/${value}`, PUT, null, null, null, true);
    }

    updateBillingConfiguration(requestId, billingProfileId) {
        return this.callApi( `${requestId}/updateBillingConfiguration/${billingProfileId}`, POST, null, null, null, true);
    }

    takeAutomationSnapshot(requestId) {
        return this.callApi( `${requestId}/takeAutomationSnapshot`, GET, null, null, null, true);
    }

    addNote(requestId, note) {
        return this.callApi( `${requestId}/notes`, POST, note, null, null, true);
    }

    //This will only work for vendors that are nonWorksmithClients
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }

    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }

    updateAllV2(requestUpdateParams) {
        return this.callApi( `updateAll`, PATCH, requestUpdateParams, null, null, true);
    }

    writeToReadRequestOverallSummary() {
        return this.callApi( `writeToReadRequestOverallSummary`, POST, null, null, null, true);
    }

    exportRequestsCSV(currentDate, emailAddress, requestSearchParams) {
        let params = {
            currentDate,
            emailAddress
        };

        return this.callApi( `exportRequestsCSV`, POST, requestSearchParams, params , null, true);
    }

    setIsExemptFromTeamStats(requestId, isExemptFromTeamStats) {
        return this.callApi( `${requestId}/setIsExemptFromTeamStats/${isExemptFromTeamStats}`, PATCH, null, null, null, true);
    }
}

export default RequestForProposalService;
