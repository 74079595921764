import React from 'react';
import PropTypes from 'prop-types';

import {AlignItems} from "../../../../enums/CSSEnums";
import Button from "../../../../components/Button/Button";
import Grid from "../../../../components/Grid/Grid";
import Text, {TextVariant} from "../../../../components/Text/Text";
import TextField, {TextFieldType} from "../../../../components/Inputs/TextField/TextField";
import CircularProgress from "../../../../components/Loader/CircularProgress";

const ArrivalForm = ({arrivalFormFields, handleChange, isSubmitting, submitArrivalInfo, toggleEdit}) => {

    return (
        <Grid container alignItems={AlignItems.FLEX_START} spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3} xs={12} md={8} alignItems={AlignItems.FLEX_START}>
                    <Grid item xs={12}>
                        <Text gutterBottom variant={TextVariant.BODY_1}>Parking Instructions</Text>
                        <TextField
                            fullWidth
                            initialValue={arrivalFormFields.parkingDirections}
                            name={'parkingDirections'}
                            onChange={value => handleChange('parkingDirections', value)}
                            placeholder={'i.e. Alley by the west entrance'}
                            type={TextFieldType.TEXT}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Text gutterBottom variant={TextVariant.BODY_1}>Please describe the service entrance location</Text>
                        <TextField
                            fullWidth
                            initialValue={arrivalFormFields.serviceEntranceDescription}
                            name={'serviceEntranceDescription'}
                            onChange={value => handleChange('serviceEntranceDescription', value)}
                            placeholder={'i.e. Service door in the west alley entrance'}
                            type={TextFieldType.TEXT}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Text gutterBottom variant={TextVariant.BODY_1}>Any additional notes regarding location access?</Text>
                        <TextField
                            fullWidth
                            initialValue={arrivalFormFields.additionalAccessInfo}
                            name={'additionalAccessInfo'}
                            onChange={value => handleChange('additionalAccessInfo', value)}
                            placeholder={'i.e. Best mall entrance is Entrance F'}
                            type={TextFieldType.TEXT}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={8} margin={'32px 0 0 0'}>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} onClick={toggleEdit}>CANCEL</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} primary onClick={submitArrivalInfo}>{isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default ArrivalForm;

ArrivalForm.propTypes = {
    arrivalFormFields: PropTypes.shape({
        parkingDirections: PropTypes.string,
        serviceEntranceDescription: PropTypes.string,
        additionalAccessInfo: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    submitArrivalInfo: PropTypes.func,
    toggleEdit: PropTypes.func
}
