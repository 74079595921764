import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import BidDetailsView from "./BidDetailsView";
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import BidService from "../../services/api/BidService";
import ScheduleHandlerService from "../../services/api/ScheduleHandlerService";
import RequestForProposalService from "../../services/api/RequestForProposalService";
import {ValueIsSet} from "../../../Helpers";

const graphQLService = new GraphQLServiceClass();
const bidService = new BidService();
const requestService = new RequestForProposalService();
const scheduleHandlerService = new ScheduleHandlerService();

class BidDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bid: {},
            canAcceptBid: true,
            loading: true,
        };
    }

    componentDidMount = () => {
        const {bidId, isAdmin} = this.props;

        graphQLService.findOneById(
            bidId,
            GraphQLObjectType.BID,
            BidDetailsGraphQLString
        ).then((data) => {
            this.setState({loading: false, bid: data});
        })

        if (!isAdmin) {
            bidService.canAcceptBid(bidId).then((data) => {
                this.setState({canAcceptBid: data});
                }
            )
        }
    }

    acceptBid = (schedules) => {
        const {bidId, onAccept, startLoad} = this.props;
        const {bid} = this.state;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.accept({id: bidId, concurrentWithCounteringSchedule: ValueIsSet(schedules)})
            .then((data) => {
               if (schedules) {
                   scheduleHandlerService.makeCounterOffer({scheduleHandlerId: bid.scheduleHandler.id, schedules: schedules, concurrentWithProposalAcceptance: true})
                       .then(() => {
                           onAccept();
                           this.setState({loading: false});

                       })
               } else {
                   onAccept();
                   this.setState({loading: false});
               }
            })
    }

    acceptAndSchedule = (schedule) => {
        const {bidId, onAccept, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.acceptAndSchedule({bidId: bidId, scheduleId: schedule.id})
            .then((data) => {
                onAccept();
                this.setState({loading: false});
            })
    }

    requestApproval = () => {
        const {bidId, onAccept, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.requestApproval(bidId)
            .then((data) => {
                onAccept();
                this.setState({loading: false});
            })
    }

    requestRevision = (requestedRevision) => {
        const {bidId, onRequestRevision, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.requestRevision(bidId, requestedRevision)
            .then((data) => {
                onRequestRevision();
                this.setState({loading: false});
            })
    }

    cancelRequest = (canceledRequestObject) => {
        const {onRequestCancel, startLoad} = this.props;
        const {bid} = this.state;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        let requestId = bid.opportunity.request.id;
        requestService.cancel(
            requestId,
            canceledRequestObject,
            false,
            false,
            false,
        ).then(() => {
                onRequestCancel();
                this.setState({loading: false});
            })
    }

    decline = (declineReasonId, customReason) => {
        const {bidId, onDecline, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.decline(bidId , declineReasonId, customReason)
            .then((data) => {
                onDecline();
                this.setState({loading: false});
            })
    }

    render() {
        const {acceptBid, acceptAndSchedule, cancelRequest, decline, requestApproval, requestRevision} = this;
        const {bid, canAcceptBid, loading} = this.state;
        const {
            bidId,
            isAdmin,
            isRegionalManager,
            isEstimate,
        } = this.props;

        return (
            <BidDetailsView
                acceptBid={acceptBid}
                acceptAndSchedule={acceptAndSchedule}
                activeObligation={bid.activeObligation}
                additionalApprovalAmount={bid.additionalApprovalAmount}
                additionalTerms={bid.additionalTerms}
                bidId={bidId}
                bidItems={bid.bidItems}
                bidSubmittedActivity={bid.bidSubmittedActivity}
                cancelRequest={cancelRequest}
                canAcceptBid={canAcceptBid}
                canRequestRevision={bid.canRequestRevision}
                createdTimestamp={bid.createdTimestamp}
                decline={decline}
                duration={bid.duration}
                frequency={bid.frequency}
                isCompleted={bid.isCompleted}
                isAdmin={isAdmin}
                isRegionalManager={isRegionalManager}
                isEstimate={isEstimate}
                latestRevision={bid.latestRevision}
                loading={loading}
                requestApproval={requestApproval}
                requestedFrequency={bid.opportunity ? bid.opportunity.request.frequency : null}
                requestRevision={requestRevision}
                requestedRevision={bid.requestedRevision}
                request={bid.opportunity ? bid.opportunity.request : null}
                scheduleHandler={bid.scheduleHandler}
                status={bid.status}
                totalRetailPrice={bid.totalRetailPrice}
                vendorLocation={bid.opportunity ? bid.opportunity.vendorLocation : null}/>
        )
    }
}

BidDetails.propTypes = {
    bidId: PropTypes.number,
    isAdmin: PropTypes.bool,
    isRegionalManager: PropTypes.bool,
    isEstimate: PropTypes.bool,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func,
    onRequestCancel: PropTypes.func,
    onRequestRevision: PropTypes.func,
    startLoad: PropTypes.func,
};

const BidDetailsGraphQLString = `
    additionalApprovalAmount
    additionalTerms
    bidItems {
        isDoable
        lineItemType {
            name
        }
        quantity 
        name
        unitRetailPrice
        rateCardEntry {
            id
            rateCard {
                id
            }
            title
            itemName
            scopeOfWork
            unitWholesalePrice
            unitRetailPrice
            sequencePosition
            displayValue
        }
    }
    bidSubmittedActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    canRequestRevision
    createdTimestamp
    duration
    frequency {
        recurrenceCount
        recurrenceType
        repeatEvery
    }
    isCompleted
    latestRevision
    opportunity {
        vendorLocation {
            vendor {
                officialName
            }
        }
        request {
            id
            newStatus
            type
            frequency {
                recurrenceCount
                recurrenceType
                repeatEvery
            }
            serviceLine {
                id
                name
                useTicketing
            }
        }
    }
    requestedRevision
    scheduleHandler {
        id
        openScheduleOptions {
            id
            isExpired
            status
            schedule {
                arrivalEndTime
                arrivalStartTime
                date
                dayOfMonth
                daysOfWeek
                duration
                recurrenceType
                repeatEvery
                returnDate
            }
        }
    }
    status
    totalRetailPrice
`;

export default BidDetails;