import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MediaLibraryForm from "./MediaLibraryForm";
import MediaLibraryView from "./MediaLibraryView";

import {AlignItems} from "../../../enums/CSSEnums";
import Accordion from '../../../components/Accordion/Accordion'
import Grid from "../../../components/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import {IconType} from "../../../enums/MaterialEnums";
import Text, {TextVariant} from "../../../components/Text/Text";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientLocationService from "../../../services/api/ClientLocationService";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientLocationService = new ClientLocationService();

const MediaLibraryPanel = ({location, readOnly}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [fileUpload, setFileUpload] = useState([]);
    const [formFields, setFormFields] = useState({file: []});
    const [initialAttachments, setInitialAttachments] = useState(null);

    const refetchMediaData = async () => {
        const mediaData = await asyncGraphQLServiceClass.findOneById(location.id, GraphQLObjectType.CLIENT_LOCATION, mediaFields);
        const files = mediaData.clientLocationAttachments.map(file => ({
            ...file,
            url: file.singleUseUrl,
            name: file.title,
        }));
        setInitialAttachments(files);
        setFileUpload(files)
    };

    const submitMedia = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let submitData = [];
        submitData = formFields.file.filter(file => !file.id);

        const preEditAttachmentListIds = initialAttachments.map(attachment => {
            return attachment.id;
        });

        const postEditAttachmentListIds = fileUpload.map(attachment => {
            return attachment.id
        });

        let deleteData = preEditAttachmentListIds.filter(attachmentId => {
            return !postEditAttachmentListIds.includes(attachmentId);
        });


        try {
            if(submitData.length > 0) {
                await clientLocationService.addAttachments(location.id, submitData, "");
            }
            if(deleteData.length > 0 ){
                await clientLocationService.deleteAttachments(deleteData);
            }
             await refetchMediaData();
            setIsEditing(false);
            setIsSubmitting(false);
        } catch (err) {
            console.error(err)
            setIsEditing(false);
            setIsSubmitting(false);
        }

    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const addAttachment = files => {
        const attachments = files.map(file => ({
            ...file,
            url: file.url,
            name: file.name,
        }));
        setFileUpload(attachments);
        setFormFields({file: files})
    };


    useEffect(() => {
        if (location) {
            const files = location.clientLocationAttachments.map(file => ({
                ...file,
                url: file.singleUseUrl,
                name: file.title,
            }));
            setFileUpload(files);
            setInitialAttachments(files)
        }
    }, []);

    const panelSummary = (
        <Grid container>
            <Grid container item xs={2} md={1} alignItems={AlignItems.CENTER}>
                <Icon name={IconType.PHOTO_LIBRARY} />
            </Grid>
            <Grid container item xs={10} md={11} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H5}>
                    {"Media Library"}
                </Text>
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            summary={panelSummary}
            details={isEditing && !readOnly ?
                <MediaLibraryForm
                    addAttachment={addAttachment}
                    fileUpload={fileUpload}
                    isSubmitting={isSubmitting}
                    submitMedia={submitMedia}
                    toggleEdit={toggleEdit}
                />
                :
                <MediaLibraryView
                    fileUpload={fileUpload}
                    readOnly={readOnly}
                    toggleEdit={toggleEdit}
                />
            }
            large
        />
    )
};

export default MediaLibraryPanel;

MediaLibraryPanel.propTypes = {
    readOnly: PropTypes.bool,
    location: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        client: PropTypes.shape({
            adminUsers: PropTypes.arrayOf(PropTypes.shape({
                clientRole: PropTypes.string,
                companyName: PropTypes.string,
                displayName: PropTypes.string,
                email: PropTypes.string.isRequired,
                phone: PropTypes.string,
                clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
                    clientLocation: PropTypes.shape({
                        locationCode: PropTypes.string,
                        client: PropTypes.shape({
                            officialName: PropTypes.string.isRequired
                        }).isRequired
                    })
                })).isRequired
            })),
            id: PropTypes.number.isRequired
        }).isRequired,
        clientLocationAttachments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            singleUseUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })),
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
        email: PropTypes.string,
        industry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            internalNotes: PropTypes.string,
            coi: PropTypes.string,
            address: PropTypes.string
        }),
        openingDate: PropTypes.string,
        regionalManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        locationManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        clientLocationOperationDetails: PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            unionLaborRequirement: PropTypes.string,
            occurrenceInsuranceRequirement: PropTypes.number,
            aggregateInsuranceRequirement: PropTypes.number,
            additionalInsuranceDetails: PropTypes.string,
            locationSquareFootage: PropTypes.number,
            hasLadderOnSite: PropTypes.bool,
            ladderHeightFt: PropTypes.number,
            parkingDirections: PropTypes.string,
            serviceEntranceDescription: PropTypes.string,
            additionalAccessInfo: PropTypes.string
        }),
        locationHours: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            dayOfWeekInteger: PropTypes.number,
            openTime: PropTypes.string,
            closeTime: PropTypes.string,
            closed: PropTypes.bool,
        })),
        id: PropTypes.number,
        locationCode: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired
    })
};

const mediaFields = `
        clientLocationAttachments {
        id
        singleUseUrl
        title
    }
`;