import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import {AlignItems, Cursor, Display, JustifyContent, TextAlign} from "worksmith/enums/CSSEnums";
import Box from "@mui/material/Box";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import {DialogSize} from "worksmith/enums/DialogSize";
import Grid from "../../../../components/Grid/Grid.web";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {IconColor} from "worksmith/components/Icon/Icon.web";
import PropTypes from 'prop-types';
import React, {Fragment, useState} from 'react';
import RequestStateLayout from "../RequestStateLayout";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import styled from "styled-components";
import Text, {TextColor, TextVariant} from "../../../../components/Text/Text.web";
import {ValueIsSet} from "../../../../../Helpers";

const ClickableLinkTextBox = styled(Box)`
    cursor: ${Cursor.POINTER};
`;

const ContactingPremiumVendors = (props) => {
    const {
        chatRef,
        conversationHasUnreadScopeClarificationMessage,
        request,
    } = props;

    let [requestedVendorModalOpen, setRequestedVendorModalOpen] = useState(false);
    let [vendorPremiumModalOpen, showVendorPremiumModal] = useState(false);

    const isRequestedVendor = ValueIsSet(request.primaryVendorRateCard) && request.primaryVendorRateCard.isRequestedVendor;

    const vendorTypeString = isRequestedVendor ? "Requested" : "Premium";

    let status = 'Contacting your ' + vendorTypeString + ' Vendor';
    let statusColor = TextColor.PRIMARY;

    let responses = [
        request.numberOfActiveBids > 0,
        request.numberOfActiveEstimates > 0,
        request.numberOfActiveWalkthroughs > 0
    ];

    let anyResponses = responses.filter(hasResponses => hasResponses).length > 0;

    if(request.numberOfActiveWalkthroughs > 0) {
        status = 'Your ' + vendorTypeString +' Vendor Submitted a Walkthrough';
        statusColor = TextColor.ERROR;
    }
    else if (anyResponses) {
        status = 'Your ' + vendorTypeString +' Vendor Submitted Pricing';
        statusColor = TextColor.ERROR;
    }

    const scrollToChat = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    };

    return (
        <>
            {conversationHasUnreadScopeClarificationMessage ?
                <Grid margin={'0 0 2rem 0'} container xs={12} alignItems={AlignItems.FLEX_START}>
                    <Grid margin={'.5rem 0 0 0'} item xs={5}>
                        <Text fullwidth variant={TextVariant.BODY_2}>
                            Vendor has requested scope clarification.
                        </Text>
                    </Grid>
                    <Grid item xs={7}>
                        <Button onClick={()=>ValueIsSet(chatRef) && ValueIsSet(chatRef.current) ? scrollToChat(chatRef) : null} secondary variant={ButtonVariant.OUTLINED}>Clarify Scope</Button>
                    </Grid>
                </Grid>
                :
                null
            }
            <RequestStateLayout
                description={(
                    <Fragment>

                        {/*premium vendor modal*/}
                        <SimpleDialog
                            size={DialogSize.MEDIUM}
                            onClose={() => showVendorPremiumModal(false)}
                            open={vendorPremiumModalOpen}
                            PaperProps={{style: {padding: 20}}}
                            body={(
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Text semiBold italic variant={TextVariant.BODY_1} align={TextAlign.CENTER}>
                                            You've been assigned a Worksmith Premium Vendor at a pre-negotiated rate for this job!
                                        </Text>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {anyResponses ?
                                            <Text color={TextColor.ERROR} variant={TextVariant.BODY_1}>
                                                You received pricing from your assigned Premium Vendor. The request has NOT been released to any other vendors so please accept or request a revision as soon as possible.
                                            </Text>
                                            :
                                            <Text color={TextColor.ERROR} variant={TextVariant.BODY_1}>
                                                You’ve been assigned a Worksmith Premium Vendor at a pre-negotiated rate for this job. You will be notified when they respond.
                                            </Text>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text semiBold variant={TextVariant.BODY_1}>
                                            What does this mean?
                                        </Text>
                                        <Text variant={TextVariant.BODY_1}>
                                            To provide you with the best vendor quality and pricing, some jobs on Worksmith will be automatically assigned to a Worksmith Premium Vendor
                                        </Text>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text semiBold variant={TextVariant.BODY_1}>
                                            What are some of the benefits?
                                        </Text>
                                        <Text variant={TextVariant.BODY_1}>
                                            -Premium Vendors can only submit competitive rates that have been pre-negotiated by Worksmith.
                                        </Text>
                                        <Box mt={2}>
                                            <Text variant={TextVariant.BODY_1}>
                                                -Receiving one estimate per request expedites the approval process and job completion time.
                                            </Text>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text italic variant={TextVariant.BODY_1}>
                                            *Premium Vendors are limited to select service lines and geographies
                                        </Text>
                                    </Grid>
                                </Grid>
                            )}
                            title={
                                <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                                    <Text variant={TextVariant.H4}>
                                        Worksmith Premium Vendors
                                    </Text>
                                </Box>
                            }>
                        </SimpleDialog>

                        {/*requested vendor modal*/}
                        <AlertDialog
                            body={"Requested vendors are typically chosen specifically by our clients or are a legacy vendor you brought to the network."}
                            onClose={() => setRequestedVendorModalOpen(false)}
                            open={requestedVendorModalOpen}
                            title={"Client Requested Vendors"}
                            />
                        <ClickableLinkTextBox
                            mt={4}
                            onClick={() => isRequestedVendor ? setRequestedVendorModalOpen(true) : showVendorPremiumModal(true)}
                            display={Display.FLEX}>
                            <Text
                                bold
                                display={Display.INLINE}
                                color={TextColor.SECONDARY}
                                variant={TextVariant.BODY_1}>
                                What is a {vendorTypeString} Vendor
                            </Text>
                            <Box
                                ml={1}
                                display={Display.FLEX}
                                alignItems={AlignItems.CENTER}>
                                <HelpOutlineIcon color={IconColor.SECONDARY}/>
                            </Box>
                        </ClickableLinkTextBox>
                    </Fragment>
                )}
                status={status}
                statusColor={statusColor}/>
        </>

    )
};

ContactingPremiumVendors.propTypes = {
    chatRef: PropTypes.object,
    conversationHasUnreadScopeClarificationMessage: PropTypes.bool,
    request: PropTypes.shape({
        id: PropTypes.number,
        market: PropTypes.string,
        hasPreferredVendor: PropTypes.bool,
        numberOfActiveBids: PropTypes.number,
        numberOfActiveEstimates: PropTypes.number,
        numberOfActiveWalkthroughs: PropTypes.number,
        targetVendorLocation: PropTypes.shape({
            vendor: PropTypes.shape({
                officialName: PropTypes.string
            })
        }),
    })
};

export default ContactingPremiumVendors;