import React from 'react';
import PropTypes from 'prop-types';

import {AlignItems} from "../../../enums/CSSEnums";
import Button, {ButtonVariant} from "../../../components/Button/Button";
import {Color} from "../../../enums/Color";
import Grid from "../../../components/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import {IconType} from "../../../enums/MaterialEnums";
import Text, {TextVariant} from "../../../components/Text/Text";

const LocationSpecificationsView =
    ({
         locationSpecificationsFormFields,
         readOnly,
         toggleEdit
    }) => {
    const LabelText = ({text}) => (
        <Text variant={TextVariant.BODY_2} color={Color.TEXT_SECONDARY} gutterBottom={true} semiBold>{text}</Text>
    )

    const ContentText = ({text}) => (
        <Text variant={TextVariant.BODY_1} gutterBottom={true}>{text ? text : '—'}</Text>
    )

    const floors = locationSpecificationsFormFields.clientLocationFloors.map((floor, idx) => (
        <Grid item key={`floor - ${idx}`} xs={12}>
            <LabelText text={`Floor ${idx + 1}`} />
            <ContentText text={floor.ceilingHeightFt ? `${floor.ceilingHeightFt}ft Ceilings` : '— ft Ceilings'} />
            <ContentText text={floor.bathroomsCount ? `${floor.bathroomsCount} Bathrooms` : '0 Bathrooms'} />
            <ContentText text={floor.kitchensCount ? `${floor.kitchensCount} Kitchens` : '0 Kitchens'} />
            <ContentText text={floor.fittingRoomsCount ? `${floor.fittingRoomsCount} Fitting Rooms` : '0 Fitting Rooms'} />
        </Grid>
    ))

    return (
        <Grid container alignItems={AlignItems.FLEX_START}>
            <Grid container item xs={12} alignItems={AlignItems.FLEX_START}>
                <Grid container item xs={12} md={4} spacing={3} alignItems={AlignItems.FLEX_START}>
                    <Grid item xs={12}>
                        <LabelText text={'Location Sqft'} />
                        <ContentText text={locationSpecificationsFormFields.locationSquareFootage} />
                    </Grid>
                    {floors}
                </Grid>
                <Grid container item xs={12} md={4} spacing={3} alignItems={AlignItems.FLEX_START}>
                    <Grid item xs={12}>
                        <LabelText text={'Is there a ladder on site?'} />
                        <ContentText
                            text={locationSpecificationsFormFields.hasLadderOnSite ?
                                locationSpecificationsFormFields.ladderHeightFt ?
                                    `Yes - ${locationSpecificationsFormFields.ladderHeightFt}ft`
                                    :
                                    'Yes - height not specified'
                                :
                                locationSpecificationsFormFields.hasLadderOnSite === false ?
                                'No' : '—'
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LabelText text={'General Vendor Notes'} />
                        <ContentText
                            text={locationSpecificationsFormFields.vendorNotes ?
                                `${locationSpecificationsFormFields.vendorNotes}`
                                : '—'
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            {
                !readOnly ?
                    <Grid container item xs={8} margin={'32px 0 0 0'}>
                        <Grid item xs={2}>
                            <Button fullWidth variant={ButtonVariant.OUTLINED} startIcon={<Icon name={IconType.EDIT} />} onClick={toggleEdit}>EDIT</Button>
                        </Grid>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
};

export default LocationSpecificationsView;

LocationSpecificationsView.propTypes = {
    locationSpecificationsFormFields: PropTypes.shape({
        locationSquareFootage: PropTypes.number,
        hasLadderOnSite: PropTypes.bool,
        ladderHeightFt: PropTypes.number,
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
    }),
    readOnly: PropTypes.bool,
    toggleEdit: PropTypes.func
}
