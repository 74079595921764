import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import {Debounce, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {Display, FlexDirection, JustifyContent} from "worksmith/enums/CSSEnums";
import FileUpload from "worksmith/components/FileUpload/FileUpload";
import Grid from 'worksmith/components/Grid/Grid';
import {LocalStorageKeys} from "worksmith/services/utilities/HttpEnums";
import MessageList from "./MessageList";
import PortalType from "worksmith/enums/api/user/PortalType";
import TextField, {TextFieldVariant} from "worksmith/components/Inputs/TextField/TextField";
import Text from "worksmith/components/Text/Text";

const StyledConversationContainer = styled.div`
    display: ${Display.FLEX};
    flex-direction: ${FlexDirection.COLUMN};
`;

const StyledMessagesContainer = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 25em;
    padding: 0 8px;
`;

const StyledButtonContainer = styled.div`
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
`;

const StyledAttachmentsContainer = styled.div`
  font-size: .875em;
  
  span {
    font-weight: 400;
  }
`;

const StyledText = styled(Text)`
  && {
    margin-bottom: 24px;
  }
`;

const ConversationView = (props) => {
    let {
        clientLocationId,
        initialContent,
        initialFiles,
        disableSubmit,
        isOpen,
        loading,
        messages,
        onFileUploadChange,
        onTextFieldChange,
        textFieldHidden,
        textFieldRef,
        writeMessage} = props;

    if (loading) {
        return (
            <Grid container justify={JustifyContent.CENTER}>
                <CircularProgress/>
            </Grid>
        );
    }

    let debouncedOnChange = Debounce(onTextFieldChange, 50);
    const portalType = localStorage.getItem(LocalStorageKeys.PORTAL_TYPE);
    const canUploadFromMediaLibrary = portalType === PortalType.CLIENT || portalType === PortalType.ADMIN;

    return ValueIsSet(messages) ?
        <StyledConversationContainer>
            <StyledMessagesContainer>
                <MessageList isOpen={isOpen} messages={messages}/>
            </StyledMessagesContainer>
            {
                !textFieldHidden ?
                    <Fragment>
                        <TextField initialValue={initialContent}
                                   onChange={debouncedOnChange}
                                   variant={TextFieldVariant.OUTLINED}
                                   ref={textFieldRef}
                                   rowsMax={5}
                                   rows={3}
                                   multiline
                                   fullWidth/>
                        <StyledButtonContainer>
                            <div>
                                <Button disabled={disableSubmit}
                                        debounce
                                        variant={ButtonVariant.CONTAINED}
                                        primary
                                        onClick={writeMessage}>
                                    Add Message
                                </Button>
                            </div>
                        </StyledButtonContainer>
                        <StyledAttachmentsContainer>
                            <FileUpload
                                allowMediaLibrary={canUploadFromMediaLibrary}
                                initialFiles={initialFiles}
                                locationId={clientLocationId}
                                multiple
                                onChange={onFileUploadChange}
                            />
                        </StyledAttachmentsContainer>
                    </Fragment>
                    :
                    null
            }
        </StyledConversationContainer>
        :
        <Text>
            No Conversation Available.
        </Text>;
};

ConversationView.defaultProps = {
    textFieldHidden: false,
    isOpen: true
};

ConversationView.propTypes = {
    disableSubmit: PropTypes.bool.isRequired,
    initialContent: PropTypes.string,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    messages: PropTypes.arrayOf(PropTypes.shape({
        author: PropTypes.shape({
            id: PropTypes.number,
            nameWithRole: PropTypes.string
        }),
        content: PropTypes.string,
        createdTimestamp: PropTypes.string,
        id: PropTypes.id
    })),
    onFileUploadChange: PropTypes.func,
    onTextFieldChange: PropTypes.func.isRequired,
    textFieldRef: PropTypes.object,
    writeMessage: PropTypes.func.isRequired
};

export default ConversationView;