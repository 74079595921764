import React, {Component} from 'react';
import ClientService from 'worksmith/services/api/ClientService';
import RequestForProposalService from 'worksmith/services/api/RequestForProposalService'
import ServiceLineService from 'worksmith/services/api/ServiceLineService';
import * as custom from "worksmith/helpers/CustomTcombTypes";
import moment from 'moment';
import Button, {ButtonVariant} from "worksmith/components/Button/Button.web";
import {UNSET} from "worksmith/helpers/Constants";
import {GetKeyByValue, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {isAutomationTargeted} from "worksmith/helpers/AutomationHelper";
import Grid from "worksmith/components/Grid/Grid.web";
import {JustifyContent} from "worksmith/enums/CSSEnums";
import FileUpload from "worksmith/components/FileUpload/FileUpload";
import {Color} from "worksmith/enums/Color";
import styled from "styled-components";
import NewRequestStatus from "worksmith/enums/api/proposal/NewRequestStatus";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import ServiceLineType from "worksmith/enums/ServiceLineType";
import WalkthroughPreference from "worksmith/enums/api/proposal/WalkthroughPreference";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import {DisplayErrorNotification} from "../../helpers/SweetAlertHelpers";

const requestService = new RequestForProposalService();
const clientService = new ClientService();
const serviceLineService = new ServiceLineService();

const t = require('tcomb-form');
const Form = t.form.Form;

const ErrorText = styled.div`color: ${Color.RED}`;

const DateArrayToMoment = (dateArray) => {
    const dateString = `${parseInt(dateArray[1], 10) + 1}-${parseInt(dateArray[2], 10)}-${parseInt(dateArray[0], 10)}`;
    return moment(dateString, 'MM-DD-YYYY');
};


class EditRequestForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            request: props.request,
            formData: {
                id: props.request.id,
                targetType: props.request.target.type,
                targetDate: props.request.target.date,
                walkthroughPreference: props.request.walkthroughPreference,
                timePreference: props.request.timePreference,
                poNumber: props.request.poNumber,
                description: props.request.description,
                customAnswers: props.request.customAnswers,
                worksmithNTE: props.request.worksmithNTE
            },
            isEmergencyRequest: false,
            invalidDate: false,
            questionIdLookup: {},
            attachmentList: []
        };

        this.isCompleted = props.request.newStatus === NewRequestStatus.COMPLETED;
        this.requestCanBeUpdated = !this.isCompleted || this.props.isForNonWorksmithClient;
        this.onChange = this.onChange.bind(this);

        this.formRef = React.createRef();
    }

    onChange = (changedFormData) => {
        let {request} = this.props;
        let dateChangedAndNotNull = changedFormData.targetDate && changedFormData.targetDate !== this.state.formData.targetDate;

        if(dateChangedAndNotNull) {
            let enteredDate = changedFormData.targetDate;

            if (moment(enteredDate).format('YYYY-MM-DD') !== request.target.date) {
                let invalidDate = moment().startOf('day').isAfter(moment(enteredDate));
                this.setState({isEmergencyRequest: custom.isEmergencyRequest(enteredDate), invalidDate})
            } else {
                this.setState({isEmergencyRequest: custom.isEmergencyRequest(enteredDate), invalidDate: false})
            }
        }

        this.setState({formData: changedFormData});
    };

    onAttachmentsChanged = (attachmentList) => {
        this.setState({attachmentList});
    };

    getAttachmentEditPromises = () => {
        const preEditAttachmentList = this.props.request.rfpAttachments;
        const requestId = this.props.request.id;
        const {attachmentList} = this.state;

        const preEditAttachmentListIds = preEditAttachmentList.map(attachment => {
            return attachment.id;
        });

        const postEditAttachmentListIds = attachmentList.map(attachment => {
            return attachment.id
        });

        let attachmentsToAdd = attachmentList.filter(attachment => {
            return !preEditAttachmentListIds.includes(attachment.id)
        });

        let attachmentIdsToDelete = preEditAttachmentListIds.filter(attachmentId => {
            return !postEditAttachmentListIds.includes(attachmentId);
        });

        let attachmentEditPromises = [];
        if (ValueIsSet(attachmentsToAdd) && attachmentsToAdd.length > 0)
            attachmentEditPromises.push(requestService.addAttachments(requestId, null, attachmentsToAdd));

        if(ValueIsSet(attachmentIdsToDelete) && attachmentIdsToDelete.length > 0)
            attachmentEditPromises.push(requestService.deleteAttachments(attachmentIdsToDelete));

        return attachmentEditPromises;
    };

    getRequiredFieldText = () => {
        let {isForNonWorksmithClient} = this.props;

        return !isForNonWorksmithClient ?
            <ErrorText>Required Field</ErrorText>
            :
            undefined;
    };

    addRequiredLabelIfNeeded = (question) => {
        let {isForNonWorksmithClient} = this.props;

        if(isForNonWorksmithClient)
            return question;
        else
            return question + " *"
    };

    getTitle = () => {
        let {isForNonWorksmithClient, request} = this.props;
        if(isForNonWorksmithClient)
            return <h4>Edit Job Details</h4>;
        else
            return <div>
                        <h4>Editing Request #{request.id}</h4>
                        <div className="left">
                            <label>* Required</label>
                        </div>
                   </div>;
    };

    submit = () => {
        let {formData, request, invalidDate, questionIdLookup, attachmentList} = this.state;
        let {getAttachmentEditPromises, requestCanBeUpdated} = this;
        let {clientId, onCancel, handleSuccess, isForNonWorksmithClient} = this.props;

        if (!requestCanBeUpdated && formData.poNumber !== request.poNumber) { // only PO number should be editable for completed worksmith requests
            let updatedRequest = {id: request.id, poNumber: formData.poNumber};
            clientService.updateRequestForProposal(clientId, request.clientLoocation.id, request.id, false, updatedRequest).then(() => {
                handleSuccess(attachmentList);
            }).finally(() => {
                this.setState({loading: false});
            });
        } else if (!requestCanBeUpdated) {
            this.setState({loading: false});
            onCancel();
        } else if (!invalidDate && this.formRef.current.validate().isValid()) {

            let getCustomAnswers = () => {
                let formattedAnswers = [];
                let keys = Object.keys(formData);
                keys.forEach(function (key) {
                    if (!isNaN(key)) {
                        let a = {
                            question: {
                                id: key
                            },
                            answer: formData[key]
                        };

                        if (questionIdLookup[a.question.id] && questionIdLookup[a.answer]) {
                            a.answerOptionId = questionIdLookup[a.question.id][a.answer];
                        }

                        formattedAnswers.push(a);
                    }
                });

                return formattedAnswers;
            };


            let submitData = !isForNonWorksmithClient ? {
                    target: {
                        date: DateArrayToMoment(formData.targetDate).format('YYYY-MM-DD'),
                        type: formData.targetType
                    }
                }
                :
                {};
            submitData.customAnswers = getCustomAnswers();
            let mergedData = Object.assign(formData, submitData);

            delete mergedData.targetDate;
            delete mergedData.targetType;

            if (mergedData.worksmithNTE === '')
                mergedData.worksmithNTE = UNSET;

            if(isForNonWorksmithClient){
                mergedData.poNumber = null;
                mergedData.timePreference = "No Preference";
                mergedData.walkthroughPreference = WalkthroughPreference.OPTIONAL;
                mergedData.target = {
                    date: moment().add(1,'days').format(MomentFormat.ISODate), // just needs to be a date in the future
                    type: "ON_DATE"
                }

            }

            const requestEditPromise = clientService.updateRequestForProposal(clientId,
                                                        request.clientLocation.id,
                                                        request.id,
                                                        false,
                                                        mergedData);
            const attachmentPromises = getAttachmentEditPromises();

            Promise.all([requestEditPromise, ...attachmentPromises]).then(() => {
                handleSuccess();
            }, () => {
                DisplayErrorNotification("Failed to edit request.");
            }).finally(() => {
                this.setState({loading: false});
            });

        }else{
            this.setState({loading: false});
        }
    };

    startLoading = () => {
        this.setState({loading: true});
    };

    fetchRfpQuestions(serviceLineId){
        return serviceLineService.getRfpQuestions(serviceLineId);
    }

    // Life Cycle Methods ------------------------------------
    componentWillMount(){
        let _this = this;
        let {formData} = this.state;
        let {request} = this.props;
        let attachmentList = [];

        if (ValueIsSet(request.rfpAttachments) && request.rfpAttachments.length > 0) {
            attachmentList = request.rfpAttachments.map((attachment) => (
                {
                    id: attachment.id,
                    url: attachment.singleUseUrl,
                    name: attachment.fileName
                }
            ));
        }
        this.setState({attachmentList});

        _this.fetchRfpQuestions(request.serviceLine.id).then(function (data) {
            let rfpQuestions = data;
            let questionIdLookup = {};

            rfpQuestions.forEach(function (question) {
                if(question.options && question.options.length > 0) {
                    questionIdLookup[question.id] = { };

                    question.options.forEach(function(option) {
                        questionIdLookup[question.id][option.answer] = option.id;
                    });
                }
                formData.customAnswers.forEach(function(caData) {
                    if(caData.question.id === question.id){
                        formData[question.id] = caData.answer;
                    }
                })
            });
           _this.setState({formData, rfpQuestions, questionIdLookup});
        }).then(function(){
            let formDataCopy = formData;
            // I'm sorry. This date input makes me nuts
            let psd = formData.targetDate.split('-');

            // If the month has a leading zero, remove it.
            // This dang "date input" can't handle it.
            if(psd[1].split('')[0] === '0'){
                // date input starts Jan at 0 🙄 and I'm only here for
                // overly complex solutions
                // this needs to be converted to a string because if the result is 0 (for January) then the date picker doesn't load the month correctly if it is an int
                psd[1] = (parseInt(psd[1].split('')[1], 10) - 1).toString();
            } else {
                psd[1] = parseInt(psd[1], 0) - 1;
            }

            // leading zeroes need to be removed
            if(psd[2].split('')[0] === '0'){
                psd[2] = psd[2].split('')[1];
            }

            formDataCopy.targetDate = psd;

            _this.setState({ formData: formDataCopy });
        });

    }

    render() {
        let {loading, formData, rfpQuestions, request, invalidDate, attachmentList} = this.state;
        let optionFields = {};
        let order = [];
        let requestFormFields = {};
        let {isCompleted, requestCanBeUpdated, formRef, getRequiredFieldText, addRequiredLabelIfNeeded, getTitle} = this;
        let {startLoading, onAttachmentsChanged} = this;
        let {isForNonWorksmithClient} = this.props;


        if(!isForNonWorksmithClient) {
            let targetLabel = "";

            switch (request.type) {
                case RfpType.RECURRING:
                    targetLabel = 'When do you want the recurring service to start? *';
                    break;
                case RfpType.ON_DEMAND:
                    targetLabel = 'When do you want the work completed? *';
                    break;
                case RfpType.PRICING_ONLY:
                    targetLabel = 'When do you want the pricing ready? *';
                    break;
            }

            requestFormFields.targetType = t.enums({
                "ON_DATE": "On",
                "BY_DATE": "By"
            });
            optionFields.targetType = {
                label: 'When do you want the service to start? *',
                error: getRequiredFieldText(),
                disabled: isCompleted
            };
            order.push("targetType");

            requestFormFields.targetDate = custom.RequestDate;
            optionFields.targetDate = {
                label: ' ',
                hasError: invalidDate,
                error: custom.RequestDate.getValidationErrorMessage,
                help: this.state.isEmergencyRequest ?
                    <i>Target completion dates of same-day, next-day, holidays, or weekends may incur expedited fees for
                        after-hours labor.</i> : '',
                disabled: isCompleted
            };
            order.push("targetDate");

            let walkthroughPreferenceOptions = {
                "Walkthrough Optional": "Walkthrough Optional",
                "Walkthrough Prohibited": "Walkthrough Prohibited"
            };

            if (!isAutomationTargeted(GetKeyByValue(ServiceLineType, parseInt(request.serviceLine.id))))
                walkthroughPreferenceOptions["Walkthrough Required"] = "Walkthrough Required";

            requestFormFields.walkthroughPreference = t.enums(walkthroughPreferenceOptions);
            optionFields.walkthroughPreference = {
                error: getRequiredFieldText(),
                label: 'Walkthrough Preference *',
                disabled: isCompleted
            };
            order.push("walkthroughPreference");

            requestFormFields.timePreference = t.enums({
                "Before Business Hours": "Before Business Hours",
                "During Business Hours": "During Business Hours",
                "After Business Hours": "After Business Hours",
                "No Preference": "No Preference"
            });
            optionFields.timePreference = {
                nullOption: {value: '', text: 'Select a Time Preference'},
                error: getRequiredFieldText(),
                label: 'Time Preference *',
                disabled: isCompleted
            };
            order.push("timePreference");

            requestFormFields.poNumber = t.maybe(t.String);
            optionFields.poNumber = {
                label: "PO Number",
                type: 'text'
            };
            order.push("poNumber");
        }

        requestFormFields.description = t.String;
        optionFields.description = {
            type: 'textarea',
            label: addRequiredLabelIfNeeded('Description'),
            error: getRequiredFieldText(),
            disabled: !requestCanBeUpdated
        };
        order.push("description");

        requestFormFields.worksmithNTE = t.maybe(t.Number);
        optionFields.worksmithNTE = {
            type: 'number',
            label: <div><div>Not To Exceed (NTE)</div>{!isForNonWorksmithClient ? <i>Authorizes Worksmith to approve pricing up to this amount to streamline request completion</i> : null}</div>
        };
        order.push("worksmithNTE");

        if(rfpQuestions) {
            for (let i = 0; i < rfpQuestions.length; i++) {
                let question = rfpQuestions[i];

                let conditionalUpon = question.conditionalUpon;
                let conditionalUponQuestionId = question.conditionalUponQuestionId;
                let conditionalCheck = true;

                if (ValueIsSet(conditionalUpon) && ValueIsSet(conditionalUponQuestionId)) {
                    let conditionalUponAnswer = formData[conditionalUponQuestionId];
                    if (conditionalUpon.name !== conditionalUponAnswer) {
                        conditionalCheck = false;
                        delete formData[question.id];
                    }
                }

                if (conditionalCheck) {

                    if (question.options.length > 0) {

                        var optionsMap = {};

                        for (var j = 0; j < question.options.length; j++) {
                            let option = question.options[j];
                            optionsMap[option.answer] = option.answer;
                        }

                        if (question.includeOtherOption) {
                            optionsMap["Other"] = "Other";
                        }

                        if (question.required)
                            requestFormFields[question.id] = t.enums(optionsMap);
                        else
                            requestFormFields[question.id] = t.maybe(t.enums(optionsMap));
                    } else {
                        if (question.required)
                            requestFormFields[question.id] = t.String;
                        else
                            requestFormFields[question.id] = t.maybe(t.String);
                    }
                    let label = question.required && !isForNonWorksmithClient ? question.question + " *" : question.question;
                    optionFields[question.id] = {
                        label: label,
                        error: getRequiredFieldText(),
                        disabled: !requestCanBeUpdated
                    };

                    order.push(question.id);
                }
            }
        }

        // if the request is for a non-worksmith client, then make all fields optional.
        if(this.props.isForNonWorksmithClient) {
            for (const [key, value] of Object.entries(requestFormFields)) {
                if (value.name !== 'Maybe')
                    requestFormFields[key] = t.maybe(value);
            }
        }

        let EditOnDemandRequestStruct = t.struct(requestFormFields);

        let formOptions = {
            order: order,
            fields: optionFields
        };

        return (
            <div>
                {getTitle()}
                <Form
                    ref={formRef}
                    type={EditOnDemandRequestStruct}
                    options={formOptions}
                    value={formData}
                    onChange={(valueChanged) => this.onChange(valueChanged)}
                />
                <FileUpload
                    initialFiles={attachmentList}
                    onChange={onAttachmentsChanged}
                    multiple
                    tooltipPlacement={TooltipPlacement.BOTTOM_START}
                />
                <div>
                    {
                        !isForNonWorksmithClient ?
                        <div className="left">
                            <label>* Required</label>
                        </div>
                        :
                        null
                    }
                    <div className="text-right">
                        <Grid container justify={JustifyContent.FLEX_END}>
                            <Grid item>
                                <Button onClick={this.props.onCancel}
                                        variant={ButtonVariant.OUTLINED}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    debounce={true}
                                    disabled={loading}
                                    onClickIgnoreDebounce={startLoading}
                                    onClick={this.submit}
                                    primary>{isForNonWorksmithClient ? "Save" : "Submit Edits"}</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>

            </div>
        );
    }
}

export default EditRequestForm;