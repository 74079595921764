import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ContractItemScopeOfWorkView from "worksmith/components/Contract/ContractItemScopeOfWorkView";
import RateCardEntryService from "worksmith/services/api/RateCardEntryService";
import {DisplayErrorNotification} from "../../helpers/SweetAlertHelpers";

const rateCardEntryService = new RateCardEntryService();

class ContractItemScopeOfWork extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rateCardEntry: null,
            editingScopeOfWork: false,
            updatedScopeOfWorkObject: {}
        }
    };

    componentDidMount = () => {
        let { rateCardEntry } = this.props;
        this.setValues(rateCardEntry);
    };

    setValues = (rateCardEntry) => {
        this.setState({rateCardEntry: rateCardEntry, loading: false})
    };

    toggleEditingScopeOfWork = () => {
        let { editingScopeOfWork } = this.state;
        this.setState({editingScopeOfWork: !editingScopeOfWork, updatedScopeOfWorkObject:{}})
    };

    updateScopeOfWork = (input) => {
        this.setState({updatedScopeOfWorkObject: input});
    };

    saveUpdatedScopeOfWork = () => {
        let { updatedScopeOfWorkObject, rateCardEntry } = this.state;
        let rateCardEntryToUpdate = {};
        this.setState({loading: true});
        rateCardEntryToUpdate.scopeOfWork = updatedScopeOfWorkObject.value;
        rateCardEntryService.updateRateCardEntry(updatedScopeOfWorkObject.id, rateCardEntryToUpdate).then((data) => {
            let tempRateCardEntry = rateCardEntry;
            tempRateCardEntry.scopeOfWork = data.scopeOfWork;
            this.setState({rateCardEntry: tempRateCardEntry, updatedScopeOfWorkObject: {}, editingScopeOfWork: false, loading:false});
        }).catch((error) => {
            this.setState({loading: false});
            console.log(error);
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification("Error updating scope of work");
        });
    };

    render() {
        let { loading, editingScopeOfWork, rateCardEntry } = this.state;
        let { toggleEditingScopeOfWork, updateScopeOfWork, saveUpdatedScopeOfWork } = this;
        let { canEdit } = this.props;

        return (
            <Fragment>
                <ContractItemScopeOfWorkView
                    loading={loading}
                    toggleEditingScopeOfWork={toggleEditingScopeOfWork}
                    rateCardEntry={rateCardEntry}
                    editingScopeOfWork={editingScopeOfWork}
                    canEdit={canEdit}
                    updateScopeOfWork={updateScopeOfWork}
                    saveUpdatedScopeOfWork={saveUpdatedScopeOfWork}
                />
            </Fragment>
        )
    }
}

ContractItemScopeOfWork.propTypes = {
    clientLocationContractItem:      PropTypes.shape({
    id:                     PropTypes.number.isRequired,
    title:                  PropTypes.string,
    scopeOfWork:            PropTypes.string,
    locationContract:       PropTypes.shape({
        id:              PropTypes.number.isRequired,
        location:        PropTypes.shape({
            id:          PropTypes.number.isRequired,
            title:       PropTypes.string
        })
    }),
    contractItem:           PropTypes.shape({
        id:              PropTypes.number.isRequired,
        name:            PropTypes.string,
        lineItemType:    PropTypes.shape({
            serviceLine: PropTypes.shape({
                id:      PropTypes.number.isRequired,
                name:    PropTypes.string,
            })
        })
    })
}),
    canEdit:                         PropTypes.bool
};

export default ContractItemScopeOfWork;