import TicketStatus from './../enums/api/task/TicketStatus'

export const CalculateClientDisplayStatus = (ticket, vendorName) => {
    switch(ticket.status) {
        case TicketStatus.AWAITING_PICKUP:
            return "Awaiting pickup by " + vendorName;
        case TicketStatus.PICKED_UP_BY_VENDOR:
            return "Picked up by " + vendorName;
        case TicketStatus.DROPPED_OFF_BY_VENDOR:
            return "Dropped off by " + vendorName;
        case TicketStatus.AWAITING_SHIPMENT:
            return "Awaiting shipment to " + vendorName;
        case TicketStatus.SHIPPED_TO_VENDOR:
            return "In transit to " + vendorName;
        case TicketStatus.AWAITING_TAILOR_ASSIGNMENT:
            return "Received - awaiting tailor assignment";
        case TicketStatus.TAILOR_IN_PROGRESS:
            return "Assigned to tailor - in progress";
        case TicketStatus.WORK_COMPLETED_BY_TAILOR:
            return "Completed - pending shipment";
        case TicketStatus.WORK_CONFIRMED:
            return "Confirmed - pending shipment";
        case TicketStatus.RETURNED_FROM_VENDOR:
            return "In transit from " + vendorName;
        case TicketStatus.AT_STORE:
            return "Ready for pickup";
        default:
            return ticket.status[0].toUpperCase() + ticket.status.slice(1);
    }

    return ticket.status;
};
// CalculateVendorDisplayStatus was moved to the LanguageHelper.js inside vendor portal because it used InitiateIntl from vendor portal
