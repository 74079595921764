import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ScheduleOptionsForm from "./ScheduleOptionsForm";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import Grid from "../../components/Grid/Grid.web";
import Button from "../../components/Button/Button.web";

class CreateScheduleOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valid: false
        };

        this.scheduleOptionsFormRef = React.createRef();
    }

    onFormValidChange = (valid) => {
        this.setState({valid: valid});
    };

    submit = () => {
        const {scheduleOptionsFormRef} = this;
        let schedules = scheduleOptionsFormRef.current.getValue();

        this.props.onSubmit(schedules);
    }


    render() {
        const {scheduleOptionsFormRef} = this;
        const {onFormValidChange, submit} = this;
        const {valid} = this.state;
        const {duration, frequency, numberOfRequiredSchedules, numberOfSchedules, spacing, submitButtonText, useTicketing} = this.props;

        return (
            <Grid
                container
                spacing={spacing}>
                    <Grid item xs={12}>
                        <ScheduleOptionsForm
                            ref={scheduleOptionsFormRef}
                            duration={duration}
                            frequency={frequency}
                            onValidChange={onFormValidChange}
                            numberOfRequiredSchedules={numberOfRequiredSchedules}
                            numberOfSchedules={numberOfSchedules}
                            spacing={spacing}
                            useTicketing={useTicketing}/>
                    </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={!valid}
                        onClick={submit}
                        primary>
                        {submitButtonText}
                    </Button>
                </Grid>

            </Grid>
        )
    }
};

CreateScheduleOptions.defaultProps = {
    spacing: 5,
    submitButtonText: 'Submit'
};

CreateScheduleOptions.propTypes = {
    duration: PropTypes.number,
    frequency: PropTypes.shape({     // If frequency is passed in, the schedules created will be recurring
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    numberOfRequiredSchedules: PropTypes.number,
    numberOfSchedules: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    spacing: PropTypes.number,
    submitButtonText: PropTypes.string,
    useTicketing: PropTypes.bool
};

export default CreateScheduleOptions;