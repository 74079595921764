import React, {Component} from 'react';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {WithContext} from "../../context/GlobalContext";
import RecurringServicesSummaryPageView from "./RecurringServicesSummaryPageView";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import Loader from "worksmith/antd-components/Loader/Loader";
import {LoaderType} from "worksmith/enums/LoaderType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";

const graphQLServiceClass = new AsyncGraphQLServiceClass();

class RecurringServicesSummaryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            locationsCount: null,
            serviceLineMap: {},
            loading: true
        };
    }

    componentDidMount = async () => {
        let {client} = this.props;

        let obligationTemplateSearchParams = {
            clientIds: [client.id],
            filterByRequester: true,
            statuses: ObligationTemplateStatus.getOpenStatuses(),
            isForTicketingServiceLine: false
        };

        let obligationTemplateCountsByServiceLinePromise = graphQLServiceClass.findAll(
            obligationTemplateSearchParams,
            GraphQLObjectType.OBLIGATION_TEMPLATE_COUNTS_BY_SERVICE_LINE,
            obligationTemplateFields
        );

        let requestSearchParams = {
            clientId: client.id,
            hasNotYetBeenScheduled: true,
            isRecurring: true,
            filterByRequester: true
        };

        let requestCountsByServiceLinePromise = graphQLServiceClass.findAll(
            requestSearchParams,
            GraphQLObjectType.REQUEST_COUNTS_BY_SERVICE_LINE,
            requestFields
        );

        let locationSearchParams = {
            clientIds: [client.id],
            filterByRequester: true
        };

        let locationsPage = graphQLServiceClass.findPage(
            0,
            1,
            null,
            null,
            locationSearchParams,
            GraphQLObjectType.CLIENT_LOCATION,
            clientLocationFields
        );

        await Promise.all([obligationTemplateCountsByServiceLinePromise, requestCountsByServiceLinePromise, locationsPage]).then(
            data => {
                let obligationTemplateCountsByServiceLine = data[0];
                let serviceLineMap = obligationTemplateCountsByServiceLine.reduce((map, serviceLineCount) => {
                    map[serviceLineCount.serviceLineId] = {
                        serviceLineId: serviceLineCount.serviceLineId,
                        serviceLineName: serviceLineCount.serviceLineName,
                        ongoingServiceCount: serviceLineCount.count,
                        pendingSetUpServiceCount: 0
                    };
                    return map;
                }, {});

                let requestCountsByServiceLine = data[1];
                requestCountsByServiceLine.forEach(serviceLineCount => {
                    if(ValueIsSet(serviceLineMap[serviceLineCount.serviceLineId]))
                        serviceLineMap[serviceLineCount.serviceLineId].pendingSetUpServiceCount = serviceLineCount.count;
                    else
                        serviceLineMap[serviceLineCount.serviceLineId] = {
                            serviceLineId: serviceLineCount.serviceLineId,
                            serviceLineName: serviceLineCount.serviceLineName,
                            ongoingServiceCount: 0,
                            pendingSetUpServiceCount: serviceLineCount.count
                        };
                });

                this.setState({
                    locationsCount: data[2].totalElements,
                    serviceLineMap: serviceLineMap,
                    loading: false});
            }
        );
    };

    render(){
        let {locationsCount, loading, serviceLineMap} = this.state;

        return loading ? <Loader loaderType={LoaderType.LOGO}/> :
            <RecurringServicesSummaryPageView
                serviceLineMap={serviceLineMap}
                locationsCount={locationsCount}/>;
    }
}

const obligationTemplateFields = `
    serviceLineId
    serviceLineName
    count
`;

const requestFields = `
    serviceLineId
    serviceLineName
    count
`;

const clientLocationFields = `
    id
`;

export default WithContext(RecurringServicesSummaryPage);