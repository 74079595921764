import React, {useEffect, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import Button from "worksmith/components/Button/Button";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import Grid from "worksmith/components/Grid/Grid";
import IconButton from "worksmith/components/Button/IconButton";
import Select from "worksmith/components/Inputs/Select/Select";
import Text from "worksmith/components/Text/Text";
import {Color} from "worksmith/enums/Color.js";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import {Display} from "worksmith/enums/CSSEnums";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {IconType} from "worksmith/enums/MaterialEnums";
import {globalSnackbarTrigger, ValueIsSet} from "worksmith/helpers/GenericHelpers";

const graphQLService = new GraphQLServiceClass();


const AddPreferredVendorRelationship = ({
                                            client,
                                            combinations,
                                            handleChange,
                                        }) => {

    const [clientLocation, setClientLocation] = useState(null);
    const [clientLocationOptions, setClientLocationOptions] = useState(null);
    const [serviceLine, setServiceLine] = useState(null);
    const useTicketingServiceLines =  client.selectedServiceLines.filter(serviceLine => serviceLine.usesTicketing);

    const locationSelectRef = useRef(null);
    const serviceLineSelectRef = useRef(null);

    const getOptions = (list) => {
        return list.map(item => {
            return {value: item.id, label: item.name}
        })
    };

    const fetchClientLocations = async (id) => {
        try {
            const clientResponse = await graphQLService.findOneById(
                id,
                GraphQLObjectType.CLIENT,
                clientFields
            );
            setClientLocationOptions(clientResponse.locations.sort((a,b) => a.title.localeCompare(b.title)).map(location => {
                return {value: location.id, label: location.title}
            }))
        } catch (error) {
            console.error("Error fetching client locations: ", error);
            await DisplayErrorNotification("Error fetching client locations")
        }
    };

    const addPreferredVendorRelationships = (locationId, serviceLineId) => {

        if (combinations.some(combo => (combo.clientLocation.id === locationId && combo.serviceLine.id === serviceLineId))) {
            globalSnackbarTrigger("Location and service line combination already added.")
        } else {
            const {value, label} = clientLocationOptions.find(location => location.value === locationId);
            handleChange('preferredVendorRelationships', [...combinations, {id: combinations.length + 1, clientLocation: {id: value, title: label}, serviceLine: client.selectedServiceLines.find(serviceLine => serviceLine.id === serviceLineId)}]);
        }
        if (locationSelectRef.current && serviceLineSelectRef.current) {
            locationSelectRef.current.reset('');
            serviceLineSelectRef.current.reset('');
        }
    };

    const handleDelete = (id) => {
        const newList = combinations.filter(combo => combo.id !== id);
        handleChange('preferredVendorRelationships', newList);
    };

    useEffect(() => {
       fetchClientLocations(client.id)
    }, []);


    return (
        <Grid container xs={12} margin={0} rowSpacing={2}>
            <Grid item xs={12}>
                <Select
                    ref={locationSelectRef}
                    label={'Select location'}
                    fullWidth
                    onChange={(location) => {
                        setClientLocation(location);
                        // select the only option if only one option is available in the serviceLine select field. Only select if a location is selected.
                        useTicketingServiceLines.length === 1 && setServiceLine(useTicketingServiceLines[0].id);
                    }}
                    disabled={!ValueIsSet(clientLocationOptions)}
                    options={ValueIsSet(clientLocationOptions) ? clientLocationOptions : []}
                    initialValue={''}
                />
            </Grid>
            {client.selectedServiceLines.length > 1 &&
                <Grid item xs={12}>
                    <Select
                        ref={serviceLineSelectRef}
                        label={'Select service line'}
                        fullWidth
                        onChange={(serviceLine) => setServiceLine(serviceLine)}
                        options={getOptions(useTicketingServiceLines)}
                        initialValue={''}
                    />
                </Grid>
            }
            <Grid item>
                <Button
                    disabled={!ValueIsSet(clientLocation) || !ValueIsSet(serviceLine)}
                    debounce
                    secondary
                    onClick={() => addPreferredVendorRelationships(clientLocation, serviceLine)}
                >
                    add
                </Button>
            </Grid>
            {combinations.map(combo => {
                return (
                    <Box key={combo.id} display={Display.FLEX} width={'100%'} sx={{backgroundColor: Color.LIGHT_GREY, margin: '8px 0 0 8px', padding: '8px'}}>
                        <Grid container padding={'8px'}>
                            <Grid item xs={12}>
                                <Text bold>{combo.clientLocation.title}</Text>
                            </Grid>
                            <Grid item xs={12}>
                                <Text>{combo.serviceLine.name}</Text>
                            </Grid>
                        </Grid>
                        <Box sx={{right: '0'}}><IconButton iconName={IconType.CLOSE} onClick={() => handleDelete(combo.id)}/></Box>
                    </Box>
                )
            })}
        </Grid>
    )
};

export default AddPreferredVendorRelationship;

const clientFields = `
    id
    locations {
        id 
        title
    }
`;

AddPreferredVendorRelationship.propTypes = {
    client: PropTypes.object,
    combination: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        clientLocation: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        }),
        serviceLine: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            usesTicketing: PropTypes.bool,
        })
    })),
    handleChange: PropTypes.func,
};