import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {AlignItems, FlexWrap} from "worksmith/enums/CSSEnums";
import FileUploadButton from "./FileUploadButton";
import FilePreview from "worksmith/components/FileUpload/FilePreview";
import Grid from "worksmith/components/Grid/Grid";
import LightBox from "worksmith/components/Lightbox/LightBox";
import {StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {Add} from "@mui/icons-material";
import styled from "styled-components";
import Button from "worksmith/components/Button/Button.web";
import {Color} from "worksmith/enums/Color";
import CircularProgress from "worksmith/components/Loader/CircularProgress";

const FileUploadView = (props) => {
    const {
        acceptedFileFormats,
        addFiles,
        addMediaLibraryFiles,
        allowMediaLibrary,
        centerAddAttachmentButton,
        disabled,
        error,
        files,
        showFileName,
        limitToOneFile,
        loadingPreviewImage,
        locationId,
        maxFileSizeCaptionsUnderAddAttachmentButton,
        multiple,
        readOnly,
        addOnly,
        removeFile,
        uploadButtonBody,
        showUploadButtonAbovePreview,
        lightBoxOpen,
        lightBoxInitialIndex,
        onLightBoxClose,
        onFilePreviewClick,
        noPreviewMargin
    } = props;

    const defaultButtonBody =
        <UploadButton component={"span"}
                      disabled={disabled || (limitToOneFile && files.length > 0)}
                      error={error}
                      variant={"outlined"}>
            {loadingPreviewImage ? <CircularProgress /> : <Add style={{'fontSize': '3em'}}/>}
        </UploadButton>;

    const previews = files.map((preview, index) =>
        <Grid item key={preview.name + index}>
            <FilePreview addedFile={preview}
                         showFileName={showFileName}
                         index={index}
                         removeFile={!readOnly && !addOnly ? removeFile : null}
                         onFilePreviewClick={onFilePreviewClick}
                         noPreviewMargin={noPreviewMargin}
            />
        </Grid>
    );

    const fileUploadButton = !readOnly ?
        <div style={{margin: '1em 0'}}>
            <FileUploadButton
                acceptedFileFormats={acceptedFileFormats}
                addFiles={addFiles}
                addMediaLibraryFiles={addMediaLibraryFiles}
                allowMediaLibrary={allowMediaLibrary}
                centerAddAttachmentButton={centerAddAttachmentButton}
                disabled={disabled || (limitToOneFile && files.length > 0)}
                error={error}
                locationId={locationId}
                maxFileSizeCaptionsUnderAddAttachmentButton={maxFileSizeCaptionsUnderAddAttachmentButton}
                multiple={multiple}
                uploadButtonBody={ValueIsSet(uploadButtonBody) ? uploadButtonBody : defaultButtonBody}/>
        </div>
        :
        null;

    return (
        <Fragment>
            {
                showUploadButtonAbovePreview ?
                    fileUploadButton
                    :
                    null
            }
            <Grid wrap={FlexWrap.WRAP} container spacing={2} alignItems={AlignItems.FLEX_START} >
                {previews}
                <Grid item>
                {
                    !showUploadButtonAbovePreview ?
                        fileUploadButton
                        :
                        null
                }
                </Grid>
            </Grid>

            {
                ValueIsSet(files) && files.length > 0 ?
                    <LightBox attachments={files} isOpen={lightBoxOpen} initialSlideIndex={lightBoxInitialIndex}
                              onCloseRequest={onLightBoxClose}/>
                    :
                    null
            }
        </Fragment>
    )
};

const UploadButton = styled(Button)`
    width: 5.5em;
    height: 5.5em;
    && {
        ${({error}) => (StringHasText(error) || error === true) ? `border: 1px solid ${Color.ERROR};` : null}
    }
`;

FileUploadView.propTypes = {
    acceptedFileFormats: PropTypes.string,                              // takes a string containing one or more file type specifiers as its value, separated by commas. (ex. '.jpg, .jpeg, .png' or 'image/*,.pdf')
    addFiles:    PropTypes.func,
    addMediaLibraryFiles:    PropTypes.func,
    allowMediaLibrary:    PropTypes.bool,
    centerAddAttachmentButton: PropTypes.bool,
    disabled:    PropTypes.bool,
    error: PropTypes.bool,
    files:       PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
    })).isRequired,
    limitToOneFile: PropTypes.bool,
    loadingPreviewImage: PropTypes.bool,
    locationId: PropTypes.number,
    maxFileSizeCaptionsUnderAddAttachmentButton: PropTypes.bool,
    multiple:    PropTypes.bool.isRequired,
    noPreviewMargin:    PropTypes.bool,
    readOnly:    PropTypes.bool,
    removeFiles: PropTypes.func,
    uploadButtonBody: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    lightBoxOpen: PropTypes.bool,
    lightBoxInitialIndex: PropTypes.number,
    onLightBoxClose: PropTypes.func,
    showFileName: PropTypes.bool,
};

export default FileUploadView;
