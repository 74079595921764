import React, {Component} from 'react';
import PropTypes from 'prop-types';

import InternalNotesView from "./InternalNotesView";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import RequestForProposalService from "../../services/api/RequestForProposalService";
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {DisplayErrorNotification} from "../../helpers/SweetAlertHelpers";

const graphQLService = new GraphQLServiceClass();
const requestForProposalService = new RequestForProposalService();

class ClientInternalNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            submitting: false,
            notes: null,
            content: '',
            valid: false
        };

        this.textFieldRef = React.createRef();
    }

    componentDidMount(){
        this.getNotes();
    }

    getNotes = () => {
        const {requestId} = this.props;
        const {loading, submitting} = this.state;
        let _this = this;

        graphQLService.findAll(
            {
                requestId: requestId
            },
            GraphQLObjectType.RFP_NOTE,
            `
                id
                note
                createdTimestamp
                author {
                  id
                  displayName
                }
            `
        ).then((notes) => {
            _this.setState({notes});
        }).catch(() => {
            DisplayErrorNotification("Unable to load your internal notes.  Please refresh the page and try again.");
        }).finally(() => {
            if (loading)
                _this.setState({loading: false});

            if (submitting)
                _this.setState({submitting: false});
        })
    };

    writeNote = () => {
        let {content, submitting} = this.state;
        const {requestId} = this.props;
        const {textFieldRef} = this;

        if(content !== '' && ValueIsSet(content) && ValueIsSet(requestId)) {
            this.setState({submitting: true});

            let note = {
                requestForProposalId: requestId,
                note: content
            }

            requestForProposalService.addNote(requestId, note).then(updatedNote => {
                this.getNotes();
                textFieldRef.current.clear();
            }).catch((error) =>{
                DisplayErrorNotification(error.toString());
                if(submitting)
                    this.setState({submitting: false});
            });
        }
    };

    onTextFieldChange = (value) => {
        let valid = value.trim() !== '';
        this.setState({content: value, valid});
    };

    render() {
        const {notes, loading, submitting, valid} = this.state;
        const {onTextFieldChange, writeNote} = this;
        const {textFieldRef} = this;

        return (
            <InternalNotesView notes={notes}
                               writeNote={writeNote}
                               loading={loading}
                               onTextFieldChange={onTextFieldChange}
                               disableSubmit={submitting || !valid}
                               textFieldRef={textFieldRef}/>
        )
    }
}

ClientInternalNotes.propTypes = {
    requestId: PropTypes.number.isRequired,
};

export default ClientInternalNotes;