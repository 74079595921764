import React, {Fragment} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import CalendarListView from "./CalendarListView";
import Grid from 'worksmith/components/Grid/Grid';
import Tabs from "worksmith/components/Tab/Tabs";
import Text, {TextVariant} from 'worksmith/components/Text/Text';
import {ValueIsSet} from "../../Helpers";

const filterTabs = [
    <Text key={0} variant={TextVariant.SUBTITLE_2}>List view</Text>,
    <Text key={1} variant={TextVariant.SUBTITLE_2}>Completed in the last week</Text>,
    <Text key={2} variant={TextVariant.SUBTITLE_2}>Open concerns</Text>,
    <Text key={3} variant={TextVariant.SUBTITLE_2}>Awaiting sign off</Text>,
    <Text key={4} variant={TextVariant.SUBTITLE_2}>Open change orders</Text>
];

const CalendarListViewTabs = ({initialTab, clientId, title, user}) => {

    const TAB_CONTENTS = [
        <Fragment key={0}>
            <CalendarListView
                sortDesc
                initialStartDate={moment().startOf('day').subtract(3, 'days')}
                initialEndDate={moment().startOf('day').add(3, 'days')}
                clientId={clientId}
                hideFilters={false}
                title={title}
                user={user}
                showFrequentJobsFilter={true}
                initialFrequentJobsFiltered={true}
            />
        </Fragment>,
        <Fragment key={1}>
            <CalendarListView
                sortDesc
                initialStartDate={moment().startOf('day').subtract(7, 'days')}
                initialEndDate={moment().startOf('day').add(1, 'days')}
                initialCompletedSelected={"COMPLETED"}
                disableRescheduleFilter
                disableScheduleFilter
                clientId={clientId}
                hideFilters={true}
                title={title}
                user={user}
                showFrequentJobsFilter={true}
                initialFrequentJobsFiltered={true}
            />
        </Fragment>,
        <Fragment key={2}>
            <CalendarListView
                sortDesc
                initialCompletedSelected={"HAS_OPEN_CONCERN"}
                disableRescheduleFilter
                disableScheduleFilter
                clientId={clientId}
                hideFilters={true}
                title={title}
                user={user}
                showFrequentJobsFilter={true}
                initialFrequentJobsFiltered={false}
            />
        </Fragment>,
        <Fragment key={3}>
            <CalendarListView
                sortDesc
                initialCompletedSelected={"AWAITING_CLIENT_SIGNOFF"}
                disableRescheduleFilter
                disableScheduleFilter
                nullDateFilter
                clientId={clientId}
                hideFilters={true}
                title={title}
                user={user}
                showFrequentJobsFilter={true}
                initialFrequentJobsFiltered={true}
            />
        </Fragment>,
        <Fragment key={4}>
            <CalendarListView
                sortDesc
                initialCompletedSelected={"HAS_OPEN_CHANGE_ORDER"}
                disableRescheduleFilter
                disableScheduleFilter
                nullDateFilter
                clientId={clientId}
                hideFilters={true}
                title={title}
                user={user}
                showFrequentJobsFilter={true}
                initialFrequentJobsFiltered={true}
            />
        </Fragment>
    ];

    return (
        <Grid>
            {ValueIsSet(title) ? <Text variant={TextVariant.H5}>{title}</Text> : null}
            <Tabs titleList={filterTabs} initialValue={initialTab} tabFullWidth>
                {TAB_CONTENTS}
            </Tabs>
        </Grid>
    )
};

export default CalendarListViewTabs

CalendarListViewTabs.defaultProps = {
    initialTab: 0
};

CalendarListViewTabs.propTypes = {
    initialTab: PropTypes.number,
    clientId: PropTypes.number,
    title: PropTypes.string,
    user: PropTypes.element
};