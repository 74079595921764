import React, {Fragment, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from "worksmith/components/Button/Button.web";
import CalendarListView from "../calendar/CalendarListView";
import ChangeRequestStatus from "worksmith/enums/api/cr/ChangeRequestStatus";
import ClientRoutes from "../../ClientRoutes";
import CreateScheduleOptionsDialog
    from "worksmith/composite-components/ScheduleOptionsForm/CreateScheduleOptionsDialog";
import {GetParameterByName, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Grid from "worksmith/components/Grid/Grid.web";
import Loader from "worksmith/antd-components/Loader/Loader";
import {LoaderType} from "worksmith/enums/LoaderType";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import NoRecurringService from "./NoRecurringService";
import ObligationStatus from "worksmith/enums/api/task/ObligationStatus";
import ObligationTemplatePropertiesGrid from "./ObligationTemplatePropertiesGrid";
import RecurringServiceActionButton from "./RecurringServiceActionButton";
import Schedule from "worksmith/components/Schedule/Schedule.web";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import Select, {SelectVariant} from "worksmith/components/Inputs/Select/Select.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import UserType from "worksmith/enums/api/user/UserType";
import Checkbox from "worksmith/components/Checkbox/Checkbox";
import {DisplaySuccessNotification} from "worksmith/helpers/SweetAlertHelpers";

const SpacedText = styled(Text)`
    padding-top: 0.5em;
    padding-bottom: 0.5em;

`;

const PaddedBlock = styled.div`
    padding-top: 1.5em;
    padding-bottom: 0.5em;
`;

const RecurringServiceView = (props) => {
    const [visitStatus, setVisitStatus] = useState(null);

    let {
        anyActiveServices,
        acceptChangeRequest,
        counterChangeRequest,
        cancelChangeRequest,
        filterStatus,
        loading,
        mostRecentCompletedJob,
        obligationTemplate,
        onFilterChange,
        changeOrderFilter,
        page,
        reloadPage,
        reloadChangeRequest,
        reloadObligationTemplate,
        setChangeOrderFilter,
        showScheduleOptionsDialog,
        setShowScheduleOptionsDialog,
        templateChangeRequest
    } = props;

    let margin = "0.5em 0em 0.5em 0em";
    let hasPendingChangeOrderRequest = ValueIsSet(obligationTemplate.requestForProposal) ? obligationTemplate.requestForProposal.hasPendingChangeOrderRequest : false;

    // Used to get the query parameter value to set the visit status search parameters for the recurring services on page load.
    const visitStatusParams = GetParameterByName("visitStatus");


    useEffect(() => {
        if (visitStatusParams === "completed") {
            onFilterChange(ObligationFilterOptions.COMPLETED);
            setVisitStatus(ObligationFilterOptions.COMPLETED);
        }
        if (visitStatusParams === "pending") {
            onFilterChange(ObligationFilterOptions.PENDING);
            setVisitStatus(ObligationFilterOptions.PENDING);
        }
        if (visitStatusParams === "all") {
            onFilterChange(ObligationFilterOptions.ALL);
            setVisitStatus(ObligationFilterOptions.ALL);
        }

    },[]);


    return loading ? <Loader loaderType={LoaderType.LOGO}/> : <div>
        {obligationTemplate.status !== ObligationStatus.CANCELED ?
            <Grid container>
                <Grid item xs={2}>
                    <RecurringServiceActionButton reloadPage={() => reloadPage(page)} openChangeRequest={templateChangeRequest} cancelChangeRequest={cancelChangeRequest} obligationTemplate={obligationTemplate} mostRecentCompletedObligation={ValueIsSet(mostRecentCompletedJob) ? mostRecentCompletedJob : null} reloadChangeRequest={reloadChangeRequest} reloadObligationTemplate={reloadObligationTemplate}/>
                </Grid>
                {hasPendingChangeOrderRequest ?
                    <Grid item xs={10} padding={'0 0 1rem 0'}>
                        <Text color={TextColor.SECONDARY}>One or more jobs in this recurring service have a change order</Text>
                    </Grid>
                    : null}
            </Grid>
            :
            <Fragment>
                {!anyActiveServices ? <NoRecurringService serviceLine={obligationTemplate.serviceLine.name}/> : null}
                <SpacedText>This recurring service was cancelled on {moment(obligationTemplate.updatedTimestamp).format(MomentFormat.AbvFullDateTime)}. If you'd like to restart this service, please <Link to={'/' + ClientRoutes.SINGLE_REQUESTS}>create a new request.</Link></SpacedText>
            </Fragment>}

        {ValueIsSet(templateChangeRequest)  && obligationTemplate.status !== ObligationStatus.CANCELED?
            <Fragment>
                <PaddedBlock>
                    <Grid container sm={9} xs={12}>
                        <Grid margin={margin} item xs={4}><Text semiBold variant={TextVariant.SUBTITLE_1}>Currently Scheduled for:</Text></Grid>
                        <Grid margin={margin} item xs={8}><Text semiBold variant={TextVariant.SUBTITLE_1} color={TextColor.SECONDARY}>{templateChangeRequest.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT ? "The Vendor Requested a New Recurring Schedule:" : "You Requested a New Recurring Schedule: \nPENDING VENDOR INPUT"}</Text></Grid>
                        <Grid margin={margin} item xs={4}><Schedule schedule={obligationTemplate.schedule}/></Grid>
                        <Grid margin={margin} item xs={4}><Schedule schedule={templateChangeRequest.requestedSchedules[0]}/></Grid>
                        {templateChangeRequest.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT ?
                            <Fragment>
                                <Grid margin={margin} item xs={4}><Button primary onClick={() => acceptChangeRequest(templateChangeRequest.id)}>Select</Button></Grid>
                                <Grid margin={margin} item xs={4}/>
                                <Grid margin={margin} item xs={4}><Text>Propose a different time</Text></Grid>
                                <Grid margin={margin} item xs={4}><Button primary onClick={() => setShowScheduleOptionsDialog(true)}>Select</Button></Grid>
                            </Fragment>
                            :
                            null
                        }
                    </Grid>
                </PaddedBlock>
            </Fragment>
            : null}
        <ObligationTemplatePropertiesGrid loading={loading} obligationTemplate={obligationTemplate}/>
        {obligationTemplate.status !== ObligationStatus.CANCELED ?
            <Fragment>
                <Grid margin={'1.5em 0 .5em 0'} container sm={9} xs={12}>
                    <Grid item xs={3} md={4} padding={'0 0 0 0'}>
                        <Select variant={SelectVariant.OUTLINED} label={'Visit Status'} options={options} initialValue={visitStatus} onChange={(option) => onFilterChange(option)} />
                    </Grid>
                    <Grid item xs={9} md={6}>
                        <Checkbox primary
                                  checked={changeOrderFilter}
                                  label={"Show jobs with open change orders"}
                                  initialValue={changeOrderFilter}
                                  disabled={loading}
                                  onChange={(value) => {setChangeOrderFilter(value)}}/>
                    </Grid>
                </Grid>

                <CalendarListView
                    hideFilters
                    disableCompletedFilter
                    disableRescheduleFilter
                    disableScheduleFilter
                    nullDateFilter
                    clientId={obligationTemplate.client.id}
                    openChangeOrderFilter={changeOrderFilter}
                    obligationTemplateId={obligationTemplate.id}
                    status={filterStatus}/>

                <CreateScheduleOptionsDialog
                    adjustableFrequency
                    clientLocation={obligationTemplate.clientLocation}
                    title={"Propose New Recurring Schedule"}
                    open={showScheduleOptionsDialog}
                    onSubmit={(schedules) => {
                        counterChangeRequest(templateChangeRequest.id, schedules).then(() => {
                            setShowScheduleOptionsDialog(false);
                            DisplaySuccessNotification("Schedule counteroffer submitted!").then()});
                    }}
                    obligationTemplate={obligationTemplate}
                    templateChangeRequest={templateChangeRequest}
                    showCurrentSchedule
                    onCancel={() => setShowScheduleOptionsDialog(false)}
                    numberOfSchedules={1}
                    serviceLine={obligationTemplate.serviceLine}
                    userType={UserType.CLIENT}
                    scheduleOptionType={ScheduleOptionType.RECURRING_SERVICE}
                    vendor={obligationTemplate.vendor.officialName}
                    frequency={obligationTemplate.recurringScheduleTemplate.frequency}
                    duration={obligationTemplate.recurringScheduleTemplate.duration}/>
            </Fragment>
            : null }
    </div>;
};


RecurringServiceView.propTypes = {
    loading: PropTypes.bool,
    mostRecentCompletedJob: PropTypes.object,
    templateChangeRequest: PropTypes.object,
    obligationTemplate: PropTypes.object,
    showScheduleOptionsDialog: PropTypes.bool,
    page: PropTypes.number,
    showFilterOptions: PropTypes.bool,
    filterStatus: PropTypes.string,
    acceptChangeRequest: PropTypes.func,
    counterChangeRequest: PropTypes.func,
    onFilterChange: PropTypes.func,
    reloadPage: PropTypes.func,
    cancelChangeRequest: PropTypes.func,
    reloadChangeRequest: PropTypes.func,
    setShowFilterOptions: PropTypes.func,
    setShowScheduleOptionsDialog: PropTypes.func,
    reloadObligationTemplate: PropTypes.func,
    anyActiveServices: PropTypes.bool
};


const ObligationFilterOptions = Object.freeze({
    ALL: 'All',
    PENDING: 'pending',
    COMPLETED: 'completed',
});

const options = [
    {
        label: 'Scheduled',
        value: ObligationFilterOptions.PENDING
    },
    {
        label: 'Completed',
        value: ObligationFilterOptions.COMPLETED
    },
    {
        label: 'All',
        value: ObligationFilterOptions.ALL
    }
];


export default RecurringServiceView;