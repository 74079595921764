import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DeepCopy} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PricingProposalFromView from "worksmith/components/PricingProposalForm/PricingProposalFromView";
import PortalType from "worksmith/enums/api/user/PortalType";

export const PricingProposalFormVariant = Object.freeze({
    CHANGE_ORDER: 'Change Order'
});

export const PricingProposalFormFields = Object.freeze({
    ADDITIONAL_APPROVAL: 'additionalApproval',
    LINE_ITEMS: 'lineItems',
    TERMS_AND_CONDITIONS: 'termsAndConditions'
});

class PricingProposalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.proposalFormValues = {
            additionalApproval: props.initialAdditionalApproval,
            lineItems:          props.initialLineItems,
            termsAndConditions: props.initialTermsAndConditions
        }

    }

    onChange = (field, value) => {
        let {proposalFormValues} = this;
        const {onChange} = this.props;

        proposalFormValues[field] = DeepCopy(value);

        onChange(proposalFormValues);
    };

    render() {
        const {onChange} = this;
        const {
            allowAdditionalApproval,
            clientLocation,
            effectiveWholesaleRate,
            errors,
            initialAdditionalApproval,
            initialLineItems,
            initialTermsAndConditions,
            isEdit,
            overrideClientApproval,
            isForRecurringService,
            lineItemTypes,
            request,
            titleIdentifier,
            variant,
            portalType
        } = this.props;

        return <PricingProposalFromView allowAdditionalApproval={allowAdditionalApproval}
                                        clientLocation={clientLocation}
                                        effectiveWholesaleRate={effectiveWholesaleRate}
                                        errors={errors}
                                        initialAdditionalApproval={initialAdditionalApproval}
                                        initialLineItems={initialLineItems}
                                        initialTermsAndConditions={initialTermsAndConditions}
                                        isEdit={isEdit}
                                        overrideClientApproval={overrideClientApproval}
                                        isForRecurringService={isForRecurringService}
                                        lineItemTypes={lineItemTypes}
                                        onChange={onChange}
                                        request={request}
                                        titleIdentifier={titleIdentifier}
                                        portalType={portalType}
                                        variant={variant}/>
    }
}

PricingProposalForm.propTypes = {
    allowAdditionalApproval:    PropTypes.bool,
    clientLocation:             PropTypes.object.isRequired,
    effectiveWholesaleRate:     PropTypes.number.isRequired,
    errors:                     PropTypes.shape({
                                    scopeOfWork: PropTypes.bool,
                                    lineItems: PropTypes.arrayOf(
                                        PropTypes.shape({
                                            description:     PropTypes.bool,
                                            quantity:        PropTypes.bool,
                                            unitRetailPrice: PropTypes.bool
                                        })
                                    )
                                }),
    initialAdditionalApproval:  PropTypes.number,
    initialLineItems:           PropTypes.arrayOf(PropTypes.object).isRequired,
    initialTermsAndConditions:  PropTypes.string,
    isEdit:                     PropTypes.bool,
    overrideClientApproval:    PropTypes.bool,
    isForRecurringService:      PropTypes.bool,
    lineItemTypes:              PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange:                   PropTypes.func.isRequired,
    request:                    PropTypes.object,
    titleIdentifier:            CustomPropTypes.stringOrNumber,
    portalType:                 CustomPropTypes.enum(PortalType),
    variant:                    CustomPropTypes.enum(PricingProposalFormVariant).isRequired
};

PricingProposalForm.defaultProps = {
    allowAdditionalApproval: false,
    initialTermsAndConditions: ''
};

export default PricingProposalForm;