import React, {Component} from 'react';
import UserService from 'worksmith/services/api/UserService';
import ForgotPasswordPageView from "./ForgotPasswordPageView";

const userService = new UserService();

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            submitting: false,
            successfulReset: false
        };
    }

    onResetPassword = (email) => {
        this.setState({submitting: true});
        userService.forgotPasswordReset(encodeURIComponent(email)).then(() => {
            this.setState({submitting: false, successfulReset: true});
        }).catch(() => {
            this.setState({submitting: false});
        })
    };
    
    render() {
        let {onResetPassword} = this;
        let {successfulReset, submitting} = this.state;
        let {} = this.props;
        
        return (
            <ForgotPasswordPageView
                onResetPassword={onResetPassword}
                submitting={submitting}
                successfulReset={successfulReset}/>
        )
    }
}

export default ForgotPasswordPage;