import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {StripeProvider} from 'react-stripe-elements';
import {HashRouter, Route, Switch} from "react-router-dom";

import {config} from './env';
import {LoggedOutRoutes} from "./Enums";
import LoginPage from "./pages/loggedOut/LoginPage";
import ForgotPasswordPage from "./pages/loggedOut/ForgotPasswordPage";
import BurberryLoginPage from "./pages/loggedOut/BurberryLoginPage";
import SquareLoginPage from "./pages/loggedOut/SquareLoginPage";
import OAuth from "./components/auth/OAuth";
import {GetURLSearchParams} from "./Helpers";
import {GlobalContext} from "./context/GlobalContext";
import {ValueIsSet} from "./shared/helpers/Helpers";
import Loader from "./components/Loader";
import WhiteLabelSplashPage from "./pages/loggedOut/WhiteLabelSplashPage";

class LoggedOutNavigator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stripe: null,
            marketingParams: props.marketingParams,
            loading: true
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!ValueIsSet(this.state.marketingParams) && ValueIsSet(nextProps.marketingParams))
            this.state.marketingParams = nextProps.marketingParams;

        return true;
    }

    componentDidMount() {
        const stripeJs = document.createElement('script');
        stripeJs.src = 'https://js.stripe.com/v3/';
        stripeJs.async = true;
        stripeJs.onload = () => {
            this.setState({
                stripe: window.Stripe(config.STRIPE_API_KEY),
                loading: false
            });
        };
        document.body && document.body.appendChild(stripeJs);
    }

    render() {
        let {onLogin} = this.props;
        let {marketingParams, loading} = this.state;

        return (
            loading ? <Loader/> :
            <Fragment>
            <GlobalContext.Provider value={{
                marketingParams
            }}>
                <StripeProvider stripe={this.state.stripe}>
                    <HashRouter>
                        <Switch>
                            <Route exact path={LoggedOutRoutes.SQUARE_LOGIN} component={SquareLoginPage}/>
                            <Route exact path={LoggedOutRoutes.BURBERRY_LOGIN} component={BurberryLoginPage}/>

                            <Route exact path={LoggedOutRoutes.FORGOT_PASSWORD} component={ForgotPasswordPage}/>

                            <Route exact path={LoggedOutRoutes.WHITE_LABEL_SPLASH} component={WhiteLabelSplashPage}/>

                            <Route path={LoggedOutRoutes.OAUTH} render={({history}) => {
                                let params = GetURLSearchParams(history.location.search);

                                return <OAuth params={params} authType={params.authType} onLogin={onLogin}/>;
                            }}/>

                            <Route exact path={'*'} render={() => <LoginPage onLogin={onLogin}/>}/>
                        </Switch>
                    </HashRouter>
                </StripeProvider>
            </GlobalContext.Provider>
            </Fragment>
        )
    }
}

LoggedOutNavigator.propTypes = {
    onLogin: PropTypes.func.isRequired
};

export default LoggedOutNavigator;
