// This file was generated from com.worksmith.cpm.controller.ClientController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ClientService extends Http {
    constructor() {
        super('/cpm/client')
    }
    
    addAttachment(id, locationId, rfpId, attachmentName, attachment) {
        let params = {
            attachmentName
        };
    
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/attachments`, POST, null, params, null, true, attachment);
    }
    
    addAttachments(id, locationId, rfpId, attachments) {
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/attachments/multiple`, POST, null, null, null, true, attachments);
    }
    
    addCampaign(id, campaign) {
        return this.callApi( `${id}/addCampaign`, POST, campaign, null, null, true);
    }
    
    addComment(id, locationId, rfpId, comment) {
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/comments`, POST, comment, null, null, true);
    }
    
    addNewUserToClient(clientId, isAccountant, user) {
        let params = {
            isAccountant
        };
    
        return this.callApi( `${clientId}/users`, POST, user, params, null, true);
    }
    
    addNewUserToLocation(clientId, locationId, asRegionalManager, user) {
        let params = {
            asRegionalManager
        };
    
        return this.callApi( `${clientId}/locations/${locationId}/users`, POST, user, params, null, true);
    }
    
    addVendorToBlacklist(clientId, vendorId) {
        return this.callApi( `${clientId}/addVendorToBlacklist/${vendorId}`, POST, null, null, null, true);
    }
    
    associateUserWithClient(clientId, userId) {
        return this.callApi( `${clientId}/users/${userId}/associate`, PATCH, null, null, null, true);
    }
    
    associateUserWithLocation(clientId, locationId, userId, asRegionalManager) {
        let params = {
            asRegionalManager
        };
    
        return this.callApi( `${clientId}/locations/${locationId}/users/${userId}/associate`, PATCH, null, params, null, true);
    }
    
    createWithFileMask(fileMask, attachment) {
        let params = {
            fileMask
        };
    
        return this.callApi( `createWithFileMask`, POST, null, params, null, true, attachment);
    }

    createWhiteLabelClient(params){
        return this.callApi( `createWhiteLabelClient`, POST, params, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteAttachment(id, locationId, rfpId, attachmentId) {
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/attachments/${attachmentId}`, DELETE, null, null, null, true);
    }
    
    deleteComment(id, locationId, rfpId, commentId) {
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/comments/${commentId}`, DELETE, null, null, null, true);
    }
    
    deleteLocation(id, locationId) {
        return this.callApi( `${id}/locations/${locationId}`, DELETE, null, null, null, true);
    }
    
    disassociateUserWithClient(clientId, userId) {
        return this.callApi( `${clientId}/users/${userId}/disassociate`, DELETE, null, null, null, true);
    }
    
    disassociateUserWithLocation(clientId, userId, locationId) {
        return this.callApi( `${clientId}/locations/${locationId}/users/${userId}/disassociate`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findClientAdminSummaryReadObject(clientId) {
        return this.callApi( `findClientAdminSummaryReadObject/${clientId}`, GET, null, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findOneAdminFullObject(id) {
        return this.callApi( `findOneAdminFullObject/${id}`, GET, null, null, null, true);
    }
    
    findUserByClient(clientId, userId) {
        return this.callApi( `${clientId}/users/${userId}`, GET, null, null, null, true);
    }
    
    findUserByLocation(clientId, locationId, userId) {
        return this.callApi( `${clientId}/locations/${locationId}/users/${userId}`, GET, null, null, null, true);
    }
    
    findUsers(clientId) {
        return this.callApi( `${clientId}/users`, GET, null, null, null, true);
    }
    
    findUsersByLocation(clientId, locationId) {
        return this.callApi( `${clientId}/locations/${locationId}/users`, GET, null, null, null, true);
    }
    
    getAllCampaigns(id) {
        return this.callApi( `${id}/getAllCampaigns`, GET, null, null, null, true);
    }

    //This endpoint will bring in both nonWorksmith clients and Worksmith clients
    getAllListSummaries() {
        return this.callApi( `getAllListSummaries`, GET, null, null, null, true);
    }

    getAllWorksmithListSummaries(){
        return this.callApi( `getAllWorksmithListSummaries`, GET, null, null, null, true);
    }
    
    getAllOnboarded() {
        return this.callApi( `getAllOnboarded`, GET, null, null, null, true);
    }
    
    getAllSimple() {
        return this.callApi( `findAllSimple`, GET, null, null, null, true);
    }

    getAllWorksmithSimple() {
        return this.callApi( `findAllWorksmithSimple`, GET, null, null, null, true);
    }
    
    getAttachments(id, locationId, rfpId) {
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/attachments`, GET, null, null, null, true);
    }
    
    getClientConfigurationSettings(clientId) {
        return this.callApi( `${clientId}/getClientConfigurationSettings`, GET, null, null, null, true);
    }
    
    getClientCostSummary(id, startDate, endDate) {
        let params = {
            startDate, 
            endDate
        };
    
        return this.callApi( `${id}/reporting/costSummary`, GET, null, params, null, true);
    }
    
    getClientInitialSummary(clientId) {
        return this.callApi( `${clientId}/getClientInitialSummary`, GET, null, null, null, true);
    }
    
    getClientLocationSpendingOverview(id) {
        return this.callApi( `${id}/getClientLocationSpendingOverview`, GET, null, null, null, true);
    }
    
    getClientSpendingPerLocation(id) {
        return this.callApi( `${id}/getClientSpendingPerLocation`, GET, null, null, null, true);
    }
    
    getClientSpendingReportCSV(params) {
        return this.callApi( `getClientSpendingReportCSV`, POST, params, null, null, true);
    }
    
    getCoachBidSummaries(id) {
        return this.callApi( `${id}/coachBids/summaries`, GET, null, null, null, true);
    }
    
    getComments(id, locationId, rfpId) {
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/comments`, GET, null, null, null, true);
    }
    
    getDryCleaningFormConfiguration(ids) {
        return this.callApi( `settings/dryCleaning`, POST, ids, null, null, true);
    }
    
    getEmergencyTurnaround() {
        return this.callApi( `getEmergencyTurnaround`, GET, null, null, null, true);
    }
    
    getEstimates(id, status) {
        let params = {
            status
        };
    
        return this.callApi( `${id}/estimates`, GET, null, params, null, true);
    }
    
    getExpectedTurnaroundByServiceLine(id, serviceLineId) {
        let params = {
            serviceLineId
        };
    
        return this.callApi( `${id}/getExpectedTurnaroundByServiceLine`, GET, null, params, null, true);
    }

    getExploJWTToken(id, embedId){
        let params = {
            embedId
        };

        return this.callApi( `getExploJWTToken/${id}`, GET, null, params, null, true);
    }
    
    getIndividualCostItems(id, startDate, endDate, clientLocationId, serviceLineId) {
        let params = {
            startDate, 
            endDate, 
            clientLocationId, 
            serviceLineId
        };
    
        return this.callApi( `${id}/reporting/costItems`, GET, null, params, null, true);
    }
    
    getLocation(id, locationId) {
        return this.callApi( `${id}/locations/${locationId}`, GET, null, null, null, true);
    }
    
    getLocations(id) {
        return this.callApi( `${id}/locations`, GET, null, null, null, true);
    }
    
    getNTEConfigs(id) {
        return this.callApi( `${id}/getNTEConfigs`, GET, null, null, null, true);
    }
    
    getNonCorporateSignUpOptions() {
        return this.callApi( `nonCorporateSignUpFieldOptions`, GET, null, null, null, true);
    }
    
    getRfpByClient(id, rfpId) {
        return this.callApi( `${id}/requestForProposals/${rfpId}`, GET, null, null, null, true);
    }
    
    getRfpForLocation(clientId, locationId, rfpId) {
        return this.callApi( `${clientId}/locations/${locationId}/requestForProposals/${rfpId}`, GET, null, null, null, true);
    }
    
    getRfpsByClient(id) {
        return this.callApi( `${id}/requestForProposals`, GET, null, null, null, true);
    }
    
    getRfpsForLocation(clientId, locationId) {
        return this.callApi( `${clientId}/locations/${locationId}/requestForProposals`, GET, null, null, null, true);
    }
    
    getSelectedServiceLines(id) {
        return this.callApi( `${id}/getSelectedServiceLines`, GET, null, null, null, true);
    }
    
    getSignUpOptions() {
        return this.callApi( `signUpFieldOptions`, GET, null, null, null, true);
    }
    
    getSingleDryCleaningFormConfiguration(id) {
        return this.callApi( `${id}/settings/dryCleaningForm`, GET, null, null, null, true);
    }
    
    getStandardItemInformation(clientId) {
        return this.callApi( `getStandardItemInformation/${clientId}`, GET, null, null, null, true);
    }
    
    getStandardItems(id, serviceLineId) {
        let params = {
            serviceLineId
        };
    
        return this.callApi( `${id}/standardItems`, GET, null, params, null, true);
    }
    
    getStandardItemsWithUserAndLocation() {
        return this.callApi( `getStandardItemsWithUser`, GET, null, null, null, true);
    }
    
    getTicketFormOptions(id, clientLocationId) {
        let params = {
            clientLocationId
        };
    
        return this.callApi( `${id}/settings/ticketOptions`, GET, null, params, null, true);
    }
    
    getVendorBlacklist(clientId) {
        return this.callApi( `${clientId}/getVendorBlacklist`, GET, null, null, null, true);
    }
    
    getVendorsByClientLocation(id, locationId, serviceLineId) {
        let params = {
            serviceLineId
        };
    
        return this.callApi( `${id}/locations/${locationId}/vendors`, GET, null, params, null, true);
    }
    
    getWorksmithServiceLineNTEConfigurationSummaries(id) {
        return this.callApi( `${id}/getWorksmithServiceLineNTEConfigurationSummaries`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    insertLocation(location) {
        return this.callApi( `locations`, POST, location, null, null, true);
    }
    
    insertRequestForProposal(clientId, locationId, requestForProposal) {
        return this.callApi( `${clientId}/locations/${locationId}/requestForProposals`, POST, requestForProposal, null, null, true);
    }
    
    massLocationSave(locations) {
        return this.callApi( `massLocationSave`, POST, locations, null, null, true);
    }
    
    onboard(id) {
        return this.callApi( `${id}/onboard`, POST, null, null, null, true);
    }
    
    pauseClient(clientId) {
        return this.callApi( `pauseClient/${clientId}`, PATCH, null, null, null, true);
    }
    
    removeVendorFromBlacklist(clientId, vendorId) {
        return this.callApi( `${clientId}/removeVendorFromBlacklist/${vendorId}`, DELETE, null, null, null, true);
    }
    
    saveStandardItems(items) {
        return this.callApi( `saveStandardItems`, POST, items, null, null, true);
    }
    
    searchSimple(name, clientId) {
        let params = {
            name, 
            clientId
        };
    
        return this.callApi( `searchSimple`, GET, null, params, null, true);
    }

    updateAutoAcceptConfiguration(config) {
        return this.callApi( `updateAutoAcceptConfiguration`, POST, config, null, null, true);
    }

    setAutoAcceptConfiguration(config) {
        return this.callApi( `setAutoAcceptConfiguration`, POST, config, null, null, true);
    }
    
    setNTEConfig(params) {
        return this.callApi( `setNTEConfig`, POST, params, null, null, true);
    }

    removeNTEConfig(identity) {
        return this.callApi( `removeNTEConfig`, POST, identity, null, null, true);
    }

    unpauseClient(clientId) {
        return this.callApi( `unpauseClient/${clientId}`, PATCH, null, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    updateClientConfigurationSettings(settings) {
        return this.callApi( `updateClientConfigurationSettings`, PUT, settings, null, null, true);
    }
    
    updateComment(id, locationId, rfpId, commentId, comment) {
        return this.callApi( `${id}/locations/${locationId}/requestForProposals/${rfpId}/comments/${commentId}`, PUT, comment, null, null, true);
    }
    
    updateLocation(id, locationId, location) {
        return this.callApi( `${id}/locations/${locationId}`, PUT, location, null, null, true);
    }
    
    updateRequestForProposal(clientId, locationId, rfpId, muteVendorNotifications, requestForProposal) {
        let params = {
            muteVendorNotifications
        };
    
        return this.callApi( `${clientId}/locations/${locationId}/requestForProposals/${rfpId}`, PUT, requestForProposal, params, null, true);
    }
    
    updateUserForClient(clientId, userId, user) {
        return this.callApi( `${clientId}/users/${userId}`, PUT, user, null, null, true);
    }
    
    updateUserForLocation(clientId, locationId, userId, user) {
        return this.callApi( `${clientId}/locations/${locationId}/users/${userId}`, PUT, user, null, null, true);
    }
    
    updateV2(params) {
        return this.callApi( `v2/update`, PATCH, params, null, null, true);
    }
    
    
}

export default ClientService;
