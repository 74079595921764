import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import ScheduleStatus from "../../../../enums/api/proposal/ScheduleStatus";
import ReschedulingClientSubmittedAvailability from "./ReschedulingClientSubmittedAvailability";
import ReschedulingVendorSubmittedAvailability from "./ReschedulingVendorSubmittedAvailability";
import ReschedulingElapsedView from "./ReschedulingElapsedView";
import {TextVariant} from "../../../../components/Text/Text.web";
import Schedule from "../../../../components/Schedule/Schedule.web";
import UserType from "../../../../enums/api/user/UserType";
import RequestStateLayout from "worksmith/composite-components/RequestState/RequestStateStatus/RequestStateLayout";
import AlertDialog from "../../../../components/Dialog/AlertDialog";

export const getReschedulingSubStatusFromCreatorType = (creatorType) => {
    if (creatorType === UserType.CLIENT) {
        return 'You Requested to Reschedule';
    } else {
        return 'Vendor Requested to Reschedule';
    }
}

// KEEP IN MIND THAT THIS VIEW IS USED FOR WALKTHROUGH DETAILS
class ReschedulingView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cancelChangeRequestModalOpen: false,
        }
    }

    showCancelChangeRequestModal = () => {
        this.setState({cancelChangeRequestModalOpen: true})
    };

    hideCancelChangeRequestModal = () => {
        this.setState({cancelChangeRequestModalOpen: false})
    };

    render() {
        const {hideCancelChangeRequestModal, showCancelChangeRequestModal} = this;
        const {cancelChangeRequestModalOpen} = this.state;
        const {
            acceptChangeRequest,
            cancelChangeRequest,
            creatorType,
            duration,
            frequency,
            loading,
            proposeSchedules,
            schedule,
            scheduleHandler,
            vendorLocation,
            useTicketing,
        } = this.props;

        let scheduledSubStateView = null;

        if (loading) {
            return (
                <RequestStateLayout loading={loading} status={''}/>
            );
        }

        if (scheduleHandler && scheduleHandler.openScheduleOptions.length > 0) {
            let hasActiveSchedules = scheduleHandler.openScheduleOptions.filter(schedule => !schedule.isExpired).length > 0;
            let scheduleState = scheduleHandler.openScheduleOptions[0].status;

             if (!hasActiveSchedules) {
                // return Schedules Elapsed
                scheduledSubStateView = (
                    <ReschedulingElapsedView
                        creatorType={creatorType}
                        duration={duration}
                        frequency={frequency}
                        loading={loading}
                        proposeSchedules={proposeSchedules}
                        schedule={schedule}
                        scheduleHandler={scheduleHandler}
                        showCancelChangeRequestModal={showCancelChangeRequestModal}
                        useTicketing={useTicketing}
                        vendorLocation={vendorLocation}/>
                );
            } else if (hasActiveSchedules && scheduleState === ScheduleStatus.NEEDS_CLIENT) {
                // scheduledSubStateView = Vendor Submitted Availability
                scheduledSubStateView = (
                    <ReschedulingVendorSubmittedAvailability
                        creatorType={creatorType}
                        acceptChangeRequest={acceptChangeRequest}
                        duration={duration}
                        frequency={frequency}
                        loading={loading}
                        proposeSchedules={proposeSchedules}
                        schedule={schedule}
                        scheduleHandler={scheduleHandler}
                        showCancelChangeRequestModal={showCancelChangeRequestModal}
                        useTicketing={useTicketing}
                        vendorLocation={vendorLocation}/>
                );
            } else if (hasActiveSchedules && scheduleState === ScheduleStatus.NEEDS_VENDOR) {
                // The client has submitted availability and is waiting for the Vendor Response
                scheduledSubStateView = (
                    <ReschedulingClientSubmittedAvailability
                        creatorType={creatorType}
                        loading={loading}
                        schedule={schedule}
                        scheduleHandler={scheduleHandler}
                        showCancelChangeRequestModal={showCancelChangeRequestModal}/>
                );
            }
        } else {
            scheduledSubStateView = (
                <RequestStateLayout status={'Rescheduling'}/>
            )
        }

        let onChangeRequestCancel = () => {
           hideCancelChangeRequestModal();
           cancelChangeRequest();
        }

        return (
            <Fragment>
                {
                    creatorType === UserType.CLIENT && scheduleHandler ?
                        <AlertDialog
                            acceptText={'Yes, Continue'}
                            body={`This will keep your current time.`}
                            cancelText={'Exit'}
                            debounceAccept
                            onAccept={onChangeRequestCancel}
                            onCancel={hideCancelChangeRequestModal}
                            open={cancelChangeRequestModalOpen}
                            title={'Cancel your Request to Reschedule?'}>
                            <Schedule
                                schedule={schedule}
                                textVariant={TextVariant.BODY_1}/>
                        </AlertDialog>
                    :
                    null
                }
                {scheduledSubStateView}
            </Fragment>
        )
    }
}

ReschedulingView.propTypes = {
    acceptChangeRequest: PropTypes.func.isRequired,
    cancelChangeRequest: PropTypes.func.isRequired,
    creatorType: PropTypes.oneOf([
        UserType.CLIENT,
        UserType.VENDOR,
    ]),
    duration: PropTypes.number,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    loading: PropTypes.bool,
    proposeSchedules: PropTypes.func.isRequired,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    scheduleHandler: PropTypes.shape({
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        })),
    }),
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

export default ReschedulingView;