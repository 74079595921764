import React from "react";
import * as PropTypes from "prop-types";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers"

import {TextVariant} from "worksmith/components/Text/Text.web";
import Text from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import {
    AlignItems,
    FlexDirection,
    JustifyContent,
    OverflowOption,
    TextAlign,
    WordBreak
} from "worksmith/enums/CSSEnums";
import {RequestTargetString} from "worksmith/helpers/LanguageHelper";
import Frequency from "worksmith/components/Frequency/Frequency.web";

const LocationAndRequestDetails = (props) => {
    const {address, cityStateZip, description, frequency, locationHours, target, timePreference} = props;

    return (
        <Grid alignItems={AlignItems.FLEX_START}
             container
             direction={FlexDirection.COLUMN}
             justify={JustifyContent.FLEX_START}
             textAlign={TextAlign.LEFT}>
            <Grid item>
                <Text variant={TextVariant.H6}>Details</Text>
            </Grid>

            <Grid item>
                <Text variant={TextVariant.SUBTITLE_2}>Store Address</Text>
                {ValueIsSet(address) &&
                    <Text variant={TextVariant.BODY_2}>{address}</Text>}
                <Text variant={TextVariant.BODY_2}>{cityStateZip}</Text>
            </Grid>

            <Grid item>
                <Text variant={TextVariant.SUBTITLE_2}>To Be Completed</Text>
                <Text variant={TextVariant.BODY_2}>{RequestTargetString(target)}</Text>
            </Grid>

            {ValueIsSet(frequency) &&
                <Grid item>
                    <Text variant={TextVariant.SUBTITLE_2}>Store Hours</Text>
                    <Frequency frequency={frequency}/>
                </Grid>}

            <Grid item>
                <Text variant={TextVariant.SUBTITLE_2}>Time Preference</Text>
                <Text variant={TextVariant.BODY_2}>{timePreference}</Text>
            </Grid>

            <Grid item>
                <Text variant={TextVariant.SUBTITLE_2}>Description of Service Request</Text>
                <Text wordBreak={WordBreak.BREAK_ALL} variant={TextVariant.BODY_2}>{description}</Text>
            </Grid>

            {ValueIsSet(locationHours) &&
                <Grid item>
                    <Text variant={TextVariant.SUBTITLE_2}>Store Hours</Text>
                    {locationHours.map((hours, index) =>
                        <div key={index}>
                            <Text variant={TextVariant.BODY_2}>{hours}</Text>
                        </div>
                    )}
                </Grid>}
        </Grid>
    );
};

LocationAndRequestDetails.propTypes = {
    address:        PropTypes.string,
    cityStateZip:   PropTypes.string.isRequired,
    description:    PropTypes.string.isRequired,
    frequency:      PropTypes.object,
    locationHours:  PropTypes.arrayOf(PropTypes.string),
    target:         PropTypes.object.isRequired,
    timePreference: PropTypes.string.isRequired
};

export default LocationAndRequestDetails;