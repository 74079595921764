import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LineItemFieldsView from "worksmith/components/LineItem/LineItemForm/LineItemFieldsView";
import {LineItemProperties} from "worksmith/enums/LineItemProperties";
import LineItemType from "worksmith/enums/api/psldb/LineItemType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

//TODO smartinelli FORM VALIDATION?
class LineItemFields extends Component {
    constructor(props) {
        super(props);

        if(ValueIsSet(props.initialLineItem)) {
            const {
                excludeRevenueShare,
                lineItemType,
                quantity,
                unitRetailPrice,
                unitWholesalePrice,
                rateCardEntry
            } = props.initialLineItem;

            this.state = {
                excludeRevShare:            excludeRevenueShare,
                lineItemType:               lineItemType,
                totalRetailPrice:           unitRetailPrice * quantity,
                totalWholesalePrice:        Number(unitWholesalePrice).toFixed(2) * quantity,
                unitRetailPrice:            unitRetailPrice,
                rateCardEntry: rateCardEntry
            };
        } else {
            this.state = {
                excludeRevShare:     false,
                lineItemType:        LineItemType[0],
                totalRetailPrice:    0.00,
                totalWholesalePrice: 0.00,
                unitWholesalePrice:  0.00
            };
        }

        this.lineItem = props.initialLineItem;
    }

    onChange = (property, value) => {
        let {lineItem} = this;
        const {unitWholesalePrice} = this.state;
        const {effectiveWholesaleRate, index, lineItemTypes, onChange} = this.props;

        lineItem[property] = value;

        if(property === LineItemProperties.LINE_ITEM_TYPE) {
            lineItem[property] = lineItemTypes.filter((lineItemType) => lineItemType.name === value)[0];
            if(ValueIsSet(lineItem[property].contractItem)) {
                lineItem.rateCardEntry              = lineItem[property];
                lineItem.unitRetailPrice            = lineItem[property].unitRetailPrice;
                lineItem.unitWholesalePrice         = lineItem[property].unitWholesalePrice;
                lineItem.lineItemType               = lineItem[property].contractItem.lineItemType;
                this.setState({
                    lineItemType:               lineItem.lineItemType,
                    totalRetailPrice:           lineItem.unitRetailPrice * lineItem.quantity,
                    totalWholesalePrice:        Number(lineItem[property].unitWholesalePrice).toFixed(2) * lineItem.quantity,
                    rateCardEntry:              lineItem.rateCardEntry});
            } else {
                lineItem.rateCardEntry = null;
                this.setState({
                    lineItemType:               lineItem[property],
                    rateCardEntry:              null
                });
            }
        }

        if(property === LineItemProperties.UNIT_WHOLESALE_PRICE)
            lineItem.unitRetailPrice = lineItem.unitWholesalePrice / effectiveWholesaleRate;

        if(lineItem[LineItemProperties.LINE_ITEM_TYPE].name === LineItemType.FEE
            && lineItem[LineItemProperties.EXCLUDE_REVENUE_SHARE])
            lineItem.unitWholesalePrice = lineItem.unitRetailPrice;
        else
            lineItem.unitRetailPrice = lineItem.unitWholesalePrice / effectiveWholesaleRate;

        if(lineItem.unitWholesalePrice !== unitWholesalePrice || property === LineItemProperties.QUANTITY) {
            this.setState({
                totalRetailPrice: lineItem.unitRetailPrice * lineItem.quantity,
                totalWholesalePrice: Number(lineItem.unitWholesalePrice).toFixed(2) * lineItem.quantity,
                unitRetailPrice: Number(lineItem.unitRetailPrice)
            });
        }
        onChange(index, lineItem);
    };

    onBlur = (property, event) => {
        let {onChange} = this;
        onChange(property, event.target.value);
    };

    render() {
        const {onChange, onBlur} = this;
        const {
            excludeRevShare,
            lineItemType,
            totalRetailPrice,
            totalWholesalePrice,
            unitRetailPrice,
            rateCardEntry
        } = this.state;
        const {
            allowExcludeRevShare,
            errors,
            lineItemTypes,
            portalType
        } = this.props;
        const {
            description,
            quantity,
            unitWholesalePrice,
        } = this.props.initialLineItem;

        return (
            <LineItemFieldsView allowExcludeRevShare={allowExcludeRevShare}
                                excludeRevShare={excludeRevShare}
                                errors={errors}
                                initialDescription={description}
                                initialQuantity={Number(quantity)}
                                initialUnitWholesalePrice={Number(unitWholesalePrice).toFixed(2)}
                                lineItemType={lineItemType}
                                lineItemTypes={lineItemTypes}
                                onChange={onChange}
                                onBlur={onBlur}
                                totalRetailPrice={totalRetailPrice}
                                totalWholesalePrice={totalWholesalePrice}
                                unitRetailPrice={Number(unitRetailPrice)}
                                portalType={portalType}
                                rateCardEntry={rateCardEntry}/>
        )
    }
}

LineItemFields.propTypes = {
    allowExcludeRevShare:   PropTypes.bool,
    effectiveWholesaleRate: PropTypes.number,
    errors:                 PropTypes.shape({
                                description:     PropTypes.bool,
                                quantity:        PropTypes.bool,
                                unitRetailPrice: PropTypes.bool
                            }),
    index:                  PropTypes.number,
    initialLineItem:        PropTypes.object.isRequired,
    lineItemTypes:          PropTypes.arrayOf(PropTypes.shape({
                                id:   PropTypes.number.isRequired,
                                name: PropTypes.string.isRequired
                            })).isRequired,
    onChange:               PropTypes.func.isRequired,
    portalType:             CustomPropTypes.enum(PortalType)
};

LineItemFields.defaultProps = {
    allowExcludeRevShare: false
};

export default LineItemFields;