import React from 'react'
import PropTypes from 'prop-types';

import {AlignItems, FlexWrap, JustifyContent} from "worksmith/enums/CSSEnums";
import Grid from "worksmith/components/Grid/Grid";
import Icon, {IconFontSize} from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";

const MaxFileSizeTextAndToolTip = ({title, tooltipPlacement}) => {

    return (
        <Grid container justify={JustifyContent.FLEX_START} wrap={FlexWrap.NO_WRAP} alignItems={AlignItems.BASELINE}>
            {title &&
                <Grid item>
                    <Text variant={TextVariant.SUBTITLE_1}>{title}</Text>
                </Grid>
            }
            <Tooltip
                placement={tooltipPlacement}
                title={
                    <>
                        <Text color={TextColor.WHITE}>16MB max for individual uploads</Text>
                        <Text color={TextColor.WHITE}>64MB max for combined total</Text>
                    </>
                }
            >
                <Grid container item margin={'0'} justify={JustifyContent.FLEX_START} wrap={FlexWrap.NO_WRAP} alignItems={AlignItems.BASELINE}>
                    <Grid item padding={title ? "0 0 0 10px" : "0 4px"}>
                        <Text variant={TextVariant.CAPTION} color={TextColor.SECONDARY}>
                            Max file size
                        </Text>
                    </Grid>
                    <Grid item padding={'0 3px 0 3px'}>
                        <Icon verticalSub secondary fontSize={IconFontSize.SMALL} name={IconType.INFO_OUTLINED}/>
                    </Grid>
                </Grid>
            </Tooltip>
        </Grid>
    );
};

export default MaxFileSizeTextAndToolTip;

MaxFileSizeTextAndToolTip.propTypes = {
    title: PropTypes.string,                                            //title text presented before the "Max File Size" info text
    tooltipPlacement: PropTypes.oneOf(Object.values(TooltipPlacement)), //tooltip placement for the "Max File Size" info text
};