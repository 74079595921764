import React, {Component} from 'react';
import t from 'tcomb-form';
import ClientService from 'worksmith/services/api/ClientService';
import notification from 'antd/lib/notification';
import Button from "../Button";
import Loader from "../Loader";

const Form = t.form.Form;
const clientService = new ClientService();

class AssociateUserToLocationForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            user: this.props.user,
            locations: [],
            value: {},
            loading: true
        };

        this.submitAssociation = this.submitAssociation.bind(this);
        this.submitDisassociation = this.submitDisassociation.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        let _this = this;
        let {user} = this.state;

        let clientId;

        if (user.clientRoles !== null) {
            clientId = user.clientRoles[0].client.id;
        } else {
            clientId = user.clientLocationRoles[0].clientId;
        }
        clientService.getLocations(clientId).then(function (data) {
            let locations = data;
            let locationsToObject;
            if(locations.length){
                locationsToObject =  locations.reduce(function (map, obj) {
                    map[obj.title + " " + obj.id] = obj.title;
                    return map;
                }, {});

                _this.setState({
                    locations: locationsToObject
                });
            }

            _this.setState({ loading: false });
        })
    }

    onChange(value){
        this.setState({ value })
    }

    submitAssociation(asRegionalManager){
        let _this = this;
        let {user, value} = this.state;
        let clientId = user.clientLocationRoles[0].clientId;
        // This is because in order for tcombs to sort the list, the name has to be included in the key
        let parsedKey = value.location.split(" ");
        let locationId = parseInt(parsedKey[parsedKey.length - 1], 10);
        let userId = user.id;
        clientService.associateUserWithLocation(clientId, locationId, userId, asRegionalManager).then(function(data){
            let user = data;
            _this.setState({ user });
            notification['success']({
                message: 'User updated!'
            });
        })
    }

    submitDisassociation(locationId){
        let _this = this;

        if (_this.state.user.clientLocationRoles.length > 1) {
            let {user} = _this.state;
            let clientId = user.clientLocationRoles[0].clientId;
            let userId = user.id;
            clientService.disassociateUserWithLocation(clientId, userId, locationId).then(function (data) {
                _this.setState({user: data});
                notification['success']({
                    message: 'User updated!'
                });
            })
        } else {
            notification['warning']({
                message: 'Users must have at least one location associated to them!'
            })
        }
    }

    render(){
        let {user, loading, locations} = this.state;
        let _this = this;

        if(!loading){
            const AssociateUserToLocationStruct = t.struct({
                location: t.enums(locations)
            });

            const AssociateUserToLocationOptions = {
              fields: {
                  location: {
                      label: `Add a new location to ${user.firstName}'s profile`
                  }
              }
            };

            return(
                <div className='ws-section ws-section--plain'>
                    <div className="row">
                        <div className="col-md-6">
                            <Form options={AssociateUserToLocationOptions}
                                  onChange={this.onChange}
                                  type={AssociateUserToLocationStruct}
                                  value={this.state.value}/>
                        </div>
                        <div className="col-md-6">
                            <div className={'hidden-sm-down'}><label>&nbsp;</label></div>
                            <div><Button type={'info'} className={'full-width-sm'} message={`Add ${user.firstName} as a Regional User for Location`} onPress={() => this.submitAssociation(true)}/></div>
                            <Button type={'success'} className={'full-width-sm'} message={`Add ${user.firstName} as a Local User for Location`} onPress={() => this.submitAssociation(false)}/>
                        </div>
                    </div>
                    <div className="margin-top-sm">
                        <p className="section-accent">Assigned Locations</p>
                        {user.clientLocationRoles.map(function(role, idx){
                            return (
                                <div key={idx}>
                                    <div className="d-flex flex-row align-items-center">
                                        <p className={'margin-bottom-xs'}>{`${role.role} @ ${role.clientLocation.name}`}</p>
                                        <Button type={'danger-plain'} icon={'close'} onPress={() => _this.submitDisassociation(role.clientLocation.id)}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

export default AssociateUserToLocationForm;