import styled from "styled-components";
import React, {Component} from "react";
import {Color} from 'worksmith/enums/Color';
import {Avatar, MenuItem, Menu, Button as MaterialButton} from '@mui/material';
import {withStyles} from '@mui/styles'
import Button from "./Button";
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Text from "worksmith/antd-components/Text/Text.web"
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip.web";
import {withRouter} from "react-router-dom";
import Icon from "@mui/material/Icon";


const userService = new UserService();
const authTokenManager = new AuthTokenManager();

const TopBar = styled.div`
    padding-top: 0.5em;
    border-style: solid;
    border-width: 0px 0px 0.5px 0px;
    border-color: ${Color.GREY};
    grid-area: header;
    direction: rtl;
    
    @media print {
        display: none;
    }
`;

const InlineBlock= styled.div`
    display: inline-block;
    direction: ltr;
`;

const MenuExpander = styled.div`
    display: none;
    @media only screen and (max-width: 900px) {
        display: block;
        position: absolute;
        left: 0.5em;
        margin-top: 0.5em;
    }
`;

const MenuIcon = styled(Icon)`
    font-size: 2.0em !important;
`;

const CornerAvatar = styled(Avatar)`
    margin-top: 0.1em;
    margin-right: 2em;
    margin-left: 1em;
`;

const AddButton = withStyles({
    root: {
        background: '#293D4B',
        color: 'white',
    },
})(MaterialButton);

const userMenu = (isSelfService, user, handleLogout) => [
    <Text>{user.firstName} {user.lastName}</Text>,
    <Button id={'topMenuMyAccount'} type={'link'} linkTo={'/account'} icon={'user'} message={'My Account'}/>,
    ...!isSelfService ? [<Button id={'topMenuApprovalSettings'} type={'link'} linkTo={'/approval-settings'} icon={'settings'} message={'Approval Settings'}/>] : [],
    <Button type={'link'} message={'Log Out'} onPress={() => handleLogout()} icon={'logout'}/>
    ];

const addItemMenu = (isSelfService, user) => [
    ...!isSelfService ? [<Button id={'topMenuAddNewRequest'} type={'link'} linkTo={'/new-request'} icon={'envelope'} message={'Add new request'}/>] : [],
    ...user.clientRoles ? [<Button type={'link'} linkTo={'/new-location'} icon={'map'} message={'Add new location'}/>] : [],
    ...user.clientRoles ? [<Button type={'link'} linkTo={'/new-user'} icon={'user'} message={'Add new user'}/>] : []
];

class TopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            avatarAnchorElement: null,
            newItemAnchorElement: null,
        };
    }

    componentDidMount() {
        let _this = this;
        userService.findOne(authTokenManager.getUserId()).then(function (data) {
            _this.setState({user: data});
        })
    }

    handleLogout = () => {
        let {onLogout} = this.props;
        onLogout();
    };

    setAvatarAnchorElement = (target) => {
        this.setState({avatarAnchorElement: target});
    };

    setNewItemAnchorElement = (target) => {
        this.setState({newItemAnchorElement: target});
    };

    render() {
        const {user, avatarAnchorElement, newItemAnchorElement} = this.state;
        const {setAvatarAnchorElement, setNewItemAnchorElement, handleLogout} = this;
        const {expandSidebar, clientName} = this.props;
        const {isSelfService} = this.props.client;

        return !ValueIsSet(user.firstName) ? <div/> :
        <TopBar>
            <InlineBlock>
                <Tooltip placement={TooltipPlacement.BOTTOM_START} title={`${user.displayName} | ${clientName}`}>
                    <CornerAvatar id={'userAvatar'} onClick={(event) => setAvatarAnchorElement(event.currentTarget)}>{user.firstName[0]+user.lastName[0]}</CornerAvatar>
                </Tooltip>
                <Menu
                    id="avatar-menu"
                    anchorEl={avatarAnchorElement}
                    keepMounted
                    open={Boolean(avatarAnchorElement)}
                    onClose={() => setAvatarAnchorElement(null)}
                >
                    {userMenu(isSelfService, user, handleLogout).map((option, index) => (
                        <MenuItem
                            key={index}
                            disabled={index === 0}
                            onClick={() => setAvatarAnchorElement(null)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            </InlineBlock>
            <InlineBlock>
                <AddButton id={'topMenuAddButton'} variant="contained" color="inherit" onClick={event => setNewItemAnchorElement(event.currentTarget)}>
                    + Add
                </AddButton>
                <Menu
                    id="add-menu"
                    anchorEl={newItemAnchorElement}
                    keepMounted
                    open={Boolean(newItemAnchorElement)}
                    onClose={() => setNewItemAnchorElement(null)}
                >
                    {addItemMenu(isSelfService, user).map((option, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => setNewItemAnchorElement(null)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            </InlineBlock>
            <MenuExpander>
                <MenuIcon onClick={() => expandSidebar(true)}>menu</MenuIcon>
            </MenuExpander>
        </TopBar>
    }
}

export default withRouter(TopMenu);