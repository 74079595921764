import React, {Component, Fragment} from 'react';

import PropTypes from 'prop-types';
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import ObligationTemplateDetailsView from "./ObligationTemplateDetailsView";

const graphQLService = new GraphQLServiceClass();

class ObligationTemplateDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            obligationTemplate: {}
        };
    }

    componentDidMount = () => {
        const {obligationTemplateId} = this.props;

        graphQLService.findOneById(
            obligationTemplateId,
            GraphQLObjectType.OBLIGATION_TEMPLATE,
            ObligationTemplateDetailsGraphQlString
        ).then((data) => {
            this.setState({loading: false, obligationTemplate: data});
        })
    }

    render() {
        const {loading, obligationTemplate} = this.state;

        return (
            <ObligationTemplateDetailsView
                loading={loading}
                itemTemplates={obligationTemplate.itemTemplates}
                schedule={obligationTemplate.schedule}
                totalRetailPrice={obligationTemplate.totalRetailPrice}
                vendorLocation={obligationTemplate.vendorLocation}
            />
        )
    }
}

ObligationTemplateDetails.propTypes = {
    obligationTemplateId: PropTypes.number,
};

const ObligationTemplateDetailsGraphQlString = `
    hasPreferredVendor
    itemTemplates {
        lineItemType {
            name
        }
        quantity
        title
        unitRetailPrice
    }
    schedule {
        arrivalEndTime
        arrivalStartTime
        date
        dayOfMonth
        daysOfWeek
        duration
        recurrenceType
        repeatEvery
        returnDate
    }
    totalRetailPrice
    vendorLocation {
        id
        rating
        ratingsCount
        vendor {
            id
            officialName
            qualifiesForPremiumBadge
            vendorBio {
                aboutUs
            }
        }
    }
`;

export default ObligationTemplateDetails;