import React, {Component} from 'react';
import PropTypes from 'prop-types';

import AsyncGraphQLServiceClass from "../../../../services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RfpStatus from "../../../../enums/api/proposal/RfpStatus";
import CorporateBidReviewView from "./CorporateBidReviewView";
import ClientLocationRole from "worksmith/enums/api/user/ClientLocationRole";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import BidService from "../../../../services/api/BidService";
import ScheduleHandlerService from "worksmith/services/api/ScheduleHandlerService";

const graphQLServiceClass = new AsyncGraphQLServiceClass();
const bidService = new BidService();
const scheduleHandlerService = new ScheduleHandlerService();

class CorporateBidReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null,
            bid: ValueIsSet(props.request) ? props.request.approvalRequestedOpportunity.activeBidOrWalkthrough : null
        };
    }

    async componentDidMount() {
        const {requestId} = this.props;

        if (requestId) {
            let data = await this.getRequestFromId(requestId);
            this.setState({loading: false, request: data, bid: data.approvalRequestedOpportunity.activeBidOrWalkthrough});
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            newState.request = DeepCopy(props.request);
        }

        return newState;
    };

    getRequestFromId = async (requestId) => {

        return await graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            CorporateBidReviewGraphQLString)
    };


    acceptBid = (schedules) => {
        const {bidId, onAcceptBid, startLoad} = this.props;
        const {bid} = this.state;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.accept({id: ValueIsSet(bidId) ? bidId : bid.id, concurrentWithCounteringSchedule: ValueIsSet(schedules)})
            .then((data) => {
                if (schedules) {
                    scheduleHandlerService.makeCounterOffer({scheduleHandlerId: bid.scheduleHandler.id, schedules: schedules, concurrentWithProposalAcceptance: true})
                        .then(() => {
                            onAcceptBid();
                            this.setState({loading: false});

                        })
                } else {
                    onAcceptBid();
                    this.setState({loading: false});
                }
            })
    }

    acceptAndSchedule = (schedule) => {
        const {bidId, onAcceptBid, startLoad} = this.props;
        const {bid} = this.state;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.acceptAndSchedule({bidId: ValueIsSet(bidId) ? bidId : bid.id, scheduleId: schedule.id})
            .then((data) => {
                onAcceptBid();
                this.setState({loading: false});
            })
    }

    requestRevision = (requestedRevision) => {
        const {onRequestRevision, startLoad} = this.props;
        const {request} = this.state;

        if (startLoad) {
            startLoad();
        } else {
            this.setState({loading: true});
        }

        bidService.requestRevision(request.approvalRequestedOpportunity.activeBidOrWalkthrough.id, requestedRevision)
            .then((data) => {
                onRequestRevision();
                this.setState({loading: false});
            })
    }

    render() {
        const {acceptBid, acceptAndSchedule, requestRevision} = this;
        const {bid, request, loading} = this.state;
        const {user} = this.props;

        return (
            <CorporateBidReviewView
                acceptBid={acceptBid}
                duration={ValueIsSet(bid) ? bid.duration : null}
                frequency={ValueIsSet(bid) ? bid.frequency : null}
                acceptAndSchedule={acceptAndSchedule}
                bidId={ValueIsSet(bid) ? bid.id : null}
                requestRevision={requestRevision}
                loading={loading || this.props.loading}
                request={request}
                user={user}/>
        )
    }
}

CorporateBidReview.propTypes = {
    loading: PropTypes.bool,
    onAcceptBid: PropTypes.func.isRequired,
    onDeclineBid: PropTypes.func.isRequired,
    onRequestCancel: PropTypes.func.isRequired,
    request: PropTypes.shape({
        id: PropTypes.number,
        clientLocation: PropTypes.shape({
            id: PropTypes.number
        }),
        status: CustomPropTypes.enum(RfpStatus),
        approvalRequestedActivity: PropTypes.shape({
            user: PropTypes.shape({
                id: PropTypes.number,
                displayName: PropTypes.string
            })
        }),
        approvalRequestedOpportunity: PropTypes.shape({
            activeBidOrWalkthrough: PropTypes.shape({
                additionalApprovalAmount: PropTypes.number,
                additionalTerms: PropTypes.string,
                bidItems: PropTypes.arrayOf(PropTypes.shape({
                    lineItemType: PropTypes.shape({
                        name: PropTypes.string
                    }),
                    quantity: PropTypes.number,
                    name: PropTypes.string,
                    unitRetailPrice: PropTypes.number
                })),
                duration: PropTypes.number,
                id: PropTypes.number,
                frequency: PropTypes.shape({
                    recurrenceCount: PropTypes.number,
                    recurrenceType: CustomPropTypes.enum(RecurrenceType),
                    repeatEvery: PropTypes.number,
                }),
                scheduleHandler: PropTypes.shape({
                    id: PropTypes.number,
                    openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
                        id: PropTypes.number,
                        isExpired: PropTypes.bool,
                        schedule: PropTypes.shape({
                            arrivalEndTime: PropTypes.string,
                            arrivalStartTime: PropTypes.string,
                            date: PropTypes.string,
                            dayOfMonth: PropTypes.bool,
                            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                            duration: PropTypes.number,
                            recurrenceType: CustomPropTypes.enum(RecurrenceType),
                            repeatEvery: PropTypes.number,
                            returnDate: PropTypes.string
                        }),
                        status: PropTypes.string
                    }))
                }),
                totalRetailPrice: PropTypes.number,
            }),
            hasPreferredVendor: PropTypes.bool,
            vendorLocation: PropTypes.shape({
                rating: PropTypes.number,
                vendor: PropTypes.shape({
                    officialName: PropTypes.string,
                    qualifiesForPremiumBadge: PropTypes.bool,
                })
            }),
        }),
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func,
    user: PropTypes.shape({
        id: PropTypes.number,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
        clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
            clientLocation: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: CustomPropTypes.enum(ClientLocationRole),
        })),
    })
};

const CorporateBidReviewGraphQLString = `
    clientLocation {
        id
    }
    id
    status
    approvalRequestedActivity {
        user {
            id
            displayName
        }
    }
    serviceLine{
        useTicketing
    }
    approvalRequestedOpportunity {
        activeBidOrWalkthrough {
            additionalApprovalAmount
            canRequestRevision
            additionalTerms
            isWalkthrough
            bidItems {
                lineItemType {
                    name
                }
                quantity 
                name
                unitRetailPrice
            },
            duration
            id
            frequency {
                recurrenceCount
                recurrenceType
                repeatEvery
            }
            scheduleHandler {
                id
                openScheduleOptions {
                    id
                    isExpired
                    status
                    schedule {
                        arrivalEndTime
                        arrivalStartTime
                        date
                        dayOfMonth
                        daysOfWeek
                        duration
                        recurrenceType
                        repeatEvery
                        returnDate
                    }
                }
            }
            totalRetailPrice
        }
        hasPreferredVendor
        vendorLocation {
            rating
            ratingsCount
            vendor {
                officialName
                qualifiesForPremiumBadge
            }
        }
    }
`;

export default CorporateBidReview;