import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";

import {Color} from "../Enums";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Text from 'worksmith/antd-components/Text/Text.web';
import {TextDecoration} from "worksmith/enums/CSSEnums.js";


const Option = styled.div`
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    background-color: transparent;    
    :hover {
        background-color: rgba(255,255,255,0.15);      
    }
`;

const OptionText = styled(Text)`
    font-family: "Open Sans", Verdana, Geneva, sans-serif;
    letter-spacing: 0.75px;
    font-size: 13px;
    color: ${Color.WHITE};
`;

const MenuOptionExternal = (props) =>
    <a href={props.to} target={"_blank"} style={{ textDecoration: TextDecoration.NONE }}>
        <Option id={props.id}>
            <OptionText className={props.className}>{props.children} <OpenInNewIcon className={"v-middle"} style={{color:Color.WHITE, fontSize:'13px'}} /></OptionText>
        </Option>
    </a>
;

MenuOptionExternal.propTypes = {
    id: PropTypes.string,
    to: PropTypes.string
};

export default MenuOptionExternal
