import React, {Component} from 'react';
import Modal from 'react-modal';
import notification from 'antd/lib/notification';
import Button from "../Button";
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';

const obligationItemGroupService = new ObligationItemGroupService();
const t = require('tcomb-form');
const Form = t.form.Form;

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width                 : '40%'
    }
};

class TicketOutreachForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticket: props.ticket,
            visible: props.visible,
            outreach: {},
            valid: false
        };

        this.submit = this.submit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    submit() {
        let {ticket, outreach} = this.state;
        let _this = this;

        ticket.outreach = outreach;

        obligationItemGroupService.update(ticket).then(function (data) {
            notification['success']({
                message: 'Outreach Added!'
            });
            _this.handleClose();
        }).catch(function (error) {
            alert(`${error}`);
        });
    }

    handleClose() {
        this.setState({outreach: {}, valid: false});

        this.props.handleClose();
    }

    onChange(value) {
        let validation = this.refs.form.validate();

        this.setState({outreach: value, valid: !validation.errors.length});
    }

    componentWillReceiveProps(newProps) {
        this.setState({visible: newProps.visible});
    }

    render() {
        let {visible, valid} = this.state;
        let _this = this;

        const OutreachForm = t.struct({
            submitterName: t.String,
            outreachType: t.enums({
                'phone': 'Phone',
                'email': 'Email',
                'sms': 'SMS'
            }),
            description: t.maybe(t.String)
        });

        const OutreachFormOptions = {
            fields: {
                submitterName: {
                    label: 'Please enter your name *'
                },
                outReachType: {
                    label: 'Outreach Type *'
                },
                description: {
                    type: 'textarea'
                }
            }
        };

        return (<div>
            <Modal isOpen={visible} style={customStyles}>
                <div style={{float: 'right'}}>
                    <Button type={'danger-outline'}
                            onPress={() => this.handleClose()}
                            className={'margin-right-sm'}
                            icon={'close'}/>
                </div>
                <h2>Log Outreach</h2>
                <Form ref="form"
                      type={OutreachForm}
                      value={_this.state.outreach}
                      onChange={(value) => this.onChange(value)}
                      options={OutreachFormOptions}/>
                <button disabled={!valid} onClick={() => _this.submit()}>Submit</button>
            </Modal>
        </div>)
    }
}

export default TicketOutreachForm;
