import React, {Component} from 'react';
// noinspection NpmUsedModulesInstalled
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import CustomPropTypes from "../../../utilities/propTypes/CustomPropTypes";
import {ModalStyle} from "../../../Enums";
import Button from "../../Button";
import {Display, Position} from "../../../CSSEnums";

const ButtonContainer = styled.div`
    display: ${Display.INLINE_BLOCK};
    position: ${Position.ABSOLUTE};
    right: 20px;
    top: 10px;
`;

class Modal extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            open: false
        };
    }

    open = () => {
        let {open} = this.state;

        if (!open) {
            this.setState({open: true})
        }
    };

    close = () => {
        let {open} = this.state;
        let { onClose } = this.props;

        if (open) {
            this.setState({open: false});
            if(onClose)
                onClose();
        }
    };
    
    render() {
        let {close} = this;
        let {open} = this.state;
        let {style, hideCloseButton, title, children} = this.props;

        return (
            <ReactModal
                ariaHideApp={false}
                style={style}
                onRequestClose={close}
                isOpen={open}
                contentLabel={title}>
                {hideCloseButton ?
                    null
                    :
                    <ButtonContainer>
                        <Button type={'link'} icon={'remove'} message={'close'}
                                onPress={close}/>
                    </ButtonContainer>
                }
                {children}
            </ReactModal>
        )
    }
}

Modal.propTypes = {
    hideCloseButton: PropTypes.bool,
    onClose: PropTypes.func,
    style: CustomPropTypes.enum(ModalStyle),
    title: PropTypes.string
};

Modal.defaultProps = {
    style: ModalStyle.DEFAULT
};



export default Modal;