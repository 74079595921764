import React, {useState} from 'react';
import PropTypes from "prop-types";

import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import ClientService from "worksmith/services/api/ClientService";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import TextField, {TextFieldSize} from "worksmith/components/Inputs/TextField/TextField";
import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {Debounce, globalSnackbarTrigger, StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {IconType} from "worksmith/enums/MaterialEnums";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const clientService = new ClientService();


const StandardItemEdit = ({
                              client,
                              getClientStandardItems,
                              lineItem,
                              serviceLine,
                              setEdit,
                              setStandardItems,
                              standardItems,
                          }) => {

    const [addingItem, setAddingItem] = useState(false);
    const [saving, setSaving] = useState(false);
    const [initialStandardItems, setInitialStandardItems] = useState(JSON.parse(JSON.stringify(standardItems)));

    const handleUpdateItem = (name, id) => {
        setStandardItems((prevState) => {
            return prevState.map((item) => {
                if (item.id === id) {
                    item.changed = true;
                    // Check if inputs values contain text. If not add error field to item.
                    if (StringHasText(name)) {
                        item.name = name;
                        item.error = null;
                        return item
                    } else {
                        item.error = "*Input required";
                        return item
                    }
                } else return item;
            });
        });
    };

    const handleAddNewItem = () => {
        setAddingItem(true);
        setStandardItems([...standardItems, {id: 0, name: null}])
    };

    const checkInputsAreValid = () => {
        let newItemList = [];
        let isValid = true;
        standardItems.map(item => {
            if (!StringHasText(item.name)) {
                newItemList.push({id: item.id, name: item.name, error: "*Input required"});
                isValid = false;
            }
            else {
                newItemList.push({id: item.id, name: item.name})
            }
        });
        setStandardItems(newItemList);
        return isValid;
    };

    const onSave = () => {
        if (checkInputsAreValid()) {
            setSaving(true);

            let updatedItems = [];

            standardItems.filter(item => item.changed).forEach(item => {
                updatedItems.push({
                    name: item.name,
                    id: item.id,
                    client:{
                        id: client.id,
                        name: client.officialName
                    },
                    serviceLine:{
                        id: serviceLine.id,
                        name: serviceLine.name
                    },
                    lineItemType:{
                        id: lineItem.id,
                        name: lineItem.name
                    }
                });
            });

            if (updatedItems.length > 0 ) {
                clientService.saveStandardItems(updatedItems).then(() => {
                    getClientStandardItems();
                    globalSnackbarTrigger("Items updated.");
                }).catch(error => {
                    console.error("Error saving standard items: " + error);
                    DisplayErrorNotification("Error saving standard items");
                }).finally(() => {
                    setSaving(false);
                    setEdit(false);
                });
            } else {
                setSaving(false);
                setEdit(false);
            }
            return updatedItems
        }
    };


    return (
        <Grid container justify={JustifyContent.FLEX_START} alignItems={AlignItems.FLEX_START}>
            {standardItems.map(item => {
                return (
                    <Grid item xs={3} key={item.id} margin={'0 0 20px 0'}>
                        <TextField
                            label={'Garment name'}
                            initialValue={item.name}
                            size={TextFieldSize.SMALL}
                            onChange={Debounce((name) => handleUpdateItem(name, item.id), 400)}
                            error={ValueIsSet(item.error)}
                            helperText={item.error}
                        />
                    </Grid>
                )
            })}
            {!addingItem &&
                <Grid item xs={3} padding={'11px 0 0 8px'}>
                    <Button
                        onClick={() => handleAddNewItem()}
                        variant={ButtonVariant.OUTLINED}
                        startIcon={<Icon name={IconType.ADD}/>}
                        primary
                    >
                        Add Garment
                    </Button>
                </Grid>
            }
            <Grid container item xs={12} padding={'20px 0 0 0'}>
                <Grid item xs={2}>
                    <Button
                        onClick={() => {
                            setStandardItems(initialStandardItems);
                            setEdit(false)
                        }}
                        variant={ButtonVariant.CONTAINED}
                        fullWidth
                    >
                        Cancel
                    </Button>

                </Grid>
                <Grid item xs={2}>
                    <Button
                        onClick={() => onSave()}
                        variant={ButtonVariant.CONTAINED}
                        primary
                        fullWidth
                        disabled={standardItems.some(item => ValueIsSet(item.error))}
                    >
                        {saving ? <CircularProgress size={24}/> : 'Save'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default StandardItemEdit;

StandardItemEdit.propTypes = {
    client: PropTypes.object,
    getClientStandardItems: PropTypes.func,
    lineItem: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        isAvailableForStandardItems: PropTypes.bool,
        standardItemsForClient: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                client: PropTypes.shape({
                    id: PropTypes.number
                })
            })
        )
    }),
    serviceLine: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        lineItemTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isAvailableForStandardItems: PropTypes.bool,
                standardItemsForClient: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        client: PropTypes.shape({
                            id: PropTypes.number
                        })
                    })
                )
            })
        )
    }),
    setEdit: PropTypes.func,
    setStandardItems: PropTypes.func,
    standardItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            client: PropTypes.shape({
                id: PropTypes.number
            })
        })
    ),
};