import React, {useState} from 'react';
import PropTypes from "prop-types";

import CollapsableLocations from "../locations/CollapsableLocations";
import Grid from "worksmith/components/Grid/Grid";
import IconButton from "worksmith/components/Button/IconButton";
import Table from "worksmith/components/Table/Table";
import Text from "worksmith/components/Text/Text";
import {CountryCode} from "worksmith/enums/api/country/CountryCode";
import {IconType} from "worksmith/enums/MaterialEnums";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const VendorsPageView = ({
                             currentPage,
                             handleChangePage,
                             loading,
                             setEditVendor,
                             setShowAddEditVendor,
                             totalDataLength,
                             vendors,
                             vendorsPerPage,
                         }) => {

    const [hoveredRow, setHoveredRow] = useState(null);


    let columns = [
        {
            title: 'Company Name',
            cellValueFunction: (data) => {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Text>
                                {data.nickname}
                            </Text>
                        </Grid>
                    </Grid>
                )
            },
            cellProps: {
                style: {
                    minWidth: '125px',
                }
            }
        },
        {
            title: "User Name",
            cellValueFunction: (data) => {
                return (
                    <Grid container>
                        {data.users != null && data.users.length > 0 &&
                        <Grid item xs={12}>
                            <Text>{data.users[0].firstName + " " + data.users[0].lastName}</Text>
                        </Grid>
                        }
                    </Grid>

                )
            },
            cellProps: {
                style: {
                    minWidth: '100px',
                }
            }
        },
        {
            title: 'Contact Info',
            cellValueFunction: (data) => {
                return (
                    <Grid container>
                        {data.users != null && data.users.length > 0 &&
                        <Grid item xs={12}>
                            <Text>{data.users[0].email}</Text>
                            {ValueIsSet(data.users[0].phone) &&
                                <Text>{`${CountryCode[data.users[0].countryCode]} ${data.users[0].phone}`}</Text>
                            }
                        </Grid>
                        }
                    </Grid>
                )
            },
            cellProps: {
                style: {
                    minWidth: '200px',
                }
            }
        },
        {
            title: 'Location(s)',
            cellValueFunction: (data) => {
                return (
                    <CollapsableLocations locations={data.uniqueLocations}/>
                )
            },
            cellProps: {
                style: {
                    minWidth: '200px',
                }
            }
        },
        {
            title: ' ',
            cellValueFunction: (data) => {
                return (
                    <Grid container>
                        {hoveredRow === data.id &&
                        <IconButton
                            iconName={IconType.EDIT}
                            secondary
                            onClick={() => onEditClicked(data)}
                            size="medium"/>}
                    </Grid>
                )
            },
            cellProps: {
                style: {
                    minWidth: '75px',
                }
            }
        }
    ];


    const onEditClicked = (row) => {
        setShowAddEditVendor(true);
        setEditVendor({id: row.id, isInHouseTailor: row.vendorLocations[0].isBurberryVendor});
    };

    const onMouseEnter = (e, row) => {
        setHoveredRow(row.id)
    };

    const onMouseLeave = () => {
        setHoveredRow(null)
    };


    return (
        <Grid container xs={12}>
            <Table
                disableCursorStyling={true}
                hover
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                columns={columns}
                data={vendors}
                handleChangePage={handleChangePage}
                isPaginated={true}
                loading={loading}
                page={currentPage}
                rowsPerPage={vendorsPerPage}
                rowsPerPageOptions={[vendorsPerPage]}
                showTopTablePagination
                totalDataLength={totalDataLength}
            />
        </Grid>
    )
};

export default VendorsPageView;

VendorsPageView.propTypes = {
    currentPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    loading: PropTypes.bool,
    setEditVendor: PropTypes.func,
    setShowAddEditVendor: PropTypes.func,
    totalDataLength: PropTypes.number,
    vendors: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        nickname: PropTypes.string,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                email: PropTypes.string,
                phone: PropTypes.string,
                countryCode: PropTypes.string
            })
        ),
        preferredVendorRelationships: PropTypes.array,
        uniqueLocations: PropTypes.object
    }
    )),
    vendorsPerPage: PropTypes.number,
};