import React from 'react'
import PropTypes from 'prop-types';

import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import CustomScheduleDialogView from "worksmith/composite-components/ScheduleOptionsForm/CustomScheduleDialogView";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";

const CustomScheduleDialog = ({
                                  addingRecurringScheduleJob,
                                  cancelButtonText,
                                  customScheduleSaved,
                                  date,
                                  dayOfMonth,
                                  daysOfWeek,
                                  daysOfWeekNumber,
                                  handleAddScheduleChange,
                                  handleCustomScheduleOpen,
                                  handleCustomScheduleChecked,
                                  handleCustomScheduleSave,
                                  onScheduleChange,
                                  open,
                                  recurrenceType,
                                  repeatEvery,
                                  schedule,
                                  submitButtonText
}) => {

    const handleCancel = () => {
        handleCustomScheduleOpen(false);
        if (!customScheduleSaved) {
            handleCustomScheduleChecked(false)
        }
    };

    return (
        <>
            <AlertDialog
                acceptDisabled={false}
                acceptText={submitButtonText}
                cancelText={cancelButtonText}
                debounceAccept
                fullScreenOnMobile
                onAccept={handleCustomScheduleSave}
                onCancel={handleCancel}
                open={open}
                title={"Custom Schedule"}>
                    <CustomScheduleDialogView
                        addingRecurringScheduleJob={addingRecurringScheduleJob}
                        date={date}
                        dayOfMonth={dayOfMonth}
                        daysOfWeek={daysOfWeek}
                        daysOfWeekNumber={daysOfWeekNumber}
                        handleAddScheduleChange={handleAddScheduleChange}
                        onScheduleChange={onScheduleChange}
                        recurrenceType={recurrenceType}
                        repeatEvery={repeatEvery}
                        schedule={schedule}
                    />
            </AlertDialog>
        </>
    )
};


CustomScheduleDialog.propTypes = {
    addingRecurringScheduleJob: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    customScheduleSaved: PropTypes.bool,
    date: PropTypes.string,
    dayOfMonth: PropTypes.string,
    daysOfWeek: PropTypes.array,
    daysOfWeekNumber: PropTypes.array,
    handleAddScheduleChange: PropTypes.func,
    handleCustomScheduleOpen: PropTypes.func,
    handleCustomScheduleChecked: PropTypes.func,
    handleCustomScheduleSave: PropTypes.func,
    onScheduleChange: PropTypes.func,
    open: PropTypes.bool,
    recurrenceType: PropTypes.string,
    repeatEvery: PropTypes.number,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string.isRequired,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    submitButtonText: PropTypes.string,
};

export default CustomScheduleDialog;