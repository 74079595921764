import React from 'react';
import PropTypes from 'prop-types';
import AlertModalTemplate from "worksmith/components/Modal/Templates/AlertModalTemplate";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import TextField from "worksmith/components/Inputs/TextField/TextField";
import Button, {ButtonVariant} from "worksmith/components/Button/Button.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const FreeFormTextModalView = (props) => {
    let {closeModal, isValid, onChange, onSubmit, submitting, validationError, question, supportingText, required, inputLabel, cancelButtonText, submitButtonText, textFieldPlaceholder, textFieldRows} = props;

    const body = <div style={{minWidth: '500px', maxWidth: '700px'}}>
        <div style={{marginBottom: '1em'}}>
            <Text variant={TextVariant.H6}>{question}</Text>
        </div>
        {ValueIsSet(supportingText) &&
            <div style={{marginBottom: '2em'}}>
                <Text variant={TextVariant.SUBTITLE_1}>{supportingText}</Text>
            </div>
        }
        <TextField onChange={onChange}
                   disabled={submitting}
                   placeholder={textFieldPlaceholder}
                   rows={textFieldRows}
                   rowsMax={ValueIsSet(textFieldRows) ? undefined : 5}
                   required={required}
                   multiline
                   label={inputLabel}
                   fullWidth/>
        {!isValid && <Text color={TextColor.ERROR}> {validationError} </Text>}
    </div>;

    const submitButton = <Button variant={ButtonVariant.CONTAINED}
                                 disabled={submitting}
                                 debounce
                                 onClick={onSubmit}
                                 primary>
        {submitButtonText ?
            submitButtonText
            :
            'Submit'
        }
    </Button>;

    const cancelButton = <Button primary
                                 onClick={closeModal}
                                 variant={ButtonVariant.OUTLINED}>
        {cancelButtonText ?
            cancelButtonText
            :
            'Cancel'
        }
    </Button>;

    return (
        <AlertModalTemplate body={body} buttons={[cancelButton,submitButton]}/>
    )
};

FreeFormTextModalView.propTypes = {
    cancelButtonText: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    inputLabel: PropTypes.string,
    isValid: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    required: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    validationError: PropTypes.string,
    question: PropTypes.string,
    submitButtonText: PropTypes.string,
    supportingText: PropTypes.string,
    textFieldPlaceholder: PropTypes.string,
    textFieldRows: PropTypes.number
};

export default FreeFormTextModalView;