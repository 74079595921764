import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import ClientRoutes from "../../ClientRoutes";
import {Color, FunctionalColor} from 'worksmith/enums/Color';
import Grid from 'worksmith/components/Grid/Grid';
import Icon, {IconFontSize} from 'worksmith/components/Icon/Icon';
import {IconType} from 'worksmith/enums/MaterialEnums';
import Text, {TextColor, TextVariant} from 'worksmith/components/Text/Text';
import {ValueIsSet} from 'worksmith/helpers/GenericHelpers';

const StyledText = styled(Text)`
  && {
    display: flex;
    padding: .25rem;
    align-items: center;
    color: ${({isWalkthrough}) => isWalkthrough ? FunctionalColor.BLACK : Color.WHITE}
  }
`

const AgendaView = ({event}) => {
    const isComplete = ValueIsSet(event.completionDate);
    const isFirstInRecurringService = event.isFirstObligationInRecurringService;
    const isRescheduling = event.isRescheduling;
    const isWalkthrough = event.isWalkthrough;

    const href = ValueIsSet(event.obligationTemplateId) && !isWalkthrough ?
        ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(event.serviceLineId, event.clientLocationId, false, event.obligationTemplateId)
        :
        ClientRoutes.SINGLE_REQUESTS(event.requestId)
    let description = `${event.serviceLineName} / ${event.clientLocationTitle}`;
    if (event.requestId) {
        description += ` / ${event.requestId}`
    }

    return (

        <Grid container item xs={12}>
            {isComplete ? <Icon fontSize={IconFontSize.SMALL} name={IconType.CHECK_CIRCLE} iconColor={isWalkthrough ? FunctionalColor.BLACK : Color.WHITE} left/> : null}
            {isRescheduling ? <Icon fontSize={IconFontSize.SMALL} name={IconType.UPDATE} iconColor={isWalkthrough ? FunctionalColor.BLACK : Color.WHITE} left/> : null}
            {isFirstInRecurringService ? <Icon fontSize={IconFontSize.SMALL} name={IconType.LOOKS_ONE} iconColor={isWalkthrough ? FunctionalColor.BLACK : Color.WHITE} left/> : null}
            <StyledText
                isWalkthrough={isWalkthrough}
                link
                href={`#/${href}`}
                target={'_blank'}
                textDecoration={'underline'}
                variant={TextVariant.BODY_2}
            >
                {description}
            </StyledText>
        </Grid>
    )


}

export default AgendaView;

AgendaView.propTypes = {
    event: PropTypes.shape({
        allDay: PropTypes.bool,
        clientLocation: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string
        }),
        color: PropTypes.string,
        end: PropTypes.string,
        isFirstObligationInRecurringService: PropTypes.bool,
        isRescheduling: PropTypes.bool,
        isWalkthrough: PropTypes.bool,
        obligationDate: PropTypes.string,
        obligationTemplate: PropTypes.shape({
            id: PropTypes.number
        }),
        request: PropTypes.shape({
            completionDate: PropTypes.string,
            id: PropTypes.number
        }),
        serviceLine: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        start: PropTypes.string
    }),
}

