import React, {Component, Fragment} from 'react';
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import Button, {ButtonVariant} from "../Button/Button";
import Modal from "../Modal/Modal";
import AlertModalTemplate from "../Modal/Templates/AlertModalTemplate";
import PropTypes from "prop-types";
import ContractScopeOfWorkView from "worksmith/components/Contract/ContractScopeOfWorkView";
import Loader from "../Loader/Loader";

const graphQLService = new GraphQLServiceClass();


class ContractScopeOfWork extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            rateCardEntries: [],
            locationTitle: null,
            serviceLineName: null,
            loading: true
        }
    };
    componentDidMount() {
        this.loadScopesOfWork();
    }

    loadScopesOfWork = () => {
        let { locationId, serviceLineId } = this.props;

        graphQLService.findAll(
            {clientLocationIds:[locationId], serviceLineIds:[serviceLineId]},
            GraphQLObjectType.RATE_CARD,
            rateCardObject
        ).then((data) => {
            //should only be a single rate card for now
            let tempLocationTitle = data[0].clientLocation.title;
            let tempServiceLineName = data[0].serviceLine.name;
            let tempRateCardEntries = data[0].rateCardEntries;
            this.setState({rateCardEntries: tempRateCardEntries, locationTitle:tempLocationTitle, serviceLineName:tempServiceLineName, loading:false})
        }).catch((error) => {
            console.log(error);
            this.setState({loading:false});
        });
    };

    onClose = () => {
        this.setState({isOpen: false});
    };

    onOpen = () => {
        this.setState({isOpen: true});
    };

    render() {
        let { rateCardEntries, locationTitle, serviceLineName, loading } = this.state;
        let { buttonText, canEdit, fullWidth } = this.props;
        let { onOpen } = this;
        return (
            <Fragment>
                <Button variant={ButtonVariant.OUTLINED}
                        fullWidth={fullWidth}
                        primary
                        onClick={onOpen}>
                    {!buttonText ? 'Scope of Work' : buttonText}
                </Button>
                <Modal open={this.state.isOpen}
                       onClose={this.onClose}>
                    <AlertModalTemplate
                        onOk={() => {}}
                        body={
                            loading ? <Loader/> :
                            <ContractScopeOfWorkView
                            loading={loading}
                            locationTitle={locationTitle}
                            serviceLineName={serviceLineName}
                            rateCardEntries={rateCardEntries}
                            canEdit={canEdit}
                        />}
                        buttons={[
                            <Button key={1} primary variant={ButtonVariant.OUTLINED} onClick={this.onClose}>
                                CLOSE
                            </Button>
                        ]}
                    />
                </Modal>
            </Fragment>
        )
    }
}

const rateCardObject = `
    id
    clientLocation {
        id
        title
    }
    serviceLine {
        id
        name
    }
    rateCardEntries {
        id
        itemName
        scopeOfWork
        displayValue
    }
`;

ContractScopeOfWork.propTypes = {
    buttonText:              PropTypes.string,
    canEdit:                 PropTypes.bool,
    fullWidth:               PropTypes.bool,
    locationId:              PropTypes.number.isRequired,
    serviceLineId:           PropTypes.number.isRequired
};

ContractScopeOfWork.defaultProps = {
    buttonText: 'Scope Of Work',
    fullWidth: false
};

export default ContractScopeOfWork;