import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';
import Box from "@mui/material/Box";
import {fill} from "lodash";

import Grid from "worksmith/components/Grid/Grid.web";
import {AlignItems, Height, JustifyContent} from "worksmith/enums/CSSEnums";
import {ThemeConstants} from "worksmith/components/Theme/ThemeProvider";
import RequestTypeBar from "worksmith/composite-components/RequestTypeBar/RequestTypeBar";
import Divider from "worksmith/components/Divider/Divider.web";
import Skeleton, {SkeletonVariant} from "worksmith/components/Skeleton/Skeleton";
import {withTheme} from "../../components/Theme/ThemeProvider";

const RequestSummaryLoadingView = (props) => {
    let {leftMargin} = props;
    const theme = useTheme();
    const mobile =  useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Fragment>
            <Grid container spacing={0} height={90} alignItems={AlignItems.CENTER}>
                <Grid item height={Height.INHERIT}>
                    <Box height={Height.INHERIT} width={ThemeConstants.spacingUnit * leftMargin}>
                        <RequestTypeBar loading={true}/>
                    </Box>
                </Grid>
                <Grid item container spacing={2} xs={11} justify={JustifyContent.SPACE_BETWEEN} alignItems={AlignItems.CENTER}>
                    <Grid item>
                        <Skeleton variant={SkeletonVariant.TEXT} width={'14rem'}/>
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>
            <Box mt={5} mr={5} ml={leftMargin}>
                <Grid container
                      spacing={5}
                      ItemProps={{
                          spacing: 2,
                          xs: 12
                      }}>
                    {
                        fill(Array(10), 0)
                            .map((item, index) => (
                                <Grid container
                                      key={index}
                                      item
                                      justify={JustifyContent.FLEX_START}>
                                    <Grid item xs={12}>
                                        <Skeleton variant={SkeletonVariant.TEXT} width={mobile ? '10rem' : '15rem'}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton variant={SkeletonVariant.TEXT} width={mobile ? '20rem' : '30rem'}/>
                                    </Grid>
                                </Grid>
                            ))
                    }
                </Grid>
                <Box mt={6}>
                    <Skeleton variant={SkeletonVariant.TEXT} width={mobile ? '13rem' : '20rem'}/>
                </Box>
            </Box>
        </Fragment>
    );
};

RequestSummaryLoadingView.propTypes = {
    leftMargin: PropTypes.number
}

export default withTheme(RequestSummaryLoadingView);