import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import Divider, {DividerVariant} from "worksmith/components/Divider/Divider.web";
import InvoiceSectionByDate from "./InvoiceSectionByDate";
import styled from "styled-components";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const Section = styled.div`
    margin: .5em 2em;
`;

const DividerSection = styled.div`
    margin: .5em 0;
`;

const InvoiceSections = (props) => {
    const {linkToInvoice, sectionGroupings, sectionTitle} = props;

    return (
        <Fragment>
            {ValueIsSet(sectionTitle) ?
                <Fragment>
                    <Section>
                        <Text variant={TextVariant.SUBTITLE_2} color={TextColor.TEXT_SECONDARY}>{sectionTitle}</Text>
                    </Section>
                    <DividerSection>
                        <Divider variant={DividerVariant.FULL_WIDTH}/>
                    </DividerSection>
                </Fragment> : null}
            {Object.entries(sectionGroupings).map(([title, invoices]) =>
                <InvoiceSectionByDate
                    title={title}
                    invoices={invoices}
                    linkToInvoice={linkToInvoice}
                    key={title}/>)}
        </Fragment>
    )
};

InvoiceSections.propTypes = {
    linkToInvoice:    PropTypes.func.isRequired,
    sectionTitle:     PropTypes.string,
    sectionGroupings: PropTypes.object.isRequired,
};

export default InvoiceSections;