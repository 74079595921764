import React, {Fragment} from 'react';
import Box from "@mui/material/Box";
import {Display, JustifyContent} from "worksmith/enums/CSSEnums";

const FullWidthPageContainer = (props) => {

    return (
        <Box
            id={props.id}
            display={Display.FLEX}
            pl={{xs: 2, sm: '5%'}}
            pr={{xs: 2, sm: 0}}
            width={'100%'}
            justifyContent={JustifyContent.CENTER}>
                {props.children}
        </Box>
    )
};

export default FullWidthPageContainer;