import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Tooltip from 'react-tooltip';
import CustomPropTypes from "../utilities/propTypes/CustomPropTypes";
import {Color} from "../Enums";
import {Debounce} from "../Helpers";

class Button extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: props.disabled
        };

        this.onClick = this.onClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({disabled: nextProps.disabled});
    }

    onClick() {
        let {onPress} = this.props;

        if (onPress !== null && onPress !== undefined) {
            onPress();
        }

        if (this.props.disableWhenClicked) {
            this.setState({disabled: true});
        }
    };

    render(){
        let {debounce, onPressIgnoreDebounce, message, type, icon, linkTo, className, size, style, htmlType, outline, tooltip, tooltipType, messageColor, id} = this.props;
        let {disabled} = this.state;
        let {onClick} = this;
        let iconType = type.includes('-') ? type.split('-')[0] : type;
        let hiddenOnPrint = false;
        if(icon === 'printer') {
            hiddenOnPrint = true;
        }

        let onButtonPress = onClick;
        if (debounce) {
            onButtonPress = Debounce(onClick, 400, onPressIgnoreDebounce);
        }

        if(!linkTo){
            return(
                <button id={id} className={`${outline ? 'ws-btn ws-btn-' + `${type}` + '-outline' : 'ws-btn ws-btn-' + `${type}`} ${size ? 'ws-btn-' + size : ''} text-center ${disabled ? 'ws-btn-disabled' : ''} ${hiddenOnPrint ? ' hidden-print' : ''} ${className ? className : ''}` }
                        style={style}
                        disabled={disabled}
                        type={htmlType || 'button'}
                        onClick={onButtonPress}>
                    <Tooltip type={tooltipType ? tooltipType : type} effect="float"/>
                    <div className={'text-center'} data-tip={tooltip}>
                        {this.props.icon ? <i className={`icon-${icon} icon-md v-middle text-${iconType}`}/> : ''}
                        <span style={{color: messageColor}} className="pad-top-xs pad-left-xs">{message}</span>
                    </div>
                </button>
            )
        }else {
            return(
                <Link id={id} to={linkTo}>
                    <button type={htmlType || 'button'} className={`ws-btn ws-btn-${type} ${size ? 'ws-btn-' + size : ''} text-center ${disabled ? 'ws-btn-disabled' : ''} ${hiddenOnPrint ? ' hidden-print' : ''} ${className ? className : ''}`}
                            disabled={disabled}
                            onClick={onButtonPress}>
                        <div className="text-center">
                            { this.props.icon ? <i className={`icon-${icon} icon-lg v-middle text-${iconType}`}/> : ''}
                            <span style={{color: messageColor}} className="pad-top-xs pad-left-xs">{message}</span>
                        </div>
                    </button>
                </Link>
            )
        }
    }
}

Button.propTypes = {
    className:             PropTypes.string,                  // Changes the styling to that of the class
    debounce:              PropTypes.bool,                    // Calls the onPress function after 200 milliseconds from the last click
    disabled:              PropTypes.bool,                    // self explanatory
    htmlType:              PropTypes.string,                  // Type of html button, e.g. submit, button
    icon:                  PropTypes.string,                  // simple-line-icons string without the 'icon'. i.e. 'icon-settings' is just 'settings'
    id:                    PropTypes.string,
    linkTo:                PropTypes.string,                  // <Link to={linkTo} /> i.e. pass in '/account' to route to AccountPage
    message:               PropTypes.string,                  // String, title of the button
    messageColor:          CustomPropTypes.enum(Color),       // Sets the color of the message
    onPress:               PropTypes.func,                    // callback that the button should execute, set on the parent
    onPressIgnoreDebounce: PropTypes.func,                    // callback that ignores the debounce method
    outline:               PropTypes.bool,                    // converts the styling to be the outline style
    size:                  PropTypes.number,                  // sets the size of the button. (sm, md, lg, xl)
    tooltip:               PropTypes.bool,                    // adds a tooltip if true
    tooltipType:           PropTypes.string,                  // react tooltip type that determines how the tool tip is
    type:                  PropTypes.string.isRequired        // primary, warning, danger, link, plain
};

export default Button;