import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import {Link} from "react-router-dom";
import ClientRoutes from "../../ClientRoutes";
import styled from 'styled-components';
import Grid from "worksmith/components/Grid/Grid.web";
import {SecondaryColor} from "worksmith/enums/Color";

const Title = styled(Text)`
    padding-bottom: 0.75em;
`;

const BlockedText = styled(Text)`
    padding-bottom: 0.75em;
    padding-top: 0.75em;
`;

const IndentedLink = styled(Link)`
    padding-left: 0.5em;
    text-decoration: underline;
    color: ${SecondaryColor.main}
`
;

const RecurringServiceRequest = (props) => {
    let {request} = props;
    return <div>
        <Title bold={false} variant={TextVariant.SUBTITLE_1}>Current Service</Title>
        <BlockedText>This recurring service is not yet scheduled. Once you and the vendor agree on pricing and frequency, the schedule will be populated on this page.</BlockedText>
        <BlockedText>To schedule the service or view more details, please go to the request below.</BlockedText>
        <Grid container>
            <Grid item xs={6} sm={3}>
                <BlockedText semiBold> Service Status</BlockedText>
            </Grid>
            <Grid item xs={6} sm={3}>
                <BlockedText>Pending Setup</BlockedText>
            </Grid>
        </Grid>
        <Grid container>
            <Grid  xs={6} sm={3}>
                <IndentedLink to={'/' + ClientRoutes.SINGLE_REQUESTS(request.id)}>Request {request.id}</IndentedLink>
            </Grid>
            <Grid  xs={6} sm={3}>
                <Text>Contacting Vendors</Text>
            </Grid>
        </Grid>
    </div>;
};


RecurringServiceRequest.propTypes = {
    request: PropTypes.object
};



export default RecurringServiceRequest;