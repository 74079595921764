import React, {Component} from 'react';
import moment from "moment";
import PropTypes from 'prop-types';

import Button, {ButtonVariant} from "worksmith/components/Button/Button.web";
import CSVHelper from "worksmith/helpers/CSVHelper";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import DateRangePicker from "worksmith/components/DateRangePicker/DateRangePicker";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import Grid from "worksmith/components/Grid/Grid.web";
import Icon from "worksmith/components/Icon/Icon.web";
import IconButton from "worksmith/components/Button/IconButton.web";
import {InvoiceSearchType} from "./InvoiceSearch";
import Select, {SelectVariant} from "worksmith/components/Inputs/Select/Select.web";
import TextField, {TextFieldType, TextFieldVariant} from "worksmith/components/Inputs/TextField/TextField.web";
import {DisplayErrorNotification} from "../../../../helpers/SweetAlertHelpers";

const graphQLService = new GraphQLServiceClass();

class InvoiceSearchAndExportFields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.initialType
        };

        this.searchRef = React.createRef();
    }

    onTypeChange = (type) =>{
        const {onTypeChange} = this.props;
        this.searchRef.current.clear();
        this.setState({type});
        onTypeChange(type);
    };

    onExport = () => {
        const {clientId} = this.props;

        let searchParams = {
            clientId,
        };

        graphQLService.findAll(
            searchParams,
            GraphQLObjectType.STATEMENT_ITEM,
            InvoiceSearchAndExportFields.statementItemFields
        ).then(data => {
            let csvJSON = [];

            //Title
            csvJSON.push({
                transactionDate: 'Transaction Date',
                transactionType: 'Transaction Type',
                referenceNumber: 'Invoice Number',
                receivable: 'Location',
                dueDate: 'Due Date',
                amount: 'Amount',
                openBalance: 'Open Balance'
            });

            csvJSON = csvJSON.concat(data.map(item => {
                item.receivable = ValueIsSet(item.receivable) ? item.receivable.clientLocation.title : 'N/A';
                return item;
            }));

            const csvHelper = new CSVHelper(csvJSON,'invoices');
            csvHelper.download();
        }).catch(error => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification(error.toString());
        });
    };

    render() {
        const {onExport, onTypeChange, searchRef} = this;
        const {type} = this.state;
        const {initialFilterString, onDatesChange, onFilterChange, onSearch, onSearchClick} = this.props;

        // noinspection SSBasedInspection
        const options =  Object.values(InvoiceSearchType).map(value => {
            return {
                label: value,
                value: value
            }
        });

        return (
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Select variant={SelectVariant.OUTLINED}
                            initialValue={type}
                            options={options}
                            onChange={onTypeChange}/>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <TextField ref={searchRef}
                               type={TextFieldType.SEARCH}
                               endAdornment={<IconButton iconName={'Search'} onClick={onSearchClick} primary size="medium" />}
                               onKeyUp={onSearch}
                               onChange={onFilterChange}
                               placeholder={'Search'}
                               variant={TextFieldVariant.SIMPLE_LINE}
                               initialValue={initialFilterString}/>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Button variant={ButtonVariant.OUTLINED} onClick={onExport} primary>
                        <Icon name={'GetAppSharp'}/> EXPORT ALL INVOICES
                    </Button>
                </Grid>

                {type === InvoiceSearchType.PAYMENTS ?
                    <Grid item xs={12} sm={6}>
                        <DateRangePicker onDatesChange={onDatesChange}
                                         isDayHighlighted={ day => day.isSame(moment(), 'd')}
                        />
                    </Grid>
                    :
                    null}
            </Grid>
        );
    }
}

InvoiceSearchAndExportFields.statementItemFields = `
    amount
    dueDate
    id
    openBalance
    receivable {
        clientLocation {
            title
        }
        id
    }
    referenceNumber
    transactionDate
    transactionType
`;

InvoiceSearchAndExportFields.propTypes = {
    clientId:            CustomPropTypes.stringOrNumber.isRequired,
    initialFilterString: PropTypes.string,
    initialType:         PropTypes.string,
    onDatesChange:       PropTypes.func.isRequired,
    onFilterChange:      PropTypes.func.isRequired,
    onSearch:            PropTypes.func.isRequired,
    onSearchClick:       PropTypes.func.isRequired,
    onTypeChange:        PropTypes.func.isRequired
};

export default InvoiceSearchAndExportFields;