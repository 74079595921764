import React, {Component} from 'react';
import MaterialInputBase from '@mui/material/InputBase';
import PropTypes from 'prop-types';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {withTheme} from '../../Theme/ThemeProvider';

export const InputBaseVariant = Object.freeze({
    SIMPLE_LINE: 'standard',
    OUTLINED: 'outlined',
    ERROR: 'errored'
});

export const InputBaseType = Object.freeze({
    EMAIL: 'email',
    NUMBER: 'number',
    PASSWORD: 'password',
    SEARCH: 'search',
    TEXT: 'text'
});

class InputBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue || ''     //always initialize value in state because if it isn't set the text field will be treated as uncontrolled and throw an error in the console
        };
    }

    onChange = (event) => {
        this.setState(
            {value: event.target.value}, () => {
                if (ValueIsSet(this.props.onChange))
                    this.props.onChange(this.state.value);
            }
        );
    };

    render() {
        const {
            disabled,
            endAdornment,
            error,
            fullWidth,
            multiline,
            name,
            placeholder,
            rows,
            rowsMax,
            startAdornment,
            type,
            variant
        } = this.props;

        const {onChange} = this;
        const {value} = this.state;

        return (
            <MaterialInputBase disabled={disabled}
                               endAdornment={endAdornment}
                               error={error}
                               fullWidth={fullWidth}
                               className={this.props.className}
                               multiline={multiline}
                               name={name}
                               onChange={onChange}
                               placeholder={placeholder}
                               rows={rows}
                               rowsMax={rowsMax}
                               startAdornment={startAdornment}
                               type={type}
                               value={value}
                               variant={variant}/>
        )
    }
}

InputBase.propTypes = {
    disabled: PropTypes.bool,
    endAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    initialValue: CustomPropTypes.stringOrNumber,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    rows: CustomPropTypes.stringOrNumber,                   //Number of rows to display when multiline option is set to true
    rowsMax: CustomPropTypes.stringOrNumber,                //Maximum number of rows to display when multiline option is set to true
    startAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    type: CustomPropTypes.enum(InputBaseType),
    variant: CustomPropTypes.enum(InputBaseVariant)
};

InputBase.defaultProps = {
    multiline: false,
    type: InputBaseType.TEXT,
    variant: InputBaseVariant.OUTLINED
};

export default withTheme(InputBase);