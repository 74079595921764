import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import ServiceLineLocationSummary from "./ServiceLineLocationSummary";
import Select, {SelectVariant} from "worksmith/components/Inputs/Select/Select.web";
import styled from 'styled-components';

const Title = styled(Text)`
    padding-top: 0.2em;
    padding-bottom: 1em;
`;

const Padder = styled.div`
    margin-bottom: 2.5em;
`;

const SpacingBlock = styled.div`
    margin-bottom: 1.5em;
`;

const ObligationFilterOptions = Object.freeze({
    ALL: 'All',
    ONGOING: 'Ongoing',
    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
    NO_SERVICE: 'No Service'
});


const ServiceLineSummaryPageView = (props) => {
    let {requests, obligationTemplates, locationsByState, serviceLine, onFilterChange, unfilteredLocations} = props;
    let totalLocations = 0;
    Object.keys(unfilteredLocations).forEach(key =>
        unfilteredLocations[key].forEach(location => { totalLocations += 1}));

    let options = [];
    if(obligationTemplates.length > 0)
        options.push({
            label: 'Ongoing',
            value: ObligationFilterOptions.ONGOING
        });
    if(requests.length > 0){
        options.push({
            label: 'Pending Setup',
            value: ObligationFilterOptions.PENDING
        });
    }
    options.push({
        label: 'All',
        value: ObligationFilterOptions.ALL
    });
    options.push({
        label: 'No Service',
        value: ObligationFilterOptions.NO_SERVICE
    });
    if(!obligationTemplates.length > 0)
        options.push({
            label: 'Ongoing',
            value: ObligationFilterOptions.ONGOING
        });
    if(!requests.length > 0){
        options.push({
            label: 'Pending Setup',
            value: ObligationFilterOptions.PENDING
        });
    }

    let sortedKeys = Object.keys(locationsByState).sort().slice();

    return <div>
        <Title semiBold variant={TextVariant.H5}>{serviceLine.name} - Recurring Service</Title>
        <Padder>
            <Text variant={TextVariant.H6} color={TextColor.PRIMARY}>Locations</Text>
        </Padder>
        <SpacingBlock>
            <Select variant={SelectVariant.OUTLINED} label={'Service Status'} options={options} onChange={(option) => onFilterChange(option)}/>
        </SpacingBlock>
        {sortedKeys.map(state =>{
                return <SpacingBlock>
                    <Text semiBold>{state}</Text>
                    {
                        locationsByState[state].map(location => {
                            return <ServiceLineLocationSummary serviceLine={serviceLine} requests={requests.filter(r => r.clientLocationId === location.id)}
                                                               obligationTemplates={obligationTemplates.filter(ot => ot.clientLocationId === location.id)} location={location}/>
                        })
                    }
                </SpacingBlock>
            }
        )}
    </div>;
};

ServiceLineSummaryPageView.propTypes = {
    requests: PropTypes.arrayOf(PropTypes.object),
    obligationTemplates: PropTypes.arrayOf(PropTypes.object),
    locationsByState: PropTypes.object,
    unfilteredLocations: PropTypes.arrayOf(PropTypes.object),
    serviceLines: PropTypes.arrayOf(PropTypes.object),
    onFilterChange: PropTypes.func
};

export default ServiceLineSummaryPageView;