import React, {Component, Fragment} from 'react';
import t from 'tcomb-form';
import notification from 'antd/lib/notification';
import Button from "../../components/Button";
import SupportTicketService from 'worksmith/services/api/SupportTicketService';
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import {Link} from 'react-router-dom';

const Form = t.form.Form;
const userService = new UserService();
const supportTicketService = new SupportTicketService();
const authTokenManager = new AuthTokenManager();

const CustomerSupportForm = t.struct({
    subject: t.String,
    message: t.String,
});

const CustomerSupportOptions = {
    fields: {
        message: {
            type: 'textarea'
        }
    }
};

class ClientSupportPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: {},
            corporateValue: {},
            user: null,
            ccs: [],
            cc: {},
        };

        this.submit = this.submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.submitCorporate = this.submitCorporate.bind(this);
        this.onCorporateChange = this.onCorporateChange.bind(this);
        this.addCC = this.addCC.bind(this);
    }

    componentDidMount() {
        let _this = this;

        userService.findOne(authTokenManager.getUserId()).then(function (user) {
            _this.setState({user: user});
        })
    }

    addCC(){
        let {ccs, cc} = this.state;
        let baseCCs = ccs;
        baseCCs.push(cc.cc);
        this.setState({ccs, cc: {}});
    }

    onChange(value) {
        this.setState({value});
    }

    onCorporateChange(corporateValue) {
        this.setState({corporateValue});
    }

    submit() {
        let {value, user} = this.state;
        let _this = this;

        let submitData = {
            email: user.email,
            subject: value.subject,
            comments: [{comment: value.message}],
            // copies: [{email:""}]
        };

        supportTicketService.addTicketToZendesk(submitData).then(function (data) {
            notification['success']({
                message: 'Ticket submitted!'
            });
            _this.setState({value: {}});
        });
    }

    submitCorporate() {
        let {corporateValue, user} = this.state;
        let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;
        let _this = this;

        let submitData = {
            email: user.email,
            subject: corporateValue.subject,
            comments: [{comment: corporateValue.message}],
            // copies: [{email:""}]
        };

        supportTicketService.sendToCorporate(clientId, submitData).then(() =>{
            notification['success']({
                message: 'Ticket submitted to Corporate!'
            });
            _this.setState({corporateValue: {}});
        });
    }

    render() {
        //let {ccs} = this.state;
        // const CCsForm = t.struct(
        //     {
        //         cc: t.String
        //     }
        // );
        //
        // const CCOptions = {
        //     fields: {
        //         cc: {
        //             label: 'Add CCs'
        //         }
        //     }
        // };
        let {user} = this.state;

        return (
            <div>
                <section className='ws-section'>
                    <h1 className='page-header'>Client Support</h1>
                </section>

                <section className="ws-section">
                    <h4>Send Email to Worksmith Support </h4>
                    <p className="text-center">Please note: This form is for all general inquiries, suggestions, and issues. {user && user.companyType !== 'Dry Cleaning Only' ? <Fragment>
                        If you would like to submit a service request, please click <Link to="/new-request">here</Link>.
                        </Fragment> : null}</p>

                    <div className="ws-section col-md-6 offset-md-3 margin-top-xl margin-bottom-xl">
                        <Form type={CustomerSupportForm} onChange={this.onChange} options={CustomerSupportOptions}
                              value={this.state.value}/>
                        <div className="text-right">
                            <Button type={'success'} onPress={this.submit} message={'Submit'}/>
                        </div>
                    </div>
                </section>

                {this.props.client.configurationSettings.contactCorporateSupport ?
                <section className="ws-section">
                    <h4>Send Email to Corporate</h4>

                    <div className="ws-section col-md-6 offset-md-3 margin-top-xl margin-bottom-xl">
                        <Form type={CustomerSupportForm} onChange={this.onCorporateChange} options={CustomerSupportOptions}
                              value={this.state.corporateValue}/>
                        <div className="text-right">
                            <Button type={'success'} onPress={this.submitCorporate} message={'Submit'}/>
                        </div>
                    </div>
                </section>
                    : null }
            </div>
        )
    }
}

export default ClientSupportPage;