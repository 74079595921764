import * as PropTypes from "prop-types";
import styled from "styled-components";
import React from "react";
import {Color, FunctionalColor} from "worksmith/enums/Color";
import RecurringServiceServiceLineSummaryString from "./RecurringServiceServiceLineSummaryString";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import {Cursor, TextAlign} from "worksmith/enums/CSSEnums";
import Icon from "worksmith/components/Icon/Icon.web";
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import RecurringServiceClientSummaryString
    from "worksmith/composite-components/RecurringServiceCard/RecurringServiceClientSummaryString";
import RecurringServiceClientLocationSummaryString
    from "worksmith/composite-components/RecurringServiceCard/RecurringServiceClientLocationSummaryString";
import OpportunityLogo from "worksmith/composite-components/NonWorksmithOpportunity/OpportunityLogo";

const RecurringServiceCard = (props) =>{

    let {onClick, client, clientLocation, serviceLine, obligationTemplates, pendingRequests, pendingOpportunities, ongoingServiceCount, pendingSetUpServiceCount} = props;

    let cardTitle = null;
    if (ValueIsSet(serviceLine))
        cardTitle = serviceLine.name;
    else if (ValueIsSet(client)) {
        let isForNonWorksmithClient = ValueIsSet(client.vendor);
        cardTitle = <>{client.officialName + '\n'}
            <Text variant={TextVariant.BODY_2}>
            <OpportunityLogo isForNonWorksmithClient={isForNonWorksmithClient}
                             vendorName={isForNonWorksmithClient ? client.vendor.nickname : null}
                             companyLogo={isForNonWorksmithClient ? client.vendor.logoPath : null}
                             widthInPixels={28}/>
            </Text>
         </>;
    }
    else if (ValueIsSet(clientLocation))
        cardTitle = clientLocation.city + ' - ' + clientLocation.title;

    return (
        <OptionGrid onClick={onClick}>
            <BoldOptionText bold={false} color={TextColor.PRIMARY}
                            variant={TextVariant.H6}>
                {cardTitle}
            </BoldOptionText>
            {ValueIsSet(serviceLine) ?
                <RecurringServiceServiceLineSummaryString align={TextAlign.RIGHT}
                                                          serviceLineId={serviceLine.id}
                                                          ongoingServiceCount={ongoingServiceCount}
                                                          pendingSetUpServiceCount={pendingSetUpServiceCount}/>
                :
                ValueIsSet(client) ?
                    <RecurringServiceClientSummaryString clientId={client.id}
                                                         pendingOpportunities={pendingOpportunities}
                                                         obligationTemplates={obligationTemplates}/>
                                                         :
                    <RecurringServiceClientLocationSummaryString clientLocationId={clientLocation.id}
                                                         pendingOpportunities={pendingOpportunities}
                                                         obligationTemplates={obligationTemplates}/>
            }
            <ArrowIcon iconColor={FunctionalColor.BLACK} name={'KeyboardArrowRight'}/>
        </OptionGrid>
    )

};

RecurringServiceCard.propTypes = {
    client: PropTypes.shape({               //client is only required if you want to group the data by client
        id: PropTypes.number.isRequired,
        officialName: PropTypes.string.isRequired
    }),
    clientLocation: PropTypes.shape({       //clientLocation is only required if you want to group the data by clientLocation
        city: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
    }),
    obligationTemplates: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.shape({
            id: PropTypes.number.isRequired,
            officialName: PropTypes.string.isRequired
        }),
        serviceLine: PropTypes.shape({
            id: PropTypes.number.isRequired
        }).isRequired,
        status: CustomPropTypes.enum(ObligationTemplateStatus)
    })),
    onClick: PropTypes.func,
    pendingOpportunities: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.shape({
            id: PropTypes.number.isRequired
        }),
        serviceLine: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number
        }).required
    })),
    pendingRequests: PropTypes.arrayOf(PropTypes.shape({
        serviceLine: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number
        }).required
    })),
    serviceLine: PropTypes.shape({          //serviceLine is only required if you want to group the data by serviceLine
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })
};

const OptionText = styled(Text)`
    font-size: 1em;
    margin: 1em;
    grid-column: 2;
`;

const BoldOptionText = styled(OptionText)`
    margin-right: 0em;
    grid-column: 1;
`;

const OptionGrid = styled.div`
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 0.2em;
    display: grid;
    grid-template-columns: 30% 65% 5%;
    width: 100%;
    cursor: ${Cursor.POINTER};
    :hover {
        background-color: ${Color.OPTION_HOVER_BLUE};       
    }
    @media only screen and (max-width: 900px) {
        width: 80%;
        grid-template-columns: 15% 75% 10%;
    }        
`;

const ArrowIcon = styled(Icon)`
    grid-column: 3;
    margin-top: 0.25em;
`;

export default RecurringServiceCard;