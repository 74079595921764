// This file was generated from com.worksmith.proposal.controller.BidController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class BidService extends Http {
    constructor() {
        super('/bids')
    }
    
    accept(params) {
        return this.callApi( `accept`, PATCH, params, null, null, true);
    }
    
    acceptAndSchedule(params) {
        return this.callApi( `acceptAndSchedule`, PATCH, params, null, null, true);
    }
    
    acceptSchedule(bidId, scheduleId) {
        return this.callApi( `${bidId}/schedules/${scheduleId}/acceptSchedule`, PATCH, null, null, null, true);
    }
    
    canAcceptBid(bidId) {
        return this.callApi( `${bidId}/canBeAcceptedByRequester`, GET, null, null, null, true);
    }
    
    canRequestRevision(id) {
        return this.callApi( `${id}/canRequestRevision`, GET, null, null, null, true);
    }
    
    cancel(bidId) {
        return this.callApi( `${bidId}/cancel`, PATCH, null, null, null, true);
    }
    
    createAndAcceptScheduleWithoutApproval(id, schedule) {
        return this.callApi( `${id}/createAndAcceptScheduleWithoutApproval`, POST, schedule, null, null, true);
    }
    
    createCoachBid(coachBid) {
        return this.callApi( `coach`, POST, coachBid, null, null, true);
    }
    
    createDeclineReason(params) {
        return this.callApi( `createDeclineReason`, POST, params, null, null, true);
    }
    
    deactivateDeclineReason(declineReasonId) {
        let params = {
            declineReasonId
        };
    
        return this.callApi( `deactivateDeclineReason`, PATCH, null, params, null, true);
    }
    
    decline(bidId, declineReasonId, followupResponse) {
        let params = {
            declineReasonId, 
            followupResponse
        };
    
        return this.callApi( `${bidId}/decline`, PATCH, null, params, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findClientBidCards(params) {
        return this.callApi( `findClientBidCards`, POST, params, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getAllCoachBids() {
        return this.callApi( `coach`, GET, null, null, null, true);
    }
    
    getBidSummary(bidId) {
        return this.callApi( `${bidId}/getBidSummary`, GET, null, null, null, true);
    }
    
    getClientBidDetails(bidId) {
        return this.callApi( `${bidId}/getClientBidDetails`, GET, null, null, null, true);
    }
    
    getClientProposalSchedulingObject(bidId) {
        return this.callApi( `${bidId}/getClientProposalSchedulingObject`, GET, null, null, null, true);
    }
    
    getClientVendorSummary(bidId) {
        return this.callApi( `${bidId}/getClientVendorSummary`, GET, null, null, null, true);
    }
    
    getDeclineReasons(id) {
        return this.callApi( `${id}/getDeclineReasons`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    requestApproval(bidId) {
        return this.callApi( `${bidId}/requestApproval`, PATCH, null, null, null, true);
    }
    
    requestRevision(id, requestedRevision) {
        return this.callApi( `${id}/requestRevision`, POST, requestedRevision, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    updateCoachBid(id, coachBid) {
        return this.callApi( `coach/${id}`, PUT, coachBid, null, null, true);
    }

    updateWorkOrderNumber(bidId, workOrder) {
        let params = {workOrderNumber: workOrder};
        return this.callApi( `${bidId}/updateWorkOrderNumber`, PATCH, null, params, null, true);
    }
    
    
}

export default BidService;
