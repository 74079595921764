import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AlertDialog from "../../components/Dialog/AlertDialog";
import Text, {TextVariant} from "../../components/Text/Text";
import {DialogSize} from "../../enums/DialogSize";
import TextField from "../../components/Inputs/TextField/TextField";

const TitlesContainer = styled.div`
    padding-bottom: 1em;
`;

class QuestionForWorksmith extends Component {
    constructor(props) {
        super(props);
         this.textFieldRef = React.createRef();
         this.state = {
             submitting: false,
             submitted: false
         }
    };

    shouldComponentUpdate(nextProps, nextState) {
        if(!this.props.open && nextProps.open && this.state.submitted)
            this.setState({submitting: false, submitted: false});
        return true;
    }

    onAccept = (commentText) => {
        this.setState({submitting: true});
        this.props.sendComment(commentText).then(() =>
            this.setState({submitting: false, submitted: true}));
    };

    render() {
        const {open, onCloseModal, isClient } = this.props;
        const {submitting, submitted} = this.state;
        const {onAccept} = this;

        return (
            !submitted ?
            <AlertDialog open={open}
                         size={DialogSize.SMALL}
                         cancelText={'CANCEL'}
                         acceptText={'SEND'}
                         debounceAccept
                         onAccept={() => onAccept(this.textFieldRef.current.textContent)}
                         onCancel={onCloseModal}
                         acceptDisabled={submitting}>
                <TitlesContainer>
                    <Text variant={TextVariant.H6} semiBold>Message Worksmith</Text>
                    <Text variant={TextVariant.BODY_1}>Have you contacted the { isClient ? "vendor" : "client" }? They can likely provide the most
                        accurate information and a quicker response.</Text>
                </TitlesContainer>
                <TextField placeholder="Start Typing"
                           multiline
                           rows={4}
                           fullWidth
                           inputRef={this.textFieldRef}
                           disabled={submitting}/>

            </AlertDialog>
            :
            <AlertDialog open={open}
                         size={DialogSize.SMALL}
                         acceptText={'OK'}
                         onAccept={onCloseModal}
                         acceptDisabled={submitting}>
                <div>
                    <Text variant={TextVariant.H6} bold>Message Sent</Text>
                    <Text variant={TextVariant.BODY_1}>Our team typically responds within 48 hours (12 hours for emergency requests). For a quicker response, please contact the { isClient ? "vendor" : "client" } directly.</Text>
                </div>

            </AlertDialog>
        )
    }
};

QuestionForWorksmith.propTypes = {
    open: PropTypes.bool.isRequired,
    isClient: PropTypes.bool.isRequired,
    sendComment: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired

};

export default QuestionForWorksmith;
