import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "../Text/Text.web";
import IconButton from "../Button/IconButton.web";

const Pagination = (props) => {
    let {
        startingItem,
        endingItem,
        totalElements,
        onPageChange,
        currentPage,
        totalPages
    } = props;

    return (
        <Fragment>
            {
                totalElements ?
                    <Text variant={TextVariant.BODY_2} color={TextColor.TEXT_SECONDARY}>{startingItem} - {endingItem} of {totalElements}</Text>
                    :
                    null
            }
                <IconButton
                    iconName={'ChevronLeft'}
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                    size="medium" />
                <IconButton
                    iconName={'ChevronRight'}
                    disabled={currentPage === totalPages}
                    onClick={() => onPageChange(currentPage + 1)}
                    size="medium" />
            </Fragment>
    );
};

Pagination.propTypes = {
    startingItem: PropTypes.number,
    endingItem: PropTypes.number,
    totalElements: PropTypes.number,
    onPageChange: PropTypes.func,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number
};

export default Pagination;