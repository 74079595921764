import React, {Component} from 'react';
import PropTypes from 'prop-types';

const anchorRef = React.createRef();

class WorksmithCsvLink extends Component {
    constructor(props) {
        super(props);

    }

    buildURI = (data) => {
        const blob = new Blob(['', data], {type: 'text/csv'});
        const dataURI = `data:text/csv;charset=utf-8,${data}`;

        const URL = window.URL || window.webkitURL;

        return (typeof URL.createObjectURL === 'undefined')
            ? dataURI
            : URL.createObjectURL(blob);
    };

    handleLegacy = (event, data, filename) => {
        // If this browser is IE 11, it does not support the `download` attribute
        if (window.navigator.msSaveOrOpenBlob) {
            // Stop the click propagation
            event.preventDefault();

            let blob = new Blob(['', data], {type: 'text/csv'});
            window.navigator.msSaveBlob(blob, filename);

            return false
        }
    };

    clickLink = () => {
        anchorRef.current.click();
    };

    render() {
        let {filename, data, children} = this.props;

        return (
            <a download={filename}
               ref={anchorRef}
               href={this.buildURI(data)}
               onClick={event => this.handleLegacy(event, data, filename)}>
                {children}
            </a>
        )
    }
}

WorksmithCsvLink.propTypes = {
    filename: PropTypes.string,
    data: PropTypes.string,
};

export default WorksmithCsvLink;