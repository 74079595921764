export const IconType = Object.freeze({
    ACCESS_TIME:             'AccessTime',
    ACCOUNT_BOX:             'AccountBox',
    ACCOUNT_CIRCLE:          'AccountCircle',
    ACCOUNT_CIRCLE_OUTLINED: 'AccountCircleOutlined',
    ADD:                     'Add',
    ADD_CIRCLE:              'AddCircle',
    ADD_CIRCLE_OUTLINE:      'AddCircleOutline',
    ALIGN_CENTER:            'FormatAlignCenter',
    ALIGN_JUSTIFY:           'FormatAlignJustify',
    ALIGN_LEFT:              'FormatAlignLeft',
    ALIGN_RIGHT:             'FormatAlignRight',
    ARROW_BACK:              'ArrowBack',
    ARROW_DOWNWARD:          'ArrowDownward',
    ARROW_DROP_DOWN:         'ArrowDropDown',
    ARROW_FORWARD:           'ArrowForward',
    ARROW_UPWARD:            'ArrowUpward',
    ASSIGNMENT_CHECKED:      'AssignmentTurnedInRounded',
    ASSIGNMENT_OUTLINED:     'AssignmentOutlined',
    ATTACH_FILE:             'AttachFile',
    AUTO_RENEW:              'Autorenew',
    AUTO_AWESOME:            'AutoAwesome',
    BOLD:                    'FormatBold',
    BOOKMARK_BORDER_OUTLINE: 'BookmarkBorderOutlined',
    BORDER_COLOR:            'BorderColor',
    BUILD:                   'Build',
    CAMERA:                  'PhotoCamera',
    CANCEL:                  'CancelOutlined',
    CANCEL_FILLED:           'Cancel',
    CHECK:                   'Check',
    CHECK_CIRCLE:            'CheckCircle',
    CHECK_CIRCLE_OUTLINED:   'CheckCircleOutlined',
    CHEVRON_LEFT:            'ChevronLeft',
    CHEVRON_RIGHT:           'ChevronRight',
    CLOSE:                   'Close',
    COLOR_TEXT:              'FormatColorText',
    COMMENT:                 'Comment',
    DATE_RANGE:              'DateRange',
    DELETE:                  'Delete',
    DO_NOT_DISTURB_ALT_OUTLINED: 'DoNotDisturbAltOutlined',
    DESCRIPTION:             'Description',
    DOT:                     'FiberManualRecord',
    EDIT:                    'Edit',
    EMAIL:                   'Email',
    ERROR:                   'Error',
    ERROR_OUTLINE:           'ErrorOutline',
    EXPAND_MORE:             'ExpandMore',
    EXPAND_LESS:             'ExpandLess',
    FILE_COPY:               'FileCopy',
    FILTER_LIST:             'FilterList',
    FORUM:                   'Forum',
    FORUM_OUTLINED:          'ForumOutlined',
    GET_APP:                 'GetApp',
    GROUP:                   'Group',
    HELP_OUTLINE:            'HelpOutline',
    HIGHLIGHT_OFF:           'HighlightOff',
    HOME:                    'Home',
    HORIZONTAL_RULE:         'HorizontalRule',
    HOURGLASS_EMPTY:         'HourglassEmpty',
    IMAGE:                   'Image',
    IMAGE_NOT_SUPPORTED:     'ImageNotSupported',
    INFO:                    'Info',
    INFO_OUTLINED:           'InfoOutlined',
    INSERT_DRIVE_FILE:       'InsertDriveFile',
    INSERT_LINK:             'InsertLink',
    INSIDE_GEOFENCE:         'MyLocation',
    ITALIC:                  'FormatItalic',
    KEYBOARD_ARROW_DOWN:     'KeyboardArrowDown',
    KEYBOARD_ARROW_LEFT:     'KeyboardArrowLeft',
    KEYBOARD_ARROW_RIGHT:    'KeyboardArrowRight',
    LANGUAGE:                'Language',
    LIST_BULLETED:           'FormatListBulleted',
    LIST_NUMBERED:           'FormatListNumbered',
    LOCAL_ATM:               'LocalAtm',
    LOCATION_DISABLED:       'LocationDisabled',
    LOCATION_ON:             'LocationOn',
    LOOKS_ONE:               'LooksOne',
    MENU:                    'Menu',
    MORE_HORIZ:              'MoreHoriz',
    MORE_VERT:               'MoreVert',
    NOTES:                   'Notes',
    OPEN_IN_NEW:             'OpenInNew',
    PAUSE:                   'Pause',
    PERSON:                  'Person',
    PHONE:                   'Phone',
    PHOTO_LIBRARY:           'PhotoLibrary',
    PLAY_ARROW:              'PlayArrow',
    PLAY_CIRCLE_OUTLINE:     'PlayCircleOutline',
    PRINT:                   'Print',
    CHAT_BUBBLES:            'QuestionAnswer',
    REDO:                    'Redo',
    REPLY:                   'Reply',
    REPORT_PROBLEM_OUTLINED: 'ReportProblemOutlined',
    SCHEDULE:                'Schedule',
    SEARCH:                  'Search',
    SEND:                    'Send',
    SETTINGS:                'Settings',
    STOP:                    'Stop',
    STORE:                   'Store',
    SWAP_VERT:               'SwapVert',
    SYSTEM_UPDATE_ALT:       'SystemUpdateAlt',
    THUMBS_DOWN:             'ThumbDown',
    THUMBS_UP:               'ThumbUp',
    TODAY:                   'Today',
    TUNE:                    'Tune',
    UNDERLINE:               'FormatUnderlined',
    UNDO:                    'Undo',
    UPDATE:                  'Update',
    VERIFIED_USER:           'VerifiedUser',
    YOU_TUBE:                'YouTube',
});

export const MATERIAL_X_PRO_LICENSE_KEY =
    '742496b16c464356d03a4b57a6cbd593Tz04NzE1NixFPTE3NDI5OTY5NTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';
