import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import ClientRoutes from "../../ClientRoutes";
import {Color, FunctionalColor} from 'worksmith/enums/Color';
import Grid from 'worksmith/components/Grid/Grid';
import Icon, {IconFontSize} from 'worksmith/components/Icon/Icon';
import {IconType} from 'worksmith/enums/MaterialEnums';
import {MomentFormat} from 'worksmith/enums/MomentFormat';
import Text, {TextColor, TextDisplay, TextVariant} from 'worksmith/components/Text/Text';
import Tooltip from 'worksmith/components/Tooltip/Tooltip';
import {ValueIsSet} from 'worksmith/helpers/GenericHelpers';

const StyledTextContainer = styled.div`
  display: flex;
  padding: .25rem;
  
  && {
    p {
      color: ${({isWalkthrough, truncate}) => isWalkthrough && truncate ? FunctionalColor.BLACK : Color.WHITE}
    }
  }
`

const MWDView = ({event}) => {
    const isComplete = ValueIsSet(event.completionDate);
    const isFirstInRecurringService = event.isFirstObligationInRecurringService;
    const isRescheduling = event.isRescheduling;
    const isWalkthrough = event.isWalkthrough;

    const href = ValueIsSet(event.obligationTemplateId) && !isWalkthrough ?
        ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(event.serviceLineId, event.clientLocationId, false, event.obligationTemplateId)
        :
        ClientRoutes.SINGLE_REQUESTS(event.requestId)
    const timeWindow = `${moment(event.start).format(MomentFormat.StandardTime)} - ${moment(event.end).format(MomentFormat.StandardTime)}`;
    let description = `${event.serviceLineName} / ${event.clientLocationTitle} / ${timeWindow}`;

    if (event.requestId) {
        description = `${event.serviceLineName} / ${event.clientLocationTitle} / ${event.requestId} / ${timeWindow}`;
    }

    const link = (truncate) => {
        return (
            <StyledTextContainer isWalkthrough={isWalkthrough} truncate={truncate}>
                {isComplete ? <Icon fontSize={IconFontSize.SMALL} name={IconType.CHECK_CIRCLE} iconColor={isWalkthrough && truncate ? FunctionalColor.BLACK : Color.WHITE} left/> : null}
                {isRescheduling ? <Icon fontSize={IconFontSize.SMALL} name={IconType.UPDATE} iconColor={isWalkthrough && truncate ? FunctionalColor.BLACK : Color.WHITE} left/> : null}
                {isFirstInRecurringService ? <Icon fontSize={IconFontSize.SMALL} name={IconType.LOOKS_ONE} iconColor={isWalkthrough && truncate ? FunctionalColor.BLACK : Color.WHITE} left/> : null}
                <Text
                    display={TextDisplay.INLINE_BLOCK}
                    variant={TextVariant.BODY_2}
                    noWrap={event.view === 'month' && truncate}
                    link
                    href={`#/${href}`}
                    target={'_blank'}
                    textDecoration={'underline'}
                >
                    {description}
                </Text>
            </StyledTextContainer>
        )
    }

    return (
        <Tooltip
            title={
                <Grid container item xs={12}>
                    {link(false)}
                </Grid>
            }
        >
            <Grid container item xs={12}>
                {link(true)}
            </Grid>
        </Tooltip>
    )
}

export default MWDView;

MWDView.propTypes = {
    event: PropTypes.shape({
        allDay: PropTypes.bool,
        clientLocation: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string
        }),
        color: PropTypes.string,
        end: PropTypes.string,
        isFirstObligationInRecurringService: PropTypes.bool,
        isRescheduling: PropTypes.bool,
        isWalkthrough: PropTypes.bool,
        obligationDate: PropTypes.string,
        obligationTemplate: PropTypes.shape({
            id: PropTypes.number
        }),
        request: PropTypes.shape({
            completionDate: PropTypes.string,
            id: PropTypes.number
        }),
        serviceLine: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        start: PropTypes.string
    }),
}

