import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {VictoryLabel, VictoryTooltip} from 'victory';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {DataVisualizationTheme} from "../Theme/ThemeProvider";

class CustomDataLabel extends Component {
    parseTooltip = (info) => {
        const {combinedDataTotal} = this.props;
        const tooltip = info.data[info.index].tooltip;

        if (ValueIsSet(tooltip)) {
            let evaluatedTooltip = tooltip;

            if (evaluatedTooltip.includes('${percent}'))
                evaluatedTooltip = evaluatedTooltip.replace(/\${percent}/, (info.datum.y / combinedDataTotal * 100).toFixed(1) + '%');

            if (evaluatedTooltip.includes('${value}'))
                evaluatedTooltip = evaluatedTooltip.replace(/\${value}/, info.datum.y);

            return evaluatedTooltip;
        }

        return '';
    };

    render() {
        const {parseTooltip} = this;
        const {withLegend, hideLabels, type, data, index, datum} = this.props;

        let tooltipLabelStyle = DataVisualizationTheme[type].style.labels;

        if(ValueIsSet(datum.tooltipLabelFontSize))
            tooltipLabelStyle.fontSize = isNaN(datum.tooltipLabelFontSize) ? datum.tooltipLabelFontSize : datum.tooltipLabelFontSize + 'px';

        let tooltipProps = {};

        //If there was no tooltip field set on a data point, don't show a tooltip for it
        const showTooltip = ValueIsSet(data[index].tooltip);

        //Tooltips can look wildly different between graph types, so we set the props for the tooltip based on the graph type
        switch(type) {
            case 'bar':
                tooltipProps = {
                    text: parseTooltip
                };
                break;
            case 'pie':
                tooltipProps = {
                    cornerRadius: 50,
                    flyoutHeight: 100,
                    flyoutWidth: 100,
                    flyoutStyle: {fill: 'transparent', stroke: 'transparent'},
                    orientation: 'top',
                    pointerLength: 0,
                    text: parseTooltip,
                    x: withLegend && hideLabels ? 200 : withLegend ? 170 : 250,
                    y: 225
                };
                break;
        }

        return (
            <g>
                {hideLabels ?
                    null
                    :
                    <VictoryLabel {...this.props}/>
                }
                {showTooltip ?
                    <VictoryTooltip
                        labelComponent={<VictoryLabel lineHeight={1.3}/>}
                        constrainToVisibleArea
                        theme={DataVisualizationTheme}
                        {...this.props}
                        {...tooltipProps}
                    />
                    :
                    null
                }
            </g>
        )
    }
}

CustomDataLabel.defaultEvents = VictoryTooltip.defaultEvents;

CustomDataLabel.propTypes = {
    combinedDataTotal: PropTypes.number,        //passed in to support quick calculations for adding percent values to tooltips
    hideLabels: PropTypes.bool,
    type: PropTypes.oneOf([
        'bar',
        'pie'
    ]),
    withLegend: PropTypes.bool
};

export default CustomDataLabel;