import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Autocomplete as MaterialAutocomplete} from '@mui/material';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import {createFilterOptions} from '@mui/material/Autocomplete';
import styled from "styled-components";

import {Debounce, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {withTheme} from "worksmith/components/Theme/ThemeProvider";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import {ComponentColor} from "worksmith/enums/Color";
import {Display, JustifyContent} from "worksmith/enums/CSSEnums";
import {TextFieldVariant} from "worksmith/components/Inputs/TextField/TextField.web";
import {deburr, isEqual} from "lodash";
import {JavascriptType} from "worksmith/enums/GenericEnums";

class Autocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsLoading: false,
            options: props.options ? props.options : ValueIsSet(props.initialSelectedItem) ? props.initialSelectedItem: [],
            value: ValueIsSet(props.initialSelectedItem) ? props.initialSelectedItem : ValueIsSet(props.defaultMultipleValue) ? props.defaultMultipleValue : null
        };

        this.searchCounter = 0;
        this.debouncedGetOptions = props.getOptions ?
            Debounce((inputValue) => {
                let {getOptions} = this.props;

                //Increment the search count
                this.searchCounter += 1;

                //Keep a record of the search count when this search was first started
                const initialSearchCount = this.searchCounter;

                const results = getOptions(inputValue);

                //Check if the type of the results is a Promise
                if (typeof results.then === JavascriptType.FUNCTION) {
                    results.then(data => {
                        //Only use the results if there hasn't been a more recent search
                        if (this.searchCounter === initialSearchCount) {
                            this.setState({options: data, optionsLoading: false})
                        }
                    })
                } else {
                    this.setState({options: getOptions(inputValue), optionsLoading: false});
                }
            }, 500)
            :
            null;
    }

    setOptionThroughRef = (option) => {
        this.setState({value: option});
    };

    clear = () => {
        const {onSelect, multiple} = this.props;
        let val = null;
        if(multiple) {
            val = [];
        }
        this.setState({value: val}, () => {
            if (ValueIsSet(onSelect))
                onSelect(val);
        })
    };

    static getDerivedStateFromProps(props) {
        let newState = {};
        if (props.options) {
            newState.options = props.options;
        }

        return newState;
    }

    onSelect = (event, option) => {
        let {allowEmptyValue,
             onSelect,
             useFullOptionAsOnSelectParameter} = this.props;

        if (ValueIsSet(option) || allowEmptyValue) {
            this.setState({value: option}, () => {
                if (ValueIsSet(onSelect)){
                    let selection =
                        ValueIsSet(option) ?
                            useFullOptionAsOnSelectParameter || !ValueIsSet(option.value) ?
                                option
                                :
                                option.value
                            :
                            null;

                    onSelect(selection);
                }
            });
        }
    };

    onInputChange = (event, value, reason) => {

        const {loadOptionsOnChange} = this.props;
        if(loadOptionsOnChange){
            loadOptionsOnChange(value);
        }

        const {
            characterBuffer,
            getOptions,
            onInputChange} = this.props;
        const {options} = this.state;

        if(ValueIsSet(onInputChange))
            onInputChange(event, value, reason);

        if (getOptions) {
            //deburr removes all “combining diacritical marks”, for example é becomes e
            const inputValue = deburr(value.trim()).toLocaleLowerCase();
            const inputLength = inputValue.length;

            if (inputLength >= characterBuffer) {
                this.setState({optionsLoading: true});

                this.debouncedGetOptions(inputValue);
            } else if (options.length > 0) {
                this.setState({options: []});
            }
        }
    };

    filterOptionsIncludeConstants = (options, { inputValue }) => {
        let {constantOptions, filterOptions, characterBuffer} = this.props;
        let {optionsLoading} = this.state;

        let filteredOptions = [];

        if(!optionsLoading && ValueIsSet(constantOptions))
            filteredOptions = filteredOptions.concat(constantOptions);

        if (inputValue.length >= characterBuffer) {
            if(ValueIsSet(filterOptions))
                filteredOptions = filteredOptions.concat(filterOptions(options, {inputValue}));
            else{
                const defaultFilterOptions = (options, { inputValue }) => {
                    const filterOptions = createFilterOptions({
                        matchFrom: 'any',
                        stringify: option => option.label,
                    });

                    return filterOptions(options, { inputValue });
                };

                filteredOptions = filteredOptions.concat(defaultFilterOptions(options, {inputValue}))
            }

        }

        return filteredOptions;
    };

    componentDidUpdate(prevProps) {
        const {updateSelectedOptionsOnPropChange} = this.props;

        if(updateSelectedOptionsOnPropChange && prevProps.defaultMultipleValue !== this.props.defaultMultipleValue){
            this.setState({value: this.props.defaultMultipleValue});
        }
    }

    render() {
        let {onInputChange, onSelect, filterOptionsIncludeConstants} = this;
        const {
            optionsLoading,
            options,
            value,
        } = this.state;
        const {
            allowEmptyValue,
            background,
            blurOnSelect,
            chipProps,
            constantOptions,
            defaultMultipleValue,
            disableClearable,
            disabled,
            error,
            filterOptions,
            filterSelectedOptions,
            freeSolo,
            fullWidth,
            getOptionLabel,
            helperText,
            inputRef,
            label,
            limitTags,
            multiple,
            name,
            nameless,
            noOptionsText,
            onKeyDown,
            onFocus,
            placeholder,
            renderOption,
            renderTags,
            size,
            startAdornment,
            startAdornmentProps,
            variant,
        } = this.props;

        return (
            <MaterialAutocomplete
                ChipProps={chipProps}
                multiple={multiple}
                blurOnSelect={blurOnSelect}
                onFocus={onFocus}
                defaultValue={defaultMultipleValue}
                disabled={disabled}
                disableClearable={disableClearable && !allowEmptyValue}
                filterOptions={!ValueIsSet(constantOptions) || constantOptions.length === 0 ? filterOptions : filterOptionsIncludeConstants}
                filterSelectedOptions={filterSelectedOptions}
                freeSolo={freeSolo}
                getOptionLabel={ValueIsSet(getOptionLabel) ? getOptionLabel : (option) => ValueIsSet(option.label) ? option.label : ''}
                isOptionEqualToValue={(option, value) => (isEqual(option.value, value) || isEqual(option, value))}
                renderInput={(params) => (
                    <StyledInputProps background={background}>
                        <TextField
                            {...params}
                            className={this.props.className}
                            helperText={helperText}
                            error={error}
                            fullWidth={fullWidth}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: ValueIsSet(startAdornment) ? (
                                    <>
                                        <InputAdornment position={'start'} {...startAdornmentProps}>
                                            {startAdornment}
                                        </InputAdornment>
                                        {params.InputProps.startAdornment }
                                    </>
                                ) : params.InputProps.startAdornment,
                            }}
                            label={label}
                            name={name}
                            nameless={nameless}
                            placeholder={placeholder}
                            inputRef={inputRef}
                            variant={variant}/>
                    </StyledInputProps>
                )}
                renderTags={renderTags}
                limitTags={limitTags}
                loading={optionsLoading}
                loadingText={
                    <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                        <CircularProgress size={30} color={ComponentColor.SECONDARY}/>
                    </Box>
                }
                renderOption={renderOption}
                noOptionsText={noOptionsText}
                onChange={onSelect}
                onInputChange={onInputChange}
                onKeyDown={onKeyDown}
                options={options}
                size={size}
                value={value}/>
        )
    }
}

Autocomplete.propTypes = {
    allowEmptyValue: PropTypes.bool,
    updateSelectedOptionsOnPropChange: PropTypes.bool,
    blurOnSelect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    characterBuffer: PropTypes.number,                  //The number of characters that have to be entered before the getSuggestions prop is called
    chipProps: PropTypes.object,
    defaultMultipleValue: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })),
    disabled: PropTypes.bool,
    disableClearable: PropTypes.bool,
    endAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    endAdornmentProps: PropTypes.object,
    error: PropTypes.bool,
    filterOptions: PropTypes.func,
    filterSelectedOptions: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    }),
    freeSolo: PropTypes.bool,
    fullWidth: PropTypes.bool,
    getOptions: PropTypes.func,          //The callback used to determine the set of options that can be selected as the user types
    helperText: PropTypes.string,
    initialSelectedItem: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    }),
    label: PropTypes.string,
    loadOptionsOnChange: PropTypes.func,
    limitTags: PropTypes.number,
    maxMenuHeight: PropTypes.number,                    //The max height of the list of options before the scroll bar appears
    multiple: PropTypes.bool,
    name: PropTypes.string,
    nameless: PropTypes.bool,
    onFocus: PropTypes.func,
    noOptionsText: PropTypes.node,
    onSelect: PropTypes.func,
    useFullOptionAsOnSelectParameter: PropTypes.bool, // usually the onSelect function passed in is called by taking option.value as
    // the parameter -- onSelect(option.value). If this prop is true(or if option.value is undefined), it will be called by
    // taking the full option object -- onSelect(option).
    options: PropTypes.arrayOf(PropTypes.shape({    // A static list of options can be given as an alternative to getOptions (filtering is done is component)
        label: PropTypes.string,
        value: PropTypes.any
    })),
    constantOptions: PropTypes.arrayOf(PropTypes.shape({  // A static list of options that will show up any time the component is not loading, regaurdless of the filter
        label: PropTypes.string,
        value: PropTypes.any
    })),
    placeholder: PropTypes.string,
    renderOption: PropTypes.func,
    getOptionLabel: PropTypes.func,
    renderTags: PropTypes.func,
    size: PropTypes.string,
    startAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    startAdornmentProps: PropTypes.object,
    variant: CustomPropTypes.enum(TextFieldVariant),
};

Autocomplete.defaultProps = {
    allowEmptyValue: true,
    updateSelectedOptionsOnPropChange: false,
    characterBuffer: 3,
    variant: TextFieldVariant.OUTLINED
};

export default withTheme(Autocomplete);

const StyledInputProps = styled('div')`
    && {
        & .MuiInputBase-root {
            background-color: ${props => props.background ? props.background : 'inherit'};
        }    
    }    
`;
