import React, {useState} from 'react';
import PropTypes from 'prop-types'

import ActionableModalTemplate from "worksmith/components/Modal/Templates/ActionableModalTemplate"
import {AlignItems} from "worksmith/enums/CSSEnums";
import Button from "worksmith/components/Button/Button";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import Grid from "worksmith/components/Grid/Grid";
import Modal from "worksmith/components/Modal/Modal"
import Select from "worksmith/components/Inputs/Select/Select";
import {StateFullName} from "worksmith/enums/StateFullName"
import TextField, {TextFieldType} from "worksmith/components/Inputs/TextField/TextField";

import ClientLocationService from "worksmith/services/api/ClientLocationService";
import MallService from "worksmith/services/api/MallService";

const clientLocationService = new ClientLocationService();
const mallService = new MallService();

const stateOptions = Object.keys(StateFullName).map(state => ({
    label: state,
    value: state
}))

const GeneralInfoMallModal =
    ({
         closeModal,
         locationId,
         mallModalOpen,
         refetchGeneralData
    }) => {
        const [mallErrors, setMallErrors] = useState(false);
        const [isSubmitting, setIsSubmitting] = useState(false);

        let mallFormFields = {
            mallName: '',
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            propertyOwner: '',
            requirements: '',
            coi: ''
        }

        const handleMallModalChange = (formField, value) => {
            mallFormFields[formField] = value
        }

        const submitMallInfo = async (e) => {
            e.preventDefault();
            setIsSubmitting(true);

            let submitData = {}
            submitData = {
                name: mallFormFields.mallName,
                phone: mallFormFields.phone,
                propertyOwner: mallFormFields.propertyOwner,
                address: {
                    addressLineOne: mallFormFields.addressLineOne,
                    addressLineTwo: mallFormFields.addressLineTwo,
                    city: mallFormFields.city,
                    state: mallFormFields.state,
                    zip: mallFormFields.zip,
                },
                requirements: mallFormFields.requirements,
                coi: mallFormFields.coi,
            }

            if (mallFormFields.mallName && mallFormFields.phone && mallFormFields.propertyOwner) {
                try {
                    const mallData = await mallService.createMall(submitData)
                    await clientLocationService.update({id: locationId, mall: mallData})
                    await refetchGeneralData();
                    setIsSubmitting(false);
                    closeModal();
                } catch (err) {
                    console.error(err)
                    setIsSubmitting(false);
                    closeModal();
                }
            } else {
                setMallErrors(true);
                setIsSubmitting(false);
            }
        }
    return (
        <Modal
            onClose={closeModal}
            open={mallModalOpen}
        >
            <ActionableModalTemplate
                body={
                    <Grid spacing={2} container alignItems={AlignItems.FLEX_START}>
                        <Grid item xs={12}>
                            <TextField
                                error={!mallFormFields.mallName && mallErrors}
                                fullWidth
                                initialValue={mallFormFields.mallName}
                                label={'Mall name'}
                                name={'mallName'}
                                onChange={value => handleMallModalChange('mallName', value)}
                                required
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                initialValue={mallFormFields.addressLineOne}
                                label={'Address Line 1'}
                                name={'addressLineOne'}
                                onChange={value => handleMallModalChange('addressLineOne', value)}
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                initialValue={mallFormFields.addressLineTwo}
                                label={'Address Line 2'}
                                name={'addressLineTwo'}
                                onChange={value => handleMallModalChange('addressLineTwo', value)}
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                initialValue={mallFormFields.city}
                                label={'City'}
                                name={'city'}
                                onChange={value => handleMallModalChange('city', value)}
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Select
                                fullWidth
                                initialValue={mallFormFields.state}
                                label={'State'}
                                name={'state'}
                                onChange={value => handleMallModalChange('state', value)}
                                options={stateOptions}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                initialValue={mallFormFields.zip}
                                label={'Zipcode'}
                                name={'zip'}
                                onChange={value => handleMallModalChange('zip', value)}
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                error={mallErrors && !mallFormFields.phone}
                                fullWidth
                                initialValue={mallFormFields.phone}
                                label={'Mall phone number'}
                                name={'phone'}
                                onChange={value => handleMallModalChange('phone', value)}
                                required
                                type={TextFieldType.PHONE}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                error={mallErrors && !mallFormFields.propertyOwner}
                                fullWidth
                                initialValue={mallFormFields.propertyOwner}
                                label={'Property Owner'}
                                name={'propertyOwner'}
                                onChange={value => handleMallModalChange('propertyOwner', value)}
                                required
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                initialValue={mallFormFields.requirements}
                                label={'Mall Requirements'}
                                name={'requirements'}
                                onChange={value => handleMallModalChange('requirements', value)}
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                initialValue={mallFormFields.coi}
                                label={'COI'}
                                name={'coi'}
                                onChange={value => handleMallModalChange('coi', value)}
                                type={TextFieldType.TEXT}
                            />
                        </Grid>
                    </Grid>
                }
                buttons={[
                    <Button onClick={closeModal}>Cancel</Button>,
                    <Button disabled={isSubmitting} primary onClick={submitMallInfo}>{isSubmitting ? <CircularProgress size={24} /> : 'OK'}</Button>
                ]}
                onApprove={submitMallInfo}
                title={'New Mall'}
            />
        </Modal>
    )

    }
export default GeneralInfoMallModal;

GeneralInfoMallModal.propTypes = {
    closeModal: PropTypes.func,
    locationId: PropTypes.string,
    mallModalOpen: PropTypes.bool,
}