import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import styled from 'styled-components';
import {Link, withRouter} from "react-router-dom";
import RecurringServiceCard from "worksmith/composite-components/RecurringServiceCard/RecurringServiceCard";
import ClientRoutes from "../../ClientRoutes";

const RecurringServicesSummaryPageView = (props) => {
    let {locationsCount, history, serviceLineMap} = props;
    let serviceLineIds = Object.keys(serviceLineMap);

    return <Indent>
        <PaddedText semiBold variant={TextVariant.H5}>Recurring Services</PaddedText>
        <PaddedSubtitle semiBold>My Locations: {locationsCount}</PaddedSubtitle>
        {
            serviceLineIds.length > 0 ?
                serviceLineIds.map(serviceLineId => {
                    const ongoingServiceCount = serviceLineMap[serviceLineId].ongoingServiceCount;
                    const pendingSetUpServiceCount = serviceLineMap[serviceLineId].pendingSetUpServiceCount;
                    const serviceLineName = serviceLineMap[serviceLineId].serviceLineName;

                    if(ongoingServiceCount + pendingSetUpServiceCount < 1)
                        return null;

                    return <RecurringServiceCard onClick={() => history.push(ClientRoutes.RECURRING_SERVICE_SERVICE_LINE(serviceLineId))}
                                                 ongoingServiceCount={ongoingServiceCount}
                                                 pendingSetUpServiceCount={pendingSetUpServiceCount}
                                                 serviceLine={{id: serviceLineId, name: serviceLineName}}/>;
                })
                :
                <Text>There are no recurring services.  If you'd like to set up a recurring service please <UnderlineLink to={"/" + "new-request"}>submit a new request.</UnderlineLink></Text>
        }
    </Indent>;

};

const PaddedText = styled(Text)`
    padding-top: 0.25em;
    padding-bottom: 1.15em;
`;

const PaddedSubtitle = styled(Text)`
    padding-bottom: 0.4em;
`;

const Indent = styled.div`
    padding-left: 1em;
`;

const UnderlineLink = styled(Link)`
    text-decoration: underline;
    color: #156F83;
`;

RecurringServicesSummaryPageView.propTypes = {
    requests: PropTypes.arrayOf(PropTypes.object),
    serviceLineMap: PropTypes.object,
    locationsCount: PropTypes.number,
};

export default withRouter(RecurringServicesSummaryPageView);