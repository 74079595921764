import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {fill} from "lodash";

import ChangeRequestApprovalType from "worksmith/enums/api/cr/ChangeRequestApprovalType";
import ChangeRequestStatus from "worksmith/enums/api/cr/ChangeRequestStatus";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {ObjectsHaveIdenticalValue, ShallowCopy} from "../../helpers/GenericHelpers";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";
import ScheduleOptionsFormView from "./ScheduleOptionsFormView";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import ScheduleType from "worksmith/enums/api/proposal/ScheduleType";
import UserType from "worksmith/enums/api/user/UserType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";


class ScheduleOptionsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schedules: fill(Array(props.numberOfSchedules), {}),
            updatedFrequency: this.props.frequency ? ShallowCopy(this.props.frequency) : null,
            customScheduleWarningDialogOpen: false,
        };
        this.valid = false;
        this.approvalType = null;
        this.mutedNotifications = [];
    }

    onFrequencyChange = (newFrequency) => {
        const {schedules, updatedFrequency} = this.state;
        if(updatedFrequency.recurrenceType !== newFrequency.recurrenceType){
            schedules.forEach(schedule => {
                schedule.date = null;
            })
        }
        schedules.forEach(schedule => {
            schedule.recurrenceCount = newFrequency.recurrenceCount;
            schedule.recurrenceType = newFrequency.recurrenceType;
            schedule.repeatEvery = newFrequency.repeatEvery;
        });
        let newValid = this.isValid(schedules);
        this.setState({schedules, updatedFrequency: newFrequency}, () => {
            if (this.valid !== newValid) {
                this.onValidChange(newValid);
                this.valid = newValid;
            }
        });
    };

    onScheduleChange = (schedule, index) => {
        const {schedules} = this.state;
        let newSchedules = schedules.slice();
        newSchedules[index] = schedule;

        let newValid = this.isValid(newSchedules);
        this.setState({schedules: newSchedules}, () => {
            if (this.valid !== newValid) {
                this.onValidChange(newValid);
                this.valid = newValid;
            }
        });

    };

    isValid = (schedules) => {
        const {frequency, numberOfRequiredSchedules} = this.props;
        let validSchedules = 0;
        let invalidSchedules = 0;

        schedules.forEach(schedule => {
            let validValue = ValueIsSet(frequency) ?
                this.validateRecurringSchedule(schedule)
                :
                this.validateOneTimeSchedule(schedule);

            if (validValue > 0) {
                validSchedules += 1;
            } else if (validValue < 0) {
                invalidSchedules += 1;
            }
        });

        return validSchedules >= numberOfRequiredSchedules && invalidSchedules === 0;
    };

    getValidSchedules = (schedules) => {
        const {frequency} = this.props;

        return schedules.filter((schedule) => {
            if (ValueIsSet(frequency)) {
                return (this.validateRecurringSchedule(schedule) > 0)
            } else {
                return this.validateOneTimeSchedule(schedule) > 0;
            }
        });
    };

    validateOneTimeSchedule = (schedule) => {
        const {useTicketing,userType,obligation,obligationChangeRequest} = this.props;

        if (moment.isMoment(schedule.date)
                && schedule.date.isValid()
                && schedule.date.isSameOrAfter(moment().startOf('day'))
                && moment.isMoment(schedule.arrivalStartTime) && moment.isMoment(schedule.arrivalEndTime)
                && schedule.arrivalStartTime.isSameOrBefore(schedule.arrivalEndTime)) {

            if(userType === UserType.WORKSMITH && !ValueIsSet(this.approvalType) && ValueIsSet(obligation) && !ValueIsSet(obligationChangeRequest)) {
                return -1;
            }

            if(useTicketing) {
                if (moment.isMoment(schedule.returnDate)
                    && schedule.returnDate.isValid()
                    && schedule.returnDate.isSameOrAfter(schedule.date)) {
                    return 1;
                } else {
                    return -1;
                }
            }

            return 1
        }

        if (ValueIsSet(schedule.returnDate)) {
            return -1;
        }

        return 0;
    };

    validateRecurringSchedule = (schedule) => {
        const {frequency,userType,obligationTemplate,templateChangeRequest} = this.props;
        const {updatedFrequency} = this.state;
        let frequencyToValidateAgainst = ObjectsHaveIdenticalValue(frequency,updatedFrequency,false) ? frequency : updatedFrequency;

        if (moment.isMoment(schedule.date)
            && schedule.date.isValid()
            && schedule.date.isSameOrAfter(moment().startOf('day'))
            && moment.isMoment(schedule.arrivalStartTime) && moment.isMoment(schedule.arrivalEndTime)
            && schedule.arrivalStartTime.isSameOrBefore(schedule.arrivalEndTime)) {

            if(userType === UserType.WORKSMITH && !ValueIsSet(this.approvalType) && ValueIsSet(obligationTemplate) && !ValueIsSet(templateChangeRequest)) {
                return -1;
            }

            if(frequencyToValidateAgainst.recurrenceType === RecurrenceType.WEEKLY) {
                if (ValueIsSet(schedule.daysOfWeek)
                    && schedule.daysOfWeek.length > 0
                    && schedule.daysOfWeek.length === frequencyToValidateAgainst.recurrenceCount)
                {
                    return 1;
                } else {
                    return -1;
                }
            }

            if(frequencyToValidateAgainst.recurrenceType === RecurrenceType.MONTHLY || frequencyToValidateAgainst.recurrenceType === RecurrenceType.YEARLY) {
                if (ValueIsSet(schedule.dayOfMonth)) {
                    return 1;
                } else {
                    return -1;
                }
            }

            if(frequencyToValidateAgainst.recurrenceType === RecurrenceType.DAILY) {
                return 1;
            }

            return 0;
        }

        if (ValueIsSet(schedule.dayOfMonth) || (ValueIsSet(schedule.daysOfWeek) && schedule.daysOfWeek.length > 0)) {
            return -1;
        }

        return 0;
    };

    onValidChange = (valid) => {
        this.props.onValidChange(valid);
    };

    getApprovalType = () => {
        let {templateChangeRequest, obligationChangeRequest, userType, isPendingEntireRecurringServiceSchedule} = this.props;

        if(userType !== UserType.VENDOR)
            if (userType === UserType.WORKSMITH && this.approvalType === ChangeRequestApprovalType.NONE) {
                this.approvalType = ChangeRequestApprovalType.NONE;
            }
            // The below if statement accounts for the incident when there is a templateChangeRequest and an obligationChangeRequest open at the same time.
            else if(ValueIsSet(templateChangeRequest) && ValueIsSet(obligationChangeRequest)) {
                this.approvalType = isPendingEntireRecurringServiceSchedule ? templateChangeRequest.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT ? 'VENDOR' : 'CLIENT' : obligationChangeRequest.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT ? 'VENDOR' : 'CLIENT';
            } else if(ValueIsSet(templateChangeRequest)){
                this.approvalType = templateChangeRequest.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT ? 'VENDOR' : 'CLIENT';
            } else if(ValueIsSet(obligationChangeRequest)){
                this.approvalType = obligationChangeRequest.status === ChangeRequestStatus.NEEDS_CLIENT_INPUT ? 'VENDOR' : 'CLIENT';
            }
        return this.approvalType;
    };

    setApprovalType = (approvalType) => {
        const {schedules} = this.state;
        this.approvalType = approvalType;
        let newValid = this.isValid(schedules);
            if (this.valid !== newValid) {
                this.onValidChange(newValid);
                this.valid = newValid;
            }
    };

    getMutedNotifications = () => {
        return this.mutedNotifications;
    };

    setMutedNotifications = (mutedNotifications) => {
        this.mutedNotifications = mutedNotifications;
    };

    getValue = () => {
        const {schedules} = this.state;
        const {frequency} = this.props;

        return this.getValidSchedules(schedules).map((schedule) => {
            if (schedule.returnDate) {
                schedule.returnDate = schedule.returnDate.format(MomentFormat.ISODate);
            }
            schedule.date = schedule.date.format(MomentFormat.ISODate);
            schedule.arrivalStartTime = schedule.arrivalStartTime.format(MomentFormat.HoursMinutes);
            schedule.arrivalEndTime = schedule.arrivalEndTime.format(MomentFormat.HoursMinutes);

            if (schedule.daysOfWeek && !schedule.customScheduleArrivalWindows) {
                schedule.daysOfWeek = schedule.daysOfWeek.map(dow => {
                    return {dayOfWeek: dow}
                });
            }

            if (frequency) {
                schedule.type = ScheduleType.RECURRING;
            } else {
                schedule.type = ScheduleType.ONE_TIME;
            }

            return schedule;
        });
    };

    handleCustomScheduleWarningDialogOpen = (warningDialogOpen, deleteCustomSchedule) => {
        const {schedules, updatedFrequency} = this.state;

        this.setState({schedules, updatedFrequency, customScheduleWarningDialogOpen: warningDialogOpen});

        if (deleteCustomSchedule) {

            let newDaysOfWeek = [];
            schedules[0].daysOfWeek.map(day => {
                newDaysOfWeek.push(day.dayOfWeek)
            });

            let newSchedule = {
                ...schedules[0],
                customScheduleArrivalWindows: null,
                daysOfWeek: newDaysOfWeek,
            };

            this.onScheduleChange(newSchedule, 0);
        }
    };

    render() {
        const {handleCustomScheduleWarningDialogOpen, onScheduleChange, onFrequencyChange, setApprovalType, setMutedNotifications} = this;
        const {schedules, updatedFrequency, customScheduleWarningDialogOpen} = this.state;
        const {
            adjustableFrequency,
            allowCustomScheduleCheckBox,
            clientLocation,
            duration,
            frequency,
            isForNonWorksmithClient,
            isPendingEntireRecurringServiceSchedule,
            obligation,
            obligationChangeRequest,
            obligationTemplate,
            scheduleOptionType,
            serviceLine,
            showCurrentSchedule,
            spacing,
            submitText,
            templateChangeRequest,
            userType,
            useMuteNotificationDialogInstead,
            useTicketing,
            vendor,
        } = this.props;

        return (
            <>
                <ScheduleOptionsFormView adjustableFrequency={adjustableFrequency}
                                         allowCustomScheduleCheckBox={allowCustomScheduleCheckBox}
                                         clientLocation={clientLocation}
                                         customScheduleWarningDialogOpen={customScheduleWarningDialogOpen}
                                         frequency={frequency}
                                         handleCustomScheduleWarningDialogOpen={handleCustomScheduleWarningDialogOpen}
                                         isForNonWorksmithClient={isForNonWorksmithClient}
                                         isPendingEntireRecurringServiceSchedule={isPendingEntireRecurringServiceSchedule}
                                         obligation={obligation}
                                         obligationChangeRequest={obligationChangeRequest}
                                         obligationTemplate={obligationTemplate}
                                         onFrequencyChange={onFrequencyChange}
                                         onScheduleChange={onScheduleChange}
                                         scheduleOptionType={scheduleOptionType}
                                         schedules={schedules}
                                         serviceLine={serviceLine}
                                         setApprovalType={setApprovalType}
                                         setMutedNotifications={setMutedNotifications}
                                         showCurrentSchedule={showCurrentSchedule}
                                         spacing={spacing}
                                         submitText={submitText}
                                         templateChangeRequest={templateChangeRequest}
                                         updatedFrequency={updatedFrequency}
                                         userType={userType}
                                         useMuteNotificationDialogInstead={useMuteNotificationDialogInstead}
                                         useTicketing={useTicketing}
                                         vendor={vendor}/>
            </>
        )
    }
}

ScheduleOptionsForm.defaultProps = {
    numberOfSchedules: 2,
    numberOfRequiredSchedules: 1,
    spacing: 5,
    submitText: 'Submit'
};

ScheduleOptionsForm.propTypes = {
    allowCustomScheduleCheckBox: PropTypes.bool,    // allowCustomScheduleCheckBox prop need to be passed down the component tree in every instance the CustomScheduleCheckBox option should be displayed otherwise the CustomScheduleCheckBox will not be displayed.
    clientLocation: PropTypes.shape({
        addressLineOne: PropTypes.string,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string,
        client: PropTypes.shape({
            nickname: PropTypes.string
        }),
        state: PropTypes.string,
        zip: PropTypes.string,
    }),
    duration: PropTypes.number,
    frequency: PropTypes.shape({     // If frequency is passed in, the schedules created will be recurring
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    isForNonWorksmithClient: PropTypes.bool,
    isPendingEntireRecurringServiceSchedule: PropTypes.bool,
    numberOfRequiredSchedules: PropTypes.number, // initial value
    numberOfSchedules: PropTypes.number, // initial value
    obligation: PropTypes.shape({
        obligationDate: PropTypes.string,
        duration: PropTypes.number,
        arrivalStartTime: PropTypes.string,
        arrivalEndTime: PropTypes.string
    }),
    obligationChangeRequest: PropTypes.shape({
        requestedSchedules: PropTypes.arrayOf(PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            duration: PropTypes.number,
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }))
    }),
    obligationTemplate: PropTypes.shape({
        recurringScheduleTemplate: PropTypes.shape({
            duration: PropTypes.number,
            frequency: PropTypes.shape({
                recurrenceCount: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number
            }),
        })
    }),
    onValidChange: PropTypes.func.isRequired,    // (valid) => {}
    scheduleOptionType: CustomPropTypes.enum(ScheduleOptionType),
    serviceLine: PropTypes.shape({}),
    showCurrentSchedule: PropTypes.bool,
    spacing: PropTypes.number,
    submitText: PropTypes.string,
    templateChangeRequest: PropTypes.shape({
        requestedSchedules: PropTypes.arrayOf(PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string.isRequired,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            duration: PropTypes.number,
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }))
    }),
    useMuteNotificationDialogInstead: PropTypes.bool,
    useTicketing: PropTypes.bool,
    userType: CustomPropTypes.enum(UserType),
    vendor: PropTypes.string,
};

export default ScheduleOptionsForm;