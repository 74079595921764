import React from 'react';

import ActionableModalTemplate from "worksmith/components/Modal/Templates/ActionableModalTemplate";
import Modal from "worksmith/components/Modal/Modal";

const EmergencyConfirmationModal = (props) => {
    let {
        visible,
        onConfirm,
        onCancel
    } = props;



    return (
        <Modal open={visible}>
            <ActionableModalTemplate
                title={"Confirm Emergency?"}
                body={"Please be advised that emergency requests may incur higher fees due to overtime rates and/or short scheduling notice."}
                onApprove={onConfirm}
                onCancel={onCancel}
                cancelText={'CANCEL'}
                approvalText={'I ACKNOWLEDGE'}
            />
        </Modal>
    )
};

export default EmergencyConfirmationModal;
