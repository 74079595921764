import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

import Chip from "worksmith/components/Chip/Chip.web"
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import DatePicker from "worksmith/components/DatePicker/DatePicker.web";
import {FlexDirection, FlexWrap} from "worksmith/enums/CSSEnums";
import Grid from "worksmith/components/Grid/Grid.web";
import {HorizontalOrigin, VerticalOrigin} from "../../components/Popover/Popover.web";
import {InputBaseVariant} from "worksmith/components/Inputs/InputBase/InputBase.web";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import Select, {SelectType} from "../../components/Inputs/Select/Select.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import TimeRangeField from "worksmith/components/TimeRangeField/TimeRangeField";

const repeatByOptions = [
    {
        value: true,
        label: "Day of the month",
        helperText: 'i.e. every month on the 20th'
    },
    {
        value: false,
        label: "Day of the week",
        helperText: 'i.e. on the third Thursday of every month'
    }
];

const RecurringScheduleInputView = (props) => {
    const {
        allowCustomScheduleCheckBox,
        beforeDayOfWeekChange,
        customScheduleChecked,
        disabled,
        handleCustomScheduleWarningDialogOpen,
        hideDatePicker,
        datePickerRef,
        dayOfMonthRef,
        initialDate,
        initialDaysOfWeek,
        initialEndTime,
        initialStartTime,
        isDayBlocked,
        onDateChange,
        onDaysOfWeekChange,
        onDayOfMonthChange,
        onTimeRangeChange,
        recurrenceType,
        spacing,
        variant,
        title
    } = props;

    const handleDisabledClick = () => {
        if (disabled) {
            return handleCustomScheduleWarningDialogOpen(true)
        }
    };


    return (
        <Grid container
              item
              spacing={spacing}>
            {
                title ?
                    <Grid item xs={12}>
                        <Text color={TextColor.TEXT_SECONDARY} variant={TextVariant.BODY_1}>{title}</Text>
                    </Grid>
                    :
                    null
            }
            {(recurrenceType === RecurrenceType.WEEKLY && !hideDatePicker) ?
                <Grid container item xs={12}>
                    <Grid item xs={12}><Text variant={TextVariant.BODY_1}>Repeat on</Text></Grid>
                    <Grid item container xs={12} wrap={FlexWrap.NO_WRAP} onClick={() => handleDisabledClick()}>
                        <Grid item>
                            <Chip
                                disabled={disabled}
                                width={'4.5em'}
                                secondary
                                initialValue={initialDaysOfWeek.indexOf(1) > -1}
                                selectedIconName={null}
                                shouldComponentUpdateWhenClicked={() => {return beforeDayOfWeekChange(1)}}
                                onClick={() => {onDaysOfWeekChange(1)}}>
                                Mon
                            </Chip>
                        </Grid>
                        <Grid item>
                            <Chip
                                disabled={disabled}
                                width={'4.5em'}
                                secondary
                                initialValue={initialDaysOfWeek.indexOf(2) > -1}
                                selectedIconName={null}
                                shouldComponentUpdateWhenClicked={() => {return beforeDayOfWeekChange(2)}}
                                onClick={() => {onDaysOfWeekChange(2)}}>
                                Tue
                            </Chip>
                        </Grid>
                        <Grid item>
                            <Chip
                                disabled={disabled}
                                width={'4.5em'}
                                secondary
                                initialValue={initialDaysOfWeek.indexOf(3) > -1}
                                selectedIconName={null}
                                shouldComponentUpdateWhenClicked={() => {return beforeDayOfWeekChange(3)}}
                                onClick={() => {onDaysOfWeekChange(3)}}>
                                Wed
                            </Chip>
                        </Grid>
                        <Grid item>
                            <Chip
                                disabled={disabled}
                                width={'4.5em'}
                                secondary
                                initialValue={initialDaysOfWeek.indexOf(4) > -1}
                                selectedIconName={null}
                                shouldComponentUpdateWhenClicked={() => {return beforeDayOfWeekChange(4)}}
                                onClick={() => {onDaysOfWeekChange(4)}}>
                                Thu
                            </Chip>
                        </Grid>
                        <Grid item>
                            <Chip
                                disabled={disabled}
                                width={'4.5em'}
                                secondary
                                initialValue={initialDaysOfWeek.indexOf(5) > -1}
                                selectedIconName={null}
                                shouldComponentUpdateWhenClicked={() => {return beforeDayOfWeekChange(5)}}
                                onClick={() => {onDaysOfWeekChange(5)}}>
                                Fri
                            </Chip>
                        </Grid>
                        <Grid item>
                            <Chip
                                disabled={disabled}
                                width={'4.5em'}
                                secondary
                                initialValue={initialDaysOfWeek.indexOf(6) > -1}
                                selectedIconName={null}
                                shouldComponentUpdateWhenClicked={() => {return beforeDayOfWeekChange(6)}}
                                onClick={() => {onDaysOfWeekChange(6)}}>
                                Sat
                            </Chip>
                        </Grid>
                        <Grid item>
                            <Chip
                                disabled={disabled}
                                width={'4.5em'}
                                secondary
                                initialValue={initialDaysOfWeek.indexOf(0) > -1}
                                selectedIconName={null}
                                shouldComponentUpdateWhenClicked={() => {return beforeDayOfWeekChange(0)}}
                                onClick={() => {onDaysOfWeekChange(0)}}>
                                Sun
                            </Chip>
                        </Grid>
                    </Grid>
                </Grid> : null

            }
            {!hideDatePicker &&
                <Grid item xs={allowCustomScheduleCheckBox ? 12 : 6}>
                    <DatePicker label={"Start Date"}
                                fullWidth={true}
                                initialDate={initialDate}
                                inputVariant={variant}
                                isOutsideRange={(date) => date.isBefore(moment(), 'days')}
                                isDayBlocked={(date) => isDayBlocked(date)}
                                onChange={onDateChange}
                                popoverStartingSide={HorizontalOrigin.LEFT}
                                popoverVerticalAnchorOrigin={VerticalOrigin.TOP}
                                popoverVerticalTransformOrigin={200}
                                ref={datePickerRef}/>
                </Grid>
            }
            {!customScheduleChecked &&
                <Grid item xs={allowCustomScheduleCheckBox ? 12 : 6}>
                    <TimeRangeField onChange={onTimeRangeChange}
                                    initialEndTime={initialEndTime}
                                    initialStartTime={initialStartTime}
                                    label={"Arrival Window: "}/>
                </Grid>
            }
            {
                recurrenceType !== RecurrenceType.DAILY ?
                   <Grid container item xs={12} sm={6}>
                       {
                           recurrenceType === RecurrenceType.MONTHLY || recurrenceType === RecurrenceType.YEARLY ?
                               <Grid item xs={12}>
                                   <Text variant={TextVariant.BODY_1}>
                                       Repeat by
                                   </Text>
                                   <Select fullWidth
                                           ref={dayOfMonthRef}
                                           includeNullOption
                                           onChange={onDayOfMonthChange}
                                           options={repeatByOptions}
                                           type={SelectType.RADIO_BUTTONS}
                                           variant={variant}
                                           primary/>
                                   </Grid>
                               : null
                       }
                   </Grid>
                    :
                    null
            }
        </Grid>
    )
};

RecurringScheduleInputView.defaultProps = {
    direction: FlexDirection.ROW,
    spacing: 4,
    variant: InputBaseVariant.OUTLINED
};

RecurringScheduleInputView.propTypes = {
    beforeDayOfWeekChange: PropTypes.func,  //returns a boolean to determine if a weekday chip should be selectable
    customScheduleChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    handleCustomScheduleWarningDialogOpen: PropTypes.func,
    hideDatePicker: PropTypes.bool,
    initialDate: PropTypes.instanceOf(moment),
    initialDayOfMonth: PropTypes.bool,
    initialDaysOfWeek: PropTypes.arrayOf(PropTypes.number),
    initialStartTime: PropTypes.instanceOf(moment),
    initialEndTime: PropTypes.instanceOf(moment),
    isDayBlocked: PropTypes.func,  //returns a boolean to determine if a day is disabled on the datePicker calendar
    onDateChange: PropTypes.func.isRequired,
    onDaysOfWeekChange: PropTypes.func.isRequired,
    onDayOfMonthChange: PropTypes.func.isRequired,
    onTimeRangeChange: PropTypes.func.isRequired,
    recurrenceType: CustomPropTypes.enum(RecurrenceType),
    title: PropTypes.string,
    variant: CustomPropTypes.enum(InputBaseVariant)
};

export default RecurringScheduleInputView;