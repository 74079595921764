// This file was generated from com.worksmith.cpm.enums.ClientDashboardType
// noinspection JSUnusedGlobalSymbols
const ClientDashboardType = Object.freeze({
    DEFAULT: 'default',
    KENDRA_SCOTT: 'kendra scott',
    BURBERRY: 'burberry',
    HUGO_BOSS: 'hugo boss',
    INTERMIX: 'intermix'
});

export default ClientDashboardType;
