import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PaginatedListView from 'worksmith/components/Pagination/PaginatedListView';

import {AlignItems, Display, JustifyContent} from "../../enums/CSSEnums";
import Grid from "../Grid/Grid.web";
import Text, {TextVariant, TextDisplay} from "../Text/Text.web";
import CircularProgress from "../Loader/CircularProgress";
import Pagination from "./Pagination";
import Box from "@mui/material/Box";
import ButtonEmailModal from "worksmith/composite-components/ButtonEmailModal/ButtonEmailModal";

class PaginatedList extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    changePage = (pageNumber) => {
        // minus 1 is to account for our 0 based indexes in GraphQL and our adjustment in the render
        this.props.onPageChange(pageNumber - 1)
    };



    render() {

        const {
            columns,
            currentPage,
            data,
            getKeyFromItem,
            handleEmailChange,
            handleDownload,
            itemsPerPage,
            loading,
            onClick,
            renderItem,
            showTotalResults,
            showBottomPagination,
            showButtonEmailModal,
            spacing,
            totalElements,
            totalPages,
            variant,
            headerRow,
            titleBorder,
            userEmail
        } = this.props;

        // GraphQL is 0 indexed so we want to adjust the current page so that it can be used with totalPages
        let adjustedCurrentPage = currentPage + 1;

        let startingItem = (adjustedCurrentPage - 1) * itemsPerPage + 1;
        let endingItem = adjustedCurrentPage !== totalPages || totalElements % itemsPerPage === 0 ?
             adjustedCurrentPage * itemsPerPage
            :
            startingItem + (totalElements % itemsPerPage - 1);

        return (
            <Box display={Display.FLEX}>
               <Grid container spacing={spacing}>
                   <Grid item justify={JustifyContent.SPACE_BETWEEN} xs={12} container spacing={0}>
                       <Grid container justify={JustifyContent.SPACE_BETWEEN} item xs={6}>
                       {showTotalResults ?
                       <Text display={TextDisplay.INLINE_BLOCK} variant={TextVariant.H5}>{totalElements} Results</Text>
                           : <Grid item xs={3}/>}
                       {showButtonEmailModal ?
                       <ButtonEmailModal
                           handleDownload={handleDownload}
                           handleEmailChange={handleEmailChange}
                           initialCSVEmail={userEmail}
                       />
                       : null}
                       </Grid>
                       <Grid item xs={6} container justify={JustifyContent.FLEX_END} alignItems={AlignItems.CENTER} spacing={2}>
                               <Pagination totalPages={totalPages}
                                           totalElements={totalElements}
                                           onPageChange={this.changePage}
                                           currentPage={adjustedCurrentPage}
                                           startingItem={startingItem}
                                           endingItem={endingItem}/>
                       </Grid>
                       {headerRow}
                   </Grid>
                   {
                       loading ?
                           <Grid item xs={12}>
                               <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                                   <CircularProgress/>
                               </Box>
                           </Grid>
                           :
                           <PaginatedListView
                               columns={columns}
                               data={data}
                               getKeyFromItem={getKeyFromItem}
                               renderItem={renderItem}
                               onClick={onClick}
                               variant={variant}
                               titleBorder={titleBorder}
                           />
                   }
                   {showBottomPagination(data.length) && !loading ?
                       <Grid item xs={12} container justify={JustifyContent.FLEX_END} alignItems={AlignItems.CENTER}>
                           <Pagination totalPages={totalPages}
                                       totalElements={totalElements}
                                       onPageChange={this.changePage}
                                       currentPage={adjustedCurrentPage}
                                       startingItem={startingItem}
                                       endingItem={endingItem}/>
                       </Grid>
                       : null
                   }
               </Grid>
            </Box>
        );
    }
}

PaginatedList.defaultProps = {
    loading: false,
    showButtonEmailModal: false,
    showTotalResults: true,
    showBottomPagination: () => false,
    spacing: 4
};

// You should be able to directly input the values you used to create make the getPage call in graphQL
// and the values from the response: currentPage, itemsPerPage, totalElements, totalPages
PaginatedList.propTypes = {
    currentPage: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.any),
    getKeyFromItem: PropTypes.func, // (item) => key
    headerRow: PropTypes.element,
    handleEmailChange: PropTypes.func,
    handleDownload: PropTypes.func,
    itemsPerPage: PropTypes.number,   // Take out if using Skeleton
    loading: PropTypes.bool,
    onPageChange: PropTypes.func, // (pageNumber) => {}
    renderItem: PropTypes.func, // (pageNumber) => element
    showTotalResults: PropTypes.bool,
    showBottomPagination: PropTypes.func, // (itemsOnPage) => bool
    showButtonEmailModal: PropTypes.bool, //adds the export CSV button
    spacing: PropTypes.number,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
    titleBorder: PropTypes.bool,
    userEmail: PropTypes.string
};



export default PaginatedList;