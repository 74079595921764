import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "worksmith/components/Grid/Grid";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import {AlignItems, Display, FlexWrap, TextAlign} from "worksmith/enums/CSSEnums";
import Collapse from "worksmith/components/Collapse/Collapse";
import {Color} from "worksmith/enums/Color";
import styled from "styled-components";
import Tabs from "worksmith/components/Tab/Tabs";
import MonthPicker from "worksmith/antd-components/MonthPicker/MonthPicker.web";
import moment from "moment";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import ServiceLevelAgreementType from "worksmith/enums/api/contract/ServiceLevelAgreementType";
import Loader from "worksmith/components/Loader/Loader";
import SLASummaryLine from "worksmith/components/ServiceLevelAgreement/SLASummaryLine";
import SLAComplianceExportModal from "worksmith/components/ServiceLevelAgreement/SLAComplianceExportModal";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip.web";
import SaveAltIcon from "@mui/icons-material/SaveAlt"
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import * as CompliancePage from "worksmith/components/CompliancePage/CompliancePage";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const SLAContainer = styled.div`
    display: ${Display.INLINE_FLEX};
    padding: 24px;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.12);
    background-color: ${Color.WHITE};
`;

const RemedyContainer = styled.div`
    width: 100%;
`;

const Scrollable = styled.div`
    overflow-y: scroll;
    max-height: 8em;
`;

const graphQLService = new GraphQLServiceClass();

const tabs = [
    "Per month",
    "Signed to date"
];

class ServiceLevelAgreement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedRemedy: false,
            serviceLevelAgreements: [],
            requestAcknowledgementSLA: null,
            emergencyRequestScheduling: null,
            emergencyRequestResponse: null,
            recurringJobCompletion: null,
            onDemandRequestCompletion: null,
            loading: true,
            loadingItems: false,
            complianceExportModalOpen: false,
            slaConfig: {},
            showNoncomplianceLinks: true
        };

        this.selectedMonth = this.props.slaPeriodEndDate.endOf('month');
    }

    componentDidMount() {
        let { slaPeriodEndDate } = this.props;
        this.loadSLAData(
            slaPeriodEndDate.startOf('month').format('YYYY-MM-DD'),
            slaPeriodEndDate.endOf('month').format('YYYY-MM-DD'),
        );
    }

    loadSLAData = (startDate, endDate) => {
        const { clientId, slaConfigId } = this.props;
        const searchParams = {clientId: clientId};

        const promises = [
            graphQLService.findAll(searchParams, GraphQLObjectType.SERVICE_LEVEL_AGREEMENT, serviceLevelAgreementObject,
            null, null, {slaItemStartDate: startDate, slaItemEndDate: endDate}, '$slaItemStartDate: String, $slaItemEndDate: String'),
            graphQLService.findOneById(slaConfigId, GraphQLObjectType.SERVICE_LEVEL_AGREEMENT_CONFIG, SLAConfigGraphQLObject)
        ];

        Promise.all(promises).then(([slaData, slaConfig]) => {
            const requestAcknowledgementSLA = slaData.find(sla => {return sla.type === ServiceLevelAgreementType.REQUEST_ACKNOWLEDGEMENT});
            const emergencyRequestScheduling = slaData.find(sla => {return sla.type === ServiceLevelAgreementType.EMERGENCY_REQUEST_SCHEDULING});
            const emergencyRequestResponse = slaData.find(sla => {return sla.type === ServiceLevelAgreementType.EMERGENCY_REQUEST_RESPONSE});
            const recurringJobCompletion = slaData.find(sla => {return sla.type === ServiceLevelAgreementType.RECURRING_JOB_COMPLETION});
            const onDemandRequestCompletion = slaData.find(sla => {return sla.type === ServiceLevelAgreementType.ON_DEMAND_REQUEST_COMPLETION});
            this.setState({
                serviceLevelAgreements: slaData,
                requestAcknowledgementSLA: requestAcknowledgementSLA,
                emergencyRequestScheduling: emergencyRequestScheduling,
                emergencyRequestResponse: emergencyRequestResponse,
                recurringJobCompletion: recurringJobCompletion,
                onDemandRequestCompletion: onDemandRequestCompletion,
                loading: false,
                loadingItems: false,
                slaConfig: slaConfig
            });
        });
    };

    refreshBasedOnNewDate = () => {
        const {loadSLAData} = this;
        this.setState({loadingItems:true},
            loadSLAData(
                this.selectedMonth.startOf('month').format('YYYY-MM-DD'),
                this.selectedMonth.endOf('month').format('YYYY-MM-DD')
            ));
    };

    updateDate = (newDate) => {
        this.selectedMonth = newDate.endOf('month');
    };

    openComplianceExportModal = () => {
        this.setState({complianceExportModalOpen: true})
    };

    closeComplianceExportModal = () => {
        this.setState({complianceExportModalOpen: false})
    };

    getManuallyOrAutomaticallyMarkedCompliant(compliant, resolved){
        return ValueIsSet(resolved) ?
            resolved
            :
            compliant;
    }

    switchTabs = (tabIndex) => {
        const {slaPeriodStartDate, slaPeriodEndDate} = this.props;
        const { loadSLAData } = this;

      if(tabIndex === 1){
          this.setState({loadingItems: true, showNoncomplianceLinks: false},loadSLAData(slaPeriodStartDate.format('YYYY-MM-DD'),slaPeriodEndDate.format('YYYY-MM-DD')));
      } else {
          this.setState({loadingItems: true, showNoncomplianceLinks: true}, loadSLAData(
              moment(this.selectedMonth).startOf('month').format('YYYY-MM-DD'),
              moment(this.selectedMonth).endOf('month').format('YYYY-MM-DD')))
      }
  };


    render() {
        let {updateDate, switchTabs, openComplianceExportModal, closeComplianceExportModal, refreshBasedOnNewDate, getManuallyOrAutomaticallyMarkedCompliant} = this;
        const {expandedRemedy,
            requestAcknowledgementSLA,
            emergencyRequestScheduling,
            emergencyRequestResponse,
            recurringJobCompletion,
            onDemandRequestCompletion,
            loading,
            loadingItems,
            complianceExportModalOpen,
            slaConfig,
            showNoncomplianceLinks
        } = this.state;
        const { slaPeriodStartDate, slaPeriodEndDate, showNoncompliant, clientId } = this.props;


        const requestAcknowledgementNC = requestAcknowledgementSLA && requestAcknowledgementSLA.serviceLevelAgreementItems ?
            requestAcknowledgementSLA.serviceLevelAgreementItems.filter(sla => {return !getManuallyOrAutomaticallyMarkedCompliant(sla.compliant, sla.resolved)}).length : 0;
        const emergencyRequestSchedulingNC = emergencyRequestScheduling && emergencyRequestScheduling.serviceLevelAgreementItems ?
            emergencyRequestScheduling.serviceLevelAgreementItems.filter(sla => {return !getManuallyOrAutomaticallyMarkedCompliant(sla.compliant, sla.resolved)}).length : 0;
        const emergencyRequestResponseNC = emergencyRequestResponse && emergencyRequestResponse.serviceLevelAgreementItems ?
            emergencyRequestResponse.serviceLevelAgreementItems.filter(sla => {return !getManuallyOrAutomaticallyMarkedCompliant(sla.compliant, sla.resolved)}).length : 0;
        const recurringJobCompletionNC = recurringJobCompletion && recurringJobCompletion.serviceLevelAgreementItems ?
            recurringJobCompletion.serviceLevelAgreementItems.filter(sla => {return !getManuallyOrAutomaticallyMarkedCompliant(sla.compliant, sla.resolved)}).length : 0;
        const onDemandRequestCompletionNC = onDemandRequestCompletion && onDemandRequestCompletion.serviceLevelAgreementItems ?
            onDemandRequestCompletion.serviceLevelAgreementItems.filter(sla => {return !getManuallyOrAutomaticallyMarkedCompliant(sla.compliant, sla.resolved)}).length : 0;

        const totalRequestAcknowledgementItems = requestAcknowledgementSLA && requestAcknowledgementSLA.serviceLevelAgreementItems ?
            requestAcknowledgementSLA.serviceLevelAgreementItems.length : 0;
        const emergencyRequestSchedulingItems = emergencyRequestScheduling && emergencyRequestScheduling.serviceLevelAgreementItems ?
            emergencyRequestScheduling.serviceLevelAgreementItems.length : 0;
        const emergencyRequestResponseItems = emergencyRequestResponse && emergencyRequestResponse.serviceLevelAgreementItems ?
            emergencyRequestResponse.serviceLevelAgreementItems.length : 0;
        const recurringJobCompletionItems = recurringJobCompletion && recurringJobCompletion.serviceLevelAgreementItems ?
            recurringJobCompletion.serviceLevelAgreementItems.length : 0;
        const onDemandRequestCompletionItems = onDemandRequestCompletion && onDemandRequestCompletion.serviceLevelAgreementItems ?
            onDemandRequestCompletion.serviceLevelAgreementItems.length : 0;


        return loading ?
            <Loader/> :
            <Fragment>
                {complianceExportModalOpen ?
                <SLAComplianceExportModal
                    open={complianceExportModalOpen}
                    onClose={closeComplianceExportModal}
                    onCancel={closeComplianceExportModal}
                    slaPeriodStartDate={slaPeriodStartDate}
                    slaPeriodEndDate={slaPeriodEndDate}
                    clientId={clientId}
                /> : null}
                <SLAContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Text variant={TextVariant.H6} semiBold>Compliance Rates</Text>
                        </Grid>
                        <Grid item container xs={12} spacing={0}>
                            <Grid container item xs={12} >
                                <Grid item xs={9} alignItems={AlignItems.CENTER} wrap={FlexWrap.NO_WRAP}>
                                    <Text color={TextColor.PRIMARY} variant={TextVariant.SUBTITLE_1}>
                                        Target Compliance Rate: {slaConfig.complianceRate}%</Text>
                                </Grid>
                                <Grid item xs={3} textAlign={TextAlign.CENTER}>
                                    <Tooltip placement={TooltipPlacement.BOTTOM}
                                             leaveDelay={100}
                                             title={"Export compliance data by month"}>
                                    <Button variant={ButtonVariant.TEXT} onClick={openComplianceExportModal} secondary><SaveAltIcon/><Text color={TextColor.SECONDARY} textAlign={TextAlign.CENTER}> Export Compliance Data</Text></Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Text light color={TextColor.PRIMARY_LIGHT} variant={TextVariant.BODY_2}>As per contract terms
                                    the target compliance rate is set for each service level agreement item</Text>
                            </Grid>
                        </Grid>
                        <Grid item container xs={10}>
                            <RemedyContainer>
                                <Collapse
                                    expanded={expandedRemedy}
                                    onExpand={() => this.setState({expandedRemedy: !expandedRemedy})}
                                    label={<Text color={TextColor.PRIMARY}
                                                 variant={TextVariant.SUBTITLE_1}>Remedy</Text>} buttonRight>
                                    <Grid item xs={12}>
                                        <Scrollable>
                                            <Text variant={TextVariant.BODY_1}>
                                                {slaConfig.remedy}
                                            </Text>
                                        </Scrollable>
                                    </Grid>
                                </Collapse>
                            </RemedyContainer>
                        </Grid>
                        <Grid item container xs={12}>
                            <Text color={TextColor.PRIMARY} variant={TextVariant.SUBTITLE_1}>View</Text>
                        </Grid>
                            <Grid item container xs={12}>
                                <Tabs onChangeCallBack={(e) => {switchTabs(e)}} clearBackground titleList={tabs}>
                                    {[<Grid item container xs={12} spacing={2} key={0}>
                                        {moment(slaPeriodEndDate).isBefore(slaPeriodStartDate) ?
                                            <Grid item xs={12}>
                                                <Text variant={TextVariant.SUBTITLE_1}>The monthly data is not available
                                                    yet.</Text>
                                            </Grid> :
                                            <Grid item xs={12}>
                                                <MonthPicker
                                                    isDateDisabled={(date) => !moment(date).isBetween(slaPeriodStartDate, slaPeriodEndDate)}
                                                    onChange={(date) => {
                                                        updateDate(date);
                                                        refreshBasedOnNewDate();
                                                    }}
                                                />
                                            </Grid>
                                        }
                                    </Grid>,
                                    <Grid item container xs={12} spacing={2} key={1}>
                                        {moment(slaPeriodEndDate).isBefore(slaPeriodStartDate) ?
                                            <Grid item xs={12}>
                                                <Text variant={TextVariant.SUBTITLE_1}>The monthly data is not available
                                                    yet.</Text>
                                            </Grid> :
                                            <Grid item xs={12}>
                                                <Text
                                                    variant={TextVariant.SUBTITLE_1}>{slaPeriodStartDate.format('MM/DD/YYYY')} - {slaPeriodEndDate.format('MM/DD/YYYY')}</Text>
                                            </Grid>
                                        }
                                    </Grid>]}
                                </Tabs>
                                    <Grid item container xs={12} spacing={2}>
                                        <SLASummaryLine
                                            slaText={"Worksmith acknowledged receipt within " + requestAcknowledgementSLA.timeFrame + " " + Pluralize(requestAcknowledgementSLA.timeFrame,"hour") + " of request submission."}
                                            nonCompliantCount={requestAcknowledgementNC}
                                            totalCount={totalRequestAcknowledgementItems}
                                            loading={loadingItems}
                                            relatedObjectDescription={"request"}
                                            showNoncompliant={showNoncompliant}
                                            complianceRate={slaConfig.complianceRate}
                                            clientId={clientId}
                                            slaPeriodStartDate={this.selectedMonth.startOf('month').format('YYYY-MM-DD')}
                                            slaPeriodEndDate={this.selectedMonth.endOf('month').format('YYYY-MM-DD')}
                                            slaType={ServiceLevelAgreementType.REQUEST_ACKNOWLEDGEMENT}
                                            showNoncomplianceLinks={showNoncomplianceLinks}
                                            />

                                        <SLASummaryLine
                                            slaText={"The on-demand & recurring jobs occurred within " + onDemandRequestCompletion.timeFrame + " " + Pluralize(onDemandRequestCompletion.timeFrame,"day") + " of the scheduled date."}
                                            nonCompliantCount={recurringJobCompletionNC + onDemandRequestCompletionNC}
                                            totalCount={recurringJobCompletionItems + onDemandRequestCompletionItems}
                                            loading={loadingItems}
                                            relatedObjectDescription={"recurring and on demand job"}
                                            showNoncompliant={showNoncompliant}
                                            complianceRate={slaConfig.complianceRate}
                                            clientId={clientId}
                                            slaPeriodStartDate={this.selectedMonth.startOf('month').format('YYYY-MM-DD')}
                                            slaPeriodEndDate={this.selectedMonth.endOf('month').format('YYYY-MM-DD')}
                                            slaType={CompliancePage.ON_DEMAND_AND_RECURRING_COMPLETION_SLA_COMBO}
                                            showNoncomplianceLinks={showNoncomplianceLinks}
                                        />
                                        {ValueIsSet(emergencyRequestScheduling) ?
                                        <SLASummaryLine
                                            slaText={"Emergency jobs scheduled within " + emergencyRequestScheduling.timeFrame + " " + Pluralize(emergencyRequestScheduling.timeFrame,"hour") + " of request submission."}
                                            nonCompliantCount={emergencyRequestSchedulingNC}
                                            totalCount={emergencyRequestSchedulingItems}
                                            loading={loadingItems}
                                            relatedObjectDescription={"emergency request"}
                                            showNoncompliant={showNoncompliant}
                                            complianceRate={slaConfig.complianceRate}
                                            clientId={clientId}
                                            slaPeriodStartDate={this.selectedMonth.startOf('month').format('YYYY-MM-DD')}
                                            slaPeriodEndDate={this.selectedMonth.endOf('month').format('YYYY-MM-DD')}
                                            slaType={ServiceLevelAgreementType.EMERGENCY_REQUEST_SCHEDULING}
                                            showNoncomplianceLinks={showNoncomplianceLinks}
                                        /> : null}
                                        {ValueIsSet(emergencyRequestResponse) ?
                                            <SLASummaryLine
                                                slaText={"Emergency jobs may be scheduled (received a response) within " + emergencyRequestResponse.timeFrame + " " + Pluralize(emergencyRequestResponse.timeFrame,"hour") + " of request submission."}
                                                nonCompliantCount={emergencyRequestResponseNC}
                                                totalCount={emergencyRequestResponseItems}
                                                loading={loadingItems}
                                                relatedObjectDescription={"emergency request"}
                                                showNoncompliant={showNoncompliant}
                                                complianceRate={slaConfig.complianceRate}
                                                clientId={clientId}
                                                slaPeriodStartDate={this.selectedMonth.startOf('month').format('YYYY-MM-DD')}
                                                slaPeriodEndDate={this.selectedMonth.endOf('month').format('YYYY-MM-DD')}
                                                slaType={ServiceLevelAgreementType.EMERGENCY_REQUEST_RESPONSE}
                                                showNoncomplianceLinks={showNoncomplianceLinks}
                                            /> : null}
                                    </Grid>
                            </Grid>
                    </Grid>
                </SLAContainer>
            </Fragment>

    }
}


const serviceLevelAgreementObject =
    `id
    type
    timeFrame
    serviceLevelAgreementItems(slaItemStartDate: $slaItemStartDate, slaItemEndDate: $slaItemEndDate) {
        id
        compliant
        resolved
    }`;

const SLAConfigGraphQLObject =
    `id
    complianceRate
    minimumSampleSize
    remedy`;

ServiceLevelAgreement.propTypes = {
    clientId: PropTypes.number.isRequired,
    showNoncompliant: PropTypes.bool,
    slaConfigId: PropTypes.number.isRequired,
    slaPeriodEndDate: PropTypes.instanceOf(moment),
    slaPeriodStartDate: PropTypes.instanceOf(moment),
};

export default ServiceLevelAgreement;