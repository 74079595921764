import React from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import {AlignItems, Display, FlexDirection, JustifyContent} from "../../enums/CSSEnums";
import Text, {TextColor, TextVariant} from "../../components/Text/Text.web";
import Rating from "../../components/Rating/Rating";
import Currency from "../../components/Currency/Currency.web";
import BidStatus from "../../enums/api/proposal/BidStatus";
import ProposalHeaderSkeleton from "./ProposalHeaderSkeleton";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {WithContext} from "../../../context/GlobalContext";

const ProposalHeader = (props) => {
    const {
        context,
        additionalApprovalAmount,
        loading,
        isObligation,
        status,
        totalRetailPrice,
        vendorLocation,
        walkthroughStatusBooleans
    } = props;

    if (loading) {
        return (
            <ProposalHeaderSkeleton/>
        )
    }

    let statusTitle = null;
    let statusColor = TextColor.ERROR;
    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;


    if (walkthroughStatusBooleans) {
        statusTitle='pending';
        if(walkthroughStatusBooleans.isInCorporatePricingReview){
            statusTitle='corporate pricing review';
        }
        else if (walkthroughStatusBooleans.isCompleted) {
            statusTitle='completed';
            statusColor = TextColor.PRIMARY;
        } else if (walkthroughStatusBooleans.isScheduling) {
            statusTitle='scheduling';
        } else if (walkthroughStatusBooleans.isRescheduling) {
            statusTitle='rescheduling';
        } else if (walkthroughStatusBooleans.isScheduled) {
            statusTitle='scheduled';
            statusColor = TextColor.PRIMARY;
        }
    } else if (!isObligation) {

        if (status === BidStatus.NEEDS_CLIENT_INPUT) {
            statusTitle='pending';
        } else if (status === BidStatus.PENDING_REGIONAL_MANAGER || status === BidStatus.PENDING_ADMIN) {
            statusTitle='corporate pricing review';
        } else if (status === BidStatus.ACCEPTED) {
            statusTitle='accepted';
            statusColor = TextColor.PRIMARY;
        }
    }

    let pricingComponent =  additionalApprovalAmount ?
        (<Text semiBold variant={TextVariant.SUBTITLE_1}>
            <Currency amount={totalRetailPrice}/> - <Currency amount={totalRetailPrice + additionalApprovalAmount}/>
        </Text>)
        :
        (<Text semiBold variant={TextVariant.SUBTITLE_1}>
            <Currency amount={totalRetailPrice}/>
        </Text>);

    return (
        <Box
            width={'100%'}
            display={Display.FLEX}
            flexDirection={FlexDirection.COLUMN}
            mb={1}>
            <Box
                display={Display.FLEX}
                justifyContent={JustifyContent.SPACE_BETWEEN}>
                <Box>
                    <Text semiBold variant={TextVariant.SUBTITLE_1}>
                        {vendorLocation.vendor.officialName}
                    </Text>
                    <Rating showValue totalCount={vendorLocation.ratingsCount} value={vendorLocation.rating}/>
                </Box>
                <Box
                    display={Display.FLEX}
                    flexDirection={FlexDirection.COLUMN}
                    alignItems={AlignItems.FLEX_END} >
                    {!hidePricingForNonAdminUsers && pricingComponent}
                    {
                       statusTitle ?
                           <Text
                               color={statusColor}
                               semiBold
                               upperCase
                               variant={TextVariant.SUBTITLE_1}>
                               {statusTitle}
                           </Text>
                           :
                           null
                    }
                </Box>
            </Box>
        </Box>
    )
};

ProposalHeader.propTypes = {
    additionalApprovalAmount: PropTypes.number,
    isObligation: PropTypes.bool,
    loading: PropTypes.bool,
    status: PropTypes.string,
    totalRetailPrice: PropTypes.number.isRequired,
    vendorLocation: PropTypes.shape({
        rating: PropTypes.number,
        ratingsCount: PropTypes.number,
        vendor: PropTypes.shape({
            officialName: PropTypes.string,
        })
    }).isRequired,
    walkthroughStatusBooleans: PropTypes.shape({
        isScheduling: PropTypes.bool,
        isScheduled: PropTypes.bool,
        isRescheduling: PropTypes.bool,
        isCompleted: PropTypes.bool,
    })
};

export default WithContext(ProposalHeader);