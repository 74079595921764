import React, {Component} from 'react';

import {WithContext} from "../context/GlobalContext";
import {GraphQLObjectType} from 'worksmith/enums/GraphQLObjectType';
import GraphQLStatisticsService from 'worksmith/services/graphql/GraphQLStatisticsService';
import GraphQLServiceClass from 'worksmith/services/graphql/GraphQLServiceClass';
import DashboardPageView from "./DashboardPageView";
import {RequestStatisticCounts} from "worksmith/enums/RequestStatisticCounts";
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";
import {RequestSortColumns} from "worksmith/enums/RequestSortColumns";
import ClientUserRole from "worksmith/enums/api/user/ClientUserRole";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const graphQLStatisticsService = new GraphQLStatisticsService();
const graphQLService = new GraphQLServiceClass();

class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hideLocationNames: false,
            loading: true,
            noActionRequired: true,
            noAdminActionRequired: true,
            noLocalActionRequired: true,
            requestStatistics: {},
            requestStatisticSections: {},
            outstandingBalance: 0,
            pastDueBalance: 0,
            invoiceDueDate: null
        };
    }

    componentDidMount() {
        let {noActionRequired, noAdminActionRequired, noLocalActionRequired} = this.state;
        let {clientLocations, showCampaigns, userRole} = this.props.context;

        let hideLocationNames = clientLocations.length <= 1 && userRole !== ClientUserRole.ADMIN;

        let requestStatisticSections = {};
        Object.values(RequestStatisticCounts).map(fieldName => {
            requestStatisticSections[fieldName] = {
                loadItems: null,
                loadingSection: false,
                currentPage: -1,
                totalPages: 1,
                requests: []
            };
        });
        requestStatisticSections[RequestStatisticCounts.EMERGENCY].loadItems = () => this.getRequests(RequestStatisticCounts.EMERGENCY, {isEmergency: true, isOpen: true});
        requestStatisticSections[RequestStatisticCounts.CORPORATE_REVIEW].loadItems = () => this.getRequests(RequestStatisticCounts.CORPORATE_REVIEW, {isInCorporateReview: true, isOpen: true});
        requestStatisticSections[RequestStatisticCounts.CORPORATE_PRICING_REVIEW].loadItems = () => this.getRequests(RequestStatisticCounts.CORPORATE_PRICING_REVIEW, {isInCorporatePricingReview: true, isOpen: true});
        requestStatisticSections[RequestStatisticCounts.WALKTHROUGHS_SUBMITTED].loadItems = () => this.getRequests(RequestStatisticCounts.WALKTHROUGHS_SUBMITTED, {hasOpenWalkthrough: true, isOpen: true, isInCorporatePricingReview: false});
        requestStatisticSections[RequestStatisticCounts.ESTIMATES_PENDING_ADMIN].loadItems = () => this.getRequests(RequestStatisticCounts.ESTIMATES_PENDING_ADMIN, {hasEstimatePendingAdminApproval: true, isOpen: true, isInCorporatePricingReview: false});
        requestStatisticSections[RequestStatisticCounts.ESTIMATES_OPEN_NOT_PENDING_ADMIN].loadItems = () => this.getRequests(RequestStatisticCounts.ESTIMATES_OPEN_NOT_PENDING_ADMIN, {hasEstimatePendingAdminApproval: false, isOpen: true});
        requestStatisticSections[RequestStatisticCounts.WALKTHROUGHS_TO_SCHEDULE].loadItems = () => this.getRequests(RequestStatisticCounts.WALKTHROUGHS_TO_SCHEDULE, {hasWalkthroughNeedsClientScheduling: true, isOpen: true});
        requestStatisticSections[RequestStatisticCounts.OPEN_CHANGE_ORDERS].loadItems = () => this.getRequests(RequestStatisticCounts.OPEN_CHANGE_ORDERS, {hasOpenChangeOrder: true});
        requestStatisticSections[RequestStatisticCounts.OPEN_CHANGE_ORDERS_ADMIN].loadItems = () => this.getRequests(RequestStatisticCounts.OPEN_CHANGE_ORDERS_ADMIN, {hasChangeOrderAboveAdminNTE: true});
        requestStatisticSections[RequestStatisticCounts.OPEN_CHANGE_ORDERS_NOT_ADMIN].loadItems = () => this.getRequests(RequestStatisticCounts.OPEN_CHANGE_ORDERS_NOT_ADMIN, {hasChangeOrderAboveAdminNTE: false});
        requestStatisticSections[RequestStatisticCounts.PENDING_BIDS_OR_ESTIMATES].loadItems = () => this.getRequests(RequestStatisticCounts.PENDING_BIDS_OR_ESTIMATES, {hasPendingClientBid: true, isOpen: true, isInCorporatePricingReview: false});
        requestStatisticSections[RequestStatisticCounts.JOBS_TO_SCHEDULE].loadItems = () => this.getRequests(RequestStatisticCounts.JOBS_TO_SCHEDULE, {needsClientScheduling: true, isOpen: true});
        requestStatisticSections[RequestStatisticCounts.UNREAD_MESSAGES].loadItems = () => this.getRequests(RequestStatisticCounts.UNREAD_MESSAGES, {hasUnreadMessageOrComment: true});


        const searchParams = {
            clientLocationIds: clientLocations.map(location => location.id),
            hasCampaign: showCampaigns ? null : false
        };

        graphQLStatisticsService.findStatistics(searchParams, GraphQLObjectType.REQUEST_STATISTICS,
            `
                requestsInCorporateReviewCount
                requestsInCorporatePricingReviewCount
                requestsWithWalkthroughsSubmittedCount
                requestsWithWalkthroughsToScheduleCount
                requestsWithEstimatesPendingAdminApprovalCount
                requestsWithEstimatesOpenAndNotPendingAdminApprovalCount
                requestsWithOpenChangeOrdersAboveAdminNTECount
                requestsWithOpenChangeOrdersBelowAdminNTECount
                requestsWithOpenChangeOrdersCount
                requestsWithPendingBidsOrEstimatesCount
                jobsToScheduleCount
                jobsToRescheduleCount
                recurringScheduleChangesCount
                requestsWithUnreadMessagesCount
                emergencyRequestsCount
                jobsAwaitingSignOffCount
                jobsWithOpenConcernsCount
                jobsCompletedInLastWeek
            `).then(data => {

            Object.keys(data).forEach((key) => {

                if (data[key] > 0) {
                    switch (key){
                        case RequestStatisticCounts.OPEN_CHANGE_ORDERS_ADMIN:
                        case RequestStatisticCounts.ESTIMATES_PENDING_ADMIN:
                            noAdminActionRequired = false;
                            break;
                        case RequestStatisticCounts.OPEN_CHANGE_ORDERS_NOT_ADMIN:
                        case RequestStatisticCounts.ESTIMATES_OPEN_NOT_PENDING_ADMIN:
                            noLocalActionRequired = false;
                            break;
                        case RequestStatisticCounts.WALKTHROUGHS_SUBMITTED:
                        case RequestStatisticCounts.CORPORATE_REVIEW:
                            noAdminActionRequired = false;
                            noActionRequired = false;
                            break;
                        default:
                            noLocalActionRequired = false;
                            noActionRequired = false;
                            break;
                    }
                }

            });
            this.setState({requestStatistics: data, requestStatisticSections, loading: false, noActionRequired, noAdminActionRequired, noLocalActionRequired, hideLocationNames});
        });
    }

    getRequests = (key, extraSearchParams) => {
        let {requestStatisticSections} = this.state;
        let {clientLocations, showCampaigns} = this.props.context;

        let searchParams = {
            clientLocationIds: clientLocations.map(location => location.id),
            hasCampaign: showCampaigns ? null : false
        };

        if (extraSearchParams != null) {
            Object.assign(searchParams, extraSearchParams);
        }

        requestStatisticSections[key].loadingSection = true;
        this.setState({requestStatisticSections}, () => {
            graphQLService.findPage(
                requestStatisticSections[key].currentPage + 1,
                20,
                RequestSortColumns.CREATED_TIMESTAMP,
                GraphQLSortDirection.DESCENDING,
                searchParams,
                GraphQLObjectType.REQUEST,
                key === RequestStatisticCounts.UNREAD_MESSAGES ? RequestGraphQLObjectUnreadMessages : RequestGraphQLObject
            ).then((data) => {
                requestStatisticSections[key].loadingSection = false;
                requestStatisticSections[key].requests = requestStatisticSections[key].requests.concat(data.content);
                requestStatisticSections[key].currentPage = data.currentPage;
                requestStatisticSections[key].totalPages = data.totalPages;
                this.setState({requestStatisticSections});
            }).catch(e => {
                DisplayErrorNotification(e.toString());
            });
        });
    };

    render() {
        const {hideLocationNames,
            requestStatisticSections,
            requestStatistics,
            loading,
            noActionRequired,
            noAdminActionRequired,
            noLocalActionRequired} = this.state;
        const {userRole} = this.props.context;
        const {client} = this.props.context;
        const {storeSignOffEnabled} = this.props;

        return (
            <DashboardPageView
                client={client}
                storeSignOffEnabled={storeSignOffEnabled}
                hideLocationNames={hideLocationNames}
                loading={loading}
                noActionRequired={noActionRequired}
                noAdminActionRequired={noAdminActionRequired}
                noLocalActionRequired={noLocalActionRequired}
                requestStatistics={requestStatistics}
                requestStatisticSections={requestStatisticSections}
                userRole={userRole}/>
        )

    }
}

export const RequestGraphQLObject = `
    createdTimestamp
    approvalRequestedActivity {
        createdTimestamp
        user {
            id
            displayName
        }
    }
    clientLocation {
        id
        title
    }
    serviceLine {
        id
    }
    description
    id
    emergency
    newStatus
    activeWalkthroughs {
        createdTimestamp
    }
    associatedObligationTemplate {
        id
    }
    scheduledDate
    bidSubmittedActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    walkthroughSubmittedActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    bidPricingAcceptedActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    bidAcceptedAndScheduledActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    bidScheduleCounterOfferedActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    pendingChangeOrderRequest {
        createdTimestamp
        changeOrderSubmittedActivity {
            createdTimestamp
            user {
                displayName
            }
        }
    }
    activeBidAndWalkthroughCount
    unreadMessageAndCommentCount
    isRecurring
    serviceLine {
        name
    }
    type
`;

export const RequestGraphQLObjectUnreadMessages = `
    createdTimestamp
    clientLocation {
        id
        title
    }
    serviceLine {
        id
    }
    description
    id
    emergency
    newStatus
    associatedObligationTemplate {
        id
    }
    scheduledDate
    latestUnreadMessage {
        createdTimestamp
        author {
            displayName
        }
    }
    latestUnreadComment {
        createdTimestamp
        user {
            displayName
        }
    }
    activeBidAndWalkthroughCount
    unreadMessageAndCommentCount
    isRecurring
    serviceLine {
        name
    }
    type
`;


export default WithContext(DashboardPage);