import React from 'react';
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer';
import MuiDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Color} from 'worksmith/enums/Color';
import CustomPropTypes from 'worksmith/custom-prop-types/CustomPropTypes';
import {useDeviceSize} from 'worksmith/helpers/StylingHelpers';

export const SwipeableDrawerVariant = Object.freeze({
    PERMANENT: 'permanent',
    PERSISTENT: 'persistent',
    TEMPORARY: 'temporary',
});

export const SwipeableDrawerAnchor = Object.freeze({
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left'
});

const StyledDrawer = styled(MuiDrawer)`
  position: relative;
  box-shadow: 10px 10px rgba(0,0,0,.5);
    .paper {
      background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : Color.WHITE};
      border-radius: ${({borderRadius}) => borderRadius ? borderRadius : null};
      padding: ${({disablePadding}) => disablePadding ? 0 : '1rem'};
      border: none;
      margin: ${({isTablet, margin}) => margin ? margin : isTablet ? null : '5rem 0 0'};
      width: ${({isTablet, width}) => width ? width : isTablet ? null : '350px'};
      height: ${({height}) => height ? height : 'auto'};
      max-width: ${({disablePadding}) => disablePadding ? '100%' : 'calc(100% - 2rem)'};
      z-index: 900;
      overflow-x: hidden;
    }
`;

const StyledSwipeBar = styled.span`
  background-color: ${Color.LIGHT_GREY};
  height: 3px;
  width: 2rem;
  margin: 0 auto 1.5rem auto;
  display: block;
  text-align: center;
`;

const SwipeableDrawer = (props) => {
    let {
        anchor,
        backgroundColor,
        borderRadius,
        children,
        disablePadding,
        elevation,
        height,
        margin,
        ModalProps,
        onClose,
        open,
        showSwipeBar,
        staticDrawer,
        variant,
        width,
    } = props;
    const [isTablet] = useDeviceSize();
    const PaperProps = {elevation}; // applies box-shadow to container
    PaperProps.className = 'paper';

    const drawerProps = {
        anchor: anchor,
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        disablePadding: disablePadding,
        height: height,
        isTablet: isTablet,
        margin: margin,
        ModalProps: ModalProps,
        onClose: onClose,
        open: open,
        PaperProps: PaperProps,
        variant: variant,
        width: width,
    };

    return (
        <StyledDrawer
            as={staticDrawer ? MuiDrawer : MuiSwipeableDrawer}
            {...drawerProps}
        >
            {showSwipeBar ?
                <StyledSwipeBar/>
                :
                null
            }
            {children}
        </StyledDrawer>
    )
};

SwipeableDrawer.propTypes = {
    anchor: CustomPropTypes.enum(SwipeableDrawerAnchor),
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string, // top-left, top-right, bottom-right, bottom-left ex. "10px 10px 0 0" to round only the top or "1rem" for all corners
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    elevation: PropTypes.number,
    height: PropTypes.string,
    ModalProps: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    showSwipeBar: PropTypes.bool,
    staticDrawer: PropTypes.bool,  // will disable swipe and use a fixed drawer
    PaperProps: PropTypes.object,
    variant: CustomPropTypes.enum(SwipeableDrawerVariant),
};

export default SwipeableDrawer;
