import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ConversationView from "./ConversationView";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import RequestForProposalService from "../../services/api/RequestForProposalService";
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";

const requestForProposalService = new RequestForProposalService();
const graphQLService = new GraphQLServiceClass();

class MessageWorksmithConversation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            messages: null
        };
    }

    componentDidMount(){
        const {onMessageRead, requestId} = this.props;

        this.getMessages().then((data) => {
            let messages = data.comments.map((comment) => ({
                    id: comment.id,
                    content: comment.comment,
                    createdTimestamp: comment.createdTimestamp,
                    author: comment.user
                }
            ))
            this.setState({messages: messages, loading: false});
            requestForProposalService.createOrUpdateRead(requestId).then(() => {
                if (onMessageRead) {
                    onMessageRead();
                }
            });
        }).catch(() => {
            this.setState({loading: false});
        })
    }

    getMessages = () => {
        const {requestId} = this.props;

        return graphQLService.findOneById(requestId,
            GraphQLObjectType.REQUEST,
            `
              comments {
                id
                comment
                createdTimestamp
                user {
                  id
                  nameWithRole
                  companyName
                }
              }
            `
        )
    };


    render() {
        const {messages, loading} = this.state;

        return (
            <ConversationView messages={messages}
                              textFieldHidden
                              loading={loading}/>
        )
    }
}

MessageWorksmithConversation.propTypes = {
    requestId: PropTypes.number.isRequired,
    onMessageRead: PropTypes.func,
};

export default MessageWorksmithConversation;