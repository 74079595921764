import React from 'react';
import styled from "styled-components";

import Grid from "worksmith/components/Grid/Grid";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {AlignItems, Display} from "../../CSSEnums";
import {AppStore, GooglePlayStore} from "../../Config";
import {JustifyContent} from "worksmith/enums/CSSEnums";
import {useDeviceSize} from 'worksmith/helpers/StylingHelpers';

const WhiteLabelSplashPage = () => {

    const [isMobile] = useDeviceSize();

    return (
        <Grid container maxWidth={1400} margin={'0 auto'} padding={'10px'} justify={JustifyContent.SPACE_AROUND}>
            <Grid container item xs={12} md={5}>
                <Grid item xs={12} margin={'0 0 20px 0'}>
                    <StyledText variant={TextVariant.H2}>Facility maintenance at your fingertips.</StyledText>
                </Grid>
                <Grid item xs={12} margin={'0 0 60px 0'}>
                    <Text variant={TextVariant.BODY_1}>Welcome to your client portal, powered by Worksmith. Download the mobile app to manage your services and chat directly with your vendor.</Text>
                </Grid>
                <Grid item xs={12} margin={'0 0 20px 0'}>
                    <AppLinks>
                        <a href={'https://itunes.apple.com/us/app/worksmith-vendors/id1444807706?ls=1&mt=8'}
                           target="_blank"
                           style={{'marginRight': '20px'}}>
                            <img style={{height: '40px', width: '120px'}} src={AppStore.image}
                                 alt="Apple Store"/>
                        </a>
                        <a href={'https://play.google.com/store/apps/details?id=com.worksmithmobileapp'}
                           target="_blank"
                        >
                            <img style={{height: '40px', width: '120px'}} src={GooglePlayStore.image}
                                 alt="Google Play Store"/>
                        </a>
                    </AppLinks>
                </Grid>
                <Grid container item xs={12} justify={JustifyContent.FLEX_START}>
                    <Grid item>
                        <Text variant={TextVariant.BODY_1}>Powered By</Text>
                    </Grid>
                    <Grid item onClick={() => window.open('https://worksmith.com/', '_blank')}>
                        <img src={'https://cdn.worksmith.com/logo-with-text-horizontal-navy.png'} alt={'vendor logo'} height={30}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
                <FlexContainer>
                    <img src={'https://cdn.worksmith.com/mobile/ClientWhiteLabelSplash-image.png'} alt={'vendor logo'} height={isMobile ? '400': window.innerHeight}/>
                </FlexContainer>
            </Grid>
        </Grid>
    )
};

const StyledText = styled(Text)`
    width: ${() => window.innerWidth > 1250 ? '600px' : 'auto'};
`;

const AppLinks = styled.div`
    align-items: ${AlignItems.FLEX_END};
    flex: 1;
    display: ${Display.FLEX};
    justify-content: ${JustifyContent.FLEX_START};
`;

const FlexContainer = styled.div`
    margin-top: 20px;
    display: ${Display.FLEX};
    justify-content: ${JustifyContent.CENTER};
    align-items: ${AlignItems.CENTER};
    flex: 1;
`;

export default WhiteLabelSplashPage;