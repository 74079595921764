import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import StoreIcon from '@mui/icons-material/Store';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import AlertDialog, {AlertDialogSize} from "worksmith/components/Dialog/AlertDialog";
import Divider from "worksmith/components/Divider/Divider.web";
import {AlignItems, Display, FlexDirection, TextAlign} from "worksmith/enums/CSSEnums";
import {IconColor} from "worksmith/components/Icon/Icon.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import LinearProgress, {LinearProgressVariant} from "worksmith/components/LinearProgress/LinearProgress";

const ConfirmRequestModal = (props) => {
    const {
        attachmentsUploaded,
        attachmentsUploadError,
        handleCancel,
        handleOk,
        immediatelySubmit,
        requestSubmitError,
        requestSubmitted,
        submitting,
        visible,
    } = props;

    // Because of the addition of duplicateRequestModal this modal should immediately submit the request when visible
    const [submitImmediately, setSubmitImmediately] = useState(immediatelySubmit);
    if(submitImmediately && visible){
        setSubmitImmediately(false)
        handleOk();
    }

    let requestSubmissionStep = (
        <Fragment>
            <StoreIcon
                color={IconColor.SECONDARY}
                style={{fontSize: 50}}/>
            <Text
                variant={TextVariant.H6}
                color={TextColor.SECONDARY}>
                    Submitting Request...
            </Text>
        </Fragment>
    );

    if (requestSubmitted && !attachmentsUploaded && !attachmentsUploadError) {
        requestSubmissionStep = (
            <Fragment>
                <PhotoLibraryIcon
                    color={IconColor.SECONDARY}
                    style={{fontSize: 50}}/>
                <Text
                    variant={TextVariant.H6}
                    color={TextColor.SECONDARY}>
                    Uploading Attachments...
                </Text>
            </Fragment>
        )
    } else if (requestSubmitted && (attachmentsUploaded || attachmentsUploadError)) {
        requestSubmissionStep = (
            <Fragment>
                <CheckCircleOutlineIcon
                    color={IconColor.SECONDARY}
                    style={{fontSize: 50}}/>
                <Text
                    variant={TextVariant.H6}
                    color={TextColor.SECONDARY}>
                    Request Submitted
                </Text>
                {
                    attachmentsUploadError ?
                        <Fragment>
                            <Text
                                align={TextAlign.CENTER}
                                variant={TextVariant.SUBTITLE_1}
                                color={TextColor.ERROR}>
                                There was an issue adding your attachments.
                            </Text>
                            <Text
                                align={TextAlign.CENTER}
                                variant={TextVariant.SUBTITLE_1}
                                color={TextColor.ERROR}>
                                Please try adding your attachments again by clicking Actions > Edit Request and adding photos toward the bottom of the form.
                            </Text>
                        </Fragment>
                        :
                        null
                }
            </Fragment>
        )
    } else if (requestSubmitError) {
        requestSubmissionStep = (
            <Fragment>
                <ErrorOutlineIcon
                    color={IconColor.ERROR}
                    style={{fontSize: 50}}/>
                <Text
                    align={TextAlign.CENTER}
                    variant={TextVariant.SUBTITLE_1}
                    color={TextColor.ERROR}>
                    There was an issue submitting your request.
                </Text>
                <Text
                    align={TextAlign.CENTER}
                    variant={TextVariant.SUBTITLE_1}
                    color={TextColor.ERROR}>
                    Please try again later or contact support.
                </Text>
            </Fragment>
        )
    }

    return(
        <AlertDialog
            acceptDisabled={submitting || requestSubmitted || requestSubmitError}
            acceptText={submitting ? 'Submitting...' : 'Submit' }
            body={
                <Fragment>
                    <Box mb={1} width={{xs: 280, sm: 500, md: 600}}>
                        <Text variant={TextVariant.BODY_1}>
                            {
                                submitting || requestSubmitError || requestSubmitted ?
                                    "This may take several minutes."
                                    :
                                    "Only submit if this is a NEW request (not an update to a previous one)."
                            }
                        </Text>
                    </Box>
                    {
                        submitting || requestSubmitError || requestSubmitted ?
                            <Fragment>
                                <Divider/>
                                <Box
                                    my={1}
                                    py={4}
                                    px={2}
                                    display={Display.FLEX}
                                    flexDirection={FlexDirection.COLUMN}
                                    alignItems={AlignItems.CENTER}>
                                    {requestSubmissionStep}
                                </Box>
                                {
                                    submitting && !(requestSubmitted && attachmentsUploaded) ?
                                        <LinearProgress
                                            secondary
                                            variant={LinearProgressVariant.INDETERMINATE}/>
                                        :
                                        null
                                }
                            </Fragment>
                            :
                            null
                    }
                </Fragment>
            }
            cancelDisabled={submitting || requestSubmitted}
            cancelText={'Back'}
            debounceAccept
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            disablePortal={true}
            onAccept={handleOk}
            onCancel={handleCancel}
            open={visible}
            size={AlertDialog.SMALL}
            title={submitting || requestSubmitError || requestSubmitted ? 'Submitting Request' : 'Submit Request?'}/>
    )
}
ConfirmRequestModal.defaultProps = {
    immediatelySubmit: false
}

ConfirmRequestModal.propTypes = {
    attachmentsUploaded: PropTypes.bool,
    attachmentsUploadError: PropTypes.bool,
    handleCancel: PropTypes.func.isRequired,
    handleOk: PropTypes.func.isRequired,
    immediatelySubmit: PropTypes.bool,
    requestSubmitError: PropTypes.bool,
    requestSubmitted: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default ConfirmRequestModal;