import React from 'react';
import ServiceLineNTEConfiguration from "./ServiceLineNTEConfiguration";
import styled from "styled-components";
import PropTypes from 'prop-types';
import ServiceLineNTEConfigurationSummaryPropType
    from "../utilities/propTypes/businessObjects/nte/ServiceLineNTEConfigurationSummaryPropType";

const Container = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 
    'serviceLineLabel nteLabel'
    'exceptions expandButton'
    'entryGrid entryGrid';
`;

const ServiceLineLabel = styled.div`
    grid-area: serviceLineLabel;
    font-weight: bold;
    text-decoration: underline;
`;

const NteLabel = styled.div`
    grid-area: nteLabel;
    font-weight: bold;
    text-decoration: underline;
`;

const EntryGrid = styled.div`
    grid-area: entryGrid;
`;

const Entry = styled.div`
    margin-bottom: 10px;
`;

//DEPRECATED, DO NOT USE 10/27/22 JS
const NTESettingsListView = (props) => {
    let {configs, loading} = props;

    return (
        loading ?
            'Loading...' :
            <Container>
                <ServiceLineLabel>Service Line </ServiceLineLabel>
                <NteLabel>Nte Limit</NteLabel>
                <EntryGrid>
                    {configs.map((config) => {
                    return (
                        <Entry>
                            <ServiceLineNTEConfiguration config={config}/>
                        </Entry>
                    )
                })}
                </EntryGrid>
            </Container>
    )
};

NTESettingsListView.propTypes = {
    configs: PropTypes.arrayOf([
        ServiceLineNTEConfigurationSummaryPropType
    ]).isRequired,
    loading: PropTypes.bool.isRequired
};

export default NTESettingsListView;