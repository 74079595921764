import React, {Component} from 'react';
import SingleTicket from "../../components/obligations/SingleTicket";
import ObligationService from 'worksmith/services/api/ObligationService';
import ClientService from 'worksmith/services/api/ClientService';
import Loader from "../../components/Loader";

const obligationService = new ObligationService();
const clientService = new ClientService();

class SingleTicketPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            client: props.client,
            ticketId: props.match.match.params.ticketId,
            ticket: {},
            obligationId: props.match.match.params.obligationId,
            obligation: {},
            settings: {},
            clientTicketFieldSettings: {},
        };

        this.handleCanceledTicket = this.handleCanceledTicket.bind(this);
    }

    componentDidMount() {
        let {user, ticketId, obligationId} = this.state;
        let _this = this;

        let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;

        clientService.getSingleDryCleaningFormConfiguration(clientId).then(function (data) {
            _this.setState({ settings: { dryCleaning: data }});

            obligationService.getSummary(obligationId).then(function(data){
                let obligation = data;

                let ticket = obligation.tickets.find(function(ticket) {
                    //Params are read in as strings
                    return ticket.id == ticketId;
                });

                _this.setState({ obligation, ticket, loading: false })
            });
        });

        clientService.getTicketFormOptions(clientId).then(function (fieldSettings) {
              _this.setState({clientTicketFieldSettings: fieldSettings})
        });
    }

    handleCanceledTicket(){
        let {ticketId, obligationId} = this.state;
        let _this = this;

        obligationService.getSummary(obligationId).then(function(data){
            let obligation = data;

            let ticket = obligation.tickets.find(function(ticket) {
                return ticket.id === ticketId;
            });

            _this.setState({ obligation, ticket });
        })
    }

    render() {
        let {user, client, ticket, obligation, settings, clientTicketFieldSettings} = this.state;
        let _this = this;

        return (
            user && client && ticket && Object.keys(ticket).length > 0 && settings && Object.keys(settings).length > 0 && obligation && Object.keys(obligation).length > 0 ?
                <SingleTicket
                    ticket={ticket}
                    settings={settings}
                    printable={false}
                    user={user}
                    isSingleTicket={true}
                    obligation={obligation}
                    client={client}
                    onCancel={() => _this.handleCanceledTicket()}
                    clientTicketFieldSettings={clientTicketFieldSettings}
                />
                : ticket === undefined ? <div>Couldn't find the selected ticket!</div> : <Loader/>
        )
    }
}

export default SingleTicketPage;