import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import styled from 'styled-components';
import {Color} from "../../Enums";

const Card = styled.div`
    text-align: center;
    position: relative;
    background-color: ${Color.WHITE};
    padding: 15px;
    border: 1px solid ${Color.OFF_WHITE};
    margin: 1rem 0;
    height: 300px;
`;

const HeaderText = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

class BurberryLandingPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Row gutter={16}>
                <Col span={8}>
                    <Card>
                        <Link to='/ticketing/ticket-dashboard' ><HeaderText>Dashboard</HeaderText></Link>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Link to='/ticketing/new-ticket' ><HeaderText>New Ticket</HeaderText></Link>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Link to='/ticketing/bulk-shipments' ><HeaderText>Bulk Shipments</HeaderText></Link>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default BurberryLandingPage;
