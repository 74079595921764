import React from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from '@mui/material';
import Box from "@mui/material/Box";

import {Display, FlexDirection} from "../../enums/CSSEnums";
import Grid from "../../components/Grid/Grid.web";
import FieldTitleAndBody from "../FieldTitleAndBody/FieldTitleAndBody";
import Schedule from "../../components/Schedule/Schedule.web";
import PriceBreakdown from "../PriceBreakdown/PriceBreakdown";
import ProposalHeaderSkeleton from "worksmith/composite-components/ProposalHeader/ProposalHeaderSkeleton";
import {withTheme} from "../../components/Theme/ThemeProvider";

const ObligationTemplateDetailsSkeleton = (props) => {
    const theme = useTheme();
    const mobile =  useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box display={Display.FLEX} flexDirection={FlexDirection.COLUMN}>
        <Box display={Display.FLEX} mb={4}>
            <ProposalHeaderSkeleton/>
        </Box>
        <Grid
            container
            spacing={4}
            ItemProps={{
                loading: true,
                mobile: mobile,
                xs:12
            }}>
            <FieldTitleAndBody
                item
                title={'Service Date'}
                body={
                    <Schedule loading={true}/>
                }/>
            <FieldTitleAndBody
                item
                title={'Price Breakdown'}
                body={
                    <PriceBreakdown
                        loading={true}/>
                }/>
        </Grid>
        </Box>
    )
};

export default withTheme(ObligationTemplateDetailsSkeleton);