import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor} from "worksmith/components/Text/Text.web";
import moment from 'moment';
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Table from "worksmith/components/Table/Table.web";
import Paper from "@mui/material/Paper";
import {TextVariant} from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import SingleMetricPieChart from "worksmith/components/DataVisualization/PieChart/SingleMetricPieChart";
import CircularProgress from "@mui/material/CircularProgress";
import {LinearProgressVariant as ProgressBarVariant} from "worksmith/components/LinearProgress/LinearProgress";
import ServiceLevelAgreementType from "worksmith/enums/api/contract/ServiceLevelAgreementType";
import {MomentFormat} from "worksmith/enums/MomentFormat";

const CompliancePageView = (props) => {
    const {loading,
           slaPaginatedItems,
           targetComplianceRate,
           clientName,
           title,
           slaText,
           slaType,
           startDate,
           totalSLAItemCount,
           nonCompliantSLAItemCount,
           currentPageNumber,
           itemsPerPage,
           handleChangePage,
           getJobLinkCell,
           getEditComplianceStatusButtonCell} = props;

    if(loading)
        return <CircularProgress variant={ProgressBarVariant.INDETERMINATE}/>;

    let showVendorColumn = slaType !== ServiceLevelAgreementType.REQUEST_ACKNOWLEDGEMENT;

    let columns = showVendorColumn ?
        [
            {title: "Location", fieldPath: "locationDescription"},
            {title: "Service Line", fieldPath: "request.serviceLine.name"},
            {title: "Vendor", fieldPath: "vendor.nickname"},
            {title: "Jobs", fieldPath: "id"},
            {title: "Status", fieldPath: "id"}
        ]
        :
        [
            {title: "Location", fieldPath: "locationDescription"},
            {title: "Service Line", fieldPath: "request.serviceLine.name"},
            {title: "Jobs", fieldPath: "id"},
            {title: "Status", fieldPath: "id"}
        ];

    let convertToComponentMap = showVendorColumn ?
                                    {3: getJobLinkCell, 4: getEditComplianceStatusButtonCell}
                                    :
                                    {2: getJobLinkCell, 3: getEditComplianceStatusButtonCell};

    let displayAsComponent = showVendorColumn ?
                                [3, 4]
                                :
                                [2, 3];

    let monthAndYear = moment(startDate).format(MomentFormat.FullMonthYear);
    let numberOfJobsToResolve = Math.ceil(nonCompliantSLAItemCount + (targetComplianceRate/100) * totalSLAItemCount - totalSLAItemCount);
    if(numberOfJobsToResolve < 0)
        numberOfJobsToResolve = 0;

    return (
        <div>
            <h1 style={{fontWeight: 500, fontSize: '36px', marginTop: '20px'}}>{clientName}</h1>
            <Paper style={{paddingLeft: "20px"}}>
                <br/>
                <Text style={{paddingTop: '20px'}} bold paragraph variant={TextVariant.H5}>{title}</Text>
                <Text color={TextColor.TEXT_SECONDARY} paragraph>{slaText}</Text>
                <Text color={TextColor.TEXT_SECONDARY} paragraph>Target Compliance Rate: {targetComplianceRate}%</Text>
                <div style={{paddingLeft: '4px'}}>
                    <Grid container alignItems={'flex-start'}>
                        <Grid item xs={2}>
                            <div style={{height: '25px'}}></div>
                            <Text bold>{monthAndYear}</Text>
                            <br/>
                            <Text paragraph>{nonCompliantSLAItemCount}/{totalSLAItemCount} noncompliant jobs</Text>
                        </Grid>
                        <Grid item xs={3}>
                            <SingleMetricPieChart centerText={Math.floor(100 * (1 - nonCompliantSLAItemCount/totalSLAItemCount)) + '%'}
                                                  innerRadius={105}
                                                  width={150}
                                                  metricValue={Math.floor(100 * (1 - nonCompliantSLAItemCount/totalSLAItemCount))}
                                                  warningThreshold={targetComplianceRate}/>
                        </Grid>
                    </Grid>
                </div>

                <Text bold >{numberOfJobsToResolve + " resolved " + Pluralize(numberOfJobsToResolve, "job") + " will reach the target compliance rate"} </Text>
                <br/>

                {
                    ValueIsSet(slaPaginatedItems.content) ?
                        <Table
                            data={slaPaginatedItems.content}
                            columns={columns}
                            rowsPerPage={itemsPerPage}
                            rowsPerPageOptions={[25]}
                            page={currentPageNumber}
                            isPaginated={true}
                            showTopTablePagination={true}
                            totalDataLength={slaPaginatedItems.totalElements}
                            handleChangePage={handleChangePage}
                            displayAsComponent={displayAsComponent}
                            convertToComponentMap={convertToComponentMap}
                            />
                    :
                    null
                }

            </Paper>

        </div>
    )
};

CompliancePageView.propTypes = {
    slaPaginatedItems: PropTypes.object.isRequired,
    targetComplianceRate: PropTypes.number.isRequired,
    clientName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slaText: PropTypes.string.isRequired,
    slaType: PropTypes.string.isRequired,
    onCloseEditComplianceModal: PropTypes.func.isRequired,
    getJobLinkCell: PropTypes.func.isRequired,
    getEditComplianceStatusButtonCell: PropTypes.func.isRequired
};

export default CompliancePageView;