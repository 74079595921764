// This file was generated from com.worksmith.proposal.enums.RfpMarket
// noinspection JSUnusedGlobalSymbols
const RfpMarket = Object.freeze({
    OPEN_NETWORK: 'open market',
    WARRANTY: 'warranty',
    SINGLE_VENDOR: 'single vendor',
    CONTRACT: 'contract',
    MANAGED_MARKETPLACE: 'managed marketplace',
    NONE: 'none'
});

export default RfpMarket;
