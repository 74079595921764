// This file was generated from com.worksmith.reporting.controller.ClientReportingController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ClientReportingService extends Http {
    constructor() {
        super('/reporting/client')
    }
    
    createClientCustomReport(createParams) {
        return this.callApi( `createCustomReport`, POST, createParams, null, null, true);
    }
    
    getAvailableReports(clientId) {
        let params = {
            clientId
        };
    
        return this.callApi( `getAvailableReports`, GET, null, params, null, true);
    }
    
    getReport(params) {
        return this.callApi( `getReport`, POST, params, null, null, true);
    }
    
    
}

export default ClientReportingService;
