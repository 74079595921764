import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import ChangeOrderRequestService from "worksmith/services/api/ChangeOrderRequestService";
import LinearProgress, {LinearProgressVariant} from "worksmith/components/LinearProgress/LinearProgress";
import ChangeOrderComparisonView from "worksmith/components/ChangeOrderRequest/ChangeOrderComparisonView";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import Text from "worksmith/components/Text/Text.web";
import {TextAlign} from "worksmith/enums/CSSEnums";
import AcceptChangeOrderDialog from "worksmith/components/ChangeOrderRequestReview/AcceptChangeOrderDialog";
import DeclineChangeOrderDialog from "worksmith/components/ChangeOrderRequestReview/DeclineChangeOrderDialog";
import DialogCloseButton from "worksmith/components/DialogCloseButton/DialogCloseButton";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const changeOrderService = new ChangeOrderRequestService();
const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();


const ClientChangeOrderDetailsDialog = (props) => {
    const {open, reloadPage, changeOrderRequestId, showWholesalePrice, portalType} = props;

    let [showDeclineChangeOrderDialog, setShowDeclineChangeOrderDialog] = useState(false);
    let [showAcceptChangeOrderDialog, setShowAcceptChangeOrderDialog] = useState(false);
    let [changeOrder, setChangeOrder] = useState(null);
    let [canAcceptChangeOrderRequest, setCanAcceptChangeOrderRequest] = useState(false);
    let [loading, setLoading] = useState(true);
    let [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (open && !ValueIsSet(changeOrder)) {
            fetchChangeOrder();
        }
    }, [open]);

    const fetchChangeOrder = async () => {
        setLoading(true);
        let promises = [];
        promises.push(asyncGraphQLServiceClass.findOneById(changeOrderRequestId,
            GraphQLObjectType.CHANGE_ORDER_REQUEST,
            changeOrderFields));
        promises.push(changeOrderService.canAccept(changeOrderRequestId));

        let [changeOrderResponse, canAcceptChangeOrderRequestResponse] = await Promise.all(promises);
        setChangeOrder(changeOrderResponse);
        setCanAcceptChangeOrderRequest(canAcceptChangeOrderRequestResponse);
        setLoading(false);
    };

    const onClose = () => {
        let {onClose} = props;
        onClose();
        setLoading(true);
        setShowAcceptChangeOrderDialog(false);
        setShowDeclineChangeOrderDialog(false);
        setChangeOrder(null);
    };

    const onAcceptChangeOrder = () => {
        setSubmitting(true);
        changeOrderService.accept(changeOrderRequestId).then(() => {
            DisplaySuccessNotification("Change Order Accepted");
            onClose();
            setSubmitting(false);
            reloadPage();
        }).catch((error) => {
            console.log(error);
            DisplayErrorNotification("Failed to accept change order");
            setSubmitting(false);
            onClose();
        });
    };

    const onDeclineChangeOrder = (declineReason) => {
        changeOrderService.decline(changeOrderRequestId, declineReason).then(() => {
            DisplaySuccessNotification("Change Order Declined");
            onClose();
            reloadPage();
        }).catch((error) => {
            console.log(error);
            DisplayErrorNotification("Failed to decline change order");
            onClose();
        });
    };

    const getButtons = () => {
        if(loading)
            return null;

        let buttons = [];

        // the close button in the top right corner and the decline button appear regardless of the user's approval threshold
        buttons.push(<DialogCloseButton onClick={onClose}/>);
        buttons.push(<Button variant={ButtonVariant.OUTLINED} onClick={() => setShowDeclineChangeOrderDialog(true)}>DECLINE</Button>);

        if(canAcceptChangeOrderRequest)
            buttons.push(<Button variant={ButtonVariant.CONTAINED} primary onClick={() => setShowAcceptChangeOrderDialog(true)}>ACCEPT</Button>);
        else // unshift instead of push because this text needs to show up to the left of the decline button so that the modal doesn't look off center
            buttons.unshift(<Text align={TextAlign.RIGHT} italic>The change order is above your approval threshold. {"\n"}Please message your Admin.</Text>)

        return buttons;
    };

    // this replaces the default supporting text in the ChangeOrderComparisonView if the client user cannot accept the change order
    let cannotAcceptChangeOrderReplacementSupportingText = "A change order has been opened to reflect any additions, omissions or substitutions " +
        "to the original scope of work. The pricing is above your approved threshold and requires authorization from your Admin. You will be " +
        "notified if it has been accepted. Declining will keep your current pricing in place.";

    return (
        <>
            <AlertDialog
                title={"Change Order"}
                open={open && !showDeclineChangeOrderDialog && !showAcceptChangeOrderDialog}
                customButtons={getButtons()}
            >
                {
                    loading ?
                        <LinearProgress primary variant={LinearProgressVariant.INDETERMINATE}/>
                        :
                        <ChangeOrderComparisonView
                            originalScopeOfWork={changeOrder.obligation.scopeOfWork}
                            changedScopeOfWork={changeOrder.changedScopeOfWork}
                            originalLineItems={changeOrder.originalLineItems}
                            changedLineItems={changeOrder.changedLineItems}
                            showWholesalePrice={showWholesalePrice}
                            supportingText={!canAcceptChangeOrderRequest ? cannotAcceptChangeOrderReplacementSupportingText : undefined}
                            portalType={portalType}
                        />

                }
            </AlertDialog>

            <AcceptChangeOrderDialog open={open && showAcceptChangeOrderDialog}
                                     onAcceptChangeOrder={onAcceptChangeOrder}
                                     onCancel={onClose}
                                     submitting={submitting}
            />
            <DeclineChangeOrderDialog open={open && showDeclineChangeOrderDialog} onClose={onClose} onSubmit={onDeclineChangeOrder}/>
        </>
    )
};

const changeOrderFields = `    
    id
    changedAdditionalApprovalAmount
    changedLineItems {
        lineItemType {
            name
        }
        description
        id
        quantity
        unitRetailPrice
        unitWholesalePrice
    }
    changeOrderSubmittedActivity {
        createdTimestamp
        user {
            displayName
        }
    }
    changedScopeOfWork
    createdTimestamp
    obligation {
        id
        vendor {
            nickname
            rating
        }
        scopeOfWork
        isForRecurringService
        obligationDate
    }
    originalLineItems {
        lineItemType {
            name
        }
        description
        id
        quantity
        unitRetailPrice
        unitWholesalePrice
    }
`;

ClientChangeOrderDetailsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    reloadPage: PropTypes.func,
    changeOrderRequestId: PropTypes.number.isRequired,
    showWholesalePrice: PropTypes.bool,
    portalType: CustomPropTypes.enum(PortalType)
};

ClientChangeOrderDetailsDialog.defaultProps = {
    showWholesalePrice: false
};

export default ClientChangeOrderDetailsDialog