import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";
import TextField, {TextFieldSize, TextFieldType} from "../Inputs/TextField/TextField.web";
import Grid from "../Grid/Grid.web";
import Select, {SelectViewSize} from "../Inputs/Select/Select.web";
import Text, {TextVariant} from "../Text/Text.web"
import {Pluralize, RecurrenceTypeToNoun} from "../../helpers/LanguageHelper";
import {ShallowCopy, ValueIsSet} from "../../helpers/GenericHelpers";

class FrequencyPicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            frequency: ValueIsSet(props.initialFrequency) ? props.initialFrequency :
                {
                    recurrenceType: RecurrenceType.DAILY,
                    repeatEvery: 1,
                    recurrenceCount: 1
                },
        };

        this.repeatEveryRef = React.createRef();
    }


    onRecurrenceTypeChange = (newType) => {
        let { repeatEveryRef } = this;
        let { frequency } = this.state;

        if(newType === RecurrenceType.DAILY) repeatEveryRef.current.onChange({target: { value: '1'}});

        let tempFrequency = ShallowCopy(frequency);
        tempFrequency.recurrenceType = newType;
        tempFrequency.recurrenceCount = 1;
        this.setState({frequency: tempFrequency}, () => {
            if (ValueIsSet(this.props.onChange))
                this.props.onChange(this.state.frequency);
        });
    };

    onRepeatEveryChange = (newRepeatEvery) => {
        let { frequency } = this.state;
        let tempFrequency = frequency;
        tempFrequency.repeatEvery = parseInt(newRepeatEvery);
        this.setState({frequency: tempFrequency}, () => {
            if (ValueIsSet(this.props.onChange))
                this.props.onChange(this.state.frequency);
        });
    };

    onRecurrenceCountChange = (newRecurrenceCount) => {
        let { frequency } = this.state;
        let tempFrequency = frequency;
        tempFrequency.recurrenceCount = parseInt(newRecurrenceCount);
        this.setState({frequency: tempFrequency}, () => {
            if (ValueIsSet(this.props.onChange))
                this.props.onChange(this.state.frequency);
        });
    };

    onDisabledClick = () => {
        this.props.handleCustomScheduleWarningDialogOpen(true)
    };

    render() {
        let { onDisabledClick, onRecurrenceTypeChange, onRepeatEveryChange, onRecurrenceCountChange } = this;
        let { frequency } = this.state;
        let { disabled } = this.props;

        const recurrenceOptions = Object.keys(RecurrenceType).map((e) => {
            return {
                value: RecurrenceType[e],
                label: Pluralize(frequency.repeatEvery || 1, RecurrenceTypeToNoun(RecurrenceType[e]))
            }
        });

        return (
            <Fragment>
                <Grid xs={12} container>
                    <Grid container item xs={12}>
                        <Grid container item spacing={2} xs={12} md={8} lg={6}>
                            <Grid item xs={5} md={4} lg={4}>
                                <Text variant={TextVariant.BODY_1}>
                                    Repeat every
                                </Text>
                            </Grid>
                            <Grid item xs={3} md={3} lg={3}>
                                <TextField
                                    disabled={frequency.recurrenceType === RecurrenceType.DAILY || disabled}
                                    initialValue={frequency.repeatEvery}
                                    inputClassName={"frequencyPickerInput"}
                                    minNumericalValue={1}
                                    onChange={onRepeatEveryChange}
                                    onClick={disabled ? onDisabledClick : null}
                                    ref={this.repeatEveryRef}
                                    size={TextFieldSize.SMALL}
                                    type={TextFieldType.NUMBER}
                                />
                            </Grid>
                            <Grid item xs={4} md={5} lg={5}>
                                <Select
                                    disabled={disabled}
                                    initialValue={frequency.recurrenceType}
                                    onChange={onRecurrenceTypeChange}
                                    onClick={disabled ? onDisabledClick : null}
                                    options={recurrenceOptions}
                                    viewSize={SelectViewSize.SMALL}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {frequency.recurrenceType === RecurrenceType.WEEKLY ?
                        <Grid container item xs={12}>
                            <Grid container item spacing={2} xs={12} md={8} lg={6}>
                                <Grid item xs={5} md={4} lg={4}>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <TextField
                                        disabled={disabled}
                                        initialValue={frequency.recurrenceCount}
                                        inputClassName={"frequencyPickerInput"}
                                        maxNumericalValue={7}
                                        minNumericalValue={1}
                                        onChange={onRecurrenceCountChange}
                                        onClick={disabled ? onDisabledClick : null}
                                        size={TextFieldSize.SMALL}
                                        type={TextFieldType.NUMBER}
                                    />
                                </Grid>
                                <Grid item xs={4} md={5} lg={5}>
                                    <Text variant={TextVariant.BODY_1}>
                                        {Pluralize(parseInt(frequency.recurrenceCount),"time")} per week
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>: null
                    }
                </Grid>
            </Fragment>
        )

    }
}

FrequencyPicker.propTypes = {
    disabled: PropTypes.bool,
    initialFrequency: PropTypes.shape({
        recurrenceType: PropTypes.object,
        repeatEvery: PropTypes.number,
        recurrenceCount: PropTypes.number
    }),
    onChange: PropTypes.func,
};

export default FrequencyPicker;