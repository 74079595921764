// This file was generated from com.worksmith.support.controller.SupportTicketController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class SupportTicketService extends Http {
    constructor() {
        super('/supportTickets')
    }
    
    addAttachment(id, attachmentName, attachment) {
        let params = {
            attachmentName
        };
    
        return this.callApi( `${id}/attachments`, POST, null, params, null, true, attachment);
    }
    
    addAttachments(id, attachments) {
        return this.callApi( `${id}/attachments/multiple`, POST, null, null, null, true, attachments);
    }
    
    addCc(id, supportTicketCopy) {
        return this.callApi( `${id}/ccs`, POST, supportTicketCopy, null, null, true);
    }
    
    addComment(id, supportTicketComment) {
        return this.callApi( `${id}/comments`, POST, supportTicketComment, null, null, true);
    }
    
    addTicketToZendesk(supportTicket) {
        return this.callApi( `addTicketToZendesk`, POST, supportTicket, null, null, true);
    }
    
    cancel(id) {
        return this.callApi( `${id}/cancel`, PATCH, null, null, null, true);
    }
    
    close(id) {
        return this.callApi( `${id}/close`, PATCH, null, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findByCommonId(commonId) {
        return this.callApi( `findByCommonId/${commonId}`, GET, null, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getAttachments(id) {
        return this.callApi( `${id}/attachments`, GET, null, null, null, true);
    }
    
    getUserSupportTicketStats(userId) {
        let params = {
            userId
        };
    
        return this.callApi( `marketplaceOperationsStats`, GET, null, params, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    reopen(id) {
        return this.callApi( `${id}/reopen`, PATCH, null, null, null, true);
    }
    
    sendToCorporate(clientId, supportTicket) {
        return this.callApi( `sendToCorporate/${clientId}`, POST, supportTicket, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    
}

export default SupportTicketService;
