export const UNSET = -100;
export const DEFAULT_ADDITIONAL_APPROVAL_AMOUNT = 100;
export const ENTER_KEY_CODE = 13;
export const ESCAPE_KEY_CODE = 27;

export const MAXIMUM_TECHNICIANS_PER_JOB = 5;

export const industryOptions = [
    {label: 'Food & Beverage', value: 'Food & Beverage'},
    {label: 'Health & Wellness', value: 'Health & Wellness'},
    {label: 'Medical', value: 'Medical'},
    {label: 'Retail', value: 'Retail'},
    {label: 'Other', value: 'Other'}
]

export const newTimeBlocks = [
    {
        time: "10:00 AM = 6:00PM",
        days: [{
            closeTime: "18:00",
            openTime: "10:00",
            openHours: "10:00 AM = 6:00PM",
            day: "",
            dayOfWeekInteger: null,
            closed: false
        }]
    },
    {
        time: "9:00 AM = 6:00PM",
        days: [{
            closeTime: "18:00",
            openTime: "09:00",
            openHours: "9:00 AM = 6:00PM",
            day: "",
            dayOfWeekInteger: null,
            closed: false
        }]
    },
    {
        time: "9:30 AM = 6:00PM",
        days: [{
            closeTime: "18:00",
            openTime: "09:30",
            openHours: "9:30 AM = 6:00PM",
            day: "",
            dayOfWeekInteger: null,
            closed: false
        }]
    },
    {
        time: "10:00 AM = 5:00PM",
        days: [{
            closeTime: "17:00",
            openTime: "10:00",
            openHours: "10:00 AM = 5:00PM",
            day: "",
            dayOfWeekInteger: null,
            closed: false
        }]
    },
    {
        time: "9:00 AM = 5:00PM",
        days: [{
            closeTime: "17:00",
            openTime: "09:00",
            openHours: "9:00 AM = 5:00PM",
            day: "",
            dayOfWeekInteger: null,
            closed: false
        }]
    },
    {
        time: "9:30 AM = 5:00PM",
        days: [{
            closeTime: "17:00",
            openTime: "09:30",
            openHours: "9:30 AM = 5:00PM",
            day: "",
            dayOfWeekInteger: null,
            closed: false
        }]
    },    {
        time: "10:30 AM = 5:00PM",
        days: [{
            closeTime: "17:00",
            openTime: "10:30",
            openHours: "10:30 AM = 5:00PM",
            day: "",
            dayOfWeekInteger: null,
            closed: false
        }]
    },
];

export const CompleteJobFormFieldCharacterMinimum = {
    JOB_SUMMARY: 100,
    OTHER_ISSUE: 25
};

export const DefaultObligationCompletionFormConfiguration = {
    showJobSummaryField: true,
    showAssessmentOfIssueField: false,
    assessmentOfIssueFieldRequired: false
};

export const OTHER_ISSUE_OPTION_TEXT = "Other";