// This file was generated from com.worksmith.cpm.controller.ClientLocationController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ClientLocationService extends Http {
    constructor() {
        super('/cpm/clientLocation')
    }

    addAttachments(id, attachments, description) {
        let params = {description};
        return this.callApi( `${id}/attachments/multiple`, POST, null, params, null, true, attachments);
    }
    
    addPreferredVendor(id, vendorLocationId, serviceLineId) {
        let params = {
            vendorLocationId, 
            serviceLineId
        };

        return this.callApi( `${id}/addPreferredVendor`, PATCH, null, params, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }

    deleteAttachments(attachmentIds){
        let params = {
            attachmentIds
        };

        return this.callApi("attachments", DELETE, null, params, null, true);
    }
    
    find(clientId) {
        let params = {
            clientId
        };
    
        return this.callApi( ``, GET, null, params, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByClientId(clientId) {
        let params = {
            clientId
        };
    
        return this.callApi( `findAllByClientId`, GET, null, params, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findAllSimple(name, clientIds) {
        let params = {
            name, 
            clientIds
        };
    
        return this.callApi( `findAllLocationsSimpleByIds`, GET, null, params, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findOneAdminFullObject(id) {
        return this.callApi( `findOneAdminFullObject/${id}`, GET, null, null, null, true);
    }
    
    getAllServicedMalls() {
        return this.callApi( `malls`, GET, null, null, null, true);
    }
    
    getClientLocationQuestions() {
        return this.callApi( `getLocationQuestions`, GET, null, null, null, true);
    }
    
    getClientLocationsTotalOverview() {
        return this.callApi( `getClientLocationsTotalOverview`, GET, null, null, null, true);
    }
    
    getEstimates(id, status) {
        let params = {
            status
        };
    
        return this.callApi( `${id}/estimates`, GET, null, params, null, true);
    }
    
    getLocationAttributes(id) {
        return this.callApi( `getLocationQuestionAttributes/${id}`, GET, null, null, null, true);
    }
    
    getLocationHours(id) {
        return this.callApi( `${id}/hours`, GET, null, null, null, true);
    }
    
    getPreferredVendorLocation(id, serviceLineId) {
        let params = {
            serviceLineId
        };
    
        return this.callApi( `${id}/preferredVendorLocations`, GET, null, params, null, true);
    }
    
    getPreferredVendorLocationTicketSummaries(id, serviceLineId) {
        let params = {
            serviceLineId
        };
    
        return this.callApi( `${id}/preferredVendorTicketSummaries`, GET, null, params, null, true);
    }
    
    getPreferredVendorSummaries(id, serviceLineId, ticketingOnly) {
        let params = {
            serviceLineId, 
            ticketingOnly
        };
    
        return this.callApi( `${id}/preferredVendorSummaries`, GET, null, params, null, true);
    }
    
    getStandardPricingsByServiceLine(id, serviceLineId, vendorLocationId) {
        let params = {
            serviceLineId, 
            vendorLocationId
        };
    
        return this.callApi( `${id}/standardPricings`, GET, null, params, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    removeMallAssociation(id) {
        return this.callApi( `${id}/removeMallAssociation`, POST, null, null, null, true);
    }
    
    removePreferredVendor(id, preferredVendorId) {
        let params = {
            preferredVendorId
        };
    
        return this.callApi( `${id}/removePreferredVendor`, PATCH, null, params, null, true);
    }
    
    searchLightAdminSummary(params) {
        return this.callApi( `searchLightAdminSummary`, POST, params, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    updateAttributes(clientLocationId, attributes) {
        return this.callApi( `${clientLocationId}/updateAttributes`, POST, attributes, null, null, true);
    }

    uploadFromSheet(listOfFiles, clientId, numberOfLinesToSkip){
        let params = {
            clientId,
            numberOfLinesToSkip
        };
        return this.callApi(`uploadFromSheet`, POST, null, params, null, true, listOfFiles);
    }
    
    writeToClientLocationPricingBreakdown() {
        return this.callApi( `writeToClientLocationPricingBreakdown`, POST, null, null, null, true);
    }
    
    
}

export default ClientLocationService;
