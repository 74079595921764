// This file was generated from com.worksmith.contract.controller.ServiceLevelAgreementItemController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ServiceLevelAgreementItemService extends Http {
    constructor() {
        super('/serviceLevelAgreementItem')
    }
    
    createServiceLevelAgreementItem(params) {
        return this.callApi( ``, POST, params, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteServiceLevelAgreementItem(id) {
        return this.callApi( `${id}`, DELETE, null, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getServiceLevelAgreementItem(id) {
        return this.callApi( `${id}`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    updateServiceLevelAgreementItem(id, params) {
        return this.callApi( `${id}`, PATCH, params, null, null, true);
    }
    
    
}

export default ServiceLevelAgreementItemService;
