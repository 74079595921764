export const RequestStatisticCounts = Object.freeze({
    EMERGENCY:                              "emergencyRequestsCount",
    CORPORATE_REVIEW:                       'requestsInCorporateReviewCount',
    CORPORATE_PRICING_REVIEW:               'requestsInCorporatePricingReviewCount',
    WALKTHROUGHS_SUBMITTED:                 'requestsWithWalkthroughsSubmittedCount',
    WALKTHROUGHS_TO_SCHEDULE:               'requestsWithWalkthroughsToScheduleCount',
    ESTIMATES_PENDING_ADMIN:                'requestsWithEstimatesPendingAdminApprovalCount',
    ESTIMATES_OPEN_NOT_PENDING_ADMIN:       'requestsWithEstimatesOpenAndNotPendingAdminApprovalCount',
    OPEN_CHANGE_ORDERS_ADMIN:               'requestsWithOpenChangeOrdersAboveAdminNTECount',
    OPEN_CHANGE_ORDERS_NOT_ADMIN:           'requestsWithOpenChangeOrdersBelowAdminNTECount',
    OPEN_CHANGE_ORDERS:                     'requestsWithOpenChangeOrdersCount',
    PENDING_BIDS_OR_ESTIMATES:              'requestsWithPendingBidsOrEstimatesCount',
    JOBS_TO_SCHEDULE:                       'jobsToScheduleCount',
    JOBS_TO_RESCHEDULE:                     "jobsToRescheduleCount",
    RECURRING_SCHEDULE_CHANGES:             "recurringScheduleChangesCount",
    UNREAD_MESSAGES:                        "requestsWithUnreadMessagesCount",
    JOBS_AWAITING_SIGN_OFF:                 "jobsAwaitingSignOffCount",
    JOBS_WITH_OPEN_CONCERNS:                "jobsWithOpenConcernsCount",
    JOBS_COMPLETED_IN_LAST_WEEK:            "jobsCompletedInLastWeek",
});
