import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import AsyncGraphQLServiceClass from "../../../../services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import RequestStateLayout from "../RequestStateLayout";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import moment from "moment";
import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import UserAffiliationType from "../../../../enums/api/user/UserAffiliationType";

const graphQLServiceClass = new AsyncGraphQLServiceClass();

class Canceled extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            request: ValueIsSet(props.request) ? DeepCopy(props.request) : null
        };
    }

    async componentDidMount() {
        const {requestId} = this.props;

        if (requestId) {
            let data = await this.getRequestFromId(requestId);
            this.setState({loading: false, request: data});
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, request: data});
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            newState.request = DeepCopy(props.request);
        }

        return newState;
    };

    getRequestFromId = async (requestId) => {

        return await graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            CanceledGraphQLString)
    };

    render() {
        const {request, loading} = this.state;
        const {userId} = this.props;

        let description = '';

        if (!loading && ValueIsSet(request.canceledActivity)) {
            if (userId === request.canceledActivity.user.id) {
               description = `You canceled this request on ${moment(request.canceledActivity.createdTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)} (ET). `;
            } else {
                let displayName = request.canceledActivity.user.affiliationType === UserAffiliationType.WORKSMITH ?
                    request.canceledActivity.user.displayName + " (Worksmith)"
                    :
                    request.canceledActivity.user.displayName;

                description = `This request was canceled by ${displayName} on ${moment(request.canceledActivity.createdTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)} (ET). `;
            }
        }

        let cancellationReasons = null;
        if (!loading && ValueIsSet(request.cancellationReasons)) {
            cancellationReasons = request.cancellationReasons.flatMap(r => r.description).join();
        }

        return (
            <Fragment>
                <RequestStateLayout description={description}
                                    loading={loading || this.props.loading}
                                    status={TitleCase(NewRequestStatus.CANCELED)}
                                    statusColor={TextColor.PRIMARY}/>
                {
                    loading || !cancellationReasons ?
                        null
                        :
                        <Grid container maxWidth={800} spacing={3}>
                            <Grid container item spacing={1}>
                                <Grid item xs={12}>
                                    <Text variant={TextVariant.BODY_1}>
                                        {'Cancellation Reason: ' + cancellationReasons}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                }
            </Fragment>

        )
    }
}

Canceled.propTypes = {
    loading: PropTypes.bool,
    request: PropTypes.shape({
        canceledActivity: PropTypes.shape({
            createdTimestamp: PropTypes.string,
            user: PropTypes.shape({
                id: PropTypes.number,
                displayName: PropTypes.string,
                affiliationType: CustomPropTypes.enum(UserAffiliationType)
            })
        }),
        cancellationReasons: PropTypes.shape({
            id: PropTypes.number,
            description: PropTypes.string
        })
    }),
    requestId: PropTypes.number,
    userId: PropTypes.number
};

const CanceledGraphQLString = `
    canceledActivity {
        createdTimestamp
        user {
            id
            displayName
            affiliationType
        }
    },
    cancellationReasons {
        id
        description
    }
`;

export default Canceled;