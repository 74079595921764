import React, {useEffect, useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types'

import GeneralInfoForm from "./GeneralInfoForm";
import GeneralInfoMallModal from "./GeneralInfoMallModal";
import GeneralInfoView from "./GeneralInfoView";

import AddressPropType from 'worksmith/custom-prop-types/AddressPropType';
import {AlignItems, JustifyContent} from 'worksmith/enums/CSSEnums';
import {Color} from 'worksmith/enums/Color';
import Accordion from 'worksmith/components/Accordion/Accordion';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Text, {TextVariant} from "worksmith/components/Text/Text";

import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientLocationService from "worksmith/services/api/ClientLocationService";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {Country} from "worksmith/enums/api/country/Country";

const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const clientLocationService = new ClientLocationService();

const GeneralInfoPanel = ({location, mallsList, readOnly}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [generalInfoFormFields, setGeneralInfoFormFields] = useState({});
    const [mallModalOpen, setMallModalOpen] = useState(false);
    const isSelfService = ValueIsSet(location.client) &&  ValueIsSet(location.client.isSelfService) ? location.client.isSelfService : false;

    const mallOptions = mallsList ? mallsList.map(mall => ({
        label: mall.name,
        value: {
            ...mall
        }
    })) : [];

    let formFields = {
        id: location.id ? location.id : '',
        title: generalInfoFormFields.title ? generalInfoFormFields.title: '',
        locationCode: generalInfoFormFields.locationCode ? generalInfoFormFields.locationCode : '',
        openingDate: generalInfoFormFields.openingDate ? generalInfoFormFields.openingDate : null,
        industry: generalInfoFormFields.industry ? generalInfoFormFields.industry : null,
        otherIndustry: generalInfoFormFields.otherIndustry ? generalInfoFormFields.otherIndustry : null,
        phone: generalInfoFormFields.phone ? generalInfoFormFields.phone : null,
        noPhone: generalInfoFormFields.phone ? true : false,
        email: generalInfoFormFields.email ? generalInfoFormFields.email : null,
        country: generalInfoFormFields.country ? generalInfoFormFields.country : null,
        currency: generalInfoFormFields.currency ? generalInfoFormFields.currency : null,
        addressLineOne: generalInfoFormFields.addressLineOne ? generalInfoFormFields.addressLineOne : '',
        addressLineTwo: generalInfoFormFields.addressLineTwo ? generalInfoFormFields.addressLineTwo : '',
        city: generalInfoFormFields.city ? generalInfoFormFields.city : '',
        state: generalInfoFormFields.state ? generalInfoFormFields.state : '',
        zip: generalInfoFormFields.zip ? generalInfoFormFields.zip : '',
        mall: generalInfoFormFields.mall ? generalInfoFormFields.mall : null,
        isInMall: generalInfoFormFields.isInMall,
        isShopInShop: generalInfoFormFields.isShopInShop,
    };

    const refetchGeneralData = async () => {
        const generalData = await asyncGraphQLServiceClass.findOneById(location.id, GraphQLObjectType.CLIENT_LOCATION, generalFields);
        setGeneralInfoFormFields({
            id: generalData.id ? generalData.id : '',
            title: generalData.title ? generalData.title: '',
            locationCode: generalData.locationCode ? generalData.locationCode : '',
            openingDate: generalData.openingDate ? moment(generalData.openingDate).format(MomentFormat.MonthDayYearSlash) : '',
            industry: generalData.industry ? generalData.industry : '',
            otherIndustry: generalData.otherIndustry ? generalData.otherIndustry : '',
            phone: generalData.phone ? generalData.phone : '',
            noPhone: !generalData.phone,
            email: generalData.email ? generalData.email : '',
            country: generalData.country ? Country[generalData.country] : '',
            currency: generalData.currency ? generalData.currency : '',
            addressLineOne: generalData.addressLineOne ? generalData.addressLineOne : '',
            addressLineTwo: generalData.addressLineTwo ? generalData.addressLineTwo : '',
            city: generalData.city ? generalData.city : '',
            state: generalData.state ? generalData.state : '',
            zip: generalData.zip ? generalData.zip : '',
            mall: generalData.mall ? generalData.mall : null,
            isInMall: generalData.isInMall,
            isShopInShop: generalData.isShopInShop
        });
        setIsEditing(false);
    };

    const hasPhoneNumberInfo = generalInfoFormFields.phone || generalInfoFormFields.phone === ""

    const generalInfoComplete =
        generalInfoFormFields.title &&
        generalInfoFormFields.locationCode &&
        generalInfoFormFields.openingDate &&
        generalInfoFormFields.industry &&
        hasPhoneNumberInfo &&
        generalInfoFormFields.email &&
        generalInfoFormFields.addressLineOne &&
        generalInfoFormFields.city &&
        generalInfoFormFields.state &&
        generalInfoFormFields.zip;

    const submitGeneralInfo = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        let submitData = {
            id: formFields.id,
            title: formFields.title,
            locationCode: formFields.locationCode,
            openingDate: generalInfoFormFields.openingDate,
            industry: formFields.industry,
            otherIndustry: generalInfoFormFields.industry === 'Other' ? formFields.otherIndustry : null,
            phone: generalInfoFormFields.noPhone ? "" : formFields.phone,
            email: formFields.email,
            country: formFields.country,
            currency: formFields.currency,
            addressLineOne: formFields.addressLineOne,
            addressLineTwo: formFields.addressLineTwo,
            city: formFields.city,
            state: formFields.state,
            zip: formFields.zip,
            mall: {
                id: formFields.mall ? formFields.mall.id : null
            },
            isShopInShop: generalInfoFormFields.isShopInShop,
            isInMall: generalInfoFormFields.isInMall,
        };

        try {
            await clientLocationService.update(submitData);
            await refetchGeneralData();
            setIsSubmitting(false);
        } catch (err) {
            console.error(err);
            setIsEditing(false);
            setIsSubmitting(false);
        }
    };

    const closeModal = () => {
        setMallModalOpen(false)
        setIsEditing(false);
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleChange = (formField, value) => {
        let current = { // this keeps the uncontrolled form fields from being overwritten
            title: formFields.title,
            locationCode: formFields.locationCode,
            openingDate: formFields.openingDate,
            industry: formFields.industry,
            otherIndustry: formFields.otherIndustry,
            phone: formFields.phone,
            email: formFields.email,
            country: formFields.country,
            currency: formFields.currency,
            addressLineOne: formFields.addressLineOne,
            addressLineTwo: formFields.addressLineTwo,
            city: formFields.city,
            state: formFields.state,
            zip: formFields.zip,
            mall: formFields.mall,
            isInMall: formFields.isInMall,
            noPhone: formFields.noPhone,
            isShopInShop: formFields.isShopInShop
        };

        if (formField === 'openingDate') {
          formFields[formField] = moment(value).format(MomentFormat.ISODate)
        } else {
            formFields[formField] = value
        }

        if (formField === 'mall' && value && value.name !== '+ Add New Mall') {
            setGeneralInfoFormFields({
                ...generalInfoFormFields,
                mall: value
            })
        }
        if (formField === 'industry' || formField === 'isInMall' || formField === 'noPhone' || formField === 'isShopInShop' || formField === 'openingDate') {
            setGeneralInfoFormFields({
                ...current,
                [formField]: value,
            })
        }
        if (value && value.name === '+ Add New Mall') {
            setMallModalOpen(true)
        }
    };

    useEffect(() => {
        formFields.mall = generalInfoFormFields.mall;
        formFields.isShopInShop = generalInfoFormFields.isShopInShop
    }, [generalInfoFormFields]);

    useEffect(() => {
        if (location) {
            setGeneralInfoFormFields({
                id: location.id ? location.id : '',
                title: location.title ? location.title : '',
                locationCode: location.locationCode ? location.locationCode : '',
                openingDate: location.openingDate ? moment(location.openingDate).format(MomentFormat.MonthDayYearSlash) : '',
                industry: location.industry ? location.industry : '',
                otherIndustry: location.otherIndustry ? location.otherIndustry : '',
                phone: location.phone ? location.phone : '',
                noPhone: !location.phone,
                email: location.email ? location.email : '',
                country: location.country ? Country[location.country] : '',
                currency: location.currency ? location.currency : '',
                addressLineOne: location.addressLineOne ? location.addressLineOne : '',
                addressLineTwo: location.addressLineTwo ? location.addressLineTwo : '',
                city: location.city ? location.city : '',
                state: location.state ? location.state : '',
                zip: location.zip ? location.zip : '',
                mall: location.mall ? location.mall : null,
                isInMall: location.isInMall,
                isShopInShop: location.isShopInShop
            })
        }
    }, [location]);


    const panelSummary = (
        <Grid container>
            <Grid container item xs={2} md={1} alignItems={AlignItems.CENTER}>
                <Icon name={IconType.INFO} />
            </Grid>
            <Grid container item xs={9} md={10} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H5}>
                    {"General Information"}
                </Text>
            </Grid>
            <Grid container item xs={1} alignItems={AlignItems.CENTER} justify={JustifyContent.CENTER}>
                {!readOnly ? <Icon name={generalInfoComplete ? IconType.CHECK_CIRCLE_OUTLINED : IconType.REPORT_PROBLEM_OUTLINED} iconColor={generalInfoComplete ? Color.GREEN : Color.RED} /> : null}
            </Grid>
        </Grid>
    );

    return (
            <>
                <Accordion
                    summary={panelSummary}
                    details={isEditing && !readOnly ?
                        <GeneralInfoForm
                            generalInfoFormFields={generalInfoFormFields}
                            handleChange={handleChange}
                            mallOptions={mallOptions}
                            isSubmitting={isSubmitting}
                            isSelfService={isSelfService}
                            submitGeneralInfo={submitGeneralInfo}
                            toggleEdit={toggleEdit}
                        />
                        :
                        <GeneralInfoView
                            generalInfoFormFields={generalInfoFormFields}
                            readOnly={readOnly}
                            toggleEdit={toggleEdit}
                            location={location}
                            isSelfService={isSelfService}
                        />
                    }
                    large
                />
                <GeneralInfoMallModal
                    closeModal={closeModal}
                    locationId={location.id}
                    mallModalOpen={mallModalOpen}
                    refetchGeneralData={refetchGeneralData}
                />
            </>
    )
};

export default GeneralInfoPanel;

GeneralInfoPanel.propTypes = {
    readOnly: PropTypes.bool,
    mallsList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string,
        propertyOwner: PropTypes.string,
        mallDirectory: PropTypes.string,
        requirements: PropTypes.string,
        coi: PropTypes.string,
        address: AddressPropType
    })),
    location: PropTypes.shape({
        addressLineOne: PropTypes.string.isRequired,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string.isRequired,
        client: PropTypes.shape({
            adminUsers: PropTypes.arrayOf(PropTypes.shape({
                clientRole: PropTypes.string,
                companyName: PropTypes.string,
                displayName: PropTypes.string,
                email: PropTypes.string.isRequired,
                phone: PropTypes.string,
                clientLocationRoles: PropTypes.arrayOf(PropTypes.shape({
                    clientLocation: PropTypes.shape({
                        locationCode: PropTypes.string,
                        client: PropTypes.shape({
                            officialName: PropTypes.string.isRequired
                        }).isRequired
                    })
                })).isRequired
            })),
            id: PropTypes.number.isRequired,
            isSelfService: PropTypes.bool.isRequired
        }).isRequired,
        clientLocationAttachments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            singleUseUrl: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })),
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
        email: PropTypes.string,
        industry: PropTypes.string,
        otherIndustry: PropTypes.string,
        isShopInShop: PropTypes.bool,
        isInMall: PropTypes.bool,
        mall: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            phone: PropTypes.string,
            propertyOwner: PropTypes.string,
            mallDirectory: PropTypes.string,
            requirements: PropTypes.string,
            coi: PropTypes.string,
            address: PropTypes.string
        }),
        openingDate: PropTypes.string,
        regionalManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        locationManagers: PropTypes.arrayOf(PropTypes.shape({
            clientRole: PropTypes.string,
            companyName: PropTypes.string,
            displayName: PropTypes.string,
            email: PropTypes.string.isRequired,
            phone: PropTypes.string
        })),
        clientLocationOperationDetails: PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            unionLaborRequirement: PropTypes.string,
            occurrenceInsuranceRequirement: PropTypes.number,
            aggregateInsuranceRequirement: PropTypes.number,
            additionalInsuranceDetails: PropTypes.string,
            locationSquareFootage: PropTypes.number,
            hasLadderOnSite: PropTypes.bool,
            ladderHeightFt: PropTypes.number,
            parkingDirections: PropTypes.string,
            serviceEntranceDescription: PropTypes.string,
            additionalAccessInfo: PropTypes.string
        }),
        locationHours: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            dayOfWeekInteger: PropTypes.number,
            openTime: PropTypes.string,
            closeTime: PropTypes.string,
            closed: PropTypes.bool,
        })),
        id: PropTypes.number,
        locationCode: PropTypes.string,
        phone: PropTypes.string,
        state: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired
    })
};

const generalFields = `
    addressLineOne
    addressLineTwo
    city
    country
    currency
    email
    industry
    otherIndustry
    isShopInShop
    mall {
        id
        name
        phone
        propertyOwner
        mallDirectory
        requirements
        internalNotes
        coi
        address
    }
        isInMall
    openingDate
    id
    locationCode
    phone
    state
    title
    zip
`;