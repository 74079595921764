import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Debounce} from "../../helpers/GenericHelpers";
import TextField from "../../components/Inputs/TextField/TextField";
import AsyncGraphQLServiceClass from "../../services/graphql/AsyncGraphQLServiceClass";
import Text, {TextVariant} from "../../components/Text/Text.web";
import Box from "@mui/material/Box";
import Grid from "../../components/Grid/Grid.web";

class RaiseConcernForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            valid: false
        };

        this.concernDescription = '';
    }

    onRaiseConcernChange = (value) => {
        const {valid} = this.state;

        this.concernDescription = value;
        let newValid = value.trim() !== '';

        if (valid !== newValid) {
            this.setState({valid: newValid}, this.onChange);
        } else {
            this.onChange();
        }
    };


    onChange = () => {
        const {valid} = this.state;

        this.props.onChange({
            concernRaised: this.concernDescription,
            valid
        });
    };

    render() {
        let {onRaiseConcernChange} = this;
        const {
            loading,
            spacing
        } = this.props;

        let debouncedOnChange = Debounce(onRaiseConcernChange, 50);

        return (
            <Grid container spacing={spacing}>
                <Grid item xs={12}>
                    <Text variant={TextVariant.BODY_1}>
                        Please explain any concerns you had with this job. We will notify our team and be in touch soon.
                    </Text>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        label={'Description'}
                        multiline
                        onChange={debouncedOnChange}/>
                </Grid>
            </Grid>
        )
    }
}

RaiseConcernForm.defaultProps = {
    loading: false,
    spacing: 2
};

RaiseConcernForm.propTypes = {
    loading: PropTypes.bool,
    spacing: PropTypes.number,
    onChange: PropTypes.func.isRequired // ({concernDescription, valid}) => {}
};

export default RaiseConcernForm;