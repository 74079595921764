import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from "worksmith/components/Grid/Grid";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import {Color} from 'worksmith/enums/Color';
import SinglePriceOverview, {SinglePriceOverviewVariant} from "./SinglePriceOverview";

const WarningMessageContainer = styled.div`
    background-color: ${props => props.backgroundColor};
    padding: ${props => props.padding ? '1em' : 0};
`;

const PricingSection = styled.div`
    margin-top: 4em;
    margin-bottom: 2em;
`;

const InvoicesPricingOverviewView = (props) => {
    const {
        availableCredit,
        isPaused,
        loading,
        outstandingBalance,
        pastDueBalance
    } = props;

    let warningMessage;
    let warningFontColor;
    let warningBackgroundColor;
    let padding;

    if(pastDueBalance > 0 && isPaused) {
        warningMessage         = 'Your Worksmith Account has been paused and all related services have been put on hold. ';
        warningFontColor       = TextColor.WHITE;
        warningBackgroundColor = Color.RED;
        padding                = true;
    } else if (pastDueBalance > 0 && !isPaused) {
        warningMessage         = 'Your Account and all related services will be paused if payment is not received. ';
        warningFontColor       = TextColor.WHITE;
        warningBackgroundColor = Color.PRIMARY;
        padding                = true;
    } else {
        warningMessage         = 'Please ensure payment by the due date to avoid pausing of services. ';
        warningFontColor       = TextColor.DEFAULT;
        warningBackgroundColor = Color.TRANSPARENT;
        padding                = false;
    }

    return (
        <Fragment>
            <Text variant={TextVariant.H5}>
                Invoices
            </Text>

            <PricingSection>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <SinglePriceOverview amount={pastDueBalance}
                                             loading={loading}
                                             variant={SinglePriceOverviewVariant.PAST_DUE}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SinglePriceOverview amount={outstandingBalance}
                                             loading={loading}
                                             variant={SinglePriceOverviewVariant.OUTSTANDING}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SinglePriceOverview amount={availableCredit}
                                             loading={loading}
                                             variant={SinglePriceOverviewVariant.AVAILABLE}/>
                    </Grid>
                </Grid>
            </PricingSection>

            <WarningMessageContainer backgroundColor={warningBackgroundColor} padding={padding}>
                <Text renderSkeleton={loading} color={warningFontColor} variant={TextVariant.H6}>
                    {warningMessage}
                    Invoices will incur a 1.5% late fee for every 30 days they remain outstanding.
                </Text>
            </WarningMessageContainer>
        </Fragment>
    )
};

InvoicesPricingOverviewView.propTypes = {
    availableCredit:    PropTypes.number,
    isPaused:           PropTypes.bool,
    loading:            PropTypes.bool.isRequired,
    outstandingBalance: PropTypes.number,
    pastDueBalance:     PropTypes.number,
};

export default InvoicesPricingOverviewView;