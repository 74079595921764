import React from 'react'
import moment from "moment";
import PropTypes from 'prop-types';

import {getDayOfWeekStringFromNumber, getRecurringString} from "worksmith/helpers/LanguageHelper";
import Grid from "worksmith/components/Grid/Grid.web";
import {JustifyContent} from "worksmith/enums/CSSEnums";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import TimeRangeField from "worksmith/components/TimeRangeField/TimeRangeField";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";

const CustomScheduleDialogView = ({
                                  addingRecurringScheduleJob,
                                  date,
                                  dayOfMonth,
                                  daysOfWeek,
                                  daysOfWeekNumber,
                                  handleAddScheduleChange,
                                  onScheduleChange,
                                  recurrenceType,
                                  repeatEvery,
                                  schedule,
                              }) => {


    const initialStartTime = moment('10:00 AM', MomentFormat.StandardTime);
    const initialEndTime = moment('6:00 PM', MomentFormat.StandardTime);

    const recurrenceTypeString = getRecurringString(date, recurrenceType, repeatEvery, dayOfMonth, daysOfWeek);

    const onTimeRangeChange = (startTime, endTime, dayOfWeekIndex, index) => {
        ValueIsSet(schedule.customScheduleArrivalWindows[index].arrivalStartTime)
            ? schedule.customScheduleArrivalWindows[index] = {dayOfWeek: dayOfWeekIndex.dayOfWeek, arrivalStartTime: startTime.format(MomentFormat.HoursMinutes), arrivalEndTime: endTime.format(MomentFormat.HoursMinutes)}
            : schedule.customScheduleArrivalWindows[index] = {dayOfWeek: dayOfWeekIndex, arrivalStartTime: startTime.format(MomentFormat.HoursMinutes), arrivalEndTime: endTime.format(MomentFormat.HoursMinutes)};
        if (addingRecurringScheduleJob) {
            handleAddScheduleChange(schedule.customScheduleArrivalWindows, "customScheduleArrivalWindows")
        } else {
            onScheduleChange(schedule, 0)
        }
    };

    // Arrival Window Unknown functionality may be re-added in the future
    // const handleArrivalWindowUnknown = (value) => {
    //     let newSchedule = {
    //         ...schedule,
    //         allDay: value,
    //     };
    //     onScheduleChange(newSchedule, 0)
    // };

    return <>
        <Grid container width={"500px"} justify={JustifyContent.CENTER}>
            <Grid item xs={12} margin={'0 0 40px 0'}>
                <Text variant={TextVariant.BODY_1}>
                    Selected Frequency: {recurrenceTypeString}
                </Text>
            </Grid>
            { schedule.customScheduleArrivalWindows ? schedule.customScheduleArrivalWindows.sort((dayA, dayB) => dayA.dayOfWeek.number ? dayA.dayOfWeek.number - dayB.dayOfWeek.number : dayA.dayOfWeek - dayB.dayOfWeek).map((day, index) => {
                    return (
                        <Grid container xs={12} key={index}>
                            <Grid item xs={12} margin={'0 0 30px 0'}>
                                <Text variant={TextVariant.H6} gutterBottom>
                                    {ValueIsSet(day.dayOfWeek.number) ? getDayOfWeekStringFromNumber(day.dayOfWeek.number) : getDayOfWeekStringFromNumber(day.dayOfWeek)}
                                </Text>
                                <TimeRangeField onChange={(startTime, endTime) => onTimeRangeChange(startTime, endTime, day, index)}
                                                initialEndTime={ValueIsSet(day.arrivalEndTime) ? moment(day.arrivalEndTime, MomentFormat.StandardTime) : initialEndTime}
                                                initialStartTime={ValueIsSet(day.arrivalStartTime) ? moment(day.arrivalStartTime, MomentFormat.StandardTime) : initialStartTime}
                                                label={"Arrival Window: "}/>
                            </Grid>
                            {/*Arrival Window Unknown functionality may be re-added in the future*/}
                            {/*<Grid item xs={6}>*/}
                            {/*    <Checkbox*/}
                            {/*        checked={ValueIsSet(schedule.allDay) ? schedule.allDay : false}*/}
                            {/*        label={*/}
                            {/*            <>*/}
                            {/*                <StyledTooltip title={"Events without start and end times will be displayed as all day events on the calendar"}>*/}
                            {/*                    <Text display={TextDisplay.INLINE}>Arrival Window Unknown</Text>*/}
                            {/*                    <Icon*/}
                            {/*                        iconColor={Color.MEDIUM_GREY}*/}
                            {/*                        name={IconType.HELP_OUTLINE}*/}
                            {/*                        right*/}
                            {/*                    />*/}
                            {/*                </StyledTooltip>*/}
                            {/*            </>*/}
                            {/*        }*/}
                            {/*        onChange={value => handleArrivalWindowUnknown(value)}*/}
                            {/*        primary*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>
                    )
                })
                :
                daysOfWeekNumber.sort().map((day, index) => {
                    return (
                        <Grid container xs={12} key={index}>
                            <Grid item xs={12} margin={'0 0 30px 0'}>
                                <Text variant={TextVariant.H6} gutterBottom>
                                    {getDayOfWeekStringFromNumber(day)}
                                </Text>
                                <TimeRangeField onChange={(startTime, endTime) => onTimeRangeChange(startTime, endTime, day, index)}
                                                initialEndTime={initialEndTime}
                                                initialStartTime={initialStartTime}
                                                label={"Arrival Window: "}/>
                            </Grid>
                            {/*Arrival Window Unknown functionality may be re-added in the future*/}
                            {/*<Grid item xs={6}>*/}
                            {/*    <Checkbox*/}
                            {/*        checked={ValueIsSet(schedule.allDay) ? schedule.allDay : false}*/}
                            {/*        label={*/}
                            {/*            <>*/}
                            {/*                <StyledTooltip title={"Events without start and end times will be displayed as all day events on the calendar"}>*/}
                            {/*                    <Text display={TextDisplay.INLINE}>Arrival Window Unknown</Text>*/}
                            {/*                    <Icon*/}
                            {/*                        iconColor={Color.MEDIUM_GREY}*/}
                            {/*                        name={IconType.HELP_OUTLINE}*/}
                            {/*                        right*/}
                            {/*                    />*/}
                            {/*                </StyledTooltip>*/}
                            {/*            </>*/}
                            {/*        }*/}
                            {/*        onChange={value => handleArrivalWindowUnknown(value)}*/}
                            {/*        primary*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>
                    )
                })}
        </Grid>
    </>;
};

// Arrival Window Unknown functionality may be re-added in the future
// const StyledTooltip = styled(Tooltip)`
//   && {
//     display: flex;
//     align-items: center;
//   }
// `;

CustomScheduleDialogView.propTypes = {
    addingRecurringScheduleJob: PropTypes.bool,
    date: PropTypes.string,
    dayOfMonth: PropTypes.string,
    daysOfWeek: PropTypes.array,
    daysOfWeekNumber: PropTypes.array,
    handleAddScheduleChange: PropTypes.func,
    onScheduleChange: PropTypes.func,
    recurrenceType: PropTypes.string,
    repeatEvery: PropTypes.number,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string.isRequired,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
};

export default CustomScheduleDialogView;