import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientContactPhoneNumbersView from "worksmith/composite-components/ClientVendorConversation/ClientContactPhoneNumbersView";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CircularProgress from "worksmith/components/Loader/CircularProgress";

const graphQLService = new AsyncGraphQLServiceClass();

const LoaderContainer = styled.div`
    position: absolute;
    right: 50%;
`;

const ClientContactPhoneNumbers = (props) => {
    const {clientLocationId, displayEmailAddresses, linkToClientPage} = props;
    const [locationUserData, setLocationUserData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getContactUsers() {
            const locationUserData = await graphQLService.findOneById(clientLocationId,
                GraphQLObjectType.CLIENT_LOCATION,
                clientLocation);

            locationUserData.client.adminUsers =
                [];
            locationUserData.regionalManagers = locationUserData.regionalManagers ?
                locationUserData.regionalManagers.filter((user) => ValueIsSet(user.phone))
                :
                [];
            locationUserData.locationManagers = locationUserData.locationManagers ?
                locationUserData.locationManagers.filter((user) => ValueIsSet(user.phone))
                :
                [];
            setLocationUserData(locationUserData);
            setLoading(false);
        }
        getContactUsers();
    }
    ,
    []);

    return (
        loading ?
            <LoaderContainer>
                <CircularProgress style={{marginLeft: '50%'}}/>
            </LoaderContainer>
            :
            <ClientContactPhoneNumbersView locationPhone={locationUserData.phone}
                                           locationEmail={locationUserData.email}
                                           adminUsers={locationUserData.client.adminUsers}
                                           regionalUsers={locationUserData.regionalManagers}
                                           localUsers={locationUserData.locationManagers}
                                           displayEmailAddresses={displayEmailAddresses}
                                           isForNonWorksmithClient={ValueIsSet(locationUserData.client.vendor)}
                                           clientId={locationUserData.client.id}
                                           linkToClientPage={linkToClientPage}/>
    )
};

const clientLocation = `
    phone
    email
    client {
        id
        adminUsers {
            name 
            phone
            email
        }
        vendor {
            id
        }
    }
    regionalManagers {
        name
        phone
        email
    }
    locationManagers {
        name
        phone
        email
    }
`;

ClientContactPhoneNumbers.propTypes = {
    clientLocationId: PropTypes.number.isRequired,
    displayEmailAddresses: PropTypes.bool,
    linkToClientPage: PropTypes.func
};

ClientContactPhoneNumbers.defaultProps = {
    displayEmailAddresses: false
};

export default ClientContactPhoneNumbers