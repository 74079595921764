import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/fn/string/includes';
import 'react-stripe-elements';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { ConfigProvider } from 'antd' ;
import enUS from 'antd/lib/locale-provider/en_US';
import 'font-awesome/css/font-awesome.min.css';
import {LocalStorageKeys} from "./shared/enums/Enums";
import PortalType from "./shared/enums/api/user/PortalType";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-NFQKPMF'
};

TagManager.initialize(tagManagerArgs);

localStorage.setItem(LocalStorageKeys.PORTAL_TYPE, PortalType.CLIENT);
localStorage.setItem(LocalStorageKeys.BASE_API_URL, process.env.REACT_APP_ENV);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <ConfigProvider locale={enUS}>
        <App/>
    </ConfigProvider>
);
registerServiceWorker();

