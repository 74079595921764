import React from 'react';
import PropTypes from 'prop-types';
import MaterialIconButton from '@mui/material/IconButton';

import {PickColor} from "worksmith/helpers/ThemeHelpers";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Icon from "../Icon/Icon.web";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {withTheme} from '../Theme/ThemeProvider';
import {Link} from 'react-router-dom';
import {FunctionalColor} from "worksmith/enums/Color";

export const IconButtonSize = Object.freeze({
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
    DEFAULT: 'default',
});

const IconButton = React.forwardRef((props, ref) => {
    let {
        component,
        disabled,
        disablePadding,
        disableFocusRipple,
        disableRipple,
        href,
        iconColor,
        iconName,
        onClick,
        onMouseLeave,
        onMouseOver,
        primary,
        routerLink,
        secondary,
        size
    } = props;

    return (
        <MaterialIconButton color={PickColor(primary, secondary)}
                            component={routerLink ? Link : component}
                            className={props.className}
                            disabled={disabled}
                            disableFocusRipple={disableFocusRipple}
                            disableRipple={disableRipple}
                            href={href}
                            onMouseLeave={onMouseLeave}
                            onMouseOver={onMouseOver}
                            onClick={onClick}
                            to={routerLink}
                            ref={ref}
                            style={disablePadding ? {padding: "0px"} : null}>
            {ValueIsSet(iconName) ?
                <Icon name={iconName} iconColor={iconColor} fontSize={size}/>
                :
                props.children
            }
        </MaterialIconButton>
    )
});

IconButton.defaultProps = {
    disablePadding: false
};

IconButton.propTypes = {
    component: PropTypes.string,
    disabled: PropTypes.bool,
    disablePadding: PropTypes.bool,
    disableRipple: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    href: PropTypes.string,
    iconColor: CustomPropTypes.enum(FunctionalColor),
    iconName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func,                   //this allows support for wrapping buttons with Tooltips
    onMouseOver: PropTypes.func,                    //this allows support for wrapping buttons with Tooltips
    primary: PropTypes.bool,
    routerLink: PropTypes.string, // if path passed in, will allow button to serve as react router link
    secondary: PropTypes.bool,
    size: CustomPropTypes.enum(IconButtonSize)
};

export default  withTheme(IconButton);
