import RequestTargetType from "../enums/api/proposal/RequestTargetType";
import moment from "moment";
import RecurrenceType from "../enums/api/task/RecurrenceType";
import {ValueIsSet} from "./Helpers";

/*
 Pass this function a number, the singular of a word and the plural of that word.
 If the number is equal to 1, this function will return the singular word, else the plural word.
 If no plural word parameter is given, it simply adds an s to the end of the singular word
*/
export const Pluralize = (number, singularWord, pluralWord) => {
    if (number === 1) {
        return singularWord;
    }
    if (!ValueIsSet(pluralWord)) {
        return singularWord + "s";
    }
    return pluralWord;
};

export const RequestTargetString = (target, format) => {
    return getTargetPrefix(target) + " " + moment(target.date).format(format ? format : 'ddd MM/DD/YYYY')
};

function getTargetPrefix(target) {
    switch (target.type) {
        case RequestTargetType.BY_DATE:
            return "By";
        case RequestTargetType.ON_DATE:
            return "On";
    }
}

export const ConvertToDurationString = (duration) => {
    let durationString = '';
    let hours = Math.floor(duration / 60);
    let minutes = duration % 60;

    if (hours === 1)
        durationString += hours + ' hour ';
    else if (hours > 1)
        durationString += hours + ' hours ';
    if (hours > 0 && minutes > 0)
        durationString += ' and ';
    if (minutes > 0)
        durationString += minutes + ' minutes';

    return durationString;
};

export const RecurrenceTypeToNoun = (recurrenceType) => {
    switch (recurrenceType) {
        case RecurrenceType.DAILY:
            return 'day';
        case RecurrenceType.WEEKLY:
            return 'week';
        case RecurrenceType.MONTHLY:
            return 'month';
        case RecurrenceType.YEARLY:
            return 'year';
    }
};

export const FrequencyString = (frequency) => {

    let {recurrenceType, repeatEvery, recurrenceCount} = frequency;

    let recurrenceSentence = '';

    const recurrenceNoun = RecurrenceTypeToNoun(recurrenceType);

    if(repeatEvery === 1 && recurrenceType === RecurrenceType.DAILY) return 'Daily';

    if (recurrenceCount != null && recurrenceCount === 1 && repeatEvery === 1)
        recurrenceSentence += "Once a " + recurrenceNoun;

    if (recurrenceCount != null && recurrenceCount !== 1)
        if (recurrenceCount === 2)
            recurrenceSentence += "Twice a " + recurrenceNoun;
        else
            recurrenceSentence += recurrenceCount + " times a " + recurrenceNoun;

    if (repeatEvery > 1) {
        if (recurrenceCount != null && recurrenceCount !== 1)
            recurrenceSentence += ", every ";
        else
            recurrenceSentence += "Every ";

        recurrenceSentence += repeatEvery + " " + recurrenceNoun + "s";
    }

    return recurrenceSentence.toString();
};