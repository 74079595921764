import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import ChangeOrderRequestModalView from "./ChangeOrderRequestModalView";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import SubmitChangeOrderConfirmationModal
    from "worksmith/components/ChangeOrderRequest/SubmitChangeOrderConfirmationModal";
import PortalType from "worksmith/enums/api/user/PortalType";

class ChangeOrderRequestModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            acceptDisabled: true,
            showConfirmationModal: false,
        };

        this.viewRef = React.createRef();
    }

    disableAccept = () => {
        const {acceptDisabled} = this.state;

        if(!acceptDisabled)
            this.setState({acceptDisabled: true});
    };

    enableAccept = () => {
        const {acceptDisabled} = this.state;

        if(acceptDisabled)
            this.setState({acceptDisabled: false});
    };

    onHideConfirmation = () => {
        this.setState({showConfirmationModal: false});
    };

    onShowConfirmation = () => {
        if(this.viewRef.current.onValidate())
            this.setState({showConfirmationModal: true});
    };

    onSubmit = () => {
        const {onHideConfirmation, viewRef} = this;
        const {onClose, onCompletion} = this.props;
        this.setState({acceptDisabled: true});

        if(ValueIsSet(viewRef.current))
            viewRef.current.onSubmit();

        onHideConfirmation();
        onClose();
        if(ValueIsSet(onCompletion))
            onCompletion();
    };

    render() {
        const {
            disableAccept,
            enableAccept,
            onHideConfirmation,
            onShowConfirmation,
            onSubmit,
            viewRef} = this;
        const {
            acceptDisabled,
            showConfirmationModal
        } = this.state;
        const {
            isEdit,
            obligationId,
            onClose,
            open,
            reloadPage,
            overrideClientApproval,
            showSuccessErrorModal,
            portalType,
        } = this.props;

        return (
            <Fragment>
                <ChangeOrderRequestModalView acceptDisabled={acceptDisabled}
                                             disableAccept={disableAccept}
                                             enableAccept={enableAccept}
                                             isEdit={isEdit}
                                             obligationId={obligationId}
                                             onAccept={onShowConfirmation}
                                             onCancel={onClose}
                                             onClose={onClose}
                                             open={open}
                                             ref={viewRef}
                                             reloadPage={reloadPage}
                                             overrideClientApproval={overrideClientApproval}
                                             showSuccessErrorModal={showSuccessErrorModal}
                                             portalType={portalType}
                />
                <SubmitChangeOrderConfirmationModal acceptDisabled={acceptDisabled}
                                                    onAccept={onSubmit}
                                                    onCancel={onHideConfirmation}
                                                    open={showConfirmationModal}
                                                    overrideClientApproval={overrideClientApproval}/>
            </Fragment>
        )
    }
}

ChangeOrderRequestModal.defaultProps = {
    isEdit: false,
    showSuccessErrorModal: true
};

ChangeOrderRequestModal.propTypes = {
    isEdit:       PropTypes.bool,
    obligationId: CustomPropTypes.stringOrNumber.isRequired,
    onClose:      PropTypes.func.isRequired,
    onCompletion: PropTypes.func,
    open:         PropTypes.bool.isRequired,
    reloadPage:   PropTypes.func,
    overrideClientApproval: PropTypes.bool,
    portalType: CustomPropTypes.enum(PortalType)
};

export default ChangeOrderRequestModal;