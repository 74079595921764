import React, {Component, Fragment} from 'react';
import t from 'tcomb-form';
import moment from 'moment';
import UserService from 'worksmith/services/api/UserService';
import ClientService from 'worksmith/services/api/ClientService';
import ObligationService from 'worksmith/services/api/ObligationService';
import ClientLocationService from 'worksmith/services/api/ClientLocationService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import Loader from "../../Loader";
import Button from "../../Button";
import DatePicker from 'antd/lib/date-picker';
import notification from 'antd/lib/notification';
import {ObligationType, ServiceLine, TicketMetadata} from "../../../Enums";
import WorksmithSelect from "../../WorksmithSelect";
import Input from "antd/lib/input";
import {InputNumber} from "antd";
import {WithContext} from "../../../context/GlobalContext";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import PropTypes from "prop-types";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import StandardPricingService from "worksmith/services/api/StandardPricingService";

const {TextArea} = Input;
const Form = t.form.Form;
const userService = new UserService();
const clientService = new ClientService();
const clientLocationService = new ClientLocationService();
const obligationService = new ObligationService();
const authTokenManager = new AuthTokenManager();
const standardPricingService = new StandardPricingService();


class NewServiceForm extends Component {
    constructor(props) {
        super(props);

        let today = moment();

        this.state = {
            user: {},
            value: {
                pickupDate: [today.format('YYYY'), today.format('M') - 1, today.format('DD')],
                returnDate: [today.format('YYYY'), '', '']
            },
            locations: [],
            locationsList: [],
            locationsMap: {},
            preferredVendorsSize: 0,
            serviceLineSelectList: {},
            preferredVendorMap: {},
            availableVendors: {},
            loading: true,
            pickupWindows: {
                1: "8AM - 10AM",
                2: "10AM - 12PM",
                3: "12PM - 2PM",
                4: "2PM - 4PM"
            },
            pickupWindowObjects: {
                1: {
                    startTime: "08:00:00",
                    endTime: "10:00:00"
                },
                2: {
                    startTime: "10:00:00",
                    endTime: "12:00:00"
                },
                3: {
                    startTime: "12:00:00",
                    endTime: "14:00:00"
                },
                4: {
                    startTime: "14:00:00",
                    endTime: "16:00:00"
                }
            },
            submitting: false,
            hideSelectServiceLineField: false,
        };

        this.submit = this.submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.handleServiceLineChange = this.handleServiceLineChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        let _this = this;
        userService.findOne(authTokenManager.getUserId()).then(function (data) {
            let user = data;
            _this.setState({user});

            let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;

            if(user.clientRoles){
                clientService.getLocations(clientId).then(function (data) {
                    let locations = data;
                    let locationsToObject;
                    let locationsList;
                    let locationsMap;
                    if (locations.length) {
                        locationsList = locations.map((l) => {return {id: l.id, title: l.title}});
                        locationsToObject = locations.reduce(function (map, obj) {
                            map[obj.id] = obj.title;
                            return map;
                        }, {});
                        locationsMap = locations.reduce((map, obj) => {
                            map[obj.id] = obj;
                            return map;
                        }, {});

                        _this.setState({locations: locationsToObject, locationsList, locationsMap, loading: false});
                    }
                })
            } else if(user.clientLocationRoles){
                userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId()).then(function(data){
                    let locations = data;
                    let locationsToObject;
                    let locationsMap;
                    if (locations.length) {
                        locationsToObject = locations.reduce(function (map, obj) {
                            map[obj.id] = obj.title;
                            return map;
                        }, {});
                        locationsMap = locations.reduce((map, obj) => {
                            map[obj.id] = obj;
                            return map;
                        }, {});

                        _this.setState({locations: locationsToObject, locationsMap, loading: false});
                    }
                })
            }


        });
    }

    handleServiceLineChange(value){
        let {serviceLineSelectList, preferredVendorMap} = this.state;

        let today = moment();
        if (serviceLineSelectList[value.serviceLine] === ServiceLine.COURIER_SERVICES) {
            value.pickupDate = {
                0: today.format('YYYY'),
                1: today.format('MM'),
                2: today.format('DD'),
                date: [
                    today.format('YYYY'),
                    today.format('MM'),
                    today.format('DD')
                ]
            };
        } else if (value.serviceLine != null) {
            value.pickupDate = [today.format('YYYY'), today.format('M') - 1, today.format('DD')];
        }

        let availableVendors = preferredVendorMap[value.serviceLine];

        this.setState({ availableVendors, value });
    }

    onLocationSelect = (location) => {
        let _this = this;
        let {value} = this.state;

        if(value.location !== location) {
            value.location = location;

            if(location) {
                _this.setState({ loading : true });
                clientLocationService.getPreferredVendorSummaries(location, null).then(function (data) {
                    let preferredVendors = data.filter(preferredVendor => preferredVendor.serviceLine.useTicketing || preferredVendor.serviceLine.id === 37);
                    let preferredVendorsSize = preferredVendors.length;
                    let serviceLineSelectList = {};
                    let serviceLineMap = {};
                    let preferredVendor = null;
                    let preferredVendorMap = { };
                    let preferredVendorLocationMap = { };

                    preferredVendors.forEach(function(preferredVendor) {


                        let serviceLineId = preferredVendor.serviceLine.id;
                        let vendorLocationId = preferredVendor.vendorLocation.id;

                        serviceLineSelectList[serviceLineId] = preferredVendor.serviceLine.name;
                        serviceLineMap[serviceLineId] = preferredVendor.serviceLine;

                        if(!preferredVendorMap.hasOwnProperty(serviceLineId)) {
                            preferredVendorMap[serviceLineId] = {};
                            preferredVendorLocationMap[serviceLineId] = {};
                        }

                        preferredVendorMap[serviceLineId][vendorLocationId] = preferredVendor.vendorLocation.vendor.name;
                        preferredVendorLocationMap[serviceLineId][vendorLocationId] = preferredVendor.vendorLocation;
                    });

                    _this.setState({ preferredVendorsSize, serviceLineSelectList, serviceLineMap, preferredVendor, preferredVendorMap, preferredVendorLocationMap, loading: false }, () => {
                        if(preferredVendorsSize === 1) {
                            value.serviceLine = preferredVendors[0].serviceLine.id.toString();
                            _this.handleServiceLineChange(value);
                        }

                        // Auto select the service line if the client location only has one service line.
                        if (Object.keys(serviceLineSelectList).length === 1) {
                            value.serviceLine = Object.keys(serviceLineSelectList)[0].toString();
                            _this.setState({hideSelectServiceLineField: true});
                            _this.handleServiceLineChange(value);
                        } else {
                            // Auto select the service line if the client location only has standard pricing set up on one service line.
                            standardPricingService.findByClientLocationId(location).then(standardPricingData => {
                                const serviceLinesThatHaveStandardPricing = standardPricingData.filter((value, index, self) =>
                                        index === self.findIndex((standardPricingItem) => {
                                            return standardPricingItem.standardItem.serviceLine.id === value.standardItem.serviceLine.id;
                                        })
                                );
                                if (serviceLinesThatHaveStandardPricing.length === 1) {
                                    value.serviceLine = serviceLinesThatHaveStandardPricing[0].standardItem.serviceLine.id.toString();
                                    _this.setState({hideSelectServiceLineField: true});
                                    _this.handleServiceLineChange(value);
                                }
                            }).catch(error => {
                                console.error('Error finding service lines with standard pricing:', error);
                                DisplayErrorNotification("Error fetching standard pricing");
                            })
                        }
                    });
                });
            }

            value.preferredVendor = null;
            value.serviceLine = null;
        }

        if(value.serviceLine && this.state.value.serviceLine !== value.serviceLine) {
            this.handleServiceLineChange(value);
        }

        this.setState({value});
    };

    onChange(value) {

        let valid = this.validateForm(value);
        this.setState({ valid });

        if(this.state.value.location !== value.location) {
            if(value.location) {
                this.setState({ loading : true });
                clientLocationService.getPreferredVendorLocation(value.location, null).then((data) => {
                    let preferredVendors = data.filter(preferredVendor => preferredVendor.serviceLine.useTicketing);
                    let preferredVendorsSize = preferredVendors.length;
                    let serviceLineSelectList = {};
                    let serviceLineMap = {};
                    let preferredVendor = null;
                    let preferredVendorMap = { };
                    let preferredVendorLocationMap = { };

                    preferredVendors.forEach(function(preferredVendor) {

                        let serviceLineId = preferredVendor.serviceLine.id;
                        let vendorLocationId = preferredVendor.vendorLocation.id;

                        serviceLineSelectList[serviceLineId] = preferredVendor.serviceLine.name;
                        serviceLineMap[serviceLineId] = preferredVendor.serviceLine;

                        if(!preferredVendorMap.hasOwnProperty(serviceLineId)) {
                            preferredVendorMap[serviceLineId] = {};
                            preferredVendorLocationMap[serviceLineId] = {};
                        }

                        preferredVendorMap[serviceLineId][vendorLocationId] = preferredVendor.vendorLocation.vendor.name;
                        preferredVendorLocationMap[serviceLineId][vendorLocationId] = preferredVendor.vendorLocation;
                    });

                    this.setState({ preferredVendorsSize, serviceLineSelectList, serviceLineMap, preferredVendor, preferredVendorMap, preferredVendorLocationMap, loading: false }, () => {
                        if(preferredVendorsSize === 1) {
                            value.serviceLine = preferredVendors[0].serviceLine.id;
                            this.handleServiceLineChange(value);
                        }
                    });
                });
            }

            value.preferredVendor = null;
            value.serviceLine = null;
        }

        if(value.serviceLine && this.state.value.serviceLine !== value.serviceLine) {
            this.handleServiceLineChange(value);
        }

        this.setState({value});
    }

    setAddress(selectedLocationId, isFromAddress) {

        if(selectedLocationId != null) {
            let selectedLocation = this.state.locationsMap[selectedLocationId];

            let {value} = this.state;

            if(!value.addresses)
                value.addresses = {};

            let address = isFromAddress ? value.addresses.fromAddress : value.addresses.toAddress;

            if(!address)
                address = {};

            address.company = selectedLocation.client.name;
            address.addressLineOne = selectedLocation.addressLineOne;
            address.addressLineTwo = selectedLocation.addressLineTwo;
            address.city = selectedLocation.city;
            address.state = selectedLocation.state;
            address.zip = selectedLocation.zip;

            if(isFromAddress) {
                value.addresses.fromAddress = address;
            } else {
                value.addresses.toAddress = address;
            }

            let valid = this.validateForm(value);
            this.setState({ valid, value });
        }
    }

    validateForm(value) {
        let {serviceLineSelectList} = this.state;

        if(value.serviceLine == null)
            return false;

        if(serviceLineSelectList[value.serviceLine] === ServiceLine.COURIER_SERVICES) {
            if(value.location != null
                && value.preferredVendor != null
                && value.pickupDate != null
                && value.pickupDate.date != null
                && value.pickupDate.arrivalWindow != null
                && value.details != null
                && value.details.description != null
                && value.details.weight != null
                && value.addresses != null
                && value.addresses.fromAddress != null
                && value.addresses.fromAddress.addressLineOne != null
                && value.addresses.fromAddress.city != null
                && value.addresses.fromAddress.state != null
                && value.addresses.fromAddress.zip != null
                && value.addresses.fromAddress.name != null
                && value.addresses.toAddress != null
                && value.addresses.toAddress.addressLineOne != null
                && value.addresses.toAddress.city != null
                && value.addresses.toAddress.state != null
                && value.addresses.toAddress.zip != null
                && value.addresses.toAddress.name != null)
                return true;
        } else {
            if(value.location != null
                && value.preferredVendor != null
                && value.serviceLine != null
                && value.pickupDate != null
                && moment(value.pickupDate).isValid()
                && value.returnDate != null
                && moment(value.returnDate).isValid()) {
                return true;
            }
        }

        return false;
    }


    submit() {
        let {user, value, valid, serviceLineSelectList, serviceLineMap, pickupWindowObjects, locationsMap, preferredVendorLocationMap} = this.state;
        let {isSelfService, controlObligationInsert} = this.props;

        if(!valid)
            return;

        let _this = this;
        let clientId = user.clientRoles ? user.clientRoles[0].client.id : user.clientLocationRoles[0].clientId;
        let pickupDate = value.pickupDate.date;
        let openTicketForm = false;

        _this.setState({submitting: true});

        let submitData;

        if(serviceLineSelectList[value.serviceLine] === ServiceLine.COURIER_SERVICES) {
            submitData = {
                id: 0,
                clientId,
                serviceLineId: value.serviceLine,
                title: "On Demand Pickup",
                timeframe: pickupWindowObjects[value.pickupDate.arrivalWindow],
                pricingType: 'Itemized',
                status: 'pending',
                obligationItems: [{
                    title: value.details.description,
                    quantity: value.details.weight,
                    lineItemType: {id: 102},
                    unitRetailPrice: 0,
                    unitFulfillmentCost: 0
                }],
                fromAddress: value.addresses.fromAddress,
                toAddress: value.addresses.toAddress,
                type: ObligationType.NORMAL,
                storeProfileId: parseInt(this.state.value.location, 10),
                vendorLocationSummary: { id: this.state.value.preferredVendor },
                obligationDate: `${pickupDate[0]}-${pickupDate[1]}-${pickupDate[2]}`
            };

            if(value.details.comments) {
                submitData.obligationMetadata = {};
                submitData.obligationMetadata[TicketMetadata.DELIVERY_COMMENTS] = value.details.comments;
            }

            if (controlObligationInsert) {
                obligationService.insert(submitData).then(function(data){
                    _this.props.onSuccess(data, openTicketForm);

                    notification['success']({
                        message: 'On demand pickup request submitted!',
                    });
                }).finally(() => {
                    _this.setState({submitting: false});
                })
            }


        } else {
            openTicketForm = true;

            submitData = {
                id: 0,
                clientId,
                isSelfService,
                clientLocation: locationsMap[this.state.value.location],
                vendorLocation: preferredVendorLocationMap[value.serviceLine][this.state.value.preferredVendor],
                serviceLine: serviceLineMap[value.serviceLine],
                serviceLineId: value.serviceLine,
                title: "On Demand Pickup",
                timeframe: {
                    startTime: null,
                    endTime: null
                },
                pricingType: 'Itemized',
                status: 'pending',
                obligationMetadata: {
                    "Return date": moment(this.state.value.returnDate).format('YYYY-MM-DD')
                },
                type: ObligationType.NORMAL,
                obligationItemGroups: [],
                storeProfileId: parseInt(this.state.value.location, 10),
                vendorLocationSummary: { id: this.state.value.preferredVendor },
                obligationDate: moment(this.state.value.pickupDate).format('YYYY-MM-DD')
            };
        }

        if (controlObligationInsert) {
            _this.props.onSuccess(submitData, openTicketForm);
        } else {
            obligationService.insert(submitData).then(function(data){
                _this.props.onSuccess(data, openTicketForm);

                notification['success']({
                    message: 'On demand pickup request submitted!',
                });
            }).finally(() => {
                _this.setState({submitting: false});
            })
        }
    }


    render() {
        let {locations, locationsList, pickupWindows, loading, preferredVendorsSize, serviceLineSelectList, submitting, availableVendors, valid, value, hideSelectServiceLineField} = this.state;
        let {onLocationSelect, setAddress} = this;
        let {isSelfService} = this.props;

        if (!loading) {

            let locationSelectTemplate = (locals) => {
                return (
                    <div>
                        <label>{locals.label}</label>
                        <WorksmithSelect
                            showSearch
                            style={{width: '100%'}}
                            options={locals.options}
                            valueFieldName={"value"}
                            optionFieldName={"text"}
                            onSelect={onLocationSelect}
                            value={locals.value}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        />
                    </div>
                );
            };

            let selectTemplate = (locals) => {
                return (
                    <div>
                        <label>{locals.label}</label>
                        <WorksmithSelect
                            style={{width: '100%'}}
                            options={locals.options}
                            valueFieldName={"value"}
                            optionFieldName={"text"}
                            onSelect={locals.onChange}
                            value={locals.value}
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        />
                    </div>
                );
            };

            let formStruct = {
                location: t.enums(locations)
            };

            let formOptions = {
                fields: {
                    location: {
                        label: 'Please select your location',
                        error: 'You must select a location before proceeding',
                        template: locationSelectTemplate,
                        options: Object.keys(locations).map(key => {
                            return {
                                value: key,
                                text: locations[key]
                            }
                        }).sort((a, b) => {
                            let nameA = a.text.toUpperCase();
                            let nameB = b.text.toUpperCase();

                            if (nameA < nameB)
                                return -1;
                            if (nameA > nameB)
                                return 1;

                            return 0;
                        })
                    },
                    serviceLine: {
                        label: 'Please select the service line needed',
                        template: selectTemplate
                    },
                    preferredVendor: {
                        label: 'Please select the vendor that you would like to use',
                        template: selectTemplate
                    }
                }
            };

            //Once a location is picked, the user can select the service line
            if(value.location) {
                if(preferredVendorsSize > 0) {
                    if (!hideSelectServiceLineField) {
                        // adds the serviceLineField to the form after a location is selected.
                        Object.assign(formStruct, { serviceLine : t.enums(serviceLineSelectList) });
                    }
                } else {
                    Object.assign(formOptions, { hasError: true, error: 'You do not have any preferred vendors set up for this location.  To set up a preferred vendor, please reach out to the Worksmith team by submitting a support ticket.'})
                }
            }

            //Once a service line is picked, the user can select the preferred vendor
            if(value.location && value.serviceLine && ValueIsSet(availableVendors)) {
                Object.assign(formStruct, { preferredVendor : t.enums(availableVendors)});
            }

            //Once the location, service line, and preferred vendor are picked, the user can select the additional fields
            if(value.location && value.preferredVendor && value.serviceLine) {

                if(serviceLineSelectList[value.serviceLine] === ServiceLine.COURIER_SERVICES) {

                    let addressTemplate = (locals) => {
                        return (
                            <div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {locals.inputs.company}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {locals.inputs.addressLineOne}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {locals.inputs.addressLineTwo}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                {locals.inputs.city}
                                            </div>
                                            <div className="col-md-2">
                                                {locals.inputs.state}
                                            </div>
                                            <div className="col-md-4">
                                                {locals.inputs.zip}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row margin-top-lg">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {locals.inputs.name}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {locals.inputs.phone}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    };

                    let addressGroupTemplate = (locals) => {
                        return (
                            <div className="row margin-top-lg">
                                <div className="col-md-6">
                                    <div>From Address</div>
                                    <label className="margin-top-md">Select a store</label>
                                    <WorksmithSelect
                                        showSearch
                                        style={{width: '100%'}}
                                        options={locationsList}
                                        valueFieldName={"id"}
                                        optionFieldName={"title"}
                                        onSelect={(value) => {setAddress(value, true); }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    />
                                    <div className="margin-top-md margin-bottom-md text-center" style={{width: '100%'}}>- OR -</div>
                                    <label>Manually enter an address</label>
                                    {locals.inputs.fromAddress}
                                </div>
                                <div className="col-md-6">
                                    <div>To Address</div>
                                    <label className="margin-top-md">Select a store</label>
                                    <WorksmithSelect
                                        showSearch
                                        style={{width: '100%'}}
                                        options={locationsList}
                                        valueFieldName={"id"}
                                        optionFieldName={"title"}
                                        onSelect={(value) => {setAddress(value, false); }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    />
                                    <div className="margin-top-md margin-bottom-md text-center" style={{width: '100%'}}>- OR -</div>
                                    <label>Manually enter an address</label>
                                    {locals.inputs.toAddress}
                                </div>
                            </div>
                        )
                    };

                    let dateTemplate = (locals) => {

                        let lv = moment();

                        if(locals.value && locals.value[0]) {
                            lv = moment([locals.value[0], locals.value[1]-1, locals.value[2]]);
                        }

                        return (
                            <div>
                                <div>
                                    <label>{locals.label}</label>
                                </div>
                                <DatePicker style={{width:'100%'}} value={lv} allowClear={false} onChange={(date) => { locals.onChange([date.format('YYYY'),date.format('MM'),date.format('DD')]); }}/>
                            </div>
                        );
                    };

                    let pickupDateTemplate = (locals) => {
                        return (
                            <div className="row margin-top-lg">
                                <div className="col-md-6">
                                    {locals.inputs.date}
                                </div>
                                <div className="col-md-6">
                                    {locals.inputs.arrivalWindow}
                                </div>
                            </div>
                        )
                    };

                    let detailsTemplate = (locals) => {
                        return (
                            <div className="row margin-top-lg">
                                <div className="col-md-12">
                                    {locals.inputs.weight}
                                </div>
                                <div className="col-md-12">
                                    {locals.inputs.description}
                                </div>
                                <div className="col-md-12">
                                    {locals.inputs.comments}
                                </div>
                            </div>
                        )
                    };

                    let numberInputTemplate = (locals) => {
                        return (
                            <Fragment>
                                <label>{locals.label}</label>
                                <InputNumber value={locals.value} style={{width:'100%'}} min={0} step={1} onChange={locals.onChange} />
                            </Fragment>
                        )
                    };

                    let inputTemplate = (locals) => {
                        return (
                            <Fragment>
                                <label>{locals.label}</label>
                                <Input value={locals.value} style={{width:'100%'}} onChange={(e) => { locals.onChange(e.target.value); }} />
                            </Fragment>
                        )
                    };

                    let textAreaTemplate = (locals) => {
                        return (
                            <Fragment>
                                <label>{locals.label}</label>
                                <TextArea value={locals.value} style={{width:'100%'}} onChange={(e) => { locals.onChange(e.target.value); }} />
                            </Fragment>
                        )
                    };

                    Object.assign(formOptions.fields, {
                        pickupDate: {
                            template: pickupDateTemplate,
                            fields: {
                                date: {
                                    label: 'Pickup Date',
                                    mode: 'date',
                                    template: dateTemplate
                                },
                                arrivalWindow: {
                                    label: 'Pickup Window',
                                    template: selectTemplate
                                }
                            }
                        },
                        details: {
                            template: detailsTemplate,
                            fields: {
                                comments: {
                                    label: "Additional Comments (e.g. put at front desk)",
                                    template: textAreaTemplate
                                },
                                weight: {
                                    label: "Approximately how many pounds will be delivered?",
                                    template: numberInputTemplate
                                },
                                description: {
                                    label: "Description of item(s) for pickup",
                                    template: inputTemplate
                                }
                            }
                        },
                        addresses: {
                            template: addressGroupTemplate,
                            fields: {
                                fromAddress: {
                                    label: 'From Address:',
                                    template: addressTemplate,
                                    fields: {
                                        company: {
                                            label: 'Company Name (optional)',
                                            template: inputTemplate
                                        },
                                        addressLineOne: {
                                            label: 'Address Line One',
                                            template: inputTemplate
                                        },
                                        addressLineTwo: {
                                            label: 'Address Line Two',
                                            template: inputTemplate
                                        },
                                        city: {
                                            label: 'City',
                                            template: inputTemplate
                                        },
                                        state: {
                                            label: 'State',
                                            template: inputTemplate
                                        },
                                        zip: {
                                            label: 'Zip',
                                            template: inputTemplate
                                        },
                                        name: {
                                            label: 'Point of Contact at Pick Up?',
                                            template: inputTemplate
                                        },
                                        phone: {
                                            label: 'Point of Contact Phone (optional)',
                                            template: inputTemplate
                                        }
                                    }
                                },
                                toAddress: {
                                    label: 'To Address:',
                                    template: addressTemplate,
                                    fields: {
                                        company: {
                                            label: 'Company Name (optional)',
                                            template: inputTemplate
                                        },
                                        addressLineOne: {
                                            label: 'Address Line One',
                                            template: inputTemplate
                                        },
                                        addressLineTwo: {
                                            label: 'Address Line Two',
                                            template: inputTemplate
                                        },
                                        city: {
                                            label: 'City',
                                            template: inputTemplate
                                        },
                                        state: {
                                            label: 'State',
                                            template: inputTemplate
                                        },
                                        zip: {
                                            label: 'Zip',
                                            template: inputTemplate
                                        },
                                        name: {
                                            label: 'Point of Contact at Drop Off?',
                                            template: inputTemplate
                                        },
                                        phone: {
                                            label: 'Point of Contact Phone (optional)',
                                            template: inputTemplate
                                        }
                                    }
                                }
                            }
                        }
                    });

                    Object.assign(formStruct, {
                        pickupDate : t.struct({
                            date: t.Date,
                            arrivalWindow: t.enums(pickupWindows)
                        }),
                        details: t.struct({
                            description: t.String,
                            weight: t.Number,
                            comments: t.maybe(t.String)
                        }),
                        addresses: t.struct({
                            fromAddress: t.struct({
                                company: t.maybe(t.String),
                                addressLineOne: t.String,
                                addressLineTwo: t.maybe(t.String),
                                city: t.String,
                                state: t.String,
                                zip: t.String,
                                name: t.String,
                                phone: t.maybe(t.String)
                            }),
                            toAddress: t.struct({
                                company: t.maybe(t.String),
                                addressLineOne: t.String,
                                addressLineTwo: t.maybe(t.String),
                                city: t.String,
                                state: t.String,
                                zip: t.String,
                                name: t.String,
                                phone: t.maybe(t.String)
                            })
                        })
                    });
                } else {

                    if(isSelfService){
                        Object.assign(formOptions.fields, {
                            pickupDate: {
                                label: 'Pickup/Ship date (YYYY/MM/DD)',
                                order: ['YY', 'M', 'D']
                            },
                            returnDate: {
                                label: 'Target Return date (YYYY/MM/DD)',
                                order: ['YY', 'M', 'D']
                            }});
                    }else{
                        Object.assign(formOptions.fields, {
                            pickupDate: {
                                label: 'Pickup/Ship date (MM/DD/YYY)'
                            },
                            returnDate: {
                                label: 'Target Return date (MM/DD/YYY)'
                            }});
                    }

                     Object.assign(formStruct, { pickupDate : t.Date, returnDate : t.Date });
                }
            }

            const NewPickupFormStruct = t.struct(formStruct);

            return (
                <div>
                    <Form type={NewPickupFormStruct}
                          ref="form"
                          options={formOptions}
                          value={this.state.value}
                          onChange={(value) => this.onChange(value)}/>

                    <div className="text-right margin-top-lg">
                        <Button type={'primary'} onPress={this.submit} disabled={!valid || submitting} message={submitting ? 'Submitting...' : 'Add On Demand Pickup'}/>
                    </div>
                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

NewServiceForm.propTypes = {
    isSelfService: PropTypes.bool
};

NewServiceForm.defaultProps = {
    isSelfService: false
};

export default WithContext(NewServiceForm);
