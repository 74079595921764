import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import ServiceLineNTEConfigurationSummaryPropType from "../utilities/propTypes/businessObjects/nte/ServiceLineNTEConfigurationSummaryPropType";
import {Color} from "../Enums";

const Container = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 
    'serviceLineName serviceLineNTE'
    'exceptions expandButton'
    'locationGrid locationGrid';
`;

const ServiceLineName = styled.div`
    grid-area: serviceLineName;
    font-weight: bold;
    color: ${Color.NAVY}
`;

const ServiceLineNTE = styled.div`
    grid-area: serviceLineNTE;
    font-weight: bold;
    color: ${Color.NAVY}
`;

const Exceptions = styled.div`
    grid-area: exceptions;
    font-weight: bold;
`;

const ExpandButton = styled.div`
    grid-area: expandButton;
    text-decoration: underline;
    font-weight: bold;
`;

const LocationGrid = styled.div`
    grid-area: locationGrid;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(${props => props.rowCount}, 1.5em);
`;

class ServiceLineNTEConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    toggleExpanded = () => {
        let {expanded} = this.state;
        this.setState({expanded: !expanded});
    };

    render() {
        let {config} = this.props;
        let {expanded} = this.state;


        return (
            <Container>
                <ServiceLineName>
                    {config.serviceLine.name}
                </ServiceLineName>
                <ServiceLineNTE>
                 {config.serviceLineNTE ? '$' + config.serviceLineNTE.NTEValue : 'No NTE'}
                </ServiceLineNTE>
                {config.locationNTEs.length > 0 ?
                    <Fragment>
                    <Exceptions>
                        {config.locationNTEs.length} Exceptions
                    </Exceptions>
                    <ExpandButton>
                        <a onClick={this.toggleExpanded}>{expanded ? 'Hide' : 'View'}</a>
                    </ExpandButton>
                    {expanded ?
                        <LocationGrid rowCount={config.locationNTEs.length}>
                            {
                                config.locationNTEs.map((nte) => {
                                    return(
                                        <Fragment>
                                            <div>
                                                {nte.clientLocation.name}
                                            </div>
                                            <div>
                                                {nte.NTEValue != -100 ? '$' + nte.NTEValue : 'No NTE'}
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </LocationGrid> : null}
                    </Fragment>: null
                }
            </Container>
        )
    }
}

ServiceLineNTEConfiguration.propTypes = {
    config: ServiceLineNTEConfigurationSummaryPropType.isRequired
};

export default ServiceLineNTEConfiguration;