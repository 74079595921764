// This file was generated from com.worksmith.proposal.enums.ScheduleStatus
// noinspection JSUnusedGlobalSymbols
const ScheduleStatus = Object.freeze({
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    DECLINED: 'DECLINED',
    NEEDS_CLIENT: 'NEEDS_CLIENT',
    NEEDS_VENDOR: 'NEEDS_VENDOR'
});

export default ScheduleStatus;
