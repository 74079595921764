// This file was generated from com.worksmith.psldb.controller.StandardPricingController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class StandardPricingService extends Http {
    constructor() {
        super('/psldb/standardPricing')
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteItem(id) {
        return this.callApi( `deleteStandardItem/${id}`, DELETE, null, null, null, true);
    }

    deleteStandardItems(ids) {
        return this.callApi( `deleteStandardItems`, DELETE, ids, null, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findByClientLocationId(clientLocationId) {
        return this.callApi( `findByClientLocationId/${clientLocationId}`, GET, null, null, null, true);
    }
    
    findByVendorLocationId(vendorLocationIds) {
        return this.callApi( `findByVendorLocationIds`, POST, vendorLocationIds, null, null, true);
    }
    
    findByVendorLocationIdAndClientLocationId(vendorLocationId, clientLocationId) {
        let params = {
            vendorLocationId, 
            clientLocationId
        };
    
        return this.callApi( `findByVendorLocationIdAndClientLocationId`, GET, null, params, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    getStandardItems(clientId) {
        return this.callApi( `getStandardItems/${clientId}`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    saveItems(items) {
        return this.callApi( `saveItems`, POST, items, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
}

export default StandardPricingService;
