import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {BorderStyle, BoxSizing, Display, FlexDirection, JustifyContent, Position, TextAlign} from "../../CSSEnums";
import {Color} from "../../Enums";

const Header = styled.div`
    position: ${Position.FIXED};
    top: 0;
    background-color: ${Color.NAVY};
    width: 100%;
    height: 100px;
    z-index: 120;
    padding: 1rem !important;
`;

const AgreementContainer = styled.div`
    display: ${Display.FLEX};
    justify-content: ${JustifyContent.CENTER};
`;

const WhiteBlock = styled.div`
    display: ${Display.BLOCK};
    box-sizing: ${BoxSizing.BORDER_BOX};

    background-color: ${Color.WHITE};
    border-style: ${BorderStyle.SOLID};
    border-width: 2px;
    border-color: ${Color.OFF_WHITE};
    justify-content: ${JustifyContent.CENTER};
    margin-top: 120px;
    padding: 1.25rem;
    
    font-family: "Montserrat", Arial, "Helvetica Neue", Helvetica, sans-serif;

    width: 1100px;
`;

const TabbedParagraph = styled.p`
    margin-left: 20px;
`;

const ServiceAgreement = (props) => {
    let {} = props;

    return (
        <Fragment>
            <Header>
                <img src={'https://cdn.worksmith.com/logo-white.png'}
                     style={{height: '70px', width: 'auto'}}
                     alt={'logo'}/>
            </Header>
            <AgreementContainer>
                <WhiteBlock>
                    <h3 style={{marginBottom: '30px', textAlign: TextAlign.CENTER}}>Worksmith Service Agreement</h3>
                    <p>This Service Agreement is entered between Client and Service Provider as of the date submitted and agreed online. Service Provider agrees to provide the services outlined in each Service Order (in the form reviewed online) for the duration and fee indicated (“Service(s)”). The parties to this Service Agreement independently subscribe to the Worksmith service (offered by Worksmith, Inc.) which is a subscription service that enables local service providers and clients to communicate and connect. (“Worksmith Platform”). For the avoidance of doubt, Worksmith is not a party to this Agreement. Client and Service Provider have each entered into a separate agreement for their respective use of the Worksmith Platform.</p>
                    <br/>


                    <h5>I.&nbsp;&nbsp;&nbsp;Services</h5>
                    <br/>
                    <TabbedParagraph>
                        A.&nbsp;&nbsp;&nbsp;<span style={{fontStyle: 'italic'}}>Service Provider Fulfillment Obligations.</span>
                        Service Provider agrees to fulfill Client’s Service Order(s) submitted through the Worksmith Platform. ServiceProvider agrees that Services will be provided in a timely and professional fashion to the best of Service Provider’s ability, taking into account the input of Client through the Worksmith Platform.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        B.&nbsp;&nbsp;&nbsp;<span style={{fontStyle: 'italic'}}>Service and Delivery Confirmation.</span>
                        Service Provider will provide immediate confirmation with timestamp through Worksmith to confirm that each Service (including any remediation action) has taken place.  For the avoidance of doubt, this confirmation should happen as the action takes place, and no later than the end of day on the day that the action occurred. Service Provider agrees that in certain instances multiple Service attempts may be required, within reason, and agrees to make best efforts to fulfill the original commitment.  If a Service is unable to be completed by Service Provider or if there are any issues in the course of attempting to complete said Service, Provider will send notification through Worksmith.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        C.&nbsp;&nbsp;&nbsp;<span style={{fontStyle: 'italic'}}>Damages.</span>
                        Service Provider will be responsible for remediation, replacement and/or refund for any damage that occurs to Client’s property during Provider’s delivery or work.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        D.&nbsp;&nbsp;&nbsp;<span style={{fontStyle: 'italic'}}>Supplies.</span>
                        Service Provider will be responsible for all supplies and equipment (including maintenance) required for servicing orders through Worksmith.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        E.&nbsp;&nbsp;&nbsp;<span style={{fontStyle: 'italic'}}>Access Requirement.</span>
                        Client will provide Service Provider with access to its property, and to all areas of the property scheduled to be serviced, at the appointed time. Failure to do so without twenty-four (24) hour advanced notice through Worksmith allows Service Provider to treat the failure as a billable event in accordance with the 24 Hour Policy (as defined below).
                    </TabbedParagraph>
                    <br/>


                    <h5>II.&nbsp;&nbsp;&nbsp;Fees</h5>
                    <br/>
                    <TabbedParagraph>
                        A.&nbsp;&nbsp;&nbsp;Client agrees to pay the fees outlined in the applicable Service Order (including any applicable taxes) and all payments to Service Provider shall be made through the Worksmith Platform.  If any past due payment has not been received by Service Provider via the Worksmith Platform Service Provider may suspend the Services associated with such Service Order until such payment is made.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        B.&nbsp;&nbsp;&nbsp;Service Provider agrees that any changes to pricing for Provider Services may be made at the beginning of the calendar year and shall be subject to sixty (60) days’ prior notice to Client via the Worksmith Platform.
                    </TabbedParagraph>
                    <br/>


                    <h5>III.&nbsp;&nbsp;&nbsp;Termination</h5>
                    <br/>
                    <TabbedParagraph>
                        A.&nbsp;&nbsp;&nbsp;Either party may terminate this Service Agreement or any Service Order upon thirty (30) days written notice.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        B.&nbsp;&nbsp;&nbsp;Notwithstanding anything to the contrary, the parties agree that in the event either party terminates their respective agreements with Worksmith, then this Service Agreement shall automatically terminate. Any work in progress prior to such termination shall be completed under these terms.
                    </TabbedParagraph>
                    <br/>


                    <h5>IV.&nbsp;&nbsp;&nbsp;Changes to Service; Quality; Open Service Orders</h5>
                    <br/>
                    <TabbedParagraph>
                        A.&nbsp;&nbsp;&nbsp;Temporary Suspension. In the event Client wishes to temporarily suspend any Service under an applicable Service Order then Client shall notify Service Provider via the Worksmith Service. The 24 Hour Policy (as defined below), shall apply to any temporary suspension in the event a Service is imminently scheduled.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        B.&nbsp;&nbsp;&nbsp;One-time cancellation or rescheduling of Service. The parties agree that a Service may be cancelled or rescheduled subject to twenty four (24) hours’ notice to Worksmith (“24 Hour Policy”). In the event the 24 Hour Policy is not met, then (i) in the case of Client, the fees associated with such Service shall be billed in full and (ii) in the case of Service Provider, a credit for the full amount shall be issued through Worksmith on Client’s account.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        C.&nbsp;&nbsp;&nbsp;Changes to Scope of Service. In the event Client seeks to change the scope or nature of a particular Service, then the parties may terminate the prior Service Order and enter into a new Service Order.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        D.&nbsp;&nbsp;&nbsp;Service Quality. In the event issues pertaining to Service quality arise or Client is otherwise dissatisfied with the Service, Client shall notify Worksmith. Service Provider will cooperate with Worksmith to resolve such matter and Service Provider will be responsible for prompt remediation at Service Provider’s expense to the extent operationally feasible and reasonable to meet the Client’s expectations. The parties agree to work cooperatively with Worksmith who will assist the parties to facilitate an amicable resolution.
                    </TabbedParagraph>
                    <br/>


                    <h5>V.&nbsp;&nbsp;&nbsp;Open Service Orders</h5>
                    <br/>
                    <TabbedParagraph>
                        For convenience, Client may elect to maintain an open Service Order to assist Client in addressing one-time or emergency situations in a timely fashion (“Open Service Order”). In the event Client elects an Open Service Order for certain services, Service Provider agrees to cooperate with Worksmith to perform the Services for Client at Service Provider’s then current fees.  A Service Order shall not be deemed an Open Service Order unless clearly indicated on a Service Order
                    </TabbedParagraph>
                    <br/>


                    <h5>VI.&nbsp;&nbsp;&nbsp;CONFIDENTIALITY</h5>
                    <br/>
                    <TabbedParagraph>
                        “Confidential Information” shall mean, with respect to either party, any information disclosed by such Party to the other Party in connection with this Agreement and its terms. The receiving Party of any Confidential Information will use the disclosing Party's Confidential Information solely to perform its obligations and exercise its rights under this Agreement. The receiving Party will take all precautions necessary to safeguard the confidentiality of the disclosing party's Confidential Information.
                    </TabbedParagraph>
                    <br/>


                    <h5>VII.&nbsp;&nbsp;&nbsp;REPRESENTATIONS, WARRANTIES AND DISCLAIMER</h5>
                    <br/>
                    <TabbedParagraph>
                        A.&nbsp;&nbsp;&nbsp;Representations and Warranties.  Each Party represents and warrants to the other Party that (a) such Party has the required power and authority to enter into this Agreement and to perform its obligations hereunder; (b) the execution of this Agreement and performance of its obligations thereunder do not and will not violate any other agreement to which it is a party; and (c) this Agreement constitutes a legal, valid and binding obligation when signed by both Parties; and (d) in the case of Service Provider, the work will be provided in a good and workman like manner based on Service Provider’s industry standards.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        B.&nbsp;&nbsp;&nbsp;Disclaimer.  EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THIS AGREEMENT, NEITHER PARTY MAKES ANY ADDITIONAL REPRESENTATION OR WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER.  ALL OTHER EXPRESS OR IMPLIED CONDITIONS, REPRESENTATIONS AND WARRANTIES ARE HEREBY EXCLUDED TO THE EXTENT ALLOWED BY APPLICABLE LAW.  EACH PARTY EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, ACCURACY, TITLE, AND NON-INFRINGEMENT.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        C.&nbsp;&nbsp;&nbsp;General Cap on Liability.  EXCEPT IN THE CASE OF (A) DEATH OR INJURY TO PERSON OR PROPERTY OR (B) FRAUD, NEGLIGENCE OR MISCONDUCT,UNDER NO CIRCUMSTANCES WILL EITHER PARTY’S LIABILITY FOR ALL CLAIMS ARISING UNDER OR RELATING TO THIS AGREEMENT (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED THE AGGREGATE FEES PAID BY CLIENT TO SERVICE PROVIDER UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENT OR CIRCUMSTANCES GIVING RISE TO SUCH LIABILITY. THIS LIMITATION OF LIABILITY IS CUMULATIVE AND NOT PER INCIDENT.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        D.&nbsp;&nbsp;&nbsp;The parties agree that a) Worksmith shall not be responsible for the quality of the Services, risk of loss, defective Services or other non-performance by a Service Provider and (b) any disputes between the parties shall be resolved directly between Service Provider and Client.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        E.&nbsp;&nbsp;&nbsp;Insurance – Service Provider will maintain general liability insurance with policy coverage no less than $1,000,000 per occurrence and $2,000,000 in aggregate; auto liability insurance no less than $1,000,000 per occurrence; statutory workers compensation as required by state law; and professional liability with no less than $1,000,000 per occurrence. Upon Client request, evidence of such insurance shall be provided by Service Provider to Worksmith within thirty (30) days.]
                    </TabbedParagraph>
                    <br/>


                    <h5>VII.&nbsp;&nbsp;&nbsp;General</h5>
                    <br/>
                    <TabbedParagraph>
                        A.&nbsp;&nbsp;&nbsp;Independent Contractors.  The Parties shall not be deemed to be partners, joint venturers, employers, employees or each other's agents, and no Party shall have the right to act on behalf of any other except as expressly agreed in writing.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        B.&nbsp;&nbsp;&nbsp;Counterparts.  This Agreement may be executed in counterparts, each of which will be deemed an original and all of which together will constitute one and the same document.  This Agreement and written amendments hereto, may be executed by facsimile.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        C.&nbsp;&nbsp;&nbsp;Amendment.  Any waivers or amendments shall be effective only if made in writing and signed by a representative of the respective Parties authorized to bind the Parties.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        D.&nbsp;&nbsp;&nbsp;Assignment.  Neither Party may assign this Agreement or assign or delegate its rights or obligations under the Agreement without the other Party’s prior written consent; provided however, that either Party may assign this Agreement to an acquirer of or successor to all or substantially all of its business or assets to which this Agreement relates, whether by merger, sale of assets, sale of stock, reorganization or otherwise.  Any assignment or attempted assignment by either Party otherwise than in accordance with this Section shall be null and void.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        E.&nbsp;&nbsp;&nbsp;Force Majeure.  Each Party shall be excused from performance for any period during which, and to the extent that, it is prevented from performing any obligation or service, in whole or in part, as a result of a cause beyond its reasonable control and without its fault or negligence, including, but not limited to, acts of God, acts of war, epidemics, fire, communication line failures, power failures, earthquakes, floods, blizzard, or other natural disasters.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        F.&nbsp;&nbsp;&nbsp;Applicable Law; Venue.  This Agreement shall be deemed to have been made in, and shall be construed pursuant to the laws of the State of Texas and the United States without regard to conflict of laws provisions thereof.
                    </TabbedParagraph>
                    <TabbedParagraph>
                        G.&nbsp;&nbsp;&nbsp;Entire Agreement.  This Agreement, including the attached the Exhibits which are incorporated into this Agreement, set forth the final and entire agreement and supersedes any and all prior agreements of the Parties with respect to the transactions set forth herein.  Neither Party will be bound by, and each Party specifically objects to, any term, condition or other provision which is different from or in addition to the provisions of this Agreement (whether or not it would materially alter this Agreement) and which is proffered by the other party in any correspondence or other document, unless the party to be bound thereby specifically agrees to such provision in writing.
                    </TabbedParagraph>
                    <br/>
                </WhiteBlock>
            </AgreementContainer>
        </Fragment>
    )
};

ServiceAgreement.propTypes = {
    
};

export default ServiceAgreement;