import React from 'react';
import PropTypes from 'prop-types';
import {DataVisualizationTheme} from "worksmith/components/Theme/ThemeProvider";
import {TextAlign} from "worksmith/enums/CSSEnums";
import PieChart from "worksmith/components/DataVisualization/PieChart/PieChart";
import {Color} from "worksmith/enums/Color";
import {VictoryPie, VictoryLabel} from 'victory';
import Icon, {IconFontSize} from "worksmith/components/Icon/Icon";

const SingleMetricPieChart = (props) => {
    const {
        metricValue,
        x,
        y,
        width,
        innerRadius,
        centerText,
        hideLabels,
        warningThreshold,
        emptyDataset
    } = props;

    const data = [{x:'', y:100-metricValue},{x:'', y:metricValue}];

    const showWarning = warningThreshold != null && warningThreshold > metricValue && !emptyDataset;

    const chartColor = () => {
        return metricValue === 0 ?
            [Color.SLA_LIGHT_BLUE] : [Color.TRANSPARENT, Color.SECONDARY]
    };


    return (
        data.length > 0  ?
            <div style={{width: width + 'px', margin: 'auto'}}>
                {showWarning ?
                    <div style={{color: Color.RED, textAlign: TextAlign.RIGHT, height:0}}>
                        <Icon translateTop={15} fontSize={IconFontSize.SMALL} name={'ReportProblemOutlined'}/>
                    </div>
                    : null
                }
                <svg viewBox={'0 0 500 350'} style={{height: 'auto', width: '100%', overflow: false}}>
                    <VictoryPie theme={DataVisualizationTheme}
                     colorScale={chartColor()}
                     standalone={false}
                     labels={({datum}) => hideLabels ? '' : datum.x}
                     innerRadius={innerRadius}
                     width={500}   //the best way to dictate the actually rendered size of the chart is to change the width of the container you place it in
                     height={350}
                     x={x}
                     y={y}
                     data={data}/>
                    <VictoryLabel
                        textAnchor="middle"
                        style={{fontSize: '50px'}}
                        x={250} y={175}
                        text={centerText ? centerText : metricValue.toFixed(0) + '%'}
                    />
            </svg>
        </div>
        :
            null
        )
};

SingleMetricPieChart.propTypes = {
    centerText: PropTypes.string,
    hideLabels: PropTypes.bool,
    innerRadius: PropTypes.number,
    metricValue: PropTypes.number,  //this should be on a scale of 100
    warningThreshold: PropTypes.number,  //this should be on a scale of 100
    width: PropTypes.number.isRequired,
    x: PropTypes.oneOfType([                            //this can be used to specify a data accessor for the x value, more info here https://formidable.com/open-source/victory/docs/common-props/#x
        PropTypes.string,                               //string: specify which property in an array of data objects should be used as the x value
        PropTypes.number,                               //function: use a function to translate each element in a data array into an x value
        PropTypes.arrayOf(PropTypes.string),            //array index: specify which index of an array should be used as an x value when data is given as an array of arrays
        PropTypes.func                                  //path string or path array: specify which property in an array of nested data objects should be used as an x value
    ]),
    y: PropTypes.oneOfType([                            //this can be used to specify a data accessor for the y value, more info here https://formidable.com/open-source/victory/docs/common-props/#y
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func
    ])
};


PieChart.defaultProps = {
    innerRadius: 80,
    hideLabels: true
};

export default SingleMetricPieChart;