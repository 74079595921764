import React, {Component, Fragment} from 'react';
import Logo from '../Assets/logo-svg.svg';
import Isvg from 'react-inlinesvg';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {LoaderType} from "../../enums/LoaderType";
import '../Assets/logo-svg.web.css';

class Loader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {loaderType} = this.props;

        return (
            <Fragment>
                { loaderType === LoaderType.DOTS &&
                    <div className="text-center loader" style={{'margin': '1em 1em 1.5em 1em'}}>
                        <div className="sk-three-bounce center-block">
                            <div className="sk-child sk-bounce1 sk-lg"></div>
                            <div className="sk-child sk-bounce2 sk-lg"></div>
                            <div className="sk-child sk-bounce3 sk-lg"></div>
                        </div>
                    </div>
                }
                { loaderType === LoaderType.LOGO &&
                    <div className="text-center loader">
                        <Isvg src={Logo} />
                    </div>
                }
            </Fragment>
        );

    }
}

Loader.propTypes = {
    loaderType: CustomPropTypes.enum(LoaderType),
};

Loader.defaultProps = {
    loaderType: LoaderType.DOTS,
};

export default Loader;