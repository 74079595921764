import React from 'react';
import PropTypes from 'prop-types';
import IconButton, {IconButtonSize} from "worksmith/components/Button/IconButton";
import Icon, {IconFontSize} from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import Grid from "worksmith/components/Grid/Grid";

const EditToggleIcons = (props) => {
    const {editing, onCheck, onToggle, saveDisabled} = props;

    if(editing)
        return(
            <Grid container>
                <Grid item xs={6}>
                    <Tooltip title={'Save'} placement={TooltipPlacement.BOTTOM}>
                        <IconButton secondary
                                    disabled={saveDisabled}
                                    size={IconButtonSize.SMALL}
                                    onClick={onCheck}
                                    onFocus={event => event.stopPropagation()}>
                            <Icon secondary fontSize={IconFontSize.SMALL} name={IconType.CHECK}/>
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid item xs={6}>
                    <Tooltip title={'Cancel'} placement={TooltipPlacement.BOTTOM}>
                        <IconButton secondary
                                    size={IconButtonSize.SMALL}
                                    onClick={onToggle}
                                    onFocus={event => event.stopPropagation()}>
                            <Icon secondary fontSize={IconFontSize.SMALL} name={IconType.CANCEL}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    else
        return(
            <Tooltip title={'Edit'} placement={TooltipPlacement.BOTTOM}>
                <IconButton secondary
                        size={IconButtonSize.SMALL}
                        onClick={onToggle}
                        onFocus={event => event.stopPropagation()}>
                    <Icon secondary fontSize={IconFontSize.SMALL} name={IconType.EDIT}/>
                </IconButton>
            </Tooltip>
        );
};

EditToggleIcons.propTypes = {
    editing:      PropTypes.bool.isRequired,
    onCheck:      PropTypes.func.isRequired,
    onToggle:     PropTypes.func.isRequired,
    saveDisabled: PropTypes.bool
};

EditToggleIcons.defaultProps = {
    saveDisabled: false
};

export default EditToggleIcons;