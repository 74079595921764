import React from 'react';
import styled from 'styled-components';
import Proptypes from "prop-types";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const WorksmithLogo = (props) => {

    const {styles, color, height, width} = props;
    let margin = '0 0 0 0';
    if(ValueIsSet(props.margin)){
        margin = props.margin;
    }

    const WSLogo = styled.svg`
        height: ${height};
        width: ${width}; 
        margin: ${margin};
        ${styles}
    `;

    return (
        <WSLogo width={'78px'} height={'50px'} viewBox={"0 0 78 50"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
            <g id={"LogoWhite"} stroke={"none"} strokeWidth={"1"} fill={"none"} fillRule={"evenodd"}>
                <g id={"Worksmith_Logo_180x180"} transform={"translate(-21.000000, 0.000000)"} fill={color}>
                    <polygon id={"Path"} points={"42.5 49.23 60 18.92 77.5 49.23"}></polygon>
                    <polygon id={"Path"} points={"63.52 0.51 81.01 30.82 98.51 0.51"}></polygon>
                    <polygon id={"Path"} points={"21.49 0.51 38.99 30.82 56.48 0.51"}></polygon>
                </g>
            </g>
    </WSLogo>
    )
};

WorksmithLogo.propTypes = {
    styles: Proptypes.string,
    color: Proptypes.string.isRequired,
    height:  Proptypes.string.isRequired, // notice that this is a string
    width: Proptypes.string.isRequired, // notice that this is a string
    margin: Proptypes.string
};

export default WorksmithLogo;