import React, {useState} from 'react';
import moment from "moment";
import PropTypes from 'prop-types';

import {AlignItems, FlexDirection} from "worksmith/enums/CSSEnums";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {getRecurringString} from "worksmith/helpers/LanguageHelper";
import Grid from "../Grid/Grid.web";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";
import ScheduleSkeleton from "./ScheduleSkeleton.web";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ViewCustomScheduleDialog from "worksmith/composite-components/ScheduleOptionsForm/ViewCustomScheduleDialog";

const Schedule = (props) => {

    let [showCustomSchedule, setShowCustomSchedule] = useState(false);

    let {
        direction,
        loading,
        spacing,
        textColor,
        textVariant
    } = props;

    let alignItems = direction === FlexDirection.ROW ? AlignItems.CENTER : AlignItems.FLEX_START;
    let itemSpacing = direction === FlexDirection.ROW ? 1 : 0;

    if (loading) {
        return(
            <ScheduleSkeleton
                alignItems={alignItems}
                itemSpacing={itemSpacing}
                direction={direction}
                spacing={spacing}/>
        )
    }


    let {
        arrivalEndTime,
        arrivalStartTime,
        date,
        dayOfMonth,
        daysOfWeek,
        repeatEvery,
        recurrenceType,
        returnDate,
        scheduleDows,
    } = props.schedule;

    let scheduleDateTitle = "";
    if (ValueIsSet(returnDate)) {
        scheduleDateTitle = "Pickup Date"
    } else if (ValueIsSet(recurrenceType)) {
        scheduleDateTitle = "Start Date"
    }

    const handleViewCustomSchedule = () => {
        setShowCustomSchedule(() => !showCustomSchedule)
    };

    return (
        <Grid container
              direction={direction}
              alignItems={alignItems}
              spacing={spacing}>
            <Grid item>
                <Grid item container direction={direction} spacing={itemSpacing} alignItems={alignItems}>
                    {ValueIsSet(recurrenceType) || ValueIsSet(returnDate) ?
                        <Grid item>
                            <Text color={textColor} variant={textVariant}>{scheduleDateTitle}</Text>
                        </Grid>
                        :
                        null
                    }
                    <Grid item>
                        <Text color={textColor} variant={textVariant}>{moment(date).format(MomentFormat.DowMonthDay)}</Text>
                    </Grid>
                </Grid>
            </Grid>
            {
                ValueIsSet(returnDate) ?
                    <Grid item>
                        <Grid container direction={direction} spacing={itemSpacing} alignItems={alignItems}>
                            <Grid item>
                                <Text color={textColor} variant={textVariant}>Return Date</Text>
                            </Grid>
                            <Grid item>
                                <Text color={textColor} variant={textVariant}>{moment(returnDate).format(MomentFormat.DowMonthDay)}</Text>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    null
            }
            {
                ValueIsSet(recurrenceType) ?
                    <Grid item>
                        <Text color={textColor} variant={textVariant}>
                            {getRecurringString(date, recurrenceType, repeatEvery, dayOfMonth, daysOfWeek)}
                        </Text>
                    </Grid>
                    :
                    null
            }
            <Grid item>
                <Grid container direction={direction} spacing={itemSpacing} alignItems={alignItems}>
                    { scheduleDows && ValueIsSet(scheduleDows[0]) && ValueIsSet(scheduleDows[0].arrivalStartTime) ?
                        <Grid item>
                            <ViewCustomScheduleDialog buttonText={"Done"}
                                                      handleViewCustomSchedule={handleViewCustomSchedule}
                                                      open={showCustomSchedule}
                                                      schedule={props.schedule}
                                                      scheduleDows={scheduleDows}
                            />
                        </Grid>
                        :
                        <>
                            <Grid item>
                                <Text color={textColor} variant={textVariant}>Arrival Window</Text>
                            </Grid>
                            <Grid item>
                                {ValueIsSet(arrivalStartTime) &&  ValueIsSet(arrivalEndTime) ?
                                    <Text color={textColor} variant={textVariant}>
                                        {moment(arrivalStartTime, MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)
                                            + " - " +
                                            moment(arrivalEndTime, MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)}
                                    </Text>
                                    :
                                    <Text color={textColor} variant={textVariant}>Arriving any time of day</Text>
                                }
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
};

Schedule.propTypes = {
    direction: CustomPropTypes.enum(FlexDirection),
    loading: PropTypes.bool,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string.isRequired,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string,
        scheduleDows: PropTypes.arrayOf(PropTypes.shape({
            dayOfWeek: PropTypes.number,
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
        })),
    }),
    spacing: PropTypes.number,
    textColor: CustomPropTypes.enum(TextColor),
    textVariant: CustomPropTypes.enum(TextVariant)
};

Schedule.defaultProps = {
    direction: FlexDirection.COLUMN,
    spacing: 1
};

export default Schedule;