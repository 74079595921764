import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import Text, {LinkUnderline, TextColor, TextVariant} from "../../../../components/Text/Text.web";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import CreateScheduleOptions from "../../../ScheduleOptionsForm/CreateScheduleOptions";
import {Display} from "worksmith/enums/CSSEnums";
import CreateScheduleOptionsDialog from "../../../ScheduleOptionsForm/CreateScheduleOptionsDialog";
import UserAffiliationType from "../../../../enums/api/user/UserAffiliationType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers"

class SchedulingDeferredView extends Component {
    constructor(props) {
        super(props);

        this.state = {
           adminCreateScheduleModalOpen: false
        }
    }

    showCreateSchedulesModal = () => {
        this.setState({adminCreateScheduleModalOpen: true});
    }

    hideCreateSchedulesModal = () => {
        this.setState({adminCreateScheduleModalOpen: false});
    }

    render() {
        const {hideCreateSchedulesModal, showCreateSchedulesModal} = this;
        const {adminCreateScheduleModalOpen} = this.state;
        const {
            acceptedBy,
            duration,
            frequency,
            proposeSchedules,
            user,
            useTicketing,
            vendorLocation
        } = this.props;

        let description = null;
        let body = null;

        if (user.clientRoles && user.clientRoles.length > 0
            || acceptedBy.id === user.id) {
            description =
                `We notified the Local Managers that scheduling was deferred to them. Need to schedule on behalf of your local user? `;

            description = (
                <Fragment>
                    <Text display={Display.INLINE} variant={TextVariant.BODY_1}>
                        {description}
                        <Text
                            link
                            onClick={showCreateSchedulesModal}
                            display={Display.INLINE}
                            variant={TextVariant.INHERIT}
                            underline={LinkUnderline.ALWAYS}
                            semiBold
                            color={TextColor.SECONDARY}>
                            Click here
                        </Text>
                    </Text>
                </Fragment>
            )

            body = (
                <CreateScheduleOptionsDialog
                    duration={duration}
                    frequency={frequency}
                    useTicketing={useTicketing}
                    onCancel={hideCreateSchedulesModal}
                    onSubmit={(schedules) => {
                        hideCreateSchedulesModal();
                        proposeSchedules(schedules);
                    }}
                    open={adminCreateScheduleModalOpen}
                    title={ValueIsSet(frequency) ? 'Submit Start Date' : 'Submit Availability'}/>
            )

            return (
                <RequestStateLayout
                    description={description}
                    body={body}
                    status={TitleCase(NewRequestStatus.SCHEDULING) + ' - Deferred to Local Manager'}
                    statusColor={TextColor.PRIMARY}/>
            )

        } else {

           description = acceptedBy.affiliationType === UserAffiliationType.WORKSMITH ?  `${acceptedBy.displayName} (Worksmith) ` : acceptedBy.displayName;
           description += ` accepted ${vendorLocation.vendor.officialName}'s pricing and deferred scheduling to you. Please submit your availability below.`

           body = (
               <CreateScheduleOptions
                   duration={duration}
                   frequency={frequency}
                   useTicketing={useTicketing}
                   onSubmit={proposeSchedules}/>
           )

            return (
                <RequestStateLayout
                    description={description}
                    body={body}
                    status={TitleCase(NewRequestStatus.SCHEDULING) + ' - Submit Availability'}
                    statusColor={TextColor.ERROR}/>
            )
        }

    }
};

SchedulingDeferredView.propTypes = {
    acceptedBy: PropTypes.shape({
        id: PropTypes.number,
        displayName: PropTypes.string,
        affiliationType: CustomPropTypes.enum(UserAffiliationType)
    }),
    duration: PropTypes.number,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    proposeSchedules: PropTypes.func,
    user: PropTypes.shape({
        id: PropTypes.number,
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
    }),
    useTicketing: PropTypes.bool,
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

export default SchedulingDeferredView;