import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import * as ReactDOM from "react-dom";
import PropTypes from 'prop-types';

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MaterialSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import {PickColor} from "worksmith/helpers/ThemeHelpers";
import Radio from "@mui/material/Radio";
import {SelectType, SelectVariant} from "worksmith/components/Inputs/Select/Select.web";
import Text from "../../Text/Text.web";
import Tooltip, {TooltipPlacement} from 'worksmith/components/Tooltip/Tooltip.web';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

export const FormControlSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium'
});

class SelectView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelWidth: 0
        };

        this.inputLabelRef = React.createRef();
    }

    componentDidMount() {
        if (ValueIsSet(this.props.label) && (this.props.type === SelectType.DROPDOWN || !ValueIsSet(this.props.type)))
            this.setState({labelWidth: ReactDOM.findDOMNode(this.inputLabelRef.current).offsetWidth});
    }

    render() {
        const {
            disabled,
            endAdornment,
            error,
            fullWidth,
            helperText,
            includeNullOption,
            label,
            labelFieldName,
            name,
            nameless,
            noMaxWidth,
            noMinWidth,
            minWidth,
            onChange,
            onClick,
            options,
            placeholder,
            primary,
            secondary,
            size,
            testId,
            type,
            value,
            valueFieldName,
            variant
        } = this.props;

        const {inputLabelRef} = this;
        const {labelWidth} = this.state;

        let input = {};

        switch (variant) {
            case SelectVariant.OUTLINED:
                input.input = <OutlinedInput labelWidth={labelWidth}
                                             name={nameless ? undefined : ValueIsSet(name) ? name : label}
                                             label={label}
                                             data-testId={testId}
                />;
                break;
            case SelectVariant.SIMPLE_LINE:
            default:
                break;
        }

        if (type === SelectType.RADIO_BUTTONS)
            return (
                <StyledFormControl
                    disabled={disabled}
                    error={error}
                    fullWidth={fullWidth}
                    noMaxWidth={noMaxWidth}
                    size={size}
                >
                    <FormLabel component={Text}>
                        {label}
                    </FormLabel>
                    {options.map((option, index) => {
                        return (
                            <Fragment key={index}>
                                {option.toolTip ?
                                    <Tooltip placement={TooltipPlacement.BOTTOM_START} title={option.toolTip}>
                                        <FormControlLabel
                                            disabled={option.disabled}
                                            label={option[labelFieldName]}
                                            checked={value === option[valueFieldName]}
                                            onChange={() => onChange({
                                                target: {
                                                    value: option[valueFieldName]
                                                }
                                            })}
                                            control={
                                                <Radio inputProps={{"data-testId": option.testId}}
                                                       color={PickColor(primary, secondary)}
                                                />}
                                        />
                                    </Tooltip>
                                    :
                                    <FormControlLabel
                                        disabled={option.disabled}
                                        label={option[labelFieldName]}
                                        checked={value === option[valueFieldName]}
                                        onChange={() => onChange({
                                            target: {
                                                value: option[valueFieldName]
                                            }
                                        })}
                                        control={<Radio inputProps={{"data-testId": option.testId}}
                                                        color={PickColor(primary, secondary)}
                                        />}
                                    />
                                }
                                {ValueIsSet(option.helperText) ?
                                    <FormHelperText style={{marginTop: '-9px', marginBottom: '12px', marginLeft: '32px'}}>{option.helperText}</FormHelperText>
                                    :
                                    null
                                }
                            </Fragment>
                        )
                    })}
                </StyledFormControl>
            );
        else
            return (
                <Fragment>
                    <StyledFormControl
                        disabled={disabled}
                        error={error}
                        fullWidth={fullWidth}
                        minWidth={minWidth}
                        noMaxWidth={noMaxWidth}
                        noMinWidth={noMinWidth}
                        onClick={onClick}
                        size={size}
                        variant={variant}
                    >
                        {ValueIsSet(label) ?
                            <InputLabel ref={inputLabelRef}>{label}</InputLabel>
                            :
                            null
                        }
                        <MaterialSelect
                            variant="standard"
                            {...input}
                            endAdornment={endAdornment}
                            name={nameless ? undefined : ValueIsSet(name) ? name : label}
                            onChange={onChange}
                            placeholder={placeholder}
                            value={value}>
                            {includeNullOption ?
                                <MenuItem value={''}>-</MenuItem>
                                :
                                null
                            }
                            {options.map((option, index) =>
                                <MenuItem
                                    key={index}
                                    value={option[valueFieldName]}
                                    disabled={option.disabled}
                                >
                                    <Text testId={ValueIsSet(option.testId) ? option.testId : undefined}>{option[labelFieldName]}</Text>
                                </MenuItem>
                            )}
                        </MaterialSelect>
                        {ValueIsSet(helperText) ?
                            <FormHelperText>{helperText}</FormHelperText>
                            :
                            null
                        }
                    </StyledFormControl>
                </Fragment>
            );
    }
}

SelectView.defaultProps = {
    size: FormControlSize.MEDIUM
};

SelectView.propTypes = {
    disabled: PropTypes.bool,
    endAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    includeNullOption: PropTypes.bool,
    label: PropTypes.string,
    labelFieldName: PropTypes.string,
    name: PropTypes.string,
    nameless: PropTypes.bool,               //Only set if you don't want the input to be named. Useful if you don't want the field to auto-suggest input values
    minWidth: PropTypes.string,
    noMaxWidth: PropTypes.bool,             //Changes the max-width of the input field from '700px' to 'none'.
    noMinWidth: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            helperText: PropTypes.string,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
            value: PropTypes.any,
            testId: PropTypes.string
        })
    ),
    placeholder: PropTypes.string,
    primary: PropTypes.bool,                //Currently only affects the Radio Buttons view
    secondary: PropTypes.bool,              //Currently only affects the Radio Buttons view
    size: CustomPropTypes.enum(FormControlSize),
    type: CustomPropTypes.enum({
        DROPDOWN: 'dropdown',
        RADIO_BUTTONS: 'radio buttons'
    }),
    testId: PropTypes.string,
    value: PropTypes.any,
    valueFieldName: PropTypes.string,
    variant: CustomPropTypes.enum({
        SIMPLE_LINE: 'standard',
        OUTLINED: 'outlined'
    })
};

export default SelectView;

const StyledFormControl = styled(FormControl)`
  && {
    max-width: ${({noMaxWidth}) => noMaxWidth ? 'none' : '700px'};
    min-width: ${props => props.noMinWidth ? null : ValueIsSet(props.minWidth) ? props.minWidth : '197px'};
    position: relative;
    legend {
      border: none; // removes underline when label floats inline with field border
    }
  }
`;
