import React from 'react';
import PropTypes from 'prop-types';
import {IconType} from "worksmith/enums/MaterialEnums";
import styled from "styled-components";
import {Position} from "worksmith/enums/CSSEnums";
import {Color} from "worksmith/enums/Color";
import IconButton from 'worksmith/components/Button/IconButton';

const DialogCloseButton = (props) => {
    const {onClick} = props;

    return (
        <StyledCloseButton
            iconName={IconType.CLOSE}
            onClick={onClick}/>
    )
};

DialogCloseButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

const StyledCloseButton = styled(IconButton)`
    && {
      position: ${Position.ABSOLUTE};
      top:    0;
      right:  0;
      
      svg {
        background-color: ${Color.MEDIUM_GREY};
        fill: ${Color.WHITE};
        border-radius: 9999px;
      }
    }
`;

export default DialogCloseButton;