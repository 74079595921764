import React, {Component} from 'react';
import PropTypes from "prop-types";
import {ContractItemName} from "worksmith/enums/ContractEnums";
import LocationContractItemDetailsView from "./view/LocationContractItemDetailsView";
import {NoZeroCentCurrency, rateCardEntrySort} from "worksmith/helpers/GenericHelpers";


class LocationContractItemDetails extends Component {
    constructor(props) {
        super(props);

        this.state = this.buildDataSet(this.props.locationRateCard);
    }


    buildDataSet = (locationRateCard) => {
        let serviceLineId = locationRateCard.serviceLine.id;
        let rateCardEntries = locationRateCard.rateCardEntries;

        let contractItemsToDisplay = [];

        let hourlyRateIndex = rateCardEntries.findIndex(item => item.contractItem.name === ContractItemName.HOURLY_RATE);
        if(hourlyRateIndex !== -1) {
            let hourlyRateItem = {name: rateCardEntries[hourlyRateIndex].displayValue, sequencePosition: rateCardEntries[hourlyRateIndex].sequencePosition, value: "$" + NoZeroCentCurrency(rateCardEntries[hourlyRateIndex].unitRetailPrice) + "/hr"};
            rateCardEntries.splice(hourlyRateIndex,1);
            let tripChargeIndex= rateCardEntries.findIndex(item => item.contractItem.name === ContractItemName.TRIP_CHARGE);
            if(tripChargeIndex !== -1) hourlyRateItem.value = hourlyRateItem.value + ", $" + NoZeroCentCurrency(rateCardEntries[tripChargeIndex].unitRetailPrice) + " " + ContractItemName.TRIP_CHARGE;
            rateCardEntries.splice(tripChargeIndex,1);
            contractItemsToDisplay.push(hourlyRateItem);
        }

        let emergencyRateIndex = rateCardEntries.findIndex(item => item.contractItem.name === ContractItemName.EMERGENCY_RATE);
        if(emergencyRateIndex !== -1) {
            let emergencyRateItem = {name: rateCardEntries[emergencyRateIndex].displayValue, sequencePosition: rateCardEntries[emergencyRateIndex].sequencePosition, value: "$" + NoZeroCentCurrency(rateCardEntries[emergencyRateIndex].unitRetailPrice) + "/hr"};
            rateCardEntries.splice(emergencyRateIndex, 1);
            let emergencyTripChargeIndex = rateCardEntries.findIndex(item => item.contractItem.name === ContractItemName.EMERGENCY_TRIP_CHARGE);
            if(emergencyTripChargeIndex !== -1) emergencyRateItem.value += ", $"+NoZeroCentCurrency(rateCardEntries[emergencyTripChargeIndex].unitRetailPrice) + " " + ContractItemName.EMERGENCY_TRIP_CHARGE;
            rateCardEntries.splice(emergencyTripChargeIndex, 1);
            contractItemsToDisplay.push(emergencyRateItem);
        }

        rateCardEntries.forEach((remainingItem) => {
            contractItemsToDisplay.push({name: remainingItem.displayValue, sequencePosition: remainingItem.sequencePosition, value: "$" + NoZeroCentCurrency(remainingItem.unitRetailPrice)})
        });


        return {
            loading: false,
            rateCardEntriesToDisplay: contractItemsToDisplay.sort(rateCardEntrySort),
            serviceLineId: serviceLineId
        };
    };


    render() {
        let {loading, serviceLineId, rateCardEntriesToDisplay} = this.state;
        let {locationId} = this.props;

        return (
            <LocationContractItemDetailsView
                loading={loading}
                serviceLineId={serviceLineId}
                rateCardEntriesToDisplay={rateCardEntriesToDisplay}
                locationId={locationId}/>
        );
    }
}

LocationContractItemDetails.propTypes = {
    locationRateCard: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        serviceLine: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        }),
        rateCardEntries: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            unitRetailPrice: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            unitWholesalePrice: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            itemName: PropTypes.string.isRequired,
            contractItem: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired
            })
        }))
    })),
    locationId: PropTypes.number.isRequired
};

export default LocationContractItemDetails;
