import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import RequestStateLayout from "../RequestStateLayout";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import {TitleCase} from "../../../../helpers/LanguageHelper";
import Text, {TextColor} from "../../../../components/Text/Text.web";
import Schedule from "../../../../components/Schedule/Schedule.web";
import Grid from "../../../../components/Grid/Grid.web";
import {JustifyContent} from "../../../../enums/CSSEnums";
import Button, {ButtonSize} from "../../../../components/Button/Button.web";
import CreateScheduleOptionsDialog from "../../../ScheduleOptionsForm/CreateScheduleOptionsDialog";
import {Debounce, ValueIsSet} from "worksmith/helpers/GenericHelpers"
import UserType from "worksmith/enums/api/user/UserType";

const createOptionsGridItem = (key, body, onClick) => (
    <Grid
        container
        item
        justify={JustifyContent.SPACE_BETWEEN}
        xs={12}
        key={key}>
        <Grid item xs={12} sm={8}>
            {body}
        </Grid>
        <Grid item xs={12} sm={4}>
            <Button
                primary
                onClick={onClick}
                size={ButtonSize.SMALL}>
                Select
            </Button>
        </Grid>
    </Grid>
);

class SchedulingVendorSubmittedAvailability extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counterOfferModalOpen: false
        }
    }

    showCounterOfferModal = () => {
        this.setState({counterOfferModalOpen: true});
    };

    hideCounterOfferModal = () => {
        this.setState({counterOfferModalOpen: false});
    };

    render() {
        const {hideCounterOfferModal, showCounterOfferModal} = this;
        const {counterOfferModalOpen} = this.state;
        const {
            acceptSchedule,
            duration,
            frequency,
            loading,
            proposeSchedules,
            scheduleHandler,
            useTicketing
        } = this.props;

        return (
            <Fragment>
            <CreateScheduleOptionsDialog
                duration={duration}
                frequency={frequency}
                onSubmit={(schedules) => {
                    hideCounterOfferModal();
                    proposeSchedules(schedules);
                }}
                numberOfSchedules={2}
                numberOfRequiredSchedules={1}
                onCancel={hideCounterOfferModal}
                open={counterOfferModalOpen}
                title={ValueIsSet(frequency) ? "Submit Start Date" : "Submit Availability"}
                userType={UserType.CLIENT}
                useTicketing={useTicketing}/>
            <RequestStateLayout
                description={"Please accept a time or propose a new one."}
                loading={loading}
                body={
                    <Grid container spacing={3} maxWidth={500}>
                        {
                            scheduleHandler.openScheduleOptions.map(scheduleOption => (
                                createOptionsGridItem(
                                    scheduleOption.id,
                                    <Schedule
                                        schedule={scheduleOption.schedule}/>,
                                    () => Debounce(acceptSchedule(scheduleOption.id), 400, null)
                                )
                            ))
                        }
                        {
                            createOptionsGridItem(
                                0,
                                <Text>Propose a different time</Text>,
                                showCounterOfferModal
                            )
                        }
                    </Grid>
                }
                status={TitleCase(NewRequestStatus.SCHEDULING) + ' - Vendor Submitted Availability'}
                statusColor={TextColor.ERROR}/>
            </Fragment>
        )
    }
}

SchedulingVendorSubmittedAvailability.propTypes = {
    acceptSchedule: PropTypes.func.isRequired,
    duration: PropTypes.number,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
    }),
    loading: PropTypes.bool,
    proposeSchedules: PropTypes.func.isRequired,
    scheduleHandler: PropTypes.shape({
        openSchedules: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        })),
    }),
    useTicketing: PropTypes.bool
};

export default SchedulingVendorSubmittedAvailability;