import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {withTheme} from '../../Theme/ThemeProvider';
import SelectView from "./SelectView.web";
import styled from "styled-components";
import {Color} from "worksmith/enums/Color";

export const SelectVariant = Object.freeze({
    SIMPLE_LINE: 'standard',
    OUTLINED: 'outlined'
});

export const SelectType = Object.freeze({
    DROPDOWN: 'dropdown',
    RADIO_BUTTONS: 'radio buttons'
});

export const SelectViewSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium'
});

const StyledSelectViewContainer = styled.div`
  position: relative;
`

const StyledAsterisk = styled.span`
    color: ${Color.RED};
    position: absolute;
    font-family: 'Open Sans';
    top: 0px;
    left: -12.5px;
`

class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ValueIsSet(props.initialValue) ?
                props.initialValue
                :
                props.includeNullOption ?
                    ''
                    :
                    props.options.length > 0 ?
                        props.options[0][props.valueFieldName]
                        :
                        ''
        };

    }

    componentDidUpdate = (prevProps) => {
        if (this.props.initialValue && prevProps.initialValue !== this.props.initialValue) {
            this.setState({
                value: this.props.initialValue
            })
        }
    };

    onChange = (event) => {
        this.setState(
            {value: event.target.value}, () => {
                if (ValueIsSet(this.props.onChange))
                    this.props.onChange(this.state.value);
            }
        );
    };

    clear = () => {
        this.setState({value: ''});
    };

    reset = (value) => {
      this.setState({value: value ? value : this.props.initialValue})
    };

    render() {
        let {onChange} = this;
        let {value} = this.state;
        let {
            disabled,
            endAdornment,
            error,
            fullWidth,
            helperText,
            includeNullOption,
            label,
            name,
            nameless,
            minWidth,
            noMaxWidth,
            noMinWidth,
            onClick,
            options,
            placeholder,
            primary,
            secondary,
            showRequiredAsterisk,
            type,
            testId,
            variant,
            valueFieldName,
            labelFieldName,
            viewSize
        } = this.props;

        return (
            // TODO: use TextField as comp
            <StyledSelectViewContainer>
                {ValueIsSet(showRequiredAsterisk) ? <StyledAsterisk>*</StyledAsterisk> : null}
                <SelectView disabled={disabled}
                            endAdornment={endAdornment}
                            error={error}
                            fullWidth={fullWidth}
                            helperText={helperText}
                            includeNullOption={includeNullOption}
                            label={label}
                            labelFieldName={labelFieldName}
                            minWidth={minWidth}
                            name={name}
                            noMaxWidth={noMaxWidth}
                            noMinWidth={noMinWidth}
                            nameless={nameless}
                            onChange={onChange}
                            onClick={onClick}
                            options={options}
                            placeholder={placeholder}
                            primary={primary}
                            secondary={secondary}
                            size={viewSize}
                            type={type}
                            testId={testId}
                            value={value}
                            valueFieldName={valueFieldName}
                            variant={variant}
                />
            </StyledSelectViewContainer>

        )
    }
}

Select.propTypes = {
    disabled: PropTypes.bool,
    endAdornment: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    includeNullOption: PropTypes.bool,
    initialValue: PropTypes.any,
    label: PropTypes.string,
    labelFieldName: PropTypes.string,
    name: PropTypes.string,
    nameless: PropTypes.bool,
    minWidth: PropTypes.string,
    noMaxWidth: PropTypes.bool,                               //Changes the max-width of the input field from '700px' to 'none'.
    noMinWidth: PropTypes.bool,                               //Only set if you don't want the input to be name. Useful if you don't want the field to auto-suggest input values
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            helperText: PropTypes.string,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
            value: PropTypes.any,
            disabled: PropTypes.bool,
            toolTip: PropTypes.element
        })
    ),
    placeholder: PropTypes.string,
    primary: PropTypes.bool,                //Currently only affects the Radio Buttons view
    secondary: PropTypes.bool,              //Currently only affects the Radio Buttons view
    showRequiredAsterisk: PropTypes.bool,
    type: CustomPropTypes.enum(SelectType),
    testId: PropTypes.string,
    valueFieldName: PropTypes.string,
    variant: CustomPropTypes.enum(SelectVariant),
    viewSize: CustomPropTypes.enum(SelectViewSize)
};

Select.defaultProps = {
    options: [],
    type: SelectType.DROPDOWN,
    variant: SelectVariant.OUTLINED,
    valueFieldName: 'value',
    labelFieldName: 'label',
    viewSize: SelectViewSize.MEDIUM
};

export default withTheme(Select);
