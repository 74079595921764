import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';
import Icon, {IconFontSize} from '../Icon/Icon'

import {withTheme} from '../Theme/ThemeProvider';
import {PickColor} from "worksmith/helpers/ThemeHelpers";
import {Debounce} from "worksmith/helpers/GenericHelpers";
import {Color} from "worksmith/enums/Color"
import styled from "styled-components";

const StyledButton = styled(MaterialButton)`
    && {
        min-width: 30px;
        width: 30px;
        height: 30px;
        background-color: ${({add}) => add ? Color.INCREMENT_LIGHT_BG : Color.OFF_WHITE};
        margin-left: ${({add}) => add ? '16px': 0};
        margin-right: ${({add}) => add ? 0 : '16px'};
        
        &:hover {
            background-color: ${({add}) => add ? Color.SLA_LIGHT_BLUE : null}
        }
    }
`

const Button = React.forwardRef((props, ref) => {
    let {
        add,
        component,
        debounce,
        disabled,
        endIcon,
        fullWidth,
        onClickIgnoreDebounce,
        onMouseLeave,
        onMouseOver,
        primary,
        size,
        secondary,
        startIcon,
        variant
    } = props;

    let onClick = (event) => {
        event.stopPropagation();
        props.onClick(event);
    };

    if (debounce)
        onClick = Debounce(onClick, 400, onClickIgnoreDebounce);


    return (
        <StyledButton
            add={add}
            color={PickColor(primary, secondary)}
            className={props.className}
            component={component}
            disabled={disabled}
            endIcon={endIcon}
            fullWidth={fullWidth}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseOver}
            size={'small'}
            startIcon={startIcon}
            ref={ref}
            variant={'text'}>
            {add ? <Icon fontSize={IconFontSize.SMALL} secondary name={'Add'} /> : <Icon fontSize={IconFontSize.SMALL} name={'Remove'} />}
        </StyledButton>
    )
});

Button.propTypes = {
    add: PropTypes.bool,
    component: PropTypes.string,                    //this is used to tell what component type it is (default: button). Another option includes span
    debounce: PropTypes.bool,
    disabled: PropTypes.bool,
    endIcon: PropTypes.node,
    fullWidth: PropTypes.bool,
    incrementer: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onClickIgnoreDebounce: PropTypes.func,
    onMouseLeave: PropTypes.func,                   //this allows support for wrapping buttons with Tooltips
    onMouseOver: PropTypes.func,                    //this allows support for wrapping buttons with Tooltips
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    startIcon: PropTypes.node,
};

export default withTheme(Button);