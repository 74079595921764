import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import Select, {SelectType} from "../../components/Inputs/Select/Select.web";
import Schedule from "../../components/Schedule/Schedule.web";
import {TextVariant} from "../../components/Text/Text.web";
import AlertDialog from "../../components/Dialog/AlertDialog";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import SelectScheduleForm from "../SelectSchedules/SelectScheduleForm";

class SelectScheduleModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            selectedSchedule: null
        };
    }

    onFormChange= (schedule) => {
        this.setState({selectedSchedule: schedule});
    }

    setLoading = (loading) => {
        this.setState({loading});
    }


    selectSchedules = () => {
        let {selectedSchedule}  = this.state;

        this.props.selectSchedules(selectedSchedule);
        this.props.closeModal();
    }

    render() {
        const {selectSchedules, setLoading, onFormChange} = this;
        const {loading, selectedSchedule} = this.state;
        const {closeModal, scheduleHandler, open} = this.props;

        return (
            <AlertDialog
                acceptDisabled={!ValueIsSet(selectedSchedule) || loading}
                acceptText={'Submit'}
                cancelText={'Cancel'}
                debounceAccept
                fullScreenOnMobile
                onAccept={() => {
                    selectSchedules();
                    closeModal();
                }}
                onAcceptIgnoreDebounce={() => setLoading(true)}
                onCancel={closeModal}
                title={'Select a Time'}
                open={open}>
                <SelectScheduleForm
                    initialScheduleHandler={scheduleHandler}
                    onChange={onFormChange}/>
            </AlertDialog>
        )
    }
}

SelectScheduleModal.propTypes = {
    selectSchedules: PropTypes.func.isRequired,
    scheduleHandler: PropTypes.shape({
        id: PropTypes.number,
        openScheduleOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            isExpired: PropTypes.bool,
            schedule: PropTypes.shape({
                arrivalEndTime: PropTypes.string,
                arrivalStartTime: PropTypes.string,
                date: PropTypes.string,
                dayOfMonth: PropTypes.bool,
                daysOfWeek: PropTypes.arrayOf(PropTypes.number),
                duration: PropTypes.number,
                recurrenceType: CustomPropTypes.enum(RecurrenceType),
                repeatEvery: PropTypes.number,
                returnDate: PropTypes.string
            }),
            status: PropTypes.string
        }))
    }),
    open: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default SelectScheduleModal;