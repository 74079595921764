import React, {useState} from 'react'
import PropTypes from "prop-types";

import Collapse from "worksmith/components/Collapse/Collapse";
import Grid from "worksmith/components/Grid/Grid";
import {TextDecoration} from "worksmith/enums/CSSEnums";
import Text, {LinkUnderline, TextColor} from "worksmith/components/Text/Text";
import ClientRoutes from "../../ClientRoutes";

const CollapsableLocations = ({locations}) => {

    const [collapsed, setCollapsed] = useState(true);

    const renderLocation = (location) => {
        return (<Text href={"#/" + ClientRoutes.CLIENT_LOCATION(location.id)}
                    underline={LinkUnderline.ALWAYS}
                    link color={TextColor.SECONDARY}
                    textDecoration={TextDecoration.UNDERLINE}>
                    {location.title}
                </Text>)
    };

    return (
        <Grid width={'100%'}>

            {locations != null && locations.length > 0 ?
                locations.length === 1 ? renderLocation(locations[0])
                    :
                    <Collapse
                        buttonRight expanded={!collapsed}
                        justifySpaceBetween
                        label={renderLocation(locations[0])}
                        onExpand={() => setCollapsed(!collapsed)}
                    >
                        <Grid item xs={12}>
                            {locations.slice(1).map((location) => {
                                return <Grid margin={'0 0 0.5rem 0'}>
                                    {renderLocation(location)}
                                </Grid>
                            })}
                        </Grid>

                    </Collapse>
                :
                null
            }
        </Grid>
    );
};

export default CollapsableLocations;

CollapsableLocations.prototype = {
    locations: PropTypes.arrayOf({
        id: PropTypes.number,
        title: PropTypes.string
    })
};