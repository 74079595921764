import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import Accordion from "../../components/Accordion/Accordion";
import ProposalHeader from "../ProposalHeader/ProposalHeader";
import ProposalHeaderSkeleton from "../ProposalHeader/ProposalHeaderSkeleton";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import {Display, JustifyContent} from "../../enums/CSSEnums";
import CircularProgress from "../../components/Loader/CircularProgress";
import BidDetails from "../BidDetails/BidDetails";

class BidListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expansionPanelStates: [],
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevProps.loading && this.props.loading) {
            this.setState({
                expansionPanelStates: [],
            })
        }

        if (prevProps.loading && !this.props.loading) {
            let newExpansionPanelStates = [];
            let exactlyOneBid = this.props.bids.length === 1;
            this.props.bids.forEach((bid) => {
                newExpansionPanelStates.push(
                    {open: exactlyOneBid, hasBeenOpened: exactlyOneBid}
                )
            });
            this.setState({expansionPanelStates: newExpansionPanelStates})
        }
    }

    onExpansionPanelClick = (index) => {
        const {expansionPanelStates} = this.state;

        let newExpansionPanelStates = expansionPanelStates.slice();

        newExpansionPanelStates[index].open = !newExpansionPanelStates[index].open;
        newExpansionPanelStates[index].hasBeenOpened = true;
        this.setState({expansionPanelStates: newExpansionPanelStates})
    }

    render() {
        const {onExpansionPanelClick} = this;
        const {expansionPanelStates} = this.state;
        const {
            bids,
            isAdmin,
            isRegionalManager,
            isEstimate,
            loading,
            numberOfActiveBids,
            onBidAction,
            startLoad,
        } = this.props;

        let bidExpansionPanels = [];

        if (loading) {
            if (!ValueIsSet(numberOfActiveBids)) {
                bidExpansionPanels.push(
                    <Box display={Display.FLEX} justifyContent={JustifyContent.CENTER}>
                        <CircularProgress/>
                    </Box>
                )
            } else {
                for (let loadingBid = 0; loadingBid < numberOfActiveBids; loadingBid++) {
                    bidExpansionPanels.push(
                        <Accordion
                            details={<Fragment/>}
                            key={loadingBid}
                            summary={<ProposalHeaderSkeleton/>}
                            expanded={false}
                            expandIcon={false}/>
                    )
                }
            }
        } else {
            bidExpansionPanels =
                bids.map((bid, index) => (
                    <Accordion
                        key={bid.id}
                        summary={
                            <ProposalHeader
                                additionalApprovalAmount={bid.additionalApprovalAmount}
                                hasPreferredVendor={bid.opportunity.hasPreferredVendor}
                                status={bid.status}
                                totalRetailPrice={bid.totalRetailPrice}
                                vendorLocation={bid.opportunity.vendorLocation}/>
                        }
                        details={
                            expansionPanelStates.length > 0
                            && expansionPanelStates[index].hasBeenOpened ?
                            <BidDetails
                                bidId={bid.id}
                                isAdmin={isAdmin}
                                isRegionalManager={isRegionalManager}
                                isEstimate={isEstimate}
                                onAccept={onBidAction}
                                onDecline={onBidAction}
                                onRequestCancel={onBidAction}
                                onRequestRevision={onBidAction}
                                startLoad={startLoad}/>
                                :
                            <Fragment/>
                        }
                        elevation={2}
                        expanded={expansionPanelStates.length > 0 && expansionPanelStates[index].open}
                        expandIcon={false}
                        onChange={() => {
                            onExpansionPanelClick(index);
                        }}/>
                ))
        }


        return (
            bidExpansionPanels
        )
    }
};

BidListView.defaultProps = {
   bids: []
}

BidListView.propTypes = {
    isAdmin: PropTypes.bool,
    isRegionalManager: PropTypes.bool,
    isEstimate: PropTypes.bool,
    loading: PropTypes.bool,
    numberOfActiveBids: PropTypes.number,
    bids: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        additionalApprovalAmount: PropTypes.number,
        opportunity: PropTypes.shape({
            hasPreferredVendor: PropTypes.bool,
            vendorLocation: PropTypes.shape({
                rating: PropTypes.number,
                ratingsCount: PropTypes.number,
                vendor: PropTypes.shape({
                    officialName: PropTypes.string,
                    qualifiesForPremiumBadge: PropTypes.bool,
                    vendorBio: PropTypes.shape({
                        aboutUs: PropTypes.string
                    })
                })
            }).isRequired,
        }),
        status: PropTypes.string,
        totalRetailPrice: PropTypes.number.isRequired
    })),
    onBidAction: PropTypes.func,
    startLoad: PropTypes.func,
};

export default BidListView;