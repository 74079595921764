import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "../../Grid/Grid.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Text, {TextVariant} from "../../Text/Text.web";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import Card from "../../Card/Card.web";
import styled from "styled-components";
import Button, {ButtonVariant} from "../../Button/Button.web";
import {JustifyContent, Display} from "worksmith/enums/CSSEnums";

const StyledCard = styled(Card)`
    min-width: 280px;
    max-width: 1000px;
    
    min-height: 97px;
    max-height: calc(60% + 120px);
`;

const FlexDiv = styled.div`
    display: ${Display.FLEX}
    flex-direction: column;
    padding: 16px 16px 0 16px;
`;

const TitleDiv = styled.div`
    margin-bottom: 8px;
`;

const BodyDiv = styled.div`
    margin-bottom: 20px;
    max-height: 80vh;
    overflow-y: scroll;
    
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
`;

const ButtonsDiv = styled.div`
    margin-top: 8px;
`;

const AlertModalTemplate = (props) => {
    let {
        body,
        buttons,
        hideCancel,
        onCancel,
        onOk,
        title
    } = props;

    return (
        <StyledCard>
            <FlexDiv>
                {ValueIsSet(title) ?
                    <TitleDiv>
                        {typeof title === JavascriptType.STRING ?
                            <Text variant={TextVariant.H6}>{title}</Text>
                            :
                            title
                        }
                    </TitleDiv>
                    :
                    null
                }
                <BodyDiv>
                    {typeof body === JavascriptType.STRING ?
                        <Text>{body}</Text>
                        :
                        body
                    }
                </BodyDiv>
                <ButtonsDiv>
                    <Grid container justify={JustifyContent.FLEX_END}>
                        {buttons ?
                            buttons.map((button, index) => {
                                return <Grid key={index} item>
                                    {button}
                                </Grid>
                            })
                            :
                            <Fragment>
                                {
                                    hideCancel ?
                                        null
                                        :
                                        <Grid item>
                                            <Button onClick={onCancel}
                                                    variant={ButtonVariant.TEXT}>Cancel</Button>
                                        </Grid>
                                }
                                <Grid item>
                                    <Button variant={ButtonVariant.TEXT}
                                            onClick={onOk}
                                            primary>Ok</Button>
                                </Grid>
                            </Fragment>
                        }
                    </Grid>
                </ButtonsDiv>
            </FlexDiv>
        </StyledCard>
    );
};

AlertModalTemplate.defaultProps = {
    hideCancel: false
};

AlertModalTemplate.propTypes = {
    body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    buttons: PropTypes.arrayOf(PropTypes.element),          //use this if you want to add completely custom buttons to the bottom of the modal
    hideCancel: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ])
};

export default AlertModalTemplate;