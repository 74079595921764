import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {Debounce, ValueIsSet} from "../../helpers/GenericHelpers";
import Grid from "../../components/Grid/Grid";
import Select from "../../components/Inputs/Select/Select";
import TextField from "../../components/Inputs/TextField/TextField";
import Text from "../../components/Text/Text.web";
import BidService from "../../services/api/BidService";
import {Uppercase} from "../../helpers/LanguageHelper";

const bidService = new BidService();

class DeclineBidForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            bidDeclineReasonOptions: [],
            declineReason: null,
            loading: true,
            requireCustomReason: false,
            valid: false
        };

        this.customReason = "";
    }

    componentDidMount = () => {
        const {bidId} = this.props;

        this.setState({loading: true});
        bidService.getDeclineReasons(bidId)
            .then((data) => {
                let declineReasonOptions = data.map(reason => (
                    {label: Uppercase(reason.description), value: reason}
                ));

                this.setState({declineReasonOptions, loading: false});
            })
    }

    onDeclineReasonChange = (declineReason) => {
        const {valid, requireCustomReason} = this.state;

        let newState = {
            declineReason
        };

        newState.valid = this.isValid(
            declineReason,
            this.customReason,
        );

        this.setState(newState, this.onChange);
    };

    onCustomReasonChange = (customReasonValue) => {
        const {valid, declineReason} = this.state;
        let newState = {};

        this.customReason = customReasonValue;
        newState.valid = this.isValid(declineReason, customReasonValue);

        if (valid !== newState.valid) {
            this.setState(newState, this.onChange);
        } else {
            this.onChange();
        }
    };

    onChange = () => {
        const {declineReason, valid} = this.state;

        this.props.onChange({
            declineReason: declineReason,
            customReason: this.customReason,
            valid
        });
    };

    isValid = (declineReason, customReason) => {
        if (declineReason) {
            if (declineReason.canRespond) {
                return ValueIsSet(customReason) && customReason.trim() !== '';
            }

            return true;
        }

        return false;
    };

    render() {
        let {onCustomReasonChange, onDeclineReasonChange} = this;
        const {
            loadingForm,
            spacing
        } = this.props;
        const {
            declineReason,
            declineReasonOptions,
            loading,
        } = this.state;

        let debouncedOnChange = Debounce(onCustomReasonChange, 50);

        return (
            <Grid
                container
                spacing={spacing}>
                <Grid item xs={12}>
                    <Select
                        disabled={loading || loadingForm}
                        fullWidth
                        label={'Reason'}
                        options={declineReasonOptions}
                        onChange={onDeclineReasonChange}/>
                </Grid>
                {
                   declineReason
                   && (declineReason.followUp || declineReason.canRespond)
                   && !loading ?
                       <Grid item xs={12}>
                           {
                               declineReason.followUp ?
                                   <Text>
                                       {declineReason.followUp}
                                   </Text>
                                   :
                                   null
                           }
                           {
                               declineReason.canRespond ?
                                   <TextField
                                       disabled={loading || loadingForm}
                                       fullWidth
                                       multiline
                                       onChange={debouncedOnChange}/>
                                   :
                                   null
                           }
                       </Grid>
                       :
                       null
                }
            </Grid>
        )
    }
}

DeclineBidForm.defaultProps = {
    loadingForm: false,
    spacing: 2
};

DeclineBidForm.propTypes = {
    bidId: PropTypes.number.isRequired,
    loadingForm: PropTypes.bool,
    spacing: PropTypes.number,
    onChange: PropTypes.func.isRequired
};

export default DeclineBidForm;