import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SingleInvoiceLineSummary from "./SingleInvoiceLineSummary";
import Divider, {DividerVariant} from "worksmith/components/Divider/Divider.web";
import CollapseBar from "worksmith/components/Collapse/Informational/CollapseBar";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import moment from "moment";

const Section = styled.div`
    margin: 0 2em;
`;

const DividerSection = styled.div`
    margin: .5em 0;
`;

const InvoiceItemList = styled.div`
    margin-left: 2em;
    margin-top: .5em;
`;

const InvoiceSectionByDate = (props) => {
    let {invoices, linkToInvoice, title} = props;

    const dateTitle = moment(title);

    if(dateTitle.isValid())
        title = dateTitle.format("MM/DD/YYYY");

    const singleInvoiceItemList = invoices.map(invoice => {
        const location     = ValueIsSet(invoice.receivable) ? invoice.receivable.clientLocation.title : 'N/A';
        const receivableId = ValueIsSet(invoice.receivable) ? invoice.receivable.id : null;
        return <SingleInvoiceLineSummary amount={invoice.amount}
                                         date={moment(invoice.transactionDate)}
                                         dueDate={moment(invoice.dueDate)}
                                         invoiceNumber={invoice.referenceNumber}
                                         key={invoice.id}
                                         linkToInvoice={ValueIsSet(receivableId) ? () => linkToInvoice(receivableId) : null}
                                         location={location}
                                         openBalance={invoice.openBalance}
                                         type={invoice.transactionType}/>});

    return <Fragment>
        {ValueIsSet(invoices) && invoices.length > 0 ?
            <Fragment>
                <Section>
                    <CollapseBar label={title}>
                        <InvoiceItemList>
                            {singleInvoiceItemList}
                        </InvoiceItemList>
                    </CollapseBar>
                </Section>
                <DividerSection>
                    <Divider variant={DividerVariant.FULL_WIDTH}/>
                </DividerSection>
            </Fragment>
            : null}
            </Fragment>;
};

InvoiceSectionByDate.propTypes = {
    invoices:      PropTypes.arrayOf(PropTypes.shape({
                        amount: PropTypes.number,
                        dueDate: PropTypes.string,
                        id: PropTypes.number,
                        openBalance: PropTypes.number,
                        receivable: PropTypes.object,
                        referenceNumber: PropTypes.string,
                        transactionDate: PropTypes.string,
                        transactionType: PropTypes.string
                    })),
    linkToInvoice: PropTypes.func.isRequired,
    title:         PropTypes.string
};

export default InvoiceSectionByDate;