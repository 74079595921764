import React from 'react';

export const GlobalContext = React.createContext();

export const WithContext = (Component) => {
    return React.forwardRef((props, ref) => (
        <GlobalContext.Consumer>
            {context => <Component ref={ref} {...props} context={context}/>}
        </GlobalContext.Consumer>
    ))
};