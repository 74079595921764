import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Loader from "../Loader";

import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import MarketingService from 'worksmith/services/api/MarketingService';

const userService = new UserService();
const marketingService = new MarketingService;
const authTokenManager = new AuthTokenManager();

const PresignedCheck = (props) => {
    let {onLogin, history, marketingParams} = props;

    let token = props.params.token;
    let redirectUrl = props.params.redirectUrl;

    userService.validateToken(token).then((data) => {
        authTokenManager.storeAuthData(data);
        if (ValueIsSet(marketingParams)) {
            marketingService.trackLogin(data.userId,
                marketingParams.utm_campaign,
                marketingParams.utm_content,
                marketingParams.utm_medium,
                marketingParams.utm_term,
                marketingParams.utm_source)
                .catch(() => {
                    console.log("Error occurred while saving tracking data");
                });
        }
        history.replace(redirectUrl);
        onLogin();
    }).catch(function () {
        if(authTokenManager.isAuthorized()){
            history.replace(redirectUrl);
        }else {
            history.push('/');
        }
    });

    return <Loader/>
};

PresignedCheck.propTypes = {
    onLogin: PropTypes.func.isRequired
};

export default withRouter(PresignedCheck);