import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SingleInvoicePageView from "./SingleInvoicePageView";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {DisplayErrorNotification} from "../../../helpers/SweetAlertHelpers";

const graphQLService = new GraphQLServiceClass();

class SingleInvoicePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            receivable: null
        };
    }

    componentDidMount() {
        const {id} = this.props;

        graphQLService.findAll({id: Number(id)},
            GraphQLObjectType.RECEIVABLE,
            SingleInvoicePage.receivableQueryFields).then(receivable => {
                this.setState({loading: false, receivable: receivable[0]});
        }).catch((error) => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification(error.toString());
            this.setState({loading: false});
        });
    }

    getPdf = () => {
        const {receivable} = this.state;

        window.open(receivable.presignedUrl,'_blank');
    };

    render() {
        const {getPdf} = this;
        const {loading, receivable} = this.state;
        const {linkTo} = this.props;

        return <SingleInvoicePageView getPdf={getPdf}
                                      linkTo={linkTo}
                                      loading={loading}
                                      receivable={receivable}/>;
    }
}

SingleInvoicePage.receivableQueryFields = `
    activeReceivableItems {
        pricingItem {
            obligationItem {
                reasonCode
            }
        }
        description
        effectiveTaxRate
        id
        lineItemType {
            name
        }
        obligation {
            id
            requestId
            vendor {
                nickname
            }
        }
        quantity
        referenceNumber
        serviceDate
        unitDiscount
        unitPrice
    }
    billingProfile {
        addressLineOne
        addressLineTwo
        city
        client {
            nickname
        }
        name
        paymentTerms
        state
        zip
    }
    clientLocation {
        addressLineOne
        addressLineTwo
        city
        locationCode
        state
        title
        zip
    }
    effectiveTax
    grossRetail
    netRetail
    presignedUrl
    receivableDate
    receivableNumber
    total
    totalDiscount
    poNumber
`;

SingleInvoicePage.propTypes = {
    id:     CustomPropTypes.stringOrNumber.isRequired,
    linkTo: PropTypes.func.isRequired,
};

export default SingleInvoicePage;