import React, {Component,} from 'react';
import PropTypes from 'prop-types'
import {GraphQLSortDirection} from "worksmith/enums/GraphQLSortDirection";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import ObligationStatus from "worksmith/enums/api/task/ObligationStatus";
import ChangeRequestService from "worksmith/services/api/ChangeRequestService";
import ChangeRequestType from "../../shared/enums/api/cr/ChangeRequestType";
import RecurringServiceView from "./RecurringServiceView";
import {DisplaySuccessNotification} from "worksmith/helpers/SweetAlertHelpers";

const graphQLService = new AsyncGraphQLServiceClass();
const changeRequestService = new ChangeRequestService();

class RecurringService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            mostRecentCompletedJob: null,
            templateChangeRequest: null,
            changeOrderFilter: false,
            obligationChangeRequests: [],
            showScheduleOptionsDialog: false,
            filter: ObligationStatus.PENDING,
            filterText: "Scheduled",
            page: 0
        };
    }

    componentDidMount = async () => {
        let {getMostRecentCompletedJob, getOpenTemplateChangeRequest} = this;
        await Promise.all([ getMostRecentCompletedJob(), getOpenTemplateChangeRequest()]).then(
            data => {
                this.setState({
                    mostRecentCompletedJob: data[0],
                    templateChangeRequest: data[1],
                    loading: false})
            }
        );
    };

    counterChangeRequest = async (id, schedules) => {

        let {obligationTemplate} = this.props;
        let {reloadChangeRequest} = this;

        let changeRequest = {
            id: id,
            scheduleHandler: {
                scheduleOptions: schedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        changeRequest.obligationTemplate = {
            id: obligationTemplate.id
        };


       await changeRequestService.counterChangeRequest(id, changeRequest);
       await reloadChangeRequest;
    } ;

    getMostRecentCompletedJob = async () => {
        let {obligationTemplate} = this.props;

        let searchParams = {
            obligationTemplateId: obligationTemplate.id,
            status: ObligationStatus.COMPLETED
        };

        let pageData = await graphQLService.findPage(
            0,
            1,
            'obligationDate',
            GraphQLSortDirection.DESCENDING,
            searchParams,
            GraphQLObjectType.OBLIGATION,
            obligationFields);
        if(pageData.content.length !== 0){
            return pageData.content[0];
        }
        return null;


    };

    getOpenTemplateChangeRequest = async () => {
        let {obligationTemplate} = this.props;

        let searchParams = {
            obligationTemplateId: obligationTemplate.id,
            isOpen: true,
        };

        let changeRequestData = await graphQLService.findAll(searchParams,
            GraphQLObjectType.CHANGE_REQUEST,
            changeRequestFields,
            'createdTimestamp',
            GraphQLSortDirection.DESCENDING
            );

        // there can only be one open change request per obligation template
        return changeRequestData[0];

    };

    reloadChangeRequest = async () => {
        this.setState({loading: true});
        let openChangeRequest = await this.getOpenTemplateChangeRequest();
        this.setState({loading: false, templateChangeRequest:openChangeRequest});
    };

    onFilterChange = async (obligationStatus) => {
        switch(obligationStatus){
            case ObligationStatus.PENDING:
                 await this.setState({filter: ObligationStatus.PENDING});
                break;
            case ObligationStatus.COMPLETED:
                 await this.setState({filter: ObligationStatus.COMPLETED});
                break;
            case "All":
                 await this.setState({filter: null})
        }
    };

    acceptChangeRequest = (changeRequestId) => {
        let {reloadChangeRequest} = this;
        let {reload} = this.props;

        changeRequestService.accept(changeRequestId).then(async () => {
            await reloadChangeRequest();
            reload();
            DisplaySuccessNotification("Reschedule Request Accepted.").then();
        }
        )
    };

    cancelChangeRequest = (changeRequestId) => {
        let {reloadChangeRequest} = this;
        let {reload} = this.props;

        changeRequestService.cancel(changeRequestId).then(async () => {
                reload();
                await Promise.all([reloadChangeRequest()]);
                DisplaySuccessNotification("Cancelled request to reschedule.").then();
            }
        )
    };

    setShowScheduleOptionsDialog = (show) => {
        this.setState({showScheduleOptionsDialog: show});
    };

    setChangeOrderFilter = (value) => {
        this.setState({changeOrderFilter: value});
    };


    render(){
        let {obligationTemplate, reload, anyActiveServices} = this.props;
        let {loading, mostRecentCompletedJob, templateChangeRequest, changeOrderFilter, showScheduleOptionsDialog, page, filter} = this.state;
        let { acceptChangeRequest, counterChangeRequest, onFilterChange, cancelChangeRequest, reloadChangeRequest, setChangeOrderFilter, setShowScheduleOptionsDialog} = this;

        return <RecurringServiceView
                filterStatus={filter}
                obligationTemplate={obligationTemplate}
                loading={loading}
                anyActiveServices={anyActiveServices}
                reloadObligationTemplate={reload}
                mostRecentCompletedJob={mostRecentCompletedJob}
                templateChangeRequest={templateChangeRequest}
                showScheduleOptionsDialog={showScheduleOptionsDialog}
                page={page}
                acceptChangeRequest={acceptChangeRequest}
                counterChangeRequest={counterChangeRequest}
                onFilterChange={onFilterChange}
                cancelChangeRequest={cancelChangeRequest}
                reloadChangeRequest={reloadChangeRequest}
                setChangeOrderFilter={setChangeOrderFilter}
                changeOrderFilter={changeOrderFilter}
                setShowScheduleOptionsDialog={setShowScheduleOptionsDialog}
        />
    }

}

const obligationFields = `
    id
    obligationDate
    completionDate
    status
    totalRetailPrice
    vendor {
        officialName
    }
    clientLocation{
        title
        addressLineOne
        addressLineTwo
        state
        city
        zip
    }
    serviceLine {
        name
    }
    pendingChangeOrderRequest{
        id
    }
    arrivalStartTime
    arrivalEndTime
    duration
    totalRetailPrice
    openChangeRequests {
        id
        currentState
        expired
        status
        initiatedByClient
        requestedSchedules {
            arrivalStartTime
            arrivalEndTime
            readableString
            date
            dayOfMonth
            daysOfWeek
            duration
            recurrenceType
            repeatEvery
            returnDate
        }
    }
`;

const changeRequestFields = `
    id
    currentState
    expired
    status
    initiatedByClient
    requestedSchedules {
        arrivalStartTime
        arrivalEndTime
        readableString
        date
        dayOfMonth
        daysOfWeek
        duration
        recurrenceType
        repeatEvery
        returnDate
        scheduleDows {
            dayOfWeek
            arrivalStartTime
            arrivalEndTime
        }
    }
`;

RecurringService.propTypes = {
    obligationTemplate: PropTypes.object,
    reload: PropTypes.func,
    anyActiveServices: PropTypes.bool,
    displayCancelledServices: PropTypes.bool
};

export default RecurringService;