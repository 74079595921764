import React, {Component} from 'react';
import Dropzone from 'react-dropzone'

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };

        this.onDrop = this.onDrop.bind(this);
    }

    onDrop(files) {
        let allFiles = this.state.files.concat(files);
        this.setState({
            files: allFiles
        });

        this.props.getFileData(allFiles); // returns all files
    }

    removeFile(file) {
        let stateCopy = Object.assign({}, this.state);

        stateCopy.files.splice(this.state.files.indexOf(file), 1);

        this.setState({
            files: stateCopy.files
        })
    }


    render() {
        let {size} = this.props;
        return (
            <section>
                <Dropzone className={`ws-section ${size === 'small' ? 'dropzone-sm' : 'dropzone'} pointer`} onDrop={this.onDrop.bind(this)}>
                    <i className={`icon-docs icon-${size === 'small' ? 'xl' : 'xxxl'}`}/>
                    {size !== 'small' ? <p className={'margin-top-lg'}>Try dropping some files here, or click to select files to upload.</p> : null }
                </Dropzone>
                {size !== 'small' ?
                <aside>
                    <ul>
                        {!this.state.files.length ? <p className={'margin-top-xs'}>No files dropped</p> : null}
                        {this.state.files.map(f => <li key={f.name}>{f.name} - {f.size} bytes <i className={'icon-close'} onClick={() => this.removeFile(f)}/></li>)}
                    </ul>
                </aside> : <p className={'text-sm pad-top-sm text-center'}>Add File</p> }
            </section>
        )
    }
}

export default FileUploader;