import React from 'react';
import {Button as AntdButton} from 'antd';
import PropTypes from 'prop-types';
import VendorButtonStyle from "./VendorButtonStyle";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {Debounce, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CommonButtonStyle from "./CommonButtonStyle";
import {ButtonType} from 'worksmith/enums/ButtonType';
import {ComponentSize} from 'worksmith/enums/ComponentSize';

const Button = (props) => {
    let {onClick, onClickIgnoreDebounce, message, disabled, debounce, icon, style, size, type, loading, block, className, children} = props;

    let onButtonPress = debounce ?
        Debounce(onClick, 400, onClickIgnoreDebounce)
        :
        onClick;

    if ((ValueIsSet(type) || !ValueIsSet(style))) {
        // This switch exists so that we can use the antd button type
        // and override it in specific cases. It also makes sure that we do not use
        // both type and style
        switch (type) {
            case ButtonType.PRIMARY:
                style = CommonButtonStyle.PRIMARY;
                break;
            default:
                style = null;
                break;
        }

        if(disabled)
            style = CommonButtonStyle.DISABLED;
    }

    return <AntdButton
        type={style ? null : type}
        style={style}
        size={size}
        className={className}
        onClick={onButtonPress}
        icon={icon}
        disabled={disabled}
        loading={loading}
        block={block}
    >{message || children}</AntdButton>;

};

Button.propTypes = {
    debounce: PropTypes.bool,
    message: PropTypes.string,         // String, title of the button
    disabled: PropTypes.bool,
    onClick: PropTypes.func,           // callback that the button should execute, set on the parent
    onClickIgnoreDebounce: PropTypes.func,           // callback that the button should execute, set on the parent
    icon: PropTypes.string,            // name of the antd Icon you want displayed on the button.,
    loading: PropTypes.bool,
    style: PropTypes.oneOf([
        CustomPropTypes.enum(VendorButtonStyle),
        CustomPropTypes.enum(CommonButtonStyle)
        ]),
    size: CustomPropTypes.enum(ComponentSize),
    type: CustomPropTypes.enum(ButtonType),
    block: PropTypes.bool,              // Block determines if the button should take up the entire space in it's container or not
};

Button.defaultProps = {
    debounce: false,
    disabled: false,
    loading: false,
    block: false
};

export default Button;