import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import ConversationListView from "./ConversationListView";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import RfpMarket from "../../enums/api/proposal/RfpMarket";
import NewRequestStatus from "../../enums/api/proposal/NewRequestStatus";

const graphQLService = new AsyncGraphQLServiceClass();

class ConversationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            conversations: []
        };
    }

    componentDidMount = async () => {
        const {requestId, requestMarket, requestNewStatus} = this.props;

        let searchParams = {
            requestId: requestId,
        };

        let conversations = await graphQLService.findAll(
            searchParams,
            GraphQLObjectType.CONVERSATION,
            ConversationGraphQlString
        );

        if (requestMarket === RfpMarket.OPEN_NETWORK && requestNewStatus === NewRequestStatus.MARKET) {
            conversations = conversations.filter(conversation =>
                conversation.opportunity.hasActivity ||
                conversation.messageCount > 0);
        } else {
            conversations = conversations.filter(conversation => conversation.isOpen || conversation.messageCount > 0);
        }

        this.setState({loading: false, conversations: conversations});
    };

    render() {
        const {conversations, loading} = this.state;
        const {onMessageGroupRead, displayAsVendor} = this.props;

        return (
            <ConversationListView
                conversations={conversations}
                loading={loading}
                onMessageGroupRead={onMessageGroupRead}
                displayAsVendor={displayAsVendor}/>
        )
    }
}

ConversationList.propTypes = {
    onMessageGroupRead: PropTypes.func,
    requestId: PropTypes.number,
    requestMarket: CustomPropTypes.enum(RfpMarket).isRequired,
    requestNewStatus: CustomPropTypes.enum(NewRequestStatus).isRequired,
    displayAsVendor: PropTypes.bool
};

const ConversationGraphQlString = `
    id
    hasUnreadScopeClarificationMessage
    isOpen
    opportunity {
        hasActiveBid
        hasActiveWalkthrough
        hasActivity
        request {
            clientLocation {
                id
                client {
                    nickname
                }
            }
        }
        vendorLocation { 
            id
            vendor {
                id
                officialName
            }
            rating
            ratingsCount
        }
    }
    messageCount
    unreadMessageCount
`;

export default ConversationList;