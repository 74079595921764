import PropTypes from 'prop-types';
import SimpleBusinessObjectPropType from "../SimpleBusinessObjectPropType";
import ClientNTEListObjectPropType from "./ClientNTEListObjectPropType";

const ServiceLineNTEConfigurationSummaryPropType = PropTypes.shape({
    serviceLine: SimpleBusinessObjectPropType,
    serviceLineNTE: ClientNTEListObjectPropType,
    locationNTEs: PropTypes.arrayOf([
        ClientNTEListObjectPropType
    ])
});

export default ServiceLineNTEConfigurationSummaryPropType;