import React, {Component, Fragment} from 'react';
import ClientStandardReportingPage from "./ClientStandardReportingPage";
import {WithContext} from "../../context/GlobalContext";
import ClientEnhancedReportingPage from "./ClientEnhancedReportingPage";


class ClientReportingPageContainer extends Component {
    constructor(props){
        super(props);
    }

    render() {
        let {context} = this.props;

        return(
            <Fragment>
                { context.client.exploToken != null ?
                        <ClientEnhancedReportingPage/> :
                        <ClientStandardReportingPage />
                }
            </Fragment>
            )
    }
}

export default WithContext(ClientReportingPageContainer);