import moment from "moment/moment";

export const isExpired = (schedule) => {
    let time = moment(schedule.arrivalStartTime, "hh:mm:ss");
    return schedule.arrivalStartTime ?
        // compare to current time
        (moment(schedule.date).hour(time.hour()).minute(time.minute())).isBefore(moment())
        :
        // compare to the start of today
        (moment(schedule.date)).isBefore(moment().startOf('day'));
};