// This file was generated from com.worksmith.proposal.controller.ChangeOrderRequestController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ChangeOrderRequestService extends Http {
    constructor() {
        super('/changeOrderRequest')
    }
    
    accept(id) {
        return this.callApi( `${id}/accept`, POST, null, null, null, true);
    }
    
    canAccept(id) {
        return this.callApi( `${id}/canAccept`, POST, null, null, null, true);
    }

    cancel(id){
        return this.callApi(`${id}/cancel`, PATCH, null, null, null, true);
    }

    create(params) {
        return this.callApi( `create`, POST, params, null, null, true);
    }

    update(params){
        return this.callApi('update', PATCH, params, null, null, true);
    }
    
    decline(id, declineReason) {
        const data = {
            declineReason
        }
        return this.callApi( `${id}/decline`, POST, data, null, null, true);
    }
    
    requestApproval(id) {
        return this.callApi( `${id}/requestApproval`, POST, null, null, null, true);
    }
    
    
}

export default ChangeOrderRequestService;
