import React, {Component} from 'react';
import Modal from 'antd/lib/modal';
import Button from "../Button";
import UserService from 'worksmith/services/api/UserService';
import {Color} from "../../Enums";
import styled from "styled-components";

const FormContainer = styled.div`
    max-width: ${props => props.maxWidth};
`;

const t = require('tcomb-form');
const Form = t.form.Form;

const userService = new UserService();

const changePasswordFormStruct = t.struct({
    password: t.String,
    confirmationPassword: t.String,
});

const changePasswordOptions = {
    fields: {
        password: {type: 'password', label: 'New Password'},
        confirmationPassword: {type: 'password', label: 'Confirm New Password'},
    }
};

class ChangePasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            changePasswordValue: {},
            errorMessage: null
        };

        this.submitPasswordChange   = this.submitPasswordChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        this.setState({changePasswordValue: value});
    }

    submitPasswordChange(){
        let {changePasswordValue, user} = this.state;
        let _this = this;

        this.setState({errorMessage: null}, () => {
            if (!changePasswordValue.password || changePasswordValue.password.length < 8) {
                _this.setState({errorMessage: 'Password must be at least 8 characters long!'})
            } else if (changePasswordValue.password !== changePasswordValue.confirmationPassword) {
                _this.setState({errorMessage: 'Entered passwords do not match!'})
            } else {
                let submitData = {
                    user: {
                        id: user.id,
                        password: changePasswordValue.password,
                    },
                    newPassword: changePasswordValue.confirmationPassword
                };

                userService.changePassword(submitData).then(function (data) {
                    Modal.success({
                        title: "Password successfully changed!",
                        onOk: () => _this.props.onSuccess ? _this.props.onSuccess() : null,
                        onCancel: () => _this.props.onSuccess ? _this.props.onSuccess() : null,
                        okText: "Ok"
                    });
                    _this.setState({changePasswordValue: {}});
                })
            }
        });
    }

    render() {
        let {errorMessage, changePasswordValue} = this.state;
        let {onChange, submitPasswordChange} = this;
        let {maxWidth} = this.props;

        return (
            <section className="ws-section flex-1">
                <h2 className="subhead">Change your password</h2>
                <FormContainer maxWidth={maxWidth}>
                    <Form
                        type={changePasswordFormStruct}
                        value={changePasswordValue}
                        onChange={(value) => onChange(value)}
                        options={changePasswordOptions} />
                </FormContainer>

                <div className="text-right">
                    <div style={{color: Color.RED}}>{errorMessage}</div>
                    <Button type="success" message={'Reset my password'} onPress={() => submitPasswordChange()} />
                </div>
            </section>
        )
    }
}

export default ChangePasswordForm;