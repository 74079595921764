import React from 'react';
import styled from 'styled-components';
import Proptypes from "prop-types";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import Tooltip, {TooltipPlacement} from 'worksmith/components/Tooltip/Tooltip.web';

const AutoMarkedCompliantIcon = (props) => {

    const {styles, height, width} = props;
    let margin = '0 0 0 0';
    if(ValueIsSet(props.margin)){
        margin = props.margin;
    }

    const IconSvg = styled.svg`
        height: ${height};
        width: ${width}; 
        margin: ${margin};
        ${styles}
    `;

    return (
        <Tooltip placement={TooltipPlacement.BOTTOM} title={'compliant - auto'} style={{margin: '0 auto'}}>
            <IconSvg width={'24px'} height={'24px'} viewBox={"0 0 24 24"} version={"1.1"} xmlns={"http://www.w3.org/2000/svg"}>
                <g id="v3-sla-admin-portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-55.000000, -79.000000)">
                        <g id="C" transform="translate(55.000000, 79.000000)">
                            <rect id="Rectangle" fill="#177418" x="0" y="0" width="24" height="24" rx="4"></rect>
                            <path d="M12.816201,17.133752 C13.7881321,17.133752 14.6575199,16.988854 15.4243646,16.699058 L15.4243646,16.699058 L15.4243646,15.3281003 C15.0052751,15.4573939 14.60179,15.5632808 14.2139092,15.6457612 C13.8260285,15.7282416 13.4247725,15.7694818 13.0101414,15.7694818 C12.0515856,15.7694818 11.3192935,15.4618523 10.8132652,14.8465932 C10.3072369,14.2313341 10.0542227,13.3240498 10.0542227,12.1247404 C10.0542227,10.9789318 10.3172683,10.0805643 10.8433594,9.42963805 C11.3694505,8.77871176 12.0917112,8.45324862 13.0101414,8.45324862 C13.4158557,8.45324862 13.8026219,8.51232241 14.1704398,8.63046999 C14.5382578,8.74861757 14.8871275,8.88571334 15.217049,9.04175732 L15.217049,9.04175732 L15.7921826,7.71092514 C14.9450867,7.29183561 14.022198,7.08229084 13.0235166,7.08229084 C12.0738776,7.08229084 11.2468445,7.28514801 10.5424174,7.69086234 C9.83799034,8.09657667 9.29963863,8.68062698 8.92736229,9.44301325 C8.55508595,10.2053995 8.36894778,11.0948502 8.36894778,12.1113652 C8.36894778,13.7253057 8.75237012,14.9658553 9.51921479,15.833014 C10.2860595,16.7001726 11.3850549,17.133752 12.816201,17.133752 Z" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </IconSvg>
        </Tooltip>
    )
};

AutoMarkedCompliantIcon.propTypes = {
    styles: Proptypes.string,
    height:  Proptypes.string,
    width: Proptypes.string,
    margin: Proptypes.string
};

export default AutoMarkedCompliantIcon;