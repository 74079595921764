import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import ActionableModalTemplate from 'worksmith/components/Modal/Templates/ActionableModalTemplate';
import {AlignItems} from 'worksmith/enums/CSSEnums';
import Button, {ButtonVariant} from 'worksmith/components/Button/Button';
import CircularProgress from 'worksmith/components/Loader/CircularProgress';
import Grid from 'worksmith/components/Grid/Grid';
import Icon from 'worksmith/components/Icon/Icon';
import IconButton from 'worksmith/components/Button/IconButton';
import {IconType} from 'worksmith/enums/MaterialEnums';
import {industryOptions} from 'worksmith/helpers/Constants';
import {JustifyContent} from 'worksmith/enums/CSSEnums';
import Modal from 'worksmith/components/Modal/Modal';
import Select from 'worksmith/components/Inputs/Select/Select';
import Text, {TextDisplay, TextVariant} from 'worksmith/components/Text/Text';
import TextField, {TextFieldType} from 'worksmith/components/Inputs/TextField/TextField';

import ClientLocationService from 'worksmith/services/api/ClientLocationService';
import ServiceLineType from '../../lib-worksmith/enums/ServiceLineType';

const clientLocationService = new ClientLocationService();

const LocationProfileQuestionsModal =
    ({
         closeProfileModal,
         locationId,
         locationProfileData,
         openConfirmationModal,
         profileModalOpen,
         profileQuestionTopics,
         selectedServiceLine,
     }) => {
        const [profileModalFormFields, setProfileModalFormFields] = useState({});
        const [submittingProfile, setSubmittingProfile] = useState(false);

        let formFields = {
            otherIndustry: ''
        };

        const handleLocationProfileModalChange = (formField, value, idx) => {
            if (formField === 'ceilingHeightFt') {
                const newFloors = profileModalFormFields.clientLocationFloors.map((floor, i) => {
                    let updated = floor;
                    if (i === idx) {
                        updated = {
                            ...floor,
                            ceilingHeightFt: value
                        };
                    }
                    return updated;
                });

                setProfileModalFormFields({
                    ...profileModalFormFields,
                    clientLocationFloors: newFloors
                });

            }
            if (formField === 'hasLadderOnSite') {
                setProfileModalFormFields({
                    ...profileModalFormFields,
                    clientLocationOperationDetails: {
                        ...profileModalFormFields.clientLocationOperationDetails,
                        hasLadderOnSite: value
                    }
                });
            }
            if (formField === 'ladderHeightFt') {
                setProfileModalFormFields({
                    ...profileModalFormFields,
                    clientLocationOperationDetails: {
                        ...profileModalFormFields.clientLocationOperationDetails,
                        ladderHeightFt: value
                    }
                });
            }
            if (formField === 'industry') {
                setProfileModalFormFields({
                    ...profileModalFormFields,
                    [formField]: value
                });
            }
            formFields[formField] = value;
        };

        const handleAddFloor = () => {
            setProfileModalFormFields({
                ...profileModalFormFields,
                clientLocationFloors: [
                    ...profileModalFormFields.clientLocationFloors,
                    {
                        ceilingHeightFt: null,
                        level: profileModalFormFields.clientLocationFloors.length + 1
                    }
                ]
            });
        };

        const handleRemoveFloor = (idx) => {
            const updatedFloors = profileModalFormFields.clientLocationFloors.filter((block, i) => i !== idx);
            setProfileModalFormFields({
                ...profileModalFormFields,
                clientLocationFloors: updatedFloors
            });
        };

        const saveProfileQuestions = async (e) => {
            e.preventDefault();
            setSubmittingProfile(true);

            let submitData = profileModalFormFields;

            submitData.id = locationId;
            submitData.otherIndustry = profileModalFormFields.industry && profileModalFormFields.industry === 'Other' ? formFields.otherIndustry : null;

            try {
                await clientLocationService.update(submitData);
                closeProfileModal();
                setSubmittingProfile(false);
            } catch (err) {
                console.error(err);
                setSubmittingProfile(false);
            }
        };

        useEffect(() => {
            if (locationProfileData) {
                if (selectedServiceLine === ServiceLineType.PEST_CONTROL) {
                    setProfileModalFormFields({
                        industry: locationProfileData.industry ? locationProfileData.industry : null,
                    });
                }
                if (selectedServiceLine === ServiceLineType.PAINTING ||
                    selectedServiceLine === ServiceLineType.LIGHTING ||
                    selectedServiceLine === ServiceLineType.ELECTRICAL_WORK
                ) {
                    setProfileModalFormFields({
                        clientLocationFloors: locationProfileData.clientLocationFloors.length > 0 ? locationProfileData.clientLocationFloors : [{
                            ceilingHeightFt: null,
                            level: 1,
                        }],
                        clientLocationOperationDetails: locationProfileData.clientLocationOperationDetails ? locationProfileData.clientLocationOperationDetails : {}
                    });
                }
                if (selectedServiceLine === ServiceLineType.HANDYMAN ||
                    selectedServiceLine === ServiceLineType.WINDOW_WASHING
                ) {
                    setProfileModalFormFields({
                        clientLocationOperationDetails: locationProfileData.clientLocationOperationDetails ? locationProfileData.clientLocationOperationDetails : {}
                    });
                }
            }
        }, [locationId, locationProfileData, selectedServiceLine]);

        const floors = profileModalFormFields.clientLocationFloors && profileModalFormFields.clientLocationFloors.length > 0 ? profileModalFormFields.clientLocationFloors.map((floor, idx) => (
            <Grid spacing={3} container item key={`floor - ${idx}`} xs={12} md={8} margin={'16px 0 0 0'}>
                <Grid item container justify={JustifyContent.SPACE_BETWEEN} alignItems={AlignItems.CENTER} xs={12}>
                    <Text display={TextDisplay.INLINE} gutterBottom variant={TextVariant.H6}>Floor {idx + 1}</Text>
                    {profileModalFormFields.clientLocationFloors.length > 1 && idx === profileModalFormFields.clientLocationFloors.length - 1 ?
                        <IconButton
                            onClick={() => handleRemoveFloor(idx)}
                            secondary
                            iconName={IconType.DELETE}
                            size="large" />
                        :
                        null
                    }
                </Grid>
                <TextField
                    fullWidth
                    label={'Ceiling height (ft)'}
                    name={'ceilingHeightFt'}
                    onChange={(value) => handleLocationProfileModalChange(`ceilingHeightFt`, Number(value), idx)}
                    type={TextFieldType.NUMBER}
                    initialValue={floor.ceilingHeightFt}
                />
            </Grid>
        )) : null;

        return (
            <Modal
                open={profileModalOpen}
            >
                <ActionableModalTemplate
                    body={
                        <Grid spacing={2} container item margin={'16px 0 0 0'} alignItems={AlignItems.FLEX_START}>
                            {
                                profileQuestionTopics.includes('ladder') ?
                                    <Grid container item xs={12} md={8}>
                                        <Grid item>
                                            <Text
                                                gutterBottom
                                                variant={TextVariant.BODY_1}
                                            >
                                                Is there a ladder on site?
                                            </Text>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item>
                                                <Button
                                                    onClick={() => handleLocationProfileModalChange('hasLadderOnSite', true)}
                                                    primary
                                                    variant={profileModalFormFields.clientLocationOperationDetails && profileModalFormFields.clientLocationOperationDetails.hasLadderOnSite ? 'contained' : 'outlined'}
                                                >
                                                    YES
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => handleLocationProfileModalChange('hasLadderOnSite', false)}
                                                    primary
                                                    variant={profileModalFormFields.clientLocationOperationDetails && profileModalFormFields.clientLocationOperationDetails.hasLadderOnSite === false ? 'contained' : 'outlined'}
                                                >
                                                    NO
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {
                                            profileModalFormFields.clientLocationOperationDetails && profileModalFormFields.clientLocationOperationDetails.hasLadderOnSite ?
                                                <Grid item xs={12} margin={'16px 0 0 0'}>
                                                    <TextField
                                                        fullWidth
                                                        label={'Ladder height (ft)'}
                                                        name={'ladderHeightFt'}
                                                        onChange={(value) => handleLocationProfileModalChange('ladderHeightFt', Number(value))}
                                                        type={TextFieldType.NUMBER}
                                                        initialValue={profileModalFormFields.clientLocationOperationDetails.ladderHeightFt}
                                                    />
                                                </Grid>
                                                :
                                                null
                                        }
                                    </Grid>
                                    :
                                    null
                            }
                            {
                                profileQuestionTopics.includes('ceilingHeight') ?
                                    <Grid item xs={12} md={8} alignItems={AlignItems.FLEX_START}>
                                        {floors}
                                        <Grid item xs={12} margin={'32px 0 0 0'}>
                                            <Grid item margin={'0 0 0 32px'}>
                                                <Button variant={ButtonVariant.TEXT} onClick={handleAddFloor}
                                                        startIcon={<Icon name={IconType.ADD}/>}>ADD ANOTHER
                                                    FLOOR</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                            }
                            {
                                profileQuestionTopics.includes('industry') ?
                                    <Grid spacing={3} container item xs={12} md={8} alignItems={AlignItems.FLEX_START}>
                                        <Grid item xs={12} md={10}>
                                            <Select
                                                fullWidth
                                                initialValue={profileModalFormFields.industry}
                                                label={'Select your industry'}
                                                name={'industry'}
                                                onChange={value => handleLocationProfileModalChange('industry', value)}
                                                options={industryOptions}
                                            />
                                        </Grid>
                                        {profileModalFormFields.industry === 'Other' ?
                                            <Grid item xs={12} md={10}>
                                                <TextField
                                                    fullWidth
                                                    initialValue={null}
                                                    label={'What industry'}
                                                    name={'otherIndustry'}
                                                    onChange={value => handleLocationProfileModalChange('otherIndustry', value)}
                                                    type={TextFieldType.TEXT}
                                                />
                                            </Grid>
                                            :
                                            null
                                        }
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>
                    }
                    title={<Text variant={TextVariant.H6} gutterBottom>Additional information needed</Text>}
                    subtitle={
                        <Text variant={TextVariant.BODY_1}>
                            Your response will be saved to your <Link to={`/locations/${locationId}`}
                                                                      rel={'noopener noreferrer'} target={'_blank'}>Location
                            Profile</Link> so you won't have to answer this question again next time you add a new
                            request.
                        </Text>
                    }
                    buttons={[
                        <Button onClick={openConfirmationModal}>SKIP</Button>,
                        <Button disabled={submittingProfile} primary onClick={saveProfileQuestions}>{submittingProfile ?
                            <CircularProgress size={24}/> : 'SAVE'}</Button>
                    ]}
                />
            </Modal>
        );
    };

export default LocationProfileQuestionsModal;

LocationProfileQuestionsModal.propTypes = {
    handleAddFloor: PropTypes.func,
    handleLocationProfileModalChange: PropTypes.func,
    handleRemoveFloor: PropTypes.func,
    openConfirmationModal: PropTypes.func,
    profileModalOpen: PropTypes.bool,
    profileQuestionTopics: PropTypes.arrayOf(PropTypes.string),
    submitting: PropTypes.bool
};