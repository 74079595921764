import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Color, LoggedOutRoutes} from "../../Enums";
import {Display, FlexDirection, Position, TextAlign} from "../../CSSEnums";
import {Link} from "react-router-dom";

const Background = styled.div`
    background: linear-gradient(to right, rgb(41, 61, 75) 0%, rgb(242, 242, 242) 200%);
    position: ${Position.ABSOLUTE};
    
    width: 100%;
    top: 0;
    bottom: 0;
`;

const Container = styled.div`
    width: 100%;
    height: 600px;
    position: ${Position.ABSOLUTE};
    top: 0;
    bottom: 0;
    margin: auto;
`;

const LogoContainer = styled.div`
    display: ${Display.FLEX};
    flex-direction: ${FlexDirection.ROW};
    
    height: 500px; 
    width: 100%; 
    padding: 100px; 
    margin: 0px; 
    
    background: rgba(216,216,216,.15);
`;

const FlexDiv = styled.div`
    flex: 1;
`;

const WorksmithLogoContainer = styled(FlexDiv)`
    text-align: ${TextAlign.RIGHT};
    border-right: 1px solid ${Color.WHITE};
`;

const WorksmithLogo = styled.img`
    height: 150px;
    margin-top: 75px;
    margin-right: 50px;
`;

const BurberryLogo = styled.img`
    height: 100px;
    margin-left: 50px;
    margin-top: 100px;
`;

const ButtonContainer = styled.div`
    width:100%; 
    text-align:center; 
    margin-top:60px;
`;


const BurberryLoginPageView = (props) => {
    let {} = props;

    return (
        <Background>
            <Container>
                <LogoContainer>
                    <WorksmithLogoContainer>
                        <WorksmithLogo src={'https://cdn.worksmith.com/logo-white.png'} alt={'worksmithLogo'}/>
                    </WorksmithLogoContainer>

                    <FlexDiv>
                        <BurberryLogo src={'https://cdn.worksmith.com/burberry/burberry.png'} alt={'burberryLogo'}/>
                    </FlexDiv>
                </LogoContainer>

                <ButtonContainer>
                    <Link to={LoggedOutRoutes.BURBERRY_AUTH} style={{color: Color.WHITE, fontSize: '32px', border: '2px solid ' + Color.WHITE, padding: '6px 48px'}}>Sign In</Link>
                </ButtonContainer>
            </Container>
        </Background>
    )
};

BurberryLoginPageView.propTypes = {
    
};

export default BurberryLoginPageView;