import React from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

import {AlignItems, Display, FlexDirection, JustifyContent} from "../../enums/CSSEnums";
import Text, {TextColor, TextVariant} from "../../components/Text/Text.web";
import Rating from "../../components/Rating/Rating";
import ConversationHeaderSkeleton from "./ConversationHeaderSkeleton";
import Alert from "../../components/Alert/Alert";

const ConversationHeader = (props) => {
    const {
        conversationClosed,
        loading,
        vendorLocation,
        clientLocation,
        unreadMessageCount
    } = props;

    if (loading) {
        return (
            <ConversationHeaderSkeleton/>
        )
    }

    return (
        <Box
            width={'100%'}
            display={Display.FLEX}
            flexDirection={FlexDirection.COLUMN}
            mb={1}>
            <Box
                display={Display.FLEX}
                justifyContent={JustifyContent.SPACE_BETWEEN}>
                <Box>
                    <Text semiBold variant={TextVariant.SUBTITLE_1} color={conversationClosed ? TextColor.TEXT_SECONDARY : TextColor.TEXT_PRIMARY}>
                        {vendorLocation.vendor.officialName}
                    </Text>
                    <Rating showValue disabled={conversationClosed} totalCount={vendorLocation.ratingsCount} value={vendorLocation.rating}/>
                </Box>
                <Box
                    display={Display.FLEX}
                    flexDirection={FlexDirection.COLUMN}
                    justifyContent={JustifyContent.CENTER}
                    alignItems={AlignItems.FLEX_END}>
                    {
                        unreadMessageCount ?
                            <Alert
                                count={unreadMessageCount}
                                label={'new'}/>
                            :
                            null
                    }
                    {
                        conversationClosed ?
                            <Text variant={TextVariant.CAPTION}
                                  color={TextColor.TEXT_SECONDARY}>Conversation closed</Text>
                            :
                            null
                    }
                </Box>
            </Box>
        </Box>
    )
};

ConversationHeader.defaultProps = {
    conversationClosed: false,
    loading: false,
};

ConversationHeader.propTypes = {
    conversationClosed: PropTypes.bool,
    loading: PropTypes.bool,
    unreadMessageCount: PropTypes.number,
    vendorLocation: PropTypes.shape({
        rating: PropTypes.number,
        ratingsCount: PropTypes.number,
        vendor: PropTypes.shape({
            officialName: PropTypes.string,
        })
    }).isRequired,
};

export default ConversationHeader;