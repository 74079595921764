import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import Loader from "../../components/Loader";
import {WithContext} from "../../context/GlobalContext";
import Section from 'worksmith/antd-components/Section/Section.web';
import Title from 'worksmith/antd-components/Title/Title.web';
import Grid from 'worksmith/components/Grid/Grid.web';
import Text from 'worksmith/components/Text/Text.web';
import Select from 'worksmith/antd-components/Select/Select';
import {ValueIsSet} from "../../Helpers";
import GraphQLServiceClass from "../../lib-worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {ExploReportingVariableValues} from "../../Enums";
import {LocalStorageKeys} from "../../shared/enums/Enums";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";
import ExploHelper from "./ExploHelper";

const graphQLService = new GraphQLServiceClass();

const ReportSelectSection =  styled.div`
    flex: 2;
`;

class ClientEnhancedReportingPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: false,
            environment: null,
            selectedReport: null,
            selectedReportVariables: null,
            exploDashboards: [],
            dashboardMap: {}
        };
    }

    componentDidMount() {
        let {context} = this.props;
        let _this = this;

        graphQLService.findAll({clientId: context.client.id},
            GraphQLObjectType.EXPLO_DASHBOARD,
            exploDashboardQueryFields).then((data) => {
                let exploDashboards = data;

                let dashboardMap = exploDashboards.reduce(function(map, option) {
                    map[option.dashboardKey] = option;
                    return map;
                }, {});

                _this.setState({exploDashboards, dashboardMap, loading: false});
        }, (error) => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification(error.toString());
            _this.setState({loading: false});
        });

        let environment = localStorage.getItem(LocalStorageKeys.BASE_API_URL);
        this.setState({environment});
    }

    onReportSelect = (value) => {
        let selectedReport = this.state.dashboardMap[value];

        if(selectedReport == null) {
            DisplayErrorNotification('Dashboard Unavailable');
        } else {
            let selectedReportVariables = selectedReport.variables.reduce(function(map, obj) {
                let date = ExploReportingVariableValues.calculateValue(obj.value);
                map[obj.key] = date;
                return map;
            }, {});

            this.setState({selectedReport, selectedReportVariables});
        }
    };

    render() {
        let {loading, environment, exploDashboards, selectedReport, selectedReportVariables} = this.state;
        let {context} = this.props;
        let {onReportSelect} = this;

        return(
            <Fragment>
                {loading ? <Loader/> :
                    <Fragment>
                        <Section>
                            <Title level={3}>Client Reporting</Title>
                        </Section>

                        <Section>
                            <Grid container spacing={2}>
                                <Grid item lg={4} xs={12}>
                                    <ReportSelectSection>
                                        <div>
                                            <Text>Report Type</Text>
                                        </div>
                                        <Select
                                            style={{width: '100%'}}
                                            options={exploDashboards}
                                            optionFieldName={'name'}
                                            valueFieldName={'dashboardKey'}
                                            onSelect={onReportSelect}/>
                                    </ReportSelectSection>
                                </Grid>
                            </Grid>
                        </Section>

                        <ExploHelper
                            clientId={context.client.id}
                            selectedReport={selectedReport}
                            selectedReportVariables={selectedReportVariables}
                            environment={environment}
                        />
                    </Fragment>
                    }
            </Fragment>
            )
    }
}

const exploDashboardQueryFields = `
    id
    name
    dashboardKey
    type
    variables{
        key
        value
    }
`;

export default WithContext(ClientEnhancedReportingPage);