import React, {Component} from 'react';
import Button from "../../components/Button";
import WSButton, {ButtonVariant} from 'worksmith/components/Button/Button.web';
import NewRequestForm from "../../components/forms/requests/NewRequestForm";
import NewRequestDefaultFields from "./NewRequestDefaultFields";
import {AuthTokenManager} from "../../lib-worksmith/services/utilities/AuthTokenManager";
import GraphQLServiceClass from "../../lib-worksmith/services/graphql/GraphQLServiceClass";
import AsyncGraphQLServiceClass from "../../lib-worksmith/services/graphql/AsyncGraphQLServiceClass";
import ClientService from "../../lib-worksmith/services/api/ClientService";
import UserService from "../../lib-worksmith/services/api/UserService";
import {StringHasText, ValueIsSet} from "../../lib-worksmith/helpers/GenericHelpers";
import {GraphQLObjectType} from "../../lib-worksmith/enums/GraphQLObjectType";
import NewContractRequestForm from "./NewContractRequestForm";
import ServiceLineService from "../../lib-worksmith/services/api/ServiceLineService";
import ReactHtmlParser from "react-html-parser";
import {ErrorText} from "../../components/styledComponents/Basic";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import Grid from "worksmith/components/Grid/Grid.web";
import RequestForProposalService from "worksmith/services/api/RequestForProposalService";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import Icon from "worksmith/components/Icon/Icon.web";
import DuplicateRequestModal from "worksmith/components/DuplicateRequestModal/DuplicateRequestModal";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const authTokenManager = new AuthTokenManager();
const graphQLService = new GraphQLServiceClass();
const asyncGraphQLService = new AsyncGraphQLServiceClass();
const clientService = new ClientService();
const userService = new UserService();
const serviceLineService = new ServiceLineService();
const requestService = new RequestForProposalService();

const t = require('tcomb-form');

class NewRequestPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            checkedDuplicateLocationId: null,
            checkedDuplicateServiceLine: null,
            duplicateRequest: [],
            recheckDuplicates: false,
            serviceLines: [],
            selectedServiceLine: null,
            selectedLocationId: null,
            defaultFieldsValue: {location: null, serviceLine: null},
            clientLocationContracts: null,
            activeContractItemsForServiceLine: [],
            serviceLineSpecificQuestions: [],
            serviceLineSpecificQuestionAnswers: [],
            questionIdLookup: {},
            checklistReviewed: null,
            checklist: null,
            customQuestions: [],
            customAnswers: [],
            customQuestionFormFields: null,
            customOptionFields: null,
            customOrder: null,
            locationProfileData: null,
            showWarrantyWarning: false
        };
    }

    componentDidMount() {
        let _this = this;

        this.fetchLocations().then(function (data) {
            let locations = data;

            let locationsToObject = locations.map((location) => {
                return {
                    value: location.id,
                    text: location.title
                }
            });
            _this.setState({
                locations: locationsToObject,
            });

            if (locations.length === 1) {
                let currentDefaultFieldsValue = {serviceLine: null, location: locations[0].id.toString()};
                _this.onDefaultFieldsChange(currentDefaultFieldsValue);
            }
        });

        this.fetchServiceLines().then(function (data) {
            let serviceLines = data;

            let serviceLinesToObject = serviceLines.map((serviceLine) => {
                return {
                    value: serviceLine.id,
                    text: serviceLine.name,
                    useTicketing: serviceLine.useTicketing,
                    requestDescription: serviceLine.requestDescription
                }
            });
            _this.setState({
                serviceLines: serviceLinesToObject,
            });
        });
    }

    // Fetch Request Methods -----------------------------------------------------------------------
    fetchLocations() {
        return userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId());
    }

    fetchServiceLines() {
         return clientService.getSelectedServiceLines(this.props.client.id);
    }

    async fetchLocationProfileData(locationId) {
        return await graphQLService.findOneById(locationId, GraphQLObjectType.CLIENT_LOCATION, locationFields)
    }

    fetchRfpQuestions(serviceLineId) {
        let _this = this;

        serviceLineService.getRfpQuestions(serviceLineId).then(function (data) {
            let serviceLineSpecificQuestions = data.slice(0).sort((a, b) => a.ordering <= b.ordering ? -1 : 1);
            let questionIdLookup = {};
            serviceLineSpecificQuestions.forEach(function (question) {
                if (question.options && question.options.length > 0) {
                    questionIdLookup[question.id] = {};
                    question.options.forEach(function (option) {
                        questionIdLookup[question.id][option.answer] = option.id;
                    });
                }
            });
            _this.setState({serviceLineSpecificQuestions, questionIdLookup});
        });
    }

    getCustomQuestions = async (clientLocationId, serviceLineId) => {
        let {client} = this.props;
        let searchParams = {
            clientId: client.id,
            clientLocationId: clientLocationId,
            serviceLineId: serviceLineId,
            customClientQuestion: true
        };
        if(!ValueIsSet(serviceLineId) || !ValueIsSet(clientLocationId))
            return;
        const response = await asyncGraphQLService.findAll(
            searchParams,
            GraphQLObjectType.RFP_QUESTION,
            rfpQuestionFields
        );
        this.setState({customQuestions: response});
    };

    fetchSOPs(serviceLineId) {
        const {client} = this.props;

        graphQLService.findAll(
            {clientId: client.id, isActive: true, serviceLineIds: [serviceLineId]},
            GraphQLObjectType.STANDARD_OPERATING_PROCEDURE,
            `description`
        ).then((sop) => {
            if (sop.length > 0)
                this.setState({checklist: sop[0].description, checklistReviewed: false});
            else
                this.setState({checklistReviewed: true});
        }).catch((error) => {
            console.log(error);
        });
    };

    async fetchPrimaryRateCardEntries(locationId, serviceLineId){
        return await graphQLService.findAll(
            {clientLocationId:locationId, serviceLineId:serviceLineId, rateCardPriority:1},
            GraphQLObjectType.RATE_CARD_ENTRY,
            rateCardEntryObject
        );
    }

    hasCustomAnswer = (answerId, value) => {
        let _this = this;
        let keys = Object.keys(value);
        let hasAnswer = false;

        keys.forEach(function (key) {
            if (!isNaN(key)) {

                let questionIdLookup = _this.state.questionIdLookup;
                if (questionIdLookup[key] != null && questionIdLookup[key][value[key]] === answerId) {
                    hasAnswer = true;
                }
            }
        });

        return hasAnswer;
    };

    getCustomRfpQuestionFormFields = (value) => {

        let {customQuestions} = this.state;
        let customQuestionFormFields = {};
        let order = [];
        let optionFields = {};
        let _this = this;

        let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
        for (let i = 0; i < customQuestions.length; i++) {
            let question = customQuestions[i];

            if (!question.conditionalUpon || _this.hasCustomAnswer(question.conditionalUpon.id, value)) {
                if (question.answerOptions.length > 0) {

                    let optionsMap = {};

                    for (let j = 0; j < question.answerOptions.length; j++) {
                        let option = question.answerOptions[j];
                        optionsMap[option.answer] = option.answer;
                    }

                    if (question.includeOtherOption) {
                        optionsMap["Other"] = "Other";
                    }

                    if (question.required)
                        customQuestionFormFields[question.id] = t.enums(optionsMap);
                    else
                        customQuestionFormFields[question.id] = t.maybe(t.enums(optionsMap));
                } else {
                    if (question.required)
                        customQuestionFormFields[question.id] = t.String;
                    else
                        customQuestionFormFields[question.id] = t.maybe(t.String);
                }

                let label;
                if (isHTML(question.question)) {
                    label = question.required ? <div>{ReactHtmlParser(question.question)} *</div> : <div>{ReactHtmlParser(question.question)}</div>
                } else {
                    label = question.required ? question.question + " *" : question.question;
                }

                optionFields[question.id] = {label: label, error: <ErrorText>Required Field</ErrorText>};
                order.push(question.id);
            }
        }

        return {customQuestionFormFields: customQuestionFormFields, order: order, customOptionFields: optionFields};
    };


    getAllFormFields = (value) => {
        let customFields = this.getCustomRfpQuestionFormFields(value);
        let fields = this.getRfpSpecificQuestionFormFields(value);

        let serviceLineSpecificFormFields = Object.assign({}, customFields.customQuestionFormFields, fields.serviceLineSpecificFormFields);
        let order = customFields.order.concat(fields.order);
        let optionFields = Object.assign({}, customFields.customOptionFields, fields.optionFields);
        return {serviceLineSpecificFormFields, order, optionFields};
    };

    getRfpSpecificQuestionFormFields = (value) => {

        let {serviceLineSpecificQuestions} = this.state;
        let serviceLineSpecificFormFields = {};
        let order = [];
        let optionFields = {};
        let _this = this;

        let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
        for (let i = 0; i < serviceLineSpecificQuestions.length; i++) {
            let question = serviceLineSpecificQuestions[i];

            if (!question.conditionalUpon || _this.hasCustomAnswer(question.conditionalUpon.id, value)) {
                if (question.options.length > 0) {

                    let optionsMap = {};

                    for (let j = 0; j < question.options.length; j++) {
                        let option = question.options[j];
                        optionsMap[option.answer] = option.answer;
                    }

                    if (question.required)
                        serviceLineSpecificFormFields[question.id] = t.enums(optionsMap);
                    else
                        serviceLineSpecificFormFields[question.id] = t.maybe(t.enums(optionsMap));
                } else {
                    if (question.required)
                        serviceLineSpecificFormFields[question.id] = t.String;
                    else
                        serviceLineSpecificFormFields[question.id] = t.maybe(t.String);
                }

                let label;
                if (isHTML(question.question)) {
                    label = question.required ? <div>{ReactHtmlParser(question.question)} *</div> : <div>{ReactHtmlParser(question.question)}</div>
                } else {
                    label = question.required ? question.question + " *" : question.question;
                }

                optionFields[question.id] = {label: label, error: <ErrorText>Required Field</ErrorText>};
                order.push(question.id);
            }
        }

        return {serviceLineSpecificFormFields: serviceLineSpecificFormFields, order: order, optionFields: optionFields};
    };

    clearCustomAnswers = (currentValue) => {
        let keys = Object.keys(currentValue);
        keys.forEach(function (key) {
            if (!isNaN(key)) {
                delete currentValue[key];
            }
        });
    };

    getCustomAnswers = (currentValue, serviceLineSpecificQuestions, questionIdLookup) => {
        let serviceLineSpecificQuestionAnswers = [];
        let keys = Object.keys(currentValue);
        let _this = this;
        keys.forEach(async function (key) {
            if (!isNaN(key)) {
                let rfpQuestion = serviceLineSpecificQuestions.find(function (question) {
                    return question.id == key
                });
                if(!ValueIsSet(rfpQuestion)){
                    rfpQuestion = await asyncGraphQLService.findOneById(key, GraphQLObjectType.RFP_QUESTION, rfpQuestionFields);
                }

                if (rfpQuestion.conditionalUpon == null || _this.hasCustomAnswer(rfpQuestion.conditionalUpon.id, currentValue)) {

                    let a = {
                        question: {
                            id: key
                        },
                        answer: currentValue[key]
                    };

                    if (questionIdLookup[a.question.id] && questionIdLookup[a.answer]) {
                        a.answerOptionId = questionIdLookup[a.question.id][a.answer];
                    }

                    serviceLineSpecificQuestionAnswers.push(a);
                }
            }
        });

        return serviceLineSpecificQuestionAnswers;
    };

    async onDefaultFieldsChange(currentValue){
        if(!ValueIsSet(currentValue))
            return;

        let {serviceLines, clientLocationContracts, defaultFieldsValue, selectedServiceLine, serviceLineSpecificQuestions, questionIdLookup, activeContractItemsForServiceLine} = this.state;
        let selectedLocationId;
        let selectedLocationChanged = StringHasText(currentValue.location) && (currentValue.location !== defaultFieldsValue.location);
        let selectedServiceLineChanged = StringHasText(currentValue.serviceLine) && (currentValue.serviceLine !== defaultFieldsValue.serviceLine);

        selectedLocationId = currentValue.location;

        if(selectedServiceLineChanged){
            selectedServiceLine = serviceLines.find(serviceLine => serviceLine.value.toString() === currentValue.serviceLine.toString());
            this.clearCustomAnswers(currentValue);
            this.fetchRfpQuestions(currentValue.serviceLine);
            this.fetchSOPs(currentValue.serviceLine);
        }

        if(selectedServiceLineChanged || selectedLocationChanged)
        {
            this.getCustomQuestions(currentValue.location, currentValue.serviceLine);
        }

        if(!ValueIsSet(selectedServiceLine))
            this.setState({serviceLineSpecificQuestions: [], questionIdLookup: {}});

        if((selectedLocationChanged || selectedServiceLineChanged) && StringHasText(currentValue.location)) {
            this.checkForWarranty(currentValue.location, currentValue.serviceLine);
        }

        if((selectedLocationChanged || selectedServiceLineChanged) && (StringHasText(currentValue.location) && StringHasText(currentValue.serviceLine))){

            let locationContractsPromise = this.fetchPrimaryRateCardEntries(currentValue.location, currentValue.serviceLine);
            let locationProfilePromise = this.fetchLocationProfileData(currentValue.location);

            Promise.all([locationContractsPromise, locationProfilePromise])
                .then(([contractData, profileData]) => {
                    activeContractItemsForServiceLine = contractData;
                    this.setState({
                        activeContractItemsForServiceLine: activeContractItemsForServiceLine,
                        locationProfileData: profileData,
                        loading: false
                    })
                }).catch((error) => {
                console.log(error);
                this.setState({loading:false});
            });
        }

        let serviceLineSpecificQuestionAnswers = this.getCustomAnswers(currentValue, serviceLineSpecificQuestions, questionIdLookup);
        this.setState({selectedLocationId, selectedServiceLine, defaultFieldsValue: currentValue, serviceLineSpecificQuestionAnswers, clientLocationContracts});
    }

    checkForWarranty = async (locationId, serviceLineId) => {
        const {showWarrantyWarning} = this.state;
        let clientLocation = null;

        if ((typeof serviceLineId === JavascriptType.STRING && StringHasText(serviceLineId)) || typeof serviceLineId === JavascriptType.NUMBER) {
            try {
                clientLocation = await asyncGraphQLService.findOneById(
                    locationId,
                    GraphQLObjectType.CLIENT_LOCATION,
                    `
                    hasValidWarrantyForServiceLine(serviceLineId: ` + serviceLineId + `)
                `
                );

                if (ValueIsSet(clientLocation) && clientLocation.hasValidWarrantyForServiceLine !== showWarrantyWarning) {
                    this.setState({showWarrantyWarning: clientLocation.hasValidWarrantyForServiceLine});
                }
            } catch (e) {
                console.error('Error retrieving warranty data for clientLocationId=' + locationId + ', serviceLineId=' + serviceLineId);
            }
        } else {
            try {
                clientLocation = await asyncGraphQLService.findOneById(
                    locationId,
                    GraphQLObjectType.CLIENT_LOCATION,
                    `
                    warranties {
                        id
                    }
                `
                );

                if (ValueIsSet(clientLocation)) {
                    if (ValueIsSet(clientLocation.warranties) && (clientLocation.warranties.length !== 0) !== showWarrantyWarning) {
                        this.setState({showWarrantyWarning: clientLocation.warranties.length !== 0});
                    }
                    else if (showWarrantyWarning !== false) {
                        this.setState({showWarrantyWarning: false})
                    }
                }
            } catch (e) {
                console.error('Error retrieving warranty data for clientLocationId=' + locationId);
            }
        }
    };

    markChecklistReviewed = () =>{
        this.setState({checklistReviewed: true});
    };

    onCancelRequest = () => {
        const { history } = this.props;
        requestService.requestCanceledByChecklist().then(() => {
            history.push('/');
        });
    };

    closeDuplicateRequestModal = () => {
        this.setState({showDuplicateRequestModal: false})
    }

    checkChangedServiceOrLocation = () => {
    let {checkedDuplicateLocationId,
        checkedDuplicateServiceLine,
        selectedServiceLine,
        selectedLocationId
        } = this.state

        if(checkedDuplicateLocationId !== selectedLocationId && selectedServiceLine !== null){
            this.setState({checkedDuplicateLocationId: selectedLocationId, recheckDuplicates: true})
        }
        if(checkedDuplicateServiceLine !== selectedServiceLine && selectedLocationId !== null){
            this.setState({checkedDuplicateServiceLine: selectedServiceLine, recheckDuplicates: true})
        }
    }

    findDuplicateRequests = () => {
        let {checkedDuplicateLocationId, checkedDuplicateServiceLine} = this.state;

        graphQLService.findAll(
            {
                serviceLineIds: [checkedDuplicateServiceLine.value],
                clientLocationIds: [checkedDuplicateLocationId],
                isOpen: true,
                complete: false
            },
            GraphQLObjectType.REQUEST,
            requestShortDescription
        ).then((requests) => {
            this.setState({duplicateRequest: requests, showDuplicateRequestModal: requests.length > 0})
        }).catch(() => {
            DisplayErrorNotification("Unable to load potential duplicate requests.  Please refresh the page and try again.");
        })

        this.setState({recheckDuplicates: false})
    }

    render() {
        let {
            locations,
            checkedDuplicateLocationId,
            checkedDuplicateServiceLine,
            duplicateRequest,
            recheckDuplicates,
            serviceLines,
            selectedServiceLine,
            selectedLocationId,
            defaultFieldsValue,
            activeContractItemsForServiceLine,
            serviceLineSpecificQuestionAnswers,
            checklist,
            checklistReviewed,
            showDuplicateRequestModal,
            showWarrantyWarning
        } = this.state;

        let {history, match, user, client} = this.props;
        let {checkChangedServiceOrLocation, closeDuplicateRequestModal, findDuplicateRequests, getRfpSpecificQuestionFormFields, markChecklistReviewed, onCancelRequest, getAllFormFields} = this;
        let isAdmin = !!user.clientRoles;
        let prohibitRequests = client.settings.prohibitLocationManagerSubmissions && !isAdmin;
        let locationTitle = null;

        if(ValueIsSet(selectedLocationId))
            locationTitle = locations.filter(location => location.value === Number(selectedLocationId))[0].text;

        if(selectedServiceLine !== checkedDuplicateServiceLine || selectedLocationId !== checkedDuplicateLocationId){
            checkChangedServiceOrLocation()
        }

        if(recheckDuplicates === true){
            findDuplicateRequests()
        }

        let form = null;

        if(ValueIsSet(selectedServiceLine) && StringHasText(selectedLocationId)){
            if(activeContractItemsForServiceLine.length > 0) {
                form = <NewContractRequestForm clientId={client.id}
                                               client={client}
                                               defaultWalkthroughPreference={client.configurationSettings.walkthroughPreference}
                                               locationTitle={locationTitle}
                                               locationProfileData={this.state.locationProfileData}
                                               selectedLocationId={selectedLocationId.toString()}
                                               selectedServiceLine={selectedServiceLine}
                                               activeContractItemsForServiceLine={activeContractItemsForServiceLine}
                                               serviceLineSpecificQuestionAnswers={serviceLineSpecificQuestionAnswers}
                                               history={history}/>;
            }
            else
                form = <NewRequestForm history={history}
                                       match={match}
                                       user={user}
                                       client={client}
                                       defaultWalkthroughPreference={client.configurationSettings.walkthroughPreference}
                                       locationProfileData={this.state.locationProfileData}
                                       selectedServiceLine={selectedServiceLine}
                                       selectedLocationId={selectedLocationId}
                                       serviceLineSpecificQuestionAnswers={serviceLineSpecificQuestionAnswers}
                                       />;
        }

        return (
            <div>
            {
                prohibitRequests ?
                    <section className="ws-section" style={{'text-align': 'center'}}>
                        <h1 className="page-header">Only Admins are allowed to submit new requests.</h1>
                        <Button type={'primary'} onPress={() => window.history.back()} message={'Go Back'}/>
                    </section>
                :
                    <div id={'submitNewRequestForm'} data-testid={'submitNewRequestForm'}>
                        <section style={{'border':'0px', 'textAlign':'center'}} className="ws-section">
                            <h1 className="page-header">Submit Request</h1>
                        </section>

                        {showWarrantyWarning ?
                            <section style={{'border':'0px'}} className="ws-section">
                                <Grid container>
                                    <Grid item width={'auto'}>
                                        <Icon translateTop={4} name={'ErrorOutline'}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <text style={{fontWeight: 'bold'}}>
                                            This request might be eligible for warranty and upon submission will be sent to the
                                            Admin for review.
                                        </text>
                                    </Grid>
                                </Grid>
                            </section>
                            :
                            null
                        }

                        <section id={'submitNewRequestFormBody'} style={{'border':'0px'}} className="ws-section">
                            <NewRequestDefaultFields checklist={checklist}
                                                     checklistReviewed={checklistReviewed}
                                                     locations={locations}
                                                     serviceLines={serviceLines}
                                                     value={defaultFieldsValue}
                                                     onChange={(value) => this.onDefaultFieldsChange(value)}
                                                     getRfpSpecificQuestionFormFields={getAllFormFields}/>

                            {ValueIsSet(checklistReviewed) && !checklistReviewed ? <Grid container>
                                    <Grid item xs={12}>
                                        <div style={{marginBottom: '1em'}}>
                                            <Text variant={TextVariant.SUBTITLE_2}>Request Checklist</Text>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text variant={TextVariant.BODY_2}>{checklist}</Text>
                                    </Grid>
                                    <Grid item xs={12} sm={6}/>
                                    <Grid item xs={12} sm={3}>
                                        <WSButton fullWidth variant={ButtonVariant.OUTLINED} primary onClick={onCancelRequest}>CANCEL REQUEST</WSButton>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <WSButton fullWidth primary onClick={markChecklistReviewed} data-testid={'continue-to-request'}>CONTINUE TO REQUEST</WSButton>
                                    </Grid>
                                </Grid>
                                : null}
                            {ValueIsSet(checklistReviewed) && checklistReviewed ? form : null}
                            <DuplicateRequestModal  duplicateRequests={duplicateRequest}
                                                    linkString={'requests/'}
                                                    onClose={closeDuplicateRequestModal}
                                                    open={showDuplicateRequestModal}>
                            </DuplicateRequestModal>
                        </section>
                    </div>
            }
            </div>
        )
    }
}

const requestShortDescription = `
            id
            type
            description
            emergency
            status
        `;

const rfpQuestionFields = `
id
question
required
answerFormat
includeOtherOption
ordering
answerOptions{
    answer
}
conditionalUpon {
    answer
    id
}

`;

const rateCardEntryObject =
    `id
    title
    scopeOfWork
    unitRetailPrice
    contractItem{
        id
        name
        lineItemType{
            serviceLine{
                id
                name
            }
        }
    }`;

const locationFields = `
    clientLocationAttachments {
        id
        singleUseUrl
        title
        description
    }
    clientLocationFloors {
        id
        locationId
        level
        title
        description
        ceilingHeightFt
        deleted
    }
    industry
    otherIndustry
    clientLocationOperationDetails {
        id
        locationId
        hasLadderOnSite
        ladderHeightFt
    }
    id
`

export default NewRequestPage;