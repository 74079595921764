import React, {Fragment} from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';

import Loader from "../components/Loader";
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import List from "worksmith/components/List/List.web";
import ExpandableListItem from "worksmith/components/ListItem/ExpandableListItem/ExpandableListItem";
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import ClientRoutes from "../ClientRoutes";
import {Link, withRouter} from "react-router-dom";
import {RequestStatisticCounts} from "worksmith/enums/RequestStatisticCounts";
import InfiniteScrollingList from "worksmith/components/InfiniteScrollingList/InfiniteScrollingList";
import RequestCard from "worksmith/composite-components/RequestCard/RequestCard.web";
import CustomLink from '../components/Link';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import NewRequestStatus from "worksmith/enums/api/proposal/NewRequestStatus";
import RfpType from "worksmith/enums/api/proposal/RfpType";
import ClientUserRole from "worksmith/enums/api/user/ClientUserRole";
import ClientInvoiceWarningBanner from "worksmith/components/ClientInvoiceWarningBanner/ClientInvoiceWarningBanner";
import Box from "@mui/material/Box";
import FullWidthPageContainer from "../FullWidthPageContainer";
import Tabs from "worksmith/components/Tab/Tabs";
import {formatTimestamp, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import RequestApprovalType from "worksmith/enums/api/cpm/RequestApprovalType";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import moment from "moment/moment";

const CongratulationsText = styled(Text)`
    font-size: 1em;
    margin: 1em;
    font-family: "Open Sans", Verdana, Geneva, sans-serif;
`;

const ListWithMargins = styled(InfiniteScrollingList)`
    margin-top: 2em;
    margin-bottom: 1em;
`;

const GridWithMargins = styled(Grid)`
    padding-top: 2em;
`;

const filterTabs = [
    <Text key={0}>Admin Only</Text>,
    <Text key={1}>All Users</Text>
];


const DashboardPageView = (props) => {
    let {client,
        hideLocationNames,
        loading,
        noActionRequired,
        noAdminActionRequired,
        noLocalActionRequired,
        requestStatistics,
        requestStatisticSections,
        storeSignOffEnabled,
        userRole} = props;

    let linkTo = (path) => {
        props.history.push(path);
    };


    let getRenderItem = (key) => {
        return (request, style) => {
            let tooltip = null;

            switch (key){
                case RequestStatisticCounts.ESTIMATES_PENDING_ADMIN:
                case RequestStatisticCounts.ESTIMATES_OPEN_NOT_PENDING_ADMIN:
                case RequestStatisticCounts.PENDING_BIDS_OR_ESTIMATES:
                    if(ValueIsSet(request.bidSubmittedActivity)){
                        tooltip = "Submitted by " + request.bidSubmittedActivity.user.displayName + " on " + formatTimestamp(request.bidSubmittedActivity.createdTimestamp);
                    }
                    break;
                case RequestStatisticCounts.WALKTHROUGHS_SUBMITTED:
                    if(ValueIsSet(request.walkthroughSubmittedActivity)){
                        tooltip = "Submitted by " + request.walkthroughSubmittedActivity.user.displayName + " on " + formatTimestamp(request.walkthroughSubmittedActivity.createdTimestamp);
                    }else if (ValueIsSet(request.bidSubmittedActivity)){
                        tooltip = "Submitted on " + formatTimestamp(request.bidSubmittedActivity.createdTimestap);
                    }else if (ValueIsSet(request.bidAcceptedAndScheduledActivity)){
                        tooltip = "Submitted by " + request.bidAcceptedAndScheduledActivity.user.displayName + " on " + formatTimestamp(request.bidAcceptedAndScheduledActivity.createdTimestamp);
                    }else {
                        let mostRecentTimestamp = '1970-01-01T12:12:12'; //hard coding old timestamp for comparisions
                        request.activeWalkthroughs.forEach(wt => {
                            const timestamp = wt.createdTimestamp;
                            if(moment(timestamp).isAfter(moment(mostRecentTimestamp))){
                                mostRecentTimestamp = timestamp;
                            }
                        });
                        tooltip = "Submitted on " + formatTimestamp(mostRecentTimestamp);
                    }
                    break;
                case RequestStatisticCounts.JOBS_TO_SCHEDULE:
                    if(ValueIsSet(request.bidScheduleCounterOfferedActivity)){
                        tooltip = "Submitted by " + request.bidScheduleCounterOfferedActivity.user.displayName + " on " + formatTimestamp(request.bidScheduleCounterOfferedActivity.createdTimestamp);
                    }else if (ValueIsSet(request.bidSubmittedActivity)){
                        tooltip = "Submitted on " + formatTimestamp(request.bidSubmittedActivity.createdTimestap);
                    }
                    break;
                case RequestStatisticCounts.WALKTHROUGHS_TO_SCHEDULE:
                    if(ValueIsSet(request.bidScheduleCounterOfferedActivity)){
                        tooltip = "Submitted by " + request.bidScheduleCounterOfferedActivity.user.displayName + " on " + formatTimestamp(request.bidScheduleCounterOfferedActivity.createdTimestamp);
                    } else if(ValueIsSet(request.walkthroughSubmittedActivity)){
                        tooltip = "Submitted by " + request.walkthroughSubmittedActivity.user.displayName + " on " + formatTimestamp(request.walkthroughSubmittedActivity.createdTimestamp);
                    }else if (ValueIsSet(request.bidSubmittedActivity)){
                        tooltip = "Submitted on " + formatTimestamp(request.bidSubmittedActivity.createdTimestap);
                    }else {
                        let mostRecentTimestamp = '1970-01-01T12:12:12'; //hard coding old timestamp for comparisions
                        request.activeWalkthroughs.forEach(wt => {
                            const timestamp = wt.createdTimestamp;
                            if(moment(timestamp).isAfter(moment(mostRecentTimestamp))){
                                mostRecentTimestamp = timestamp;
                            }
                        });
                        tooltip = "Submitted on " + formatTimestamp(mostRecentTimestamp);
                    }
                    break;
                case RequestStatisticCounts.CORPORATE_REVIEW:
                    tooltip = "Submitted on " + formatTimestamp(request.createdTimestamp);
                    break;
                case RequestStatisticCounts.CORPORATE_PRICING_REVIEW:
                    tooltip = "Submitted by " + request.approvalRequestedActivity.user.displayName + " on " + formatTimestamp(request.approvalRequestedActivity.createdTimestamp);
                    break;
                case RequestStatisticCounts.OPEN_CHANGE_ORDERS_ADMIN:
                case RequestStatisticCounts.OPEN_CHANGE_ORDERS_NOT_ADMIN:
                case RequestStatisticCounts.OPEN_CHANGE_ORDERS:
                    if(ValueIsSet(request.pendingChangeOrderRequest) && ValueIsSet(request.pendingChangeOrderRequest.changeOrderSubmittedActivity)){
                        tooltip = "Submitted by " + request.pendingChangeOrderRequest.changeOrderSubmittedActivity.user.displayName + " on " + formatTimestamp(request.pendingChangeOrderRequest.changeOrderSubmittedActivity.createdTimestamp);
                    }
                    break;
                case RequestStatisticCounts.UNREAD_MESSAGES:
                    if(ValueIsSet(request.latestUnreadMessage)){
                        tooltip = "Submitted by " + request.latestUnreadMessage.author.displayName + " on " + formatTimestamp(request.latestUnreadMessage.createdTimestamp);
                    }else if(ValueIsSet(request.latestUnreadComment)){
                        tooltip = "Submitted by " + request.latestUnreadComment.user.displayName + " on " + formatTimestamp(request.latestUnreadComment.createdTimestamp);
                    }
                   break;
            }

            return (
                <div style={{...style, overflow: "hidden"}}>
                    <Tooltip placement={TooltipPlacement.RIGHT} title={ValueIsSet(tooltip) ? tooltip : null}>
                        <CustomLink to={getLinkTo(request, key)}>
                            <RequestCard
                                request={request}
                                hideLocationName={hideLocationNames}/>
                        </CustomLink>
                    </Tooltip>
                </div>
            )
        };
    };

    let getLinkTo = (request, key) => {
        let keysThatShouldGoToRecurringServicePage = [
            RequestStatisticCounts.OPEN_CHANGE_ORDERS,
            RequestStatisticCounts.OPEN_CHANGE_ORDERS_NOT_ADMIN,
            RequestStatisticCounts.OPEN_CHANGE_ORDERS_ADMIN,
            RequestStatisticCounts.JOBS_TO_RESCHEDULE,
            RequestStatisticCounts.RECURRING_SCHEDULE_CHANGES,
            RequestStatisticCounts.UNREAD_MESSAGES
        ];

        if (request.isRecurring &&
            keysThatShouldGoToRecurringServicePage.includes(key) &&
            (request.newStatus === NewRequestStatus.SCHEDULED || request.newStatus === NewRequestStatus.COMPLETED)) {

            return ClientRoutes.RECURRING_SERVICES_SINGLE_LOCATION_ANCHOR_TAB(request.serviceLine.id, request.clientLocation.id, false, request.associatedObligationTemplate.id)
        }

        return ClientRoutes.SINGLE_REQUESTS(request.id);
    };

    let createCollapsedList = (key) => {
        let count = requestStatistics[key];
        let height = 490;
        let itemSize = 70;
        if (((count) * itemSize) < height) {
            height = (count) * itemSize;
        }

        let renderItem = getRenderItem(key);

        return (
            <ListWithMargins
                currentPage={requestStatisticSections[key].currentPage}
                height={height}
                items={requestStatisticSections[key].requests}
                itemSize={itemSize}
                loading={requestStatisticSections[key].loadingSection}
                loadNextPageCallback={requestStatisticSections[key].loadItems}
                renderItems={renderItem}
                totalPages={requestStatisticSections[key].totalPages}
                threshold={2}
                width={"100%"}/>
        )
    };

    const TAB_CONTENTS = [
        <Fragment key={0}>
            {noAdminActionRequired ?
                <Grid item xs={12}>
                    <CongratulationsText>Congratulations, you're all caught up! View <Link to={"/requests"}>All
                        Requests</Link> or your <Link
                        to={"/calendar"}>Calendar</Link>.</CongratulationsText>
                </Grid>
                :
                <Grid item xs={12} sm={11} md={9} lg={6}>
                    <List>
                        {
                            requestStatistics[RequestStatisticCounts.CORPORATE_REVIEW] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsInCorporateReviewCount}
                                    title={Pluralize(requestStatistics.requestsInCorporateReviewCount, "Request") + " in corporate review"}>
                                    {createCollapsedList(RequestStatisticCounts.CORPORATE_REVIEW)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.CORPORATE_PRICING_REVIEW] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsInCorporatePricingReviewCount}
                                    title={Pluralize(requestStatistics.requestsInCorporatePricingReviewCount, "Request") + " in corporate pricing review"}>
                                    {createCollapsedList(RequestStatisticCounts.CORPORATE_PRICING_REVIEW)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            ValueIsSet(client.adminNTE) && requestStatistics[RequestStatisticCounts.WALKTHROUGHS_SUBMITTED] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithWalkthroughsSubmittedCount}
                                    title={Pluralize(requestStatistics.requestsWithWalkthroughsSubmittedCount, "Request") + " with walkthroughs submitted"}>
                                    {createCollapsedList(RequestStatisticCounts.WALKTHROUGHS_SUBMITTED)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.OPEN_CHANGE_ORDERS_ADMIN] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithOpenChangeOrdersAboveAdminNTECount}
                                    title={'Open Change ' + Pluralize(requestStatistics.requestsWithOpenChangeOrdersAboveAdminNTECount, "Order")}>
                                    {createCollapsedList(RequestStatisticCounts.OPEN_CHANGE_ORDERS_ADMIN)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.PENDING_BIDS_OR_ESTIMATES] && client.id === 954 ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithPendingBidsOrEstimatesCount}
                                    title={Pluralize(requestStatistics.requestsWithPendingBidsOrEstimatesCount, "Request") + " with pending bids/estimates"}>
                                    {createCollapsedList(RequestStatisticCounts.PENDING_BIDS_OR_ESTIMATES)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.ESTIMATES_PENDING_ADMIN] && client.id !== 954 ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithEstimatesPendingAdminApprovalCount}
                                    title={Pluralize(requestStatistics.requestsWithEstimatesPendingAdminApprovalCount, "Request") + " with pending bids/estimates"}>
                                    {createCollapsedList(RequestStatisticCounts.ESTIMATES_PENDING_ADMIN)}
                                </ExpandableListItem>
                                : null
                        }
                    </List>
                </Grid>
            }
        </Fragment>,
        <Fragment key={1}>
            {noLocalActionRequired ?
                <Grid item xs={12}>
                    <CongratulationsText>Congratulations, you're all caught up! View <Link to={"/requests"}>All
                        Requests</Link> or your <Link
                        to={"/calendar"}>Calendar</Link>.</CongratulationsText>
                </Grid>
                :
                <Grid item xs={12} sm={11} md={9} lg={6}>
                    <List>
                        {
                            requestStatistics[RequestStatisticCounts.EMERGENCY] ?
                                <ExpandableListItem
                                    count={requestStatistics[RequestStatisticCounts.EMERGENCY]}
                                    title={"Emergency " + Pluralize(requestStatistics[RequestStatisticCounts.EMERGENCY], "request")}>
                                    {createCollapsedList(RequestStatisticCounts.EMERGENCY)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.OPEN_CHANGE_ORDERS_NOT_ADMIN] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithOpenChangeOrdersBelowAdminNTECount}
                                    title={'Open Change ' + Pluralize(requestStatistics.requestsWithOpenChangeOrdersBelowAdminNTECount, "Order")}>
                                    {createCollapsedList(RequestStatisticCounts.OPEN_CHANGE_ORDERS_NOT_ADMIN)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.WALKTHROUGHS_SUBMITTED] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithWalkthroughsSubmittedCount}
                                    title={Pluralize(requestStatistics.requestsWithWalkthroughsSubmittedCount, "Request") + " with walkthroughs submitted"}>
                                    {createCollapsedList(RequestStatisticCounts.WALKTHROUGHS_SUBMITTED)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.WALKTHROUGHS_TO_SCHEDULE] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithWalkthroughsToScheduleCount}
                                    title={Pluralize(requestStatistics.requestsWithWalkthroughsToScheduleCount, "Request") + " with walkthroughs to schedule"}>
                                    {createCollapsedList(RequestStatisticCounts.WALKTHROUGHS_TO_SCHEDULE)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.ESTIMATES_OPEN_NOT_PENDING_ADMIN] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithEstimatesOpenAndNotPendingAdminApprovalCount}
                                    title={Pluralize(requestStatistics.requestsWithEstimatesOpenAndNotPendingAdminApprovalCount, "Request") + " with pending bids/estimates"}>
                                    {createCollapsedList(RequestStatisticCounts.ESTIMATES_OPEN_NOT_PENDING_ADMIN)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.JOBS_TO_SCHEDULE] ?
                                <ExpandableListItem
                                    count={requestStatistics.jobsToScheduleCount}
                                    title={Pluralize(requestStatistics.jobsToScheduleCount, "Job") + " to schedule"}>
                                    {createCollapsedList(RequestStatisticCounts.JOBS_TO_SCHEDULE)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.JOBS_TO_RESCHEDULE] ?
                                <ExpandableListItem expandable={false}
                                                    onClick={() => linkTo(ClientRoutes.SCHEDULE_CHANGES)}
                                                    count={requestStatistics.jobsToRescheduleCount}
                                                    title={Pluralize(requestStatistics.jobsToRescheduleCount, "Job") + " to reschedule"}/>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.RECURRING_SCHEDULE_CHANGES] ?
                                <ExpandableListItem expandable={false}
                                                    onClick={() => linkTo(ClientRoutes.SCHEDULE_CHANGES)}
                                                    count={requestStatistics.recurringScheduleChangesCount}
                                                    title={"Recurring schedule " + Pluralize(requestStatistics.recurringScheduleChangesCount, "change")}/>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.UNREAD_MESSAGES] ?
                                <ExpandableListItem
                                    count={requestStatistics.requestsWithUnreadMessagesCount}
                                    title={Pluralize(requestStatistics.requestsWithUnreadMessagesCount, "Request") + " with unread messages"}>
                                    {createCollapsedList(RequestStatisticCounts.UNREAD_MESSAGES)}
                                </ExpandableListItem>
                                : null
                        }
                        {
                            storeSignOffEnabled && requestStatistics[RequestStatisticCounts.JOBS_AWAITING_SIGN_OFF] ?
                                <ExpandableListItem expandable={false}
                                                    onClick={() => linkTo(ClientRoutes.CALENDAR_LIST_VIEW_INITIAL_TAB(3))}
                                                    count={requestStatistics.jobsAwaitingSignOffCount}
                                                    title={Pluralize(requestStatistics.jobsAwaitingSignOffCount, "Job") + " awaiting your sign off"}/>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.JOBS_WITH_OPEN_CONCERNS] ?
                                <ExpandableListItem expandable={false}
                                                    onClick={() => linkTo(ClientRoutes.CALENDAR_LIST_VIEW_INITIAL_TAB(2))}
                                                    count={requestStatistics.jobsWithOpenConcernsCount}
                                                    title={Pluralize(requestStatistics.jobsWithOpenConcernsCount, "Job") + " with open concerns"}/>
                                : null
                        }
                        {
                            requestStatistics[RequestStatisticCounts.JOBS_COMPLETED_IN_LAST_WEEK] ?
                                <ExpandableListItem expandable={false}
                                                    onClick={() => linkTo(ClientRoutes.CALENDAR_LIST_VIEW_INITIAL_TAB(1))}
                                                    count={requestStatistics.jobsCompletedInLastWeek}
                                                    title={Pluralize(requestStatistics.jobsCompletedInLastWeek, "Job") + " completed in last week"}/>
                                : null
                        }
                    </List>
                </Grid>
            }
        </Fragment>
        ];



    return (
        <FullWidthPageContainer id={"dashboardPage"}>
            {loading ?
                <Loader/>
                :
                <Box mt={5} width={'100%'}>
                    {
                        userRole === ClientUserRole.ADMIN ?
                            <ClientInvoiceWarningBanner/>
                            :
                            null
                    }
                    <GridWithMargins container spacing={2}>

                        <Grid item xs={12}>
                            <Text variant={TextVariant.H5}>Needs Attention</Text>
                        </Grid>

                        {noActionRequired ?
                            <Grid item xs={12}>
                                <CongratulationsText>Congratulations, you're all caught up! View <Link to={"/requests"}>All
                                    Requests</Link> or your <Link
                                    to={"/calendar"}>Calendar</Link>.</CongratulationsText>
                            </Grid>
                            :
                            (client.configurationSettings.showAdminTabDashboard && userRole === ClientUserRole.ADMIN && (ValueIsSet(client.adminNTE) || client.settings.requiresApprovalToSubmit === RequestApprovalType.ADMIN)) ?
                                <Grid item xs={12} margin={'10px 0 0 0'}>
                                    <Tabs titleList={filterTabs} initialValue={0} tabFullWidth>
                                        {TAB_CONTENTS}
                                    </Tabs>
                                </Grid>
                                :
                                <Grid item xs={12} sm={11} md={9} lg={6}>
                                    <List>
                                        {
                                            requestStatistics[RequestStatisticCounts.EMERGENCY] ?
                                                <ExpandableListItem
                                                    count={requestStatistics[RequestStatisticCounts.EMERGENCY]}
                                                    title={"Emergency " + Pluralize(requestStatistics[RequestStatisticCounts.EMERGENCY], "request")}>
                                                    {createCollapsedList(RequestStatisticCounts.EMERGENCY)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.CORPORATE_REVIEW] ?
                                                <ExpandableListItem
                                                    count={requestStatistics.requestsInCorporateReviewCount}
                                                    title={Pluralize(requestStatistics.requestsInCorporateReviewCount, "Request") + " in corporate review"}>
                                                    {createCollapsedList(RequestStatisticCounts.CORPORATE_REVIEW)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            // AS OF RIGHT NOW ONLY HIDE THIS FROM CHANEL REGIONAL/LOCAL MANAGERS
                                            requestStatistics[RequestStatisticCounts.CORPORATE_PRICING_REVIEW] && client.id !== 954 ?
                                                <ExpandableListItem
                                                    count={requestStatistics.requestsInCorporatePricingReviewCount}
                                                    title={Pluralize(requestStatistics.requestsInCorporatePricingReviewCount, "Request") + " in corporate pricing review"}>
                                                    {createCollapsedList(RequestStatisticCounts.CORPORATE_PRICING_REVIEW)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.WALKTHROUGHS_SUBMITTED] && client.id !== 954 ?
                                                <ExpandableListItem
                                                    count={requestStatistics.requestsWithWalkthroughsSubmittedCount}
                                                    title={Pluralize(requestStatistics.requestsWithWalkthroughsSubmittedCount, "Request") + " with walkthroughs submitted"}>
                                                    {createCollapsedList(RequestStatisticCounts.WALKTHROUGHS_SUBMITTED)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.WALKTHROUGHS_TO_SCHEDULE] ?
                                                <ExpandableListItem
                                                    count={requestStatistics.requestsWithWalkthroughsToScheduleCount}
                                                    title={Pluralize(requestStatistics.requestsWithWalkthroughsToScheduleCount, "Request") + " with walkthroughs to schedule"}>
                                                    {createCollapsedList(RequestStatisticCounts.WALKTHROUGHS_TO_SCHEDULE)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.OPEN_CHANGE_ORDERS] ?
                                                <ExpandableListItem
                                                    count={requestStatistics.requestsWithOpenChangeOrdersCount}
                                                    title={'Open Change ' + Pluralize(requestStatistics.requestsWithOpenChangeOrdersCount, "Order")}>
                                                    {createCollapsedList(RequestStatisticCounts.OPEN_CHANGE_ORDERS)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.PENDING_BIDS_OR_ESTIMATES] && client.id !== 954 ?
                                                <ExpandableListItem
                                                    count={requestStatistics.requestsWithPendingBidsOrEstimatesCount}
                                                    title={Pluralize(requestStatistics.requestsWithPendingBidsOrEstimatesCount, "Request") + " with pending bids/estimates"}>
                                                    {createCollapsedList(RequestStatisticCounts.PENDING_BIDS_OR_ESTIMATES)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.JOBS_TO_SCHEDULE] ?
                                                <ExpandableListItem
                                                    count={requestStatistics.jobsToScheduleCount}
                                                    title={Pluralize(requestStatistics.jobsToScheduleCount, "Job") + " to schedule"}>
                                                    {createCollapsedList(RequestStatisticCounts.JOBS_TO_SCHEDULE)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.JOBS_TO_RESCHEDULE] ?
                                                <ExpandableListItem expandable={false}
                                                                    onClick={() => linkTo(ClientRoutes.SCHEDULE_CHANGES)}
                                                                    count={requestStatistics.jobsToRescheduleCount}
                                                                    title={Pluralize(requestStatistics.jobsToRescheduleCount, "Job") + " to reschedule"}/>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.RECURRING_SCHEDULE_CHANGES] ?
                                                <ExpandableListItem expandable={false}
                                                                    onClick={() => linkTo(ClientRoutes.SCHEDULE_CHANGES)}
                                                                    count={requestStatistics.recurringScheduleChangesCount}
                                                                    title={"Recurring schedule " + Pluralize(requestStatistics.recurringScheduleChangesCount, "change")}/>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.UNREAD_MESSAGES] ?
                                                <ExpandableListItem
                                                    count={requestStatistics.requestsWithUnreadMessagesCount}
                                                    title={Pluralize(requestStatistics.requestsWithUnreadMessagesCount, "Request") + " with unread messages"}>
                                                    {createCollapsedList(RequestStatisticCounts.UNREAD_MESSAGES)}
                                                </ExpandableListItem>
                                                : null
                                        }
                                        {
                                            storeSignOffEnabled && requestStatistics[RequestStatisticCounts.JOBS_AWAITING_SIGN_OFF] ?
                                                <ExpandableListItem expandable={false}
                                                                    onClick={() => linkTo(ClientRoutes.CALENDAR_LIST_VIEW_INITIAL_TAB(3))}
                                                                    count={requestStatistics.jobsAwaitingSignOffCount}
                                                                    title={Pluralize(requestStatistics.jobsAwaitingSignOffCount, "Job") + " awaiting your sign off"}/>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.JOBS_WITH_OPEN_CONCERNS] ?
                                                <ExpandableListItem expandable={false}
                                                                    onClick={() => linkTo(ClientRoutes.CALENDAR_LIST_VIEW_INITIAL_TAB(2))}
                                                                    count={requestStatistics.jobsWithOpenConcernsCount}
                                                                    title={Pluralize(requestStatistics.jobsWithOpenConcernsCount, "Job") + " with open concerns"}/>
                                                : null
                                        }
                                        {
                                            requestStatistics[RequestStatisticCounts.JOBS_COMPLETED_IN_LAST_WEEK] ?
                                                <ExpandableListItem expandable={false}
                                                                    onClick={() => linkTo(ClientRoutes.CALENDAR_LIST_VIEW_INITIAL_TAB(1))}
                                                                    count={requestStatistics.jobsCompletedInLastWeek}
                                                                    title={Pluralize(requestStatistics.jobsCompletedInLastWeek, "Job") + " completed in last week"}/>
                                                : null
                                        }
                                    </List>
                                </Grid>


                        }
                    </GridWithMargins>
                </Box>}
        </FullWidthPageContainer>
    );
};

DashboardPageView.defaultProps = {
    hideLocationNames: false
};

DashboardPageView.propTypes = {
    client: PropTypes.shape({
        adminNTE: PropTypes.number,
        configurationSettings: PropTypes.shape({
            showAdminTabDashboard: PropTypes.bool,
        })
    }),
    hideLocationNames: PropTypes.bool,
    loading: PropTypes.bool,
    noActionRequired: PropTypes.bool,
    noAdminActionRequired: PropTypes.bool,
    requestStatistics: PropTypes.shape({
        requestsInCorporateReviewCount: PropTypes.number,
        requestsInCorporatePricingReviewCount: PropTypes.number,
        requestsWithWalkthroughsSubmittedCount: PropTypes.number,
        requestsWithWalkthroughsPendingAdminApprovalCount: PropTypes.number,
        requestsWithWalkthroughsOpenAndNotPendingAdminApprovalCount: PropTypes.number,
        requestsWithEstimatesPendingAdminApprovalCount: PropTypes.number,
        requestsWithEstimatesOpenAndNotPendingAdminApprovalCount: PropTypes.number,
        requestsWithOpenChangeOrdersAboveAdminNTECount: PropTypes.number,
        requestsWithOpenChangeOrdersBelowAdminNTECount: PropTypes.number,
        requestsWithWalkthroughsToScheduleCount: PropTypes.number,
        requestsWithPendingBidsOrEstimatesCount: PropTypes.number,
        jobsToScheduleCount: PropTypes.number,
        jobsToRescheduleCount: PropTypes.number,
        recurringScheduleChangesCount: PropTypes.number,
        requestsWithUnreadMessagesCount: PropTypes.number,
        emergencyRequestsCount: PropTypes.number,
        jobsAwaitingSignOffCount: PropTypes.number,
        jobsWithOpenConcernsCount: PropTypes.number,
    }),
    requestStatisticSections: PropTypes.shape({
        loadItems: PropTypes.func,
        loadingSection: PropTypes.bool,
        currentPage: PropTypes.number,
        totalPages: PropTypes.number,
        requests: PropTypes.arrayOf(
            PropTypes.shape({
                clientLocation: PropTypes.shape({
                    title: PropTypes.string.isRequired
                }),
                bidScheduleCounterOfferedActivity: PropTypes.shape({
                    createdTimestamp: PropTypes.string,
                    user: PropTypes.shape({
                        displayName: PropTypes.string
                    }),
                }),
                bidSubmittedActivity: PropTypes.shape({
                    createdTimestamp: PropTypes.string,
                    user: PropTypes.shape({
                        displayName: PropTypes.string
                    }),
                }),
                pendingChangeOrderRequest: PropTypes.shape({
                    changeOrderSubmittedActivity: PropTypes.shape({
                        createdTimestamp: PropTypes.string,
                        user: PropTypes.shape({
                            displayName: PropTypes.string
                        }),
                    }),
                }),
                walkthroughSubmittedActivity: PropTypes.shape({
                    createdTimestamp: PropTypes.string,
                    user: PropTypes.shape({
                        displayName: PropTypes.string
                    }),
                }),
                emergency: PropTypes.bool,
                id: PropTypes.number.isRequired,
                serviceLine: PropTypes.shape({
                    name: PropTypes.string.isRequired
                }),
                newStatus: CustomPropTypes.enum(NewRequestStatus),
                type: CustomPropTypes.enum(RfpType)
            })
        )
    }),
    storeSignOffEnabled: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired
};

export default withRouter(DashboardPageView);