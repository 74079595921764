import React, {Component} from 'react';
import Button from "../../components/Button";
import NewRequestForm from "../../components/forms/requests/NewRequestForm";

class KendraScottNewRequestPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            client: props.client,
            type: '',
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(value){
        this.setState({value});
    }

    render() {
        let isAdmin = !!this.state.user.clientRoles;
        let prohibitRequests = this.state.client.settings.prohibitLocationManagerSubmissions && !isAdmin;

        return (
            <div>
            {
                prohibitRequests ?
                    <section className="ws-section" style={{'text-align': 'center'}}>
                        <h1 className="page-header">Only Admins are allowed to submit new requests.</h1>
                        <Button type={'primary'} onPress={() => window.history.back()} message={'Go Back'}/>
                    </section>
                :
                    <div>
                        <section style={{'border':'0px', 'textAlign':'center'}} className="ws-section">
                            <h1 className="page-header">Submit Request</h1>
                        </section>

                        <section style={{'border':'0px'}} className="ws-section">
                            <NewRequestForm
                                props={this.props}
                                initialType={'on-demand'}
                                defaultItemizedBidRequired={true}
                                defaultWalkthroughPreference={"Walkthrough Prohibited"}
                                hideRequestType={true}
                                hideWalkthroughPreference={true}
                                />
                        </section>
                    </div>
            }
            </div>
        )
    }
}

export default KendraScottNewRequestPage;