import React, {Component} from 'react';
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import ClientInvoiceWarningBannerView
    from "worksmith/components/ClientInvoiceWarningBanner/ClientInvoiceWarningBannerView";
import {WithContext} from "../../../context/GlobalContext";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";

const graphQLService = new GraphQLServiceClass();

class ClientInvoiceWarningBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            outstandingBalance: 0,
            pastDueBalance: 0,
            invoiceDueDate: ''
        };
    }

    componentDidMount() {
        graphQLService.findOneById(this.props.context.client.id, GraphQLObjectType.CLIENT, clientQueryFields).then(client => {
            this.setState({outstandingBalance: client.balanceDueOnUpcomingInvoiceDueDate, invoiceDueDate: client.upcomingInvoiceDueDate, pastDueBalance: client.pastDueBalance, loading: false});
        });
    }

    render() {
        const {outstandingBalance, pastDueBalance, invoiceDueDate, loading} = this.state;
        return (
            !loading ?
            <ClientInvoiceWarningBannerView
                outstandingBalance={outstandingBalance}
                pastDueBalance={pastDueBalance}
                invoiceDueDate={invoiceDueDate}/>
                :
                null
        )
    }
}

let clientQueryFields = `
    pastDueBalance
    upcomingInvoiceDueDate
    balanceDueOnUpcomingInvoiceDueDate
`;

export default WithContext(ClientInvoiceWarningBanner);