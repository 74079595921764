import React, {Fragment} from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import IconButton, {IconButtonSize} from "../Button/IconButton";
import Icon from "../Icon/Icon";
import Loader from "../Loader/Loader";
import TextField, {TextFieldVariant} from "../Inputs/TextField/TextField";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import {IconFontSize} from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";


const ScopeOfWorkContainer = styled.div`
    padding: 1em 0em;
`;

const ScopeOfWorkItemHeaderContainer = styled.div`
    display: inline-block;
`;

const ScopeOfWorkTextContainer = styled.div`
`;

const ScopeOfWorkTitleContainer = styled.div`
    display: inline-block;
`;

const EditButtonContainer = styled.div`
    display: inline-block;
`;

const SaveButtonContainer = styled.div`
    display: inline-block;
`;

const CancelButtonContainer = styled.div`
    display: inline-block;
`;


const ContractItemScopeOfWorkView = (props) => {
    let { loading, editingScopeOfWork, rateCardEntry, toggleEditingScopeOfWork, canEdit, updateScopeOfWork, saveUpdatedScopeOfWork } = props;

    if (loading) {
        return (<Loader/>)
    } else {
        return (
            <Fragment>
                <ScopeOfWorkContainer>
                    {!editingScopeOfWork ?
                        <Fragment>
                            <ScopeOfWorkItemHeaderContainer>
                                <ScopeOfWorkTitleContainer>
                                    <Text light color={TextColor.TEXT_PRIMARY} variant={TextVariant.SUBTITLE_1}>
                                        {rateCardEntry.displayValue}
                                    </Text>
                                </ScopeOfWorkTitleContainer>
                                {canEdit ?
                                    <EditButtonContainer>
                                        <IconButton secondary size={IconButtonSize.SMALL}
                                                    onClick={toggleEditingScopeOfWork}>
                                            <Icon secondary fontSize={IconFontSize.SMALL} name={IconType.EDIT}/>
                                        </IconButton>
                                    </EditButtonContainer> : null
                                }
                            </ScopeOfWorkItemHeaderContainer>
                            <ScopeOfWorkTextContainer>
                                <Text light color={TextColor.TEXT_PRIMARY} variant={TextVariant.BODY_2}>
                                    {rateCardEntry.scopeOfWork == null || rateCardEntry.scopeOfWork ===  "" ?
                                        "No scope of work defined yet." : rateCardEntry.scopeOfWork}
                                </Text>
                            </ScopeOfWorkTextContainer>
                        </Fragment>
                        :
                        <Fragment>
                            <ScopeOfWorkItemHeaderContainer>
                                <ScopeOfWorkTitleContainer>
                                    <Text color={TextColor.TEXT_PRIMARY} variant={TextVariant.SUBTITLE_2}>
                                        {rateCardEntry.displayValue}
                                    </Text>
                                </ScopeOfWorkTitleContainer>
                                <SaveButtonContainer>
                                    <IconButton secondary size={IconButtonSize.SMALL}
                                                onClick={saveUpdatedScopeOfWork}><Icon fontSize={IconFontSize.SMALL}
                                                                                       name={IconType.CHECK}/>
                                    </IconButton>
                                </SaveButtonContainer>
                                <CancelButtonContainer>
                                    <IconButton secondary size={IconButtonSize.SMALL}
                                                onClick={toggleEditingScopeOfWork}>
                                        <Icon secondary fontSize={IconFontSize.SMALL} name={IconType.CANCEL}/>
                                    </IconButton>
                                </CancelButtonContainer>
                            </ScopeOfWorkItemHeaderContainer>
                            <ScopeOfWorkTextContainer>
                                <TextField onChange={e => updateScopeOfWork({id: rateCardEntry.id, value: e})}
                                           initialValue={rateCardEntry.scopeOfWork}
                                           variant={TextFieldVariant.OUTLINED} multiline rows={3} fullWidth={true}/>
                            </ScopeOfWorkTextContainer>
                        </Fragment>
                    }
                </ScopeOfWorkContainer>
            </Fragment>
        )
    }
};

ContractItemScopeOfWorkView.propTypes = {
    loading:                    PropTypes.bool.isRequired,
    rateCardEntry:              PropTypes.shape({
        id:                     PropTypes.number.isRequired,
        displayValue:           PropTypes.string,
        scopeOfWork:            PropTypes.string
    }),
    canEdit:                    PropTypes.bool,
    toggleEditingScopeOfWork:   PropTypes.func,
    editingScopeOfWork:         PropTypes.bool,
    updateScopeOfWork:          PropTypes.func,
    saveUpdatedScopeOfWork:     PropTypes.func
};

export default ContractItemScopeOfWorkView;