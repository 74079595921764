export const Color = Object.freeze({
    BLUE: '#3F7BBF',
    LIGHT_BLUE: '#E3EAF8',
    DARK_BLUE: '#40577E',
    PRIMARY_DARK: '#182934',
    NAVY: '#293D4B',
    PRIMARY: '#293D4B',
    SECONDARY_DARK: '#0C4D58',
    PRIMARY_LIGHT: '#527187',
    TURQUOISE: '#156F83',
    SECONDARY: '#156F83',
    SECONDARY_LIGHT: '#2296B5',
    SLA_LIGHT_BLUE:'#8AB7C1',
    INCREMENT_LIGHT_BG: '#d1e7eb',
    GREEN: '#6DAD3D',
    VENDOR_GREEN: '#6FC080',
    LIGHT_ORANGE: '#F4DACB',
    ORANGE: '#F78B21',
    DARK_ORANGE: '#BD4A09',
    VENDOR_ORANGE: '#F2B776',
    VENDOR_GREY: '#797979',
    RED: '#DD2E2B',
    RUST: '#CE3E04',
    ERROR: '#B00020',
    BLACK: '#171616',
    DARK_GREY: '#3D3D3D',
    SNACKBAR: '#323232',
    TEXT_SECONDARY: '#808080',
    BORDER_GREY: '#979797',
    MEDIUM_GREY: '#B2B2B2',
    GREY: '#D9D9D9',
    LIGHT_GREY: '#EAEAEA',
    OFF_WHITE: '#F8F8F8',
    WHITE: '#FFFFFF',
    OPTION_HOVER_BLUE: '#E6EEF6',
    ICON_GRAY: '#A5B7C5',
    TRANSPARENT: 'transparent',
    LEGEND_EMERGENCY: '#C74C62',
    HOVER_GREY: '#0000000a',
});

//Order is important. Colors should be picked in the order listed here when adding additional data points to a graph/chart
export const DataVisualizationColor = Object.freeze({
    PURPLE_60: '#6929C4',
    CYAN_60: '#1192E8',
    TEAL_70: '#005D5D',
    MAGENTA_70: '#9F1853',
    RED_50: '#FA4D56',
    RED_90: '#570408',
    GREEN_60: '#198038',
    BLUE_80: '#002D9C',
    MAGENTA_50: '#EE538B',
    YELLOW_50: '#B28600',
    TEAL_50: '#009D9A',
    CYAN_90: '#012749',
    ORANGE_70: '#8A3800',
    PURPLE_50: '#A56EFF'
});

//This object doesn't use Object.freeze because the Material UI theme we pass this into doesn't work if this object is frozen. Haven't investigated fully why yet.
export const PrimaryColor = {
    main: '#293D4B',
    light: '#527187',
    dark: '#182934',
    '900': '#182934',
    '800': '#293D4B',
    '700': '#374F60',
    '600': '#456376',
    '500': '#527187',
    '400': '#6B8599',
    '300': '#839BAC',
    '200': '#A5B7C5',
    '100': '#C6D5DF',
    '50': '#E6EEF6'
};

//This object doesn't use Object.freeze because the Material UI theme we pass this into doesn't work if this object is frozen. Haven't investigated fully why yet.
export const SecondaryColor = {
    main: '#156F83',
    light: '#2296B5',
    dark: '#0C4D58',
    '900': '#0C4D58',
    '800': '#156F83',
    '700': '#1A829B',
    '600': '#2296B5',
    '500': '#26A5C8',
    '400': '#33B1D2',
    '300': '#50BEDC',
    '200': '#7FD0E8',
    '100': '#B1E3F2',
    '50': '#DFF4FA'
};

export const ErrorColor = {
    main: '#B00020'
};

//This is the collection of our Functional Color palette
//These are accessible for color blindness and low vision
//and convey specific meanings and functions
export const FunctionalColor = Object.freeze({
    GREEN: '#078D2E',
    ORANGE: '#FF832B',
    RED: '#CE3E04',
    YELLOW: '#F1C21B',
    GRAY: '#A0A0A0',
    NAVY: '#293D4B',
    TEAL: '#156F83',
    BLUE: '#0D68A4',
    BLACK: '#000000'
});

//Components in Material UI expect one of these values for their color prop
export const ComponentColor = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DEFAULT: 'default',
    INHERIT: 'inherit',
    ERROR: 'error',
    ACTION: 'action'
});
