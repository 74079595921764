import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@mui/styles";
import DateRangeIcon from '@mui/icons-material/DateRange';
import {InputAdornment} from "@mui/material";
import Box from "@mui/material/Box";
import OutlinedInput from '@mui/material/OutlinedInput';
import NotchedOutline from "@mui/material/OutlinedInput/NotchedOutline";
import Divider from "@mui/material/Divider";

import {withTheme} from "../Theme/ThemeProvider";
import {Display} from "../../enums/CSSEnums";

const customStyles = theme => {
    return {
       root: {'&$error $focusedNotchedOutline': {
               zIndex: 1,
               borderColor: theme.palette.error.main
           }
           },
       disabledNotchedOutline: {
           borderColor: theme.palette.action.disabled,
       },
       focusedNotchedOutline: {
           borderColor: theme.palette.primary.main,
           borderWidth: 2
       },
       divider: {
            marginTop: 10,
            marginBottom: 10,
       },
       notchedOutlineOverride: {
            borderWidth: 0
       }
    };
};

const OutlinedRangePickerInputView = (props) => {
    const {
        classes,
        autoFocusEndDate,
        autoFocusStartDate,
        childRef,
        disabled,
        endDate,
        endDateInputRef,
        endDatePlaceholderText,
        error,
        fullWidth,
        onEndDateChange,
        onEndDateClick,
        onEndDateFocus,
        onInputBlur,
        onStartDateChange,
        onStartDateClick,
        onStartDateFocus,
        readOnly,
        startDate,
        startDateInputRef,
        startDatePlaceholderText,
    } = props;


    return (
        <Box
            sx={{
                border: 'solid silver 1px',
                borderRadius: '5px',
                '&:hover': {
                    border: 'solid black 1px'
                }
            }}
            disabled
            width={fullWidth ? '100%' : null}
            ref={childRef}
            display={Display.INLINE_FLEX}>
            <Box display={Display.INLINE_FLEX} width={fullWidth ? '100%' : null}>
                <OutlinedInput
                    fullWidth
                    disabled={disabled}
                    readOnly={readOnly}
                    error={error && autoFocusStartDate}
                    renderSuffix={state => (
                        <NotchedOutline
                            className={state.focused  || autoFocusStartDate ?
                                classes.focusedNotchedOutline
                                :
                                classes.notchedOutlineOverride
                            }
                            labelWidth={0}
                            notched={false}/>
                    )}
                    startAdornment={
                        <InputAdornment position={'start'} disablePointerEvents>
                            <DateRangeIcon/>
                        </InputAdornment>
                    }
                    inputRef={startDateInputRef}
                    autoFocus={autoFocusStartDate}
                    onBlur={onInputBlur}
                    onChange={onStartDateChange}
                    onClick={onStartDateClick}
                    onFocus={onStartDateFocus}
                    placeholder={startDatePlaceholderText}
                    value={startDate}/>
                <Divider orientation="vertical" flexItem className={classes.divider}/>
                <OutlinedInput
                    fullWidth
                    disabled={disabled}
                    readOnly={readOnly}
                    error={error && autoFocusEndDate}
                    renderSuffix={state => (
                        <NotchedOutline
                            className={state.focused  || autoFocusEndDate ?
                                classes.focusedNotchedOutline
                                :
                                classes.notchedOutlineOverride
                            }
                            labelWidth={0}
                            notched={false}/>
                    )}
                    inputRef={endDateInputRef}
                    autoFocus={autoFocusEndDate}
                    onBlur={onInputBlur}
                    onChange={onEndDateChange}
                    onClick={onEndDateClick}
                    onFocus={onEndDateFocus}
                    placeholder={endDatePlaceholderText}
                    value={endDate}/>
            </Box>
        </Box>
    );
};

OutlinedRangePickerInputView.defaultProps = {
    autoFocusEndDate: false,
    autoFocusStartDate: false,
    disabled: false,
    error: false,
    fullWidth: false,
    readOnly: false,
};

OutlinedRangePickerInputView.propTypes = {
    autoFocusEndDate: PropTypes.bool,
    autoFocusStartDate: PropTypes.bool,
    childRef: PropTypes.object,
    disabled: PropTypes.bool,
    endDate: PropTypes.string,
    endDateInputRef: PropTypes.object,
    endDateOnBlur: PropTypes.func,
    endDatePlaceholderText: PropTypes.string,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onEndDateChange: PropTypes.func,
    onEndDateClick: PropTypes.func,
    onEndDateFocus: PropTypes.func,
    onStartDateChange: PropTypes.func,
    onStartDateClick: PropTypes.func,
    onStartDateFocus: PropTypes.func,
    readOnly: PropTypes.bool,
    startDate: PropTypes.string,
    startDateInputRef: PropTypes.object,
    startDatePlaceholderText: PropTypes.string,
};

export default withTheme(withStyles(customStyles, {name: 'MuiOutlinedRangePicker'})(OutlinedRangePickerInputView));

