// This file was generated from com.worksmith.reporting.enums.customReport.ClientStandardReportType
// noinspection JSUnusedGlobalSymbols
const ClientStandardReportType = Object.freeze({
    TICKETING: 'Standard Ticketing Report',
    FACILITIES: 'Standard Facilities Report',
    FACILITIES_REAL_TIME: 'Real Time Standard Facilities Report',
    TICKETING_REAL_TIME: 'Real Time Standard Ticketing Report',
    PAUSED_SERVICES_VENDOR: 'Paused Services Vendor',
    UNPAUSED_SERVICES_CLIENT_ADMIN: 'Unpaused Services Client Admin',
    UNPAUSED_SERVICES_CLIENT_LOCAL: 'Unpaused Services Client Local',
    UNPAUSED_SERVICES_VENDOR: 'Unpaused Services Vendor',
    PAUSED_SERVICES_WORKSMITH: 'Paused services for the vendor'
});

export default ClientStandardReportType;
