import {Color} from "worksmith/enums/Color";

const BLANK_BUTTON = {
    cursor: "pointer",
    borderWidth: "0px",
    backgroundColor: "transparent",
    padding: '0px',
    WebkitBoxShadow: "none",
    boxShadow: "none",

};

const CommonButtonStyle = Object.freeze({
    ICON: {
        ...BLANK_BUTTON,
    },
    RIGHT_ICON: {
        ...BLANK_BUTTON,
        position: 'absolute',
        right: '24px',
    },
    PRIMARY: {
        color: Color.WHITE,
        backgroundColor: Color.NAVY,
    },
    DISABLED: {
        color: Color.GREY,
        backgroundColor: Color.OFF_WHITE,
        borderColor: Color.GREY
    }
});

export default CommonButtonStyle;
