import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {ListItemSecondaryAction, ListItemText} from "@mui/material";
import MaterialListItem from "@mui/material/ListItem";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Text, {TextVariant} from "../../Text/Text.web";
import Grid from "../../Grid/Grid.web";
import {ValueIsSet} from "../../../helpers/GenericHelpers";
import {withTheme} from "../../Theme/ThemeProvider";
import Collapsible from "../../Collapse/Collapsible.web";
import {Color} from "../../../enums/Color"
import {IconFontSize} from "../../Icon/Icon.web";
import CustomPropTypes from "../../../custom-prop-types/CustomPropTypes";

const StyledListItem = styled(MaterialListItem)`
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    
    :hover {
        cursor: pointer;
        ${props => props.hoverBackgroundColor ?
            `background-color: ${Color.OPTION_HOVER_BLUE}` 
            :
            null
        }
    }
`;

class ExpandableListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    onClick = () => {
        let {expanded} = this.state;
        let {onClick, expandable} = this.props;

        if (expandable) {
           this.setState({expanded: !expanded}, () => ValueIsSet(onClick) ? onClick() : null);
        } else {
            onClick();
        }

    };

    render() {
        let {onClick} = this;
        let {expanded} = this.state;
        let {
            collapsibleProps,
            count,
            expandable,
            hoverBackgroundColor,
            iconSize,
            subtitle,
            title
        } = this.props;

        return (
            <Fragment>
                <StyledListItem
                    hoverBackgroundColor={hoverBackgroundColor}
                    onClick={onClick}>
                    <Grid container>
                        {
                            ValueIsSet(count) ?
                                <Grid item xs={1}>
                                    <Text bold variant={TextVariant.BODY_1}>{count > 99 ? "99+" : count}</Text>
                                </Grid>
                                :
                                null
                        }
                        <Grid item xs={10}>
                            <ListItemText primary={title} secondary={subtitle}/>
                        </Grid>
                        <Grid item xs={1}>
                            <ListItemSecondaryAction>
                                {
                                    expanded ?
                                        <ExpandLess fontSize={iconSize}/>
                                        :
                                        <ExpandMore fontSize={iconSize}/>
                                }
                            </ListItemSecondaryAction>
                        </Grid>
                    </Grid>
                </StyledListItem>
                {
                    expandable && ValueIsSet(this.props.children) ?
                        <Collapsible
                            expanded={expanded}
                            {...collapsibleProps}>
                            {this.props.children}
                        </Collapsible>
                        :
                        null
                }
            </Fragment>
        )
    }
}

ExpandableListItem.defaultProps = {
    collapsibleProps: {
        mountOnEnter: true,
        unmountOnExit: true
    },
    expandable: true,
    hoverBackgroundColor: true,
    iconSize: IconFontSize.DEFAULT
};

ExpandableListItem.propTypes = {
    count: PropTypes.number,
    collapsibleProps: PropTypes.object,
    expandable: PropTypes.bool,
    hoverBackgroundColor: PropTypes.bool,
    iconSize: CustomPropTypes.enum(IconFontSize),
    onClick: PropTypes.func,
    subtitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
};

export default withTheme(ExpandableListItem);