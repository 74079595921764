import {ComponentColor, FunctionalColor} from './../enums/Color'

export const PickColor = (primary, secondary, error, action, defaultIsNull) => {
    if (secondary) {
        return ComponentColor.SECONDARY;
    } else if (primary) {
        return ComponentColor.PRIMARY;
    } else if(error) {
        return ComponentColor.ERROR;
    } else if(action) {
        return ComponentColor.ACTION;
    } else return defaultIsNull ? null : ComponentColor.INHERIT;
};

export const PickColorDefaultToDefault = (primary, secondary, error, action, defaultIsNull) => {
    if (secondary) {
        return ComponentColor.SECONDARY;
    } else if (primary) {
        return ComponentColor.PRIMARY;
    } else if(error) {
        return ComponentColor.ERROR;
    } else if(action) {
        return ComponentColor.ACTION;
    } else return defaultIsNull ? null : ComponentColor.DEFAULT;
};