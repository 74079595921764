import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from "moment";

import {AlignItems} from "worksmith/enums/CSSEnums";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Grid from 'worksmith/components/Grid/Grid.web';
import {MomentFormat} from "worksmith/enums/MomentFormat";
import ObligationAttachmentSection from "worksmith/composite-components/OpportunityState/ObligationAttachmentSection";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import UserType from "worksmith/enums/api/user/UserType";
import {StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import PriceBreakdown from "worksmith/composite-components/PriceBreakdown/PriceBreakdown";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import ObligationService from "worksmith/services/api/ObligationService";
import {DisplayErrorNotification} from "../../helpers/SweetAlertHelpers";

const obligationService = new ObligationService();

const JobCompletionDetailsDialogView = (props) => {
    const {hidePricingForNonAdminClientUsers, obligation, userType, onCancel} = props;


    let [submitting, setSubmitting] = useState(false);


    const downloadCompletionDetails = () => {
        setSubmitting(true);
        obligationService.getJobCompletionDownload(obligation.id).then((zipFile) => {
            const blob = new Blob([zipFile], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "CompletionDetails_" + obligation.id + ".zip");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        }).catch((error) => {
            onCancel();
            console.error(error);
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification("Download Failed!");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Fragment>
            <Grid container spacing={4}>
                {userType === UserType.VENDOR ?
                    <Grid item xs={12}>
                        <Text variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY} italic>Marked complete on {moment(obligation.completedTimestamp).format(MomentFormat.DateTime)} EST</Text>
                    </Grid> : null}
                {userType === UserType.CLIENT ?
                    <Grid item xs={12}>
                        <Button primary
                                debounce
                                variant={ButtonVariant.OUTLINED}
                                isSubmitting={submitting}
                                onClick={() => downloadCompletionDetails()}>
                            Download
                        </Button>
                    </Grid> : null}
                <Grid container item xs={12} alignItems={AlignItems.FLEX_START}>
                    <Grid item xs={12}>
                        <Text fontSize={14} >{obligation.serviceLine.name}</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Text fontSize={14}>Request # {obligation.requestId}</Text>
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems={AlignItems.FLEX_START}>
                    <Grid container item xs={6}>
                        <Grid item xs={12}><Text variant={TextVariant.SUBTITLE_1} bold>Scheduled Date</Text></Grid>
                        <Grid item xs={12}>
                            <Text variant={TextVariant.BODY_1}>
                                {moment(obligation.obligationDate).format(MomentFormat.MonthDayYearSlash)}
                            </Text>
                        </Grid>
                        {ValueIsSet(obligation.arrivalStartTime) ?
                            <Grid container item xs={12} spacing={0}>
                                <Grid item xs={12}><Text variant={TextVariant.BODY_1} light>Arrival Window</Text></Grid>
                                <Grid item xs={12}>
                                    <Text variant={TextVariant.BODY_1} light>{moment(obligation.arrivalStartTime, MomentFormat.MilitaryTime).format(MomentFormat.StandardTime) + ' - ' + moment(obligation.arrivalEndTime, MomentFormat.MilitaryTime).format(MomentFormat.StandardTime)}</Text>
                                </Grid>
                            </Grid> : null}
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid item xs={12}><Text variant={TextVariant.SUBTITLE_1} bold>All Work Completed on</Text></Grid>
                        <Grid item xs={12}>
                            <Text variant={TextVariant.BODY_1}>{ValueIsSet(obligation.completionDate) ?
                                moment(obligation.completionDate).format(MomentFormat.MonthDayYearSlash) :
                                moment(obligation.obligationDate).format(MomentFormat.MonthDayYearSlash)}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Text variant={TextVariant.SUBTITLE_1} bold>Summary of Work Completed</Text>
                    </Grid>
                    {obligation.updatedArrivalTime ?
                        <Grid item xs={12}>
                            <Text variant={TextVariant.BODY_1}><SemiBoldSpan>Start Time:</SemiBoldSpan> {moment(obligation.updatedArrivalTime,MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)}</Text>
                        </Grid> : null}
                    {obligation.leadTechnician ?
                        <Grid item xs={12}>
                            <Text variant={TextVariant.BODY_1}><SemiBoldSpan>Lead Technician:</SemiBoldSpan> {obligation.leadTechnician}</Text>
                        </Grid> : null}
                    {obligation.assessmentOfIssue ?
                        <Grid item xs={12}>
                            <Text variant={TextVariant.BODY_1}><SemiBoldSpan>Assessment of Issue:</SemiBoldSpan> {obligation.assessmentOfIssue}</Text>
                        </Grid> : null}
                    {
                        <Grid item xs={12}>
                            <Text variant={TextVariant.BODY_1}>
                                <SemiBoldSpan>Client Onsite Contact:</SemiBoldSpan> {StringHasText(obligation.clientOnsiteContact) ? obligation.clientOnsiteContact : "None"}
                            </Text>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {StringHasText(obligation.summaryOfWorkCompleted) ?
                            <Text variant={TextVariant.BODY_1}><SemiBoldSpan>Description of Work Completed</SemiBoldSpan>: {obligation.summaryOfWorkCompleted}</Text> :
                        null}
                    </Grid>
                </Grid>
                {userType === UserType.VENDOR || obligation.obligationAttachments.length > 0 ?
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <ObligationAttachmentSection addOnly={true} readOnly={userType === UserType.CLIENT} obligationId={obligation.id}/>
                    </Grid>
                </Grid> : null}
                <Grid container item xs={12} alignItems={AlignItems.FLEX_START} margin={'1em 0 0 0'}>
                    <Grid item xs={12}>
                        <Text variant={TextVariant.SUBTITLE_1} bold>Terms and Conditions</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Text variant={TextVariant.BODY_1}>All pricing includes labor and material.</Text>
                    </Grid>
                    {!hidePricingForNonAdminClientUsers &&
                        <Grid item xs={12}>
                            <Text variant={TextVariant.SUBTITLE_1} bold>Pricing Breakdown</Text>
                        </Grid>
                    }
                    {!hidePricingForNonAdminClientUsers &&
                        <Grid item xs={12} margin={'.6em 0 .2em 0'}>
                            <PriceBreakdown
                                items={obligation.obligationItems}
                                activityTotal={userType === UserType.VENDOR ? obligation.totalFulfillmentCost : obligation.totalRetailPrice}
                                userType={userType}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Fragment>
    )
};


const SemiBoldSpan = styled.span`
    font-weight: 600;
`;

JobCompletionDetailsDialogView.propTypes = {
    hidePricingForNonAdminClientUsers: PropTypes.bool,
    obligation: PropTypes.object,
    userType: CustomPropTypes.enum(UserType)
};

JobCompletionDetailsDialogView.defaultProps = {
    hidePricingForNonAdminClientUsers: false,
};

export default JobCompletionDetailsDialogView;