import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Card from "worksmith/components/Card/Card.web";
import styled from "styled-components";
import Button, {ButtonVariant} from "worksmith/components/Button/Button.web";
import InvoiceView from "./InvoiceView";
import Icon from "worksmith/components/Icon/Icon.web";
import {IconType} from "worksmith/enums/MaterialEnums";

const PageSection = styled.div`
    margin-top: 1em;
    margin-bottom: 1em;
`;

const FloatRight = styled.div`
    float: right;
`;

const SingleInvoicePageView = (props) => {
    const {getPdf, linkTo, loading, receivable} = props;

    return (
        <Fragment>
            <PageSection>
                <Card padding={3} raised width={'inherit'}>
                    <FloatRight>
                        <Button variant={ButtonVariant.OUTLINED} onClick={getPdf} primary>
                            <Icon name={IconType.PRINT}/> PRINT INVOICE
                        </Button>
                    </FloatRight>
                </Card>
            </PageSection>

            <PageSection>
                <Card padding={0} raised width={'inherit'}>
                    <InvoiceView linkTo={linkTo}
                                 loading={loading}
                                 receivable={receivable}/>
                </Card>
            </PageSection>

            <PageSection>
                <Card padding={3} raised width={'inherit'}>
                    <FloatRight>
                        <Button variant={ButtonVariant.OUTLINED} onClick={getPdf} primary>
                            <Icon name={IconType.PRINT}/> PRINT INVOICE
                        </Button>
                    </FloatRight>
                </Card>
            </PageSection>
        </Fragment>
    )
};

SingleInvoicePageView.propTypes = {
    getPdf:     PropTypes.func,
    linkTo:     PropTypes.func,
    loading:    PropTypes.bool.isRequired,
    receivable: PropTypes.object
};

export default SingleInvoicePageView;