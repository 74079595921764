import React, {useState} from 'react';
import PropTypes from "prop-types";

import Accordion from "worksmith/components/Accordion/Accordion";
import Grid from "worksmith/components/Grid/Grid";
import StandardPricingItem from "worksmith/composite-components/ClientLocationProfile/standardPricing/StandardPricingItem";
import Text from "worksmith/components/Text/Text";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {CurrencySymbolEnum} from "worksmith/enums/CurrencySymbolEnum";



const HugoBossAlterationsStandardPricing = ({
                                                currency,
                                                getClientStandardItemsPricing,
                                                initialAlterations,
                                                initialCategory,
                                                locationId,
                                                selfServiceClientVendorRelationships,
                                                standardItemsPricing,
                                                userIsAdmin,
}) => {

    const alterations = JSON.parse(JSON.stringify(initialAlterations));  // this creates deep copy of the initialAlterations array of objects. (JSON.parse(JSON.stringify())
    const category = {name: initialCategory, error: null};
    const [expanded, setExpanded] = useState(null);


    return (
        <Grid container margin={'0 0 32px 0'}>
            <Grid item xs={12} margin={'0 0 16px 0'}>
                <Text bold>{category.name}</Text>
            </Grid>
            {alterations && alterations.filter(item => item.name !== "Multiple Alterations").sort((a, b) => a.name.localeCompare(b.name)).map(item => {
                return (
                    <Grid item xs={8} key={item.id} padding={'0 0 0px 8px'}>
                        <Accordion
                            transitionProps={{unmountOnExit: true}}
                            expanded={expanded === item.id}
                            onChange={() => {
                                setExpanded((prevState) => prevState === item.id ? null : item.id)
                            }}
                            summary={item.name}
                            details={
                                <StandardPricingItem
                                    allItemsPricings={standardItemsPricing}
                                    alterations={alterations}
                                    currency={currency}
                                    getClientStandardItemsPricing={getClientStandardItemsPricing}
                                    initialItemPricings={standardItemsPricing.filter(itemPricing => itemPricing.standardItem.id === item.id)}
                                    isHugoBossAlteration
                                    locationId={locationId}
                                    selfServiceClientVendorRelationships={selfServiceClientVendorRelationships}
                                    serviceLineId={1} // serviceLine will always be Dry Cleaning and Alterations for this component.
                                    setExpanded={setExpanded}
                                    standardItem={item}
                                    userIsAdmin={userIsAdmin}
                                />
                            }
                            square
                            renderDetailsOnFirstExpansion
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
};

export default HugoBossAlterationsStandardPricing;

HugoBossAlterationsStandardPricing.propTypes = {
    currency: CustomPropTypes.enum(CurrencySymbolEnum),
    getClientStandardItemsPricing: PropTypes.func,
    initialAlterations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
        category: PropTypes.string,
        name: PropTypes.string,
    })),
    initialCategory: PropTypes.string,
    locationId: PropTypes.number,
    selfServiceClientVendorRelationships: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    standardItemsPricing: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        updatedTimestamp: PropTypes.string,
        unitRetailPrice: PropTypes.number,
        unitWholesalePrice: PropTypes.number,
        unitCustomerCharge: PropTypes.number,
        clientLocationId: PropTypes.number,
        vendorLocationId: PropTypes.number,
        vendorName: PropTypes.string,
        standardItem: PropTypes.shape({
            id: PropTypes.number,
            createdTimestamp: PropTypes.string,
            updatedTimestamp: PropTypes.string,
            name: PropTypes.string,
            client: PropTypes.object,
            serviceLine: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            lineItemType: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
        }),
    })),
    userIsAdmin: PropTypes.bool,
};