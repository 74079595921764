import React from 'react';
import PropTypes from 'prop-types';

import {AlignItems} from "../../../../enums/CSSEnums";
import Button, {ButtonVariant} from "../../../../components/Button/Button";
import Currency from "../../../../components/Currency/Currency";
import Grid from "../../../../components/Grid/Grid";
import Icon from "../../../../components/Icon/Icon";
import {IconType} from "../../../../enums/MaterialEnums";
import Text, {TextColor, TextVariant} from "../../../../components/Text/Text";

const InsuranceView = ({handleChange, insuranceFormFields, readOnly, toggleEdit, whiteLabelClient}) => {

    const LabelText = ({text}) => (
        <Text variant={TextVariant.BODY_2} color={'textSecondary'} gutterBottom={true} semiBold>{text}</Text>
    )

    const ContentText = ({text}) => (
        <Text variant={TextVariant.BODY_1} gutterBottom={true}>{text}</Text>
    )

    const unionLaborText = (requirement) => {
        switch (requirement) {
            case "ALL_TRADES":
                return <Text variant={TextVariant.BODY_1}>Yes, for all trades</Text>
                break;
            case "MAJOR_TRADES_ONLY":
                return (
                    <>
                        <Text variant={TextVariant.BODY_1}>Yes, but only for major trades</Text>
                        <Text variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY}>HVAC, electrical work, plumbing</Text>
                    </>
                )
                break;
            case "NONE":
                return <Text variant={TextVariant.BODY_1}>No, union labor is not required</Text>
                break;
        }
    }

    return (
        <Grid container alignItems={AlignItems.FLEX_START}>
            <Grid container item xs={12} spacing={3} alignItems={AlignItems.FLEX_START}>
                <Grid item xs={12}>
                    <Text gutterBottom variant={TextVariant.H6}>Is union labor required?</Text>
                    <Grid item>
                        {unionLaborText(insuranceFormFields.unionLaborRequirement)}
                    </Grid>
                </Grid>
                {!whiteLabelClient ? <Grid container item spacing={3} xs={12} md={8}>
                    <Grid item xs={12}>
                        <Text gutterBottom variant={TextVariant.H6}>Insurance Requirements</Text>
                        <Text gutterBottom variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY}>Note that all
                            Worksmith vendors must meet minimum COI requirements of $1M each occurrence, $2M general
                            aggregate.</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <LabelText text={'Per Occurrence Amount'}/>
                        <ContentText text={insuranceFormFields.occurrenceInsuranceRequirement ?
                            <Currency amount={insuranceFormFields.occurrenceInsuranceRequirement}
                                      removeDecimals={true}/> : '—'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LabelText text={'General Aggregate Amount'}/>
                        <ContentText text={insuranceFormFields.aggregateInsuranceRequirement ?
                            <Currency amount={insuranceFormFields.aggregateInsuranceRequirement}
                                      removeDecimals={true}/> : '—'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LabelText text={'Other Insurance Information'}/>
                        <ContentText
                            text={insuranceFormFields.additionalInsuranceDetails ? insuranceFormFields.additionalInsuranceDetails : '—'}/>
                    </Grid>
                </Grid>
                :
                null}
            </Grid>
            {!readOnly ?
                <Grid container item xs={8} margin={'32px 0 0 0'}>
                    <Grid item xs={2}>
                        <Button variant={ButtonVariant.OUTLINED} startIcon={<Icon name={IconType.EDIT}/>}
                                onClick={toggleEdit}>EDIT</Button>
                    </Grid>
                </Grid>
                : null
            }
        </Grid>
    )
};

export default InsuranceView;

InsuranceView.defaultProps = {
    whiteLabelClient: false
};

InsuranceView.propTypes = {
    insuranceFormFields: PropTypes.shape({
        unionLaborRequirement: PropTypes.string,
        occurrenceInsuranceRequirement: PropTypes.number,
        aggregateInsuranceRequirement: PropTypes.number,
        additionalInsuranceDetails: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    readOnly: PropTypes.bool,
    toggleEdit: PropTypes.func,
    whiteLabelClient: PropTypes.bool
}
