import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {AlignItems, Display, JustifyContent} from 'worksmith/enums/CSSEnums';
import {TextColor, TextVariant} from 'worksmith/components/Text/Text';
import Text from "worksmith/components/Text/Text.web";
import {GetTranslation, ValueIsSet} from "worksmith/helpers/GenericHelpers";

const CharacterCount = (props) => {

    const {
        caption,
        helperText,
        length,
        maxLength,
        minLength
    } = props;

    let error = false;
    if(((ValueIsSet(maxLength) && length > maxLength) || (ValueIsSet(minLength) && length < minLength)) && length !== 0)
        error = true;

    return (
        <Box
            alignItems={AlignItems.CENTER}
            display={Display.FLEX}
            justifyContent={JustifyContent.SPACE_BETWEEN}
        >
            <Box marginRight={'5px'}>
                <Typography
                    color={TextColor.TEXT_SECONDARY}
                    variant={caption ? TextVariant.CAPTION : TextVariant.BODY_2}
                >
                    {helperText}
                </Typography>
            </Box>
            {maxLength ?
                <Typography
                    color={error ? TextColor.ERROR : TextColor.TEXT_SECONDARY}
                    variant={caption ? TextVariant.CAPTION : TextVariant.BODY_2}
                >
                    {`${length}/${maxLength}`}
                </Typography> :
                minLength ?
                <Text
                    color={error ? TextColor.ERROR : TextColor.TEXT_SECONDARY}
                    variant={caption ? TextVariant.CAPTION : TextVariant.BODY_2}
                >
                    {length} {GetTranslation("common.characters", "characters")} ({minLength} {GetTranslation("common.characters", "characters")} {GetTranslation("common.minimum", "minimum")})
                </Text> : null
            }
        </Box>
    )
};

CharacterCount.propTypes = {
    caption: PropTypes.bool,
    helperText: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    maxLength: PropTypes.number,
    minLength: PropTypes.number
};

export default CharacterCount;
