import React, {Component} from 'react';
import FileUploader from "./components/FileUploader";
import RequestForProposalService from 'worksmith/services/api/RequestForProposalService';
import ObligationService from 'worksmith/services/api/ObligationService';
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';
import ObligationItemService from 'worksmith/services/api/ObligationItemService';

import Loader from "./components/Loader";

const requestForProposalService = new RequestForProposalService();
const obligationService = new ObligationService();
const obligationItemGroupService = new ObligationItemGroupService();
const obligationItemService = new ObligationItemService();

class AttachmentsList extends Component {
    constructor(props){
        super(props);

        this.state = {
            request: props.request,
            obligation: props.obligation,
            obligationItem: props.obligationItem,
            removeTitle: props.removeTitle ? props.removeTitle : false,
            obligationItemGroup: props.obligationItemGroup,
            attachments: props.attachments ? props.attachments : [],
            includeFileUploader: props.includeFileUploader,
            loading: true,
            attachmentType: null
        };

        this.getFileData = this.getFileData.bind(this);
    }

    componentDidMount(){
        let _this = this;
        let {request, obligationItemGroup, obligation, obligationItem, attachmentType} = this.state;

        if (request) {
            requestForProposalService.getAttachments(request.id).then(function (data) {
                let attachments = data;
                attachmentType = 'request';
                _this.setState({attachments, request, attachmentType, loading: false});
            });
        } else if (obligationItemGroup) {
            obligationItemGroupService.getAttachments(obligationItemGroup.id).then(function (data) {
                let attachments = data;
                attachmentType = 'ticket';
                _this.setState({attachments, obligationItemGroup, attachmentType, loading: false});
            });
        } else if (obligation) {
            obligationService.getAttachments(obligation.id).then((data) => {
                let attachments = data;
                attachmentType = 'job';
                _this.setState({attachments, obligation, attachmentType, loading: false});
            });
        } else if (obligationItem) {
            obligationItemService.getAttachments(obligationItem.id).then((data) => {
                let attachments = data;
                attachmentType = 'item';
                _this.setState({attachments, obligationItem, attachmentType, loading: false});
            })
        }
    }

    getFileData(fileData) {
        let _this = this;
        let {request, obligationItemGroup, obligation, obligationItem} = this.state;
        this.setState({ loading: true });

        if (request) {
            requestForProposalService.addAttachments(request.id, false, fileData).then(function (data) {
                window.setTimeout(function () {
                    requestForProposalService.getAttachments(request.id).then(function (attachments) {
                        _this.setState({attachments, loading: false})
                    });
                }, 2000)
            });
        } else if (obligationItemGroup) {
            obligationItemGroupService.addAttachments(obligationItemGroup.id, fileData).then(function (data) {
                window.setTimeout(function () {
                    obligationItemGroupService.getAttachments(obligationItemGroup.id).then(function (data) {
                        let attachments = data;
                        _this.setState({attachments, loading: false})
                    });
                }, 2000)
            });
        } else if (obligation) {
            obligationService.addAttachments(obligation.id, "COMPLETION_PHOTO", fileData).then(function (data) {
                window.setTimeout(function () {
                    obligationService.getAttachments(obligation.id).then(function (data) {
                        let attachments = data;
                        _this.setState({attachments, loading: false})
                    });
                }, 2000)
            });
        } else if (obligationItem) {
            obligationItemService.addAttachments(obligationItem.id, fileData).then(function (data) {
                window.setTimeout(function () {
                    obligationItemService.getAttachments(obligationItem.id).then(function (data) {
                        let attachments = data;
                        _this.setState({attachments, loading: false})
                    });
                }, 2000)
            })
        }
    }
    
    render(){
        let {attachments, loading, removeTitle, includeFileUploader, attachmentType} = this.state;
        let {maxNameLength} = this.props;

        if(attachments.length && !loading){
            return(
                <div>
                    {removeTitle ? null : <p className="section-accent">Attachments</p>}
                    <div className="d-flex flex-row justify-content-start flex-wrap">
                        {attachments.map(function(attachment, idx){
                            return (
                                <div key={idx} className={'image-preview margin-right-sm'}>
                                    <div className={'text-center'}>
                                        <a href={attachment.singleUseUrl} target={'_blank'}>
                                            <img src={attachment.singleUseUrl} alt={''}/>
                                            {attachment.fileName.includes('.pdf') ? <img src={'https://cdn.worksmith.com/images/icons/pdf.png'} alt={'file icon'} /> : null}
                                            {attachment.fileName.includes('.xls') || attachment.fileName.includes('.xlsx') ? <img src={'https://cdn.worksmith.com/images/icons/xls-icon.png'} alt={'file icon'} /> : null }
                                            {attachment.fileName.includes('.csv') ? <img src={'https://cdn.worksmith.com/images/icons/csv.png'} alt={'file icon'} /> : null }
                                        </a>
                                        <p><small>{maxNameLength ? attachment.fileName.substring(0, maxNameLength-1) + (attachment.fileName.length >= maxNameLength ? '...' : '') : attachment.fileName}</small></p>
                                    </div>
                                </div>
                            )
                        })}
                        { includeFileUploader ?
                            <FileUploader getFileData={this.getFileData} size={'small'}/>
                            : null
                        }
                    </div>
                </div>
            )
        }else if(!attachments.length && !loading){
            return (
                <div>
                    {removeTitle ? null : <p className="section-accent">Attachments</p>}
                    <p>{'No attachments for this ' + attachmentType + '.'}</p>
                    { includeFileUploader ?
                        <div className="row">
                            <div className="col-md-1">
                                <FileUploader getFileData={this.getFileData} size={'small'}/>
                            </div>
                        </div>
                        : null
                    }
                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

export default AttachmentsList;
