import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import TextField from "worksmith/components/Inputs/TextField/TextField";
import Grid from "worksmith/components/Grid/Grid.web";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import {Debounce} from "worksmith/helpers/GenericHelpers";

class ResolveConcernForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            valid: false
        };

        this.resolutionNotes = '';
    }

    onResolveConcernChange = (value) => {
        const {valid} = this.state;

        this.resolutionNotes = value;
        let newValid = value.trim() !== '';

        if (valid !== newValid) {
            this.setState({valid: newValid}, this.onChange);
        } else {
            this.onChange();
        }
    };


    onChange = () => {
        const {valid} = this.state;

        this.props.onChange({
            resolutionNotes: this.resolutionNotes,
            valid
        });
    };

    render() {
        let {onResolveConcernChange} = this;
        const {
            loading,
            spacing
        } = this.props;

        let debouncedOnChange = Debounce(onResolveConcernChange, 50);

        return (
            <Grid container spacing={spacing}>
                <Grid item xs={12}>
                    <Text variant={TextVariant.BODY_1}>
                        Please explain how the concern was resolved.
                    </Text>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={loading}
                        fullWidth
                        label={'Details'}
                        multiline
                        onChange={debouncedOnChange}/>
                </Grid>
            </Grid>
        )
    }
}

ResolveConcernForm.defaultProps = {
    loading: false,
    spacing: 2
};

ResolveConcernForm.propTypes = {
    loading: PropTypes.bool,
    spacing: PropTypes.number,
    onChange: PropTypes.func.isRequired // ({resolutionNotes, valid}) => {}
};

export default ResolveConcernForm;