import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text";
import Grid from "worksmith/components/Grid/Grid";
import styled from "styled-components";

const InformationContainer = styled.div`
    min-height: 200px;
    width:      80vmin;
`;


const RequestChecklistModalView = (props) => {
    const {information, loading, locationTitle, serviceLineName} = props;

    return (
        <InformationContainer>
            <Grid container width={'inherit'}>
                <Grid item xs={12}>
                    <Text renderSkeleton={loading} color={TextColor.TEXT_PRIMARY} variant={TextVariant.H6}>
                        {locationTitle}
                    </Text>
                    <Text renderSkeleton={loading} color={TextColor.TEXT_PRIMARY} variant={TextVariant.SUBTITLE_2}>
                        {serviceLineName} - Request Checklist
                    </Text>
                </Grid>
                <Grid item xs={12}>
                    <Text renderSkeleton={loading} variant={TextVariant.BODY_2}>
                        {information}
                    </Text>
                </Grid>
            </Grid>
        </InformationContainer>
    )
};

RequestChecklistModalView.propTypes = {
    information:     PropTypes.string.isRequired,
    loading:         PropTypes.bool.isRequired,
    locationTitle:   PropTypes.string.isRequired,
    serviceLineName: PropTypes.string.isRequired,
};

export default RequestChecklistModalView;