import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {ValueIsSet} from '../../helpers/GenericHelpers';
import {ThemeConstants, withTheme} from '../Theme/ThemeProvider';
import {ComponentColor} from '../../enums/Color';
// The below components are referencing MUI core directly to solve for slow animation/re-rendering issues
// that took place when using our wrapped components
import {ListItemIcon} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

const StyledListItem = styled(ListItem)`
  ${({gridList}) => gridList ? 'height: 100% !important' : null};
  ${({radioStyle}) => radioStyle ? `padding: 0 16px !important; span { font-size: 1rem; } div {min-width: 0px}` : null};
`;

const StyledGridList = styled(ImageList)`
  && {
    overflow-y: hidden;
  }
`;

class CheckboxList extends Component {
    constructor(props) {
        super(props);
    }

    onCheck = (item) => {
        let {selectedOptions, selectedGroups} = this.props;
        let {isGroupChecked} = this;

        let tempSelectedOptions = selectedOptions.slice();
        let selectedIndex = selectedOptions.indexOf(item.value);
        if (selectedIndex >= 0) {
            tempSelectedOptions.splice(selectedIndex, 1);
        } else {
            tempSelectedOptions.push(item.value);
        }

        let tempSelectedGroups = selectedGroups.slice();
        if (ValueIsSet(item.group)) {
            let selectedGroupIndex = selectedGroups.indexOf(item.group);
            if (selectedGroupIndex < 0 && isGroupChecked(item.group, tempSelectedOptions)) {
                tempSelectedGroups.push(item.group);
            } else if (selectedGroupIndex >= 0 && !isGroupChecked(item.group, tempSelectedOptions)) {
                tempSelectedGroups.splice(selectedGroupIndex, 1);
            }
        }

        this.props.onChange(tempSelectedOptions, tempSelectedGroups);
    };

    selectedValuesNotInGroup = (group) => {
        let {selectedOptions, options} = this.props;

        return options.reduce((newSelectedOptions, currentOption) => {
            if (selectedOptions.includes(currentOption.value) && currentOption.group !== group) {
                newSelectedOptions.push(currentOption.value);
                return newSelectedOptions;
            } else {
                return newSelectedOptions;
            }
        }, []);
    };

    onGroupCheck = (group) => {
        let {selectedGroups, options} = this.props;

        let groupIndex = selectedGroups.indexOf(group);
        if (groupIndex >= 0) {
            selectedGroups.splice(group, 1);
            this.props.onChange(this.selectedValuesNotInGroup(group),
                selectedGroups);
        } else {
            selectedGroups.push(group);
            this.props.onChange(this.selectedValuesNotInGroup(group)
                    .concat(options.filter(o => o.group === group)
                        .map(option => option.value)),
                selectedGroups);
        }
    };

    isGroupChecked = (group, selectedOptions) => {
        let {options} = this.props;

        return options.reduce((isChecked, currentOption) => {
            if (isChecked) {
                return (currentOption.group !== group || selectedOptions.includes(currentOption.value));
            } else {
                return isChecked;
            }
        }, true);
    };

    getListItemsFromOptionsObject = (options) => {
        let {onCheck, onGroupCheck, createListItem} = this;
        let {selectedOptions, selectedGroups} = this.props;
        let listItems = [];
        let groups = [];

        options.forEach(option => {
            if (!ValueIsSet(option.group)) {
                listItems.push(
                    createListItem(option.value, () => onCheck(option), selectedOptions.indexOf(option.value) >= 0, option.label)
                );
            } else {
                if (!groups.includes(option.group)) {
                    listItems.push(
                        createListItem(groups.length, () => onGroupCheck(option.group), selectedGroups.includes(option.group), option.group)
                    );
                    listItems = listItems.concat(
                        options.filter(o => o.group === option.group).map(li => {
                            return (
                                createListItem(li.value, () => onCheck(li), selectedOptions.includes(li.value), li.label, {paddingLeft: (ThemeConstants.spacingUnit * 6) + 'px'})
                            );
                        })
                    );
                    groups.push(option.group);
                }
            }
        });

        return listItems;
    };

    createListItem = (key, onClick, checked, label, style, listItemProps) => {
        let {cols, radioStyle, ListItemProps} = this.props;
        return (
            <StyledListItem
                key={key}
                button
                radioStyle={radioStyle}
                disableRipple
                disableTouchRipple
                gridList={Boolean(cols)}
                {...ListItemProps}
                onClick={onClick}
                style={style}
                text={label}
                {...listItemProps}>
                <ListItemIcon>
                    <Checkbox
                        color={ComponentColor.PRIMARY}
                        checked={checked}/>
                </ListItemIcon>
                <ListItemText>
                    {label}
                </ListItemText>
            </StyledListItem>
        );
    };

    render() {
        const {getListItemsFromOptionsObject} = this;
        const {
            cols,
            colSpacing,
            includeSelectAll,
            selectedOptions,
            ListProps,
            options,
            radioStyle,
            selectAll,
            selectAllLabel
        } = this.props;


        return (
            ValueIsSet(cols) ?
                <>
                    {
                        includeSelectAll ?
                            <StyledGridList {...ListProps} spacing={colSpacing} style={{marginBottom: '4px'}}>
                                {this.createListItem('selectAll', selectAll, selectedOptions.length === options.length, selectAllLabel)}
                            </StyledGridList>
                            :
                            null
                    }
                    <StyledGridList {...ListProps} spacing={colSpacing} cols={cols}>
                        {getListItemsFromOptionsObject(options)}
                    </StyledGridList>
                </>
                :
                <List {...ListProps} disablePadding={radioStyle}>
                    {
                        includeSelectAll ?
                            this.createListItem('selectAll', selectAll, selectedOptions.length === options.length, selectAllLabel)
                            :
                            null
                    }
                    {getListItemsFromOptionsObject(options)}
                </List>
        );
    }
}

CheckboxList.defaultProps = {
    ListProps: {
        dense: true,
    },
    colSpacing: 16
};

CheckboxList.propTypes = {
    cols: PropTypes.number,
    colSpacing: PropTypes.number,
    radioStyle: PropTypes.bool, // will align styles when used in same section with radio buttons
    includeSelectAll: PropTypes.bool,
    ListProps: PropTypes.object,
    ListItemProps: PropTypes.object,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
            group: PropTypes.string
        })
    ).isRequired,
    selectAll: PropTypes.func,
    selectAllLabel: PropTypes.string,
    selectedOptions: PropTypes.arrayOf(PropTypes.any),
    selectedGroups: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
};

export default withTheme(CheckboxList);
