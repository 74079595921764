import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {throttle} from "lodash";
import {withTheme} from '../Theme/ThemeProvider';

class BreakpointListener extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            breakpoint: this.calculateCurrentBreakpoint(),
            propOptions: this.calculatePropOptions(props)
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.throttledWindowResizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.throttledWindowResizeHandler);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.toString() !== nextProps.toString())
            this.state.propOptions = this.calculatePropOptions(nextProps);

        return true;
    }

    throttledWindowResizeHandler = throttle(() => {
        let currentBreakpoint = this.calculateCurrentBreakpoint();

        //When the breakpoint reflects a different screen size than it did previously, rerender using the new breakpoint values
        if (currentBreakpoint !== this.state.breakpoint)
            this.setState({breakpoint: currentBreakpoint});
    }, 200);

    calculateCurrentBreakpoint = () => {
        const breakpoints = this.props.theme.breakpoints.values;
        let currentBreakpoint = 'xs';

        if (window.innerWidth >= breakpoints.xl)
            currentBreakpoint = 'xl';
        else if (window.innerWidth >= breakpoints.lg)
            currentBreakpoint = 'lg';
        else if (window.innerWidth >= breakpoints.md)
            currentBreakpoint = 'md';
        else if (window.innerWidth >= breakpoints.sm)
            currentBreakpoint = 'sm';

        return currentBreakpoint;
    };

    calculatePropOptions = (props) => {
        const propOptions = {
            xs: {},
            sm: {},
            md: {},
            lg: {},
            xl: {}
        };

        Object.keys(props).forEach((key) => {
            if (key === 'children' || key === 'theme')
                return;

            let calculatedKey = key;
            if (key === 'childrenProp')
                calculatedKey = 'children';

            //xs is the smallest size, so no matter what if this is set we can always fall back to its value. If it's not set we could encounter unexpected behavior
            if(!props[key].hasOwnProperty('xs'))
                console.warn('Passed in prop "' + key + '" to BreakpointListener without defining a value for "xs"');
            else
                propOptions.xs[calculatedKey] = props[key].xs;

            //Go through each breakpoint, if the passed in prop value is set, use it, otherwise copy the value from one size smaller
            propOptions.sm[calculatedKey] = props[key].hasOwnProperty('sm') ? props[key].sm : propOptions.xs[calculatedKey];
            propOptions.md[calculatedKey] = props[key].hasOwnProperty('md') ? props[key].md : propOptions.sm[calculatedKey];
            propOptions.lg[calculatedKey] = props[key].hasOwnProperty('lg') ? props[key].lg : propOptions.md[calculatedKey];
            propOptions.xl[calculatedKey] = props[key].hasOwnProperty('xl') ? props[key].xl : propOptions.lg[calculatedKey];
        });

        return propOptions;
    };

    render() {
        const {} = this;
        const {breakpoint, propOptions} = this.state;
        const {children} = this.props;

        //This ensures that 'children' is mapped to an array regardless of whether one or multiple children were passed in
        const childrenArray = [].concat(children);
        
        return childrenArray.map((element) => React.cloneElement(element, {...propOptions[breakpoint]}));
    }
}

BreakpointListener.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
};

export default withTheme(BreakpointListener);