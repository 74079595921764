import {Color} from "worksmith/enums/Color";
import {TextDecoration} from "worksmith/enums/CSSEnums";

const VENDOR_BASE = {
    borderRadius: '0px',
    color: 'white',
    fontSize: '15px',
    textTransform: 'capitalize',
    height: "40px",
};

// Button styles for vendor portal (to align with current style standards)
const VendorButtonStyle = Object.freeze({
    PRIMARY: {
        ...VENDOR_BASE,
        backgroundColor: Color.NAVY,
    },
    WARNING: {
        ...VENDOR_BASE,
        backgroundColor: Color.VENDOR_ORANGE,
    },
    SUCCESS: {
        ...VENDOR_BASE,
        backgroundColor: Color.VENDOR_GREEN,
    },
    DRAB:{
        ...VENDOR_BASE,
        backgroundColor: Color.MEDIUM_GREY,
    },
    DELETE:{
        ...VENDOR_BASE,
        backgroundColor: Color.RED
    },
    LINK:{
        ...VENDOR_BASE,
        color: Color.BLUE,
        textDecoration: TextDecoration.UNDERLINE
    },
    NONE:{
        ...VENDOR_BASE,
        backgroundColor: Color.TRANSPARENT,
        color: Color.DARK_GREY
    }
});

export default VendorButtonStyle;
