import React from "react";

import {Typography} from "antd";
const {Text: AntdText} = Typography;

const Text = (props) => {
    return <AntdText className={props.className} ellipsis={props.ellipsis}>
        {props.children}
    </AntdText>
};

export default Text;