import React, {Component, Fragment} from 'react';
import {Select} from 'antd';
import PropTypes from 'prop-types';

import PropTypeError from "./PropTypeError";

const Option = Select.Option;

class WorksmithSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionComponents: []
        };
    }

    componentDidMount = () => {
         let optionComponents= this.getOptionsFromArray(this.props.options);
         this.setState({optionComponents});

    };

    shouldComponentUpdate = (nextProps, nextState) => {

        if (nextProps.options !== this.props.options) {
            this.state.optionComponents = this.getOptionsFromArray(nextProps.options);
        }
        return true;
    };

    getOptionsFromArray = (options) => {
        let {valueFieldName, optionFieldName, optionIsDisabled} = this.props;

        if (optionIsDisabled) {
            return options.map((option) => <Option value={option[valueFieldName]} disabled={optionIsDisabled(option)} key={option[optionFieldName]}>{option[optionFieldName]}</Option>);
        } else {
            return options.map((option) => <Option value={option[valueFieldName]} key={option[optionFieldName]}>{option[optionFieldName]}</Option>);
        }
    };


    render() {
        let {props} = this;
        let {optionComponents} = this.state;
        let {label} = this.props;

        return(
            <Fragment>
                <div>{label}</div>
                <Select {...props}>
                    {optionComponents}
                </Select>
            </Fragment>
        )
    }
}

WorksmithSelect.propTypes = {
    options: PropTypes.array.isRequired,
    valueFieldName: function(props, propName, componentName) {
        if((props.options[0])[props[propName]] === undefined && typeof props[propName] !== "string" ) {
            return new PropTypeError(propName, componentName,propName + ' does not exist in options object.')
        }
    },
    optionFieldName: function(props, propName, componentName) {
        if(props.options[0][props[propName]] === undefined && typeof props[propName] !== "string" ) {
            return new PropTypeError(propName, componentName,propName + ' does not exist in options object.')
        }
    },
    onSelect: PropTypes.func.isRequired,
    optionIsDisabled: PropTypes.func,
    label: PropTypes.string
};

export default WorksmithSelect;
