import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import MaterialRating from "@mui/lab/Rating";
import Icon from "worksmith/components/Icon/Icon.web";
import Text from "worksmith/components/Text/Text.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {JavascriptType} from "worksmith/enums/GenericEnums";
import {TextVariant, TextColor} from "worksmith/components/Text/Text";
import {Pluralize} from "worksmith/helpers/LanguageHelper";
import {Display, AlignItems} from "worksmith/enums/CSSEnums";
import Box from "@mui/material/Box";
import {withTheme} from '../Theme/ThemeProvider';

export const RatingSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
});

const Rating = (props) => {
    const {
        disabled,
        emptyIcon,
        icon,
        max,
        precision,
        showValue,
        size,
        totalCount,
        value
    } = props;

    return (
        <Box display={Display.FLEX} alignItems={AlignItems.CENTER}>
            <MaterialRating emptyIcon={emptyIcon}
                            disabled={disabled}
                            icon={icon}
                            max={max}
                            precision={precision}
                            size={size}
                            value={value}
                            readOnly/>
            {showValue ?
                <Box ml={1}>
                    <Text color={disabled ? TextColor.TEXT_SECONDARY : TextColor.PRIMARY}>({value})</Text>
                </Box>
                :
                null
            }
            {ValueIsSet(totalCount) ?
                typeof totalCount === JavascriptType.NUMBER ?
                    <Box marginLeft={'5px'}>
                        <Text
                            variant={TextVariant.CAPTION}
                            color={TextColor.TEXT_SECONDARY}>{
                                totalCount} {Pluralize(totalCount, 'Rating')}
                        </Text>
                    </Box>
                    :
                    <Box marginLeft={'5px'}>
                        {totalCount}
                    </Box>
                :
                null
            }
        </Box>
    )
};

Rating.propTypes = {
    disabled: PropTypes.bool,
    emptyIcon: PropTypes.node,
    icon: PropTypes.node,
    max: PropTypes.number,
    precision: PropTypes.number,
    showValue: PropTypes.bool,
    size: CustomPropTypes.enum(RatingSize),
    totalCount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.element
    ]),
    value: PropTypes.number
};

Rating.defaultProps = {
    disabled: false,
    emptyIcon: <Icon name={'StarBorder'} primary/>,
    icon: <Icon name={'Star'} primary/>,
    max: 5,
    precision: 0.5,
    size: RatingSize.MEDIUM
};

export default withTheme(Rating);