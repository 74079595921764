export const Headers = Object.freeze({
    CONTENT_TYPE_JSON: { "Content-Type" : "application/json" },
    ACCEPT_PDF:  { "Accept" : "application/pdf"},
    MULTIPART_FORM_DATA: {
        "Content-Type": "multipart/form-data"
    }
});

export const LocalStorageKeys = Object.freeze({
    ACCESS_TOKEN:  'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    USER_ID:       'userId',
    PORTAL_TYPE:   'portalType',
    BASE_API_URL:  'baseApiURL',
    AUTH_TYPE:     'authType',
    STREAM_CHAT_TOKEN: 'streamChatToken'
});

export const RequestMethod = Object.freeze({
    DELETE:  'DELETE',
    GET:     'GET',
    HEAD:    'HEAD',
    OPTIONS: 'OPTIONS',
    PATCH:   'PATCH',
    POST:    'POST',
    PUT:     'PUT'
});

export const BaseApiURL = Object.freeze({
    LOCAL:      'http://localhost:8085/ws-core-service',
    LOCAL_DEV:  'https://dev-core-api.worksmith.com/ws-core-service',
    DEMO:       'https://demo-core-api.worksmith.com/ws-core-service',
    QA:         'https://qa-core-api.worksmith.com/ws-core-service',
    PRODUCTION: 'https://core-api.worksmith.com/ws-core-service'
});
