import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import ObligationStatus from "../../enums/api/task/ObligationStatus";
import {Display, FlexDirection} from "../../enums/CSSEnums";
import Grid from "../../components/Grid/Grid.web";
import FieldTitleAndBody from "../FieldTitleAndBody/FieldTitleAndBody";
import FileUpload from "../../components/FileUpload/FileUpload";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import Schedule from "../../components/Schedule/Schedule.web";
import PriceBreakdown from "../PriceBreakdown/PriceBreakdown";
import Text, {TextVariant} from "../../components/Text/Text.web";
import ProposalHeader from "../ProposalHeader/ProposalHeader";
import Box from "@mui/material/Box";
import JobDetailsSkeleton from "worksmith/composite-components/JobDetails/JobDetailsSkeletonView";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import moment from "moment";
import DownloadCompletionDetailsButton from "../JobCompletionDetails/DownloadCompletionDetailsButton";
import {StringHasText} from "worksmith/helpers/GenericHelpers";
import styled from "styled-components";
import {WithContext} from "../../../context/GlobalContext";

const JobDetailsView = (props) => {
    const {
        context,
        additionalApprovalAmount,
        assessmentOfIssue,
        updatedArrivalTime,
        bid,
        completionDate,
        clientOnsiteContact,
        hasPreferredVendor,
        leadTechnician,
        loading,
        obligationAttachments,
        obligationDate,
        obligationItems,
        obligationId,
        requestId,
        scopeOfWork,
        schedule,
        status,
        summaryOfWorkCompleted,
        totalRetailPrice,
        useTicketing,
        vendorLocation
    } = props;

    if (loading) {
        return(
            <JobDetailsSkeleton/>
        )
    }

    const hidePricingForNonAdminUsers = ValueIsSet(context.client) && ValueIsSet(context.user) && context.client.configurationSettings.hidePricingForNonAdminUsers && !context.user.isClientAdmin;

    let attachmentsList = [];
    if (ValueIsSet(obligationAttachments) && obligationAttachments.length > 0) {
        attachmentsList = obligationAttachments.map((attachment) => (
            {
                url: attachment.singleUseUrl,
                name: ValueIsSet(attachment.fileName) ? attachment.fileName : attachment.name
            }
        ));
    }

    let termsAndConditions = 'No additional terms';
    if (bid && bid.additionalTerms) {
       termsAndConditions = bid.additionalTerms;
    } else if (scopeOfWork) {
       termsAndConditions = scopeOfWork;
    }

    return (
        <Box display={Display.FLEX} flexDirection={FlexDirection.COLUMN}>
        <Box display={Display.FLEX} mb={4}>
            <ProposalHeader
                additionalApprovalAmount={additionalApprovalAmount}
                hasPreferredVendor={hasPreferredVendor}
                isObligation
                totalRetailPrice={totalRetailPrice}
                vendorLocation={vendorLocation}/>
        </Box>
        <Grid
            container
            spacing={4}
            ItemProps={{
                xs:12
            }}>
            {
                status === ObligationStatus.COMPLETED ?
                   <Fragment>
                       <Grid container item xs={12}>
                           <Grid item xs={6} sm={3}>
                               <DownloadCompletionDetailsButton
                                   obligationId={obligationId}
                                   obligationAttachments={obligationAttachments}
                                   updatedArrivalTime={updatedArrivalTime}
                                   leadTechnician={leadTechnician}
                                   requestId={requestId}
                                   summaryOfWorkCompleted={summaryOfWorkCompleted}
                               />
                           </Grid>
                       </Grid>
                       <FieldTitleAndBody
                           item
                           title={'All work completed on'}
                           body={
                               <Grid item xs={12}>
                                   <Text variant={TextVariant.BODY_1}>{ValueIsSet(completionDate) ?
                                       moment(completionDate,MomentFormat.ISODate).format(MomentFormat.DowMonthDay) :
                                       moment(obligationDate).format(MomentFormat.DowMonthDay)}
                                   </Text>
                               </Grid>
                           }
                       />
                       <FieldTitleAndBody
                           item
                           title={'Summary of Work Completed'}
                           body={
                               <Grid container item xs={12}>
                                   {updatedArrivalTime ?
                                       <Grid item xs={12}><Text variant={TextVariant.BODY_1}>
                                           <strong>Start Time:</strong> {moment(updatedArrivalTime,MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)}
                                       </Text></Grid> : null}
                                   {leadTechnician ?
                                       <Grid item xs={12}><Text variant={TextVariant.BODY_1}><strong>Lead Technician:</strong> {leadTechnician}</Text></Grid> : null}

                                   {<Grid item xs={12}>
                                       <Text variant={TextVariant.BODY_1}><strong>Client Onsite Contact Name:</strong> {StringHasText(clientOnsiteContact) ? clientOnsiteContact : "None"}</Text>
                                   </Grid>}

                                   {StringHasText(assessmentOfIssue) ?
                                       <Grid item xs={12}>
                                           <Text variant={TextVariant.BODY_1}><strong>Assessment of Issue:</strong> {assessmentOfIssue}</Text>
                                       </Grid>
                                       :
                                       null
                                   }

                                   {StringHasText(summaryOfWorkCompleted) ?
                                       <Grid container item xs={12}>
                                            <OverFlowWrapGrid item xs={10}>
                                                <Text variant={TextVariant.BODY_1}><strong>Description of Work Completed:</strong> {summaryOfWorkCompleted}</Text>
                                            </OverFlowWrapGrid>
                                       </Grid>
                                       :
                                       null
                                   }
                               </Grid>
                           }/>
                       <FieldTitleAndBody
                           item
                           title={'Completion Photos/Files'}
                           body={<FileUpload readOnly initialFiles={attachmentsList.length > 0 ? attachmentsList : []}/>}/>
                   </Fragment>
                    :
                    null
            }
            <FieldTitleAndBody
                item
                title={'Scheduled Date'}
                body={
                    <Schedule schedule={schedule} textVariant={TextVariant.BODY_1}/>
                }/>
            <FieldTitleAndBody
                item
                title={'Terms & Conditions'}
                body={termsAndConditions}/>
            {
                (!useTicketing && !hidePricingForNonAdminUsers) ?
                    <FieldTitleAndBody
                        item
                        title={'Price Breakdown'}
                        body={
                            <PriceBreakdown
                                items={obligationItems}
                                activityTotal={totalRetailPrice}
                                additionalApprovalAmount={status === ObligationStatus.COMPLETED ? null : additionalApprovalAmount}/>
                        }/>
                        :
                    null
            }
        </Grid>
        </Box>
    )
};

const OverFlowWrapGrid = styled(Grid)`
  overflow-wrap: break-word;
`;

JobDetailsView.defaultProps = {
    useTicketing: false
};

JobDetailsView.propTypes = {
    assessmentOfIssue: PropTypes.string,
    additionalApprovalAmount: PropTypes.number,
    bid: PropTypes.shape({
        additionalTerms: PropTypes.string
    }),
    clientOnsiteContact: PropTypes.string,
    completionDate: CustomPropTypes.moment,
    hasPreferredVendor: PropTypes.bool,
    loading: PropTypes.bool,
    leadTechnician: PropTypes.string,
    obligationAttachments: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        singleUseUrl: PropTypes.string
    })),
    obligationDate: CustomPropTypes.moment,
    obligationId: PropTypes.number,
    obligationItems: PropTypes.arrayOf(PropTypes.shape({
        lineItemType: PropTypes.shape({
            name: PropTypes.string
        }),
        quantity: PropTypes.number,
        title: PropTypes.string,
        unitRetailPrice: PropTypes.number
    })),
    requestId: PropTypes.number,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        returnDate: PropTypes.string
    }),
    scopeOfWork: PropTypes.string,
    status: CustomPropTypes.enum(ObligationStatus),
    summaryOfWorkCompleted: PropTypes.string,
    totalRetailPrice: PropTypes.number,
    useTicketing: PropTypes.bool,
    updatedArrivalTime: PropTypes.string,
    vendorLocation: PropTypes.shape({
        rating: PropTypes.number,
        ratingsCount: PropTypes.number,
        vendor: PropTypes.shape({
            officialName: PropTypes.string,
            qualifiesForPremiumBadge: PropTypes.bool
        })
    }),
};

export default WithContext(JobDetailsView);