import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import Grid from "worksmith/components/Grid/Grid";
import StandardItemsSelectedServiceAccordion from "./StandardItemsSelectedServiceAccordion";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {Color} from "worksmith/enums/Color";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {DisplayErrorNotification} from "worksmith/helpers/SweetAlertHelpers";

const graphQLService = new GraphQLServiceClass();


const StandardItemsPageView = ({client}) => {

    const [standardItems, setStandardItems] = useState(null);
    const [loading, setLoading] = useState(false);

    const getClientStandardItems = () => {
        setLoading(true);
        graphQLService.findOneById(
            client.id,
            GraphQLObjectType.CLIENT,
            clientQueryFields,
            {clientId: client.id}, '$clientId: Long'
        ).then(client => {
            setStandardItems(client.selectedServiceLines.filter(serviceLine => serviceLine.useTicketing));
        }).catch(error => {
            console.error("Error fetching Client Standard Items: ", error);
            DisplayErrorNotification("Error fetching Client Standard Items");
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        getClientStandardItems();
    }, []);


    return (
        <Grid container>
            <Grid item xs={12} margin={"10px 0 0 0"}>
                <Text variant={TextVariant.H4}>Standard Items Master List</Text>
            </Grid>
            <Grid item xs={12} margin={"10px 0 0 0"}>
                <Text variant={TextVariant.BODY_1}>This is the total list of items available. Go to the <Link to={"/locations/"} style={{textDecoration: 'underline', color: Color.SECONDARY}}>location profile</Link> to select which items are available at that specific store and update the pricing and vendor.</Text>
            </Grid>
            <StandardItemsSelectedServiceAccordion
                client={client}
                getClientStandardItems={getClientStandardItems}
                loading={loading}
                standardItems={standardItems}
            />
        </Grid>
    )
};

const clientQueryFields = `
    id
    selectedServiceLines {
        id
        lineItemTypes {
            id
            name
            isAvailableForStandardItems
            standardItemsForClient(clientId: $clientId) {
                id
                name
                client {
                    id
                }
            }
        }
        name
        useTicketing
    }
`;

export default StandardItemsPageView;

StandardItemsPageView.propTypes = {
    client: PropTypes.object,
};
