import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import Accordion from "worksmith/components/Accordion/Accordion";
import Text from "worksmith/components/Text/Text";
import LocationContractItemDetails from "../LocationContractItemDetails";
import {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import CircularProgress from "worksmith/components/Loader/CircularProgress";


const ClientLocationItemDetailsContainer = styled.div`
    width: 100%;
`;


const LocationContractDetailsView = (props) => {

    let {
        loading,
        locationId,
        locationRateCardsByServiceLine
    } = props;

    if (loading) {
        return (<CircularProgress/>)
    } else {
        return (
            Object.keys(locationRateCardsByServiceLine).length === 0 ?
                <Text>No data available yet. Start with adding service lines.</Text>
                :
                <ClientLocationItemDetailsContainer>
                    {
                        Object.keys(locationRateCardsByServiceLine).map((key) => {
                            return (
                                <Accordion
                                    elevation={0}
                                    key={key}
                                    summary={<Text semiBold variant={TextVariant.SUBTITLE_2}>{key}</Text>}
                                    details={
                                        <LocationContractItemDetails
                                            locationRateCard={locationRateCardsByServiceLine[key][0]}
                                            locationId={locationId}
                                        />
                                    }
                                />
                            )
                        })
                    }
                </ClientLocationItemDetailsContainer>
        )
    }
};

LocationContractDetailsView.propTypes = {
    locationRateCardsByServiceLine: PropTypes.shape({
        serviceLineName: PropTypes.arrayOf(PropTypes.shape({
            rateCard: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                startDate: PropTypes.string,
                endDate: PropTypes.string,
                serviceLine: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired
                }),
                rateCardEntries: PropTypes.arrayOf(PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    unitRetailPrice: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number
                    ]),
                    unitWholesalePrice: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number
                    ]),
                    itemName: PropTypes.string.isRequired,
                    contractItem: PropTypes.shape({
                        id: PropTypes.number.isRequired,
                        name: PropTypes.string.isRequired
                    })
                }))
            }))
        }))
    }),
    loading: PropTypes.bool.isRequired,
    locationId: PropTypes.number.isRequired
};

export default LocationContractDetailsView;