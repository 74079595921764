import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InvoicesPricingOverviewView from "./InvoicesPricingOverviewView";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {DisplayErrorNotification} from "../../../../helpers/SweetAlertHelpers";

const graphQLService = new GraphQLServiceClass();

class InvoicesPricingOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableCredit:    0,
            isPaused:           false,
            loading:            true,
            outstandingBalance: 0,
            pastDueBalance:     0
        };
    }

    componentDidMount() {
        const {billingProfiles, isPaused} = this.props;

        graphQLService.findAll({ids: billingProfiles.map(profile => profile.id)},
            GraphQLObjectType.BILLING_PROFILE,
            InvoicesPricingOverview.billingProfileQueryFields).then((billingProfiles) => {
                const availableCredit = billingProfiles.reduce((sum, profile) => {
                    sum += profile.availableCredit;
                    return sum;
                }, 0);
                const outstandingBalance = billingProfiles.reduce((sum, profile) => {
                    sum += profile.outstandingBalance;
                    return sum;
                }, 0);
                const pastDueBalance = billingProfiles.reduce((sum, profile) => {
                    sum += profile.pastDueBalance;
                    return sum;
                }, 0);
                this.setState({availableCredit, isPaused, loading: false, outstandingBalance, pastDueBalance});
        }).catch(error => {
            // noinspection JSIgnoredPromiseFromCall
            DisplayErrorNotification(error.toString());
        });
    }

    render() {
        const {availableCredit,
            isPaused,
            loading,
            outstandingBalance,
            pastDueBalance} = this.state;

        return (
            <InvoicesPricingOverviewView availableCredit={availableCredit}
                                         isPaused={isPaused}
                                         loading={loading}
                                         outstandingBalance={outstandingBalance}
                                         pastDueBalance={pastDueBalance}/>
        )
    }
}

InvoicesPricingOverview.billingProfileQueryFields = `
    availableCredit
    outstandingBalance
    pastDueBalance
`;

InvoicesPricingOverview.propTypes = {
    billingProfiles: PropTypes.arrayOf(PropTypes.object).isRequired,
    isPaused:        PropTypes.bool.isRequired,
};

export default InvoicesPricingOverview;