import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import MaterialChip from '@mui/material/Chip';
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {PickColor} from "worksmith/helpers/ThemeHelpers";

import {withTheme} from "../Theme/ThemeProvider";
import {Color, ComponentColor} from "worksmith/enums/Color";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import Cancel from '@mui/icons-material/Cancel';
import Icon from "../Icon/Icon.web";

export const ChipIconSize = Object.freeze({
    LARGE: 'large',
    DEFAULT: 'default',
    SMALL: 'small',
    INHERIT: 'inherit'
});

export const ChipVariant = Object.freeze({
    DEFAULT: 'default',
    OUTLINED: 'outlined'
});

export const ChipSize = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium'
});

const getBackgroundColor = (canClick, color, value) => {
    if (!value && canClick) {
        return Color.WHITE;
    }

    const colorMap = {
        [ComponentColor.SECONDARY]: Color.SECONDARY,
        [ComponentColor.ERROR]: Color.ERROR,
    };

    return colorMap[color] || Color.PRIMARY;
};

const StyledMaterialChip = styled(MaterialChip)`
    background-color: ${({canClick, color, value}) => getBackgroundColor(canClick, color, value)} !important;
    color: ${({canClick, color, value}) => !value && !canClick ? Color.WHITE : value && canClick ? Color.WHITE : color === ComponentColor.SECONDARY ? Color.SECONDARY : Color.PRIMARY} !important;
    border: ${({canClick, color, value}) => !value && !canClick ? "none" : value && canClick ? "none" : color === ComponentColor.SECONDARY ? `1px solid ${Color.SECONDARY}` : `1px solid ${Color.PRIMARY}`} !important;

     ${props => props.width ? `
           width: ${props.width}
        ` : null
    }
        
        ${props => props.disabled ? `
            opacity: 0.4;
            pointerEvents: none;
            `
        :
        null
    }
    
        ${props => props.canClick  && !props.disabled ? `
            :hover {
                cursor: pointer;
            }`
        :
        null
    }
`;

const StyledDeleteIcon = styled(Icon)`
    color: ${props => !props.selected && props.canClick ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.7)'} !important;
    
    :hover {
        color: ${props => !props.selected && props.canClick ? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.86)'} !important;
    }
`;

class Chip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: !!props.initialValue
        };
    }

    onClick = () => {
        const {value} = this.state;

        if(!ValueIsSet(this.props.shouldComponentUpdateWhenClicked) || this.props.shouldComponentUpdateWhenClicked()) {
            this.setState(
                {value: !value},
                () => {
                    if (ValueIsSet(this.props.onClick))
                        this.props.onClick(this.state.value)
                }
            );
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.initialValue !== this.props.initialValue) {
            this.setState({value: this.props.initialValue});
        }
    }

    render() {
        const {value} = this.state;
        const {onClick} = this;
        const {
            avatar,
            deleteIconName,
            iconFontSize,
            iconName,
            label,
            onDelete,
            primary,
            secondary,
            selectedIconName,
            disabled,
            size,
            variant,
            error,
            width
        } = this.props;

        const clickable = ValueIsSet(this.props.onClick);
        const color = PickColor(primary, secondary, error);

        const deleteIcon = (
            <div>
                <StyledDeleteIcon canClick={clickable}
                                  centered
                                  onMouseDown={(event) => event.stopPropagation()}
                                  name={deleteIconName}
                                  fontSize={size}
                                  selected={value}/>
            </div>
        );

        let iconToShow = iconName ? <Icon fontSize={iconFontSize} name={iconName}/> : null;

        if (selectedIconName && value)
            iconToShow = <Icon fontSize={iconFontSize} name={selectedIconName}/>;

        return (
            <StyledMaterialChip avatar={avatar}
                                canClick={clickable}
                                className={this.props.className}
                                deleteIcon={deleteIcon}
                                icon={iconToShow}
                                label={ValueIsSet(label) ? label : this.props.children}
                                onClick={clickable && !disabled ? onClick : undefined}
                                onDelete={onDelete}
                                color={color === ComponentColor.INHERIT ? ComponentColor.PRIMARY : color}
                                value={value}
                                disabled={disabled}
                                size={size}
                                variant={variant}
                                width={width}/>
        )
    }
}

Chip.propTypes = {
    avatar: PropTypes.element,
    deleteIconName: PropTypes.string,
    iconFontSize: PropTypes.string,
    iconName: PropTypes.element,
    initialValue: PropTypes.bool,
    label: PropTypes.node,
    onClick: PropTypes.func,
    shouldComponentUpdateWhenClicked: PropTypes.func,    //If false, this will prevent setState and onChange from firing
    onDelete: PropTypes.func,                            //if set, will show a delete icon
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    error: PropTypes.bool,
    selectedIconName: PropTypes.string,
    size: CustomPropTypes.enum(ChipSize),
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    width: PropTypes.string                             //sets the width of the Chip
};

Chip.defaultProps = {
    deleteIconName: 'Cancel',
    selectedIconName: 'Check',
    disabled: false,
    size: ChipSize.MEDIUM,
    variant: 'default'
};

export default withTheme(Chip);