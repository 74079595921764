import React, {Component} from 'react';
import moment from 'moment';
import t from 'tcomb-form';
import Modal from 'antd/lib/modal';

import Button from "../Button";
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';
import TicketForm from "../forms/TicketForm";
import AttachmentsList from "../../AttachmentsList";
import TicketOutreachForm from "../forms/TicketOutreachForm";
import Comments from "../Comments";
import styled from 'styled-components';
import {Color} from "../../Enums";
import {CalculateClientDisplayStatus} from "worksmith/helpers/TicketStatusHelper";
import {CurrencySymbolFormat, ValueIsSet} from "worksmith/helpers/GenericHelpers";

const obligationItemGroupService = new ObligationItemGroupService();

const Form = t.form.Form;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  linear-gradient(to right, white, white);
  transition: 0.5s;

  &:after {
    font-family: FontAwesome, serif;
    content: ${props => props.flip ? "'\\f077'" : "'\\f078'"};
    font-size: 20px;
    opacity: 0;
    top: 0;
    right: 20px;
    position: relative;
    transition: 0.5s;
  }

  & div {
    display: inline-block;
    padding: 20px 25px;
  }

  &:hover div {
    background: linear-gradient(to right, ${Color.GREY}, white);
  }

  &:hover:after {
    opacity: 1;
    right: 0;
  }
`;

const Accordion = styled.div`
  max-height: ${props => props.show ? '100%' : '0'};
  overflow: hidden;
`;

const Line = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border-bottom: 0.5px solid ${Color.GREY};
`;

const RedText = styled.span`
  color: ${Color.RED};
`;

const TDOverflowAuto = styled.td`
  overflow: auto;
`;

class SingleTicket extends Component {
    constructor(props) {
        super(props);

        let {ticket, obligation} = props;
        ticket.displayStatus = CalculateClientDisplayStatus(ticket, obligation.vendorLocation.name);

        this.state = {
            user: props.user,
            client: props.client,
            obligation: props.obligation,
            printable: props.printable,
            ticket: props.ticket,
            settings: props.settings,
            isSingleTicket: props.isSingleTicket,
            ticketCancelConfirmState: false,
            deletingEmployee: null,
            updatingEmployee: null,
            previousStatus: null,
            ensuingStatus: null,
            ticketEditable: false,
            outreachModalVisible: false,
            isInHouseTailor: props.obligation.vendorLocation.isBurberryVendor,
            itemAttachmentsModalVisible: {},
            value: {}
        };

        this.onChange = this.onChange.bind(this);
        this.handleTicketCancelState = this.handleTicketCancelState.bind(this);
        this.handleTicketOutreachFormClose = this.handleTicketOutreachFormClose.bind(this);
        this.handleTicketEditState = this.handleTicketEditState.bind(this);
        this.deleteUncompletedTicket = this.deleteUncompletedTicket.bind(this);
        this.incrementStatus = this.incrementStatus.bind(this);
        this.decrementStatus = this.decrementStatus.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.refreshTicket = this.refreshTicket.bind(this);
        this.refreshParent = this.refreshParent.bind(this);
        this.toggleShowMessages = this.toggleShowMessages.bind(this);
        this.toggleShowActivity = this.toggleShowActivity.bind(this);
        this.toggleShowOutreach = this.toggleShowOutreach.bind(this);
        this.toggleShowAttachments = this.toggleShowAttachments.bind(this);
    }

    refreshParent() {
        if (this.state.isSingleTicket) {
            window.location = '/#/ticketing/ticket-dashboard';
        } else {
            this.props.onCancel();
        }
    }

    onChange(value) {

        this.setState({updatingEmployee: value.updaterName, value})
    }

    toggleShowMessages() {
        let {showMessages, ticket} = this.state;
        this.commentsChild.getComments(false);
        if (!showMessages && ticket.numberOfUnreadMessages > 0) {
            //requestForProposalService.createOrUpdateRead(request.id);
            ticket.numberOfUnreadMessages = 0;
            this.setState({ticket});
        }

        this.setState({showMessages: !showMessages});
    }

    toggleShowActivity() {
        let {showActivity} = this.state;
        this.setState({showActivity: !showActivity});
    }

    toggleShowOutreach() {
        let {showOutreach} = this.state;
        this.setState({showOutreach: !showOutreach});
    }

    toggleShowAttachments() {
        let {showAttachments} = this.state;
        this.setState({showAttachments: !showAttachments});
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.updateStatus(this.state.ticket, this.state.settings, this.state.isInHouseTailor);

        if (this.state.settings.dryCleaning.allowTicketMessaging)
            this.commentsChild.getComments(false);
    }

    camelCaseToWords(string) {
        return string.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
            return str.toUpperCase();
        });
    }

    getCorrectDataLayout(dataKey, dataValue, currency) {
        if (dataValue !== null && dataValue !== undefined) {
            switch (dataKey) {
                case ('valueOfGarment'):
                case ('customerCharge'):
                    return CurrencySymbolFormat(dataValue, '0,0.00', currency);
                case ('confirmSampleSale'):
                    return dataValue !== 'false' ? 'Confirmed' : 'Not Confirmed';
                default:
                    return dataValue;
            }
        }
    }

    isAddressSpecificField(key) {
        let addressKeys = ['addressLineTwo', 'city', 'state', 'zip'];

        return addressKeys.indexOf(key) !== -1;
    }

    updateStatus(ticket, settings, isInHouseTailor) {

        ticket.previousStatus = null;
        ticket.ensuingStatus = null;

        if (ticket.vendorDeliveryType == 'vendor pick up') {
            if (ticket.status === 'picked up by customer') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'sent to customer') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            }
            if (ticket.status === 'picked up by guest') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'sent to guest') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'checked in at store') {
                this.setState({previousStatus: 'picked up by vendor'});
                if (settings.dryCleaning.useGuestNaming) {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by guest' : 'sent to guest'});
                } else {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by customer' : 'sent to customer'});
                }
            } else if (ticket.status === 'waiting for client pickup') {
                this.setState({previousStatus: 'checked in at store'});
                if (settings.dryCleaning.useGuestNaming) {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by guest' : 'sent to guest'});
                } else {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by customer' : 'sent to customer'});
                }
            } else if (ticket.status === 'dropped off by vendor') {
                this.setState({previousStatus: 'picked up by vendor', ensuingStatus: 'checked in at store'});
            } else if (ticket.status === 'picked up by vendor') {
                this.setState({previousStatus: 'awaiting pickup', ensuingStatus: 'dropped off by vendor'});
            } else if (ticket.status === 'awaiting pickup') {
                this.setState({previousStatus: null, ensuingStatus: 'picked up by vendor'});
            }
        } else if (ticket.vendorDeliveryType == 'ship to vendor') {
            if (ticket.status === 'picked up by customer') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'sent to customer') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            }
            if (ticket.status === 'picked up by guest') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'sent to guest') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'checked in at store') {
                this.setState({previousStatus: 'return shipped from vendor'});
                if (settings.dryCleaning.useGuestNaming) {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by guest' : 'sent to guest'});
                } else {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by customer' : 'sent to customer'});
                }
            } else if (ticket.status === 'waiting for client pickup') {
                this.setState({previousStatus: 'checked in at store'});
                if (settings.dryCleaning.useGuestNaming) {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by guest' : 'sent to guest'});
                } else {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by customer' : 'sent to customer'});
                }
            } else if (ticket.status === 'return shipped from vendor') {
                if (isInHouseTailor) {
                    this.setState({previousStatus: null, ensuingStatus: 'checked in at store'});
                } else {
                    this.setState({
                        previousStatus: 'shipment to vendor processed',
                        ensuingStatus: 'checked in at store'
                    });
                }
            } else if (ticket.status === 'shipment to vendor processed') {
                if (isInHouseTailor) {
                    this.setState({previousStatus: 'awaiting shipment', ensuingStatus: null});
                } else {
                    this.setState({previousStatus: 'awaiting shipment', ensuingStatus: 'return shipped from vendor'});
                }
            } else if (ticket.status === 'awaiting shipment') {
                this.setState({previousStatus: null, ensuingStatus: 'shipment to vendor processed'})
            }
        } else {
            //In house
            if (ticket.status === 'picked up by customer') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'sent to customer') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'picked up by guest') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'sent to guest') {
                this.setState({previousStatus: 'checked in at store', ensuingStatus: null});
            } else if (ticket.status === 'checked in at store') {
                this.setState({previousStatus: 'dropped off by vendor'});
                if (settings.dryCleaning.useGuestNaming) {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by guest' : 'sent to guest'});
                } else {
                    this.setState({ensuingStatus: ticket.deliveryType === 'pick up from store' ? 'picked up by customer' : 'sent to customer'});
                }
            } else if (ticket.status === 'dropped off by vendor') {
                this.setState({previousStatus: 'in progres', ensuingStatus: 'checked in at store'});
            } else if (ticket.status === 'completed, waiting for outreach') {
                this.setState({previousStatus: 'in progress', ensuingStatus: 'checked in at store'});
            } else if (ticket.status === 'in progress') {
                this.setState({
                    previousStatus: 'awaiting tailor assignment',
                    ensuingStatus: 'completed, waiting for outreach'
                });
            } else if (ticket.status === 'awaiting tailor assignment') {
                this.setState({previousStatus: null, ensuingStatus: 'in progress'})
            }
        }
    }

    handleTicketEditState() {
        this.setState({ticketEditable: !this.state.ticketEditable})
    }

    refreshTicket(id) {
        let _this = this;
        let {settings, obligation, isInHouseTailor} = this.state;
        let vendorName = obligation.vendorLocation.name;
        obligationItemGroupService.findOne(id).then(function (data) {
            let ticket = data;
            ticket.displayStatus = CalculateClientDisplayStatus(ticket, vendorName);
            _this.setState({ticket});
            _this.updateStatus(ticket, settings, isInHouseTailor);
            _this.setState({ticketEditable: false});
        })
    }

    incrementStatus(ticket) {
        let _this = this;
        if (!this.state.ensuingStatus)
            return;

        let update = {
            name: this.state.updatingEmployee,
            status: this.state.ensuingStatus
        };

        obligationItemGroupService.updateStatus(ticket.id, update).then(function (data) {
            let ticket = data;
            _this.setState({updatingEmployee: null, value: null, ticket});
            _this.refreshTicket(ticket.id);
        }, function () {
            alert('There was an error updating the status, please try again.')
        })
    }

    decrementStatus(ticket) {
        let _this = this;

        if (!this.state.previousStatus)
            return;

        let update = {
            name: this.state.updatingEmployee,
            status: this.state.previousStatus
        };

        obligationItemGroupService.updateStatus(ticket.id, update).then(function (data) {
            let ticket = data;
            _this.setState({updatingEmployee: null, ticket});
            _this.refreshTicket(ticket.id);
        });
    }

    handleTicketCancelState() {
        this.setState({ticketCancelConfirmState: !this.state.ticketCancelConfirmState})
    }

    handleTicketOutreachFormClose() {
        this.setState({outreachModalVisible: false});
        this.refreshTicket(this.state.ticket.id);
    }

    deleteUncompletedTicket(ticketId) {
        let _this = this;

        obligationItemGroupService.deleteWithUpdater(ticketId, null)
            .then(function () {
                _this.refreshParent();
            }, function (error) {
                //let message = (error.data !== null && error.status === 422 ? error.data : "There was an error cancelling this pickup.") + " Please try again.";
            });
    }

    toggleShowItemAttachmentsModal = (itemId) => {
        let {itemAttachmentsModalVisible} = this.state;
        itemAttachmentsModalVisible[itemId] = !itemAttachmentsModalVisible[itemId];
        this.setState({itemAttachmentsModalVisible});
    };

    render() {
        let {
            user,
            client,
            obligation,
            printable,
            ticket,
            settings,
            ticketEditable,
            outreachModalVisible,
            showMessages,
            showActivity,
            showOutreach,
            showAttachments,
            itemAttachmentsModalVisible
        } = this.state;
        let {
            camelCaseToWords,
            getCorrectDataLayout,
            isAddressSpecificField,
            toggleShowMessages,
            toggleShowActivity,
            toggleShowOutreach,
            toggleShowAttachments,
            toggleShowItemAttachmentsModal
        } = this;
        let currency = ValueIsSet(obligation.clientLocation) && ValueIsSet(obligation.clientLocation.currency) ? obligation.clientLocation.currency : 'USD';

        let isLocalUser = user.clientRoles === null || user.clientRoles.length === 0;
        const totalRetailPrice = ticket.obligationItems.reduce((accumulator, currentValue) => accumulator + currentValue.totalRetailPrice, 0);
        const clientHasIndividualItemAssignedTailorEnabled = ValueIsSet(this.props.clientTicketFieldSettings) && this.props.clientTicketFieldSettings.some(fieldSetting => fieldSetting.field.metadataName === "individualItemAssignedTailor") && obligation.vendorLocation.locationTailors.length > 0;

        const RequesterForm = t.struct({
            updaterName: t.String
        });

        const RequesterFormOptions = {
            fields: {
                updaterName: {
                    label: 'Your Name'
                }
            }
        };

        const DeleterForm = t.struct({
            updater: t.String
        });

        const DeleterFormOptions = {
            fields: {
                updater: {
                    label: 'Requester Name'
                }
            }
        };

        let metaDataFields = [];
        if (ticket.metadata) {
            for (let key in ticket.metadata) {
                if (ticket.metadata.hasOwnProperty(key)) {
                    if (!ticket.metadata.addressLineOne || (!isAddressSpecificField(key) && key !== 'addressLineOne')) {
                        metaDataFields.push(
                            <div>
                                <p className="section-accent">{camelCaseToWords(key)}</p>
                                <p>{getCorrectDataLayout(key, ticket.metadata[key], currency)}</p>
                            </div>
                        )
                    } else if (key === 'addressLineOne') {
                        metaDataFields.push(
                            <div>
                                <p className="section-accent">Address</p>
                                <address>
                                    <p>{ticket.metadata.addressLineOne || 'Address not supplied'}</p>
                                    {ticket.metadata.addressLineTwo ? <p>{ticket.metadata.addressLineTwo}</p> : null}
                                    <p>{ticket.metadata.city + ','} {ticket.metadata.state} {ticket.metadata.zip}</p>
                                </address>
                            </div>
                        )
                    }
                }
            }
        }

        let itemMetadataFieldNames = [];
        if (ticket.obligationItems) {
            ticket.obligationItems.forEach(function (item) {
                if (item.metadata) {
                    for (let key in item.metadata) {
                        if (item.metadata.hasOwnProperty(key)) {
                            if (itemMetadataFieldNames.indexOf(key) === -1)
                                itemMetadataFieldNames.push(key)
                        }
                    }
                }
            });
            return (
                <div className={'ws-section no-pad'} style={{'page-break-before': 'always'}}>
                    <div className="ws-section ws-section--inverse margin-top-none">
                        <div className="d-flex flex-row">
                            <h5 className={'flex-1'}>{ticket.groupId ? 'Ticket #' + ticket.groupId : 'WS Ticket ID: ' + ticket.id}</h5>
                            <div className="flex-1 text-right">
                                <h6 className={'margin-right-sm'}>{ticket.displayStatus}</h6>
                                <Button type={'white-outline'}
                                        onPress={() => this.handleTicketEditState()}
                                        className={'margin-right-sm'}
                                        icon={ticketEditable ? 'ban' : 'pencil'}/>
                            </div>
                        </div>
                        <h6 className={'flex-1'}>{ticket.ticketType}</h6>
                    </div>

                    {!ticketEditable ?

                        <div className="ws-section ws-section-empty">
                            <div className={'row'}>
                                <div className="col-md-12 margin-top-none">
                                    {ticket.obligationItems.length ?
                                        <div>
                                            <table className="ws-table table-small table-bordered">
                                                <thead>
                                                <tr className={'d-flex justify-content-around'}>
                                                    <th className={'flex-4'}>Item ID</th>
                                                    <th className={'flex-4'}>Item</th>
                                                    {clientHasIndividualItemAssignedTailorEnabled &&
                                                        <th className={'flex-3'}>Assigned Tailor</th>
                                                    }
                                                    {
                                                        itemMetadataFieldNames.map(function (name) {
                                                            return (
                                                                <th className={'flex-3'}>{camelCaseToWords(name)}</th>
                                                            )
                                                        })
                                                    }
                                                    <th className={'flex-4'}>Notes</th>
                                                    <th className={'flex-2'}>Quantity</th>
                                                    {settings.dryCleaning.hidePricingFromLocalUsers && isLocalUser ? null :
                                                        <th className={'flex-2'}>Total Price</th>}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {ticket.obligationItems.map(function (item, idx) {
                                                    return (
                                                        <tr className={'d-flex justify-content-around'} key={idx}>
                                                            <TDOverflowAuto className={'flex-4'}>
                                                                <span>WS</span>{item.id}
                                                            </TDOverflowAuto>
                                                            <TDOverflowAuto className={'flex-4'}>
                                                                <div>{item.title}</div>
                                                                <div>{item.lineItemType.name}</div>
                                                                {settings.dryCleaning.allowTicketAttachments ?
                                                                    <React.Fragment>
                                                                        <a onClick={() => toggleShowItemAttachmentsModal(item.id)}
                                                                           href='javascript:;'>Attachments</a>
                                                                        <Modal
                                                                            title={'Attachments for ' + item.title}
                                                                            visible={itemAttachmentsModalVisible[item.id]}
                                                                            footer={<div style={{textAlign: 'center'}}>
                                                                                <Button type={'primary'} message={'Ok'}
                                                                                        onPress={() => toggleShowItemAttachmentsModal(item.id)}/>
                                                                            </div>}
                                                                            onCancel={() => toggleShowItemAttachmentsModal(item.id)}
                                                                        >
                                                                            <AttachmentsList maxNameLength={15}
                                                                                             includeFileUploader={true}
                                                                                             obligationItem={item}/>
                                                                        </Modal>
                                                                    </React.Fragment>
                                                                    :
                                                                    null
                                                                }
                                                            </TDOverflowAuto>
                                                            {clientHasIndividualItemAssignedTailorEnabled &&
                                                                <TDOverflowAuto className={'flex-3'}>
                                                                    <div>{ValueIsSet(item.vendorLocationTailor) && item.vendorLocationTailor.name}</div>
                                                                </TDOverflowAuto>
                                                            }
                                                            {
                                                                itemMetadataFieldNames.map(function (name) {
                                                                    return (
                                                                        <TDOverflowAuto className={'flex-3'}>{getCorrectDataLayout(name, item.metadata[name], currency)}</TDOverflowAuto>
                                                                    )
                                                                })
                                                            }
                                                            <TDOverflowAuto className={'flex-4'} style={{overflow: 'auto'}}>{item.description}</TDOverflowAuto>
                                                            <TDOverflowAuto className={'flex-2 tex-right'}>{item.quantity}</TDOverflowAuto>
                                                            {settings.dryCleaning.hidePricingFromLocalUsers && isLocalUser ? null :
                                                                <TDOverflowAuto className={'flex-2'}>{CurrencySymbolFormat(item.totalRetailPrice, '0,0.00', currency)}</TDOverflowAuto>}
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                            <div className="text-right margin-bottom-sm">Total
                                                Price: {CurrencySymbolFormat(totalRetailPrice, '0,0.00', currency)}</div>
                                        </div> : 'No items'}
                                </div>
                                <div className="col-md-3">
                                    {obligation.clientLocation && obligation.clientLocation.name ?
                                        <div>
                                            <p className="section-accent">Location</p>
                                            <p>{obligation.clientLocation.name}</p>
                                        </div> :
                                        null
                                    }

                                    {ticket.name ?
                                        <div>
                                            <p className="section-accent">Name</p>
                                            <p>{ticket.name}</p>
                                        </div> : null}

                                    {ticket.deliveryType ?
                                        <div>
                                            <p className="section-accent">Delivery Type</p>
                                            <p>{ticket.deliveryType}</p>
                                        </div> : null}

                                    {obligation.vendorLocation.name ?
                                        <div>
                                            <p className="section-accent">Vendor</p>
                                            <p>{obligation.vendorLocation.name}</p>
                                        </div> : null}

                                    {obligation.vendorContact ?
                                        <div>
                                            <p className="section-accent">Contact Info</p>
                                            <p>{obligation.vendorContact.displayName + ": " + obligation.vendorContact.phone}</p>
                                        </div> : null}

                                    {ticket.urgency ?
                                        <div>
                                            <p className="section-accent">Urgency</p>
                                            <p>{ticket.urgency}</p>
                                        </div> : null}

                                    {ticket.vendorLocationTailor ?
                                        <div>
                                            <p className="section-accent">Assigned Tailor</p>
                                            <p>{ticket.vendorLocationTailor.name}</p>
                                        </div> : null}

                                </div>
                                <div className="col-md-3">
                                    {
                                        metaDataFields.map(function (field) {
                                            return (
                                                <div>
                                                    {field}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {(ticket.status === 'awaiting pickup' || ticket.status === 'awaiting shipment' || ticket.status === 'awaiting tailor assignment') && !printable ?
                                <div>
                                    <br/>
                                    <div className="text-right">
                                        <Button type={'danger-outline'}
                                                onPress={() => this.handleTicketCancelState()}
                                                message={this.state.ticketCancelConfirmState ? 'back' : 'cancel ticket'}/>
                                    </div>
                                    {this.state.ticketCancelConfirmState ?
                                        <div className={'ws-section ws-section--danger'}>
                                            <p>Are you sure you want to delete this ticket?</p>
                                            {settings.dryCleaning.requireRequester ?
                                                <div>
                                                    <Form
                                                        type={DeleterForm}
                                                        options={DeleterFormOptions}
                                                        value={this.state.deletingEmployee}
                                                        onChange={(value) => this.setState({deletingEmployee: value})}/>
                                                </div> : null}

                                            <div className="text-right">
                                                <Button
                                                    onPress={() => this.deleteUncompletedTicket(ticket.id)}
                                                    type={'danger'}
                                                    message={'YES, DELETE THIS TICKET'}
                                                    disabled={ticket.deletingEmployee && settings.dryCleaning.requireRequester}/>
                                            </div>
                                        </div> : null}
                                </div>
                                : null
                            }

                            {!printable ?
                                <div>
                                    <div className={'margin-bottom-md margin-top-sm'}>
                                        <div className={'row'}>
                                            {settings.dryCleaning.allowStatusChange
                                            && (this.state.previousStatus || this.state.ensuingStatus) ?
                                                <div className={'col-md-12'}>
                                                    {settings.dryCleaning.requireRequester ?
                                                        <Form type={RequesterForm}
                                                              value={this.state.value}
                                                              onChange={this.onChange}
                                                              options={RequesterFormOptions}/> : null}


                                                    <div className={'text-right'}>
                                                        {this.state.previousStatus ?
                                                            <Button
                                                                type={'warning'}
                                                                disabled={!this.state.updatingEmployee && settings.dryCleaning.requireRequester}
                                                                className={'margin-right-sm'}
                                                                onPress={() => this.decrementStatus(ticket)}
                                                                message={`Revert to ${this.state.previousStatus}`}/>
                                                            : null}

                                                        {this.state.ensuingStatus ?
                                                            <Button
                                                                type={'primary'}
                                                                disabled={!this.state.updatingEmployee && settings.dryCleaning.requireRequester}
                                                                onPress={() => this.incrementStatus(ticket)}
                                                                message={`Mark as ${this.state.ensuingStatus}`}/>
                                                            : null}

                                                    </div>
                                                </div> : null}
                                        </div>

                                        <div className="row">
                                            {this.state.ticketEditable ?
                                                <div className={'col-md-12'}>
                                                    <h1>Ticket Editable</h1>
                                                </div>
                                                : null}
                                        </div>
                                    </div>

                                    <Line/>

                                    <Header onClick={toggleShowAttachments} flip={showAttachments}>
                                        <div>Attachments</div>
                                    </Header>
                                    <Accordion show={showAttachments} maxHeight={500}>
                                        <AttachmentsList removeTitle={true} obligationItemGroup={ticket}
                                                         includeFileUploader={true}/>
                                    </Accordion>

                                    {settings.dryCleaning.allowOutreachLogging ?
                                        <React.Fragment>
                                            <Line/>
                                            <Header onClick={toggleShowOutreach} flip={showOutreach}>
                                                <div>Client Outreach</div>
                                            </Header>
                                            <Accordion show={showOutreach} maxHeight={500}>
                                                <div className={'row'}>
                                                    <div className={'col-md-8'}>
                                                        {ticket.outreaches && ticket.outreaches.length > 0 ? ticket.outreaches.sort((a, b) => moment(a.createdTimestamp).isBefore(b.createdTimestamp))
                                                            .map(function (outreach, idx) {
                                                                return (
                                                                    <div key={idx} style={{marginBottom: "10px"}}>
                                                                        <div>{outreach.submitterName} reached out
                                                                            by {outreach.outreachType}.
                                                                        </div>
                                                                        {outreach.description ? <div
                                                                            style={{fontStyle: 'italic'}}>{outreach.description}</div> : null}
                                                                        <small>{moment(outreach.createdTimestamp).format('MM/DD/YY h:mm a')}</small>
                                                                    </div>
                                                                )
                                                            }) : 'Nothing logged yet.'}
                                                    </div>
                                                    <div className={'col-md-4 text-right'}>
                                                        <Button
                                                            type={'primary'}
                                                            onPress={() => this.setState({outreachModalVisible: true})}
                                                            message={'Log Outreach'}/>
                                                        <TicketOutreachForm visible={outreachModalVisible}
                                                                            ticket={ticket}
                                                                            handleClose={() => this.handleTicketOutreachFormClose()}/>
                                                    </div>
                                                </div>
                                            </Accordion>
                                        </React.Fragment>
                                        : null
                                    }

                                    {settings.dryCleaning.allowTicketMessaging ?
                                        <React.Fragment>
                                            <Line/>

                                            <Header onClick={toggleShowMessages} flip={showMessages}>
                                                <div>Messages {(ticket.numberOfUnreadMessages ?
                                                    <RedText>{ticket.numberOfUnreadMessages} unread {ticket.numberOfUnreadMessages === 1 ? 'message' : 'messages'}</RedText> : null)}</div>
                                            </Header>
                                            <Accordion show={showMessages} maxHeight={500}>
                                                <Comments ref={e => {
                                                    this.commentsChild = e;
                                                }} ticket={ticket} style={{'padding': '10px 40px 9px'}}/>
                                            </Accordion>
                                        </React.Fragment>
                                        : null}

                                    <Line/>

                                    <Header onClick={toggleShowActivity} flip={showActivity}>
                                        <div>Ticket History</div>
                                    </Header>
                                    <Accordion show={showActivity} maxHeight={500}>
                                        {ticket.activities.sort((a, b) => {
                                            let aIsAfter = moment(a.createdTimestamp).isAfter(moment(b.createdTimestamp));

                                            return aIsAfter ? 1 : -1;
                                        }).map(function (activity, idx) {
                                            return (

                                                <div key={idx}>
                                                    <div>{activity.activity}</div>
                                                    <small>{moment(activity.createdTimestamp).format('MM/DD/YY h:mm a')}</small>
                                                </div>
                                            )
                                        })}
                                    </Accordion>

                                </div> : null}
                        </div> :
                        <section className={'ws-section'}>
                            <div className="row">
                                <div className="col-md-12">
                                    <TicketForm onSuccess={() => this.refreshTicket(this.state.ticket.id)}
                                                client={client}
                                                globalSettings={settings}
                                                obligation={obligation}
                                                ticketId={ticket.id}/>
                                </div>
                            </div>
                        </section>}
                </div>
            )

        }
    }
}

export default SingleTicket;
