import React, {Fragment} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import EventIcon from '@mui/icons-material/Event';
import ReactDOM from "react-dom";

import {withAphroditeTheme} from "../Theme/ReactDatesThemeProvider";
import {FontFamily, withTheme} from "../Theme/ThemeProvider";
import TextField from "../Inputs/TextField/TextField.web";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import CalendarMonth from "../Dates/CalendarMonth";
import MonthNavigationButton from "../Dates/MonthNavigationButton";
import Popover, {HorizontalOrigin, VerticalOrigin} from "../Popover/Popover.web";
import SingleDateCalendarBreakpointWrapper from "./SingleDateCalendarBreakpointWrapper";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import {InputBaseVariant} from "../Inputs/InputBase/InputBase.web";
import IconButton from "worksmith/components/Button/IconButton";
import {MomentFormat} from "worksmith/enums/MomentFormat";

export const DateInputFormat = Object.freeze({
    MonthDayYearSlash: {
        format: 'MM/DD/YYYY',
        placeholder: 'mm/dd/yyyy',
    },
    MonthDayYear: {
        format: MomentFormat.MonthDayYear,
        placeholder: 'mm-dd-yyyy'
    },
    DayMonthYearSlash: {
        format: MomentFormat.DayMonthYearSlash,
        placeholder: 'dd/mm/yyyy'
    }
});

class DatePicker extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            date: props.initialDate,
            focused: false,
            inputError: false,
        };

        this.textFieldRef = React.createRef();
        this.iconRef = React.createRef();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        let {date} = this.state;
        let {onChange} = this.props;

        if ((!ValueIsSet(prevState.date) && ValueIsSet(date)) ||
            (ValueIsSet(prevState.date) && !prevState.date.isSame(date))) {
           onChange(date);
        }
    };

    onCalendarDateChange = (date) => {
        let {textFieldRef} = this;
        let {dateInputFormat, asIcon} = this.props;

        if (!asIcon) {
            textFieldRef.current.onChange({target: {value: date.format(dateInputFormat.format)}})
        } else {
            this.updateDate(date.format(dateInputFormat.format));
        }
    };

    updateDate = (dateString) => {
        const {isDayBlocked, isOutsideRange, dateInputFormat} = this.props;
        const {date} = this.state;

        const newDate = moment(dateString, dateInputFormat.format, true);
        if (newDate.isValid()) {
            if ((!ValueIsSet(date) || !date.isSame(newDate))
                && !isDayBlocked(newDate) && !isOutsideRange(newDate)) {
                this.setState({anchorEl: null, date: moment(dateString, dateInputFormat.format), inputError: false});
            } else {
                this.setState({inputError: false});
            }
        } else if (!ValueIsSet(dateString) || dateString === '') {
            this.setState({inputError: false, date: null});
        } else {
            this.setState({inputError: true});
        }
    };

    onTextFieldBlur = () => {
        const {textFieldRef} = this;
        const {dateInputFormat, isDayBlocked, isOutsideRange} = this.props;
        const {date} = this.state;

        // When blurred, the text field will validate, then clear the picker, or reset to last date
        if (textFieldRef.current.state.value === '') {
            textFieldRef.current.onChange({target: { value: ''}});
        } else if (!moment(textFieldRef.current.state.value, dateInputFormat.format, true).isValid()
                    || isDayBlocked(date) || isOutsideRange(date) ) {
            textFieldRef.current.onChange({target: { value:
                        ValueIsSet(date) ?
                            date.format(dateInputFormat.format)
                            :
                            ''
                }
            });
        }
    };

    onTextFieldClick = () => {
        const {textFieldRef} = this;
        const {disabled} = this.props;

        if (!disabled) {
            this.setState({anchorEl: ReactDOM.findDOMNode(textFieldRef.current), focused: true});
        }
    };

    onIconClick = () => {
        const {iconRef} = this;
        const {disabled} = this.props;

        if (!disabled) {
            this.setState({anchorEl: ReactDOM.findDOMNode(iconRef.current), focused: true});
        }
    };

    onCalendarClose = () => {
        this.setState({anchorEl: null, focused: false});
    };

    onFocusChange = () => {
        // Force the focused states to always be truthy so that date is always selectable
        this.setState({ focused: true });
    };

    onClear = () => {
        this.textFieldRef.current.clear();
        this.setState({tempDate: null});
    };

    render() {
        const {textFieldRef, iconRef} = this;
        const { onCalendarClose, onCalendarDateChange, onTextFieldBlur, updateDate, onTextFieldClick, onIconClick } = this;
        const {
            asIcon,
            dateInputFormat,
            disabled,
            error,
            fullWidth,
            helperText,
            initialDate,
            initialVisibleMonth,
            InputProps,
            inputVariant,
            isDayBlocked,
            isDayHighlighted,
            isOutsideRange,
            label,
            popoverStartingSide,
            popoverVerticalAnchorOrigin,
            popoverVerticalTransformOrigin,
            size,
            TextFieldProps,
            useEndAdornment,
            usePopoverPositionAnchor} = this.props;
        const { anchorEl, date, focused, inputError } = this.state;

        return (
            <Fragment>
                {asIcon ?
                    <IconButton
                        disabled={disabled}
                        iconName={'Event'}
                        onClick={onIconClick}
                        ref={iconRef}
                        size="medium" />
                    :
                    <TextField
                        {...TextFieldProps}
                        disabled={disabled}
                        error={inputError || error}
                        fullWidth={fullWidth}
                        helperText={helperText}
                        label={label}
                        onBlur={onTextFieldBlur}
                        onChange={updateDate}
                        onClick={onTextFieldClick}
                        placeholder={dateInputFormat.placeholder}
                        ref={textFieldRef}
                        initialValue={ValueIsSet(initialDate) ? initialDate.format(dateInputFormat.format) : null}
                        startAdornment={useEndAdornment ? null : <EventIcon/>}
                        endAdornment={useEndAdornment ? <EventIcon/> : null}
                        variant={inputVariant}
                        size={size}
                        InputProps={InputProps}
                    />
                }
                <Popover
                    open={ValueIsSet(anchorEl)}
                    ModalProps={{
                        disableAutoFocus: true,
                        disableEnforceFocus: true,
                        disableRestoreFocus: true
                    }}
                    anchorEl={usePopoverPositionAnchor ? null :anchorEl}
                    anchorOrigin={{
                        horizontal: popoverStartingSide,
                        vertical: popoverVerticalAnchorOrigin
                    }}
                    onClose={onCalendarClose}
                    transformOrigin={{
                        horizontal: popoverStartingSide,
                        vertical: popoverVerticalTransformOrigin
                    }}>
                    <Box fontFamily={FontFamily}>
                        <SingleDateCalendarBreakpointWrapper
                            date={date}
                            focused={focused}
                            initialVisibleMonth={initialVisibleMonth}
                            isDayBlocked={isDayBlocked}
                            isDayHighlighted={isDayHighlighted}
                            isOutsideRange={isOutsideRange}
                            noBorder
                            onDateChange={onCalendarDateChange}
                            onFocusChange={this.onFocusChange}
                            renderMonthElement={(props) => {
                                return <CalendarMonth {...props}/>
                            }}
                            renderNavNextButton={(props) => {
                                return <MonthNavigationButton next iconName={'ChevronRight'} {...props}/>
                            }}
                            renderNavPrevButton={(props) => {
                                return <MonthNavigationButton prev iconName={'ChevronLeft'} {...props}/>
                            }}
                        />
                    </Box>
                </Popover>
            </Fragment>
        );
    }
}

DatePicker.defaultProps = {
    dateInputFormat: DateInputFormat.MonthDayYearSlash,
    disabled: false,
    initialDate: null,
    initialVisibleMonth: null,
    inputVariant: InputBaseVariant.OUTLINED,
    isDayBlocked: () => false,
    isDayHighlighted: () => false,
    isOutsideRange: () => false,
    popoverStartingSide: HorizontalOrigin.RIGHT,
    popoverVerticalAnchorOrigin: VerticalOrigin.BOTTOM,
    popoverVerticalTransformOrigin: VerticalOrigin.TOP,
    TextFieldProps: { // by default turn off browser autocomplete for date picker fields
        InputProps: {
            autoComplete: 'off'
        }
    },
    useEndAdornment: false,
    usePopoverPositionAnchor: false
};


DatePicker.propTypes = {
    asIcon: PropTypes.bool, // determines whether the date picker will display as a textfield or as just a calendar Icon
    dateInputFormat: PropTypes.shape({
        format: PropTypes.string.isRequired, // moment format for displaying the value
        placeholder: PropTypes.string,
    }).isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    initialDate: PropTypes.instanceOf(moment),
    initialVisibleMonth: PropTypes.func,
    InputProps: PropTypes.object,                           //Properties applied to the underlying Material UI Input component
    inputVariant: CustomPropTypes.enum(InputBaseVariant),
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,
    isOutsideRange: PropTypes.func,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    popoverStartingSide: CustomPropTypes.enum(HorizontalOrigin),
    popoverVerticalAnchorOrigin: PropTypes.oneOfType([
        PropTypes.number,
        CustomPropTypes.enum(VerticalOrigin)
    ]),
    popoverVerticalTransformOrigin: PropTypes.oneOfType([
        PropTypes.number,
        CustomPropTypes.enum(VerticalOrigin)
    ]),
    TextFieldProps: PropTypes.object,
    useEndAdornment: PropTypes.bool,                            // can be used if the popup is cut off by edge of screen
    usePopoverPositionAnchor: PropTypes.bool                    // can be used if the popup is cut off by edge of screen
};

export default withTheme(withAphroditeTheme(DatePicker));
