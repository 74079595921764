import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import ResolveConcernForm from "./ResolveConcernForm";
import {DialogSize} from "worksmith/enums/DialogSize";
import ObligationService from "worksmith/services/api/ObligationService";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";

const obligationService = new ObligationService();

class ResolveConcernDialog extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            valid: false,
            loading: false
        };

        this.resolutionNotes = null;
    }

    resolveConcern = () => {
        const {resolutionNotes} = this;
        const {onResolveConcern, concernId} = this.props;
        const {valid} = this.state;

        if (valid) {
            let resolveConcernParams = {
                resolutionNotes: resolutionNotes
            };

            obligationService.resolveConcern(concernId, resolveConcernParams)
                .then(() => {
                    if (onResolveConcern) {
                        onResolveConcern();
                    }
                    this.setState({loading: false});
                    DisplaySuccessNotification("Concern Resolved!");
                }, () => {
                    if (onResolveConcern) {
                        onResolveConcern();
                    }
                    DisplayErrorNotification("Failed to Resolve Concern")});
        }
    };

    onFormChange = ({resolutionNotes, valid}) => {
        this.resolutionNotes = resolutionNotes;

        if (this.state.valid !== valid) {
            this.setState({valid});
        }
    };

    setLoading = (loading) => {
        this.setState({loading: loading});
    };

    render() {
        let {resolveConcern, onFormChange, setLoading} = this;
        const {loading, valid} = this.state;
        const {onCancel, open} = this.props;

        return (
            <AlertDialog
                acceptDisabled={!valid || loading}
                acceptText={'Resolve'}
                cancelText={'Cancel'}
                onCancel={onCancel}
                onAccept={resolveConcern}
                onAcceptIgnoreDebounce={() => setLoading(true)}
                size={DialogSize.SMALL}
                title={'Resolve Concern?'}
                open={open}>
                <ResolveConcernForm
                    onChange={onFormChange}
                    loading={loading}/>
            </AlertDialog>
        )
    }
}

ResolveConcernDialog.propTypes = {
    concernId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onResolveConcern: PropTypes.func.isRequired,
    open: PropTypes.bool
};

export default ResolveConcernDialog;