// This file was generated from com.worksmith.vpm.controller.VendorController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class VendorService extends Http {
    constructor() {
        super('/vpm/vendor')
    }
    
    addAttachment(id, filetype, attachments) {
        return this.callApi( `${id}/${filetype}/attachments`, POST, null, null, null, true, attachments);
    }

    addAttachments(id, attachments) {
        return this.callApi( `${id}/attachments/multiple`, POST, null, null, null, true, attachments);
    }

    addVendorLogo(id, attachments) {
        return this.callApi( `${id}/addVendorLogo`, POST, null, null, null, true, attachments);
    }

    deleteAttachments(attachmentIds) {
        let params = {
            attachmentIds
        };

        return this.callApi( `attachments`, DELETE, null, params, null, true);
    }

    removeAttachment(id, filetype) {
        return this.callApi( `${id}/${filetype}/attachments`, DELETE, null, null, null, true);
    }

    removeVendorLogo(id) {
        return this.callApi( `${id}/removeVendorLogo`, DELETE, null, null, null, true);
    }

    removeWorkersCompDocument(id, documentId) {
        return this.callApi( `${id}/workersCompDocument/${documentId}`, DELETE, null, null, null, true);
    }

    updateWorkersCompDocument(id, params, file) {
        return this.callApi( `${id}/workersCompDocument`, PUT, params, null, null, true, file);
    }

    updateWorkersCompDocumentMetadata(id, params) {
        return this.callApi( `${id}/workersCompDocumentMetadata`, PUT, params, null, null, true);
    }

    addWorkersCompDocument(id, params, file) {
        return this.callApi( `${id}/workersCompDocument`, POST, null, params, null, true, file);
    }


    removeMinorityOwnerCertification(id, certificationId) {
        return this.callApi( `${id}/minorityOwnerCertification/${certificationId}`, DELETE, null, null, null, true);
    }

    updateMinorityOwnerCertification(id, params, file) {
        return this.callApi( `${id}/minorityOwnerCertification`, PUT, params, null, null, true, file);
    }

    updateMinorityOwnerCertificationMetadata(id, params) {
        return this.callApi( `${id}/minorityOwnerCertificationMetadata`, PUT, params, null, null, true);
    }

    addMinorityOwnerCertification(id, params, file) {
        return this.callApi( `${id}/minorityOwnerCertification`, POST, null, params, null, true, file);
    }
    
    addClientToBlacklist(vendorId, clientId) {
        return this.callApi( `${vendorId}/addClientToBlacklist/${clientId}`, POST, null, null, null, true);
    }
    
    addContact(id, contact, updaterId) {
        let headers = {
            updaterId
        };
    
        return this.callApi( `addContact/${id}`, POST, contact, null, headers, true);
    }
    
    addUser(id, user) {
        return this.callApi( `${id}/users`, POST, user, null, null, true);
    }

    assignVendorToMosUser(vendorId, userId){
        let params = {
            vendorId: vendorId,
            userId: userId
        };

        return this.callApi( `assignVendorToMosUser`, POST, null, params, null, true);
    }
    
    cancelPremiumMembership(vendorId) {
        return this.callApi( `${vendorId}/cancelPremiumMembership`, POST, null, null, null, true);
    }
    
    createVendorBio(vendorBio) {
        return this.callApi( `createVendorBio`, POST, vendorBio, null, null, true);
    }
    
    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteV2(id, removalReason) {
        let params = {
            removalReason
        };
    
        return this.callApi( `${id}`, DELETE, null, params, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllActive() {
        return this.callApi( `findAllActive`, GET, null, null, null, true);
    }
    
    findAllActiveSummaries() {
        return this.callApi( `findAllActiveSummaries`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findByUserId() {
        return this.callApi( `findByUserId`, GET, null, null, null, true);
    }
    
    findClientLocations(id, clientId) {
        let params = {
            clientId
        };
    
        return this.callApi( `${id}/findClientLocations`, GET, null, params, null, true);
    }
    
    findFullSummaries(searchParams) {
        return this.callApi( `fullSummaries`, POST, searchParams, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findPastDueObligations(vendorId) {
        return this.callApi( `${vendorId}/findPastDueObligations`, GET, null, null, null, true);
    }
    
    findSimpleVendor(id) {
        return this.callApi( `${id}/simple`, GET, null, null, null, true);
    }
    
    findStandardPricings(id, serviceLineId) {
        return this.callApi( `${id}/serviceLines/${serviceLineId}/standardPricings`, GET, null, null, null, true);
    }
    
    findUsers(id) {
        return this.callApi( `${id}/users`, GET, null, null, null, true);
    }
    
    findVendorSimpleBusinessObjects(params) {
        return this.callApi( `simple`, POST, params, null, null, true);
    }
    
    getAllVendorComplianceSummaries(startDate, endDate) {
        let params = {
            startDate, 
            endDate
        };
    
        return this.callApi( `outOfCompliance`, GET, null, params, null, true);
    }

    updateOnboardingInfo(params) {
        return this.callApi('updateOnboardingInfo', POST, params, null, null, true);
    }
    
    getClientsServiced(id) {
        return this.callApi( `${id}/getClientsServiced`, GET, null, null, null, true);
    }
    
    getCoachBid(id) {
        return this.callApi( `${id}/coachBid`, GET, null, null, null, true);
    }
    
    getOutOfComplianceCSV(startDate, endDate) {
        let params = {
            startDate, 
            endDate
        };
    
        return this.callApi( `outOfComplianceCSV`, GET, null, params, null, true);
    }
    
    getStatistics(id) {
        return this.callApi( `${id}/statistics`, GET, null, null, null, true);
    }
    
    getStatisticsByUser() {
        return this.callApi( `statistics`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    reactivateVendor(id) {
        return this.callApi( `${id}/reactivate`, PATCH, null, null, null, true);
    }
    
    removeClientFromBlacklist(vendorId, clientId) {
        return this.callApi( `${vendorId}/removeClientFromBlacklist/${clientId}`, DELETE, null, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }

    updateAllowDispatcher(vendorId, isAllowing){
        let params = {isAllowing};
        return this.callApi(`${vendorId}/updateAllowDispatchers`, POST, null, params, null, true)
    }
    
    updateAllPremiumBadgeQualifications() {
        return this.callApi( `updateAllPremiumBadgeQualifications`, POST, null, null, null, true);
    }
    
    updateBillDotComVendorPaymentTerms(vendorId, billDotComPaymentTerm) {
        let params = {
            vendorId, 
            billDotComPaymentTerm
        };
    
        return this.callApi( `updateBillDotComVendorPaymentTerms`, PATCH, null, params, null, true);
    }
    updatePaymentProfile(vendorId, profileData) {
        return this.callApi( `${vendorId}/paymentProfile`, PATCH, profileData, null, null, true);
    }

    updateVendorBio(vendorBio) {
        return this.callApi( `updateVendorBio`, PATCH, vendorBio, null, null, true);
    }

    updateW9(vendorId, attachments) {
        return this.callApi( `${vendorId}/w9/attachments`, POST, null, null, null, true, attachments);
    }

    upgradeToPremium(vendorId) {
        return this.callApi( `${vendorId}/upgradeToPremium`, POST, null, null, null, true);
    }

    activateAndUpdateWorkbench(params) {
        return this.callApi(`activateAndUpdateWorkbench`, POST, params, null, null, true);
    }

    deactivateWorkbench(vendorId) {
        return this.callApi(`deactivateWorkbench/${vendorId}`, POST, null, null, null, true);
    }

    addNote(params) {
        return this.callApi(`addNote`, POST, params, null, null, true);
    }

    addVendorPlatformFeeClientExemption(vendorId, clientId) {
        return this.callApi(`addVendorPlatformFeeClientExemption/${vendorId}/${clientId}`, POST, null, null, null, true);
    }

    deleteVendorPlatformFeeClientExemption(id) {
        return this.callApi(`deleteVendorPlatformFeeClientExemption/${id}`, DELETE, null, null, null, true);
    }

    logDisconnect(params) {
        return this.callApi(`logDisconnect`, POST, params, null, null, true);
    }

}

export default VendorService;
