import React from "react";
import PropTypes from 'prop-types';

import Grid from "worksmith/components/Grid/Grid.web";
import FieldTitleAndBody from "worksmith/composite-components/FieldTitleAndBody/FieldTitleAndBody";
import Text, {TextVariant} from "worksmith/components/Text/Text.web";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import UserType from "worksmith/enums/api/user/UserType";
import Address from "worksmith/components/Address/Address";
import {TextColor} from "../../components/Text/Text.web";

const ScheduleOptionsFormServiceSummary = (props) => {
    const {
        clientLocation,
        scheduleOptionType,
        serviceLine,
        vendor,
        userType
    }  = props;
return (
    <Grid container item spacing={3}>
                <Grid container item xs={12} sm={6}>
                    <FieldTitleAndBody
                        key={'Type'}
                        xs={6}
                        item
                        title={'Type'}
                        titleColor={TextColor.TEXT_SECONDARY}
                        titleTextVariant={TextVariant.SUBTITLE_2}
                        body={<Text variant={TextVariant.BODY_2} color={TextColor.TEXT_SECONDARY}>{scheduleOptionType}</Text>}
                    />
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <FieldTitleAndBody
                        key={'Service Line'}
                        xs={6}
                        item
                        title={'Service Line'}
                        titleColor={TextColor.TEXT_SECONDARY}
                        titleTextVariant={TextVariant.SUBTITLE_2}
                        body={<Text variant={TextVariant.BODY_2} color={TextColor.TEXT_SECONDARY}>{serviceLine.name}</Text>}
                    />
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <FieldTitleAndBody
                        key={'Client Location'}
                        xs={6}
                        item
                        titleColor={TextColor.TEXT_SECONDARY}
                        titleTextVariant={TextVariant.SUBTITLE_2}
                        title={userType === UserType.CLIENT ? "Location" : clientLocation.client.nickname}
                        body={<Address
                            addressLineOne={clientLocation.addressLineOne}
                            addressLineTwo={clientLocation.addressLineTwo}
                            city={clientLocation.city}
                            state={clientLocation.state}
                            textColor={TextColor.TEXT_SECONDARY}
                            zip={clientLocation.zip}
                            />}
                    />
                </Grid>
                {userType === UserType.WORKSMITH || userType === UserType.CLIENT ?
                    <Grid container item xs={12} sm={6}>
                        <FieldTitleAndBody
                            key={'Vendor'}
                            xs={6}
                            item
                            title={'Vendor'}
                            titleColor={TextColor.TEXT_SECONDARY}
                            titleTextVariant={TextVariant.SUBTITLE_2}
                            body={<Text variant={TextVariant.BODY_2} color={TextColor.TEXT_SECONDARY}>{vendor}</Text>}
                        />
                    </Grid> : null}
        </Grid>
    );
};

ScheduleOptionsFormServiceSummary.propTypes = {
    clientLocation: PropTypes.shape({
        addressLineOne: PropTypes.string,
        addressLineTwo: PropTypes.string,
        city: PropTypes.string,
        client: PropTypes.shape({
            nickname: PropTypes.string
        }),
        state: PropTypes.string,
        zip: PropTypes.string,
    }),
    scheduleOptionType: CustomPropTypes.enum(ScheduleOptionType),
    serviceLine: PropTypes.shape({
        name: PropTypes.string
    }),
    userType: CustomPropTypes.enum(UserType),
    vendor: PropTypes.string,
};

export default ScheduleOptionsFormServiceSummary;