import React, {Component} from 'react';
import ReactSelect from 'react-select';
import ClientLocationService from 'worksmith/services/api/ClientLocationService';
import UserService from 'worksmith/services/api/UserService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import {Link} from 'react-router-dom';

import Loader from "../../components/Loader";
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import styled from 'styled-components';
import {Color} from "../../Enums";

const userService = new UserService();
const clientLocationService = new ClientLocationService();
const authTokenManager = new AuthTokenManager();

const Card = styled.div`
    text-align: center;
    position: relative;
    background-color: ${Color.WHITE};
    padding: 15px;
    border: 1px solid #eaeaea;
    margin: 1rem 0;
    height: 300px;
`;

const HeaderText = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const HeaderSpan = styled.span`
    font-size: 28px;
    font-weight: 200;
    color: ${Color.NAVY};
    margin-right:10px;
`;

const BodyText = styled.div`
    font-size: 12px;
`;

const BodyTextLeftJustified = styled(BodyText)`
    font-size: 12px;
`;

const Spacer = styled.div`
    box-sizing: border-box;	
    height: 120px;	
    width: 100%;
`;

class BurberryBulkShipmentDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            locationId: null,
            hasSingleLocation: true,
            locations: [],
            preferredVendors: [],
            searchParams: {}
        }

        this.getUserLocations = this.getUserLocations.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.getPreferredVendors = this.getPreferredVendors.bind(this);
    }

    componentWillMount() {
        let _this = this;
        this.getUserLocations().then(function() {
            _this.getPreferredVendors();
        });
    }

    getUserLocations() {

        let _this = this;

        return userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId()).then(function (data) {
            let locationsData = data;

            var locations = locationsData.map((location)=>
            { return {value: location.id, label: location.title} });

            if (locations.length === 1) {
                _this.setState({ hasSingleLocation: true })
            }

            let searchParams = _this.state.searchParams;
            searchParams.clientLocationId = locations[0].value;

            _this.setState({ searchParams, locations });
        });
    }

    getPreferredVendors() {
        let _this = this;
        _this.setState({loading: true});

        clientLocationService.getPreferredVendorLocationTicketSummaries(_this.state.searchParams.clientLocationId, null).then(function (data) {
            _this.setState({ preferredVendors: data });
            _this.setState({loading: false});
        })
    }

    onLocationChange(location) {
        let { searchParams } = this.state;
        searchParams.clientLocationId = location.value;
        this.setState({searchParams});

        this.getPreferredVendors();
    }

    render() {

        let { loading, preferredVendors, locations, searchParams } = this.state;

        return (
                <div>
                    { locations.length > 1 ?
                        <section className="ws-section">
                            <Row className="margin-bottom-lg">
                                <Col>
                                    <ReactSelect options={locations}
                                                 onChange={this.onLocationChange}
                                                 value={searchParams.clientLocationId}
                                                 clearable={false}/>
                                </Col>
                            </Row>
                        </section> : null }
                    <Row>
                        <Col>
                            { loading ?
                                <Loader/>
                                :
                                <div>
                                    {preferredVendors.length > 0 ?
                                        <Row gutter={16}>
                                            {preferredVendors.map(function (vendorSummary) {
                                                return (
                                                    <Col key={vendorSummary.vendor.id} span={8}>
                                                        <Card>
                                                            <Link to={`/ticketing/vendor-dashboard/${vendorSummary.vendor.id}`} ><HeaderText>{vendorSummary.vendor.name}</HeaderText></Link>
                                                            <BodyText>{vendorSummary.serviceLines.map(sl => sl.name).join(', ')}</BodyText>
                                                            <Spacer/>
                                                            <BodyTextLeftJustified><HeaderSpan>{vendorSummary.ticketsReadyToBeShipped}</HeaderSpan> Items waiting to ship.</BodyTextLeftJustified>
                                                        </Card>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                        :
                                        <div className="ws-section">
                                            <p>There are no vendors to show.</p>
                                        </div>
                                    }
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
        )
    }
}

export default BurberryBulkShipmentDashboard;
