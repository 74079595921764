import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from "worksmith/components/Modal/Modal";
import {StringHasText, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import FreeFormTextModalView from "./FreeFormTextModalView";

class FreeFormTextModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isValid: true,
            submitting: false,
            value: ''
        };
    }

    onSubmit = () => {
        const {validate} = this;
        const {value} = this.state;
        const {onSubmit} = this.props;

        const isValid = validate();

        if(isValid) {
            this.setState({isValid, submitting: true}, () => {
                onSubmit(value);
            });
        } else {
            this.setState({isValid});
        }
    };

    onChange = (value) => {
        this.setState({value});
    };

    validate = () => {
        const {value} = this.state;

        return ValueIsSet(value) && StringHasText(value);
    };

    render() {
        const {onChange, onSubmit} = this;
        const {isValid, submitting} = this.state;
        const {onClose, open, validationError, question, supportingText, inputLabel, required, cancelButtonText, submitButtonText, textFieldPlaceholder, textFieldRows} = this.props;

        return (
            <Modal open={open}
                   onClose={onClose}>
                <FreeFormTextModalView closeModal={onClose}
                                       isValid={isValid}
                                       inputLabel={inputLabel}
                                       onChange={onChange}
                                       onSubmit={onSubmit}
                                       required={required}
                                       submitting={submitting}
                                       textFieldPlaceholder={textFieldPlaceholder}
                                       textFieldRows={textFieldRows}
                                       validationError={validationError}
                                       question={question}
                                       supportingText={supportingText}
                                       cancelButtonText={cancelButtonText}
                                       submitButtonText={submitButtonText}/>
            </Modal>
        )
    }
}

FreeFormTextModal.propTypes = {
    cancelButtonText: PropTypes.string,
    inputLabel: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    required: PropTypes.bool,
    validationError: PropTypes.string,
    question: PropTypes.string,
    submitButtonText: PropTypes.string,
    supportingText: PropTypes.string,
    textFieldPlaceholder: PropTypes.string,
    textFieldRows: PropTypes.number
};

export default FreeFormTextModal;