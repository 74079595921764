import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {convert24HourNumberToMomentTime} from "../../helpers/TimeHelpers";
import {MomentFormat} from "../../enums/MomentFormat";
import TimeRangeFieldView from "./TimeRangeFieldView";

class TimeRangeField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startTime: props.initialStartTime,
            endTime: props.initialEndTime
        };

        // Copy initial values into object and use these instead of props
        this.initialStartTime = props.initialStartTime;
        this.initialEndTime = props.initialEndTime;
    }

    onTimeRangeCommitted = (timeArray) => {
        const {onChange} = this.props;

        this.setState({startTime: convert24HourNumberToMomentTime(timeArray[0]), endTime: convert24HourNumberToMomentTime(timeArray[1])},
            () => onChange(this.state.startTime, this.state.endTime));
    };

    onTimeRangeChange = (timeArray) => {
        this.setState({startTime: convert24HourNumberToMomentTime(timeArray[0]), endTime: convert24HourNumberToMomentTime(timeArray[1])});
    };

    render() {
        let {onTimeRangeCommitted, onTimeRangeChange} = this;
        const {startTime, endTime} = this.state;
        const {initialEndTime, initialStartTime, minRange, maxRange, step, label} = this.props;

        return (
            <TimeRangeFieldView
                endTime={endTime}
                initialEndTime={initialEndTime}
                initialStartTime={initialStartTime}
                minRange={minRange}
                maxRange={maxRange}
                onTimeRangeChange={onTimeRangeChange}
                onTimeRangeCommitted={onTimeRangeCommitted}
                startTime={startTime}
                step={step}
                label={label}/>
        )
    }
}

TimeRangeField.defaultProps = {
    initialEndTime: moment('11:30 PM', MomentFormat.StandardTime),
    initialStartTime: moment('12:00 AM', MomentFormat.StandardTime),
    minRange: moment('12:00 AM', MomentFormat.StandardTime),
    maxRange: moment('11:30 PM', MomentFormat.StandardTime),
    step: 0.5,
};

TimeRangeField.propTypes = {
    initialEndTime: PropTypes.instanceOf(moment),
    initialStartTime: PropTypes.instanceOf(moment),
    minRange: PropTypes.instanceOf(moment),
    maxRange: PropTypes.instanceOf(moment),
    onChange: PropTypes.func.isRequired, // (startTime, endTime) => {}
    step: PropTypes.number,
    label: PropTypes.string.isRequired
};

export default TimeRangeField;