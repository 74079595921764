import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import MaterialPopover from "@mui/material/Popover";

import {ThemeConstants, withTheme} from "../Theme/ThemeProvider";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import Box from "@mui/material/Box";
import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";

export const HorizontalOrigin = Object.freeze({
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
});

export const VerticalOrigin = Object.freeze({
    TOP: 'top',
    CENTER: 'center',
    BOTTOM: 'bottom'
});

const Popover = (props) => {
    let {
        anchorEl,
        anchorOrigin,
        anchorPosition,
        anchorReference,
        className,
        ModalProps,
        open,
        onClose,
        PaperStyle,
        transformOrigin
    } = props;

    return (
        <MaterialPopover
            {...ModalProps}
            anchorReference={ValueIsSet(anchorEl) ? 'anchorEl': 'anchorPosition'}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            anchorPosition={anchorPosition}
            className={className}
            open={open}
            onClose={onClose}
            keepMounted={false}
            PaperProps={{style:{padding: (ThemeConstants.spacingUnit * 2) + 'px', ...PaperStyle}}}
            transformOrigin={transformOrigin}>
            <Box>
                {props.children}
            </Box>
        </MaterialPopover>
    )
};

Popover.defaultProps = {
    anchorPosition: {
        left: 0,
        top: 0
    },
    anchorOrigin: {
        horizontal: HorizontalOrigin.LEFT,
        vertical: VerticalOrigin.BOTTOM
    },
    transformOrigin: {
        horizontal: HorizontalOrigin.LEFT,
        vertical: VerticalOrigin.TOP
    }
};

Popover.propTypes = {
    anchorEl: PropTypes.oneOfType([ //Element that the popover is anchored to
        PropTypes.object,
        PropTypes.func
    ]),
    anchorOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(HorizontalOrigin)
            ]),
        vertical: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(VerticalOrigin)
            ])
    }),
    anchorPosition: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
    }),
    anchorReference: PropTypes.string,
    className: PropTypes.string,
    ModalProps: PropTypes.shape({
        disableAutoFocus: PropTypes.bool,
        disableEnforceFocus: PropTypes.bool,
        disableRestoreFocus: PropTypes.bool,
        disableScrollLock: PropTypes.bool
    }),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    PaperStyle: PropTypes.object,
    transformOrigin: PropTypes.shape({
        horizontal: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(HorizontalOrigin)
            ]),
        vertical: PropTypes.oneOfType(
            [
                PropTypes.number,
                CustomPropTypes.enum(VerticalOrigin)
            ])
    }),
};

export default withTheme(Popover);
