import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {Color} from "worksmith/enums/Color";
import styled from "styled-components";
import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {Link, withRouter} from "react-router-dom";
import {toCurrencyString} from "worksmith/helpers/LanguageHelper";

const Banner = styled.div`
        display: flex;
        justify-content: ${JustifyContent.FLEX_START};
        align-items: ${AlignItems.CENTER};
        background-color: ${props => props.color};
        padding: 20px 10px 15px 35px;
        color: ${Color.WHITE};
`;

const BannerText = styled.p`
    margin: 0 0 4px 0;
    fontSize: 18px;
`;

const ClientInvoiceWarningBannerView = (props) => {
    const {outstandingBalance,
           pastDueBalance,
           invoiceDueDate} = props;
    let showInvoiceWarningBanner = true;
    let invoiceWarningBannerColor;
    let invoiceWarningText;
    let invoiceDueDateMoment = moment(invoiceDueDate);

    if(pastDueBalance > 0) {
        invoiceWarningBannerColor = Color.RED;
        invoiceWarningText =
            <BannerText>You have a total of {toCurrencyString(pastDueBalance)} past due. Your account will be locked and services will be paused if payment is not received.{' '}
                <Link style={{color: Color.WHITE, textDecoration: 'underline'}} to={'/invoices'}>{' View Past Due Invoices'}</Link>
            </BannerText>
    }
    else if((invoiceDueDateMoment.isSame(moment().add(3, 'd'), 'day')
        || invoiceDueDateMoment.isSame(moment().add(1, 'd'), 'day'))
        && outstandingBalance > 0){
        invoiceWarningBannerColor = Color.PRIMARY;
        invoiceWarningText =
            <BannerText>You have a total of {toCurrencyString(outstandingBalance)} due on {invoiceDueDateMoment.format('MM/DD/YYYY')}. Please ensure payment to avoid service interruption.{' '}
                <Link style={{color: Color.WHITE, textDecoration: 'underline'}} to={'/invoices'}>{' View Outstanding Invoices'}</Link>
            </BannerText>
    }
    else if(invoiceDueDateMoment.isSame(moment(), 'day') && outstandingBalance > 0){
        invoiceWarningBannerColor = '#1A829B';
        invoiceWarningText =
            <BannerText>You have a total of {toCurrencyString(outstandingBalance)} due today. Please ensure payment to avoid service interruption.{' '}
                <Link style={{color: Color.WHITE, textDecoration: 'underline'}} to={'/invoices'}>{' View Outstanding Invoices'}</Link>
            </BannerText>
    }
    else
        showInvoiceWarningBanner = false;

    let invoiceWarningBannerJSX = showInvoiceWarningBanner ? (
            <Banner color={invoiceWarningBannerColor}>
                {invoiceWarningText}
            </Banner>
        )
        :
        null;

    return (
        invoiceWarningBannerJSX
    )
};

ClientInvoiceWarningBannerView.propTypes = {
    outstandingBalance: PropTypes.number,
    pastDueBalance: PropTypes.number,
    invoiceDueDate: PropTypes.string
};

export default withRouter(ClientInvoiceWarningBannerView);