import * as PropTypes from "prop-types";
import React, {Fragment, useEffect, useState} from "react";
import Text, {TextDisplay} from "worksmith/components/Text/Text.web";
import ObligationStatus from "worksmith/enums/api/task/ObligationStatus";
import Grid from "worksmith/components/Grid/Grid.web";
import ObligationTemplateStatus from "worksmith/enums/api/task/ObligationTemplateStatus";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {Uppercase} from "worksmith/helpers/LanguageHelper";
import styled from "styled-components";
import ClientVendorConversation from "worksmith/composite-components/ClientVendorConversation/ClientVendorConversation";
import Accordion from "worksmith/components/Accordion/Accordion";
import ConversationHeader from "worksmith/composite-components/ConversationHeader/ConversationHeader";
import ClientRoutes from "../../ClientRoutes";
import {Link} from "react-router-dom";
import {Color} from "worksmith/enums/Color";
import {AlignItems} from "worksmith/enums/CSSEnums";
import AlertExpandableSection from "worksmith/components/ExpandableSection/AlertExpandableSection";
import Box from "@mui/material/Box";
import ConversationList from "worksmith/composite-components/ConversationList/ConversationList";


const SpacedText = styled(Text)`
    padding-top: 0.5em;
    padding-bottom: 0.5em;

`;

const StyledConversationGrid = styled(Grid)`
    padding-top: 0 !important;
`;

const ObligationTemplatePropertiesGrid = (props) =>{

    let {obligationTemplate, skipFirstLine, loading} = props;

    const [unreadConversationGroupCount, setUnreadConversationGroupCount] = useState(0);
    const [showConversationUnreadMessages, setShowConversationUnreadMessages] = useState(true);

    useEffect(() => {
        setShowConversationUnreadMessages(ValueIsSet(obligationTemplate.conversation))

        if (!loading && ValueIsSet(obligationTemplate.conversation)) {
            setUnreadConversationGroupCount(obligationTemplate.conversation.unreadMessageCount)
        }
    }, [loading])


    return <Grid xs={12} container alignItems={AlignItems.FLEX_START}>
        <Grid container item xs={6}>
            {obligationTemplate.status === ObligationStatus.CANCELED && !skipFirstLine?
                <Fragment>
                    <Grid item xs={6}>
                        <SpacedText semiBold display={TextDisplay.inline}>Cancelled on</SpacedText>
                    </Grid>
                    <Grid item xs={6}>
                        <SpacedText display={TextDisplay.inline}>{moment(obligationTemplate.updateTimestamp).format(MomentFormat.MonthDayYearSlash)}</SpacedText>
                    </Grid>
                </Fragment> : null
            }
            {obligationTemplate.status === ObligationStatus.CANCELED && ValueIsSet(obligationTemplate.cancellationReason) ?
                <Fragment>
                    <Grid item xs={6}>
                        <SpacedText semiBold display={TextDisplay.inline}>Cancellation Reason</SpacedText>
                    </Grid>
                    <Grid item xs={6}>
                        <SpacedText display={TextDisplay.inline}>{obligationTemplate.cancellationReason}</SpacedText>
                    </Grid>
                </Fragment> : null
            }
            <Grid item xs={6}>
                <SpacedText semiBold display={TextDisplay.inline}>Service Status</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText display={TextDisplay.inline}>{obligationTemplate.status !== ObligationTemplateStatus.CANCELED ? "Ongoing" : "Cancelled"}</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText semiBold display={TextDisplay.inline}>Frequency</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText>{obligationTemplate.recurringScheduleTemplate.readableScheduleString}</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText semiBold display={TextDisplay.inline}>Last Service Date</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText>{ValueIsSet(obligationTemplate.lastCompletedVisitDate) ? moment(obligationTemplate.lastCompletedVisitDate).format(MomentFormat.MonthDayYearSlash) : "None"}</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText semiBold display={TextDisplay.inline}>Scope of Work</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText>{ValueIsSet(obligationTemplate.requestForProposal) ? Uppercase(obligationTemplate.requestForProposal.description) : "None Listed"}</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText semiBold display={TextDisplay.inline}>Vendor</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText>{obligationTemplate.vendor.officialName}</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText semiBold display={TextDisplay.inline}>Original Request</SpacedText>
            </Grid>
            <Grid item xs={6}>
                <SpacedText>
                    {
                    ValueIsSet(obligationTemplate.requestForProposal) ?
                        <Link style={{textDecoration: 'underline', color: Color.SECONDARY}} to={'/' + ClientRoutes.SINGLE_REQUESTS(obligationTemplate.requestForProposal.id)} target={'_blank'}>{obligationTemplate.requestForProposal.id}</Link>
                        :
                        "No Request"
                    }
                </SpacedText>
            </Grid>
        </Grid>
        <StyledConversationGrid container item xs={6}>
            {ValueIsSet(obligationTemplate.conversation) ? <StyledConversationGrid item xs={12}>
                <AlertExpandableSection
                    title={'Contact Vendor'}
                    initialExpanded={true}
                    alerts={unreadConversationGroupCount && unreadConversationGroupCount > 0 ?
                        [{
                            count: unreadConversationGroupCount,
                            label: 'new'
                        }]
                        :
                        null
                    }
                    hideAlerts={!showConversationUnreadMessages}
                >
                    <Box mt={3} ml={1} mr={2}>
                        <ConversationList
                            onMessageGroupRead={(number) => {
                                setUnreadConversationGroupCount(unreadConversationGroupCount - number)
                            }}
                            requestId={obligationTemplate.requestForProposal.id}
                        />
                    </Box>
                </AlertExpandableSection>
            </StyledConversationGrid> : null}
        </StyledConversationGrid>
        {obligationTemplate.status === ObligationStatus.CANCELED ?
            <Fragment>
                <Grid item xs={6}>
                    <SpacedText semiBold display={TextDisplay.inline}>Vendor</SpacedText>
                </Grid>
                <Grid item xs={6}>
                    <SpacedText>{ValueIsSet(obligationTemplate.vendor) ? obligationTemplate.vendor.officialName : "None"}</SpacedText>
                </Grid>
                <Grid item xs={6}>
                    <SpacedText semiBold display={TextDisplay.inline}>Price per visit</SpacedText>
                </Grid>
                <Grid item xs={6}>
                    <SpacedText>{ValueIsSet(obligationTemplate.totalRetailPrice) ? "$" + obligationTemplate.totalRetailPrice : "None Listed"}</SpacedText>
                </Grid>
            </Fragment>
            : null }
        <Grid item xs={6}/>
        <Grid item xs={6}>
        </Grid>

    </Grid>
};

ObligationTemplatePropertiesGrid.propTypes = {
    obligationTemplate: PropTypes.object,
    skipFirstLine: PropTypes.bool,
    displayVendorAndPriceDetails: PropTypes.bool
};

export default ObligationTemplatePropertiesGrid;