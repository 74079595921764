import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GraphQLServiceClass from "../../services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "../../enums/GraphQLObjectType";
import WalkthroughListView from "./WalkthroughListView";

const graphQLService = new GraphQLServiceClass();

class WalkthroughList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            walkthroughs: []
        };
    }

    componentDidMount = () => {
        const {requestId} = this.props;

        let searchParams = {
            isCanceled: false,
            isDeclined: false,
            rfpId: requestId
        };

        graphQLService.findAll(
            searchParams,
            GraphQLObjectType.WALKTHROUGH,
            WalkthroughListGraphQlString
        ).then((data) => {
            this.setState({loading: false, walkthroughs: data});
        })
    }

    render() {
        const {isAdmin, numberOfWalkthroughs, onWalkthroughAction, startLoad} = this.props;
        const {loading, walkthroughs} = this.state;

        return (
            <WalkthroughListView
                loading={loading}
                isAdmin={isAdmin}
                numberOfWalkthroughs={numberOfWalkthroughs}
                onWalkthroughAction={onWalkthroughAction}
                startLoad={startLoad}
                walkthroughs={walkthroughs}/>
        )
    }
}

WalkthroughList.propTypes = {
    isAdmin: PropTypes.bool,
    numberOfWalkthroughs: PropTypes.number,
    onWalkthroughAction: PropTypes.func,
    requestId: PropTypes.number,
    startLoad: PropTypes.func,
};

const WalkthroughListGraphQlString = `
    activeObligation {
        completedObligationActivity {
                aggregatorResource {
                    trustedTechnician
            }
                user {
                    displayName
                }
            }
        totalRetailPrice
    }
    id
    isScheduling  
    isScheduled
    isRescheduling
    isCompleted
    status
    opportunity {
        vendorLocation {
            rating
            ratingsCount
            vendor {
                officialName
                vendorBio {
                    aboutUs
                }
            }
        }
    }
    totalRetailPrice
`;

export default WalkthroughList;