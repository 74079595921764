import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import {HashRouter as Router, Route, withRouter} from 'react-router-dom';
import {ClientDashboardType, ClientType, GlobalRoutes, InternalNotesType, OAuthProvider} from "./Enums";
import Intercom from 'react-intercom';
import * as ReactGA from 'react-ga';

import Sidebar from './components/Sidebar';
import TopMenu from './components/TopMenu';
import DashboardPage from "./pages/DashboardPage";
import LocationsPage from "./pages/locations/LocationsPage";
import NewLocationPage from "./pages/locations/NewLocationPage";
import ClientLocationProfilePage from "./pages/clientLocationProfile/ClientLocationProfilePage";
import SingleRequestPage from "./pages/requests/SingleRequestPage";
import NewRequestPage from "./pages/requests/NewRequestPage";
import KendraScottNewRequestPage from "./pages/requests/KendraScottNewRequestPage";
import RequestsPage from "./pages/requests/RequestsPage";
import InvoicesOverview from "./pages/invoices/InvoicesOverview";
import SingleInvoice from "./pages/invoices/SingleInvoice";
import UsersPage from "./pages/users/UsersPage";
import NewUserPage from "./pages/users/NewUserPage";
import SingleUserPage from "./pages/users/SingleUserPage";
import ClientAccountPage from "./pages/account/ClientAccountPage";
import DelinquentClientRedirect from "./pages/account/DelinquentClientRedirect";
import ClientApprovalSettings from "./pages/approval-settings/ClientApprovalSettings";
import ClientSupportPage from "./pages/support/ClientSupportPage";
import ClientHowToPage from "./pages/support/ClientHowToPage";
import UserService from 'worksmith/services/api/UserService';
import ClientService from 'worksmith/services/api/ClientService';
import Loader from "./components/Loader";
import FeedbackRequestsModal from "./components/FeedbackRequestsModal";
import SinglePickupPage from "./pages/pickups/SinglePickupPage";
import TicketDashboardPage from "./pages/tickets/TicketDashboardPage";
import NewTicketPage from "./pages/ticket/NewTicketPage";
import PrintTicketsPage from "./pages/ticket/PrintTicketsPage";
import BurberryLandingPage from "./pages/burberry/BurberryLandingPage";
import BurberryDashboard from "./pages/burberry/BurberryDashboard";
import BurberryBulkShipmentList from "./pages/burberry/BurberryBulkShipmentList";
import BurberryNewTicketPage from "./pages/burberry/BurberryNewTicketPage";
import BurberryBulkShipmentDashboard from "./pages/burberry/BurberryBulkShipmentDashboard";
import SingleTicketPage from "./pages/burberry/SingleTicketPage";
import {GlobalContext} from "./context/GlobalContext";
import {Branding} from "./Config";
import {ValueIsSet} from "./Helpers";
import Footer from "./components/Footer";
import AuthTokenManager from "worksmith/services/utilities/AuthTokenManager";
import {HttpHandler} from 'worksmith/services/utilities/HttpHandler';
import {hotjar} from 'react-hotjar'
import {config} from "./env";
import {LocalStorageKeys} from "./shared/enums/Enums";
import styled from 'styled-components';
import CalendarPage from "./pages/calendar/CalendarPage";
import UnsubscribePage from "./pages/account/UnsubscribePage";
import ScheduleChanges from "./components/obligations/ScheduleChanges";
import ClientUserRole from "./lib-worksmith/enums/api/user/ClientUserRole";
import MobileAppRedirectModal from "worksmith/components/Modal/MobileAppRedirectModal.web";
import ContractPage from "./pages/contract/ContractPage";
import FullWidthPageContainer from "./FullWidthPageContainer";
import LegacyPageContainer from "./LegacyPageContainer";
import {getDashboardType} from "worksmith/helpers/ClientHelper";
import UtilityServiceClass from "./components/http/UtilityServiceClass";
import RecurringServicesSummaryPage from "./pages/recurringServices/RecurringServicesSummaryPage";
import ServiceLineSummaryPage from "./pages/recurringServices/ServiceLineSummaryPage";
import SingleLocationServiceLinePage from "./pages/recurringServices/SingleLocationServiceLinePage";
import ClientReportingPageContainer from "./pages/reporting/ClientReportingPageContainer";
import VendorsPage from "./pages/vendors/VendorsPage";
import StandardItemsPageView from "./pages/standardItems/StandardItemsPageView";

const userService = new UserService();
const clientService = new ClientService();
const authTokenManager = new AuthTokenManager();
const utilityServiceClass = new UtilityServiceClass();

const MainGrid = styled.div`
    display: grid; 
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;    
    grid-template-columns: 229px auto;
    grid-template-rows: 4em auto 8em;
    grid-template-areas:
    "sidebar header"
    "sidebar main"
    "sidebar footer";  
    
    @media print {
        grid-template-areas:
        "main main"
        "main main"
        "main main";
    }
    
    @media only screen and (max-width: 900px) {
        grid-template-areas:
        "header header"
        "main main"
        "footer footer";
    }  
`;

const MainOverlay = styled.div`
    background-color: rgba(0,0,0,0.5);
    grid-area: main;
    z-index: 10;
    cursor: pointer;
    display: none;

    @media only screen and (max-width: 900px) {
        display: ${props => props.sidebarIsExpanded ? 'block' : 'none'};
    }
`;
const HeaderOverlay = styled(MainOverlay)`
    grid-area: header;
`;
const FooterOverlay = styled(MainOverlay)`
    grid-area: footer;
`;

class LoggedInNavigator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            client: {},
            userRole: null,
            clientLocations: [],
            loading: true,
            marketingParams: props.marketingParams,
            sidebarIsExpanded: false,
            showCampaigns: true
        };

        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.initialize('UA-84078736-1', {
                gaOptions: {
                    userId: authTokenManager.getUserId()
                }
            });
        }
        HttpHandler.onUnauthorized = () => { this.logout() };

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!ValueIsSet(this.state.marketingParams) && ValueIsSet(nextProps.marketingParams))
            this.state.marketingParams = nextProps.marketingParams;

        return true;
    }

    componentDidMount() {
        let _this = this;

        if (ValueIsSet(config.HOTJAR_SITE_ID) && ValueIsSet(config.HOTJAR_SITE_VERSION)) {
            hotjar.initialize(config.HOTJAR_SITE_ID, config.HOTJAR_SITE_VERSION);
        }

        userService.findOne(authTokenManager.getUserId()).then(function (data) {
            let user = data;
            let userRole;
            let isAccountant = false;
            let clientLocations = [];

            if (user) {
                let clientId;
                if (user.clientRoles !== null) {
                    clientId = user.clientRoles[0].client.id;
                    isAccountant = user.clientRoles[0].isAccountant;
                    userRole = ClientUserRole.ADMIN;
                } else {
                    clientId = user.clientLocationRoles[0].clientId;
                    let isRegionalManager = false;
                    clientLocations = user.clientLocationRoles.map(function(role) {
                        if (role.role === ClientUserRole.REGIONAL_MANAGER) {
                            isRegionalManager = true;
                        }
                        return role.clientLocation;
                    });
                    if (isRegionalManager) {
                        userRole = ClientUserRole.REGIONAL_MANAGER;
                    } else {
                        userRole = ClientUserRole.LOCAL_MANAGER;
                    }
                }

                clientService.getClientInitialSummary(clientId).then((data) => {
                    let client = data;

                    if (ValueIsSet(user.companyLogo))
                        Branding.logoWithText = user.companyLogo;
                    else
                        Branding.logoWithText = require('./assets/logo-with-text.png');

                    if (ValueIsSet(user.companyLogoHeight))
                        Branding.logoWithTextHeight = user.companyLogoHeight;
                    else
                        Branding.logoWithTextHeight = '1.5em';

                    if (userRole === ClientUserRole.ADMIN) {
                        clientLocations = client.clientLocations;
                    }

                    let showCampaigns = _this.getShowCampaigns(client.configurationSettings, userRole);


                    _this.setState({user, userRole, clientLocations, client, loading: false, showCampaigns});

                    if(isAccountant){
                        _this.props.history.push('/invoices');
                    }

                }).catch(function (error) {
                    if (error.response && error.response.data)
                        console.log(error.response.data);
                    else
                        console.log(`${error}`);
                });

            } else {
                _this.setState({user, loading: false});
            }

        }).catch(function (error) {
            if (error.response && error.response.data)
                console.log(error.response.data);
            else
                console.log(`${error}`);
        });
    }

    logout = () => {
        let {onLogout, history} = this.props;
        let authType = authTokenManager.getAuthType();

        authTokenManager.clearAuthData();
        localStorage.setItem(LocalStorageKeys.BASE_API_URL, process.env.REACT_APP_ENV);
        localStorage.removeItem(LocalStorageKeys.REQUESTS_PAGE_SEARCH_PARAMETERS);
        if(authType === OAuthProvider.AUTH0) {
            utilityServiceClass.logUserOutOfAuth0();
        } else {
            onLogout();
            history.push(GlobalRoutes.APP);
            window.location.reload();
        }
    };

    expandSidebar = (expand) => {
        this.setState({sidebarIsExpanded: expand});
    };

    getShowCampaigns = (configurationSettings, userRole) => {
        switch (userRole) {
            case ClientUserRole.ADMIN:
                return configurationSettings.showCampaignsToAdmins;
            case ClientUserRole.REGIONAL_MANAGER:
                return configurationSettings.showCampaignsToRegionalUsers;
            case ClientUserRole.LOCAL_MANAGER:
                return configurationSettings.showCampaignsToLocalUsers;
            default:
                console.error("invalid userRole: ", userRole);
                return true;
        }
    };

    render() {
        let {loading, user, userRole, clientLocations, client, marketingParams, sidebarIsExpanded, showCampaigns} = this.state;
        let {expandSidebar} = this;

        let isAdmin = !!user.clientRoles;
        let userType = 'clientAll';

        ReactGA.pageview(window.location.pathname + this.props.history.location.pathname + this.props.history.location.search);

        if (user.companyType === 'Dry Cleaning Only')
            userType = 'dryCleaning';
        else if (user.companyType === 'Non-dry cleaning only')
            userType = 'nonDryCleaning';

        const intercomAppId = config.INTERCOM_ID;
        let intercomUser = {
            app_id:  intercomAppId,
            name:    user.firstName + " " + user.lastName,
            user_id: user.id,
        };

        if(ValueIsSet(client) && ValueIsSet(client.configurationSettings)) {
            let dashboard = getDashboardType(client.serviceLineConfigurationType, client.configurationSettings.dashboardType);

            intercomUser = {
                accountStatus: client.isPaused ? 'Paused' : 'Active',
                app_id:        intercomAppId,
                dashboard:     dashboard,
                email:         user.email,
                location:      user.clientLocationRoles ? user.clientLocationRoles[0].clientLocation.name : null,
                logo:          client.nickname,
                name:          user.firstName + " " + user.lastName,
                onboarding:    client.intercomOnboarding,
                user_id:       user.id,
                userRole:      user.clientLocationRoles ?
                                    user.clientLocationRoles.find(clr => clr.role === "Regional Manager") ?
                                        "Regional Manager" : user.clientLocationRoles[0].role : user.clientRoles ?
                                        user.clientRoles[0].role : null,
                vendor:        false,
                serviceLines:  client.selectedServiceLines ? client.selectedServiceLines.map(serviceLine => serviceLine.name).join() : null
            };
        }

        let homepage = (history, match) => {
            let clientDashboardType = client.configurationSettings.dashboardType;

            if (clientDashboardType === ClientDashboardType.BURBERRY) {
                return (
                    <LegacyPageContainer>
                        <BurberryLandingPage user={this.state.user}/>
                    </LegacyPageContainer>
                );
            } else if (clientDashboardType === ClientDashboardType.HUGO_BOSS || clientDashboardType === ClientDashboardType.INTERMIX) {
                return (
                    <LegacyPageContainer>
                        <BurberryDashboard user={this.state.user} client={this.state.client}/>
                    </LegacyPageContainer>
                );
            } else if ((userType) === 'dryCleaning') {
                return (
                    <LegacyPageContainer>
                        <TicketDashboardPage history={history} match={match} user={this.state.user} client={this.state.client}/>
                    </LegacyPageContainer>
                );
            } else {
                return (
                    <DashboardPage user={this.state.user} storeSignOffEnabled={this.state.client.storeSignOffEnabled} clientConfigurations={this.state.client.configurationSettings}/>
                )
            }
        };

        if (!loading) {
            return (
                <GlobalContext.Provider value={{
                    client,
                    user,
                    userRole,
                    clientLocations,
                    clientDashboardType: ValueIsSet(client.configurationSettings) ? client.configurationSettings.dashboardType : null,
                    internalNotesConfig: ValueIsSet(client.configurationSettings) ? client.configurationSettings.internalNotesConfig : InternalNotesType.NONE,
                    marketingParams,
                    showCampaigns
                }}>
                    <IntlProvider locale="en">
                        <div>
                            {config.USE_INTERCOM ? <Intercom appID={intercomAppId} {...intercomUser} /> : null}
                            <Router>
                                <div className="app">
                                    <MobileAppRedirectModal ticketingOnly={client.serviceLineConfigurationType === ClientType.DRY_CLEANING_ONLY}
                                                            userId={user.id}/>
                                    <FeedbackRequestsModal/>
                                    <MainGrid>
                                        {user.isPaused ? null:
                                            <Fragment>
                                                <HeaderOverlay onClick={() => expandSidebar(false)}  sidebarIsExpanded={sidebarIsExpanded}/>
                                                <TopMenu onLogout={this.logout} expandSidebar={expandSidebar} clientName={this.state.client.officialName} client={this.state.client}/>
                                                <Sidebar sidebarIsExpanded={sidebarIsExpanded} expandSidebar={expandSidebar} type={userType} clientConfig={client.configurationSettings} activeContract={client.activeContract} isCorporateClient={client.isCorporateClient} client={client} onLogout={this.logout}/>
                                                <MainOverlay onClick={() => expandSidebar(false)} sidebarIsExpanded={sidebarIsExpanded}/>
                                            </Fragment>
                                        }
                                        <div style={{gridArea:'main'}}>
                                            <main>
                                                {/* Invoices */}
                                                {isAdmin ? <div>
                                                    <Route exact path="/invoices" render={() => (
                                                        <LegacyPageContainer>
                                                            <InvoicesOverview/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    <Route path="/invoices/:id" render={({match}) => (
                                                        <LegacyPageContainer>
                                                            <SingleInvoice match={match}/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                </div> : null}

                                                {/* Logout Page */}
                                                <Route path="/logout" render={ this.logout }/>

                                                {/* Landing Pages*/}
                                                {user.isPaused ?
                                                    <Route path="/" render={() => (
                                                        <LegacyPageContainer>
                                                            <DelinquentClientRedirect isAdministrator={user.isClientAdmin}/>
                                                        </LegacyPageContainer>
                                                        )}/>
                                                :
                                                <Fragment>
                                                    <Route exact path="/"
                                                           render={({history, match}) => homepage(history, match)}/>

                                                    <Route exact path="/standard-items"
                                                           render={() => (
                                                               <LegacyPageContainer>
                                                                   <StandardItemsPageView client={this.state.client}/>
                                                               </LegacyPageContainer>
                                                           )}/>

                                                    <Route exact path="/vendors"
                                                           render={() => (
                                                               <LegacyPageContainer>
                                                                   <VendorsPage client={client}/>
                                                               </LegacyPageContainer>
                                                           )}/>

                                                    {/* OLD DASHBOARD, NOW CALENDAR */}
                                                    <Route exact path="/calendar"
                                                           render={() => (
                                                               <LegacyPageContainer>
                                                                   <CalendarPage user={this.state.user} storeSignOffEnabled={this.state.client.storeSignOffEnabled} clientConfigurations={this.state.client.configurationSettings}/>
                                                               </LegacyPageContainer>
                                                           )}/>

                                                    <Route exact path="/calendar/listView"
                                                           render={() => (
                                                               <LegacyPageContainer>
                                                                   <CalendarPage storeSignOffEnabled={this.state.client.storeSignOffEnabled} user={this.state.user} clientConfigurations={this.state.client.configurationSettings} initialTab={1}/>
                                                               </LegacyPageContainer>
                                                           )}/>

                                                    <Route exact path="/calendar/listView/initialTab/:initialTab"
                                                           render={({match})=> (
                                                               <LegacyPageContainer>
                                                                   <CalendarPage storeSignOffEnabled={this.state.client.storeSignOffEnabled} user={this.state.user} clientConfigurations={this.state.client.configurationSettings} initialTab={1} initialClientListViewTab={parseInt(match.params.initialTab)}/>
                                                               </LegacyPageContainer>
                                                           )}/>
                                                    <Route exact path="/contract"
                                                           render={() => (
                                                               <LegacyPageContainer>
                                                                   <ContractPage/>
                                                               </LegacyPageContainer>
                                                               )}/>

                                                    <Route exact path="/ticket-dashboard"
                                                           render={({history, match}) => (
                                                               <LegacyPageContainer>
                                                                   <TicketDashboardPage history={history} match={match} user={this.state.user} client={this.state.client}/>
                                                               </LegacyPageContainer>
                                                           )}/>

                                                    {/* PICKUPS */}
                                                    <Route exact path="/pickups/:id" render={({history, match}) => (
                                                        <LegacyPageContainer>
                                                            <SinglePickupPage history={history} match={match} user={this.state.user} client={this.state.client}/>
                                                        </LegacyPageContainer>
                                                    )}/>

                                                    {/* TICKETS */}
                                                    <Route exact path="/pickups/:id/ticket/new"
                                                           render={({match, history}) => (
                                                               <LegacyPageContainer>
                                                                   <NewTicketPage history={history} match={match} user={this.state.user} client={this.state.client}/>
                                                               </LegacyPageContainer>
                                                           )}/>

                                                    {/* PRINT TICKETS */}
                                                    <Route exact path="/printTickets" render={({match, history}) => (
                                                        <LegacyPageContainer>
                                                            <PrintTicketsPage history={history} match={match} user={this.state.user}/>
                                                        </LegacyPageContainer>
                                                    )}/>


                                                    {/* LOCATIONS */}
                                                    <Route exact path="/locations"
                                                           render={()=> (
                                                               <LegacyPageContainer>
                                                                   <LocationsPage client={this.state.client} user={this.state.user}/>
                                                               </LegacyPageContainer>
                                                           )}/>
                                                    <Route path="/locations/:id"
                                                           render={({match}) => (
                                                               <LegacyPageContainer>
                                                                   <ClientLocationProfilePage
                                                                       locationId={match.params.id}
                                                                       clientId={this.state.client.id}
                                                                       userIsAdmin={isAdmin}
                                                                       clientDashboardType={this.state.client.configurationSettings.dashboardType}
                                                                       isSelfService={this.state.client.isSelfService}
                                                                   />
                                                               </LegacyPageContainer>
                                                           )}/>

                                                    {isAdmin ? <div>
                                                        <Route path="/new-location"
                                                               render={({match, history}) => (
                                                                   <LegacyPageContainer>
                                                                       <NewLocationPage history={history}
                                                                                        match={match}
                                                                                        user={this.state.user}
                                                                                        client={this.state.client}
                                                                                        requireUsersForNewLocations={this.state.client.configurationSettings.requireUsersForNewLocations}
                                                                                        setLocationCount={this.setLocationCount}/>
                                                                   </LegacyPageContainer>
                                                               )}/>
                                                    </div> : null}

                                                    {/* Schedule Changes */}
                                                    <Route exact path="/scheduleChanges" render={() => (
                                                        <LegacyPageContainer>
                                                            <ScheduleChanges/>
                                                        </LegacyPageContainer>
                                                    )}/>

                                                    {/* Requests */}
                                                    <Route exact path="/requests/filtered/:filter" render={({match, history}) => (
                                                        <RequestsPage history={history} match={match} client={this.state.client} user={this.state.user}/>
                                                    )}/>

                                                    <Route exact path="/requests" render={({match, history}) => (
                                                        <RequestsPage history={history} match={match} client={this.state.client} user={this.state.user}/>
                                                    )}/>

                                                    <Route exact path="/requests/:id" render={({match, history}) => (
                                                        <SingleRequestPage history={history} match={match} client={this.state.client} user={this.state.user}/>
                                                    )}/>

                                                    <Route exact path={"/recurringServices"} render={({match, history}) => (
                                                        <LegacyPageContainer>
                                                            <RecurringServicesSummaryPage history={history} match={match} client={this.state.client} user={this.state.user}/>
                                                        </LegacyPageContainer>
                                                    )}/>

                                                    <Route exact path={"/recurringServices/serviceLine/:serviceLineId"} render={({match, history}) => (
                                                        <LegacyPageContainer>
                                                            <ServiceLineSummaryPage history={history} match={match} client={this.state.client} user={this.state.user}/>
                                                        </LegacyPageContainer>
                                                    )}/>

                                                    <Route path={"/recurringServices/serviceLine/:serviceLineId/location/:locationId"} render={({match, history}) => (
                                                        <LegacyPageContainer>
                                                            <SingleLocationServiceLinePage history={history} match={match} client={this.state.client} user={this.state.user}/>
                                                        </LegacyPageContainer>
                                                    )}/>

                                                    <Route path="/new-request" render={({match, history}) => (
                                                        <LegacyPageContainer>
                                                            {
                                                                this.state.client.configurationSettings.dashboardType === ClientDashboardType.KENDRA_SCOTT ?
                                                                    <KendraScottNewRequestPage history={history} match={match} user={this.state.user} client={this.state.client}/>:
                                                                    <NewRequestPage history={history} match={match} user={this.state.user} client={this.state.client}/>
                                                            }
                                                        </LegacyPageContainer>
                                                    )}/>


                                                    {/* Users */}
                                                    {isAdmin ?
                                                        <Fragment>
                                                                <Route exact path="/users" render={() => (
                                                                    <LegacyPageContainer>
                                                                        <UsersPage/>
                                                                    </LegacyPageContainer>
                                                                )}/>
                                                                <Route path="/users/:id" render={({match, history}) => (
                                                                    <LegacyPageContainer>
                                                                        <SingleUserPage history={history} match={match}/>
                                                                    </LegacyPageContainer>
                                                                )}/>
                                                                <Route path="/new-user" render={ () => <NewUserPage client={this.state.client} setLocationCount={this.setLocationCount}/> }/>
                                                        </Fragment>
                                                        : null}


                                                    {/* Account */}
                                                    <Route exact path="/account" render={() => (
                                                        <LegacyPageContainer>
                                                            <ClientAccountPage client={this.state.client}/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    <Route path="/account/changePassword" render={() => (
                                                        <LegacyPageContainer>
                                                            <ClientAccountPage client={this.state.client}/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    <Route path="/unsubscribe/:notificationSetting" render={({match,history}) => (
                                                        <LegacyPageContainer>
                                                            <UnsubscribePage match={match} history={history} user={this.state.user} />
                                                        </LegacyPageContainer>
                                                    )} />

                                                    {/* Reporting */}
                                                    {isAdmin ? <Route path="/reporting" render={() => (
                                                        <LegacyPageContainer>
                                                            <ClientReportingPageContainer/>
                                                        </LegacyPageContainer>
                                                    )}/> : null}

                                                    {/* Approval Settings */}
                                                    <Route path="/approval-settings" render={() => (
                                                        <LegacyPageContainer>
                                                            <ClientApprovalSettings isAdmin={isAdmin}/>
                                                        </LegacyPageContainer>
                                                    )}/>

                                                    {/* Support */}
                                                    <Route path="/support" render={() => (
                                                        <LegacyPageContainer>
                                                            <ClientSupportPage client={this.state.client}/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    <Route path="/how-to" render={() => (
                                                        <LegacyPageContainer>
                                                            <ClientHowToPage/>
                                                        </LegacyPageContainer>
                                                    )}/>

                                                    {/* Burberry */}
                                                    <Route path="/ticketing/landing" render={() => (
                                                        <LegacyPageContainer>
                                                            <BurberryLandingPage user={this.state.user}/>
                                                        </LegacyPageContainer>
                                                    )} />
                                                    <Route path="/ticketing/ticket-dashboard" render={() => (
                                                        <LegacyPageContainer>
                                                            <BurberryDashboard user={this.state.user} client={this.state.client}/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    <Route path="/ticketing/bulk-shipments" render={() => (
                                                        <LegacyPageContainer>
                                                            <BurberryBulkShipmentDashboard user={this.state.user} />
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    <Route path="/ticketing/vendor-dashboard/:id" render={({match, history}) => (
                                                        <LegacyPageContainer>
                                                            <BurberryBulkShipmentList match={match} history={history} user={this.state.user} />
                                                        </LegacyPageContainer>
                                                    )} />
                                                    <Route path="/ticketing/new-ticket" render={() => (
                                                        <LegacyPageContainer>
                                                            <BurberryNewTicketPage user={this.state.user}/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    <Route path="/single-ticket/:obligationId/:ticketId" render={(match) =>(
                                                        <LegacyPageContainer>
                                                            <SingleTicketPage match={match} user={this.state.user} client={this.state.client}/>
                                                        </LegacyPageContainer>
                                                    )}/>
                                                    {/* Miscellaneous */}
                                                    <Route path="/logout" render={ this.logout }/>
                                            </Fragment>}
                                        </main>
                                    </div>
                                    {user.isPaused ? null :
                                        <Fragment>
                                            <FooterOverlay onClick={() => expandSidebar(false)} sidebarIsExpanded={sidebarIsExpanded}/>
                                            <div style={{gridArea:'footer'}}>
                                                <Footer  serviceLineConfigurationType={client.serviceLineConfigurationType}/>
                                            </div>
                                        </Fragment>
                                    }
                                    </MainGrid>
                                </div>
                            </Router>
                        </div>
                    </IntlProvider>
                </GlobalContext.Provider>
            )
        } else {
            return (
                <FullWidthPageContainer>
                    <Loader/>
                </FullWidthPageContainer>
            )
        }
    }
}

LoggedInNavigator.propTypes = {
    onLogout: PropTypes.func.isRequired
};

export default withRouter(LoggedInNavigator);