import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {merge} from "lodash";

import AsyncGraphQLServiceClass from "../../../../services/graphql/AsyncGraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {DeepCopy, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "../../../../custom-prop-types/CustomPropTypes";
import RecurrenceType from "../../../../enums/api/task/RecurrenceType";
import ReschedulingView from "./ReschedulingView";
import ScheduleStatus from "../../../../enums/api/proposal/ScheduleStatus";
import ScheduleHandlerService from "../../../../services/api/ScheduleHandlerService";
import ChangeRequestService from "../../../../services/api/ChangeRequestService";

const graphQLServiceClass = new AsyncGraphQLServiceClass();
const changeRequestService = new ChangeRequestService();
const scheduleHandlerService = new ScheduleHandlerService();

class Rescheduling extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: ValueIsSet(props.request) ? props.loading : true,
            creatorType: null,
            duration: null,
            frequency: null,
            pendingChangeRequestId: null,
            schedule: null,
            scheduleHandler: null,
            vendorLocation: null,
            useTicketing: null,
        };

        if (ValueIsSet(props.request)) {
            this.state = merge(this.state, Rescheduling.getStateFromRequest(props.request));
        }

    }

    componentDidMount = async () => {
        const {requestId} = this.props;

        if (requestId) {
            let data = await this.getRequestFromId(requestId);
            this.setState({loading: false,  ...Rescheduling.getStateFromRequest(data)});
        }
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevProps.requestId !== this.props.requestId &&
            this.props.requestId &&
            !ValueIsSet(this.props.request)) {

            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, ...Rescheduling.getStateFromRequest(data)});
        }

        if (prevProps.loading && !this.props.loading && this.props.requestId) {
            this.setState({loading: true});
            let data = await this.getRequestFromId(this.props.requestId);
            this.setState({loading: false, ...Rescheduling.getStateFromRequest(data)});
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};

        if (props.request && !props.loading) {
            merge(newState, this.getStateFromRequest(DeepCopy(props.request)))
        }

        return newState;
    };

    getRequestFromId = async (requestId) => {

        return await graphQLServiceClass.findOneById(
            requestId,
            GraphQLObjectType.REQUEST,
            ScheduledGraphQLString)
    };

    static getStateFromRequest = (request) => {
        let newState = {};

        if (request.associatedObligation) {
            newState.duration = request.associatedObligation.duration;
            newState.schedule = request.associatedObligation.schedule;

            if (request.associatedObligation.pendingChangeRequest) {
                newState.scheduleHandler = request.associatedObligation.pendingChangeRequest.scheduleHandler
                newState.pendingChangeRequestId = request.associatedObligation.pendingChangeRequest.id;
                newState.creatorType = request.associatedObligation.pendingChangeRequest.creatorType;
            }
            newState.vendorLocation = request.associatedObligation.vendorLocation;
        } else if (request.associatedObligationTemplate) {
            newState.duration = request.associatedObligationTemplate.recurringScheduleTemplate.duration;
            newState.frequency = request.associatedObligationTemplate.recurringScheduleTemplate.frequency;
            newState.schedule = request.associatedObligationTemplate.schedule;

            if (request.associatedObligationTemplate.pendingChangeRequest) {
                newState.scheduleHandler = request.associatedObligationTemplate.pendingChangeRequest.scheduleHandler
                newState.pendingChangeRequestId = request.associatedObligationTemplate.pendingChangeRequest.id;
                newState.creatorType = request.associatedObligationTemplate.pendingChangeRequest.creatorType;
            }
            newState.vendorLocation = request.associatedObligationTemplate.vendorLocation;
        }
        newState.useTicketing = request.serviceLine.useTicketing;

        return newState;
    };

    acceptChangeRequest = () => {
        const {pendingChangeRequestId} = this.state;
        const {onAcceptChangeRequest, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        if(pendingChangeRequestId) {
            changeRequestService.accept(pendingChangeRequestId)
                .then(() => onAcceptChangeRequest())
        }
    };

    proposeSchedules = (schedules) => {
        const {scheduleHandler} = this.state;
        const {onProposeSchedule, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        if (scheduleHandler) {
            let proposeScheduleParams = {
                scheduleHandlerId: scheduleHandler.id,
                schedules: schedules
            }

            scheduleHandlerService
                .makeCounterOffer(proposeScheduleParams)
                .then(() => {
                    onProposeSchedule();
                });
        }
    };

    cancelChangeRequest = () => {
        const {pendingChangeRequestId} = this.state;
        const {onCancelChangeRequest, startLoad} = this.props;

        if (startLoad) {
            startLoad();
        }

        if(pendingChangeRequestId) {
            changeRequestService.cancel(pendingChangeRequestId)
                .then(() => onCancelChangeRequest())
        }
    };

    render() {
        const {acceptChangeRequest, proposeSchedules, cancelChangeRequest} = this;
        const {
            creatorType,
            duration,
            loading,
            frequency,
            schedule,
            scheduleHandler,
            vendorLocation,
            useTicketing,
        } = this.state;
        const {user} = this.props;

        return (
            <ReschedulingView
                acceptChangeRequest={acceptChangeRequest}
                cancelChangeRequest={cancelChangeRequest}
                creatorType={creatorType}
                duration={duration}
                frequency={frequency}
                loading={loading || this.props.loading}
                proposeSchedules={proposeSchedules}
                schedule={schedule}
                scheduleHandler={scheduleHandler}
                user={user}
                useTicketing={useTicketing}
                vendorLocation={vendorLocation}/>
        )
    }
}

const SchedulePropTypes = {
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        duration: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    })
}

const ObligationScheduledPropTypes = {
    ...SchedulePropTypes,
    pendingChangeRequest: PropTypes.shape({
        id: PropTypes.number,
        creatorType: PropTypes.string,
        scheduleHandler: PropTypes.shape({
            openScheduleOptions: PropTypes.arrayOf(PropTypes.shape( {
                id: PropTypes.number,
                isExpired: PropTypes.bool,
                ...SchedulePropTypes,
                status: CustomPropTypes.enum(ScheduleStatus)
            })),
        }),
    }),
    vendorLocation: PropTypes.shape({
        vendor: PropTypes.shape({
            officialName: PropTypes.string
        })
    }),
};

Rescheduling.propTypes = {
    loading: PropTypes.bool,
    onAcceptChangeRequest: PropTypes.func.isRequired,
    onCancelChangeRequest: PropTypes.func.isRequired,
    onProposeSchedule: PropTypes.func.isRequired,
    request: PropTypes.shape({
        serviceLine: PropTypes.shape({
            useTicketing: PropTypes.bool
        }),
        associatedObligation: PropTypes.shape({
            duration: PropTypes.number,
            ...ObligationScheduledPropTypes,
        }),
        associatedObligationTemplate: PropTypes.shape({
            recurringScheduleTemplate: PropTypes.shape({
                duration: PropTypes.number,
                frequency: PropTypes.shape({
                    recurrenceCount: PropTypes.number,
                    recurrenceType: CustomPropTypes.enum(RecurrenceType),
                    repeatEvery: PropTypes.number,
                })
            }),
            ...ObligationScheduledPropTypes,
        })
    }),
    requestId: PropTypes.number,
    startLoad: PropTypes.func
};

const ObligationScheduledFields = `
    schedule {
        arrivalEndTime
        arrivalStartTime
        date
        dayOfMonth
        daysOfWeek
        duration
        recurrenceType
        repeatEvery
        returnDate
        scheduleDows {
            dayOfWeek
            arrivalStartTime
            arrivalEndTime
        }
    }
    pendingChangeRequest {
        id
        creatorType 
        scheduleHandler {
            id
            openScheduleOptions {
                id
                isExpired
                schedule {
                    arrivalEndTime
                    arrivalStartTime
                    date
                    dayOfMonth
                    daysOfWeek
                    duration
                    recurrenceType
                    repeatEvery
                    returnDate
                }
                status
            }
        }
    }
    vendorLocation {
        vendor {
            officialName
        }
    }
`;

const ScheduledGraphQLString = `
    serviceLine {
        useTicketing
    }
    associatedObligation {
        id
        duration
        ${ObligationScheduledFields}
    }
    associatedObligationTemplate {
        id
        recurringScheduleTemplate {
            duration
            frequency {
                recurrenceCount
                recurrenceType
                repeatEvery
            }
        }
        ${ObligationScheduledFields}
    }
`;

export default Rescheduling;