/* eslint-disable */

import React, {Component} from 'react';
import ReactHtmlParser from 'react-html-parser';

import UserService from 'worksmith/services/api/UserService';
import UtilityServiceClass from "../../components/http/UtilityServiceClass";
const userService = new UserService();
const UtilityService = new UtilityServiceClass();

class ClientHowToPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: {},
            howToGuide: {}
        }
    }

    componentDidMount(){
        let _this = this;
        userService.findOne(authTokenManager.getUserId()).then(function(data){
            let user = data;
            _this.setState({ user })
        });

        UtilityService.howTo().then(function(data){
            let howToGuide = data.article;
            _this.setState({howToGuide});
        })
    }

    render(){
        let {user, howToGuide} = this.state;
        return(
            <div>
                <div className='ws-section'>
                    <h1 className='page-header'>How-to</h1>
                </div>

                <section className="ws-section">
                    {user.companyType !== 'Dry Cleaning Only' ? <div>{ ReactHtmlParser(howToGuide.body) }</div> : null }

                    {user.companyType === 'Dry Cleaning Only' ? <div class="row">
                        <div class="col-lg-9">
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-01.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-03.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-04.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-05.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-06.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-07.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-08.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-09.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-10.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-11.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-12.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-13.png"
                                     style="max-width: 100%;"/>
                            </div>
                            <div class="wrapper wrapper-content animated fadeInRight">
                                <img alt='how-to' src="https://cdn.worksmith.com/training/clients/dca/Worksmith+DC%26A+Training+Manual-14.png"
                                     style="max-width: 100%;"/>
                            </div>
                        </div>
                    </div> : null }
                </section>
            </div>
        )
    }
}

export default ClientHowToPage;