import React from 'react'

import Checkbox from "worksmith/components/Checkbox/Checkbox";
import CustomScheduleDialog from "worksmith/composite-components/ScheduleOptionsForm/CustomScheduleDialog";
import Grid from "worksmith/components/Grid/Grid";
import {TextAlign} from "worksmith/enums/CSSEnums";
import Tooltip, {TooltipPlacement} from "worksmith/components/Tooltip/Tooltip";
import PropTypes from "prop-types";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";

const CustomScheduleCheckBox = ({
                                       addingRecurringScheduleJob,
                                       customScheduleChecked,
                                       customScheduleOpen,
                                       customScheduleSaved,
                                       date,
                                       dayOfMonth,
                                       daysOfWeek,
                                       daysOfWeekNumber,
                                       handleAddScheduleChange,
                                       handleCustomScheduleOpen,
                                       handleCustomScheduleChecked,
                                       handleCustomScheduleSave,
                                       handleCustomScheduleWarningDialogOpen,
                                       onChange,
                                       onScheduleChange,
                                       recurrenceType,
                                       repeatEvery,
                                       schedule,
                                       showCustomScheduleOption,
}) => {


    const handleCheckBoxClick = () => {
        if (customScheduleSaved) {
            handleCustomScheduleWarningDialogOpen(true);
        } else {
            handleCustomScheduleChecked(!customScheduleChecked);
            handleCustomScheduleOpen(!customScheduleOpen);
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <Tooltip placement={TooltipPlacement.TOP} title={'Custom schedule available for services happening weekly and more than once a week with a start date selected.'}>
                    <Checkbox checked={customScheduleChecked}
                              disabled={!showCustomScheduleOption}
                              label={"Custom Schedule"}
                              onChange={handleCheckBoxClick}
                              primary
                              onlyChangeFromProp={customScheduleSaved}
                    />
                </Tooltip>
            </Grid>
            {customScheduleOpen &&
                <CustomScheduleDialog
                      addingRecurringScheduleJob={addingRecurringScheduleJob}
                      customScheduleSaved={customScheduleSaved}
                      cancelButtonText={"Go Back"}
                      date={date}
                      dayOfMonth={dayOfMonth}
                      daysOfWeek={daysOfWeek}
                      daysOfWeekNumber={daysOfWeekNumber}
                      handleAddScheduleChange={handleAddScheduleChange}
                      handleCustomScheduleOpen={handleCustomScheduleOpen}
                      handleCustomScheduleChecked={handleCustomScheduleChecked}
                      handleCustomScheduleSave={handleCustomScheduleSave}
                      onChange={onChange}
                      onScheduleChange={onScheduleChange}
                      open={customScheduleOpen}
                      recurrenceType={recurrenceType}
                      repeatEvery={repeatEvery}
                      schedule={schedule}
                      showCustomScheduleOption={customScheduleOpen}
                      submitButtonText={"Save"}
                />
            }
        </>
    )
};

CustomScheduleCheckBox.propTypes = {
    addingRecurringScheduleJob: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    customScheduleSaved: PropTypes.bool,
    date: PropTypes.string,
    dayOfMonth: PropTypes.string,
    daysOfWeek: PropTypes.array,
    daysOfWeekNumber: PropTypes.array,
    handleAddScheduleChange: PropTypes.func,
    handleCustomScheduleOpen: PropTypes.func,
    handleCustomScheduleSave: PropTypes.func,
    onScheduleChange: PropTypes.func,
    open: PropTypes.bool,
    recurrenceType: PropTypes.string,
    repeatEvery: PropTypes.number,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string.isRequired,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string
    }),
    showCustomScheduleOption: PropTypes.bool,
    submitButtonText: PropTypes.string,
};

export default CustomScheduleCheckBox;