import React from 'react';
import PropTypes from 'prop-types';

import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import CircularProgress from "worksmith/components/Loader/CircularProgress";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import IconButton from "worksmith/components/Button/IconButton";
import {IconType} from "worksmith/enums/MaterialEnums";
import IncrementButton from "worksmith/components/Button/IncrementButton";
import Text, {TextDisplay, TextVariant} from "worksmith/components/Text/Text";
import TextField, {TextFieldType} from "worksmith/components/Inputs/TextField/TextField";

const LocationSpecificationsForm =
    ({
         locationSpecificationsFormFields,
         handleAddFloor,
         handleChange,
         handleRemoveFloor,
         handleUpdateFloor,
         isSubmitting,
         submitLocationSpecifications,
         toggleEdit
    }) => {
    const floors = locationSpecificationsFormFields.clientLocationFloors.map((floor, idx) => (
        <Grid spacing={3} container item key={`floor - ${idx}`} xs={12} md={8} margin={'16px 0 0 0'}>
            <Grid item container justify={JustifyContent.SPACE_BETWEEN} alignItems={AlignItems.CENTER} xs={12}>
                <Text display={TextDisplay.INLINE} gutterBottom variant={TextVariant.H6}>Floor {idx + 1}</Text>
                {locationSpecificationsFormFields.clientLocationFloors.length > 1 && idx === locationSpecificationsFormFields.clientLocationFloors.length - 1 ?
                    <IconButton
                        onClick={() => handleRemoveFloor(idx)}
                        secondary
                        iconName={'Delete'}
                        size="medium" />
                    :
                    null
                }
            </Grid>
            <TextField
                fullWidth
                label={"Ceiling height (ft)"}
                name={"ceilingHeightFt"}
                onChange={(value) => handleChange(`ceilingHeightFt`, Number(value), idx)}
                type={TextFieldType.NUMBER}
                initialValue={floor.ceilingHeightFt}
            />
            <Grid container spacing={4} item xs={12} margin={'16px 0 0 0'}>
                <Grid item container xs={12}>
                    <Grid container item alignItems={AlignItems.CENTER} xs={6}>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>Bathrooms</Text>
                    </Grid>
                    <Grid container item xs={6} justify={JustifyContent.FLEX_END} alignItems={AlignItems.CENTER}>
                        <IncrementButton disabled={!floor.bathroomsCount} onClick={() => handleUpdateFloor(`bathroomsCount`, -1, idx)}/>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>{floor.bathroomsCount ? floor.bathroomsCount : "0"}</Text>
                        <IncrementButton add onClick={() => handleUpdateFloor(`bathroomsCount`, 1, idx)}/>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid container item alignItems={AlignItems.CENTER} xs={6}>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>Kitchens</Text>
                    </Grid>
                    <Grid container item xs={6} justify={JustifyContent.FLEX_END} alignItems={AlignItems.CENTER}>
                        <IncrementButton disabled={!floor.kitchensCount} onClick={() => handleUpdateFloor(`kitchensCount`, -1, idx)}/>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>{floor.kitchensCount ? floor.kitchensCount : "0"}</Text>
                        <IncrementButton add onClick={() => handleUpdateFloor(`kitchensCount`, 1, idx)}/>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid container item alignItems={AlignItems.CENTER} xs={6}>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>Fitting Rooms</Text>
                    </Grid>
                    <Grid container item xs={6} justify={JustifyContent.FLEX_END} alignItems={AlignItems.CENTER}>
                        <IncrementButton disabled={!floor.fittingRoomsCount} onClick={() => handleUpdateFloor(`fittingRoomsCount`, -1, idx)}/>
                        <Text display={TextDisplay.INLINE} variant={TextVariant.BODY_1}>{floor.fittingRoomsCount ? floor.fittingRoomsCount : "0"}</Text>
                        <IncrementButton add onClick={() => handleUpdateFloor(`fittingRoomsCount`, 1, idx)}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ))

    return (
        <Grid container spacing={3} alignItems={AlignItems.FLEX_START}>
            <Grid item  xs={12} md={6} alignItems={AlignItems.FLEX_START}>
                <Grid item xs={12} md={8}>
                    <TextField
                        fullWidth
                        label={"Location Sqft"}
                        name={"locationSquareFootage"}
                        onChange={(value) => handleChange('locationSquareFootage', Number(value))}
                        type={TextFieldType.NUMBER}
                        initialValue={locationSpecificationsFormFields.locationSquareFootage}
                    />
                </Grid>
                {floors}
                <Grid item xs={12} margin={'32px 0 0 0'}>
                    <Grid item margin={'0 0 0 32px'}>
                        <Button variant={ButtonVariant.TEXT} onClick={handleAddFloor} startIcon={<Icon name={IconType.ADD} />}>ADD ANOTHER FLOOR</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={6} alignItems={AlignItems.FLEX_START}>
                <Grid container item xs={12} md={8} margin={'-12px 0 0 0'}>
                    <Grid item>
                        <Text
                            gutterBottom
                            variant={TextVariant.BODY_1}
                        >
                            Is there a ladder on site?
                        </Text>
                    </Grid>
                    <Grid container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => handleChange('hasLadderOnSite', true)}
                                primary
                                variant={locationSpecificationsFormFields.hasLadderOnSite ? 'contained' : 'outlined'}
                            >
                                YES
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleChange('hasLadderOnSite', false)}
                                primary
                                variant={locationSpecificationsFormFields.hasLadderOnSite === false ? 'contained' : 'outlined'}
                            >
                                NO
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        locationSpecificationsFormFields.hasLadderOnSite ?
                            <Grid item xs={12} margin={'16px 0 0 -4px'}>
                                <TextField
                                    fullWidth
                                    label={"Ladder height (ft)"}
                                    name={"ladderHeightFt"}
                                    onChange={(value) => handleChange('ladderHeightFt', Number(value))}
                                    type={TextFieldType.NUMBER}
                                    initialValue={locationSpecificationsFormFields.ladderHeightFt}
                                />
                            </Grid>
                            :
                            null
                    }
                    <Grid item margin={'28px 0 0 0'}>
                        <Text
                            gutterBottom
                            variant={TextVariant.H6}
                        >
                            General Notes to Vendor
                        </Text>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            placeholder={'Please provide any additional notes that will help you vendor when they are on site'}
                            name={"vendorNotes"}
                            onChange={(value) => handleChange(`vendorNotes`, value)}
                            type={TextFieldType.TEXT}
                            initialValue={locationSpecificationsFormFields.vendorNotes}
                            displayCharacterCount
                            rows={6}
                            fullWidth
                            multiline
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={8} margin={'32px 0 0 0'}>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} onClick={toggleEdit}>CANCEL</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} primary onClick={submitLocationSpecifications}>{isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default LocationSpecificationsForm;

LocationSpecificationsForm.propTypes = {
    locationSpecificationsFormFields: PropTypes.shape({
        locationSquareFootage: PropTypes.number,
        hasLadderOnSite: PropTypes.bool,
        ladderHeightFt: PropTypes.number,
        clientLocationFloors: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            locationId: PropTypes.number.isRequired,
            level: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            ceilingHeightFt: PropTypes.number,
            bathroomsCount: PropTypes.number,
            kitchensCount: PropTypes.number,
            fittingRoomsCount: PropTypes.number,
            deleted: PropTypes.bool
        })),
    }),
    handleAddFloor: PropTypes.func,
    handleChange: PropTypes.func,
    handleRemoveFloor: PropTypes.func,
    handleUpdateFloor: PropTypes.func,
    isSubmitting: PropTypes.bool,
    submitLocationSpecifications: PropTypes.func,
    toggleEdit: PropTypes.func
}
