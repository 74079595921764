import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import Divider from "@mui/material/Divider";

import Grid from "worksmith/components/Grid/Grid";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import LineItemBreakdown from "worksmith/components/LineItem/LineItemBreakdown";
import BreakpointEnum from "worksmith/enums/BreakpointEnum";
import Currency from "worksmith/components/Currency/Currency.web";
import {withTheme} from "../Theme/ThemeProvider";
import {ToCurrency, ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const StyledLineItem = styled.div`
    margin-top: .7em;
`;

const StyledHeader = styled.div`
    margin-bottom: .7em;
`;

const StyledTopBlock = styled.div`
    margin-top: 1.5em;
`;

const LineItemBreakdownList = (props) => {
    const {additionalApproval, lineItems, showWholesale, showDivider, textColor, portalType} = props;

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(BreakpointEnum.XS));

    const totalRetail = lineItems.reduce((total, nextItem) => total + (nextItem.unitRetailPrice * nextItem.quantity), 0);
    const totalAdjustedRetail =  additionalApproval ? totalRetail + additionalApproval : totalRetail;

    const totalWholesale = lineItems.reduce((total, nextItem) => total + (nextItem.unitWholesalePrice * nextItem.quantity), 0);
    const totalAdjustedWholesale =  additionalApproval ? totalWholesale + additionalApproval : totalWholesale;


    return (

        <Fragment>
            {!isSmall ?
                <Fragment>
                    <StyledHeader>
                        <Grid container>
                            <Grid item xs={4}>
                                <Text variant={TextVariant.SUBTITLE_2} color={textColor}>Item</Text>
                            </Grid>
                            <Grid item xs={2}>
                                <Text variant={TextVariant.SUBTITLE_2} color={textColor}>Qty</Text>
                            </Grid>
                            <Grid item xs={3}>
                                <Text variant={TextVariant.SUBTITLE_2} color={textColor}>Unit Price</Text>
                            </Grid>
                            <Grid item xs={3}>
                                <Text variant={TextVariant.SUBTITLE_2} color={textColor}>Total Price</Text>
                            </Grid>
                        </Grid>
                    </StyledHeader>

                    {showDivider ? <Divider component={'hr'}/> : null}
                </Fragment>
                : null
            }

            {lineItems.map(lineItem => <StyledLineItem key={lineItem.id}>
                <LineItemBreakdown description={lineItem.description}
                                   lineItemType={lineItem.lineItemType}
                                   quantity={lineItem.quantity}
                                   unitRetailPrice={lineItem.unitRetailPrice}
                                   unitWholesalePrice={lineItem.unitWholesalePrice}
                                   showWholesale={showWholesale}
                                   textColor={textColor}
                                   portalType={portalType}
                />
            </StyledLineItem>)}


            <StyledLineItem>

                {showWholesale ?
                    <Grid container>
                        <Grid item xs={portalType === PortalType.ADMIN ? 8 : 12}></Grid>
                        <Grid item xs={portalType === PortalType.ADMIN ? 2 : 7}>
                            {portalType === PortalType.ADMIN &&
                                <StyledTopBlock>
                                    <Text bold variant={TextVariant.SUBTITLE_1} color={textColor}>Total Retail</Text>
                                </StyledTopBlock>
                            }
                        </Grid>
                        { portalType === PortalType.ADMIN &&
                            <Grid item xs={2}>
                                <StyledTopBlock>
                                    <Text variant={TextVariant.SUBTITLE_2} color={textColor}>
                                        <Currency amount={totalRetail}/> {totalAdjustedRetail !== totalRetail ?
                                        <Fragment>- <Currency amount={totalAdjustedRetail}/></Fragment> : null}
                                    </Text>
                                </StyledTopBlock>
                            </Grid>
                        }
                        { portalType === PortalType.ADMIN &&
                            <Grid item xs={8}></Grid>
                        }
                        <Grid item xs={2}>
                            <StyledTopBlock>
                                <Text bold variant={TextVariant.SUBTITLE_1} color={textColor}>Total Wholesale</Text>
                            </StyledTopBlock>
                        </Grid>
                        <Grid item xs={2}>
                            <StyledTopBlock>
                                <Text variant={TextVariant.SUBTITLE_2} color={textColor}>
                                    <Currency amount={totalWholesale}/> {totalAdjustedWholesale !== totalWholesale ?
                                    <Fragment>- <Currency amount={totalAdjustedWholesale}/></Fragment> : null}
                                </Text>
                            </StyledTopBlock>
                        </Grid>
                    </Grid>
                :   <Grid container>
                        <Grid item xs={12} sm={6}/>
                        <Grid item xs={6} sm={3}>
                            <Text variant={TextVariant.SUBTITLE_2} color={textColor}>
                                Estimated Total:
                            </Text>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Text variant={TextVariant.SUBTITLE_2} color={textColor}>
                                <Currency amount={totalRetail}/> {totalAdjustedRetail !== totalRetail ?<Fragment>- <Currency amount={totalAdjustedRetail}/></Fragment> : null}
                            </Text>
                        </Grid>
                    </Grid>
                }
            </StyledLineItem>

        </Fragment>
    )
};

LineItemBreakdownList.defaultProps = {
    showWholesale: false,
    showDivider: true
};

LineItemBreakdownList.propTypes = {
    additionalApproval: PropTypes.number,
    lineItems:          PropTypes.arrayOf(PropTypes.object).isRequired,
    showWholesale:      PropTypes.bool,
    showDivider:        PropTypes.bool,
    textColor: PropTypes.string,
    portalType: CustomPropTypes.enum(PortalType)
};

export default withTheme(LineItemBreakdownList);