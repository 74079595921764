import React from "react";

import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {DialogSize} from "worksmith/enums/DialogSize";
import PropTypes from "prop-types";
import RecurrenceType from "worksmith/enums/api/task/RecurrenceType";
import SelectedCustomScheduleList
    from "worksmith/composite-components/ScheduleOptionsForm/SelectedCustomScheduleList";
import Text, {LinkUnderline, TextColor} from "worksmith/components/Text/Text";
import {TextVariant} from "../../components/Text/Text";

const ViewCustomScheduleDialog = ({buttonText, handleViewCustomSchedule, open, schedule, scheduleDows}) => {

    return (
        <>
            <Text variant={TextVariant.BODY_1} semiBold link onClick={() => handleViewCustomSchedule()} underline={LinkUnderline.ALWAYS} color={TextColor.SECONDARY}>
                View Custom Schedule
            </Text>
            <AlertDialog
                acceptDisabled={false}
                acceptText={buttonText}
                debounceAccept
                onAccept={handleViewCustomSchedule}
                open={open}
                title={"View Custom Schedule"}
                size={DialogSize.LARGE}
            >
                <SelectedCustomScheduleList schedule={schedule}
                                            scheduleDows={scheduleDows}
                />
            </AlertDialog>

        </>
    )
};

ViewCustomScheduleDialog.propTypes = {
    buttonText: PropTypes.string,
    handleViewCustomSchedule: PropTypes.func,
    open: PropTypes.bool,
    schedule: PropTypes.shape({
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
        date: PropTypes.string.isRequired,
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number,
        returnDate: PropTypes.string,
    }),
    scheduleDows: PropTypes.arrayOf(PropTypes.shape({
        dayOfWeek: PropTypes.number,
        arrivalEndTime: PropTypes.string,
        arrivalStartTime: PropTypes.string,
    })),
};

export default ViewCustomScheduleDialog;