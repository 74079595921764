import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import Loader from "worksmith/components/Loader/Loader";
import {getLineItemErrors, GetUniqueReactKey, ValueIsSet} from "worksmith/helpers/GenericHelpers"
import LineItemTypeService from "worksmith/services/api/LineItemTypeService";
import PricingProposalForm, {PricingProposalFormVariant} from "worksmith/components/PricingProposalForm/PricingProposalForm";
import ChangeOrderRequestService from "worksmith/services/api/ChangeOrderRequestService";
import LineItemType from "worksmith/enums/api/psldb/LineItemType";
import moment from 'moment';
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const graphQLService      = new GraphQLServiceClass();
const lineItemTypeService = new LineItemTypeService();
const changeOrderRequestService  = new ChangeOrderRequestService();

class ChangeOrderRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allowAdditionalApproval: false,
            errors: {
                scopeOfWork: false,
                lineItems: []
            },
            lineItemTypes: [],
            loading:       true,
            obligation:    null,
        };

        this.changeOrderRequest = {
            changedAdditionalApprovalAmount: null,
            changedDuration: null,
            changedScopeOfWork: null,
            lineItems: null,
            obligationId: null,
            existingChangeOrderId: null
        };
    }

    componentDidMount() {
        const {obligationId} = this.props;

        if(ValueIsSet(obligationId)) {
            graphQLService.findOneById(obligationId,
                GraphQLObjectType.OBLIGATION,
                ChangeOrderRequest.queryFields.obligation).then(obligation => {
                    let promises = [lineItemTypeService.findGroupedByServiceLineId([obligation.serviceLine.id])];

                    let opportunityType = null;

                    if(ValueIsSet(obligation.bid) && ValueIsSet(obligation.bid.opportunity))
                        opportunityType = obligation.bid.opportunity.type;

                    if(ValueIsSet(obligation.obligationTemplate) && ValueIsSet(obligation.obligationTemplate.bid) && ValueIsSet(obligation.obligationTemplate.bid.opportunity))
                        opportunityType = obligation.obligationTemplate.bid.opportunity.type;

                    if(opportunityType === 'CONTRACT') {
                        promises.push(graphQLService.findAll({
                                vendorLocationId: obligation.vendorLocation.id,
                                clientLocationId: obligation.clientLocation.id,
                                serviceLineId: obligation.serviceLine.id,
                                isPriced: true
                            },
                            GraphQLObjectType.RATE_CARD_ENTRY,
                            ChangeOrderRequest.queryFields.rateCardEntries));
                    }

                    Promise.all(promises).then(([serviceLineLineItemTypes, rateCardEntries]) => {
                        let lineItemTypes = serviceLineLineItemTypes[obligation.serviceLine.id];

                        if(ValueIsSet(rateCardEntries) && rateCardEntries.length > 0) {
                            lineItemTypes = lineItemTypes.filter(lineItemType => lineItemType.name !== LineItemType.LABOR && lineItemType.name !== LineItemType.TRIP_CHARGE);
                            lineItemTypes = lineItemTypes.concat(rateCardEntries.map(item => {
                                item.name = item.itemName;
                                return item;
                            }));
                        }
                        this.setState({lineItemTypes, loading: false, obligation});
                    }).catch(error => {
                        // noinspection JSIgnoredPromiseFromCall
                        DisplayErrorNotification('Could not load job: ' + error.toString());
                    });
            }).catch(error => {
                // noinspection JSIgnoredPromiseFromCall
                DisplayErrorNotification('Could not load job: ' + error.toString());
                this.setState({loading: false});
            });
        }
    }

    onFormChange = (formValues) => {
        const {obligationId, enableAccept, overrideClientApproval}   = this.props;

        this.changeOrderRequest = {
            changedAdditionalApprovalAmount: formValues.additionalApproval,
            changedDuration:                 formValues.duration,
            changedScopeOfWork:              formValues.termsAndConditions,
            lineItems:                       formValues.lineItems,
            obligationId:                    obligationId,
            overrideClientApproval:          overrideClientApproval
        };

        if(ValueIsSet(enableAccept))
            enableAccept();
    };

    getTitleIdentifier = () => {
        const {obligation} = this.state;
        return obligation.isForRecurringService ?
            moment(obligation.obligationDate).format(MomentFormat.MonthDayYearSlash) + " visit"
            :
            ("Opportunity #" + obligation.bid.opportunity.commonId);
    };

    onSubmit = () => {
        const{disableAccept, enableAccept, isEdit, reloadPage, showSuccessErrorModal} = this.props;

        this.changeOrderRequest.lineItems = this.changeOrderRequest.lineItems.map(lineItem =>{
            if(ValueIsSet(lineItem.lineItemType.rateCardEntry)){
                lineItem.lineItemTypeId = lineItem.lineItemType.rateCardEntry.contractItem.lineItemType.id;
                lineItem.type = lineItem.lineItemType.rateCardEntry.contractItem.lineItemType.name;
            } else {
                lineItem.lineItemTypeId = lineItem.lineItemType.id;
                lineItem.type = lineItem.lineItemType.name;
            }
            return lineItem;
        });

        if (ValueIsSet(disableAccept))
            disableAccept();

        // Create a new change order
        if(!isEdit) {
            changeOrderRequestService.create(this.changeOrderRequest).then(() => {
                //TODO: Matt/Justin look into this reload and maybe do some cleanup
                if(ValueIsSet(reloadPage)){
                    reloadPage()
                }

                if(showSuccessErrorModal){
                    // noinspection JSIgnoredPromiseFromCall
                    DisplaySuccessNotification('Change Order Submitted!');
                }

            }).catch(error => {
                // noinspection JSIgnoredPromiseFromCall
                console.error(error);

                if(showSuccessErrorModal){
                    // noinspection JSIgnoredPromiseFromCall
                    DisplayErrorNotification('Could not submit change order: ' + error.toString());
                }
            }).finally(ValueIsSet(enableAccept) ? enableAccept() : null);
        }

        // Edit an existing change order
        if(isEdit){
            changeOrderRequestService.update(this.changeOrderRequest).then(() => {
                if(ValueIsSet(reloadPage)){
                    reloadPage()
                }

                if(ValueIsSet(showSuccessErrorModal)){
                    // noinspection JSIgnoredPromiseFromCall
                    DisplaySuccessNotification('Change Order Updated');
                }

            }).catch(error => {
                console.error(error);
                if(ValueIsSet(showSuccessErrorModal)){
                    // noinspection JSIgnoredPromiseFromCall
                    DisplayErrorNotification('Could not update change order: ' + error.toString());
                }
            }).finally(ValueIsSet(enableAccept) ? enableAccept() : null);
        }
    };

    onValidate = () =>{
        const {changeOrderRequest} = this;
        let errors = {
            lineItems: [],
            scopeOfWork: false
        };
        let isValid = true;

        if((!ValueIsSet(changeOrderRequest.changedScopeOfWork) || changeOrderRequest.changedScopeOfWork === '') && !changeOrderRequest.overrideClientApproval) {
            errors.scopeOfWork = true;
            isValid=false;
        }

        changeOrderRequest.lineItems.forEach((lineItem, index) => {
            let lineItemErrors = getLineItemErrors(lineItem);

            if(Object.values(lineItemErrors).some(val => val === true))
                isValid = false;

            errors.lineItems[index] = lineItemErrors;
        });

        this.setState({errors});
        return isValid;
    };

    render() {
        const {onFormChange, getTitleIdentifier} = this;
        const {
            allowAdditionalApproval,
            errors,
            lineItemTypes,
            loading,
            obligation
        } = this.state;
        const {isEdit, overrideClientApproval, portalType} = this.props;

        let initialLineItems = [];

        // If creating a new change order
        if(ValueIsSet(obligation) && !isEdit){
            initialLineItems = obligation.obligationItems.map(item => {
                item.reactKey = GetUniqueReactKey();
                item.description = item.title;
                return item;
            });
        }

        // If editing an existing change order
        if(ValueIsSet(obligation) && isEdit){
            this.changeOrderRequest.existingChangeOrderId = obligation.pendingChangeOrderRequest.id;

            initialLineItems = obligation.pendingChangeOrderRequest.changedLineItems.map(item => {
                item.reactKey = GetUniqueReactKey();
                item.title = item.description;
                item.unitWholesalePrice = (item.unitRetailPrice * obligation.wholesaleRate);
                item.excludeRevenueShare = false;
                return item;
            });
        }

        return (
            loading ?
                <Loader/>
            :
                <PricingProposalForm allowAdditionalApproval={allowAdditionalApproval}
                                     clientLocation={obligation.clientLocation}
                                     effectiveWholesaleRate={obligation.wholesaleRate}
                                     errors={errors}
                                     initialDuration={isEdit ?
                                                         obligation.pendingChangeOrderRequest.changedDuration
                                                         :
                                                         obligation.duration}
                                     initialTermsAndConditions={isEdit ?
                                                                     obligation.pendingChangeOrderRequest.changedScopeOfWork
                                                                     :
                                                                     null}
                                     initialLineItems={initialLineItems}
                                     isEdit={isEdit}
                                     isForRecurringService={obligation.isForRecurringService}
                                     lineItemTypes={lineItemTypes}
                                     onChange={onFormChange}
                                     request={obligation.request}
                                     titleIdentifier={getTitleIdentifier()}
                                     variant={PricingProposalFormVariant.CHANGE_ORDER}
                                     overrideClientApproval={overrideClientApproval}
                                     portalType={portalType}
                />
        )


    }
}

ChangeOrderRequest.defaultProps = {
    showSuccessErrorModal: true
};

ChangeOrderRequest.propTypes = {
    disableAccept: PropTypes.func,
    enableAccept:  PropTypes.func,
    isEdit:        PropTypes.bool,
    obligationId:  PropTypes.number.isRequired,
    reloadPage:    PropTypes.func,
    overrideClientApproval: PropTypes.bool,
    showSuccessErrorModal: PropTypes.bool,
    portalType:    CustomPropTypes.enum(PortalType)
};

ChangeOrderRequest.queryFields ={
    obligation: `
        bid {
            opportunity {
                commonId
                type
            }
        }
        obligationTemplate {
            id
            bid {
                opportunity {
                    type
                }
            }
        }
        clientLocation {
            addressLineOne
            addressLineTwo
            city
            client {
                nickname
            }
            id
            state
            zip
        }
        duration
        isForRecurringService
        obligationDate
        obligationItems {
            excludeRevenueShare
            id
            lineItemType {
                id
                name
            }
            quantity
            title
            unitRetailPrice
            unitWholesalePrice
            rateCardEntry {
                id
                rateCard {
                    id
                }
                title
                itemName
                scopeOfWork
                unitWholesalePrice
                unitRetailPrice
                sequencePosition
                displayValue
            }
        }
        pendingChangeOrderRequest {
            id
            changedDuration
            changedScopeOfWork
            changedLineItems{
                description
                id
                quantity
                unitRetailPrice
                lineItemType{
                    id
                    name
                }
                rateCardEntry{
                    id
                    rateCard {
                        id
                    }
                    title
                    itemName
                    scopeOfWork
                    unitWholesalePrice
                    unitRetailPrice
                    sequencePosition
                    displayValue
                }
            }
        }
        request {
            description
            target {
                date
                type
            }
            timePreference
        }
        scopeOfWork
        serviceLine {
            id
        }
        wholesaleRate
        vendorLocation {
            id
        }`,
    rateCardEntries: `
        id
        contractItem {
            lineItemType {
                id
                name
            }
        }
        itemName
        title
        scopeOfWork
        unitRetailPrice
        unitWholesalePrice
    `
};

export default ChangeOrderRequest;