import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {ValueIsSet} from "../../../helpers/GenericHelpers";
import BidService from "../../../services/api/BidService";
import TextFieldDialog from "../../../components/Dialog/TextFieldDialog";
import AlertDialog from "worksmith/components/Dialog/AlertDialog";
import {DisplayErrorNotification, DisplaySuccessNotification} from "../../../helpers/SweetAlertHelpers";

const bidService = new BidService();

class RequestARevisionModal extends Component {
    constructor(props) {
        super(props);
    }

    requestARevision = (requestedRevision) => {
        const {bidId, closeModal, onAccept, requestRevision, inCancelDialog, closeCancelDialog} = this.props;

        if (requestRevision) {
            requestRevision(requestedRevision);
        } else {
            this.setState({loading: true});
            bidService.requestRevision(bidId, requestedRevision).then(() => {
                DisplaySuccessNotification("Revision Requested!");

                if(ValueIsSet(onAccept))
                    onAccept();

                if(inCancelDialog === true){
                    closeCancelDialog();
                }

                closeModal();
            }).catch(error => {
                this.setState({loading: false});
                DisplayErrorNotification(error.toString());
            });
        }
    };

    render() {
        const {open, canRequestRevision, closeModal} = this.props;

        return (
            canRequestRevision ?
                <TextFieldDialog
                    description={'Requesting a revision prompts your vendor to make updates to your pricing based on your feedback. Please describe in detail anything you have questions or concerns about.'}
                    onAccept={this.requestARevision}
                    onCancel={closeModal}
                    open={open}
                    textFieldPlaceholder={'E.g. The price does not match the scope of work, The scope of work was misunderstood, I want to increase the scope of work, etc.'}
                    title={'Request a Revision'}
                />
            :
                <AlertDialog
                    cancelText={"Go Back"}
                    onCancel={closeModal}
                    open={open}
                    title={'Request a Revision'}>
                    There is already a pending request revision on this bid
                </AlertDialog>
        )
    }
};

RequestARevisionModal.defaultProps={
    inCancelDialog: false,
    canRequestRevision: true
};

RequestARevisionModal.propTypes = {
    bidId: PropTypes.number,
    closeCancelDialog: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    inCancelDialog: PropTypes.bool,
    onAccept: PropTypes.func,
    open:  PropTypes.bool.isRequired,
    requestRevision: PropTypes.func,
};

export default RequestARevisionModal;