import React from 'react';
import PropTypes from "prop-types";

import {AlignItems} from "../../../enums/CSSEnums";
import Button, {ButtonVariant} from "../../../components/Button/Button";
import FileUpload from "../../../components/FileUpload/FileUpload";
import Grid from "../../../components/Grid/Grid";
import Icon from "../../../components/Icon/Icon";
import {IconType} from "../../../enums/MaterialEnums";
import Text, {TextColor, TextVariant} from "../../../components/Text/Text";


const MediaLibraryView = ({fileUpload, readOnly, toggleEdit}) => {

    return (
        <Grid container alignItems={AlignItems.FLEX_START}>
            <Grid item xs={12} md={10}>
                {!readOnly ?
                    <Text gutterBottom variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY}>
                        Add any additional documents, photos, or videos that could be helpful to vendors such as floorplans, images of appliances, video tours of your location, etc.
                    </Text>
                    :
                    fileUpload.length === 0 ?
                        <Text gutterBottom variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY}>
                            No media currently uploaded
                        </Text>
                        : null
                }
                <FileUpload
                    initialFiles={fileUpload}
                    readOnly
                />
            </Grid>
            {
                !readOnly ?
                    <Grid container item xs={8} margin={'32px 0 0 0'}>
                        <Grid item xs={2}>
                            <Button fullWidth variant={ButtonVariant.OUTLINED} startIcon={<Icon name={IconType.EDIT} />} onClick={toggleEdit}>EDIT</Button>
                        </Grid>
                    </Grid>
                    :
                    null
            }
        </Grid>
    )
};

export default MediaLibraryView;

MediaLibraryView.propTypes = {
    readOnly: PropTypes.bool,
    toggleEdit: PropTypes.func,
    fileUpload: PropTypes.shape({
        file: PropTypes.arrayOf(PropTypes.object),
        description: PropTypes.string
    }),
}
