import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {InputBaseVariant} from "worksmith/components/Inputs/InputBase/InputBase.web";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import RecurringScheduleInputView from "./RecurringScheduleInputView";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import {ValueIsSet} from "../../helpers/GenericHelpers";

class RecurringScheduleInput extends Component {
    constructor(props) {
        super(props);

        this.values = {
            date: null,
            dayOfMonth: null,
            daysOfWeek: [],
            endTime: moment('6:00 PM', MomentFormat.StandardTime),
            recurrenceCount: null,
            recurrenceType: null,
            repeatEvery: null,
            startTime: moment('10:00 AM', MomentFormat.StandardTime),
        };

        if (props.frequency) {
            this.values.recurrenceCount = props.frequency.recurrenceCount;
            this.values.recurrenceType = props.frequency.recurrenceType;
            this.values.repeatEvery = props.frequency.repeatEvery;
        }

        // Initial schedule will take precedence over frequency prop
        if (props.initialSchedule) {
            this.values.date = props.initialSchedule.date;
            this.values.dayOfMonth = props.initialSchedule.dayOfMonth;
            this.values.daysOfWeek = props.initialSchedule.daysOfWeek;

            this.values.recurrenceCount = props.initialSchedule.recurrenceCount;
            this.values.recurrenceType = props.initialSchedule.recurrenceType;
            this.values.repeatEvery = props.initialSchedule.repeatEvery;

            if(props.initialSchedule.arrivalStartTime != null && props.initialSchedule.arrivalEndTime != null) {
                this.values.endTime = props.initialSchedule.arrivalEndTime;
                this.values.startTime = props.initialSchedule.arrivalStartTime;
            }

            //set Sunday to 0 to work with the datepicker library
            let sundayIndex = this.values.daysOfWeek.indexOf(0);
            if(sundayIndex > -1) this.values.daysOfWeek[sundayIndex] = 0;
        }

        this.datePickerRef = React.createRef();
        this.dayOfMonthRef = React.createRef();
    }

    shouldComponentUpdate = (nextProps, nextState, nextContext) => {

        if(this.values.recurrenceType !== nextProps.frequency.recurrenceType){
            this.values.daysOfWeek = [];
            this.datePickerRef.current.onClear();
            if(ValueIsSet(this.dayOfMonthRef.current)) this.dayOfMonthRef.current.clear();
            this.values.dayOfMonth = null;
        }

        this.values.recurrenceCount = nextProps.frequency.recurrenceCount;
        this.values.recurrenceType = nextProps.frequency.recurrenceType;
        this.values.repeatEvery = nextProps.frequency.repeatEvery;

        return true;
    };

    onChange = () => {
       const {date, daysOfWeek, endTime, dayOfMonth, startTime, ...frequency}  = this.values;

       //the datepicker library treats Sunday as 0, but our system expects 7
       let sundayIndex = daysOfWeek.indexOf(0);
       let copiedDaysOfWeek = [...daysOfWeek];
       if(sundayIndex > -1) copiedDaysOfWeek[sundayIndex] = 7;

       this.props.onChange({
           arrivalEndTime: endTime,
           arrivalStartTime: startTime,
           date,
           dayOfMonth: dayOfMonth,
           daysOfWeek: copiedDaysOfWeek,
           type: 'recurring',
           ...frequency
       });
    };

    beforeDayOfWeekChange = (dayOfWeek) => {
        let indexOfDay = this.values.daysOfWeek.indexOf(dayOfWeek);
        if(indexOfDay > -1 || this.values.daysOfWeek.length < this.values.recurrenceCount) return true;
        else return false;
    };

    isDayBlocked = (date) => {
        return this.values.recurrenceType === RecurrenceType.WEEKLY && this.values.daysOfWeek.indexOf(date.day()) === -1;
    };

    onDayOfMonthChange = (dayOfMonth) => {
        this.values.dayOfMonth = dayOfMonth;
        this.onChange();
    };

    onDaysOfWeekChange = (dayOfWeek) => {
        let indexOfDay = this.values.daysOfWeek.indexOf(dayOfWeek);

        if(indexOfDay > -1){
            if(ValueIsSet(this.values.date) && this.values.date.day() === dayOfWeek){
                this.datePickerRef.current.onClear();
            }
            this.values.daysOfWeek.splice(indexOfDay,1);
        }
        else if(this.values.daysOfWeek.length < this.values.recurrenceCount) {
            this.values.daysOfWeek.push(dayOfWeek);
        }
        this.onChange();
    };

    onDateChange = (date) => {
        this.values.date = date;
        this.onChange();
    };

    onTimeRangeChange = (startTime, endTime) => {
        this.values.startTime = startTime;
        this.values.endTime = endTime;
        this.onChange();
    };

    render() {
        const {beforeDayOfWeekChange, onDateChange, onDaysOfWeekChange, onDayOfMonthChange, onTimeRangeChange,isDayBlocked,datePickerRef,dayOfMonthRef} = this;
        const {date, daysOfWeek, dayOfMonth, endTime, recurrenceType, startTime} = this.values;
        const {allowCustomScheduleCheckBox, customScheduleChecked, direction, disabled, handleCustomScheduleWarningDialogOpen, hideDatePicker, spacing, title, variant} = this.props;

        return (
            <RecurringScheduleInputView
                allowCustomScheduleCheckBox={allowCustomScheduleCheckBox}
                beforeDayOfWeekChange={beforeDayOfWeekChange}
                customScheduleChecked={customScheduleChecked}
                datePickerRef={datePickerRef}
                dayOfMonthRef={dayOfMonthRef}
                direction={direction}
                disabled={disabled}
                handleCustomScheduleWarningDialogOpen={handleCustomScheduleWarningDialogOpen}
                hideDatePicker={hideDatePicker}
                initialDate={date}
                initialDayOfMonth={dayOfMonth}
                initialDaysOfWeek={daysOfWeek}
                initialEndTime={endTime}
                initialStartTime={startTime}
                isDayBlocked={isDayBlocked}
                onDateChange={onDateChange}
                onDaysOfWeekChange={onDaysOfWeekChange}
                onDayOfMonthChange={onDayOfMonthChange}
                onTimeRangeChange={onTimeRangeChange}
                recurrenceType={recurrenceType}
                spacing={spacing}
                title={title}
                variant={variant}/>
        )
    }
}

RecurringScheduleInput.defaultProps = {
    spacing: 3,
    title: 'Preference 1:',
    variant: InputBaseVariant.OUTLINED
};

RecurringScheduleInput.propTypes = {
    allowCustomScheduleCheckBox: PropTypes.bool,    // allowCustomScheduleCheckBox prop need to be passed down the component tree in every instance the CustomScheduleCheckBox option should be displayed otherwise the CustomScheduleCheckBox will not be displayed.
    customScheduleChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    frequency: PropTypes.shape({
        recurrenceCount: PropTypes.number,
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    handleCustomScheduleWarningDialogOpen: PropTypes.func,
    hideDatePicker: PropTypes.bool,
    initialSchedule: PropTypes.shape({
        arrivalEndTime: PropTypes.instanceOf(moment),
        arrivalStartTime: PropTypes.instanceOf(moment),
        date: PropTypes.instanceOf(moment),
        dayOfMonth: PropTypes.bool,
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        recurrenceType: CustomPropTypes.enum(RecurrenceType),
        repeatEvery: PropTypes.number
    }),
    onChange: PropTypes.func.isRequired,
    spacing: PropTypes.number,
    title: PropTypes.string,
    variant: CustomPropTypes.enum(InputBaseVariant)
};

export default RecurringScheduleInput;