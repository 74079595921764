import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import styled from 'styled-components';

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import NewRequestStatus from "../../enums/api/proposal/NewRequestStatus";
import {Display, JustifyContent} from "../../enums/CSSEnums";
import CircularProgress from "../../components/Loader/CircularProgress";
import MessageWorksmithConversation from "../../components/Conversation/MessageWorksmithConversation";
import JobDetails from "../JobDetails/JobDetails";
import ObligationTemplateDetails from "../ObligationTemplateDetails/ObligationTemplateDetails";
import Card from "../../components/Card/Card.web";
import BidList from "../BidList/BidList";
import WalkthroughList from "../WalkthroughList/WalkthroughList";
import List from "worksmith/components/List/List.web";
import AlertExpandableSection from "worksmith/components/ExpandableSection/AlertExpandableSection";
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import ConversationList from "worksmith/composite-components/ConversationList/ConversationList";
import RfpMarket from "worksmith/enums/api/proposal/RfpMarket";
import ClientLocationRole from "../../enums/api/user/ClientLocationRole";
import Link from "worksmith/components/Link/Link";
import {ExternalLinks} from "worksmith/enums/ExternalLinks";
import ClientInternalNotes from "../../components/InternalNotes/ClientInternalNotes";
import {WithContext} from "../../../context/GlobalContext";
import {InternalNotesType} from "../../../Enums";
import Grid from "worksmith/components/Grid/Grid";
import OpenConcernExpansionPanel from "worksmith/composite-components/JobDetails/OpenConcernExpansionPanel";
import ResolvedConcernExpansionPanel from "worksmith/composite-components/JobDetails/ResolvedConcernExpansionPanel";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import SignOffExpansionPanel from "worksmith/composite-components/JobDetails/SignOffExpansionPanel";

const SectionHeaderContainer = styled.div`
    margin-bottom: 48px;
    width: 100%;
`;

const RequestExpandableSections = (props) => {
    const {
        chatRef,
        loading,
        request,
        reloadRequest,
        startLoad,
        user,
        context
    } = props;
    const [showConversationUnreadMessages, setShowConversationUnreadMessages] = useState(true);
    const [showWorksmithUnreadMessages, setShowWorksmithUnreadMessages] = useState(true);
    const [unreadConversationGroupCount, setUnreadConversationGroupCount] = useState(0);

    useEffect(() => {
        setShowConversationUnreadMessages(true)
        setShowWorksmithUnreadMessages(true)

        if (!loading) {
            setUnreadConversationGroupCount(request.unreadMessageCount)
        }
    }, [loading])

    if (loading) {
        return (
            <Box
                display={Display.FLEX}
                justifyContent={JustifyContent.CENTER}
                width={'100%'}>
                <CircularProgress/>
            </Box>
        )
    }

    let showJobDetails = request.associatedObligation || request.associatedObligationTemplate;
    let jobDetailsTitle = '';
    let isAdmin = user.clientRoles && user.clientRoles.length > 0;
    let isRegionalManager = user.clientLocationRoles && user.clientLocationRoles.some(role => role.role === ClientLocationRole.REGIONAL_MANAGER);
    let showableOpportunityConversations = request.opportunityConversations ? request.opportunityConversations.filter(conversation =>
            conversation.opportunity.hasActivity || conversation.messageCount > 0 || request.market !== RfpMarket.OPEN_NETWORK)
        :
        [];

    if (showJobDetails) {
        if (request.associatedObligation) {
            jobDetailsTitle = request.newStatus === NewRequestStatus.COMPLETED ? 'Completion Details' : 'Job Details';
        } else {
            jobDetailsTitle = 'Recurring Service Details'
        }
    }

    let walkthroughAlerts = [];
    if (request.numberOfActiveWalkthroughs) {
        let pendingWalkthroughCount = (
            request.numberOfActiveWalkthroughs
            - request.numberOfSchedulingWalkthroughs
            - request.numberOfScheduledWalkthroughs
        )
        if (pendingWalkthroughCount) {
            walkthroughAlerts.push({
                label: 'pending',
                count: pendingWalkthroughCount
            })
        }
        if (request.numberOfSchedulingWalkthroughs) {
            walkthroughAlerts.push({
                label: 'scheduling',
                count: request.numberOfSchedulingWalkthroughs
            })
        }
        if (request.numberOfScheduledWalkthroughs - request.numberOfReschedulingWalkthroughs > 0) {
            walkthroughAlerts.push({
                label: 'scheduled',
                count: request.numberOfScheduledWalkthroughs - request.numberOfReschedulingWalkthroughs,
                hideAlertCircle: true
            })
        }
        if (request.numberOfReschedulingWalkthroughs) {
            walkthroughAlerts.push({
                label: 'rescheduling',
                count: request.numberOfReschedulingWalkthroughs
            })
        }
        if (request.numberOfCompletedWalkthroughs) {
            walkthroughAlerts.push({
                label: 'completed',
                count: request.numberOfCompletedWalkthroughs,
                hideAlertCircle: true
            })
        }
    }


    return (
        <List>
            {
                showJobDetails ?
                    <SectionHeaderContainer>
                    <AlertExpandableSection
                        collapsibleProps={{
                            unmountOnExit: false
                        }}
                        title={jobDetailsTitle}>
                        <Box width={'100%'} mt={3} ml={1}>
                            <Card width={'100%'} padding={2} elevation={3}>
                                {
                                    request.associatedObligation ?
                                        <JobDetails
                                            obligationId={request.associatedObligation.id}
                                            useTicketing={request.serviceLine.useTicketing}/>
                                        :
                                        <ObligationTemplateDetails obligationTemplateId={request.associatedObligationTemplate.id}/>
                                }
                            </Card>
                        </Box>
                    </AlertExpandableSection>
                    </SectionHeaderContainer>
                    :
                    null
            }
            {ValueIsSet(request.associatedObligation) && request.associatedObligation.confirmed ?
                <Grid>
                    <SectionHeaderContainer>
                        <SignOffExpansionPanel confirmedUser={request.associatedObligation.confirmedUser} confirmedTimestamp={request.associatedObligation.confirmedTimestamp}/>
                    </SectionHeaderContainer>
                </Grid>
                :
                null}
            {ValueIsSet(request.associatedObligation) && request.associatedObligation.hasOpenConcern ?
                <Grid>
                    <SectionHeaderContainer>
                        <OpenConcernExpansionPanel concern={request.associatedObligation.openConcern}/>
                    </SectionHeaderContainer>
                </Grid>
                :
                null}
            {ValueIsSet(request.associatedObligation) && request.associatedObligation.resolvedConcerns.length > 0 ?
                <Grid>
                    <SectionHeaderContainer>
                        <ResolvedConcernExpansionPanel concerns={request.associatedObligation.resolvedConcerns} sidePanel={false}/>
                    </SectionHeaderContainer>
                </Grid>
                :
                null}
            {
                request.numberOfActiveEstimates && !showJobDetails ?
                    <SectionHeaderContainer>
                    <AlertExpandableSection
                        collapsibleProps={{
                            unmountOnExit: false
                        }}
                        initialExpanded={request.newStatus === NewRequestStatus.SCHEDULING}
                        title={'Estimates'}
                        alerts={request.numberOfActiveEstimates && request.newStatus !== NewRequestStatus.SCHEDULING ?
                            [{
                                count: request.numberOfActiveEstimates,
                                label: 'pending'
                            }]
                            :
                            null
                        }>
                        <Box mt={3} ml={1} mr={2}>
                            <BidList
                                isAdmin={isAdmin}
                                isRegionalManager={isRegionalManager}
                                isEstimate
                                numberOfActive={request.numberOfActiveEstimates}
                                onBidAction={reloadRequest}
                                requestId={request.id}
                                startLoad={startLoad}/>
                        </Box>
                    </AlertExpandableSection>
                    </SectionHeaderContainer>
                    :
                    null
            }
            {
                request.numberOfActiveBids && !showJobDetails ?
                    <SectionHeaderContainer>
                    <AlertExpandableSection
                        collapsibleProps={{
                            unmountOnExit: false
                        }}
                        initialExpanded={request.newStatus === NewRequestStatus.SCHEDULING}
                        title={'Bids'}
                        alerts={request.numberOfActiveBids && request.newStatus !== NewRequestStatus.SCHEDULING ?
                            [{
                                count: request.numberOfActiveBids,
                                label: 'pending'
                            }]
                            :
                            null
                        }>
                        <Box mt={3} ml={1} mr={2}>
                            <BidList
                                isAdmin={isAdmin}
                                isRegionalManager={isRegionalManager}
                                numberOfActive={request.numberOfActiveBids}
                                onBidAction={reloadRequest}
                                requestId={request.id}
                                startLoad={startLoad}/>
                        </Box>
                    </AlertExpandableSection>
                    </SectionHeaderContainer>
                    :
                    null
            }
            {
                request.numberOfActiveWalkthroughs || request.numberOfCompletedWalkthroughs ?
                    <SectionHeaderContainer>
                    <AlertExpandableSection
                        collapsibleProps={{
                            unmountOnExit: false
                        }}
                        title={'Walkthroughs'}
                        alerts={walkthroughAlerts}>
                        <Box mt={3} ml={1} mr={2}>
                            <WalkthroughList
                                isAdmin={isAdmin}
                                numberOfWalkthroughs={request.numberOfActiveWalkthroughs + request.numberOfCompletedWalkthroughs}
                                onWalkthroughAction={reloadRequest}
                                requestId={request.id}
                                startLoad={startLoad}/>
                        </Box>
                    </AlertExpandableSection>
                    </SectionHeaderContainer>
                    :
                    null
            }
            {
                showableOpportunityConversations.length > 0 ?
                   <SectionHeaderContainer>
                    <AlertExpandableSection
                        title={'Contact Vendor'}
                        initialExpanded={true}
                        alerts={unreadConversationGroupCount && unreadConversationGroupCount > 0 ?
                            [{
                                count: unreadConversationGroupCount,
                                label: 'new'
                            }]
                            :
                            null
                        }
                        hideAlerts={!showConversationUnreadMessages}>
                        <Box mt={3} ml={1} mr={2}>
                            <div ref={chatRef}>
                            <ConversationList
                                onMessageGroupRead={(number) => {
                                    setUnreadConversationGroupCount(unreadConversationGroupCount - number)
                                }}
                                requestId={request.id}
                                requestMarket={request.market}
                                requestNewStatus={request.newStatus}/>
                            </div>
                        </Box>
                    </AlertExpandableSection>
                   </SectionHeaderContainer>
                    :
                    null
            }
            {
                request.canClientSeeComments  && request.commentCount > 0 ?
                    <SectionHeaderContainer>
                    <AlertExpandableSection
                        title={'Worksmith Messages'}
                        alerts={request.unreadCommentCount ?
                            [{
                                count: request.unreadCommentCount,
                                label: 'new'
                            }]
                            :
                            null
                        }
                        hideAlerts={!showWorksmithUnreadMessages}>
                        <Box mb={5}>
                            <Text color={TextColor.TEXT_SECONDARY} variant={TextVariant.BODY_2}>
                                If you still need help from Worksmith on this request, please <Link onClick={() => window.open(ExternalLinks.ZENDESK_SUPPORT_TICKET)}>contact support</Link>.
                            </Text>
                        </Box>
                        <Box width={'100%'} maxHeight={500} minHeight={350} mt={3}>
                            <MessageWorksmithConversation
                                onMessageRead={() => setShowWorksmithUnreadMessages(false)}
                                requestId={request.id}/>
                        </Box>
                    </AlertExpandableSection>
                    </SectionHeaderContainer>
                    :
                    null
            }
            {
                context.internalNotesConfig == InternalNotesType.ALL ||
                    (context.internalNotesConfig == InternalNotesType.WARRANTY_ONLY && request.isWarranty) ?
                    <SectionHeaderContainer>
                        <AlertExpandableSection
                            title={'Internal Notes'}
                            initialExpanded={true}>
                            <Box mb={5}>
                                <Text color={TextColor.TEXT_SECONDARY} variant={TextVariant.BODY_2}>
                                    Internal notes are available to your team only. They will not be shared with the vendor.
                                </Text>
                            </Box>
                            <Box width={'100%'} maxHeight={500} minHeight={350} mt={3}>
                                <ClientInternalNotes
                                    requestId={request.id}/>
                            </Box>
                        </AlertExpandableSection>
                    </SectionHeaderContainer>
                    :
                    null
            }
        </List>
    )
};

RequestExpandableSections.propTypes = {
    chatRef: PropTypes.object,
    loading: PropTypes.bool,
    reloadRequest: PropTypes.func,
    request: PropTypes.shape({
        associatedObligation: PropTypes.shape({
            id: PropTypes.number
        }),
        associatedObligationTemplate:
 PropTypes.shape({
            id: PropTypes.number
        }),
        canClientSeeComments: PropTypes.bool,
        id: PropTypes.number.isRequired,
        newStatus: CustomPropTypes.enum(NewRequestStatus),
        messageCount: PropTypes.number,
        numberOfActiveBids: PropTypes.number,
        numberOfActiveEstimates: PropTypes.number,

        numberOfActiveWalkthroughs: PropTypes.number,
        numberOfScheduledWalkthroughs: PropTypes.number,
        numberOfSchedulingWalkthroughs: PropTypes.number,
        numberOfReschedulingWalkthroughs: PropTypes.number,
        numberOfCompletedWalkthroughs: PropTypes.number,
        unreadCommentCount:PropTypes.number,
        opportunityConversations: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            messageCount: PropTypes.number,
            isOpen: PropTypes.bool
        })),
        serviceLine: PropTypes.shape({
            useTicketing: PropTypes.bool
        }),
        unreadMessageCount: PropTypes.number,
        isWarranty: PropTypes.bool
    }),
    user: PropTypes.shape({
        clientRoles: PropTypes.arrayOf(PropTypes.shape({
            client: PropTypes.shape({
                id: PropTypes.number,
            }),
            role: PropTypes.string,
        })),
    }).isRequired,
    startLoad: PropTypes.func
};

export const RequestExpandableSectionsGraphQLString = `
        associatedObligation {
            id
            hasOpenConcern
            confirmed
            confirmedTimestamp
            confirmedUser{
                displayName
            }
            resolvedConcerns{
                id
                resolvedTimestamp
                resolvedUser{
                    displayName
                }
                description
                resolutionNotes
            }
            openConcern{
                id
                createdTimestamp
                creatorUser{
                    displayName
                }
                description
            }
        }
        associatedObligationTemplate {
            id
        }
        client{
            storeSignOffEnabled
        }
        commentCount
        canClientSeeComments
        id
        market
        newStatus
        numberOfActiveBids
        numberOfActiveEstimates
        numberOfActiveWalkthroughs
        numberOfScheduledWalkthroughs
        numberOfSchedulingWalkthroughs
        numberOfReschedulingWalkthroughs
        numberOfCompletedWalkthroughs
        unreadCommentCount
        opportunityConversations {
            id
            messageCount
            isOpen
            opportunity{
                hasActivity
            }
        }
        serviceLine {
            useTicketing
        }
        unreadMessageCount
        messageCount
        noteCount
        isWarranty
`;

export default WithContext(RequestExpandableSections);