import React, {Component} from 'react';
import moment from 'moment';
import ReactModal from 'react-modal';
import {Link} from 'react-router-dom';
import ObligationService from 'worksmith/services/api/ObligationService';
import ObligationItemGroupService from 'worksmith/services/api/ObligationItemGroupService';
import ClientService from 'worksmith/services/api/ClientService';
import UserService from 'worksmith/services/api/UserService';
import ClientLocationService from 'worksmith/services/api/ClientLocationService';
import ServiceLineService from 'worksmith/services/api/ServiceLineService';
import VendorLocationService from 'worksmith/services/api/VendorLocationService';
import AuthTokenManager from 'worksmith/services/utilities/AuthTokenManager';
import Loader from "../Loader";
import Button from "../Button";
import notification from 'antd/lib/notification';
import NewServiceForm from "../forms/obligations/NewServiceForm";
import NewChangeRequestForm from "../forms/NewChangeRequestForm";
import DatePicker from 'antd/lib/date-picker';
import Checkbox from 'antd/lib/checkbox';
import ShippingForm from '../forms/ShippingForm';
import {ModalStyle} from "../../Enums";
import Modal from "../presentational/modal/Modal";

import t from 'tcomb-form';
import TicketForm from "../forms/TicketForm";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import CombinedTicketingForm from "../forms/obligations/CombinedTicketingForm";

const clientService = new ClientService();
const clientLocationService = new ClientLocationService();
const vendorLocationService = new VendorLocationService();
const obligationService = new ObligationService();
const obligationItemGroupService = new ObligationItemGroupService();
const userService = new UserService();
const serviceLineService = new ServiceLineService();
const authTokenManager = new AuthTokenManager();

const Form = t.form.Form;

class ObligationsList extends Component {
    constructor(props) {
        super(props);

        let startDate = moment().subtract(7, 'days');
        let endDate = moment().add(14, 'days');

        this.state = {
            obligations: [],
            incompleteOnly: true,
            obligation: {},
            newlyCreatedObligation: {},
            locations: [],
            obligationTemplate: {},
            changeRequestModalVisible: false,
            clientLocations: [],
            loading: true,
            locationsLoading: true,
            vendors: [],
            serviceLines: {},
            settings: {},
            filterValues: {},
            customerName: '',
            vendorTicketCountLoading: false,
            startDateFilter: startDate,
            endDateFilter: endDate,
            clientId: props.user.clientRoles ? props.user.clientRoles[0].client.id : props.user.clientLocationRoles[0].clientId,
            isBurberryUser: props.user.clientRoles === null || props.user.clientRoles.length === 0 ? props.user.clientLocationRoles[0].clientId == '140' : props.user.clientRoles[0].client.id == '140',
            searchParams: {
                incomplete: true,
                dateRange: {
                    startDate: startDate,
                    endDate: endDate
                }
            },
            completeStatuses: ['picked up by customer', 'sent to customer', 'picked up by guest', 'sent to guest']

        };

        this.getObligations = this.getObligations.bind(this);
        this.getServiceLines = this.getServiceLines.bind(this);
        this.submitFilter = this.submitFilter.bind(this);
        this.handleNewTicket = this.handleNewTicket.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleNewChangeRequestModal = this.handleNewChangeRequestModal.bind(this);
        this.handleChangeRequestSuccess = this.handleChangeRequestSuccess.bind(this);
        this.printTickets = this.printTickets.bind(this);
        this.shipItems = this.shipItems.bind(this);
        this.updateFilter = this.updateFilter.bind(this);

        this.newServiceModalRef = React.createRef();
    }

    handleNewChangeRequestModal(obligation) {
        this.setState({changeRequestModalVisible: !this.state.changeRequestModalVisible, obligation})
    }


    handleNewTicket(obligation) {
        let _this = this;
        if (obligation.vendorLocation.isBurberryVendor) {
            _this.setState({vendorTicketCountLoading: true});
            vendorLocationService.getVendorActiveTicketNumber(obligation.vendorLocation.id).then(function(data) {
                let numberOfActiveTickets = data;
                _this.setState({vendorTicketCountLoading: false});

                if (numberOfActiveTickets < 25)
                    _this.props.history.push(`/pickups/${obligation.id}/ticket/new`);
                else {
                    notification['warning']({
                        message: "Your in-house tailor has reached their ticket limit. Please set up a service with your supplementary vendor."
                    });
                }
            });
        } else {
            _this.props.history.push(`/pickups/${obligation.id}/ticket/new`);
        }
    }

    handleCloseModal() {
        this.setState({
            changeRequestModalVisible: false
        });

        this.newServiceModalRef.current.close();
    }

    handleChangeRequestSuccess() {
        this.handleCloseModal();
        this.getObligations();
    }

    updateFilter(filterValues) {
        let _this = this;

        if(filterValues.location != null && filterValues.location != _this.state.filterValues.location) {
            clientLocationService.getPreferredVendorSummaries(filterValues.location, null, true).then(function (data) {
                let vendorData = data;
                console.log(vendorData);

                let filterVendors = vendorData.reduce(function (map, obj) {
                    map[obj.vendorLocation.vendor.id] = obj.vendorLocation.vendor.name;
                    return map;
                }, {});

                _this.setState({filterVendors})
            })
        }

        _this.setState({ filterValues });
    }

    submitFilter() {

        let searchParams = {
            incomplete: this.state.incompleteOnly,
            dateRange: {
                startDate: this.state.startDateFilter,
                endDate: this.state.endDateFilter
            }
        }

        this.setState({ searchParams });

        this.getObligations();
    }

    getServiceLines() {
        let _this = this;

        serviceLineService.findSimpleBusinessObjects(null, true).then(function (data) {
            let serviceLines = data;

            let serviceLinesToObject = serviceLines.reduce(function (map, obj) {
                map[obj.id] = obj.name;
                return map;
            }, {});

            _this.setState({ serviceLines : serviceLinesToObject });
        })
    }

    getObligations() {
        this.setState({ loading: true });
        let _this = this;
        let params = { };

        if (this.state.searchParams.dateRange.startDate) {
            params.startDate = moment(this.state.searchParams.dateRange.startDate).format('YYYY-MM-DD')
        }

        if (this.state.searchParams.dateRange.endDate) {
            params.endDate = moment(this.state.searchParams.dateRange.endDate).format('YYYY-MM-DD')
        }

        if (this.state.filterValues.location) {
            params.clientLocationId = this.state.filterValues.location;
        }

        if (this.state.filterValues.serviceLine) {
            params.serviceLineId = this.state.filterValues.serviceLine;
        }

        if(this.state.filterValues.vendor) {
            params.vendorId = this.state.filterValues.vendor;
        }

        if (this.state.customerName) {
            params.customerName = this.state.customerName;
        }

        params.ticketingOnly = true;

        obligationService.summaries(params).then(function (data) {
            let obligations = data;

            if (_this.state.searchParams.incomplete) {
                obligations.forEach(function (obligation) {
                    obligation.printDisabled = true;
                    let ticketList = [];

                    obligation.tickets.forEach(function (ticket) {
                        ticket.selected = false;

                        if (!_this.state.completeStatuses.includes(ticket.status)) {
                            ticket.itemCount = 0;
                            ticket.obligationItems.forEach(function (obligationItem) {
                                ticket.itemCount += obligationItem.quantity;
                            });
                            ticketList.push(ticket);
                        }
                    });
                    obligation.tickets = ticketList;
                });
                _this.setState({obligations, loading: false})
            }

            _this.setState({obligations, loading: false})

        })
    }


    componentDidMount() {

        let _this = this;
        let clientLocations = _this.state.locations;

        /////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////
        clientService.getSingleDryCleaningFormConfiguration(_this.state.clientId)
            .then(function (data) {
                _this.setState({settings: {dryCleaning: data}});
            });

        this.getObligations();
        this.getServiceLines();

        userService.getClientLocationClientSummariesForUser(authTokenManager.getUserId()).then(function (data) {
            let locations = data;

            let locationsToObject = locations.reduce(function (map, obj) {
                map[obj.id] = obj.title;
                return map;
            }, {});

            if (clientLocations.length === 1) {
                clientLocationService.getPreferredVendorSummaries(locations[0].id, null, true).then(function (data) {
                    _this.setState({vendors:data})
                })
            }

            _this.setState({
                locations: locationsToObject,
                locationsLoading: false
            });
        });

    }

    toggleTicket(obligation, ticket) {
        ticket.selected = !ticket.selected;

        let disablePrint = true;
        obligation.tickets.forEach(function(ticket) {
            if (ticket.selected)
                disablePrint = false;
        });

        let obligations = this.state.obligations;
        obligations[obligations.indexOf(obligation)].printDisabled = disablePrint;
        this.setState(obligations);
    }

    printTickets(obligation) {
        let ticketsToPrint = [];
        obligation.tickets.forEach(function(ticket) {
            if (ticket.selected) {
                ticket.vendorLocation = obligation.vendorLocation;
                ticketsToPrint.push(ticket);
            }
        });

        window.localStorage.setItem('selectedTickets', JSON.stringify(ticketsToPrint));
        window.open("/#/printTickets");
    }

    shipItems(trackingNumber) {

        let _this = this;

        let obligation = this.state.shippedObligation;

        let ticketsToShip = [];
        let ticketIdsToShip = [];
        obligation.tickets.forEach(function(ticket) {
            if(ticket.selected) {
                ticketsToShip.push(ticket);
                ticketIdsToShip.push(ticket.id);
            }

        });

        let submitData = {
            ticketIds : ticketIdsToShip,
            trackingNumber: trackingNumber
        }

        obligationItemGroupService.shipItems(submitData).then(function (data) {
            data.forEach(function(ticket) {
                ticket.vendorLocation = obligation.vendorLocation;
            });

            window.localStorage.setItem('selectedTickets', JSON.stringify(data));
            window.open("/#/printTickets");
            _this.setState({ shippingModalVisible: false, shippedObligation: null });
            _this.getObligations();
        })
    }

    render() {
        let _this = this;
        let {obligations, vendors, filterVendors, settings, loading, locationsLoading, isBurberryUser} = this.state;
        let {client} = this.props;

        if (!loading && !locationsLoading) {

            let filterFields = {
                location: t.enums(this.state.locations),
                serviceLine: t.enums(this.state.serviceLines)
            };

            if(filterVendors != null) {
                filterFields['vendor'] = t.enums(filterVendors);
            }

            let LocationFilterFormStruct = t.struct(filterFields);

            let filterDisabled = () => {
                let {startDateFilter, endDateFilter} = this.state;

                if (!startDateFilter || !endDateFilter) {
                    return true;
                }
            }

            return (
                <div>

                    <section className="ws-section">
                        <ReactModal
                            isOpen={this.state.shippingModalVisible}
                            contentLabel="Modal"
                            style={ModalStyle.DEFAULT}>

                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button
                                        message={'Close'}
                                        type={'link'}
                                        onPress={() => this.setState({shippingModalVisible: false})}/>
                                </div>
                            </div>

                            <ShippingForm handleSubmit={this.shipItems} />
                        </ReactModal>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <Button type={'link'} icon={!this.state.showFilter ? 'arrow-down' : 'arrow-up'} message={!this.state.showFilter ? 'Show Filter' : 'Hide Filter'} onPress={() => this.setState({ showFilter: !this.state.showFilter })} />
                            </div>
                        </div>
                        {this.state.showFilter ?
                            <div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Pickup/Ship Date Range</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <DatePicker size={'large'}
                                                    value={this.state.startDateFilter}
                                                    onChange={(startDateFilter) => this.setState({startDateFilter})}/>
                                        <span>&nbsp;to&nbsp;</span>
                                        <DatePicker size={'large'}
                                                    value={this.state.endDateFilter}
                                                    onChange={(endDateFilter) => this.setState({endDateFilter})}/>
                                        <div className="margin-top-md margin-bottom-md">
                                            <Checkbox
                                                onChange={(e) => this.setState({incompleteOnly: e.target.checked})}
                                                checked={this.state.incompleteOnly}>Show
                                                incomplete tickets only</Checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form type={LocationFilterFormStruct} value={this.state.filterValues}
                                              onChange={(filterValues) => this.updateFilter(filterValues)}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label>Customer Name</label>
                                        <div>
                                            <input type="text" value={this.state.customerName} onChange={(customerName) => this.setState({customerName : customerName.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <Button type={'primary'} message={'filter'} disabled={filterDisabled()}
                                                onPress={() => this.submitFilter()}/>
                                    </div>
                                </div>
                            </div> : null}
                    </section>

                    <section className={'ws-section'}>
                        <ReactModal
                            style={ModalStyle.DEFAULT}
                            onRequestClose={this.handleCloseModal}
                            isOpen={this.state.changeRequestModalVisible}
                            contentLabel="New Change Request">
                            <div className="text-right">
                                <Button type={'link'} icon={'remove'} message={'close'}
                                        onPress={() => this.setState({changeRequestModalVisible: false})}/>
                            </div>
                            <NewChangeRequestForm obligation={this.state.obligation}
                                                  onSuccess={this.handleChangeRequestSuccess}
                                                  serviceLineId={this.state.obligation.serviceLineId}
                                                  obligationTemplate={this.state.obligationTemplate}/>
                        </ReactModal>

                        <CombinedTicketingForm
                            newServiceModalRef={this.newServiceModalRef}
                            client={client}
                            onSuccess={() => this.getObligations()}
                        />

                        <div className="row">
                            <div className="col-md-6">
                                <p className="body-copy-header">Your Scheduled Services ({ moment(this.state.searchParams.dateRange.startDate).format("MM/DD/YYYY") } - { moment(this.state.searchParams.dateRange.endDate).format("MM/DD/YYYY") }{ this.state.searchParams.incomplete ? <span>, only incomplete tickets</span> : null })</p>
                            </div>
                            <div className="col-md-6 text-right">
                                <Button type={'success'} message={'Add On-Demand Pick Up'}
                                        onPress={() => this.newServiceModalRef.current.open()}/>
                            </div>
                        </div>

                        {obligations.length ?
                            <table className="table ws-table">
                                <thead>
                                <tr>
                                    <th>Location</th>
                                    {vendors || vendors.length > 1 ? <th>Vendor</th> : null}
                                    <th>Service Line</th>
                                    <th>Pickup/Ship Date</th>
                                    <th>Expected Return Date</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {obligations.map(function (obligation, idx) {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <div>{obligation.clientLocation.name}</div>
                                                <div>{obligation.clientLocation.city}, {obligation.clientLocation.state} {obligation.clientLocation.zip}</div>
                                            </td>

                                            {/* VENDOR LOCATION */}
                                            {vendors || vendors.length > 1 ?
                                                (ValueIsSet(obligation.vendorContact) ?
                                                    (<td>{obligation.vendorLocation.name} <br/> {obligation.vendorContact.displayName} <br/> {obligation.vendorContact.phone}</td>)
                                                    :
                                                    <td>{obligation.vendorLocation.name}</td>)
                                                :
                                                null
                                            }

                                            {/* SERVICE LINE */}
                                            <td>
                                                <div>{obligation.serviceLine.name}</div>
                                            </td>

                                            {/* PICKUP/SHIP DATE */}
                                            <td>
                                                <div>{moment(obligation.obligationDate).format('ddd MMM DD YYYY')}</div>
                                            </td>

                                            {/* RETURN DATE */}
                                            <td>
                                                <div>{moment(obligation.metadata['Return date']).format('ddd MMM DD YYYY')}</div>
                                            </td>

                                            {/* TICKETS LIST */}
                                            <td>
                                                {obligation.tickets.length > 0 ?
                                                    <div className="col-md-12">
                                                        <table className="table-bordered table-condensed" style={{border: 0}}>
                                                            <thead>
                                                            <tr>
                                                                {settings.dryCleaning.showTicketNumber ?
                                                                    <th>Ticket #</th> : null}
                                                                {!settings.dryCleaning.itemLevelNames ?
                                                                    <th>Customer</th> : null}
                                                                <th>Items</th>
                                                                <th>Status</th>
                                                                {isBurberryUser ? <th>Urgency</th> : null }
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {obligation.tickets.map(function (ticket, idx) {
                                                                return (
                                                                    <tr key={idx}>
                                                                        {settings.dryCleaning.showTicketNumber ?
                                                                            <td>{ticket.groupId}</td> : null}
                                                                        {!settings.dryCleaning.itemLevelNames ?
                                                                            <td>{ticket.name || ticket.employeeName}</td> : null}
                                                                        <td>{ticket.obligationItems.length}</td>
                                                                        <td>{ticket.status}</td>
                                                                        {isBurberryUser ?
                                                                            <td>{ticket.urgency != null ? ticket.urgency : 'standard'}</td> : null }
                                                                        <td><input type="checkbox"
                                                                                onChange={() => _this.toggleTicket(obligation, ticket)}/></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                                <tr>
                                                                    {settings.dryCleaning.showTicketNumber ?
                                                                        <td style={{border: 0}}></td> : null}
                                                                    {!settings.dryCleaning.itemLevelNames ?
                                                                        <td style={{border: 0}}></td> : null}
                                                                    <td style={{border: 0}}></td>
                                                                    <td style={{border: 0}}></td>
                                                                    <td style={{border: 0}}>
                                                                        { obligation.serviceLine.id == 1 ?
                                                                            <Button type={'info'} message={'Print'} disabled={obligation.printDisabled} onPress={() => _this.printTickets(obligation)}/>
                                                                            : null }
                                                                        { obligation.serviceLine.id != 1 ?
                                                                            <Button type={'info'} message={'Ship Items'} disabled={obligation.printDisabled} onPress={() => _this.setState({ shippingModalVisible: true, shippedObligation: obligation })}/>
                                                                            : null }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> : null}

                                                <div className="row margin-top-sm">
                                                    <div className="col-md-4 margin-top-sm">
                                                        <Link to={`/pickups/${obligation.id}`}
                                                              className={'ws-btn ws-btn-primary'}>View</Link>
                                                    </div>

                                                    <div className="col-md-4 text-right">
                                                        <Button type={'success'} message={_this.state.vendorTicketCountLoading ? 'Loading...' : 'Add Ticket'}
                                                                disabled={_this.state.vendorTicketCountLoading}
                                                                onPress={() => _this.handleNewTicket(obligation)}/>
                                                    </div>

                                                    {obligation.tickets.length === 0 ? <div className="col-md-4 text-right">
                                                        <Button type='warning' message='Change'
                                                                onPress={() => _this.handleNewChangeRequestModal(obligation)}/>
                                                    </div> : null}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </table> : <p>No services to show.</p>}
                    </section>
                </div>
            )
        } else {
            return <Loader/>
        }
    }
}

export default ObligationsList;
