import React, {memo, useState} from 'react';
import PropTypes from "prop-types";

import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Grid from "worksmith/components/Grid/Grid";
import Icon from "worksmith/components/Icon/Icon";
import StandardItemEdit from "./StandardItemEdit";
import Text from "worksmith/components/Text/Text";
import {AlignItems, JustifyContent} from "worksmith/enums/CSSEnums";
import {IconType} from "worksmith/enums/MaterialEnums";

const StandardItem = ({
                          client,
                          getClientStandardItems,
                          lineItem,
                          serviceLine,
}) => {

    const [edit, setEdit] = useState(false);
    const [standardItems, setStandardItems] = useState(lineItem.standardItemsForClient);


    return (
        <>
            {(!edit) ?
                <>
                    {standardItems.length === 0 ?
                        <Grid container>
                            <Grid item xs={12}>
                                <Text>No items. Click Edit to add items.</Text>
                            </Grid>
                        </Grid>
                        :
                        <Grid container justify={JustifyContent.FLEX_START} alignItems={AlignItems.FLEX_START}>
                            {standardItems.map(item => {
                                return (
                                    <Grid item xs={3} key={item.id} margin={'0 0 20px 0'}>
                                        <Text>{item.name}</Text>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                    <Grid constainer xs={12} margin={'10px 0 0 0'}>
                        <Button
                            onClick={() => setEdit(true)}
                            variant={ButtonVariant.OUTLINED}
                            startIcon={<Icon name={IconType.EDIT}/>}
                        >
                            Edit
                        </Button>
                    </Grid>
                </>
                :
                <StandardItemEdit
                    client={client}
                    getClientStandardItems={getClientStandardItems}
                    lineItem={lineItem}
                    serviceLine={serviceLine}
                    setEdit={setEdit}
                    setStandardItems={setStandardItems}
                    standardItems={standardItems}
                />
            }
        </>
    )
};

export default memo(StandardItem);

StandardItem.propTypes = {
    client: PropTypes.object,
    getClientStandardItems: PropTypes.func,
    lineItem: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        isAvailableForStandardItems: PropTypes.bool,
        standardItemsForClient: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                client: PropTypes.shape({
                    id: PropTypes.number
                })
            })
        )
    }),
    serviceLine: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        lineItemTypes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isAvailableForStandardItems: PropTypes.bool,
                standardItemsForClient: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        client: PropTypes.shape({
                            id: PropTypes.number
                        })
                    })
                )
            })
        )
    }),
};