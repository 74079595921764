import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Button, {ButtonSize, ButtonVariant} from "worksmith/components/Button/Button.web";
import MenuItem from "worksmith/components/Menu/MenuItem.web";
import MenuButton from "worksmith/components/MenuButton/MenuButton.web";
import CancelObligationModal from "worksmith/components/CancelObligationModal/CancelObligationModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import CreateScheduleOptionsDialog
    from "worksmith/composite-components/ScheduleOptionsForm/CreateScheduleOptionsDialog";
import ChangeRequestType from "../../shared/enums/api/cr/ChangeRequestType";
import ObligationService from "worksmith/services/api/ObligationService";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import ObligationStatus from "../../shared/enums/api/task/ObligationStatus";
import UserType from "worksmith/enums/api/user/UserType";
import ScheduleOptionType from "worksmith/enums/api/proposal/ScheduleOptionType";
import RaiseConcernDialog from "worksmith/composite-components/RaiseConcern/RaiseConcernDialog";
import ClientSignOffModal from "worksmith/composite-components/ClientSignOff/ClientSignOffModal";
import ChangeOrderRequestModal from "worksmith/components/ChangeOrderRequest/ChangeOrderRequestModal";
import ChangeOrderRequestCancelModal from "worksmith/components/ChangeOrderRequest/ChangeOrderRequestCancelModal";
import ClientChangeOrderDetailsDialog from "worksmith/components/ChangeOrderRequest/ClientChangeOrderDetailsDialog";
import ResolveConcernDialog from "worksmith/composite-components/ResolveConcern/ResolveConcernDialog";

const obligationService = new ObligationService();

const CalendarListViewActionButton = (props) => {

    const createChangeRequest = async (schedules) => {
        let {obligation, reload} = props;

        let changeRequest = {
            scheduleHandler: {
                scheduleOptions: schedules
            },
            type: ChangeRequestType.TIME_CHANGE
        };

        changeRequest.obligation = {
            id: obligation.id
        };
        await obligationService.addChangeRequest(obligation.id, changeRequest, false, false, false);
        reload(true);
    };

    const resolveConcern = async () => {
        await obligationService.resolveConcern(obligation.openConcern.id);
        reload(true)
    };

    const unconfirmJob = async () => {
        await obligationService.unconfirmJob(obligation.id);
        reload(true)
    };

    let {obligation, cancelChangeRequest, reload, variant, onSubmit, inAdminPortal, existingChangeRequestId, existingChangeOrderId, obligationId} = props;

    let hasOpenChangeRequest = obligation.openChangeRequests.length >= 1;
    let openChangeRequest = null;
    if(hasOpenChangeRequest)
        openChangeRequest = obligation.openChangeRequests[0];

    let [showActionMenu, setShowActionMenu] = useState(false);

    let [showRaiseAConcernModal, setShowRaiseAConcernModal] = useState(false);
    let [showResolveConcernModal, setShowResolveConcernModal] = useState(false);
    let [showConfirmJobModal, setShowConfirmJobModal] = useState(false);

    //these options should only be available in client portal
    let [showCancelObligationModal, setShowCancelObligationModal] = useState(false);
    let [showScheduleOptionsDialog, setShowScheduleOptionsDialog] = useState(false);

    //these options should only be available in admin portal
    let [showOpenChangeOrderModal, setShowOpenChangeOrderModal] = useState(false);
    let [showEditChangeOrderModal, setShowEditChangeOrderModal] = useState(false);
    let [showCancelChangeOrderModal, setShowCancelChangeOrderModal] = useState(false);
    let [showReviewChangeOrderModal, setShowReviewChangeOrderModal] = useState(false);

    let menuOptions = [];
    if(!inAdminPortal) {
        if (ValueIsSet(openChangeRequest) && openChangeRequest.initiatedByClient) {
            menuOptions.push(
                <MenuItem onClick={() => {
                    cancelChangeRequest(openChangeRequest.id);
                    reload(true);
                }}>
                    Cancel Request to Reschedule
                </MenuItem>
            );
        } else if (!ValueIsSet(openChangeRequest) && obligation.status !== ObligationStatus.COMPLETED) {
            menuOptions.push(<MenuItem onClick={() => {
                setShowActionMenu(false);
                setShowScheduleOptionsDialog(true);
            }}>Request New Visit Time</MenuItem>)
        }
        if (obligation.isForRecurringService && obligation.status !== ObligationStatus.COMPLETED) {
            menuOptions.push(
                <MenuItem onClick={() => {
                    setShowActionMenu(false);
                    setShowCancelObligationModal(true);
                }}>Cancel Visit</MenuItem>
            );
        }
    }


    if(obligation.isContestable){
        menuOptions.push(
            <MenuItem onClick={() => {
                setShowActionMenu(false);
                setShowRaiseAConcernModal(true);
            }}>Raise Concern</MenuItem>
        );
    }
    // commenting out instead of deleting in case we want to bring it back
    // else if(obligation.hasOpenConcern){
    //     menuOptions.push(
    //         <MenuItem onClick={() => {
    //             setShowActionMenu(false);
    //             setShowResolveConcernModal(true);
    //         }}>Resolve Concern</MenuItem>
    //     );
    // }

    if(obligation.client.storeSignOffEnabled && obligation.awaitingClientSignOff){
        menuOptions.push(
            <MenuItem onClick={() => {
                setShowActionMenu(false);
                setShowConfirmJobModal(true);
            }}>Sign Off On Job</MenuItem>
        );
    }
    else if(obligation.client.storeSignOffEnabled && obligation.clientCanUnconfirmJob){
        menuOptions.push(
            <MenuItem onClick={() => {
                setShowActionMenu(false);
                unconfirmJob();
            }}>Unconfirm Job</MenuItem>
        );
    }

    if(inAdminPortal && obligation.isForRecurringService){
        if(!ValueIsSet(existingChangeOrderId) ){
            menuOptions.push(<MenuItem onClick={() => {
                setShowOpenChangeOrderModal(true);
                setShowActionMenu(false);}}
                      disabled={existingChangeRequestId}
            >
                Open Change Order
            </MenuItem>)
        }
        if(ValueIsSet(existingChangeOrderId)) {
            menuOptions.push(<MenuItem onClick={() => {
                setShowEditChangeOrderModal(true);
                setShowActionMenu(false);
            }}>
                Edit Change Order
            </MenuItem>)
        }
        if(ValueIsSet(existingChangeOrderId)){
            menuOptions.push(<MenuItem onClick={() => {
                setShowReviewChangeOrderModal(true);
                setShowActionMenu(false);
            }}>
                Review Change Order
            </MenuItem>)
        }
        if(ValueIsSet(existingChangeOrderId)){
            menuOptions.push(<MenuItem onClick={() => {
                setShowCancelChangeOrderModal(true);
                setShowActionMenu(false);
            }}>
                Cancel Change Order
            </MenuItem>)
        }
    }

    return <Fragment>
        {menuOptions.length > 0 ?
            <MenuButton
                Button={
                    <Button primary
                            variant={variant}
                            size={ButtonSize.MEDIUM}
                            endIcon={<ArrowDropDownIcon style={{fontSize: 30}}/>}
                            onClick={() => setShowActionMenu(true)}>
                        ACTIONS
                    </Button>
                }
                onClose={() => setShowActionMenu(false)}
                open={showActionMenu}>
                {menuOptions}
            </MenuButton>
            :
            null}
        <RaiseConcernDialog
            obligationId={obligation.id}
            onCancel={() => setShowRaiseAConcernModal(false)}
            onRaiseConcern={() => reload(true)}
            open={showRaiseAConcernModal}/>
        {
             ValueIsSet(obligation.openConcern) ?
                <ResolveConcernDialog
                    concernId={obligation.openConcern.id}
                    onCancel={() => setShowResolveConcernModal(false)}
                    onResolveConcern={() => {
                        setShowResolveConcernModal(false);
                        reload(true);
                    }}
                    open={showResolveConcernModal}/>
                :
                 null
        }
        <ClientSignOffModal
            obligationId={obligation.id}
            onCancel={() => setShowConfirmJobModal(false)}
            onCompletion={() => reload(true)}
            open={showConfirmJobModal}/>
        <CancelObligationModal
            locationName={obligation.clientLocation.title}
            obligationId={obligation.id}
            onClose={() => setShowCancelObligationModal(false)}
            open={showCancelObligationModal}
            serviceLineName={obligation.serviceLine.name}
            serviceDate={moment(obligation.obligationDate)}
            onCompletion={() => reload(false)}
            isForNonWorksmithClient={obligation.isForNonWorksmithClient}
            userType={UserType.CLIENT}
        />
        <CreateScheduleOptionsDialog
            clientLocation={obligation.clientLocation}
            title={"Propose New Visit Time"}
            open={showScheduleOptionsDialog}
            onSubmit={(schedules) => {
                createChangeRequest(schedules);
                setShowScheduleOptionsDialog(false);
            }}
            onCancel={() => setShowScheduleOptionsDialog(false)}
            numberOfSchedules={1}
            scheduleOptionType={ScheduleOptionType.RECURRING_VISIT}
            serviceLine={obligation.serviceLine}
            showCurrentSchedule={true}
            obligation={props.obligation}
            obligationChangeRequest={openChangeRequest}
            userType={UserType.CLIENT}
            vendor={obligation.vendor.officialName}/>
        {showOpenChangeOrderModal ?
            <ChangeOrderRequestModal
                onClose={() => setShowOpenChangeOrderModal(false)}
                open={showOpenChangeOrderModal}
                obligationId={obligationId}
                reloadPage={onSubmit}
            /> : null
        }
        {showCancelChangeOrderModal ?
            <ChangeOrderRequestCancelModal
                onClose={() => setShowCancelChangeOrderModal(false)}
                open={showCancelChangeOrderModal}
                changeOrderRequestId={existingChangeOrderId}
                reloadPage={onSubmit}
            /> : null
        }
        {showEditChangeOrderModal ?
            <ChangeOrderRequestModal
                isEdit={true}
                onClose={() => setShowEditChangeOrderModal(false)}
                open={showEditChangeOrderModal}
                obligationId={obligationId}
                reloadPage={onSubmit}
            /> : null
        }
        {showReviewChangeOrderModal ?
            <ClientChangeOrderDetailsDialog
                changeOrderRequestId={existingChangeOrderId}
                onClose={() => setShowReviewChangeOrderModal(false)}
                open={showReviewChangeOrderModal}
                reloadPage={onSubmit}
                showWholesalePrice={true}
            /> : null
        }

    </Fragment>;
};

CalendarListViewActionButton.defaultProps = {
    variant: ButtonVariant.CONTAINED,
    inAdminPortal: false
};

CalendarListViewActionButton.propTypes = {
    obligation: PropTypes.object,
    reload: PropTypes.func,
    cancelChangeRequest: PropTypes.func,
    variant: PropTypes.string,
    inAdminPortal: PropTypes.bool,
    existingChangeOrderId: PropTypes.number,         //currently only used in admin portal
    existingChangeRequestId: PropTypes.number,       //currently only used in admin portal
    onSubmit: PropTypes.func,                        //currently only used in admin portal
    obligationId: PropTypes.number,                  //currently only used in admin portal
};



export default CalendarListViewActionButton;