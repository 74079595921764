import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import CustomPropTypes from "../../custom-prop-types/CustomPropTypes";
import ObligationStatus from "../../enums/api/task/ObligationStatus";
import RecurrenceType from "../../enums/api/task/RecurrenceType";
import {ValueIsSet} from "../../helpers/GenericHelpers";
import FieldTitleAndBody from "../FieldTitleAndBody/FieldTitleAndBody";
import FileUpload from "../../components/FileUpload/FileUpload";
import Schedule from "../../components/Schedule/Schedule.web";
import Text, {TextVariant} from "../../components/Text/Text.web";
import DownloadCompletionDetailsButton
    from "worksmith/composite-components/JobCompletionDetails/DownloadCompletionDetailsButton";
import Grid from "worksmith/components/Grid/Grid.web";
import moment from "moment";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import {WordBreak} from "worksmith/enums/CSSEnums";


const WalkthroughScheduledAndCompletedFields = ({activeObligation}) => {
    const {
        completionDate,
        leadTechnician,
        obligationAttachments,
        obligationId,
        requestId,
        schedule,
        status,
        updatedArrivalTime,
        summaryOfWorkCompleted
    } = activeObligation;

    let attachmentsList = [];
    if (ValueIsSet(obligationAttachments) && obligationAttachments.length > 0) {
        attachmentsList = obligationAttachments.map((attachment) => (
            {
                url: attachment.singleUseUrl,
                name: ValueIsSet(attachment.fileName) ? attachment.fileName : attachment.name
            }
        ));
    }

    return (
        <Fragment>
                {
                    status === ObligationStatus.COMPLETED ?
                        <Fragment>
                            <Grid container item xs={12}>
                                <Grid item xs={6} sm={3}>
                                    <DownloadCompletionDetailsButton
                                        completionDate={completionDate}
                                        leadTechnician={leadTechnician}
                                        obligationAttachments={obligationAttachments}
                                        obligationId={obligationId}
                                        requestId={requestId}
                                        summaryOfWorkCompleted={summaryOfWorkCompleted}
                                    />
                                </Grid>
                            </Grid>
                            <FieldTitleAndBody
                                item
                                title={'Summary of Work Completed'}
                                body={
                                    <Grid container item xs={12}>
                                        {updatedArrivalTime ?
                                            <Grid item xs={12}><Text variant={TextVariant.BODY_1}>
                                                Arrival Time - {moment(updatedArrivalTime,MomentFormat.HoursMinutesSeconds).format(MomentFormat.StandardTime)}
                                            </Text></Grid> : null}
                                        {leadTechnician ?
                                            <Grid item xs={12}><Text variant={TextVariant.BODY_1}>Lead Technician - {leadTechnician}</Text></Grid> : null}
                                        {summaryOfWorkCompleted ?
                                            <Grid item xs={12}><Text variant={TextVariant.BODY_1} wordBreak={WordBreak.BREAK_WORD}>{summaryOfWorkCompleted}</Text></Grid> :
                                            <Grid item xs={12}><Text variant={TextVariant.BODY_1}>No Summary</Text></Grid>}
                                    </Grid>
                                }/>
                            <FieldTitleAndBody
                                key={'completionPhotos'}
                                xs={10}
                                item
                                title={'Completion Photos/Files'}
                                body={
                                    attachmentsList.length > 0 ?
                                        <FileUpload initialFiles={attachmentsList} readOnly/>
                                        :
                                        'None'
                                }/>
                        </Fragment>
                        :
                        null
                }
                <FieldTitleAndBody
                    key={'serviceDate'}
                    xs={10}
                    item
                    title={'Service Date'}
                    body={
                        <Schedule
                            textVariant={TextVariant.BODY_1}
                            schedule={schedule}/>
                    }/>
        </Fragment>
    )
};

WalkthroughScheduledAndCompletedFields.propTypes = {
    activeObligation: PropTypes.shape({
        completionDate: PropTypes.string,
        leadTechnician: PropTypes.string,
        obligationAttachments: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            singleUseUrl: PropTypes.string
        })),
        obligationId: PropTypes.number,
        requestId: PropTypes.number,
        schedule: PropTypes.shape({
            arrivalEndTime: PropTypes.string,
            arrivalStartTime: PropTypes.string,
            date: PropTypes.string,
            dayOfMonth: PropTypes.bool,
            daysOfWeek: PropTypes.arrayOf(PropTypes.number),
            duration: PropTypes.number,
            recurrenceType: CustomPropTypes.enum(RecurrenceType),
            repeatEvery: PropTypes.number,
            returnDate: PropTypes.string
        }),
        status: CustomPropTypes.enum(ObligationStatus),
        summaryOfWorkCompleted: PropTypes.string,
    })
};

export default WalkthroughScheduledAndCompletedFields;