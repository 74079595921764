import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web";
import {TextAlign} from "worksmith/enums/CSSEnums";
import styled from 'styled-components';
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const RecurringServiceServiceLineSummaryString = (props) => {
    const {align, pendingSetUpServiceCount, ongoingServiceCount} = props;

    let summaryTextList = [];
    if(ValueIsSet(ongoingServiceCount) && ongoingServiceCount > 0)
        summaryTextList.push(ongoingServiceCount + " ongoing");
    if(ValueIsSet(pendingSetUpServiceCount) && pendingSetUpServiceCount > 0)
        summaryTextList.push(pendingSetUpServiceCount + " pending setup");

    let summary_text = "Services: ";
    for(let i=0; i < summaryTextList.length; i++){
        summary_text += summaryTextList[i];
        if(i !== summaryTextList.length - 1)
            summary_text += ' | ';
    }

    return <SummaryText color={TextColor.TEXT_SECONDARY} variant={TextVariant.SUBTITLE_1} light align={align}>{summary_text}</SummaryText>
};

const SummaryText = styled(Text)`
 padding-top: 0.2em;
`;

RecurringServiceServiceLineSummaryString.propTypes = {
    align: CustomPropTypes.enum(TextAlign),
    pendingSetUpServiceCount: PropTypes.number,
    ongoingServiceCount: PropTypes.number
};

export default RecurringServiceServiceLineSummaryString;