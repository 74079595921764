import React from 'react';
import { Route } from 'react-router-dom'

const NavigationButton = (props) => (
    <Route render={({ history}) => (
        <button
            type='button'
            className='ws-btn ws-btn-primary'
            onClick={() => { history.push(props.route) }}>
            {props.icon ? <i className={`icon-${props.icon}`} />: null}
            {props.message}
        </button>
    )} />
)

export default NavigationButton;