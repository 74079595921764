import React, {Component} from 'react';

import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import {WithContext} from "../../context/GlobalContext";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import Loader from "worksmith/antd-components/Loader/Loader";
import SingleLocationServiceLinePageView from "./SingleLocationServiceLinePageView";
import {LoaderType} from "worksmith/enums/LoaderType";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {ParamStringToObject} from "../../Helpers";

const graphQLServiceClass = new AsyncGraphQLServiceClass();

class SingleLocationServiceLinePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: [],
            obligationTemplates: [],
            location: null,
            loading: true,
            filterString: [],
            serviceLine: null
        };
    }

    componentDidMount = async () => {
        await this.loadPage();
    };

    loadPage = async () => {
        let {client} = this.props;
        let {serviceLineId, locationId} = this.props.match.params;

        let obligationTemplateSearchParams = {
            clientIds: [client.id],
            serviceLineId: serviceLineId,
            clientLocationIds: [locationId],
            filterByRequester: true
        };

        let requestSearchParams = {
            clientId: client.id,
            clientLocationIds: [locationId],
            hasNotYetBeenScheduled: true,
            isRecurring: true,
            serviceLineIds: [serviceLineId],
            filterByRequester: true
        };

        let obligationTemplates = graphQLServiceClass.findAll(
            obligationTemplateSearchParams,
            GraphQLObjectType.OBLIGATION_TEMPLATE,
            obligationTemplateFields
        );

        let requests = graphQLServiceClass.findAll(
            requestSearchParams,
            GraphQLObjectType.REQUEST,
            requestFields
        );

        let location = graphQLServiceClass.findOneById(
            locationId,
            GraphQLObjectType.CLIENT_LOCATION,
            clientLocationFields
        );

        let serviceLine = graphQLServiceClass.findOneById(
            serviceLineId,
            GraphQLObjectType.SERVICE_LINE,
            serviceLineFields
        );

        await Promise.all([obligationTemplates, requests, location, serviceLine]).then(
            data => {
                this.setState({obligationTemplates: data[0], requests: data[1], location: data[2], serviceLine: data[3], loading:false});
            }
        );
    };


    render(){
        let {requests, obligationTemplates, serviceLine, loading, location} = this.state;
        let {loadPage} = this;
        let filteredRequests = requests.filter(r => !obligationTemplates.map(ot => ValueIsSet(ot.requestForProposal) ? ot.requestForProposal.id : -1).includes(r.id));
        let params = ParamStringToObject(this.props.history.location.search);
        let obligationTemplateId = ValueIsSet(params.obligationTemplateId) ? parseInt(params.obligationTemplateId) : null;

        return loading ? <Loader loaderType={LoaderType.LOGO}/> :
            <SingleLocationServiceLinePageView
                reload={loadPage}
                requests={filteredRequests}
                obligationTemplates={obligationTemplates}
                obligationTemplateId={obligationTemplateId}
                serviceLine={serviceLine}
                location={location}
            />;
    }
}

const obligationTemplateFields = `
    id
    client {
        id
        storeSignOffEnabled
    }
    clientLocation {
        id
        title
        addressLineOne
        addressLineTwo
        state
        city
        zip
    }
    conversation {
        id
        isOpen
        messageCount
        unreadMessageCount
    }
    serviceLine { 
        id 
        name
    }
    vendor {
        id
        officialName
    }
    vendorLocation {
        rating
        ratingsCount
        vendor {
            officialName
        }
    }
    requestForProposal {
        id
        description
        hasPendingChangeOrderRequest
        concernRaisedActivities {
            createdTimestamp
        }
    }
    cancellationReason
    recurringScheduleTemplate {
        readableScheduleString
        duration
        frequency{
            recurrenceType
            recurrenceCount
            repeatEvery
        }
    }
    schedule{
        arrivalStartTime
        arrivalEndTime
        readableString
        date
        dayOfMonth
        daysOfWeek
        duration
        recurrenceType
        repeatEvery
        returnDate
        scheduleDows {
            dayOfWeek
            arrivalStartTime
            arrivalEndTime
        }
    }
    status
    totalRetailPrice
    nextUpcomingObligationDate
    lastCompletedVisitDate
    scopeOfWork
    createdTimestamp
    updatedTimestamp
`;

const requestFields = `
    id
    description
    clientLocation {
        id
        title
        state
        city
    }
    serviceLine { 
        id 
        name
    }
    status
`;

const clientLocationFields = `
    id
    title
    state
    city
`;

const serviceLineFields = `
    id
    name
`;

export default WithContext(SingleLocationServiceLinePage);