import React, {Component, Fragment} from 'react';
import GraphQLServiceClass from "worksmith/services/graphql/GraphQLServiceClass";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import PropTypes from "prop-types";
import Modal from "worksmith/components/Modal/Modal";
import AlertModalTemplate from "worksmith/components/Modal/Templates/AlertModalTemplate";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import RequestChecklistModalView from "worksmith/components/Contract/RequestChecklist/RequestChecklistModalView.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const graphQLService = new GraphQLServiceClass();

class RequestChecklistModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            information: null,
            loading:     false,
            open:        false
        };
    };

    componentDidMount() {
        this.getStandardOperatingProcedure();

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.serviceLineId !== this.props.serviceLineId || prevProps.clientId !== this.props.clientId)
            this.getStandardOperatingProcedure();
    }

    getStandardOperatingProcedure = () => {
        const { clientId, serviceLineId } = this.props;

        graphQLService.findAll(
            {clientId, isActive: true, serviceLineIds: [serviceLineId]},
            GraphQLObjectType.STANDARD_OPERATING_PROCEDURE,
            RequestChecklistModal.queryFields
        ).then((sop) => {
            if(sop.length > 0)
                this.setState({information: sop[0].description, loading: false});
            else
                this.setState({loading: false});
        }).catch((error) => {
            console.log(error);
            this.setState({loading: false});
        });
    };

    onClose = () => {
        this.setState({open: false});
    };

    onOpen = () => {
        this.setState({open: true});
    };

    render() {
        const { locationTitle, serviceLineName } = this.props;
        const { information, loading, open } = this.state;
        const { onClose, onOpen } = this;

        return (
            <Fragment>
                {ValueIsSet(information) ? <Fragment>
                <Button variant={ButtonVariant.OUTLINED}
                        primary
                        onClick={onOpen}>
                    Request Checklist
                </Button>
                <Modal open={open}
                       onClose={onClose}>
                    <AlertModalTemplate
                        onOk={() => {}}
                        body={<RequestChecklistModalView information={information}
                                                         loading={loading}
                                                         locationTitle={locationTitle}
                                                         serviceLineName={serviceLineName}/>}
                        buttons={[
                            <Button key={1} primary variant={ButtonVariant.OUTLINED} onClick={onClose}>
                                CLOSE
                            </Button>
                        ]}
                    />
                </Modal>
            </Fragment> : null}
            </Fragment>
        )
    }
}

RequestChecklistModal.queryFields = `
    description
    id
    serviceLine {
        name
    }
`;

RequestChecklistModal.propTypes = {
    clientId:        PropTypes.number.isRequired,
    locationTitle:   PropTypes.string.isRequired,
    serviceLineId:   PropTypes.number.isRequired,
    serviceLineName: PropTypes.string.isRequired
};

export default RequestChecklistModal;