// This file was generated from com.worksmith.task.controller.ObligationItemGroupController
// noinspection JSUnusedGlobalSymbols

import Http from '../Http/Http';
import {RequestMethod} from '../utilities/HttpEnums';

// noinspection JSUnusedLocalSymbols
const {DELETE, GET, HEAD, OPTIONS, PATCH, POST, PUT} = RequestMethod;

class ObligationItemGroupService extends Http {
    constructor() {
        super('/obligationItemGroups')
    }
    
    addAttachments(id, attachments) {
        return this.callApi( `${id}/attachments/multiple`, POST, null, null, null, true, attachments);
    }
    
    addComment(id, obligationItemGroupComment) {
        return this.callApi( `${id}/comments`, POST, obligationItemGroupComment, null, null, true);
    }

    dcaManifestCSV(dcaManifestCsvCreateParams) {
        return this.callApi( `dcaManifestCSV`, POST, dcaManifestCsvCreateParams, null , null, true);
    }

    delete(id) {
        return this.callApi( `delete/${id}`, DELETE, null, null, null, true);
    }
    
    deleteAttachment(id, attachmentId) {
        return this.callApi( `${id}/attachments/${attachmentId}`, DELETE, null, null, null, true);
    }
    
    deleteWithUpdater(id, updater) {
        let params = {
            updater
        };
    
        return this.callApi( `${id}`, DELETE, null, params, null, true);
    }
    
    findAll() {
        return this.callApi( `findAll`, GET, null, null, null, true);
    }
    
    findAllByIds(ids) {
        return this.callApi( `findAllByIds`, POST, ids, null, null, true);
    }
    
    findClientSummaries(obligationItemGroupSearchParams) {
        return this.callApi( `findClientSummaries`, POST, obligationItemGroupSearchParams, null, null, true);
    }
    
    findFullDetails(obligationItemGroupSearchParams) {
        return this.callApi( `findFullDetails`, POST, obligationItemGroupSearchParams, null, null, true);
    }
    
    findOne(id) {
        return this.callApi( `findOne/${id}`, GET, null, null, null, true);
    }
    
    findSummaries(obligationItemGroupSearchParams) {
        return this.callApi( `findSummaries`, POST, obligationItemGroupSearchParams, null, null, true);
    }
    
    getAttachments(id) {
        return this.callApi( `${id}/attachments`, GET, null, null, null, true);
    }
    
    getClientSummariesCsv(ticketsIds) {
        return this.callApi( `getClientSummariesCsv`, POST, ticketsIds, null, null, true);
    }
    
    getComments(id) {
        return this.callApi( `${id}/comments`, GET, null, null, null, true);
    }
    
    getEditableTicket(id) {
        return this.callApi( `${id}/editableTicket`, GET, null, null, null, true);
    }
    
    getTicket(id) {
        return this.callApi( `${id}/ticket`, GET, null, null, null, true);
    }
    
    insert(businessObject) {
        return this.callApi( `insert`, POST, businessObject, null, null, true);
    }
    
    insertTicket(ticket) {
        return this.callApi( `ticket`, POST, ticket, null, null, true);
    }
    
    recalculatePricing(ticketIds) {
        return this.callApi( `recalculatePricing`, PATCH, ticketIds, null, null, true);
    }
    
    shipItems(shipment) {
        return this.callApi( `shipItems`, POST, shipment, null, null, true);
    }
    
    update(businessObject) {
        return this.callApi( `update`, PUT, businessObject, null, null, true);
    }
    
    updateAll(businessObjects) {
        return this.callApi( `updateAll`, PUT, businessObjects, null, null, true);
    }
    
    updateStatus(id, update) {
        return this.callApi( `${id}/status`, PATCH, update, null, null, true);
    }
    
    updateTicket(id, ticket) {
        return this.callApi( `${id}/ticket`, PUT, ticket, null, null, true);
    }
    
    
}

export default ObligationItemGroupService;
