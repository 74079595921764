import React from "react";
import PropTypes from 'prop-types';
import {Typography} from "antd";
const {Title: AntdTitle} = Typography;

const Title = (props) => <AntdTitle className={props.className} level={props.level}>{props.children}</AntdTitle>;

Title.propTypes = {
    level: PropTypes.number,
};

Title.defaultProps = {
    level: 4,
};

export default Title;