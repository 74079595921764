import React from "react";
import PropTypes from 'prop-types';
import Loader from "worksmith/components/Loader/Loader";
import Accordion from "worksmith/components/Accordion/Accordion"
import Text, {TextColor, TextVariant} from "worksmith/components/Text/Text.web"
import styled from 'styled-components';
import Grid from "worksmith/components/Grid/Grid.web";
import LocationContractDetails from "../LocationContractDetails";
import {StateFullName} from "worksmith/enums/StateFullName";
import {Color} from "worksmith/enums/Color"

const StateContainerDiv = styled.div`
    display: block;
    width: 100%;
    margin: 1em 0em;
    background-color:${Color.TRANSPARENT};
`;

const StateTitlePanel = styled.div`
    display: block;
    min-height: 60px;
    padding: 0px 24px;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.12);
    background-color:white;
`;

const StateTitle = styled.div`
    display: block;
    padding: 18px 0px;
    font-weight: 600;
    font-size:20px; 
    letter-spacing: 0.25px;
    font-family:"Open Sans";
`;

const Summary = (item) => {
    return (
        <Grid container>
            <Grid item md={11} xs={10}>
                <Text variant={TextVariant.SUBTITLE_1}>{item.clientLocation.city} - {item.clientLocation.title}</Text>
            </Grid>
        </Grid>
    )
};

const LocationContractListView = (props) => {
    let {locationContractsByState} = props;

        return (Object.keys(locationContractsByState).map((key) => {
            let fullStateName = StateFullName[key] ? StateFullName[key] : key;
                return (
                    <StateContainerDiv key={key}>
                        <StateTitlePanel>
                            <StateTitle>{fullStateName}</StateTitle>
                        </StateTitlePanel>
                        {
                            locationContractsByState[key].sort().map((item) => {

                                return (<Accordion
                                        key={item.id}
                                        transitionProps={{unmountOnExit: true}}
                                        summary={Summary(item)}
                                        details={
                                            <LocationContractDetails locationId={item.clientLocation.id}/>
                                        }
                                    />
                                )
                            })
                        }
                    </StateContainerDiv>
                )
            })
        );
    };

LocationContractListView.propTypes = {
    locationContractsByState: PropTypes.shape({
        state: PropTypes.arrayOf(PropTypes.shape({
            clientLocation: PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string
            })
        }))
    })
};

export default LocationContractListView;