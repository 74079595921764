import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import {GraphQLObjectType} from "worksmith/enums/GraphQLObjectType";
import AsyncGraphQLServiceClass from "worksmith/services/graphql/AsyncGraphQLServiceClass";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import {RequestType} from "../../../Enums";
import JobDetailsSidePanelView from "worksmith/composite-components/JobDetails/JobDetailsSidePanelView";
import {useDeviceSize} from "worksmith/helpers/StylingHelpers";
import SwipeableDrawer, {SwipeableDrawerAnchor} from "worksmith/components/SwipeableDrawer/SwipeableDrawer";
import ChangeRequestService from "worksmith/services/api/ChangeRequestService";
import {DisplaySuccessNotification} from "../../helpers/SweetAlertHelpers";


const asyncGraphQLServiceClass = new AsyncGraphQLServiceClass();
const changeRequestService = new ChangeRequestService();

const JobDetailsSidePanel = props => {
    let {
        showJobDetails,
        setShowJobDetails,
        jobId,
        reloadPage,
        currentPage,
        pageLoading,
    } = props;

    const [isMobile] = useDeviceSize();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [changeOrderModal, setChangeOrderModal] = useState(false);
    const [priceModal, setPriceModal] = useState(false);

    const refetchJob = async (jobId) => {
        setLoading(true);
        let jobData = await asyncGraphQLServiceClass.findOneById(jobId, GraphQLObjectType.OBLIGATION, JobFields);
        if(ValueIsSet(jobData.request) && jobData.request.emergency){
            if(jobData.isWalkthrough){
                jobData.type = "Emergency Walkthrough"
            }
            else{
                jobData.type = "Emergency"
            }
        }
        else if(ValueIsSet(jobData.request) && jobData.request.type === RequestType.RECURRING &&
            ValueIsSet(jobData.obligationTemplate) && ValueIsSet(jobData.obligationTemplate.recurringScheduleTemplate.frequency.readableString)){
            jobData.type = jobData.obligationTemplate.recurringScheduleTemplate.frequency.readableString
        }
        else if(ValueIsSet(jobData.request) && jobData.request.type === RequestType.ON_DEMAND){
            if(jobData.isWalkthrough){
                jobData.type = "Walkthrough"
            }
            else{
                jobData.type = "One Time";
            }
        }

        jobData.attachments = ValueIsSet(jobData.request)  ? jobData.request.rfpAttachments.map(attachment => ({
            url: attachment.singleUseUrl,
            name: attachment.fileName
        }))
        :
        [];

        setJob(jobData);
        setLoading(false);
    };

    const reload = async (showJob) => {
        let promises = [];
        showJob ?  promises.push(refetchJob(jobId)) : setShowJobDetails(false)
        promises.push(reloadPage(currentPage - 1));
        await Promise.all(promises);
    };

    const cancelChangeRequest = (changeRequestId) => {

        changeRequestService.cancel(changeRequestId).then(async () => {
                DisplaySuccessNotification("Cancelled request to reschedule.").then();
            }
        )
    };

    useEffect(() => {
        if(jobId != null){
            refetchJob(jobId);
        }
    }, [jobId, pageLoading]);

    return (
        <>
            {showJobDetails && !loading && job !== null ?
                <>
                    {!isMobile ?
                        <Box marginTop={"76px"} width={"30%"} sx={{border: '1px solid lightgrey'}}>
                            <JobDetailsSidePanelView
                                job={job}
                                loading={loading}
                                cancelChangeRequest={cancelChangeRequest}
                                changeOrderModal={changeOrderModal}
                                setChangeOrderModal={setChangeOrderModal}
                                priceModal={priceModal}
                                setPriceModal={setPriceModal}
                                showJobDetails={showJobDetails}
                                setShowJobDetails={setShowJobDetails}
                                reload={reload}/>
                        </Box>
                    :
                        <SwipeableDrawer
                            anchor={SwipeableDrawerAnchor.BOTTOM}
                            onClose={() => setShowJobDetails(false)}
                            open={showJobDetails}
                            staticDrawer>
                            <JobDetailsSidePanelView
                                job={job}
                                loading={loading}
                                changeOrderModal={changeOrderModal}
                                setChangeOrderModal={setChangeOrderModal}
                                priceModal={priceModal}
                                setPriceModal={setPriceModal}
                                showJobDetails={showJobDetails}
                                setShowJobDetails={setShowJobDetails}
                                reload={reload}/>
                        </SwipeableDrawer>}
                </>

                :
                null}
        </>
    );
};

JobDetailsSidePanel.propTypes = {
    jobId: PropTypes.number,
    showJobDetails: PropTypes.bool,
    setShowJobDetails: PropTypes.func,
    reloadPage: PropTypes.func,
    currentPage: PropTypes.number,
    pageLoading: PropTypes.bool,
};


const JobFields = `
    id
    awaitingClientSignOff
    confirmed
    confirmedUser{
        displayName
    }
    confirmedTimestamp
    client{
        storeSignOffEnabled
    }
    clientCanUnconfirmJob
    isWalkthrough
    isForRecurringService
    isContestable
    hasPendingChangeOrderRequest
    latestNonCancelledChangeOrderRequest {
        id
    }
    hasOpenConcern
    resolvedConcerns{
        id
        resolutionNotes
        resolvedTimestamp
        resolvedUser{
            displayName
        }
        description
    }
    openConcern{
        id
        createdTimestamp
        creatorUser{
            displayName
        }
        description
    }
    status
    vendor{
        officialName
    }
    clientLocation{
        addressLineOne
        addressLineTwo
        state
        zip
        city
    }
    request{
        id
        description
        emergency
        type
        rfpAttachments{
            fileName
            singleUseUrl
        }
    }
    obligationItems{
        lineItemType {
            name
        }
        quantity
        title
        unitRetailPrice
        totalRetailPrice
    }
    obligationTemplate{
        recurringScheduleTemplate{
            frequency{
                readableString
            }
        }
    }
    openChangeRequests {
        id
        currentState
        expired
        status
        initiatedByClient
        requestedSchedules {
            arrivalStartTime
            arrivalEndTime
            readableString
            date
            dayOfMonth
            daysOfWeek
            duration
            recurrenceType
            repeatEvery
            returnDate
        }
    }
    serviceLine{
        name
    }
    totalRetailPrice
`;

export default JobDetailsSidePanel;