import React, {Component} from 'react';
import ObligationsList from "../../components/obligations/ObligationsList";
import UserService from 'worksmith/services/api/UserService';
import ChangeRequestList from "../../components/ChangeRequestList";
import EstimateList from "../../components/EstimateList";

const userService = new UserService();

class TicketDashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyType: props.user.companyType,
            actionItems: []
        };
    }

    componentDidMount() {
        let _this = this;
        userService.getActionItems().then(function (data) {
            _this.setState({actionItems: data})
        })
    }

    render() {
        return (
            <div className={'margin-top-md'}>

                <div className="row">
                    <div className="col-md-12">
                        <EstimateList user={this.props.user}/>
                    </div>
                </div>
                    
                <ObligationsList match={this.props.match} history={this.props.history} user={this.props.user} client={this.props.client}/>

                <div className="row">
                    <div className="col-md-12">
                        <ChangeRequestList user={this.props.user} clientConfigurations={this.props.client.configurationSettings} />
                    </div>
                </div>
            </div>
        )
    }
}

export default TicketDashboardPage;
