import React from 'react';
import PropTypes from 'prop-types';
import Text, {TextColor} from "worksmith/components/Text/Text.web";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";
import LineItemBreakdownList from "worksmith/components/LineItem/LineItemBreakdownList";
import styled from "styled-components";
import CustomPropTypes from "worksmith/custom-prop-types/CustomPropTypes";
import PortalType from "worksmith/enums/api/user/PortalType";

const ChangeOrderComparisonView = (props) => {
    const {originalScopeOfWork, changedScopeOfWork, originalLineItems, changedLineItems, showWholesalePrice, supportingText, portalType} = props;

    return (
        <>
            <StyledPaddedText>{supportingText}</StyledPaddedText>
            <StyledPaddedText>ORIGINAL ESTIMATE</StyledPaddedText>
            <StyledPaddedText bold>Terms and Conditions:</StyledPaddedText>
            <StyledPaddedText>{ValueIsSet(originalScopeOfWork) ? originalScopeOfWork : "none" }</StyledPaddedText>
            <br/>
            <StyledPaddedText>Price Breakdown:</StyledPaddedText>
            <LineItemBreakdownList showWholesale={showWholesalePrice} showDivider={false} lineItems={originalLineItems} portalType={portalType}/>
            <br/>
            <StyledPaddedText color={TextColor.ERROR}>CHANGE ORDER </StyledPaddedText>
            <StyledPaddedText bold color={TextColor.ERROR}>Terms and Conditions:</StyledPaddedText>
            <StyledPaddedText color={TextColor.ERROR}>{ValueIsSet(changedScopeOfWork) ? changedScopeOfWork : "none" }</StyledPaddedText>
            <br/>
            <StyledPaddedText color={TextColor.ERROR}>Price Breakdown:</StyledPaddedText>
            <LineItemBreakdownList showWholesale={showWholesalePrice} showDivider={false} lineItems={changedLineItems} textColor={TextColor.ERROR} portalType={portalType}/>
        </>
    )
};

ChangeOrderComparisonView.propTypes = {
    originalScopeOfWork: PropTypes.string,
    changedScopeOfWork: PropTypes.string,
    originalLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    changedLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    showWholesalePrice: PropTypes.bool,
    supportingText: PropTypes.string,
    portalType: CustomPropTypes.enum(PortalType)
};

ChangeOrderComparisonView.defaultProps = {
    supportingText: "If accepted, this pricing will replace the original. If declined, the original pricing will stay in place. Reminder: Do NOT do any additional work without approval."
};

const StyledPaddedText = styled(Text)`
    padding-bottom: 1.2em;
`;

export default ChangeOrderComparisonView;