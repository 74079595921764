import RequestTargetType from 'worksmith/enums/api/proposal/RequestTargetType';
import RecurrenceType from 'worksmith/enums/api/task/RecurrenceType';
import {ValueIsSet} from "./GenericHelpers";
import moment from "moment";
import {MomentFormat} from "../enums/MomentFormat";
import RfpType from "worksmith/enums/api/proposal/RfpType";

/*
 Pass this function a number, the singular of a word and the plural of that word.
 If the number is equal to 1, this function will return the singular word, else the plural word.
 If no plural word parameter is given, it simply adds an s to the end of the singular word
*/
export const Pluralize = (number, singularWord, pluralWord) => {
    if (number === 1) {
        return singularWord;
    }
    if (!ValueIsSet(pluralWord)) {
        return singularWord + "s";
    }
    return pluralWord;
};

export const TitleCase = (string) => {
    return string.trim().split(" ").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ").trim();
};

export const Uppercase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeEveryWord = (string) => {
    const words = string.split(" ");
    const capitalizedWords = words.map(word => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();
        return firstLetter + restOfWord;
    });
    return capitalizedWords.join(" ");
};

export const TruncateArrayToStringWithEllipsisAndNumberOfItemsTruncated = (array, numberOfLetters) => {
    let str = '';
    array.every((word, index) => {
        if ((str + ', ' + word).length < numberOfLetters) {
            str = str.length > 0 ? str + ', ' + word : word;
            return true
        } else {
            str = str + '... +' + (array.length - index);
            return false
        }
    })
    return str;
};

export const GetPossessiveName = (string) => {
    let possessiveName;
    if (string.charAt(string.length - 1) === 's') {
        possessiveName = `${string}'`
    } else {
        possessiveName = `${string}'s`
    }
    return possessiveName;
};

export const RequestTargetString = (target, format) => {
    return RequestTargetTypeToString(target.type) + " " + moment(target.date).format(format ? format : MomentFormat.DowMonthDayYearSlash)
};

export const RequestTargetTypeToString = targetType => {
    switch (targetType) {
        case RequestTargetType.BY_DATE:
            return "By";
        case RequestTargetType.ON_DATE:
            return "On";
    }
};

export const ConvertToDurationString = (duration) => {
    let durationString = '';
    let hours = Math.floor(duration / 60);
    let minutes = duration % 60;

    if (hours === 1)
        durationString += hours + ' hour ';
    else if (hours > 1)
        durationString += hours + ' hours ';
    if (hours > 0 && minutes > 0)
        durationString += 'and ';
    if (minutes > 0)
        durationString += minutes + ' minutes';

    return durationString;
};

export const getTimeStringFromMinutes = (minutes) => {
    if(!ValueIsSet(minutes) || minutes === 0)
        return "00:00";

    let timeString = "";

    if(minutes < 600)
        timeString+="0";

    timeString+=`${Math.floor(minutes/60)}`;
    timeString+=":";

    if(ValueIsSet(minutes) && minutes % 60 < 10)
        timeString+="0";

    timeString+=`${minutes % 60}`;

    return timeString;
};

export const getMinutesFromHoursAndMinutes = (hours, minutes) => {
    return (Number(hours) * 60) + Number(minutes);
};

// Function to convert any case type of recurrenceType to the proper case type for recurrenceType. It takes a recurrenceType as input and returns the corresponding capitalized recurrence type.
export const convertAnyCaseTypeToCapitalizeRecurrenceType = (recurrenceType) => {
    const lowerCaseRecurrenceType = recurrenceType.toLowerCase();
    if (lowerCaseRecurrenceType === "on-demand" || lowerCaseRecurrenceType === "on demand") {
        return RfpType.ON_DEMAND;
    } else {
        switch (lowerCaseRecurrenceType) {
            case RecurrenceType.DAILY.toLowerCase():
                return RecurrenceType.DAILY;
            case RecurrenceType.WEEKLY.toLowerCase():
                return RecurrenceType.WEEKLY;
            case RecurrenceType.MONTHLY.toLowerCase():
                return RecurrenceType.MONTHLY;
            case RecurrenceType.YEARLY.toLowerCase():
                return RecurrenceType.YEARLY
        }
    }
};

export const RecurrenceTypeToNoun = (recurrenceType) => {
    switch (recurrenceType) {
        case RecurrenceType.DAILY:
            return 'day';
        case RecurrenceType.WEEKLY:
            return 'week';
        case RecurrenceType.MONTHLY:
            return 'month';
        case RecurrenceType.YEARLY:
            return 'year'
    }
};

export const FrequencyString = (frequency) => {

    let {recurrenceType, repeatEvery, recurrenceCount} = frequency;

    let recurrenceSentence = '';

    const recurrenceNoun = RecurrenceTypeToNoun(recurrenceType);

    if(repeatEvery === 1 && recurrenceType === RecurrenceType.DAILY) return 'Daily';

    if (recurrenceCount != null && recurrenceCount === 1)
        recurrenceSentence += "Once a " + recurrenceNoun;

    if (recurrenceCount != null && recurrenceCount !== 1)
        if (recurrenceCount === 2)
            recurrenceSentence += "Twice a " + recurrenceNoun;
        else
            recurrenceSentence += recurrenceCount + " times a " + recurrenceNoun;

    if (ValueIsSet(repeatEvery) && repeatEvery > 0) {
        if (recurrenceCount != null)
            recurrenceSentence += ", every ";
        else
            recurrenceSentence += "Every ";
        if (repeatEvery !== 1) {
            recurrenceSentence += repeatEvery + " " + recurrenceNoun + "s";
        } else {
            recurrenceSentence += recurrenceNoun
        }
    }

    return recurrenceSentence.toString();
};

export const getRecurringString = (date, recurrenceType, repeatEvery, dayOfMonth, daysOfWeek) => {

    if (!ValueIsSet(repeatEvery))
        return null;

    if (!ValueIsSet(recurrenceType))
        return null;

    let dateStr;
    if (recurrenceType === RecurrenceType.DAILY) {
        dateStr = repeatEvery === 1 ? "Daily" : "Every " + repeatEvery + " days";
    } else if (recurrenceType === RecurrenceType.WEEKLY) {
        dateStr = repeatEvery === 1 ? "Weekly" : "Every " + repeatEvery + " weeks";
        dateStr += " on ";

        if (daysOfWeek.length !== 0 && ValueIsSet(daysOfWeek[0]) && (ValueIsSet(daysOfWeek[0].dayOfWeek) || ValueIsSet(daysOfWeek[0].number))) {
            let daysOfWeekNumberArray = daysOfWeek.map(dow => {
                if (ValueIsSet(dow.number)) {
                    return dow.number;
                } else if (ValueIsSet(dow.dayOfWeek.number)){
                    return dow.dayOfWeek.number
                } else {
                    return dow.dayOfWeek
                }
            }).sort();
            let daysOfWeekString = daysOfWeekNumberArray.map(dow => getDayOfWeekStringFromNumber(dow));
            dateStr += convertToList(daysOfWeekString);
        } else {
            let daysOfWeekString = daysOfWeek.sort().map(dow => getDayOfWeekStringFromNumber(dow));
            dateStr += convertToList(daysOfWeekString);
        }
    } else if (recurrenceType === RecurrenceType.MONTHLY) {
        dateStr = repeatEvery === 1 ? "Monthly" : "Every " + repeatEvery + " months";

        if (ValueIsSet(dayOfMonth) && dayOfMonth) {
            dateStr += " on day " + moment(date).date();
        } else {
            dateStr += " on the " + getWeekOfMonth(date, true) + " " + moment(date).format(MomentFormat.FullDow);
        }
    } else { //YEARLY
        dateStr = repeatEvery === 1 ? "Yearly" : "Every " + repeatEvery + " years";

        if (ValueIsSet(dayOfMonth) && dayOfMonth) {
            dateStr += " on day " + moment(date).date() + " of " + moment(date).format(MomentFormat.FullMonth);
        } else {
            dateStr += " on the " + getWeekOfMonth(date, true) + " " + moment(date).format(MomentFormat.FullDow) + " of " + moment(date).format(MomentFormat.FullMonth);
        }
    }

    return dateStr;
};

export const getDayOfWeekStringFromNumber = (dow) => {
    let dowAsInt = parseInt(dow);
    switch (dowAsInt) {
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        case 7:
            return 'Sunday';
    }
};
export const getDayOfWeekNumberFromString = (dow) => {
    const formattedDayOfWeek = dow.toLowerCase().charAt(0).toUpperCase() + dow.toLowerCase().slice(1);
    switch (formattedDayOfWeek) {
        case 'Monday':
            return 1;
        case 'Tuesday':
            return 2;
        case 'Wednesday':
            return 3;
        case 'Thursday':
            return 4;
        case 'Friday':
            return 5;
        case 'Saturday':
            return 6;
        case 'Sunday':
            return 7;
    }
};

export const getWeekOfMonth = (date, includeSuffix) => {
    let weekOfMonth = Math.ceil(moment(date).date() / 7);
    switch (weekOfMonth) {
        case 1:
            return includeSuffix ? "1st" : weekOfMonth;
        case 2:
            return includeSuffix ? "2nd" : weekOfMonth;
        case 3:
            return includeSuffix ? "3rd" : weekOfMonth;
        default:
            return includeSuffix ? weekOfMonth + "th" : weekOfMonth;
    }
};

export const convertToList = (list) => {
    if (list.length === 1) {
        return list.toString();
    } else if (list.length === 2) {
        return list.join(" and ");
    } else {
        let listString = "";
        for(let i = 0; i < list.length; i++) {
            if(i === list.length - 1) {
                listString += "and " + list[i];
            }else {
                listString += list[i]+", ";
            }
        }
        return listString;
    }
};

export const toCurrencyString = (input, removeDecimals) => {
    let isNegative = false;
    if (!ValueIsSet(input)) {
        return null;
    }

    if(input < 0) {
        isNegative = true;
        input = Math.abs(input);
    }

    let currency = input.toFixed(removeDecimals ? 0 : 2).toString();
    let stopIndex = removeDecimals ? currency.length : currency.toString().indexOf('.');
    let startIndex = removeDecimals ? currency.length - 1 :  currency.toString().indexOf('.') - 1;
    let i;
    for(i = startIndex; i >= 0; i--){
        if((stopIndex - i) % 3 === 0 && i !== 0){
            currency = currency.slice(0, i) + ',' + currency.slice(i);
        }
    }
    return (isNegative ?  "-$" : "$") + currency;
};

export const getNextInvoiceDate = (moment) => {
    let momentInstance = moment.clone();
    if(momentInstance.date() <= 10){
        momentInstance.date(10);
        return momentInstance;
    }
    if(momentInstance.date() <= 20){
        momentInstance.date(20);
        return momentInstance;
    }

    return momentInstance.endOf("month");

};

export const getInvoicePeriodStart = (moment) => {
    let momentInstance = moment.clone();
    if(momentInstance.date() <= 10){
        momentInstance.date(1);
        return momentInstance;
    }
    if(momentInstance.date() <= 20){
        momentInstance.date(10);
        return momentInstance;
    }

    momentInstance.date(20);
    return momentInstance;
};

export const validateSSN = (ssn) => {
    let patt = new RegExp("\\d{3}[\\-]\\d{2}[\\-]\\d{4}");
    return patt.test(ssn);
}
export const validateEIN = (ein) => {
    let patt = new RegExp("\\d{2}[\\-]\\d{7}");
    return patt.test(ein);
}

export const validatePhoneNumber = (number) => {
    return number.match(/\d/g).length===10;
};

export const validateEmail = (email) => {
    let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (!email) return false;

    let emailParts = email.split('@');

    if (emailParts.length !== 2) return false

    let account = emailParts[0];
    let address = emailParts[1];

    if (account.length > 64) return false

    else if (address.length > 255) return false

    let domainParts = address.split('.');
    if (domainParts.some(function (part) {
        return part.length > 63;
    })) return false;

    if (!tester.test(email)) return false;

    return true;
}

const URL_MATCHER = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const EMAIL_MATCHER = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const URLMatcher = (text) => {
    const match = URL_MATCHER.exec(text);
    return (
        match && {
            index: match.index,
            length: match[0].length,
            text: match[0],
            url: match[0]
        }
    );
};

export const EmailMatcher = (text) => {
    const match = EMAIL_MATCHER.exec(text);
    return (
        match && {
            index: match.index,
            length: match[0].length,
            text: match[0],
            url: `mailto:${match[0]}`
        }
    );
}
