import React from "react";
import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Icon from "worksmith/components/Icon/Icon";
import {IconType} from "worksmith/enums/MaterialEnums";
import Modal from "worksmith/components/Modal/Modal";
import ActionableModalTemplate from "worksmith/components/Modal/Templates/ActionableModalTemplate";
import TextField, {TextFieldType} from "worksmith/components/Inputs/TextField/TextField";
import PropTypes from "prop-types";
import {ValueIsSet} from "worksmith/helpers/GenericHelpers";

const ButtonEmailModal = ({
     belowTextAreaSectionJSX,
     disabled,
     handleDownload,
     handleEmailChange,
     initialCSVEmail
 }) => {

    const [modalOpen, setModalOpen] = React.useState(false);
    const [emailAddress, setEmailAddress] = React.useState(ValueIsSet(initialCSVEmail) ? initialCSVEmail : "");
    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const onEmailChange = (emailString) => {
        if(ValueIsSet(handleEmailChange))
            handleEmailChange(emailString);

        setEmailAddress(emailString);
    };

    return (
        <>
            <Button debounce secondary disabled={disabled} onClick={handleOpen} variant={ButtonVariant.OUTLINED}>
                <Icon left name={IconType.SYSTEM_UPDATE_ALT} />
                Export To CSV
            </Button>
            <Modal
                open={modalOpen}
                onClose={handleClose}
            >
                <ActionableModalTemplate
                    title={"Your CSV export will be emailed to you"}
                    subtitle={"Confirm the email address you want your export sent to:"}
                    body={
                    <>
                        <TextField
                            initialValue={ValueIsSet(initialCSVEmail) ? initialCSVEmail : ""}
                            onChange={value => {onEmailChange(value)}}
                            fullWidth
                            label={'Email Address'}
                            name={'emailAddress'}
                            type={TextFieldType.email}
                        />
                        {belowTextAreaSectionJSX}
                    </>
                    }
                    buttons={[
                        <Button secondary variant={ButtonVariant.OUTLINED} onClick={handleClose}>
                            Cancel
                        </Button>,
                        <Button primary onClick={() => { handleDownload(emailAddress); handleClose();}}>
                            Send
                        </Button>
                    ]}
                />
            </Modal>
        </>
    )
}

export default ButtonEmailModal;

ButtonEmailModal.propTypes = {
    belowTextAreaSectionJSX: PropTypes.element,
    disabled: PropTypes.bool,
    handleDownload: PropTypes.func.isRequired,
    handleEmailChange: PropTypes.func,
    initialCSVEmail: PropTypes.string
};
