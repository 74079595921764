import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InvoiceSectionTotal from "./InvoiceSectionTotal";
import {PastDueRanges} from './InvoiceOverviewList';
import Divider, {DividerVariant} from "worksmith/components/Divider/Divider.web";
import {InvoiceSearchType} from "./InvoiceSearch";

const TotalSection = styled.div`
    margin: .5em 2em .5em 4em;
`;

const TotalsSummary = (props) => {
    const {totalsSummary, type} = props;

    const pastDueAmount =  totalsSummary.oneToThirtyDaysPastDueAmount +
        totalsSummary.thirtyOneToSixtyDaysPastDueAmount +
        totalsSummary.sixtyOneToNinetyDaysPastDueAmount +
        totalsSummary.ninetyOneOrMoreDaysPastDueAmount;

    const openBalanceAmount =  totalsSummary.oneToThirtyDaysPastDueOpenBalance +
        totalsSummary.thirtyOneToSixtyDaysPastDueOpenBalance +
        totalsSummary.sixtyOneToNinetyDaysPastDueOpenBalance +
        totalsSummary.ninetyOneOrMoreDaysPastDueOpenBalance;

    const ninetyOneOrMore = <InvoiceSectionTotal amount={totalsSummary.ninetyOneOrMoreDaysPastDueAmount}
                                                 openBalance={totalsSummary.ninetyOneOrMoreDaysPastDueOpenBalance}
                                                 title={'Total for ' + PastDueRanges.NINETY_ONE_OR_MORE}/>;
    const sixtyOneToNinety = <InvoiceSectionTotal amount={totalsSummary.sixtyOneToNinetyDaysPastDueAmount}
                                                 openBalance={totalsSummary.sixtyOneToNinetyDaysPastDueOpenBalance}
                                                 title={'Total for ' + PastDueRanges.SIXTY_ONE_TO_NINETY}/>;
    const thirtyOneToSixty = <InvoiceSectionTotal amount={totalsSummary.thirtyOneToSixtyDaysPastDueAmount}
                                                 openBalance={totalsSummary.thirtyOneToSixtyDaysPastDueOpenBalance}
                                                 title={'Total for ' + PastDueRanges.THIRTY_ONE_TO_SIXTY}/>;
    const oneToThirty = <InvoiceSectionTotal amount={totalsSummary.oneToThirtyDaysPastDueAmount}
                                                 openBalance={totalsSummary.oneToThirtyDaysPastDueAmount}
                                                 title={'Total for ' + PastDueRanges.ONE_TO_THIRTY}/>;
    const pastDue = <InvoiceSectionTotal amount={pastDueAmount} openBalance={openBalanceAmount} title={'TOTAL PAST DUE'}/>;
    const outstanding = <InvoiceSectionTotal amount={totalsSummary.outstandingAmount}
                                             openBalance={totalsSummary.outstandingOpenBalance}
                                             title={'TOTAL OUTSTANDING'}/>;
    const credit = <InvoiceSectionTotal amount={totalsSummary.creditAmount} openBalance={totalsSummary.creditOpenBalance} title={'TOTAL CREDITS'}/>;
    const total = <InvoiceSectionTotal amount={totalsSummary.totalAmount}
                                       openBalance={totalsSummary.totalOpenBalance}
                                       title={'TOTAL UNPAID'}/>;

    return (
        <Fragment>
            <TotalSection>
                {ninetyOneOrMore}
            </TotalSection>
            <Divider variant={DividerVariant.FULL_WIDTH}/>
            <TotalSection>
                {sixtyOneToNinety}
            </TotalSection>
            <Divider variant={DividerVariant.FULL_WIDTH}/>
            <TotalSection>
                {thirtyOneToSixty}
            </TotalSection>
            <Divider variant={DividerVariant.FULL_WIDTH}/>
            <TotalSection>
                {oneToThirty}
            </TotalSection>
            <Divider variant={DividerVariant.FULL_WIDTH}/>
            <TotalSection>
                {pastDue}
            </TotalSection>
            {type !== InvoiceSearchType.PAST_DUE ?
                <Fragment>
                    <Divider variant={DividerVariant.FULL_WIDTH}/>
                    <TotalSection>
                        {outstanding}
                    </TotalSection>
                </Fragment> : null }
            <Divider variant={DividerVariant.FULL_WIDTH}/>
            <TotalSection>
                {credit}
            </TotalSection>
            {type !== InvoiceSearchType.PAST_DUE ?
                <Fragment>
                    <Divider variant={DividerVariant.FULL_WIDTH}/>
                    <TotalSection>
                        {total}
                    </TotalSection>
                </Fragment> : null}
        </Fragment>
    )
};

TotalsSummary.propTypes = {
    totalsSummary: PropTypes.object,
    type:          PropTypes.string
};

export default TotalsSummary;