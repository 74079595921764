import React from 'react';
import PropTypes from "prop-types";

import RegionalView from "./RegionalView";

import {AlignItems} from "../../../../enums/CSSEnums";
import Accordion from '../../../../components/Accordion/Accordion'
import Text, {TextVariant} from "../../../../components/Text/Text";
import Grid from "../../../../components/Grid/Grid";

const RegionalPanel = ({regionalViewFields, vendorPortal}) => {

    const panelSummary = (
        <Grid container>
            <Grid container item xs={12} alignItems={AlignItems.CENTER}>
                <Text variant={TextVariant.H6}>
                    {"Regional Users"}
                </Text>
            </Grid>
        </Grid>
    );

    return (
        <Accordion
            summary={panelSummary}
            details={
                <RegionalView
                    regionalViewFields={regionalViewFields}
                    vendorPortal={vendorPortal}
                />
            }
            elevation={0}
            large
            width={'95%'}
        />
    )
};

export default RegionalPanel;

RegionalPanel.propTypes = {
    regionalViewFields: PropTypes.arrayOf(PropTypes.shape({
        location: PropTypes.string,
        userColumn: PropTypes.string,
        approvalThreshold: PropTypes.number
    })),
    vendorPortal: PropTypes.bool,
};

