import React, {Component} from 'react';
import t from 'tcomb-form';
import Button from "../Button";

const Form = t.form.Form;
class ShippingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: {},
            loading: false,
            handleSubmit: props.handleSubmit
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        this.setState({value});
    }

    onSubmit() {
        let _this = this;

        let { value } = this.state;

        _this.setState({loading: true});
        this.state.handleSubmit(value.trackingNumber);
    }

    render() {
        let {loading } = this.state;

        const ShippingFormStruct = t.struct({
            trackingNumber: t.String,
        });

        const ShippingFormOptions = {
            fields: {
                trackingNumber: {
                    label: 'What is the tracking number for this shipment?'
                }
            }
        };

        return (
            <div className="ibox-content">
                <section id="shipping">
                        <Form type={ShippingFormStruct} options={ShippingFormOptions} value={this.state.value}
                              onChange={this.onChange}/>

                        <div>
                            <Button type={'success'} message={loading ? 'Processing...' : 'Submit & Print Packing Slip'}
                                    onPress={() => this.onSubmit() }/>
                        </div>
                </section>
            </div>
        )
    }
}

export default ShippingForm;
