import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Display, FlexDirection} from "worksmith/enums/CSSEnums";
import {Color} from "worksmith/enums/Color";
import Paragraph from "../../../antd-components/Paragraph/Paragraph";
import Linkify from 'react-linkify';
import FileUpload from "worksmith/components/FileUpload/FileUpload";


const MessageHeader = styled.div`
    display: ${Display.FLEX};
    flex-direction: ${FlexDirection.COLUMN};
    margin-bottom: 1em;
`;

const Author = styled.div`
    color: ${Color.NAVY};
    font-weight: bold;
   ${props => props.shiftRight ? 'text-align: right;' : null}
`;

const CreatedTimestamp = styled.div`
    color: ${Color.BLUE};
    font-size: .9em;
    ${props => props.shiftRight ? 'text-align: right;' : null}
`;

const MessageContainer = styled(Paragraph)`
    max-width: 60%;
    ${props => props.shiftRight ? 
    `display: inline-block;
    text-align: left;`
    : null}
`;

const Message = (props) => {
    let {attachments, author, content, createdTimestamp, shiftRight} = props;

    if(attachments && attachments.length){
        attachments = attachments.map(att => ({
            ...att,
            url: att.singleUseUrl
        }))
    }

    return (
        <Fragment>
            <MessageHeader>
                <Author shiftRight={shiftRight}>
                    {author}
                </Author>
                <CreatedTimestamp shiftRight={shiftRight}>
                    {createdTimestamp}
                </CreatedTimestamp>
            </MessageHeader>
            <div style={shiftRight ? {textAlign: "right"} : null}>
                <MessageContainer shiftRight={shiftRight}>
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        {content}
                    </Linkify>
                    <FileUpload initialFiles={attachments} readOnly />
                </MessageContainer>
            </div>
        </Fragment>
    )
};

Message.propTypes = {
    author: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    createdTimestamp: PropTypes.string.isRequired,
    shiftRight: PropTypes.bool
};

export default Message;