import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import moment from "moment";

import RequestStateLayout from "../RequestStateLayout";
import {TitleCase} from "worksmith/helpers/LanguageHelper";
import NewRequestStatus from "../../../../enums/api/proposal/NewRequestStatus";
import {TextColor} from "worksmith/components/Text/Text.web";
import {MomentFormat} from "worksmith/enums/MomentFormat";
import Button, {ButtonSize, ButtonVariant} from "../../../../components/Button/Button.web";
import {Display} from "worksmith/enums/CSSEnums";
import AlertDialog from "../../../../components/Dialog/AlertDialog";
import UserAffiliationType from "worksmith/enums/api/user/UserAffiliationType";
import CancelRequest from "worksmith/composite-components/CancelRequest/CancelRequest";

class AdminCorporateReviewView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            approvalModalOpen: false,
            cancelModalOpen: false,
            loadingModals: false,
        };
    }

    showApproveModal = () => {
        this.setState({approvalModalOpen: true});
    }

    showCancelModal = () => {
        this.setState({cancelModalOpen: true});
    }

    hideApproveModal = () => {
        this.setState({approvalModalOpen: false});
    }

    hideCancelModal = () => {
        this.setState({cancelModalOpen: false});
    }

    render() {
        const {approveRequest, loading, onCancel, request} = this.props;
        const {approvalModalOpen, cancelModalOpen} = this.state;
        const {hideApproveModal, hideCancelModal, showApproveModal, showCancelModal} = this;

        let onRequestApprove = () => {
            hideApproveModal();
            approveRequest();
        }

        let onRequestCancel = () => {
            hideCancelModal();
            onCancel();
        }

        let description = '';
        if (!loading) {
            let creatorTitle = '';

            switch(UserAffiliationType[request.creator.affiliationType]) {
                case UserAffiliationType.CLIENT:
                    creatorTitle = "Admin User - ";
                    break;
                case UserAffiliationType.MULTIPLE_CLIENT_LOCATIONS:
                    creatorTitle = "Regional Manager - ";
                    break;
                case UserAffiliationType.CLIENT_LOCATION:
                    creatorTitle = "Store Manager - ";
                    break;
                case UserAffiliationType.WORKSMITH:
                    creatorTitle = "Worksmith User - ";
                    break;
                default:
                    break;
            }

            description = creatorTitle + request.creator.displayName
                + ' submitted this request on '
                + moment(request.createdTimestamp).format(MomentFormat.MonthDayYearTimeSlashWithAt)
                + ' (ET). Please "Release To Market" if you\'d like a Worksmith vendor to complete this request.'
                + '\n'
                + '\n'
                + 'If this issue will be handled internally or done under warranty from an external vendor you can Cancel the request when ready.';
        }

        return (
            <Fragment>
                {
                    !loading ?
                        <Fragment>
                            <AlertDialog
                                acceptText={'Yes, Release'}
                                body={'This will release the request to the Worksmith vendor network. Continue?'}
                                cancelText={'No, Back'}
                                debounceAccept
                                onAccept={onRequestApprove}
                                onCancel={hideApproveModal}
                                title={'Release to Market?'}
                                open={approvalModalOpen}/>
                            <CancelRequest
                                cancelRequestModalOpen={cancelModalOpen}
                                closeCancelRequestModal={hideCancelModal}
                                requestId={request.id}
                            />
                        </Fragment>
                        :
                        null
                }
                <RequestStateLayout
                    description={description}
                    loading={loading}
                    status={TitleCase(NewRequestStatus.CORPORATE_REVIEW)}
                    statusColor={TextColor.ERROR}
                    body={
                        <Box display={Display.INLINE_FLEX}>
                            <Box mr={2}>
                                <Button onClick={showCancelModal}
                                        size={ButtonSize.LARGE}
                                        disabled={loading}
                                        variant={ButtonVariant.OUTLINED}
                                        primary>
                                    Cancel Request
                                </Button>
                            </Box>
                            <Button onClick={showApproveModal}
                                    size={ButtonSize.LARGE}
                                    disabled={loading}
                                    primary>
                                Release To Market
                            </Button>
                        </Box>
                    }/>
            </Fragment>
        )
    }
};

AdminCorporateReviewView.defaultProps = {
    approveRequest: PropTypes.func.isRequired,
    loading: true,
    onCancel: PropTypes.func.isRequired,
    request: PropTypes.shape({
        id: PropTypes.number,
        createdTimestamp: PropTypes.string,
        creator: PropTypes.shape({
            displayName: PropTypes.string
        })
    })
}

AdminCorporateReviewView.propTypes = {
    loading: PropTypes.bool
};

export default AdminCorporateReviewView;