// This file was generated from com.worksmith.cr.enums.ChangeRequestStatus
// noinspection JSUnusedGlobalSymbols
const ChangeRequestStatus = Object.freeze({
    NEEDS_CLIENT_INPUT: 'needs client input',
    NEEDS_VENDOR_INPUT: 'needs vendor input',
    CANCELED: 'canceled',
    ACCEPTED: 'accepted',
    getClosedStatuses: function() {
        return [this.CANCELED, this.ACCEPTED];
    },
    getOpenStatuses: function() {
        return [this.NEEDS_CLIENT_INPUT, this.NEEDS_VENDOR_INPUT];
    }
});

export default ChangeRequestStatus;
