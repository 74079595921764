import React from 'react';
import PropTypes from 'prop-types';

import {AlignItems} from "../../../../enums/CSSEnums";
import Button, {ButtonVariant} from "../../../../components/Button/Button";
import {Color} from "../../../../enums/Color";
import Grid from "../../../../components/Grid/Grid";
import Icon from "../../../../components/Icon/Icon";
import {IconType} from "../../../../enums/MaterialEnums";
import Text, {TextVariant} from "../../../../components/Text/Text";

const ArrivalView = ({arrivalFormFields, readOnly, toggleEdit}) => {

    const LabelText = ({text}) => (
        <Text variant={TextVariant.BODY_2} color={Color.TEXT_SECONDARY} gutterBottom={true} semiBold>{text}</Text>
    )

    const ContentText = ({text}) => (
        <Text variant={TextVariant.BODY_1} gutterBottom={true}>{text}</Text>
    )

    return (
        <Grid container alignItems={AlignItems.FLEX_START}>
            <Grid container item xs={12} alignItems={AlignItems.FLEX_START}>
                <Grid container item spacing={3} xs={12} md={8}>
                    <Grid item xs={12}>
                        <LabelText text={'Parking Instructions'} />
                        <ContentText text={arrivalFormFields.parkingDirections ? arrivalFormFields.parkingDirections : '—'} />
                    </Grid>
                    <Grid item xs={12}>
                        <LabelText text={'Please describe the service entrance location'} />
                        <ContentText text={arrivalFormFields.serviceEntranceDescription ? arrivalFormFields.serviceEntranceDescription : '—'} />
                    </Grid>
                    <Grid item xs={12}>
                        <LabelText text={'Any additional notes regarding location access?'} />
                        <ContentText text={arrivalFormFields.additionalAccessInfo ? arrivalFormFields.additionalAccessInfo : '—'} />
                    </Grid>
                </Grid>
            </Grid>
            {!readOnly ?
                <Grid container item xs={8} margin={'32px 0 0 0'}>
                    <Grid item xs={2}>
                        <Button variant={ButtonVariant.OUTLINED} startIcon={<Icon name={IconType.EDIT}/>}
                                onClick={toggleEdit}>EDIT</Button>
                    </Grid>
                </Grid>
                : null
            }
        </Grid>
    )
};

export default ArrivalView;

ArrivalView.propTypes = {
    arrivalFormFields: PropTypes.shape({
        parkingDirections: PropTypes.string,
        serviceEntranceDescription: PropTypes.string,
        additionalAccessInfo: PropTypes.string,
    }),
    readOnly: PropTypes.bool,
    toggleEdit: PropTypes.func
}
