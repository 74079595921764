import React from 'react';
import PropTypes from 'prop-types';
import {Add} from "@mui/icons-material";

import {AlignItems} from "../../../enums/CSSEnums";
import Button, {ButtonVariant} from "../../../components/Button/Button";
import {ComponentColor} from "worksmith/enums/Color";
import FileUpload from "../../../components/FileUpload/FileUpload";
import Grid from "../../../components/Grid/Grid";
import Text, {TextColor, TextVariant} from "../../../components/Text/Text";
import TextField, {TextFieldType} from "../../../components/Inputs/TextField/TextField";
import CircularProgress from "../../../components/Loader/CircularProgress";


const MediaLibraryForm = ({addAttachment, fileUpload, isSubmitting, submitMedia, toggleEdit}) => {

    return (
        <Grid container alignItems={AlignItems.FLEX_START} spacing={3}>
            <Grid item xs={12} md={8}>
                <Text variant={TextVariant.BODY_1} color={TextColor.TEXT_SECONDARY}>
                    Add any additional documents, photos, or videos that could be helpful to vendors such as floorplans, images of appliances, video tours of your location, etc.
                </Text>
            </Grid>
            <Grid item xs={12}>
                <FileUpload
                    initialFiles={fileUpload}
                    multiple
                    onChange={addAttachment}
                />
            </Grid>
            <Grid container item xs={8} margin={'32px 0 0 0'}>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} onClick={toggleEdit}>CANCEL</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} primary onClick={submitMedia}>{isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default MediaLibraryForm;

MediaLibraryForm.propTypes = {
    submitMedia: PropTypes.func,
    addAttachment: PropTypes.func,
    fileUpload: PropTypes.shape({
        file: PropTypes.arrayOf(PropTypes.object),
        description: PropTypes.string
    }),
    isSubmitting: PropTypes.bool,
    toggleEdit: PropTypes.func
}
