import React from 'react';
import PropTypes from 'prop-types';

import {AlignItems} from "../../../../enums/CSSEnums";
import Button, {ButtonVariant} from "../../../../components/Button/Button";
import Chip from "../../../../components/Chip/Chip";
import {DaysOfWeek} from '../../../../enums/Time'
import Icon from "../../../../components/Icon/Icon";
import IconButton from "../../../../components/Button/IconButton";
import {IconType} from "../../../../enums/MaterialEnums";
import Grid from "../../../../components/Grid/Grid";
import TextField, {TextFieldType} from "../../../../components/Inputs/TextField/TextField";
import Text, {TextVariant} from "../../../../components/Text/Text";
import CircularProgress from "../../../../components/Loader/CircularProgress";
import Switch from "worksmith/components/Switch/Switch";


const LocationHoursForm =
    ({
         handleAddLocationHours,
         handleChange,
         handleRemoveLocationHours,
         isSubmitting,
         submitLocationHoursInfo,
         timeBlocks,
         toggleEdit
    }) => {

    const days = (value, timeBlock) => Object.entries(DaysOfWeek).map((time, idx) => {
        return (
            <Grid item key={time[1]}>
                <Chip label={time[0]} initialValue={value.includes(time[1])} onClick={() => handleChange('day', time[1], timeBlock)} selectedIconName={null} size={'small'} width={'48px'}/>
            </Grid>
        )
    })

    const daysInBlock = timeBlocks.map(block => block.days.map(b => b.day))
    const scheduler = timeBlocks.map((block, idx) => {
        return (
            <Grid key={`block - ${Math.random() * 100}`} container item spacing={4} margin={idx !== timeBlocks.length - 1 ? '0 0 16px 0' : 0} xs={12}>
                <Grid container item xs={12} alignItems={AlignItems.CENTER}>
                    <Grid item>
                        <Text gutterBottom variant={TextVariant.BODY_1}>Open from</Text>
                        <TextField onChange={value => handleChange('openTime', value, block)} initialValue={block.days[0] && block.days[0].openTime ? block.days[0].openTime : null} type={TextFieldType.TIME} />
                    </Grid>
                    <Grid item>
                        <Text gutterBottom variant={TextVariant.BODY_1}>{' '}</Text>
                        <Text variant={TextVariant.BODY_1}>to</Text>
                    </Grid>
                    <Grid item>
                        <Text gutterBottom variant={TextVariant.BODY_1}>{' '}</Text>
                        <TextField onChange={value => handleChange('closeTime', value, block)} initialValue={block.days[0] && block.days[0].closeTime ? block.days[0].closeTime : null} type={TextFieldType.TIME} />
                    </Grid>
                    {idx !== 0 ?
                        <Grid item>
                            <Text gutterBottom variant={TextVariant.BODY_1}>{' '}</Text>
                            <IconButton
                                onClick={() => handleRemoveLocationHours(idx)}
                                secondary
                                iconName={IconType.DELETE}
                                size="medium" />
                        </Grid>
                        :
                        null
                    }
                </Grid>
                <Grid item>
                    <Text variant={TextVariant.BODY_1}>Closed</Text>
                    <Switch
                        group
                        initialValue={block.days[0].closed}
                        onChange={value => handleChange('closed', value, block)}
                        secondary
                    />
                </Grid>
                <Grid xs={12} item>
                    <Grid item margin={'0 0 0 -16px'}>
                        <Text gutterBottom variant={TextVariant.BODY_1}>On the following days</Text>
                        <Grid container>
                            {days(daysInBlock[idx], block)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    })

    return (
        <Grid container spacing={4} alignItems={AlignItems.FLEX_START}>
            <Grid container spacing={4} item xs={12} alignItems={AlignItems.FLEX_START}>
                {scheduler}
                {
                    timeBlocks.length < 7 ?
                        <Grid item xs={12}>
                            <Grid item>
                                <Button variant={ButtonVariant.TEXT} onClick={handleAddLocationHours} startIcon={<Icon name={IconType.ADD} />}>ADD MORE HOURS</Button>
                            </Grid>
                        </Grid>
                        :
                        null
                }
            </Grid>
            <Grid container item xs={8} margin={'32px 0 0 0'}>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} onClick={toggleEdit}>CANCEL</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button fullWidth disabled={isSubmitting} primary onClick={submitLocationHoursInfo}>{isSubmitting ? <CircularProgress size={20} /> : 'SAVE'}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default LocationHoursForm;

LocationHoursForm.propTypes = {
    handleAddLocationHours: PropTypes.func,
    handleChange: PropTypes.func,
    handleRemoveLocationHours: PropTypes.func,
    isSubmitting: PropTypes.bool,
    submitLocationHoursInfo: PropTypes.func,
    timeBlocks: PropTypes.arrayOf(PropTypes.shape({
        days: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            day: PropTypes.string,
            dayOfWeekInteger: PropTypes.number,
            openTime: PropTypes.string,
            openHours: PropTypes.string,
            closeTime: PropTypes.string,
            closed: PropTypes.bool,
        })),
        time: PropTypes.string
    })),
    toggleEdit: PropTypes.func
}
