import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Button, {ButtonVariant} from "worksmith/components/Button/Button";
import Grid from "worksmith/components/Grid/Grid";
import RadioGroupPaper from "worksmith/components/Radio/RadioGroupPaper";
import Text, {TextVariant} from "worksmith/components/Text/Text";
import {JustifyContent} from "worksmith/enums/CSSEnums";
import {vendorOrInHouseTailorType} from "./AddInHouseTailorRelationship";


const SelectAddVendorOrInHouseTailor = props => {
    const {
        setAddVendorOrInHouseTailor,
        setShowAddEditVendor
    } = props;

    const [accountType, setAccountType] = useState(false);

    const radioOptions = [
        {
            label: 'Add a Vendor',
            value: vendorOrInHouseTailorType.ADD_VENDOR
        },
        {
            label: 'Create an In-House Tailor Account',
            value: vendorOrInHouseTailorType.IN_HOUSE_TAILOR
        },
    ];


    return (
        <Grid container width={700}>
            <Grid item xs={12} padding={'24px 0 24px 8px'}>
                <Text variant={TextVariant.H5}>{"Add Vendor"}</Text>
            </Grid>
            <Grid xs={12}>
                <RadioGroupPaper
                    options={radioOptions}
                    onChange={(value) => setAccountType(value)}
                />
            </Grid>
            <Grid container xs={12} justify={JustifyContent.FLEX_END} margin={'1rem 0 0 0'}>
                <Grid item>
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => setShowAddEditVendor(false)}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={!accountType}
                        onClick={() => {
                            setAddVendorOrInHouseTailor(accountType)}
                        }
                        primary
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};



SelectAddVendorOrInHouseTailor.propTypes = {
    setAddVendorOrInHouseTailor: PropTypes.func,
    setShowAddEditVendor: PropTypes.func,
};

export default SelectAddVendorOrInHouseTailor;